import React, { Fragment } from 'react';
import _ from 'services/i18n';
import lodash from 'lodash';

export function PatientGoalsTable({ error, loading, goals, onShowGoal }) {
    if (error) {
        return <BodyMessage>{_`Sorry, the server reported an error.`}</BodyMessage>;
    }

    if (loading) {
        return <BodyMessage>{_`Loading. Please wait...`}</BodyMessage>;
    }

    return (
        <div className="goals-list">
            <br /><br />
            <div className="table-responsive">
                <table className="table table-bordered table-condensed table-nomargin">
                    <thead>
                        <tr>
                            <th>{_`Goals`}</th>
                            <th>{_`Description`}</th>
                            <th>{_`Target`}</th>
                            <th>{_`Status`}</th>
                        </tr>
                    </thead>
                    {goals.length && (
                        <GoalsItems
                            goals={goals}
                            onShowGoal={onShowGoal}
                        />
                    )}
                    {!goals.length && (
                        <tbody>
                            <tr>
                                <td colSpan="4">{_`Sorry, no goals found.`}</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
}

const BodyMessage = ({ children }) => {
    return (
        <div>{children}</div>
    );
};

function GoalsItems({ goals, onShowGoal }) {
    return (
        <Fragment>
            {goals.map((goal, idx) => {
                const label = lodash.get(goal, 'content.label');
                const description = lodash.get(goal, 'content.description');
                const type = lodash.get(goal, 'content.type');
                const givenValue = lodash.get(goal, 'content.given_value');
                const unit = lodash.get(goal, 'content.unit');

                return (
                    <tbody key={idx}>
                        <tr>
                            <td className="vmiddle">
                                <button className="btn btn-link" onClick={onShowGoal.bind(null, goal)}>
                                    {label}
                                </button>
                            </td>
                            <td className="vmiddle">
                                <p>{description}</p>
                            </td>
                            <td className="vmiddle">
                                {(!type || type === 'numerical' || type === 'steps-target') && givenValue
                                && <div>{unit ? unit : _`steps`}: {givenValue ? givenValue : 0}</div>
                                }
                            </td>
                            <td className={'vmiddle ' + getStatusClass(goal)}>
                                {getStatusText(goal)}
                            </td>
                        </tr>
                    </tbody>
                );
            })}
        </Fragment>
    );
}

function getStatusClass(goal) {
    switch (lodash.get(goal, 'content.status', '').toLowerCase()) {
    case 'active':
        return 'success';
    case 'completed':
        return 'info';
    case 'deleted':
        return 'danger';
    }
    return '';
}

function getStatusText(goal) {
    const original = lodash.get(goal, 'content.status', '');
    switch (original.toLowerCase()) {
    case 'active':
        return 'Active';
    case 'completed':
        return 'Completed';
    case 'deleted':
        return 'Hidden';
    }
    return original;
}
