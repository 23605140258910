import React, { FunctionComponent } from 'react';
import { Link } from 'react-router';

import _ from 'services/i18n';
import { useTeamPreferences } from 'common/useTeamPreferences';

interface TopBarProps {
    inPathway?: string;
    backToPathwayLink?: string;
    backToQuestionnairesListLink?: string;
    allocateQuestionnaireLink?: string;
    allocateSimpleQuestionnaireLink?: string; 
    onCompleteQuestionnaire: () => void;
    canBeCompletedByClinician: boolean;
    isEditMode?: boolean;
    showAllocateQuestionnaire?: boolean;
}

export const TopBar: FunctionComponent<TopBarProps> = (props) => {

    const {
        inPathway,
        backToPathwayLink,
        backToQuestionnairesListLink,
        allocateQuestionnaireLink,
        allocateSimpleQuestionnaireLink,
        onCompleteQuestionnaire,
        canBeCompletedByClinician,
        isEditMode,
        showAllocateQuestionnaire = true,
    } = props;

    const createPathway = inPathway ? `?pathway=${encodeURIComponent(inPathway)}` : '';
    const { portal } = useTeamPreferences();

    const onPrint = () => window.print();

    return (
        <div className='top-bar top-bar--no-padding'>
            <div className="top-bar__bt-left">
                {inPathway
                    ? <Link to={backToPathwayLink} className={'top-bar__button'}>{_`Back to Pathway`}</Link>
                    : (
                        <Link to={backToQuestionnairesListLink}
                            className={'top-bar__button'}>{_`Back to Questionnaire List`}</Link>
                    )}
                {(!isEditMode && showAllocateQuestionnaire) && (
                    <Link to={`${allocateQuestionnaireLink}${createPathway}`}
                        className={'top-bar__button'}>{_`Allocate Questionnaire`}</Link>
                )}
                {(!isEditMode && showAllocateQuestionnaire) && (
                     <Link to={`${allocateSimpleQuestionnaireLink}`}
                     className={'top-bar__button'}>{_`Send Simple Questionnaire`}</Link>
                )}
            </div>
            <div className="top-bar__bt-right">
                {!!portal.show_print_button && <a onClick={onPrint} className={'top-bar__button'}>{_`Print`}</a>}
                {canBeCompletedByClinician && (
                    <a onClick={onCompleteQuestionnaire}
                        className={'top-bar__button'}>{_`Complete Questionnaire`}</a>
                )}
            </div>
        </div>
    );
};
