import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'services/i18n';
import lodash from 'lodash';
import { siteLinks } from './adminPortal.siteLinks';
import Sidebar from 'layouts/template/_sidebar';
import Header from 'layouts/template/_header';
import { AdminSidebar } from './AdminSidebar';
import { useTeamPreferences } from 'common/useTeamPreferences';

const SidebarTeamPrefFilter = (props) => {
    const teamPreferences = useTeamPreferences();
    const filteredLinks = props.menu.filter(link => {
        return link.teamPref === undefined || lodash.get(teamPreferences, link.teamPref);
    });
    return <Sidebar {...props} menu={filteredLinks} />;
};

export const AdminPortalTemplateLayout = ({
    children,
    currentTeam,
    currentOrg,
}) => {
    const [sidebarCollapse, setSidebarCollapse] = useState(false);
    const mainpageClass = sidebarCollapse ? 'expand-main-page' : '';

    const handleCheckAllowed = useCallback((item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'requireTeamId') && item.requireTeamId && !currentTeam.teamId) {
            return false;
        }

        if (Object.prototype.hasOwnProperty.call(item, 'requireOrgId') && item.requireOrgId) {
            if (!currentOrg.name || currentTeam.teamId) {
                return false;
            }
        }

        if (currentTeam.teamId && item.services) {
            if (!currentTeam.teamService) {
                console.error('Service is not defined for team');
                return false;
            }

            const { services } = item;
            const { teamService } = currentTeam;

            if (services.filter((service) => service === teamService).length === 0) {
                return false;
            }
        }

        if (currentOrg.name && item.services) {
            if (!currentOrg.services || !currentOrg.services.length) {
                return true;
            }

            const { services } = item;
            const { services: allowedServices } = currentOrg;

            if (services.filter((service) => allowedServices.indexOf(service) > -1).length === 0) {
                return false;
            }
        }

        return true;
    }, [currentOrg, currentTeam]);

    const adminSidebar = <AdminSidebar
        currentTeam={currentTeam}
        currentOrg={currentOrg}
    ></AdminSidebar>;

    return <div className="container-fluid hint-admin_portal">
        <Header title="Admin Portal" mainPage={mainpageClass} />
        <div className={'main-page ' + mainpageClass}>
            <SidebarTeamPrefFilter
                sideBarItems={() => adminSidebar}
                menu={siteLinks}
                title={_`Admin Portal`}
                link="/admin_portal"
                collapse={sidebarCollapse}
                onCheckAllowed={handleCheckAllowed}
                onToggleSidebar={() => {setSidebarCollapse(isCollapsed => !isCollapsed);}}
            />
            {children}
        </div>
        <div style={{ clear: 'both' }} />
    </div>;
};

AdminPortalTemplateLayout.propTypes = {
    children: PropTypes.element.isRequired,
    currentTeam: PropTypes.object,
    currentOrg: PropTypes.object,
};
