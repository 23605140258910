import React from 'react';
import { DropdownSelect, DropdownSelectOption } from 'common/ui/dropdown/DropdownSelect';
import { FolderSelectionState } from './FolderSelectionState';

export function SelectionOptionsDropdown(props: {folderSelectionState: FolderSelectionState; folderIds: number[]}): JSX.Element
{
    if (!props.folderIds
        || props.folderIds.length == 0)
    {
        return null;
    }

    return (
        <DropdownSelect
            placeholder="Select..."
            onChange={({ target: { value } }) =>
            {
                switch (value)
                {
                case 'All':
                    props.folderSelectionState.selectAll();
                    break;
                case 'Page':
                    props.folderSelectionState.selectOnlyFolderIds(props.folderIds);
                    break;
                case 'None':
                    props.folderSelectionState.reset();
                    break;
                default:
                    console.warn('Unhandled selection mode [' + value + ']');
                    break;
                }
            }}>
            <DropdownSelectOption value="All">All</DropdownSelectOption>
            <DropdownSelectOption value="Page">Page</DropdownSelectOption>
            <DropdownSelectOption value="None">None</DropdownSelectOption>
        </DropdownSelect>
    );
}
