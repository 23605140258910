import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import FormPanel from 'components/form-panel';
import TextInput from 'components/form-panel/text-input';
import SelectorInput from 'components/form-panel/selector-input';
import CheckboxInput from 'components/form-panel/checkbox-input';
import TypeaheadInput from 'components/form-panel/typeahead-input';
import {formatUbrn} from 'ui/ubrn';

import triageDecisionCodes from './triage-decision-codes';
import { APPOINTMENT_MODE, appointmentModeLabels } from 'services/appointment-helpers';
import _ from 'services/i18n';

// Maps appointment types to human-readable labels.
// Note that this is deliberately a subset for this simplified form.
// The first item is the default.
const appointmentModes = [
    {
        value: APPOINTMENT_MODE.FACE_TO_FACE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.FACE_TO_FACE),
    },
    {
        value: APPOINTMENT_MODE.NON_FACE_TO_FACE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.NON_FACE_TO_FACE),
    },
];

// Create the selector options for appointment types.
const modeOptions = appointmentModes.map(({ value, label }, idx) => {
    return (<option key={idx} value={value}>{label}</option>);
});

// Maps appointment types to human-readable labels.
// Note that this is deliberately a subset for this simplified form.
// The first item is the default.
const appointmentTypes = [
    ['outpatient', 'Outpatient'],
    ['telephone', 'Telephone']
];

// Create the selector options for appointment types.
const typeOptions = appointmentTypes.map((item, idx) => {
    return (<option key={idx} value={item[0]}>{item[1]}</option>);
});

// Create the selector options for triage decisions.
const triageDecisionCodeProps = Object.keys(triageDecisionCodes).sort();
const triageDecisionOptions = triageDecisionCodeProps.reduce((options, type) => {
    const codes = triageDecisionCodes[type].sort().map((code) => {
        return {
            label: code,
            value: code
        };
    });
    return options.concat({
        groupLabel: type,
        options: codes
    });
}, []);


function getTriageDecisionCodes(value = '') {
    if (value.trim() === '') {
        return triageDecisionOptions;
    }
    value = value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    const regex = new RegExp(value, 'i');

    return triageDecisionOptions
        .reduce((list, section) => {
            const item = {
                groupLabel: section.groupLabel,
                options: section.options.filter((option) => regex.test(option.label))
            };
            if (item.options.length <= 0) {
                return list;
            }
            list.push(item);
            return list;
        }, []);
}

/**
 * Displays a form for creating a triage decision.
 *
 * Props (data):
 * - referral = Required. The referral composition which is being updated.
 * - appointment = Optional. The appointment composition which is being updated. This is used to prepopulate the form. The values are set to empty if this is not present.
 * - busy = Optional. If truthy, the form will be disabled.
 * - appointmentOnly = Optional. For supporting new triage workflow
 *
 * Props (callbacks):
 * - onSubmit = Required. Called with the form contents when the submit button is clicked.
 * - onCancel = Required. Called if the cancel button is clicked.
 * - onFormChange = Required. Called if the value of the form input is changed.
 */
export const TriageDecisionForm = (props) => {

    const {
        referral,
        appointment,
        busy,
        onSubmit,
        onCancel,
        onFormChange,
        appointmentOnly
    } = props;

    if (!busy && !referral && !appointmentOnly) {
        console.warn('Expected referral composition in prop: referral');
    }

    const referralUbrn = appointmentOnly ? '' : formatUbrn(lodash.get(referral, 'content.ubrn', ''));
    const appointmentUbrn = formatUbrn(lodash.get(appointment, 'content.ubrn', ''));

    const isInterpreterRequired = lodash.get(appointment, 'interpreter.required', false);
    const isTransportRequired = lodash.get(appointment, 'transport.required', false);

    const defaultModeOption = (modeOptions && modeOptions[0] && modeOptions[0].props && modeOptions[0].props.value) || '';

    return (
        <div className='triage-decision'>

            <FormPanel
                title={'Triage Decision'}
                intro=''
                id='triage-decision-form'
                onSubmit={onSubmit}
                onChange={onFormChange}
                onCancel={onCancel}
                submitLabel={'Invite Patient'}
                cancelLabel={'Cancel'}
                busy={busy}>

                {!appointmentOnly && <TextInput
                    label={'Referral UBRN:'}
                    id="tdf-referral-ubrn"
                    disabled={true}
                    helpText={'The referral you are creating a triage decision for. To change this, go back to the referrals table and select a different referral.'}
                    value={referralUbrn}
                />}

                <TextInput
                    label={'Booking Password:'}
                    id='tdf-booking-password'
                    placeholder={'e.g. abcde 12345'}
                    initialValue={appointmentOnly ? '' : lodash.get(referral, 'content.booking_password', undefined)}
                    helpText={'This is the password which the patient will use to book their appointment on eRS.'}
                    required={true}
                />

                <TypeaheadInput
                    label={'Triage decision:'}
                    placeholder={'Select a triage decision code'}
                    id='tdf-triage-decision'
                    multiSection={true}
                    getSuggestions={getTriageDecisionCodes}
                    initialValue={appointmentOnly ? '' : lodash.get(referral, 'content.triage_decision', undefined)}
                    helpText={'Select the outcome of the triage. This is also known as the Clinic Code.'}
                    required={true}
                />

                <TextInput
                    label={'Appointment UBRN:'}
                    id='tdf-appointment-ubrn'
                    placeholder={'e.g. 0000 1111 2222'}
                    helpText={'Enter the UBRN for the bookable appointment.'}
                    required={true}
                    validation='numeric'
                    minLength={12}
                    maxLength={12}
                    spaces='remove'
                    initialValue={appointmentUbrn}
                />

                <SelectorInput
                    label={'Appointment Type:'}
                    id='tdf-appointment-type'
                    initialValue={lodash.get(appointment, 'content.type', appointmentTypes[0][0])}
                    helpText={'What kind of appointment will this be?'}
                    required={true}
                >
                    {typeOptions}
                </SelectorInput>

                <SelectorInput
                    label={'Appointment mode:'}
                    id='tdf-appointment-mode'
                    initialValue={defaultModeOption}
                    helpText={'What kind of appointment mode will this be?'}
                    required={true}
                >
                    {modeOptions}
                </SelectorInput>

                <CheckboxInput
                    label='Transport required'
                    id='tdf-transport-required'
                    initialValue={isTransportRequired}
                    helpText='Tick this box if the patient will need transport arranged for their appointment.'
                />

                <TextInput
                    label='Transport requirements details:'
                    id='tdf-transport-details'
                    value={lodash.get(appointment, 'transport.details', '')}
                    hideValidationSuccess={true}
                    disabled={!isTransportRequired}
                    helpText='Additional details about transport requirements'
                />


                <CheckboxInput
                    label='Interpreter required'
                    id='tdf-interpreter-required'
                    initialValue={isInterpreterRequired}
                    helpText='Tick this box if the patient will need an interpreter arranged for their appointment.'
                />

                <TextInput
                    label='Interpreter requirements details:'
                    id='tdf-interpreter-details'
                    disabled={!isInterpreterRequired}
                    value={lodash.get(appointment, 'interpreter.details', '')}
                    hideValidationSuccess={true}
                    helpText='Additional details about interpreter requirements'
                />

            </FormPanel>
        </div>
    );
};

TriageDecisionForm.propTypes = {
    onFormChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    referral: PropTypes.any,
    appointment: PropTypes.any,
    busy: PropTypes.any,
    appointmentOnly: PropTypes.bool
};

TriageDecisionForm.defaultProps = {
    appointment: {
        interpreter: {
            required: false
        },
        transport: {
            required: false
        }
    },
    appointmentOnly: false
};

export default TriageDecisionForm;
