import React, { FC } from 'react';
import { DASHBOARD_COMPONENT_NAMES, TeamPrefDashboardModule } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { dashboardComponentsMap } from './dashboard-components/componentsMap';

interface DashboardItemProps {
    data: TeamPrefDashboardModule;
}
const objDummy = {};
export const DashboardItem: FC<DashboardItemProps> = ({ data }) => {
    const Component = dashboardComponentsMap[data.name];

    if(!Component) {
        console.error('[DashboardItem] Unknown component:', data.name, '\nList of known components: ', DASHBOARD_COMPONENT_NAMES);
        return null;
    }

    const props = data.props || objDummy;


    if(!data.children) {
        return <Component {...props}/>;
    }

    return (
        <Component {...props}>
            {data.children.map((item, i) => {
                if(item.name === DASHBOARD_COMPONENT_NAMES.DashboardColumn) {
                    item.props = {
                        width: 100 / data.children.length,
                        ...(item.props || {}),
                    };
                }
                return <DashboardItem key={i} data={item}/>;
            })}
        </Component>
    );
};
