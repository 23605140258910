import React, { FC, useContext, useMemo } from 'react';
import { TeamPreferencesContext } from 'common/TeamPreferencesContext';
import { Dashboard } from './Dashboard';
import { DashboardContext } from './data/DashboardContext';
import { getDashboardData } from './data/getDashboardData';


export const PatientDashboardPage: FC = () => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { portal: { dashboard_modules: dashboardModules } } = useContext(TeamPreferencesContext);
    const ctxData = useMemo(() => {
        return {
            getDashboardData
        };
    }, []);

    return (
        <DashboardContext.Provider value={ctxData}>
            <Dashboard items={dashboardModules}/>
        </DashboardContext.Provider>
    );
};
export default PatientDashboardPage;
