import React, { useState, Fragment } from 'react';
import { EditDemographicsModalDialog } from './EditDemographicsModalDialog';
import { useTeamPreferences } from 'common/useTeamPreferences';

export const EditDemographicsButton = (props) => {
    const [isShowDialog, setShowDialog] = useState(false);
    const teamPreferences = useTeamPreferences();

    const handleClick = (e) => {
        setShowDialog(!isShowDialog);
        e.preventDefault();
    };

    if (!teamPreferences.portal.show_edit_patient) {
        return null;
    }

    return <Fragment>
        <a onClick={handleClick}>Edit demographics</a>
        {isShowDialog && <EditDemographicsModalDialog
            patientData={props.dataForModal}
            givenName={props.dataForModal.name.given_name}
            middleName={props.dataForModal.name.middle_name}
            familyName={props.dataForModal.name.family_name}
            primaryIdentifier={props.personPrimaryIdentifier}
            dateOfBirth={props.dataForModal.date_of_birth}
            folderId={props.folderIdForModal}
            onClose={() => setShowDialog(false)}
        />}
    </Fragment>;
};
