import { apiV2Service, fetchAll } from './api-v2.service';
import { IFolderSelectorParam, IRole } from 'phr-api-client/index';
import { DataStore } from 'services/data-store';
import { appointmentsService } from './appointments.service';
import { goalsService } from './goals.service';
import { messagesService } from './messages.service';
import { referralsService } from './referrals.service';
import { questionnaireService } from './questionnaire.service';

class SyncService {
    fullSync({role, folderId, archetypeNames, limit = 100, offset = 0}: {limit?: number, offset?: number, archetypeNames?: string, role?: IRole, folderId?: IFolderSelectorParam}): Promise<any> {
        if(limit === Infinity) {
            return this.fullSyncAll({role, folderId, archetypeNames});
        }

        role = role || DataStore.get('me.currentRole');
        folderId = folderId || DataStore.get('currentFolder');

        archetypeNames = archetypeNames || [
            appointmentsService.archetypeName,
            goalsService.archetypeName,
            messagesService.archetypeName,
            referralsService.archetypeName
        ].concat(questionnaireService.responsesArchetypeNames).join(',');

        return apiV2Service.fullSync({
            role,
            folderId,
            archetypeNames,
            limit,
            offset
        });
    }

    private fullSyncAll({role, folderId, archetypeNames}: {archetypeNames?: string, role?: IRole, folderId?: IFolderSelectorParam}): Promise<Array<any>> {
        const limit = 100;
        return fetchAll((offset: number) => {
            return this.fullSync({role, folderId, archetypeNames, offset, limit});
        });
    }
}

export const syncService = new SyncService();
