
import { notesService } from 'services/notes.service';
import { UserNote } from 'models/compositions/UserNote';
import { Composition } from 'models/Composition';

export type UserNoteRetrievalFn = (
    limit: number,
    offset: number
) => Promise<{message: {total: number, results: Composition<UserNote>[]}}>;

export const useEntityNoteRetriever = (folderId: number, uuid: string): UserNoteRetrievalFn => (
    limit,
    offset,
) => notesService.search({
    folderId,
    limit,
    offset,
    sort: [{ ascending: false, field: 'created_at' }],
    search: {
        'content.parent_doc_uuid': uuid
    }
});