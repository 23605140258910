import { ExternalLink } from 'common/ui/ExternalLink';
import { HTMLModalDialog } from 'components/modals/html-modal';
import React, { useState } from 'react';
import mediaResourceService from 'services/media-resource/media-resource.service';
import { CoopResource } from 'models/compositions/CoopResource';

type ResourceLabelProps = {
    resource: CoopResource;
}
export default function ResourceLabel({ resource }: ResourceLabelProps) {
    const [isShowDialog, setIsShowDialog] = useState(false);

    function handleCreateButtonClick() {
        setIsShowDialog(true);
    }

    function handleCloseDialog() {
        setIsShowDialog(false);
    }

    const handleOpenResourceLink = (resource: CoopResource) => {
        if (resource.resource_type === 'media_resource') {
            mediaResourceService.getFileLink(resource.media_resource_uuid)
                .then((url) => {
                    window.open(url, '_blank');
                });
            return;
        }
        if(resource.resource_type === 'phone') {
            window.open(`tel:${resource.phone}`, '_blank');
            return;
        }
        let href = resource.url || '';
        href = href ? /https?:\/\//.test(href) ? href : `http://${href}` : '';
        window.open(resource.url);
    };

    if (resource.resource_type === 'html') {
        return (
            <td>
                <a onClick={handleCreateButtonClick}>{resource.label}</a>
                {isShowDialog && (
                    <HTMLModalDialog 
                        onClose={handleCloseDialog}
                        label={resource.label}
                        html_content={resource.html_content}></HTMLModalDialog>
                )
                }
            </td>
        );
    }
    
    return (<td>{<ExternalLink onClick={() => handleOpenResourceLink(resource)}>{resource.label}</ExternalLink>}</td>);
}
