import React, { FC } from 'react';
// interfaces
import { AdvancedSearchOutputFieldProps } from './AdvancedSearchOutputField.interface';
// styles
import './AdvancedSearchOutputField.scss';

const AdvancedSearchOutputField: FC<AdvancedSearchOutputFieldProps> = (
    props,
) => {
    return (
        <div className={'advanced-search-output-field'}>
            <div className={'advanced-search-output-field__buttons'}>
                <button
                    type="button"
                    className="btn btn-xs btn-primary glyphicon glyphicon-remove"
                    onClick={() => props.onRemove(props.path)}
                    disabled={props.outputConfig.default?.mandatory}
                />
                <button
                    type="button"
                    className="btn btn-xs glyphicon glyphicon-chevron-left"
                    onClick={() => props.onMove(props.path, -1)}
                    disabled={!props.allowMoveLeft}
                />
                <button
                    type="button"
                    className="btn btn-xs glyphicon glyphicon-chevron-right"
                    onClick={() => props.onMove(props.path, 1)}
                    disabled={!props.allowMoveRight}
                />
            </div>
            <label>
                <strong>{props.outputConfig.name}</strong>
            </label>
        </div>
    );
};

export default AdvancedSearchOutputField;
