import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { terService } from 'services/ter.service';
import { PatientContext } from 'pages/clinical_portal/folder/_folderId/patient/PatientContext';
import { ReferralsContext } from 'features/ReferralsProvider';

const emptyArray = [];
export function useReferralPathways(referralId) {
    const {
        itemMap,
        updateContent,
    } = useContext(ReferralsContext);

    const referral = itemMap.get(referralId);
    const labels = referral && referral.content ? referral.content.labels : emptyArray;

    const {teamId, folderId} = useContext(PatientContext);
    const [pathways, setPathways] = useState(new Map());
    const [allPathways, setAllPathways] = useState(new Map());

    const teamPreferences = useTeamPreferences();

    useEffect(() => {
        if (!labels) {
            return setPathways(new Map());
        }
        return setPathways(preparePathways(labels));
    }, [labels]);

    useEffect(() => {
        if (!teamPreferences) {
            return setPathways(new Map());
        }

        const pathways = preparePathways(teamPreferences.pathway_list || emptyArray);
        return setAllPathways(pathways);
    }, [teamPreferences, setPathways]);

    const handleAddPathway = useCallback(
        (pathway) => {
            const pathwayId = getPathwayId(pathway);
            const {name, type, context} = pathway;
            const newPathways = new Map([...pathways.entries(), [pathwayId, pathway]]);
            setPathways(newPathways);
            updateContent(referralId, {labels: getLabels(referralId, newPathways)});

            return terService.createTer({action: 'setLabel', folderId, teamId, data: {
                name, type, context, composition_uuid: referralId
            }})
                .catch((e) => {
                    const oldPathways = new Map([...pathways.entries()].filter(([mapId]) => mapId !== pathwayId))
                    setPathways(oldPathways);
                    updateContent(referralId, {labels: getLabels(referralId, oldPathways)});
                    return Promise.reject(e);
                });
        },
        [referralId, pathways, updateContent],
    );

    const handleRemovePathway = useCallback(
        (pathway) => {
            const pathwayId = getPathwayId(pathway);
            const {name, type, context} = pathway;
            const newPathways = new Map([...pathways.entries()].filter(([mapId]) => mapId !== pathwayId));
            setPathways(newPathways);
            updateContent(referralId, {labels: getLabels(referralId, newPathways)});
            return terService.createTer({action: 'unsetLabel', folderId, teamId, data: {
                name, type, context, composition_uuid: referralId
            }})
                .catch((e) => {
                    const oldPathways =new Map([...pathways.entries(), [pathwayId, pathway]]);
                    setPathways(oldPathways);
                    updateContent(referralId, {labels: getLabels(referralId, oldPathways)});
                    return Promise.reject(e);
                });
        },
        [referralId, pathways, updateContent],
    );

    return {pathways, allPathways, handleAddPathway, handleRemovePathway};
}

const getPathwayId = ({context, name, type}) => `${context || ''}-${name || ''}-${type || ''}`;

function preparePathways(labels) {
    return new Map(labels
        .filter(label => (label.status || label.state === 'true') && label.type === 'pathway')
        .map((label) => ([
            getPathwayId(label),
            label
        ])));
}

function getLabels(referralId, pathwaysMap) {
    return [...pathwaysMap.entries()].map(([, {
        context,
        type,
        name,
    }]) => ({
        context,
        name,
        type,
        status: 'true',
        referral_uuid: referralId
    }));
}
