import { cloneDeep as _cloneDeep }from 'lodash';

import { appointmentsService } from 'services/appointments.service';
import { convertToOutputTzDate } from 'common/datetime/DateTime';

/**
 * This class provides functionality for querying and updating the transport and interpreter worklist.
 * This feature is currently specific to MyPathway.
 */
class TransportAndInterpreterService {

    /**
     * Get a list of items on the transport and interpreter worklist.
     * The results are provided in the form of appointment compositions.
     * Parameters are passed in via an object with named parameters.
     *
     * @returns {Promise.<TResult>|*|Thenable<U>|Promise<U>} If successful, the promise will resolve with an object containing two properties: count (total number of items on the worklist), and results (an array of appointment compositions on the requested page of the worklist).
     */
    list () {

        const now = convertToOutputTzDate(new Date()).unix();

        const searchCondition = {
            '$and':[
                {
                    'deleted_at': {
                        '$exists': false
                    },
                    'content.status': 'booked',
                    'content.type': {
                        '$ne':'triage'
                    },
                    'content.date':{
                        '$ne': null,
                        '$gt': now
                    },
                    'content.unknown_date': false
                },
                {
                    '$or':[
                        {
                            'content.transport.required': true,
                            'content.transport.arranged': {
                                '$ne': true
                            }
                        },
                        {
                            'content.interpreter.required': true,
                            'content.interpreter.arranged': {
                                '$ne': true
                            }
                        }
                    ]
                }
            ]
        };

        return appointmentsService.search(({
            folderId: 'all',
            search:searchCondition
        }))
            .then(({message}) => {
                const {
                    total,
                    results
                } = message;

                return {
                    count: total,
                    results
                }
            });
    }

    /**
     * Update the transport and interpreter arrangements for an appointment.
     * This will work for any appointment the team can access, not just items
     *  currently on the T&I worklist. This allows the user to "undo" a change
     *  if they made a mistake.
     *
     * @param {string} appointmentUuid The UUID of the appointment to be updated.
     * @param {object} content The appointment's content.
     * @param {bool} transportArranged True to mark the transportation as having been arranged for the appointment, or false to mark it as not arranged.
     * @param {bool} interpreterArranged True to mark the interpreter as having been arranged for the appointment, or false to mark it as not arranged.
     */
    update ({appointmentUuid, content, transportArranged, interpreterArranged, folderId}) {
        if (!appointmentUuid) {
            return Promise.reject('appointmentUuid must be specified.');
        }

        if (transportArranged === undefined && interpreterArranged === undefined) {
            return Promise.reject('transportArranged and/or interpreterArranged must be specified.');
        }

        const newContent = _cloneDeep(content);

        if (transportArranged !== undefined) {
            newContent.transport.arranged = !!transportArranged;
        }

        if (interpreterArranged !== undefined) {
            newContent.interpreter.arranged = !!interpreterArranged;
        }

        return appointmentsService.update({
            folderId,
            uuid: appointmentUuid,
            content: newContent
        }).then(({message}) => {
            const uuid = message.uuid;

            return appointmentsService.getByUuid({
                folderId,
                uuid
            });
        }).then(({message}) => {
            return message;
        });
    }
}

export default new TransportAndInterpreterService();
