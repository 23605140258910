import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';

import UserMessageComponent from 'components/user/message';

/**
 * Extended Page Component for user_portal/documents
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
        
        this.backRoute = `${this.getFolderPath()}/patient/messages`;
                
        this.createMessageRoute = `${this.getFolderPath()}/patient/messages/create`;
    }

    pageTitle () {
        return _`Viewing ${this.$p()} Message | MyPathway Clinical Portal`;
    }

    render () {
        return (<UserMessageComponent createRoute={this.createMessageRoute}
                                      backRoute={this.backRoute}
                                      clinicalView={true}
                                      {...this.props} />);
    }
}
