import { CompositionService } from './composition.service';

class NotificationLogService extends CompositionService {

    get archetypeName(): string {
        return 'notificationLog';
    }
}

export const notificationLogService = new NotificationLogService();
