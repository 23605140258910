import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { store } from 'store';
import { toast } from 'react-toastify';
import DataStore from 'services/data-store';
import { getLatestLoginTime } from 'features/latest-login/useLatestLogin';
import { showLatestLoginToast } from 'features/latest-login/LatestLoginToast';

class AuthCheckMiddleware extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.doLoginCheck();
    }

    componentWillReceiveProps() {
        this.doLoginCheck();
    }

    async showLastLogin() {

        const [, portalType, page] = window.location.pathname.split('/');
        if(page === 'login' || page === 'logout') {
            toast.dismiss();
        }
        const isFrontPage = window.location.pathname.split('/').filter(Boolean).length === 1;
        const isNotSeenLatestLoginMessage = !DataStore.get('isSeenLatestLogin');
        if (isFrontPage && isNotSeenLatestLoginMessage) {
            DataStore.set('isSeenLatestLogin', true);
            try {
                const time = await getLatestLoginTime();
                await this.waitForSelectingTeam();
                showLatestLoginToast(time, `${portalType + (page ? ` ${portalType}-${page}` : '')}`);
            } catch(e) {
                // if wanted here can be implemented something to notify user about the error
            }
        }
    }

    waitForSelectingTeam() {
        return new Promise((resolve) => {
            let unsubscribe2;
            const unsubscribe = DataStore.getListenerDestroy('me.currentRole', (entity) => {
                console.log('role listener', entity);
                if(entity) {
                    unsubscribe();
                    unsubscribe2();
                    resolve();
                }
            });
            unsubscribe2 = DataStore.getListenerDestroy('me.currentOrg', (entity) => {
                console.log('org listener', entity);
                if(entity) {
                    unsubscribe();
                    unsubscribe2();
                    resolve();
                }
            });
        });
    }

    doLoginCheck() {
        if (!this.props.isAuthenticated){
            browserHistory.push(this.props.redirectTo);
        } else {
            this.showLastLogin();
        }
    }

    render() {

        const {
            isAuthenticated,
            children
        } = this.props;

        return isAuthenticated ? children : null;
    }
}

AuthCheckMiddleware.propTypes = {
    redirectTo: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state){
    return {
        isAuthenticated: store.getState().auth.get('isAuthenticated')
    }
}

export default connect(mapStateToProps)(AuthCheckMiddleware);
