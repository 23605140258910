import React from 'react';
import BasePage from 'components/page/base';

import {Row, Column} from 'ui';
import {TabNavigator, NavTab} from 'ui/tab/navigator';
import _ from 'services/i18n';

export default class ReportsPage extends BasePage {
    constructor (props) {
        super(props);

        this.baseRoute = this.props.route.baseRoute || '/clinical_portal/reports';
    }

    render () {
        return (
            <div className="page">
                <Row>
                    <Column sm='12'>
                        <TabNavigator
                            currentRoute={this.props.location.pathname}
                            tabPosition='top'
                            tabStyle='tab'
                            routes={this.props.routes}
                        >
                            <NavTab title={_`Report Index`} route={this.baseRoute} prefixMatch={false} />
                            <NavTab title={_`Patient Sign-up`} route={this.baseRoute + '/patient-signup'} prefixMatch={true} />
                            <NavTab title={_`Patient Invites`} route={this.baseRoute + '/patient-invites'} prefixMatch={true} />
                            <NavTab title={_`Referrals`} route={this.baseRoute + '/referrals'} prefixMatch={true} />
                            <NavTab title={_`Completed EQ-5D™ questionnaires`} route={this.baseRoute + '/completed-eq5ds'} prefixMatch={true} />
                            <NavTab title={_`Overdue EQ-5D™ questionnaires`} route={this.baseRoute + '/overdue-eq5ds'} prefixMatch={true} />
                            <NavTab title={_`Issue to Completion`} route={this.baseRoute + '/issue-completion-eq5ds'} prefixMatch={true} />
                        </TabNavigator>
                    </Column>

                    <Column sm='12'>
                        {this.props.children}
                    </Column>
                </Row>
            </div>
        );
    }
}
