import React from 'react';

// eslint-disable-next-line react/display-name
export default class extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <div className="container-fluid">
                <div className="main-guest-page">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
