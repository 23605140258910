import { SendLetterState } from '../letters.interface';

export function validate(
    state: SendLetterState,
    onErrors: (errors: string[]) => void,
): boolean {
    const normalisedState = normalise(state);

    const errors = [];
    if (normalisedState.htmlContent === '') {
        errors.push('Letter body is required');
    }

    if (!normalisedState.stationery) {
        errors.push('Sending Department field is required');
    }

    onErrors(errors);

    return errors.length == 0;
}

export function normalise(state: SendLetterState): SendLetterState {
    return {
        ...state,
        htmlContent: state.htmlContent.trim(),
    };
}
