import React from 'react';
import {Link} from 'react-router';
import _ from 'services/i18n';

const DropdownList = ({list, onlyList = false}) => {
    const liList = list.map((item, idx) => {
        // if menu type is separator, then show a separator
        if (item.type == 'separator') {
            return (
                <li key={idx} role="separator" className="divider"/>
            );
        }

        // if item type is a callback, then display the callback
        if (item.type == 'callback' || item.callback) {
            return (
                <li key={idx} onClick={item.callback}><span>{item.label}</span></li>
            );
        }

        return (
            <li key={idx}><Link to={item.route}>{item.label}</Link></li>
        );
    });

    if (onlyList) {
        return liList;
    }

    return (
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {liList}
        </ul>
    );
};

const SingleItem = ({item, sizeClass = '', styleClass = 'default'}) => {
    const btnClass = 'btn ' + sizeClass + ' btn-' + styleClass;

    // if item type is a callback, then display the callback
    if (item.type == 'callback' || item.callback) {
        return (
            <button className={btnClass} onClick={item.callback}>{item.label}</button>
        );
    }

    return (
        <Link to={item.route} className={btnClass}>{item.label}</Link>
    );
};

export const ActionMenu = ({label, style = 'default', size, onCheckAllowed = null, links}) => {
    const actionsLabel = label || _`Actions`;
    let btnSizeClass = '';
    const btnStyleClass = style || 'default';
    let list;

    if (!links) {
        return (<noscript />);
    }
    
    if (size) {
        btnSizeClass = `btn-${size}`;
    }

    if (onCheckAllowed) {
        list = links.filter((item, idx) => {
            return onCheckAllowed(item);
        });
    } else {
        list = links;
    }
    
    if (list.length == 0) {
        return <noscript />;
    }
    
    if (list.length == 1) {
        return (
            <div className="space-handler">
                <SingleItem item={list[0]} sizeClass={btnSizeClass} styleClass={btnStyleClass} />
            </div>
        );
    }

    return (
        <div className="space-handler">
            <div className="btn-group">
                <button type="button" className={`btn dropdown-toggle ${btnSizeClass} btn-${btnStyleClass}`}
                    data-toggle="dropdown" id="dropdownMenuButton"
                    aria-haspopup="true" aria-expanded="false">
                    {actionsLabel} <span className="caret"/>
                </button>
                <DropdownList list={list}/>
            </div>
        </div>
    );
};

export {DropdownList};
export default ActionMenu;
