import React, { Component } from 'react';
import lodash from 'lodash';
import View from './view';
import MSKListView from './MSKListView';
import { browserHistory } from 'react-router';
import { convertToDate, standardDateTimeFormats, convertToTimestamp } from 'common/datetime/convertToDate';
import { questionnaireService } from 'services/questionnaire.service';
import { convertToOutputTzDate } from 'common/datetime/DateTime';

export default class QuestionnaireList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                current: 1
            },
            uiList: [],
            list: [],
            perPage: 10,
            count: 0,
            order: {
                by: 'issuedOn',
                dir: 'desc'
            },
            loading: true
        };
    }

    handleApplySort(by) {
        this.setState((state) => {
            const newState = {
                order: {
                    by
                }
            };
            if (state.order.by === by) {
                newState.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
            } else {
                newState.order.dir = 'desc';
            }
            newState.list = this.sortQuestionnaires(state.list, newState.order);
            newState.count = newState.list.length;
            return newState;
        }, () => {
            this.handleChangePage(this.state.pagination.current);
        });
    }


    sortQuestionnaires(list = [], order = this.state.order) {
        if (!order) {
            return list;
        }

        const comparator = this.getQuestinnaireComparator(order);

        if (!comparator) {
            return list;
        }

        return list.sort((a, b) => {
            return (order.dir === 'desc' ? -1 : 1) * comparator(a, b);
        });
    }

    getQuestinnaireComparator(order) {
        switch (order.by) {
        case 'issuedOn':
            return (a, b) => convertToTimestamp(a.questionnaireResponse.document.created_at) - convertToTimestamp(b.questionnaireResponse.document.created_at);
        case 'completedOn':
            return (a, b) => {
                if (a.isCompleted && b.isCompleted) {
                    return convertToTimestamp(a.questionnaireResponse.document.updated_at) - convertToTimestamp(b.questionnaireResponse.document.updated_at);
                }
                if (a.isCompleted && !b.isCompleted) {
                    return 1;
                }
                if (!a.isCompleted && b.isCompleted) {
                    return -1;
                }
                if (!a.isCompleted && !b.isCompleted) {
                    const statusA = a.questionnaireResponse.document.content.status.trim().toLowerCase();
                    const statusB = b.questionnaireResponse.document.content.status.trim().toLowerCase();
                    return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
                }
            };
        case 'score':
            return (a, b) => {
                if (a.isCompleted && !b.isCompleted) {
                    return 1;
                }
                if (!a.isCompleted && b.isCompleted) {
                    return -1;
                }
                if (!a.isCompleted && !b.isCompleted) {
                    return 0;
                }
                const nameA = lodash.upperCase(a.scoreName);
                const nameB = lodash.upperCase(b.scoreName);
                return nameA < nameB ? -1 : nameA > nameB ? 1 : a.scoreValue - b.scoreValue;
            };
        case 'name':
            return (a, b) => {
                const nameA = a.questionnaire.document.name.toLowerCase();
                const nameB = b.questionnaire.document.name.toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            };
        }
    }

    handleCompare(questionnaireResponse) {
        let queryString = [
            `questionnaire_uuid=${questionnaireResponse.document.content.questionnaire_uuid}`
        ];

        if (questionnaireResponse.type === 'coopQuestionnaireResponse') {
            queryString.push('coopQuestionnaire');
        }

        queryString = queryString.join('&');

        browserHistory.push(`/clinical_portal/folder/${this.props.folderId}/patient/questionnaires/compare?${queryString}`);
    }

    handleDeleteQuestionnaire(uuid) {
        const {
            questionnaireResponse: questionnaire
        } = lodash.find(this.state.list, ({ questionnaireResponse, isValid }) => {
            return questionnaireResponse.document.uuid === uuid && isValid;
        });

        if (!questionnaire) {
            console.warn(`Unable to archive non existent questionnaire response ${uuid}`);
            return;
        }

        if (questionnaire.document.content.status !== 'Assigned') {
            console.warn(`Unable to archive a non-assigned questionnaire ${uuid}`);
            return;
        }

        questionnaireService.delete({
            uuid,
            folderId: this.props.folderId,
            teamId: this.props.teamId,
            type: questionnaire.type,
            archetypeName: questionnaire.type,
        }).then(() => {
            this.loadComponent();
        });
    }

    handleChangePage(idx) {
        this.setState({
            pagination: {
                current: idx
            },
            loading: false,
            uiList: this.state.list.slice((idx - 1) * this.state.perPage, idx * this.state.perPage)
        });
    }

    componentDidMount() {
        this.loadComponent();
    }

    loadComponent() {
        this.setState({
            loading: true
        });

        Promise.all([
            this.getQuestionnaires(),
            this.getResponses(),
            this.getScores()
        ])
            .then(([questionnairesMap, questionnaireResponses, questionnaireScoresMap]) => {

                const list = this.createQuestionnairesList(questionnairesMap, questionnaireResponses, questionnaireScoresMap);
                const sortedList = this.sortQuestionnaires(list);

                this.setState({
                    list: sortedList,
                    uiList: sortedList.slice(0, this.state.perPage),
                    count: sortedList.length,
                    loading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    loading: false
                });
            });
    }

    getScores() {
        return questionnaireService.getScores({
            folderId: this.props.folderId,
            teamId: this.props.teamId,
            limit: Infinity,
        })
            .then((questionnaireScores) => {
                const scoresMap = new Map();

                questionnaireScores.forEach((score) => {
                    scoresMap.set(score.content.questionnaire_response_uuid, score);
                });

                return scoresMap;
            });
    }

    getResponses() {
        return questionnaireService.getResponses({
            folderId: this.props.folderId,
            teamId: this.props.teamId,
            limit: Infinity,
        }).then((results) => {
            let documents = results.map((questionnaireResponse) => {
                const newItem = Object.assign({}, questionnaireResponse);

                return {
                    type: questionnaireResponse.document_type,
                    document: newItem
                };
            });

            documents = documents.sort((prev, next) => {
                const prevMoment = convertToDate(prev.document.created_at);
                const nextMoment = convertToDate(next.document.created_at);
                const prevTimeStamp = prevMoment && prevMoment.isValid() ? prevMoment.unix() : 0;
                const nextTimeStamp = nextMoment && nextMoment.isValid() ? nextMoment.unix() : 0;
                return prevTimeStamp - nextTimeStamp;
            });

            return {
                documents,
                count: documents.length
            };
        });
    }

    getQuestionnaires() {
        return questionnaireService.getQuestionnaires({
            folderId: this.props.folderId,
            teamId: this.props.teamId,
            limit: Infinity
        }).then((results) => {
                return results.reduce((acc, questionnaire) => {
                    const data = {
                        type: questionnaire.document_type,
                        document: {
                            version: questionnaire.content.version,
                            name: questionnaire.content.name
                        }
                    };
                    acc.set(questionnaire.uuid, data);
                    //fallback for legacy questionnaires (uuid should be used for grouping)
                    acc.set(questionnaire.content.questionnaire_uuid, Object.assign({}, data, { isLegacy: true }));
                    return acc
                }, new Map());
            });
    }

    createQuestionnairesList(questionnairesMap, questionnaireResponses, questionnaireScoresMap) {

        const completedQuestionnairesMap = questionnaireResponses.documents.reduce((acc, questionnaireResponse) => {
            const {
                document
            } = questionnaireResponse;

            if (questionnaireResponse.type === 'questionnaireResponse') {
                if (document.content.status !== 'scored') {
                    return acc;
                }
            } else {
                if (document.content.status !== 'complete') {
                    return acc;
                }
            }

            const uuid = document.content.questionnaire_uuid;

            acc[uuid] = typeof acc[uuid] !== 'undefined' ? acc[uuid] + 1 : 1;
            return acc;
        }, {});

        return questionnaireResponses.documents.reduce((list, questionnaireResponse) => {

            const {
                document
            } = questionnaireResponse;

            const questionnaire = questionnairesMap.get(document.content.questionnaire_uuid);

            if (!questionnaire) {
                return list;
            }

            const status = questionnaireResponse.document.content.status.trim().toLowerCase();
            const isCompleted = ['complete', 'scored'].indexOf(status) !== -1;

            let score;
            let couldBeCompared;
            if (questionnaireResponse.type === 'questionnaireResponse') {
                score = lodash.get(questionnaireScoresMap.get(document.uuid), 'content.scores[0]');
                couldBeCompared = status === 'scored' && completedQuestionnairesMap[document.content.questionnaire_uuid] > 1;
            } else {
                /*
                    Dirty hack for old archetype - coopQuestionnaire (EQ5D)
                    https://adi-health.atlassian.net/browse/MPA-2063
                    Extract value not from scoring but from composition response composition itself
                    EQ5D has 6 answers
                */
                const answers = lodash.get(document, 'content.details.answers', []);
                if (isCompleted && answers.length === 6) {
                    score = {
                        score_value_int: answers.find(({ question_id }) => {
                            return question_id === '6';
                        }).value,
                        score_name: 'VAS'
                    };
                }
                couldBeCompared = status === 'complete' && completedQuestionnairesMap[document.content.questionnaire_uuid] > 1;
            }


            list.push({
                isValid: true,
                questionnaireResponse,
                questionnaire,
                isLegacy: questionnaire.isLegacy,
                isConfidential: (this.props.role !== 'admin') && (document.content.hide_from_clinician === true),
                scoreValue: score && extractScoreValue(score),
                scoreName: score && score.score_name,
                isCompleted,
                couldBeCompared
            });

            return list;
        }, []);
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            pagination: {
                current: 1
            },
            loading: true
        }, this.loadComponent);
    }

    render() {

        const CurrentView = this.props.service === 'MSK' ? MSKListView : View;

        return (
            <CurrentView
                onSubmit={(e) => this.handleSubmit(e)}
                onChangePage={(idx) => this.handleChangePage(idx)}
                onArchiveQuestionnaire={(uuid) => this.handleDeleteQuestionnaire(uuid)}
                onApplySort={(by) => this.handleApplySort(by)}
                onCompare={(questionnaireResponse) => this.handleCompare(questionnaireResponse)}
                pagination={this.state.pagination}
                order={this.state.order}
                list={this.state.list}
                uiList={this.state.uiList}
                loading={this.state.loading}
                count={this.state.count}
                perPage={this.state.perPage}
                folderId={this.props.folderId}
            />
        );

    }
}

function extractScoreValue(score) {
    if (score.score_value_int != null) {
        return score.score_value_int;
    }
    if (score.score_value_float != null) {
        return score.score_value_float;
    }
    if (score.score_value_string != null) {
        return score.score_value_string;
    }
    if (score.score_value_boolean != null) {
        return score.score_value_boolean;
    }
    return undefined;
}
