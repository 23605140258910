import React from 'react';

import BasePage from 'components/page/base';
import _ from 'services/i18n';
import DataStore from 'services/data-store';

import QuestionnaireContainerComponent from 'features/patient/questionnaire/QuestionnaireContainerComponent';

/**
 * Extended Page Component for
 **/
export default class ViewQuestionnairePage extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Viewing ${this.$p()} Questionnaire | MyPathway Clinical Portal`;
    }

    render () {
        const allocateQuestionnaireLink = `/clinical_portal/folder/${this.props.params.folderId}/patient/questionnaires/create`;
        const allocateSimpleQuestionnaireLink=`/clinical_portal/folder/${this.props.params.folderId}/patient/questionnaires/create-simple`;
        const backLink = `/clinical_portal/folder/${this.props.params.folderId}/patient/questionnaires`;

        return (
            <QuestionnaireContainerComponent
                location={this.props.location}
                questionnaireId={this.props.params.id || 'create'}
                backLink={backLink}
                allocateQuestionnaireLink={allocateQuestionnaireLink}
                allocateSimpleQuestionnaireLink={allocateSimpleQuestionnaireLink}
            />
        );
    }
}
