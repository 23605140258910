import React, { useState, useEffect, Fragment } from 'react';
import PageTitle from 'page-title';
import { browserHistory, Link } from 'react-router';
import lodash from 'lodash';
import _ from 'services/i18n';
import { goalsService } from 'services/goals.service';
import { PatientGoalView } from './PatientGoalView';
import { ModalDialog } from 'common/modalDialog';
import { useTeamPreferences } from 'common/useTeamPreferences';
// eslint-disable-next-line @typescript-eslint/camelcase
import Not_Found from 'pages/clinical_portal/not_found';

export function PatientGoalsShowTab({ params }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [goal, setGoal] = useState(null);
    const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false);
    const teamPreferences = useTeamPreferences();

    const uuid = params.goalUuid;

    useEffect(() => {
        PageTitle.setTitle(
            'Patient Goal | PHR Clinical Portal'
        );
    }, []);

    useEffect(() => {
        if (uuid && teamPreferences.show_goals) {
            setIsLoading(true);
            loadGoal(uuid);
        }
    }, [teamPreferences.show_goals, uuid]);

    if (!teamPreferences.show_goals) {
        return <Not_Found />;
    }

    async function loadGoal(uuid) {
        const { status, message: goal } = await goalsService.getByUuid({ uuid });

        if (status >= 300) {
            return setIsError(true);
        }

        if (goal.deleted_at != null && goal.deleted_at !== '') {
            console.warn('Trying to show an archived composition.');
            return setIsError(true);
        }

        setIsError(false);
        setIsLoading(false);
        setGoal(goal);
    }

    function handleShowList() {
        browserHistory.push(`/clinical_portal/folder/${goal.folder_id}/patient/goals`);
    }

    if (isError) {
        return <div>{_`Sorry, the server reported an error.`}</div>;
    }

    if (isLoading) {
        return <div>{_`Loading. Please wait...`}</div>;
    }

    const itemName = lodash.get(goal, 'content.description', '');
    let msg = _`Are you sure you want to archive this?`;
    if (itemName !== '') {
        msg = _`Are you sure you want to archive '${itemName}'?`;
    }

    function handleDeleteGoal() {
        setIsShowDeleteConfirm(true);
    }

    function handleCancelDelete() {
        setIsShowDeleteConfirm(false);
    }

    async function handleConfirmDelete() {
        setIsShowDeleteConfirm(false);
        setIsLoading(true);
        try {
            await goalsService.delete({ uuid: goal.uuid });
            handleShowList();
        } catch {
            setIsLoading(false);
        }
    }

    const buttons = (
        <Fragment>
            <button
                className="btn btn-default btn-danger"
                onClick={handleConfirmDelete}
            >{_`Yes`}</button>
            <button
                className="btn btn-default"
                onClick={handleCancelDelete}
            >{_`No`}</button>
        </Fragment>
    );

    return (
        <div>
            <div className="top-bar">
                <a onClick={handleShowList}>{_`Back to Goals List`}</a>
            </div>
            <h3>{_`Patient Goal`}</h3>
            <div>
                {goal && <Link className="btn btn-primary" to={`/clinical_portal/folder/${goal.folder_id}/patient/goals/edit/${goal.uuid}`}>{_`Edit`}</Link>}
                &nbsp;&nbsp;
                <button className="btn btn-danger" onClick={handleDeleteGoal}>{_`Delete`}</button>
            </div>
            <PatientGoalView
                goal={goal}
                showScores={teamPreferences.lifevit_integration}
            />
            {isShowDeleteConfirm && (
                <ModalDialog
                    size='small'
                    title={_`Confirm archival`}
                    onClose={handleCancelDelete}
                    busy={isLoading}
                    buttons={buttons}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <strong>{msg}</strong>
                        </div>
                    </div>
                </ModalDialog>
            )}
        </div>
    );
}
