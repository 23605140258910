import React, { useContext } from 'react';
import { ReferralsContext } from 'features/ReferralsProvider';

type returnContentAndHandlers = [
    Map<string, any>,
    {
        invalidateItem: (uuid: string) => void;
        updateContent: (uuid: string, content: object) => void
    }];
export function useReferralsByUuid(): returnContentAndHandlers {
    const {
        itemMap,
        updateContent,
        invalidateItem,
    } = useContext(ReferralsContext);

    return [itemMap, {
        invalidateItem,
        updateContent
    }];
}
