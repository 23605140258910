import { Route } from 'react-router';
import React from 'react';
import { MSKRouteComponent } from 'common/routes/MSKRouteComponent';


// https://marmelab.com/blog/2016/09/20/custom-react-router-component.html
// Override to access private function
// Will be not needed when react router will be upgraded
// todo [denis] pay attention to it when upgrading router - https://adi-health.atlassian.net/browse/MPA-8417
// eslint-disable-next-line @typescript-eslint/no-var-requires
const RouteUtils = require('react-router/lib//RouteUtils');
const createRoutesFromReactChildren = RouteUtils['createRoutesFromReactChildren'];

export const MSKRoute = () => (
    <div>
      &lt;MSKRoute&gt; elements are for configuration only and should not be
      rendered
    </div>
);

MSKRoute.createRouteFromReactElement = (element, parentRoute) => {
    const { children, component, ...props } = element.props;

    const routeProps = { ...props, component: MSKRouteComponent, originalComponent: component };

    let route;
    if (children) {
        route = createRoutesFromReactChildren(
            <Route {...routeProps}>{children}</Route>,
            parentRoute
        )[0];
    } else {
        route = createRoutesFromReactChildren(
            <Route {...routeProps} />,
            parentRoute
        )[0];
    }
    return route;
};
