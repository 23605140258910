import React, { useEffect, useState } from 'react';
// components
import * as AlertBoxes from 'common/ui/alert-boxes';
import AppointmentComponent from 'components/user/appointments';
import ApprovePIFUAppointmentForm from 'features/patient/appointments/ApprovePIFUAppointmentForm/ApprovePIFUAppointmentForm';
// services
import { appointmentsService } from 'services/appointments.service';
import { portalToast } from 'ui/toast/Toast';
// interfaces
import { Composition } from 'models/Composition';
import { Appointment } from 'models/compositions/Appointment';
import {
    isCompletedProposedParticipantStatus
} from 'features/patient/appointments/ApprovePIFUAppointmentForm/ApprovePIFUAppointmentForm.hooks';
import { UserNoteEntryView } from 'pages/clinical_portal/folder/_folderId/patient/user-notes/UserNoteEntryView';

type AppointmentContainerProps = {
    uuid: string;
    folderId: number;
    onSubmit: () => void;
};

function isPIFURequested(appointment: Composition<Appointment>): boolean {
    const proposedParticipant = appointment.content.proposed_participant;
    if (!proposedParticipant) {
        return false;
    }
    return !isCompletedProposedParticipantStatus(proposedParticipant.status);
}

function AppointmentContainer(props: AppointmentContainerProps) {
    const { uuid, folderId, onSubmit } = props;

    const [isLoading, setIsLoading] = useState(true);
    // workaround before we refactor create and edit appointment form component
    const [isCreateType, setIsCreateType] = useState(false);
    const [appointment, setAppointment] =
        useState<Composition<Appointment> | null>(null);

    useEffect(() => {
        async function loadAppointment() {
            if (uuid === 'create') {
                setIsCreateType(true);
                setIsLoading(false);
                return;
            }

            try {
                const appointment = await appointmentsService.getByUuid({
                    uuid,
                    folderId,
                });

                setAppointment(appointment.message);
                setIsLoading(false);
            } catch (err) {
                portalToast.error({
                    title: 'Error',
                    content:
                        'Something went wrong while loading an appointment.',
                });
                setIsLoading(false);
            }
        }

        loadAppointment();
    }, [uuid, folderId]);

    const renderAppointmentForm = () => {
        if (!appointment && isCreateType) {
            return (
                <AppointmentComponent
                    uuid={uuid}
                    folderId={folderId}
                    onSuccess={onSubmit}
                />
            );
        }

        if (isPIFURequested(appointment)) {
            return (
                <ApprovePIFUAppointmentForm
                    appointment={appointment}
                    onSubmit={onSubmit}
                />
            );
        }
        return (
            <>
                <AppointmentComponent
                    uuid={uuid}
                    folderId={folderId}
                    onSuccess={onSubmit}
                />
                {!appointment.content.proposed_participant &&
                    <UserNoteEntryView
                        folderId={folderId}
                        parentComposition={appointment}
                        defaultTitle={'Appointment event'}
                        defaultStatus={'reviewed'}
                    />
                }
            </>
        );
    };

    return (
        <div>
            <AlertBoxes.Loading show={isLoading} />
            {!isLoading && renderAppointmentForm()}
        </div>
    );
}

export default AppointmentContainer;
