import React, { useEffect, useState } from 'react';
import { teamPreferencesService } from 'services/team-preferences.service';
import { TeamPreferences } from 'models/TeamPreferences';

export function useTeamPreferences(): TeamPreferences {
    const [teamPreferences, setTeamPreferences] = useState(teamPreferencesService.defaultProperties);

    useEffect(() => {
        teamPreferencesService.getFirst({}).then((teamPreferences) => {
            setTeamPreferences(teamPreferences);
        });
    }, []);

    return teamPreferences;
}
