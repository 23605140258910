import { useEffect, useState } from 'react';
import { makeGetRequest } from '../../services/api';

export const getLatestLoginTime = async () => {
    try {
        const response = await makeGetRequest('/latest-logins');
        const { results } = response;
        if (!results) {
            return;
        }
        const notCurrent = results.filter(s => s.current !== true);
        if (notCurrent.length === 0) {
            return;
        }
        return notCurrent[0].session_start;
    } catch(e) {
        console.error('[getLatestLoginTime] Error', e);
        throw new Error(e);
    }
};

export const useLatestLogin = () => {
    const [latestLogin, setLatestLogin] = useState(undefined);
    useEffect(() => {
        getLatestLoginTime()
            .then((sessionStartTime) => {
                setLatestLogin(sessionStartTime);
            });
    }, []);
    return latestLogin;
};
