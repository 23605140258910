import React, { Fragment, Component } from 'react';
import _ from 'services/i18n';
import DataStore from 'services/data-store';
import { QuestionnaireComponent } from 'features/patient/questionnaire/index';
import Modal, { ModalButton } from 'common/ui/modal/Modal';
import { questionnaireService } from 'services/questionnaire.service';
import lodash from 'lodash';
import PageTitle from 'page-title';
import moment from 'moment';
import { getInputByType } from 'features/patient/questionnaire/questionnaire-view/EditableQuestionnaire';
import { profileService } from 'services/profile.service';
import { QuestionnaireArchetype } from 'models/QuestionnaireData';
import { TopBar } from 'features/patients/questionnaire/questionnaire-view/TopBar';
import { Location } from 'history';

const TIMEOUT_AFTER_COMPLETE = 10000;
const tag = '[QuestionnaireContainer]';

export interface ContainerProps {
    location: Location;
    questionnaireId: string;
    teamId: number;
    folderId: number;
    role?: any;
    backLink: string;
    allocateQuestionnaireLink: string;
    allocateSimpleQuestionnaireLink: string; 
    inModal: boolean;
}
interface ContainerState {
    inPathway: string | undefined;
    loading: boolean;
    isUpdating: boolean;
    isEditMode: boolean;
    givenAnswers: any[];
    showUpdateStatusModal: boolean;
    showWarningModal: boolean;
    questionnaireData: any;
    unsupportedTypeModal?: boolean;
    showReport: boolean;
    inModal: boolean;
}

export default class QuestionnaireContainerComponent extends Component<ContainerProps, ContainerState> {

    archetype: QuestionnaireArchetype = Object.prototype.hasOwnProperty.call(this.props.location.query, 'questionnaire') ?
        QuestionnaireArchetype.questionnaire : QuestionnaireArchetype.coopQuestionnaire;
    role: any = DataStore.get('role');
    isLegacy: boolean = Object.prototype.hasOwnProperty.call(this.props.location.query, 'legacy');
    state: ContainerState;
    warningModalButtons: ModalButton[];
    
    constructor(props) {
        super(props);

        this.state = {
            inPathway: this.props.location.query?.pathway as string,
            loading: true,
            isUpdating: false,
            isEditMode: false,
            givenAnswers: [],
            showUpdateStatusModal: false,
            showWarningModal: false,
            questionnaireData: {},
            showReport: false,
            unsupportedTypeModal: false,
            inModal: this.props.inModal,
        };

        this.warningModalButtons = [
            {
                type: 'callback',
                callback: this.onWarningModalConfirm,
                label: 'Yes'
            },
            {
                type: 'callback',
                callback: this.onCloseWarningModal,
                label: 'No'
            }
        ];

        PageTitle.setTitle(_`Viewing Patient Questionnaire | MyPathway Clinical Portal`);
    }

    onCloseModal = (e) => {
        this.setState({
            showReport: false
        });
    };

    onCloseUnsupportedTypeModal = () => {
        this.setState({
            unsupportedTypeModal: false
        });
    }

    onShowReport = (e) => {
        this.setState({
            showReport: true
        });
    };

    onCancel = () => {
        this.setState({
            isEditMode: false
        });
    };

    onCompleteQuestionnaire = () => {
        if (!this.state.questionnaireData.canBeCompletedByClinician) {
            return;
        }
        if (this.hasUnsupportedInputTypes()) {
            this.setState({ unsupportedTypeModal: true });
            return;
        }
        this.setState((state) => {
            return {
                isEditMode: !state.isEditMode
            };
        });
    };

    hasUnsupportedInputTypes = () => {
        let result = false;
        const { questionnaireData } = this.state;
        const sections = (questionnaireData && questionnaireData.sections) || [];
        sections.forEach((section) => {
            const { questions = [] } = section;
            questions.forEach((question) => {
                const { inputs } = question || [];
                inputs.forEach((input) => {
                    const type = getInputByType(input.type);
                    result = result === true || type === null;
                });
            });
        });
        return result;
    }

    onCloseUpdateErrorModal = () => {
        this.setState({
            showUpdateStatusModal: false
        });
    };

    onCloseWarningModal = () => {
        this.setState({
            showWarningModal: false,
            givenAnswers: []
        });
    };

    onWarningModalConfirm = () => {
        const answers = this.state.givenAnswers;
        this.onCloseWarningModal();
        this.doSubmit({
            answers
        });
    };

    onSubmit = ({ answers }) => {
        this.setState({
            showWarningModal: true,
            givenAnswers: answers
        });
    };

    getCurrentUserName() {
        const currentUserInfo = DataStore.get('currentUser');
        const currentUserName = currentUserInfo.preferred_name ? currentUserInfo.preferred_name : currentUserInfo.name;
        if (!currentUserName || currentUserName.trim().length === 0) {
            return currentUserInfo.email;
        }
        return currentUserName;
    }

    getCurrentUserId = async () => {
        const response = await profileService.getCurrentUserProfile();
        return response.message.id;
    };

    doSubmit = async ({ answers }) => {
        const responseToUpdate = lodash.cloneDeep(this.state.questionnaireData.questionnaireResponse.content);
        responseToUpdate.details.answers = answers;
        responseToUpdate.details.lastUpdated = moment().unix();
        responseToUpdate.status = 'complete';
        // eslint-disable-next-line @typescript-eslint/camelcase
        responseToUpdate.respondent_name = this.getCurrentUserName();
        // eslint-disable-next-line @typescript-eslint/camelcase
        responseToUpdate.respondent_id = await this.getCurrentUserId();
        const responseUuid = this.state.questionnaireData.questionnaireResponse.uuid;

        this.setState({
            isUpdating: true
        });

        try {
            const couldBeUpdated = await questionnaireService.questionnaireCouldBeUpdated({
                uuid: this.props.questionnaireId,
                archetype: this.archetype,
                folderId: this.props.folderId,
                teamId: this.props.teamId,
            });

            if (couldBeUpdated) {
                await questionnaireService.updateQuestionnaireResponse({
                    archetype: this.archetype,
                    folderId: this.props.folderId,
                    teamId: this.props.teamId,
                    response: responseToUpdate,
                    uuid: responseUuid,
                });
            } else {
                console.warn('Questionnaire can\'t be updated: status doesn\'t allow it');
                this.setState({
                    showUpdateStatusModal: true
                });
            }
        } catch(e) {
            this.setState({
                isEditMode: false,
                isUpdating: false
            });
            console.error(tag, e);
        }
        this.loadDataAfterTimeout();
    };

    loadDataAfterTimeout = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                isEditMode: false,
                isUpdating: true,
            }, () => {
                setTimeout(() => {
                    this.loadData().then(() => {
                        this.setState({
                            isEditMode: false,
                            isUpdating: false
                        }, () => {
                            resolve(true);
                        });
                    }).catch(reject);
                }, TIMEOUT_AFTER_COMPLETE);
            });
        });
    };

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps = (newProps) => {
        const changed = newProps.questionnaireId !== this.props.questionnaireId;
        if (!changed) {
            return false;
        }
        this.loadData();
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const params = {
            role: this.role,
            archetype: this.archetype,
            id: this.props.questionnaireId,
            folderId: this.props.folderId,
            legacy: this.isLegacy,
            teamId: this.props.teamId
        };
        return questionnaireService.loadQuestionnaireData(params)
            .then((questionnaireData) => {
                this.setState({ questionnaireData, loading: false });
            })
            .catch((e) => {
                console.error(tag, e);
            });
    };


    render () {

        return (
            <Fragment>
                {!this.props.inModal && (
                <TopBar
                    inPathway={this.state.inPathway}
                    backToPathwayLink={this.props.backLink}
                    backToQuestionnairesListLink={this.props.backLink}
                    allocateQuestionnaireLink={this.props.allocateQuestionnaireLink}
                    allocateSimpleQuestionnaireLink={this.props.allocateSimpleQuestionnaireLink}
                    onCompleteQuestionnaire={this.onCompleteQuestionnaire}
                    canBeCompletedByClinician={this.state.questionnaireData.canBeCompletedByClinician}
                    isEditMode={this.state.isEditMode}
                />
                )}
                <QuestionnaireComponent
                    loading={this.state.loading}
                    isUpdating={this.state.isUpdating}
                    folderId={this.props.folderId}
                    isEditMode={this.state.isEditMode}
                    archetype={this.archetype}
                    questionnaireData={this.state.questionnaireData}
                    showReport={this.state.showReport}
                    onCancel={this.onCancel}
                    onSubmit={this.onSubmit}
                    onCloseModal={this.onCloseModal}
                    onShowReport={this.onShowReport}
                />
                {
                    this.state.showUpdateStatusModal && (
                        <Modal title={_`Update can't be done`} size="sm" onClose={this.onCloseUpdateErrorModal}>
                            <p>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                Failed to update questionnaire because it's status is not - assigned.
                            </p>
                        </Modal>
                    )
                }

                {
                    this.state.unsupportedTypeModal && (
                        <Modal title={_`Questionnaire can't be displayed`} size="sm" onClose={this.onCloseUnsupportedTypeModal}>
                            <p>
                                Failed to display questionnaire because it has unsupported elements.
                            </p>
                        </Modal>
                    )
                }

                {
                    this.state.showWarningModal && (
                        <Modal title={_`Confirmation`} size="sm" onClose={this.onCloseWarningModal} buttons={this.warningModalButtons}>
                            <p>
                                Do you want to submit your answers?
                            </p>
                        </Modal>
                    )
                }
            </Fragment>
        );
    }
}
