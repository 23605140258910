import { Composition } from 'models/Composition';
import { ResourceCategory } from 'models/ResourceCategory';
import { useCallback, useState } from 'react';
import { resourceCategoryService } from 'services/resourceCategory.service';
import { useSafeAsyncUpdate } from '../../../../../shared/common/useSafeAsyncUpdate';

const useFilteredCollections = (departmentUUID: string) => {
    const [collections, setCollections] = useState<Composition<ResourceCategory>[]>([]);

    const fetchCollectionList = useCallback(async () => {
        try {
            if(departmentUUID) {
                const searchCondition = {
                    'content.labels.context': departmentUUID,
                };
                const collectionList = await resourceCategoryService.search({ folderId: 'team', search:  searchCondition });
                return collectionList.message.results;
            } else {
                const collectionList = await resourceCategoryService.list({ folderId: 'team' });
                return collectionList.message.results;
            }
        } catch(e) {
            return [];
        }
    }, [departmentUUID]);

    useSafeAsyncUpdate(fetchCollectionList, setCollections);

    return collections;
};

export default useFilteredCollections;
