import React, { useEffect, useState, useCallback, useRef } from 'react';
import _ from 'services/i18n';
import { FormGroup } from 'ui';
import { mfaAuthenticationService } from 'services/mfa-authentication.service';
import { OTPEntry } from 'components/user/profile/mfa-setup/OTPEntry';
import { browserHistory } from 'react-router';

const View = ({
    portal
}) => {
    const [ mfaOptions, setMFAOptions ] = useState([]);
    const [ mfaOption, setMFAOption ] = useState();
    const [codeRequestingState, setCodeRequestingState] = useState('pending');
    const [otpCode, setOTPCode] = useState('');
    const [otpError, setOTPError] = useState('');
    const clearOTPRef = useRef(() => unknown);
    const clearOTP = () => {
        setOTPError('');
        const clearFn = clearOTPRef.current;
        if (clearFn) {
            clearFn();
        }
    }

    const selectMFAOption = (option) => {
        setCodeRequestingState('pending');
        clearOTP();
        setMFAOption(option);
    }

    const onOTPEntryUpdate = (otpCode, complete) => {
        setOTPCode(complete ? otpCode : '');
    }

    const requestCode = useCallback((mfaOption) => {
        if (mfaOption.mfa_method === 'authenticator') {
            setCodeRequestingState('sent');
        } else {
            setCodeRequestingState('pending');

            mfaAuthenticationService.requestCode(mfaOption)
                .then(success => {
                    if (success) {
                        setTimeout(() => setCodeRequestingState('sent'), 1000);
                    } else {
                        setCodeRequestingState('failed');
                    }
                })
                .catch(() => setCodeRequestingState('failed'));
        }
        clearOTP();
    }, []);

    useEffect(() => {
        mfaAuthenticationService.listUserMFAOptions()
            .then(options => {
                const filteredOptions = options.filter(option => option.status === 'confirmed')
                setMFAOptions(filteredOptions);
                setMFAOption(filteredOptions.find(option => option.preferred));
            });
    }, []);

    useEffect(() => {
        if (mfaOption) {
            requestCode(mfaOption);
        }
    }, [mfaOption]);

    const onSubmit = (evt) => {
        evt.preventDefault();
        mfaAuthenticationService.submitCode(mfaOption, otpCode)
            .then(success => {
                if (success) {
                    browserHistory.push(`/${portal}`)
                } else {
                    clearOTP();
                    setOTPError('The code you entered was not accepted. Please try again or request a new code.')
                }
            });
    }

    const getMobileNumberSuffix = (mobileNumber) => mobileNumber.substring(mobileNumber.length-3)

    return (
        <div className=''>
            <div className='modal show' tabIndex={-1} role='dialog'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h4 className='modal-title'>{_`Multi Factor Authentication`}</h4>
                        </div>
                        <div className='modal-body'>
                            {mfaOption &&
                                <form onSubmit={onSubmit} autoComplete="off">
                                    <FormGroup>
                                        <div className='hflow-vcenter ant-row-center'>
                                            {mfaOption.mfa_method === 'authenticator' && <label className='text-center'>
                                                {_`Please enter the code from your authenticator app`}
                                            </label>}
                                            {mfaOption.mfa_method !== 'authenticator' && <>
                                                {(codeRequestingState === 'pending')
                                                    && <label className='text-center'>{_`Requesting a code...`}</label>}
                                                {codeRequestingState === 'failed' &&
                                                    <label className='text-center'>{_`Failed to get code!`}</label>}
                                                {codeRequestingState === 'sent' && <>
                                                    {mfaOption.mfa_method === 'email' && <label className='text-center'>
                                                        Please enter the code we have sent to your registered email address
                                                    </label>}
                                                    {mfaOption.mfa_method === 'sms' && <label className='text-center'>
                                                        Please enter the code we have sent to your mobile phone
                                                        ending <strong>
                                                            {getMobileNumberSuffix(mfaOption.method_details)}
                                                        </strong>
                                                    </label>}
                                                </>}
                                            </>}
                                        </div>
                                        <OTPEntry onOTPEntryUpdate={onOTPEntryUpdate} clearEntry={clearOTPRef}/>
                                        {otpError && <>
                                            <hr/>
                                            <div className="hflow-vcenter ant-row-center alert alert-warning">
                                                <span>{otpError}</span>
                                            </div>
                                        </>}
                                    </FormGroup>
                                    <FormGroup>
                                        <div className='hflow-vcenter ant-row-center'>
                                            <button type="submit" className="btn btn-primary" disabled={!otpCode}>
                                                {_`Submit`}
                                            </button>
                                            {mfaOption.mfa_method !== 'authenticator' && (<button type="button"
                                                    className="btn btn-secondary"
                                                    disabled={codeRequestingState !== 'sent'}
                                                    onClick={() => requestCode(mfaOption)}>
                                                {_`Send another code`}
                                            </button>)}
                                        </div>
                                    </FormGroup>
                                </form>
                            }
                        </div>
                        <div className='modal-footer'>
                            {mfaOptions.filter(option => option !== mfaOption).map(option => (
                                <button className='btn btn-link'
                                        disabled={codeRequestingState !== 'sent'}
                                        key={option.id}
                                        onClick={() => selectMFAOption(option)}>Use {option.mfa_method} instead
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default View;

