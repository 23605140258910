import React, {useEffect, useState, useCallback} from 'react';
import { stepsService } from 'services/steps.service';
import { convertToDate } from 'common/datetime/convertToDate';

export function useStepsChart({
    goal
}) {
    const [isChecked, setIsChecked] = useState(false); //hasInvalidScoresFormat
    const [isError, setIsError] = useState(false);
    const [state, setState] = useState({
        stepsData: [],
        currentWeekIndex: undefined,
        currentDay: undefined,
    });

    useEffect(() => {
        if (!checkStepsFormat(goal.content.scores)) {
            setIsError(true);
        }
        setIsChecked(true);
    }, [goal]);

    useEffect(() => {
        if (isChecked && !isError) {
            const threshold = (goal.content.type === 'range') ? 10
                : (goal.content.type === 'boolean') ? 1
                : (goal.content.type === 'numerical' || goal.content.type === 'steps-data') ? parseInt(goal.content.given_value, 10) : 0;

            const createdAt = convertToDate(goal.created_at).unix();
            const stepsData = stepsService.generateGraphData(createdAt, goal.content.scores, threshold);

            console.log(stepsData)
            setState({
                threshold,
                stepsData,
                currentDay: stepsService.whatDayFrom(createdAt),
                currentWeekIndex: stepsData.length - 1
            });
        }
    }, [isChecked, isError]);

    const handleOnSelectNextPage = useCallback(
        () => {
            if (state.stepsData[state.currentWeekIndex + 1]) {
                setState((prevState) => {
                    return Object.assign({}, prevState, {
                        currentWeekIndex: state.currentWeekIndex + 1
                    });
                });
            }
        },
        [state],
    );

    const handleOnSelectPrevPage = useCallback(
        () => {
            if (state.stepsData[state.currentWeekIndex - 1]) {

                setState((prevState) => {
                    return Object.assign({}, prevState, {
                        currentWeekIndex: state.currentWeekIndex - 1
                    });
                });
            }
        },
        [state],
    );

    return [ state, isError, handleOnSelectNextPage, handleOnSelectPrevPage ];
}

function checkStepsFormat(steps = []) {
    return steps.every(({date}) => {
        return convertToDate(date).isValid();
    });
}
