import { PATIENT } from 'actions/types';
import { userPreferencesService } from 'services/user-preferences.service';

export const clearPatientData = ({fields=[]} : {fields:Array<string>} = {fields:[]}) => {
    return (dispatch:any) => {
        dispatch({type: PATIENT.CLEAR_DATA, fields});
    };
};

export const updateUserPrefences = ({content}: {content:any}) => {
    return () => {
        return userPreferencesService.update({content});
    };
};

export const loadUserPreferences = ({} = {}) => {
    return (dispatch:any) => {

        dispatch({
            type: PATIENT.LOAD_PREFERENCES_START,
        });

        return userPreferencesService.getFirst({})
            .then((composition:any) => {
                dispatch({
                    type: PATIENT.LOAD_PREFERENCES_SUCCESS,
                    composition
                });
            })
            .catch((exception:any) => {
                dispatch({type: PATIENT.LOAD_PREFERENCES_FAIL});
                throw exception;
            });
    };
};
