import React from 'react';
import BasePage from 'components/page/base';
import { deviceService } from './services/devices.service';
import { devicesServiceHelper } from './services/DevicesServiceHelper';
import { DevicesIndex } from 'features/patient/devices/DevicesIndex';
import { deviceDispenseService } from 'features/patient/devices/services/device-dispense.service';
import DataStore from 'services/data-store';

export default class DevicesPage extends BasePage {

    constructor(props) {
        super(props);

        this.state = {
            results: [],
            loading: true,
            isEditDeviceMode: false,
            isReturnDeviceMode: false,
            deviceItemToEdit: {},
            deviceItemToReturn: {}
        };
        this.baseRoute = `${this.getFolderPath()}/patient/devices`;

        this.handleShowEditForm = this.handleShowEditForm.bind(this);
        this.handleShowReturnForm = this.handleShowReturnForm.bind(this);
        this.handleAddDevice = this.handleAddDevice.bind(this);
        this.handleUpdateDevice = this.handleUpdateDevice.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleCloseReturnModal = this.handleCloseReturnModal.bind(this);
    }

    componentDidMount () {
        this.loadComponent();
    }

    /**
     * Fetches device and device-dispense compositions for current patient. Compositions are converted to DeviceGridItem type for displaying devices list in Grid table.
     */
    loadComponent() {
        let isMounted = true;
        const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});

        Promise.all([
            deviceService.list({ archetypeName: 'device' }),
            deviceService.list({ archetypeName: 'device-dispense' }),
            devicesServiceHelper.getDeviceTypesFromContentServer(this.props.teamId, roleUuid)
        ])
            .then(([devices, deviceDispenses, deviceTypes]) => {
                if (isMounted) {
                    this.setState({
                        results: devicesServiceHelper.mapDeviceCompositionsToFormItem(devices.message.results,
                            deviceDispenses.message.results, deviceTypes),
                        loading: false
                    });
                }
            });

        return () => {
            isMounted = false;
        };
    }

    /**
     * Creates new device and device-dispense composition instances. Devices list is re-loaded by successful response.
     * @param {DeviceFormItem} deviceItem - new device object
     */
    handleAddDevice(deviceItem) {
        deviceService.create({ content: devicesServiceHelper.mapFormItemToDeviceComposition(deviceItem, false) })
            .then((deviceUuid) => {
                deviceDispenseService.create({ content:
                        devicesServiceHelper.mapFormItemToDeviceDispenseComposition(deviceItem, deviceUuid, false) })
                    .then(() => {
                        this.loadComponent();
                    });
            });
    }

    /**
     * Updates device composition instance by uuid. Used for editing and returning the device. Devices list is re-loaded by successful response.
     * @param {DeviceFormItem} currentDevice - current device object
     * @param {boolean} isDeviceReturned - flag to define if the device is being returned (i.e. new device-dispense composition is created)
     */
    handleUpdateDevice(currentDevice, isDeviceReturned) {
        Promise.all([
            deviceService.update({ uuid: currentDevice.key, content: devicesServiceHelper.mapFormItemToDeviceComposition(currentDevice, isDeviceReturned) }),
            isDeviceReturned ?
                deviceDispenseService.create({ content: devicesServiceHelper.mapFormItemToDeviceDispenseComposition(currentDevice, currentDevice.key, true) })
                : (deviceDispenseService.update({ uuid: currentDevice.dispenseId, content: devicesServiceHelper.mapFormItemToDeviceDispenseComposition(currentDevice, currentDevice.key, false) }),
                currentDevice.returnId ? deviceDispenseService.update({ uuid: currentDevice.returnId, content: devicesServiceHelper.mapFormItemToDeviceDispenseComposition(currentDevice, currentDevice.key, true) })
                    : null
                )])
            .then(() => {
                this.loadComponent();
            });
    }

    /**
     * Displays form to edit current device (with pre-filled input fields).
     * @param {DeviceFormItem} currentDevice - current device object
     */
    handleShowEditForm(currentDevice) {
        this.setState({
            isEditDeviceMode: true,
            deviceItemToEdit: currentDevice,
        });
    }

    /**
     * Displays form to return current device.
     * @param {DeviceFormItem} currentDevice - current device object
     */
    handleShowReturnForm(currentDevice) {
        this.setState({
            isReturnDeviceMode: true,
            deviceItemToReturn: currentDevice,
        });
    }

    /**
     * Closes form for editing current device.
     */
    handleCloseEditModal () {
        this.setState({
            isEditDeviceMode: false,
            deviceItemToEdit: {}
        });
    }

    /**
     * Closes form for returning current device.
     */
    handleCloseReturnModal () {
        this.setState({
            isReturnDeviceMode: false,
            deviceItemToReturn: {}
        });
    }

    render() {
        return (
            <DevicesIndex
                path={`${this.getFolderPath()}/patient/devices`}
                folderId={this.props.folderId}
                teamId={this.props.teamId}
                loading={this.state.loading}
                devices={this.state.results}
                isEditFormShown={this.state.isEditDeviceMode}
                isReturnFormShown={this.state.isReturnDeviceMode}
                hideEditForm={this.handleCloseEditModal}
                hideReturnForm={this.handleCloseReturnModal}
                deviceItemToEdit={this.state.deviceItemToEdit}
                deviceItemToReturn={this.state.deviceItemToReturn}
                showEditForm={this.handleShowEditForm}
                showReturnForm={this.handleShowReturnForm}
                onUpdateDevice={this.handleUpdateDevice}
                onAddDevice={this.handleAddDevice}
            />
        );
    }
}
