import React, { useState } from 'react';
import { ResourceCreateForm } from 'components/user/resource/create/form';
import { CreateResourceState, normalise, validate } from 'components/user/resource/create/validator';
import { resourcesService } from 'services/resources.service';
import { ResourceType } from 'models/compositions/Resource';
import { portalToast } from 'ui/toast/Toast';
import { NOTIFICATION_TYPE, pushNotificationService } from 'services/push-notifications.service';

const initialFormState: CreateResourceState = {
    type: '',
    label: '',
    url: '',
    phone: '',
    html: '',
    description: '',
    mediaFile: null,
    mediaFileUuid: '',
    category: [],
    lozengeTitle: '',
    lozengeText: '',
    sendPush: false,
    notifyTimeline: false,
    tags: []
};

const CreateResourceComponent = ({ submitLabel, currentUser, onRefresh }) => {
    const [resource, setResource] = useState<CreateResourceState>(initialFormState);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (validate(resource, setErrors)) {

            const normalisedResource = normalise(resource);

            let labels = [];

            if (resource.tags && resource.tags.length) {
                labels = [...labels, ...resource.tags.map(tag => ({
                    name: tag,
                    context: tag.toLowerCase().replace(' ', '_'),
                    type: 'resourceTags',
                    status: true,
                }))];
            }

            const newResource = {
                label: normalisedResource.label,
                url: normalisedResource.url,
                phone: normalisedResource.phone,
                resource_type: normalisedResource.type as ResourceType,
                description: normalisedResource.description,
                html_content: normalisedResource.html,
                category: [{
                    category_id: '00000000-1111-2222-3333-444444444444',
                    category_folder_id: 1000,
                    name: ''
                }],
                labels: labels,
                name_of_issuer: currentUser
            };

            resourcesService.create({ 
                content: newResource,
                file: normalisedResource.mediaFile,
                lozenge: normalisedResource.notifyTimeline ? {
                    title: normalisedResource.lozengeTitle,
                    text: normalisedResource.lozengeText
                } : undefined
            }).then(() => {
                setResource(initialFormState);
                portalToast.success({
                    title: 'Successfully added new resource.',
                    content: 'The new resource has successfully been assigned to the patient.',
                });

                onRefresh();

                if (normalisedResource.notifyTimeline && normalisedResource.sendPush) {
                    return pushNotificationService.send({ notification: NOTIFICATION_TYPE.RESOURCE });
                }
            }).catch(() => {
                portalToast.error({
                    title: 'Error',
                    content: 'A problem was encountered whilst trying to create the new resource.',
                });
            });
        }
    };

    return (
        <ResourceCreateForm
            errors={errors}
            onChange={(c) => setResource({ ...resource, ...c })}
            form={resource}
            onSubmit={handleSubmit}
            hideSubmit={false}
            buttonLabel={submitLabel}
            onShowMediaResource={null}
            showPatientOptions={true} />
    );
};

export default CreateResourceComponent;
