import { FileUnit } from 'common/ui/files-list/FilesList';
import { makeGetRequest } from 'services/api';

const getContentFiles = async ({
    folder,
    roleUuid,
    type = 'live',
}): Promise<Array<FileUnit>> => {
    let response;
    try {
        response = await makeGetRequest(`/bitbucket/${type}?folder=${folder}&using_role_uuid=${roleUuid}`);
    } catch (e) {
        const { response: { response } } = e;
        const { data } = response;
        throw new Error(data.error);
    }
    return response;
};

export const getLiveFiles = async({
    folder,
    roleUuid,
}): Promise<Array<FileUnit>> => {
    return getContentFiles({
        folder,
        roleUuid,
        type: 'live'
    });
};

export const getUserFiles = async({
    folder,
    roleUuid,
}): Promise<Array<FileUnit>> => {
    return getContentFiles({
        folder,
        roleUuid,
        type: 'files'
    });
};

export const refreshContentFiles = async ({
    roleUuid,
}): Promise<Array<FileUnit>> => {
    let response;
    try {
        response = await makeGetRequest(`/bitbucket/refresh?using_role_uuid=${roleUuid}`);
    } catch (e) {
        const { response: { response } } = e;
        const { data } = response;
        throw new Error(data.error);
    }
    return response;
};

export const getChangedFiles = async ({
    roleUuid,
}): Promise<FileUnit[]> => {
    let response;
    try {
        response = await makeGetRequest(`/bitbucket/changes?using_role_uuid=${roleUuid}`);
    } catch (e) {
        const { response: { response } } = e;
        const { data } = response;
        throw new Error(data.error);
    }
    return response;
};
