import { RoleType } from 'features/patient/patient-dashboard/RoleType';
import React, { FC } from 'react';
import { useDashboardData } from 'features/patient/patient-dashboard/data/useDashboardData';
import { GridItem } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/dashboard-questionnaire-row/DashboardQuestionnaireRow';
import { DASHBOARD_COMPONENT_NAMES } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { DashboardQuestionnaireTableView } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/DashboardQuestionnaireTableView';
import { TeamPrefItem } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/DashboardQuestionnaireTable';

interface QuestionnaireModuleProps {
    context: string;
    title: string;
    items: TeamPrefItem[];
}

export const DashboardQuestionnaireTableForCarer: FC<QuestionnaireModuleProps> = ({
    title,
    items,
    context,
}) => {

    const { data, loading } = useDashboardData<GridItem[]>({
        type: DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTableForCarer,
        payload: {
            context,
            items
        }
    });

    return (
        <DashboardQuestionnaireTableView role={RoleType.Carer} title={title} data={data} loading={loading}/>
    );
};
