/* eslint-disable indent */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { browserHistory } from 'react-router';
// components
import AdvancedSearchComponent from './AdvancedSearchComponent';
// utils
import {
    AdvancedSearch,
    AdvancedSearchDocumentConfig,
} from 'models/AdvancedSearchDefinition';
import { AdvancedSearchServiceProvider } from 'features/patients/advanced-search/AdvancedSearchServiceProvider';
// interfaces
import { Composition } from 'models/Composition';

function useAdvancedSearchHistoryTracker(
    selectedSearchUuid: string,
    setSelectedSearchUuid: (newSelection: string) => void,
) {
    const shouldPushToHistory = useRef<boolean>(false);

    useEffect(() => {
        const { filter, uuid } = browserHistory.getCurrentLocation().query;
        if (filter !== 'advanced-search' || !uuid) {
            setSelectedSearchUuid(null);
        } else {
            if (typeof uuid == 'string') {
                setSelectedSearchUuid(uuid);
            }
        }

        const unlisten = browserHistory.listenBefore((location) => {
            if (location.action == 'PUSH' || location.action == 'POP') {
                if (location.query?.filter == 'advanced-search') {
                    const uuid = location.query.uuid;

                    const newUuidValue =
                        typeof uuid === 'string' && !!uuid ? uuid : null;
                    setSelectedSearchUuid(newUuidValue);
                }
            }
        });

        return () => {
            unlisten();
        };
    }, [setSelectedSearchUuid]);

    useEffect(() => {
        if (shouldPushToHistory.current) {
            const newQuery = selectedSearchUuid
                ? {
                      filter: 'advanced-search',
                      uuid: selectedSearchUuid,
                  }
                : {
                      filter: 'advanced-search',
                  };
            browserHistory.push({
                pathname: browserHistory.getCurrentLocation().pathname,
                query: newQuery,
            });
        }
        shouldPushToHistory.current = true;
    }, [selectedSearchUuid]);
}

const AdvancedSearchContainer: FC<{
    searchService: AdvancedSearchServiceProvider;
}> = ({ searchService }) => {
    const [selectedSearchUuid, setSelectedSearchUuid] = useState<string | null>(
        null,
    );
    const [searchList, setSearchList] = useState<Composition<AdvancedSearch>[]>(
        [],
    );
    const [documentSearchConfigList, setDocumentSearchConfigList] =
        useState<AdvancedSearchDocumentConfig[]>(null);

    const handleSelectSearchUuid = useCallback((uuid: string | null) => {
        setSelectedSearchUuid(uuid);
    }, []);

    const loadSearchList = useCallback(() => {
        return searchService.listSearches().then((searchList) => {
            setSearchList(searchList.sort((a, b) => {
                return a.content.name.localeCompare(b.content.name, 'en', { sensitivity: 'base' });
            }));
        });
    }, [searchService]);

    useAdvancedSearchHistoryTracker(selectedSearchUuid, setSelectedSearchUuid);

    useEffect(() => {
        loadSearchList();
    }, [loadSearchList]);

    useEffect(() => {
        searchService.getSearchConfig().then(setDocumentSearchConfigList);
    }, [searchService]);

    return (
        <AdvancedSearchComponent
            searchService={searchService}
            selectedSearchUuid={selectedSearchUuid}
            changeSelectedSearchUuid={handleSelectSearchUuid}
            searchList={searchList}
            loadSearchList={loadSearchList}
            documentSearchConfigList={documentSearchConfigList}
        />
    );
};

export default AdvancedSearchContainer;
