import { DASHBOARD_COMPONENT_NAMES } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { TeamPrefItem } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/DashboardQuestionnaireTable';
import { getDataForDemographicTable } from 'features/patient/patient-dashboard/data/getDataForDemographicTable';
import { getDataForQuestionnaireGraph } from 'features/patient/patient-dashboard/data/getDataForQuestionnaireGraph';
import { getDataForQuestionnaireTable } from 'features/patient/patient-dashboard/data/getDataForQuestionnaireTable';
import { getCarerInfo, getDataForCarerDemographicTable } from 'features/patient/patient-dashboard/data/getDataForCarerDemographicTable';

export type ParamTypes =
    | {type: DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTable; payload: {folderId?: number; teamId?: number; context: string; items: TeamPrefItem[]}}
    | {type: DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTableForCarer; payload: {folderId?: number; teamId?: number; context: string; items: TeamPrefItem[]}}
    | {type: DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireGraph; payload: {folderId?: number; teamId?: number; context: string; questionnaire: string; measure?: string}}
    | {type: DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireGraphForCarer; payload: {folderId?: number; teamId?: number; context: string; questionnaire: string; measure?: string}}
    | {type: DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTable; payload?: {folderId?: number} }
    | {type: DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTableForCarer; payload?: {folderId?: number} };

export type DashboardDataGetter = (params: ParamTypes) => Promise<any>;
export const getDashboardData: DashboardDataGetter = async (params) => {
    let data;
    let skip = false;
    let carerFolderId: number;
    switch (params.type) {
    case DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTable: {
        if (params.payload.folderId) {
            data = await getDataForDemographicTable({ folderId: params.payload.folderId });
        } else {
            skip = true;
        }
        break;
    }

    case DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTableForCarer: {
        if (params.payload.folderId) {
            data = await getDataForCarerDemographicTable({ folderId: params.payload.folderId });
        } else {
            skip = true;
        }
        break;
    }

    case DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireGraph: {
        const { teamId, folderId, context, questionnaire, measure } = params.payload;
        if(teamId && folderId && context && questionnaire) {
            data = await getDataForQuestionnaireGraph({ teamId, folderId, context, questionnaire, measure });
        } else {
            skip = true;
        }
        break;
    }
    case DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireGraphForCarer: {
        const { teamId, folderId, context, questionnaire, measure } = params.payload;
        if(teamId && folderId && context && questionnaire) {
            const { carer } = await getCarerInfo(folderId);
            if(!carer) {
                return;
            }
            data = await getDataForQuestionnaireGraph({ teamId, folderId: carer.id, context, questionnaire, measure });
            carerFolderId = carer.id;
        } else {
            skip = true;
        }
        break;
    }
    case DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTable: {
        const { teamId, folderId, context, items } = params.payload;
        if(teamId && folderId && context && items && items.length) {
            data = await getDataForQuestionnaireTable({ teamId, folderId, context, items });
        } else {
            skip = true;
        }
        break;
    }
    case DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTableForCarer: {
        const { teamId, folderId, context, items } = params.payload;
        if(teamId && folderId && context && items && items.length) {
            const { carer } = await getCarerInfo(folderId);
            if(!carer) {
                return;
            }
            data = await getDataForQuestionnaireTable({ teamId, folderId: carer.id, context, items });
        } else {
            skip = true;
        }
        break;
    }
    }
    return { data, skip, carerFolderId };
};
