class ModalService {
    constructor() {
        this.modalComponents = new Map();
    }

    registerModalComponent(id, component) {
        this.modalComponents.set(id, component);
    }

    getModalComponenent(id) {
        return this.modalComponents.get(id);
    }
}

export const modalService = new ModalService();
