import React from 'react';
import _ from 'services/i18n';
import JSONTable from 'components/jsontable';
import PaginationView from 'components/pagination';
import { Link } from 'react-router';
import Modal from 'components/modal';
import ErrorBox from 'components/errorbox';

export default class extends React.Component {
    renderServices() {
        return this.props.services.map((item, idx) => {
            return (
                <option key={idx} value={item.id}>{item.name}</option>
            );
        });
    }

    renderModals () {
        let title, buttons;
        if (!this.props.modal) {
            return null;
        }

        if (this.props.modal == 'manage') {
            title = _`Create Team`;

            if (this.props.modalId) {
                title = _`Edit Team`;
            }

            buttons = [
                {type: 'callback', style: 'primary', callback: this.props.onSubmitModal, label: title},
                {type: 'callback', callback: this.props.onCloseModal, label: _`Cancel`},
            ];

            return (
                <Modal title={title} onClose={this.props.onCloseModal} busy={this.props.modalBusy} buttons={buttons}>
                    <div className="row">
                        <ErrorBox errors={this.props.errors} />
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>{_`Name`}</label>
                                <input type="text" className="form-control" name="teamName"
                                       onChange={this.props.onChange} value={this.props.teamName} />
                            </div>
                            <div className="form-group">
                                <label>{_`Service`}</label>
                                <select name="service" className="form-control" onChange={this.props.onChange} readOnly={this.props.modalId}
                                        value={this.props.service || ''}>
                                    <option value="">--- Select a Service ---</option>
                                    {this.renderServices()}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        }
    }

    render() {
        const actionLinks = [
            {type: 'callback', callback: this.props.onClickEditTeam, label: _`Edit Team`}
        ];

        if (this.props.loading) {
            return (
                <div className="team-list">
                    <h3>{_`Team List Loading...`}</h3>
                </div>
            );
        }

        return (
            <div className="team-list">
                <h3>{_`Team List`}</h3>
                <div className="row spacer">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-default"
                                onClick={this.props.onClickCreateTeam}>{_`Create New Team`}</button>
                    </div>
                </div>
                <JSONTable results={this.props.results} actions={actionLinks} />
                <PaginationView currentPage={this.props.page}
                                pageCount={Math.ceil(this.props.count / this.props.perPage)}
                                onChange={this.props.onChangePage}/>
                {this.renderModals()}
            </div>
        );
    }
}
