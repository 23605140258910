import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';
import { $webPost } from 'services/api';
import * as lo from 'lodash';

export default class SignUpComponentContainer extends BaseComponent {
    /**
     * @param {Object} props - Passed in properties
     */
    constructor (props) {
        super(props);

        this.state = {
            email: this.props.location.query.email || '',
            token: this.props.location.query.token || '',
            password: '',
            complete: false,
            inviteConfirmed: false,
            confirmPassword: '',
            errors: [],
            loading: true,
            tokenError: false,
            service: this.props.location.query.service || '',
            missingRequiredParams: []
        };

        this.bindEvents([
            'Change',
            'Submit'
        ]);

        this.View = View;
    }


    inputParamsIsValid() {
        const missingRequiredParams = [];
        if(!this.state.email) {
            missingRequiredParams.push('email');
        }

        if(!this.state.token) {
            missingRequiredParams.push('token');
        }

        this.setState({
            missingRequiredParams
        });

        return !missingRequiredParams.length;
    }

    componentDidMount () {
        if(!this.inputParamsIsValid()) {
            return;
        }

        if (this.state.token !== '') {
            $webPost(['getInviteEmail'], {
                token: this.state.token,
                portal: this.props.portal
            }).then((response) => {
                this.setState({
                    email: response.message.email,
                    loading: false
                });
            }, (error) => {
                this.setState({
                    email: '',
                    tokenError: true,
                    loading: false
                });
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    handleChange (e) {
        let newState = {};

        switch(e.target.name) {
            case 'email':
            case 'password':
            case 'confirmPassword':
                newState[e.target.name] = e.target.value;
                this.setState(newState);
                break;
        }
    }

    handleSubmit (e) {
        var submission = {}, errors = [];
        e.preventDefault();

        submission = {
            email: this.state.email,
            password: this.state.password,
            portal: this.props.portal
        };

        if (lo.get(this.props, 'location.query.token', null)) {
            submission.token = this.props.location.query.token;
        }

        if (!this.state.email.match(/^[^\s@]+@[^\s@]+$/)) {
            errors.push('Email address must be in a valid format');
        }


        if (this.state.password.length < 8) {
            errors.push('Password must be a minimum length of 8 characters');
        }

        if (this.state.confirmPassword !== this.state.password) {
            errors.push('Password and Password Confirmation must match');
        }

        if (errors.length > 0) {
            this.setState({
                loading: false,
                errors: errors
            });
            return;
        }

        this.setState({loading: true});

        this.server.postRequest('/register-user', submission, (response) => {
            if (response.status == 'OK') {
                this.setState({
                    complete: true
                });

                if (response.inviteConfirmed == 1) {
                    this.setState({
                        inviteConfirmed: true
                    });
                }
                return;
            }

            if (response.hasOwnProperty("details")) {
                if (response.details.hasOwnProperty("password")) {
                    this.setState({
                        loading: false,
                        errors: response.details.password
                    });
                    return;
                }
            }

            if (typeof response.details == "string") {
                this.setState({
                    loading: false,
                    errors: [
                        response.details
                    ]
                });

                return;
            }

            this.setState({
                loading: false,
                errors: [
                    response.message
                ]
            });
        });
    }
}