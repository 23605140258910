import React, { Fragment } from 'react';
import { Row, Column, FormGroup } from 'ui';
import _ from 'services/i18n';
import { BulkOperationReviewProps } from './models/bulkOperationHistoryModels';


const BulkOperationEmailReview = ({ bulkOperationData, bulkOperationType }: BulkOperationReviewProps):  JSX.Element => {
    return (
        <Fragment>
            <form noValidate>
                <Column md={12}>
                    <Row>
                        <FormGroup>
                            <label htmlFor='title'>{bulkOperationType === 'sendEmail' ? _`Email subject` : _`Title`}</label>
                            <input
                                type="text"
                                name="title"
                                className={'form-control'}
                                value={bulkOperationData.subject} readOnly />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup>
                            <label htmlFor='content'>{bulkOperationType === 'sendEmail' ? _`Email content` : _`Letter content`}</label>
                            <textarea value={bulkOperationData.textContent} className='form-control' rows={10} readOnly />
                        </FormGroup>
                    </Row>
                </Column>
            </form>
        </Fragment>
    );
};

export default BulkOperationEmailReview;
