import React from 'react';
import _ from 'services/i18n';
import { Panel, PanelFooter, Row, Column } from 'ui';
import BaseInput from './base-input';

export default class extends React.Component {
    render () {
        return (
            <form onSubmit={this.props.onSubmit} id={this.props.id} className='form-panel'>
                <IntroText {...this.props} />
                <RequiredFieldExplanation {...this.props} />
                <Panel
                    title={this.props.title}
                    busy={this.props.busy}
                    alertText={this.props.alertText}
                    alertType={this.props.alertType || 'info'} >
                    
                    {this.renderChildren()}
                    <PanelFooter>
                        <div>
                            <SubmitButton {...this.props} />
                            <CancelButton {...this.props} />
                            <ResetButton {...this.props} />
                        </div>
                        <div>
                            <FooterMessage {...this.props} />
                        </div>
                    </PanelFooter>
                </Panel>
            </form>
        );
    }
    
    renderChildren () {
        return React.Children.map(this.props.children, (child) => {
            
            if (child != null && child.type.prototype instanceof BaseInput) {
                
                if (child.props.onChange != null) {
                    console.warn('Form input component already has an onChange handler. This will be overridden by the FormPanel change handler.');
                }
                
                // This is a form input component.
                return React.cloneElement(
                    child,
                    {
                        // Inject a change handler.
                        onChange: this.props.onChange,
                        // Force validation to be shown if necessary.
                        forceShowValidation: this.props.forceShowValidation
                    }
                );
                
            }
            // Render the component unmodified.
            return child;
        });
    }
};

const IntroText = ({intro}) => {
    if (intro == null) {
        return (<noscript />);
    }
    return (
        <div className='intro-text'>
            {intro}
        </div>
    );
};

const RequiredFieldExplanation = ({hasRequiredChildren}) => {
    if (hasRequiredChildren) {
        return (
            <div className='intro-text'>
                <span className='text-danger'>*</span> = {`required field`}
            </div>
        );
    }
    
    return (<noscript />);
};

const SubmitButton = ({submitLabel}) => {
    return (
        <button type='submit' className='btn btn-success'>
            {submitLabel || _`Submit`}
        </button>
    );
};

const CancelButton = ({showCancel, onCancel, cancelLabel}) => {
    if (!showCancel) {
        return (<noscript />);
    }
    
    return (
        <button type='button' className='btn btn-danger' onClick={onCancel}>
            {cancelLabel || _`Cancel`}
        </button>
    );
};

const ResetButton = ({showReset, onReset, resetLabel}) => {
    if (!showReset) {
        return (<noscript />);
    }
    return (
        <button type='reset' className='btn btn-default' onClick={onReset}>
            {resetLabel || _`Reset`}
        </button>
    );
};

const FooterMessage = ({allValid, forceShowValidation}) => {
    if (!allValid && forceShowValidation) {
        return (
            <p className='text-danger'>
                {_`Please fix the errors shown above.`}
            </p>
        );
    }
    
    return (<noscript />);
};
