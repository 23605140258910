import React, { useState } from 'react';
import { ResponsiveTable } from 'ui';
import _ from 'services/i18n';
import { resourcesService } from 'services/resources.service';
import { PaginationView } from 'components/pagination';
import { HTMLModalDialog } from 'components/modals/html-modal';
import { ExternalLink } from 'common/ui/ExternalLink';
import mediaResourceService from 'services/media-resource/media-resource.service';
import { portalToast } from 'ui/toast/Toast';
import ManageResourceModal from 'components/resource/manage/ManageResourceModal';
import './ResourceList.scss';
import { CoopResource } from 'models/compositions/CoopResource';
import { Composition } from 'models/Composition';
import { resourceTypeList } from 'features/resource/ResourceTypeSelect/ResourceTypeSelect';

type ResourceListProps = {
    categoryId: string;
    currentUser: string;
    results: Composition<CoopResource>[];
    totalResults: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    onRefresh: () => void;
    showActions: boolean;
}

const RESOURCES_PER_PAGE = 10;

const ResourceList = ({ categoryId, currentUser, results, totalResults, currentPage, setCurrentPage, onRefresh, showActions }: ResourceListProps) => {

    const [displayEditResourceModal, setDisplayEditResourceModal] = useState<boolean>(false);
    const [editResourceUUID, setEditResourceUUID] = useState<string>(null);

    const removeResourceFromCollection = async (uuid: string) => {

        try {
            const resourceResponse = await resourcesService.getByUuid({ uuid: uuid });

            const updatedCategories = resourceResponse.message.content.category.filter((category) => category.category_id !== categoryId);

            const updatedResource = {
                ...resourceResponse.message.content,
                category: updatedCategories,
            };

            resourcesService.update({ uuid: uuid, content: updatedResource })
                .then(() => onRefresh())
                .catch(() => {
                    portalToast.error({
                        title: 'Error',
                        content:
                        'Unable to remove resource as it must belong to at least one collection',
                    });
                });

        } catch(e) {
            portalToast.error({
                title: 'Error',
                content:
                    'Something went wrong while removing the resource.',
            });
        }
    };

    const editResource = (uuid: string) => {
        setEditResourceUUID(uuid);
        setDisplayEditResourceModal(true);
    };

    const list = results.map(({ uuid, folder_id: folderId, content }, idx) => {

        let tags = [];

        if (content.labels) {
            tags = content.labels
                .filter(label => label.type === 'resourceTags' && label.status === true)
                .map(label => label.name);
        }

        return (
            <tr key={idx}>
                <td>{resourceTypeList.find((type) => type.value === content.resource_type).label || ''}</td>
                <ResourceLabel {...content} />
                <td>{content.description}</td>
                <td>{tags.join(', ')}</td>
                {showActions && (
                    <td>
                        <div className="resource-list-action-buttons">
                            <button className="btn btn-xs btn-default"
                                onClick={() => editResource(uuid)}
                            >{_`Edit`}</button>
                            <button className="btn btn-xs btn-danger"
                                onClick={() => removeResourceFromCollection(uuid)}
                            >{_`Remove`}</button>
                        </div>
                    </td>
                )}
            </tr>
        );
    });
    
    return (
        <div className="resource-list-component">
            {results.length > 0 
                ? (
                    <ResponsiveTable className="table table-bordered table-condensed table-hover table-nomargin">
                        <thead>
                            <tr>
                                <th>{_`Type`}</th>
                                <th>{_`Label`}</th>
                                <th>{_`Details`}</th>
                                <th>{_`Tags`}</th>
                                {showActions && 
                                    <th>{_`Actions`}</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {list}
                        </tbody>
                    </ResponsiveTable>
                )
                : (
                    <b>{_`There are no resources in this collection`}</b>
                )
            
            }
            <PaginationView currentPage={currentPage}
                pageCount={totalResults / RESOURCES_PER_PAGE}
                onChange={setCurrentPage}/>
            {displayEditResourceModal && (
                <ManageResourceModal id={editResourceUUID} categoryId={categoryId} currentUser={currentUser} onClose={() => {
                    setEditResourceUUID(null);
                    setDisplayEditResourceModal(false);
                    onRefresh();
                }}/>
            )}
        </div>
    );
};

function handleOpenResourceLink(resource) {
    if (resource.resource_type === 'media_resource') {
        mediaResourceService.getFileLink(resource.media_resource_uuid)
            .then((url) => {
                window.open(url, '_blank');
            });
        return;
    }
    if(resource.resource_type === 'phone') {
        window.open(`tel:${resource.phone}`, '_blank');
        return;
    }
    let href = resource.url || '';
    href = href ? /https?:\/\//.test(href) ? href : `http://${href}` : '';
    window.open(resource.url);
}

const ResourceLabel = (props) => {
    const [isShowDialog, setIsShowDialog] = useState(false);

    function handleCreateButtonClick() {
        setIsShowDialog(true);
    }

    function handleCloseDialog() {
        setIsShowDialog(false);
    }

    if (props.resource_type === 'html') {
        return (
            <td>
                <a onClick={handleCreateButtonClick}>{props.label}</a>
                {isShowDialog && (
                    <HTMLModalDialog 
                        onClose={handleCloseDialog}
                        label={props.label}
                        html_content={props.html_content}></HTMLModalDialog>
                )
                }
            </td>
        );
    }
    
    return (<td>{<ExternalLink onClick={() => handleOpenResourceLink(props)}>{props.label}</ExternalLink>}</td>);
};

export default ResourceList;
