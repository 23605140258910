export const AUTH = {
    AUTH_SUCCESSFUL: 'AUTHENTICATION_SUCCESSFUL',
    AUTH_FAILED: 'AUTHENTICATION_FAILED',
    LOGOUT_SUCCESSFUL: 'LOGOUT_SUCCESSFUL'
};

export const PATIENT = {
    CLEAR_DATA: 'CLEAR_DATA',
    LOAD_PREFERENCES_START: 'LOAD_PREFERENCES_START',
    LOAD_PREFERENCES_SUCCESS: 'LOAD_PREFERENCES_SUCCESS',
    LOAD_PREFERENCES_FAIL: 'LOAD_PREFERENCES_FAIL'
};

export enum ADMINP_MEMBERS_LIST {
    OPEN_MODAL = 'OPEN_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
    GET_ROLES = 'GET_ROLES',
    SUBMIT_NEW_USER = 'SUBMIT_NEW_USER',
    RESET_INVITE_FORM = 'RESET_INVITE_FORM'
}