import { UserFolder } from 'models/UserFolder';
import {
    PersonPrimaryIdentifier,
    PRIMARY_IDENTIFIER_NAMESPACE
} from 'models/PersonPrimaryIdentifier';
import {
    HumanReadableLabel,
} from 'models/TeamPreferences';
import { Demographics } from 'models/compositions/Demographics';

export const findLabel = (namespace: PRIMARY_IDENTIFIER_NAMESPACE, labels: HumanReadableLabel[] = []): string => {
    const tag = '[findLabel]';
    const foundLabel = labels.find(({ namespace: ns }) => ns === namespace);

    if(foundLabel?.label) {
        return foundLabel.label;
    }

    if(namespace === PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER) {
        return 'NHS Number';
    }
    console.warn(tag, `Human readable label not found for namespace ${namespace} among the available variants:`, labels);
    return namespace || '';
};

export const getPersonPrimaryIdentifierFromUserFolder = (patient: UserFolder, availableHumanReadableLabels?: HumanReadableLabel[], demographics?: Demographics): PersonPrimaryIdentifier => {
    const primaryIdentifier = patient.person_primary_identifier;

    if(primaryIdentifier?.namespace) {
        const label = findLabel(primaryIdentifier.namespace, availableHumanReadableLabels);
        primaryIdentifier.label = label;
        return primaryIdentifier;
    }

    const nhsNumber = patient?.user_details?.identifiers?.nhs_number || demographics?.identifiers?.nhs_number;

    if(nhsNumber) {
        return {
            namespace: PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER,
            label: 'NHS Number',
            value: nhsNumber,
        };
    }

};

export const getNamespaceLabel = (primaryIdentifier) => {
    if (primaryIdentifier) {
        const { namespace } = primaryIdentifier;
        const label = primaryIdentifier.human_readable_labels.find(item => item.namespace === namespace);
        if (label) {
            return label.label;
        }
    }
    return 'NHS Number/Identifier';
};
