import React, { useEffect } from 'react';
import PageTitle from 'page-title';
import { Loading, LoadingError } from 'common/ui/alert-boxes';
import { useCurrentTeam } from 'common/useCurrentTeam';
import { MNDDepressedPatientsWorklist } from './MNDDepressedPatientsWorklist';
import { useMNDDepressedPatientsWorklistCollection } from './useMNDDepressedPatientsWorklistCollection';
import { useI18N } from 'common/i18n/useI18N';

export function MNDDepressedPatientsWorklistPage() {
    const team = useCurrentTeam();
    const _ = useI18N();

    useEffect(() => {
        PageTitle.setTitle(_`Worklist for identifying depressed patients | MyPathway Clinical Portal`);
    }, [_]);

    if (!team || !team.teamId) {
        return null;
    }

    return (
        <div className='worklist-tab'>
            <MNDDepressedPatientsWorklistTab teamId={team.teamId}/>
        </div>
    );
}

function MNDDepressedPatientsWorklistTab({
    teamId,
}) {
    const [
        MNDDepressedPatientsWorklistItems,
        {
            isFetching,
            isLazyFetching,
            isError,
        }
    ] = useMNDDepressedPatientsWorklistCollection({
        teamId
    });

    if (isFetching || isLazyFetching) {
        return <Loading show={isFetching}/>;
    }

    if (isError) {
        return <LoadingError show={isError}/>;
    }

    return (
        <MNDDepressedPatientsWorklist
            items={MNDDepressedPatientsWorklistItems}
            isLazyFetchingInProcess={isLazyFetching}
        />
    );
}
