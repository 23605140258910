
export type CostEstimateValue = {
    status: CostEstimateStatusType;
    value: number;
    description?: string;
};

export enum CostEstimateStatusType {
    COMPLETE, NONE, FAILED, PENDING, UNKNOWN
}

export const PendingCostEstimateValue: CostEstimateValue = {
    status: CostEstimateStatusType.PENDING,
    value: null,
    description: 'Estimating costs...'
};

export const UnknownCostEstimateValue: CostEstimateValue = {
    status: CostEstimateStatusType.UNKNOWN,
    value: null,
    description: 'Unknown cost'
};
