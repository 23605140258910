
// The triage decision codes available to choose from in the SPA triage decision form.
// They are grouped by specialty.
// TODO: Load this from a composition in the team folder.
export const triageDecisionCodes = {
    'Orthopaedics': [
        'Clinic OE 1',
        'Clinic OE 2',
        'Clinic OE 3',
        'Clinic OE 4',
        'Clinic OE 5',
        'Clinic OE 6',

        'Clinic OFA 1',
        'Clinic OFA 1B',
        'Clinic OFA 2',
        'Clinic OFA 3',
        'Clinic OFA 4',
        'Clinic OFA 5',
        'Clinic OFA 6',

        'Clinic OH 1',
        'Clinic OH 2',
        'Clinic OH 3',
        'Clinic OH 4',

        'Clinic OK 1',
        'Clinic OK 2',
        'Clinic OK 3',
        'Clinic OK 4',
        'Clinic OSH 1',
        'Clinic OSH 2',
        'Clinic OSH 3',
        'Clinic OSH 4',
        'Clinic OSH 5',
        'Clinic OSH 6',

        'Clinic OSP 1 A',
        'Clinic OSP 1 B',
        'Clinic OSP 1 C',

        'Clinic OSP 2 A',
        'Clinic OSP 2 B',
        'Clinic OSP 2 C',

        'Clinic OSP 3 A',
        'Clinic OSP 3 B',
        'Clinic OSP 3 C',

        'Clinic OSP 4 A',
        'Clinic OSP 4 B',
        'Clinic OSP 4 C',

        'Clinic OSP 5 A',
        'Clinic OSP 5 B',
        'Clinic OSP 5 C',

        'Clinic OSP 6 A',
        'Clinic OSP 6 B',
        'Clinic OSP 6 C',

        'Clinic OSP 7 A',
        'Clinic OSP 7 B',
        'Clinic OSP 7 C',

        'Clinic OSP 8 A',
        'Clinic OSP 8 B',
        'Clinic OSP 8 C',

        'Clinic OSP 9 A',
        'Clinic OSP 9 B',
        'Clinic OSP 9 C'
    ],

    'Pain': [
        'Clinic PA',
        'Clinic PB',
        'Clinic PCNS',
        'Clinic PD',
        'Clinic PMDP',
        'Clinic POT',
        'Clinic PPHY',
        'Clinic PSCS'
    ],

    'Physioworks': [
        'Clinic PWERP hip',
        'Clinic PWERP knee',
        'Clinic PWERP F&A',
        'Clinic PWERP shoulder',
        'Clinic PWERP elbow',
        'Clinic PWERP H&W',
        'Clinic PWERP back',
        'Clinic PWERP neck',
        'Clinic PWERP other',

        'Clinic PW7 hip',
        'Clinic PW7 knee',
        'Clinic PW7 F&A',
        'Clinic PW7 shoulder',
        'Clinic PW7 elbow',
        'Clinic PW7 H&W',
        'Clinic PW7 back',
        'Clinic PW7 neck',
        'Clinic PW7 other',
        'Clinic PW7 pod', // formerly 'Clinic PWFA7'

        'Clinic PW6 hip',
        'Clinic PW6 knee',
        'Clinic PW6 F&A',
        'Clinic PW6 shoulder',
        'Clinic PW6 elbow',
        'Clinic PW6 H&W',
        'Clinic PW6 back',
        'Clinic PW6 neck',
        'Clinic PW6 other',
        'Clinic PW6 pod', // formerly 'Clinic PWFA6'

        'Clinic PW5 hip',
        'Clinic PW5 knee',
        'Clinic PW5 F&A',
        'Clinic PW5 shoulder',
        'Clinic PW5 elbow',
        'Clinic PW5 H&W',
        'Clinic PW5 back',
        'Clinic PW5 neck',
        'Clinic PW5 other',

        'Clinic PW8 pod', // formerly 'Clinic PWFA8a'

        'Clinic PWIPT7',
        'Clinic PWIPT6',
        'Clinic PWIPTERP ',

        'Clinic VFB - shoulder',
        'Clinic VFB - elbow',
        'Clinic VFB - H&W',
        'Clinic VFB - neck',

        'Clinic PWCAS hip',
        'Clinic PWCAS knee',
        'Clinic PWCAS F&A',
        'Clinic PWCAS shoulder',
        'Clinic PWCAS elbow',
        'Clinic PWCAS H&W',
        'Clinic PWCAS back',
        'Clinic PWCAS neck',
        'Clinic PWCAS other',

        'Clinic PWEIK',
        'Clinic PWEIB'
    ],

    'Rheumatology': [
        'Clinic RAD',
        'Clinic RAS',
        'Clinic RCTD',
        'Clinic REAC',
        'Clinic RSEMC',
        'Clinic RSEMR',
        'Clinic RG'
    ],

    'Therapy services': [
        'Clinic TH 1',
        'Clinic TH 2 ',
        'Clinic TH 3',
        'Clinic TH 4',
        'Clinic TH 5',
        'Clinic TH 6',
        'Clinic TH 7',
        'Clinic TH 8',
        'Clinic TH 9',
        'Clinic TH 10',
        'Clinic TH 11',
        'Clinic TH 12',
        'Clinic TH 13',
        'Clinic TH 14',
        'Clinic TH 15',
    ],

    'Hand/Wrist': [
        'Clinic OHW A',
        'Clinic OHW O',
        'Clinic OHW P',
        'Clinic OHW BP',
        'Clinic OHW C',
        'Clinic OHW CT',
        'Clinic OHW FP',
        'Clinic OHW NM',
        'Clinic OHW ST',
        'Clinic OHW WF',
        'Clinic OHW HT',
        'Clinic OHW HF',
    ]
};


// Create the selector options for triage decisions.
const triageDecisionCodeProps = Object.keys(triageDecisionCodes).sort();
const flatMapTriageDecisions = () => {
    let triageDecisionFlatAccumulator = {};

    // Go through each specialty.
    triageDecisionCodeProps.forEach((propName, specialtyIdx) => {
        if (triageDecisionCodes.hasOwnProperty(propName) && triageDecisionCodes[propName] && triageDecisionCodes[propName].length > 0) {
            triageDecisionCodes[propName].forEach((triageCodeName, triageCodeIdx) => {

                // replace some of the names to be identical
                let replacedPropName = propName.replace("Physioworks", "Physiotherapy");
                replacedPropName = replacedPropName.replace("Orthopaedics", "Orthopaedic");
                replacedPropName = replacedPropName.replace("Therapy services", "Therapy Services");
                triageDecisionFlatAccumulator[triageCodeName] = replacedPropName;
            });
        }
    });

    return triageDecisionFlatAccumulator;
}

export const triageDecisionFlat = flatMapTriageDecisions();


export default triageDecisionCodes;
