import React from 'react';
import PropTypes from 'prop-types'
import {Link} from 'react-router';

const ButtonGroup = ({ links, size = 'xs', style = 'default',upperCased = false }) => {

    const buttons = links.map((item,index) => {
        const buttonSizeClass = `btn-${item.size || size}`;
        const buttonStyleClass = `btn-${item.style || style}`;

        if (item.callback && typeof item.callback === 'function'){
            return (<button type="button"
                            className={`c-button-group__button btn ${buttonSizeClass} ${buttonStyleClass}`}
                            key={index}
                            onClick={item.callback}>
                {item.label}
            </button>);
        }else{
            return (
                (
                    <Link className={`c-button-group__button btn ${buttonSizeClass} ${buttonStyleClass}`}
                        to={item.route}
                        key={index}>
                        {item.label}
                    </Link>
                )
            );
        }
    });

    let containerExtraClass = [];
    if (upperCased){
        containerExtraClass.push('text-uppercase');
    }

    containerExtraClass = containerExtraClass.join(' ');
    return (
        <div className={`c-button-group btn-group ${containerExtraClass}`}>
            {buttons}
        </div>
    );
}

ButtonGroup.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            style: PropTypes.string,
            size: PropTypes.string,
            callback: PropTypes.func,
            route: PropTypes.route
        })
    ).isRequired,
    size: PropTypes.string,
    style: PropTypes.string
};

export default ButtonGroup;
