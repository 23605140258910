import React from 'react';
import PropTypes from 'prop-types'
import CheckboxInputView from './view';
import BaseComponent from 'components/BaseComponent';
import BaseInput from 'components/form-panel/base-input';

/**
 * Represents a checkbox input in a form panel.
 * Note that this component does not carry out any validation.
 
 * Props (data):
 *  - label = Required. A text label identifying the input field.
 *  - id = Required. An identifier for the input field.
 *  - helpText = Optional. Help text to display below the input field.
 *  - readOnly = Optional. Boolean flag indicating if the input element is readOnly. The value will not be reported to the parent form.
 *  - disabled = Optional. Boolean flag indicating if the input element is disabled. The value will not be reported to the parent form.
 *  - value = Optional. The raw value in the input field (boolean, where true = checked). Only set this if you intend to respond to this control's onChange or the form's onChange events.
 *  - initialValue = Optional. The initial value for the form (boolean, where true = checked). Ignored after construction, or if value is specified.
 *
 * Props (handlers):
 *  - onChange = Required. A function called when the value changes. Parameters are: id, rawValue, effectiveValue, validated. NOTE: This is provided automatically by the parent form if used directly inside FormPanel.
 *
 */
export default class CheckboxInput extends BaseInput {
    constructor (props) {
        super(props);

        let value = false;
        if (this.props.value !== undefined) {
            value = !!this.props.value;
        } else if (this.props.initialValue !== undefined) {
            value = !!this.props.initialValue;
        }
        
        this.state = {
            // The value of the checkbox.
            //  true = checked, false = unchecked
            value: value,
        };
        
        if (this.props.label == '') {
            console.warn('label prop must not be empty.');
        }
        
        if (this.props.id == '') {
            console.warn('id prop must not be empty.');
        }
        
        if (this.props.onChange == null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }

        this.bindEvents([
            'Change'
        ]);

        this.View = CheckboxInputView;
    }
    
    componentWillMount () {
        // Notify the parent of the initial value.
        this.notifyParent();
    }
    
    componentWillReceiveProps (newProps) {
        
        if (this.props.onChange == null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }
        
        let notifyParent = false, changed = false, newState = {};

        // If the parent has specified an explicit value, then use it.
        // Otherwise, retain our existing value.
        let newValue = this.state.value;
        if (this.props.value !== undefined) {
            newValue = !!this.props.value;
        }
        
        // We need to avoid updating data unless necessary.
        // This prevents UI glitches and potential infinite event loops.
        
        if (newValue != this.state.value) {
            // The state of the checkbox has been changed from outside.
            newState.value = newValue;
            changed = true;
        }
        
        if (newProps.onChange != this.props.onChange) {
            // The parent's change handler has changed.
            notifyParent = true;
        }

        if (changed) {
            this.setState(
                newState,
                this.notifyParent.bind(this)
            );
        } else if (notifyParent) {
            this.notifyParent();
        }
    }

    /**
     * Event handler: The content of the form field has changed.
     */
    handleChange (e) {
        let newValue = !!e.target.checked;
        
        this.setState(
            {
                value: newValue,
            },
            this.notifyParent.bind(this)
        );
    }

    /**
     * Notify the parent of the current input value which is stored in state.
     * This uses the onChange handler specifies in props.
     * It will report the value as undefined if the control is disabled or readonly.
     */
    notifyParent () {
        if (this.props.onChange) {
            if (this.props.readOnly || this.props.disabled) {
                this.props.onChange(this.props.id, undefined, undefined, undefined);
            } else {
                this.props.onChange(this.props.id, this.state.value, this.state.value, true);
            }
        }
    }
}

CheckboxInput.propTypes = {
    label:          PropTypes.string.isRequired,
    id:             PropTypes.string.isRequired,
    helpText:       PropTypes.string,
    readOnly:       PropTypes.bool,
    disabled:       PropTypes.bool,
    value:          PropTypes.bool,
    initialValue:   PropTypes.bool,
    onChange:       PropTypes.func
};
