import React, { FC, Fragment, useMemo } from 'react';

import _ from 'services/i18n';
import {
    defaultNumberSort,
    Grid,
    GridBody,
    GridCell,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow, GridSortState, SortOrder, TABLE_FILTER
} from 'common/ui/grid';
import { PaginationView } from 'components/pagination';
import {
    AttendAnywhereItem,
} from 'features/attend-anywhere/AttendAnywhereHelpers';
import {
    Filter
} from 'common/ui/grid/paginatedTableReducer';
import { isEmpty } from 'lodash';
import { Loading } from 'common/ui/alert-boxes';
import { Link } from 'react-router';
import NhsNumber from 'ui/nhs-number';
import { appointmentModeLabels } from 'services/appointment-helpers';
import { SelectConfirm } from 'features/attend-anywhere/select-confirm/SelectConfirm';
import { usePreparedAttendAnywhere } from 'features/attend-anywhere/usePreparedAttendAnywhere';
import { useTableFilter } from 'common/ui/grid/useTableFilter';
import { useTableSort } from 'common/ui/grid/useTableSort';
import { usePagination } from 'common/ui/usePagination';
import { filterNhs } from 'common/filterNhs';

const initialSortState = {
    date: SortOrder.DESC,
};

const filterSelector = {
    date: item => item.dateFilter,
};
const filters = {
    nhsNumber: (nhs: string, filterString: string) => {
        return filterNhs(filterString, nhs);
    }
};
const sortSelector = {
    date: item => item.dateSort,
};

const sortHandlers = {
    date: defaultNumberSort,
};

export interface AAWorkListContainerProps {
    list: AttendAnywhereItem[];
    sortOrder: GridSortState;
    filterState: Filter;
    onListItemAction: (param: { type: string; uuid: string; data: any }) => void;
    loading: boolean;
}

export const AttendAnywhereWorkList: FC<AAWorkListContainerProps> = (props) => {

    const { list, onListItemAction, loading } = props;

    const preparedItems = usePreparedAttendAnywhere(list);

    const { onFilter, filteredItems, filterState } = useTableFilter({
        items: preparedItems,
        selectors: filterSelector,
        filters
    });

    const { onSort, sortedItems, sortState } = useTableSort({
        items: filteredItems,
        initialSortState,
        selectors: sortSelector,
        sortHandlers,
    });

    const { items: paginatedItems, total, onPageChange, page } = usePagination({
        items: sortedItems,
        perPage: 8,
        currentPage: 1
    });

    return (
        <AttendAnywhereWorkListView
            list={paginatedItems}
            sortOrder={sortState}
            filterState={filterState}
            page={page}
            pagesTotal={total}
            onSort={onSort}
            onFilter={onFilter}
            onPageChange={onPageChange}
            onListItemAction={onListItemAction}
            isLoading={loading}
        />
    );
};

interface AttendAnywhereProps {
    list: AttendAnywhereItem[];
    sortOrder: GridSortState;
    filterState: Filter;
    page: number;
    pagesTotal: number;
    isLoading: boolean;
    onSort: (sort: GridSortState) => void;
    onFilter: (filter: Filter) => void;
    onListItemAction: (param: { type: string; uuid: string; data?: any }) => void;
    onPageChange: (page: number) => void;
}

export const AttendAnywhereWorkListView: FC<AttendAnywhereProps> = (props) => {

    const {
        list,
        sortOrder,
        filterState,
        page,
        pagesTotal,
        isLoading,

        onSort,
        onFilter,
        onListItemAction,
        onPageChange,
    } = props;

    const listIsEmpty = !isLoading && !list?.length && isEmpty(filterState);

    const modeFilterOptions = useMemo(() => {
        return Array.from(appointmentModeLabels.values());
    }, []);

    // render

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Fragment>
            <Grid
                onSort={onSort}
                onFilter={onFilter}
                sortOrder={sortOrder}
                filterState={filterState}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell
                            field="name"
                            sortable={true}
                            filterable={true}
                            title={_`Name`}
                            width={'25%'}
                        >
                            {_`Name`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="dateOfBirth"
                            sortable={true}
                            filterable={true}
                            title={_`Date of birth`}
                        >
                            {_`Date of birth`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="nhsNumber"
                            sortable={true}
                            filterable={true}
                            title={_`NHS Number`}
                        >
                            {_`NHS Number`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="hospitalNumber"
                            sortable={true}
                            filterable={true}
                            title={_`Hospital Number`}
                        >
                            {_`Hospital Number`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="triageDecision"
                            sortable={true}
                            filterable={true}
                            title={_`Triage decision`}
                        >
                            {_`Triage decision`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field="speciality"
                            title={_`Speciality`}
                        >
                            {_`Specialty`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field="clinicCode"
                            title={_`Clinic code (point of care)`}
                        >
                            {_`Clinic code`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field="date"
                            title={_`Date`}
                        >
                            {_`Date`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="type"
                            title={_`Mode`}
                            sortable={true}
                            filterable={true}
                            filterType={TABLE_FILTER.SELECT}
                            filterOptions={modeFilterOptions}
                        >
                            {_`Mode`}
                        </GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {list.map((listItem, index) => {
                        const {
                            name,
                            dateOfBirth,
                            nhsNumber,
                            hospitalNumber,
                            triageDecision,
                            speciality,
                            clinicCode,
                            uuid,
                            date,
                            type,
                            appointmentModeOptions,
                            folderId,
                            prevType,
                            sentType,
                            processing,
                        } = listItem;

                        return (
                            <GridRow key={index}>
                                <GridCell>
                                    <Link
                                        title={_`Go to the appointment details`}
                                        to={`/clinical_portal/folder/${folderId}/patient/appointments/${uuid}`}
                                    >
                                        {name}
                                    </Link>
                                </GridCell>
                                <GridCell className={'ws-nowrap'}>
                                    {dateOfBirth}
                                </GridCell>
                                <GridCell>
                                    <NhsNumber>{nhsNumber}</NhsNumber>
                                </GridCell>
                                <GridCell>
                                    {hospitalNumber}
                                </GridCell>
                                <GridCell className={'ws-nowrap'}>
                                    {triageDecision}
                                </GridCell>
                                <GridCell>
                                    {speciality}
                                </GridCell>
                                <GridCell>
                                    {clinicCode}
                                </GridCell>
                                <GridCell className={'ws-nowrap'}>
                                    {date}
                                </GridCell>
                                <GridCell>
                                    <SelectConfirm
                                        value={type}
                                        className={'attend-anywhere--select-confirm'}
                                        options={appointmentModeOptions}
                                        status={(processing && (type == sentType)) ? 'processing' : 'idle'}
                                        onChange={({ target: { value } }) => onListItemAction({ type: 'onchange', uuid, data: { type: value } })}
                                        onSubmit={() => onListItemAction({ type: 'onsubmit', uuid })}
                                    />
                                </GridCell>
                            </GridRow>
                        );
                    })}
                </GridBody>
            </Grid>
            { listIsEmpty &&
                <div className={'communication-page_empty-placeholder'}>{_`List is empty`}</div>
            }
            <PaginationView
                currentPage={page}
                pageCount={pagesTotal}
                onChange={onPageChange}
            />
        </Fragment>
    );
};
