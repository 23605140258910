import React from 'react';
import { WYSIWYGPreview, WYSIWYGPreviewProps } from './WYSIWYGPreview';

/**
 * A wrapper for the WYSIWYG editor, which will strip out the surrounding content
 * from a template if provided and only pass the content of the body to the editor.
 * 
 * When a change to the body is detected, it will get wrapped back in the template
 * if provided before calling the onChange handler.
 */
export const HTMLLetterWYSIWYGPreview = ({
    value,
    type,
    onChange,
    triggerContentUpdate,
    readOnly = false
}: WYSIWYGPreviewProps) => {

    const extractBodyFromContent = (content: string) => {
        const domParser = new DOMParser();
        const parsedHTML = domParser.parseFromString(content, 'text/html');

        let extractedBody = '';
        if (containsHTMLTemplate(parsedHTML)) {
            extractedBody = parsedHTML.body.getElementsByClassName('body')[0].innerHTML;
        } else {
            extractedBody = content;
        }
        return extractedBody;
    };

    const insertIntoTemplate = (updatedValue: string, field?: string) => {

        const domParser = new DOMParser();
        const parsedOriginalHTML = domParser.parseFromString(value, 'text/html');

        if (containsHTMLTemplate(parsedOriginalHTML)) {
            parsedOriginalHTML.body.getElementsByClassName('body')[0].innerHTML = updatedValue;
            onChange(parsedOriginalHTML.documentElement.innerHTML, field);
        } else {
            onChange(updatedValue);
        }
    };

    const containsHTMLTemplate = (parsedHTML: Document) => {
        return parsedHTML.body && parsedHTML.body.getElementsByClassName('body').length > 0;
    };

    return (
        <WYSIWYGPreview
            triggerContentUpdate={triggerContentUpdate}
            onChange={insertIntoTemplate}
            type={'letter'}
            value={extractBodyFromContent(value)}
            readOnly={readOnly}
        />
    );
};
