import { get as _get } from 'lodash';
import { IRole } from 'phr-api-client';
import { IFolderSelectorParam } from 'phr-api-client';
import { apiV2Service } from './api-v2.service';
import { DataStore } from 'services/data-store';

export type DocumentGroupNames = 'Appointments' | 'Demographics' | 'Communications' | 'Consent configuration' | 'File processing log' | 'Goals' | 'Invitations' | 'Messages' | 'Privacy notices' | 'Questionnaires' | 'Questionnaire responses' | 'Private questionnaire responses' | 'Referrals' | 'Registered GPs' | 'Request to invite' | 'Resource categories' | 'Resources' | 'Scheduled triggers' | 'Team configuration' | 'Trigger engine logs' | 'User preferences' | 'User profiles' | 'Team Preferences' | 'Message templates' | 'User tokens' | 'User feedbacks' | 'Media Resources';

export interface IDocumentGroup {
    any_archetypes: boolean;
    archetypes: string[];
    name: DocumentGroupNames;
    uuid: string;
}

export interface IChangeDocumentGroupArgs {
    documentGroupName: DocumentGroupNames;
    forDocument: {uuid: string; archetypeName: string};
    role?: IRole;
    folderId?: IFolderSelectorParam;
}

class DocumentGroupService {

    getGroupDefinitions({role}: {role?: IRole} = {}): Promise<any> {
        role = role || DataStore.get('me.currentRole');
        return apiV2Service.getDocuments({
                archetypeName: 'document-group-definitions',
                role
            })
            .then((response) => {
                return response.message.results;
            });
    }

    getGroupByUuid({uuid, role}: {uuid: string, role?: IRole}): Promise<any> {
        role = role || DataStore.get('me.currentRole');
        return this.getGroupDefinitions({role})
            .then(([definition]) => {
                if (!definition) {
                    return;
                }
                const {
                    document_groups: documentGroups
                } = definition.content;
                return documentGroups.find((documentGroup: any) => {
                    return documentGroup.uuid === uuid;
                });
            });
    }

    getGroupDefinitionUuid({groupName, role}: {groupName: string, role?: IRole}): Promise<string> {
        role = role || DataStore.get('me.currentRole');
        return this.getGroupDefinitions({role})
            .then(([definition]) => {
                if (!definition) {
                    return;
                }
                groupName = groupName.toLowerCase();
                const {
                    document_groups: documentGroups
                } = definition.content;
                const documentGroup = documentGroups.find(({name}: {name: string}) => {
                    return name.toLowerCase() === groupName
                });
                return documentGroup ? documentGroup.uuid : undefined;
            });
    }

    changeDocumentGroup({documentGroupName, forDocument, folderId, role}: IChangeDocumentGroupArgs): Promise<any> {
        role = role || DataStore.get('me.currentRole');
        folderId = folderId || DataStore.get('currentFolder');
        const {uuid, archetypeName} = forDocument;
        return this.getGroupDefinitionUuid({groupName: documentGroupName, role})
            .then((groupUuid) => {
                if (!groupUuid) {
                    console.error(`Unknown groupName: ${documentGroupName}`);
                    return;
                }

                return apiV2Service.changeDocumentGroup({
                    uuid,
                    archetypeName,
                    role,
                    groupUuid,
                    folderId
                });

            });
    }
}

export const documentGroupService = new DocumentGroupService();
