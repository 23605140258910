import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import './ProfileChangeEmail.less';
import _ from '../../services/i18n';
import { Column, ConfirmModal } from '../../../portal-components/ui';
import TextInput from '../../../portal-components/components/form-panel/text-input';
import { useCurrentUser } from '../../common/useCurrentUser';
import FormPanel from '../../../portal-components/components/form-panel';

import { profileService } from '../../services/profile.service';

export const ProfileChangeEmail: FunctionComponent<{
    portal: 'clinical_portal' | 'admin_portal'
}> = ({
    portal = 'clinical_portal',
}) => {
    const [emailToSave, setEmailToSave] = useState(undefined);
    const [alert, setAlert] = useState({ type: 'info', text: undefined });
    const user = useCurrentUser();
    const [email, setEmail] = useState(undefined);

    useEffect(() => {
        setEmail(user ? user.email : undefined);
    }, [user]);

    const handleSubmit = (email) => { setEmailToSave(email.get('ef-email')); };
    const handleReset = () => { setEmail(user ? user.email : undefined); };

    const saveEmail = () => {
        profileService.saveEmail(emailToSave, `${window.location.origin}/${portal}/login?status=email-changed`)
            .then(() => {
                setAlert({type: 'info', text: 'Request to change email was sent.'});
            })
            .catch(() => {
                setAlert({type: 'danger', text: 'Request to change email was failed. Please try again later.'});
            })
            .finally(() => {
                setEmailToSave(undefined);
            });
        
    };

    if (!user ) {
        return null;
    }

    if (!user.email) {
        console.error('No email for user');
        return null;
    }

    return <Fragment>
        <Column md='6'>
            <FormPanel
                title={_`Change your email`}
                intro={_`Use this form to change email address you use to login to the MyPathway portal.`}
                id='email-form'
                onSubmit={handleSubmit}
                onReset={handleReset}
                submitLabel={_`Save Changes`}
                resetLabel={_`Discard Changes`}
                onChange={(id, rawValue) => { setEmail(rawValue); }}
                alertText={alert.text}
                alertType={alert.type}
            >
                <TextInput
                    label={_`Email Address:`}
                    id='ef-email'
                    value={email}
                    autofocus={true}
                    required={true}
                    validation="email"
                />
            </FormPanel>
        </Column>
        {emailToSave &&
            <ConfirmModal
                onConfirm={saveEmail}
                onClose={() => { setEmailToSave(undefined); }}>
                Are you sure? <br/>
                <br/>
                New Email is <strong>{emailToSave}</strong>
            </ConfirmModal>
        }
    </Fragment>;
};
