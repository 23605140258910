import { CompositionService } from './composition.service';
import { UserLetter } from 'models/compositions/UserLetter';
import { Composition } from 'models/Composition';
import { Email } from 'models/compositions/Email';
import { Letter } from 'models/compositions/Letter';
import { PushNotification } from 'models/compositions/PushNotification';
import { getAllUserCompositions } from 'services/composition.service';
import { SMS } from 'models/compositions/SMS';
import { HtmlLetter } from 'models/compositions/HtmlLetter';
class CommunicationsService extends CompositionService {

    get archetypeName(): string {
        return;
    }

    public getEmails = async ({ teamId, folderId }: { folderId: number; teamId: number }): Promise<Composition<Email>[]> => {
        return await getAllUserCompositions<Email>({ archetype: 'email', folderId, teamId }).catch(() => []);
    };

    public getSMS = async ({ teamId, folderId }: { folderId: number; teamId: number }): Promise<Composition<SMS>[]> => {
        return await getAllUserCompositions<SMS>({ archetype: 'sms', folderId, teamId }).catch(() => []);
    };

    public getMeshLetters = async ({ teamId, folderId }: { folderId: number; teamId: number }): Promise<Composition<UserLetter>[]> => {
        return await getAllUserCompositions<any>({ archetype: 'mesh-letter', folderId, teamId }).catch(() => []);
    };

    public getPushNotifications = async ({ teamId, folderId }: { folderId: number; teamId: number }): Promise<Composition<PushNotification>[]> => {
        return await getAllUserCompositions<any>({ archetype: 'push-notification', folderId, teamId }).catch(() => []);
    };

    public getLetters = async ({ teamId, folderId }: { folderId: number; teamId: number }): Promise<Composition<Letter>[]> => {
        return await getAllUserCompositions<any>({ archetype: 'letter', folderId, teamId }).catch(() => []);
    };
    
    public getHtmlLetters = async ({ teamId, folderId }: { folderId: number; teamId: number }): Promise<Composition<HtmlLetter>[]> => {
        return await getAllUserCompositions<any>({ archetype: 'html-letter', folderId, teamId }).catch(() => []);
    };
}

export const communicationsService = new CommunicationsService();
