import React, { FC } from 'react';
import { GridCell, GridRow } from 'common/ui/grid';
import { Link } from 'react-router';
import { DateSpan } from 'common/datetime/DateTime';

export interface GridItem {
    measure: string;
    score: string;
    diff: string;
    url: string;
    changePeriod: number;
    date: number;
}

export const ListRow: FC<{ item: GridItem }> = ({
    item
}) => {
    return (
        <GridRow>
            <GridCell field='measure'>
                <Link to={item.url}>{item.measure}</Link>
            </GridCell>
            <GridCell field='score'>
                {item.score}
            </GridCell>
            <GridCell field='date'>
                <DateSpan>{item.date}</DateSpan>
            </GridCell>
            <GridCell field='diff'>
                {item.diff}
            </GridCell>
            <GridCell field='changePeriod'>
                {item.changePeriod !== -1 ? `${item.changePeriod} days` : '-'}
            </GridCell>
        </GridRow>
    );
};
