import React, { Fragment } from 'react';
import { store } from 'store';
import PageTitle from 'page-title';
import _ from 'services/i18n';
import { TabNavigator, NavTab } from 'ui/tab/navigator';
import { Row, Column } from 'ui';
import PatientBar from 'components/patient-bar';
import { Link } from 'react-router';
import { folderService } from 'services/folder.service';
import { teamPreferencesService } from 'services/team-preferences.service';
import { patientsService } from 'services/patients.service';
import { clearPatientData } from 'actions/patient.actions';
import { terService } from 'services/ter.service';
import { ConfirmModal } from 'ui';
import Modal from 'ui/modal';
import { useActivePathways } from 'features/pathway-labels/useActivePathways';
import './patient.less';
import { useCompositionList } from 'common/useCompositionList';
const RegistrationStatusLabels = {
    [patientsService.STATUS.REGISTERED]: 'Registered',
    [patientsService.STATUS.INVITED]: 'Invited',
    [patientsService.STATUS.PENDING]: 'Pending',
};
import { PatientContext } from 'pages/clinical_portal/folder/_folderId/patient/PatientContext';
import { ReferralsProvider } from 'features/ReferralsProvider';
import { getPersonPrimaryIdentifierFromUserFolder } from 'common/personPrimaryIdentifierHelpers';
import { EditDemographicsButton } from 'features/patients/edit-demographics/EditDemographicsButton';
import { browserHistory } from 'react-router';


export default class PatientShowPage extends React.Component {

    constructor(props) {
        super(props);
        this.baseRoute = `/clinical_portal/folder/${this.props.params.folderId}/patient`;
        PageTitle.setTitle('Patient Info | MyPathway Clinical Portal');

        this.state = {
            folderDetails: {},
            isLoadingTeamPreferences: true,
            teamPreferences: undefined,
            newEmail: '',
            changeEmailConfirmation: false,
            notification: {},
            notificationModal: false
        };

    }

    componentDidMount() {
        this.loadFolderDetails();
    }

    componentWillUnmount() {
        store.dispatch(clearPatientData());
    }
    componentDidUpdate() {
        if (
            this.props.location.pathname === this.baseRoute &&
            !this.state.isLoadingTeamPreferences &&
            this.state.teamPreferences
        ) {
            this.navigateToTab(this.state.teamPreferences.portal);
        }
    }
    navigateToTab(portalTeamPrefs) {
        if (portalTeamPrefs.show_dashboard) {
            browserHistory.replace(`${this.baseRoute}/dashboard`);
        } else if (portalTeamPrefs.show_pathway) {
            browserHistory.replace(`${this.baseRoute}/pathway`);
        } else {
            browserHistory.replace(`${this.baseRoute}/appointments`);
        }
    }

    onSubmitEmail() {
        const email = this.state.newEmail;
        this.setState({
            newEmail: '',
            changeEmailConfirmation: false,
        });
        return terService.createTer({ action: 'changeEmail', data: { email } })
            .then(() => {
                this.setState({
                    notification: {
                        title: 'Request was sent',
                        text: 'Request for change email was sent'
                    },
                    notificationModal: true
                });
            })
            .catch(() => {
                this.setState({
                    notification: {
                        title: 'Request was failed',
                        text: 'Request for change email was failed. Please try again later.'
                    },
                    notificationModal: true
                });
            });
    }

    closeChangeEmailModal() {
        this.setState({
            changeEmailConfirmation: false
        });
    }

    confirmChangeEmail(email) {
        this.setState({
            newEmail: email,
            changeEmailConfirmation: true
        });
        return Promise.resolve();
    }

    loadFolderDetails() {
        if (this.props.params && this.props.params.folderId) {
            folderService.getFolderById({ folderId: this.props.params.folderId })
                .then((folder) => {
                    this.setState({
                        folderDetails: folder
                    });
                })
                .catch((e) => {
                    console.error('Error during loading folderDetails data', e);
                });
        }

        teamPreferencesService.getFirst({})
            .catch(() => {
                this.setState({
                    isLoadingTeamPreferences: false,
                });

                return teamPreferencesService.defaultProperties;
            })
            .then((teamPreferences) => {
                this.setState({
                    isLoadingTeamPreferences: false,
                    teamPreferences,
                });
            });
    }

    getPatientFullname({ given_name: givenName = '', middle_name: middleName = '', family_name: familyName = '', prefix = '', suffix = '' } = {}) {
        return `${prefix} ${givenName} ${middleName} ${familyName} ${suffix}`.trim();
    }

    getRegistrationStatus(status) {
        return RegistrationStatusLabels[status] || null;
    }

    openChangeEmailModal() {
        this.setState({
            changeEmailModal: true
        });
    }

    submitChangeEmail(email) {
        return terService.createTer({ action: 'changeEmail', data: { email } });
    }

    render() {

        const {
            demographicsData,
            teamId,
        } = this.props;
        const {
            teamPreferences,
            folderDetails,
        } = this.state;


        if (!teamPreferences) {
            return null;
        }
        const humanReadableLabels = teamPreferences.primary_identifier && teamPreferences.primary_identifier.human_readable_labels;

        const patientFullName = this.getPatientFullname(demographicsData.preferred_name)
            || this.getPatientFullname(demographicsData.name);

        // Ensure the child components have access to the folder and
        //  demographics information.
        let bodyContent;
        if (this.props.children) {
            bodyContent = React.cloneElement(this.props.children, {
                teamId: this.props.teamId,
                folderId: this.props.params.folderId,
                demographicsData: this.props.demographics
            });
        }

        const navTabs = [];
        teamPreferences.portal.show_dashboard && navTabs.push(
            <NavTab
                title={_`Dashboard`}
                key={0}
                route={`${this.baseRoute}/dashboard`}
                prefixMatch={true} />
        );
        teamPreferences.portal.show_pathway && navTabs.push(
            <NavTab
                title={_`Pathway`}
                key={1}
                route={`${this.baseRoute}/pathway`}
                prefixMatch={true} />
        );
        teamPreferences.show_appointments && navTabs.push((
            <NavTab title={_`Appointments`}
                key={2}
                route={`${this.baseRoute}/appointments`}
                prefixMatch={true} />
        ));
        navTabs.push((
            <NavTab title={_`PROMs`}
                key={3}
                route={`${this.baseRoute}/questionnaires`}
                prefixMatch={true} />
        ));
        navTabs.push((
            <NavTab title={_`Resources`}
                key={4}
                route={`${this.baseRoute}/resources`}
                prefixMatch={true} />
        ));
        teamPreferences.show_messages && navTabs.push((
            <NavTab title={_`Messages`}
                key={5}
                route={`${this.baseRoute}/messages`}
                prefixMatch={true} />
        ));
        teamPreferences.show_referrals && navTabs.push((
            <NavTab title={_`Referrals`}
                key={6}
                route={`${this.baseRoute}/referrals`}
                prefixMatch={true} />
        ));
        teamPreferences.show_goals && navTabs.push((
            <NavTab title={_`Goals`}
                key={7}
                route={`${this.baseRoute}/goals`}
                prefixMatch={true} />
        ));
        navTabs.push((
            <NavTab title={_`Settings`}
                key={8}
                route={`${this.baseRoute}/settings`}
                prefixMatch={true} />
        ));
        teamPreferences.show_communications_tab && navTabs.push((
            <NavTab title={_`Communications`}
                key={9}
                route={`${this.baseRoute}/communications`}
                prefixMatch={true} />
        ));
        teamPreferences.show_results_page_v2 && navTabs.push((
            <NavTab title={_`Graphs`}
                key={10}
                route={`${this.baseRoute}/graphs`}
                prefixMatch={true} />
        ));
        teamPreferences.show_results_tab && navTabs.push((
            <NavTab title={_`Graphs`}
                key={11}
                route={`${this.baseRoute}/results`}
                prefixMatch={true} />
        ));
        teamPreferences.show_user_notes && navTabs.push((
            <NavTab title={_`User Notes`}
                key={12}
                route={`${this.baseRoute}/user-notes`}
                prefixMatch={true} />
        ));
        teamPreferences.show_observations && navTabs.push((
            <NavTab title={_`Observations`}
                key={12}
                route={`${this.baseRoute}/observations`}
                prefixMatch={true} />
        ));
        teamPreferences.portal.show_devices_tab && navTabs.push((
            <NavTab title={_`Devices`}
                key={4}
                route={`${this.baseRoute}/devices`}
                prefixMatch={true} />
        ));

        const registrationStatus = this.state.folderDetails ? this.getRegistrationStatus(this.state.folderDetails.status) : null;
        return (
            <PatientContextProviders teamId={this.props.teamId} folderId={this.props.params.folderId}>
                <Row>
                    <Column sm="6" md="7" lg="8">
                        <ul className="breadcrumb">
                            <li>
                                <Link to='/clinical_portal'>Overview</Link>
                            </li>
                            <li>
                                <Link to='/clinical_portal/patients'>Find Patient</Link>
                            </li>
                            <li className="active">{patientFullName}</li>
                        </ul>
                    </Column>
                </Row>
                <Row>
                    <Column sm="12">
                        <PatientBar
                            patientData={this.props.demographicsData}
                            folderData={this.state.folderDetails}
                            onSubmitEmail={(email) => this.confirmChangeEmail(email)}
                            personPrimaryIdentifier={getPersonPrimaryIdentifierFromUserFolder(folderDetails, humanReadableLabels, demographicsData)}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column sm="12">
                        <EditDemographicsButton
                            dataForModal={this.props.demographicsData}
                            personPrimaryIdentifier={getPersonPrimaryIdentifierFromUserFolder(folderDetails, humanReadableLabels, demographicsData)}
                            folderIdForModal={this.props.folderId} />
                    </Column>
                </Row>
                <Row className="patient-details__tab-title">
                    <Column sm="12" md="6">
                        <h1>Patient Overview</h1>
                    </Column>
                    <Column sm="12" md="6">
                        <Row>
                            <Column sm="12" md="6">
                                {registrationStatus &&
                                    <h3>Status: {registrationStatus}</h3>}
                            </Column>
                            <Column sm="12" md="6">
                                <AssignedPathways folderId={folderDetails.id} teamId={teamId} />
                            </Column>
                        </Row>
                    </Column>
                </Row>
                <Row>
                    <Column sm="12">
                        <TabNavigator
                            currentRoute={this.props.location.pathname}
                            tabPosition='top'
                            tabStyle='tab'>
                            {navTabs}
                        </TabNavigator>
                    </Column>
                </Row>
                <Row>
                    <Column sm="12">
                        {bodyContent}
                    </Column>
                </Row>

                {this.state.changeEmailConfirmation && (
                    <ConfirmModal
                        onConfirm={() => this.onSubmitEmail()}
                        onClose={() => this.closeChangeEmailModal()}>
                        Are you sure? <br />
                        <br />
                        New Email is <strong>{this.state.newEmail}</strong>
                    </ConfirmModal>
                )}
                {this.state.notificationModal && (
                    <Modal title={this.state.notification.title}
                        type="info"
                        buttons={[
                            { type: 'callback', callback: () => this.setState({ notificationModal: false }), label: 'Ok' }
                        ]}
                        onClose={() => this.setState({ notificationModal: false })}
                    >
                        {this.state.notification.text}
                    </Modal>
                )}

            </PatientContextProviders>
        );
    }
}

function AssignedPathways({ teamId, folderId }) {
    const pathways = useActivePathways({ teamId, folderId });
    return (
        <Fragment>
            {pathways.length ? (
                <Fragment>
                    <h3>Pathway:</h3>
                    <div className="available-pathway-labels">
                        {pathways.map(({ name }, key) => <span key={key} className={'pathway-name'}>{name}</span>)}
                    </div>
                </Fragment>
            ) :
                null
            }
        </Fragment>
    );
}

function PatientContextProviders({
    teamId,
    folderId,
    children,
}) {
    return (
        <PatientContext.Provider value={{ teamId, folderId }}>
            <ReferralsProvider folderId={folderId} teamId={teamId} >
                {children}
            </ReferralsProvider>
        </PatientContext.Provider>
    );
}
