import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';

import {
    ViewQuestionnaireCompositionComponent,
    ViewQuestionnaireTemplateComponent
} from 'components/questionnaire-template';

class QuestionnaireTemplateViewPage extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Viewing Questionnaire Template | PHR Admin Portal`;
    }

    render () {

        const Component = typeof this.props.location.query.composition !== 'undefined' ?
            ViewQuestionnaireCompositionComponent :
            ViewQuestionnaireTemplateComponent;

        return (<Component templateId={this.props.params.templateId || 0} {...this.props} />);
    }
}

export default QuestionnaireTemplateViewPage;
