import React, { useState, ReactElement } from 'react';
import _ from 'services/i18n';
import { Link } from 'react-router';
import { LocationShape } from 'react-router/lib/PropTypes';

import { usePatientsString } from 'common/usePatientsString';
import { useCurrentTeam } from 'common/useCurrentTeam';
import { usePrivacyNotices } from './usePrivacyNotices';
import { PrivacyNoticeComposition } from './PrivacyNotice.archetype';

import HeadingDoc from 'ui/heading-doc';
import { Grid, GridHeader, GridHeaderRow, GridHeaderCell, GridBody, GridRow, GridCell } from 'common/ui/grid';
import { Checkbox} from 'common/ui/checkbox/Checkbox';
import { Row, Column } from 'ui';

export function PrivacyNoticeList({
    location
}: {
    location: LocationShape
}): ReactElement {
    const [, patientsString] = usePatientsString();
    const team = useCurrentTeam();
    const [privacyNotices, isLoading] = usePrivacyNotices(team);
    const [isShowAllVersion, setIsShowAllVersion] = useState<boolean>(false);

    const hasNewerVersion = (privacyNotice: PrivacyNoticeComposition) => privacyNotices.some(
        notice => notice.service_name === privacyNotice.service_name
        && notice.content.language === privacyNotice.content.language
        && notice.content.version > privacyNotice.content.version
    );

    const filteredList = privacyNotices.filter((privacyNotice) => isShowAllVersion || !hasNewerVersion(privacyNotice));

    const toggleIsShowAllVersion = () => setIsShowAllVersion(!isShowAllVersion);

    return <div className="page">
        <HeadingDoc title="Privacy Policy Manager">
            The privacy policy manager allows you to create and update privacy policies for team,
            that can be agreed to by {patientsString}.
        </HeadingDoc>

        <Row spacer>
            <Column md="12">
                <Link to={`${location.pathname}/add`}
                    className="btn btn-success">{_`Create Privacy Policy`}</Link>
            </Column>
        </Row>

        <Checkbox
            value={isShowAllVersion}
            onChange={({target: {value}}) => setIsShowAllVersion(value)}
            title="Show all versions"
        ><span onClick={toggleIsShowAllVersion}>{_`Show all versions`}</span></Checkbox>
        {isLoading && <h1>Loading...</h1>}
        <Grid>
            <GridHeader>
                <GridHeaderRow>
                    <GridHeaderCell>{_`Name`}</GridHeaderCell>
                    <GridHeaderCell>{_`Language`}</GridHeaderCell>
                    <GridHeaderCell>{_`Version`}</GridHeaderCell>
                    <GridHeaderCell>{_`Actions`}</GridHeaderCell>
                </GridHeaderRow>
            </GridHeader>
            <GridBody>
                {filteredList.map(privacyNotice => {
                    const {content} = privacyNotice;
                    return <GridRow key={privacyNotice.uuid}>
                        <GridCell><Link to={`${location.pathname}/${privacyNotice.uuid}`}>{content.name}</Link></GridCell>
                        <GridCell>{content.language}</GridCell>
                        <GridCell>{content.version}</GridCell>
                        <GridCell>
                            <Link to={`${location.pathname}/add/${privacyNotice.uuid}`} className="btn btn-default">Add new version</Link>
                        </GridCell>
                    </GridRow>;
                })}
            </GridBody>
        </Grid>
    </div>;
}
