import React from 'react';

/**
 * A generic pagination component
 */
export default class PaginationViewComponent extends React.Component {

    /**
     * @param {Object} props - Passed in properties
     * @param {Number} props.pageCount - Total number of pages
     * @param {Number} props.currentPage - Current page
     * @param {Function} props.onChange - Callback on page change
     */
    constructor (props) {
        super(props);
        this.state = {
            pageCount: Math.ceil(props.pageCount)
        };
    }

    componentWillReceiveProps (props) {
        this.setState({
            pageCount: Math.ceil(props.pageCount)
        });
    }

    /**
     * @private renderPages
     */
    renderPages () {
        let page = this.props.currentPage,
            lastPage = this.state.pageCount,
            startPage = page - 4,
            firstPage = false,
            endPage,
            pages = [],
            i,
            activeClass;

        startPage = page - 4;

        if (startPage < 1) {
            startPage = 1;
        }

        if (startPage > 1) {
            firstPage = 1;
        }

        endPage = startPage + 8;

        if (endPage > lastPage) {
            endPage = lastPage;
            startPage = endPage - 8;
        }

        if (startPage < 1) {
            startPage = 1;
            firstPage = false;
        }

        if (endPage == lastPage) {
            lastPage = false;
        }

        if(firstPage){
            pages.push((
                <li key={firstPage} onClick={this.props.onChange.bind(null, firstPage)}>
                    <span className="glyphicon glyphicon-chevron-left"></span>
                </li>
            ));
        }

        for (i = startPage; i <= endPage; i++) {
            if (i == this.props.currentPage) {
                activeClass = 'active';
            } else {
                activeClass = '';
            }

            pages.push(<li key={i} className={activeClass} onClick={this.props.onChange.bind(null, i)}><span>{i}</span></li>);
        }

        if(lastPage){
            pages.push(<li key={lastPage} onClick={this.props.onChange.bind(null, lastPage)}><span className="glyphicon glyphicon-chevron-right"></span></li>);
        }

        return pages;
    }

    /**
     * Render the component
     */
    render () {
        if (this.state.pageCount == 1) {
            return null;
        }
        return (
            <div className="pagination-component">
                <ul className="pagination">
                    {this.renderPages()}
                </ul>
            </div>
        );
    }
}
export const PaginationView = PaginationViewComponent;
