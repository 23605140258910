import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import HeadingDoc from 'ui/heading-doc';
import CollectionContainer from 'features/resource/CollectionContainer/CollectionContainer';

export default class CollectionViewPage extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Resource Manager | PHR Admin Portal`;
    }

    render () {
        return (
            <div className="page">
                <HeadingDoc title="Resource Manager">
                    The resource manager section allows you to view and create resources and categories. Each resource must be created in a category. A resource can be a website, video, link etc. Once you have created a resource, it can be assigned to a {this.$p()} for them to use on their app.
                </HeadingDoc>
                <CollectionContainer 
                    editResourceId={this.props.params.id}
                    categoryId={this.props.params.categoryId}
                    currentUser={this.getUserName()}
                />
            </div>
        );
    }
}
