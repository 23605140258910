import React, { Component } from 'react';
import {IndexLink} from 'react-router';
import lodash from 'lodash'
import BackgroundImageChecker from 'components/background-image-checker';

export default class UserProfileMenu extends Component<any, any>{
  constructor (props) {
    super(props);
    this.state = {
      photoUrl: props.user?.photoUrl ? `url(${this.props.user.photoUrl}?random=${Date.now()})` : ''
    };
    this.onProfileClick = this.onProfileClick.bind(this);
  }

  renderMenu(){
    if (!this.props.menu || !this.props.menu.length) {
      return null;
    }

    const items = this.props.menu.map(({label, link, onClick}, i) =>{
      if (link) {
        return <li key={i}><IndexLink to={link} activeClassName="active">{label}</IndexLink></li>
      }
      if (onClick) {
        return <li key={i}><a href="javascript:void(0)" onClick={onClick}>{label}</a></li>
      }
      return null;
    });

    return (
        <ul className="clearfix">
          {items}
        </ul>
    )
  }

  onProfileClick(){
    this.props.onProfileClick && this.props.onProfileClick();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.user.photoUrl !== prevProps.user.photoUrl) {
      this.setState({photoUrl: this.props.user.photoUrl ? `url(${this.props.user.photoUrl}?random=${Date.now()})` : ''})
    }
  }

  render(){
    const userName = (lodash.get(this.props,'user.name','') || '').trim() || 'Not specified';
    return (
        <div className={`user-profile-menu clearfix ${this.props.className || ''}`}>
          <div className="user-profile-menu__name">
            {userName}
          </div>
          <div className="user-profile-menu__photo-menu">
            <BackgroundImageChecker imageUrl={this.state.photoUrl}>
                    <span className="user-profile-menu__photo-img"
                          onClick={this.onProfileClick}>
                    </span>
            </BackgroundImageChecker>
            <div className="user-profile-menu__dropdown-menu clearfix">
              {this.props.upMenuContent &&
              <div className="user-profile-menu__up-content clearfix">
                {this.props.upMenuContent}
              </div>
              }
              {this.renderMenu()}
            </div>
          </div>
        </div>
    )
  }
};
