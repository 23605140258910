import React from 'react';
import BasePage from '../../../../portal-components/components/page/base';
import _ from 'services/i18n';

import PasswordForm from '../../../../portal-components/components/user/profile/password-form';

/**
 * Extended Page Component for clinical_portal/documents
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Password` + ' | ' + _`User Profile` + ' | ' + _`PHR Clinical Portal`;
    }

    render () {
        return (<PasswordForm {...this.props} />);
    }
}
