import {
    GroupedDataOption,
    GroupedDepartmentDataOptions,
    SortedDepartmentData
} from 'features/department/department.interface';
import { useMemo } from 'react';
import { createGroupedDepartmentDataOptionList } from 'features/department/department.utils';

export function useGroupedDepartmentDataOptionList<T>(
    data: SortedDepartmentData<T>,
    getLabel: (d: T) => string,
    options?: GroupedDepartmentDataOptions
): [optionGroupedList: GroupedDataOption<T>[], optionCount: number] {
    return useMemo<[GroupedDataOption<T>[], number]>(
        () => [
            createGroupedDepartmentDataOptionList(data, getLabel, options),
            data.current.length + data.other.length
        ],
        [data, getLabel, options]
    );
}
