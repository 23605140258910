import React, { Fragment, useMemo } from 'react';
import { useI18N } from 'common/i18n/useI18N';
import { usePatientsString } from 'common/usePatientsString';
import { Grid, GridHeader, GridHeaderRow, GridHeaderCell, GridBody, SortOrder } from 'common/ui/grid';
import PaginationView from 'components/pagination';
import { useTableFilter } from 'common/ui/grid/useTableFilter';
import { defaultNumberSort } from 'common/ui/grid/helpers';
import { useTableSort } from 'common/ui/grid/useTableSort';
import { usePagination } from 'common/ui/usePagination';
import { usePreparedReferrals } from './usePreparedReferals';
import { ReferralGridRow } from './ReferralGridRow';
import { useTeamPreferences } from 'common/useTeamPreferences';

const initialSortState = {
    date: SortOrder.DESC,
};

const filterSelector = {
    date: item => item.dateTimeFilter,
    triageDecision: item => item.triageDecisionSortFilter,
    pathwayNames: item => item.pathwaySortAndFilter,

};
const sortSelector = {
    date: item => item.dateTimeSort,
    triageDecision: item => item.triageDecisionSortFilter,
    pathwayNames: item => item.pathwaySortAndFilter,
};
const sortHandlers = {
    date: defaultNumberSort,
};

export const ReferralsGrid = ({
    loading,
    referrals,
    getTriageDecisionForList,
    getSendLetterButton,
    getDischargeButton,
}) => {
    const _ = useI18N();
    const [patientString] = usePatientsString();
    const teamPreferences = useTeamPreferences();

    const isShowDischargeButton = !!getDischargeButton;

    const preparedItems = usePreparedReferrals(referrals, {
        getTriageDecisionForList,
        getSendLetterButton,
        getDischargeButton,
    });

    const { onFilter, filteredItems, filterState } = useTableFilter({
        items: preparedItems,
        selectors: filterSelector,
    });

    const { onSort, sortedItems, sortState } = useTableSort({
        items: filteredItems,
        initialSortState,
        selectors: sortSelector,
        sortHandlers,
    });

    const { items: paginatedItems, total, onPageChange, page } = usePagination({
        items: sortedItems,
        perPage: 8,
        currentPage: 1
    });

    if (loading) {
        return (
            <div>
                <p><em>{_`Loading referrals please wait...`}</em></p>
            </div>
        );
    }

    if (referrals.length === 0) {
        return (
            <div>
                <div>{_`There are currently no referrals for this ${patientString}`}</div>
            </div>
        );
    }

    return (
        <Fragment>
            <Grid
                onSort={onSort}
                onFilter={onFilter}
                sortOrder={sortState}
                filterState={filterState}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'pathwayNames'}
                        >{_`Pathway`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'ubrn'}
                        >{_`UBRN`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'status'}
                        > {_`Status`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'triageDecision'}
                        ><span className="no-wrap">{_`Triage Decision`}</span></GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'referringServiceName'}
                        ><span className="no-wrap">{_`Referred By`}</span></GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'referredToServiceName'}
                        ><span className="no-wrap">{_`Referred To`}</span></GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'date'}
                        ><span className="no-wrap">{_`Created At`}</span></GridHeaderCell>
                        <GridHeaderCell
                            sortable={false}
                            filterable={false}
                        ><span className="no-wrap">{_`Invitation Letter`}</span></GridHeaderCell>
                        {isShowDischargeButton && teamPreferences && !teamPreferences.hide_discharge_button && (
                            <GridHeaderCell
                                sortable={false}
                                filterable={false}>
                                    &nbsp;
                            </GridHeaderCell>
                        )}
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {paginatedItems.map(item => (
                        <ReferralGridRow
                            teamPreferences={teamPreferences}
                            key={item.key}
                            referralListItem={item}
                            isShowDischargeButton={isShowDischargeButton}
                        />
                    ))}
                </GridBody>
            </Grid>
            <PaginationView
                currentPage={page}
                pageCount={total}
                onChange={onPageChange}
            />
        </Fragment>
    );
};

export const ReferralsGridWithCategories = ({
    loading,
    referrals,
    getTriageDecisionForList,
    getSendLetterButton,
    getDischargeButton,
    categoryGroups,
}) => {
    const categoryNames = useMemo(() => categoryGroups.map(({ name }) => name), [categoryGroups]);

    const categoryReferrals = useMemo(() => {
        return categoryGroups.map(({
            name,
            displayName
        }) => {
            const referralsByCategory = name === 'default'
                ? referrals.filter(({ content: { category } }) => category === name || categoryNames.indexOf(category) === -1)
                : referrals.filter(({ content: { category } }) => category === name);

            return {
                name,
                displayName,
                referralsByCategory,
            };
        });
    }, [categoryGroups, referrals, categoryNames]);

    return categoryReferrals.map(({
        name,
        displayName,
        referralsByCategory,
    }) => {
        if (!referralsByCategory.length) { return null; }

        return (
            <Fragment key={name}>
                <h3>{displayName}</h3>
                <ReferralsGrid
                    loading={loading}
                    referrals={referralsByCategory}
                    getTriageDecisionForList={getTriageDecisionForList}
                    getSendLetterButton={getSendLetterButton}
                    getDischargeButton={getDischargeButton}
                ></ReferralsGrid>
            </Fragment>
        );
    });
};
