import React, { useEffect, useState } from 'react';
import { simpleQuestionnaireService } from './simpleQuestionnaireContentService';
import { CreateSimpleQuestionnaire } from './CreateSimpleQuestionnaire';
import { SimpleQuestionnaire } from 'models/compositions/SimpleQuestionnaire';

export const EditSimpleQuestionnaire = (): JSX.Element => {
    const [questionnaire, setQuestionnaire] = useState<SimpleQuestionnaire>();
    const [questionnaireError, setQuestionnaireError] = useState<string>(null);
    const urlLocation = window.location.pathname;
    const id = urlLocation.slice(urlLocation.lastIndexOf('/') + 1);

    useEffect(() => {
        getQuestionnaire(id);
    }, [id]);

    const getQuestionnaire = (id): void => {
        const location = 'general_content/simple-questionnaire';
        simpleQuestionnaireService.getContentById(id, location).then((res) => {
            if (res.status === 200) {
                setQuestionnaire(res.message);
            }
            if (res.status !== 200) {
                setQuestionnaireError(res.response);
            }
        });
    };

    return (
        <div>
            {questionnaireError && (
                <h3>Problem loading questionnaire: {questionnaireError}</h3>
            )}
            <CreateSimpleQuestionnaire questionnaireState={questionnaire} />
        </div>
    );
};

