import React, { useMemo, Fragment, FC } from 'react';
import Select, { GroupTypeBase } from 'react-select';
import { uuid } from 'uuidv4';
// components
import AdvancedSearchCriteriaAndOutputs from 'features/patients/advanced-search/AdvancedSearchCriteriaAndOutputs';
// hooks
import {
    useAdvancedSearchChildren,
    useAdvancedSearchSubqueryJoinModeDescriptions,
    useAdvancedSearchSubqueryJoinModeSetting,
    useChildDocumentConfigs,
    useDocumentConfig,
} from 'features/patients/advanced-search/AdvancedSearchSetupHooks';
// utils
import {
    AdvancedSearchDocumentConfig,
    AdvancedSearchQueryDefinition,
    getParentLink,
    makeQueryDefinitionFromConfig,
    groupDocumentConfigsByClassification,
    isFieldAPseudoDocument,
} from 'models/AdvancedSearchDefinition';
// styles
// TODO check how to split classes
import '../AdvancedSearchQueryDefinition.scss';
import { Validator } from 'common/ui/validation/NestedComponentValidator';

function getRemainingChildDocumentConfigs(
    documentType: string,
    allChildDocumentConfigs: AdvancedSearchDocumentConfig[],
    currentChildren: AdvancedSearchQueryDefinition[],
): AdvancedSearchDocumentConfig[] {
    return allChildDocumentConfigs.filter((config) => {
        const parentLink = getParentLink(config, documentType);
        if (parentLink.cardinality == 'single') {
            return (
                currentChildren.find(
                    (child) => child.documentType == config.documentType,
                ) == null
            );
        }
        return true;
    });
}

type AdvancedSearchQueryDefinerProps = {
    documentConfigs: AdvancedSearchDocumentConfig[];
    queryDefinition: AdvancedSearchQueryDefinition;
    onRemove: () => void;
    isRootQuery: boolean;
    validator: Validator;
    customTitle?: string;
    parentEntityName?: string;
};

export const AdvancedSearchQueryDefiner: FC<AdvancedSearchQueryDefinerProps> = (
    props,
) => {
    const documentType = props.queryDefinition.documentType;

    const myConfig = useDocumentConfig(props.documentConfigs, documentType);
    const childDocumentConfigs = useChildDocumentConfigs(
        props.documentConfigs,
        myConfig,
    );

    const [children, addChild, removeChild] = useAdvancedSearchChildren(
        props.queryDefinition,
    );
    // Wrap up the children with a set of UUIDs which only changes when the list of children itself changes.
    // Use UUID as react key when building children. Prevents annoying display failures when e.g. removing a child
    // - if using array index, if there's a later child which drops down to fill the gap, the redraw doesn't
    // kick in properly because the key for the item has changed to match the one you just removed, and sadness ensues.
    const childrenWithPreBuiltKeys = useMemo(() => {
        return children.map((child) => {
            return { child: child, key: uuid() };
        });
    }, [children]);

    const availableChildDocumentConfigs = getRemainingChildDocumentConfigs(
        documentType,
        childDocumentConfigs,
        children,
    );

    const childDocumentClassification = groupDocumentConfigsByClassification(
        availableChildDocumentConfigs,
    );

    const addNewChildFromConfig = (config: AdvancedSearchDocumentConfig) => {
        addChild(makeQueryDefinitionFromConfig(config));
    };

    const showCriteriaAndOutputs = !!myConfig.fields.find(
        (field) => !isFieldAPseudoDocument(field),
    );
    const indexToConnectorLabel = (index: number) =>
        index == 0 && !showCriteriaAndOutputs ? '' : 'and...';

    return (
        <div className={'advanced-search-query-request'}>
            <AdvancedSearchQueryRow
                myConfig={myConfig}
                parentEntityName={props.parentEntityName}
                queryDefinition={props.queryDefinition}
                onRemove={props.onRemove}
                isRootConfig={props.isRootQuery}
                customTitle={props.customTitle}
            />
            {showCriteriaAndOutputs && (
                <AdvancedSearchCriteriaAndOutputs
                    documentConfig={myConfig}
                    queryDefinition={props.queryDefinition}
                    validator={props.validator}
                />
            )}
            {childrenWithPreBuiltKeys.map((child, index) => (
                <Fragment key={child.key}>
                    <label>{indexToConnectorLabel(index)}</label>

                    <AdvancedSearchQueryDefiner
                        documentConfigs={props.documentConfigs}
                        parentEntityName={props.isRootQuery ? null : myConfig.name}
                        queryDefinition={child.child}
                        onRemove={() => removeChild(index)}
                        isRootQuery={false}
                        validator={props.validator}
                    />
                </Fragment>
            ))}
            {childDocumentClassification.length > 0 && (
                <ChildDocumentTypeSelector
                    childDocumentClassification={childDocumentClassification}
                    addNewChildFromConfig={addNewChildFromConfig}
                    placeholder={
                        children.length == 0 && !showCriteriaAndOutputs
                            ? 'Filter by...'
                            : 'and...'
                    }
                />
            )}
        </div>
    );
};

const JoinModeSelector: FC<{
    queryDefinition: AdvancedSearchQueryDefinition;
    childEntityName: string;
    parentEntityName?: string;
}> = (props) => {
    const [joinMode, setJoinMode] = useAdvancedSearchSubqueryJoinModeSetting(props.queryDefinition);
    const joinModeDescriptions = useAdvancedSearchSubqueryJoinModeDescriptions(props.childEntityName, props.parentEntityName);
    const joinModeSelection = joinModeDescriptions.find(display => display.value === joinMode);
    return (
        <Select
            id={'join-mode-selector'}
            value={joinModeSelection}
            options={joinModeDescriptions}
            onChange={selection => setJoinMode(selection.value)}
            className={'react-select-input-container'}
            classNamePrefix='filter-select'
        />
    );
};

type AdvancedSearchQueryRowProps = {
    myConfig: AdvancedSearchDocumentConfig;
    parentEntityName?: string;
    queryDefinition: AdvancedSearchQueryDefinition;
    onRemove: () => void;
    isRootConfig: boolean;
    customTitle?: string;
};

const AdvancedSearchQueryRow: FC<AdvancedSearchQueryRowProps> = (props) => {
    return (
        <div className={'advanced-search-query-row'}>
            {!props.isRootConfig && (
                <JoinModeSelector
                    queryDefinition={props.queryDefinition}
                    childEntityName={props.myConfig.name}
                    parentEntityName={props.parentEntityName}
                />
            )}
            <div className={'push'} />
            {!props.isRootConfig && (
                <button
                    type="button"
                    className="btn btn-xs btn-primary glyphicon glyphicon-remove"
                    onClick={props.onRemove}
                />
            )}
        </div>
    );
};

type ChildDocumentTypeSelectorProps = {
    childDocumentClassification: GroupTypeBase<{
        label: string;
        value: AdvancedSearchDocumentConfig;
    }>[];
    addNewChildFromConfig: (config: AdvancedSearchDocumentConfig) => void;
    placeholder: string;
};

const ChildDocumentTypeSelector: FC<ChildDocumentTypeSelectorProps> = (
    props,
) => {
    return (
        <Select
            value={null}
            options={props.childDocumentClassification}
            onChange={(selection: {
                label: string;
                value: AdvancedSearchDocumentConfig;
            }) => props.addNewChildFromConfig(selection.value)}
            placeholder={props.placeholder}
            className={'react-select-input-container'}
            classNamePrefix={'react-select-input'}
        />
    );
};
