import { CompositionService } from './composition.service';

class AppointmentsService extends CompositionService {

    get archetypeName(): string {
        return 'appointment';
    }
}

export const appointmentsService = new AppointmentsService();
