import React, { useState, Fragment, useRef, useEffect, useMemo } from 'react';
import _ from 'services/i18n';
import { useForm } from 'common/form/useForm';
import { ModalDialog } from 'common/modalDialog';
import { nhsValidator, clearNHSNumber, formatNHSNumber } from 'common/form/nhsNumber';
import { terService } from 'services/ter.service';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { apiV2Service } from 'services/api-v2.service';
import { DataStore } from 'services/data-store';
import { extractPrimaryIdentifierNamespaceAndLabel } from 'services/team-preferences.service';
import { Checkbox} from 'common/ui/checkbox/Checkbox';
import DateInput from 'components/form/date-input';
import moment from 'moment-timezone';
import { standardDateTimeFormats } from 'common/datetime/convertToDate';

let editDemographicsState = {};

const editDemographicsSchema = {
    givenName: {
        required: true,
    },
    middleName: {},
    familyName: {
        required: true,
    },
    namespace: {},
    isNeedToChangeNamespace: {},
    primaryIdentifier: {
        required: true,
    },
    dateOfBirth: {
        required: true,
    },
};

const nhsFieldRules = {
    rules: [
        (value) => {
            const clearedValue = clearNHSNumber(value);
            return (clearedValue.length === 10) ? false : _`Wrong NHS Number`;
        },
        (value) => {
            const clearedValue = clearNHSNumber(value);
            return nhsValidator(clearedValue) ? false : _`Wrong NHS Number`;
        }
    ],
    format: (value) => formatNHSNumber(value)
};

export const EditDemographicsModalDialog = ({
    givenName,
    middleName,
    familyName,
    namespace,
    primaryIdentifier,
    dateOfBirth,
    folderId,
    onClose
}) => {
    const [formError, setFormError] = useState('');
    const [isLoadingMessage, setisLoadingMessage] = useState(false);
    const { primary_identifier: teamPrimaryIdentifier } = useTeamPreferences();

    let { namespace: teamPrimaryIdentifierNamespace, label: teamPrimaryIdentifierLabel } = extractPrimaryIdentifierNamespaceAndLabel(teamPrimaryIdentifier);
    editDemographicsState = {
        givenName: { value: givenName, error: '' },
        middleName: { value: middleName, error: '' },
        familyName: { value: familyName, error: '' },
        namespace: {value : primaryIdentifier.namespace,  error: ''},
        isNeedToChangeNamespace: {value: false, error: ''},
        primaryIdentifier: { value: primaryIdentifier.value, error: '' },
        dateOfBirth: { value: dateOfBirth, error: '' },
    };

    const formattedNamespace = editDemographicsState.namespace.value.toLowerCase();

    if (formattedNamespace === 'nhs_number' || formattedNamespace === 'unverified_nhs_number'
        || formattedNamespace === 'temporary_id') {
        editDemographicsSchema.primaryIdentifier = {
            required: true,
            ...nhsFieldRules
        };
    } else {
        editDemographicsSchema.primaryIdentifier = {
            required: true,
        };
    }

    async function checkPatientsIdentifier(patientIdentifierValue) {
        try {
            const teamId = (DataStore.get("me.currentRole") || {}).teamId || 0,
                roleUuid = (DataStore.get("me.currentRole") || {}).uuid || 0;

            const searchParam = {
                status: 'registered' | 'invited' | 'pending',
                teamId: teamId,
                searchString: patientIdentifierValue,
                roleUuid: roleUuid,
                limit: 100,
                offset: 0
            };

            let isIdentifierExisting = false;
            setisLoadingMessage(true);
            setFormError(null);
            const users = await apiV2Service.searchUserFolders(searchParam);

            if (users.message.results.length !== 0) {
                isIdentifierExisting = true;
            }
            setisLoadingMessage(false);

            return isIdentifierExisting;

        } catch (err) {
            setFormError(_`Failed to check entered identifier.`);
            console.error(err);
        }
    }

    const onSubmitForm = async (state) => {
        const name = {
            given_name: state.givenName.value,
            middle_name: state.middleName.value,
            family_name: state.familyName.value,
        };

        if (name.middle_name === '') {
            delete name.middle_name;
        }

        const primaryIdentifierValue = state.primaryIdentifier.value.replace(/\s/g, '');

        const updatedDemographics = {
            name,
            dob: state.dateOfBirth.value,
            person_primary_identifier: {
                namespace: state.isNeedToChangeNamespace.value === false ? state.namespace.value : teamPrimaryIdentifierNamespace,
                value: primaryIdentifierValue,
            },
        };

        let existedIdentifierValue = undefined;

        existedIdentifierValue = await checkPatientsIdentifier(updatedDemographics.person_primary_identifier.value);
        setisLoadingMessage(false);

        if (existedIdentifierValue && primaryIdentifierValue !== primaryIdentifier.value) {
            return setFormError('This identifier already exists');
        }

        try {
            await terService.createTer({action: 'updateUser', data: updatedDemographics, folderId});
            onClose();
        } catch (err) {
            setFormError(_`Failed to update patient demographics.`);
            console.error(err);
        }
    };

    const { state, handleOnChange, handleOnBlur, handleOnSubmit, disable } = useForm(
        editDemographicsState,
        editDemographicsSchema,
        onSubmitForm,
    );

    const buttons = <Fragment>
        <label
            className={`btn btn-default ${disable ? 'btn-disabled' : 'btn-primary '}`}
            htmlFor="updateDemographicsFormSubmit"
        >Update</label>
        <button
            className="btn btn-default"
            onClick={onClose}
        >Cancel</button>
    </Fragment>;

    return <ModalDialog
        title={_`Edit Demographics`}
        onClose={onClose}
        buttons={buttons}
    >
        <form name="updateDemographicsForm"
              onSubmit={handleOnSubmit}
        >
            {formError && <div className="alert alert-danger">{formError}</div>}
            {!formError && (isLoadingMessage && <h4>Loading. Please wait...</h4>)}
            <InputField
                field="givenName" fieldName={_`Given Name`} isFocus={true}
                state={state} schema={editDemographicsSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <InputField
                field="middleName" fieldName={_`Middle Name`}
                state={state} schema={editDemographicsSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <InputField
                field="familyName" fieldName={_`Family Name`}
                state={state} schema={editDemographicsSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <div className={'form-group form-required ' + (state.dateOfBirth.error ? 'has-error' : '')}>
                <label htmlFor="dateOfBirth">{_`Date of birth`}</label>
                <DateInput
                    name="dateOfBirth"
                    value={state.dateOfBirth.value}
                    maxDate={moment().format(standardDateTimeFormats.date_input)}
                    onChange={handleOnChange}
                />
                {state.dateOfBirth.error && <div className="form-errors">
                    <div className="form-error">{state.dateOfBirth.error}</div>
                </div>}
            </div>
            <InputField
                field="primaryIdentifier" fieldName={teamPrimaryIdentifierLabel}
                state={state} schema={editDemographicsSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            {teamPrimaryIdentifierNamespace && state.namespace && state.namespace.value.toLowerCase() !== teamPrimaryIdentifierNamespace.toLowerCase() &&
            <Fragment>
                <div className="checkbox-container-row">
                    <Checkbox state={state} schema={editDemographicsSchema} value={state.isNeedToChangeNamespace.value} onChange={handleOnChange} name={'isNeedToChangeNamespace'} title={'Have you verified this number?'} />
                    <span>{_`Have you verified this number?`}</span>
                </div>
            </Fragment>
            }
            <div
                className="form-submit-btn-container"
            >
                <input type="submit"
                       id="updateDemographicsFormSubmit"
                       className="hidden"
                />
            </div>
        </form>
    </ModalDialog>;
};

const InputField = ({state, field, fieldName, onChange, onBlur, schema, isFocus}) => {
    const { required } = schema[field];

    const ref1 = useRef(null);

    useEffect(() => {
        if (isFocus) {
            ref1.current.focus();
        }
    }, [isFocus]);

    return <div className={`form-group ${required ? 'form-required' : ''} ${state[field].error ? 'has-error' : ''}`}>
        <label htmlFor={field}>{fieldName}</label>
        <input type="text" className="form-control"
            ref={ref1}
            name={field}
            value={state[field].value}
            placeholder={fieldName}
            onChange={onChange}
            onBlur={onBlur}
        />
        {state[field].error && <div className="form-errors">
            <div className="form-error">{state[field].error}</div>
        </div>}
    </div>;
};
