import React from 'react';
import DataStore from 'services/data-store';
import {$post} from 'services/api';
import { browserHistory } from 'react-router';

const PathwayFromNhs = ({value, children, extra}) => {
    let wrapper, newValue;

    if (value) {
        // strip all non-numerics from field
        newValue = value.replace(/[^0-9]/g,'');
        if (newValue.length == 10) {
            return (
                <a onClick={getPathwayFromNhs.bind(null, newValue, extra)}>{children}</a>
            )
        }
    }

    return (
        <div>
            {children}
        </div>
    );
}

export function getPathwayFromNhs(nhs, optional) {
    let team = DataStore.get('me.currentRole') || {},
        teamId = team.teamId || 0;
    $post(['clinical_portal', 'find-patient-folder'], {nhs_number:nhs}, teamId).then(loadPatient.bind(null, optional));
}

export function loadPatient(extra = '', {message:item}) {
    browserHistory.push(`/clinical_portal/folder/${item.folder_id}/patient/${extra}`);
}

export default PathwayFromNhs;
