import React, { ChangeEvent, Component, FormEvent } from 'react';
import { convertToDate } from 'common/datetime/convertToDate';
import {
    getQuestionnaireTemplates,
} from 'services/questionnaire-templates.service';
import './AllocateQuestionnaireComponent.scss';
import { checkErrors, getQuestionnaire } from 'features/patient/questionnaire/create/helpers';
import {
    AllocateQuestionnaireView,
} from 'features/patient/questionnaire/create/AllocateQuestionnaireView';
import { Moment } from 'moment';
import { api } from 'common/api';
import { getUserName } from 'common/sharedBaseComponentFunctions';
import { getTeam } from 'common/baseComponentFunctions';
import { NOTIFICATION_TYPE, pushNotificationService } from 'services/push-notifications.service';

export interface DateTime {
    years: number;
    months: number;
    days: number;
    hours: number;
}

export interface AllocateQuestionnaireForm {
    lozengeTitle: string;
    lozengeContent: string;
    popupTitle: string;
    popupContent: string;
    useDueBy: boolean;
    questionnaireTemplateId: string;
    sendPush: boolean;
}
interface State {
    errors: string[];
    loading: boolean;
    dueBy: Moment;
    // todo: any
    questionnaireTemplateList: any[];
    expirationDate?: string;
    showTimelineFields: boolean;
    form: AllocateQuestionnaireForm;
}
interface Props {
    teamId: number;
    folderId: number;
    onSubmit: (param: unknown, cb: () => void) => void;
}
export class AllocateQuestionnaireComponent extends Component<Props, State> {

    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState(): State {
        const dueBy = convertToDate(new Date(), 'date_input');

        return {
            questionnaireTemplateList: [],
            loading: false,
            dueBy,
            errors: [],
            showTimelineFields: true,
            form: {
                lozengeTitle: void 0,
                lozengeContent: void 0,
                popupTitle: void 0,
                popupContent: void 0,
                useDueBy: false,
                questionnaireTemplateId: '',
                sendPush: false,
            }
        };
    }

    componentDidMount() {
        this.loadComponent();
    }

    loadComponent() {
        this.setState({
            loading: true
        });

        this.getQuestionnaires()
            .then((questionnaires) => {
                this.setState({
                    questionnaireTemplateList: questionnaires,
                    loading: false
                });
            });
    }

    onChangeDueBy = (newDate: ChangeEvent<HTMLInputElement>) => {
        const newState = { dueBy: void 0 };
        newState.dueBy = convertToDate((newDate as ChangeEvent<HTMLInputElement>).target.value, 'date_input');
        this.setState(newState);
    }

    /**
     * Handle the change of state from various elements
     * @param  {Event} e The element that has changed
     */
    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        const newState: Partial<State> = {
            form: {
                ...this.state.form,
            }
        };
        if (!name) {
            console.error('[AllocateQuestionnaire#onChange] Name of field is empty');
            return;
        }

        switch (name) {
        case 'useDueBy':
        case 'sendPush':
            newState.form[e.target.name] = Boolean(e.target.checked);
            break;
        case 'questionnaireTemplateId':
            newState.form.questionnaireTemplateId = value;
            break;
        default:
            newState.form[name] = e.target.value;
        }
        this.setState(newState as State);
    }

    onSubmit = (e: FormEvent<HTMLInputElement>) => {
        const {
            dueBy,
            expirationDate,
            questionnaireTemplateList,
            form: {
                lozengeTitle,
                lozengeContent,
                popupTitle,
                popupContent,
                useDueBy,
                questionnaireTemplateId,
                sendPush,
            }
        } = this.state;

        const { folderId } = this.props;

        const errors = checkErrors(
            {
                questionnaireTemplateId,
                useDueBy,
                dueBy,
                expirationDate,
            }
        );

        e.preventDefault();

        if (errors.length > 0) {
            this.setState({
                errors: errors
            });
            return;
        }

        this.setState({
            loading: true
        });

        const questionnaire = getQuestionnaire({
            questionnaireTemplateList,
            questionnaireTemplateId,
        });

        // call page to handle submission of this form

        this.props.onSubmit({
            questionnaireData: questionnaire,
            state: {
                dueBy,
                useDueBy,
                timeline: {
                    lozengeTitle,
                    lozengeContent,
                    popupTitle,
                    popupContent,
                }
            },
            userName: getUserName(),
            team: getTeam(),
            folderId,
        }, () => {
            if (sendPush) {
                pushNotificationService.send({ notification: NOTIFICATION_TYPE.QUESTIONNAIRE });
            }
            if (api.version === 2) {
                return;
            }
            this.setState(this.initialState());
            this.loadComponent();
        });
    }

    getQuestionnaires() {
        return getQuestionnaireTemplates({
            portal: 'clinical_portal',
        }).then(({
            templates,
            compositions,
        }) => {
            return [templates, compositions];
        }).then(([templates, compositions]) => {
            return templates.concat(compositions);
        });
    }
    render() {
        return (
            <AllocateQuestionnaireView
                errors={this.state.errors}
                loading={this.state.loading}
                showTimelineFields={api.version === 2}
                showPushNotification={api.version === 2}
                dueBy={this.state.dueBy}
                formData={this.state.form}
                questionnaireTemplateList={this.state.questionnaireTemplateList}
                onSubmit={this.onSubmit}
                onChange={this.onChange}
                onChangeDueBy={this.onChangeDueBy}
            />
        );
    }
}
