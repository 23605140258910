import { TerAction, terService, TerParams } from 'services/ter.service';
import { SendPushNotificationContent, validatePushNotificationContent } from 'models/TriggerEngineRequestContent';

export enum NOTIFICATION_TYPE {
    MESSAGE = 'message',
    RESOURCE = 'resource',
    QUESTIONNAIRE = 'questionnaire',
    GOAL = 'goal',
    APPOINTMENT = 'appointment',
    APPOINTMENT_UPDATE = 'appointmentUpdate'
}

class PushNotificationsService {
    send(data: SendPushNotificationContent = {}) {
        const {
            notification = '',
            body = '',
            title = '',
        } = data;

        if(!validatePushNotificationContent(data)) {
            console.error('[PushNotificationService] Provided data:', data);
            throw new Error('[PushNotificationService] Need provide at least one parameter');
        }

        const sendPushTerContent: TerParams = {
            action: 'sendPushNotification' as TerAction,
            data: {
                notification,
                body,
                title,
            },
            role: void 0,
            folderId: void 0,
        };
        return terService.createTer(sendPushTerContent)
            .catch((e) => {
                console.error('[PushNotificationsService] Unable to send TER', e);
                return Promise.reject();
            });
    }
}

export const pushNotificationService = new PushNotificationsService();
