import React from 'react';
import PropTypes from 'prop-types'
import lodash from 'lodash';

import NhsNumber from 'ui/nhs-number';

/**
 * Displays the contents of a table cell containing the patient's NHS number.
 * This is part of a row of the transport and interpreter worklist.
 * 
 * Props (data):
 * - demographics = Optional. The patient's demographics composition. If the data is still being loaded, this should be undefined. If the patient has no demographics data, this should be an empty object.
 */
const NhsNumberField = ({demographics}) => {
    if (!demographics) {
        return (<span className='text-muted'>Loading...</span>);
    }
    
    return (
        <NhsNumber empty='(unknown)'>
            {lodash.get(demographics, 'content.identifiers.nhs_number', '')}
        </NhsNumber>
    );
};

export default NhsNumberField;

NhsNumberField.propTypes = {
    demographics: PropTypes.object
};
