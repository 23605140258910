import React from 'react';
import {Link} from 'react-router';
import lodash from 'lodash';
import Markdown from 'components/ui/markdown';

/**
 * Displays a message composition on a MyPathway timeline.
 * Props:
 * - composition = Required. An object containing the composition data to be displayed.
 * - folderLink = Required. Base URL for the patient's folder in the portal. This is used to construct links to specific archetypes.
 */
export const Message = ({composition, folderLink, compositionQueryString}) => {

    const uuid = lodash.get(composition, 'uuid', undefined);
    const title = lodash.get(composition, 'content.title', '');

    let content = lodash.get(composition, 'content.content', '');
    if (content.length > 100) {
        content = content.substr(0, 100) + '...';
    }

    const body = (
        <div className='composition-message'>
            <h4><Markdown sanitize={false}>{title}</Markdown></h4>
            <div className='message-content'><Markdown sanitize={false}>{content}</Markdown></div>
        </div>
    );


    if (!uuid) {
        // The dummy welcome message won't have a UUID.
        // Don't wrap make it a link in this case.
        return body;
    }

    let link = `${folderLink}/messages/${uuid}`;
    if (compositionQueryString){
        link += `?${compositionQueryString}`;
    }

    return (
        <Link to={link}>
            {body}
        </Link>
    );
};

export default Message;

