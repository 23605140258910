import React, { FC } from 'react';
import _ from 'services/i18n';
import './SelectConfirm.scss';

export interface SelectConfirmProps {
    options: {value: string; label: string; unselectable?: boolean} [];
    value: string | void;
    disabled?: boolean;
    status?: 'idle' | 'processing';
    onChange: ({ target: { value: string } }) => void;
    onSubmit: () => void;
    name?: string;
    className?: string;
}

export const SelectConfirm: FC<SelectConfirmProps> = ({ options, value, disabled = false, onChange, onSubmit, name, status = 'idle', className = ''}) => {
    const title = options.find(({ value: val }) => value === val)?.label || _`Select item`;
    const isProcessing = status === 'processing';
    const btnClassName = isProcessing ? ' btn-warning select-confirm__processing' : ' btn-primary';
    const showButton = isProcessing ? true : !disabled;
    const componentClassNames = (disabled ? ' select-confirm__disabled' : '') + (className ? ` ${className}` : '');
    return (
        <div className={'select-confirm' + componentClassNames} style={{ width: '100%' }}>
            <select name={name} className="form-control form-control_small" value={value || ''} disabled={disabled} onChange={onChange} title={title}>
                <option key={'--'} value={''}>{_`-- select --`}</option>
                {options.map(({ value, label, unselectable = false }) => {
                    return <option key={value} value={value} disabled={unselectable}>{label}</option>;
                })}
            </select>
            {showButton && (
                <button disabled={isProcessing} className={'btn btn-xs' + btnClassName} type="button" onClick={onSubmit} title={isProcessing ? _`Processing...` : _`Apply Changes`}>
                    { isProcessing ? <i className="far fa-clock"/> : _`Approve` }
                </button>
            )}
        </div>
    );
};
