import React from 'react';
import _ from 'services/i18n';
import { useCurrentTeam } from 'common/useCurrentTeam';

export function usePatientsString() {
    const team = useCurrentTeam();

    const service = team && team.teamService;

    return service && service === 'CAMHS' ? [_`Young Person`, _`Young People`] : [_`Patient`, _`Patients`]
}