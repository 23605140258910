import { useEffect, useState } from 'react';
import { teamPreferencesService } from 'services/team-preferences.service';
import { BulkOperationSendMessageConfigurator } from './BulkOperationSendMessageConfigurator';
import { BulkOperationCreateResourceConfigurator } from './BulkOperationCreateResourceConfigurator';
import { BulkOperationSendSmsConfigurator } from './BulkOperationSendSmsConfigurator';
import { BulkOperationSendEmailConfigurator } from './BulkOperationSendEmailConfigurator';
import { BulkOperationQuestionnaireConfigurator } from './BulkOperationQuestionnaireConfigurator';
import { BulkOperationSendLetterConfigurator } from './BulkOperationSendLetterConfigurator';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationSimpleQuestionnaireConfigurator } from './BulkOperationSimpleQuestionnaireConfigurator';
import { BulkOperationAddPifuConfigurator } from './BulkOperationAddPifuConfigurator';
import { BulkOperationAutoCommunicationConfigurator } from './auto-communication/BulkOperationAutoCommunicationConfigurator';
import { BulkOperationSendResourceCollectionConfigurator } from './resource-collection/BulkOperationSendResourceCollectionConfigurator';
import {
    BulkOperationAssignPathwayEventConfigurator
} from "components/bulk-operations/BulkOperationAssignPathwayEventConfigurator";

export function useEnabledBulkOperations(): BulkOperationConfigurationComponent<unknown>[] {
    const [enabledOperations, setEnabledOperations] = useState([]);
    
    useEffect(() => {
        teamPreferencesService.getFirst({}).then((teamPreferences) => {
            const teamEnabledOperations: BulkOperationConfigurationComponent<unknown>[] = [];
            const bulkOperationsPreferences: any = teamPreferences.bulkOperations;
            const pifuActionName = teamPreferences.pifu?.pifu_display_name;

            if (!bulkOperationsPreferences)
            {
                setEnabledOperations(teamEnabledOperations);
                return;
            }

            if (bulkOperationsPreferences.sendTimelineMessage) {
                teamEnabledOperations.push(new BulkOperationSendMessageConfigurator());
            }

            if (bulkOperationsPreferences.sendSms) {
                teamEnabledOperations.push(new BulkOperationSendSmsConfigurator());
            }

            if (bulkOperationsPreferences.assignResourceWithMessage) {
                teamEnabledOperations.push(new BulkOperationCreateResourceConfigurator());
            }

            if (bulkOperationsPreferences.sendQuestionnaire) {
                teamEnabledOperations.push(new BulkOperationQuestionnaireConfigurator());
            }

            if (bulkOperationsPreferences.sendSimpleQuestionnaire) {
                teamEnabledOperations.push(new BulkOperationSimpleQuestionnaireConfigurator());
            }

            if (bulkOperationsPreferences.sendEmail) {
                teamEnabledOperations.push(new BulkOperationSendEmailConfigurator());
            }

            if (bulkOperationsPreferences.sendLetter) {
                teamEnabledOperations.push(new BulkOperationSendLetterConfigurator());
            }

            if (bulkOperationsPreferences.addPifu && teamPreferences.pifu?.pifu_enabled) {
                teamEnabledOperations.push(new BulkOperationAddPifuConfigurator(pifuActionName));
            }

            if (bulkOperationsPreferences.autoCommunication) {
                teamEnabledOperations.push(new BulkOperationAutoCommunicationConfigurator());
            }

            if (bulkOperationsPreferences.assignTeamResources) {
                teamEnabledOperations.push(new BulkOperationSendResourceCollectionConfigurator());
            }

            if (bulkOperationsPreferences.sendInvite) {
                teamEnabledOperations.push(new BulkOperationAssignPathwayEventConfigurator());
            }

            setEnabledOperations(teamEnabledOperations);
        });
    }, []);

    return enabledOperations;
}

