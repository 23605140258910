import React, { FunctionComponent } from 'react';
import { useI18N } from 'common/i18n/useI18N';

import Markdown from 'components/ui/markdown';
import { DateTime } from 'common/datetime/DateTime';
import {
    PreviousQuestionnaireResponseDetails
} from './usePreviousCompletedQuestionnaire';

interface Score {
    score_name: string;
    score_description?: string;
    score_value_string?: string;
    score_value_float?: number;
    score_value_int?: number;
    score_value_boolean?: boolean;
}

interface ScoresProps {
    status: string;
    questionnaireScores: Score[];
    previousResponseData?: PreviousQuestionnaireResponseDetails;
}

function getScoreValue(score?: Score) {
    return score && (score.score_value_int || score.score_value_float || score.score_value_string || score.score_value_boolean);
}

export const QuestionnaireScores: FunctionComponent<ScoresProps> = (props) => {
    // eslint-disable-next-line react/prop-types
    const { status, questionnaireScores, previousResponseData } = props;
    const previousScores = previousResponseData?.previousResponseScores;
    const previousCompletedAt = previousResponseData?.previousResponseCompletedAt;
    const previousResponsePath = previousResponseData?.previousResponsePath;

    const _ = useI18N();

    if (status !== 'scored' || !questionnaireScores) {
        return null;
    }

    return (
        <div>
            <h4 className="spacer-top28">{_`Scores`}</h4>
            <div className="table-responsive">
                <table className="table table-bordered table-condensed table-nomargin">
                    <thead>
                        <tr>
                            <th style={{ width: '40%' }}>{_`Name`}</th>
                            <th>{_`Value`}</th>
                            { previousScores?.length > 0 && (
                                <th className='ws-nowrap'>
                                    {_`Previous Value`}
                                    {' ('}
                                    <a href={previousResponsePath}><DateTime>{previousCompletedAt}</DateTime></a>
                                    {')'}
                                </th>
                            )}
                            <th>{_`Description`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            questionnaireScores.map((score, i) => {
                                const scoreValue = getScoreValue(score);
                                const previousScore = score && previousScores?.length > 0 && previousScores.find(
                                    previousScore => previousScore.score_name === score.score_name
                                );
                                const previousScoreValue = getScoreValue(previousScore);
                                const changedScore = previousScores?.length > 0 && previousScoreValue !== scoreValue;
                                return (
                                    <tr key={i}>
                                        <td>{score.score_name}</td>
                                        <td className={`ws-nowrap ${changedScore ? 'bg-diff-neutral' : ''}`}>{scoreValue}</td>
                                        { previousScores?.length > 0 && <td className={`ws-nowrap ${changedScore ? 'bg-diff-neutral' : ''}`}>{previousScoreValue || '-'}</td> }
                                        <td><Markdown sanitize={false}>{score.score_description || ''}</Markdown></td>
                                    </tr>
                                );
                            })
                        }
                        {
                            previousScores?.length > 0 && previousScores
                                .filter(previousScore => !questionnaireScores.some(score => score.score_name === previousScore.score_name))
                                .map((previousScoreNotInLatest, i) => {
                                    const previousScoreValue = getScoreValue(previousScoreNotInLatest);
                                    return (
                                        <tr key={questionnaireScores.length + i}>
                                            <td>{previousScoreNotInLatest.score_name}</td>
                                            <td className='bg-diff-neutral'>-</td>
                                            { previousScoreValue && <td className='bg-diff-neutral'>{previousScoreValue}</td> }
                                            <td><Markdown sanitize={false}>{previousScoreNotInLatest.score_description || ''}</Markdown></td>
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};
