import { useEffect, useMemo } from 'react';

export interface Validator {
    validate: () => boolean;
    addValidationStep: (validationFn: () => boolean) => void;
    removeValidationStep: (validationFn: () => boolean) => void;
}

class ValidatorImpl implements Validator {
    private validators = new Set<() => boolean>();

    validate() {
        return Array
            .from(this.validators)
            .reduce((valid, validateFn) => {
                const nextValid = validateFn();
                return valid && nextValid;
            }, true);
    }

    addValidationStep(validationFn: () => boolean) {
        this.validators.add(validationFn);
    }

    removeValidationStep(validationFn: () => boolean) {
        this.validators.delete(validationFn);
    }
}

export function useValidatorRoot() {
    return useMemo(() => {
        return new ValidatorImpl();
    }, []);
}

export function useValidator(validator: Validator, validationFn: () => boolean) {
    useEffect(() => {
        validator.addValidationStep(validationFn);
        return () => {
            validator.removeValidationStep(validationFn);
        };
    }, [validator, validationFn]);
}
