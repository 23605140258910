/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Link } from 'react-router';
import _ from 'services/i18n';
import { useForm } from 'common/form/useForm';
import { goalsService } from 'services/goals.service';
import { convertToTimestamp } from 'common/datetime/convertToDate';
import { NOTIFICATION_TYPE, pushNotificationService } from 'services/push-notifications.service';

const patientGoalState = {
    label: { value: '', error: '' },
    description: { value: '', error: '' },
    givenValue: { value: '', error: '' },
    type: { value: '', error: '' },
    unit: { value: '', error: '' },
    sendPush: { value: false, error: '' },
    sendPushWhenGoalReady: { value: false, error: '' }
};

const patientGoalSchema = {
    label: {
        required: true,
    },
    description: {},
    givenValue: {},
    type: {
        required: true,
    },
    unit: {},
    sendPushWhenGoalReady: {},
    sendPush: {},
};

export const PatientGoalCreate = ({
    folderId
}) => {
    const [formError, setFormError] = useState('');
    const [, setIsLoading] = useState(true);
    const [, setIsError] = useState(false);
    const [goal, setGoal] = useState(null);
    const [uuid, setUuid] = useState('');
    const urlString = window.location.pathname;

    useEffect(() => {
        if (!urlString.includes('edit')) { return; }
        const _uuid = urlString.substring(urlString.lastIndexOf('/') + 1);

        setUuid(_uuid);
    }, [urlString]);

    async function loadGoal(uuid) {
        const { status, message: goal } = await goalsService.getByUuid({ uuid });

        if (status >= 300) {
            return setIsError(true);
        }

        if (goal.deleted_at != null && goal.deleted_at !== '') {
            console.warn('Trying to show an archived composition.');
            return setIsError(true);
        }

        setIsError(false);
        setIsLoading(false);
        setGoal(goal.content);
    }

    useEffect(()=> {
        uuid && loadGoal(uuid);
    }, [uuid]);

    const onSubmitForm = async (state) => {
        const defaultGoal = {
            goal_type: 'big',
            parent_uuid: '',
            status: 'active',
            description: '',
            recurring: 'daily',
            date_scheduled: convertToTimestamp(Date.now()),
            scores: []
        };

        const content = Object.assign({}, defaultGoal, goal, {
            label: state.label.value,
            given_value: state.givenValue.value,
            description: state.description.value,
            type: state.type.value,
            unit: state.unit.value,
            push_notification_on_reminder: state.sendPushWhenGoalReady.value
        });

        try {
            if (urlString.includes('edit')) {
                await goalsService.update({  content, uuid });
            } else {
                await goalsService.create({ content });
                if (state.sendPush.value) {
                    await pushNotificationService.send({ notification: NOTIFICATION_TYPE.GOAL });
                }
            }

            onCancel();
        } catch (err) {
            setFormError(_`Failed to save goal.`);
            console.error(err);
        }
    };

    const onCancel = () => {
        window.location.pathname = `/clinical_portal/folder/${folderId}/patient/goals`;
    };

    const { state, handleOnChange, handleOnSubmit, disable } = useForm(
        patientGoalState,
        patientGoalSchema,
        onSubmitForm,
    );

    useEffect(() => {
        if (goal) {
            handleOnChange({ target: { name: 'label', value: goal.label } });
            handleOnChange({ target: { name: 'description', value: goal.description } });
            handleOnChange({ target: { name: 'givenValue', value: goal.given_value } });
            handleOnChange({ target: { name: 'type', value: goal.type } });
            handleOnChange({ target: { name: 'unit', value: goal.unit } });
            handleOnChange({ target: { name: 'sendPushWhenGoalReady', value: goal.push_notification_on_reminder } });
        }
    }, [goal, uuid, handleOnChange]);

    return (
        <div>
            <div className='top-bar'>
                <Link to={`/clinical_portal/folder/${folderId}/patient/goals`} >Back To Goals</Link>
            </div>
            <h3>{goal ? _`Edit patient goal` : _`Create patient goal`}</h3>
            <form name="patientGoalForm"
                onSubmit={handleOnSubmit}
            >
                {formError && <div className="alert alert-danger">{formError}</div>}
                <InputField
                    field="label" fieldName={_`Label`} isFocus={true}
                    state={state} schema={patientGoalSchema} onChange={handleOnChange}
                />
                <div className="form-group">
                    <label>{_`Description`}</label>
                    <textarea className="form-control" name="description"
                        onChange={handleOnChange} value={state.description.value} />
                    {!!state.description.error && (
                        <div className="form-errors">
                            <div className="form-error">{state.description.error}</div>
                        </div>
                    )}
                </div>
                <div className={'form-group form-required ' + (state.type.error ? 'has-error' : '')}>
                    <label htmlFor="type">{_`Score type`}</label>
                    <select className="form-control"
                        name="type"
                        value={state.type.value}
                        onChange={handleOnChange}
                    >
                        <option value="">{_`Select score type`}</option>
                        <option value="numerical">{_`Number`}</option>
                        <option value="steps-target">{_`Steps`}</option>
                        <option value="range">{_`Range`}</option>
                        <option value="boolean">{_`Boolean`}</option>
                        <option value="text">{_`Text`}</option>
                    </select>
                    {!!state.type.error && (
                        <div className="form-errors">
                            <div className="form-error">{state.type.error}</div>
                        </div>
                    )}
                </div>
                {(state.type.value === 'numerical' || state.type.value === 'steps-target') && (
                    <Fragment>
                        <InputField
                            field="givenValue" fieldName={_`Goal target`}
                            state={state} schema={patientGoalSchema} onChange={handleOnChange}
                        />
                        <InputField
                            field="unit" fieldName={_`Units of measure`}
                            state={state} schema={patientGoalSchema} onChange={handleOnChange}
                        />
                    </Fragment>
                )}
                <div className="form-group">
                    <label>
                        <input type="checkbox" name={'sendPush'} onChange={handleOnChange} checked={state.sendPush.value} />
                        <span>&nbsp;{_`Notify patient by  push notification`}</span>
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <input type="checkbox" name={'sendPushWhenGoalReady'} onChange={handleOnChange} checked={state.sendPushWhenGoalReady.value} />
                        <span>&nbsp;{_`Notify patient by push notification when goal is ready to rate`}</span>
                    </label>
                </div>
                <input type="submit"
                    id="patientGoalFormSubmit"
                    className="hidden"
                />
                <Fragment>
                    <label
                        className={`btn btn-default ${disable ? 'btn-disabled' : 'btn-primary '}`}
                        htmlFor="patientGoalFormSubmit"
                    >{goal ? _`Update` : _`Create`}</label>
                    <button
                        className="btn btn-default btn-cancel"
                        onClick={onCancel}
                    >Cancel</button>
                </Fragment>
            </form>
        </div>
    );
};

const InputField = ({ state, field, fieldName, onChange, onBlur, schema, isFocus }) => {
    const { required } = schema[field];

    const ref1 = useRef(null);

    useEffect(() => {
        if (isFocus) {
            ref1.current.focus();
        }
    }, [isFocus]);

    return (
        <div className={`form-group ${required ? 'form-required' : ''} ${state[field].error ? 'has-error' : ''}`}>
            <label htmlFor={field}>{fieldName}</label>
            <input type="text" className="form-control"
                ref={ref1}
                name={field}
                value={state[field].value}
                placeholder={fieldName}
                onChange={onChange}
                onBlur={onBlur}
            />
            {!!state[field].error && (
                <div className="form-errors">
                    <div className="form-error">{state[field].error}</div>
                </div>
            )}
        </div>
    );
};
