import React, { useMemo } from 'react';
import { useI18N } from 'common/i18n/useI18N';
import { DateTime, convertToOutputTzDate, formatDate } from 'common/datetime/DateTime';
import { usePathways } from 'features/patients/pathways/usePathways';
import { useLocationsContext } from 'features/locations/locations.context';

function getDisplayType (type, _) {
    if (!type || type == '') {
        return _`Unknown`;
    }

    switch (type.trim().toLowerCase()) {
    case 'triage': return _`Triage`;
    case 'inpatient': return _`Inpatient`;
    case 'outpatient': return _`Outpatient`;
    case 'telephone': return _`Telephone`;
    case 'assessment': return _`Assessment`;
    case 'day-care': return _`Day Care`;
    case 'pre-admission': return _`Pre-Admission`;
    case 'waiting-list': return _`Waiting List`;
    case 'ward-attendance': return _`Ward Attendance`;
    case 'other': return _`Other`;
    }

    return type;
}

function getDisplayStatus (appointment, _) {
    const { status, proposed_participant } = appointment.content;

    if (!status) {
        return _`Unknown`;
    }

    switch (status.trim().toLowerCase()) {
    case 'available-to-book': return _`Available to book`;
    case 'booked': return _`Booked`;
    case 'waiting': return _`Waiting`;
    case 'admitted': return _`Admitted`;
    case 'arrived': return _`Arrived`;
    case 'called': return _`Called`;
    case 'seen': return _`Seen`;
    case 'departed': return _`Attended`;
    case 'discharged': return _`Discharged`;
    case 'did-not-attend': return _`Did not attend`;
    case 'cancelled': return _`Cancelled`;
    case 'closed': return _`Closed`;
    case 'proposed':
        if (proposed_participant && proposed_participant.status) {
            switch (proposed_participant.status.trim().toLowerCase()) {
            case 'needs-attention':
                return _`Proposed (Needs Attention)`;
            case 'accepted':
                return _`Proposed (Accepted)`;  
            case 'declined': 
                return _`Proposed (Declined)`;
            case 'needs-further-information': 
                return _`Proposed (Needs Further Information)`;
            default:
                return _`Proposed`;
            }
        }
        return _`Proposed`;
    }

    return status;
}

interface LocationDetails {
    point_of_care?: string;
    room?: string;
    consultation_mechanism?: string;
}

interface Appointment {
    uuid: string;
    folder_id: number;
    content: {
        location: string;
        date: number;
        type: string;
        status: string;
        unknown_date: boolean;
        referral_id: string;
        approximate_date: boolean;
        labels: any[];
        location_details?: LocationDetails;
    };
}

const AppointmentDateTime = ({
    unknown_date,
    date,
    approximate_date,
}) => {
    const _ = useI18N();

    let dateTime = null;
    if (unknown_date || !date || date == '') {
        dateTime = (<span className='text-muted'>-</span>);
    } else {
        dateTime = (<DateTime format="nhs_date_with_time" empty="-">{date}</DateTime>);
        if (approximate_date) {
            dateTime = (
                <span>
                    {dateTime}{' '}
                    <span className='text-muted'>
                        {_`(approx.)`}
                    </span>
                </span>
            );
        }
    }

    return dateTime;
};

export const usePreparedAppointments = (appointments: Appointment[], referrals: any[]) => {
    const _ = useI18N();
    const { getPathwaysByLabels, getPathwaysByReferral } = usePathways();
    const { locations } = useLocationsContext();

    return useMemo(() => appointments.map(appointment => {
        const { content, uuid, folder_id: folderId } = appointment;
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { location, date, type, status, unknown_date, approximate_date, labels, location_details } = content;
        let { pathwayNames } = getPathwaysByLabels(labels);
        if (pathwayNames === '') {
            pathwayNames = getPathwaysByReferral(appointment.content.referral_id, referrals);
        }

        const getConsultationMechanism = () => {
            let consultationMechanism = '';

            if (location_details?.consultation_mechanism) {

                if (locations && locations[location_details?.consultation_mechanism]) {
                    consultationMechanism = locations[location_details.consultation_mechanism]?.name;
                } else {
                    consultationMechanism = location_details.consultation_mechanism;
                }
            }
            return consultationMechanism;
        };

        return {
            key: appointment.uuid,
            appointmentLink: `/clinical_portal/folder/${folderId}/patient/appointments/${uuid}`,
            location: location ?? '-',
            type: getDisplayType(type, _),
            status: getDisplayStatus(appointment, _),
            dateTime: <AppointmentDateTime
                unknown_date={unknown_date}
                date={date}
                approximate_date={approximate_date}
            />,
            consultationMechanism: getConsultationMechanism(),
            dateTimeSort: date,
            dateTimeFilter: `${date ? formatDate(convertToOutputTzDate(date), 'nhs_date_with_time') : ''} ${approximate_date ? '' : '(approx.)'}`,
            pathwayNames,
            _source: appointment,
        };
    }), [appointments, getPathwaysByLabels, _, getPathwaysByReferral, referrals, locations]);
};
