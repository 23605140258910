import moment, { Moment } from 'moment';

export const formatDateToString = (dateToFormat: Moment | string, formatOfDate = 'YYYY-MM-DD'): string => {
    return moment(dateToFormat).format(formatOfDate);
};

export const formatStringToMoment = (dateToFormat: string): Moment => {
    return moment(dateToFormat);
};

export const generateElementIdentifier = (documentType: string, valuePath: string, inputType: string): string => {
    const path = valuePath.replace(/[.]/g, '-');

    return `${documentType}_${path}__${inputType}`;
};

export const calculatePercentageComplete = (totalCompleted: number, totalItems: number): string => {
    const percentageComplete = Math.round(totalCompleted/totalItems*100);

    return `${percentageComplete}%`;
};
