/* eslint-disable @typescript-eslint/camelcase */
import { createContext } from 'react';
import { TeamPreferences } from 'models/TeamPreferences';

export const TeamPreferencesContext = createContext<TeamPreferences>({
    show_appointments: true,
    show_referrals: true,
    show_goals: true,
    show_messages: true,
    show_create_appointment_button: true,
    show_create_patient_button: false,
    show_mechanical_turk_elements: false,
    show_create_patient: false,
    can_user_add_personalized_resource: false,
    hide_allocate_questionnaire: false,
    referrals_list_category_groups: [],
    team_role_uuids: ({} as any),
    pathway_list: [],
    primary_identifier: ({} as any),
    portal: {
        graph_results: [],
        show_dashboard: false,
        show_pathway: true,
        dashboard_modules: [],
    }
});
