import './PatientBanner.less';
import React from 'react';
import { DateSpan } from 'common/datetime/DateTime';
import NhsNumber from 'ui/nhs-number';

export function PatientBanner ({
    name,
    preferredName,
    hospitalNumber,
    gender,
    dob,
    nhs,
}: {
    name: string;
    preferredName?: string;
    hospitalNumber?: string;
    gender?: string;
    dob: number;
    nhs: string;
}) {
    return (
        <div className="patient-banner">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="patient-banner__name">
                            <strong>{name}</strong>
                        </div>
                        {
                            preferredName &&
                            <div className="patient-banner__preferred-name">
                                <span>Preferred Name: <strong>{preferredName}</strong>&nbsp;</span>
                            </div>
                        }
                    </div>
                    <div className="col-md-6 text-right">
                        {dob && <span className={'no-wrap'}> Born: <strong><DateSpan>{dob}</DateSpan></strong>&nbsp;</span>}
                        {hospitalNumber && <span className={'no-wrap'}> Hospital Number: <strong>{hospitalNumber}</strong>&nbsp;</span>}
                        {gender && <span className="no-wrap"> Gender: <strong>{gender}</strong>&nbsp;</span>}
                        {nhs && <span className="no-wrap"> NHS Number: <strong><NhsNumber>{nhs}</NhsNumber></strong></span>}
                    </div>
                </div>
            </div>
        </div>
    );
}
