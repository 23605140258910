import { IFolderSelectorParam, IRole, ISearchDocumentsParams } from 'phr-api-client';
import { apiV2Service, fetchAll } from './api-v2.service';
import { DataStore } from 'services/data-store';
import { Composition } from 'models/Composition';

const tag = '[Composition Service]';

export abstract class CompositionService {

    abstract get archetypeName(): string;

    create({role, content, folderId, archetypeName = this.archetypeName, documentGroupUuid}: ICreateArgs): Promise<string> {
        return this.handleApiRequest(apiV2Service.createDocument.bind(apiV2Service), {
            docType: archetypeName,
            role,
            content,
            folderId,
            documentGroupUuid
        });
    }

    update({uuid, role, content, folderId, archetypeName = this.archetypeName}: IUpdateArgs): Promise<any> {
        return this.handleApiRequest(apiV2Service.updateDocument.bind(apiV2Service), {
            docType: archetypeName,
            role,
            content,
            folderId,
            uuid
        });
    }

    delete({uuid, role, folderId, archetypeName = this.archetypeName}: ICompositionCommonArgs): Promise<any> {
        return this.handleApiRequest(apiV2Service.deleteDocument.bind(apiV2Service), {
            uuid,
            docType: archetypeName,
            role,
            folderId
        });
    }

    getByUuid({uuid, role, folderId, archetypeName = this.archetypeName}: ICompositionCommonArgs): Promise<any> {
        return this.handleApiRequest(apiV2Service.getDocumentByUuid.bind(apiV2Service), {
            uuid,
            archetypeName,
            role,
            folderId
        });
    }

    list<T=any>({
        role,
        folderId,
        sort = '-updated_at',
        limit = 100,
        offset = 0,
        archetypeName = this.archetypeName
    }: IListArgs = {role: undefined, folderId: undefined}): Promise<{ message: {total: number; results: T[]} }> {
        if (limit === Infinity) {
            return this.listAll({role, folderId, sort, archetypeName})
                .then((results) => {
                    return {message: {total: results.length, results}};
                });
        }
        return this.handleApiRequest(apiV2Service.getDocuments.bind(apiV2Service), {
            archetypeName,
            role,
            folderId,
            sort,
            limit,
            offset
        });
    }

    search({
        role,
        folderId,
        search,
        sort = [{field: 'updated_at', ascending: true}],
        limit = 100,
        offset = 0,
        archetypeName = this.archetypeName
    }: ISearchArgs): Promise<any> {

        if (limit === Infinity) {
            return this.searchAll({role, folderId, sort, search, archetypeName});
        }

        return this.handleApiRequest(apiV2Service.searchDocuments.bind(apiV2Service), {
            archetypeName,
            role,
            folderId,
            sort,
            limit,
            offset
        }, search)
            .catch((e) => {
                console.error(tag, `Error during trying to search for compositions ${archetypeName}`, e);
                return Promise.reject(e);
            });
    }

    private searchAll({role, folderId, sort, search, archetypeName = this.archetypeName}: ISearchAllArgs): Promise<any[]> {
        const limit = 100;
        return fetchAll((offset: number) => {
            return this.search({role, folderId, sort, archetypeName, search, offset, limit});
        });
    }

    private listAll({role, folderId, sort, archetypeName = this.archetypeName}: IListAllArgs): Promise<any[]> {
        const limit = 100;
        return fetchAll((offset: number) => {
            return this.list({role, folderId, sort, archetypeName, offset, limit});
        });
    }

    private handleApiRequest(method: any, ...args: any[]) {

        const role = DataStore.get('me.currentRole');

        args[0].role = args[0].role || role;
        args[0].folderId = args[0].folderId || DataStore.get('currentFolder');
        if (!args[0].role) {
            console.error(tag, 'Role is undefined. Role from DataStore: ', role);
            return Promise.reject('Current role is undefined');
        }
        return method.apply(null, args);
    }
}
const getOffsets = (total, max) => {
    const count = Math.floor(total / max) - (total%max ? 0 : 1);
    return new Array(count).fill((d) => null).map((d, i) => {
        return (i + 1) * max;
    });
};

export const getAllUserCompositions = async <T>({ limit = 100, archetype, folderId }: { limit?: number; archetype: string; folderId: number, teamId?: number }): Promise<Composition<T>[]> => {
    try {
        const list = await compositionService.list({ folderId, archetypeName: archetype, limit });
        const { message: { results: firstResults, total } } = list;
        if(total <= limit) {
            return firstResults;
        }
        const offsets = getOffsets(total, limit);
        const requests = offsets.map(offset => compositionService.list({ folderId, archetypeName: archetype, offset, limit }));
        const responses = await Promise.all(requests);
        return responses.reduce((acc, { message: { results } }) => {
            return acc.concat(results);
        }, []).concat(firstResults);
    } catch(e) {
        console.error('[getAllCompositionsWithoutLimit]', 'Something went wrong', e);
        throw new Error(e);
    }
};

interface IGeneralArgs {
    archetypeName?: string;
    role?: IRole;
    folderId?: IFolderSelectorParam;
}

export interface ICreateArgs extends IGeneralArgs {
    content: any;
    documentGroupUuid?: string;
}

export interface IUpdateArgs extends IGeneralArgs {
    uuid: string;
    content: any;
}

export interface ICompositionCommonArgs extends IGeneralArgs {
    uuid: string;
}

interface IListAllArgs extends IGeneralArgs {
    sort?: string;
}

interface IListArgs extends IGeneralArgs {
    sort?: string;
    limit?: number;
    offset?: number;
}

interface ISearchAllArgs extends IGeneralArgs {
    sort?: { ascending: boolean, field: string }[];
    search: ISearchDocumentsParams;
}

interface ISearchArgs extends IGeneralArgs {
    sort?: { ascending: boolean, field: string }[];
    search: ISearchDocumentsParams;
    limit?: number;
    offset?: number;
}

export const compositionService = new (class extends CompositionService {
    get archetypeName(): string {
        throw new Error('Invalid composition instance use');
    }
})();
