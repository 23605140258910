import { StateLinkable } from 'components/dataSource/StateLinking';
import { createContext, useContext } from 'react';

export class MultipleRowExpansionState implements StateLinkable<boolean, string> {

    private pathListeners: Map<string, Set<(expanded: boolean) => void>> = new Map();
    private pathExpansions: Map<string, boolean> = new Map();

    public getState = (subStateKey: string): boolean => {
        return this.pathExpansions.get(subStateKey) ?? false;
    };

    public setState = (expanded: boolean, subStateKey: string): void => {
        this.pathExpansions.set(subStateKey, expanded);
        const listeners = this.pathListeners.get(subStateKey);
        if (listeners) {
            listeners.forEach(callbackFn => callbackFn(expanded));
        }
    };

    public deregisterStateChangeListener = (toDeregister: { stateChangeCallback: (expanded: boolean) => void; subStateKey: string }): void => {
        const listeners = this.pathListeners.get(toDeregister.subStateKey);
        if (listeners) {
            listeners.delete(toDeregister.stateChangeCallback);
            if (listeners.size == 0) {
                this.pathListeners.delete(toDeregister.subStateKey);
            }
        }
    };

    public registerStateChangeListener = (toRegister: { stateChangeCallback: (expanded: boolean) => void; subStateKey: string }): void => {
        const listeners = this.pathListeners.get(toRegister.subStateKey) ?? new Set();
        listeners.add(toRegister.stateChangeCallback);
        this.pathListeners.set(toRegister.subStateKey, listeners);
    };
}

export const ExpansionStateContext = createContext<MultipleRowExpansionState>(null);
export const useExpansionStateContext = () => {
    const expansionState = useContext<MultipleRowExpansionState>(ExpansionStateContext);
    if (!expansionState) {
        throw new Error('This component must be within an ExpansionStateContext provider');
    }
    return expansionState;
};
