import React, {useEffect, useState} from 'react';
import {TabNavigator, NavTab} from '../../../portal-components/ui/tab/navigator';
import DataStore from '../../services/data-store';
import PageTitle from '../../../app/page-title';

import {Column, Row} from '../../../portal-components/ui';
import { useI18N } from '../../common/i18n/useI18N';
import { useLatestLogin } from '../latest-login/useLatestLogin';

export const ProfileContainer = ({
    portal = 'clinical_portal',
    location,
    children,
}) => {
    const baseRoute = `/${portal}/profile`;
    const [fullName, setFullname] = useState(undefined);
    const _ = useI18N();


    const latestLogin = useLatestLogin();

    useEffect(() => {
        const currentUser = DataStore.get('currentUser');
        if (!currentUser) { return; }

        setFullname([
            currentUser.first_name,
            currentUser.last_name
        ].join(' '));
    }, [])

    useEffect(() => {
        PageTitle.setTitle(_`User Profile` + ' | ' + _`PHR Clinical Portal`);
    }, []);

    return (
        <div className="page">
            <Row>
                <Column sm="6" md="7" lg="8">
                    <ul className="breadcrumb">
                        <li><a href="#">My Profile</a></li>
                        <li className="active">{fullName}</li>
                    </ul>
                </Column>
            </Row>
            <Row>
                <Column lg="4">
                    <h2>Your Profile</h2>
                    <p>View and manage your account from here</p>
                    {!!latestLogin && (
                        <p>Your previous login was: {latestLogin}</p>
                    )}
                </Column>
                <Column lg="4">
                    <img src="" alt=""/>
                </Column>
            </Row>
            <h3>{_`Manage your User Profile`}</h3>
            <br />
            <Column sm='12'>
                    <TabNavigator
                        currentRoute={location.pathname}
                        tabPosition='top'
                        tabStyle='tab'
                    >
                        <NavTab title={_`Email`}
                            route={`${baseRoute}/change-email`}
                            prefixMatch={true} />
                        <NavTab title={_`Password`}
                            route={`${baseRoute}/password`}
                            prefixMatch={true} />                            
                        <NavTab title={_`Photograph`}
                            route={`${baseRoute}/photograph`}
                            prefixMatch={true} />
                        <NavTab title={_`Multi Factor Authentication`}
                                route={`${baseRoute}/mfa`}
                                prefixMatch={true} />
                    </TabNavigator>
            </Column>

            <Column sm='12'>
                {children}
            </Column>
        </div>
    );
};
