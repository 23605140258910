import { post, get, patch } from 'services/api';

interface ICreateTeamParams {
    name: string;
    organisation_id: number;
    service_id: number;
    parent_team_id?: number;
}

interface IEditTeamParams {
    operations: Array<{
        op: 'replace';
        path: string; // for example - /name
        value: string;
    }>;
    teamId: string;
}

class TeamsService {

    public list({teamId, organisationId}: {teamId?: number, organisationId?: number} = {}): Promise<any> {
        if(teamId) {
            return get(`/admin_portal/teams/${teamId}/teams?per_page=100`);
        }

        if(organisationId) {
            return get(`/admin_portal/organisations/${organisationId}/teams?per_page=100`);
        }

        return get(`/me/teams`);
    }

    public creatNewTeam(params: ICreateTeamParams) {
        return post(`/admin_portal/teams`, {body: params});
    }

    public editTeam({teamId, ...body}: IEditTeamParams) {
        return patch(`/admin_portal/teams/${teamId}`, {body});
    }

}

export const teamsService = new TeamsService();
