import React from 'react';

/**
 * Formats and returns an NHS number for display.
 * The input value should be a string of decimal digits.
 * Whitespace anywhere in the input value will be ignored.
 * If it is the wrong length or contains invalid characters
 *  then a console warning will be emitted.
 *
 * Returns the formatted NHS number as a string.
 * Returns an empty string if the input parameter is null or only whitespace.
 */
export function formatNhsNumber (value) {

    if (value == null) {
        return '';
    }

    // Remove all whitespace.
    value = value.replace(/\s/g, '');
    if (value == '') {
        return '';
    }

    // Output a warning if the input looks invalid.
    if (value.match(/^[0-9]{10}$/) == null) {
        console.warn('Invalid NHS number "' + value + '". Expected 10 decimal digits.');
    }

    // Format as: XXX YYY ZZZZ...
    // If there are more than 10 characters, display them all in the final group.
    return value.replace(/^(.{3})(.{3})?(.*)?$/g, "$1\xa0$2\xa0$3");
}


/**
 * Formats and renders an NHS number as text contained inside a <span> element.
 * Displays an empty span if no value is provided, or it is empty.
 * The input NHS number can be provided as the children of the component or
 *  as the value prop. (Value takes priority if it exists.)
 *
 * Properties:
 *  - value|childreen = Optional. The raw NHS number as a sequence of decimal 10 digits. Whitespace anywhere inside is ignored.
 *  - empty = Optional. Content to display if the value is empty. Defaults to nothing.
 *  - className = Optional. Class name to apply to the span. Defaults to 'nhs-number' if nothing is specified.
 *  - style = Optional. Inline style to apply to the span. Defaults to empty.
 */
export const NhsNumber = (props) => {
    const {children, value, className, style, empty} = props;
    let content = formatNhsNumber(value || children) || empty || '';
    return (
        <span className={className || 'nhs-number'} style={style || null}>
            {content}
        </span>
    );
};

export default NhsNumber;
