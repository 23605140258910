import React, { Component } from 'react';
import PageTitle from '../../../page-title';
import { Link, browserHistory} from 'react-router';
import predefinedMessageService from 'services/predefined-messages.service';
import _ from 'services/i18n';
import { ConfirmModal } from 'ui';
import './predefined-messages.less';
import Pagination from 'components/pagination';

const tag = '[Predefined Messages Page]';
export default class extends Component {

    constructor(props) {
        super(props);
        PageTitle.setTitle(`${_(['Predefined messages'])} | PHR Admin Portal`);
        this.state = {
            messages: [],
            total: 0,
            confirmDelete: false,
            loading: true,
            available: true,
            pageCount: 1
        };
        this.deleteMessage = this.deleteMessage.bind(this);
        this.limit = 10;
    }

    confirmDelete() {
        // dummy will be redefined inside showModal method
    }

    closeModal() {
        // dummy will be redefined inside showModal method
    }

    componentDidMount() {
        if (predefinedMessageService.isAvailable()) {
            this.loadMessages();
        } else {
            this.setState({available: false, loading: false});
        }
    }

    loadMessages(offset = 0) {
        this.setState({loading: true});
        return predefinedMessageService.list({limit: this.limit, offset})
            .then((result) => {
                const {message: {total, results: messages}} = result;
                this.setState({messages, total});
            })
            .catch((e) => {
                console.error(tag, e);
            })
            .then(() => {
                this.setState({loading: false});
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.page !== this.props.params.page) {
            this.loadMessages(((this.getPage() - 1) * this.limit));
        }
    }

    getPage(){
        const page = this.props.params.page || 'page-1';
        const pageNumber = Number(page.split('-')[1]);
        return isNaN(pageNumber) ? 0 : pageNumber;
    }

    showModal() {
        this.setState({confirmDelete: true});
        return new Promise((resolve, reject) => {
            this.confirmDelete = () => {
                this.setState({confirmDelete: false});
                resolve();
            };

            this.closeModal = () => {
                this.setState({confirmDelete: false});
                reject();
            };
        });
    }

    deleteMessage(uuid) {
        this.showModal()
            .then(() => {
                predefinedMessageService.delete({uuid})
                    .then(() => {
                        this.loadMessages((this.getPage() - 1) * this.limit);
                    })
                    .catch((e) => {
                        console.error(tag, e);
                    });
            })
            .catch(() => {
                // 'delete cancelled';
            });
    }

    render() {
        return (
            <div className="predefined-messages">
                <h3>{_`Predefined messages`}</h3>
                {this.state.available ?
                    this.state.loading && this.state.messages.length === 0 ?
                        <div className="predefined-messages_status">{_`Loading...`}</div> :
                        <Messages messages={this.state.messages}
                                  pageCount={Math.ceil(this.state.total / this.limit)}
                                  currentPage={this.props.params.page && this.props.params.page.split('-')[1] || 1}
                                  deleteMessage={this.deleteMessage}/> :
                    <div className="predefined-messages_status">{_`Not available.`}</div>}
                {this.state.confirmDelete &&
                <ConfirmModal onConfirm={this.confirmDelete} onClose={this.closeModal}>
                    {_`Are you sure you want to delete it?`}
                </ConfirmModal>}
            </div>
        );
    }
}

export const Messages = ({messages, pageCount, currentPage, deleteMessage}) => {
    return (<div>
        {messages.length ?
            <List messages={messages}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  deleteMessage={deleteMessage}/> :
            <div className="predefined-messages_status">{_`There are no predefined messages yet. You can create a new one.`}</div>
        }
        <Link className="btn btn-primary" to="/admin_portal/predefined-messages/edit/new">
            {_`Add New`}
        </Link>
    </div>);
};

export const List = ({messages, pageCount, currentPage, deleteMessage}) => {
    return (
        <div>
            <table className="table table-bordered table-responsive">
                <thead>
                <tr>
                    <th className="predefined-messages_title">{_`Name`}</th>
                    <th className="predefined-messages_content">{_`Content`}</th>
                    <th className="predefined-messages_action-buttons">{_`Actions`}</th>
                </tr>
                </thead>
                <tbody>
                {messages.map(({content: {name, content}, uuid}, i) =>
                    <tr key={i}>
                        <td>{name}</td>
                        <td className="predefined-messages_content">{content}</td>
                        <td className="predefined-messages_action-buttons">
                            <Link className="btn btn-default btn-xs"
                                  to={`/admin_portal/predefined-messages/edit/${uuid}`}>
                                {_`Edit`}
                            </Link>
                            <button className="btn btn-danger btn-xs"
                                    onClick={deleteMessage.bind(null, uuid)}>{_`Delete`}</button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            {pageCount > 1 &&
            <div>
                <Pagination
                    pageCount={pageCount}
                    currentPage={currentPage}
                    onChange={(page) => browserHistory.push(`/admin_portal/predefined-messages/page-${page}`)}
                />
                <p>
                    {_`Total number of pages:`} {pageCount}
                </p>
            </div>}
        </div>
    );
};
