import React, { ChangeEvent, Component } from 'react';
import { useTeamRoles, TEAM_ROLES_GROUPS } from 'common/useTeamRoles';
import { cliniciansService, TeamMember } from 'services/clinicians.service';
import { RoleDefinition } from 'services/roles.service';
import { connect } from 'react-redux';
import MemberListView from './view';
import { ModalChangeTypeProps, ModalDeleteUserProps, ModalType, ModalUserInviteProps, ShowModalParams } from 'components/user/member-list/memberListModals';
import { cloneDeep } from 'lodash';
import { makeDeleteRequest } from 'services/api';
import { apiV2Service } from 'services/api-v2.service';
import { DataStore } from 'services/data-store';
import { terService } from 'services/ter.service';
import { defaultSort, GridSortState } from 'common/ui/grid';
import { Filter, searchCallback } from 'common/ui/grid/paginatedTableReducer';
import { USER_STATUS } from 'models/UserFolder';
import './MemberList.scss';
import { ISearchUserFoldersParams } from 'phr-api-client/index';
import { PaginationWrapper } from 'components/pagination-wrapper/PaginationWrapper';
import { PaginationContext } from 'components/pagination-wrapper/PaginationContext';

interface MemberListState {
    searchText: string;
    pagination: {current: number};
    modal?: ShowModalParams;
    results: TeamMember[];
    perPage: number;
    count: number;
    loading: boolean;
    filterState: Filter;
    sortState: GridSortState;
    error: string;
}


const tag = '[MemberList]';
class MemberList extends Component<{clinicalRoles: RoleDefinition[]; listType: 'team' | 'org'}, MemberListState> {
    statusOptions: USER_STATUS[] = ['registered', 'pending', 'invited'] as USER_STATUS[];
    constructor (props) {
        super(props);

        this.state = {
            searchText: '',
            pagination: {
                current: 1
            },
            modal: undefined,
            results: [],
            perPage: 10,
            count: 0,
            loading: true,
            error: undefined,
            filterState: {},
            sortState: {}
        };
        this.onChangeUserRoles = this.onChangeUserRoles.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.onInviteUser = this.onInviteUser.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onSort = this.onSort.bind(this);
    }

    componentDidMount(){
        if (this.props.clinicalRoles) {
            this.loadComponent();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.clinicalRoles && (!prevProps.clinicalRoles)) {
            this.loadComponent();
        }
    }

    filterOptions = {
        handlers: {
            name: (filterValue: string, value, user: TeamMember) => {
                const name = `${user?.user_details?.name?.given_name || ''} ${user?.user_details?.name?.family_name || ''}`;
                const matchesName = name.toUpperCase().includes(filterValue.toUpperCase());
                return filterValue === '' || matchesName;
            },
            email: (filterValue: string, value, user: TeamMember) => {
                const email = user?.user_details?.email || '';
                return filterValue === '' || email.toUpperCase().includes(filterValue.toUpperCase());
            },
            roles: (filterValue: string, value, user: TeamMember) => {
                if (filterValue === '') {
                    return true;
                }
                const role = this.props.clinicalRoles.find((role) => role.name === filterValue);
                if (!role) {
                    return false;
                }
                const hasRole = user.user_roles.find((roleName) => roleName === role.name);
                return hasRole !== undefined;
            },
            status: (filterValue: string, value, user: TeamMember) => {
                if (filterValue === '') {
                    return true;
                }
                return user.status === filterValue;
            },
        }
    };

    sortOptions = {
        handlers: {
            name: (a: TeamMember, b: TeamMember, sortVector) => {
                const aValue = a?.user_details?.name?.given_name || '';
                const bValue = b?.user_details?.name?.given_name || '';
                return defaultSort(aValue, bValue, sortVector);
            },
            email: (a: TeamMember, b: TeamMember, sortVector) => {
                const aValue = a?.user_details?.email || '';
                const bValue = b?.user_details?.email || '';
                return defaultSort(aValue, bValue, sortVector);
            },
            status: (a: TeamMember, b: TeamMember, sortVector) => {
                return defaultSort(a.status, b.status, sortVector);
            },
            roles: (a: TeamMember, b: TeamMember, sortVector) => {
                const aValue = a?.user_roles.join(', ');
                const bValue = b?.user_roles.join(', ');
                return defaultSort(aValue, bValue, sortVector);
            }
        }
    };

    async handleSubmitUserType (selectedRoles: RoleDefinition[], user: TeamMember) {
        if(!selectedRoles.length) {
            
            this.setModalError(this.state.modal as ModalChangeTypeProps, 'Should be selected at least one role');
            return;
        }

        const rolesToAssign = selectedRoles.filter((role) => !user.user_roles.includes(role.name));

        const roleNamesToUnassign = user.user_roles.filter(roleName => !selectedRoles.map(role => role.name).includes(roleName));
        const rolesToUnassign = this.props.clinicalRoles.filter(role => roleNamesToUnassign.includes(role.name));


        const results = cloneDeep(this.state.results);
        user = results.find((member) => member.user_id === user.user_id);

        // eslint-disable-next-line @typescript-eslint/camelcase
        user.user_roles = selectedRoles.map(({ name }) => name);
        this.setState({
            modal: undefined,
            results
        });

        try {
            const role = DataStore.get('me.currentRole');
            await Promise.all(rolesToAssign.map(async ({ uuid }) => {
                return await apiV2Service.assignTeamRole({
                    role,
                    folderId: user.folder_id,
                    newRoleUuid: uuid,
                });
            }));
            await Promise.all(rolesToUnassign.map(async ({ uuid }) => {
                return await apiV2Service.unassignTeamRole({
                    role,
                    folderId: user.folder_id,
                    roleUuidToUnassign: uuid,
                });
            }));
        } catch (e) {
            console.error(tag, 'Unable to Change user roles', e);
            this.setState({ error: 'Unable to change user roles, please try again later' });
            this.loadComponent();
        }
    }

    setModalError(modal: ModalChangeTypeProps, err) {
        this.setState({
            modal: {
                ...modal,
                params: {
                    ...modal.params,
                    error: err
                }
            }
        });
    }


    async getClinicians() {
        const clinicalRoles = this.props.clinicalRoles;

        const clinicalRoleNameList = clinicalRoles.map(role => role.name);
        const memberListResponse = await cliniciansService.getAll(clinicalRoleNameList);

        const memberList = memberListResponse.message;

        this.setState({
            results: memberList,
            count: memberList.length,
            loading: false,
        });
    }

    async loadComponent () {
        this.setState({
            loading: true
        });

        await this.getClinicians();

        this.setState({
            loading: false,
        });
    }

    async handleChangePage (newPage) {
        const nextPartState = {
            pagination: {
                current: newPage
            },
        };
        await new Promise((resolve) => this.setState(nextPartState, () => resolve(null)));
        if(this.state.searchText.trim()) {
            await this.onSearchUsers();
        } else {
            await this.loadComponent();
        }
    }

    async onSubmitDeleteUser (user: TeamMember) {
        this.setState({ modal: undefined });
        let results = cloneDeep(this.state.results);
        results = results.filter((item) => item.user_id !== user.user_id);
        this.setState({
            results
        });

        try {
            let path = '/api/admin_portal';
            if(this.props.listType === 'team') {
                path += '/teams';
                return terService.createTer({
                    action: 'removeUserFromTeam',
                    folderId: user.folder_id,
                });
            }

            path += '/organisations';

            if (user.status == 'registered') {
                path += `/remove-user?user_id=${user.user_id}&team_id=${user.team_id}`;
            } else {
                path += `/remove-user-invite?invite_id=${user.invite_id}&team_id=${user.team_id}`;
            }

            await makeDeleteRequest(path, {});
        } catch (e) {
            console.error(tag, 'Unable to submit delete user', e);
            this.setState({ error: 'Unable to delete user, please try again later' });
            this.loadComponent();
        }
    }

    handleDeleteUser (user: TeamMember) {
        const deleteUserModalProps: ModalDeleteUserProps = {
            name: ModalType.deleteUser,
            params: {
                onClose: () => this.setState({ modal: undefined }),
                entityType: this.props.listType,
                onSubmitDeleteUser: () => {
                    this.onSubmitDeleteUser(user);
                },
                name: (`${user?.user_details?.name?.given_name || ''} ${user?.user_details?.name?.family_name || ''}`).trim() || 'Unknown',
                email: user?.user_details?.email,
                roles: (user?.user_roles || []).join(', '),
                status: user.status,
                busy: false,
            }
        };

        this.setState({
            modal: deleteUserModalProps,
        });

    }

    onChangeUserRoles(user: TeamMember) {
        const changeUserModalProps: ModalChangeTypeProps = {
            name: ModalType.changeType,
            params: {
                user,
                onClose: () => this.setState({ modal: undefined }),
                onSubmitUserType: (selectedRoles) => {
                    this.handleSubmitUserType(selectedRoles, user);
                },
                busy: false,
            },
        };

        this.setState({
            modal: changeUserModalProps,
        });
    }

    onInviteUser() {
        const inviteModalProps: ModalUserInviteProps = {
            name: ModalType.userInvite,
            params: {
                onClose: () => this.setState({ modal: undefined }),
            }
        };

        this.setState({
            modal: inviteModalProps, 
        });
    }

    onSort(sortState: GridSortState) {
        this.setState({
            sortState,
        });
    }

    onFilter(filterState: Filter) {
        this.setState({
            filterState,
        });
    }

    onSearchChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchText: e.target.value,
        });
    }
    async onSearchUsers () {
        this.setState({
            loading: true,
        });
        const offset = (this.state.pagination.current - 1) * this.state.perPage;
        const role = DataStore.get('me.currentRole');
        const searchParam: ISearchUserFoldersParams = {
            teamId: Number(role.teamId),
            searchString: this.state.searchText,
            roleUuid: role.uuid,
            offset,
            limit: this.state.perPage,
        };
        const response = await apiV2Service.searchUserFolders(searchParam);
        this.setState({
            results: response.message.results,
            count: response.message.total,
            loading: false,
        });
    }

    onSearchSubmit(e: MouseEvent, onSearch: searchCallback): void {
        e.preventDefault();
        const { searchText } = this.state;
        onSearch((item) => {
            const name = `${item?.user_details?.name?.given_name || ''} ${item?.user_details?.name?.family_name || ''}`.toUpperCase();
            const email = item?.user_details?.email || '';
            return name.includes(searchText.toUpperCase()) || email.toUpperCase().includes(searchText.toUpperCase());
        });
    }

    render() {

        const roleNames = this.props.clinicalRoles?.map(d => d.name);

        const {
            modal,
            loading,
            error,
            results,
            sortState,
            filterState
        } = this.state;

        return (
            <PaginationWrapper
                perPage={10}
                loading={loading}
                sortOrder={sortState}
                filterState={filterState}
                sortOptions={this.sortOptions}
                filterOptions={this.filterOptions}
                data={results}>
                <PaginationContext.Consumer>
                    {value => (
                        <MemberListView
                            entityType={this.props.listType}
                            loading={loading}
                            results={value.data as TeamMember[]}
                            modal={modal}
                            error={error}
                            onChangeUserRoles={this.onChangeUserRoles}
                            onDeleteMember={this.handleDeleteUser}
                            onUserInvite={this.onInviteUser}
                            onChangePage={this.handleChangePage}
                            onSearchSubmit={((e: MouseEvent) => this.onSearchSubmit(e, value.onSearch))}
                            onSearchChange={this.onSearchChange}
                            onSort={this.onSort}
                            onFilter={this.onFilter}
                            filterRoleOptions={roleNames}
                            filterStatusOptions={this.statusOptions}
                            filterState={filterState}
                            sortState={sortState}
                            searchString={this.state.searchText}
                        />
                    )}
                </PaginationContext.Consumer>
            </PaginationWrapper>
        );
    }
}

function MemberListWithClinicianRoles(props) {
    const [, teamRolesGroups] = useTeamRoles();
    const clinicalRoles = teamRolesGroups.get(TEAM_ROLES_GROUPS.CLINICIAN);

    function mapDispatchToProps(dispatch) {
        return {
            dispatch,
        };
    }

    function mapStateToProps({ adminPortalMembersList }) {
        return {
            reduxStore: adminPortalMembersList.toJS(),
            clinicalRoles,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(MemberList);

    return <ReduxComponent {...props} />;
}

export default MemberListWithClinicianRoles;
