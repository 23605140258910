import { useEffect } from 'react';

export function useSafeAsyncUpdate<R>(
    asyncFn: () => Promise<R>,
    onAsyncResponse: (result: R) => unknown
) {
    useEffect(() => {
        let active = true;
        asyncFn()
            .then((asyncResponse) => {
                if (active) {
                    onAsyncResponse(asyncResponse);
                }
            });
        // Return a cleanup function which sets the query to inactive,
        // so if the hook is called again or the component is unmounted,
        // we'll ignore old responses arriving out of order.
        return () => active = false;
    }, [asyncFn, onAsyncResponse]);
}
