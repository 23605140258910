export enum MODAL_TYPE {
    PATHWAY = 'Pathway',
    DASHBOARD = 'Dashboard', 
    APPOINTMENTS = 'Appointments',
    PROMS = 'PROMs',
    QUESTIONNAIRES = 'Questionnaires',
    RESOURCES = 'Resources',
    MESSAGES = 'Messages',
    REFERRALS = 'Referrals',
    GOALS = 'Goals',
    COMMUNICATIONS = 'Communications',
    SETTINGS = 'Settings',
    GRAPHS = 'Graphs',
    USER_NOTES = 'User Notes'
}
