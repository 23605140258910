import React from 'react';
import _ from 'services/i18n';

const ErrorBox = (props) => {
    let errorList;
    let errors = props.errors || [];
    if (errors.length == 0) {
        return (<noscript />);
    }

    errorList = errors.map((item, idx) => {
        return (
            <li style={{margin: "0px", padding: "0px", listStylePosition: "inside"}} key={idx}>{item}<br /></li>
        );
    });

    if (errorList.length > 1) {
        return (
            <div className="alert alert-danger">
                <span>{_`The following errors need to be corrected:`}<br /></span>
                <ol style={{margin: "0px", padding: "0px"}}>
                    {errorList}
                </ol>
            </div>
        );
    } else {
        return (
            <div className="alert alert-danger">
                <span>{_`The following error needs to be corrected:`}<br /></span>
                <ul style={{margin: "0px", padding: "0px"}}>
                {errorList}
                </ul>
            </div>
        );
    }
}

export default ErrorBox;
