import { demographicsService } from 'services/demographics.service';
import { Demographics } from 'models/compositions/Demographics';
import { DemographicsData } from 'features/patient/patient-dashboard/dashboard-components/dashboard-demographics-table/DashboardDemographicsTable';
import _ from 'services/i18n';
import { PRIMARY_IDENTIFIER_NAMESPACE } from 'models/PersonPrimaryIdentifier';
export const getDataForDemographicTable = async (params: {folderId: number}): Promise<DemographicsData> => {
    const { folderId } = params;
    if(!folderId) {
        return;
    }
    const response =  await demographicsService.getFirst({ folderId });
    if (response?.content) {
        return prepareDemographics(response.content);
    }
};

export const prepareDemographics = (data: Demographics, carerId?: string): DemographicsData => {
    const name = [data.name?.family_name || '', data.name?.given_name || ''].join(' ') || '-';
    const primaryIdentifier = {
        label: carerId ? _`Carer Id` : _`NHS Number`,
        value: carerId ?? data.identifiers.nhs_number,
        namespace: carerId ? PRIMARY_IDENTIFIER_NAMESPACE.CARER_ID : PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER
    };
    return {
        name,
        dob: data.date_of_birth || '-',
        gender: data.gender || '-',
        email: data.email?.[0] || '-',
        primaryIdentifier
    };
};
