import { get } from 'services/api';
import { teamsService } from './teams.service';

class OrganisationsService {

    list(): Promise<any> {
        return get(`/me/organisations?limit=100`);
    }

    teams(organisationId: number): Promise<any> {
        return teamsService.list({organisationId});
    }

    services(): Promise<any> {
        return get(`/admin_portal/services?per_page=100`);
    }
}

export const organisationsService = new OrganisationsService();
