// generated by https://app.quicktype.io/
// from https://bitbucket.org/adihealth/private-server/src/master/resources/schemas/archetypes/coop-resource.json

import { CoopResourceCategory } from 'models/compositions/CoopResource';

// To parse this data:
//
//   import { Convert, Resource } from "./file";
//
//   const resource = Convert.toResource(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface Resource {
    category: CoopResourceCategory[];
    /**
     * UUID of the original coopResponse in team folder this one is a copy of
     */
    created_based_on_uuid?:   string;
    description?:             string;
    hash?:                    string;
    html_content?:            string;
    label:                    string;
    labels?:                  Label[];
    media_resource_mimetype?: string;
    /**
     * The UUID of linked media-resource composition
     */
    media_resource_uuid?: string;
    name_of_issuer?:      string;
    phone?:               string;
    resource_type:        ResourceType;
    url?:                 string;
    /**
     * For sorting resources in the united list. The bigger is value - the higher is resource in
     * list. Non-existing values are treated as zero
     */
    weight?: number;
}

export interface Category {
    category_folder_id?: number;
    category_id?:        string;
    name?:               string;
    /**
     * For sorting resources inside each category. The bigger is value - the higher is resource
     * in list. Non-existing values are treated as zero
     */
    weight?: number;
}

/**
 * Schema for the labels array structure.
 */
export interface Label {
    /**
     * An indication to what it is the name is referring to in the context of the label type
     */
    context?:    string;
    event_uuid?: string;
    /**
     * Short (machine-usable - e.g. in Trigger Engine rules) name for label
     */
    name: string;
    /**
     * The referral to which the type/context of the label is related
     */
    referral_uuid?: string;
    /**
     * True if the label is currently active
     */
    status: boolean;
    /**
     * Short human-readable string to identify the primary purpose of the label
     */
    type: string;
}

export enum MediaResourceType {
    Audio = 'audio',
    Image = 'image',
    Video = 'video',
    Document = 'document',
    Link = 'link'
}

export enum ResourceType {
    Audio = 'audio',
    HTML = 'html',
    MediaResource = 'media_resource',
    PDF = 'pdf',
    Phone = 'phone',
    URL = 'url',
    Video = 'video',
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toResource(json: string): Resource {
        return cast(JSON.parse(json), r('Resource'));
    }

    public static resourceToJson(value: Resource): string {
        return JSON.stringify(uncast(value, r('Resource')), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
    if (key) {
        throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
    }
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`, );
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) {return val;}
        return invalidValue(typ, val, key);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {
                //
            }
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) {return val;}
        return invalidValue(cases, val);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) {return invalidValue('array', val);}
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue('Date', val);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== 'object' || Array.isArray(val)) {
            return invalidValue('object', val);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, prop.key);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key);
            }
        });
        return result;
    }

    if (typ === 'any') {return val;}
    if (typ === null) {
        if (val === null) {return val;}
        return invalidValue(typ, val);
    }
    if (typ === false) {return invalidValue(typ, val);}
    while (typeof typ === 'object' && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) {return transformEnum(typ, val);}
    if (typeof typ === 'object') {
        // eslint-disable-next-line no-prototype-builtins
        return typ.hasOwnProperty('unionMembers') ? transformUnion(typ.unionMembers, val)
            // eslint-disable-next-line no-prototype-builtins
            : typ.hasOwnProperty('arrayItems')    ? transformArray(typ.arrayItems, val)
                // eslint-disable-next-line no-prototype-builtins
                : typ.hasOwnProperty('props')         ? transformObject(getProps(typ), typ.additional, val)
                    : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== 'number') {return transformDate(val);}
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    'Resource': o([
        { json: 'category', js: 'category', typ: a(r('Category')) },
        { json: 'created_based_on_uuid', js: 'created_based_on_uuid', typ: u(undefined, '') },
        { json: 'description', js: 'description', typ: u(undefined, '') },
        { json: 'hash', js: 'hash', typ: u(undefined, '') },
        { json: 'html_content', js: 'html_content', typ: u(undefined, '') },
        { json: 'label', js: 'label', typ: '' },
        { json: 'labels', js: 'labels', typ: u(undefined, a(r('Label'))) },
        { json: 'media_resource_mimetype', js: 'media_resource_mimetype', typ: u(undefined, '') },
        { json: 'media_resource_uuid', js: 'media_resource_uuid', typ: u(undefined, '') },
        { json: 'name_of_issuer', js: 'name_of_issuer', typ: u(undefined, '') },
        { json: 'phone', js: 'phone', typ: u(undefined, '') },
        { json: 'resource_type', js: 'resource_type', typ: r('ResourceType') },
        { json: 'url', js: 'url', typ: u(undefined, '') },
        { json: 'weight', js: 'weight', typ: u(undefined, 0) },
    ], false),
    'Category': o([
        { json: 'category_folder_id', js: 'category_folder_id', typ: u(undefined, 0) },
        { json: 'category_id', js: 'category_id', typ: u(undefined, '') },
        { json: 'name', js: 'name', typ: u(undefined, '') },
        { json: 'weight', js: 'weight', typ: u(undefined, 0) },
    ], false),
    'Label': o([
        { json: 'context', js: 'context', typ: u(undefined, '') },
        { json: 'event_uuid', js: 'event_uuid', typ: u(undefined, '') },
        { json: 'name', js: 'name', typ: '' },
        { json: 'referral_uuid', js: 'referral_uuid', typ: u(undefined, '') },
        { json: 'status', js: 'status', typ: true },
        { json: 'type', js: 'type', typ: '' },
    ], 'any'),
    'ResourceType': [
        'audio',
        'html',
        'media_resource',
        'pdf',
        'phone',
        'url',
        'video',
    ],
};
