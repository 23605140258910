/* eslint-disable indent */
import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router';
import Select from 'react-select';
import ListAltIcon from '@material-ui/icons/ListAlt';
// components
import { Loading, LoadingError } from 'common/ui/alert-boxes';
import AdvancedSearchResultsDisplay from 'features/patients/advanced-search/AdvancedSearchResultsDisplay';
import AdvancedSearchSetupModal from 'features/patients/advanced-search/AdvancedSearchSetupModal';
import AdvancedSearchQueryToolbar from 'features/patients/advanced-search/AdvancedSearchQueryToolbar';
// utils
import confirmationModal from 'components/ConfirmationModal';
import {
    AdvancedSearch,
    getDocumentSearchConfig,
} from 'models/AdvancedSearchDefinition';
import {
    useAdvancedSearchResultsManager,
    useSelectionStateContainer,
} from 'features/patients/advanced-search/AdvancedSearchServiceProvider';
import { handleExportAggregateQuery } from './AdvancedSearchComponent.utils';
import { useSortedDepartmentCompositionData } from 'features/department/hooks/useSortedDepartmentData';
import { findDepartmentDataOptionInGroupedList } from 'features/department/department.utils';
import { useGroupedDepartmentDataOptionList } from 'features/department/hooks/useGroupedDepartmentDataOptionList';
// context
import { SelectionStateContext } from 'components/dataSource/FolderSelectionState';
import { AdvancedSearchHelperContext } from 'features/patients/advanced-search/advanced-search-helper-context';
// interfaces
import { SortOrder } from 'common/ui/grid';
import { Composition } from 'models/Composition';
import { AdvancedSearchComponentProps } from './AdvancedSearchComponent.interface';
// styles
// TODO extract relevant styles into separate file
import '../../AdvancedSearchResultDisplay.scss';

const initialSortState = {
    'result.content.name.family_name': SortOrder.ASC,
};

const AdvancedSearchComponent: FC<AdvancedSearchComponentProps> = ({
    searchService,
    selectedSearchUuid,
    changeSelectedSearchUuid,
    searchList,
    loadSearchList,
    documentSearchConfigList,
}) => {
    const [showSetupModal, setShowSetupModal] = useState<{ composition: Composition<AdvancedSearch> }>(null);

    const sortedSearchCompositions = useSortedDepartmentCompositionData(searchList);
    const [searchesDisplayList, totalSearches] = useGroupedDepartmentDataOptionList(sortedSearchCompositions, s => s.content.name);
    const selectedSearchItem = selectedSearchUuid ? findDepartmentDataOptionInGroupedList(searchesDisplayList, item => item.uuid == selectedSearchUuid) : null;

    const selectedSearch = selectedSearchItem ? selectedSearchItem.value : null;

    const searchQueryResultsManager = useAdvancedSearchResultsManager(
        searchService,
        selectedSearch,
        initialSortState,
    );

    const selectionStateContainer = useSelectionStateContainer(
        searchQueryResultsManager,
    );

    const onSave = useCallback((saveResponse) => {
        loadSearchList().then(() => {
            const uuid =
                typeof saveResponse == 'string'
                    ? saveResponse
                    : saveResponse.uuid;
            changeSelectedSearchUuid(uuid);
            setShowSetupModal(null);
        });
    }, [changeSelectedSearchUuid, loadSearchList]);

    const error = searchQueryResultsManager.error;
    const isLoading = searchQueryResultsManager.loading || !documentSearchConfigList;
    const hasResults = !error && !isLoading;

    const searchRootName = documentSearchConfigList
        && selectedSearch
            ? getDocumentSearchConfig(documentSearchConfigList, selectedSearch.content.queryDefinition.documentType).name
            : '';

    const onSearchDefinitionDelete = async () => {
        const confirmDeletion = await confirmationModal.show('Deleting a filter is irreversible. Do you wish to continue?');
        if (confirmDeletion) {
            searchService
                .deleteAdvancedSearch(selectedSearch.uuid)
                .then(() => loadSearchList())
                .then(() => changeSelectedSearchUuid(null));
        }
    };

    const onSearchStaticSettingChange = (isActive: boolean) => {
        searchService.updateAdvancedSearch(selectedSearch.uuid, {
                ...selectedSearch.content,
                static: !isActive,
            })
            .then(() => loadSearchList());
    };

    return (
        <>
            <AdvancedSearchHelperContext.Provider
                value={{ refresh: searchQueryResultsManager.onRefresh }}
            >
                <SelectionStateContext.Provider value={selectionStateContainer}>
                    <div className={'advanced-search-container'}>
                        <div className={'advanced-search-query-banner'}>
                            <div className={'advanced-search-query-navigation'}>
                                <Select
                                    className="filter-select"
                                    classNamePrefix="filter-select"
                                    placeholder={`Custom results (${totalSearches})`}
                                    options={searchesDisplayList}
                                    onChange={(option) =>
                                        changeSelectedSearchUuid(
                                            option.value.uuid,
                                        )
                                    }
                                    value={selectedSearchItem}
                                />
                                <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                        setShowSetupModal({ composition: null })
                                    }
                                >
                                    Add New Filter
                                </button>
                                <Link
                                    title="View History"
                                    className={
                                        'btn btn-xs btn-default icon-button'
                                    }
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    to={'/clinical_portal/bulk-operations'}
                                >
                                    <ListAltIcon style={{ fontSize: 20 }} />
                                </Link>
                            </div>
                            <div className={'push'} />
                            {selectedSearch && (
                                <AdvancedSearchQueryToolbar
                                    isStaticSearch={
                                        selectedSearch.content.static
                                    }
                                    hasQueryResults={
                                        hasResults &&
                                        searchQueryResultsManager.queryResults
                                            .length > 0
                                    }
                                    onRefresh={
                                        searchQueryResultsManager.onRefresh
                                    }
                                    onSearchDefinitionEdit={() =>
                                        setShowSetupModal({
                                            composition: selectedSearch,
                                        })
                                    }
                                    onSearchDefinitionDelete={
                                        onSearchDefinitionDelete
                                    }
                                    onSearchStaticSettingChange={
                                        onSearchStaticSettingChange
                                    }
                                    onQueryResultExport={() =>
                                        handleExportAggregateQuery(
                                            searchService,
                                            selectedSearch,
                                        )
                                    }
                                    searchRootName={searchRootName}
                                    queryName={
                                        selectedSearch
                                            ? selectedSearch.content.name
                                            : ''
                                    }
                                    queryResultsLastUpdated={
                                        selectedSearch
                                            ? selectedSearch.content
                                                  .viewUpdatedAt
                                            : ''
                                    }
                                />
                            )}
                        </div>
                        {error && <LoadingError />}
                        {isLoading && selectedSearch && <Loading />}
                        {selectedSearch && !isLoading && !error && (
                            <AdvancedSearchResultsDisplay
                                documentSearchConfigs={documentSearchConfigList}
                                searchDefinition={selectedSearch.content}
                                searchResultsManager={searchQueryResultsManager}
                            />
                        )}
                    </div>
                </SelectionStateContext.Provider>
            </AdvancedSearchHelperContext.Provider>
            {showSetupModal && (
                <AdvancedSearchSetupModal
                    searchService={searchService}
                    searchConfigs={documentSearchConfigList}
                    searchComposition={showSetupModal.composition}
                    onClose={() => setShowSetupModal(null)}
                    onSave={onSave}
                />
            )}
        </>
    );
};

export default AdvancedSearchComponent;
