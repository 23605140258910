import React, { useState } from 'react';
import Modal from 'ui/modal';
import TriageDecisionForm from 'components/user/triage-decision/form';
import lodash from 'lodash';
import * as AlertBoxes from 'common/ui/alert-boxes';
import { appointmentsService } from 'services/appointments.service';
import { setAppointmentMode } from 'services/appointment-helpers';
import { browserHistory } from 'react-router';
import { triageDecisionFlat } from 'components/user/triage-decision/form/triage-decision-codes';

const TriageAndInviteAction = ({
    folderId,
    teamId
}) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [savingErrorMessage, setSavingErrorMessage] = useState<string>(null);
    const [triageModalOpen, setTriageModalOpen] = useState<boolean>(false);
    const [appointment, setAppointment] = useState({
        transport: {
            required: false,
            details: ''
        },
        interpreter: {
            required: false,
            details: ''
        }
    });

    const checkUBRN = (data: Map<any, any>): Promise<Map<any, any>> => {
        const ubrn = data.get('tdf-appointment-ubrn');
        if (!ubrn) {
            return Promise.reject('Appointment UBRN not specified in form data.');
        }

        const search = {'content.ubrn': {$eq: ubrn}};
        const limit = 1;
        return appointmentsService.search({search, limit}).then((response) => {
                const count = lodash.get(response, 'message.total', undefined);
                if (count === undefined) {
                    return Promise.reject('Unexpected response structure from server.');
                }
                return (count > 0);
            })
            .then((exists) => {
                if (exists) {
                    setSavingErrorMessage('That appointment UBRN already exists. Perhaps this appointment has already been added to the system?');
                    return Promise.reject('Appointment UBRN conflict');
                }
                return data;
            });
    }

    const submitAppointment = (data: Map<any, any>) => {

        const content = lodash.cloneDeep(appointment) as any;

        // Set various values which are fixed for this process.
        content.location = '';
        content.status = 'available-to-book';
        content.details = "You can now book your first appointment. This will take you to the NHS e-Referral booking service";

        content.booking_password = data.get('tdf-booking-password') || undefined;

        // Store the other values specified on the form.
        content.ubrn = data.get('tdf-appointment-ubrn');
        if (!content.ubrn) {
            return Promise.reject('Appointment UBRN not specified.');
        }
        content.type = data.get('tdf-appointment-type');
        if (!content.type) {
            return Promise.reject('Appointment type not specified.');
        }

        const mode = data.get('tdf-appointment-mode');
        if(!mode) {
            return Promise.reject('Appointment mode not specified.');
        }

        // Make sure the appointment date is marked as unknown.
        content.unknown_date = true;

        const triage_decision = data.get('tdf-triage-decision');
        if (!triage_decision) {
            return Promise.reject('Triage decision code not specified.');
        }

        //Nasty hacky stuff carried over from the existing form. Not removing for now as miles out of scope of this change
        if (triage_decision === 'Clinic PA') {
            content.additional_details = 'You may find that you are unable to book an appointment as there are none available. In this case, Sheffield Teaching Hospitals Pain Clinic will know about your referral and will be in touch with an appointment as soon as possible. If you wish to speak to someone about your appointment please call 0114 271 5210.';

        } else if (triage_decision === 'Clinic PD') {
            content.additional_details = 'Your appointment will be at a clinic provided by Pain Management Solutions. Unfortunately, we are not currently able to connect MyPathway to their appointment system, so details of your appointment will not appear on your MyPathway timeline. If you wish to speak to someone about your appointment, please call Pain Management Solutions on 0800 034 0406. This includes appointments at the Concord Move More Centre, Dovercourt Surgery, Mill Road Surgery or Sloane Medical Practice.';

        } else if (triage_decision.indexOf('Clinic O') === 0) {
            content.additional_details = '**If you choose to book with one of our partner hospitals...**  \n\nYou can book through the link above with our partner hospitals but your appointment details will not appear on your MyPathway timeline before your appointment. If you have any further queries about this appointment then please contact your chosen hospital: \n\n- Barlborough NHS Treatment Centre on 0333 200 4066 \n\n- Claremont Hospital on 0114 263 0330 \n\n- Thornbury Hospital on 0114 266 1133 \n\n- Pain Management Solutions (PMS) on 0800 034 0406 \n\n**If you try to book an appointment but none are available.** \n\n You may not be able to book an appointment as there might not be any available. If this is the case, Sheffield Teaching Hospitals will contact you with an appointment as soon as possible. If you wish to speak to someone about your appointment please call 0114 226 6255.';

        } else if (triage_decision.indexOf('Clinic PW') === 0  || triage_decision.indexOf('Clinic VF') === 0) {
            delete content.additional_details;
        } else {
            content.additional_details = "Please book your appointment as soon as possible. You will receive a reminder letter if you haven’t done this.\nIf you do not book your appointment you will be referred back to your GP and your treatment will be delayed.";
        }

        content.timeline = {};
        content.timeline.title = (lodash.get(triageDecisionFlat, triage_decision, '') + ' Appointment').trim();

        //New in this change - we now cache the triage decision on the ATB appointment in order to carry it over later
        content.booking_request = {};
        content.booking_request.triage_decision = triage_decision;

        lodash.set(content, 'timeline.booking_link_url','https://www.ebs.ncrs.nhs.uk/pabs/login');
        lodash.set(content, 'timeline.booking_username_label', 'Booking UBRN');
        lodash.set(content, 'timeline.booking_password_label', 'Booking Password');
        lodash.set(content, 'timeline.booking_link_label', 'Click here to book your appointment');
        lodash.set(content, 'timeline.booking_link_advice', 'You will need your booking UBRN and booking password to do this.');

        if (!content.interpreter.details) delete content.interpreter.details;
        if (!content.transport.details) delete content.transport.details;

        return appointmentsService.create({content}).then((uuid) => {
            return setAppointmentMode(uuid, mode, folderId, teamId);
        });
    }

    const onFormChange = (id, rawValue, effectiveValue, valid) => {
        if (rawValue === undefined && effectiveValue === undefined && valid === undefined) {
            return;
        }

        const newAppointment = lodash.cloneDeep(appointment);

        switch (id) {
            case 'tdf-transport-required':
                newAppointment.transport.required = effectiveValue;
                if (!effectiveValue) {
                    newAppointment.transport.details = '';
                }
                break;
            case 'tdf-interpreter-required':
                newAppointment.interpreter.required = effectiveValue;
                if (!effectiveValue) {
                    newAppointment.interpreter.details = '';
                }
                break;
            case 'tdf-transport-details':
                newAppointment.transport.details = effectiveValue;
                break;
            case 'tdf-interpreter-details':
                newAppointment.interpreter.details = effectiveValue;
                break;
        }
        setAppointment(newAppointment)
    }
    const onSubmit = (data) => {
        setBusy(true);
        setSavingErrorMessage(null);

        return checkUBRN(data)
            .then(submitAppointment)
            .then(() => {
                setSavingErrorMessage(null);
                setBusy(false);
                setTriageModalOpen(false);
                browserHistory.push(`/clinical_portal/folder/${folderId}/patient/appointments`);
            })
            .catch((err) => {
                console.warn(err);
                setBusy(false);
                setSavingErrorMessage('An error occurred.');
                AlertBoxes.scrollToFirstAlertBox();
            });
    }

    return (<>
        <a onClick={() => setTriageModalOpen(true)}>Triage & Invite To Book</a>
        <AlertBoxes.SavingError show={savingErrorMessage && !busy}>{savingErrorMessage}</AlertBoxes.SavingError>
        {triageModalOpen && <Modal title={'Triage & Invite To Book'} onClose={() => setTriageModalOpen(false)}
                buttons={[]}>
            <TriageDecisionForm
                busy={busy}
                onSubmit={onSubmit}
                onFormChange={onFormChange}
                onCancel={() => setTriageModalOpen(false)}
                appointment={appointment}
                referral={null}
                appointmentOnly={true}
            />
        </Modal>}
    </>);
}

export default TriageAndInviteAction;