import { AUTH } from 'actions/types';
import { combineReducers } from 'redux';
import adminPortalMembersReducer from './admin-portal/members-page.reducer';
import authReducer from './auth.reducer';
import patientReducer from './patient.reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    currentlyViewedPatient: patientReducer,
    adminPortalMembersList: adminPortalMembersReducer
});

export default (state: any, action: any) => {
    if ([AUTH.LOGOUT_SUCCESSFUL, AUTH.AUTH_FAILED].indexOf(action.type) !== -1) {
        state = undefined;
    }
    return rootReducer(state, action);
};
