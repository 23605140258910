import { useState, useEffect, useCallback } from 'react';
import ServerFetch from 'server-fetch';
import DataStore from '../services/data-store';

import { demographicsService } from 'services/demographics.service';

export function useCurrentUser(portal = 'clinical_portal') {
    const [user, setUser] = useState(undefined);

    const setUserFields = useCallback(
        (fields) => {
            setUser((user) => user ? { ...user, ...fields } : { ...fields });
        },
        [],
    );

    useEffect(() => {
        let listenIdx;

        function getUserProfile() {
            return ServerFetch.getRequest(this, '/api/api/currentUserProfile',
                (response) => {
                    const currentUser = response.message || {};
                    const emails = (currentUser.emails || [])[0] || {};
                    const userFolderId = DataStore.get('userFolderId');

                    const userFields = {
                        id: currentUser.id,
                        name: currentUser.name,
                        preferredName: currentUser.preferred_name,
                        email: emails.email,
                        photoUrl: `/files/${portal}/api/currentUserPhoto`,
                        userFolderId,
                    };

                    DataStore.set('currentUser', userFields);
                    setUserFields(userFields);
                }
            );
        }

        listenIdx = DataStore.addListener('currentUser',
            /**
             * @param  {Object} user
             */
            (user) => {
                if (!user) {
                    return getUserProfile();
                }
                const emails = (user.emails || [])[0] || {};
                setUserFields({
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    preferredName: user.preferred_name,
                    email: emails.email,
                    photoUrl: `/files/${portal}/api/currentUserPhoto`
                });
            }
        );

        const listenIdx2 = DataStore.addListener('userFolderId',
            (userFolderId) => {
                setUserFields({ userFolderId });
            }
        );

        const currentUser = DataStore.get('currentUser');

        if (!currentUser) {
            getUserProfile();
        }

        if (currentUser) {
            setUserFields(currentUser);
        }

        return () => {
            DataStore.removeListener(listenIdx);
            DataStore.removeListener(listenIdx2);
        };
    }, [portal, setUserFields]);

    return user;
}
