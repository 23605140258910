import { convertToTimestamp } from 'common/datetime/convertToDate';
import { useMemo } from 'react';
import { usePathways } from 'features/patients/pathways/usePathways';
import { useI18N } from 'common/i18n/useI18N';
import { convertToOutputTzDate, formatDate } from 'common/datetime/DateTime';

interface Referral {
    uuid: string;
    folder_id: number;
    created_at: string;
    content: {
        ubrn: string;
        referring_service: { name: string };
        referred_to_service: { name: string };
        status: string;
        triage_decision: string;
    };
}

function getDisplayStatus(status, _) {
    if (!status) {
        return _`Unknown`;
    }

    switch (status.trim().toLowerCase()) {
    case 'open': return _`Open`;
    case 'closed': return _`Open`;
    case 'rejected': return _`Rejected`;
    case 'discharged': return _`Discharged`;
    }

    return status;
}

export const usePreparedReferrals = (referrals: Referral[], {
    getTriageDecisionForList,
    getSendLetterButton,
    getDischargeButton,
}) => {
    const { addPathwaysToReferral, deps } = usePathways();
    const _ = useI18N();

    return useMemo(() => {
        return addPathwaysToReferral(referrals).map(referral => {
            const {
                uuid,
                content,
                created_at: createdAt,
                pathwaySortAndFilter,
            } = referral;

            const {
                ubrn,
                referring_service: referringService,
                referred_to_service:referredToService,
                status,
            } = content;

            const referringServiceName = referringService?.name ?? '-';
            const referredToServiceName = referredToService?.name ?? '-';
            const date = convertToTimestamp(createdAt);
            const { triageDecisionCell, triageDecisionSortFilter } = getTriageDecisionForList(referral);
            const sendLetterButton = getSendLetterButton(referral);
            const dischargeButton = getDischargeButton ? getDischargeButton(referral) : undefined;

            return {
                uuid,
                key: uuid,
                ubrn,
                referringServiceName,
                referredToServiceName,
                date,
                dateTimeFilter: formatDate(convertToOutputTzDate(date)),
                dateTimeSort: date,
                status,
                displayStatus: getDisplayStatus(status, _),
                triageDecisionCell,
                triageDecisionSortFilter,
                sendLetterButton,
                pathwaySortAndFilter,
                dischargeButton,
                _source: referral,
            };
        });
    }, [addPathwaysToReferral, referrals, getTriageDecisionForList, getSendLetterButton, getDischargeButton, _]);
};
