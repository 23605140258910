import { makeGetRequest, makePostRequest } from 'services/api';
// interfaces
import { DepartmentLocation } from './department.interface';

export class DepartmentService {
    async getCurrentLocation(
        teamId: number | string,
        roleId: string,
    ): Promise<DepartmentLocation> {
        const response = await makeGetRequest(
            `/teams/${teamId}/department-folders/myLocation?using_role_uuid=${roleId}&path_from_root=true`,
        );

        const currentLocation = response.message[
            'department-folder'
        ] as DepartmentLocation;

        return currentLocation;
    }

    async getLocationTree(
        teamId: number | string,
        roleId: string,
    ): Promise<DepartmentLocation> {
        const response = await makeGetRequest(
            `/teams/${teamId}/department-folders/locations?using_role_uuid=${roleId}&full_tree=true`,
        );

        const locationTree = response.message as DepartmentLocation;

        return locationTree;
    }

    async changeLocation(
        teamId: number | string,
        roleId: string,
        nextLocation: string,
    ) {
        const data = {
            'department-folder-uuid': nextLocation,
        };

        const response = await makePostRequest(
            `/teams/${teamId}/department-folders/myLocation?using_role_uuid=${roleId}`,
            data,
        );

        return response;
    }
}

export const departmentService = new DepartmentService();
