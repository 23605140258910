import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import LoginComponent from 'components/login';
import DataStore from 'services/data-store';

/**
 * Extended Page Component for user_portal/login
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle ()  {
        return _`COOP Login`;
    }

    onLoginEvent () {
        DataStore.set('coop-app-type','desktop');
    }

    render () {
        return (<LoginComponent title="COOP Login" portal="coopweb" {...this.props} onLoginEvent={this.onLoginEvent.bind(this)} />)
    }
}
