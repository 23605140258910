import React, { FC } from 'react';
// styles
import './PIFUFormOption.style.scss';

type PIFUFormOptionProps = {
    id: string;
    title: string;
    description: string;
    selected: boolean;
    onToggle: (id: string) => void;
    underline?: boolean;
};

const PIFUFormOption: FC<PIFUFormOptionProps> = ({
    id,
    title,
    description,
    selected,
    onToggle,
    underline = false,
}) => {
    return (
        <div className="pifu-form-option" onClick={() => onToggle(id)}>
            <div className="pifu-form-option__input-wrapper">
                <input
                    className="pifu-form-option__input"
                    type="radio"
                    checked={selected}
                    onChange={() => undefined}
                />
            </div>
            <div className="pifu-form-option__info-wrapper">
                <div className="pifu-form-option__title">{title}</div>
                <div className="pifu-form-option__description">
                    {description}
                </div>
                {underline && <div className="pifu-form-option__underline" />}
            </div>
        </div>
    );
};

export default PIFUFormOption;
