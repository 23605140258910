import React, { FC } from 'react';
import { GridItem } from './dashboard-questionnaire-row/DashboardQuestionnaireRow';
import { useDashboardData } from 'features/patient/patient-dashboard/data/useDashboardData';
import { DASHBOARD_COMPONENT_NAMES } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { RoleType } from 'features/patient/patient-dashboard/RoleType';
import { DashboardQuestionnaireTableView } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/DashboardQuestionnaireTableView';

export interface TeamPrefItem {
    questionnaireName: string;
    measure: string;
}
interface QuestionnaireModuleProps {
    context: string;
    title: string;
    role?: RoleType;
    items: TeamPrefItem[];
}

export const DashboardQuestionnaireTable: FC<QuestionnaireModuleProps> = ({
    title,
    items,
    context,
    role = RoleType.Patient,
}) => {

    const { data, loading } = useDashboardData<GridItem[]>({
        type: DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTable,
        payload: {
            context,
            items
        }
    });

    return (
        <DashboardQuestionnaireTableView role={role} title={title} data={data} loading={loading}/>
    );
};

