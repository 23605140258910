import React from 'react';
import { Row, Column } from 'ui';
import { Iframe } from 'components/user/letters/create/iframe';
import { BulkOperationReviewProps } from './models/bulkOperationHistoryModels';

const BulkOperationLetterReview = ({ bulkOperationData }: BulkOperationReviewProps): JSX.Element => {
    return (
        <Column md="12">
            <Row style={{ overflow: 'scroll' }}>
                <Iframe
                    srcDoc={bulkOperationData.htmlContent}
                    style={{ border: 'none', width: '600px' }}
                    useScrollHeight={false}
                />
            </Row>
        </Column>
    );
};

export default BulkOperationLetterReview;
