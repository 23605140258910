import { Link } from 'react-router';
import React, { useState, useEffect } from 'react';
import _ from 'services/i18n';
import './editor.less';
import Pagination from 'components/pagination';
import { simpleQuestionnaireService } from './simpleQuestionnaireContentService';

export const EditorView = (): JSX.Element => {
    const [selectedContent, setSelectedContent] = useState<any>('');
    const [contentList, setContentList] = useState<any>([]);
    const [paginatedContentList, setPaginatedContentList] = useState([]);
    const [contentsTotal, setContentsTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [contentError, setContentError] = useState<boolean>(false);

    const perPageLimit = 10;

    useEffect((): void => {
        if (!selectedContent) {
            setContentsTotal(0);
            return;
        }
        getContentList();
    }, [selectedContent]);
    
    useEffect(() => {
        const urlLocation = window.location.search;
        const type = urlLocation.slice(urlLocation.lastIndexOf('type') + 5);
        const selectedContent: object = list.find((item) => item.value === type);

        setSelectedContent(selectedContent);
    }, []);

    useEffect(() => {
        const urlLocation = window.location.search;
        const type = urlLocation.slice(urlLocation.lastIndexOf('type') + 5);
        const selectedContent: object = list.find((item) => item.value === type);

        setSelectedContent(selectedContent);
    }, []);

    useEffect((): void => {
        handlePagination();
    }, [currentPage, contentList]);

    const list: any[] = [{
        value: 'simple-questionnaire',
        name: 'Simple Questionnaire'
    }];

    const handlePagination = (): void => {
        if (!contentList.length) { return; }
        const sortedContentList = contentList.sort((a, b) => {
            if(a.id < b.id) { return -1; }
            if(a.id > b.id) { return 1; }
            return 0;
        });
        const start: number = ((currentPage - 1) * perPageLimit);
        const end: number = (perPageLimit * currentPage);
        const pageItems: any = sortedContentList.slice(start, end);

        setPaginatedContentList(pageItems);
    };

    const handleContentSelect = (e): void => {
        const selectedContent: any = list.find((item: any) => item.value === e.target.value);

        setSelectedContent(selectedContent);
    };

    const getContentList = (): void => {
        simpleQuestionnaireService.getContentList(selectedContent.value).then((res) => {
            if (res.message.content.length < 1) {
                setContentError(true);
            }
            setContentList(res.message.content);
            setContentsTotal(res.message.content.length);
        }).catch(() => {
            setContentError(true);
        });
    };

    const displaySelectedContent = (): JSX.Element => {
        return (
            <table className="table table-bordered table-condensed table-striped table-nomargin">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Content Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedContentList.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.content_type}</td>
                                <td>
                                    <a href={`/admin_portal/editor/simplequestionnaire/edit/${item.id}`}>
                                        Edit
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table >
        );
    };

    return (
        <div>
            <h2>Content Editor</h2>
            <label>Select content to edit </label>
            <select name="ContentId" className="form-control list-select" value={selectedContent && selectedContent.value} onChange={handleContentSelect}>
                <option value={''}>{_`-- Select Category --`}</option>
                {list.map((listItem, idx,) => {
                    return (
                        <option key={idx} value={listItem.value}>{listItem.name}</option>
                    );
                })}
            </select>

            {contentError && selectedContent && (
                <div className='alert alert-info alert-box-scroll-target alert-box-loading'>
                    <p>No {selectedContent.name} to display</p>
                </div>
            )}

            {selectedContent && (
                <Link to={'/admin_portal/editor/simplequestionnaire/create'}>
                    <button className='create-button'>
                        Create
                    </button>
                </Link>
            )}

            {selectedContent && !contentError && displaySelectedContent()}

            <div className="advanced_search-pagination">
                <Pagination
                    pageCount={Math.ceil(contentsTotal / perPageLimit)}
                    currentPage={currentPage}
                    onChange={(newPage: number) => {
                        setCurrentPage(newPage);
                    }}
                />
                <p className='pagination-summary'>
                    Total number of results: {contentsTotal}
                </p>
            </div>
        </div>
    );
};
