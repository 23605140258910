import React, { FC } from 'react';
import Select from 'react-select';
// components
import AdvancedSearchOutputField from '../AdvancedSearchOutputField';
// utils
import {
    AdvancedSearchDocumentField,
    getFieldConfig,
} from 'models/AdvancedSearchDefinition';
// interfaces
import { AdvancedSearchOutputsProps } from './AdvancedSearchOutputs.interface';
// styles
import './AdvancedSearchOutputs.scss';

const AdvancedSearchOutputs: FC<AdvancedSearchOutputsProps> = (props) => {
    return (
        <div className={'advanced-search-query-request-output-list'}>
            {props.selectedOutputs.map((selectedOutput, index) => {
                const outputConfig = getFieldConfig(
                    props.documentConfig.fields,
                    selectedOutput,
                );

                return (
                    <AdvancedSearchOutputField
                        key={`${selectedOutput}_${index}`}
                        outputConfig={outputConfig}
                        path={selectedOutput}
                        allowMoveLeft={index > 0}
                        allowMoveRight={
                            index < props.selectedOutputs.length - 1
                        }
                        onRemove={props.removeOutput}
                        onMove={props.moveOutput}
                    />
                );
            })}
            {props.remainingOutputs.length > 0 && (
                <Select
                    value={null}
                    options={props.remainingOutputs.map((availableOutput) => {
                        return {
                            label: availableOutput.name,
                            value: availableOutput,
                        };
                    })}
                    onChange={(selection: {
                        label: string;
                        value: AdvancedSearchDocumentField;
                    }) => props.addOutput(selection.value.path)}
                    placeholder={
                        props.selectedOutputs.length == 0 ? 'Show...' : 'and...'
                    }
                    className={'react-select-input-container'}
                    classNamePrefix={'react-select-input'}
                />
            )}
        </div>
    );
};

export default AdvancedSearchOutputs;
