import React from 'react';
import { StateSourceLinkProps, useStateSourceCallback } from 'components/dataSource/StateLinking';
import 'common/ui/checkbox/Checkbox.less';

export function LinkedCheckbox<K>(props: StateSourceLinkProps<boolean, K>)
{
    const currentState = useStateSourceCallback<boolean, K>(props.stateSource, props.stateKey);

    const contents = currentState ? <i className="far fa-check-square" /> : <i className="far fa-square" />;
    return (
        <div tabIndex={1}  className={'ui-checkbox' + ` ${currentState ? 'ui-checkbox__checked' : ''}` + ` ${null || ''}`}>
            <label>
                <input type="checkbox" className={'ui-checkbox-input'}
                    checked={currentState}
                    onChange={(e) => (props.stateSource.setState(e.target.checked, props.stateKey))} />
                {contents}
            </label>
        </div>
    );
}
