import { createContext } from 'react';
import { searchCallback } from 'common/ui/grid/paginatedTableReducer';

export interface PaginationContextProps {
   data: unknown[];
   onSearch?: searchCallback;
}

export const PaginationContext = createContext<PaginationContextProps>({
    data: [],
});
