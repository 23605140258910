import React, { useEffect, useState } from 'react';
import { DropdownSelect, DropdownSelectOption } from '../dropdown/DropdownSelect';

export type DropdownForTagsOptions = [string, string][];
type DropdownForTagsProps = {
    items: DropdownForTagsOptions;
    name: string;
    value: string;
    showFilter: boolean;
    filterString?: string;
    input?: any;
    openDropdown?: boolean;
    onChange: (event: any) => void;
    keyDown?: any;
    keyUp?: any;
}

const DropdownForTagsComponent: any = ({
    items,
    value,
    name,
    onChange,
    input,
    showFilter,
    filterString,
    openDropdown,
    keyDown,
    keyUp,
}, ref) => {
    const [filteredItems, setFilteredItems] = useState(items);

    const currentItem = items.find(([key]) => key === value);
    const currentLabel = currentItem ? currentItem[1] : '';

    useEffect(() => {
        setFilteredItems(items.filter(([, name]) => name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1));
    }, [items, filterString]);

    const handleOnBlur = () => {
        setFilteredItems(items.filter(([, name]) => name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1));
    };

    return (
        <DropdownSelect
            label={currentLabel}
            name={name}
            onBlur={handleOnBlur}
            onChange={onChange}
            input={input}
            ref={ref}
            showFilter={showFilter}
            openDropdown={openDropdown}
            keyUp={keyUp}
            keyDown={keyDown}
        >
            {filteredItems.map(([key, label]) => {
                return <DropdownSelectOption key={key} value={key}>{label}</DropdownSelectOption>;
            })}
        </DropdownSelect>
    );
};

export const DropdownForTags = React.forwardRef<HTMLDivElement, DropdownForTagsProps>(DropdownForTagsComponent);
