/* eslint-disable @typescript-eslint/camelcase */
import { GridCell, GridRow } from 'common/ui/grid';
import { Link } from 'react-router';
import { TERActionTypes } from 'models/TerActionTypes';
import { calculatePercentageComplete, formatDateToString } from 'features/patients/advanced-search/ASBOWLUtils';
import { getBulkOperationStatusDescription, getBulkOperationStatusLabel } from 'models/BulkOperationStatus';
import React from 'react';
import { BulkOperationAction, BulkOperationRequest } from 'models/BulkOperationRequests';

const formatDate = (dateToFormat: string): string => {
    return formatDateToString(dateToFormat, 'dddd Do MMMM YYYY [at] HH:mm');
};

const BulkOperationTableRow = (props: {
    bulkOperation: BulkOperationRequest;
}) => {
    const {
        uuid,
        created_at,
        content
    } = props.bulkOperation;
    
    const percentageComplete = calculatePercentageComplete(
        content.actions_failed + content.actions_succeeded,
        content.action_count
    );

    const countDesc = content.folder_count === content.action_count
        ? content.folder_count
        : `${content.folder_count} (${content.action_count} actions)`;

    return (
        <GridRow key={uuid}>
            <GridCell>
                <Link
                    to={`/clinical_portal/bulk-operations/${uuid}`}
                >
                    {content.name}
                </Link>
            </GridCell>
            <GridCell>
                {content.actions.map(
                    (
                        action: BulkOperationAction,
                        index: number,
                    ) => {
                        return (
                            <div
                                className="action-labels_row"
                                key={
                                    index
                                }
                            >
                                <div className="pathway-labels_cell no-wrap">
                                    {
                                        TERActionTypes[
                                            action.action_type
                                        ]
                                    }
                                </div>
                            </div>
                        );
                    },
                )}
            </GridCell>
            <GridCell>
                {countDesc}
            </GridCell>
            <GridCell>
                {formatDate(created_at)}
            </GridCell>
            <GridCell>
                {percentageComplete}
            </GridCell>
            <GridCell>
                <div
                    className="action-labels_row"
                >
                    <div className="pathway-labels_cell no-wrap">
                        {content.actions_succeeded} succeeded
                    </div>
                </div>
                {content.actions_failed > 0 && (
                    <div
                        className="action-labels_row"
                    >
                        <div className="pathway-labels_cell no-wrap">
                            {content.actions_failed} failed
                        </div>
                    </div>
                )}
            </GridCell>
            <GridCell>
                <div
                    title={getBulkOperationStatusDescription(
                        content.status,
                    )}
                >
                    {getBulkOperationStatusLabel(
                        content.status,
                    )}
                </div>
            </GridCell>
            <GridCell>
                <Link
                    to={`/clinical_portal/bulk-operations/${uuid}`}
                    className="btn btn-xs btn-default"
                >
                    View
                </Link>
            </GridCell>
        </GridRow>
    );
};

export default BulkOperationTableRow;
