import React from 'react';
import PropTypes from 'prop-types'
import BaseComponent, {API_PORTAL_FOLDER_REQUEST} from 'components/BaseComponent';
import HeadingDoc from 'ui/heading-doc';
import PatientTimeline from 'components/my-pathway/patient-timeline';
import { syncService } from 'services/sync.service';

/**
 * Displays the contents of a patient dashboard for the MyPathway service.
 * This currently shows a summary view of what the patient would see on their
 *  timeline in the app.
 */
export default class MyPathwayPatientDashboard extends BaseComponent {
    constructor (props) {
        super(props);

        this.state = {
            // Indicates if we're currently loading patient data.
            loading: true,

            // Indicates if an error occurred while loading patient data.
            loadingError: false,

            // The compositions loaded from the patient pathway.
            // This should only include items which the patient can see in the app,
            //  and which the clinician is also allowed to see.
            compositions: []
        };
    }

    componentWillMount () {
        this.loadCompositions();
    }

    loadCompositions () {
        return this.$setState({
            loading: true,
            loadingError: false,
            compositions: []
        }).
        then(() => {
            // Fetch all the compositions in the patient's folder.;
            return syncService.fullSync({});
        }).
        then((response) => {
            if (!response || !response.message || !response.message.results) {
                throw new Error('Server response did not have expected structure.');
            }

            return this.$setState({
                loading: false,
                compositions: response.message.results
            });
        }).
        catch((err) => {
            console.warn(err);
            this.setState({
                loading: false,
                loadingError: true
            });
        });
    }

    render () {

        let header;
        if (typeof this.props.header === 'undefined'){
            header = (<HeadingDoc title='Patient Timeline'>
                This page shows what the patient can see on their timeline.
            </HeadingDoc>)
        }else{
            header = this.props.header;
        }

        return (
            <div className="page">
                {header}
                {this.renderBody()}
            </div>
        );
    }

    renderBody () {
        if (this.state.loading) {
            return (
                <div className='alert alert-info'>
                    Loading. Please wait...
                </div>
            )
        }

        if (this.state.loadingError) {
            return (
                <div className='alert alert-danger'>
                    Sorry. An error occurred. Please try again.
                </div>
            );
        }

        if (this.state.compositions.length == 0) {
            return (
                <div className='alert alert-warning'>
                    This patient doesn't have anything on their timeline yet.
                </div>
            );
        }

        const folderLink = this.props.folderLink || this.getFolderPath();

        return (
            <PatientTimeline
                compositionQueryString={this.props.compositionQueryString}
                compositions={this.state.compositions}
                folderLink={folderLink}
            />
        );
    }
}


MyPathwayPatientDashboard.propTypes = {
    // Header element to render , pass null if no header needed
    header: PropTypes.element,
    // base link for compositions
    folderLink: PropTypes.string,
    // query string to append to composition url
    compositionQueryString: PropTypes.string
}

MyPathwayPatientDashboard.defaultProps = {
    compositionQueryString:''
}
