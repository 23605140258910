import React, { FC, useContext, useEffect, useState } from 'react';
import { I18NContext } from 'common/i18n/I18NContext';
import { TopBar } from 'features/patient/top-bar/TopBar';
import { DevicesGrid } from 'features/patient/devices/DevicesGrid';
import {
    DeviceFormItem,
    DeviceGridItem,
    devicesServiceHelper, SnomedCodeInfo
} from './services/DevicesServiceHelper';
import { DeviceForm, DeviceFormModes } from 'features/patient/devices/DevicesForm';
import DataStore from 'services/data-store';

interface DevicesIndexProps {
    loading: boolean;
    devices: DeviceGridItem[];
    folderId: number;
    teamId: number;
    showReturnForm: (deviceItem: DeviceGridItem) => void;
    showEditForm: (deviceItem: DeviceGridItem) => void;
    onUpdateDevice: (deviceItem: DeviceFormItem, isDeviceReturned: boolean) => void;
    onAddDevice: (deviceItem: DeviceFormItem) => void;
    hideEditForm: () => void;
    hideReturnForm: () => void;
    deviceItemToEdit: DeviceFormItem;
    deviceItemToReturn: DeviceFormItem;
    isEditFormShown: boolean;
    isReturnFormShown: boolean;
}

export const DevicesIndex: FC<DevicesIndexProps> = ({
    loading,
    devices = [],
    folderId,
    teamId,
    showReturnForm,
    showEditForm,
    onUpdateDevice,
    hideEditForm,
    hideReturnForm,
    deviceItemToEdit,
    deviceItemToReturn,
    isEditFormShown,
    isReturnFormShown,
    onAddDevice
}) => {

    const [isModalOpen, toggleIsModalOpen] = useState(false);

    const [deviceTypesList, setDeviceTypesList] = useState([]);

    const [ownershipTypesList, setOwnershipTypesList] = useState([]);

    const [snomedCodesList, setSnomedCodesList] = useState([]);

    const emptyFormState = {
        dateAssigned: '',
        assignedBy: '',
        department: '',
        type: '',
        ownershipInfo: '',
        make: '',
        model: '',
        key: '',
        id: '',
        dateReturned: '',
        returnedTo: '',
        dispenseId: '',
        returnId: '',
        snomedCode: ''
    };

    const staticOwnershipTypesList = [
        'Trust owned, single use',
        'Trust owned, reusable',
        'Patient owned'
    ];

    let formMode;

    const handleCloseDialog = () => {
        toggleIsModalOpen(false);
        hideEditForm();
        hideReturnForm();
    };

    useEffect(() => {
        if (isEditFormShown || isReturnFormShown) {
            toggleIsModalOpen(true);
        }
    }, [isEditFormShown, isReturnFormShown]);

    useEffect(() => {
        let isMounted = true;

        const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});

        devicesServiceHelper.getDeviceTypesFromContentServer(teamId, roleUuid)
            .then((response) => {
                if (isMounted) {
                    const deviceTypesFromContentServer = Object.values(response).reduce((newArr: string[], deviceType: SnomedCodeInfo) => {
                        if (deviceType.assignable) {
                            newArr.push(deviceType.name);
                        }
                        return newArr;
                    }, []);

                    const snomedCodesFromContentServer = devicesServiceHelper.getArrayOfAssignableCodes(response);

                    setDeviceTypesList(deviceTypesFromContentServer);
                    setOwnershipTypesList(staticOwnershipTypesList);
                    setSnomedCodesList(snomedCodesFromContentServer);
                }
            });

        return () => {
            isMounted = false;
        };
    }, []);

    const handleSubmit = (item: DeviceFormItem) => {
        isEditFormShown ? onUpdateDevice(item, false) :
            (isReturnFormShown ? onUpdateDevice(item, true) : onAddDevice(item));
    };

    const isFormInEditMode = (deviceItemToEdit: DeviceFormItem) => {
        return Object.keys(deviceItemToEdit).length > 0;
    };

    const isFormInReturnMode = (deviceItemToReturn: DeviceFormItem) => {
        return Object.keys(deviceItemToReturn).length > 0;
    };

    const defineFormState = (): DeviceFormItem => {
        if (isFormInEditMode(deviceItemToEdit)) {
            formMode = DeviceFormModes.editDeviceMode;
            return deviceItemToEdit;
        }

        if (isFormInReturnMode(deviceItemToReturn)) {
            formMode = DeviceFormModes.returnDeviceMode;
            return deviceItemToReturn;
        }

        return emptyFormState;
    };

    const _ = useContext(I18NContext);
    return (
        <div className="page-devices">
            <TopBar
                folderId={folderId}
                teamId={teamId}
            >
                <a onClick={() => toggleIsModalOpen(true)} >{_`Add new device`}</a>
            </TopBar>
            {isModalOpen &&
                (
                    <DeviceForm
                        id='device-form'
                        deviceTypesList={deviceTypesList}
                        ownershipTypesList={ownershipTypesList}
                        snomedCodesList={snomedCodesList}
                        form={defineFormState()}
                        deviceFormMode={formMode}
                        onSubmit={handleSubmit}
                        onClose={handleCloseDialog}
                    />
                )}
            <DevicesGrid
                loading={loading}
                devices={devices}
                onReturnDevice={showReturnForm}
                onEditDevice={showEditForm}
            />
        </div>
    );
};


