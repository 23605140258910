import { makePostRequest, makePutRequest, makeDeleteRequest } from 'services/api';
import { CompositionService } from 'services/composition.service';
import { makeGetRequest } from 'services/api';
import { DataStore } from 'services/data-store';
import { SimpleQuestionnaireApiForm } from 'models/compositions/SimpleQuestionnaire';

export class ContentEditorApiService<T> extends CompositionService {
    get archetypeName(): string {
        throw new Error('Method not implemented.');
    }

    getContentList = (contentType: string, location: null | string = 'general_content'): Promise<any> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        let url = `/teams/${role.teamId}/content/index?using_role_uuid=${role.uuid}&contentType=${contentType}`;

        if(location !== null) {
            url += `&location=${location}`;
        }

        return makeGetRequest(url, apiVersion);
    };

    postContent = (contentFormData: T): Promise<any> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        return makePostRequest(`/teams/${role.teamId}/content?using_role_uuid=${role.uuid}`, contentFormData, apiVersion);
    };

    getContentById = (id: string, location: string): Promise<any> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        return makeGetRequest(`/teams/${role.teamId}/content?using_role_uuid=${role.uuid}&location=${location}&id=${id}`, apiVersion);
    };

    updateContentById = (contentFormData: T): Promise<any> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        return makePutRequest(`/teams/${role.teamId}/content?using_role_uuid=${role.uuid}`, contentFormData, apiVersion);
    }

    deleteContentById = (id: string, location: string): Promise<any> => {
        const role = DataStore.get('me.currentRole');

        return makeDeleteRequest(`/teams/${role.teamId}/content?using_role_uuid=${role.uuid}&location=${location}&id=${id}`);
    }
}

const simpleQuestionnaireService = new ContentEditorApiService<SimpleQuestionnaireApiForm>();

export {
    simpleQuestionnaireService
};
