import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import LoginComponent from 'components/login';
import ServerFetch from 'server-fetch';
import DataStore from 'services/data-store';
/**
 * Extended Page Component for user_portal/login
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            message: _`Clearing session...`
        };
    }

    pageTitle ()  {
        return _`COOP Login`;
    }

    componentDidMount () {
        DataStore.clear(() => {
            window.getLoggedInUser = function() {
                return false;
            }

            ServerFetch.postRequest(this,'/logout',{},(response) => {
                this.setState({
                    message: _`You have been logged out of COOP`
                });
            });
        });

    }

    render () {
        return (<LoginComponent title={_`COOP Login`} message={this.state.message} portal="coopweb" {...this.props} />)
    }
}
