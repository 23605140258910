import React, { FC } from 'react';
// components
import DepartmentBreadcrumbs from 'features/department/components/DepartmentBreadcrumbs';
// styles
import './DepartmentLocation.scss';

const DepartmentLocation: FC = () => {
    return (
        <div className="department-location">
            <span>{'You are currently viewing the portal as: '}</span>
            <DepartmentBreadcrumbs />
        </div>
    );
};

export default DepartmentLocation;
