import React from 'react';

/**
 * Formats and returns a UBRN (Unique Booking Reference Number) for display.
 * The input value should be a string of 12 decimal digits, although an empty
 *  string or null/undefined is permitted.
 * Whitespace anywhere in the input value will be ignored.
 * The output will be spaced into groups of 4 digits.
 * If the input is non-empty, this expects 12 decimal digits. A console warning
 *  will be omitted if the input does not correspond, although it will be processed anyway.
 *
 * @param {string} input The raw UBRN as a string of decimal digits.
 * @returns {string} The formatted UBRN, or an empty string if there was no input value.
 */
export function formatUbrn (input) {

    if (input == null) {
        return '';
    }

    // Remove all whitespace.
    input = input.replace(/\s/g, '');
    if (input == '') {
        return '';
    }

    // Output a warning if the input looks invalid.
    if (input.match(/^[0-9]{12}$/) == null) {
        console.warn('UBRN does not look valid: "' + input + '". Expected 12 decimal digits.');
    }

    return input.replace(/(.{4})/g, '$1 ');
}


/**
 * React component which renders a UBRN for display inside a span.
 * Displays an empty span if no value is provided, or it is empty.
 * The input UBRN can be provided as the children of the component or
 *  as the value prop. (Value takes priority if it exists.)
 *
 * Properties:
 *  - value|children = Optional. The raw UBRN. Whitespace anywhere inside is ignored.
 *  - empty = Optional. Content to display if the value is empty. Defaults to nothing.
 *  - className = Optional. Class name to apply to the span. Defaults to 'ubrn' if nothing is specified.
 *  - style = Optional. Inline style to apply to the span. Defaults to empty.
 */
export const Ubrn = ({children = null, value = null, className = 'ubrn', style = null, empty = ''}) => {
    let content = formatUbrn(value || children) || empty;
    return (
        <span className={className} style={style || null}>
            {content}
        </span>
    );
};

export default Ubrn;
