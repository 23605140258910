import React, { forwardRef, useEffect, useState } from 'react';
import { DropdownSelect, DropdownSelectOption } from './DropdownSelect';

export type SimpleDropdownOptions = [string, string][];
type SimpleDropdownProps = {
    items: SimpleDropdownOptions;
    name: string;
    value: string;
    onChange: (event: any) => void;
}

export const SimpleDropdown = forwardRef<HTMLDivElement, SimpleDropdownProps>(({
    items,
    value,
    name,
    onChange,
}, ref) => {
    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);

    const currentItem = items.find(([key]) => key === value);
    const currentLabel = currentItem ? currentItem[1] : '';

    const handleOnSearch = (search: string) => {
        setFilteredItems(items.filter(([, name]) => name.toLowerCase().indexOf(search.toLowerCase()) !== -1));
    };

    const handleOnBlur = () => {
        setFilteredItems(items);
    };

    return (
        <DropdownSelect
            label={currentLabel}
            value={value}
            onSearch={handleOnSearch}
            name={name}
            onBlur={handleOnBlur}
            onChange={onChange}
            ref={ref}
        >
            {filteredItems.map(([key, label]) => {
                return <DropdownSelectOption key={key} value={key}>{label}</DropdownSelectOption>;
            })}
        </DropdownSelect>
    );
});
SimpleDropdown.displayName = 'SimpleDropdown';
