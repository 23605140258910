import React, { FC, useState } from 'react';
// components
import { LoadingError } from 'common/ui/alert-boxes';
// hooks
import { useAdvancedSearchQueryName } from 'features/patients/advanced-search/AdvancedSearchSetupHooks';
// interfaces
import { AdvancedSearchQueryNameInputProps } from './AdvancedSearchQueryNameInput.interface';
import { useValidator } from 'common/ui/validation/NestedComponentValidator';

const AdvancedSearchQueryNameInput: FC<AdvancedSearchQueryNameInputProps> = (
    props,
) => {
    const MIN_NAME_LENGTH = 3;
    const MAX_NAME_LENGTH = 150;
    const [queryName, setQueryName] = useAdvancedSearchQueryName(
        props.searchRequest,
    );
    const [error, setError] = useState<string>(null);

    useValidator(props.validator, () => {
        const queryNameTrimmed = queryName.trim();

        const valid =
            queryNameTrimmed.length >= MIN_NAME_LENGTH &&
            queryNameTrimmed.length <= MAX_NAME_LENGTH;

        setQueryName(queryNameTrimmed);

        setError(
            valid
                ? null
                : `Name must be between ${MIN_NAME_LENGTH} and ${MAX_NAME_LENGTH} characters long.`,
        );
        return valid;
    });

    return (
        <div className="form-group" id="as_query_name">
            <label htmlFor="as_query_name">
                <strong>Filter Name</strong>
            </label>
            {error && <LoadingError>{error}</LoadingError>}
            <input
                type="text"
                id="query_name__input"
                className={'form-control'}
                name="as_query_name"
                value={queryName}
                onChange={(e) => setQueryName(e.target.value.trimLeft())}
                minLength={MIN_NAME_LENGTH}
                maxLength={MAX_NAME_LENGTH}
            />
        </div>
    );
};

export default AdvancedSearchQueryNameInput;
