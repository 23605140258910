import { makeGetRequest } from 'services/api';
import { QuestionnaireScoreResults } from 'models/QuestionnaireResult';
import DataStore from 'services/data-store';

export function getQuestionnaireResults ({ folderId, teamId, pathwayContext }): Promise<QuestionnaireScoreResults>{
    const { uuid } = (DataStore.get('me.currentRole') || {});
    return makeGetRequest(`/teams/${teamId}/folders/${folderId}/questionnaire-results?pathway_context=${pathwayContext}&using_role_uuid=${uuid}`)
        .then(({ message }) => {
            return message && (message[0] || message);
        })
        .catch((e) => {
            console.error('[getQuestionnaireResults]', 'Unable to get questionnaire results', folderId, teamId, pathwayContext);
            return Promise.reject(e);
        });
}
