import DataStore from 'services/data-store';
export const getUserName = () => {
    const currentUser = DataStore.get('currentUser');
    if (!currentUser) {
        return '';
    }

    if (currentUser.preferred_name !== '') {
        return currentUser.preferred_name;
    } else if (currentUser.first_name !== '') {
        return currentUser.first_name + ' ' + currentUser.last_name;
    } else {
        return currentUser.name;
    }
};
