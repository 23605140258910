import { useEffect } from 'react';
import { browserHistory } from 'react-router';
import ServerFetch from 'server-fetch';
import DataStore from 'services/data-store';

export function useInactive(portal = 'clinical_portal') {
    useEffect(() => {
        const listenIdx = DataStore.getListener('inactive',
            /**
             * @param  {any} value
             */
            (value) => {
                if (value) {
                    DataStore.clear(() => {
                        window.getLoggedInUser = () => {
                            return false;
                        };
                        ServerFetch.postRequest(this, '/logout', {}, () => {
                            browserHistory.push(`/${portal}/login?status=session-timeout&path=` + encodeURIComponent(window.location.pathname));
                        });
                    });
                }
            });
        return () => {
            DataStore.removeListener(listenIdx);
        };
    }, [portal]);
}
