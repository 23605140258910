import React, { Fragment, useEffect, useState } from 'react';

import {
    defaultSort,
    Grid,
    GridBody,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    SortOrder, styleSortedColumns,
} from 'common/ui/grid';
import { usePagination } from 'common/ui/usePagination';
import PaginationView from 'components/pagination';
import { MNDWorklistRow } from './MNDWorklistRow';
import { convertToDate, standardDateTimeFormats } from 'common/datetime/convertToDate';

export interface MNDWorklistItem {
    uuid: string;
    name: string;
    patientURL: string;
    nhs: number;
    patientGraphsURL: string;
    latestCompletedAt: number;
    alsTimestamp?:  number;
    alsQuestionnaireURL?: string;
    alsScore?: number;
    alsScoreString?: string;
    alsScoreDiff?: number;
    breathingTimestamp?:  number;
    breathingQuestionnaireURL?: string;
    breathingScore?: number;
    breathingScoreString?: string;
    breathingScoreDiff?: number;
    snaqTimestamp?:  number;
    snaqQuestionnaireURL?: string;
    snaqScore?: number;
    snaqScoreString?: string;
    snaqScoreDiff?: number;
    weightTimestamp?:  number;
    weightQuestionnaireURL?: string;
    weightScore?: number;
    weightScoreString?: string;
    weightScoreDiff?: number;
    weightScoreDiffString?: string;
}


const getDateFilterString: (timestamp: number) => string = (timestamp) => {
    const date = convertToDate(timestamp);
    return date && date.isValid() && date.format(standardDateTimeFormats.nhs_date_short) || '--';
};

const getMndWorklistFilterString: (item: MNDWorklistItem, key: string) => string = (item, key) => {
    switch (key) {
    case 'latestCompletedAt':
        return getDateFilterString(item[key]) ;
    default:
        return item[key] ?? '--';
    }
};

export const MndWorklist = ({
    items,
    isLazyFetchingInProcess,
}: {
    items: MNDWorklistItem[];
    isLazyFetchingInProcess?: boolean;
}) => {
    const [sortOrder, setSortOrder] = useState<{ [key: string]: SortOrder }>({ latestCompletedAt: SortOrder.DESC });
    const [filterState, setFilterState] = useState<{ [key: string]: string }>({});
    const [filteredItems, setFilteredItems] = useState([]);
    const { page, total: pagesTotal, items: paginatedItems, onPageChange } = usePagination({
        items: isLazyFetchingInProcess ? items : filteredItems
    });

    useEffect(() => {
        const sortEntries = Object.entries(sortOrder);
        let sortedItems = items;
        if (sortEntries.length > 0) {
            const [sortKey, sortOrder] = sortEntries[0];
            sortedItems = items.sort((a, b) => defaultSort(a[sortKey], b[sortKey], sortOrder));
        }


        const filterEntries = Object.entries(filterState).filter(([, filterString]) => filterString !== '');
        const filteredItems = sortedItems.filter(item => {
            if (!filterEntries.length) {
                return true;
            }
            return filterEntries.every(([filterKey, filterString]) => {
                const itemString = getMndWorklistFilterString(item, filterKey);

                if (!itemString) {
                    return false;
                }

                return itemString.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
            });
        });
        setFilteredItems(filteredItems);
    }, [filterState, items, sortOrder]);

    return (
        <Fragment>
            <Grid onSort={setSortOrder}
                sortOrder={sortOrder}
                onFilter={setFilterState}
                filterState={filterState}
                sortable={true}
                filterable={true}
                className={styleSortedColumns}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell field="name" className="text-center">Name</GridHeaderCell>
                        <GridHeaderCell field="nhs" className="text-center">NHS</GridHeaderCell>
                        <GridHeaderCell field="latestCompletedAt" className="text-center">Update</GridHeaderCell>
                        <GridHeaderCell field="alsScoreString" className="text-center">ALS-FRS-R</GridHeaderCell>
                        <GridHeaderCell field="alsScoreDiff" className="text-center">diff</GridHeaderCell>
                        <GridHeaderCell field="breathingScoreString" className="text-center">Breathing</GridHeaderCell>
                        <GridHeaderCell field="breathingScoreDiff" className="text-center">diff</GridHeaderCell>
                        <GridHeaderCell field="snaqScoreString" className="text-center">SNAQ</GridHeaderCell>
                        <GridHeaderCell field="snaqScoreDiff" className="text-center">diff</GridHeaderCell>
                        <GridHeaderCell field="weightScoreString" className="text-center">Weight</GridHeaderCell>
                        <GridHeaderCell field="weightScoreDiff" className="text-center">diff</GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>

                <GridBody>
                    {paginatedItems.map((item, i) => (
                        <MNDWorklistRow
                            key={item.uuid}
                            worklistItem={item}
                            isLoading={false}
                            className={i % 2 === 1 ? 'even' : 'odd'}
                        />
                    ))}
                </GridBody>
            </Grid>

            <PaginationView
                currentPage={page}
                pageCount={pagesTotal}
                onChange={onPageChange}
            />
        </Fragment>
    );
};

