import { useCompositionCollection } from 'common/useCompositionCollection';
import { useCompositions } from 'common/useCompositions';
import { useEffect, useMemo } from 'react';
import { MNDDepressedPatientsWorklistItem } from './MNDDepressedPatientsWorklistItem';
import {
    getNameFromDemographics,
    getPatientUrl,
    getPatientGraphsURL,
    getNHSFromDemographics,
    getMNDQuestionnaireInfo
} from './MNDDepressedPatientsHelpers';
import { useAPI } from 'common/useAPI';
import DataStore from 'services/data-store';

const getMNDDepressedPatientsURL = (teamId, roleUuid, perPage, page) =>
    `/api/teams/${teamId}/worklists/mnd-depressed-patients-worklist?using_role_uuid=${roleUuid}&per_page=${perPage}&page=${page}`;

export type useMNDDepressedPatientsWorklistCollectionReturnType = [
    MNDDepressedPatientsWorklistItem[],
    {
        isFetching: boolean;
        isLazyFetching: boolean;
        isError: boolean;
        totalItems: number;
    },
    {
        questionnaireScoresMap: Map<string, any>;
        questionnaireResponsesMap: Map<string, any>;
        demographicsMap: Map<string, any>;
    }
];

export function useMNDDepressedPatientsWorklistCollection({ teamId }): useMNDDepressedPatientsWorklistCollectionReturnType {
    const { get } = useAPI();

    const [worklistCollection, {
        isFetching,
        isLazyFetching,
        totalItems,
        isError
    }] = useCompositionCollection({
        archetype: 'WorklistReferral',
        teamId,
        getListFunction: async ({ teamId, perPage, page }) => {
            const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});
            const worklistURL = getMNDDepressedPatientsURL(teamId, roleUuid, perPage, page);
            const response = await get(worklistURL);
            const results = response.message.results || {};
            return [Object.values(results), 0];
        }
    });

    const [questionnaireResponsesMap, , {
        setItems: setQuestionnaireResponses
    }] = useCompositions({
        archetype: 'questionnaireResponse',
        teamId
    });

    const [questionnaireScoresMap, , {
        setItems: setQuestionnaireScores
    }] = useCompositions({
        archetype: 'questionnaireScores',
        teamId
    });

    const [demographicsMap, , {
        setItems: setDemographics
    }] = useCompositions({
        archetype: 'demographics',
        teamId
    });

    useEffect(() => {
        const filteredWorklist = filterWorklist(worklistCollection);
        const {
            questionnaireResponses,
            questionnaireScores,
        } = getQuestionnaireStuffFromWorklist(filteredWorklist);

        setQuestionnaireResponses(questionnaireResponses);
        setQuestionnaireScores(questionnaireScores);
        const demographics = getDemographicsFromWorklist(filteredWorklist);
        setDemographics(demographics);
    }, [
        worklistCollection,
        setQuestionnaireResponses,
        setDemographics,
        setQuestionnaireScores,
    ]);

    const worklistItems = useMemo(() => {
        const filteredWorklist = filterWorklist(worklistCollection);

        return filteredWorklist.map(({ uuid }) => mapToWorklistItem(uuid, {
            questionnaireResponsesMap,
            questionnaireScoresMap,
            demographicsMap,
        })).filter(item => !!item);
    }, [worklistCollection, questionnaireResponsesMap, questionnaireScoresMap, demographicsMap]);

    return [worklistItems, {
        isFetching,
        isLazyFetching,
        totalItems,
        isError
    }, {
        demographicsMap,
        questionnaireResponsesMap,
        questionnaireScoresMap
    }];
}

function filterWorklist(worklist) {
    return Object.values(worklist)
        .filter(({
            questionnaireResponse,
            questionnaireScores,
            demographics,
            referral,
        }) => questionnaireResponse && questionnaireResponse.length > 0
            && questionnaireScores && questionnaireScores.length > 0
            && demographics && demographics.length > 0
            && referral && referral.length > 0
        ).map(({
            questionnaireResponse,
            questionnaireScores,
            demographics,
            referral,
        }) => {
            const firstReferral = referral[0];
            return {
                ...firstReferral,
                relatedCompositions: {
                    demographics,
                    questionnaireResponse,
                    questionnaireScores,
                }
            };
        });
}

function getQuestionnaireStuffFromWorklist(worklistCollection) {
    const questionnaireAndResponses = worklistCollection.map(({ relatedCompositions, ...referral }) => {
        const questionnaireResponses = relatedCompositions.questionnaireResponse || [];
        const questionnaireScores = relatedCompositions.questionnaireScores || [];

        return [referral.uuid, questionnaireResponses, questionnaireScores];
    });
    return {
        questionnaireResponses: questionnaireAndResponses.map(([referralUuid, responses]) => ([referralUuid, responses])),
        questionnaireScores: questionnaireAndResponses.map(([referralUuid, , scores]) => ([referralUuid, scores])),
    };
}

function getDemographicsFromWorklist(worklistCollection) {
    return worklistCollection.map(({ relatedCompositions, ...referral }) => {
        const demographics = relatedCompositions.demographics;
        return [referral.uuid, demographics && demographics.length ? demographics[0] : undefined];
    });
}

const convertToNumber = (str: string) => {
    const num = parseInt(str, 10);

    if (`${num}` !== str) {
        return -Infinity;
    }

    return num;
};

function mapToWorklistItem(uuid, {
    questionnaireResponsesMap,
    questionnaireScoresMap,
    demographicsMap,
}): MNDDepressedPatientsWorklistItem {
    try {
        const demographics = demographicsMap.get(uuid);

        if (!demographics) {
            return null;
        }

        const questionnaireResponses = questionnaireResponsesMap.get(uuid);
        const questionnaireScores = questionnaireScoresMap.get(uuid);

        const folderId = demographics.folder_id;

        const resultInfoPHQ8 = getMNDQuestionnaireInfo('PHQ-8', questionnaireScores, questionnaireResponses, folderId);
        if (!resultInfoPHQ8) {
            return null;
        }
        const resultInfoGAD7 = getMNDQuestionnaireInfo('GAD-7', questionnaireScores, questionnaireResponses, folderId);
        if (!resultInfoGAD7) {
            return null;
        }

        return {
            name: getNameFromDemographics(demographics),
            patientURL: getPatientUrl(folderId),
            nhs: getNHSFromDemographics(demographics),
            patientGraphsURL: getPatientGraphsURL(folderId),
            phq8Timestamp:  resultInfoPHQ8.date,
            phq8QuestionnaireURL: resultInfoPHQ8.url,
            phq8Score: convertToNumber(resultInfoPHQ8.score),
            phq8ScoreString: resultInfoPHQ8.scoreString,
            phq8ScoreDiff: convertToNumber(resultInfoPHQ8.diff),
            gad7QuestionnaireURL: resultInfoGAD7.url,
            gad7Timestamp:  resultInfoGAD7.date,
            gad7Score: convertToNumber(resultInfoGAD7.score),
            gad7ScoreString: resultInfoGAD7.scoreString,
            gad7ScoreDiff: convertToNumber(resultInfoGAD7.diff)
        };
    } catch (err) {
        console.error(err);
        return null;
    }
}

