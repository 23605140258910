import { useState, useEffect } from 'react';
import { contentService } from 'services/content.service';

export type ContentVariableItem = {
    variable: string;
    description: string;
};

export const useContentVariables = () => {
    const [variables, setVariables] = useState<ContentVariableItem[]>([]);

    useEffect(() => {

        async function fetchContentVariables() {
            try {
                const contentVariables = await contentService.getAllVariables();
                setVariables(contentVariables);
            } catch(e) {
                setVariables([]);
            }
        }

        fetchContentVariables();
    }, []);

    return variables;
};
