import { useState, useEffect } from 'react';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { RoleDefinition, rolesService } from 'services/roles.service';

export enum TEAM_ROLES_GROUPS {
    PATIENT = 'patient_roles',
    CLINICIAN = 'clinical_roles',
    ADMIN = 'admin_roles'
}
export interface FolderGroup {
    uuid: string;
    consent: {
        create_archetypes: string[];
    };
    document_groups: unknown;
    owner_only?: boolean;
}

export function useTeamRoles(): [RoleDefinition[], Map<TEAM_ROLES_GROUPS, RoleDefinition[]>] {
    const teamPreferences = useTeamPreferences();
    const [teamRoles, setRoles] = useState<RoleDefinition[] | null>(null);
    const [teamRolesGroups, setTeamRolesGroups] = useState<Map<TEAM_ROLES_GROUPS, RoleDefinition[]>>(new Map());
    
    useEffect(() => {
        async function fetchRoles() {
            const roles = await rolesService.getRoleDefinitions({ folderId: 'team' });
            setRoles(roles);
        }

        fetchRoles();
    }, []);

    useEffect(() => {
        if (!teamPreferences || !teamRoles) {
            return setTeamRolesGroups(new Map());
        }

        const userRoles = new Map();

        const teamRolesGroups = teamPreferences.team_role_uuids || {};
        for (const userRoleSet of Object.keys(teamRolesGroups)) {
            const roles = teamRolesGroups[userRoleSet]
                .map((roleName) => {
                    return teamRoles.find(({ name }) => roleName === name);
                })
                .filter(role => !!role);
            userRoles.set(userRoleSet, roles);
        }

        setTeamRolesGroups(userRoles);
    }, [teamPreferences, teamRoles]);

    return [teamRoles, teamRolesGroups];
}
