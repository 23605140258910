import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import MFAComponent from 'components/mfa';
import faviconService from 'services/favicon.service.js';
import ServerFetch from 'server-fetch';

/**
 * Extended Page Component for admin_portal/mfa
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
        ServerFetch.setPortal('admin')

        this.state = {};
    }

    pageTitle () {
        return _`MyPathway Multi-factor Authentication`;
    }

    componentWillMount () {
        faviconService.setDefaultIcon();
    }

    render () {
        return (<MFAComponent title="Admin Portal Login" portal="admin_portal" {...this.state} {...this.props} />)
    }
}
