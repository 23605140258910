import React from 'react';
import _ from 'services/i18n';
import ErrorBox from 'components/errorbox';
import {Link} from 'react-router';
import {Panel, Row, Column, FormGroup, InputField} from 'ui';
import classNames from 'classnames';

/**
 * A generic logout component
 */
export default class SignUpComponent extends React.Component {
    renderLoading () {
        return (
            <div className="panel-loading">
                <span className="glyphicon glyphicon-lock"/>
            </div>
        );
    }

    render () {

        if (this.props.missingRequiredParams.length) {
            return (
                <Container>
                    <div className="alert alert-warning">
                        {_`Required URL params are missing. Please check the link which was emailed to you and try again.`}
                    </div>
                </Container>
            );
        }

        let loginLink, queryString = [], lStyle = {color: 'white', textDecoration: 'underline'};
        if (this.props.inviteConfirmed) {
            return this.renderInviteComplete();
        }

        if (this.props.complete) {
            return this.renderComplete();
        }

        if (this.props.token !== '') {
            queryString.push('token=' + encodeURIComponent(this.props.token));
        }

        if (this.props.email !== '') {
            queryString.push('email=' + encodeURIComponent(this.props.email));
        }

        if (this.props.service !== '') {
            queryString.push('service=' + encodeURIComponent(this.props.service));
        }

        const isStepUpService = (this.props.portal === 'user_portal') && (this.props.service === 'CAMHS');
        const panelTitleMessage = !isStepUpService ? _`Register` : _`Sign Up`;

        if (this.props.tokenError) {
            return (
                <div className="register-component" style={{paddingTop: '20px'}}>
                    <div className="container">
                        <Row>
                            <Column lgOffset="3" lg="6" mdOffset="3" md="6" smOffset="3" sm="6">
                                <Panel title={panelTitleMessage}>
                                    <div className="content">
                                        <div className="alert alert-warning">
                                            <span>
                                                The invitation link you have attempted to use has either expired or is invalid.
                                                Please contact your clinician or click on{' '}
                                                <Link style={{color: 'white', textDecoration: 'underline', fontWeight: 'bold'}}
                                                      to={'/' + this.props.portal + '/forgot-password'}>Forgot Password</Link> to retrieve your details
                                            </span>
                                        </div>
                                    </div>
                                </Panel>
                            </Column>
                        </Row>
                    </div>
                </div>
            );
        }

        loginLink = '/' + this.props.portal + '/login?' + queryString.join('&');

        return (
            <div className="register-component" style={{paddingTop: '20px'}}>
                <div className="container">
                    <Row>
                        <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="3" sm="6">
                            <Panel title={panelTitleMessage}>
                                {
                                    isStepUpService &&
                                    <div className="register-component__logo-container">
                                        <img className="register-component__logo" src="/assets/stepup-logo.png" />
                                    </div>
                                }
                                {this.props.loading ? this.renderLoading() : null}
                                {
                                    !isStepUpService ?
                                        <p>{_`Please register using the form below`}</p>
                                        :
                                        <p>{_`Please sign up using the form below`}</p>
                                }
                                <ErrorBox errors={this.props.errors}/>
                                <form onSubmit={this.props.onSubmit}>
                                    <FormGroup>
                                        <label>{_`Email Address`}</label>
                                        {this.props.email
                                            ? <p className="static-control">{this.props.email}</p>
                                            : <InputField type="email" name="email" cls={this.props}/>
                                        }

                                    </FormGroup>
                                    <FormGroup>
                                        <label>{_`Password`}</label>
                                        <InputField type="password" name="password" cls={this.props}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>{_`Confirm Password`}</label>
                                        <InputField type="password" name="confirmPassword" cls={this.props}/>
                                    </FormGroup>

                                    <div className="alert alert-info">
                                        <span>Your password must be at least 8 characters in length and not match your email address</span>
                                    </div>
                                    <hr />
                                    <FormGroup>
                                        <button type="submit"
                                                className="btn btn-primary btn-block">
                                            {!isStepUpService ? _`Register Account` : _`Sign Up`}
                                        </button>
                                    </FormGroup>
                                    <hr />
                                    {
                                        !isStepUpService &&
                                        <div className="form-group text-center">
                                            <Link to={loginLink} className="text-info">{_`Return to Login Page`}</Link>
                                        </div>
                                    }
                                </form>
                            </Panel>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }

    renderComplete () {
        return (
            <div className="register-component" style={{paddingTop: '20px'}}>
                <div className="container">
                    <Row>
                        <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="3" sm="6">
                            <Panel title={_`Confirm your Registration`}>
                                <p>Thank you for registering. You will shortly receive an email to the account that you
                                    specified when signing up.</p>
                                <p>In order to complete your registration, you will need to follow the instructions in
                                    the email.</p>
                            </Panel>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }

    renderInviteComplete () {

        const isStepUpService = (this.props.portal === 'user_portal') && (this.props.service === 'CAMHS');
        const containerClassName = classNames('sign-up-confirm', {'sign-up-confirm--coop': isStepUpService});

        return (
            <div className={containerClassName} style={{paddingTop: '20px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-offset-4 col-lg-4 col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8">
                            {!isStepUpService ?
                                <GeneralInviteBody portal={this.props.portal}/> :
                                <StepUpInviteBody />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const StepUpInviteBody = () => {
    return (
        <div className="panel panel-default">
            <div className="sign-up-confirm__header-container">
                <img className="sign-up-confirm__logo" src="/assets/stepup-logo.png" />
                <h2 className="sign-up-confirm__title text-center">
                    Sign up successful. <br/>
                    Thank you
                </h2>
            </div>
            <div className="panel-body panel-body-relative">
                <p className="text-center">Please download <strong>StepUp!</strong> now</p>
                <div className="sign-up-confirm__store-buttons-container clearfix">
                    <div className="col-xs-6 sign-up-confirm__store-button-container">
                        <a href="http://stepupcamhs.app.link/">
                            <img className="img-responsive sign-up-confirm__store-button-image"
                                 src="/assets/button-apple.svg"
                                 alt="appstore-download"/>
                        </a>
                    </div>
                    <div className="col-xs-6 sign-up-confirm__store-button-container">
                        <a href="http://stepupcamhs.app.link/">
                            <img className="img-responsive sign-up-confirm__store-button-image"
                                 src="/assets/button-android.svg"
                                 alt="googleplay-download"/>
                        </a>
                    </div>
                </div>
                <p className="text-center">and sign in</p>
            </div>
        </div>
    );
};

const GeneralInviteBody = ({portal}) => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h2 className="panel-title">Registration successful</h2>
            </div>
            <div className="panel-body panel-body-relative">
                <p>Your invitation to the platform has been successful.</p>
                <ShowLink portal={portal}/>
            </div>
        </div>
    );
};

const ShowLink = ({portal}) => {
    switch (portal) {
        case 'clinical_portal': {
            return (
                <Link to="/clinical_portal/login" className="btn btn-default">Go to Clinical Portal Login</Link>
            );
        }
        case 'admin_portal': {
            return (
                <Link to="/admin_portal/login" className="btn btn-default">Go to Admin Portal Login</Link>
            );
        }
        case 'user_portal': {
            return (
                <p>You may now login using your mobile device to your application.</p>
            );
        }
    }
};

const Container = ({children, title}) => {
    return (
        <div className="register-component" style={{paddingTop: '20px'}}>
            <div className="container">
                <Row>
                    <Column lgOffset="3" lg="6" mdOffset="3" md="6" smOffset="3" sm="6">
                        <Panel title={title || _`Register`}>
                            <div className="content">
                                {children}
                            </div>
                        </Panel>
                    </Column>
                </Row>
            </div>
        </div>
    );
};