import React from 'react';
import PropTypes from 'prop-types'
import BaseComponent from 'components/BaseComponent';
import lodash from 'lodash';
import { questionnaireService } from 'services/questionnaire.service';

export default function createQuestionnaireTemplateComponent(Component,{archetype}){

    class ViewQuestionnaireTemplateComponent extends BaseComponent{

        constructor (props) {
            super(props);

            this.state = {
                loading: true,
                templateId: this.props.templateId
            };

            this.onResults = this.onResults.bind(this);
            this.View = Component;
        }

        componentWillReceiveProps (newProps) {
            this.setState({
                templateId: newProps.templateId || 0
            }, () => {
                this.getQuestionnaire()
                    .then(this.onResults);
            });
        }

        componentDidMount () {
            this.getQuestionnaire()
                .then(this.onResults);
        }

        getQuestionnaire(){
            if (archetype === 'template') {
                return questionnaireService.getCoopQuestionnaireById(this.state.templateId);
            }

            return questionnaireService.getTemplateByUuid({uuid: this.state.templateId, archetypeName: 'questionnaire'});
        }

        /**
         * Handle the server response
         * @param {Object} response - The server message
         */
        onResults (response) {
            if (!lodash.has(response,'message')){
                return null;
            }

            const newState = {
                document:response.message,
                loading:false
            };

            this.setState(newState);
        }
    }

    ViewQuestionnaireTemplateComponent.propTypes = {
        templateId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    };

    ViewQuestionnaireTemplateComponent.defaultProps = {
        templateId: 0
    };
    
    return ViewQuestionnaireTemplateComponent;
}