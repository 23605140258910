import React from 'react';
import PropTypes from 'prop-types'

/**
 * View component for the checkbox input.
 */
export default class CheckboxInputView extends React.Component {
    
    render () {
        return (
            <div className='form-group'>
                <label className='checkbox-label small' htmlFor={this.props.id}>
                    <input
                        type={'checkbox'}
                        id={this.props.id}
                        className='form-control'
                        onChange={this.props.onChange}
                        readOnly={this.props.readOnly}
                        disabled={this.props.disabled}
                        checked={this.props.value}
                    />{' '}
                    {this.props.label}
                </label>
                <HelpBlock {...this.props} />
            </div>
        );
    }
}

const HelpBlock = ({helpText}) => {
    if (helpText == null || helpText == '') {
        return (<noscript />);
    }
    return (
        <p className='help-block'>
            {helpText}
        </p>
    );
};

CheckboxInputView.propTypes = {
    label:      PropTypes.string.isRequired,
    id:         PropTypes.string.isRequired,
    helpText:   PropTypes.string,
    readOnly:   PropTypes.bool,
    disabled:   PropTypes.bool,
    value:      PropTypes.bool.isRequired,
    onChange:   PropTypes.func.isRequired
};
