import React, { createContext, FC, useMemo } from 'react';
import { SortOrder } from './SortOrder';
import './Grid.less';
import { Filter } from './paginatedTableReducer';

export type GridSortState = {
    [key: string]: SortOrder;
}

export interface GridProps {
    onSort?: (param: GridSortState) => void;
    onFilter?: (param: Filter) => void;
    className?: string;
    sortOrder?: GridSortState;
    filterState?: Filter;
    filterable?: boolean;
    sortable?: boolean;
}
export interface GridContextData {
    onSort?: (param: GridSortState) => void;
    onFilter?: (param: Filter) => void;
    sortOrder?: GridSortState;
    filterState?: Filter;
    globalFilterable?: boolean;
    globalSortable?: boolean;
}
export const GridContext = createContext<GridContextData>(null);

export const styleSortedColumns = 'ui-grid__highlight-sorted';

export const Grid: FC<GridProps> = (props) => {
    const {
        children,
        onSort,
        onFilter,
        sortOrder,
        filterState = {},
        filterable,
        sortable,
        className = ''
    } = props;

    const gridContextData = useMemo(() => {
        return {
            onSort,
            onFilter,
            sortOrder,
            filterState,
            globalFilterable: filterable ?? true,
            globalSortable: sortable ?? true,
        };
    }, [onSort, onFilter, sortOrder, filterState, filterable, sortable]);

    return (
        <GridContext.Provider value={gridContextData}>
            <table className={'ui-grid ' + className}>
                {children}
            </table>
        </GridContext.Provider>
    );
};
