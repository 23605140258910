import React, { useState, useEffect } from 'react';
import ErrorBox from 'components/errorbox';
import _ from 'services/i18n';
import { browserHistory } from 'react-router';
import DepartmentSelect from 'features/department/components/DepartmentSelect/DepartmentSelect';
import { ModalDialog } from 'common/modalDialog';
import { resourceCategoryService } from 'services/resourceCategory.service';
import { ResourceCategory } from 'models/ResourceCategory';
import { Composition } from 'models/Composition';
import { makeDepartmentFolderLabel } from 'features/department/department.utils';
import './ResourceCollectionModal.scss';

type ResourceCollectionModalProps = {
    onClose: () => void;
    onSubmit: () => void;
    category?: Composition<ResourceCategory>;
}

const ResourceCollectionModal = ({ category, onClose, onSubmit }: ResourceCollectionModalProps) => {
    const [name, setName] = useState(category?.content.name || '');
    const [description, setDescription] = useState(category?.content.description || '');
    const [departmentUUID, setDepartmentUUID] = useState<string>(null);
    const [errors, setErrors] = useState([]);
    const [categoryMap, setCategoryMap] = useState(new Map());

    useEffect(() => {

        async function fetchCategories() {
            const existingCategoriesResponse = await resourceCategoryService.list({ folderId: 'team' });
            const existingCategoryMap = new Map();

            existingCategoriesResponse.message.results.map((item) => {
                existingCategoryMap.set(item.content.name.trim().toLowerCase(), true);
            });

            setCategoryMap(existingCategoryMap);
        }
        fetchCategories();
    }, []);

    useEffect(() => {
        if (category && category.content?.labels) {
            const departmentLabel = category.content?.labels.filter(label => label.type === 'department-folder')[0];
            setDepartmentUUID(departmentLabel ? departmentLabel.context : null);
        }
    }, [category]);

    const submitForm = async () => {
        const safeName = name.trim().toLowerCase();

        if (safeName == '') {
            setErrors([_`Collection name cannot be blank`]);
            return;
        }

        if (category == null && categoryMap.has(safeName)) {
            setErrors([_`Collection name already exists.`]);
            return;
        }

        if (description.trim() === '') {
            setErrors([_`Collection description cannot be blank`]);
            return;
        }

        if (departmentUUID == null) {
            setErrors([_`Department cannot be blank`]);
            return;
        }

        try {

            const departmentLabel = makeDepartmentFolderLabel(departmentUUID);

            if (category?.uuid) {
                resourceCategoryService.update({ uuid: category.uuid, content: { name: name.trim(), description: description.trim(), labels: [departmentLabel] }, folderId: 'team' })
                    .then(() => {
                        onSubmit();
                    });
            } else {
                resourceCategoryService.create({
                    content: { name: name.trim(), description: description.trim(), labels: [departmentLabel] }, folderId: 'team'
                }).then((createdCollectionUUID) => {
                    onSubmit();
                    browserHistory.push(`/admin_portal/resources/${createdCollectionUUID}`);
                });
            }

        } catch (error) {
            setErrors([error.response.message.details]);
        }
    };

    return (
        <div className="modal-resource-category-create">
            <ModalDialog 
                title={category?.uuid ? _`Edit Resource Collection` : _`Create Resource Collection`}
                onClose={onClose} 
                busy={false} 
                buttons = {
                    (
                        <>
                            <button
                                className="btn btn-default btn-success"
                                onClick={submitForm}
                            >
                                {category?.uuid ? _`Update Collection` : _`Create Collection`}
                            </button>
                            <button className="btn btn-default" onClick={onClose}>
                                Cancel
                            </button>
                        </>
                    )
                }
                overflow={true}
            >
                <div className='resource-collection-form'>
                    <div className="row">
                        <div className="col-md-12">
                            <ErrorBox errors={errors} />
                            <div className="form-group">
                                <label className='field-label'>{_`Please name this collection of resources`}</label>
                                <input type="text" className="form-control" name="name"
                                    onChange={e => setName(e.target.value)} value={name} />
                                <label className='field-label'>{_`Please add a description for this collection of resources`}</label>
                                <textarea className="form-control" name="description"
                                    onChange={e => setDescription(e.target.value)} value={description} />
                                <label className='field-label'>{_`Choose relevant department for this collection`}</label>
                                <DepartmentSelect departmentUUID={departmentUUID} setDepartmentUUID={setDepartmentUUID} showAllDepartmentOption={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        </div>
    );
};

export default ResourceCollectionModal;
