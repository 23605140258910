import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import { browserHistory } from 'react-router';
import _ from 'services/i18n';
import HeaderMenu from 'layouts/template/_header-menu';
import SideMenu from 'layouts/template/_side-menu';
import { useCurrentUser } from 'common/useCurrentUser';
import { SidebarItems } from './SidebarItems';
import teamPreferencesService from 'services/team-preferences.service';

export const goToPage = ({ url, query: newQuery, replaceOldQuery = false }) => {
    if(!replaceOldQuery) {
        const query = browserHistory.getCurrentLocation().query;
        newQuery = { ...query, ...newQuery };
    }
    const queryString = Object.keys(newQuery)
        .reduce((acc, queryKey) => {
            if(newQuery[queryKey]) {
                acc.push(`${queryKey}=${encodeURIComponent(newQuery[queryKey])}`);
            }
            return acc;
        }, []).join('&');
    browserHistory.push(`${url}?${queryString}`);
};

const useSearch = () => {
    const location = browserHistory.getCurrentLocation();
    const initialSearch = useMemo(() => location.query.search || '', [location.query.search]);

    const searchPatientAction = useCallback((type, search) => {
        goToPage({
            url: '/clinical_portal/my-team/patients',
            query: { search: search.replace(/\s/g, ' '), page: 1 }
        });
    }, []);

    return {
        onSubmit: searchPatientAction,
        onChange: () => null,
        value: initialSearch,
    };
};


export function NewNavigation({
    title,
    location,
    teamName,
    onTeamClick,
}) {
    const [sideMenuShow, setSideMenuShow] = useState(false);
    const [teamPreferences, setTeamPreferences] = useState();
    const {
        onSubmit: onSearchSubmit,
        onChange: onSearchChange,
        value: searchValue
    } = useSearch();

    useEffect(() => {
        if(teamPreferences) { return; }
        teamPreferencesService.get({}).then((res) => {
            setTeamPreferences(res);
        });
    }, [])


    const user = useCurrentUser();

    const baseLink = teamPreferences?.portal.show_default_dashboard_tabs ? '/clinical_portal' : '/clinical_portal?filter=advanced-search';
    const menuLinks = [
        { title: 'My Filters', link: `${baseLink}` },
        { title: 'My Team', link: '/clinical_portal/my-team' },
    ];

    const userMenuLinks = [
        { link: '/clinical_portal/profile', label: _`My Profile` },
        { link: '/clinical_portal/logout', label: _`Log Out` }
    ];

    return (
        <div>
            <SideMenu
                title={_`${title}`}
                links={menuLinks}
                onSearchBoxChange={({ value }) => onSearchChange(value)}
                searchAction={onSearchSubmit}
                visible={sideMenuShow}
                toggle={() => setSideMenuShow(!sideMenuShow)}
            />
            <HeaderMenu
                title={_`${title}`}
                searchAction={onSearchSubmit}
                onSearchBoxChange={({ value }) => onSearchChange(value)}
                sideMenuButtonAction={() => setSideMenuShow(!sideMenuShow)}
                links={menuLinks}
                location={location}
                searchValue={searchValue}
                user={user}
                onProfileClick={() => browserHistory.push('/clinical_portal/profile')}
                userMenuLinks={userMenuLinks}
                baseLink={baseLink}
            >
                <SidebarItems
                    teamName={teamName}
                    onTeamClick={onTeamClick}
                />
            </HeaderMenu>
        </div>
    );
}
