import { CompositionService } from "./composition.service";
import { post, get } from "../../app/services/api";
import { DataStore } from "../../app/services/data-store";

const teamId = (DataStore.get("me.currentRole") || {}).teamId || 0;

class ProfileService extends CompositionService {
  get archetypeName(): string {
    return "userProfile";
  }

  getCurrentUserProfile(): Promise<any> {
    return get(`/api/currentUserProfile?team_id=${teamId}`);
  }

  saveEmail = (emailToSave, redirectLink) => {
    const requestPayload = {
      email: emailToSave,
      redirect_link: redirectLink,
    };
    return post(`api/change-own-email?`, requestPayload);
  };

  changePassword = (password, newPassword, portalType) => {
    const data = {
      password: password,
      newPassword: newPassword,
    };
    return post(`${portalType}/change-password?team_id=${teamId}`, data);
  };

  changePhoto = (photo, portalType) => {
    const data = {
      photo: photo,
    };
    return post(`${portalType}/changePhoto?team_id=${teamId}`, data);
  };
}

export const profileService = new ProfileService();
