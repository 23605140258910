/* eslint-disable @typescript-eslint/camelcase */

import { SimpleQuestionnaireCreate, SimpleQuestion, SimpleQuestionnaireFormResult } from 'models/compositions/SimpleQuestionnaire';
import { PreviewSimpleQuestionnaireApiModel } from './PreviewSimpleQuestionnaireModel';

export class SimpleQuestionnaireForm implements SimpleQuestionnaireCreate {

    public content_type: string;
    public location: string;
    public version: string;
    public id: string;
    public live: {
        in_lozenge: boolean;
        name: string;
        questions: SimpleQuestion[];
        introduction_text: string;
    };

    constructor(defaults: SimpleQuestionnaireCreate) {
        Object.assign(this, defaults);
    }

    get questionCount(): number {
        return this.live.questions.length;
    }

    get questions(): SimpleQuestion[] {
        return this.live.questions;
    }

    set questions(questions: SimpleQuestion[]) {
        this.live.questions = questions;
    }

    public addQuestion(): void {
        const emptyQuestion: SimpleQuestion = {
            order: this.questions.length + 1,
            question_text: null,
            question_type: null,
            question_parameters: {
                options: [null],
            },
            isQuestionValid: false,
        };

        this.questions = this.questions.concat(emptyQuestion);
    }

    public removeQuestion(question: SimpleQuestion): void {
        this.questions = this.questions
            .filter(q => q.order !== question.order)
            .map((question, index) => {
                question.order === index + 1;
                return question;
            });
    }

    public updateQuestion(question: SimpleQuestion): void {
        this.questions = this.questions.map((q) => {
            if (q.order == question.order) {
                return question;
            }

            return q;
        });
    }

    public updateQuestionPosition(from: number, to: number): void {
        const [reorderedItem]: any = this.questions.splice(from, 1);
        this.questions.splice(to, 0, reorderedItem);

        this.questions = this.questions.map((question, index) => ({
            ...question,
            order: index + 1
        }));
    }

    public isValid(): SimpleQuestionnaireFormResult {
        let errorMessages: string[] = [];

        if (!this.live.name) { errorMessages.push('Questionnaire needs a name'); }
        if (!this.live.introduction_text) { errorMessages.push('Please add Introduction text'); }

        const questionsValidationResult = this.areQuestionsValid();

        if (!questionsValidationResult.isValid) {
            errorMessages = errorMessages.concat(questionsValidationResult.messages);
        }

        return {
            isValid: errorMessages.length === 0,
            messages: errorMessages
        };
    }

    public areQuestionsValid(): SimpleQuestionnaireFormResult {
        const errors: string[] = [];
        const hasQuestions: boolean = this.live.questions.length >= 1;

        this.questions.forEach((question) => {
            if (!question.isQuestionValid) {
                errors.push(`Please check Question no: ${question.order}`);
            }
        });

        if (!hasQuestions) { errors.push('Please add at least one question'); }

        return {
            isValid: errors.length === 0,
            messages: errors
        };
    }

    public static Update(defaults: SimpleQuestionnaireCreate): SimpleQuestionnaireForm {
        return new SimpleQuestionnaireForm(defaults);
    }

    public static Create(existingQuestionnaire?: SimpleQuestionnaireCreate): SimpleQuestionnaireForm {
        if (existingQuestionnaire) {
            return this.HydrateFromExistingQuestionnaire(existingQuestionnaire);
        }

        const defaults = {
            content_type: 'simple-questionnaire',
            location: 'general_content/simple-questionnaire',
            version: '',
            id: '',
            live: {
                in_lozenge: true,
                name: '',
                questions: [],
                introduction_text: '',
            }
        } as SimpleQuestionnaireCreate;

        return new SimpleQuestionnaireForm(defaults);
    }

    private static HydrateFromExistingQuestionnaire(existingQuestionnaire: SimpleQuestionnaireCreate): SimpleQuestionnaireForm {
        const questionnaire = SimpleQuestionnaireForm.Create();

        questionnaire.live.name = existingQuestionnaire.live.name;
        questionnaire.id = existingQuestionnaire.id;
        questionnaire.version = existingQuestionnaire.version;
        questionnaire.live.introduction_text = existingQuestionnaire.live.introduction_text;
        questionnaire.live.in_lozenge = true;
        questionnaire.content_type = existingQuestionnaire.content_type;
        questionnaire.live.questions = existingQuestionnaire.live.questions.map((q, index) => {
            return {
                order: index + 1,
                isQuestionValid: q.isQuestionValid,
                question_text: q.question_text,
                question_type: q.question_type,
                question_parameters: q.question_parameters
            } as SimpleQuestion;
        });

        return questionnaire;
    }

    public static hydrateForPreviewQuestionnaire = (questionnaire: SimpleQuestionnaireForm): PreviewSimpleQuestionnaireApiModel => {
        return {
            location: questionnaire.location,
            id: questionnaire.id,
            lozenge_content: questionnaire.live.introduction_text,
            lozenge_title: questionnaire.live.name
        } as PreviewSimpleQuestionnaireApiModel;
    }
}
