import { useMemo } from 'react';
import { useI18N } from '../../common/i18n/useI18N';
import { convertToOutputTzDate, formatDate } from '../../common/datetime/DateTime';
import { AttendAnywhereItem } from './AttendAnywhereHelpers';
import { convertToTimestamp } from 'common/datetime/convertToDate';

export const usePreparedAttendAnywhere = (attendAnywhereList: AttendAnywhereItem[]) => {
    const _ = useI18N();
    return useMemo(() => attendAnywhereList.map(attendAnywhereItem => {
        const date = convertToTimestamp(attendAnywhereItem.date);

        return {
            dateSort: date,
            dateFilter: formatDate(convertToOutputTzDate(attendAnywhereItem.date), 'nhs_date_with_time'),
            ...attendAnywhereItem,
            _source: attendAnywhereItem,
        }

    }), [attendAnywhereList]);
};
