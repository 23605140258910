/* eslint-disable indent */
import React, { FC } from 'react';
// components
import {
    AdvancedSearchCriterionCSVColumnImport,
    AdvancedSearchCriterionListInputMultiSelection,
    AdvancedSearchCriterionListInputSingleSelection,
    AdvancedSearchInputUnavailable,
} from 'features/patients/advanced-search/AdvancedSearchCriterionInputs';
// hooks
import { useCriterionListValue } from 'features/patients/advanced-search/AdvancedSearchSetupHooks';
// utils
import {
    getDisplayListForQueryValueOptions,

} from 'models/AdvancedSearchDefinition';
// interfaces
import { AdvancedSearchCriterionListProps } from './AdvancedSearchCriterionList.interface';
import { getDisplayValuesForValueOptions } from 'common/ui/dropdown/DropdownUtils';

export const AdvancedSearchCriterionList: FC<AdvancedSearchCriterionListProps> =
    (props) => {
        const [values, setValues] = useCriterionListValue(props.criterionValue);

        const optionList = getDisplayListForQueryValueOptions(props.config);
        const valuesWithDefault = getDisplayValuesForValueOptions(
            optionList,
            values,
        );
        const onSelectionChange = (newSelection) =>
            setValues(newSelection.map((item) => item.value));

        const type = props.config.type;
        switch (type) {
            case 'fileupload':
                return (
                    <AdvancedSearchCriterionCSVColumnImport
                        documentType={props.documentType}
                        path={props.config.path}
                        onChange={setValues}
                        initialValues={values}
                        validator={props.validator}
                    />
                );
            case 'options':
            case 'boolean':
            case 'yesNo':
                if (optionList.length <= 2) {
                    return (
                        <AdvancedSearchCriterionListInputSingleSelection
                            config={props.config}
                            value={values ? values[0] : undefined}
                            onChange={(newValue) => setValues([newValue])}
                        />
                    );
                }

                return (
                    <AdvancedSearchCriterionListInputMultiSelection
                        value={valuesWithDefault}
                        options={optionList}
                        onChange={onSelectionChange}
                    />
                );
            default:
                console.error(
                    `Search criteria type [${type}] doesn't support multiple-choice input.`,
                );
                return <AdvancedSearchInputUnavailable />;
        }
    };
