import React, { FunctionComponent, useState, useRef, useEffect, useCallback } from 'react';
import { useForm } from 'common/form/useForm';
import _ from 'services/i18n';

export interface AntenatalAppointmentProps {
    ubrn?: string;
    bookingPassword?: string;
    isSubmitFromOutside?: boolean;
    isReadOnly?: boolean;
    onChange?: ({
        disable,
        isSubmitFromOutside
    }) => void;
    onSubmit: (state: object) => void;
}

export const AntenatalAppointmentForm: FunctionComponent<AntenatalAppointmentProps> = (props) => {
    const {
        ubrn,
        bookingPassword,
        isSubmitFromOutside,
        isReadOnly = false,
        onChange,
        onSubmit,
    } = props;

    const [onceSubmitted, setStateSubmitted] = useState(false);

    const appointmentFormState = {
        ubrn: { value: ubrn || '', error: '' },
        bookingPassword: { value: bookingPassword || '', error: '' }
    };

    const appointmentFormSchema = {
        ubrn: {
            required: true,
            rules: [
                (value: any) => {
                    return !/^\d{12}$/.test(value.trim()) ? 'Invalid UBRN' : null;
                }
            ]
        },
        bookingPassword: {
            required: true,
        }
    };

    const handleSubmitForm = useCallback(
        (state) => {
            onSubmit({
                bookingPassword: state.bookingPassword.value,
                ubrn: state.ubrn.value,
            });
        },
        [onSubmit],
    );

    const { state, handleOnChange, handleOnSubmit, disable } = useForm(
        appointmentFormState,
        appointmentFormSchema,
        handleSubmitForm,
    );

    const onSubmitHandler = (...args: any[]) => {
        setStateSubmitted(true);
        handleOnSubmit(args[0]);
    };

    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (!formRef || !isSubmitFromOutside) { return; }
        let event;
        if(typeof(Event) === 'function') {
            event = new Event('submit');
        }else{
            // ie 11
            event = document.createEvent('Event');
            event.initEvent('submit', true, true);
        }
        formRef.current.dispatchEvent(event);
    }, [isSubmitFromOutside]);

    if (onChange) {
        onChange({
            isSubmitFromOutside: false,
            disable,
        });
    }

    return (
        <div className={'antenatal-form'}>
            <form onSubmit={onSubmitHandler} ref={formRef}>
                <div className={'antenatal-form_table-layout'}>
                    <div className="antenatal-form_table-layout-row">
                        <div className={'antenatal-form_table-layout-cell'}>
                            <span className={'antenatal-form_label'}>{_`UBRN`}</span>
                        </div>
                        <div className={'antenatal-form_table-layout-cell'}>
                            <input onChange={handleOnChange}
                                value={state['ubrn'].value}
                                type="text"
                                name={'ubrn'}
                                {...(isReadOnly ? { readOnly: true } : {} )}
                                className="form-control"
                                placeholder={_`Type UBRN...`}/>
                            {onceSubmitted &&
                            <div className={'antenatal-form_error'}>{state['ubrn'].error}</div>
                            }
                        </div>
                    </div>
                    <div className="antenatal-form_table-layout-row">
                        <div className={'antenatal-form_table-layout-cell'}>
                            <span className={'antenatal-form_label'}>{_`Booking password`}</span>
                        </div>
                        <div className={'antenatal-form_table-layout-cell'}>
                            <input onChange={handleOnChange}
                                value={state['bookingPassword'].value}
                                type="text"
                                name={'bookingPassword'}
                                {...(isReadOnly ? { readOnly: true } : {} )}
                                className="form-control"
                                placeholder={_`Type Booking Password...`}/>
                            {onceSubmitted &&
                            <div className={'antenatal-form_error'}>{state['bookingPassword'].error}</div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
