import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import LoginComponent from 'components/login';
import connect from 'react-redux/es/connect/connect';
import { logout } from 'actions/auth.actions';

/**
 * Extended Page Component for user_portal/login
 **/

export class ClinicalLogout extends BasePage {
    constructor(props) {
        super(props);

        this.state = {
            message: _`Clearing session...`
        };
    }

    pageTitle() {
        return _`Clinical Portal Login | iFocus Healthcare`;
    }

    componentDidMount() {
        const logoutMessage = _`You have been logged out of the Clinical Portal`;

        this.props.dispatch(logout())
            .then(() => {
                this.setState({
                    message: logoutMessage
                });
            })
            .catch(() => {
                console.error(`Error while logging out: ${JSON.stringify(error)}`);
                this.setState({
                    message: logoutMessage
                });
            });
    }

    render() {
        return (<LoginComponent title={_`Clinical Portal Login`} message={this.state.message}
                                portal="clinical_portal" {...this.props} />)
    }
}

export default connect()(ClinicalLogout);

