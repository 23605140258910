import moment from 'moment-timezone';
import { upperFirst as _upperFirst, lowerCase as _lowerCase } from 'lodash';

class StepsService {

    private readonly DATE_FORMAT = 'YYYY-MM-DD';

    /**
     * @description
     * return will include today and start date
     *
     * @param startDate {string|number} - timestamp
     * @returns {number}
     */
    whatDayFrom(startDate: number): number {
        return this.getDatesDiff(startDate, moment().unix());
    };

    /**
     * @description
     * include start and stop date
     * in case of startDate and stopDate are the same - diff will be equal to 1
     *
     * @param startDate {string|number} - timestamp
     * @param stopDate {string|number} - timestamp
     * @returns {number}
     */
    getDatesDiff(startDate: number, stopDate: number): number {

        const momentStartDate = moment.unix(startDate).startOf('day');
        const momentStopDate = moment.unix(stopDate).add(1, 'days').startOf('day');

        return momentStopDate.diff(momentStartDate, 'days');
    };

    /**
     *
     * @param startDate - timestamp
     * @param scores {{
     *     date: timestamp
     *     value: number
     * }[]}
     * @param threshold {number}
     * @returns {{
     *     weekNumber: string 'YYYY-ww(isoWeekNumber as 00)',
     *     days: {
     *         color: '#fff',
     *         backgroundColor: '#fff',
     *         date: 'YYYY-MM-DD',
     *         y: number,
     *         x: string (week day label or today)
     *     }[]
     * }[]}
     * @note
     * last day in last week should have label today if it is actually today
     */
    generateGraphData(startDate: number, scores: Array<{ date: number, value: number }> = [], threshold: number = 0): Array<{
        weekNumber: string,
        days: Array<{
            color: string,
            backgroundColor: string,
            date: string,
            y: number
            x: string
        }>
    }> {
        const existingDays = this.summByDate(scores);
        const allDays = this.fillMissedDays(startDate, moment().unix(), existingDays);
        const weeks = this.groupByWeeks(allDays).map((week) => {
            return ({
                weekNumber: week.weekNumber,
                days: this.daysToGraphData(week.days, threshold)
            });
        });

        if (weeks.length) {
            const lastWeek = weeks[weeks.length - 1];
            const lastDay = lastWeek.days[lastWeek.days.length - 1];
            if (lastDay.date === moment().format(this.DATE_FORMAT)) {
                lastDay.x = 'Today';
            }
        }

        return weeks;
    };

    /**
     *
     * @param days {{
     *     value: number,
     *     date: string 'YYYY-MM-DD',
     *     dayOfWeek: number ('1-7 ISO'),
     * }}
     * @param threshold {number}
     * @returns {{
     *     color: '#fff',
     *     backgroundColor: '#fff',
     *     date: 'YYYY-MM-DD',
     *     y: number 'value of the day',
     *     x: day label
     * }[]} - array of graph specific days
     */
    daysToGraphData(days: Array<{ value: number, date: string, dayOfWeek: number }> = [], threshold: number = 0): Array<{
        color: string,
        backgroundColor: string,
        date: string,
        y: number,
        x: string
    }> {

        const positiveBgColor = '#2ba558';
        const negativeBgColor = '#ff2c36';
        const color = '#f9d90d';

        const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

        return days.map(v => {
            return {
                color,
                backgroundColor: v.value >= Math.ceil(threshold * 0.8) ? positiveBgColor : negativeBgColor,
                date: v.date,
                y: v.value,
                x: _upperFirst(_lowerCase(daysOfWeek[v.dayOfWeek - 1]))
            };
        });

    };

    /**
     *
     * @param scores {{
     *     date: timestamp
     *     value: number
     * }[]}
     * @returns {{
     *     'YYYY-MM-DD' : value
     *     ...
     * }}
     */
    summByDate(scores: Array<{ date: number, value: number }> = []): { [key: string]: number } {
        return scores.reduce((acc, score, i) => {
            const date = moment.unix(score.date);
            const formatted = date.format('YYYY-MM-DD');
            acc[formatted] = acc[formatted] ? acc[formatted] + score.value : 0 + score.value;
            return acc;
        }, {} as { [key: string]: number });
    };

    /**
     *
     * @param days {{
     *     'YYYY-MM-DD' : value
     *     ...
     * }}
     *
     * @returns {{
     * weekNumber: 'YYYY-ww(iso week number)',
     * days: {
     *   value: number,
     *   date: 'YYYY-MM-DD'
     *   dayOfWeek: 'iso day of week'
     * }[]
     * }[]}
     */
    groupByWeeks(days: { [key: string]: number } = {}) {

        type Day = {
            value: number,
            date: string,
            dayOfWeek: number
        };

        const weeks = Object.keys(days).sort().reduce((prev, next) => {
            const date = moment(next);
            const weekNum = `${date.isoWeek()}`;
            const week = `${date.year()}-${weekNum.length === 1 ? `0${weekNum}` : weekNum}`;
            prev[week] = prev[week] ? prev[week] : [];
            prev[week].push({value: days[next], date: next, dayOfWeek: date.isoWeekday()});
            return prev;
        }, {} as {
            [key: string]: Array<Day>
        });

        return Object.keys(weeks).sort().reduce((prev, weekNumber) => {
            prev.push({
                weekNumber,
                days: weeks[weekNumber]
            });
            return prev;
        }, [] as Array<{
            weekNumber: string,
            days: Array<Day>
        }>);
    };


    /**
     *
     * @param startDate {timestamp}
     * @param stopDate {timestamp}
     * @param days {{
     *     'YYYY-MM-DD' : value
     *     ...
     * }}
     * @returns {{
     *     'YYYY-MM-DD' : value
     *     ...
     * }}
     */
    fillMissedDays(startDate: number, stopDate: number, days: { [key: string]: number }): { [key: string]: number } {
        let startDateFormatted = moment.unix(startDate).format(this.DATE_FORMAT);
        const stopDateFormatted = moment.unix(stopDate).format(this.DATE_FORMAT);

        const dates = Object.keys(days).sort();

        if (startDateFormatted > dates[0]) {
            console.warn('Current goal startDate (goal.created_at) is smaller than first goal item date.');
            console.warn('startDate', startDateFormatted, '\ngoal Item date', dates[0]);
            startDateFormatted = dates[0];
        }

        if (dates.indexOf(startDateFormatted) === -1) {
            dates.push(startDateFormatted);
        }

        if (dates.indexOf(stopDateFormatted) === -1) {
            dates.push(stopDateFormatted);
        }
        dates.sort();

        const newDates = [];

        const dateStart = dates[0];
        const dateStop = dates[dates.length - 1];
        if (dates.length && dateStart <= dateStop) {
            for (let date = dateStart; date <= dateStop; date = moment(date).add(1, 'days').format(this.DATE_FORMAT)) {
                newDates.push(date);
            }
            return newDates.reduce((prev, next) => {
                prev[next] = days[next] || 0;
                return prev;
            }, {} as { [key: string]: number });
        } else {
            return days;
        }
    };
}

export const stepsService = new StepsService();
