import React, { ChangeEvent, FC, FormEvent, useMemo } from 'react';
import _ from 'services/i18n';
import ErrorView from 'components/errorbox';
import DateInput from 'components/form/date-input';
import { AllocateQuestionnaireForm } from 'features/patient/questionnaire/create/index';
import { Moment } from 'moment';
import { standardDateTimeFormats } from 'common/datetime/convertToDate';
import Select from 'react-select';
import { SortedDepartmentData } from 'features/department/department.interface';
import { useSortedDepartmentLabelableData } from 'features/department/hooks/useSortedDepartmentData';
import { CoopQuestionnaire, Questionnaire, QuestionnaireType } from 'models/QuestionnaireData';
import { useGroupedDepartmentDataOptionList } from 'features/department/hooks/useGroupedDepartmentDataOptionList';
import { findDepartmentDataOptionInGroupedList } from 'features/department/department.utils';

export interface ViewProps {
    errors: string[];
    loading: boolean;
    showTimelineFields: boolean;
    showPushNotification: boolean;
    dueBy: Moment;
    formData: AllocateQuestionnaireForm;
    // todo: any
    questionnaireTemplateList: any[];
    onSubmit: (e: FormEvent) => void;
    onChange: (e: ChangeEvent) => void;
    onChangeDueBy: (date: ChangeEvent<HTMLInputElement>) => void;
}

export const AllocateQuestionnaireView: FC<ViewProps> = (props) => {
    return (
        <div className='questionnaire-create'>
            <ErrorView errors={props.errors}/>
            <Form
                loading={props.loading}
                showTimelineFields={props.showTimelineFields}
                showPushNotification={props.showPushNotification}
                dueBy={props.dueBy}
                questionnaireTemplateList={props.questionnaireTemplateList}
                formData={props.formData}
                onSubmit={props.onSubmit}
                onChange={props.onChange}
                onChangeDueBy={props.onChangeDueBy}
            />
        </div>
    );
};

export const TemplateSelect = ({ questionnaireTemplateList = [], questionnaireTemplateId, onChange }) => {
    const formattedQuestionnaireList: (CoopQuestionnaire | Questionnaire)[] = useMemo(() => {
        return questionnaireTemplateList.reduce((formattedCompositions, questionnaire) => {
            if (questionnaire.type === QuestionnaireType.COMPOSITION) {
                formattedCompositions.push({
                    ...questionnaire.document.content,
                    uuid: questionnaire.document.uuid
                });
            } else {
                formattedCompositions.push(questionnaire.document);
            }

            return formattedCompositions;
        }, []);
    }, [questionnaireTemplateList]);

    const sortedListData: SortedDepartmentData<CoopQuestionnaire | Questionnaire> = useSortedDepartmentLabelableData(formattedQuestionnaireList);

    const [searchOptionList, numOptions] = useGroupedDepartmentDataOptionList(
        sortedListData,
        d => d.name,
    );

    const selectedQuestionnaire = findDepartmentDataOptionInGroupedList(searchOptionList, q => q.uuid === questionnaireTemplateId || q.id === questionnaireTemplateId);

    return (
        <Select
            className="filter-select questionnaire-template-select"
            classNamePrefix="filter-select"
            placeholder={`Select a Questionnaire (${numOptions})`}
            options={searchOptionList}
            onChange={({ value }) => {
                onChange({
                    target: {
                        value: value.id ? value.id : value.uuid,
                        name: 'questionnaireTemplateId'
                    }
                });
            }}
            value={selectedQuestionnaire}
        />
    );
};

export interface FormProps {
    loading: boolean;
    showTimelineFields: boolean;
    showPushNotification: boolean;
    dueBy: Moment;
    onSubmit: (e: FormEvent) => void;
    onChange: (e: ChangeEvent) => void;
    onChangeDueBy: (e: ChangeEvent<HTMLInputElement>) => void;
    // todo: any
    questionnaireTemplateList: any[];
    formData: AllocateQuestionnaireForm;
}

const Form: FC<FormProps> = (props) => {
    if (props.loading) {
        return (
            <h5>{_`Loading please wait...`}</h5>
        );
    }

    return (
        <form onSubmit={props.onSubmit} noValidate>
            <div className='form-group'>
                <label htmlFor='questionnaireTemplateId'>{_`Questionnaire`}: </label>
                <TemplateSelect
                    questionnaireTemplateId={props.formData.questionnaireTemplateId}
                    questionnaireTemplateList={props.questionnaireTemplateList}
                    onChange={props.onChange}
                />
            </div>
            {!!props.showTimelineFields && (
                <div className="timeline-fields">
                    <div className='form-group'>
                        <label htmlFor='lozengeTitle'>{_`Lozenge Title`}: </label>
                        <input
                            placeholder={_`Please enter lozenge title...`}
                            type="text"
                            className={'form-control'}
                            name={'lozengeTitle'}
                            value={props.formData.lozengeTitle}
                            onChange={props.onChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='lozengeContent'>{_`Lozenge Content`}: </label>
                        <input
                            placeholder={_`Please enter lozenge content...`}
                            type="text"
                            className={'form-control'}
                            name={'lozengeContent'}
                            value={props.formData.lozengeContent}
                            onChange={props.onChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='popupTitle'>{_`Popup Title`}: </label>
                        <input
                            placeholder={_`Please enter popup title...`}
                            type="text"
                            className={'form-control'}
                            name={'popupTitle'}
                            value={props.formData.popupTitle}
                            onChange={props.onChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='popupContent'>{_`Popup Content`}: </label>
                        <input
                            placeholder={_`Please enter popup content...`}
                            type="text"
                            className={'form-control'}
                            name={'popupContent'}
                            value={props.formData.popupContent}
                            onChange={props.onChange}
                        />
                    </div>
                </div>
            )}
            {!!props.showPushNotification && (
                <div className='form-group'>
                    <label className='checkbox-inline'>
                        <input type="checkbox" name="sendPush" onChange={props.onChange} checked={props.formData.sendPush}/>
                        <span>{_`Notify patient by  push notification`}</span>
                    </label>
                </div>
            )}
            <div className='form-group'>
                <label className='checkbox-inline'>
                    <input
                        type='checkbox'
                        name='useDueBy'
                        value='1'
                        onChange={props.onChange}
                        checked={props.formData.useDueBy}
                    />
                    {_`Use Due By Date`}
                </label>
            </div>
            {props.formData.useDueBy ? (
                <div className='form-group'>
                    <label htmlFor='dueBy'>{_`Due Date`}: </label>
                    <DateInput
                        name="due-by"
                        value={props.dueBy.format(standardDateTimeFormats.date_input)}
                        onChange={props.onChangeDueBy}
                    />
                </div>
            )
                : null
            }
            <div className='form-group'>
                <button type='submit' className='btn btn-primary'>
                    {_`Allocate Questionnaire`}
                </button>
            </div>
        </form>
    );
};
