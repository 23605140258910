import React, { Fragment } from 'react';

import {Iframe} from "components/user/letters/create/iframe";
import {SendLetterPage} from "models/TriggerEngineRequestContent";

const AutoCommunicationLetterReview = ({pages}: { pages: SendLetterPage[] }) => {
    return (
        <Fragment>
            <h4>Letter</h4>
            <div className="letter-preview">
                {pages.map(function (page, i) {
                    return <div className="letter-page" key={"page-" + i}>
                        <Iframe
                            srcDoc={page.body}
                            style={{border: 'none', width: '600px'}}
                            useScrollHeight={false}
                        />
                    </div>
                })}
            </div>
        </Fragment>
    );
};

export default AutoCommunicationLetterReview;
