import { DASHBOARD_COMPONENT_NAMES } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { DashboardColumn } from './dashboard-column/DashboardColumn';
import { DashboardDummy } from './dashboard-dummy/DashboardDummy';
import { DashboardRow } from './dashboard-row/DashboardRow';
import { DashboardQuestionnaireGraph } from './dashboard-questionnaire-graph/DashboardQuestionnaireGraph';
import { DashboardQuestionnaireTable } from './dashboard-questionnaire-table/DashboardQuestionnaireTable';
import { DashboardDemographicsTable } from './dashboard-demographics-table/DashboardDemographicsTable';
import { DashboardDemographicsTableForCarer } from 'features/patient/patient-dashboard/dashboard-components/dashboard-demographics-table-for-carer/DashboardDemographicsTableForCarer';
import { DashboardQuestionnaireGraphForCarer } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-graph-for-carer/DashboardQuestionnaireGraphForCarer';
import { DashboardQuestionnaireTableForCarer } from './dashboard-questionnaire-table-for-carer/DashboardQuestionnaireTableForCarer';

export const dashboardComponentsMap = {
    [DASHBOARD_COMPONENT_NAMES.DashboardRow]: DashboardRow,
    [DASHBOARD_COMPONENT_NAMES.DashboardColumn]: DashboardColumn,
    [DASHBOARD_COMPONENT_NAMES.DashboardDummy]: DashboardDummy,
    [DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireGraph]: DashboardQuestionnaireGraph,
    [DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireGraphForCarer]: DashboardQuestionnaireGraphForCarer,
    [DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTable]: DashboardQuestionnaireTable,
    [DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireTableForCarer]: DashboardQuestionnaireTableForCarer,
    [DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTable]: DashboardDemographicsTable,
    [DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTableForCarer]: DashboardDemographicsTableForCarer,
};
