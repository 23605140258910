import { createContext, useContext } from 'react';

interface AdvancedSearchHelperContextState {
    refresh: () => void;
}

export const AdvancedSearchHelperContext =
    createContext<AdvancedSearchHelperContextState>(null);

export const useAdvancedSearchHelper = () => {
    const helpers = useContext(AdvancedSearchHelperContext);

    if (!helpers) {
        throw new Error('No AdvancedSearchHelperContext found');
    }

    return helpers;
};
