import {
    ContentCreate,
    ContentData,
    ContentDetails,
    ContentList,
    ContentType,
    ContentUpdate,
    ContentUpdateResponse,
} from 'models/compositions/Content';
import {
    PIFUContent,
    PIFUContentCreate,
    PIFUContentData,
    PIFUContentUpdate,
} from 'models/compositions/PIFU';
import {
    makeDeleteRequest,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
} from 'services/api';
import { CompositionService } from 'services/composition.service';
import DataStore from 'services/data-store';

export class ContentEditorApiService<
    T extends ContentType,
    D extends ContentData,
    C extends ContentCreate<T, D>,
    U extends ContentUpdate<T, D>,
    P extends ContentDetails<T, D>,
> extends CompositionService {
    get archetypeName(): string {
        throw new Error('Method not implemented.');
    }
    getContentList = async (
        contentType: string,
        location: null | string = 'general_content',
    ): Promise<ContentList<T>> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        let url = `/teams/${role.teamId}/content/index?using_role_uuid=${role.uuid}&contentType=${contentType}`;
        if (location !== null) {
            url += `&location=${location}`;
        }

        const response: { status: number; message: ContentList<T>} = await makeGetRequest(url, apiVersion);

        if (response.status < 200 || response.status >= 300) {
            return Promise.reject('Error while loading content list');
        }

        return response.message;
    };
    postContent = async (contentFormData: C): Promise<ContentUpdateResponse> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        const response = await makePostRequest(
            `/teams/${role.teamId}/content?using_role_uuid=${role.uuid}`,
            contentFormData,
            apiVersion,
        );

        if (response.status < 200 || response.status >= 300) {
            return Promise.reject('Error while posting content!');
        }

        return response.message;
    };
    getContentById = async (id: string, location: string): Promise<P> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        const response = await makeGetRequest(
            `/teams/${role.teamId}/content?using_role_uuid=${role.uuid}&location=${location}&id=${id}`,
            apiVersion,
        );

        if (response.status < 200 || response.status >= 300) {
            return Promise.reject('Error while retrieving content!');
        }

        return response.message;
    };
    updateContentById = async (
        contentFormData: U,
    ): Promise<{ status: number; response: ContentUpdateResponse }> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;

        const response = await makePutRequest(
            `/teams/${role.teamId}/content?using_role_uuid=${role.uuid}`,
            contentFormData,
            apiVersion,
        );

        if (response.status < 200 || response.status >= 300) {
            return Promise.reject('Error while retrieving content!');
        }

        return response.message;
    };
    deleteContentById = async(id: string, location: string): Promise<any> => {
        const role = DataStore.get('me.currentRole');

        const response = await makeDeleteRequest(
            `/teams/${role.teamId}/content?using_role_uuid=${role.uuid}&location=${location}&id=${id}`,
        );

        if (response.status < 200 || response.status >= 300) {
            return Promise.reject('Error while retrieving content!');
        }

        return response.message;
    };
}

const pifuContentService = new ContentEditorApiService<
    'pifu',
    PIFUContentData,
    PIFUContentCreate,
    PIFUContentUpdate,
    PIFUContent
>();

export {
    pifuContentService,
};
