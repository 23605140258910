import serverFetch from '../server-fetch';
import { IHttp, IRequestOptions } from 'phr-api-client';

class CustomHttp implements IHttp {
    request(url: string, options: IRequestOptions = {method: 'GET'}): Promise<any> {
        return new Promise((resolve, reject) => {
            serverFetch.performRequest(
                Math.random(),
                url,
                options.body,
                options.method,
                (response: any) => resolve(Object.assign({}, response, {data: response})),
                reject,
                options.headers
            );
        });
    }
}

export const customHttp = new CustomHttp();