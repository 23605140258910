import React from 'react';
import lodash from 'lodash';

/**
 * Extract and display a person's name an object derived from HL7.
 * If the name object is not provided, is null/undefined, or has none of
 *  the expected properties, then the contents of the 'empty' prop is displayed.
 * By default, this will display: prefix given_name family_name
 * However, each part can be enabled/disabled.
 *
 * Props (data):
 * - name = Optional. Object. Specifies the parts of the name using some or all of the following string properties: prefix, given_name, middle_name, family_name, suffix
 * - empty = Optional. String. Text to show if no name is given, or the expected properties are not present. Defaults to an empty string.
 * - showPrefix = Optional. Boolean. Controls whether or not the name prefix is displayed. Defaults to true.
 * - showGivenName = Optional. Boolean. Controls whether or not the given name(s) is/are displayed. Defaults to true.
 * - showMiddleName = Optional. Boolean. Controls whether or not the middle name(s) is/are displayed. Defaults to false.
 * - showFamilyName = Optional. Boolean. Controls whether or not the family name is displayed. Defaults to true.
 * - showSuffix = Optional. Boolean. Controls whether or not the name suffix is displayed. Defaults to false.
 * - className = Optional. The CSS class of the span to wrap the output in. Defaults to 'hl7-name'.
 */
export const Name = ({name = null, empty = '', showPrefix = true, showGivenName = true, showMiddleName = false, showFamilyName = true, showSuffix = false, className = 'hl7-name'}) => {
    let output = '';
    
    if (name) {
        let parts = [
            {prop: 'prefix', show:showPrefix},
            {prop: 'given_name', show:showGivenName},
            {prop: 'middle_name', show:showMiddleName},
            {prop: 'family_name', show:showFamilyName},
            {prop: 'suffix', show:showSuffix}
        ];
        
        // Go through each part of the name.
        parts.forEach((part) => {
            // Skip parts which are not to be shown.
            if (!part.show) {
                return;
            }
            
            // Skip parts which are not specified.
            const partValue = lodash.get(name, part.prop, '');
            if (!partValue) {
                return;
            }
            
            // Add a space between parts.
            if (output) {
                output += ' ';
            }
            
            output += partValue;
        });
    }
    
    return (<span className={className}>{output || empty}</span>);
};

export default Name;
