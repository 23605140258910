/* eslint-disable @typescript-eslint/camelcase */
import { makeGetRequest, makePostRequest } from './api';
import { DataStore } from './data-store';
import { CompositionService } from './composition.service';
import { BulkOperationRequestParams, BulkOperationRequestAction } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import {
    BulkOperationDetailResponse,
    BulkOperationRequest
} from 'models/BulkOperationRequests';
import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';

const apiVersion = 2;

type BulkOperationRequestBody = {
    using_role_uuid: string;
    name: string;
    actions: BulkOperationRequestAction<TriggerEngineRequestContent>[];
    viewIdentifier: string;
    advancedSearchUuid: string;
    useAllInSearch: boolean;
    includedFolderIds?: number[];
    excludedFolderIds?: number[];
    filter?: { field: string; value: string }[];
}

class BulkOperationService extends CompositionService {

    get archetypeName(): string {
        return 'bulk-operation';
    }

    public async createBulkOperationRequest(requestParams: BulkOperationRequestParams): Promise<any>
    {
        const valid = requestParams.actions.map((action) => action.validateContent()).reduce((valid, currentValid) => valid && currentValid, true);
        if (!valid)
        {
            return Promise.reject('Invalid bulk operation request content');
        }

        const selectionState: FolderSelectionState = requestParams.selectionStateContainer.selectionState;
        const role = DataStore.get('me.currentRole');

        const data: BulkOperationRequestBody = {
            using_role_uuid: role.uuid,
            name: requestParams.boName,
            actions: requestParams.actions,
            viewIdentifier: requestParams.selectionStateContainer.viewIdentifier,
            advancedSearchUuid: requestParams.selectionStateContainer.advancedSearchUuid,
            useAllInSearch: selectionState.isSelectAll()
        };

        if (data.useAllInSearch) {
            const excludedFolderIds = selectionState.getExcludedFolderIds() as number[];
            if (excludedFolderIds.length > 0) {
                data.excludedFolderIds = excludedFolderIds;
            }
            if (requestParams.selectionStateContainer.filterState) {
                data.filter = Object.entries(requestParams.selectionStateContainer.filterState).map((item) => {
                    return {
                        field: item[0],
                        value: item[1]
                    };
                });
            }
        }
        else {
            data.includedFolderIds = selectionState.getIncludedFolderIds() as number[];
        }

        return makePostRequest(`/teams/${role.teamId}/user-folders/bulk-operation`, data, apiVersion);
    }

    public getBulkOperationRequests = ({
        onlyInProgressBulkOperations = false,
        limit = 10,
        offset = 0,
        boName = undefined,
        fromDate = undefined,
        toDate = undefined,
        clinicianId = undefined,
    }: {
        onlyInProgressBulkOperations: boolean;
        limit: number;
        offset:  number;
        boName?: string;
        fromDate?: string;
        toDate?: string;
        clinicianId?: number;
    }): Promise<{total: number; results: BulkOperationRequest[]}> => {
        const role = DataStore.get('me.currentRole');

        const basePath = `/teams/${role.teamId}/user-folders/bulk-operation`;
        const searchParams = new URLSearchParams();
        searchParams.set('using_role_uuid', role.uuid);
        searchParams.set('in_progress', onlyInProgressBulkOperations.toString());
        searchParams.set('extended', 'true');
        searchParams.set('limit', limit.toString());
        searchParams.set('offset', offset.toString());
        searchParams.set('sort', '-created_at');

        if(boName) {
            searchParams.set('name', boName);
        }

        if(fromDate && toDate) {
            searchParams.set('date_from', fromDate);
            searchParams.set('date_to', toDate);
        }

        if(typeof clinicianId === 'number') {
            searchParams.set('user', clinicianId.toString());
        }

        const url = basePath + '?' + searchParams.toString();

        return makeGetRequest(url, apiVersion)
            .then((response) => {
                return {
                    total: response.message.total,
                    results: response.message.results
                };
            });
    };

    public getBulkOperationDetailByUuid = (
        bulkOperationUuid: string,
        limit: number,
        offset: number,
        statuses?: string[],
        ppi?: string
    ): Promise<BulkOperationDetailResponse> => {
        const role = DataStore.get('me.currentRole');
        const basePath = `/teams/${role.teamId}/user-folders/bulk-operation/${bulkOperationUuid}`;

        const searchParams = new URLSearchParams();
        searchParams.set('using_role_uuid', role.uuid);
        searchParams.set('include_action_details', 'true');
        searchParams.set('limit', limit.toString());
        searchParams.set('offset', offset.toString());

        if (ppi && ppi.replace(/\s/g,'')) {
            searchParams.set('ppi', ppi.replace(/\s/g,''));
        }

        let requestUrl = basePath + '?' + searchParams.toString();
        if (statuses && statuses.length) {
            statuses.forEach(status => {
                requestUrl = `${requestUrl}&statuses[]=${status}`;
            });
        }

        return makeGetRequest(requestUrl, apiVersion)
            .then((response) => {
                return {
                    bulkOperationRequest: response.message.bulkOperationRequest,
                    actionDetails: response.message.actionDetails,
                    currentActionCount: response.message.currentActionCount
                };
            });
    }
}

export const bulkOperationService = new BulkOperationService();
