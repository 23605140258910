import React from 'react';
import { ReferralsGridWithCategories } from './ReferralGrid';
const EMPTY_ARRAY = [];

const categoryGroups = [
    {name: '3', displayName: 'GP Triage Referrals'},
    {name: 'MSKTAS', displayName: 'Follow-on triage referrals'},
    {name: 'default', displayName: 'Direct Clinic Referrals'},
]

export const ReferralsIndex = ({
    loading,
    referrals = EMPTY_ARRAY,
    getTriageDecisionForList,
    getSendLetterButton,
    getDischargeButton,
}) => {
    return (
        <div className="page-referrals-list">
            <ReferralsGridWithCategories
                loading={loading}
                referrals={referrals}
                getTriageDecisionForList={getTriageDecisionForList}
                getSendLetterButton={getSendLetterButton}
                getDischargeButton={getDischargeButton}
                categoryGroups={categoryGroups}
            ></ReferralsGridWithCategories>
        </div>
    );
}