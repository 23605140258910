/* eslint-disable @typescript-eslint/camelcase */

import { IFolderSelectorParam, IRole } from 'phr-api-client/index';
import { syncService } from './sync.service';
import { DataStore } from 'services/data-store';
import { CompositionService, compositionService } from './composition.service';
import { terService } from './ter.service';
import { map as _map } from 'lodash';
import { post, get, makePostRequest  } from 'services/api';
import { SimpleQuestionnaireItem } from 'models/compositions/SimpleQuestionnaire';
import { PreviewSimpleQuestionnaireApiModel } from 'features/content-editor/model/PreviewSimpleQuestionnaireModel';
type ITemplateArchetype = 'questionnaire-template' | 'questionnaire';

class QuestionnaireService extends CompositionService {

    get DEFAULT_ANSWER_VALUE() {return '99999';}

    get archetypeName(): string {
        throw('Invalid questionnaireService instance use');
    }

    get questionnaireArchetypeNames(): Array<string> {
        return ['questionnaire', 'coopQuestionnaire'];
    }

    get responsesArchetypeNames(): Array<string> {
        return ['questionnaireResponse', 'coopQuestionnaireResponse'];
    }

    get templatesArchetypeNames(): Array<string> {
        return ['questionnaire-template', 'questionnaire'];
    }

    getResponses({role, folderId, limit = 100, offset = 0}: { limit?: number; offset?: number; role?: IRole; folderId?: IFolderSelectorParam }): Promise<any> {
        const archetypeNames = this.responsesArchetypeNames.join(',');
        return syncService.fullSync({role, folderId, archetypeNames, limit, offset});
    }

    getScores({role, folderId, limit = 100, offset = 0}: { limit?: number; offset?: number; role?: IRole; folderId?: IFolderSelectorParam }): Promise<Array<any>> {
        return this.list({role, folderId, archetypeName: 'questionnaireScores', offset, limit})
            .then(r => r.message.results);
    }

    getQuestionnaires({role, folderId, limit = 100, offset = 0}: { limit?: number; offset?: number; role?: IRole; folderId?: IFolderSelectorParam }): Promise<Array<any>> {
        const archetypeNames = this.questionnaireArchetypeNames.join(',');
        return syncService.fullSync({role, folderId, archetypeNames, limit, offset });
    }

    getTemplates({role, folderId = 'team', limit = 100, offset = 0}: { limit?: number; offset?: number; role?: IRole; folderId?: IFolderSelectorParam }): Promise<Array<any>> {
        const archetypeNames = this.templatesArchetypeNames.join(',');
        return syncService.fullSync({role, folderId, archetypeNames, limit, offset });
    }

    getCoopQuestionnaires(page = 0, perPage = 0, portalType = 'admin_portal'): Promise<any> {
        const teamId = (DataStore.get('me.currentRole') || {}).teamId || 0;

        return get(`/${portalType}/questionnaire-templates?team_id=${teamId}&page=${page}&per_page=${perPage}`).then((resp) => {
            return resp.message.results;
        });
    }

    createTemplate({ role, folderId = 'team', archetypeName, content }: { role?: IRole; folderId?: IFolderSelectorParam; archetypeName: ITemplateArchetype; content: any }): Promise<any> {
        return compositionService.create({ role, folderId, archetypeName, content });
    }

    createCoopQuestionnaire(jsonString): Promise<any> {
        const teamId = (DataStore.get('me.currentRole') || {}).teamId || 0;

        return post(`admin_portal/questionnaire-template?team_id=${teamId}`, jsonString);
    }

    updateTemplate({uuid, role, folderId = 'team', archetypeName, content }: { uuid: string; role?: IRole; folderId?: IFolderSelectorParam; archetypeName: ITemplateArchetype; content: any }): Promise<any> {
        return compositionService.update({ uuid, role, folderId, archetypeName, content });
    }

    getTemplateByUuid({uuid, role, folderId = 'team', archetypeName }: { uuid: string; role?: IRole; folderId?: IFolderSelectorParam; archetypeName: ITemplateArchetype }): Promise<any> {
        return compositionService.getByUuid({ uuid, role, folderId, archetypeName });
    }

    getCoopQuestionnaireById(id): Promise<any> {
        const teamId = (DataStore.get('me.currentRole') || {}).teamId || 0;
        
        return get(`/admin_portal/questionnaire-template/${id}?team_id=${teamId}`);
    }

    allocate({questionnaireDocumentType, questionnaireName, role, folderId, data = {}}: { questionnaireDocumentType: string; questionnaireName: string; role: IRole; folderId: IFolderSelectorParam; data: { due_date?: number; name_of_issuer?: string; name_of_team?: string } }): Promise<any> {

        let action: any;

        if (questionnaireDocumentType === 'coopQuestionnaire') {
            action = 'assignCoopQuestionnaire';
        } else {
            action = 'assignQuestionnaire';
        }

        Object.assign(data, {
            // eslint-disable-next-line camelcase
            questionnaire_name: questionnaireName 
        });

        return terService.createTer({ role, folderId, action, data });
    }

    async loadQuestionnaireData(params) {

        const { role, archetype, id: responseUuid, folderId } = params;
        const [scoring, scores, response] = await Promise.all([
            this.getQuestionnaireScoring({ uuid: responseUuid, folderId }),
            this.getQuestionnaireScores({ uuid: responseUuid, folderId }),
            this.getQuestionnaireResponse({ archetype, uuid: responseUuid, folderId })
        ]);
        const questionnaireUuid = response.content.questionnaire_uuid;
        const questionnaire = await this.getQuestionnaire({
            uuid: questionnaireUuid,
            archetype,
            folderId
        });

        const data = this.arrangeQuestionnaireData({questionnaire, response, scores, scoring, role, archetype});

        return data;
    }

    arrangeQuestionnaireData({questionnaire, response, scores, scoring, role, archetype}) {
        const scoringData = this.calculateQuestionnaireScoring({
            questionnaireResponse: response,
            score: scoring,
            archetype});

        const {
            content,
            updated_at: updatedAt,
            created_at: createdAt,
            uuid
        } = response;

        const isHidden = (role !== 'admin') && content.hide_from_clinician;

        let data = {
            useQuestionNumber: questionnaire.content.details.use_question_number ?? false,
            questionnaireResponse: response,
            name: questionnaire.content.name,
            nameOfIssuer: content.name_of_issuer,
            createdAt,
            createdUser: content.created_user,
            dueBy: content.due_date,
            status: content.status,
            lastUpdated: updatedAt,
            uuid,
            isHidden,
            canBeCompletedByClinician: response.content.status.toLowerCase() === 'assigned' && !isHidden,
            questionnaireScores : scores,
            viewedQuestions: content.details.viewed_questions,
            labels: content.labels,
            sections: this.getQuestionnaireSections({questionnaire, archetype}),
            answers: scoringData.answers
        };

        if (archetype === 'coopQuestionnaire') {
            data = Object.assign({}, data, {
                scoring: scoringData.score.scoring.scoring,
                scores: scoringData.scores,
                questionScores: scoringData.questionScores,
                questionGroups: scoringData.questionGroups
            });
        }

        return data;

    }

    async getQuestionnaire({uuid, archetype, folderId}) {
        const role = DataStore.get('me.currentRole');
        const archetypeName = archetype === 'coopQuestionnaire' ? 'coopQuestionnaire' : 'questionnaire';

        if (archetype === 'coopQuestionnaire') {
            return await this.list({archetypeName: archetypeName, folderId}).then((response) => {
                return response.message.results.find(msg => msg.content.questionnaire_uuid === uuid);
            });
        }

        const response = await compositionService.getByUuid({uuid, role, archetypeName, folderId});
        return response.message;
    }

    async getQuestionnaireResponse({archetype, uuid, folderId}) {
        const role = DataStore.get('me.currentRole');
        const archetypeName = archetype === 'coopQuestionnaire' ? 'coopQuestionnaireResponse' : 'questionnaireResponse';
        const response = await compositionService.getByUuid({uuid, role, folderId, archetypeName});
        return response.message;
    }

    getQuestionnaireScoring({uuid, folderId}) {
        const role = DataStore.get('me.currentRole');
        const search = {
            'content.questionnaire_response_uuid': uuid,
        };
        return questionnaireService.search({archetypeName: 'questionnaireScoring', search, role, folderId})
            .then((response) => {
                return response.message.results[0]?.content;
            });
    }

    getQuestionnaireScores({uuid, folderId}) {
        const role = DataStore.get('me.currentRole');
        const search = {
            'content.questionnaire_response_uuid': uuid,
        };
        return questionnaireService.search({archetypeName: 'questionnaireScores', search, role, folderId})
            .then((response) => {
                return response.message.results[0]?.content?.scores;
            });
    }

    calculateQuestionnaireScoring({questionnaireResponse, score, archetype}) {
        const answers = {};

        if (questionnaireResponse.content.details) {
            if (archetype === 'coopQuestionnaire') {
                questionnaireResponse.content.details.answers.forEach((item) => {
                    answers[item.question_id] = item.value;
                });
            } else {
                questionnaireResponse.content.details.answers.forEach(({question_id, inputs}) => {
                    answers[question_id] = _map(inputs, ({id, value}) => {
                        return {
                            id,
                            value: value == '99999' ? undefined : value
                        };
                    });
                });
            }
        }

        const questionScores = {};
        const questionGroups = {};
        if (score) {
            try {
                if (typeof score.scoring === 'string') {
                    score.scoring = JSON.parse(score.scoring);
                }
            } catch (e) {
                score.scoring = {
                    scoring: [],
                    questions: []
                };
            }
            score.scoring.questions.map((item) => {
                questionScores[item.question_id] = item;
                questionGroups[item.question_id] = item.groups;
            });
        } else {
            /*
                Dirty hack to handle EQ5D questionnaires
                Trigger engine doesn't handle it so we have to process it manually
                until it's moved to the new archetype
                There is only one coopQuestionnaire - EQ5D, there won't be new ones
                with this archetype
             */
            if (archetype === 'coopQuestionnaire') {
                score = {
                    scoring: {
                        questions: _map(answers, (value, questionNumber) => {
                            const score = parseInt(value, 10);
                            return {
                                question_id: questionNumber,
                                score: parseInt(questionNumber, 10) < 6 ? score + 1 : score,
                                groups: []
                            };
                        }),
                        scoring: [],
                        partial: questionnaireResponse.content.status !== 'complete',
                        total: null
                    }
                };
                score.scoring.questions.forEach((item) => {
                    questionScores[item.question_id] = item;
                    questionGroups[item.question_id] = item.groups;
                });
            } else {
                score = {
                    scoring: {
                        scoring: [],
                        partial: null,
                        total: null
                    }
                };
            }
        }

        const scores: any = {
            partial: score.scoring.partial
        };
        //eslint-disable-next-line no-prototype-builtins
        if (score.scoring.hasOwnProperty('total')) {
            scores.total = score.scoring.total;
        }

        return {
            scores,
            score,
            answers,
            questionScores,
            questionGroups
        };
    }

    getQuestionnaireSections({questionnaire, archetype}) {
        if (archetype === 'coopQuestionnaire') {
            return [{
                preamble: questionnaire.content.details.preamble,
                questions: questionnaire.content.details.questions
            }];
        } else {
            return questionnaire.content.details.sections;
        }
    }

    async questionnaireCouldBeUpdated({archetype, uuid, folderId, teamId}) {
        const questionnaireResponse = await this.getQuestionnaireResponse({archetype, uuid, folderId});
        return questionnaireResponse && questionnaireResponse.content && questionnaireResponse.content.status
            && questionnaireResponse.content.status.toLowerCase() === 'assigned';
    }

    updateQuestionnaireResponse({archetype, folderId, teamId, response, uuid}) {
        const role = DataStore.get('me.currentRole');
        const archetypeName = archetype === 'coopQuestionnaire'
            ? 'coopQuestionnaireResponse'
            : 'questionnaireResponse';
        compositionService.update({uuid, role, folderId, archetypeName, content: response});
    }

    public previewSimpleQuestionnaire = (previewRequestBody: PreviewSimpleQuestionnaireApiModel) => {
        const role = DataStore.get('me.currentRole');

        return makePostRequest(`/teams/${role.teamId}/content/previewSimpleQuestionnaire?using_role_uuid=${role.uuid}`, previewRequestBody);
    }
}

export const questionnaireService = new QuestionnaireService();
export default questionnaireService;
