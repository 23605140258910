import { Label } from '../Label';
import { ClinicalCode } from './ClinicalCode';
// https://bitbucket.org/adihealth/private-server/src/master/resources/schemas/archetypes/referral.json
export enum REFERRAL_STATUS {
    OPEN = 'open',
    CLOSED = 'closed',
    REJECTED = 'rejected',
    CANCELLED = 'cancelled',
    DISCHARGED = 'discharged',
}
/**
 * Provides information about a patient referral to or between services.
 */
export interface Referral {
    /**
     * Provides some additional information which will be displayed as part of the referral in
     * the app.
     */
    additional_details?: string;
    /**
     * An array of preadmit numbers of appointments which related to this referral. If there are
     * no entries, the whole property must be omitted.
     */
    appointment_preadmit_numbers?: string[];
    /**
     * UBRNs of referrals associated with current triage decision referral.
     */
    associated_ubrns?: string[];
    /**
     * A password the patient can use to book the related appointment if applicable, e.g. via
     * eRS.
     */
    booking_password?: string;
    /**
     * TBC
     */
    category?:       string;
    clinical_codes?: ClinicalCode[];
    /**
     * Provides detail which will be displayed as part of the referral in the app.
     */
    details?: string;
    /**
     * TBC
     */
    disposition?: string;
    /**
     * Specifies the dates/times of the events associated with this referral, and whether each
     * is open or closed. There must be at least one item for a valid referral.
     */
    effective_dates: EffectiveDate[];
    /**
     * Identifies the episode this referral relates to. This is used to match existing
     * compositions against incoming HL7 data.
     */
    episode?: string;
    /**
     * If true, this referral should be hidden from the timeline in the app. If this property is
     * omitted, assume it is false.
     */
    hidden_from_timeline?: boolean;
    labels?:              Label[];
    /**
     * One or more other identifiers (besides episode, UBRN, and PPI) used to refer to this
     * referral by external systems.
     */
    other_external_identifiers?: Identifier[];
    /**
     * Another unique identifier for this referral. Often the same as UBRN, but sometimes will
     * be different.
     */
    patient_pathway_identifier?: string;
    /**
     * Indicates the priority of the referral. Valid values TBC.
     */
    priority?: string;
    /**
     * Unix timestamp. Meaning TBC.
     */
    process_date?: number;
    /**
     * One or more unique identifiers for the referral. This is used to match HL7 messages
     * against our existing records. A single referral composition can end up with multiple
     * referral IDs during updates.
     */
    referral_ids: string[];
    /**
     * A code identifying the reason for the referral, e.g. 'OTPAT'. Meaning TBC. A
     * human-readable version will be probably be added from a look-up table at some stage.
     */
    referral_reason?: string;
    /**
     * Unix timestamp for referral receipt
     */
    referral_receipt_date?: number;
    /**
     * Identifies the service the referral came from.
     */
    referred_to_service?: Service;
    /**
     * Identifies the service the referral came from.
     */
    referring_service?: Service;
    /**
     * Specifies the UUIDs of zero or more resources associated with this referral. The user
     * should be able to access the resources via the referral. The resource compositions may be
     * in the patient's own folder, or they may be in a team/organisation folder.
     */
    resource_uuids?: string[];
    /**
     * Indicates what state the referral is in.
     */
    status: REFERRAL_STATUS;
    /**
     * Useful descriptive title
     */
    title?: string;
    /**
     * A code designating the outcome of this referral, if it was a triage.
     */
    triage_decision?: string;
    /**
     * An array of strings identifying the type of referral. The meaning of the values is TBC.
     */
    type?: string[];
    /**
     * Unique Booking Reference Number for this referral. This is used to match existing
     * compositions against incoming HL7 data.
     */
    ubrn?: string;
}

export interface EffectiveDate {
    /**
     * Indicates if this referral event is open (true) or closed (false).
     */
    open: boolean;
    /**
     * Unix timestamp specifying the date/time of this referral event.
     */
    timestamp: number;
}

/**
 * One or more other identifiers (besides episode, UBRN, and PPI) used to refer to this
 * referral by external systems.
 */
export interface Identifier {
    /**
     * A unique identifier. The format depends on the type of identifier.
     */
    id: string;
    /**
     * Identifies the type or namespace of the identifier, e.g. 'GMC' or 'SDSID'.
     */
    type: string;
}

/**
 * Identifies the service the referral came from.
 */
export interface Service {
    /**
     * The name of the referring/referred clinician or service. Required if name is not
     * specified.
     */
    identifiers?: Identifier[];
    /**
     * The name of the referring/referred clinician or service. Required if identifiers is not
     * specified.
     */
    name?: Name;
}

/**
 * The name of the referring/referred clinician or service. Required if identifiers is not
 * specified.
 */
export interface Name {
    /**
     * The clinician's last name, aka surname or family name. This should be an actual name and
     * not an initial. Alternatively, this could give the name of the referring/referred service.
     */
    family_name?: string;
    /**
     * The clinician's first name or initial. If it is an initial, there should not be a period.
     * E.g. 'F' is acceptable but 'F.' is not.
     */
    given_name?: string;
    /**
     * The clinician's middle name(s) or initial(s), if known. For intials, there should be no
     * following period.
     */
    middle_name?: string;
    /**
     * The clinician's prefix(es) or title(s) if known, such as Mr, Dr, Rev, etc.
     */
    prefix?: string;
    /**
     * The clinician's name suffix(es) or post nominal letters if known, such as OBE or Jr.
     */
    suffix?: string;
}
