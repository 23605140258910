import React from 'react';
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux';
import AppRoutes from './AppRoutes.js';


const ApplicationRoutes = ({store}) => {
    return (
        <Provider store={store}>
            <Router history={browserHistory}>
                {AppRoutes}
            </Router>
        </Provider>
    );
};

export default ApplicationRoutes;