/** @module controllers/clinical_portal/messages */

import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import View from './view';
import { validate, normalise } from './validator';
import { messagesService } from 'services/messages.service';
import { NOTIFICATION_TYPE, pushNotificationService } from 'services/push-notifications.service';

export default class UserMessageCreateComponent extends BaseComponent {
    /**
     * Setup the component
     * @param props Properties to pass into component
     */
    constructor (props) {
        super(props);

        this.state = {
            form: {
                title: '',
                content: '',
                sendPush: false,
            },
            errors: [],
            backRoute:this.props.backRoute || `${this.getFolderPath()}/messages`,
            sendAsEmail: 0,
            messageType: this.props.messageType || 'clinician',
            sendingMessage: false
        };

        this.bindEvents([
            'Submit',
            'Change'
        ]);

        this.bindFunctions([
            'onMessageSent'
        ]);

        this.View = View;
    }

    componentWillReceiveProps(newProps) {
        if (newProps.messageType !== this.state.messageType) {
            this.setState({
                messageType: newProps.messageType
            });
        }
    }

    /**
     * Run any Server Calls
     */
    componentWillMount () {
        this.server.renderFromServerState();
    }

    /**
     * Update the state for the given user input
     */
    handleChange(changedFormState)
    {
        this.setState({ form: {...this.state.form, ...changedFormState} });
    }

    /**
     * Validate the form and submit the message via the API
     * @param {Object} e - The form submit event
     */
    handleSubmit (e)
    {
        e.preventDefault();

        if (validate(this.state.form, (errors) => this.setState({errors: errors})))
        {
            const normalisedForm = normalise(this.state.form);

            this.setState({
                loading: true
            });

            const terContent = {
                title: normalisedForm.title,
                content: normalisedForm.content,
                sender_name: 'clinician',
                type: this.state.messageType || 'clinician'
            };

            if (this.state.messageType === 'welcome') {
                delete(terContent.sender_name);
            }

            if (this.props.onSubmit) {
                this.props.onSubmit(terContent, () => {
                    this.setState({
                        errors: [],
                        sendingMessage: false,
                        loading: false,
                        title: '',
                        content: '',
                        sendAsEmail: 0,
                        messageType: this.props.messageType || 'clinician'
                    });
                });
            } else {
                messagesService.create({ content: terContent })
                    .then(() =>{
                        if (normalisedForm.sendPush) {
                            return pushNotificationService.send({ notification: NOTIFICATION_TYPE.MESSAGE });
                        }
                    })
                    .then(this.onMessageSent);
            }
        }
    }

    onMessageSent () {
        browserHistory.push(this.state.backRoute);
    }
}

UserMessageCreateComponent.propTypes = {
    backRoute: PropTypes.string
};
