import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import LoginComponent from 'components/login';
import DataStore from 'services/data-store';
import faviconService from 'services/favicon.service.js';

/**
 * Extended Page Component for admin_portal/login
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {};

        switch(this.props.location.query.status) {
            case 'not-a-clinical-admin': {
                this.state = {
                    message: 'Only clinical admins can log in using this portal.'
                };
                break;
            }
        }
    }

    pageTitle () {
        return _`Admin Portal Login | iFocus Healthcare System`;
    }

    componentWillMount () {
        faviconService.setDefaultIcon();
        DataStore.clear(() => {});
    }

    render () {
        return (<LoginComponent title="Admin Portal Login" portal="admin_portal" {...this.state} {...this.props} />)
    }
}

