import React, { useState, Fragment, useRef, useEffect } from 'react';
import _ from 'services/i18n';
import { useForm } from 'common/form/useForm';
import { ModalDialog } from 'common/modalDialog';
import { nhsValidator, clearNHSNumber, formatNHSNumber } from 'common/form/nhsNumber';
import { terService } from 'services/ter.service';
import { phoneValidator } from 'common/form/phone';
import { emailValidator } from 'common/form/email';
import { useTeamRoles, TEAM_ROLES_GROUPS } from 'common/useTeamRoles';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { extractPrimaryIdentifierNamespaceAndLabel } from 'services/team-preferences.service';
import { apiV2Service } from 'services/api-v2.service';
import { DataStore } from 'services/data-store';
import moment from 'moment-timezone';
import DateInput from 'components/form/date-input';
import { standardDateTimeFormats } from 'common/datetime/convertToDate';

const createPatientState = {
    givenName: { value: '', error: '' },
    middleName: { value: '', error: '' },
    familyName: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
    primaryIdentifier: { value: '', error: '' },
    dateOfBirth: { value: '', error: '' },
    gender: { value: '', error: '' },
    label: { value: undefined, error: ''},
    roles: { value: [], error: ''},
};

const createPatientSchema = {
    givenName: {
        required: true,
    },
    middleName: {
    },
    familyName: {
        required: true,
    },
    email: {
        rules: [
            (value) => {
                if (!value) { return false; }
                return emailValidator(value.trim()) ? false : _`Wrong email`;
            }
        ]
    },
    phone: {
        rules: [
            (value) => {
                if (!value) { return false; }
                return phoneValidator(value.trim()) ? false : _`Wrong mobile number format`;
            }
        ]
    },
    primaryIdentifier: {
        required: true,
    },
    dateOfBirth: {
        required: true,
    },
    gender: {
        required: true,
    },
    roles: {
        isMultiselect: true,
        required: true
    },
};

const nhsFieldRules = {
    rules: [
        (value) => {
            const clearedValue = clearNHSNumber(value);
            return (clearedValue.length === 10) ? false : _`Wrong NHS Number`;
        },
        (value) => {
            const clearedValue = clearNHSNumber(value);
            return nhsValidator(clearedValue) ? false : _`Wrong NHS Number`;
        }
    ],
    format: (value) => formatNHSNumber(value)
};

export const CreatePatientModalDialog = ({
    onClose,
}) => {
    const [formError, setFormError] = useState('');
    const [isLoadingMessage, setisLoadingMessage] = useState(false);
    const [, teamRolesGroups] = useTeamRoles();
    let { primary_identifier: primaryIdentifier } = useTeamPreferences();
    let {namespace: primaryIdentifierNamespace, label: primaryIdentifierLabel} = extractPrimaryIdentifierNamespaceAndLabel(primaryIdentifier);

    const { portal: { create_patient_form_email_mandatory: isEmailMandatory } } = useTeamPreferences();

    if (isEmailMandatory) {
        createPatientSchema.email.required = true;
    }

    if (primaryIdentifierNamespace.toLowerCase() === 'nhs_number') {
        createPatientSchema.primaryIdentifier = {
            required: true,
            ...nhsFieldRules
        };
    } else {
        createPatientSchema.primaryIdentifier = {
            required: true,
        };
    }

    async function checkPatientsIdentifier(patientIdentifierValue) {
        try {
            const teamId = (DataStore.get("me.currentRole") || {}).teamId || 0,
            roleUuid = (DataStore.get("me.currentRole") || {}).uuid || 0;

            const searchParam = {
                status: 'registered' | 'invited' | 'pending',
                teamId: teamId,
                searchString: patientIdentifierValue,
                roleUuid: roleUuid,
                limit: 100,
                offset: 0
            };

            let isEmail = undefined, isIdentifier = undefined;
            setisLoadingMessage(true);
            setFormError(null);
            const users = await apiV2Service.searchUserFolders(searchParam);
            if ((users.message.results.length !== 0) && (emailValidator(patientIdentifierValue.trim()))) {
                isEmail = (users.message.results[0].email.toLowerCase().trim() === patientIdentifierValue.toLowerCase().trim());
            }

            if ((users.message.results.length !== 0) && !(emailValidator(patientIdentifierValue.trim()))) {
                isIdentifier = (users.message.results[0].person_primary_identifier.value.trim() === patientIdentifierValue.trim());
            }
            setisLoadingMessage(false);

            return {
                isEmail,
                isIdentifier,
            }

        } catch (err) {
            setFormError(_`Failed to check entered e-mail and identifier.`);
            console.error(err);
        }
    }

    const onSubmitForm = async (state) => {
        const name = {
            given_name: state.givenName.value,
            middle_name: state.middleName.value,
            family_name: state.familyName.value,
        };

        if (!name.middle_name) {
            delete name.middle_name;
        }

        const primaryIdentifierValue = state.primaryIdentifier.value.replace(/\s/g, '');

        const patient = {
            name,
            email: state.email.value,
            phone: state.phone.value,
            dob: state.dateOfBirth.value,
            label: state.label.value ? {
                context: state.label.context,
                name: state.label.value,
                type: state.label.type,
            } : null,
            gender: state.gender.value,
            role_uuids: state.roles.value,
            person_primary_identifier: {
                namespace: primaryIdentifierNamespace,
                value: primaryIdentifierValue,
            },
        };

        let existedIdentifierValue = undefined;

        if (patient.email.length) {
            existedIdentifierValue = await checkPatientsIdentifier(patient.email);
            if (existedIdentifierValue.isEmail) {
                return setFormError('This e-mail already exists');
            }
        }

        if (!patient.email && !patient.phone) {
            return setFormError('Email or mobile number is required');
        }

        existedIdentifierValue = await checkPatientsIdentifier(patient.person_primary_identifier.value);
        setisLoadingMessage(false);

        if (existedIdentifierValue.isIdentifier) {
            return setFormError('This NHS number already exists');
        }

        if (!patient.email) {
            delete patient.email;
        }

        if (!patient.phone) {
            delete patient.phone;
        }

        try {
            await terService.createTer({action: 'createUser', data: patient, folderId: 'team'});
            onClose();
        } catch (err) {
            setFormError(_`Failed to create patient.`);
            console.error(err);
        }
    };

    const { state, handleOnChange, handleOnBlur, handleOnSubmit, disable } = useForm(
        createPatientState,
        createPatientSchema,
        onSubmitForm,
    );

    useEffect(() => {
        const roles = teamRolesGroups.get(TEAM_ROLES_GROUPS.PATIENT);
        if (roles && roles.length === 1) {
            handleOnChange({target: {name: 'roles', value: [roles[0].uuid]}});
        }
    }, [teamRolesGroups]);
    const buttons = <Fragment>
        <label
            className={`btn btn-default ${disable ? 'btn-disabled' : 'btn-primary '}`}
            htmlFor="createPatientFormSubmit"
        >Create</label>
        <button
            className="btn btn-default"
            onClick={onClose}
        >Cancel</button>
    </Fragment>;

    const roles = teamRolesGroups.get(TEAM_ROLES_GROUPS.PATIENT);
    const rolesOptions = roles ? roles.map(role => ({ name: role.name, value: role.uuid })) : [];

    const teamPrefs = useTeamPreferences();
    const labelsTeamPref = teamPrefs.portal.create_patient_labels;
    const isLabelsPrefSet = !!labelsTeamPref;

    if (labelsTeamPref) {
        const filterLabelsByName = labelsTeamPref.filter(function (labelObj) {
            return labelObj.name === state.label.value;
        });
        if (filterLabelsByName.length > 0) {
            state.label.context = filterLabelsByName[0].context;
            state.label.type = filterLabelsByName[0].type;
        }
    }
    return <ModalDialog
        title={_`Create Patient`}
        onClose={onClose}
        buttons={buttons}
    >
        <form name="createPatientForm"
            onSubmit={handleOnSubmit}
        >
            {formError && <div className="alert alert-danger">{formError}</div>}
            {!formError && (isLoadingMessage && <h4>Loading. Please wait...</h4>)}
            <InputField
                field="givenName" fieldName={_`Given Name`} isFocus={true}
                state={state} schema={createPatientSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <InputField
                field="middleName" fieldName={_`Middle Name`}
                state={state} schema={createPatientSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <InputField
                field="familyName" fieldName={_`Family Name`}
                state={state} schema={createPatientSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <div className={'form-group form-required ' + (state.gender.error ? 'has-error' : '')}>
                <label htmlFor="gender">{_`Gender`}</label>
                <select className="form-control"
                        name="gender"
                        value={state.gender.value}
                        onChange={handleOnChange}
                >
                    <option value="">{_`Select gender`}</option>
                    <option value="male">{_`Male`}</option>
                    <option value="female">{_`Female`}</option>
                    <option value="other">{_`Other`}</option>
                </select>
                {state.gender.error && <div className="form-errors">
                    <div className="form-error">{state.gender.error}</div>
                </div>}
            </div>
            <div className={'form-group form-required ' + (state.dateOfBirth.error ? 'has-error' : '')}>
                <label htmlFor="dateOfBirth">{_`Date of birth`}</label>
                <DateInput
                    name="dateOfBirth"
                    value={state.dateOfBirth.value}
                    maxDate={moment().format(standardDateTimeFormats.date_input)}
                    onChange={handleOnChange}
                />
                {state.dateOfBirth.error && <div className="form-errors">
                    <div className="form-error">{state.dateOfBirth.error}</div>
                </div>}
            </div>
            <InputField
                field="primaryIdentifier" fieldName={primaryIdentifierLabel}
                state={state} schema={createPatientSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <InputField
                field="email" fieldName={_`Email`}
                state={state} schema={createPatientSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            <InputField
                field="phone" fieldName={_`Mobile Number`}
                state={state} schema={createPatientSchema} onChange={handleOnChange} onBlur={handleOnBlur}
            ></InputField>
            {roles && roles.length > 1 &&
            <InputMultiSelectDropdown
                field="roles" fieldName={_`Roles`} options={rolesOptions}
                state={state} schema={createPatientSchema} onChange={handleOnChange}
            ></InputMultiSelectDropdown>
            }
            <div
                className="form-submit-btn-container"
            >
                <input type="submit"
                    id="createPatientFormSubmit"
                    className="hidden"
                />
            </div>
            {isLabelsPrefSet && (
                <div className={'form-group form-required ' + (state.label.error ? 'has-error' : '')}>
                    <label htmlFor="label">{_`Label`}</label>
                    <select className="form-control"
                        name="label"
                        value={state.label.value}
                        onChange={handleOnChange}
                    >
                        <option value="">{_`Select patient label`}</option>
                        {teamPrefs.portal.create_patient_labels.map((option, key) => {
                            return (<option key={key} value={option.value}>{option.name}</option>);
                        }
                        )}
                    </select>
                    {state.label.error && <div className="form-errors">
                        <div className="form-error">{state.label.error}</div>
                    </div>}
                </div>)}
        </form>
    </ModalDialog>;
};

const InputField = ({state, field, fieldName, onChange, onBlur, schema, isFocus}) => {
    const { required } = schema[field];

    const ref1 = useRef(null);

    useEffect(() => {
        if (isFocus) {
            ref1.current.focus();
        }
    }, [isFocus]);

    return <div className={`form-group ${required ? 'form-required' : ''} ${state[field].error ? 'has-error' : ''}`}>
        <label htmlFor={field}>{fieldName}</label>
        <input type="text" className="form-control"
            ref={ref1}
            name={field}
            value={state[field].value}
            placeholder={fieldName}
            onChange={onChange}
            onBlur={onBlur}
        />
        {state[field].error && <div className="form-errors">
            <div className="form-error">{state[field].error}</div>
        </div>}
    </div>;
};

const InputMultiSelectDropdown = ({options, onChange, state, schema, field, fieldName}) => {
    const { required } = schema[field];

    const notSelectedOptions = options.filter(option => state[field].value.indexOf(option.value) === -1);

    const optionsSelect = <Fragment>
        <option value="">{_`Select to add`}</option>
        {notSelectedOptions.map(
            option => <option key={option.value} value={option.value}>{option.name}</option>
        )}
    </Fragment>;

    const handleChange = (event) => {
        let value = state[field].value;
        if (value && value.indexOf(event.target.value) === -1) {
            value = [...value, event.target.value];
        }
        onChange({target: {name: field, value}});
    };

    const handleRemoveClick = (event, value) => {
        event.preventDefault();

        let newValue = state[field].value;
        newValue = newValue.filter(v => v !== value);
        onChange({target: {name: field, value: newValue}});
    };

    const selected = state[field].value;
    const selectedBlock = selected ?
        selected
            .map(value => options.find(option => option.value === value))
            .map(option => <span key={option.value}>{option.name}
                    <a className="glyphicon glyphicon-remove"
                        onClick={(event) => handleRemoveClick(event, option.value)}></a>
                </span>
            )
        : null;

    return <div className={`form-group ${required ? 'form-required' : ''} ${state[field].error ? 'has-error' : ''}`}>
        <label htmlFor="type">{fieldName}</label>
        <div>{selectedBlock}</div>
        {!!notSelectedOptions.length && <select className="form-control"
            name={field}
            value=""
            onChange={handleChange}
        >
            {optionsSelect}
        </select>}
        {state[field].error && <div className="form-errors">
            <div className="form-error">{state[field].error}</div>
        </div>}
    </div>;
};
