import React from 'react';
import BasePage from 'components/page/base';

import {ResponsiveTable, Row, Column} from 'ui';
import _ from 'services/i18n';
import HeadingDoc from 'ui/heading-doc';
import Events from './_events.js';
import FormPanel from 'components/form-panel';
import DateInputField from 'components/form-panel/date-inputs/date-input-field';
import { SecondsToText } from 'common/datetime/SecondsToText.js';

export default class extends BasePage {
    constructor (props) {
        super(props);
        let from = new Date();

        from.setMonth(from.getMonth()-1);

        this.state = {
            loading: false,
            average: "",
            results: [],
            fromDate: from,
            toDate: new Date(),
            formKey: 1,
            alertText: false
        };

        this.bindEvents(['Submit', 'Reset', 'DownloadCSV'], new Events);
    }

    pageTitle () {
        return _`EQ-5D™ issue to completion time | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="report-tab">
                <HeadingDoc title="Average Issue to Completion Time of EQ-5D™ questionnaires">
                    {_`The report below will display the average time taken from issue to completion of EQ-5D™ questionnaires issued between the dates specified.`}
                </HeadingDoc>
                <Page {...this.state} events={this.events}/>
            </div>
        );
    }
}

const Page = (props) => {
    return (
        <div>
            <ReportForm {...props} />
            <Results {...props} />
        </div>
    );
};

const ReportForm = (props) => {
    let alertType, alertText;

    if (props.loading) {
        alertType = 'info';
        alertText = _`Loading. Please wait...`;
    }

    if (props.alertText) {
        alertType = 'danger';
        alertText = props.alertText;
    }

    return (
        <div className="report-form">
            <FormPanel
                key={props.formKey}
                title={_`Refine Report`}
                intro={_`Please enter the date period`}
                id='report-form'
                onSubmit={props.events.onSubmit}
                onReset={props.events.onReset}
                submitLabel={_`Run Report`}
                resetLabel={_`Discard Changes`}
                busy={props.loading}
                alertText={alertText}
                alertType={alertType}>

                <DateInputField
                    label={_`From Date:`}
                    id='report-from-date'
                    initialValue={props.fromDate}
                    pastOnly={true}
                />

                <DateInputField
                    label={_`To Date:`}
                    id='report-to-date'
                    initialValue={props.toDate}
                    pastOnly={true}
                />

            </FormPanel>
        </div>
    );
};

const Results = (props) => {
    let warning = null, averageWarning;
    if (props.average == '') {
        return (
            <div>
                <div className="alert alert-info">
                    <span>Please select a date range and run search above to view the results.</span>
                </div>
            </div>
        );
    }

    if (props.results.length < 3) {
        warning = (
            <Row>
                <Column md="12">
                    <div className="alert alert-info">
                        <span>Less than 3 questionnaires were used in this average.</span>
                    </div>
                </Column>
            </Row>
        );
    }

    if (props.average < 48600) {
        averageWarning = (
            <Row>
                <Column md="12">
                    <div className="alert alert-info">
                        <span>The average value returned is less than half a day and so will not be displayed in the table below.</span>
                    </div>
                </Column>
            </Row>
        )
    }
    return (
        <div>
            <div className="form-group">
                <button type="button" className="btn btn-success" onClick={props.events.onDownloadCSV}>Download as CSV
                </button>
            </div>
            {warning}
            {averageWarning}
            <Row>
                <Column md="6">
                    <ResponsiveTable>
                        <thead>
                        <tr>
                            <th>Average Issue to Completion Time:</th>
                            <th>
                                <SecondsToText seconds={false} minutes={false}
                                               hours={true}>{props.average}</SecondsToText>
                            </th>
                        </tr>
                        </thead>
                    </ResponsiveTable>
                </Column>
            </Row>
        </div>
    );
};
