import React from 'react';
import _ from 'services/i18n';
import {Link} from 'react-router';
import PaginationView from 'components/pagination';
import ActionMenu from 'components/action-menu';
import classNames from 'classnames';
import { usePathwayNames } from 'features/pathway-labels/usePathwayNames';
import { DateTime } from 'common/datetime/DateTime';

const ResultRows = ({uiList, folderId, onArchiveQuestionnaire}) => {
    return (
        <tbody>{
            !!uiList && uiList.length && uiList.map((data, idx) => <ResultRow
                data={data}
                folderId={folderId}
                onDeleteQuestionnaire={onArchiveQuestionnaire}
                key={idx}
            ></ResultRow>)
        }</tbody>
    );
};

const ResultRow = ({
    data,
    folderId,
    onDeleteQuestionnaire,
}) => {
    const {
        questionnaireResponse,
        questionnaire,
        scoreValue,
        scoreName,
        isValid,
    } = data;

    const { labels } = questionnaireResponse ? questionnaireResponse.document.content : {};
    const { pathwayNamesWithEvent } = usePathwayNames({ labels });

    if (!isValid) {
        return (
            <tr>
                <td colSpan="7">Invalid Questionnaire</td>
                <td>
                </td>
            </tr>
        );
    }

    const queryParams = [];
    if (questionnaireResponse.type === 'questionnaireResponse'){
        queryParams.push('questionnaire');
    }
    if (data.isLegacy){
        queryParams.push('legacy');
    }

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
    const path = `/clinical_portal/folder/${folderId}/questionnaires/${questionnaireResponse.document.uuid}${queryString}`;

    const questionnaireStatus = questionnaireResponse.document.content.status.trim().toLowerCase();

    const actionList = [
        {route: path, label: _`View Questionnaire`}
    ];

    if (questionnaireStatus === 'assigned') {
        // allow deletion for assigned status
        actionList.push(
            {
                type: 'callback',
                callback: onDeleteQuestionnaire.bind(null, questionnaireResponse.document.uuid),
                label: _`Archive`
            }
        )
    }

    const rowClassName = classNames({
        'warning': questionnaireStatus === 'overdue'
    });

    return (
        <tr className={rowClassName}>
            {pathwayNamesWithEvent.length ?
                <td colSpan={2} className="pathway-labels_cell-wrapper">
                    <div className="pathway-labels">
                        {pathwayNamesWithEvent.map((names, i) => {
                            return (
                                <div className="pathway-labels_row" key={i}>
                                    <div className="pathway-labels_cell no-wrap" title={names[0] || ''}>{names[0] || ''}</div>
                                    <div className="pathway-labels_cell no-wrap" title={names[1] || ''}>{names[1] || ''}</div>
                                </div>
                            );
                        })}
                    </div>
                </td> : <td colSpan={2}></td>
            }
            <td>
                {
                    !data.isConfidential ?
                        <Link to={path}>{questionnaire.document.name}</Link>
                        :
                        <span>{questionnaire.document.name}</span>
                }
            </td>
            <td>
                {
                    scoreName != null && (<span><div>{scoreName}</div><div>{scoreValue}</div></span>)
                }
            </td>
            <td>{questionnaireResponse.document.content.status}</td>
            <td>{questionnaireResponse.document.content.name_of_issuer}</td>
            <td>{questionnaireResponse.document.content.due_date ? <DateTime>{questionnaireResponse.document.content.due_date}</DateTime> : 'Not Specified'}</td>
            <td><DateTime
                parseFormat="nhs_date_fulldate"
            >{questionnaireResponse.document.created_at}</DateTime></td>
            <td>
                {
                    !data.isConfidential ?
                        <ActionMenu size="xs" links={actionList} label={_`Actions`}/>
                        :
                        <p>Confidential</p>
                }
            </td>
            <td>{questionnaire.document.version}</td>
        </tr>
    );
}

const Results = (props) => {
    if (props.loading) {
        return (
            <h5>{_`Loading questionnaires...`}</h5>
        );
    }
    if (props.count == 0) {
        return (
            <div role='alert'>{_`Sorry, no matching results found`}</div>
        );
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-condensed table-striped table-responsive table-nomargin">
                <thead>
                <tr>
                    <th colSpan={2} className="pathway-labels_cell-wrapper"
                        width="16%"
                    >
                        <div className="pathway-labels">
                            <div className="pathway-labels_row">
                                <div className="pathway-labels_cell">{_`Pathway`}</div>
                                <div className="pathway-labels_cell">{_`Clinical Event`}</div>
                            </div>
                        </div>
                    </th>
                    <th>{_`Questionnaire`}</th>
                    <th>{_`Score`}</th>
                    <th>{_`Status`}</th>
                    <th>{_`Issued By`}</th>
                    <th>{_`Response Due By`}</th>
                    <th>{_`Issued On`}</th>
                    <th>{_`Actions`}</th>
                    <th>{_`Version`}</th>
                </tr>
                </thead>
                <ResultRows {...props} />
            </table>
        </div>
    );
};

const ListView = (props) => {
    return (
        <div className='questionnaires-list'>
            <Results {...props} />
            <PaginationView
                currentPage={props.pagination.current}
                pageCount={Math.ceil(props.count / props.perPage)}
                onChange={props.onChangePage}
            />
        </div>
    );
};

export default ListView;
