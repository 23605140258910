import { useEffect, useMemo } from 'react';

import DataStore from 'services/data-store';
import { useAPI } from 'common/useAPI';
import { useCompositionCollection } from 'common/useCompositionCollection';
import { useCompositions } from 'common/useCompositions';
import { MNDWorklistItem } from './MndWorklist';
import {
    getNameFromDemographics,
    getPatientUrl,
    getPatientGraphsURL,
    getNHSFromDemographics,
    getMNDQuestionnaireInfo
} from '../mnd-depressed-patients-worklist/MNDDepressedPatientsHelpers';

const getMNDWorklistURL = (teamId, roleUuid, perPage, page) =>
    `/api/teams/${teamId}/worklists/mnd-patients-worklist?using_role_uuid=${roleUuid}&per_page=${perPage}&page=${page}`;

export const useMNDPatientsWorklistCollection = ({ teamId }): [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any[], {
        isFetching: boolean;
        isLazyFetching: boolean;
        isError: boolean;
        totalItems: number;
    }
] => {
    const { get } = useAPI();

    const [worklistCollection, {
        isFetching,
        isLazyFetching,
        totalItems,
        isError
    }] = useCompositionCollection({
        archetype: 'WorklistReferral',
        teamId,
        getListFunction: async ({ teamId, perPage, page }) => {
            const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});
            const worklistURL = getMNDWorklistURL(teamId, roleUuid, perPage, page);
            const response = await get(worklistURL);
            const results = response.message.results || {};
            return [Object.values(results), 0];
        }
    });

    const [questionnaireResponsesMap, , {
        setItems: setQuestionnaireResponses
    }] = useCompositions({
        archetype: 'questionnaireResponse',
        teamId
    });

    const [questionnaireScoresMap, , {
        setItems: setQuestionnaireScores
    }] = useCompositions({
        archetype: 'questionnaireScores',
        teamId
    });

    const [demographicsMap, , {
        setItems: setDemographics
    }] = useCompositions({
        archetype: 'demographics',
        teamId
    });

    useEffect(() => {
        const filteredWorklist = filterWorklist(worklistCollection);
        const {
            questionnaireResponses,
            questionnaireScores,
        } = getQuestionnaireStuffFromWorklist(filteredWorklist);

        setQuestionnaireResponses(questionnaireResponses);
        setQuestionnaireScores(questionnaireScores);
        const demographics = getDemographicsFromWorklist(filteredWorklist);
        setDemographics(demographics);
    }, [
        worklistCollection,
        setQuestionnaireResponses,
        setDemographics,
        setQuestionnaireScores,
    ]);

    const worklistItems = useMemo(() => {
        const filteredWorklist = filterWorklist(worklistCollection);

        return filteredWorklist.map(({ uuid }) => mapToWorklistItem(uuid, {
            questionnaireResponsesMap,
            questionnaireScoresMap,
            demographicsMap,
        })).filter(item => !!item);
    }, [
        worklistCollection,
        questionnaireResponsesMap,
        questionnaireScoresMap,
        demographicsMap
    ]);

    return [worklistItems, {
        isFetching,
        isLazyFetching,
        totalItems,
        isError
    }];
};

function filterWorklist(worklist) {
    return Object.values(worklist)
        .filter(({
            questionnaireResponse,
            questionnaireScores,
            demographics,
            referral,
        }) => questionnaireResponse && questionnaireResponse.length > 0
            && questionnaireScores && questionnaireScores.length > 0
            && demographics && demographics.length > 0
            && referral && referral.length > 0
        ).map(({
            questionnaireResponse,
            questionnaireScores,
            demographics,
            referral,
        }) => {
            const firstReferral = referral[0];
            return {
                ...firstReferral,
                relatedCompositions: {
                    demographics,
                    questionnaireResponse,
                    questionnaireScores,
                }
            };
        });
}

const convertToNumber = (str: string) => {
    const num = parseInt(str, 10);

    if (`${num}` !== str) {
        return -Infinity;
    }

    return num;
};

function getQuestionnaireStuffFromWorklist(worklistCollection) {
    const questionnaireAndResponses = worklistCollection.map(({ relatedCompositions, ...referral }) => {
        const questionnaireResponses = relatedCompositions.questionnaireResponse || [];
        const questionnaireScores = relatedCompositions.questionnaireScores || [];

        return [referral.uuid, questionnaireResponses, questionnaireScores];
    });
    return {
        questionnaireResponses: questionnaireAndResponses.map(([referralUuid, responses]) => ([referralUuid, responses])),
        questionnaireScores: questionnaireAndResponses.map(([referralUuid, , scores]) => ([referralUuid, scores])),
    };
}

function getDemographicsFromWorklist(worklistCollection) {
    return worklistCollection.map(({ relatedCompositions, ...referral }) => {
        const demographics = relatedCompositions.demographics;
        return [referral.uuid, demographics && demographics.length ? demographics[0] : undefined];
    });
}

function mapToWorklistItem(uuid, {
    questionnaireResponsesMap,
    questionnaireScoresMap,
    demographicsMap,
}): MNDWorklistItem {
    try {
        const demographics = demographicsMap.get(uuid);

        if (!demographics) {
            return null;
        }

        const questionnaireResponses = questionnaireResponsesMap.get(uuid);
        const questionnaireScores = questionnaireScoresMap.get(uuid);

        const folderId = demographics.folder_id;

        const resultInfoSNAQ = getMNDQuestionnaireInfo('SNAQ', questionnaireScores, questionnaireResponses, folderId);
        const resultInfoBreathing = getMNDQuestionnaireInfo('Breathing', questionnaireScores, questionnaireResponses, folderId);
        const resultInfoALS = getMNDQuestionnaireInfo('ALS-FRS-R', questionnaireScores, questionnaireResponses, folderId);
        const resultInfoWeight = getMNDQuestionnaireInfo('Weight', questionnaireScores, questionnaireResponses, folderId);

        const latestCompletedAt = Math.max(resultInfoSNAQ?.date || 0, resultInfoBreathing?.date || 0, resultInfoALS?.date || 0,resultInfoWeight?.date || 0);

        return {
            uuid: demographics.uuid,
            name: getNameFromDemographics(demographics),
            patientURL: getPatientUrl(folderId),
            nhs: getNHSFromDemographics(demographics),
            patientGraphsURL: getPatientGraphsURL(folderId),
            latestCompletedAt,
            snaqTimestamp:  resultInfoSNAQ?.date,
            snaqQuestionnaireURL: resultInfoSNAQ?.url,
            snaqScore: convertToNumber(resultInfoSNAQ?.score),
            snaqScoreString: resultInfoSNAQ?.scoreString,
            snaqScoreDiff: convertToNumber(resultInfoSNAQ?.diff),
            breathingTimestamp:  resultInfoBreathing?.date,
            breathingQuestionnaireURL: resultInfoBreathing?.url,
            breathingScore: convertToNumber(resultInfoBreathing?.score),
            breathingScoreString: resultInfoBreathing?.scoreString,
            breathingScoreDiff: convertToNumber(resultInfoBreathing?.diff),
            alsTimestamp:  resultInfoALS?.date,
            alsQuestionnaireURL: resultInfoALS?.url,
            alsScore: convertToNumber(resultInfoALS?.score),
            alsScoreString: resultInfoALS?.scoreString,
            alsScoreDiff: convertToNumber(resultInfoALS?.diff),
            weightTimestamp:  resultInfoWeight?.date,
            weightQuestionnaireURL: resultInfoWeight?.url,
            weightScore: convertToNumber(resultInfoWeight?.score),
            weightScoreString: resultInfoWeight?.scoreString,
            weightScoreDiff: convertToNumber(resultInfoWeight?.diff),
            weightScoreDiffString: resultInfoWeight?.diff,
        };
    } catch (err) {
        console.error(err);
        return null;
    }
}
