import { ModalDialog } from 'common/modalDialog';
import React, { Fragment, MouseEventHandler, useEffect, useState } from 'react';
import _ from 'services/i18n';
import { Loading } from 'common/ui/alert-boxes';
import { SimpleQuestionnaireForm } from './model/SimpleQuestionnnaireModel';
import questionnaireService from 'services/questionnaire.service';
import { PreviewSimpleQuestionnaireApiModel } from './model/PreviewSimpleQuestionnaireModel';
import TimelinePhone from 'common/ui/timeline-phone/TimelinePhone';

type PreviewSimpleQuestionnaireModalProps = {
  onClose: MouseEventHandler<any>;
  questionnaire: SimpleQuestionnaireForm;
}

export const PreviewSimpleQuestionnaireModal = ({
    onClose,
    questionnaire
}:  PreviewSimpleQuestionnaireModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [questionnairePreview, setQuestionnairePreview] = useState<any>(null);
    const [error, setError] = useState<boolean>(false);
      
    useEffect(() => {
        loadPreview();
    }, [questionnaire]);
      
    const loadPreview = (): void => {
        const previewApiModel: PreviewSimpleQuestionnaireApiModel = SimpleQuestionnaireForm.hydrateForPreviewQuestionnaire(questionnaire);
        
        questionnaireService.previewSimpleQuestionnaire(previewApiModel)
            .then((resp) => {
                setQuestionnairePreview(resp.message);
                setIsLoading(false);
                setError(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                setError(true);
                setQuestionnairePreview(null);
            });
    };
      
    const buttons = (
        <Fragment>
            <button
                className="btn btn-default"
                onClick={onClose}>Cancel</button>
        </Fragment>
    );
        
    return (
        <ModalDialog
            size={'lg'}
            title={_`Preview Simple Questionnaire`}
            buttons={buttons}
            onClose={onClose}>
            
            {isLoading && (
                <Loading show={isLoading}>{_`Loading Preview...`}</Loading>
            )}
      
            {!isLoading && error && (
                <div className='alert alert-danger'>
                    <p>Sorry, something went wrong when requesting the preview from the server, please try again later.</p>
                </div>
            )}
      
            {!isLoading && questionnairePreview && (
                <TimelinePhone
                    content_type={'questionnaire-timeline'}
                    classes={'simple-questionnaire-preview'}
                    title={questionnairePreview.questionnaire.content[0].name}
                    lozenge_content={questionnairePreview.questionnaireResponse.content[0].details.timeline.lozenge_content}
                    lozenge_title={questionnairePreview.questionnaireResponse.content[0].details.timeline.lozenge_title}
                    questionnaire={questionnairePreview.questionnaire.content[0]}
                    popup_content={questionnairePreview.questionnaireResponse.content[0].details.timeline.lozenge_content}
                    popup_title={questionnairePreview.questionnaireResponse.content[0].details.timeline.lozenge_title} />
            )}
      
        </ModalDialog>
    );
};
