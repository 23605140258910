import { makeGetRequest } from './api';
import { IRole } from 'phr-api-client';
import { DataStore } from 'services/data-store';
import { UserFolder, USER_STATUS } from 'models/UserFolder';
import { ServerResponse } from 'models/ServerResponse';
import {fetchAll} from 'services/api-v2.service';

export type TeamMember = {
    folder_id: number;
    user_id: number;
    status: USER_STATUS;
    user_details?: {
        name?: {
            given_name: string;
            family_name: string;
        };
        email: string;
    };
    team_id: number;
    user_roles: string[];
    invite_id: number | null;
}

class CliniciansService {
    getAll(roles: string[]): Promise<{
        status: number;
        message: TeamMember[];
    }> {
        const role = DataStore.get('me.currentRole');

        let url = `/teams/${role.teamId}/get-team-users-by-role?using_role_uuid=${role.uuid}`;

        roles.forEach((role) => {
            url += `&role_names[]=${role}`;
        });

        return makeGetRequest(url);
    }

    list({
        role,
        limit = 100,
        offset = 0,
        roleUuids
    }: {
        role?: IRole;
        limit: number;
        offset: number;
        roleUuids: string[];
    }): Promise<ServerResponse<UserFolder[]> | UserFolder[]> {
        role = role || DataStore.get('me.currentRole');
        if (limit === Infinity) {
            return this.listAll({ role, roleUuids });
        }
        return makeGetRequest(`/teams/${role.teamId}/user-folders?using_role_uuid=${role.uuid}&offset=${offset}&limit=${limit}&role_uuids=${roleUuids}&include_email_addresses=true`);
    }

    listAll({ role, roleUuids }): Promise<any> {
        const limit = 100;
        return fetchAll((offset: number) => {
            return this.list({ role, roleUuids, limit, offset });
        });
    }
}

export const cliniciansService = new CliniciansService();
