import _ from '../../services/i18n';
import { portalToast } from '../../../portal-components/ui/toast/Toast';

export const showLatestLoginToast = (time, className) => {
    return portalToast.default({
        title: _(['Latest Login']),
        content: time ? time : _(['Never']),
        className,
        toastId: 'latestLogin'
    });
};
