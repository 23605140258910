import React from 'react';
import { ModalShowHideFnRef } from 'common/ui/modal';
import { ModalDialogSelfClosing } from 'common/ModalDialogSelfClosing';
import PatientModalView from 'features/pathwayModal/PatientModalView';

export const AdvancedSearchPatientResultViewModal = (props: {
    selectedTab: string;
    folderId: number;
    tabView: boolean;
    showHideFnRef: ModalShowHideFnRef;
}) => {
    return (
        <ModalDialogSelfClosing size='lg' title='Patient Overview' showHideFnRef={props.showHideFnRef}>
            <PatientModalView tab={props.selectedTab} folderId={props.folderId.toString()} tabView={props.tabView} />
        </ModalDialogSelfClosing>
    );
};
