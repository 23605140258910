import { useSelectionStateContext } from 'components/dataSource/FolderSelectionState';
import React, { Fragment } from 'react';
import { LinkedCheckbox } from 'ui/checkbox/LinkedCheckbox';
import _ from 'services/i18n';
import {
    Grid,
    GridBody,
    GridCell,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow,
    GridSortState,
} from '../../../common/ui/grid';
import { CoopResource } from 'models/compositions/CoopResource';
import { Composition } from 'models/Composition';
import { resourceTypeList } from '../ResourceTypeSelect/ResourceTypeSelect';

type ResourcesSearchResultsTableProps = {
    results: Composition<CoopResource>[];
    isIncluded: (resource: Composition<CoopResource>) => boolean;
    includedColumnHeading: string;
    onSort: (param: GridSortState) => void;
    sortOrder: GridSortState;
};

export const ResourcesSearchResultsTable = ({ results, includedColumnHeading, isIncluded, onSort, sortOrder }: ResourcesSearchResultsTableProps) => {

    return (
        <Fragment>
            <div className={'scrollable-table'}>
                <Grid
                    onSort={onSort}
                    sortOrder={sortOrder}
                >
                    <GridHeader>
                        <GridHeaderRow>
                            <GridHeaderCell
                                sortable={false}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                            </GridHeaderCell>
                            <GridHeaderCell
                                field='content.label'
                                sortable={true}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                                {_`Title`}
                            </GridHeaderCell>
                            <GridHeaderCell
                                field='content.description'
                                sortable={true}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                                {_`Details`}
                            </GridHeaderCell>
                            <GridHeaderCell
                                field='content.is_included'
                                sortable={false}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                                {includedColumnHeading}
                            </GridHeaderCell>
                            <GridHeaderCell
                                field='content.resource_type'
                                sortable={false}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                                {_`Tags`}
                            </GridHeaderCell>
                            <GridHeaderCell
                                field='content.resource_type'
                                sortable={true}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                                {_`Type`}
                            </GridHeaderCell>
                        </GridHeaderRow>
                    </GridHeader>
                    <GridBody>
                        {(results && results.length > 0) && (
                            results.map((result) => {
                                let tags = [];

                                if (result.content.labels) {
                                    tags = result.content.labels
                                        .filter(label => label.type === 'resourceTags' && label.status === true)
                                        .map(label => label.name);
                                }

                                return (
                                    <GridRow key={result.uuid}>
                                        <GridCell>
                                            <FolderSelectionStateCheckbox
                                                uuid={result.uuid}
                                            />
                                        </GridCell>
                                        <GridCell>{result.content.label}</GridCell>
                                        <GridCell>{result.content.description}</GridCell>
                                        <GridCell>{isIncluded(result) ? _`Yes` : _`No`}</GridCell>
                                        <GridCell>{tags.join(', ')}</GridCell>
                                        <GridCell>{resourceTypeList.find((type) => type.value === result.content.resource_type).label || ''}</GridCell>
                                    </GridRow>
                                );
                            })
                        )}
                    </GridBody>
                </Grid>
            </div>
            {(!results || results.length <= 0) && (
                <p>
                    No records match the specified filters.
                </p>
            )}
        </Fragment>
    );
};

function FolderSelectionStateCheckbox(props: { uuid: number | string }) {
    const selectionStateContainer = useSelectionStateContext();

    return (
        <LinkedCheckbox
            stateSource={selectionStateContainer.selectionState}
            stateKey={props.uuid}
        />
    );
}

export default ResourcesSearchResultsTable;
