import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import ServerFetch from 'server-fetch';

import TeamSelector from 'components/team-selector';

import { useCurrentTeam } from 'common/useCurrentTeam';
import { useCurrentTeams } from 'common/useCurrentTeams';
import { useCurrentRoles } from 'common/useCurrentRoles';
import { useTeamSelect } from './useTeamSelect';
import { useInactive } from 'common/useInactive';
import { ClinicalPortalTemplateWithTeam } from './ClinicalPortalTemplateWithTeam';

export function ClinicalPortalTemplate({
    location,
    children,
}) {
    ServerFetch.setPortal('clinical');
    ServerFetch.renderFromServerState();

    const team = useCurrentTeam();
    useInactive();
    const [roles, hasInvites] = useCurrentRoles('clinical_portal', ['clinician', 'teamAdmin']);
    const teams = useCurrentTeams();
    const handleTeamSelect = useTeamSelect();

    useEffect(() => {
        if (teams && teams.length === 1 && hasInvites === false) {
            // auto select the team
            return handleTeamSelect(teams[0]);
        }
    }, [teams, hasInvites, handleTeamSelect]);

    const [forceTeamSelect, setForceTeamSelect] = useState(false);

    if (!roles || !teams) { return null; }

    if (!team || !team.teamId || forceTeamSelect) {
        return (
            <TeamSelector teams={teams}
                noClose={true}
                onSubmit={(team) => {
                    setForceTeamSelect(false);
                    handleTeamSelect(team);
                }}
            />
        );
    }

    return (
        <ClinicalPortalTemplateWithTeam
            team={team}
            location={location}
            onTeamClick={() => setForceTeamSelect(true)}
        >
            {children}
        </ClinicalPortalTemplateWithTeam>
    );
}

ClinicalPortalTemplate.propTypes = {
    location: PropTypes.object,
    children: PropTypes.element,
};
