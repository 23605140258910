import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';
import { CostEstimateValue, PendingCostEstimateValue, CostEstimateStatusType } from './CostEstimateValue';

export abstract class BulkOperationCostEstimator
{
    private costEstimatePromise: Promise<CostEstimateValue>;
    private costEstimate: CostEstimateValue;
    private onCostEstimateChange: (newCostEstimate: CostEstimateValue) => void;
    private selectionState: FolderSelectionState;

    constructor(selectionState: FolderSelectionState, onCostEstimateChange: (newCostEstimate: CostEstimateValue) => void) {
        this.selectionState = selectionState;
        this.onCostEstimateChange = onCostEstimateChange;
    }

    public getCurrentCostEstimate(): CostEstimateValue {
        if (!this.costEstimate) {
            return PendingCostEstimateValue;
        }
        return this.costEstimate;
    }

    public getSelectionState(): FolderSelectionState{
        return this.selectionState;
    }

    public async estimateCost(): Promise<CostEstimateValue> 
    {
        if (this.costEstimatePromise) {
            return this.costEstimatePromise;
        }

        this.costEstimatePromise = this.estimateCostImpl()
            .then((newCostEstimate: CostEstimateValue) =>
            {
                return this.handleNewCostEstimate(newCostEstimate);
            })
            .catch(() =>
            {
                return this.handleNewCostEstimate({
                    status:CostEstimateStatusType.FAILED,
                    value:undefined,
                    description:'Cost estimation failed' });
            });
        
        return this.costEstimatePromise;
    }

    private handleNewCostEstimate(newCostEstimate: CostEstimateValue): CostEstimateValue
    {
        this.costEstimate = newCostEstimate;
        this.onCostEstimateChange(newCostEstimate);
        return newCostEstimate;
    }

    protected abstract estimateCostImpl(): Promise<CostEstimateValue>;
}

export class BulkOperationCostEstimatorNone extends BulkOperationCostEstimator
{
    protected estimateCostImpl(): Promise<CostEstimateValue>
    {
        return new Promise<CostEstimateValue>((resolve) => resolve({
            status: CostEstimateStatusType.NONE,
            value: null,
            description: 'No cost'
        }));
    }
}

export class BulkOperationCostEstimatorUnknown extends BulkOperationCostEstimator
{
    protected estimateCostImpl(): Promise<CostEstimateValue>
    {
        return new Promise<CostEstimateValue>((resolve) => resolve({
            status: CostEstimateStatusType.UNKNOWN,
            value: null,
            description: 'Unknown cost'
        }));
    }
}
