import * as TER from 'models/TriggerEngineRequestContent';

export type AddPifuState = {
    name: string;
    context: string;
    description?: string;
    actionName?: string;
}

export function validate(state: AddPifuState, onErrors: (errors: string[]) => void): boolean {
    const normalisedState = normalise(state);
    const errors = [];
    if(normalisedState.name === '' || null) {errors.push('Please select an option.');}
    onErrors(errors);

    return errors.length === 0;
}

export function normalise(state: AddPifuState): AddPifuState {

    return {
        name: state.name,
        context: state.context,
        description: state.description,
        actionName: state.actionName
    };
}

export function getTriggerEngineContent(state: TER.AddPifuContent): TER.AddPifuContent {
    return {
        name: state.name,
        context: state.context
    };
}
