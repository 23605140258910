import { useState, useEffect } from 'react';

export interface StateProvider<V, K>
{
    registerStateChangeListener(toRegister: {stateChangeCallback: (newState: V) => (unknown); subStateKey? : K}): void;
    deregisterStateChangeListener(toDeregister: {stateChangeCallback? : (newState: V) => (unknown); subStateKey? : K}): void;
}

export interface StateLinkable<V, K> extends StateProvider<V, K>
{
    getState(subStateKey? : K): V;
    setState(newState: V, subStateKey? : K): void;
}

export type StateSourceLinkProps<V, K> =
{
    stateSource: StateLinkable<V, K>;
    stateKey? : K;
}

type StateProviderAccessFunction<V, K> = (subStateKey? : K) => V;

export function useStateProviderCallback<V, K>(stateProvider: StateProvider<V, K>, initialState: StateProviderAccessFunction<V, K>, subStateKey? : K): V
{
    const [newState, onStateChange] = useState<V>(initialState(subStateKey));

    useEffect(() =>
    {
        stateProvider.registerStateChangeListener({ stateChangeCallback: onStateChange, subStateKey: subStateKey });
        onStateChange(initialState(subStateKey));
        return function cleanup() {stateProvider.deregisterStateChangeListener({ stateChangeCallback: onStateChange, subStateKey: subStateKey });};
    }, [stateProvider, subStateKey, initialState]);

    return newState;
}

export function useStateSourceCallback<V, K>(stateSource: StateLinkable<V, K>, subStateKey? : K): V
{
    return useStateProviderCallback(stateSource, stateSource.getState, subStateKey);
}
