import React, { Fragment } from 'react';
import _ from 'services/i18n';

interface LozengeFormProps {
    onChange: (e) => unknown,
    lozengeTitle: string,
    lozengeContent: string
}

export const LozengeContentForm = ({
    onChange,
    lozengeTitle,
    lozengeContent
}: LozengeFormProps) => {

    return (
        <Fragment>
            <div className='form-group'>
                <label htmlFor='lozengeTitle'>{_`Lozenge Title`}: </label>
                <input
                    placeholder={_`Please enter lozenge title...`}
                    type="text"
                    className={'form-control lozenge-title'}
                    name={'lozengeTitle'}
                    value={lozengeTitle}
                    onChange={(e) => { onChange({ lozengeTitle: e.target.value }) }}
                />
            </div>
            <div className='form-group'>
                <label htmlFor='lozengeContent'>{_`Lozenge Content`}: </label>
                <input
                    placeholder={_`Please enter lozenge content...`}
                    type="text"
                    className={'form-control lozenge-content'}
                    name={'lozengeContent'}
                    value={lozengeContent}
                    onChange={(e) => { onChange({ lozengeContent: e.target.value }) }}
                />
            </div>
        </Fragment>
    )
}