import React from 'react';
import {Link} from 'react-router';
import _ from 'services/i18n';
import Markdown from 'components/ui/markdown';
import { DateTime } from 'common/datetime/DateTime';

/**
 * Message View Component
 */
class View extends React.Component {
    renderTopBar () {
        if (this.props.clinicalView) {
            return (
                <div className='top-bar'>
                    <Link
                        to={this.props.backRoute}>{_`Back to Messages`}</Link>
                    <Link
                        to={this.props.createRoute}>{_`Add New Message`}</Link>
                </div>
            )
        } else {
            return (
                <div className='top-bar'>
                    <Link to={"/user_portal/messages"}>{_`Back to Messages`}</Link>
                </div>
            )
        }
    }

    render () {

        if (this.props.error) {
            return (
                <div className="message-view">
                    {this.renderTopBar()}

                    <h3 className="spacer20">{_`Sorry, the server reported an error.`}</h3>
                </div>
            );
        }

        if (this.props.loading) {
            return (
                <div className="message-view">
                    {this.renderTopBar()}

                    <h3 className="spacer20">{_`Loading message...`}</h3>
                </div>
            );
        }

        return (
            <div className="message-view">
                {this.renderTopBar()}

                <h3 className="spacer20"><Markdown sanitize={false}>{_`View message ${this.props.message.content.title}`}</Markdown></h3>

                <div className="table-responsive">
                    <table className='table table-bordered table-striped table-wrap'>
                        <tbody>
                        <tr>
                            <th>{_`Title`}</th>
                            <td><Markdown sanitize={false}>{this.props.message.content.title}</Markdown></td>
                        </tr>
                        <tr>
                            <th>{_`Type`}</th>
                            <td>{this.props.message.content.type}</td>
                        </tr>
                        <tr>
                            <th>{_`Sender`}</th>
                            <td>{this.props.message.content.sender_name}</td>
                        </tr>
                        <tr>
                            <th>{_`Date Sent`}</th>
                            <td><DateTime
                                parseFormat='server_datetime'
                            >{this.props.message.created_at}</DateTime></td>
                        </tr>
                        <tr>
                            <th>{_`Type`}</th>
                            <td>
                                {this.props.message.content.send_as_email == 1 ? _`Email` : _`In App`}
                            </td>
                        </tr>
                        <tr>
                            <th>{_`Content`}</th>
                            <td><Markdown sanitize={false}>{this.props.message.content.content}</Markdown></td>
                        </tr>
                        <tr>
                            <th>{_`Popup Text`}</th>
                            <td>{this.props.message.content.popup_text ? <Markdown sanitize={false}>{this.props.message.content.popup_text}</Markdown> : null}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default View;
