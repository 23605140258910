import { getQuestionnaireResults } from 'features/patient/questionnaire-results/getQuestionnaireResults';
import { Line } from 'common/ui/line-graph/LineGraph';
import { generateLines } from 'features/patient/questionnaire-results/QuestionnaireResultsPage.reducer';

export interface QuestionnaireGraphParams {
    folderId: number;
    teamId: number;
    context: string;
    questionnaire: string;
    measure: string;
}
export const getDataForQuestionnaireGraph = async (params: QuestionnaireGraphParams): Promise<Line[]> => {
    const { folderId, teamId, context } = params;
    const results = await getQuestionnaireResults({ pathwayContext: context, folderId, teamId });
    return generateLines(results, params.questionnaire, params.measure);
};
