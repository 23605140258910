import Modal from 'ui/modal';
import React from 'react';
import _ from 'services/i18n';

export const Row = (props) => {
    const {spacer,children,className} = props
    let classVal = "row";

    if (spacer) {
        classVal = "row spacer";
    }

    if (className){
        classVal+= ` ${className}`;
    }

    return (
        <div className={classVal}>{children}</div>
    )
};

export const Column = (props) => {
    let colClasses = [];

    ['xs','sm','md','lg'].map((item) => {
        if (props[item]) {
            colClasses.push('col-' + item + '-' + props[item]);
        };

        if (props[item + 'Offset']) {
            colClasses.push('col-' + item + '-offset-' + props[item + 'Offset']);
        };
    });

    return (
        <div className={colClasses.join(' ')}>{props.children}</div>
    );
};

export const PanelFooter = () => { console.warn('Cannot call this component directly'); return <noscript />; };

export const Panel = (props) => {
    let content = [], footerContent = [], childList = [].concat(props.children);
    let panelType = props.type || "default", footer;

    let alertType = props.alertType || 'warning';
    let alertText = props.alertText || '';

    childList.map((item, idx) => {
        if (item && item.type && item.type == PanelFooter) {
            footerContent.push(item.props.children);
            return;
        }

        content.push(item);
    });

    if (footerContent.length > 0) {
        footer = (
            <div className="panel-footer">
                {footerContent}
            </div>
        );
    }

    let heading;
    if (props.title != undefined && props.title != '') {
        heading = (
            <div className="panel-heading">
                <h2 className="panel-title">{props.title}</h2>
            </div>
        );
    }

    let alertBox;
    if (alertText != '') {
        alertBox = (
            <div className={'alert alert-' + (alertType || 'warning')}>
                {alertText}
            </div>
        );
    }

    let busy;
    if (props.busy) {
        busy = (
            <div className="panel-loading">
                <span className="glyphicon glyphicon-lock" />
            </div>
        );
    }

    return (
        <div className={"panel panel-" + panelType}>
            {heading}
            {alertBox}
            <div className='panel-body-footer-group'>
                {busy}
                <div className="panel-body">
                    {content}
                </div>
                {footer}
            </div>
        </div>
    );
};

/**
 * Represents an element and optional label in a form.
 * The child of the element is the input item.
 * Properties:
 * - label = Optional. A label shown above or in-line with the input field.
 * - inline = Optional. If true, the label (if specified) will appear in the same line as the input element.
 * - spacer = Optional. If true, this item will be spaced farther than usual from neighbours.
 */
export const FormGroup = (props) => {

    let label, content, formGroupClass = 'form-group';

    if (props.spacer) {
        formGroupClass = 'form-group spacer';
    }

    if (props.label != undefined && props.label != '') {
        label = (
            <label>
                {props.label}
            </label>
        );
    }

    if (props.inline) {
        return (
            <div className={formGroupClass}>
                <div className="form-inline">
                    {label}
                    {props.children}
                </div>
            </div>
        );
    } else {
        return (
            <div className={formGroupClass}>
                {label}
                {props.children}
            </div>
        );
    }
};

export const InputField = (props) => {
    const inputType = props.type || 'text';
    const cls = props.cls;
    const id = props.id || '';
    const name = props.name;
    const placeholder = props.placeholder || '';
    const size = props.size;
    const value = cls[name];
    const additionalAttributes = ['autoComplete', 'list'].reduce((acc, attribute) => {
        if (props[attribute]) {
            acc = Object.assign({}, acc, { [attribute]: props[attribute]});
        }
        return acc;
    }, {});

    return (
        <input size={size} placeholder={placeholder} type={inputType} className="form-control" name={name} value={value}
               {...additionalAttributes}
               onChange={props.onChange || cls.onChange} id={id}/>
    );
};

export const ResponsiveTable = (props) => {
    return (
        <div className="table-responsive">
            <table className={props.className || 'table table-bordered table-striped table-wrap'}>
                {props.children}
            </table>
        </div>
    )
};

/**
 * Represents a drop-down box allowing a single selection from a list of options.
 * Props:
 * - id: Required. The ID of the control.
 * - value: Required. Currently selected value of the drop-down.
 * - options: Optional. Array of objects representing available options. Each object should have properties 'value' and 'label', and optionally a boolean called 'disabled'. Can be optionally passed as children instead.
 * - disabled: Optional. Boolean indicating if the control is disabled or not.
 * - onChange: Required. Callback triggered when the selection is changed. First argument is the newly selected value. Second is the event argument.
 *
 * The 'children' prop will only be used if the 'options' prop is not specified.
 */
export const SingleSelect = (props) => {
    if (props.id === undefined || props.value === undefined || props.onChange === undefined) {
        console.warn('One or more required props missing from SingleSelect.');
    }

    let options = props.options || props.children || [];
    let optionElems = options.map((item, idx) => {
        return (<option key={idx} value={item.value} disabled={item.disabled || false}>{item.label}</option>);
    });

    return (
        <select
            id={props.id}
            value={props.value}
            onChange={(e) => { props.onChange(e.target.value, e); }}
            disabled={props.disabled || false} >
            {optionElems}
        </select>
    );
}

/**
 * Represents a list box which allows multiple selection.
 * Props:
 * - id: Required. The ID of the control.
 * - value: Required. Array of currently selected values (must match values specified in options).
 * - options: Optional. Array of objects representing available options. Each object should have properties 'value' and 'label', and optionally a boolean called 'disabled'. Can be optionally passed as children instead.
 * - disabled: Optional. Boolean indicating if the control is disabled or not.
 * - onChange: Required. Callback triggered when the selection is changed. First argument is an array of currently selected values. Second is the event argument.
 * - size: Optional. Specifies vertical size of list box as a number of options.
 * - minSize: Optional. Specifies the smallest allowed size the list box is allowed to be as a number of options.
 * - maxSize: Optional. Specifies the largest allowed size the list box is allowed to be as a number of options.
 * - intValue: Optional. If true, the values will be parsed to integers before being passed to the onChange handler.
 *
 * The 'children' prop will only be used if the 'options' prop is not specified.
 *
 * The size of the list box can be controlled explicitly by specifying the size property.
 * Alternatively, it can shrink/grow automatically based on the number of options, within constraints defined by minSize and maxSize.
 * If size is specified then minSize and maxSize are ignored.
 * If no size props are specified then it defaults to 5.
 */
export const MultiSelect = (props) => {
    if (props.id === undefined || props.value === undefined || props.onChange === undefined) {
        console.warn('One or more required props missing from MultiSelect.');
    }
    if (props.minSize != null && props.maxSize != null && props.maxSize < props.minSize) {
        console.warn('MultiSelect: minSize must be less than maxSize');
    }

    let options = props.options || props.children || [];
    let optionElems = options.map((item, idx) => {
        return (<option key={idx} value={item.value} disabled={item.disabled || false}>{item.label}</option>);
    });

    let size = 5;
    if (props.size != null) {
        // If an explicit size if specified then it takes priority.
        size = props.size;
    } else if (props.minSize != null || props.maxSize != null) {
        // Change size dynamically.
        size = options.length || 1;
        if (props.minSize != null && size < props.minSize) {
            size = props.minSize;
        }
        if (props.maxSize != null && size > props.maxSize) {
            size = props.maxSize;
        }
    }

    let changeHandler = (e) => {
        let value = [];
        for (let opt of e.target.options) {
            if (opt.selected) {
                if (props.intValue) {
                    value.push(parseInt(opt.value));
                } else {
                    value.push(opt.value);
                }
            }
        }
        props.onChange(value, e);
    }

    return (
        <select
            multiple={true}
            id={props.id}
            value={props.value}
            onChange={changeHandler}
            disabled={props.disabled || false}
            size={size} >
            {optionElems}
        </select>
    );
}

export const ConfirmModal = ({onConfirm, onClose, children}) => {
    let buttons = [
        {
            type: 'callback', callback: onConfirm, label: 'Confirm', style: 'danger'
        },
        {
            type: 'callback', callback: onClose, label: 'Cancel'
        }
    ];

    return (
        <div className="confirm-modal">
            <Modal title="Confirm" type="info" onClose={onClose} buttons={buttons}>
                {children}
            </Modal>
        </div>
    )
};

export const Folder = ({type, children}) => {
    return (
        <div className="folderContainer">
            <div className={"folder folder-" + (type || 'bottom')}>
                {children}
            </div>
            <div className="folder-spacer"></div>
        </div>
    )
}

export const Section = ({title, children}) => {
    return (
        <div className="section-panel panel panel-default">
            <div className="panel-heading">
                <h2>{title}</h2>
            </div>
            <div className="panel-body">
                {children}
            </div>
        </div>
    );
};

/**
 * Displays summary information about a referrer.

 *
 * Properties:
 *  - code = An identifier code for the referrer, e.g. a GP practice organisation code. Must be specified if name is not.
 *  - name = The human-readable name of the referrer. Must be specified if code is not.
 *  - nameLookup = An object mapping codes to names. This allows the name to be looked-up automatically. Ignored if name is specified.
 *  - type = Identifies the type of referrer. If specifies then this will be shown in a human-readable form if possible.
 *  - empty = The text to display if there is no referrer information. If not specified, there will be no output if there is no referrrer info.
 */
export const ReferrerSummary = ({type, code, name, nameLookup, empty}) => {

    // We can't display any data if we don't have a code or a name.
    if (!code && !name) {
        if (empty) {
            return (<div>{empty}</div>);
        }

        return (<noscript />);
    }

    // Lookup the name if necessary.
    if (code && nameLookup && !name && nameLookup.hasOwnProperty(code)) {
        name = nameLookup[code];
    }

    let mainInfo, extraInfo;

    if (type == 'gp_practice') {
        extraInfo = _`GP Practice`;
    } else if (type) {
        extraInfo = type;
    }

    if (name) {
        mainInfo = name;
        if (code) {
            if (extraInfo) {
                extraInfo += ': ' + code;
            } else {
                extraInfo = code;
            }
        }
    } else if (code) {
        mainInfo = code;
    }

    return (
        <div>
            <span>{mainInfo}</span><br/>
            <span className='text-muted'>{extraInfo}</span>
        </div>
    );
};