import React from 'react';
import _ from 'services/i18n';
import { SimpleDropdown, SimpleDropdownOptions } from 'common/ui/dropdown/SimpleDropdown';
import { useForm } from 'common/form/useForm';
import './AntenatalTriageDecisionForm.less';

const ANTENATAL_TRIAGE_DECISIONS: SimpleDropdownOptions = [
    ['group', 'Group'],
    ['reject', 'Reject'],
    ['video', 'Video Information'],
    ['Telephone 30', 'Telephone 30'],
    ['Telephone 60', 'Telephone 60'],
    ['One to one 30', 'One to one 30'],
    ['One to one 60', 'One to one 60'],

];

const antenatalTriageDecisionState = {
    triageDecision: {
        value: '',
        error: ''
    },
    isUrgent: {
        value: false,
        error: ''
    },
    isInterpreterRequired: {
        value: false,
        error: ''
    },
    interpreterDetails: {
        value: '',
        error: '',
    },
    details: {
        value: '',
        error: ''
    }
};

const antenatalTriageDecisionSchema = {
    triageDecision: {
        required: true
    },
    isUrgent: {},
    isInterpreterRequired: {},
    interpreterDetails: {},
    details: {},
};

const getTriageInitialData = (initialData) => {
    const appointment = initialData.appointment ?? {};
    const referral = initialData.referral ?? {};
    if (!appointment.content || !referral.content?.triage_decision) {
        return {};
    }
    return {
        triageDecision: {
            value: referral.content.triage_decision,
            error: ''
        },
        isUrgent: {
            value: (referral.content.priority == 'Urgent'),
            error: ''
        },
        isInterpreterRequired: {
            value: (appointment.content.interpreter?.required ?? false),
            error: ''
        },
        interpreterDetails: {
            value: (appointment.content.interpreter?.details ?? ''),
            error: '',
        },
        details: {
            value: (appointment.content.triage_decision_notes ?? ''),
            error: ''
        }
    };
};

export function AntenatalTriageDecisionForm({ onSubmitForm = () => void 0, onSubmitRef, initialData = {} }) {
    const initialState = getTriageInitialData(initialData);
    const {
        state,
        handleOnChange,
        handleOnSubmit,
        disable
    } = useForm({ ...antenatalTriageDecisionState, ...initialState }, antenatalTriageDecisionSchema, onSubmitForm);
    onSubmitRef.current = handleOnSubmit;
    return (
        <form
            name="antenatalTriageDecisionForm"
            autoComplete="off"
            onSubmit={handleOnSubmit}
            className={'inline-form inline-form--width100pc'}
        >
            <div className="inline-form_table-layout">
                <div className={'inline-form_table-layout-row ' + (state.triageDecision.error ? 'has-error' : '')}>
                    <div className="inline-form_table-layout-cell">
                        <label htmlFor="triageDecision"><strong>{_`Triage Decision`}</strong></label>
                    </div>
                    <div className="inline-form_table-layout-cell">
                        <SimpleDropdown
                            name='triageDecision'
                            items={ANTENATAL_TRIAGE_DECISIONS}
                            value={state.triageDecision.value}
                            onChange={handleOnChange}
                        />
                        {!!state.triageDecision.error && (
                            <div className="inline-form_error">
                                {state.triageDecision.error}
                            </div>
                        )}
                    </div>
                </div>
                <div className={'inline-form_table-layout-row'}>
                    <div className="inline-form_table-layout-cell">
                        <label htmlFor="isUrgent"><strong>{_`Urgent`}</strong></label>
                    </div>
                    <div className="inline-form_table-layout-cell">
                        <input
                            type={'checkbox'}
                            name='isUrgent'
                            id={'isUrgent'}
                            checked={state.isUrgent.value}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
                <div className={'inline-form_table-layout-row'}>
                    <div className="inline-form_table-layout-cell">
                        <label htmlFor="isInterpreterRequired"><strong>{_`Interpreter`}</strong></label>
                    </div>
                    <div className="inline-form_table-layout-cell">
                        <div className="inline-form_checkbox-with-textarea">
                            <input
                                type={'checkbox'}
                                name='isInterpreterRequired'
                                id={'isInterpreterRequired'}
                                checked={state.isInterpreterRequired.value}
                                onChange={handleOnChange}
                            />
                            <textarea
                                className="form-control"
                                name="interpreterDetails"
                                placeholder={_`Interpreter details`}
                                onChange={handleOnChange}
                                value={state.interpreterDetails.value}
                                disabled={!state.isInterpreterRequired.value}
                            />
                        </div>
                    </div>
                </div>
                <div className={'inline-form_table-layout-row ' + (state.details.error ? 'has-error' : '')}>
                    <div className="inline-form_table-layout-cell">
                        <label htmlFor="details"><strong>{_`Details`}</strong></label>
                    </div>
                    <div className="inline-form_table-layout-cell">
                        <textarea className="form-control"
                            name="details"
                            placeholder={_`Details`}
                            onChange={handleOnChange}
                            value={state.details.value}
                        />
                        {!!state.details.error && (
                            <div className="inline-form_error">
                                {state.details.error}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
}
