import React, { FC, Fragment, useEffect, useState } from 'react';
import _ from 'services/i18n';

import {
    Grid,
    GridBody,
    GridCell,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow,
    SortOrder,
    TABLE_FILTER
} from 'common/ui/grid';
import { Link } from 'react-router';
import NhsNumber from 'ui/nhs-number';
import { DateSpan } from 'common/datetime/DateTime';
import { APPOINTMENT_STATUS, QUESTIONNAIRE_STATUS, TRIAGE_STATUS } from '../antenatalHelpers';
import { convertToDate, standardDateTimeFormats } from 'common/datetime/convertToDate';
import './AntenatalWorkList.less';
import PaginationView from 'components/pagination';
import { Loading } from 'common/ui/alert-boxes';
import { usePagination } from 'common/ui/usePagination';
import { Referral } from 'features/antenatal/AntenatalWorklistPage';
import { terService } from 'services/ter.service';
import { filterNhs } from 'common/filterNhs';

interface AntenatalWorklistItemAppointment {
    date: number;
    unknownDate: boolean;
    uuid: string;
    folderId: number;
}

export interface AntenatalWorklistItem {
    appointment?: AntenatalWorklistItemAppointment;
    uuid: string;
    name: string;
    patientURL: string;
    dob: number;
    hiddenFromTimeline?: boolean;
    nhs: string;
    hospitalNumber: string;
    selfReferredTimestamp: number;
    questionnaireStatus: QUESTIONNAIRE_STATUS | string;
    triageStatus: TRIAGE_STATUS | string;
    isInterpreter: boolean;
    interpreterDetails: string;
    triageDetails: string;
    isUrgent: boolean;
    appointmentStatus: APPOINTMENT_STATUS;
    appointmentLabel?: string;
    appointmentUBRNBookingPasswordLockedForEdit: boolean;
    appointmentEditMode?: boolean;
    done?: boolean;
}

const triageStatusLabels = {
    [TRIAGE_STATUS.NONE]: _`None`,
    [TRIAGE_STATUS.GROUP]: _`Group`,
    [TRIAGE_STATUS.TELEPHONE]: _`Telephone`,
    [TRIAGE_STATUS.TELEPHONE60]: _`Telephone 60`,
    [TRIAGE_STATUS.TELEPHONE30]: _`Telephone 30`,
    [TRIAGE_STATUS.VIDEO]: _`Video`,
    [TRIAGE_STATUS.ONE_TO_ONE]: _`One to one`,
    [TRIAGE_STATUS.ONE_TO_ONE60]: _`One to one 60`,
    [TRIAGE_STATUS.ONE_TO_ONE30]: _`One to one 30`,
    [TRIAGE_STATUS.REJECT]: _`Reject`,
};

enum doneFieldOptions {
    TRUE = 'marked',
    FALSE = 'unmarked'
}

const getDefaultSortString: (item: AntenatalWorklistItem, key: string) => string = (item, sortKey) => {
    return item[sortKey];
};

const getAppointmentSortString: (item: AntenatalWorklistItem) => string = (item) => {
    if (item.appointmentStatus === APPOINTMENT_STATUS.AVAILABLE_TO_BOOK && !item.hiddenFromTimeline) {
        return 'Offered';
    }
    return item.appointmentLabel ? item.appointmentLabel : item.appointmentStatus;
};

const getStatusSortString: (item: AntenatalWorklistItem) => string = (item) => {
    return `${item.isUrgent ? 'Urgent' : '0'}${item.isInterpreter ? 'Interpreter' : '0'}`;
};

const getAntenatalWorklistString: (item: AntenatalWorklistItem, key: string) => string = (item, key) => {
    switch (key) {
    case 'appointmentStatus':
        return getAppointmentSortString(item) || '--';
    case 'status':
        return getStatusSortString(item) || '--';
    case 'done':
        return item.done ? 'a' : 'b';
    case 'triageStatus':
        if (item.triageStatus === TRIAGE_STATUS.NONE) {
            return 'a';
        }
        if (item.triageStatus === TRIAGE_STATUS.NOT_AVAILABLE) {
            return 'z';
        }
        // eslint-disable-next-line no-fallthrough
    default:
        return getDefaultSortString(item, key) || '--';
    }
};

const getDateFilterString: (timestamp: number) => string = (timestamp) => {
    const date = convertToDate(timestamp);
    return date && date.isValid() && date.format(standardDateTimeFormats.nhs_date_short) || '--';
};

const getAntenatalWorklistFilterString: (item: AntenatalWorklistItem, key: keyof AntenatalWorklistItem) => string = (item, key) => {
    switch (key) {
    case 'dob':
    case 'selfReferredTimestamp':
        return getDateFilterString(item[key]);
    case 'triageStatus':
        return item[key] === TRIAGE_STATUS.NONE ? 'TRIAGE' : triageStatusLabels[item[key]];
    case 'questionnaireStatus':
        return item.questionnaireStatus === QUESTIONNAIRE_STATUS.COMPLETED ? getAntenatalWorklistString(item, key) : _`Not completed`;
    case 'interpreterDetails':
        return (item.triageDetails || item. interpreterDetails) ? 'DETAILS' : '';
    default:
        return getAntenatalWorklistString(item, key) || '--';
    }
};

const defaultSort = (a, b, sortOrder) => {
    const aIsNull = a == null;
    const bIsNull = b == null;

    if (typeof a === 'string') {
        a = a.toLowerCase();
    }
    if (typeof b === 'string') {
        b = b.toLowerCase();
    }

    if (a === b || (aIsNull && bIsNull)) {
        return 0;
    }

    if (aIsNull && !bIsNull) {
        return sortOrder === SortOrder.ASC ? -1 : 1;
    }

    if (!aIsNull && bIsNull) {
        return sortOrder === SortOrder.DESC ? 1 : -1;
    }

    if (a > b) {
        return sortOrder === SortOrder.DESC ? -1 : 1;
    }

    if (a < b) {
        return sortOrder === SortOrder.DESC ? 1 : -1;
    }
};

const antenatalWorklistSort = (sortKey, sortOrder) => (a, b) => {
    const aString = getAntenatalWorklistString(a, sortKey);
    const bString = getAntenatalWorklistString(b, sortKey);

    return defaultSort(aString, bString, sortOrder);
};


export function AntenatalWorklist({
    items,
    onTriageClick,
    onQuestionnaireClick,
    onAppointmentClick,
    triageDetailsClick,
    referralsMap,
    setItems,
    isLazyFetchingInProcess,
}: {
    items: AntenatalWorklistItem[];
    setItems: (items) => void;
    onTriageClick?: (uuid: string) => void;
    referralsMap?: Map<string, Referral>;
    onQuestionnaireClick?: (uuid: string) => void;
    onAppointmentClick?: (uuid: string) => void;
    triageDetailsClick?: (worklistItem: AntenatalWorklistItem) => void;
    isLazyFetchingInProcess?: boolean;
}) {
    const [sortOrder, setSortOrder] = useState<{ [key: string]: SortOrder }>({ triageStatus: SortOrder.ASC });
    const [filterState, setFilterState] = useState<{ [key: string]: string }>({ done: 'unmarked' });
    const [filteredItems, setFilteredItems] = useState([]);
    const {
        page,
        total: pagesTotal,
        items: paginatedItems,
        onPageChange
    } = usePagination({ items: isLazyFetchingInProcess ? items : filteredItems });

    const customFilterCallbacks = {
        done(filterValue: doneFieldOptions, item: AntenatalWorklistItem) {
            return (filterValue === doneFieldOptions.TRUE && item.done) || (filterValue === doneFieldOptions.FALSE && !item.done);
        },
        nhs(filterValue: string, item: AntenatalWorklistItem) {
            return filterNhs(filterValue, item.nhs);
        }
    };

    useEffect(() => {
        const sortEntries = Object.entries(sortOrder);
        let sortedItems = items;
        if (sortEntries.length > 0) {
            const [sortKey, sortOrder] = sortEntries[0];
            sortedItems = items.sort(antenatalWorklistSort(sortKey, sortOrder));
        }

        const filterEntries: [keyof AntenatalWorklistItem, string][] = (Object.entries(filterState) as any).filter(([, filterString]) => filterString !== '');
        const filteredItems = sortedItems.filter(item => {
            if (!filterEntries.length) {
                return true;
            }

            return filterEntries.every(([filterKey, filterString]) => {
                if (customFilterCallbacks[filterKey]) {
                    return customFilterCallbacks[filterKey](filterString, item);
                }

                const itemString = getAntenatalWorklistFilterString(item, filterKey);
                if (!itemString) {
                    return false;
                }

                return itemString.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
            });
        });
        setFilteredItems(filteredItems);
    }, [filterState, items, sortOrder]);

    const updateItem = (worklistItem: AntenatalWorklistItem) => {
        const newItems = items.map((item) => {
            if (item.uuid === worklistItem.uuid) {
                return worklistItem;
            }
            return item;
        });
        setItems(newItems);
    };

    return (
        <Fragment>
            <Loading show={isLazyFetchingInProcess}>{_`Loading all items...`}</Loading>

            {(!isLazyFetchingInProcess && paginatedItems.length <= 0) &&
            <div className="alert alert-info"><span>There are currently no Antenatal worklists to display</span></div>}

            <Grid onSort={setSortOrder}
                sortOrder={sortOrder}
                onFilter={setFilterState}
                filterState={filterState}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell field="name"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Name'}
                        >Name</GridHeaderCell>
                        <GridHeaderCell field="dob"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Date of birth'}
                        >Date of birth</GridHeaderCell>
                        <GridHeaderCell field="nhs"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'NHS number'}
                        >NHS number</GridHeaderCell>
                        <GridHeaderCell field="hospitalNumber"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Hospital Number'}
                        >Hospital number</GridHeaderCell>
                        <GridHeaderCell field="selfReferredTimestamp"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Self-referred'}
                        >Self-referred</GridHeaderCell>
                        <GridHeaderCell field="questionnaireStatus"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Questionnaire'}
                        >Questionnaire</GridHeaderCell>
                        <GridHeaderCell field="triageStatus"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Triage'}
                        >Triage</GridHeaderCell>
                        <GridHeaderCell field="status"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Status'}
                        >Status</GridHeaderCell>
                        <GridHeaderCell field="interpreterDetails"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Details'}
                        >Details</GridHeaderCell>
                        <GridHeaderCell field="appointmentStatus"
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            title={'Appointment'}
                        >Appointment</GridHeaderCell>
                        <GridHeaderCell
                            field={'done'}
                            sortable={!isLazyFetchingInProcess}
                            filterable={!isLazyFetchingInProcess}
                            filterType={TABLE_FILTER.SELECT}
                            filterOptions={['marked', 'unmarked']}
                            title={'Done'}
                        >Done</GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {paginatedItems.map((item) => (
                        <AntenatalWorklistRow
                            key={item.uuid}
                            worklistItem={item}
                            updateItem={updateItem}
                            onTriageClick={onTriageClick}
                            triageDetailsClick={triageDetailsClick}
                            referral={referralsMap.get(item.uuid)}
                            onQuestionnaireClick={onQuestionnaireClick}
                            onAppointmentClick={onAppointmentClick}
                            isLoading={false}
                        />
                    ))}
                </GridBody>
            </Grid>
            <PaginationView
                currentPage={page}
                pageCount={pagesTotal}
                onChange={onPageChange} />
        </Fragment>
    );

}

function AntenatalWorklistRow({
    worklistItem,
    isLoading,
    onTriageClick,
    updateItem,
    referral,
    triageDetailsClick,
    onQuestionnaireClick,
    onAppointmentClick
}: {
    worklistItem: AntenatalWorklistItem;
    isLoading: boolean;
    referral: Referral;
    updateItem: (item: AntenatalWorklistItem) => void;
    onTriageClick: (uuid: string) => void;
    onQuestionnaireClick: (uuid: string) => void;
    triageDetailsClick: (worklistItem: AntenatalWorklistItem) => void;
    onAppointmentClick: (uuid: string) => void;
}) {

    const [isDone, setIsDone] = useState(worklistItem.done);

    const handleOnChange = (e) => {
        if (worklistItem) {
            const label = e.target.checked ? 'setLabel' : 'unsetLabel';
            const { uuid } = worklistItem;
            try {
                setIsDone(e.target.checked);
                updateItem({
                    ...worklistItem,
                    done: e.target.checked
                });
                terService.createTer({
                    action: label, folderId: referral.folder_id, data: {
                        name: 'Done',
                        type: 'worklist',
                        context: 'antenatal_done',
                        'composition_uuid': uuid
                    }
                });
            } catch (e) {
                setIsDone(!e.target.checked);
            }
        }
    };

    return (
        <GridRow>
            {!!isLoading && <GridCell colSpan={9}>Loading</GridCell>}
            {!isLoading && (
                <Fragment>
                    <GridCell><Link to={worklistItem.patientURL}>{worklistItem.name}</Link></GridCell>
                    <GridCell><DateSpan>{worklistItem.dob || '--'}</DateSpan></GridCell>
                    <GridCell><NhsNumber>{worklistItem.nhs || '--'}</NhsNumber></GridCell>
                    <GridCell>{worklistItem.hospitalNumber || '--'}</GridCell>
                    <GridCell><DateSpan>{worklistItem.selfReferredTimestamp}</DateSpan></GridCell>
                    <GridCell>
                        {worklistItem.questionnaireStatus === QUESTIONNAIRE_STATUS.COMPLETED ? (
                            <button className={'btn btn-default btn-sm'}
                                onClick={() => onQuestionnaireClick(worklistItem.uuid)}>
                                {_`Results`}
                            </button>
                        ) :
                            _(['Not completed'])
                        }
                    </GridCell>
                    <GridCell>
                        {worklistItem.triageStatus === TRIAGE_STATUS.NONE &&
                            <button onClick={() => onTriageClick(worklistItem.uuid)} className={'btn btn-sm btn-success'}>{_`TRIAGE`}</button>
                        }
                        {worklistItem.triageStatus !== TRIAGE_STATUS.NONE
                            && worklistItem.triageStatus !== TRIAGE_STATUS.NOT_AVAILABLE
                            && (
                                <div>
                                    {`${triageStatusLabels[worklistItem.triageStatus]} `}
                                    <i onClick={() => onTriageClick(worklistItem.uuid)} className="fas fa-edit edit-icon" />
                                </div>
                            )}
                    </GridCell>
                    <GridCell>
                        <div className={'status-icons'}>
                            {!!worklistItem.isInterpreter && (
                                <div className="status-icons_icon" title={_`Interpreter required`}>
                                    <i className="fas fa-comment-dots" />
                                </div>
                            )}
                            {!!worklistItem.isUrgent && (
                                <div className="status-icons_icon" title={_`Urgent!`}>
                                    <i className="fas fa-exclamation" />
                                </div>
                            )}
                        </div>
                    </GridCell>
                    <GridCell><DetailsCell worklistItem={worklistItem}
                        triageDetailsClick={triageDetailsClick}/></GridCell>
                    <GridCell>
                        <AppointmentInfo
                            status={worklistItem.appointmentStatus}
                            hiddenFromTimeline={worklistItem.hiddenFromTimeline}
                            label={worklistItem.appointmentLabel}
                            appointment={worklistItem.appointment}
                            appointmentUBRNBookingPasswordLockedForEdit={worklistItem.appointmentUBRNBookingPasswordLockedForEdit}
                            onAppointmentClick={() => onAppointmentClick(worklistItem.uuid)} />
                    </GridCell>
                    <GridCell>
                        <input onChange={handleOnChange} checked={isDone} type="checkbox" />
                    </GridCell>
                </Fragment>
            )}
        </GridRow>
    );
}

export const DetailsCell: FC<{
    worklistItem: AntenatalWorklistItem;
    triageDetailsClick;
}> = (props) => {
    const {
        worklistItem,
        triageDetailsClick,
    } = props;

    if (worklistItem.interpreterDetails || worklistItem.triageDetails) {
        return (
            <button onClick={() => triageDetailsClick(worklistItem)}
                className={'btn btn-sm btn-success'}>{_`DETAILS`}</button>
        );
    }

    return <span>{'--'}</span>;
};

export const AppointmentInfo: FC<{
    status: APPOINTMENT_STATUS;
    label: string;
    appointment: AntenatalWorklistItemAppointment;
    hiddenFromTimeline: boolean;
    onAppointmentClick: () => void;
    appointmentUBRNBookingPasswordLockedForEdit: boolean;
}> = (props) => {
    const {
        status,
        label,
        appointment,
        onAppointmentClick,
        hiddenFromTimeline,
    } = props;

    if (status === APPOINTMENT_STATUS.AVAILABLE_TO_BOOK && hiddenFromTimeline === false) {
        return (
            <Fragment>
                <Link to={`/clinical_portal/folder/${appointment.folderId}/patient/appointments/${appointment.uuid}`}>
                    {appointment.unknownDate === false ? <DateSpan>{appointment.date}</DateSpan> : _`Unknown date`}
                </Link>
            </Fragment>
        );
    }

    if (status === APPOINTMENT_STATUS.BOOKED || status === APPOINTMENT_STATUS.AVAILABLE_TO_BOOK) {
        return (
            <button className={'btn btn-sm btn-default btn-default--blue-link'}
                onClick={onAppointmentClick}
            >{label}</button>
        );
    }

    if (status === APPOINTMENT_STATUS.CANCELLED) {
        return <Fragment>{'Cancelled'}</Fragment>;
    }

    if (status === APPOINTMENT_STATUS.DEPARTED) {
        return <Fragment>{'Attended'}</Fragment>;
    }


    if (status === APPOINTMENT_STATUS.NOT_AVAILABLE) {
        return <Fragment>{'--'}</Fragment>;
    }


    return <Fragment>{label || '--'}</Fragment>;
};
