import React from 'react';
import PropTypes from 'prop-types'

import Header from './header';
import TransportAndInterpreterTable from './table';
import Footer from './footer';

/**
 * Displays the contents of the transport and interpreter worklist.
 * The parent component must supply all the data and handlers.
 *
 * Props (data):
 * - appointments = Required. A sorted array of appointments to display in the worklist table.
 * - demographicsLookup = Required. A map of folder IDs to demographics compositions for looking-up patient identity details. Entries can be omitted if they are still loading. If a folder doesn't have demographics info, it should have an entry with an empty object.
 * - updatingTransportList = Required. An array of appointment UUIDs where the transport arrangements are being updated.
 * - updatingInterpreterList = Required. An array of appointment UUIDs where the interpreter arrangements are being updated.
 * - failedList = Required. An array of appointments UUIDs where an update failed.
 * - loadingInitial = Required. Boolean indicating if the initial page load is in progress. No user actions are possible while the page is loading.
 * - loadingInitialError = Required. Boolean indicating if an error occurred during initial page loading. No user actions are possible if this happens.
 * - loadingMore = Required. Boolean indicating if the page is loading additional items from the worklist. Other parts of the page are still functional while loading more entries.
 * - loadingMoreError = Required. Boolean indicating if an error occurred while loading more entries from the worklist. The remainder of the page should still be functional if this happens.
 * - numItemsLeftToLoad = Required. The number of additional items on the worklist, in addition to what is currently displayed.
 * - hasCompletedItems = Required. Boolean indicating if any of the items on the displayed work list have been completed.
 * 
 * Props (functions):
 * - onArrangedTransport = Required. Callback to be triggered when the user has arranged transport for an appointment. Takes the appointment composition as a parameter.
 * - onArrangedInterpreter = Required. Callback to be triggered when the user has arranged an interpreter for an appointment. Takes the appointment composition as a parameter.
 * - onUndoTransport = Required. Callback to be triggered when the user has cancelled transport arrangements for an appointment. Takes the appointment composition as a parameter.
 * - onUndoInterpreter = Required. Callback to be triggered when the user has cancelled interpreter arrangements for an appointment. Takes the appointment composition as a parameter.
 * - onShowMore = Required. Callback triggered when the user wants to load more entries from the worklist.
 * - onHideCompletedItems = Required. Callback triggered when the user wants to hide completed items from the displayed worklist.
 */
const TransportAndInterpreterWorklist = ({appointments, demographicsLookup, updatingTransportList, updatingInterpreterList, failedList, onArrangedTransport, onArrangedInterpreter, onUndoTransport, onUndoInterpreter, loadingInitial, loadingInitialError, loadingMore, loadingMoreError, numItemsLeftToLoad, hasCompletedItems, onShowMore, onHideCompletedItems}) => {
    return (
        <div>
            <Header />
            <TransportAndInterpreterTable
                appointments=               {appointments}
                demographicsLookup=         {demographicsLookup}
                updatingTransportList=      {updatingTransportList}
                updatingInterpreterList=    {updatingInterpreterList}
                failedList=                 {failedList}
                onArrangedTransport=        {onArrangedTransport}
                onArrangedInterpreter=      {onArrangedInterpreter}
                onUndoTransport=            {onUndoTransport}
                onUndoInterpreter=          {onUndoInterpreter}
            />
            <Footer
                loadingInitial=             {loadingInitial}
                loadingInitialError=        {loadingInitialError}
                loadingMore=                {loadingMore}
                loadingMoreError=           {loadingMoreError}
                numItemsLeftToLoad=         {numItemsLeftToLoad}
                hasCompletedItems=          {hasCompletedItems}
                onShowMore=                 {onShowMore}
                onHideCompletedItems=       {onHideCompletedItems}
            />
        </div>
    );
};

export default TransportAndInterpreterWorklist;

TransportAndInterpreterWorklist.propTypes = {
    appointments:               PropTypes.array.isRequired,
    demographicsLookup:         PropTypes.object.isRequired,
    updatingTransportList:      PropTypes.array.isRequired,
    updatingInterpreterList:    PropTypes.array.isRequired,
    failedList:                 PropTypes.array.isRequired,
    loadingInitial:             PropTypes.bool.isRequired,
    loadingInitialError:        PropTypes.bool.isRequired,
    loadingMore:                PropTypes.bool.isRequired,
    loadingMoreError:           PropTypes.bool.isRequired,
    numItemsLeftToLoad:         PropTypes.number.isRequired,
    hasCompletedItems:          PropTypes.bool.isRequired,

    onArrangedTransport:        PropTypes.func.isRequired,
    onArrangedInterpreter:      PropTypes.func.isRequired,
    onUndoTransport:            PropTypes.func.isRequired,
    onUndoInterpreter:          PropTypes.func.isRequired,
    onShowMore:                 PropTypes.func.isRequired,
    onHideCompletedItems:       PropTypes.func.isRequired
};
