import { ResultsPageState } from './ResultsPage';
import {
    actionAppointmentsHaveReceived,
    actionMeasureHasChanged,
    actionPathwayChanged,
    actionQuestionnaireNameHasChanged,
    actionQuestionnaireResultsUpdated,
    actionReferralCompositionsIsSet,
    actionUserNotesHaveReceived
} from './ResultsPageActions';
import { Composition } from 'models/Composition';
import { Referral } from 'models/compositions/Referral';
import { UserNote } from 'models/compositions/UserNote';
import { Appointment } from 'models/compositions/Appointment';
import { QuestionnaireScoreResults } from 'models/QuestionnaireResult';


export type ResultsPageAction =
    { type: 'PATHWAY_CHANGED'; payload: { pathwayContext: string } } |
    { type: 'QUESTIONNAIRE_CHANGED'; payload: { questionnaireName: string } } |
    { type: 'MEASURE_CHANGED'; payload: { scoreName: string } } |
    { type: 'SET_COMPOSITIONS_REFERRAL'; payload: { compositions: Composition<Referral>[] } } |
    { type: 'SET_COMPOSITIONS_USER_NOTE'; payload: { compositions: Composition<UserNote>[] } } |
    { type: 'SET_COMPOSITIONS_APPOINTMENT'; payload: { compositions: Composition<Appointment>[] } } |
    { type: 'QUESTIONNAIRE_RESULTS_UPDATE'; payload: { questionnaireResults: QuestionnaireScoreResults } };

export const resultsPageReducer = (state: ResultsPageState, action: ResultsPageAction) => {
    let newState = {
        ...state
    };
    switch (action.type) {
    case 'PATHWAY_CHANGED':
        newState = actionPathwayChanged(state, action.payload.pathwayContext);
        break;
    case 'QUESTIONNAIRE_RESULTS_UPDATE':
        newState = actionQuestionnaireResultsUpdated(state, action.payload.questionnaireResults);
        break;
    case 'QUESTIONNAIRE_CHANGED':
        newState = actionQuestionnaireNameHasChanged(state, action.payload.questionnaireName);
        break;
    case 'MEASURE_CHANGED':
        newState = actionMeasureHasChanged(state, action.payload.scoreName);
        break;
    case 'SET_COMPOSITIONS_REFERRAL':
        newState = actionReferralCompositionsIsSet(state, action.payload.compositions);
        break;
    case 'SET_COMPOSITIONS_USER_NOTE':
        newState = actionUserNotesHaveReceived(state, action.payload.compositions);
        break;
    case 'SET_COMPOSITIONS_APPOINTMENT':
        newState = actionAppointmentsHaveReceived(state, action.payload.compositions);
        break;

    default:
        return state;
    }
    return newState;
};
