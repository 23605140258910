import React, { Fragment } from 'react';
import _ from 'services/i18n';
import ErrorView from 'components/errorbox';
import { useEffect } from 'react';
import { useState } from 'react';
import { getQuestionnaires } from 'services/questionnaire-templates.service';
import { TemplateSelect } from 'features/patient/questionnaire/create/AllocateQuestionnaireView';
import { SendQuestionnaireState } from './questionnaireValidator';
import { LozengeContentForm } from 'components/user/lozenge/create/form';
import { PopupContentForm } from 'components/user/popup/create/form';
import { PushNotificationCreateForm } from 'components/user/push-notification/create/form';

const getAllQuestionnaireTemplates = (): Promise<any> => {
    return getQuestionnaires().then((compositions) => {
        return compositions;
    });
};

export interface QuestionnaireCreateFormProps {
    onChange: (c) => unknown;
    form: SendQuestionnaireState;
    errors?: string[];
}

export const QuestionnaireCreateForm = ({ onChange, form, errors }: QuestionnaireCreateFormProps) => {
    const [questionnaireTemplates, setQuestionnaireTemplates] = useState<any[]>([]);

    useEffect(() => {
        getAllQuestionnaireTemplates().then((questionnaires: any[]) => {
            setQuestionnaireTemplates(questionnaires);
        });
    }, []);

    const getQuestionnaireName = (uuid: string): string => {
        const questionnaireTemplate: any = questionnaireTemplates.find((questionnaire) => {            
            return questionnaire.document.uuid === uuid;
        });

        return questionnaireTemplate.document.content.name;
    };
        
    return (
        <form >
            <ErrorView errors={errors} />
            <div className='form-group'>
                <label htmlFor='questionnaireTemplateId'>{_`Questionnaire`}: </label>
                <TemplateSelect
                    questionnaireTemplateId={form.questionnaireId}
                    questionnaireTemplateList={questionnaireTemplates}
                    onChange={(e) => onChange({
                        questionnaireId: e.target.value,
                        questionnaireName: getQuestionnaireName(e.target.value)
                    })} />
            </div>
            <div className="timeline-fields">
                <LozengeContentForm
                    onChange={onChange}
                    lozengeTitle={form.lozengeTitle}
                    lozengeContent={form.lozengeContent} />
                <PopupContentForm
                    onChange={onChange}
                    popupTitle={form.popupTitle}
                    popupContent={form.popupContent} />
            </div>
            <PushNotificationCreateForm 
                sendPush={form.sendPush}
                onChange={onChange}
            />
        </form>
    );
};
