import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
// interfaces
import { FolderBreadcrumbItemProps } from './FolderBreadcrumbs.interface';

const useClickOutside = <T extends HTMLElement>(onClickOutside) => {
    const ref = useRef<T>();

    useEffect(() => {
        function handleMouseDown(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        }

        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, [onClickOutside]);

    return ref;
};

const FolderBreadcrumbItem: FC<FolderBreadcrumbItemProps> = ({
    folder,
    handleLocationChange,
    isCurrent,
}) => {
    const [isFolderOpen, setIsFolderOpen] = useState(false);
    const [isSubfolderMenuOpen, setIsSubfolderMenuOpen] = useState(false);

    const subfolderMenuClasslist = classnames({
        'subfolder-menu': true,
        open: isSubfolderMenuOpen,
    });

    const delimeterClasslist = classnames({
        'subfolder-delimeter': true,
        'show-menu': isCurrent,
    });

    const toggleSubfolderMenu = () => {
        if (isCurrent) {
            setIsSubfolderMenuOpen((prev) => !prev);
        }
    };

    const folderClasslist = classnames({
        'folder-menu': true,
        open: isFolderOpen,
    });

    const handleSelect = (id: number | string) => {
        handleLocationChange(id);
        setIsFolderOpen(false);
        setIsSubfolderMenuOpen(false);
    };

    const toggleFolderMenu = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        ev.stopPropagation();

        setIsFolderOpen(!isFolderOpen);
    };

    const onClickOutsideFolderMenu = useCallback(() => {
        setIsFolderOpen(false);
    }, []);

    const onClickOutsideSubfolderMenu = useCallback(() => {
        setIsSubfolderMenuOpen(false);
    }, []);

    const folderMenuRef = useClickOutside<HTMLUListElement>(
        onClickOutsideFolderMenu,
    );
    const subfolderMenuRef = useClickOutside<HTMLUListElement>(
        onClickOutsideSubfolderMenu,
    );

    const titleClassList = classnames({
        title: true,
        selected: isCurrent,
    });

    return (
        <span className={'folder'}>
            <span ref={folderMenuRef}>
                <span className={titleClassList} onClick={toggleFolderMenu}>
                    {folder.name}
                </span>
                {folder.siblings?.length !== 0 && (
                    <ul className={folderClasslist}>
                        {folder.siblings.map((sibling) => {
                            const optionClassList = classnames({
                                'folder-menu-option': true,
                                active: folder.id === sibling.id,
                            });

                            return (
                                <li
                                    key={sibling.id}
                                    className={optionClassList}
                                    onClick={() => handleSelect(sibling.id)}
                                >
                                    {sibling.name}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </span>

            {folder.children?.length !== 0 && (
                <span ref={subfolderMenuRef} className={'subfolder'}>
                    <span
                        className={delimeterClasslist}
                        onClick={toggleSubfolderMenu}
                    >
                        {'>'}
                    </span>
                    <ul className={subfolderMenuClasslist}>
                        {folder.children.map((sub) => {
                            return (
                                <li
                                    className={'subfolder-option'}
                                    key={sub.id}
                                    onClick={() => handleSelect(sub.id)}
                                >
                                    {sub.name}
                                </li>
                            );
                        })}
                    </ul>
                </span>
            )}
        </span>
    );
};

export default FolderBreadcrumbItem;
