import React, { Component } from 'react';
import PageTitle from '../../../page-title';
import predefinedSmsService from 'services/predefined-sms.service';
import _ from 'services/i18n';
import './predefined-sms.less';
import { EditorTags } from 'common/ui/editor-tags/EditorTags';

const tag = '[Edit predefined SMS templates page]';
export default class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            error: '',
            form: {
                name: '',
                content: ''
            }
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        let title;
        if (this.props.params.smsId === 'new') {
            title = 'Create Predefined SMS Template';
        } else {
            title = 'Edit Predefined SMS Template';
            this.load();
        }

        this.setState({title});
        PageTitle.setTitle(`${_([title])} | PHR Admin Portal`);
    }

    load() {
        predefinedSmsService.getByUuid({uuid: this.props.params.smsId})
            .then((response) => {
                console.log('res', response);
                return response;
            })
            .then(({message: {content: {name, content}}}) => {
                this.setState({
                    form: {
                        name,
                        content
                    }
                });
            })
            .catch((e) => {
                console.error(tag, e);
                this.setState({
                    error: 'Unable to load predefined SMS template. Please try again'
                });
            });
    }

    onChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({form, error: ''});
    }

    onSubmit(e) {
        e.preventDefault();
        if (!this.state.form.name && !this.state.form.content) {
            this.setState({error: 'Please fill all inputs'});
            return;
        }
        let promise;
        if (this.props.params.smsId === 'new') {
            promise = predefinedSmsService.create({content: this.state.form});
        } else {
            promise = predefinedSmsService.update({uuid: this.props.params.smsId, content: this.state.form});
        }
        promise
            .then(() => {
                this.props.router.push('/admin_portal/predefined-sms');
            })
            .catch((err) => {
                console.error(tag, err);
                this.setState({error: 'Unable to create/edit SMS template. Please Try again.'});
            });
    }

    render() {
        return (
            <div className="predefined-sms predefined-sms_edit-page">
                <h3>{_([this.state.title])}</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">{_`Title`}</label>
                        <input type="text"
                               placeholder={_`Enter a title...`}
                               name="name"
                               id="name"
                               className="form-control"
                               value={this.state.form.name}
                               onChange={this.onChange}/>
                    </div>
                    <div className="form-group">
                        <EditorTags callback={(value) => this.onChange({target: {name: 'content', value: `${this.state.form.content} ${value}` }})} />
                        <label htmlFor="content">{_`Content`}</label>
                        <textarea placeholder={_`Enter SMS text...`}
                                  name="content"
                                  id="content"
                                  className="form-control"
                                  value={this.state.form.content}
                                  onChange={this.onChange}/>
                    </div>
                    {this.state.error &&
                    <div className="alert alert-danger">{_([this.state.error])}</div>}
                    <button className="btn btn-primary">{_`Submit`}</button>
                </form>
            </div>
        );
    }
}