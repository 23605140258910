import React, { useEffect } from 'react';
import { useCompositionCollection } from './useCompositionCollection';
import { useCompositions } from 'common/useCompositions';

export function useCompositionList({
    archetype,
    folderId,
    teamId,
}: {
    archetype: string;
    folderId?: number;
    teamId: number;
}): [
    any[],
    {
        isFetching: boolean;
    },
    Map<string, object>,
    any,
]  {
    const [collection, referalListStatus, {
        invalidate,
    }] = useCompositionCollection({
        archetype,
        folderId,
        teamId,
    });

    const [itemMap, referralStatusMap, {
        updateContent,
        invalidateItem,
    }] = useCompositions({
        archetype,
        folderId,
        teamId,
    });

    useEffect(() => {
        collection.forEach((uuid: string) => {
            invalidateItem(uuid);
        });
    }, [collection]);

    const status = {
        isFetching: referalListStatus.isFetching || Array.from(referralStatusMap.values()).some((status: {isFetching: boolean}) => status.isFetching)
    };

    return [
        collection
            .filter((uuid: string) => itemMap.has(uuid))
            .map((uuid: string) => itemMap.get(uuid)),
        status,
        itemMap,
        {
            invalidate,
            updateContent,
            invalidateItem,
        }
    ];
}
