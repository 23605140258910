import moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');
window['moment'] = moment;

import 'msk-timeline/dist/style.css';
import './PatientPathway.less';
import React, { useEffect, useCallback, useRef, useMemo, Fragment } from 'react';
import { browserHistory } from 'react-router';
import marked from 'marked';
import PageTitle from 'page-title';
import { useTimeline } from './useTimeline';
import { Timeline, TimelineContext, TimelineTranslations } from 'msk-timeline';
import { useCurrentTeam } from 'common/useCurrentTeam';
import { useI18N } from 'common/i18n/useI18N';
import { useAPI } from 'common/useAPI';

export const PatientPathway = ({
    params,
    patientFolderId
}) => {
    const folderId = params.folderId ? params.folderId : patientFolderId;
    const baseRoute = `/clinical_portal/folder/${folderId}/patient`;

    useEffect(() => {
        PageTitle.setTitle('Patient | Pathway | MyPathway Clinical Portal');
    }, []);

    const team = useCurrentTeam();
    const { version } = useAPI();

    const _ = useI18N();

    const {
        timeline,
        fetchItemByIndex,
    } = useTimeline({
        folderId: folderId,
        teamId: team?.teamId,
    });

    const container = useRef(undefined);

    const scrollToOffset = useCallback(
        (scrollTop) => {
            if (!container.current) { return; }
            if (typeof container.current.scrollTo === 'function') {
                container.current.scrollTo(0, scrollTop);
            } else {
                container.current.scrollTop = scrollTop;
            }
        },
        [],
    );

    const scroll$ = useMemo(() => {
        return {
            scrollSubscribers: [],
            next: (scrollTop) => {
                for (const subscriber of scroll$.scrollSubscribers) {
                    if (subscriber.__timeout) { continue; }
                    subscriber.__timeout = setTimeout(() => {
                        if (!scroll$.scrollSubscribers.find(sub => subscriber === sub)) { return; }
                        subscriber(scrollTop);
                        subscriber.__timeout = undefined;
                    });
                }
            },
            subscribe: (cb) => {
                const unsubscribe = () => {
                    scroll$.scrollSubscribers = scroll$.scrollSubscribers.filter(subscriber => subscriber !== cb);
                };
                scroll$.scrollSubscribers = [...scroll$.scrollSubscribers, cb];
                return unsubscribe;
            }
        };
    }, []);

    const handleTimelineElementClick = (timelineElement) => {
        if (timelineElement.timelineType === 'appointment') {
            browserHistory.push(`${baseRoute}/appointments/${timelineElement.document.uuid}?back=pathway`);
        }

        if (timelineElement.timelineType === 'questionnaire') {
            const query = timelineElement.document.document_type === 'questionnaireResponse' ? 'questionnaire' : 'legacy';
            browserHistory.push(`${baseRoute}/questionnaires/${timelineElement.document.uuid}?${query}&back=pathway`);
        }

        if (timelineElement.timelineType === 'referral') {
            browserHistory.push(`${baseRoute}/referrals?back=pathway`);
        }

        if (timelineElement.timelineType === 'message') {
            browserHistory.push(`${baseRoute}/messages/${timelineElement.document.uuid}?back=pathway`);
        }
    };

    return (
        <Fragment>
            <div>
                A quick snapshot of what this patient can see in their app
                List of referrals, appointment, PROMs etc ...
            </div>
            <br/>
            <div
                className="patient-pathway-timeline"
                ref={container}
                onScroll={() => {
                    if (!container.current) { return; }
                    scroll$.next(container.current.scrollTop);
                }}
            >

                <div className="patient-pathway-timeline__background-line"></div>
                <TimelineContext.Provider value={{
                    currentLanguage: 'en',
                    getMarkedHTML: (markdown) => {
                        if (!markdown) {
                            return {__html: ''};
                        }
                        return {__html: marked(markdown)};
                    },
                    getTranslate: (str) => _`${TimelineTranslations[str] ? TimelineTranslations[str] : str}`,
                    onTimelineElementClick: handleTimelineElementClick,
                    scroll$
                }}>
                    <Timeline
                        timeline={timeline}
                        fetchItemByIndex={fetchItemByIndex}
                        scrollToOffset={scrollToOffset}
                    ></Timeline>
                </TimelineContext.Provider>
            </div>
        </Fragment>
    );
};
