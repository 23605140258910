import React, { FunctionComponent, useCallback, useRef } from 'react';
import './Checkbox.less';

interface CheckboxProps {
    className?: string;
    name?: string;
    title?: string;
    onChange: (e: {target: {name: string; value: boolean}}) => void;
    value: boolean;
}

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
    const {
        className,
        name,
        title,
        onChange,
        value,
        children,
    } = props;

    const inputRef = useRef(null);

    const onChangeCallback = useCallback((e) => {
        e.target.value = e.target.checked;
        onChange({target:{value: e.target.checked, name}});
    }, []);

    const handleKeyUp = useCallback((e) => {
        switch (e.key) {
        case 'Enter':
        case ' ':
            inputRef.current.click();
        }
    }, []);

    return <div tabIndex={1} onKeyUp={handleKeyUp} className={'ui-checkbox' + ` ${value ? 'ui-checkbox__checked' : ''}` + ` ${className || ''}`} title={title}>
        <label>
            <input type="checkbox"
                name={name}
                ref={inputRef}
                checked={value}
                onChange={onChangeCallback}
            />
            {value ? <i className="far fa-check-square" /> : <i className="far fa-square" />}
        </label>
        {!!children && <div className="ui-checkbox__content">{children}</div>}
    </div>;
};
