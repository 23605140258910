import Immutable from 'immutable';
import { AUTH } from 'actions/types';

const reducer = (state = Immutable.Map({
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true'
}), action) => {
    switch (action.type) {
        case AUTH.AUTH_SUCCESSFUL:
            return state.merge({
                isAuthenticated: true
            });
        case AUTH.AUTH_FAILED:
        case AUTH.LOGOUT_SUCCESSFUL:
            return state.merge({
                isAuthenticated: false
            });
        default:
            return state;
    }
};

export default reducer;