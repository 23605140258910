import React, { Fragment, useRef, useState, useEffect } from 'react';
import _ from 'services/i18n';
import PageTitle from 'page-title';
import { useCurrentTeam } from 'common/useCurrentTeam';
import { AntenatalWorklist } from './antenatal-worklist/AntenatalWorklist';
import { useAntenatalWorklistCollection } from './useAntenatalWorklist';
import { Loading, LoadingError } from 'common/ui/alert-boxes';
import {
    getAppointmentBookingPasswordAndUBRNFromComposition,
    getPatientBannerInfo,
    isAppointmentLockedForEdit
} from './antenatalHelpers';
import { AntenatalAppointmentModal } from './AntenatalAppointmentModal';
import { browserHistory } from 'react-router';
import { questionnaireService } from 'services/questionnaire.service';
import DataStore from 'services/data-store';
import Modal from 'common/ui/modal';
import { PatientBanner } from 'features/patient/patient-banner/PatientBanner';
import { AntenatalTriageDecisionForm } from 'features/antenatal/triage-decision-form/AntenatalTriageDecisionForm';
import { ReferralsProvider } from '../ReferralsProvider';
import { QuestionnaireComponent } from 'features/patient/questionnaire/questionnaire-view/QuestionnaireComponent';
import { Appointment } from 'models/compositions/Appointment';
import { QuestionnaireArchetype } from 'models/QuestionnaireData';
import { TriageDetailsModal } from 'features/antenatal/TriageDetailsModal';

export interface Referral {
    team_id: number;
    folder_id: number;
    content: {
        labels: Array<{ context: string; name: string; status: boolean; type: string }>;
    };
}

export function AntenatalWorklistPage() {
    const team = useCurrentTeam();

    useEffect(() => {
        PageTitle.setTitle(_`Antenatal Worklist | MyPathway Clinical Portal`);
    }, []);

    if (!team || !team.teamId) {
        return null;
    }

    return (
        <div className='worklist-tab'>
            <AntenatalWorklistPageWithTeam teamId={team.teamId}/>
        </div>
    );
}


export function AntenatalWorklistPageWithTeam({
    teamId
}) {
    const tag = '[AntenatalWorklistPageWithTeam]';
    const antenatalCollection = useAntenatalWorklistCollection({ teamId });

    const [
        antenatalWorklistItems,
        {
            isFetching,
            isLazyFetching,
            isError
        },
        {
            referralsMap,
            appointmentsMap,
            demographicsMap,
            questionnaireResponsesMap,
            questionnairesMap,
            questionnaireScoringMap,
            questionnaireScoresMap,
        },
        {
            updateAppointment,
            updateReferral,
        }
    ] = antenatalCollection;



    const [appointmentModalState, setAppointmentModalState] = useState(null);
    const [triageDetailsModalState, setTriageDetailsModalState] = useState(null);
    const [questionnaireModalState, setQuestionnaireModalState] = useState(null);
    const [triageDecisionModalState, setTriageDecisionModalState] = useState(null);

    const [items, setItems] = useState([]);

    const triageDecisionSubmitRef = useRef(null);


    useEffect(() => {
        const newItems = antenatalWorklistItems.map((item) => {
            const { content } = referralsMap.get(item.uuid);
            const appointment = appointmentsMap.get(item.uuid);
            const { labels = [] } = content;
            const label = labels.find(item => item.context === 'antenatal_done') || { status: false };
            item.done = label.status;
            item.hiddenFromTimeline = appointment.content.hidden_from_timeline;
            item.appointment = {
                uuid: appointment.uuid,
                date: appointment?.content?.date,
                folderId: appointment?.folder?.folder_id,
                unknownDate: appointment?.content?.unknown_date,
            };
            return item;
        });
        setItems(newItems);
    }, [antenatalWorklistItems, referralsMap, appointmentsMap]);

    if (isFetching) {
        return <Loading show={isFetching}/>;
    }

    if (isError) {
        return <LoadingError show={isError}/>;
    }

    const handleAppointmentClick = (referralId: string) => {
        const appointment = appointmentsMap.get(referralId);

        if (!appointment) {
            return;
        }

        if (isAppointmentLockedForEdit(appointment)) {
            browserHistory.push(`/clinical_portal/folder/${appointment.folder_id}/patient/appointments/${appointment.uuid}`);
            return;
        }

        const submitHandler = async ({ bookingPassword, ubrn }) => {
            const appointment = appointmentsMap.get(referralId);
            const { content } = appointment;

            if (content.booking_password === bookingPassword && content.ubrn === ubrn) {
                setAppointmentModalState(null);
                return;
            }

            try {
                setAppointmentModalState({
                    ...appointmentModalState,
                    busy: true
                });
                await updateAppointment(referralId, {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    booking_password: bookingPassword,
                    ubrn,
                }, {
                    isPartial: true,
                    isPushToServer: true,
                });
            } catch (e) {
                console.error(tag, 'Failed to update appointment', e);
            }
            setAppointmentModalState(null);
        };

        const { ubrn, bookingPassword } = getAppointmentBookingPasswordAndUBRNFromComposition(appointment);
        setAppointmentModalState({
            ubrn,
            bookingPassword,
            patient: getPatientBannerInfo(demographicsMap.get(referralId)),
            onSubmit: submitHandler
        });
    };

    const handleTriageClick = (referralId: string) => {
        setTriageDecisionModalState({
            initialData: { appointment: appointmentsMap.get(referralId), referral: referralsMap.get(referralId) },
            onSubmit: async (state) => {
                const { isInterpreterRequired, isUrgent, interpreterDetails, details, triageDecision } = state;

                const appointmentContent: Appointment = {} as any;
                appointmentContent.interpreter = {
                    ...(appointmentContent.interpreter || {}),
                    required: isInterpreterRequired.value,
                };

                if (interpreterDetails.value) {
                    appointmentContent.interpreter.details = interpreterDetails.value;
                }

                if (details.value) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    appointmentContent.triage_decision_notes = details.value;
                }

                try {
                    setTriageDecisionModalState({
                        ...triageDecisionModalState,
                        busy: true
                    });
                    await updateAppointment(referralId, appointmentContent, {
                        isPushToServer: true,
                        isPartial: true,
                    });
                    await updateReferral(referralId, {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        triage_decision: triageDecision.value,
                        priority: isUrgent.value ? 'Urgent' : 'Routine'
                    }, {
                        isPushToServer: true,
                        isPartial: true,
                    });
                } catch (e) {
                    console.error(tag, 'Failed to update appointment & referral', e);
                }
                setTriageDecisionModalState(null);
            },
            patient: getPatientBannerInfo(demographicsMap.get(referralId))
        });
    };

    const handleQuestionnaireClick = async (referralId: string) => {
        const questionnaire = questionnairesMap.get(referralId);
        const response = questionnaireResponsesMap.get(referralId);
        const scores = questionnaireScoresMap.get(referralId);
        const scoring = questionnaireScoringMap.get(referralId);
        const role = DataStore.get('role');

        const data = questionnaireService.arrangeQuestionnaireData({
            questionnaire,
            response,
            scoring,
            scores,
            role,
            archetype: QuestionnaireArchetype.questionnaire
        });

        setQuestionnaireModalState({
            folderId: response.folder_id,
            questionnaireData: data,
            name: data.name,
            patient: getPatientBannerInfo(demographicsMap.get(referralId))
        });
    };

    const handleDetailsClick = (worklistItem) => {
        setTriageDetailsModalState(worklistItem);
    };

    const closeDetailsClick = () => {
        setTriageDetailsModalState(null);
    };

    return (
        <Fragment>
            <AntenatalWorklist
                items={items}
                isLazyFetchingInProcess={isLazyFetching}
                referralsMap={referralsMap}
                setItems={setItems}
                onTriageClick={handleTriageClick}
                triageDetailsClick={handleDetailsClick}
                onAppointmentClick={handleAppointmentClick}
                onQuestionnaireClick={handleQuestionnaireClick}
            />
            {!!appointmentModalState && (
                <AntenatalAppointmentModal
                    ubrn={appointmentModalState.ubrn}
                    bookingPassword={appointmentModalState.bookingPassword}
                    isReadOnly={appointmentModalState.isReadOnly}
                    onSubmit={appointmentModalState.onSubmit}
                    busy={appointmentModalState.busy}
                    onClose={() => setAppointmentModalState(null)}
                    patientBanner={() => appointmentModalState.patient ? <PatientBanner {...appointmentModalState.patient}/> : null}
                />
            )
            }
            {!!triageDetailsModalState && (
                <TriageDetailsModal
                    onClose={closeDetailsClick}
                    worklistItem={...triageDetailsModalState}
                />
            )
            }
            {!!questionnaireModalState && (
                <Modal
                    size={'lg'}
                    title={questionnaireModalState.name}
                    onClose={() => setQuestionnaireModalState(null)}
                    buttons={[
                        { label: 'Close', callback: () => setQuestionnaireModalState(null) },
                        { label: 'Print', callback: () => window.print() },
                    ]}
                    patientBanner={() => <PatientBanner {...questionnaireModalState.patient}/>}
                >
                    <ReferralsProvider
                        folderId={questionnaireModalState.folderId}
                        teamId={teamId}
                    >
                        <QuestionnaireComponent
                            loading={false}
                            isUpdating={false}
                            showHeader={false}
                            folderId={questionnaireModalState.folderId}
                            isEditMode={false}
                            questionnaireData={questionnaireModalState.questionnaireData}
                        />
                    </ReferralsProvider>
                </Modal>
            )
            }
            {!!triageDecisionModalState && (
                <Modal
                    size={'md'}
                    patientBanner={() => triageDecisionModalState.patient ? <PatientBanner {...triageDecisionModalState.patient}/> : null}
                    buttons={[
                        {
                            type: 'callback',
                            callback: () => triageDecisionSubmitRef.current(),
                            label: 'Submit'
                        }
                    ]}
                    busy={triageDecisionModalState.busy}
                    onClose={() => setTriageDecisionModalState(null)}
                    title={'Submit Triage Decision'}
                >
                    <AntenatalTriageDecisionForm
                        onSubmitRef={triageDecisionSubmitRef}
                        onSubmitForm={triageDecisionModalState.onSubmit}
                        initialData={triageDecisionModalState.initialData}
                    />
                </Modal>
            )
            }
        </Fragment>
    );
}
