import React from 'react';
import BasePage from 'components/page/base';
import { Link } from 'react-router';
import _ from 'services/i18n';
import QuestionnairesList from 'features/patient/questionnaire/list';
import { teamPreferencesService } from 'services/team-preferences.service';
import DataStore from 'services/data-store';
import { TopBar } from 'features/patient/top-bar/TopBar';

export default class QuestionnaireListPage extends BasePage {
    constructor (props) {
        super(props);
        this.state = {
            teamService: (DataStore.get('me.currentRole') || {}).serviceName || '',
            teamPreferences: teamPreferencesService.defaultProperties
        };

        this.baseRoute = `/clinical_portal/folder/${this.props.folderId}/patient/questionnaires`;
    }

    componentDidMount() {
        teamPreferencesService.getFirst({})
            .then((teamPreferences) => {
                this.setState({ teamPreferences });
            });
    }

    pageTitle () {
        return _`Listing ${this.$p()} Questionnaires | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="page questionnaires-page questionnaires-page--new">
                {!this.state.teamPreferences.hide_allocate_questionnaire && (
                    <TopBar
                        folderId={this.props.folderId}
                        teamId={this.props.teamId}
                    >
                        {this.state.teamPreferences.show_compare_eq5d && (
                            <Link to={`${this.baseRoute}/compare-eq5d?back=patient-questionnaires`}>
                                Compare EQ5Ds
                            </Link>
                        )}
                        {!this.state.teamPreferences.hide_allocate_questionnaire && (
                            <Link to={`${this.baseRoute}/create`}>
                                {_`Allocate Questionnaire`}
                            </Link>
                        )}
                        {!this.state.teamPreferences.hide_allocate_questionnaire && (
                            <Link to={`${this.baseRoute}/create-simple`}>
                                {_`Send Simple Questionnaire`}
                            </Link>
                        )}
                    </TopBar>
                )}
                <QuestionnairesList role={DataStore.get('role')} service={this.state.teamService} {...this.props} />
            </div>
        );
    }
}
