import { apiV2Service } from 'services/api-v2.service';
import { DataStore } from 'services/data-store';
import { FolderId } from './folder.service';

export interface Role {
    uuid: string;
    teamId: string | number;
    team: string;
    organisation: string;
    organisationId: string;
    name: TrueRoleTypes;
    serviceName: string;
}

export interface IRoleDefinitionRequestProps {
    role?: Role;
    type?: TrueRoleTypes[];
    folderId?: FolderId;
}

export interface IRolePrivileges {
    [privilegeName: string]: boolean;
}

export interface RoleDefinition {
    folder_groups: any[];
    name: string;
    uuid: string;
    privileges: IRolePrivileges;
}

export interface RoleDefinitionMap {
    [uuid: string]: RoleDefinition;
}

export type RoleTypes = 'patient' | 'clinician' | 'staff' | 'bot' | 'teamAdmin';
export type TrueRoleTypes = 'Patient' | 'Clinician' | 'General staff' | 'Team admin' | 'Bot' | 'UnsubscribeBot' | 'RegBot';

interface IGetRolesParams {
    type?: RoleTypes[];
}

class RolesService {
    private static _instance: RolesService;
    constructor() {
        if(RolesService._instance) {
            return RolesService._instance;
        }
        RolesService._instance = this;
    }

    get roleNames(): {[key: string]: string} {
        return Object.freeze({
            patient: 'Patient',
            staff: 'General staff',
            clinician: 'Clinician',
            teamAdmin: 'Team admin',
            bot: 'Bot'
        });
    }

    public getRoles({ type }: IGetRolesParams): Promise<Array<Role>> {
        return apiV2Service.getCurrentUserRoles()
            .then((response) => {
                const roles = (response.message.results as Array<any> || []).map(role => {
                    return {
                        uuid: role.uuid,
                        teamId: role.team_id,
                        team: role.team,
                        organisation: role.organisation,
                        organisationId: role.organisation_id,
                        name: role.name,
                        serviceName: role.service_name
                    } as Role;
                });
                if (!type || !type.length){
                    return roles;
                }
                const roleNames = this.roleNames;
                return roles.filter((role) => {
                    return Boolean(type.find((roleType) => {
                        return role.name === roleNames[roleType];
                    }));
                });
            });
    }

    public getRoleDefinitions(
        { role, type, folderId }: IRoleDefinitionRequestProps = {}
    ): Promise<RoleDefinition[]> {
        role = role || DataStore.get('me.currentRole');
        return apiV2Service.getDocuments({
            archetypeName: 'role-definition',
            folderId,
            role,
            limit: 100
        })
            .then((response: any) => {
                let roles: RoleDefinition[] = response.message.results
                    .map(({ content, uuid }: { content: object; uuid: string }) => Object.assign(content, { uuid }));
                if (type) {
                    roles = roles.filter(({ name }) => type.includes(name as any));
                }
                return roles || [];
            });
    }

    public getRoleDefinitionsMap(params: IRoleDefinitionRequestProps): Promise<RoleDefinitionMap> {
        return this.getRoleDefinitions(params)
            .then((roleDefinitions: RoleDefinition[]) => {
                return roleDefinitions.reduce<RoleDefinitionMap>((acc: RoleDefinitionMap, roleDefinition: RoleDefinition) => {
                    return { ...acc, ...{ [roleDefinition.uuid]: roleDefinition } };
                }, {});
            });
    }

    public getRoleDefinitionUuid({ role, roleName }: {role?: Role; roleName: string}): Promise<string> {
        role = role || DataStore.get('me.currentRole');
        return this.getRoleDefinitions({ role })
            .then((roles) => roles.find((role) => role.name === roleName).uuid);
    }
}

export const rolesService = new RolesService();
