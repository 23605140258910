import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { DateTime } from 'common/datetime/DateTime';
/**
 * Displays the contents of a table cell containing an appointment date and time.
 * This is part of a row of the transport and interpreter worklist.
 *
 * Props (data):
 * - appointment = Required. The appointment to extract the date and time from.
 */
const DateTimeField = ({appointment}) => {
    const timestamp = parseInt(lodash.get(appointment, 'content.date', '0'));
    if (!timestamp) {
        return (<span>(unknown)</span>);
    }

    return (
        <div>
            <div><DateTime format="nhs_date_short">{timestamp}</DateTime></div>
            <div><DateTime format="nhs_time_short">{timestamp}</DateTime></div>
        </div>
    );
};

export default DateTimeField;

DateTimeField.propTypes = {
    appointment: PropTypes.object.isRequired
};
