import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import Modal from 'ui/modal';
import identify from 'services/identify';
import ServerFetch from 'server-fetch';
import { browserHistory } from 'react-router';
import DataStore from 'services/data-store';

/**
 * Extended Page Component for user_portal/login
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            token: this.props.location.query.token || '',
            message: 'Please wait while we transfer you'
        };

        ServerFetch.setPortal('coop');
    }

    pageTitle ()  {
        return _`COOP Login`;
    }

    componentDidMount () {
        var details = identify();

        this.server.postRequest('/coopweb-transfer', {
            token: this.state.token,
            model: details.browser + ' ' + details.browserVersion,
            os: details.os + ' ' + details.osVersion
        }, (response) => {
            if (response.hasOwnProperty("status") && response.status == 'error') {
                this.setState({
                    message: response.message
                });
            }

            DataStore.set('coop-app-type','mobile');

            if (response.hasOwnProperty("status") && response.status == 'OK') {
                window.getLoggedInUser = function() {
                    return true;
                };

                browserHistory.push('/coopweb');
            }
        });
    }

    render () {
        return (
            <div className="transfer-page">
                <Modal title="Transferring you please wait">
                    <p>{this.state.message}</p>
                </Modal>
            </div>
        )
    }
}
