import { CompositionService } from './composition.service';
import { ISearchDocumentsParams } from 'phr-api-client';

class FeedbackService extends CompositionService {

    get archetypeName(): string {
        return 'userFeedback';
    }

    // TODO: remove when list method is ready for all-folders alias
    public list({role, folderId, sort, limit = 100, offset = 0}: any): Promise<any> {
        const search: ISearchDocumentsParams = {uuid: {$ne: 0}};
        return this.search({search, role, folderId, sort, limit, offset} as any);
    }
}

export const feedbackService = new FeedbackService();
