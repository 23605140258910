import { useState, useEffect } from 'react';
import ServerFetch from 'server-fetch';
import DataStore from 'services/data-store';
import { useAPI } from 'common/useAPI';

export function useCurrentOrgs(portal = 'clinical_portal') {
    const [currentOrgs, setCurrentOrgs] = useState(undefined);
    const [isError, setIsError] = useState(false);
    const { version } = useAPI();

    const apiURL = version === 1 ? `/api/${portal}/organisations` : '/me/organisations';

    useEffect(() => {
        const listenIdx = DataStore.getListener('me.organisations',
            (orgs) => {
                if (isError) { return; }

                if (!orgs) {
                    return ServerFetch.getRequest(this, apiURL,
                        (response) => {
                            DataStore.set('me.organisations', response.message.results);
                        },
                        () => {
                            console.error('Error when fetching organisations')
                            setIsError(true);
                        });
                }
                setCurrentOrgs(orgs);
            });

        const orgs = DataStore.get('me.organisations') || [];
        setCurrentOrgs(orgs);

        return () => {
            DataStore.removeListener(listenIdx);
        };
    }, [portal]);

    return currentOrgs;
}
