import React, { useEffect, useState } from 'react';
import faviconService from 'services/favicon.service.js';
import _ from 'services/i18n';

import Footer from 'layouts/template/_footer';
import { ModalPortal } from 'common/modal/ModalPortal';
import { NewNavigation } from 'layouts/clinical_portal/NewNavigation';
import { TeamPreferences } from 'common/TeamPreferences';
import { DepartmentContextProvider } from 'features/department/department.context';
import { LocationsContextProvider } from 'features/locations/locations.context';

export function ClinicalPortalTemplateWithTeam({
    team,
    location,
    children,
    onTeamClick,
}) {
    const [sidebarCollapse, setSidebarCollapse] = useState(false);

    useEffect(() => {
        if (!team) {
            return;
        }

        if (team.teamService === 'MSK') {
            return faviconService.setPathwayIcon();
        }

        return faviconService.setDefaultIcon();
    }, [team]);

    const mainContainerClass = 'container';
    const mainpageClasses = ['main-page--clinical-new'];

    if (sidebarCollapse) {
        mainpageClasses.push('expand-main-page');
    }

    const mainpageClass = mainpageClasses.join(' ');

    return (
        <TeamPreferences team={team}>
            <DepartmentContextProvider>
                <LocationsContextProvider>
                    <div className="page-content">
                        <NewNavigation
                            title="MyPathway"
                            location={location}
                            teamName={team.teamName}
                            onTeamClick={onTeamClick}
                        />
                        <div
                            className={`${mainContainerClass} hint-clinical_portal`}
                        >
                            <div className={`main-page ${mainpageClass}`}>
                                {children}
                            </div>

                            <div style={{ clear: 'both' }} />
                            <Footer />
                        </div>
                    </div>
                </LocationsContextProvider>
            </DepartmentContextProvider>
        </TeamPreferences>
    );
}
