import React from 'react';
import BasePage from 'components/page/base';

import {ResponsiveTable, Row, Column, ReferrerSummary} from 'ui';
import _ from 'services/i18n';
import HeadingDoc from 'ui/heading-doc';
import Events from './_events.js';
import FormPanel from 'components/form-panel';
import DateInputField from 'components/form-panel/date-inputs/date-input-field';

export default class extends BasePage {
    constructor (props) {
        super(props);
        let from = new Date();

        from.setMonth(from.getMonth()-1);

        this.state = {
            loading: false,
            results: [],
            notRun: true,
            fromDate: from,
            toDate: new Date(),
            formKey: 1,
            alertText: false
        };

        this.bindEvents(['Submit', 'Reset', 'DownloadCSV'], new Events);
    }

    pageTitle () {
        return _`Referrals | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="report-tab">
                <HeadingDoc title="Referrals">
                    {_`The report below will display how many ${this.$ps()} have been referred by each GP practice between the dates specified.`}
                </HeadingDoc>
                <Page {...this.state} events={this.events}/>
            </div>
        );
    }
}

const Page = (props) => {
    return (
        <div>
            <ReportForm {...props} />
            <Results {...props} />
        </div>
    );
};

const ReportForm = (props) => {
    let alertType, alertText;

    if (props.loading) {
        alertType = 'info';
        alertText = _`Loading. Please wait...`;
    }

    if (props.alertText) {
        alertType = 'danger';
        alertText = props.alertText;
    }

    return (
        <div className="report-form">
            <FormPanel
                key={props.formKey}
                title={_`Refine Report`}
                intro={_`Please enter the date period`}
                id='report-form'
                onSubmit={props.events.onSubmit}
                onReset={props.events.onReset}
                submitLabel={_`Run Report`}
                resetLabel={_`Discard Changes`}
                busy={props.loading}
                alertText={alertText}
                alertType={alertType}>

                <DateInputField
                    label={_`From Date:`}
                    id='report-from-date'
                    initialValue={props.fromDate}
                    pastOnly={true}
                />

                <DateInputField
                    label={_`To Date:`}
                    id='report-to-date'
                    initialValue={props.toDate}
                    pastOnly={true}
                />

            </FormPanel>
        </div>
    );
};

const Results = (props) => {
    if (props.notRun) {
        return (
            <div>
                <div className="alert alert-info">
                    <span>{_`Please select a date range and run search above to view the results.`}</span>
                </div>
            </div>
        );
    }

    if (props.results.length == 0) {
        return (
            <div>
                <div className="alert alert-info">
                    <span>{_`No results have been returned for this time period. You may need to increase the time period to see results.`}</span>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="form-group">
                <button type="button" className="btn btn-success" onClick={props.events.onDownloadCSV}>{_`Download as CSV`}</button>
            </div>
            <ResponsiveTable>
                <thead>
                <tr>
                    <th>{_`Type`}</th>
                    <th>{_`Code`}</th>
                    <th>{_`Name`}</th>
                    <th>{_`Number of referrals`}</th>
                </tr>
                </thead>
                <ResultRows {...props} />
            </ResponsiveTable>
        </div>
    );
};

const ResultRows = (props) => {
    let list = props.results.map((item, idx) => {
        return (
            <ResultRow key={idx} {...item} />
        );
    });

    return (
        <tbody>
        {list}
        </tbody>
    );
};

const ResultRow = (props) => {

    let name = props.referrer_name || (<span className='text-muted'>{_`Unknown`}</span>);

    // Make the referrer type more user-friendly if possible.
    let type;
    if (props.referrer_type == 'gp_practice') {
        type = _`GP Practice`;
    } else {
        type = props.referrer_type;
    }

    return (
        <tr>
            <td>{type}</td>
            <td>{props.referrer_code}</td>
            <td>{name}</td>
            <td>{props.count}</td>
        </tr>
    );
};
