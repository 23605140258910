import React, { Fragment, FunctionComponent } from 'react';
import { useI18N } from 'common/i18n/useI18N';
import { DateTime } from 'common/datetime/DateTime';
import { Label } from 'models/Label';

interface DetailsProps {
    labels: Label[];
    folderId: number;
    status: string;
    dueBy: string;
    nameOfIssuer: string;
    createdAt: string;
    completedAt: string | number;
    completedBy: string;
    lastUpdated: string;
    pathwayNamesWithEvent: [string, string][];
}

export const QuestionnaireDetails: FunctionComponent<DetailsProps> = (props) => {
    const { pathwayNamesWithEvent } = props;
    const _ = useI18N();
    const pathways = pathwayNamesWithEvent.map(([name]) => name).join(', ');
    const clinicalEvents = pathwayNamesWithEvent.map(([,event]) => event).join(', ');

    let whoCompletedQuestionnaire = null;

    switch (props.completedBy) {
    case null:
        whoCompletedQuestionnaire = 'Clinician';
        break;
    case undefined:
        whoCompletedQuestionnaire = '';
        break;
    default:
        whoCompletedQuestionnaire = props.completedBy;
    }

    return (
        <div className="table-responsive">
            <table className='table table-bordered table-striped'>
                <tbody>
                    <tr>
                        <th>{_`Status`}</th>
                        <td>
                            {props.status}
                        </td>
                        <th>{_`Response Due By`}</th>
                        <td>{props.dueBy ?
                            <DateTime format={'nhs_date_short'}>{props.dueBy}</DateTime> : _`Not Specified`}
                        </td>
                    </tr>
                    <tr>
                        <th>{_`Issued By`}</th>
                        <td>{props.nameOfIssuer}</td>
                        <th>{_`Completed By`}</th>
                        {props.status === 'scored' || props.status === 'complete' ?
                            <td>{whoCompletedQuestionnaire}</td> :
                            <td> - </td>
                        }
                    </tr>
                    <tr>
                        <th>{_`Issued On`}</th>
                        <td><DateTime>{props.createdAt}</DateTime></td>
                        <th>{_`Completed On`}</th>
                        <td>
                            <DateTime empty={'-'}>
                                {props.completedAt}
                            </DateTime>
                        </td>

                    </tr>
                    <tr>
                        <th>{_`Pathway`}</th>
                        <td>{pathways}</td>
                        <Fragment>
                            <th>{_`Updated On`}</th>
                            <td><DateTime>{props.lastUpdated}</DateTime></td>
                        </Fragment>
                    </tr>
                    <tr>
                        {clinicalEvents ?
                            (
                                <Fragment>
                                    <th>{_`Clinical Event`}</th>
                                    <td>{clinicalEvents}</td>
                                </Fragment>
                            )
                            : <Fragment></Fragment>}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
