import React from 'react';
import ServerFetch from 'server-fetch';
import DataStore from 'services/data-store';
import NavigationHelper from 'services/navigation-helper';
import _ from 'services/i18n';
import lodash from 'lodash';

let currentFolderId, currentTeamId, folderToUser, folderToInvite, currentTeam, currentOrg, currentOrgId, currentUser;

DataStore.getListener('currentFolder', (value) => {
    currentFolderId = value || 0;
});

DataStore.getListener('me.currentRole', (value) => {
    currentTeam = value || {};
    currentTeamId = currentTeam.teamId || 0;
});

DataStore.getListener('me.currentOrg', (value) => {
    currentOrg = value || {};
    currentOrgId = currentOrg.id || 0;
});

DataStore.getListener('me.folderLookup', (value) => {
    folderToUser = value;
});

DataStore.getListener('me.folderInviteLookup', (value) => {
    folderToInvite = value;
});

DataStore.getListener('currentUser', (value) => {
    currentUser = value;
});

export const API_PORTAL_FOLDER_REQUEST = 0;
export const API_REQUEST = 2;

class BaseComponentClass<P extends {params: any; location: any} = any, S=any> extends React.Component<P, S> {
    pendingComponents = 0;
    events: any;
    server: any;
    getPortal: any;
    clearLoginSession: any;
    data: any;
    View: any;

    onPendingComplete: () => void;
    getTeam () {
        return currentTeam;
    }

    getOrganisation () {
        return currentOrg;
    }

    getType () {
        if (currentTeamId !== 0) {
            return 'team';
        } else if (currentOrgId !== 0) {
            return 'org';
        }

        return 'unknown';
    }

    /**
     * THIS FUNCTION IS DEPRECATED. DO NOT USE!
     *
     * Use "this.props.folderSummary.user_id" instead, which is
     *  available on top-level components (e.g. pages). Data should be passed
     *   down to child components as necessary.
     */
    getUserId () {
        const folders = folderToUser || [];
        return folders[currentFolderId] || 0;
    }

    /**
     * THIS FUNCTION IS DEPRECATED. DO NOT USE!
     *
     * Use "this.props.folderSummary.invite_id" instead, which is
     *  available on top-level components (e.g. pages). Data should be passed
     *   down to child components as necessary.
     */
    getInviteId () {
        const folderInvite = folderToInvite || [];
        return folderInvite[currentFolderId] || 0;
    }

    /**
     * THIS FUNCTION IS DEPRECATED. DO NOT USE!
     *
     * Use "this.props.folderSummary.folder_id" instead, which is
     *  available on top-level components (e.g. pages). Data should be passed
     *   down to child components as necessary.
     */
    getFolderId () {
        if (currentFolderId != null && currentFolderId > 0) {
            return currentFolderId;
        }

        // Fall-back on the route parameters.
        if (this.props.params) {
            if (this.props.params.folderId != null && this.props.params.folderId != '' && this.props.params.folderId != 0 && this.props.params.folderId != '0') {
                return parseInt(this.props.params.folderId);
            }
        }

        return 0;
    }

    getTeamId () {
        return currentTeamId;
    }

    getTeamFolderId () {
        if (currentTeamId !== 0) {
            return currentTeam.teamFolderId;
        } else {
            return false;
        }
    }

    getOrgFolderId () {
        if (currentTeamId !== 0) {
            return currentTeam.orgFolderId;
        } else {
            return currentOrg.orgFolderId;
        }
    }

    /**
     * THIS FUNCTION IS DEPRECATED AND BROKEN. DO NOT USE!
     *
     * Use "this.props.folderSummary.patient_accessor_id" instead, which is
     *  available on top-level components (e.g. pages). Data should be passed
     *   down to child components as necessary.
     */
    getUserAccessorId () {
        const patient = (DataStore.get('patient.' + this.getUserId()) || false),
            invite = (DataStore.get('invite.' + this.getInviteId()) || false);

        if (patient) {
            return patient.accessors[patient.team_folder_id];
        } else if (invite) {
            return invite.accessors[invite.team_folder_id];
        } else {
            return false;
        }
    }

    /**
     * DEPRECATED.
     */
    getFolderPath () {
        return '/clinical_portal/folder/' + currentFolderId;
    }

    addPending () {
        this.pendingComponents++;
    }

    getUserName() {
        if (!currentUser) {
            return '';
        }

        if (currentUser.preferred_name !== '') {
            return currentUser.preferred_name;
        } else if (currentUser.first_name !== '') {
            return currentUser.first_name + ' ' + currentUser.last_name;
        } else {
            return currentUser.name;
        }
    }

    getClinicianUserId () {
        return currentUser.id;
    }

    removePending () {
        this.pendingComponents--;

        if (this.pendingComponents < 1 && this.onPendingComplete) {
            this.onPendingComplete();
        }
    }

    constructor (props) {
        super(props);

        this.state = {} as S;
        this.events = {};
        this.server = {};

        if (props && props.folderId && (props.folderId !== DataStore.get('currentFolder'))) {
            DataStore.set('currentFolder', props.folderId);
        }

        this.pendingComponents = 0;

        if (typeof(ServerFetch.renderFromServerState) === 'function') {
            this.server.renderFromServerState = ServerFetch.renderFromServerState.bind(ServerFetch);
        }

        this.getPortal = ServerFetch.getPortal.bind(ServerFetch);

        this.bindServerFetchFunctions([
            'addCacheRequest',
            'addRequest',
            'getRequest',
            'postRequest',
            'patchRequest',
            'putRequest',
            'deleteRequest',
            'cancelContext',
            'cancelCurrentRequests'
        ]);

        this.clearLoginSession = ServerFetch.clearLoginSession.bind(ServerFetch);

        this.data = DataStore;
        this.View = null;
    }

    $p () {
        if (currentTeam) {
            if (currentTeam.serviceName) {
                switch(currentTeam.serviceName) {
                case 'CAMHS':
                    return _`Young Person`;
                default:
                    return 'Patient';
                }
            }
        }

        return 'Patient';
    }

    $ps () {
        if (currentTeam) {
            if (currentTeam.serviceName) {
                switch(currentTeam.serviceName) {
                case 'CAMHS':
                    return _`Young People`;
                default:
                    return 'Patients';
                }
            }
        }

        return 'Patients';
    }

    compareProps (oldProps, newProps, propKeys, cb) {
        const changed = propKeys.some((item, idx) => {
            if (oldProps[item] != newProps[item]) {
                return true;
            }
            return false;
        });

        if (changed) {
            cb(newProps);
        }
    }

    $get (path, query, cb, reqType = API_REQUEST, otherFolderId = undefined) {
        const uri = this.api(path, query, reqType, otherFolderId);

        this.server.getRequest(uri, cb);
    } 

    $post (path, query, data, cb, reqType = API_REQUEST, otherFolderId = undefined) {
        const uri = this.api(path, query, reqType, otherFolderId);

        this.server.postRequest(uri, data, cb);
    }

    $put (path, query, data, cb, reqType = API_REQUEST, otherFolderId = undefined) {
        const uri = this.api(path, query, reqType, otherFolderId);

        this.server.putRequest(uri, data, cb);
    }

    $delete (path, query, data, cb, reqType = API_REQUEST, otherFolderId = undefined) {
        const uri = this.api(path, query, reqType, otherFolderId);

        this.server.deleteRequest(uri, data, cb);
    }

    api (path, query, reqType = API_REQUEST, otherFolderId = undefined) {
        path = [].concat(path);

        if (ServerFetch.getPortal() == 'clinical') {
            return this.apiClinical(path, query, reqType, otherFolderId);
        }
        if (ServerFetch.getPortal() == 'admin') {
            return this.apiAdmin(path, query, reqType, otherFolderId);
        }
        if (ServerFetch.getPortal() == 'user') {
            return this.apiUser(path, query, reqType, otherFolderId);
        }
        if (ServerFetch.getPortal() == 'coop') {
            return this.apiCoop(path, query, reqType, otherFolderId);
        }

        console.error('Unrecognised portal type: ' + ServerFetch.getPortal());
    }

    apiClinical (path, query, reqType = API_REQUEST, otherFolderId = undefined) {
        let page, finalQuery;

        if (reqType === API_PORTAL_FOLDER_REQUEST) {
            page = '/api/clinical_portal/folder/' + (otherFolderId || currentFolderId) + '/';
        } else if (reqType === API_REQUEST) {
            page = '/api/clinical_portal/';
        } else if (reqType === API_REQUEST) {
            page = '/api/';
        } else {
            console.error('Unrecognised request type.');
        }

        page += path.join('/');

        if (query) {
            finalQuery = ['team_id=' + currentTeamId].concat(query);
        } else {
            finalQuery = ['team_id=' + currentTeamId];
        }

        if (finalQuery) {
            page += '?' + finalQuery.join('&');
        }

        return page;
    }

    apiAdmin (path, query, reqType = API_PORTAL_FOLDER_REQUEST, otherFolderId = undefined) {
        let page, finalQuery, entityQuery;

        if (reqType === API_PORTAL_FOLDER_REQUEST) {
            page = '/api/admin_portal/folder/' + otherFolderId + '/';
        } else if (reqType === API_REQUEST) {
            page = '/api/admin_portal/';
        } else if (reqType === API_REQUEST) {
            page = '/api/api/';
        } else {
            console.error('Unrecognised request type.');
        }

        page += path.join('/');

        if (currentTeamId !== 0) {
            entityQuery = ['team_id=' + currentTeamId];
        } else {
            entityQuery = ['organisation_id=' + currentOrgId];
        }

        if (query) {
            finalQuery = entityQuery.concat(query);
        } else {
            finalQuery = entityQuery;
        }

        if (finalQuery) {
            page += '?' + finalQuery.join('&');
        }

        return page;
    }

    apiUser (path, query, reqType = API_PORTAL_FOLDER_REQUEST, otherFolderId = undefined) {
        let page;

        if (reqType === API_PORTAL_FOLDER_REQUEST) {
            page = '/api/user_portal/folder/' + (otherFolderId || currentFolderId) + '/';
        } else if (reqType === API_REQUEST) {
            page = '/api/user_portal/';
        } else if (reqType === API_REQUEST) {
            page = '/api/api/';
        } else {
            console.error('Unrecognised request type.');
        }

        page += path.join('/');

        if (query) {
            page += '?' + query.join('&');
        }

        return page;
    }

    apiCoop (path, query, reqType = API_PORTAL_FOLDER_REQUEST, otherFolderId = undefined) {
        let page;

        page = '/api/api/' + path.join('/');

        if (query) {
            page += '?' + query.join('&');
        }

        return page;
    }

    bindEvents (events, extClass = false) {
        if (extClass) {
            events.forEach((item, idx) => {
                if (typeof(extClass[item]) !== 'function') {
                    console.warn('Skipping non-existent function ' + item);
                    return;
                }
                this.events['on' + item] = extClass[item].bind(this);
            });
        } else {
            events.forEach((item) => {
                const functionName = 'handle' + item;
                if (typeof(this[functionName]) !== 'function') {
                    console.warn('Skipping non-existent function ' + functionName);
                    return;
                }
                this.events['on' + item] = this['handle' + item].bind(this);
            });
        }
    }

    bindFunctions (events) {
        events.forEach((functionName, idx) => {
            if (typeof(this[functionName]) !== 'function') {
                console.warn('Skipping non-existent function ' + functionName);
                return;
            }
            this[functionName] = this[functionName].bind(this);
        });
    }

    /**
     * Add an array of functions to this.server, bound to their ServerFetch equivalents.
     * The bind will add `this` as the first parameter.
     * @param functionNames An array of function names to bind, e.g. ['addRequest', 'getRequest'].
     */
    bindServerFetchFunctions (functionNames) {
        // Go through each provided function name.
        functionNames.map((functionName) => {
            // Check if the named function exists in ServerFetch.
            if (typeof(ServerFetch[functionName]) === 'function') {
                this.server[functionName] = ServerFetch[functionName].bind(ServerFetch, this);
            } else {
                console.warn('Skipping non-existent function: ServerFetch.' + functionName);
            }
        });
    }

    componentWillUnmount () {
        this.server.cancelContext();
    }

    getFolderByType () {
        if (this.getType() == 'team') {
            return this.getTeamFolderId();
        } else if (this.getType() == 'org') {
            return this.getOrgFolderId();
        }

        return false;
    }

    render () {
        const portalPath = {
            ['portal_path']: this.getPortalPath()
        };

        if (typeof(ServerFetch.getPortal()) == 'undefined') {
            return React.createElement(this.View, Object.assign({
                $p: this.$p(),
                $ps: this.$ps()
            },
            this.props, this.events, this.state, portalPath));
        }

        if (ServerFetch.getPortal() == 'clinical') {
            return React.createElement(this.View, Object.assign({
                folderId: currentFolderId, teamId: currentTeamId, patientId: (this.getUserId() || 0),
                teamFolderId: this.getTeamFolderId(), orgFolderId: this.getOrgFolderId(), mapObj: this.mapObj,
                $p: this.$p(),
                $ps: this.$ps()
            },
            this.props, this.events, this.state, portalPath));
        }

        if (ServerFetch.getPortal() == 'admin') {
            if (currentTeamId !== 0) {
                return React.createElement(this.View, Object.assign({
                    team: currentTeam,
                    teamId: currentTeamId,
                    teamFolderId: this.getTeamFolderId(),
                    orgFolderId: this.getOrgFolderId(),
                    mapObj: this.mapObj,
                    entityType: 'team',
                    $p: this.$p(),
                    $ps: this.$ps()
                },
                this.props, this.events, this.state, portalPath));
            } else {
                return React.createElement(this.View, Object.assign({
                    organisation: currentOrg,
                    organisationId: currentOrgId,
                    orgFolderId: this.getOrgFolderId(),
                    mapObj: this.mapObj,
                    entityType: 'org',
                    $p: this.$p(),
                    $ps: this.$ps()
                },
                this.props, this.events, this.state, portalPath));
            }
        }
        if (ServerFetch.getPortal() == 'user') {
            return React.createElement(this.View, Object.assign({
                $p: this.$p(),
                $ps: this.$ps()
            },
            this.props, this.events, this.state, portalPath));
        }

        if (ServerFetch.getPortal() == 'coop') {
            return React.createElement(this.View, Object.assign({},
                this.props, this.events, this.state, portalPath));
        }

        console.error('Unexpected portal: ' + ServerFetch.getPortal());
    }

    setStateKey (key, value, cb) {
        const newState = {};
        newState[key] = value;
        if (cb) {
            this.setState(newState, cb);
        } else {
            this.setState(newState);
        }
    }

    getPortalPath () {
        switch (ServerFetch.getPortal()) {
        case 'user':
            return '/user_portal';
        case 'clinical':
            return '/clinical_portal';
        case 'admin':
            return '/admin_portal';
        case 'coop':
            return '/coopweb';
        }
    }

    /**
     * Wrapper around NavigationHelper.changeQuery().
     * The arguments are the same except this automatically provides oldLocation.
     */
    changeQuery (newParams, force = false) {
        NavigationHelper.changeQuery(this.props.location, newParams, force);
    }

    /**
     *
     * @param archetype
     * @param search
     * @param {object} options
     * @param otherFolderId
     * @param patientId
     * @param inviteId
     * @returns {Promise}
     */
    $search (archetype, search, options = undefined, otherFolderId = undefined, patientId = undefined, inviteId = undefined) {
        return new Promise((resolve, reject) => {
            this.searchCompositions(archetype, search, options, promiseHandler.bind(null, resolve, reject),
                otherFolderId, patientId, inviteId);
        });
    }

    /**
     *
     * @param archetype
     * @param search
     * @param {object} options
     * @param cb
     * @param otherFolderId
     * @param patientId
     * @param inviteId
     */
    searchCompositions (archetype, search, options, cb, otherFolderId = undefined, patientId = undefined, inviteId = undefined) {
        const body: any = {};

        if (search) {
            body.search = JSON.stringify(search);
        }

        if (options){

            if (options.search){
                delete options.search;
            }

            if (options.order){
                options.order = JSON.stringify(options.order);
            }

            lodash.each(options,(value,key) => {
                body[key] = value;
            });
        }

        if (this.getPortal() !== 'user' && this.getPortal() !== 'coop') {
            if (otherFolderId) {
                body['folder_id'] = otherFolderId;
            } else if (patientId) {
                body['patient_id'] = patientId;
            } else if (inviteId) {
                body['invite_id'] = inviteId;
            } else {
                body['folder_id'] = this.getFolderId();
            }
        }
        this.$post(['compositions', archetype], [], body, cb, API_REQUEST, otherFolderId);
    }

    getReqType (otherFolderId) {
        switch (ServerFetch.getPortal()) {
        case 'coop':
            return API_REQUEST;
        case 'user':
            return API_REQUEST;
        case 'clinical':
            return API_PORTAL_FOLDER_REQUEST;
        case 'admin':
            if (otherFolderId) {
                return API_PORTAL_FOLDER_REQUEST;
            } else {
                return API_REQUEST;
            }
        }
    }

    $list (archetype, query, otherFolderId = undefined, patientId = undefined, inviteId = undefined) {
        return new Promise((resolve, reject) => {
            this.listCompositions(archetype, query, promiseHandler.bind(null, resolve, reject),
                otherFolderId, patientId, inviteId);
        });
    }

    listCompositions (archetype, query, cb, otherFolderId = undefined, patientId = undefined, inviteId = undefined) {
        const body = {};

        if (this.getPortal() !== 'user' && this.getPortal() !== 'coop') {
            if (otherFolderId) {
                body['folder_id'] = otherFolderId;
            } else if (patientId) {
                body['patient_id'] = patientId;
            } else if (inviteId) {
                body['invite_id'] = inviteId;
            } else {
                body['folder_id'] = this.getFolderId();
            }
        }

        this.$post(['compositions', archetype], query || [], body, cb, API_REQUEST, otherFolderId);
    }

    $show (archetype, uuid, otherFolderId = undefined) {
        return new Promise((resolve, reject) => {
            this.showComposition(archetype, uuid, promiseHandler.bind(null, resolve, reject), otherFolderId);
        });
    }

    showComposition (archetype, uuid, cb, otherFolderId = undefined) {
        const query = [
            'folder_id=' + (otherFolderId || this.getFolderId()),
            'device_id=' + this.getPortal() + 'Portal'
        ];
        this.$get(['api', 'composition', archetype, uuid], query, cb, API_REQUEST, otherFolderId);
    }

    $create (archetype, data, otherFolderId = undefined) {
        return new Promise((resolve, reject) => {
            this.createComposition(archetype, data, promiseHandler.bind(null, resolve, reject), otherFolderId);
        });
    }

    createComposition (archetype, data, cb, otherFolderId = undefined) {
        const query = [
            'folder_id=' + (otherFolderId || this.getFolderId()),
            'device_id=' + this.getPortal() + 'Portal'
        ];
        this.$post(['composition', archetype], query, { content: JSON.stringify(data) }, cb, API_REQUEST, otherFolderId);
    }

    $update (archetype, uuid, data, otherFolderId = undefined) {
        return new Promise((resolve, reject) => {
            this.updateComposition(archetype, uuid, data, promiseHandler.bind(null, resolve, reject), otherFolderId);
        });
    }

    updateComposition (archetype, uuid, data, cb, otherFolderId = undefined) {
        const query = [
            'folder_id=' + (otherFolderId || this.getFolderId()),
            'device_id=' + this.getPortal() + 'Portal'
        ];
        this.$put(['composition', archetype, uuid], query, { content: JSON.stringify(data) }, cb, API_REQUEST, otherFolderId);
    }

    $remove (archetype, uuid, otherFolderId = undefined) {
        return new Promise((resolve, reject) => {
            this.deleteComposition(archetype, uuid, promiseHandler.bind(null, resolve, reject), otherFolderId);
        });
    }

    deleteComposition (archetype, uuid, cb, otherFolderId = undefined) {
        const query = [
            'folder_id=' + (otherFolderId || this.getFolderId()),
            'device_id=' + this.getPortal() + 'Portal'
        ];
        this.$delete(['composition', archetype, uuid], query, {}, cb, API_REQUEST, otherFolderId);
    }

    $undelete (archetype, uuid, otherFolderId = undefined) {
        return new Promise((resolve, reject) => {
            this.undeleteComposition(archetype, uuid, promiseHandler.bind(null, resolve, reject), otherFolderId);
        });
    }

    /**
     * Promisified version of React setState
     *
     * @param {object|function} newStateOrUpdater Either a state object to be shallow-merged into the component state, or an updater function to carry out a state update.
     * @return {Promise} The promise resolves when the state changed has finished.
     */
    $setState (newStateOrUpdater) {
        return new Promise((resolve, reject) => {
            this.setState(newStateOrUpdater, () => resolve(void 0));
        });
    }

    undeleteComposition (archetype, uuid, cb, otherFolderId = undefined) {
        const query = [
            'folder_id=' + (otherFolderId || this.getFolderId()),
            'device_id=' + this.getPortal() + 'Portal'
        ];
        this.$post(['composition', archetype, uuid, 'undelete'], query, {}, cb, API_REQUEST, otherFolderId);
    }

    mapObj (obj, cb) {
        let item, idx;
        const list: any[] = [];

        for (idx in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(idx)) {
                list.push(cb(obj[idx], idx));
            }
        }

        return list;
    }
}

export const BaseComponent = BaseComponentClass;

export default BaseComponentClass;

function promiseHandler (resolve, reject, response, status) {
    if (status < 400) {
        resolve(response);
    } else {
        reject({
            response: response,
            status: status
        });
    }
}
