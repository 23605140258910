import {
    FolderBreadcrumbItemSubfolder,
    FolderBreadcrumbItem,
} from './components/FolderBreadcrumbs/FolderBreadcrumbs.interface';
import {
    DataOption,
    DepartmentLocation,
    DepartmentLocationListItem,
    GroupedDataOption,
    GroupedDepartmentDataOptions,
    NormalisedDepartmentLocation,
    NormalisedDepartmentLocationMap,
    SortedDepartmentData,
} from './department.interface';
import _ from 'lodash';
import { addOrReplaceLabel, Label } from 'models/Label';

function getNormalisedDepartmentLocationMap(
    tree: DepartmentLocation,
): NormalisedDepartmentLocationMap {
    const normalisedMap: NormalisedDepartmentLocationMap = {};

    const update = (d: DepartmentLocation) => {
        normalisedMap[d.uuid] = {
            parent: d.parent === '' ? null : d.parent,
            name: d.name,
            uuid: d.uuid,
            subfolders: d.subfolders
                ? d.subfolders.map((sub) => sub.uuid)
                : null,
        };
    };

    traverseTree(tree, update);

    return normalisedMap;
}

export const convertDepartmentLocationTree = (folder: DepartmentLocation): DepartmentLocationListItem => {
    return {
        label: folder.name,
        value: folder.uuid,
        children: folder.subfolders?.map(subFolder => convertDepartmentLocationTree(subFolder))
    };
};

export const findDepartmentName = (uuid, locationTree) => {

    const normalisedMap = getNormalisedDepartmentLocationMap(locationTree);
    
    return normalisedMap[uuid].name;
};

function traverseTree(
    tree: DepartmentLocation,
    update: (d: DepartmentLocation) => void,
) {
    if (!tree.subfolders || tree.subfolders.length === 0) {
        return update(tree);
    }

    for (let i = 0; i < tree.subfolders.length; i++) {
        traverseTree(tree.subfolders[i], update);
    }

    update(tree);
}

function getCurrentFolderPath(id: string, tree: DepartmentLocation): string[] {
    if (tree.uuid === id) {
        return [tree.uuid];
    }

    if (!tree.subfolders) {
        return [];
    }

    for (let i = 0; i < tree.subfolders.length; i++) {
        const pathArr = getCurrentFolderPath(id, tree.subfolders[i]);

        if (pathArr.length !== 0) {
            return [tree.uuid].concat(pathArr);
        }
    }

    return [];
}

function convertNormalizedDepartmentLocationToBreadcrumbsData(
    location: NormalisedDepartmentLocation,
    normalizedMap: NormalisedDepartmentLocationMap,
): FolderBreadcrumbItem {
    let siblings: FolderBreadcrumbItemSubfolder[] = [];
    if (location.parent) {
        siblings = normalizedMap[location.parent].subfolders.map((id) => {
            const location = normalizedMap[id];

            return {
                id: location.uuid,
                name: location.name,
            };
        });
    } else {
        siblings = [
            {
                id: location.uuid,
                name: location.name,
            },
        ];
    }

    let children: FolderBreadcrumbItemSubfolder[] = [];
    if (location.subfolders) {
        children = location.subfolders.map((id) => {
            const location = normalizedMap[id];

            return {
                id: location.uuid,
                name: location.name,
            };
        });
    }

    let parent: FolderBreadcrumbItemSubfolder | null = null;
    if (location.parent) {
        const { uuid, name } = normalizedMap[location.parent];

        parent = {
            id: uuid,
            name,
        };
    }

    return {
        id: location.uuid,
        name: location.name,
        parent,
        siblings,
        children,
    };
}

export function getFolderListFromCurrentLocation(
    currentLocation: string,
    locationTree: DepartmentLocation,
): FolderBreadcrumbItem[] {
    const locationMap = getNormalisedDepartmentLocationMap(locationTree);

    const currentLocationPath = getCurrentFolderPath(
        currentLocation,
        locationTree,
    );

    return currentLocationPath.map((uuid) => {
        const normalizedLocation = locationMap[uuid];

        return convertNormalizedDepartmentLocationToBreadcrumbsData(
            normalizedLocation,
            locationMap,
        );
    });
}

const defaultGroupedDepartmentDataOptions: GroupedDepartmentDataOptions = {
    currentLabel: 'Current department',
    otherLabel: 'Other departments',
};

export function createGroupedDepartmentDataOptionList<T>(
    data: SortedDepartmentData<T>,
    getLabel: (d: T) => string,
    options?: GroupedDepartmentDataOptions,
): GroupedDataOption<T>[] {
    const currentFolderSearchOptionList = data.current.map((dataItem) => {
        return {
            label: getLabel(dataItem),
            value: dataItem,
        };
    });

    const otherFolderSearchOptionList = data.other.map((dataItem) => {
        return {
            label: getLabel(dataItem),
            value: dataItem,
        };
    });

    return [
        {
            label:
                options?.currentLabel ||
                defaultGroupedDepartmentDataOptions.currentLabel,
            options: currentFolderSearchOptionList,
        },
        {
            label:
                options?.otherLabel ||
                defaultGroupedDepartmentDataOptions.otherLabel,
            options: otherFolderSearchOptionList,
        },
    ];
}

export function findDepartmentDataOptionInGroupedList<T>(groupedList: GroupedDataOption<T>[], matchFn: (item: T) => boolean): DataOption<T> {
    return groupedList.reduce((previousValue: DataOption<T>, currentValue) => {
        if (previousValue) {
            return previousValue;
        }

        return currentValue.options.find(option => matchFn(option.value));
    }, null);
}

export function addOrUpdateDepartmentFolderLabel<T extends { labels?: Label[] }>(
    labelable: T,
    departmentFolderLocation: string
) {
    if (!departmentFolderLocation) {
        return;
    }

    const labels = labelable.labels ? [...labelable.labels] : [];
    const newLabel = makeDepartmentFolderLabel(departmentFolderLocation);
    addOrReplaceLabel(labels, newLabel, l => l.type === 'department-folder');
    labelable.labels = labels;
}

export function makeDepartmentFolderLabel(departmentFolderLocation: string) {
    return {
        name: 'department-folder-id',
        type: 'department-folder',
        status: true,
        context: departmentFolderLocation
    };
}
