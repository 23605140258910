import { browserHistory } from 'react-router';

/**
 * Provides functions to make it easier to alter the routes and query parameters.
 */
export default class
{
    /**
     * Navigate to the same route but change one or more query parameters.
     * The existing location object should be provided in oldLocation.
     * (In a component it is usually accessed as this.props.location)
     * Parameters should be specified as key-value pairs in the newParams object.
     * To remove a query parameter, set its value to undefined.
     * By default, if the resulting query string is exactly the same as the existing
     *  query string, this won't do a navigation. You can override this be setting
     *  force to true.
     */
    static changeQuery(oldLocation, newParams, force = false)
    {
        if (!force) {
            let changed = false;
            for (let key in newParams) {
                
                // All the existing values in the query object are either undefined or string type.
                // This menas we need to compare for identical values and for string equivalence.
                if (oldLocation.query[key] !== newParams[key] &&
                    oldLocation.query[key] !== String(newParams[key])) {
                    changed = true;
                    break;
                }
            }
            if (!changed)
                return;
        }
        
        // Copy the existing location information.
        let newLocation = Object.assign({}, oldLocation);
        // Add/overwrite the query string with the new parameters.
        Object.assign(newLocation.query, newParams);
        // Navigate!
        browserHistory.push(newLocation);
    }
}
