/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import { SmsCreateForm } from '../user/sms/create/form';
import { SendSmsState, validate, normalise } from '../user/sms/create/validator';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import { BulkOperationSmsCostEstimator } from './BulkOperationSmsCostEstimator';
import { BulkOperationRequestAction, BulkOperationRequestSendSms } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import { predefinedSMSService } from 'services/predefined-sms.service';
import SmsPreview from 'components/user/sms/create/preview';
import {CompositionService} from "services/composition.service";

const initialFormState: SendSmsState = { message: '' };

export class BulkOperationSendSmsConfigurator extends BulkOperationConfigurationComponent<SendSmsState>
{
    private readonly smsTemplateService: CompositionService;

    public validate: () => boolean;

    constructor(smsTemplateServiceVal: CompositionService = predefinedSMSService) {
        super();
        this.smsTemplateService = smsTemplateServiceVal;
    }

    public getActionName(): string {
        return 'Send SMS';
    }

    public getInitialFormState(): SendSmsState {
        return initialFormState;
    }

    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState<string[]>([]);
        const { currentFormState, onFormStateChange } = this.useFormState();

        this.validate = () => validate(this.getFormState(), onErrors);

        return (
            <SmsCreateForm
                id='bo-create-sms'
                loading={false}
                errors={currentErrors}
                onChange={(c) => onFormStateChange({ ...currentFormState, ...c })}
                form={currentFormState}
                onSubmit={(f) => f.preventDefault()}
                smsTemplateService={this.smsTemplateService}
            />
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<SendSmsState> {
        return new BulkOperationSendSmsPreviewBuilder(selectionState, normalise(this.getFormState()));
    }
}

export class BulkOperationSendSmsPreviewBuilder extends BulkOperationPreviewBuilder<SendSmsState> {
    public getActionName(): string {
        return 'Send SMS';
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];
        const costEstimatorMessage = new BulkOperationSmsCostEstimator(this.getActionState(), selectionState, this.onAnyCostEstimateChange);

        const smsPreview = (): JSX.Element => {
            return (
                <SmsPreview message={this.getActionState().message} />
            );
        };
        items.push(new BulkOperationPreviewItem(smsPreview(), costEstimatorMessage));

        return items;
    }

    public getBulkOperations(): Promise<BulkOperationRequestAction<TriggerEngineRequestContent>[]> {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationRequestSendSms({
            message: this.getActionState().message,
        }));

        return Promise.resolve(request);
    }
}
