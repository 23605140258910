export function nhsValidator(value) {
    if (value.length !== 10) {
        return false;
    }

    let total = 0;

    for (var i = 0; i < 9; i++) {
        var digit = value.substr(i, 1);
        var factor = 10 - i;
        total += (digit * factor);
    }

    let checkDigit = (11 - (total % 11));

    if (checkDigit === 11) { checkDigit = 0; }

    if (checkDigit != value.substr(9, 1)) {
        return false;
    }

    return true;
}

export function clearNHSNumber(value) {
    return value.replace(/[^0-9]/g, '');
}

export function formatNHSNumber(value) {
    let newValue;
    let parts;

    // strip out all non numerics
    newValue = (value || '').replace(/[^0-9]/g, '');

    // now format to 3 3 4
    parts = newValue.match(/^([0-9]{3})([0-9]{3})([0-9]{4})$/);
    if (!parts) {
        parts = newValue.match(/^([0-9]{3})([0-9]{3})([0-9]*)$/);
    }
    if (!parts) {
        parts = newValue.match(/^([0-9]{3})([0-9]*)$/);
    }
    if (!parts) {
        parts = newValue.match(/^([0-9]*)$/);
    }

    if (parts.length == 4) {
        newValue = parts[1] + ' ' + parts[2] + ' ' + parts[3];
    } else if (parts.length == 3) {
        newValue = parts[1] + ' ' + parts[2];
    } else if (parts.length == 2) {
        newValue = parts[1];
    }

    return newValue.trim();
}
