import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useLayoutEffect,
    useRef,
    useState,
    RefObject,
    createContext,
    forwardRef,
    useEffect,
    useMemo,
} from 'react';
import './DropdownSelect.less';
import { getRelativePosition, RelativePosition } from 'common/getRelativePosition';
import { useOnClickOutside } from 'common/useOnClickOutside';
import { useWindowSize } from 'common/useWindowResize';

export interface DropdownSelectProps {
    placeholder?: string;
    label?: string;
    value?: string | number;
    name?: string;
    onSearch?: (value: string) => any;
    onBlur?: () => any;
    onChange?: (event: { target: { value: string; name: string } }) => any;
    showFilter?: boolean;
    input?: any;
    children: any;
    openDropdown?: boolean;
    keyUp?: any;
    keyDown?: any;
}

const copyClientRectSizes = ({ width, height, top, left }: any) => {
    return { width, height, top, left };
};

const DropdownSelectContext = createContext(null);
export const DropdownSelectContextKeyEvents = createContext({});

export const DropdownSelect = forwardRef<HTMLDivElement, DropdownSelectProps>((props, ref: RefObject<HTMLDivElement>) => {
    const {
        placeholder,
        label,
        value,
        children,
        showFilter = true,
        openDropdown: openDropdownProp,
        input,
        onSearch,
        name,
        keyUp,
        keyDown,
        onBlur,
        onChange
    } = props;
    const optionValues: string[] = useMemo(() => [], []);
    const windowSize = useWindowSize();
    const [dropdownPosition, setDropdownPosition] = useState({ top: '100%' as any, left: 0, position: RelativePosition.BottomLeft });
    const [dropdownIsActive, setDropdownState] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [preSelected, setPreSelected] = useState(-1);
    const ddListRef = useRef(null);
    const rootRef = useRef(null);

    const setRootRefForClickOutsideHook = useOnClickOutside(setDropdownState);

    const handleOnChange = useCallback((value: string) => {
        onChange({ target: { value, name } });
    }, [name, onChange]);

    const handleOnSearch = useCallback((value: string) => {
        setSearchValue(value);
        onSearch(value);
        setPreSelected(0);
    }, [onSearch]);

    const openDropdown = useCallback(() => {
        setDropdownState(true);

        let preSelected = 0;
        if(value) {
            preSelected = optionValues.findIndex((optionValue) => optionValue === value);
        }
        setPreSelected(preSelected);

    }, [optionValues, value]);

    const closeDropdown = useCallback(() => {
        onBlur && onBlur();
        setSearchValue('');
        setDropdownState(false);
        setPreSelected(-1);
    }, [onBlur]);

    const rootRefCallback = useCallback((node) => {
        if (node !== null) {
            rootRef.current = node;
            setRootRefForClickOutsideHook(node);
        }
    }, [setRootRefForClickOutsideHook]);

    const handleOnKeyUp = useCallback(({ key }) => {
        switch (key) {
        case 'Tab':
            return;
        case 'Enter':
            if (dropdownIsActive) {
                if (optionValues[preSelected]) {
                    handleOnChange(optionValues[preSelected]);
                }
                closeDropdown();
            } else {
                openDropdown();
            }
            break;
        case 'ArrowDown':
        case ' ':
            if(dropdownIsActive) {
                setPreSelected(Math.min(optionValues.length - 1, (preSelected || 0) + 1));
            } else {
                openDropdown();
            }
            break;
        case 'ArrowUp':
            if(dropdownIsActive) {
                setPreSelected(Math.max(0, (preSelected || 0) - 1));
            } else {
                openDropdown();
            }
            break;
        default:
            if (!input && !dropdownIsActive) {
                openDropdown();
                handleOnSearch(key);
            }

        }
    }, [preSelected, optionValues, dropdownIsActive, handleOnChange, openDropdown, closeDropdown, handleOnSearch]);

    const handleOnKeyDown = useCallback((event) => {
        switch (event.key) {
        case 'Tab':
            if (dropdownIsActive) {
                closeDropdown();
            }
            return;
        case 'Enter':
        case 'ArrowDown':
        case 'ArrowUp':
            event.stopPropagation();
            event.preventDefault();
            return;

        case ' ':
            if (!input) {
                event.stopPropagation();
                event.preventDefault();
            }
            return;
        default:
        }
    }, [dropdownIsActive, closeDropdown]);

    useLayoutEffect(() => {
        if (!dropdownIsActive) { return; }
        if (!ref || !ref.current) { return; }

        const containers = {
            targetContainer: copyClientRectSizes(ref.current.getBoundingClientRect()),
            boundingBoxContainer: { ...windowSize, top: 0, left: 0 },
            contentContainer: copyClientRectSizes(ddListRef.current.getBoundingClientRect())
        };

        const { x, y, position } = getRelativePosition({ containers, where: [RelativePosition.BottomLeft, RelativePosition.TopLeft] });
        const { left, top } = copyClientRectSizes(rootRef.current.getBoundingClientRect());

        setDropdownPosition({ top: y - top, left: x - left, position });

    }, [dropdownIsActive, windowSize, ref]);

    useEffect(() => {
        if (openDropdownProp) {
            openDropdown();
        }
    }, [openDropdownProp, openDropdown]);

    useEffect(() => {
        if (!keyDown) { return; }

        handleOnKeyDown(keyDown);
    }, [handleOnKeyDown, keyDown]);

    useEffect(() => {
        if (!keyUp) { return; }

        handleOnKeyUp(keyUp);
    }, [handleOnKeyUp, keyUp]);

    const childrenWithPropsOrder = useMemo(() => React.Children.map(children, (el: any, i) => {
        optionValues.push(el.props.value);
        return React.cloneElement(el, { order: i });
    }), [children, optionValues]);

    return (
        <DropdownSelectContext.Provider value={{
            onChange: handleOnChange,
            preSelected,
            setPreSelected,
        }}>
            <div className={'ui-dropdown-select'} ref={rootRefCallback} onKeyUp={handleOnKeyUp} onKeyDown={handleOnKeyDown}>
                {!!input && input}
                {!input && (
                    <div
                        className={'form-control ui-dropdown-select__input' + ((!label && placeholder) ? ' ui-dropdown-select__input--with-placeholder' : '')}
                        tabIndex={1}
                        ref={ref}
                        onMouseDown={openDropdown}
                    >{label || placeholder}</div>
                )}
                {dropdownIsActive ? (
                    <div className={'ui-dropdown-select__arrow ui-dropdown-select__arrow-up'}/>
                ) : (
                    <div className={'ui-dropdown-select__arrow'}
                        onMouseDown={openDropdown}
                    />
                )}
                {dropdownIsActive && (
                    <div className="ui-dropdown-select__list"
                        ref={ddListRef}
                        style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
                    >
                        {showFilter && (dropdownPosition.position === RelativePosition.BottomLeft) && onSearch &&
                        <Search value={searchValue} onSearch={handleOnSearch} onBlur={closeDropdown}/>
                        }
                        <div onMouseDown={closeDropdown}>{childrenWithPropsOrder}</div>
                        {showFilter && (dropdownPosition.position === RelativePosition.TopLeft) && onSearch &&
                        <Search value={searchValue} onSearch={handleOnSearch} onBlur={closeDropdown}/>
                        }
                    </div>
                )}
            </div>
        </DropdownSelectContext.Provider>
    );
});
DropdownSelect.displayName = 'DropdownSelect';

interface SearchProps {
    value: string;
    onSearch: (value: string) => void;
    onBlur: () => void;
}

export const Search: FunctionComponent<SearchProps> = (props) => {
    const {
        value,
        onSearch,
        onBlur,
    } = props;
    return (
        <div className="ui-dropdown-select__search">
            <input ref={(node) => setTimeout(() => {node && node.focus();})}
                type="text"
                placeholder={'Filter...'}
                className={'form-control'}
                value={value}
                onBlur={onBlur}
                onChange={({ target: { value } }) => onSearch(value)}
            />
        </div>
    );
};

export interface DropdownSelectOptionProps {
    value: string;
    className?: string;
    order?: number;
}

export const DropdownSelectOption: FunctionComponent<DropdownSelectOptionProps> = (props) => {
    const { preSelected, onChange, setPreSelected } = useContext(DropdownSelectContext);
    const {
        children,
        value,
        className,
        order,
    } = props;
    return (
        <div className={'ui-dropdown-select__option' + ` ${className || ''}` + `${preSelected === order ? ' option-preselected' : ''}`}
            onMouseDown={(event) => { event.preventDefault(); onChange(value); }}
            onMouseOver={() => setPreSelected(order)}
        >{children}</div>
    );
};
