import React, { useCallback, useEffect, useState } from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import _ from 'services/i18n';
import { resourcesService } from 'services/resources.service';
import PatientResourcesTable from '../PatientResourcesTable/PatientResourcesTable';
import { SearchResourcesComponent } from '../SearchResourcesComponent/SearchResourcesComponent';
import { portalToast } from 'ui/toast/Toast';
import ManagePatientResourceModal from '../ManagePatientResourceModal/ManagePatientResourceModal';
import CreatePatientResourceComponent from '../CreatePatientResourceComponent/CreatePatientResourceComponent';
import { Composition } from 'models/Composition';
import { CoopResource } from 'models/compositions/CoopResource';

type PatientResourcesContainerProps = {
    folderId: number;
    currentUser: string;
}

const PatientResourcesContainer = ({ folderId, currentUser }: PatientResourcesContainerProps) => {
    const [assignedResources, setAssignedResources] = useState<Composition<CoopResource>[]>([]);
    const [editResourceId, setEditResourceId] = useState<string>(null);
    const [displayEditResourceModal, setDisplayEditResourceModal] = useState<boolean>(false);

    const fetchAssignedResources = useCallback(async () => {
        try {
            const resourcesResponse = await resourcesService.list({ folderId });
            setAssignedResources(resourcesResponse.message.results);
        } catch (error) {
            setAssignedResources([]);
        }
    }, [folderId]);

    useEffect(() => {
        fetchAssignedResources();
    }, [fetchAssignedResources]);

    const assignResourcesToPatient = async (resourceIds: (string | number)[]) => {

        if (resourceIds.length > 0) {
            Promise.all(resourceIds.map(async (resourceId) => {

                const resourceResponse = await resourcesService.getByUuid({ uuid: resourceId as string, folderId: 'team' });

                const patientResourceContent = {
                    ...resourceResponse.message.content,
                    created_based_on_uuid: resourceId
                };

                return resourcesService.create({
                    content: patientResourceContent,
                    file: null,
                    lozenge: undefined
                });

            })).then(() => {
                portalToast.success({
                    title: 'Successfully added resources.',
                    content: 'The resources have been successfully added to the collection.',
                });
                fetchAssignedResources();
            }).catch(() => {
                portalToast.error({
                    title: 'Unable to add resources.',
                    content: 'There was an error whilst attempting to add resources to the collection.',
                });
            });
        }
    };

    const onEditResource = (uuid: string) => {
        setEditResourceId(uuid);
        setDisplayEditResourceModal(true);
    };

    const onRemoveResource = (uuid: string) => {
        resourcesService.delete({ uuid, folderId }).then(() => {
            fetchAssignedResources();
        });
    };

    /** 
     * Returns true if either one of the assigned resource's 
     * created_based_on_uuid matches the resource's uuid or if any of the assigned resource's 
     * has a matching label and content pair (This is a fallback check for legacy resources).
    */
    const isLibraryResourceAssigned = (resource: Composition<CoopResource>) => {

        return assignedResources.find((assignedResource) => {
            return assignedResource.content.created_based_on_uuid === resource.uuid || 
                    hasMatchingLabelAndContent(assignedResource, resource);
        }) ? true : false;
    };

    const hasMatchingLabelAndContent = (assignedResource, resourceToCompare) => {
        return (assignedResource.content.label === resourceToCompare.content.label && 
            (
                resourceToCompare.content.url && resourceToCompare.content.url === assignedResource.content.url ||
                resourceToCompare.content.phone && resourceToCompare.content.phone === assignedResource.content.phone ||
                resourceToCompare.content.html_content && resourceToCompare.content.html_content === assignedResource.content.html_content ||
                resourceToCompare.content.media_resource_uuid && resourceToCompare.content.media_resource_uuid === assignedResource.content.media_resource_uuid
            )
        );
    };
    
    return (
        <div>
            <h4><b>{_`This patient has ${assignedResources.length} assigned resource(s)`}</b></h4>
            <PanelGroup className="resource-panel-group">
                <Panel eventKey="1">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            {_`View assigned resources`}
                            <i className="fa fa-plus panel-icon" aria-hidden="true"></i>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <PatientResourcesTable
                            results={assignedResources}
                            onEditResource={onEditResource}
                            onRemoveResource={onRemoveResource}
                        />
                    </Panel.Body>
                </Panel>
                <Panel eventKey="2">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            {_`Search for resources in library`}
                            <i className="fa fa-plus panel-icon" aria-hidden="true"></i>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <SearchResourcesComponent
                            onSubmitSelection={assignResourcesToPatient}
                            submitSelectionText={_`Send to patient`}
                            isIncluded={isLibraryResourceAssigned}
                            includedColumnHeading={_`Is Assigned`}
                        />
                    </Panel.Body>
                </Panel>
                <Panel eventKey="3">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            {_`Add new resource (personalised)`}
                            <i className="fa fa-plus panel-icon" aria-hidden="true"></i>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <CreatePatientResourceComponent 
                            submitLabel={_`Add this resource to patient library`}
                            currentUser={currentUser}
                            onRefresh={() => fetchAssignedResources()}
                        />
                    </Panel.Body>
                </Panel>
            </PanelGroup>
            {displayEditResourceModal && (
                <ManagePatientResourceModal
                    id={editResourceId}
                    onClose={() => {
                        fetchAssignedResources();
                        setDisplayEditResourceModal(false);
                    }}
                    currentUser={currentUser}
                />
            )
            }
        </div>
    );
};

export default PatientResourcesContainer;
