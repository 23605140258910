import React from 'react';

const SmsPreview = ({ message }: { message: string}) => {

    return (
        <div className='sms-preview'>
            <p><strong>SMS Content:</strong></p>
            <p>{message}</p>
        </div>
    );
};

export default SmsPreview;
