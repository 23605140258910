import React from 'react';
import DateInput from 'components/form/date-input';

export default class DateInputView extends React.Component {
    
    render () {
        let formGroupClass = 'form-group';
        
        let requiredIndicator;
        if (this.props.required && !this.props.readOnly && !this.props.disabled) {
            requiredIndicator = (
                <span className="text-danger">{' '}*</span>
            );
        }
        
        // Important: Don't show the validation result if no validation has been done.
        if ((this.props.showValidation || this.props.forceShowValidation) && this.props.isValidated) {
            if (this.props.errors.length > 0) {
                formGroupClass += ' has-error';
                
            } else if (this.props.value != null && !this.props.hideValidationSuccess) {
                formGroupClass += ' has-success';
            }
        }

        return (
            <div className={formGroupClass}>
                <label className='control-label' htmlFor={this.props.id}>
                    {this.props.label}
                    {requiredIndicator}
                </label>
                <div>
                    <DateInput
                        name={this.props.id}
                        onChange={this.props.onChange}
                        onToggleApprox={this.props.onChangeApprox}
                        onToggleUnknown={this.props.onChangeUnknown}
                        onBlur={this.props.onLoseFocus}
                        readOnly={this.props.readOnly}
                        disabled={this.props.disabled}
                        showTime={this.props.showTime}
                        value={this.props.valueString}
                        timeApprox={this.props.value && this.props.value.approx}
                        dateUnknown={this.props.value && this.props.value.unknown}
                        onSetInvalid={this.props.onSetInvalid}
                    />
                </div>
                <Errors {...this.props} />
                <HelpBlock {...this.props} />
            </div>
        );
    }
}

const Errors = ({isValidated, showValidation, forceShowValidation, errors}) => {
    // Don't show any errors if validation passed or hasn't been done,
    //  or if validation errors are being suppressed.
    if (!isValidated || errors.length == 0 || (!showValidation && !forceShowValidation)) {
        return (<noscript />);
    }
    
    let content;
    if (errors.length == 1) {
        content = (<span>{errors[0]}</span>);
    } else {
        const list = errors.map((error, idx) => {
            return (<li key={idx}>{error}</li>);
        });
        
        content = (<ul>{list}</ul>);
    }
    
    return (
        <div className='alert alert-danger'>
            {content}
        </div>
    );
};

const HelpBlock = ({helpText}) => {
    if (helpText == null || helpText == '') {
        return (<noscript />);
    }
    return (
        <p className='help-block'>
            {helpText}
        </p>
    );
};
