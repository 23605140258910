import React, { FC, useEffect, useMemo, useRef } from 'react';
import { scaleLinear, max, min, select, axisLeft, axisBottom, line, scaleTime, utcFormat } from 'd3';
import './LineGraph.less';
import { useWindowSize } from 'common/useWindowResize';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { standardDateTimeFormats } from 'common/datetime/convertToDate';
import classNames from 'classnames';

export enum LineType {
    Graph,
    TimeLine
}

export enum DotType {
    Square,
    Rhombus,
    TriangleUp,
    TriangleDown,
    Round
}

export interface Dot {
    x: number;
    y?: number;
    title?: string;
    emphasized?: boolean;
    color?: string;
    uuid?: string;
}

export interface Line {
    color: string;
    title: string;
    dotType: DotType;
    disabled?: boolean;
    tooltip? : {
        title?: (line, dot) => string;
        content?: (line, dot) => string;
    };
    dots: Dot[];
}

export interface AxisParams {
    min?: number;
    max?: number;
    label?: number;
}

interface LineGraphProps {
    lines?: Line[];
    timeLines?: Line[];
    axisXParams?: AxisParams;
    axisYParams?: AxisParams;
    axisDateFormat?: string; // see https://github.com/d3/d3-time-format/blob/master/README.md#user-content-locale_format
    tooltipDateFormat?: string; // moment date format
    onLineToggle?: (name: string, value: boolean) => void;
    dotClickCallback?: (questionnaireUuid: any) => void;
    boundaryValues?: number[];
    className?: string;
    legendTitle?: boolean;
    legendXAxis?: string;
    legendYAxis?: string;
    isDotShownByClick?: boolean;
}

export const LineGraph: FC<LineGraphProps> = ({
    lines,
    axisXParams,
    axisYParams,
    timeLines,
    axisDateFormat,
    tooltipDateFormat,
    onLineToggle,
    dotClickCallback,
    boundaryValues,
    className,
    legendXAxis,
    legendYAxis,
    isDotShownByClick,
    ...props
}) => {

    const svgRef = useRef(null);
    const winSize = useWindowSize();

    const defaultMargins = useMemo(() => ({
        top: 10,
        right: 20,
        bottom: 6,
        left: 6
    }), []);

    useEffect(() => {
        const svg = select(svgRef.current);
        const canvasGroup = svg.append('g')
            .attr('class', 'canvas-group');
        canvasGroup.append('g')
            .attr('class', 'axis-group_y');
        canvasGroup.append('g')
            .attr('class', 'graph-group');
        svg.append('g')
            .attr('class', 'time-lines-group');
        svg.append('g')
            .attr('class', 'axis-group_x');
        svg.append('g')
            .attr('class', 'interactive-layer-group')
            .append('rect');

    }, []);

    useEffect(() => {

        // get selections
        const svg = select(svgRef.current);
        const axisYGroup = svg.select('.axis-group_y');
        const axisXGroup = svg.select('.axis-group_x');
        const mainGraphGroup = svg.select('.canvas-group');
        const graphGroup = svg.select('.graph-group');
        const interactiveLayerGroup = svg.select('.interactive-layer-group');
        const timeLinesGroup = svg.select('.time-lines-group');

        const timeLineMargin = 40;
        const spanHeight = 22;

        // calculate required dimensions
        const margins = defaultMargins;
        const {
            height: canvasHeight,
            width: canvasWidth
        } = getClientRect(svgRef.current);

        const edgeValues = getEdgeValues((lines || []).concat(timeLines || []), boundaryValues, axisXParams, axisYParams);
        const edgeValuesAdjusted = addSafeZones(edgeValues, axisXParams, axisYParams);

        const timeLinesSpaceHeight = calculateTimeLineHeight(timeLines?.length, spanHeight, timeLineMargin);
        const {
            axisXHeight,
            axisYWidth
        } = getAxisDimensions({ edgeValues: edgeValuesAdjusted, axisXContainer: axisXGroup, axisYContainer: axisYGroup });

        const trueXAxisLength = canvasWidth - (margins.left + margins.right + axisYWidth);
        const trueYAxisLength = canvasHeight - (margins.top + margins.bottom + axisXHeight + timeLinesSpaceHeight);

        const { x: { max: xMax, min: xMin }, y: { max: yMax, min: yMin } } = edgeValuesAdjusted;

        // now when we have all required dimensions we can put the main elements in proper places

        const { axisX, axisY, scaleX, scaleY } = createAxisHandlers({
            domainY: [yMin, yMax],
            domainX: [xMin, xMax],
            rangeX: [0, trueXAxisLength],
            rangeY: [trueYAxisLength, 0]
        });
        const maxXTickWidth = 70;
        axisX.ticks(Math.floor(trueXAxisLength / maxXTickWidth));
        axisY.tickSizeInner(trueXAxisLength);
        axisX.tickFormat(utcFormat(axisDateFormat) as any);
        axisXGroup.transition().call(axisX as any);
        axisYGroup.transition().call(axisY as any);

        mainGraphGroup
            .attr('transform', `translate(${margins.left + axisYWidth}, ${margins.top})`);
        axisYGroup
            .attr('transform', `translate(${trueXAxisLength}, ${0})`);
        axisXGroup
            .attr('transform',
                `translate(${margins.left + axisYWidth}, ${canvasHeight - margins.bottom - axisXHeight})`);
        timeLinesGroup
            .attr('transform',
                `translate(${margins.left + axisYWidth}, ${margins.top + trueYAxisLength})`
            );

        interactiveLayerGroup
            .attr('transform', `translate(${margins.left + axisYWidth}, ${margins.top})`)
            .style('pointer-events', 'all');
        interactiveLayerGroup
            .select('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', trueXAxisLength)
            .attr('height', trueYAxisLength + timeLinesSpaceHeight);

        // lines
        const lineGroups = updateLines(lines, graphGroup, { x: scaleX, y: scaleY });

        drawBoundaryLines({ lineGroups, scaleX, scaleY,  xMin, xMax, boundaryValues });

        // timelines timeLines, spanHeight, timeLineMargin, scaleY,
        const timeLineDataExtended = appendYCoordinate({
            lines: timeLines,
            marginTop: timeLineMargin,
            scaleY,
            spacing: spanHeight
        });
        const timeLineGroups = updateTimeLines(timeLineDataExtended, timeLinesGroup, { x: scaleX, y: scaleY }, trueXAxisLength);

        const linePoints = cloneDeep(lines).reduce((acc, line, lineIndex) => {
            const dots = line.dots.map(({ x, y }, dotIndex) => ({
                x: scaleX(x),
                y: scaleY(y),
                dotIndex,
                lineIndex
            }));
            return acc.concat(dots);
        }, []);

        const timeLinePoints = cloneDeep(timeLineDataExtended).reduce((acc, line, lineIndex) => {
            const dots = line.dots.map(({ x, y }, dotIndex) => ({
                x: scaleX(x),
                y: trueYAxisLength + scaleY(y),
                dotIndex,
                lineIndex
            }));
            return acc.concat(dots);
        }, []);

        interactiveLayerGroup
            .on('mousemove', interactionsHandler as any)
            .on('click', clickHandler as any);

        function interactionsHandler (this: SVGAElement, event: React.MouseEvent<HTMLElement>) {
            const { targetDot, targetLine, selectedPoint } = getLineAndPosition(event);

            const interactiveLayerRect = getClientRect(this);

            if (!targetDot) {
                return;
            }
            const time = moment(targetDot.x).format(tooltipDateFormat);
            const content = `
                            <div class="graph-tooltip_title">
                                ${targetLine?.tooltip?.title && targetLine.tooltip.title(targetLine, targetDot) || `<b>${targetDot.title || targetLine.title}</b>`}
                            </div>
                            <div class="graph-tooltip_content">
                                ${targetLine?.tooltip?.content && targetLine.tooltip.content(targetLine, targetDot) || `${time}: <b>${targetDot.y}</b>`}
                            </div>
                        `;

            showTooltip({
                y: selectedPoint.y + interactiveLayerRect.top + (window.scrollY || window.pageYOffset),
                x: selectedPoint.x + interactiveLayerRect.left
            }, content);
        }

        function getLineAndPosition(event: React.MouseEvent<HTMLElement>) {
            const targetAndSelectedItems = {
                targetLine: null,
                targetDot: null,
                selectedPoint: null,
                selectedGroupOfLines: null
            };

            const { left, top } = getClientRect(svgRef.current);
            const mouseY = event.pageY - margins.top - top - (window.scrollY || window.pageYOffset);
            const mouseX = event.pageX - (margins.left + axisYWidth) - left;
            const pointerPosition = { x: mouseX, y: mouseY };

            const linesExist = lines && lines.length;
            const timeLinesExists = timeLines && timeLines.length;

            const nearestOfGraphPoints = getNearestDotTo(pointerPosition, linePoints);
            const nearestOfTimeLinePoints = getNearestDotTo(pointerPosition, timeLinePoints);

            let selectedGroupOfLines;
            let deselectedGroupOfLines;
            let selectedData;

            if(linesExist && timeLinesExists) {
                if (getDistance(nearestOfGraphPoints, pointerPosition) <= getDistance(nearestOfTimeLinePoints, pointerPosition)) {
                    selectedGroupOfLines = lineGroups;
                    deselectedGroupOfLines = timeLineGroups;
                    targetAndSelectedItems.selectedPoint = nearestOfGraphPoints;
                    selectedData = lines;
                } else {
                    selectedGroupOfLines = timeLineGroups;
                    deselectedGroupOfLines = lineGroups;
                    targetAndSelectedItems.selectedPoint = nearestOfTimeLinePoints;
                    selectedData = timeLineDataExtended;
                }
            } else if(linesExist) {
                selectedGroupOfLines = lineGroups;
                deselectedGroupOfLines = timeLineGroups;
                targetAndSelectedItems.selectedPoint = nearestOfGraphPoints;
                selectedData = lines;
            } else if(timeLinesExists) {
                selectedGroupOfLines = timeLineGroups;
                deselectedGroupOfLines = lineGroups;
                targetAndSelectedItems.selectedPoint = nearestOfTimeLinePoints;
                selectedData = timeLineDataExtended;
            } else {
                return;
            }

            if (!targetAndSelectedItems.selectedPoint) {
                return targetAndSelectedItems;
            }
            isDotShownByClick ? selectLine(selectedGroupOfLines, targetAndSelectedItems.selectedPoint.lineIndex)
                : selectLineAndDot(selectedGroupOfLines, targetAndSelectedItems.selectedPoint.lineIndex, targetAndSelectedItems.selectedPoint.dotIndex);
            deselectLine(deselectedGroupOfLines);
            targetAndSelectedItems.targetLine = selectedData[targetAndSelectedItems.selectedPoint.lineIndex];
            targetAndSelectedItems.targetDot = targetAndSelectedItems.targetLine.dots[targetAndSelectedItems.selectedPoint.dotIndex];
            targetAndSelectedItems.selectedGroupOfLines = selectedGroupOfLines;
            return targetAndSelectedItems;
        }

        function clickHandler(event: React.MouseEvent<HTMLElement>) {
            const clickableDot = getLineAndPosition(event).targetDot;
            if (isDotShownByClick) {
                const selectedPoint = getLineAndPosition(event).selectedPoint.dotIndex;
                const targetLine = getLineAndPosition(event).selectedPoint.lineIndex;
                const selectedGroupOfLines = getLineAndPosition(event).selectedGroupOfLines;
                deselectDot(selectedGroupOfLines);
                selectDotClick(selectedGroupOfLines, targetLine, selectedPoint);
            }
            dotClickCallback(clickableDot);
        }

        interactiveLayerGroup
            .on('mouseleave', function() {
                !isDotShownByClick && deselectLine(lineGroups);
                deselectLine(timeLineGroups);
                removeTooltip();
            });

        select(window).on('scroll', function() {
            !isDotShownByClick && deselectLine(lineGroups);
            deselectLine(timeLineGroups);
            removeTooltip();
        }, true);

        return () => {
            interactiveLayerGroup.on('mousemove', null);
            interactiveLayerGroup.on('mouseleave', null);
            removeTooltip();
        };

    }, [axisXParams, axisYParams, defaultMargins, lines, axisDateFormat, timeLines, winSize, tooltipDateFormat, boundaryValues, dotClickCallback, isDotShownByClick]);


    const onSelectGraphLine = (lineIndex) => {
        const lineGroups = select(svgRef.current).select('.graph-group').selectAll('.line-group');
        const timeLineGroups = select(svgRef.current).select('.time-lines-group').selectAll('.line-group');
        selectLineAndDot(lineGroups, lineIndex);
        deselectLine(timeLineGroups);
    };

    const onDeselectGraphLine = () => {
        const lineGroups = select(svgRef.current).select('.graph-group').selectAll('.line-group');
        deselectLine(lineGroups);
    };

    const onSelectTimeLine = (lineIndex) => {
        const lineGroups = select(svgRef.current).select('.graph-group').selectAll('.line-group');
        const timeLineGroups = select(svgRef.current).select('.time-lines-group').selectAll('.line-group');
        selectLineAndDot(timeLineGroups, lineIndex);
        deselectLine(lineGroups);
    };

    const onDeselectTimeLine = () => {
        const lineGroups = select(svgRef.current).select('.time-lines-group').selectAll('.line-group');
        deselectLine(lineGroups);
    };

    return (
        <div className={classNames('line-graph', className)}>
            {lines && (
                <div className="line-graph_legend legend--top">
                    {props.legendTitle && <div className={'legend_title'}>Chart lines:</div>}
                    {lines.map((line, i) => {
                        return (
                            <div className={'legend_item'} key={i}>
                                <input
                                    name={line.title}
                                    type="checkbox"
                                    checked={!line.disabled}
                                    className={'legend_checkbox'}
                                    onChange={({ target: { name, checked: value } }) => onLineToggle(name, value)}/>
                                <a
                                    onMouseOver={() => onSelectGraphLine(i)}
                                    onClick={() => onSelectGraphLine(i)}
                                    onMouseLeave={() => onDeselectGraphLine()}
                                    className={'legend_link'}
                                >
                                    <span className="legend_color" style={{ backgroundColor: line.color }}/>
                                    <span>{line.title}</span>
                                </a>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className={'line-graph__wrapper'}>
                {
                    legendYAxis && (
                        <div className={'line-graph__legend-y-title-wrapper'}>
                            <div className={'line-graph__legend-y-title'}>{legendYAxis}</div>
                        </div>
                    )
                }
                <svg className={'line-graph_svg'} ref={svgRef}/>
            </div>
            {Boolean(timeLines?.length) && (
                <div className="line-graph_legend legend--bottom">
                    <div className={'legend_title'}>Timeline lines:</div>
                    {timeLines.map((line, i) => {
                        return (
                            <a
                                key={i}
                                onMouseOver={() => onSelectTimeLine(i)}
                                onClick={() => onSelectTimeLine(i)}
                                onMouseLeave={() => onDeselectTimeLine()}
                                className={'legend_item'}
                            >
                                <span className="legend_color" style={{ backgroundColor: line.color }}/>
                                <span>{line.title}</span>
                            </a>
                        );
                    })}
                </div>
            )}
            {
                legendXAxis && (
                    <div className={'line-graph__legend-x-title'}>{legendXAxis}</div>
                )
            }
        </div>
    );
};

LineGraph.defaultProps = {
    axisDateFormat: '%d/%m/%y',
    tooltipDateFormat: standardDateTimeFormats.nhs_date_short,
    lines: [],
    timeLines: [],
    boundaryValues: [],
    onLineToggle: (name, value) => {
        //
    },
    dotClickCallback: (targetDot) => {
        //
    },
    legendTitle: true,
};

/**
 *
 * @param domainX - what will be projected onto X range
 * @param domainY - what will be projected onto Y range
 * @param rangeX - to what dimensions X domain will be projected
 * @param rangeY - to what dimensions Y domain will be projected. Pay attention it should be DESC
 */
function createAxisHandlers({ domainX, domainY, rangeX = [0, 500], rangeY = [500, 0] }: {
    domainX: [number, number];
    domainY: [number, number];
    rangeX?: [number, number];
    rangeY?: [number, number];
}) {
    const scaleX = scaleTime().domain(domainX).range(rangeX);
    const scaleY = scaleLinear().domain(domainY).range(rangeY);
    const axisY = axisLeft(scaleY);
    const axisX = axisBottom(scaleX);
    axisY.tickSizeOuter(0);
    axisY.tickPadding(10);
    axisY.tickSizeInner(0);
    return { axisX, axisY, scaleX, scaleY };
}

function showTooltip(coord, content) {
    let tooltip: any = select('body')
        .select('.graph-tooltip');
    let newTooltip = false;
    if (tooltip.empty()) {
        tooltip = select('body')
            .append('div')
            .attr('class', 'graph-tooltip');
        newTooltip = true;
    }
    tooltip = tooltip
        .classed('show', true)
        .html(content);
    const { width, height } = getClientRect(tooltip.node());
    if (!newTooltip) {
        tooltip = tooltip.transition().duration(100);
    }

    let x = Math.max(0, coord.x - (width / 2));
    x = Math.min(x + width, window.innerWidth) - width;

    const y = Math.max(0, coord.y - height - 10);

    tooltip
        .style('top', `${y}px`)
        .style('left', `${x}px`);
}

function removeTooltip() {
    select('body').select('.graph-tooltip').remove();
}

function getEdgeValues(
    lines: Line[] = [],
    boundaryValues: number[] = [],
    axisXParams: AxisParams = {
        min: Infinity,
        max: -Infinity
    },
    axisYParams: AxisParams = {
        min: Infinity,
        max: -Infinity
    }
)  {
    const allDots = lines.reduce((prev, next) => prev.concat(next.dots), []);
    const yMinExternal = axisYParams.min || (axisYParams.min === 0 ? 0 : Infinity);
    const yMax = Math.max(max<Dot, number>(allDots, (d) => d.y) || 100, ...boundaryValues, axisYParams.max || -Infinity);
    const yMin = Math.min(min<Dot, number>(allDots, (d) => d.y) || 0, ...boundaryValues, yMinExternal);
    const xMax = Math.max(max<Dot, number>(allDots, (d) => d.x) || Date.now(), axisXParams.max || -Infinity);
    const xMin = Math.min(min<Dot, number>(allDots, (d) => d.x) || Date.now(), axisXParams.min || Infinity);

    return { x: { max: xMax, min: xMin }, y: { max: yMax, min: yMin } };
}

function addSafeZones({ x: { max: xMax, min: xMin }, y: { max: yMax, min: yMin } }, xMinMax, yMinMax) {
    const pcToAdd = .03;
    const pcYToAdd = .05;

    let addX = (xMax - xMin) * pcToAdd;
    xMax = xMax + addX;
    xMin = xMin - addX;
    let addY = (yMax - yMin) * pcYToAdd;
    yMax = yMax + addY;
    yMin = yMin - addY;

    if (xMinMax?.max != null) {
        addX = 0;
        if(xMinMax?.min != null) {
            addX = (xMinMax.max - xMinMax.min) * pcToAdd;
        }
        xMax = Math.max(xMinMax.max + addX, xMax);
    }

    if (xMinMax?.min != null) {
        addX = 0;
        if(xMinMax?.max != null) {
            addX = (xMinMax.max - xMinMax.min) * pcToAdd;
        }
        xMin = Math.min(xMinMax.min - addX, xMin);
    }

    if (yMinMax?.min != null) {
        addY = 0;
        if(yMinMax?.max != null) {
            addY = (yMinMax.max - yMinMax.min) * pcYToAdd;
        }
        yMin = Math.min(yMinMax.min - addY, xMin);
    }

    if (yMinMax?.min != null) {
        addY = 0;
        if(yMinMax?.max != null) {
            addY = (yMinMax.max - yMinMax.min) * pcYToAdd;
        }
        yMin = Math.min(yMinMax.min - addY, xMin);
    }

    return { x: { max: xMax, min: xMin }, y: { max: yMax, min: yMin } };
}

function getNearestDotTo(dot, dots) {
    let nearest;
    const measureDistance = getDistance.bind(null, dot);
    dots.forEach((d) => {
        const distance = measureDistance(d);
        if (!nearest || measureDistance(nearest) > distance) {
            nearest = d;
        }
    });
    return nearest;
}

function getDistance(d1, d2) {
    return Math.sqrt(Math.pow(d1.y - d2.y, 2) + Math.pow(d1.x - d2.x, 2));
}

function getAxisDimensions({ edgeValues: { x, y }, axisXContainer, axisYContainer }) {
    const { axisY, axisX } = createAxisHandlers({ domainY: [y.min, y.max], domainX: [x.min, x.max] });
    const svg = select('svg.line-graph_svg');
    axisXContainer = svg.append('g').attr('class', 'fake-axis-x-group');
    axisYContainer = svg.append('g').attr('class', 'fake-axis-y-group');
    axisXContainer.call(axisX);
    axisYContainer.call(axisY);
    const { height } = axisXContainer.node().getBoundingClientRect();
    const { width } = axisYContainer.node().getBoundingClientRect();
    axisXContainer.remove();
    axisYContainer.remove();
    return {
        axisXHeight: height,
        axisYWidth: width
    };
}

function calculateTimeLineHeight(linesCount = 0, lineHeight, margin) {
    let timeLinesSpaceHeight = lineHeight * linesCount;
    timeLinesSpaceHeight = timeLinesSpaceHeight ? timeLinesSpaceHeight + margin : 0;
    return timeLinesSpaceHeight;
}

function updateDots(this: SVGAElement, dotType: DotType, color: string, translationHandler) {
    const dots = select<SVGAElement, Line>(this)
        .selectAll('g.dot-group')
        .data(({ dots }) => dots);

    dots.exit().remove();

    const newDots = dots.enter()
        .append('g')
        .attr('class', 'dot-group');

    const contentCont = newDots
        .append('g')
        .attr('class', (d) => {
            return 'dot-group_content' + (d.emphasized ? ' dot-group--emphasized' : '');
        });

    newDots
        .attr('transform', translationHandler);

    appendRequiredTypeOfDots(dotType, contentCont, 8, color);

    dots.transition()
        .attr('transform', translationHandler);

    dots.merge(newDots as any)
        .style('fill', color);

}

function triangle(size, direction: 'up' | 'down') {
    const half = size / 2;
    return direction === 'up' ?
        `M ${0},-${half} L ${half},${half} L -${half},${half} z` :
        `M -${half},-${half} L ${half},-${half} L ${0},${half} z`;
}

function square(size) {
    return `M -${size / 2},-${size / 2} L ${size / 2},-${size / 2} L${size / 2},${size / 2} L -${size / 2},${size / 2} z`;
}

function appendRequiredTypeOfDots(dotType: DotType, dots, size = 8, color) {
    dots.append('circle')
        .attr('class', 'selection-circle')
        .attr('r', 2);

    dots.append('circle')
        .attr('class', 'emphasizing-circle')
        .attr('r', 2)
        .style('stroke', color);

    switch (dotType) {
    case DotType.Round:
        dots.append('circle')
            .attr('class', 'dot-shape')
            .attr('fill', d => d.color)
            .attr('r', size / 2);
        break;
    case DotType.Rhombus:
        dots.append('path')
            .attr('class', 'dot-shape')
            .attr('fill', d => d.color)
            .attr('d', square(size))
            .attr('transform', 'rotate(45)');
        break;
    case DotType.Square:
        dots.append('path')
            .attr('class', 'dot-shape')
            .attr('fill', d => d.color)
            .attr('d', square(size));
        break;
    case DotType.TriangleUp:
        dots.append('path')
            .attr('class', 'dot-shape')
            .attr('fill', d => d.color)
            .attr('transform', 'translate(0, -1)')
            .attr('d', triangle(size + 1, 'up'));
        break;
    case DotType.TriangleDown:
        dots.append('path')
            .attr('class', 'dot-shape')
            .attr('fill', d => d.color)
            .attr('transform', 'translate(0, 1)')
            .attr('d', triangle(size + 1, 'down'));
        break;
    }
}

function deselectLine(lineGroups) {
    lineGroups
        .transition()
        .style('opacity', 1);
    lineGroups
        .each(function(this: SVGAElement) {
            select(this)
                .selectAll('g.dot-group')
                .classed('selected', false);
        });
}

function deselectDot(lineGroups) {
    lineGroups
        .each(function(this: SVGAElement) {
            select(this)
                .selectAll('g.dot-group')
                .classed('selected', false);
        });
}

function selectLineAndDot(lineGroups, line: number, point?: number) {
    lineGroups
        .transition()
        .delay(100)
        .duration(50)
        .style('opacity', (d, i) => i === line ? 1 : .2);
    select(lineGroups.nodes()[line])
        .selectAll('g.dot-group')
        .classed('selected', (d, i) => i === point);
}

function selectLine(lineGroups, line: number) {
    lineGroups
        .transition()
        .delay(100)
        .duration(50)
        .style('opacity', (d, i) => i === line ? 1 : .2);
}

function selectDotClick(lineGroups, line: number, point?: number) {
    select(lineGroups.nodes()[line])
        .selectAll('g.dot-group')
        .classed('selected', (d, i) => i === point);
}

function updateTimeLines(data: Line[], selection, scale, graphWidth) {

    let lineGroups = selection.selectAll('g.line-group')
        .data(data);
    lineGroups.exit().remove();
    const newLineGroups = lineGroups.enter()
        .append('g')
        .attr('class', 'line-group');

    newLineGroups.append('path')
        .attr('fill', 'none')
        .attr('stroke', (d) => d.color)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 2);

    newLineGroups
        .append('text')
        .attr('fill', (d) => d.color)
        .attr('transform', 'translate(3, -5)')
        .attr('class', 'timeline-text');

    lineGroups = lineGroups.merge(newLineGroups);

    lineGroups
        .attr('transform', ({ dots }) => `translate(${0}, ${scale.y(dots[0]?.y || 0)})`);
    lineGroups
        .select('path')
        .transition()
        .attr('d', (d) => {
            return `M 0,0 L ${graphWidth},0`;
        });
    lineGroups
        .select('text')
        .text((d) => d.title);

    lineGroups.each(function(this: SVGAElement, data) {
        updateDots.call(this, data.dotType, data.color, (d) => `translate(${scale.x(d.x)}, ${0})`);
    });

    return lineGroups;
}

function updateLines(data: Line[], selection, scale) {

    const pathGenerator = line()
        .x((d: any) => scale.x(d.x))
        .y((d: any) => scale.y(d.y));

    let lineGroups = selection.selectAll('g.line-group')
        .data(data, (d) => d.title);
    lineGroups.exit().remove();
    const newLineGroups = lineGroups.enter()
        .append('g')
        .attr('class', 'line-group');

    newLineGroups.append('path')
        .attr('fill', 'none')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 2);

    lineGroups = lineGroups.merge(newLineGroups);
    lineGroups
        .attr('stroke', (d) => d.color);
    lineGroups
        .select('path')
        .transition()
        .attr('d', (d) => {
            return pathGenerator(d.dots);
        });
    lineGroups.each(function (this: SVGAElement, data) {
        updateDots.call(this, data.dotType, data.color, (d) => `translate(${scale.x(d.x)}, ${scale.y(d.y)})`);
    });

    return lineGroups;
}

function drawBoundaryLines({ lineGroups, scaleX, scaleY, xMin, xMax, boundaryValues }) {
    boundaryValues.forEach(boundaryValue => lineGroups
        .append('line')
        .attr('x1', scaleX(xMin))
        .attr('x2', scaleX(xMax))
        .attr('y1', scaleY(boundaryValue))
        .attr('y2', scaleY(boundaryValue))
        .attr('stroke', '#000'));
}

function appendYCoordinate({ lines, spacing, scaleY, marginTop }) {
    lines = cloneDeep(lines);
    return lines.map((line, lineIndex) => {
        line.dots = line.dots.map((dot) => {
            dot.y = scaleY.invert(lineIndex * spacing + marginTop);
            return dot;
        });
        return line;
    });
}

function getClientRect(el?: HTMLElement | SVGAElement | null): {
    right: number;
    left: number;
    top: number;
    bottom: number;
    width: number;
    height: number;
    x: number;
    y: number;
} {

    if (!el) {
        return {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            width: 0,
            height: 0,
            x: 0,
            y: 0
        };
    }

    const rect = el.getBoundingClientRect();
    return {
        left: rect.left,
        right: rect.right,
        top: rect.top,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
        x: rect.x,
        y: rect.y,
    };
}
