import React, {FC, useRef, useState} from 'react';

const useOTPValue = (onOTPEntryUpdate?: (otpCode: string, complete: boolean) => unknown): [
    otpValue: string[],
    setOTPValues: (values: string[]) => void,
    setOTPValue: (value: string, idx: number) => void,
    clearOTPValues: () => void,
    clearOTPValue: (idx: number) => void
] => {
    const blankOTP = () => ['', '', '', '', '', ''];
    const [otpValues, setOTPValue] = useState<string[]>(blankOTP());
    const setOTPValues = (values: string[]): void => {
        setOTPValue(values);
        if (onOTPEntryUpdate) {
            const otpCode = values.join('');
            onOTPEntryUpdate(otpCode, otpCode.length === 6);
        }
    }
    const setOTPValueForIndex = (value: string, id: number) => {
        const newOTPValue = [...otpValues];
        newOTPValue[id] = value;
        setOTPValues(newOTPValue);
    }
    return [
        otpValues,
        setOTPValues,
        setOTPValueForIndex,
        () => setOTPValues(blankOTP()),
        (idx) => setOTPValueForIndex('', idx)
    ]
}

export const OTPEntry: FC<{
    onOTPEntryUpdate?: (otpCode: string, complete: boolean) => unknown;
    clearEntry?: React.MutableRefObject<() => unknown>
}> = ({
    onOTPEntryUpdate,
    clearEntry
}) => {

    const [otpValue, setOTPValues, setOTPValue, clearOTPValues, clearOTPValue] = useOTPValue(onOTPEntryUpdate);
    if (clearEntry) {
        clearEntry.current = clearOTPValues;
    }
    const otpInputRef = useRef([]);

    const onOTPInputChange = (evt, id: number) => {
        const value = evt.target.value as string;
        if (value) {
            if (value.match(/^\d+$/)) {
                if (value.length === 1) {
                    setOTPValue(value, id);
                    if ((id + 1) < otpInputRef.current.length) {
                        otpInputRef.current[id + 1].focus();
                    }
                } else if (value.length === 2 && (id + 1) < otpInputRef.current.length) {
                    const numbers = value.split('');
                    setOTPValue(numbers[0], id);
                    setOTPValue(numbers[1], id+1);
                    //If we can jump the next field and continue, let's do that
                    if ((id + 2) < otpInputRef.current.length) {
                        otpInputRef.current[id + 2].focus();
                    } else {
                        otpInputRef.current[id + 1].focus();
                    }
                } else if (value.length === 6) {
                    setOTPValues(value.split(''));
                }
            }
        } else {
            evt.target.value = ''; //handling for weird Javascript 'numeric' cases like 'e'
            clearOTPValue(id);
            if (id > 0) {
                otpInputRef.current[id-1].focus();
            }
        }
    }

    const onOTPInputKeyUp = (evt, id: number) => {
        if (evt.key === 'Backspace'
            && evt.target.value === ''
            && id > 0) {
            otpInputRef.current[id-1].focus();
        }
    }

    return (<div className='hflow-vcenter ant-row-center'>
        {otpValue.map((val, idx) => (
            <input type='number'
                   autoFocus={idx === 0}
                   key={idx}
                   min={0}
                   max={9}
                   required={true}
                   className='otp-entry'
                   value={otpValue[idx]}
                   ref={el => otpInputRef.current[idx] = el}
                   onChange={(evt) => onOTPInputChange(evt, idx)}
                   onInput={(evt) => onOTPInputChange(evt, idx)}
                   onKeyUp={evt => onOTPInputKeyUp(evt, idx)}
            />
        ))}
    </div>);
}