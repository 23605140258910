import React, { useState, useCallback, useEffect } from 'react';

export function useShowViewedQuestions(questionsWithAnswer, viewedQuestions) {
    const [isShowQuestion, setIsShowQuestions] = useState(false);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const questions =  questionsWithAnswer
            .filter((data) => isShowQuestion || !viewedQuestions || viewedQuestions && viewedQuestions.indexOf(data.questionIndex) !== -1)
        setQuestions(questions);
    }, [isShowQuestion, questionsWithAnswer]);

    const showViewedQuestions = useCallback(
        () => {
            setIsShowQuestions(true);
        }, [],
    );

    const hideViewedQuestions = useCallback(
        () => {
            setIsShowQuestions(false);
        }, [],
    );

    return { questions, isShowQuestion, showViewedQuestions, hideViewedQuestions};
}
