import React from 'react';
import BasePage from 'components/page/base';

import {ResponsiveTable, Row, Column} from 'ui';
import _ from 'services/i18n';
import HeadingDoc from 'ui/heading-doc';
import Events from './_events.js';
import FormPanel from 'components/form-panel';
import DateInputField from 'components/form-panel/date-inputs/date-input-field';

export default class extends BasePage {
    constructor (props) {
        super(props);
        let from = new Date();

        from.setMonth(from.getMonth()-1);

        this.state = {
            loading: false,
            percentage: "",
            total: "",
            overdue: "",
            fromDate: from,
            toDate: new Date(),
            formKey: 1,
            alertText: false
        };

        this.bindEvents(['Submit', 'Reset','DownloadCSV'], new Events);
    }

    pageTitle () {
        return _`Overdue EQ-5D™ questionnaires | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="report-tab">
                <HeadingDoc title="Overdue EQ-5D™ questionnaires">
                    {_`The report below will display the percentage of EQ-5D™ issued between the dates specified which are overdue.`}
                </HeadingDoc>
                <Page {...this.state} events={this.events}/>
            </div>
        );
    }
}

const Page = (props) => {
    return (
        <div>
            <ReportForm {...props} />
            <Results {...props} />
        </div>
    );
};

const ReportForm = (props) => {
    let alertType, alertText;

    if (props.loading) {
        alertType = 'info';
        alertText = _`Loading. Please wait...`;
    }

    if (props.alertText) {
        alertType = 'danger';
        alertText = props.alertText;
    }

    return (
        <div className="report-form">
            <FormPanel
                key={props.formKey}
                title={_`Refine Report`}
                intro={_`Please enter the date period`}
                id='report-form'
                onSubmit={props.events.onSubmit}
                onReset={props.events.onReset}
                submitLabel={_`Run Report`}
                resetLabel={_`Discard Changes`}
                busy={props.loading}
                alertText={alertText}
                alertType={alertType}>

                <DateInputField
                    label={_`From Date:`}
                    id='report-from-date'
                    initialValue={props.fromDate}
                    pastOnly={true}
                />

                <DateInputField
                    label={_`To Date:`}
                    id='report-to-date'
                    initialValue={props.toDate}
                    pastOnly={true}
                />

            </FormPanel>
        </div>
    );
};

const Results = (props) => {
    if (props.total == '') {
        return (
            <div>
                <div className="alert alert-info">
                    <span>Please select a date range and run search above to view the results.</span>
                </div>
            </div>
        );
    }


    return (
        <div>
            <div className="form-group">
                <button type="button" className="btn btn-success" onClick={props.events.onDownloadCSV}>Download as CSV</button>
            </div>
            <Row>
                <Column md="4">
                    <ResponsiveTable>
                        <thead>
                        <tr>
                            <th>Questionnaires Issued in Time Period:</th><th>{props.total}</th>
                        </tr>
                        <tr>
                            <th>Questionnaires Overdue in Time Period:</th><th>{props.overdue}</th>
                        </tr>
                        <tr>
                            <th>Percentage of Questionnaires Overdue:</th><th>{props.percentage}%</th>
                        </tr>
                        </thead>
                    </ResponsiveTable>
                </Column>
            </Row>
        </div>
    );
};
