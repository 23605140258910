import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';

import UserMessageListComponent from 'components/user/message/list';

export default class MessagesPage extends BasePage {
    constructor(props) {
        super(props);

        this.state = {
            folderId: this.props.folderId
        };

        this.backRoute = `${this.getFolderPath()}/patient`;
        this.baseRoute = `${this.getFolderPath()}/patient/messages`;
        this.createMessageRoute = `${this.getFolderPath()}/patient/messages/create`;
    }

    pageTitle() {
        return _`${this.$p()} Messages | MyPathway Clinical Portal`;
    }

    render() {
        return (
            <UserMessageListComponent backRoute={this.backRoute}
                baseRoute={this.baseRoute}
                createRoute={this.createMessageRoute}
                clinicalView={true}
                folderId={this.state.folderId}
                {...this.props} />
        );
    }
}
