import React from 'react';
import BasePage from 'components/page/base';

import {ResponsiveTable, Row, Column} from 'ui';
import _ from 'services/i18n';
import HeadingDoc from 'ui/heading-doc';
import Events from './_events.js';
import FormPanel from 'components/form-panel';
import DateInputField from 'components/form-panel/date-inputs/date-input-field';
import {DateTime} from 'common/datetime/DateTime';
import NhsNumber from 'ui/nhs-number';
import PathwayFromNhs from 'services/pathway-helper';

const questionHeaders = {
    1: 'MOBILITY',
    2: 'SELF-CARE',
    3: 'USUAL ACTIVITIES',
    4: 'PAIN / DISCOMFORT',
    5: 'ANXIETY / DEPRESSION',
    6: 'VAS'
};

export default class extends BasePage {
    constructor (props) {
        super(props);
        let from = new Date();

        from.setMonth(from.getMonth()-1);

        this.state = {
            loading: false,
            results: [],
            notRun: true,
            fromDate: from,
            toDate: new Date(),
            formKey: 1,
            alertText: false
        };

        this.bindEvents(['Submit', 'Reset','DownloadCSV'], new Events);
    }

    pageTitle () {
        return _`Completed EQ-5D™ questionnaires | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="report-tab">
                <HeadingDoc title="Completed EQ-5D™ questionnaires ">
                    {_`The report below will display all EQ-5D™ questionnaires that have been completed between the dates specified.`}
                </HeadingDoc>
                <Page {...this.state} events={this.events}/>
            </div>
        );
    }
}

const Page = (props) => {
    return (
        <div>
            <ReportForm {...props} />
            <Results {...props} />
        </div>
    );
};

const ReportForm = (props) => {
    let alertType, alertText;

    if (props.loading) {
        alertType = 'info';
        alertText = _`Loading. Please wait...`;
    }

    if (props.alertText) {
        alertType = 'danger';
        alertText = props.alertText;
    }

    return (
        <div className="report-form">
            <FormPanel
                key={props.formKey}
                title={_`Refine Report`}
                intro={_`Please enter the date period`}
                id='report-form'
                onSubmit={props.events.onSubmit}
                onReset={props.events.onReset}
                submitLabel={_`Run Report`}
                resetLabel={_`Discard Changes`}
                busy={props.loading}
                alertText={alertText}
                alertType={alertType}>

                <DateInputField
                    label={_`From Date:`}
                    id='report-from-date'
                    initialValue={props.fromDate}
                    pastOnly={true}
                />

                <DateInputField
                    label={_`To Date:`}
                    id='report-to-date'
                    initialValue={props.toDate}
                    pastOnly={true}
                />

            </FormPanel>
        </div>
    );
};

const Results = (props) => {
    if (props.notRun) {
        return (
            <div>
                <div className="alert alert-info">
                    <span>Please select a date range and run search above to view the results.</span>
                </div>
            </div>
        );
    }

    if (props.results.length == 0) {
        return (
            <div>
                <div className="alert alert-info">
                    <span>No results have been returned for this time period. You may need to increase the time period to see results.</span>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="form-group">
                <button type="button" className="btn btn-success" onClick={props.events.onDownloadCSV}>Download as CSV</button>
            </div>
            <ResponsiveTable>
                <thead>
                <tr>
                    <th>NHS Number</th>
                    <th>Timestamp</th>
                    <th>{questionHeaders[1]}</th>
                    <th>{questionHeaders[2]}</th>
                    <th>{questionHeaders[3]}</th>
                    <th>{questionHeaders[4]}</th>
                    <th>{questionHeaders[5]}</th>
                    <th>{questionHeaders[6]}</th>
                </tr>
                </thead>
                <ResultRows {...props} />
            </ResponsiveTable>
        </div>
    );
};

const ResultRows = (props) => {
    let list = props.results.map((item, idx) => {
        return (
            <ResultRow key={idx} {...item} />
        );
    });

    return (
        <tbody>
        {list}
        </tbody>
    );
};

const ResultRow = (props) => {
    return (
        <tr>
            <td><PathwayFromNhs value={props.nhs_number}><NhsNumber empty="NO NHS NUMBER">{props.nhs_number}</NhsNumber></PathwayFromNhs></td>
            <td><DateTime>{props.timestamp}</DateTime></td>
            <td>{props.q1}</td>
            <td>{props.q2}</td>
            <td>{props.q3}</td>
            <td>{props.q4}</td>
            <td>{props.q5}</td>
            <td>{props.vas}</td>
        </tr>
    );
};
