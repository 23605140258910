import React, { useCallback, useState, useRef, useEffect } from 'react';
import './TagsInput.less';

export const ControlledTagsInput = ({
    tags = [],
    currentTag = '',
    onChange = (tags: string[]) => void 0,
    onCurrentTagChange = (event: any) => void 0,
    onKeyDown = (event: any) => void 0,
    onKeyUp = (event: any) => void 0,
    onFocus = () => void 0,
    onBlur = () => void 0,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);

    const handleInputKeyDown = useCallback(
        (event) => {
            onKeyDown(event);
            if (event.key === 'Backspace' && !currentTag && tags.length) {
                onChange(tags.filter(tag => tag !== tags[tags.length - 1]));
            }
        }, [onChange, onKeyDown, tags, currentTag]
    );

    useEffect(() => {
        if (isFocused) {
            onFocus();
        } else {
            onBlur();
        }
    }, [isFocused, onFocus, onBlur]);

    const focusInput = useCallback(
        () => {
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
            }
        },
        [],
    );

    const handleRemoveButtonClick = useCallback(
        (event, tagToRemove: string) => {
            event.stopPropagation();
            onChange(tags.filter(tag => tag !== tagToRemove));
            focusInput();
        }, [focusInput, onChange, tags]
    );

    return (
        <div
            className={`tags-input__container form-control ${isFocused ? 'focus' : ''}`}
            onClick={focusInput}
        >
            <div className="tags-input__tags">
                {tags.map(tag => (
                    <div
                        key={tag}
                        className="tags-input__tag label label-primary"
                        role="button"
                    >{tag}<span
                            className="tags-input__remove-tag-button"
                            onClick={(event) => handleRemoveButtonClick(event, tag)}
                        ></span>
                    </div>
                ))}
            </div>

            <div className="tags-input__input">
                <input type="text" value={currentTag}
                    ref={inputRef}
                    onChange={onCurrentTagChange} onKeyDown={handleInputKeyDown}
                    onKeyUp={onKeyUp}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </div>
        </div>
    );
};
