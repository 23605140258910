/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import { MessageCreateForm } from 'components/user/message/create/form';
import { SendMessageState, validate, normalise } from 'components/user/message/create/validator';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationCostEstimatorNone } from './BulkOperationCostEstimator';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import { CompositionService } from 'services/composition.service';
import { NOTIFICATION_TYPE } from 'services/push-notifications.service';
import { Timeline, TimelineContext, TimelineTranslations } from 'msk-timeline';
import marked from 'marked';
import { messageTemplateService } from 'services/message-template.service';
import { BulkOperationRequestAction, BulkOperationRequestSendTimelineMessage, BulkOperationRequestSendPushNotification } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';

const initialFormState: SendMessageState = { title: '', content: '', sendPush: false };

export class BulkOperationSendMessageConfigurator extends BulkOperationConfigurationComponent<SendMessageState>
{
    private readonly messageTemplateService: CompositionService;

    public validate: () => boolean;

    constructor(messageTemplateServiceVal: CompositionService = messageTemplateService)
    {
        super();
        this.messageTemplateService = messageTemplateServiceVal;
    }

    public getActionName(): string
    {
        return 'Send Message';
    }

    public getInitialFormState(): SendMessageState
    {
        return initialFormState;
    }

    public SetupComponent(): JSX.Element
    {
        const [currentErrors, onErrors] = useState<string[]>([]);

        const { currentFormState, onFormStateChange } = this.useFormState();

        this.validate = () => {return validate(this.getFormState(), onErrors);};

        return (
            <MessageCreateForm
                loading={false}
                errors={currentErrors}
                backRoute={''}
                onChange={(c) => onFormStateChange({ ...currentFormState, ...c })}
                form={currentFormState}
                onSubmit={(f) => f.preventDefault()}
                hideSubmit={true}
                messageTemplateService={this.messageTemplateService}
            />
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<SendMessageState>
    {
        return new BulkOperationSendMessagePreviewBuilder(selectionState, normalise(this.getFormState()));
    }
}

export class BulkOperationSendMessagePreviewBuilder extends BulkOperationPreviewBuilder<SendMessageState>
{
    public getActionName(): string
    {
        return 'Send Message';
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[]
    {
        const items: BulkOperationPreviewItem[] = [];

        const costEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);
        
        const messageTimeline = {
            document_type: 'message',
            content: {},
            timelineOrder: 1,
            timelineType: 'message',
            timelineDate: new Date(),
            lozenge: {
                title: this.getActionState().title,
                description: this.getActionState().content
            },
        };
        
        items.push(new BulkOperationPreviewItem(
            <TimelineContext.Provider value=
                {{
                    currentLanguage: 'en',
                    getMarkedHTML: (markdown) => {
                        if (!markdown) {
                            return { __html: '' };
                        }
                        return { __html: marked(markdown) };
                    },
                    getTranslate: (str) => {TimelineTranslations[str] ? TimelineTranslations[str] : str;},
                    onTimelineElementClick() {
                        console.log('clicked');
                    }
                }}
            >
                <Timeline
                    timeline={[messageTimeline]}
                    showPopup={false}
                />
            </TimelineContext.Provider>, costEstimatorMessage));

        if (this.getActionState().sendPush) {
            const costEstimatorPushNotification = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);
            items.push(new BulkOperationPreviewItem(<div key={2}>Push notification will be sent.</div>, costEstimatorPushNotification));
        }

        return items;
    }

    public getBulkOperations(): Promise<BulkOperationRequestAction<TriggerEngineRequestContent>[]> {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationRequestSendTimelineMessage({
            title: this.getActionState().title,
            content: this.getActionState().content,
            sender_name: 'clinician',
            type: 'clinician'
        }));

        if (this.getActionState().sendPush)
        {
            request.push(new BulkOperationRequestSendPushNotification({ notification: NOTIFICATION_TYPE.MESSAGE }));
        }

        return Promise.resolve(request);
    }
}
