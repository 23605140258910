import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';

export default class extends BaseComponent {
    constructor (props) {
        super(props);

        this.state = {
            emptyImage: true,
            temporaryFile: '',
            linkValue: '',
            error: false
        };

        this.bindEvents([
            'ChangeImage',
            'ChangeLink',
            'InternalUpload'
        ]);

        this.View = View;

        this.fileReader = new FileReader();
        this.fileReader.onload = this.onLoadFile.bind(this);
    }

    onLoadFile (fileEvent) {
        var result = fileEvent.target.result,
            isImage = result.match(/^data:(.*?);base64,/);

        if (!isImage[1]) {
            console.log('Invalid Upload');
            return;
        }

        switch (isImage[1]) {
            case 'image/jpeg':
            case 'image/jpg':
            case 'image/gif':
            case 'image/png':
                this.setState({
                    emptyImage: false,
                    temporaryFile: result
                });
                break;
            default:
                console.log('Not an image', isImage[1]);
        }
    }

    handleChangeImage (e) {
        var fileElement = e.target;

        if (fileElement.files.length > 0) {
            if (fileElement.files[0].size > 500000) {
                this.setState({
                    emptyImage: true,
                    temporaryFile: '',
                    error: 'File too large. 500kb max'
                });
            } else {
                this.fileReader.readAsDataURL(fileElement.files[0]);
            }
        }
    }

    handleChangeLink (e) {
        this.setState({
            linkValue: e.currentTarget.value
        });
    }

    handleInternalUpload () {
        let strippedResult = this.state.temporaryFile.match(/^data:(.*?);base64,(.*)$/);

        this.props.onUpload(strippedResult[2], this.state.linkValue);
        this.props.onClose();
    }
}
