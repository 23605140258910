import React from 'react';
import _ from 'services/i18n';
import Select from 'react-select';

const CollectionSelect = ({ categoryList, category_id, onChange }) => {
    const list = categoryList.map(({ uuid, content: { name } }, idx) => {
        return { value: uuid, label: name };
    });
    return (
        <Select
            value={category_id}
            options={list}
            onChange={onChange}
            placeholder={_`Select Collection`}
            isClearable={true}
        />
    );
};

export default CollectionSelect;
