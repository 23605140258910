import React, { FC } from 'react';
import { Link } from 'react-router';
// styles
import './DashboardNavigation.scss';

export type DashboardTabType =
    | 'registered'
    | 'invited'
    | 'pending'
    | 'advanced-search';

export const DashboardTabTypeList: DashboardTabType[] = [
    'registered',
    'invited',
    'pending',
    'advanced-search'
];

export interface DashboardTab {
    title: string;
    type: DashboardTabType;
}

interface DashboardNavigationProps {
    activeTab: DashboardTabType | null;
    tabs: DashboardTab[];
    onTabChange: (t: DashboardTabType) => void;
    currentRoute: string;
}

export const DashboardNavigation: FC<DashboardNavigationProps> = ({
    activeTab,
    tabs,
    onTabChange,
    currentRoute,
}) => {
    const handleTabChange =
        (tab: DashboardTabType) => (ev: React.MouseEvent<Link>) => {
            ev.preventDefault();

            onTabChange(tab);
        };

    return tabs.length > 1 && (
        <div className="dashboard-navigation-container">
            <div>
                { tabs.map((tab) => {
                    return (
                        <Link
                            key={tab.type}
                            to={`${currentRoute}?filter=${tab.type}&page=1`}
                            className={`tab ${
                                tab.type === activeTab ? 'active' : ''
                            }`}
                            onClick={handleTabChange(tab.type)}
                        >
                            {tab.title}
                        </Link>
                    );
                }) }
            </div>
        </div>
    );
};
