import React from 'react';
import {Link} from 'react-router';
import PaginationView from 'components/pagination';
import _ from 'services/i18n';
import {DateTime} from 'common/datetime/DateTime';

class Index extends React.Component {
    renderResultRows () {

        if (this.props.loading) {
            return (
                <tr>
                    <td colSpan="5">
                        {_`Loading Questionnaire Templates... please wait`}
                    </td>
                </tr>
            );
        }

        const results = this.props.displayResults || [];

        if (results.length == 0) {
            return (
                <tr>
                    <td colSpan="5">
                        {_`Sorry, no matching results found`}
                    </td>
                </tr>
            );
        }

        return results.map((item, idx) => {

            let linkPath;
            let name;
            let version;
            if (item.type === 'template'){
                version = item.document.version;
                name = item.document.name;
                linkPath = `/admin_portal/questionnaire-templates/${item.document.id}`;
            }else{
                version = item.document.content.version;
                name = item.document.content.name;
                linkPath = `/admin_portal/questionnaire-templates/${item.document.uuid}?composition`;
            }


            return (
                <tr key={idx}>
                    <td><Link to={linkPath}>{name}</Link></td>
                    <td>{version}</td>
                    <td>{item.document.created_user ? item.document.created_user.name : 'System'}</td>
                    <td><DateTime>{item.document.created_at}</DateTime></td>
                    <td>
                        <Link to={linkPath}>{_`View Template`}</Link>
                    </td>

                </tr>
            );
        });
    }

    renderResults () {
        return (
            <div className="table-responsive">
                <table className='table table-bordered table-condensed table-striped table-nomargin'>
                    <thead>
                    <tr>
                        <th>{_`Name`}</th>
                        <th>{_`Version`}</th>
                        <th>{_`Created By`}</th>
                        <th>{_`Date Created`}</th>
                        <th>{_`Actions`}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderResultRows()}
                    </tbody>
                </table>
            </div>
        );
    }

    renderTopBar () {
        return (
            <div>
                <div className='top-bar'>
                    <Link to='/admin_portal'>{_`Back to Dashboard`}</Link>
                    <Link to={'/admin_portal/questionnaire-templates/create'}>{_`Add New Questionnaire Template`}</Link>
                </div>
                <h3>{_`Questionnaire Templates`}</h3>
            </div>
        )
    }


    render () {
        return (
            <div className='questionnaire-template-list'>

                {this.renderTopBar()}
                {this.renderResults()}

                <PaginationView currentPage={this.props.pagination.current}
                                pageCount={Math.ceil(this.props.count / this.props.perPage)}
                                onChange={this.props.onChangePage}/>
            </div>
        );
    }
}

export default Index;
