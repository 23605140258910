import { TeamPrefItem } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/DashboardQuestionnaireTable';
import { getQuestionnaireResults } from 'features/patient/questionnaire-results/getQuestionnaireResults';
import { QuestionnaireResult, QuestionnaireScoreResults } from 'models/QuestionnaireResult';
import { GridItem } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/dashboard-questionnaire-row/DashboardQuestionnaireRow';
import moment from 'moment';

export const getDataForQuestionnaireTable = (params: {folderId: number; teamId: number; context: string; items: TeamPrefItem[]}) => {
    const { folderId, teamId, context, items } = params;

    return getQuestionnaireResults({ folderId, teamId, pathwayContext: context })
        .then((data) => {
            if(data) {
                return prepareGridItems(items, data, folderId);
            }
        });
};

export const prepareGridItems = (items: TeamPrefItem[], questionnaireResults: QuestionnaireScoreResults, folderId): GridItem[] => {
    const allScores = [];

    items.forEach((item) => {
        const data = questionnaireResults[item.questionnaireName];
        if (data) {
            const filteredByMeasure = data.filter((value) => value.score_name.toUpperCase() === item.measure.toUpperCase());
            filteredByMeasure.length && allScores.push(filteredByMeasure);
        }
    });

    allScores.forEach((scores) => {
        scores.sort((a, b) => a.timestamp - b.timestamp);
    });
    const calculateDateDiff = (penultDate, prevDate) => {
        if (!prevDate || !prevDate.timestamp) {
            return -1;
        }
        const a = moment.unix(penultDate.timestamp);
        const b = moment.unix(prevDate.timestamp);
        return a.diff(b, 'days');
    };

    return allScores.map((score: QuestionnaireResult[]) => {
        const penultScore = score.pop()  as QuestionnaireResult;
        const previousScore = score.pop() || { 'score_value_string': '0' };
        const diff = (!penultScore || !previousScore['timestamp']) ? '-' : parseInt(penultScore.score_value_string) - parseInt(previousScore.score_value_string);
        const changePeriod = calculateDateDiff(penultScore, previousScore);
        return {
            measure: penultScore.score_name,
            score: penultScore.score_value_string,
            diff: diff > 0 ? `+${diff}` : String(diff),
            url: `/clinical_portal/folder/${folderId}/patient/questionnaires/${penultScore.questionnaire_response_uuid}?questionnaire`,
            date: penultScore.timestamp,
            changePeriod
        };
    });
};
