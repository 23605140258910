import React, { Fragment, useEffect, useState, useMemo } from 'react';
import _ from 'services/i18n';
import { Loading } from 'common/ui/alert-boxes';
import { useForm } from 'common/form/useForm';
import { useActiveReferrals } from '../referrals/useActiveReferrals';
import { terService } from 'services/ter.service';
import { isActiveLabelPredicate } from 'features/pathway-labels/usePathwayNames';
import { SimpleDropdown } from 'common/ui/dropdown/SimpleDropdown';
import { useCareEvents } from './useCareEvents';
import { Link } from 'react-router';

const careEventState = {
    careEvent: { value: '', error: '' },
    pathway: { value: '', error: '' },
    userNoteTitle: { value: '', error: '' },
    userNoteComment: { value: '', error: '' },
};

const careEventSchema = {
    careEvent: {
        required: true,
    },
    pathway: {
        required: true,
    },
    userNoteTitle: {
    },
    userNoteComment: {
        required: true,
    },
};

export const CareEventDialog = ({ folderId, teamId }) => {
    const [formError, setFormError] = useState('');
    const [careEvents] = useCareEvents({ folderId, teamId });
    const [preparedPathways, isFetchingPathways] = usePreparedPathways({ folderId, teamId });

    const pathname = window.location.search;
    const prevRoute = pathname.substring(pathname.lastIndexOf('=') + 1);

    async function onSubmitForm(state) {
        setFormError('');
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const [referral, pathway] = preparedPathways[state.pathway.value];

        const newLabel = {
            status: true,
            name: state.careEvent.value,
            type: 'care-event',
            context: pathway.context
        };

        const data = {
            status: 'active',
            title: state.userNoteTitle.value,
            comment: state.userNoteComment.value,
            // eslint-disable-next-line @typescript-eslint/camelcase
            parent_doc_uuid: referral.uuid,
            labels: [newLabel],
        };

        if (!data.title) { delete data.title; }
        if (!data.type) { delete data.type; }
        if (!data.parent_doc_uud) { delete data.parent_doc_uud; }

        try {
            await terService.createTer({ action: 'createUserNote', data, folderId });
            window.location.pathname = `/clinical_portal/folder/${folderId}/patient/${prevRoute}`;
        } catch (err) {
            setFormError(_`Failed to add care event.`);
            console.error(err);
        }
    }

    const { state, handleOnChange, handleOnSubmit, disable } = useForm(
        careEventState,
        careEventSchema,
        onSubmitForm,
    );

    useEffect(() => {
        setFormError('');

        if (!isFetchingPathways && (!preparedPathways || !preparedPathways.length)) {
            setFormError(_`No active pathways for patient.`);
        }

        if (preparedPathways && preparedPathways.length === 1) {
            handleOnChange({
                target: {
                    name: 'pathway',
                    value: '0',
                }
            });
        }
    }, [preparedPathways, isFetchingPathways, handleOnChange]);

    const careEventItems = careEvents.map(({ careEventName }) => [careEventName, careEventName]);
    const careEvent = careEvents.find(({ careEventName }) => careEventName === state.careEvent.value);

    const pathwaysOptions = preparedPathways.map(([, label, isContextNeeded], index) => {
        const name = `${label.name}${isContextNeeded}` ? ` (${label.context})` : '';
        return <option value={index} key={index}>{name}</option>;
    });

    const handleOnChangeCareEvent = (event) => {
        handleOnChange(event);
    };

    if (isFetchingPathways) {
        return <Loading/>;
    }

    return (
        <div >
            <div className='top-bar'>
                <Link to={`/clinical_portal/folder/${folderId}/patient/pathway`} className="top-bar__button">{_`Back To Patient Pathway`}</Link>
            </div>
            <form name="careEventForm"
                autoComplete="off"
                onSubmit={handleOnSubmit}
            >
                <PreventAutoCompleteForm/>
                { !!formError && !isFetchingPathways && (
                    <div className="alert alert-danger">{formError}</div>
                ) }
                {!isFetchingPathways && (
                    <Fragment>
                        <div className={'form-group form-required  ' + (state.pathway.error ? 'has-error' : '')}>
                            <label htmlFor="pathway">{_`Pathway`}</label>
                            {!!preparedPathways && preparedPathways.length === 1
                                ? <p>{preparedPathways[0][1].name}</p>
                                : (
                                    <select className="form-control"
                                        name="pathway"
                                        value={state.pathway.value}
                                        onChange={handleOnChange}
                                    >
                                        <option value="">{_`Select pathway`}</option>
                                        {pathwaysOptions}
                                    </select>
                                )
                            }
                            {state.pathway.error && (
                                <div className="form-errors">
                                    <div className="form-error">{state.pathway.error}</div>
                                </div>
                            )}
                        </div>
                        <div className={'form-group form-required ' + (state.careEvent.error ? 'has-error' : '')}>
                            <label htmlFor="careEvent">{_`Care event`}</label>
                            <SimpleDropdown
                                name="careEvent"
                                items={careEventItems}
                                value={state.careEvent.value}
                                onChange={handleOnChangeCareEvent}
                            />
                            {state.careEvent.error && (
                                <div className="form-errors">
                                    <div className="form-error">{state.careEvent.error}</div>
                                </div>
                            )}
                        </div>
                        {!!careEvent && careEvent.details
                            ? (
                                <div className={'form-group form-required ' + (state.userNoteTitle.error ? 'has-error' : '')}>
                                    <label htmlFor="userNoteTitle">{_`Care event detail`}</label>
                                    <SimpleDropdown
                                        name="userNoteTitle"
                                        items={careEvent.details.map(detail => [detail, detail])}
                                        value={state.userNoteTitle.value}
                                        onChange={handleOnChange}
                                    />
                                    {state.userNoteTitle.error && (
                                        <div className="form-errors">
                                            <div className="form-error">{state.userNoteTitle.error}</div>
                                        </div>
                                    )}
                                </div>
                            )
                            : (
                                <div className={'form-group ' + (state.userNoteTitle.error ? 'has-error' : '')}>
                                    <label htmlFor="userNoteTitle">{_`Note title`}</label>
                                    <input type="text" className="form-control"
                                        name="userNoteTitle"
                                        value={state.userNoteTitle.value}
                                        placeholder={_`Note title`}
                                        onChange={handleOnChange}
                                    />
                                    {!!state.userNoteTitle.error && (
                                        <div className="form-errors">
                                            <div className="form-error">{state.userNoteTitle.error}</div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        <div className={'form-group form-required  ' + (state.userNoteComment.error ? 'has-error' : '')}>
                            <label htmlFor="userNoteComment">{_`Note comment`}</label>
                            <textarea className="form-control"
                                name="userNoteComment"
                                placeholder={_`Note comment`}
                                onChange={handleOnChange}
                                value={state.userNoteComment.value}
                            />
                            {!!state.userNoteComment.error && (
                                <div className="form-errors">
                                    <div className="form-error">{state.userNoteComment.error}</div>
                                </div>
                            )}
                        </div>
                        <div
                            className="form-submit-btn-container"
                        >
                            <input type="submit"
                                id="careEventFormSubmit"
                                className="hidden"
                            />
                            <label
                                className={`btn btn-default ${disable ? 'btn-disabled' : 'btn-primary '}`}
                                htmlFor="careEventFormSubmit"
                            >Add</label>
                            <button
                                className="btn btn-default care-event-btn"
                                onClick={() => window.location.pathname = `/clinical_portal/folder/${folderId}/patient`}
                            >Cancel</button>
                        </div>
                    </Fragment>
                )}
            </form>
        </div>
    );
};

function usePreparedPathways({ folderId, teamId }) {
    const [activeReferrals, isFetching] = useActiveReferrals({ folderId, teamId });

    const preparedPathways = useMemo(() => {
        if (isFetching) {
            return [];
        }

        const pathways = activeReferrals
            .filter(referral => referral && referral.content && referral.content.labels && referral.content.labels.length)
            .map(referral => referral.content.labels.map(label => [referral, label]))
            .flat()
            .filter(([, label]) => isActiveLabelPredicate(label));

        const names = pathways.map(([, label]) => label.name);
        const isMoreThanOneLabelWithName = (name) => names.filter((labelName) => labelName === name).length > 1;

        return pathways.map(([referral, label]) => [referral, label, isMoreThanOneLabelWithName(label.name)]);
    }, [activeReferrals, isFetching]);

    return [preparedPathways, isFetching];
}

export const PreventAutoCompleteForm = () => {
    const dummyStyle = { height: 0, background: 'transparent', color: 'transparent', border: 'none', padding: 0 };
    return (
        <div style={{ overflow: 'none', height: 0, background: 'transparent' }}
            data-description="dummyPanel for auto-fill issue"
        >
            <input type="userNoteTitle" name="userNoteTitle" style={dummyStyle} data-description="dummyUserNoteTitle" />
        </div>
    );
};
