import { useContext, useMemo } from 'react';

import { ReferralsContext } from 'features/ReferralsProvider';
import { Referral, REFERRAL_STATUS } from 'models/compositions/Referral';
import { Composition } from 'models/Composition';

export function useNonDischargedReferrals(): [Composition<Referral>[], boolean] {
    const {
        list,
        status: {
            isFetching,
        },
    } = useContext(ReferralsContext);

    const filteredList = useMemo(() => {
        return list.filter((referral) => {
            return referral.content.status !== REFERRAL_STATUS.DISCHARGED;
        });
    }, [list]);

    return [
        filteredList,
        isFetching
    ];
}
