import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'ui/modal';
import _ from 'services/i18n';

export class ModalDialog extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        const modalRoot = document.getElementById('modalRoot');
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        const modalRoot = document.getElementById('modalRoot');
        modalRoot.removeChild(this.el);
    }

    render() {
        const {
            onClose,
            buttons,
            title,
            size,
            className,
            busy,
            children,
            overflow
        } = this.props;

        const modalSizeClass = size ? 'modal-' + size : '';

        const modal = (
            <div className="modal-backdrop">
                <div className={`modal show-modal-dialog ${className ? className : ''}`} tabIndex="-1" >
                    <div className={'modal-dialog ' + modalSizeClass} role="dialog">
                        <div className="modal-content">
                            <ModalBusy busy={busy} />
                            <ModalHeader onClose={onClose} title={title} />
                            <div className={`modal-body ${ overflow ? 'modal-overflow' : ''}`}>
                                {children}
                            </div>
                            <div className="modal-footer">{buttons}</div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return ReactDOM.createPortal(
            modal,
            this.el,
        );
    }
}

const ModalBusy = (props) => {
    if (props.busy) {
        return (
            <div className="modal-busy">
                <span className="glyphicon glyphicon-lock" />
            </div>
        );
    }

    return (<noscript />);
};

export const ModalHeader = (props) => {
    return (
        <div className="modal-header">
            {props.noClose
                ? null
                : <button type="button"
                    className="close"
                    onClick={props.onClose}
                    aria-label="Close"
                ><span aria-hidden="true">&times;</span></button>}
            <h4 className="modal-title">{props.title || _`Modal Title`}</h4>
        </div>
    );
};
