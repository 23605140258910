import { Appointment } from 'models/compositions/Appointment';
import { Label } from 'models/Label';
import { Demographics } from 'models/compositions/Demographics';
import { terService } from 'services/ter.service';

export function getAppointmentSpecialityCode(appointment: Appointment) {
    return appointment.hospital_service;
}

export function getAppointmentSpeciality(appointment: Appointment) {
    return specialityCodes[appointment.hospital_service];
}

const specialityCodes = {
    108: { description: 'Spinal Surgery', name: 'Orthopaedic appointment', hiddenFromTimeline: false, phoneNumber: '0114 226 6255' },
    110: { description: 'Orthopaedics', name: 'Orthopaedic appointment', hiddenFromTimeline: false, phoneNumber: '0114 226 6255' },
    130: { description: 'Opthalmology', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    160: { description: 'Plastic Surgery', name: 'Hand Centre Appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 5684' },
    190: { description: 'Anaesthetics', name: 'Pain appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 5210' },
    191: { description: 'Pain Management', name: 'Pain appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 5210' },
    300: { description: 'General Medicine', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    302: { description: 'Endocrinology', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    315: { description: 'Palliative Medicine', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    400: { description: 'Neurology', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    410: { description: 'Rheumatology', name: 'Rheumatology appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 1947' },
    430: { description: 'Geriatric Medicine', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    501: { description: 'Obstetrics', name: 'Obstetrics appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 2866' },
    650: { description: 'Physiotherapy', name: 'Physiotherapy appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 3090 or 0114 271 2061' },
    651: { description: 'Occupational Therapy', name: 'Occupational Therapy appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 3090 or 0114 271 2061' },
    654: { description: 'Dietetics', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    663: { description: 'Podriatric Surgery', name: 'Podiatric Surgery appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 4668' },
    810: { description: 'Radiology', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    822: { description: 'Chemical Pathology', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
    960: { description: 'AHP Episode', name: 'Therapy Services appointment', hiddenFromTimeline: false, phoneNumber: '0114 271 3090 or 0114 271 2061' },
    default: { description: 'default', name: 'appointment', hiddenFromTimeline: true, phoneNumber: '0114 271 1633' },
};

export enum APPOINTMENT_MODE {
    TELEPHONE = 'Telephone',
    TELEPHONE_REQUESTED = 'Telephone requested',
    FACE_TO_FACE = 'Face to face',
    NON_FACE_TO_FACE = 'Non face to face',
    ATTEND_ANYWHERE = 'Attend Anywhere',
    ATTEND_ANYWHERE_REQUESTED = 'Attend Anywhere requested',
}

export const appointmentModeLabels = new Map([
    [APPOINTMENT_MODE.TELEPHONE, 'Telephone'],
    [APPOINTMENT_MODE.TELEPHONE_REQUESTED, 'Telephone requested'],
    [APPOINTMENT_MODE.FACE_TO_FACE, 'Face to face'],
    [APPOINTMENT_MODE.NON_FACE_TO_FACE, 'Non face to face'],
    [APPOINTMENT_MODE.ATTEND_ANYWHERE, 'Attend Anywhere'],
    [APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED, 'Attend Anywhere requested']
]);

export function getAppointmentMode (labels: Label[] = []): APPOINTMENT_MODE {
    return labels.find(({ context, status }) => status && context === 'attend_anywhere')?.name as APPOINTMENT_MODE;
}
export function isStatusProcessing (labels: Label[] = []) {
    const label = labels.find(({ context, name, status }) => status && context === 'aa_worklist' && /save pending/i.test(name));
    return Boolean(label);
}
export function getClinicCode(appointment: Appointment) {
    return appointment.clinical_codes?.[0]?.code;
}
export function getHospitalNumber(demographics: Demographics) {
    return demographics?.identifiers?.rhq_id;
}
export function setAppointmentMode(uuid: string, mode: APPOINTMENT_MODE, folderId: number, teamId: number) {
    return terService.createTer({ action: 'setAppointmentMode', folderId, data: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        mode, appointment_uuid: uuid,
    } });
}

export function filterSpaces(str: string): string {
    return str.replace(/\s/g, '');
}

