/* eslint-disable @typescript-eslint/camelcase */
import React, { FormEventHandler, useCallback, useState } from 'react';
// components
import * as AlertBoxes from 'common/ui/alert-boxes';
import Select from 'react-select';
// hooks
import {
    ApprovePIFUAppointmentFormState,
    getProposedParticipantStatusList,
    useApprovePIFUAppointmentFormState, useDefaultPatientTextForOutcome,
} from './ApprovePIFUAppointmentForm.hooks';
// services
import _ from 'services/i18n';
import { appointmentsService } from 'services/appointments.service';
import { portalToast } from 'ui/toast/Toast';
// interfaces
import { Composition } from 'models/Composition';
import { Appointment, AppointmentProposedParticipantStatus } from 'models/compositions/Appointment';
// styles
import './ApprovePIFUAppointmentForm.scss';
import { getDisplayValueForValueOption, useStateUpdateFromListSelection } from 'common/ui/dropdown/DropdownUtils';

type ApprovePIFUAppointmentFormProps = {
    appointment: Composition<Appointment>;
    onSubmit: () => void;
};

function validateState(state: ApprovePIFUAppointmentFormState): null | string {
    if (!state.responseToPatient) {
        return 'Response to patient should be more then 1 character';
    }

    return null;
}

function ApprovePIFUAppointmentForm(props: ApprovePIFUAppointmentFormProps) {
    const { appointment, onSubmit } = props;

    const proposedParticipantStatusList = getProposedParticipantStatusList();
    const { state, setNotesToStaff, setResponseToPatient, setOutcomeAction } = useApprovePIFUAppointmentFormState(appointment);
    const proposedParticipantStatusSelection = getDisplayValueForValueOption(proposedParticipantStatusList, state.outcomeAction);
    const getDefaultPatientTextFn = useDefaultPatientTextForOutcome();

    const onOutcomeActionChange = useCallback((newOutcomeAction: AppointmentProposedParticipantStatus) => {
        const defaultPatientText = getDefaultPatientTextFn(newOutcomeAction);
        setResponseToPatient(defaultPatientText);
    }, [setResponseToPatient, getDefaultPatientTextFn]);

    const onValueChange = useStateUpdateFromListSelection(setOutcomeAction, onOutcomeActionChange);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState('');

    const isValid = (state: ApprovePIFUAppointmentFormState) => {
        const errors = validateState(state);

        if (errors) {
            setErrors(errors);
            return false;
        }

        setErrors('');
        return true;
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (ev) => {
        ev.preventDefault();

        const valid = isValid(state);
        if (!valid) {
            return;
        }

        const content: Appointment = {
            ...appointment.content,
            proposed_participant: {
                ...appointment.content.proposed_participant,
                status: state.outcomeAction,
                notes: state.responseToPatient,
                booking_notes: state.notesToStaff,
            },
        };

        setIsSubmitting(true);

        try {
            await appointmentsService.update({
                uuid: appointment.uuid,
                folderId: appointment.folder_id,
                content,
            });
        } catch (err) {
            console.warn(err);

            portalToast.error({
                title: 'Error',
                content: 'Something went wrong!',
            });
        }

        setIsSubmitting(false);
        onSubmit();
    };

    return (
        <div className="approve-pifu-appointment-form-container">
            <AlertBoxes.Custom boxStyle={'light'} show>
                <p className="approve-pifu-appointment-form-container__warning">
                    {_`Warning: editing details of appointments here will not alter appointments in the hospital's booking system`}
                </p>
            </AlertBoxes.Custom>

            <div className="panel panel-default">
                <p className="panel-heading">Proposed Appointment</p>
                <form className="panel-body" onSubmit={handleSubmit}>

                    <div className="form-group">
                        <div className="errors">{errors}</div>

                        <label
                            className="control-label"
                            htmlFor="status-select"
                        >
                            Status
                        </label>
                        <div className="react-select-input-container">
                            <Select
                                id='status-select'
                                value={proposedParticipantStatusSelection}
                                options={proposedParticipantStatusList}
                                onChange={onValueChange}
                                classNamePrefix={'react-select-input'}
                            />
                        </div>

                        <label
                            className="control-label"
                            htmlFor="response-to-patient-input"
                        >
                            Response to patient
                            <span className="asterix">*</span>
                        </label>
                        <textarea
                            id="response-to-patient-input"
                            className="form-control"
                            value={state.responseToPatient}
                            onChange={(ev) =>
                                setResponseToPatient(ev.target.value)
                            }
                        />

                        <div className="approve-pifu-appointment-form-container__info-details">
                            <p>
                                These notes will be sent to the patient with information about their appointment.
                            </p>
                            <p>
                                They will also receive notes when an appointment is rejected.
                            </p>
                        </div>

                        <label
                            className="control-label"
                            htmlFor="notes-to-staff-input"
                        >
                            Notes to booking staff
                        </label>
                        <textarea
                            id="notes-to-staff-input"
                            className="form-control"
                            value={state.notesToStaff}
                            onChange={(ev) => setNotesToStaff(ev.target.value)}
                        />
                    </div>

                    <div className="approve-pifu-appointment-form-container__buttons">
                        <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ApprovePIFUAppointmentForm;
