import React, { FC, useMemo } from 'react';
// components
import FolderBreadcrumbs from 'features/department/components/FolderBreadcrumbs';
// hooks
import { useDepartmentLocation } from 'features/department/department.context';
// utils
import { getFolderListFromCurrentLocation } from 'features/department/department.utils';
// interfaces
import { FolderBreadcrumbItem } from 'features/department/components/FolderBreadcrumbs/FolderBreadcrumbs.interface';
// styles
import './DepartmentBreadcrumbs.scss';

const DepartmentBreadcrumbs: FC = () => {
    const { currentLocation, locationTree, changeLocation } =
        useDepartmentLocation();

    const folderList: FolderBreadcrumbItem[] = useMemo(() => {
        if (!currentLocation || !locationTree) {
            return [];
        }

        return getFolderListFromCurrentLocation(currentLocation, locationTree);
    }, [currentLocation, locationTree]);

    if (currentLocation === undefined || locationTree === undefined) {
        return null;
    }

    return (
        <div className={'department-breadcrumbs'}>
            <FolderBreadcrumbs
                folderList={folderList}
                handleLocationChange={changeLocation}
            />
        </div>
    );
};

export default DepartmentBreadcrumbs;
