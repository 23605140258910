import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import lodash from 'lodash';

import BasePage from 'components/page/base';

import HeadingDoc from 'ui/heading-doc';
import { ResponsiveTable, Row, Column, Panel } from 'ui';
import Pagination from 'components/pagination';
import FormPanel from 'components/form-panel';
import TextInput from 'components/form-panel/text-input';
import SelectorInput from 'components/form-panel/selector-input';
import * as AlertBoxes from 'common/ui/alert-boxes';
import NhsNumber from 'ui/nhs-number';
import PatientName from 'ui/patient-name';

import DataStore from 'services/data-store';
import _ from 'services/i18n';

import ActionMenu from 'ui/action-menu';
import ButtonGroup from 'components/button-group';

import getPatientLinks, { checkAllowed } from 'helpers/patient-links';
import { getStatusLabel, patientsService } from 'services/patients.service';
import { terService } from 'services/ter.service';
import ChangeEmailModal from 'components/change-email-and-reinvite';
import ButtonSplitDropdown from 'components/button-split-dropdown';
import { DateTime } from 'common/datetime/DateTime';
import { CreatePatientButton } from 'features/patients/create-patients/CreatePatientButton';
import { TeamPreferencesContext } from 'common/TeamPreferencesContext';
import { getPersonPrimaryIdentifierFromUserFolder } from 'common/personPrimaryIdentifierHelpers';
import { PRIMARY_IDENTIFIER_NAMESPACE } from 'models/PersonPrimaryIdentifier';
import { goToPage } from 'layouts/clinical_portal/NewNavigation';
import { getNamespaceLabel } from 'common/personPrimaryIdentifierHelpers';
import { teamPreferencesService } from 'services/team-preferences.service';

/**
 * This component shows the patient list/search page.
 */
export default class PatientsPage extends BasePage {
    constructor(props) {
        super(props);
        this.deregistrationHelper = new DeregistrationHelper((deregistrations) => {
            this.setState({ deregistrations });
        });

        this.state = {
            service: (DataStore.get('me.currentRole') || {}).serviceName || '',
            // Counter to force the form panel to reset.
            formKey: 1,

            // Indicates if we are currently loading the search/list results.
            loading: false,

            // Indicates if an error occurred during loading.
            loadingError: false,

            // An array of the current page of search/list results.
            // Each element will be an object containing summary data about a patient folder.
            resultsPage: [],

            // The total number of results matching the current filter/query.
            totalNumResults: 0,

            // A map of invite IDs to a string indicate their status: sending, sent, or failed.
            // This will only list invitations the user has sent from the current page of results.
            // It is used to provide UI feedback.
            invitations: new Map(),

            // A map of dergister IDs to a string indicate their status: sending, sent, or failed.
            // This will only list deregistrations the user has sent from the current page of results.
            // It is used to provide UI feedback.
            deregistrations: new Map(),

            teamPreferences: {}
        };

        // The number of results to return per page of results.
        this.perPage = 100;

        this.handleFilterChange = this.handleFilterChange.bind(this);

        this.loadResultsPromise = Promise.resolve();

        this.setTeamPrefs();
    }

    setTeamPrefs() {
        teamPreferencesService.get({})
            .catch(() => {
                return teamPreferencesService.defaultProperties;
            })
            .then((teamPreferences) => {
                this.setState({ teamPreferences });
            });
    }

    /**
     * Get the currently applied filter.
     * This is based on the query string parameters, although validation is applied.
     * Valid values are: 'all', 'registered', 'invited', 'pending'. The default is 'all'.
     *
     * @return {string} The currently applied filter, determined by query string parameters.
     */
    getAppliedFilter () {

        const filter = (this.props.location.query.filter || '' ).trim().toLowerCase();
        const allowedStatuses = [
            patientsService.STATUS.REGISTERED,
            patientsService.STATUS.INVITED,
            patientsService.STATUS.PENDING
        ];
        if (filter && allowedStatuses.indexOf(filter) !== -1) {
            return filter;
        }
    }

    /**
     * Get the currently applied search term
     * This is based on the query string parameters
     * @return {string} The currently applied filter, determined by query string parameters.
     */
    getAppliedSearch () {

        return (this.props.location.action !== 'POP' && this.props.location.query.search || '');
    }

    /**
     * Get the currently shown page of results.
     * This is parsed from query string parameters.
     * It is 1-based, and defaults to 1.
     *
     * @return {number} The page number specified in the query string, or 1 if it is not specified.
     */
    getPageNumber () {
        const pageNumber = parseInt(this.props.location.query.page || '1');
        if (pageNumber <= 0) {
            console.warn('Invalid page number: ' + pageNumber);
            return 1;
        }
        return pageNumber;
    }

    componentDidMount () {
        if(this.props.location.action === 'POP') {
            return this.onReset();
        }

        const filter = this.getAppliedFilter();
        const search = this.getAppliedSearch();

        const serviceName = this.getTeam().serviceName;
        const hasFilter = Boolean(filter);
        const hasSearch = Boolean(search);

        if (serviceName !== 'CAMHS' && (hasFilter || hasSearch)) {
            return this.loadResultsWithTeamPreferences();
        }
    }

    componentWillReceiveProps (newProps) {
        const shouldReload = (this.props.location.search !== newProps.location.search)
            && (newProps.location.search !== "");

        this.$setState({
            formKey: this.state.formKey + 1
        }).then(() => {
            if (shouldReload){
                this.loadResultsWithTeamPreferences();
            }
        });
    }

    loadResultsWithTeamPreferences() {
        teamPreferencesService.get()
            .then((teamPreferences) => {
                this.setState({ teamPreferences });
                this.loadResults();
            });
    }

    /**
     * Request the patient list from the server.
     * Returns a promise which resolves when successful or when any errors are handled.
     *
     * @return {Promise}
     */
    loadResults () {

        this.loadResultsPromise = this.loadResultsPromise
            .then(() => {
                return this.$setState({
                    loading: true,
                    loadingError: false,
                    resultsPage: [],
                    totalNumResults: 0
                }).
                    then(() => {

                        const page = this.getPageNumber();
                        const offset = (page - 1) * this.perPage;
                        const status = this.getAppliedFilter();
                        const search = this.getAppliedSearch();

                        const params = {
                            limit: this.perPage,
                            offset,
                            status
                        };

                        if (this.state.teamPreferences && this.state.teamPreferences.portal && this.state.teamPreferences.portal['sort_users']) {
                            params.sort = JSON.stringify(this.state.teamPreferences.portal['sort_users']);
                        }

                        if (lodash.isEmpty(search)) {
                            return patientsService.list(params);
                        }

                        const searchParams = {
                            offset,
                            patientIdentifier: search
                        };
                        status && (searchParams.status = status);

                        if (this.state.teamPreferences && this.state.teamPreferences.portal && this.state.teamPreferences.portal['sort_users']) {
                            searchParams.sortRules = this.state.teamPreferences.portal['sort_users'];
                        }

                        return patientsService.search(searchParams);
                    }).
                    then(({ patients,patientsTotal }) => {
                        return this.$setState({
                            loading: false,
                            resultsPage: patients,
                            totalNumResults: patientsTotal
                        });
                    }).
                    catch((err) => {
                        console.warn(err);
                        return this.$setState({
                            loading: false,
                            loadingError: true
                        });
                    });
            });
    }

    /**
     * Event handler: User has submitted the search / filter form.
     *
     * @param {Map} data A map containing data from the form.
     */
    onSubmit (data) {

        const newQuery = {
            filter: undefined,
            search: undefined,
            page: undefined
        };

        const filter = data.get('patients-filter');
        newQuery.filter = filter;

        const search = data.get('patients-search');
        if (search) {
            newQuery.search = search;
        }

        this.changeQuery(newQuery);
    }

    handleFilterChange(id,value) {
        if (value && id === 'patients-filter') {
            goToPage({
                url: '/clinical_portal/my-team/patients',
                query: {
                    filter: value !== 'all' ? value : '',
                    search: '',
                    page: 1
                }
            });
        }
    }

    /**
     * Event handler: User has reset the search / filter form.
     */
    onReset () {
        this.changeQuery(
            {
                page: undefined,
                filter: undefined,
                search: undefined
            },
            true
        );
    }

    /**
     * Event handler: User has selected a different page of results.
     *
     * @param {number} newPage The number of the page being switched to.
     */
    onChangePage (newPage) {
        this.changeQuery({ page: newPage });
    }

    /**
     * Ensure the search results are scrolled into view.
     */
    scrollToResults () {
        const elem = document.querySelector('.search-results');
        if (elem) {
            elem.scrollIntoView();
        }
    }

    /**
     * Get the title of this page.
     */
    pageTitle () {
        return `${this.$ps()} | PHR Clinical Portal`;
    }

    /**
     * Event handler: The user wants to send or resend the specified invitation to a pending patient.
     * Returns a promise which resolves when successful, or when errors have been handled.
     *
     * @param {number} folderId
     * @param {number} email
     */
    onSendInvite (folderId, email) {
        return this.setInvitationStatus(folderId, 'sending')
            .then(() => {
                return terService.createTer({ folderId, action: 'sendInvitation', data: email ? { email } : {} });
            }).
            then(() => {
                return this.setInvitationStatus(folderId, 'sent');
            }).
            catch((err) => {
                console.warn(err);
                return this.setInvitationStatus(folderId, 'failed');
            });
    }

    /**
     * Set the status of the specified invitation.
     * Returns a promise which resolves when the state has been updated.
     *
     * @param {number} id
     * @param {string} status The status of the invitation. Valid values are: sending, sent, failed.
     * @return {Promise}
     */
    setInvitationStatus (id, status) {
        if (status != 'sending' && status != 'sent' && status != 'failed') {
            console.warn('Unrecognised invitation status: ' + status);
            return Promise.reject();
        }

        return this.$setState((prevState, props) => {
            const invitations = prevState.invitations || new Map();
            invitations.set(id, status);
            return { invitations: invitations };
        });
    }

    /**
     * Render this component.
     */
    render () {

        return (
            <div className='page patients-page'>
                <HeadingDoc title={this.$ps()}>
                     This page lists the {this.$ps().toLowerCase()} records associated with your team or organisation. You can filter the list or search for specific records using the form below.
                </HeadingDoc>
                <AlertBoxes.LoadingError show={this.state.loadingError} >{_`Sorry, a browser timeout has occurred. Your search query will continue to run in the background. Please wait a moment and try again in order to re-run your search to retrieve any cached results.`}</AlertBoxes.LoadingError>
                <Row>
                    <Column sm='6'>
                        { this.state.service !== 'MSK' ? this.renderForm() : this.renderPathwayForm()}
                    </Column>
                </Row>
                <Row>
                    <Column sm='12'>
                        <div className='search-results'>
                            {this.renderTable()}
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column sm='12'>
                        {this.renderPagination()}
                    </Column>
                </Row>
            </div>
        );
    }

    renderPathwayForm (){
        return (
            <Panel title="Filters">
                <SelectorInput
                    id='patients-filter'
                    label='Status'
                    helpText='You can optionally filter the list to only show records with the given status.'
                    hideValidationSuccess={true}
                    onChange={this.handleFilterChange}
                    placeholder={"Please select status"}
                >
                    <option value='all'>All {this.$ps()}</option>
                    <option value='registered'>Registered {this.$ps()}</option>
                    <option value='invited'>Invited {this.$ps()}</option>
                    <option value='pending'>Pending {this.$ps()}</option>
                </SelectorInput>
            </Panel>
        );
    }

    /**
     * Render the search/filter form.
     */
    renderForm () {
        return (
            <FormPanel
                title='Filter and Search'
                id='patients-filter-and-search-form'
                submitLabel='Submit'
                resetLabel='Reset'
                busy={this.state.loading}
                onSubmit={this.onSubmit.bind(this)}
                onReset={this.onReset.bind(this)}
                key={this.state.formKey}

            >
                <TextInput
                    id='patients-search'
                    label='Search'
                    helpText='Use this box to search by name, email, NHS number or hospital number. You can search for a partial number, or you can leave this empty if you only want to use the filter below.'
                    initialValue={this.getAppliedSearch()}
                    placeholder='e.g. 111 222 3333'
                    spaces='preserve'
                    hideValidationSuccess={true}
                />

                <SelectorInput
                    id='patients-filter'
                    label='Filter'
                    helpText='You can optionally filter the list to only show records with the given status.'
                    hideValidationSuccess={true}
                    initialValue={this.getAppliedFilter() || 'all'}
                >
                    <option value='all'>All {this.$ps()}</option>
                    <option value='registered'>Registered {this.$ps()}</option>
                    <option value='invited'>Invited {this.$ps()}</option>
                    <option value='pending'>Pending {this.$ps()}</option>
                </SelectorInput>
            </FormPanel>
        );
    }


    /**
     * Render the table listing patients.
     */
    renderTable () {

        return (
            <TeamPreferencesContext.Consumer>
                {(teamPreferences) => (
                    <ResponsiveTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date of birth</th>
                                <th>{getNamespaceLabel(teamPreferences && teamPreferences.primary_identifier)}</th>
                                <th>Hospital Number</th>
                                <th>Status</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        {
                            this.renderTableBody(
                                teamPreferences.primary_identifier && teamPreferences.primary_identifier.human_readable_labels
                            )
                        }
                    </ResponsiveTable>
                )}
            </TeamPreferencesContext.Consumer>
        );
    }

    /**
     * Render the data rows for the patient list table.
     * @param {HumanReadableLabel[]} humanReadableLabelsForIdentifiers
     */
    renderTableBody (humanReadableLabelsForIdentifiers) {
        if (this.state.loadingError) {
            return null;
        }

        if (this.state.loadingError) {
            return (
                <tbody>
                    <tr>
                        <td colSpan='6'>Sorry. An error occurred. Please try again...</td>
                    </tr>
                </tbody>
            );
        }

        if (this.state.loading) {
            return (
                <tbody>
                    <tr>
                        <td colSpan='6'>Loading. Please wait...</td>
                    </tr>
                </tbody>
            );
        }

        if (this.state.totalNumResults == 0) {
            return (
                <TeamPreferencesContext.Consumer>
                    {({ show_create_patient_button }) => {
                        return (
                            <tbody>
                                <tr>
                                    <td colSpan='6'>
                                        <strong>No records match the specified filter/search.</strong>
                                    &nbsp;&nbsp;
                                        {show_create_patient_button &&
                                    <CreatePatientButton teamFolderId={this.getTeamFolderId()}/>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        );
                    }}
                </TeamPreferencesContext.Consumer>
            );
        }

        return (
            <tbody>
                {
                    this.state.resultsPage.map((item, index) => {
                        return this.renderTableRow(item, index, humanReadableLabelsForIdentifiers);
                    })
                }
            </tbody>
        );
    }

    /**
     * Shoul;d only link for MSK service
     * @param {object} data An object containing data about a single patient record.
     * @return {string}
     */

    usePatientNameOrLink (name, folderId) {

        let link;
        const portalTeamPrefs = this.state.teamPreferences && this.state.teamPreferences.portal;
        if (portalTeamPrefs && portalTeamPrefs.show_dashboard) {
            link = `/clinical_portal/folder/${folderId}/patient/dashboard`;
        } else if (portalTeamPrefs && portalTeamPrefs.show_pathway) {
            link = `/clinical_portal/folder/${folderId}/patient/pathway`;
        } else {
            link = `/clinical_portal/folder/${folderId}/patient/appointments`;
        }

        if (this.state.service === 'MSK'){
            return (<PatientName linksTo={link} name={name || '-'} />);
        } else {
            return (name || '-');
        }

    }

    /**
     * Render a single data row.
     * @param {UserFolder} patientUserFolder An object containing data about a single patient record.
     * @param {number} key - row index
     * @param {HumanReadableLabel[]} humanReadableLabelsForIdentifiers
     */
    renderTableRow (patientUserFolder, key, humanReadableLabelsForIdentifiers = []) {

        const userDetails = patientUserFolder.user_details || {};

        const {
            name = {},
            identifiers = {},
            date_of_birth
        } = userDetails;

        const displayName = [name.given_name,name.family_name].join(' ').trim() || 'Not Set';

        const { rhq_id: hospitalNumber } = identifiers;

        const isShowDeregister = !this.state.deregistrations.get(patientUserFolder.id);

        const personPrimaryIdentifier = getPersonPrimaryIdentifierFromUserFolder(patientUserFolder, humanReadableLabelsForIdentifiers);

        return (
            <tr key={key}>
                <td>{this.usePatientNameOrLink(displayName, patientUserFolder.id)}</td>
                <th><DateTime empty="-">{date_of_birth}</DateTime></th>
                <td>
                    {personPrimaryIdentifier ? (
                        personPrimaryIdentifier.namespace === PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER ?
                            <NhsNumber>{personPrimaryIdentifier.value}</NhsNumber> :
                            <Fragment>{personPrimaryIdentifier.value}</Fragment>
                    ) : '-'}
                </td>
                <td>{hospitalNumber || '-'}</td>
                <td>
                    <PatientStatusCells
                        data={patientUserFolder}
                        deregistrations={this.state.deregistrations}
                        invitations={this.state.invitations}
                    />
                </td>
                <td>
                    <PatientsTableRowActions
                        data={patientUserFolder}
                        isShowDeregister={isShowDeregister}
                        onSendInvite={(folderId, email) => this.onSendInvite(folderId, email)}
                        onDeregister={(folderId) => this.deregistrationHelper.deregister(folderId)}
                    />
                </td>
            </tr>
        );
    }

    renderActionsCell (data){
        let links = [];
        if (this.state.service === 'MSK'){
            links = [
                {
                    route: `/clinical_portal/folder/${data.id}/patient`,
                    label: _`View`
                }
            ];
        }else{
            links = getPatientLinks(data.id);
        }

        let actionMenu;
        if (this.state.service === 'MSK'){
            actionMenu = (
                <div className="space-handler">
                    <ButtonGroup links={links} upperCased/>
                </div>
            );
        }else{
            actionMenu = (
                <ActionMenu
                    size="xs"
                    links={links}
                    onCheckAllowed={checkAllowed}
                    label={'Select an Option'}/>
            );
        }

        return (
            <td>
                {actionMenu}
            </td>
        );
    }

    /**
     * Render the pagination controls, along with a summary of how many results there were.
     */
    renderPagination () {
        if (this.state.loading || this.state.loadingError) {
            return null;
        }

        return (
            <div>
                <Pagination
                    pageCount={Math.ceil(this.state.totalNumResults / this.perPage)}
                    currentPage={this.getPageNumber()}
                    onChange={this.onChangePage.bind(this)}
                />
                <p>
                    Total number of results: {this.state.totalNumResults}
                </p>
            </div>
        );
    }
}

export class PatientsTableRowActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpened: false
        };

        this.openModal = this.openModal.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
    }

    onModalClose() {
        this.setState({
            modalOpened: false
        });
    }

    openModal(e) {
        e.preventDefault();

        this.setState({
            modalOpened: true
        });
    }

    render() {
        const {
            data,
            onSendInvite
        } = this.props;
        return (

            <div className="space-handler text-uppercase">
                <div className="btn-group btn-group-spaced">
                    {this.makePatientButtons(this.props)}
                    {this.state.modalOpened && (
                        <ChangeEmailModal onSendInvite={onSendInvite}
                            onClose={this.onModalClose}
                            data={data}
                        />
                    )}
                </div>
            </div>
        );
    }

    makePatientButtons(props) {
        const {
            data,
            onSendInvite,
            onDeregister,
            isShowDeregister,
            openModal,
        } = props;

        const buttons = [
            <Link className={'btn btn-xs btn-default'}
                to={`/clinical_portal/folder/${data.id}/patient`}
                key="1">
                {_`View`}
            </Link>
        ];

        if (isShowDeregister && data.status === patientsService.STATUS.REGISTERED) {
            buttons.push(
                <button className={'btn btn-xs btn-primary'}
                    type="button"
                    onClick={() => onDeregister(data.id)}
                    key="3"
                >
                    {_`Deregister`}
                </button>
            );
        }

        if ([patientsService.STATUS.INVITED, patientsService.STATUS.PENDING].indexOf(data.status) !== -1 && data.invite_id) {
            const statusIsInvited = data.status === patientsService.STATUS.INVITED;

            buttons.push(
                <ButtonSplitDropdown key="2"
                    onClick={() => onSendInvite(data.id)}
                    label={_`Re-invite`}
                    buttonsClassNames={'btn btn-xs btn-primary'}>
                    <li><a href="#" onClick={this.openModal}>Change email and re-invite</a></li>
                    {isShowDeregister && statusIsInvited &&
                        <li><a href="" onClick={() => onDeregister(data.id)}>{_`Deregister`}</a></li>
                    }
                </ButtonSplitDropdown>
            );
        }
        return buttons;
    }
}

function getActionByInvitationStatus(invitationStatus) {
    const actionLabels = {
        'sending': { className: 'text-info', text: 'Sending invitation...' },
        'sent': { className: 'text-success', text: 'Invitation sent' },
        'failed': { className: 'text-danger', text: 'Failed to send invitation' },
        'unknown': { className: 'text-warning', text: 'Invitation not available' },
    };
    const { className, text } = actionLabels[invitationStatus] || actionLabels.unknown;
    return (<span className={className}>{text}</span>);
}

function getActionByDeregistrationStatus(deregistrationStatus) {
    const actionLabels = {
        'sending': { className: 'text-info', text: 'Sending deregistration...' },
        'sent': { className: 'text-success', text: 'Deregistration sent' },
        'failed': { className: 'text-danger', text: 'Failed to send deregistration' },
        'unknown': { className: 'text-warning', text: 'Invitation not available' },
    };
    const { className, text } = actionLabels[deregistrationStatus] || actionLabels.unknown;
    return (<span className={className}>{text}</span>);
}

function getActionByInvitationStatusAndInviteId(invitationStatus, inviteId) {
    if (invitationStatus === undefined && !inviteId) {
        return (<span className="text-warning">Invitation not available</span>);
    }

    return getActionByInvitationStatus(invitationStatus);
}

/**
 * Render the status cell of the given patient data.
 * @param {object} data An object containing data about a single patient record.
 * @param {object} deregistrations deregistration status map
 * @param {object} invitations invitation status map
 */
export const PatientStatusCells = ({ data, deregistrations, invitations }) => {
    let action;
    const { id, status, invite_id } = data;

    const statusLabel = getStatusLabel(status);

    const invitationStatus = invitations.get(id);
    if (invitationStatus) {
        action = getActionByInvitationStatusAndInviteId(invitationStatus, invite_id);
    }

    const deregistrationStatus = deregistrations.get(id);
    if (deregistrationStatus) {
        action = getActionByDeregistrationStatus(deregistrationStatus);
    }

    return (<span>{statusLabel}{action ? ': ' : null}{action}</span>);
};

export class DeregistrationHelper {
    constructor(onDeregistrationsChange) {
        this.onDeregistrationsChange = onDeregistrationsChange;
        this.deregistrations = new Map();
    }

    /**
     * Set the status of the specified degegister.
     * Returns a promise which resolves when the state has been updated.
     *
     * @param {number} id
     * @param {string} status The status of the invitation. Valid values are: sending, sent, failed.
     * @return {Promise}
     */
    setDeregisterStatus(id, status) {
        if (status != 'sending' && status != 'sent' && status != 'failed') {
            console.warn('Unrecognised deregister status: ' + status);
            return Promise.reject();
        }

        this.deregistrations = this.deregistrations.set(id, status);
        this.onDeregistrationsChange(this.deregistrations);
    }

    /**
     * Event handler: The user wants to deregister patient.
     * Returns a promise which resolves when successful, or when errors have been handled.
     *
     * @param {number} folderId
     */
    deregister(folderId) {
        this.setDeregisterStatus(folderId, 'sending');

        return  patientsService.deregister(folderId)
            .then(() => {
                return this.setDeregisterStatus(folderId, 'sent');
            })
            .catch((err) => {
                console.warn(err);
                return this.setDeregisterStatus(folderId, 'failed');
            });
    }
}

function swallowFirst (fn) {
    let firstCall = true;
    return (...args) => {
        if(firstCall) {
            firstCall = false;
            return;
        }
        return fn(...args);
    };
}

