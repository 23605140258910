import DataStore from 'services/data-store';
import lodash from 'lodash';
import { store } from 'store';

class UserActivityTracker {

    constructor(){
        this.ACTIVITY_CHECKING_INTERVAL = 1000;
        this.IDLING_INTERVAL = 60 * 60 * 1000;
        this.idleTimeout = null;
        this.lastActivityTime = null;
    }

    start(){
        const scheduleCheck = () =>{
            return window.setTimeout(() =>{
                this.checkIfUserIdling();
                this.idleTimeout = scheduleCheck();
            }, this.ACTIVITY_CHECKING_INTERVAL);
        };

        const onUserActivity = lodash.throttle((event) =>{
            if (event.type === 'mousemove' && event.movementX === 0 && event.movementY === 0) {
                return;
            }
            this.lastActivityTime = Date.now();
        }, 500);

        [
            'mousemove',
            'keydown',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'scroll'
        ].forEach((event) =>{
            document.body.removeEventListener(event, onUserActivity);
            document.body.addEventListener(event, onUserActivity);
        });

        this.lastActivityTime = Date.now();
        this.idleTimeout = scheduleCheck();
    }

    checkIfUserIdling(){
        const isIdling = (Date.now() - this.lastActivityTime) >= this.IDLING_INTERVAL;
        const isAuthenticated = store.getState().auth.get('isAuthenticated');
        if (isAuthenticated && isIdling) {
            console.log('Logging user out for inactivity.');
            DataStore.set('inactive', true);
        }
    }

    reset(){
        this.lastActivityTime = Date.now();
        DataStore.set('inactive', false);
    }
}

export default new UserActivityTracker();