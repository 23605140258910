import React from 'react';
import PageTitle from 'page-title';
import _ from 'services/i18n';
import NavigationHelper from 'services/navigation-helper'
import BaseComponent from 'components/BaseComponent';

/**
 * A generic webpage component
 */
export default class extends BaseComponent {
    constructor (props) {
        super(props);

        this.events = {};

        // call blank function that should be overloaded to provide page title
        PageTitle.setTitle(this.pageTitle());
    }

    pageTitle () {
        return _`Undefined Portal Page`;
    }
    
    /**
     * Wrapper around NavigationHelper.changeQuery().
     * The arguments are the same except this automatically provides oldLocation.
     */
    changeQuery(newParams, force = false)
    {
        NavigationHelper.changeQuery(this.props.location, newParams, force);
    }
}
