import React, { FC } from 'react';
import './DashboardDummy.scss';

interface DummyComponentProps {
    color: 'red' | 'green' | 'blue'; text: string; height: number;
}
export const DashboardDummy: FC<DummyComponentProps> = ({ color, text, height }) => {
    return (
        <div className={`dashboard-dummy dashboard-dummy--${color}`} style={{ minHeight: `${height}px` }}>{text}</div>
    );
};
