import { CompositionService } from './composition.service';
import { post } from "../../app/services/api";
import { DataStore } from "../../app/services/data-store";

const teamId = (DataStore.get("me.currentRole") || {}).teamId || 0;

class JsonService extends CompositionService {

    get archetypeName(): string {
        return 'questionnaire-template';
    }

  isValidJson = (jsonString) => {
    const requestPayload = {
        jsonString: jsonString
    };
    return post(`admin_portal/questionnaire-template/validate?team_id=${teamId}`, requestPayload);
  };

}

export const jsonService = new JsonService();