import { Email, EmailStatusMap } from 'models/compositions/Email';
import {
    COMMUNICATION_TYPE,
    UserCommunication,
    UserCommunicationEmail,
    UserCommunicationLetter,
    UserCommunicationMeshLetter,
    UserCommunicationPushNotification,
    UserCommunicationSMS,
    UserCommunicationHtmlLetter,
} from 'models/UserCommunication';
import { linksFilter } from 'common/linksFilter';
import { Letter } from 'models/compositions/Letter';
import { UserLetter } from 'models/compositions/UserLetter';
import { Composition } from 'models/Composition';
import { PushNotification } from 'models/compositions/PushNotification';
import { communicationsService } from 'services/communications.service';
import { convertToDate } from 'common/datetime/convertToDate';
import { SMS } from 'models/compositions/SMS';
import { HtmlLetter } from 'models/compositions/HtmlLetter';

export const getCommunicationInfo = (params: { folderId: number; teamId: number }): Promise<UserCommunication[]> => {
    return Promise.all([
        communicationsService.getEmails(params),
        communicationsService.getMeshLetters(params),
        communicationsService.getPushNotifications(params),
        communicationsService.getSMS(params),
        communicationsService.getLetters(params),
        communicationsService.getHtmlLetters(params),
    ])
        .then(([emails, meshLetters, pushNotifications, sms, letters, htmlLetters]) => {
            const mappedEmails = mapEmails(emails) as UserCommunication[];
            const mappedMeshLetters = mapMeshLetters(meshLetters);
            const mappedPushNotifications = mapPushNotifications(pushNotifications);
            const mappedSMS = mapSMS(sms);
            const mappedLetters = mapLetters(letters);
            const mappedHtmlLetters = mapHtmlLetters(htmlLetters);
            return mappedEmails.concat(mappedMeshLetters).concat(mappedPushNotifications).concat(mappedSMS).concat(mappedLetters).concat(mappedHtmlLetters);
        });
};

const mapEmails = (emails: Composition<Email>[]): UserCommunicationEmail[] => {
    return emails.map(email => {
        const content = linksFilter({
            content: email.content?.email_body_html || '',
            regExes: [
                /token=/
            ],
            contentType: 'html'
        });
        const date = convertToDate(email.content.sent_timestamp || email.created_at);
        return {
            type: COMMUNICATION_TYPE.EMAIL,
            status: (EmailStatusMap[email.content.status] || ''),
            label: '',
            date,
            subject: email.content.email_subject || '',
            to: email.content.email_address || '',
            from: email.content.reply_email || '',
            uuid: email.uuid || '',
            content,
        } as UserCommunicationEmail;
    });
};

const mapSMS = (sms: Composition<SMS>[]): UserCommunicationSMS[] => {
    return sms.map((sms) => {
        const date = convertToDate(sms.content.sent_timestamp || sms.created_at);

        return {
            date,
            content: sms.content.message,
            to: sms.content.mobile_number,
            status: sms.content.status,
            uuid: sms.uuid,
            subject: '',
            type: COMMUNICATION_TYPE.SMS,
        };
    });
};

const mapMeshLetters = (meshLetters: Composition<UserLetter>[]): UserCommunicationMeshLetter[] => {
    return meshLetters.map((ml) => {
        const date = convertToDate(ml.content.sent_timestamp || ml.created_at);
        return {
            type: COMMUNICATION_TYPE.MESH_LETTER,
            status: ml.content.status,
            label: '',
            date,
            subject: ml.content.title,
            to: ml.content.recipient_mailbox_id,
            uuid: ml.uuid,
            xml: ml.content.xml,
            html: ml.content.html,
            pdfMediaResourceId: ml.content.pdf_media_resource_uuid,
            xmlMediaResourceId: ml.content.xml_media_resource_uuid,
        };
    });
};

const mapPushNotifications = (pushNotifications: Composition<PushNotification>[]): UserCommunicationPushNotification[] => {
    return pushNotifications.map((pn) => {
        const date = convertToDate(pn.content.sent_timestamp || pn.created_at);
        return {
            type: COMMUNICATION_TYPE.PUSH,
            status: pn.content.status,
            label: pn.content.title,
            date,
            to: '-',
            uuid: pn.uuid,
            subject: pn.content.body,
            error: pn.content.error_details,
            highPriority: pn.content.high_priority,
        };
    });
};

const mapLetters = (letters: Composition<Letter>[]): UserCommunicationLetter[] => {
    return letters.map((letter) => {
        return {
            type: COMMUNICATION_TYPE.LETTER,
            status: letter.content.status,
            purpose: letter.content.purpose,
            name: letter.content.name,
            subject: letter.content.template_name,
            templateProperties: letter.content.template_properties,
            to: letter.content.address.join(),
            externalIds: letter.content.external_ids,
            date: new Date(letter.content.date),
            uuid: letter.uuid,
        };
    });
};

const mapHtmlLetters = (letters: Composition<HtmlLetter>[]): UserCommunicationHtmlLetter[] => {
    return letters.map((htmlLetter) => {
        return {
            date: new Date(htmlLetter.content.sent_timestamp * 1000),
            type: COMMUNICATION_TYPE.HTML_LETTER,
            status: htmlLetter.content.status,
            pages: htmlLetter.content.pages ? htmlLetter.content.pages : [],
            uuid: htmlLetter.uuid,
            to: '',
            subject: '',
            mediaResourceUuid: htmlLetter.content.pdf_media_resource_uuid
        };
    });
};
