import { Composition } from 'models/Composition';
import { ResourceCategory } from 'models/ResourceCategory';
import React, { Fragment } from 'react';
import _ from 'services/i18n';
import {
    Grid,
    GridBody,
    GridCell,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow,
} from '../../../common/ui/grid';

type ResourceCollectionsTableProps = {
    results: any[];
    selectedCollections: any[];
    setSelectedCollections: (collections: any[]) => void;
    setViewCollection: (viewCollection: boolean) => void;
    setCollection: (collection: Composition<ResourceCategory>) => void;
};

const ResourceCollectionsTable = ({ results, selectedCollections, setSelectedCollections, setViewCollection, setCollection }: ResourceCollectionsTableProps) => {

    const viewCollection = (collection: Composition<ResourceCategory>) => {
        setCollection(collection);
        setViewCollection(true);
    };

    return (
        <Fragment>
            <div className={'scrollable-table'}>
                <Grid>
                    <GridHeader>
                        <GridHeaderRow>
                            <GridHeaderCell
                                sortable={false}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                            </GridHeaderCell>
                            <GridHeaderCell
                                field='content.name'
                                sortable={false}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                                {_`Collection Name`}
                            </GridHeaderCell>
                            <GridHeaderCell
                                field='content.description'
                                sortable={false}
                                filterable={false}
                                rowSpan={4}
                                preventCloneForFilterRow={true}
                                className={'advanced-search-child-column-leading advanced-search-column-group-header'}
                            >
                                {_`Description`}
                            </GridHeaderCell>
                        </GridHeaderRow>
                    </GridHeader>
                    <GridBody>
                        {(results && results.length > 0) && (
                            results.map((result) => {

                                return (
                                    <GridRow key={result.uuid}>
                                        <GridCell>
                                            <SelectCollectionCheckbox
                                                collection={result}
                                                selectedCollections={selectedCollections}
                                                setSelectedCollections={setSelectedCollections}
                                            />
                                        </GridCell>
                                        <GridCell key={result.uuid}><a onClick={() => viewCollection(result)}>{result.content.name}</a></GridCell>
                                        <GridCell key={result.uuid}>{result.content.description}</GridCell>
                                    </GridRow>
                                );
                            })
                        )}
                    </GridBody>
                </Grid>
            </div>
            {(!results || results.length <= 0) && (
                <p>
                    No records match the specified filters.
                </p>
            )}
        </Fragment>
    );
};

function SelectCollectionCheckbox({ collection, selectedCollections, setSelectedCollections }) {

    const selected = selectedCollections.some(selectedCollection => selectedCollection.uuid === collection.uuid);
    const contents = selected ? <i className="far fa-check-square" /> : <i className="far fa-square" />;

    const toggleCollection = () => {
        if (selected) {
            const updatedCollections = selectedCollections.filter(selectedCollection => selectedCollection.uuid !== collection.uuid);
            setSelectedCollections(updatedCollections);
        } else {
            setSelectedCollections([...selectedCollections, collection]);
        }
    };

    return (
        <div tabIndex={1}  className={'ui-checkbox' + ` ${selected ? 'ui-checkbox__checked' : ''}` + ` ${null || ''}`}>
            <label>
                <input type="checkbox" className={'ui-checkbox-input'}
                    checked={selected}
                    onChange={toggleCollection} />
                {contents}
            </label>
        </div>
    );
}

export default ResourceCollectionsTable;
