import { useEffect, useState } from 'react';
import { Filter } from 'common/ui/grid/paginatedTableReducer';

const filterDelayConfig = {
    timeoutId: null,
    interval: 500,
};

const hasFilterChanged = (
    filterState: Filter,
    newFilterState: Filter,
): boolean => {
    for (const [key, value] of Object.entries(filterState)) {
        const newFilterEntry = Object.entries(newFilterState).find(
            (entry) => entry[0] == key,
        );
        const newFilterEntryValue = newFilterEntry ? newFilterEntry[1] : '';
        if (value !== newFilterEntryValue) {
            return true;
        }
    }

    for (const [key, value] of Object.entries(newFilterState)) {
        const oldFilterEntry = Object.entries(filterState).find(
            (entry) => entry[0] == key,
        );
        const oldFilterEntryValue = oldFilterEntry ? oldFilterEntry[1] : '';
        if (value !== oldFilterEntryValue) {
            return true;
        }
    }

    return false;
};

export function useRealFilterChange(
    newFilterState: Filter,
    callback: () => void
): Filter {
    const [internalFilterState, setInternalFilterState] = useState<Filter>(newFilterState);

    if (hasFilterChanged(internalFilterState, newFilterState)) {
        setInternalFilterState(newFilterState);
        callback();
    }

    return internalFilterState;
}

export function useFilterChangeDelay(
    initialFilterState: Filter,
    setFilter: (newFilterState: Filter) => void,
): [
    filterStateDisplayed: Filter,
    setFilterStateDisplayed: (newFilterState: Filter) => void,
] {
    const [filterUpdatedState, setFilterUpdatedState] =
        useState<Filter>(initialFilterState);

    useEffect(() => {
        clearTimeout(filterDelayConfig.timeoutId);

        filterDelayConfig.timeoutId = setTimeout(() => {
            if (
                !hasFilterChanged(initialFilterState, filterUpdatedState)
            ) {
                return;
            }
            setFilter(filterUpdatedState);
        }, filterDelayConfig.interval);
    }, [filterUpdatedState, initialFilterState, setFilter]);

    return [
        filterUpdatedState,
        (newFilterState) => {
            setFilterUpdatedState(newFilterState);
        },
    ];
}
