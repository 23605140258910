import { convertToOutputTzDate, formatDate } from 'common/datetime/DateTime';
import { api } from 'common/api';
import { convertToDate } from 'common/datetime/convertToDate';

export enum DEVICE_OWNERSHIP_TYPES {
    TRUST_OWNED_SINGLE_USE = 'Trust owned, single use',
    TRUST_OWNED_REUSABLE = 'Trust owned, reusable',
    PATIENT_OWNED = 'Patient owned'
}

export interface DeviceGridItem extends DeviceFormItem {
    dateSort: string;
    dateFilter: string;
}

export interface DeviceFormItem {
    dateAssigned: string;
    assignedBy: string;
    department: string;
    type: string;
    ownershipInfo: string;
    make: string;
    model: string;
    id: string;
    dateReturned: string;
    returnedTo: string;
    key: string;
    dispenseId: string;
    returnId: string;
    snomedCode: string;
}

export interface DeviceCompositionItem {
    uuid: string;
    content: DeviceCompositionContent;
}

interface DeviceCompositionContent {
    identifier?: DeviceIdentifier[];
    status: string;
    manufacturer?: string;
    deviceName?: DeviceName;
    type?: DeviceCodeableConcept;
    owner?: DeviceResource;
}

interface DeviceDispenseCompositionContent {
    identifier?: DeviceIdentifier[];
    status?: string;
    device: DeviceReference;
    performer?: DevicePerformer[];
    whenHandedOver?: string;
}

export interface DeviceDispenseCompositionItem {
    uuid: string;
    content: DeviceDispenseCompositionContent;
}

interface DeviceIdentifier {
    use?: string;
    system?: string;
    value?: string;
    name?: string;
    assigner?: DeviceResource;
}

interface DeviceResource {
    identifier?: DeviceIdentifier[];
    type: string;
    name?: string;
    role?: string;
}

interface DeviceName {
    name: string;
    type: string;
}

interface DeviceCodeableConcept {
    coding?: DeviceCoding[];
    text?: string;
}

interface DeviceCoding {
    system?: string;
    version?: string;
    code?: string;
    display?: string;
    user_selected?: boolean;
}

interface DeviceReference {
    reference?: string;
    type?: string;
    display?: string;
}

interface DevicePerformer {
    function?: DeviceCodeableConcept;
    actor: DeviceResource;
}

export interface SnomedCodeNamed {
    code: string;
    deviceName: string;
}

export interface SnomedCodeInfo {
    assignable: boolean;
    name: string;
}

export class DevicesServiceHelper {

    convertDateValueFromComposition(isoDateTime: string): string {
        if (isoDateTime) {
            const timestamp = new Date(isoDateTime.split('T')[0]).getTime() / 1000;
            return formatDate(convertToOutputTzDate(timestamp), 'nhs_date_short');
        }
        return '';
    }

    convertStringDateToISODate(nhsDate: string): string {
        if (nhsDate) {
            const momentDate = convertToDate(nhsDate, 'nhs_date_short');
            return momentDate.toISOString();
        }
        return '';
    }

    mapDeviceCompositionsToFormItem(deviceCompositionsList: DeviceCompositionItem[], deviceDispenseCompositionsList: DeviceDispenseCompositionItem[], snomedCodesWithDevices: SnomedCodeNamed[]): DeviceGridItem[] {
        return deviceCompositionsList.map((item) => {
            const { content: deviceComposition } = item;
            const devicesDataItem: DeviceGridItem = {
                key: item.uuid,
                dispenseId: '',
                returnId: '',
                type: '',
                ownershipInfo: '',
                make: deviceComposition.manufacturer,
                model: deviceComposition.deviceName ? deviceComposition.deviceName.name : '',
                id: (deviceComposition.identifier && deviceComposition.identifier.length) ? deviceComposition.identifier[0].value : '',
                dateAssigned: '',
                dateSort: '',
                dateFilter: '',
                assignedBy: '',
                department: '',
                dateReturned: '',
                returnedTo: '',
                snomedCode: deviceComposition.type.coding[0].code,
            };

            if (!(devicesDataItem.snomedCode in snomedCodesWithDevices)) {
                devicesDataItem.type = 'Unknown(SCTID:' + devicesDataItem.snomedCode + ')';
            } else {
                devicesDataItem.type = deviceComposition.type.text;
            }

            if (deviceComposition.owner && deviceComposition.owner.type === 'Organization') {
                if (deviceComposition.identifier && deviceComposition.identifier.length) {
                    devicesDataItem.ownershipInfo = DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_REUSABLE;
                } else {
                    devicesDataItem.ownershipInfo = DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_SINGLE_USE;
                }
            } else {
                devicesDataItem.ownershipInfo = DEVICE_OWNERSHIP_TYPES.PATIENT_OWNED;
            }

            const linkedDispenseItemsByRef = deviceDispenseCompositionsList.filter(item => item.content.device.reference.includes(devicesDataItem.key));

            linkedDispenseItemsByRef.map(item => {

                const { content: deviceDispenseComp } = item;

                if (deviceDispenseComp.device.reference.includes(devicesDataItem.key)) {

                    if (deviceDispenseComp.performer[0].function.text === 'Dispensed by' ||
                        deviceDispenseComp.performer[0].function.text === 'Requested by') {
                        devicesDataItem.dateAssigned = this.convertDateValueFromComposition(deviceDispenseComp.whenHandedOver);
                        devicesDataItem.dateSort = this.convertDateValueFromComposition(deviceDispenseComp.whenHandedOver);
                        devicesDataItem.dateFilter = this.convertDateValueFromComposition(deviceDispenseComp.whenHandedOver);
                        devicesDataItem.assignedBy = deviceDispenseComp.performer[0].actor.name;
                        devicesDataItem.department = deviceDispenseComp.performer[0].actor.role || '';
                        devicesDataItem.dispenseId = item.uuid;
                    }

                    if (deviceDispenseComp.performer[0].function.text === 'Returned to' ||
                        deviceDispenseComp.performer[0].function.text === 'Discontinued by') {
                        devicesDataItem.dateReturned = this.convertDateValueFromComposition(deviceDispenseComp.whenHandedOver);
                        devicesDataItem.returnedTo = deviceDispenseComp.performer[0].actor.name;
                        devicesDataItem.department = deviceDispenseComp.performer[0].actor.role || '';
                        devicesDataItem.returnId = item.uuid;
                    }
                }
            });
            return devicesDataItem;
        });
    }

    mapFormItemToDeviceComposition(formItem: DeviceFormItem, isDeviceReturned: boolean): DeviceCompositionContent {
        return {
            identifier: formItem.ownershipInfo === DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_REUSABLE ?
                [
                    {
                        use: 'official',
                        value: formItem.id,
                        assigner: {
                            type: 'Organization',
                            identifier:
                            [
                                {
                                    use: 'official',
                                    system: 'https://fhir.nhs.uk/Id/ods-organization-code',
                                    value: 'RHQ',
                                    name: 'Sheffield Teaching Hospitals NHS Foundation Trust',
                                }
                            ],
                        }
                    }
                ]: [],
            status: (isDeviceReturned || formItem.dateReturned.length) ? 'inactive' : 'active',
            manufacturer: formItem.ownershipInfo !== DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_REUSABLE ?
                formItem.make : '',
            ...(formItem.ownershipInfo !== DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_REUSABLE && {
                deviceName: {
                    name: formItem.model,
                    type: 'model-name',
                }
            }),
            type: {
                coding: [
                    {
                        system: 'http://snomed.info/sct',
                        code: formItem.snomedCode,
                        display: 'Description of device-type',
                    }
                ],
                text: formItem.type
            },
            owner: {
                type: formItem.ownershipInfo === DEVICE_OWNERSHIP_TYPES.PATIENT_OWNED ? 'Patient' : 'Organization',
                identifier: [
                    {
                        use: 'official',
                        system: 'https://fhir.nhs.uk/Id/ods-organization-code',
                        value: 'RHQ',
                        name: 'Sheffield Teaching Hospitals NHS Foundation Trust',
                    }
                ],
            }
        };
    }

    mapFormItemToDeviceDispenseComposition(formItem: DeviceFormItem, deviceUuid: string, isDeviceReturned: boolean): DeviceDispenseCompositionContent {
        return {
            status: 'completed',
            device: {
                reference: 'urn:uuid:' + deviceUuid
            },
            performer: [
                {
                    function:
                        {
                            text: this.defineDeviceDispenseFunctionText(formItem, isDeviceReturned),
                        },
                    actor:
                        {
                            type: 'Practitioner',
                            name: isDeviceReturned ? formItem.returnedTo : formItem.assignedBy,
                            role: formItem.department
                        },

                }
            ],
            whenHandedOver: isDeviceReturned ? this.convertStringDateToISODate(formItem.dateReturned) : this.convertStringDateToISODate(formItem.dateAssigned)
        };
    }

    defineDeviceDispenseFunctionText(formItem: DeviceFormItem, isDeviceReturned: boolean): string {
        let functionText = '';

        if (isDeviceReturned) {
            if (formItem.ownershipInfo === DEVICE_OWNERSHIP_TYPES.PATIENT_OWNED) {
                functionText = 'Discontinued by';
            }
            functionText = 'Returned to';
        } else {
            if (formItem.ownershipInfo !== DEVICE_OWNERSHIP_TYPES.PATIENT_OWNED) {
                functionText = 'Dispensed by';
            }
            functionText = 'Requested by';
        }
        return functionText;
    }

    getArrayOfAssignableCodes(snomedCodes: Record<number, SnomedCodeInfo>): SnomedCodeNamed[] {

        return Object.entries(snomedCodes).reduce((arr, [code, { assignable, name: deviceName }])=> {
            if (assignable) {
                arr.push({ code, deviceName });
            }
            return arr;
        }, []);
    }

    async getDeviceTypesFromContentServer(teamId, roleUuid): Promise<SnomedCodeInfo[]> {
        const url = `/teams/${teamId}/content?using_role_uuid=${roleUuid}&location=medical_devices&id=medical_device_types`;
        const response = await api.get(url);
        return response.message.live || {};
    }
}

export const devicesServiceHelper = new DevicesServiceHelper();
