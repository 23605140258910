import React, { FC } from 'react';
// components
import ToggleSwitch from 'common/ui/ToggleSwitch';
// utils
import confirmationModal from 'components/ConfirmationModal';
// interfaces
import { StaticSettingToggleProps } from './StaticSettingToggle.interface';

async function confirmStaticToggle(
    newActiveSetting: boolean,
): Promise<boolean> {
    if (!newActiveSetting) {
        return Promise.resolve(true);
    }

    return confirmationModal.show([
        'You are replacing your saved data with a new search for current data.',
        'You will lose any patient data you have stored in this list.',
        'This operation will return a current patient list and data that meets the search criteria.',
        'Are you sure you wish to continue?',
    ]);
}

const StaticSettingToggle: FC<StaticSettingToggleProps> = (props) => {
    const activeSearch = !props.isStaticSearch;

    const setActiveSearchWithConfirm = async (newValue) => {
        const confirmed = await confirmStaticToggle(newValue);
        if (confirmed) {
            props.onSearchStaticSettingChange(newValue);
        }
    };

    return (
        <ToggleSwitch
            value={activeSearch}
            setValue={setActiveSearchWithConfirm}
            offLabel={'Save Data'}
            onLabel={'Active Search'}
        />
    );
};

export default StaticSettingToggle;
