import React from 'react';
import PropTypes from 'prop-types';
import _ from 'services/i18n';
import BaseInput from 'components/form-panel/base-input';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import { get as _get } from 'lodash';


/**
 * Represents a typeahead control.
 *
 * Props (data):
 *  - label = Required. A text label identifying the input field.
 *  - id = Required. An identifier for the input field.
 *  - helpText = Optional. Help text to display below the input field.
 *  - required = Optional. Boolean indicating if a value must be entered in this field. Defaults to false.
 *  - readOnly = Optional. Boolean flag indicating if the input element is readOnly. The value will not be reported to the parent form.
 *  - disabled = Optional. Boolean flag indicating if the input element is disabled. The value will not be reported to the parent form.
 *  - forceShowValidation = Optional. Boolean indicating if validation errors will be forced to be shown in the UI. Defaults to false. Normally, some errors will be suppresed, e.g. if the user hasn't had a chance to enter anything yet. This flag is used by the containing form.
 *  - initialValue = Optional. The initial value for the form. Ignored after construction, or if value is specified.
 *  - placeholder = Optional. Placeholder text to display in the input field if there is no value present.
 *  - validation = Optional. Identifies a standard validation procedure to carry out. Ignored if blank or unspecified. Valid values are: 'email', 'numeric' (decimal digits only), 'alpha' (letters A-Z only, either case), 'alphanumeric' (letters A-Z and numbers only).
 *  - hideValidationSuccess = Optional. Defaults to false. If true, the component will not show a green tick when validation passes. This is useful where such information would be ambiguous, e.g. on a password field.
 *
 * Props (handlers):
 *  - onChange = Required. A function called when the value changes. Parameters are: id, rawValue, effectiveValue, validated. NOTE: This is provided automatically by the parent form if used directly inside FormPanel.
 *  - onValidate = Optional. A custom validation function which will be called to validate the value. It should return true if the value is valid, or if not it should return a string containing an error message, or an array of multiple error messages. This will only be called if any automated validation passes.
 *
 */
export default class TypeaheadInput extends BaseInput {
    constructor(props){
        super(props);

        this.state = {
            // Boolean flag indicating if validation has been run, regardless of whether it passed or failed.
            isValidated: false,

            // Boolean flag specifying if validation should be shown in the user interface.
            // This is used to avoid displaying errors which would not be helpful, e.g. reporting
            //  that a value is required before the user has had a chance to enter anything.
            // This is ignored if isValidated is false or props.forceShowValidation is true.
            showValidation: false,

            // Stores any validation error messages.
            // If the array if empty, validation either passed or hasn't been done yet.
            errors: [],

            suggestions: [],
            // The currently selected value, or null if the placeholder is selected.
            value: this.props.initialValue || '',
            uiValue: this.props.initialValue || ''
        };


        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.renderSectionTitle = this.renderSectionTitle.bind(this);
        this.getSectionSuggestions = this.getSectionSuggestions.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    componentWillMount(){
        // Validation will notify the parent of the initial value.
        this.initialValidation();
    }

    initialValidation(){
        // Run validation and notify the parent of the initial value.
        // Only show the validation if something is selected, or we
        //  were already reporting it.
        this.validateValue(
            this.state.value || this.state.showValidation,
            true
        );
    }

    /**
     * Event handler: Keyboard focus has moved off the input field.
     */
    onBlur(e){

        if (!this.state.uiValue || (this.state.uiValue !== this.state.value)){
            this.selectIfOnlyOneSuggestion(this.state.uiValue);
        }
        // Make sure any validation errors are displayed.
        this.validateValue(true, true);
        this.setState((state) =>{
            return {
                showValidation: true,
                uiValue: state.value
            };
        });
    }

    selectIfOnlyOneSuggestion(value){
        const suggestions = this.getSuggestions(value) || [];
        if (suggestions.length !== 1){
            return;
        }
        let matchingValue;
        if (this.props.multiSection){
            const options = _get(suggestions,'[0].options',[]);
            if (options.length !== 1){
                return;
            }
            matchingValue = options[0];
        }else{
            matchingValue = suggestions[0];
        }
        if (typeof matchingValue === 'undefined'){
            return;
        }
        this.setState({
            value: matchingValue.value
        });
    }

    /**
     * Validate the currently selected value.
     * This operates on stored state.
     * Parameters:
     *  - showValidation = Optional. If true (default), the validation result will be shown in the user interface. If false, the interface won't show any validation status.
     *  - notifyParent = Optional. If true (default), the value and its validation status will be sent to the parent's onChange handler.
     *  - cb = Optional. A call-back to trigger after validation has finished and any notifications have been dispatched.
     */
    validateValue(showValidation = true, notifyParent = true, cb = null){

        // Don't perform any validation for a control that is readOnly or disabled.
        if (this.props.readOnly || this.props.disabled) {
            this.setState({
                isValidated: false,
                showValidation: showValidation,
                errors: []
            }, () =>{
                if (notifyParent && this.props.onChange) {
                    // Notify the parent that the value doesn't exist.
                    this.props.onChange(this.props.id, undefined, undefined, undefined);
                }
                if (cb) {
                    cb();
                }
            });
            return;
        }

        // Validate the data.
        let result = this.getValidationResult();
        if (result === true) {
            result = [];
        }

        this.setState({
            isValidated: true,
            showValidation: showValidation,
            errors: result
        }, () =>{

            if (notifyParent && this.props.onChange) {
                // Report the result to the parent.
                this.props.onChange(this.props.id, this.state.value, this.state.value, this.state.errors.length == 0);
            }
            if (cb) {
                cb();
            }
        });
    }

    /**
     * Run validation on the stored effective value, and return the result.
     * This will not update current state.
     * This will return true if the validation passes.
     * Otherwise it will return an array of error messages.
     */
    getValidationResult(){
        // If a selection is required then make sure something is selected.
        if (this.props.required && (!this.state.value || this.state.value === '')) {
            return [_`Please select or type an option.`];
        }

        return this.getCustomValidationResult(this.state.value);
    }

    getSuggestionValue(suggestion){
        return suggestion.value;
    }

    renderSuggestion(suggestion){
        return (
            <span>{suggestion.label}</span>
        );
    }

    renderSectionTitle(section){
        return (
            <strong>{section.groupLabel}</strong>
        );
    }

    onChange(event, {newValue}){
        this.setState({
            uiValue: newValue
        });
    }

    onSuggestionSelected(event, {suggestion}){
        this.setState({
            value: suggestion.value,
            uiValue: suggestion.value
        }, this.validateValue.bind(this, true, true))
    }

    getSuggestions(value){
        return this.props.getSuggestions(value);
    }

    onSuggestionsFetchRequested({value}){
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    }

    onSuggestionsClearRequested(){
        this.setState({
            suggestions: []
        });
    }

    getSectionSuggestions(section){
        return section.options;
    }

    shouldRenderSuggestions(){
        return true;
    }

    render(){

        const {
            uiValue: value,
            suggestions,
            errors
        } = this.state;

        const {
            placeholder,
            required,
            readOnly,
            disabled,
            id,
            multiSection,
            label,
            hideValidationSuccess
        } = this.props;

        const inputProps = {
            placeholder,
            value,
            onBlur: this.onBlur,
            onChange: this.onChange
        };

        const shouldShowErrors = (this.state.showValidation || this.props.forceShowValidation) && this.state.isValidated;
        const hasErrors = errors.length > 0;
        const formClassName = classNames({
            'form-group': true,
            'has-error': shouldShowErrors && hasErrors,
            'has-success': shouldShowErrors && !hasErrors && !hideValidationSuccess
        });

        const shouldShowRequiredIndicatior = required && !readOnly && !disabled;

        return (
            <div className={formClassName}>
                <label className='control-label' htmlFor={id}>
                    {label}
                    {shouldShowRequiredIndicatior &&
                    <span className="text-danger">{' '}*</span>
                    }
                </label>

                <Autosuggest
                    multiSection={multiSection}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    inputProps={inputProps}
                    placeholder={placeholder}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSectionTitle={this.renderSectionTitle}
                    getSectionSuggestions={this.getSectionSuggestions}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                />

                <Errors {...this.state} {...this.props} />
                <HelpBlock {...this.state} {...this.props} />
            </div>
        );
    }
}

TypeaheadInput.propTypes = {
    initialValue: PropTypes.string,
    getSuggestions: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string
};

const Errors = ({isValidated, showValidation, forceShowValidation, errors}) =>{
    // Don't show any errors if validation passed or hasn't been done,
    //  or if validation errors are being suppressed.
    if (!isValidated || errors.length == 0 || (!showValidation && !forceShowValidation)) {
        return (<noscript/>);
    }

    let content;
    if (errors.length === 1) {
        content = (<span>{errors[0]}</span>);
    } else {
        let list = errors.map((error, idx) =>{
            return (<li key={idx}>{error}</li>)
        });

        content = (<ul>{list}</ul>);
    }

    return (
        <div className='alert alert-danger'>
            {content}
        </div>
    );
};

const HelpBlock = ({helpText}) =>{
    if (helpText == null || helpText == '') {
        return (<noscript/>);
    }
    return (
        <p className='help-block'>
            {helpText}
        </p>
    );
};