import React, { FC } from 'react';
import { TeamPrefDashboardModule } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { DashboardItem } from './DashboardItem';
import './Dashboard.scss';
export interface DashboardProps {
    items: TeamPrefDashboardModule[];
}
export const Dashboard: FC<DashboardProps> = ({ items = [] }) => {
    return (
        <div className={'msk-dashboard'}>
            {items.map((item, i) => {
                return <DashboardItem key={i} data={item}/>;
            })}
        </div>
    );
};


