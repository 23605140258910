// actions
import { Composition } from 'models/Composition';
import { Referral } from 'models/compositions/Referral';
import { UserNote } from 'models/compositions/UserNote';
import { Appointment } from 'models/compositions/Appointment';
import { DotType, Line } from 'common/ui/line-graph/LineGraph';
import { ResultsPageState } from './ResultsPage';
import { standardDateTimeFormats } from 'common/datetime/convertToDate';
import moment from 'moment';
import _ from 'services/i18n';
import { QuestionnaireResult, QuestionnaireScoreResults } from 'models/QuestionnaireResult';
import { getActivePathwayLabelsFromReferrals } from 'features/pathway-labels/activePathwayHelpers';

// actions
export function actionPathwayChanged(oldState: ResultsPageState, pathwayContext: string): ResultsPageState {
    const newState = {
        ...oldState
    };
    newState.form = {
        pathways: newState.form.pathways,
        pathwayContext,
        // we do reset all values for measure and questionnaire names because of each pathway context
        // has his own questionnaireResults (they will be requested in next step) - the source for
        // questionnaireNames and measures (scoreNames) and also source for lines - it will be reset too
        questionnaireName: undefined,
        measure: undefined,
        measures: [],
        questionnaireNames: [],
        isValid: false
    };
    newState.lines = [];

    newState.noData = false;
    newState.loading = true;
    // selected pathway is only condition to try to build timeline userNotes and appointments
    const userNotes = generateTimelineUserNotes(
        pathwayContext,
        newState.compositions.userNotes
    );

    if (userNotes?.dots?.length) {
        newState.timelines.userNotes = userNotes;
    } else {
        newState.timelines.userNotes = void 0;
    }

    const appointments = generateTimelineAppointment(
        pathwayContext,
        newState.compositions.appointment,
        newState.compositions.referrals
    );
    if (appointments?.dots?.length) {
        newState.timelines.appointments = appointments;
    } else {
        newState.timelines.appointments = void 0;
    }

    return newState;
}

export function actionQuestionnaireResultsUpdated(oldState: ResultsPageState, questionnaireResults): ResultsPageState {
    const newState = {
        ...oldState
    };
    newState.questionnaireResults = normalize(questionnaireResults);
    newState.form.questionnaireNames = Object.keys(questionnaireResults || {}).map((qn) => ({
        name: qn,
        value: qn
    }));
    newState.form.measures = [];
    newState.form.questionnaireName = undefined;
    newState.form.measure = undefined;
    newState.form.isValid = false;
    newState.loading = false;
    if (newState.form.questionnaireNames.length) {
        newState.noData = true;
    }
    newState.lines = [];
    newState.noData = !questionnaireResults;
    return newState;
}

export function actionQuestionnaireNameHasChanged(oldState: ResultsPageState, questionnaireName: string): ResultsPageState {
    const newState = {
        ...oldState
    };
    newState.form.questionnaireName = questionnaireName;
    newState.form.measures = getUniqScoreNames(newState.questionnaireResults[questionnaireName])
        .map((n) => ({ name: n, value: n }));
    newState.lines = [];
    newState.form.measure = undefined;
    newState.form.isValid = false;
    return newState;
}

export function actionReferralCompositionsIsSet(oldState: ResultsPageState, referrals: Composition<Referral>[]): ResultsPageState {
    const newState = {
        ...oldState
    };
    newState.compositions.referrals = referrals;
    newState.noData = !(referrals && referrals.length);
    newState.pathwayLabels = getActivePathwayLabelsFromReferrals(referrals);
    newState.form = {
        pathwayContext: undefined,
        measure: undefined,
        questionnaireName: undefined,
        isValid: false,
        pathways: newState.pathwayLabels.map(({ name, context }) => ({ name, value: context })),
        questionnaireNames: [],
        measures: []
    };
    if (!newState.pathwayLabels.length) {
        newState.noData = true;
    }
    newState.loading = false;
    newState.lines = [];
    newState.timelines = {
        userNotes: undefined,
        appointments: undefined
    };
    newState.questionnaireResults = {};
    return newState;
}

export function actionUserNotesHaveReceived(oldState: ResultsPageState, userNotes: Composition<UserNote>[]): ResultsPageState {
    const newState = {
        ...oldState
    };
    newState.compositions.userNotes = userNotes;
    newState.timelines.userNotes = generateTimelineUserNotes(newState.form.pathwayContext, userNotes);

    return newState;
}

export function actionAppointmentsHaveReceived(oldState: ResultsPageState, appointments: Composition<Appointment>[]): ResultsPageState {
    const newState = {
        ...oldState
    };
    newState.compositions.appointment = appointments;
    newState.timelines.appointments = generateTimelineAppointment(
        newState.form.pathwayContext,
        newState.compositions.appointment,
        newState.compositions.referrals
    );

    return newState;
}

export function actionMeasureHasChanged(oldState: ResultsPageState, scoreName: string): ResultsPageState {
    const newState = {
        ...oldState
    };
    newState.form.measure = scoreName;
    newState.form.isValid = true;
    const scores = groupBy('score_name', newState.questionnaireResults[newState.form.questionnaireName]);
    const scoreGraphLine = scoresToGraphLine(scores.get(newState.form.measure));
    if (scoreGraphLine) {
        newState.lines = [{
            color: '#447ec5',
            title: newState.form.measure,
            dotType: DotType.Square,
            dots: scoreGraphLine
        }];
    }
    return newState;
}

// helpers
function generateTimelineUserNotes(pathwayContext, userNotes: Composition<UserNote>[] = []): Line {
    if (!pathwayContext) {
        return;
    }
    userNotes = userNotes?.filter(({ content }) => {
        return content.labels && content.labels.some((label) => label.context === pathwayContext);
    });
    const userNoteTimeLine = notesToTimeLine(userNotes);
    return userNoteTimeLine;
}

function notesToTimeLine(userNotes: Composition<UserNote>[]): Line {
    if (!userNotes || !userNotes.length) {
        return;
    }
    return {
        title: _`Care Events`,
        color: '#3a0d47',
        dotType: DotType.TriangleUp,
        tooltip: {
            content: (line, dot) => `${moment(dot.x).format(standardDateTimeFormats.nhs_date_short)}`
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        dots: userNotes.map(({ created_at, content: { title } }) => ({
            x: moment(created_at).valueOf(),
            title: title || _`Unknown`
        }))
    };
}

function generateTimelineAppointment(pathwayContext: string, appointments: Composition<Appointment>[] = [], referrals: Composition<Referral>[] = []): Line {
    if (!pathwayContext) {
        return;
    }
    referrals = findReferralsByPathwayContext(pathwayContext, referrals);
    // eslint-disable-next-line @typescript-eslint/camelcase
    const referralIds = referrals.reduce((acc, { content: { referral_ids } }) => acc.concat(referral_ids), []);
    const referralsAppointments = findRelatedAppointments(appointments, referralIds);
    return appointmentsToTimeLine(referralsAppointments);
}

function findReferralsByPathwayContext(pathwayContext, referrals: Composition<Referral>[]): Composition<Referral>[] {
    return referrals.filter(({ content: { labels } }) => {
        return labels && labels.some(({ context }) => {
            return context === pathwayContext;
        });
    });
}

function findRelatedAppointments(appointments: Composition<Appointment>[], referralIds: string[]): Composition<Appointment>[] {
    // eslint-disable-next-line @typescript-eslint/camelcase
    return appointments.filter(({ content: { referral_id } }) => referralIds.some((id) => referral_id === id));
}

function appointmentsToTimeLine(appointments: Composition<Appointment>[]): Line {
    if (!appointments || !appointments.length) {
        return;
    }
    return {
        title: _`Appointment`,
        color: '#6a1d85',
        dotType: DotType.TriangleUp,
        tooltip: {
            content: (line, dot) => `${moment(dot.x).format(standardDateTimeFormats.nhs_date_short)}`
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        dots: appointments.reduce((acc, { content: { date, details } }) => {
            if (date) {
                const dot = {
                    x: moment.unix(date).valueOf(),
                    title: details || _`Unknown`
                };
                acc.push(dot);
            }
            return acc;
        }, [])
    };
}

function getUniqScoreNames(scores: QuestionnaireResult[] = []): string[] {
    return Array.from(scores.reduce((acc, item) => {
        acc.add(item.score_name);
        return acc;
    }, new Set<string>()));
}

function scoresToGraphLine(arr: QuestionnaireResult[]) {
    return arr.sort(({ timestamp: a }, { timestamp: b }) => a - b)
        // eslint-disable-next-line @typescript-eslint/camelcase
        .map(({ timestamp, score_value_float }) => ({ x: timestamp * 1000, y: score_value_float }));
}

function groupBy(field, arr): Map<string, any> {
    if (!field || !arr || !arr.length) {
        return;
    }
    return arr.reduce((acc, item) => {
        if (!acc.has(item[field])) {
            acc.set(item[field], []);
        }
        const arr = acc.get(item[field]);
        arr.push(item);
        return acc;
    }, new Map());
}

function normalize(questionnaireResults: QuestionnaireScoreResults) {
    Object.keys(questionnaireResults).forEach((key) => {
        questionnaireResults[key] = questionnaireResults[key].reduce((acc, score) => {
            let {
                // eslint-disable-next-line @typescript-eslint/camelcase
                score_value_float,
                // eslint-disable-next-line @typescript-eslint/camelcase,prefer-const
                score_value_string,
                // eslint-disable-next-line @typescript-eslint/camelcase,prefer-const
                score_value_int
            } = score;
            // eslint-disable-next-line @typescript-eslint/camelcase
            score_value_float = (typeof score_value_float !== 'undefined') ?
                // eslint-disable-next-line @typescript-eslint/camelcase
                score_value_float : typeof score_value_int !== 'undefined' ?
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    score_value_int : parseFloat(score_value_string);

            // eslint-disable-next-line @typescript-eslint/camelcase
            if (score_value_float) {
                acc.push({
                    ...score,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    score_value_float
                });
            }


            return acc;
        }, []);
    });
    return questionnaireResults;
}
