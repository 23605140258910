/* eslint-disable @typescript-eslint/camelcase */
export type BulkOperationStatus =
    | 'in_progress'
    | 'completed'
    | 'in_queue'
    | 'pending'
    | 'valid'
    | 'failed'
    | 'error'
    | 'invalid_structure'
    | 'invalid_folder'
    | 'not_permitted'
    | 'deceased';

export const INCOMPLETE_STATUSES: BulkOperationStatus[] = [
    'in_queue',
    'in_progress',
    'pending',
    'valid'
];

export const FAILURE_STATUSES: BulkOperationStatus[] = [
    'failed',
    'error',
    'invalid_structure',
    'invalid_folder',
    'not_permitted',
    'deceased'
];

const BulkOperationStatusLabelMap: Record<BulkOperationStatus, string> =
    {
        in_progress: 'In Progress',
        completed: 'Complete',
        in_queue: 'In Queue',
        pending: 'Pending',
        valid: 'Valid',
        failed: 'Failed',
        error: 'Error',
        invalid_structure: 'Invalid Structure',
        invalid_folder: 'Invalid Folder',
        not_permitted: 'Not Permitted',
        deceased: 'Deceased'
    };

const BulkOperationStatusDescriptionMap: Record<
    BulkOperationStatus,
    string
> = {
    in_progress: 'In Progress',
    completed: 'Complete',
    in_queue: 'In Queue',
    pending: 'Pending',
    valid: 'Valid',
    failed: 'Failed',
    error: 'Error',
    invalid_structure: 'Invalid Structure',
    invalid_folder: 'Invalid Folder',
    not_permitted: 'Not Permitted',
    deceased: 'Not Performed - Deceased'
};

export const getBulkOperationStatusValuesForLabel = (statusLabel: string): string[] => {
    return Object.entries(BulkOperationStatusLabelMap)
        .filter(entry => entry[1] === statusLabel)
        .map(entry => entry[0]);
};

export const getBulkOperationStatusLabels = () => {
    return Object.values(BulkOperationStatusLabelMap);
};

export const getBulkOperationStatusLabel = (status: string) => {
    return BulkOperationStatusLabelMap[status] || 'Unknown status';
};

export const getBulkOperationStatusDescription = (status: string) => {
    return BulkOperationStatusDescriptionMap[status] || 'Unknown status';
};
