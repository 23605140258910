import React, { useEffect, useState, useCallback, Fragment, FC } from 'react';
import Select from 'react-select';
import _ from 'services/i18n';
// components
import { Row, Column, FormGroup } from 'ui';
import ErrorView from 'components/errorbox';
import { Iframe } from './iframe';
// hooks
import { useSortedDepartmentFolderIdData } from 'features/department/hooks/useSortedDepartmentData';
// helpers
// interfaces
import {
    LetterStationeryItem,
    LetterTemplateItem,
    SendLetterState,
} from '../letters.interface';
import { lettersService } from 'services/letters.service';
import {
    DataOption,
} from 'features/department/department.interface';
import { useGroupedDepartmentDataOptionList } from 'features/department/hooks/useGroupedDepartmentDataOptionList';
import { HTMLLetterWYSIWYGPreview } from 'common/ui/wyiswyg-preview/HTMLLetterWYSIWYGPreview';

type LetterCreateFormProps = {
    id: string;
    errors?: string[];
    onErrors: (errors: string[]) => void;
    onChange: (partialState: Partial<SendLetterState>) => unknown;
    form: SendLetterState;
    onSubmit: (f) => unknown;
    hideSubmit?: boolean;
};

export const LetterCreateForm: FC<LetterCreateFormProps> = (props) => {
    const {
        id,
        errors,
        onErrors,
        form,
        onChange,
        onSubmit,
        hideSubmit,
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [templateList, setTemplateList] = useState<LetterTemplateItem[]>([]);
    const [stationeryList, setStationeryList] = useState<LetterStationeryItem[]>([]);

    useEffect(() => {
        async function loadResources() {
            setIsLoading(true);

            try {
                const letterTemplates = await lettersService.getAllTemplates();

                setTemplateList(letterTemplates.templates || []);
                setStationeryList(letterTemplates.stationery || []);
            } catch (err) {
                onErrors([err?.message || 'Unknown error']);
            }

            setIsLoading(false);
        }

        loadResources();
    }, [onErrors]);

    const [triggerContentUpdate, setTriggerContentUpdate] =
        useState<boolean>(false);

    const selectedDepartmentPPEOption = form.stationery
        ? {
            label: form.stationery.name,
            value: form.stationery.name,
        }
        : null;

    const [isTemplateSelected, setIsTemplateSelected] = useState(!!form.template);

    const [initialEditorValue, setInitialEditorValue] = useState(
        form.htmlContent,
    );

    const sortedTemplateList = useSortedDepartmentFolderIdData(templateList);

    const [templateOptionList] = useGroupedDepartmentDataOptionList(sortedTemplateList, d => d.name);

    const selectedTemplateOption: DataOption<LetterTemplateItem> = form.template
        ? {
            label: form.template.name,
            value: form.template,
        }
        : null;

    const handleTemplateChange = useCallback(
        (template: LetterTemplateItem | null) => {
            setTriggerContentUpdate(true);

            new Promise<void>((resolve) => {
                const data: Partial<SendLetterState> = template
                    ? {
                        template,
                        htmlContent: template.htmlContent,
                    }
                    : {
                        template: undefined,
                        htmlContent: '',
                    };

                onChange(data);
                setInitialEditorValue(template?.htmlContent || '');

                resolve();
            }).then(() => {
                setTriggerContentUpdate(false);
            });
            setIsTemplateSelected(template != null);
        },
        [onChange],
    );

    const handlePreviewChange = (value: unknown, field?: string) => {
        if (!field) {
            onChange({ htmlContent: value as string });
            console.log("handlePreviewChange()");
        }
    };

    if (isLoading) {
        return <h5>{_`Loading... please wait`}</h5>;
    }

    return (
        <Fragment>
            <ErrorView errors={errors} />
            <form onSubmit={onSubmit} id={props.id}>
                <Row>
                    <Column md={6}>
                        <FormGroup>
                            <label htmlFor={`${props.id}-template`}>{_`Populate from template`}</label>
                            <Select
                                inputId={`${props.id}-template`}
                                className="filter-select"
                                classNamePrefix="filter-select"
                                placeholder={'-- Select a template --'}
                                options={templateOptionList}
                                onChange={(option) =>
                                    handleTemplateChange(
                                        option ? option.value : null,
                                    )
                                }
                                value={selectedTemplateOption}
                            />
                        </FormGroup>
                    </Column>
                    <Column md={6}>
                        <FormGroup>
                            <label htmlFor={`${props.id}-department-ppe`}>{_`Sending Department and PPE`}</label>
                            <Select
                                inputId={`${props.id}-department-ppe`}
                                name={'department-ppe'}
                                isClearable={true}
                                isDisabled={!isTemplateSelected}
                                placeholder={_`-- Select Department / PPE --`}
                                value={selectedDepartmentPPEOption}
                                onChange={(option) => {
                                    let stationery;
                                    if (option) {
                                        stationery = stationeryList.find(
                                            (stationery) => stationery.name === option.value,
                                        );
                                    }
                                    onChange({ stationery });
                                }}
                                options={stationeryList.map((stationery) => {
                                    return {
                                        label: stationery.name,
                                        value: stationery.name
                                    };
                                })}
                            />
                        </FormGroup>
                    </Column>
                </Row>
                {!isTemplateSelected ? (
                    <div
                        style={{
                            width: '100%',
                            background: '#F1F1F1',
                            height: 140,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                color: '#555555',
                                fontWeight: 'bold',
                            }}
                        >
                            Select Template
                        </span>
                    </div>
                ) : (
                    <Row style={{ overflow: 'scroll' }}>
                        <Column md="12">
                            {form.stationery ? (
                                <Iframe
                                    srcDoc={form.stationery.header.htmlContent}
                                    style={{ border: 'none' }}
                                />
                            ) : (
                                <div
                                    style={{
                                        width: '100%',
                                        background: '#F1F1F1',
                                        height: 140,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#555555',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Select Sending Department
                                    </span>
                                </div>
                            )}
                            <div
                                style={{
                                    margin: '0 auto',
                                    maxWidth: 600,
                                    width: '100%',
                                    marginTop: 10,
                                }}
                            >
                                <FormGroup>
                                    <HTMLLetterWYSIWYGPreview
                                        triggerContentUpdate={triggerContentUpdate}
                                        onChange={handlePreviewChange}
                                        type={'letter'}
                                        value={initialEditorValue}
                                    />
                                </FormGroup>
                            </div>
                            {form.stationery ? (
                                <Iframe
                                    srcDoc={form.stationery.footer.htmlContent}
                                    style={{ border: 'none' }}
                                />
                            ) : (
                                <div
                                    style={{
                                        width: '100%',
                                        background: '#F1F1F1',
                                        height: 140,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#555555',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Select Sending Department
                                    </span>
                                </div>
                            )}
                            {!hideSubmit && (
                                <FormGroup>
                                    <button
                                        type="submit"
                                        className="btn btn-default"
                                    >{_`Send Email`}</button>
                                </FormGroup>
                            )}
                        </Column>
                    </Row>
                )}
            </form>
        </Fragment>
    );
};
