import _ from 'services/i18n';
import { upperFirst as _upperFirst } from 'lodash';
import { convertToOutputTzDate, formatDate } from 'common/datetime/DateTime';
import { convertToDate } from 'common/datetime/convertToDate';
import { SortOrder } from 'common/ui/grid';
import { QuestionnaireResponseAnyType } from 'models/compositions/QuestionnaireResponse';
import { Composition } from 'models/Composition';

export interface QuestionnaireListData {
    couldBeCompared: boolean;
    isCompleted: boolean;
    isConfidential: boolean;
    isLegacy: boolean;
    isValid: boolean;
    questionnaire: any;
    questionnaireResponse: {document: Composition<QuestionnaireResponseAnyType>};
    scoreName: string;
    scoreValue: number;
}

export function getScore({ isCompleted, scoreName, scoreValue, questionnaire }: { isCompleted: boolean; scoreName: string; scoreValue: number; questionnaire: any }): string {
    if (!isCompleted) {
        return null;
    }
    if (questionnaire.type !== 'coopQuestionnaire') {
        if (scoreValue == null) {
            return null;
        } else {
            return String(scoreValue);
        }
    } else {
        if (scoreValue == null) {
            return 'VAS-XX';
        } else {
            return `${scoreName}-${scoreValue}`;
        }
    }
}
export function getUIStatus(status = '') {
    return _upperFirst(status).replace(/_/g, ' ');
}
export function getQuestionnairePath({ questionnaireResponse, folderId }: { questionnaireResponse: any; folderId: number }) {

    let queryParams = '';
    if (questionnaireResponse?.type === 'questionnaireResponse') {
        queryParams = 'questionnaire';
    } else {
        queryParams = 'legacy';
    }

    const queryString = queryParams.length ? `?${queryParams}` : '';
    const path = `/clinical_portal/folder/${folderId}/patient/questionnaires/${questionnaireResponse.document.uuid}${queryString}`;
    return path;
}
export function getButtonsActionList({ questionnaireStatus, couldBeCompared, onArchiveQuestionnaire, onCompare, questionnaireResponse, path, questionnaireType }) {
    const actionList: any = [
        { route: path, label: _`View` }
    ];
    if (questionnaireStatus === 'assigned') {
        // allow deletion for assigned status
        actionList.push(
            {
                type: 'callback',
                callback: onArchiveQuestionnaire.bind(null, questionnaireResponse.document.uuid),
                label: _`Archive`,
                style: 'danger'
            }
        );
    }
    if (couldBeCompared && questionnaireType !== 'coopQuestionnaire') {
        actionList.push({
            type: 'callback',
            callback: onCompare.bind(null, questionnaireResponse),
            label: _`Compare`,
            style: 'success'
        });
    }

    return actionList;
}

export const filterSelectors: Record<string, (item: QuestionnaireListData) => string> = {
    name: (item) => {
        const name = item.questionnaire?.document?.name;
        return name;
    },
    issuedOn: (item) => {
        const date = formatDate(
            convertToOutputTzDate(item.questionnaireResponse?.document?.created_at, 'nhs_date_fulldate'),
            'questionnaires_date'
        );
        return date;
    },
    completedOn:  (item) => {
        const dateCompletedAt = item.questionnaireResponse?.document?.content?.status_changes.completed_at;
        const date = dateCompletedAt ? formatDate(convertToOutputTzDate(dateCompletedAt)) : '-';
        return date;
    },
    status: (item) => {
        const status = getUIStatus(item.questionnaireResponse.document.content.status);
        return status;
    },
    score: ({isCompleted, scoreName, scoreValue, questionnaire}) => {
        const score = getScore({questionnaire, isCompleted, scoreName, scoreValue}) || '-';
        return score;
    },
    issuedBy: (item) => {
        return item.questionnaireResponse.document.content.name_of_issuer || '';
    },
    dueDate: (item) => {
        return item.questionnaireResponse.document.content.due_date
            ? formatDate(
                convertToOutputTzDate(item.questionnaireResponse.document.content.due_date, 'nhs_date_fulldate'),
                'questionnaires_date'
            ) : 'No due date';
    },
    version: (item) => {
        return String(item.questionnaire.document.version || '');
    }
};
export const defaultFilter = (str, filterString) => {
    filterString = filterString.toLowerCase();
    str = str.toLowerCase();
    return str.includes(filterString);
};
export const defaultStringSort = (a, b, sortOrder) => {

    if(sortOrder === SortOrder.NO) {
        return 0;
    }

    a = String(a??'').toLowerCase();
    b = String(b??'').toLowerCase();

    return sortOrder == SortOrder.ASC ?
        a > b ?
            1 :
            a < b ?
                -1 :
                0 :
        a > b ?
            -1 :
            a < b ?
                1 :
                0;
};
export const defaultNumberSort = (a, b, sortOrder) => {
    if(sortOrder === SortOrder.NO) {
        return 0;
    }
    const numA = parseFloat(a) || 0;
    const numB = parseFloat(b) || 0;

    return sortOrder === SortOrder.ASC ? numA - numB : numB - numA;
};
export const sortSelectors: Record<string, (item: QuestionnaireListData) => string | number> = {
    name: filterSelectors.name,
    issuedOn: (item) => {
        const createdAt  = convertToOutputTzDate(item.questionnaireResponse?.document?.created_at, 'nhs_date_fulldate');
        const date = createdAt && createdAt.isValid() && createdAt.unix() || 0;
        return date;
    },
    completedOn: (item) => {
        const completedAtDate = item.questionnaireResponse?.document?.content.status_changes?.completed_at;
        const completedAt  = completedAtDate && convertToOutputTzDate(completedAtDate);
        const date = completedAt?.isValid?.() && completedAt.unix() || 0;
        return date;
    },
    status: (item) => {
        const status = getUIStatus(item.questionnaireResponse.document.content.status);
        return status;
    },
    score: ({isCompleted, scoreName, scoreValue, questionnaire}) => {
        const score = getScore({questionnaire, isCompleted, scoreName, scoreValue});
        return score;
    },
    issuedBy: (item) => {
        return item.questionnaireResponse.document.content.name_of_issuer || '';
    },
    dueDate: (item) => {
        if (!item.questionnaireResponse.document.content.due_date) {
            return -1;
        }
        const date =  convertToOutputTzDate(item.questionnaireResponse.document.content.due_date, 'nhs_date_fulldate');
        return date.isValid() ? date.unix() : 0;
    },
    version: (item) => {
        return item.questionnaire.document.version;
    }
};
export const sortHandlers: Record<string, (a: string | number , b: string | number, sortOrder: SortOrder) => number> = {
    name: defaultStringSort,
    issuedOn: defaultNumberSort,
    completedOn: defaultNumberSort,
    status: (a, b, sortOrder: SortOrder) => {

        if(sortOrder === SortOrder.NO) {
            return 0;
        }

        if(a == null && b == null) {
            return 0;
        }

        if(a == null) {
            return sortOrder === SortOrder.ASC ? -1 : 1;
        }

        if(b == null) {
            return sortOrder === SortOrder.ASC ? 1 : -1;
        }

        if(typeof a === 'string' && typeof b === 'string') {
            return defaultStringSort(a, b, sortOrder);
        }

        return 0;
    },
    score: (a, b, sortOrder: SortOrder) => {
        if(sortOrder === SortOrder.NO) {
            return 0;
        }

        if(a == null && b == null) {
            return 0;
        }

        if(a == null) {
            return sortOrder === SortOrder.ASC ? 1 : -1;
        }

        if(b == null) {
            return sortOrder === SortOrder.ASC ? -1 : 1;
        }

        const aNum = parseFloat(a as any);
        const bNum = parseFloat(b as any);

        if(isNaN(aNum) && isNaN(bNum)) {
            return defaultStringSort(a, b, sortOrder);
        }

        if(isNaN(aNum)) {
            return sortOrder === SortOrder.ASC ? 1 : -1;
        }
        if(isNaN(bNum)) {
            return sortOrder === SortOrder.ASC ? -1 : 1;
        }

        return defaultNumberSort(aNum, bNum, sortOrder);
    }
};
