export type SendEmailState = {
    subject: string;
    htmlContent: string;
    plainTextContent: string;
} 

export type EmailTemplateContent = {
    contentType: string;
    description: string;
    htmlContent: string;
    htmlFilename: string;
    htmlLastUpdated: string;
    lastUpdated: string;
    name: string;
    txtContent: string;
    txtFilename: string;
    txtLastUpdated: string;
    yamlFilename: string;
    department_folder_uuid?: string;
}

export function validate(state: SendEmailState, onErrors: (errors: string[]) => void): boolean {
    const normalisedState = normalise(state);

    const errors = [];

    if (normalisedState.subject === '') errors.push('Email subject is required');
    if (normalisedState.htmlContent === '' || normalisedState.plainTextContent === '') errors.push('Email content is required');

    onErrors(errors);
    
    return errors.length === 0;
}

export function normalise(state: SendEmailState): SendEmailState {
    return {
        subject: state.subject.trim(),
        htmlContent: state.htmlContent.trim(),
        plainTextContent: state.plainTextContent.trim()
    };
}
