import React, { FC, useCallback } from 'react';
import _ from 'services/i18n';
import ErrorView from 'components/errorbox';
import { Row, Column, FormGroup } from 'ui';
import { useEffect, useState } from 'react';
import { DeviceFormItem, SnomedCodeNamed } from './services/DevicesServiceHelper';
import { ModalDialog } from 'common/modalDialog';
import DateInput from 'components/form/date-input';
import { validateAddOrEditDeviceForm, validateReturnDeviceForm } from './utils/validator';

type DeviceFormProps = {
    id?: string;
    loading?: boolean;
    errors?: string[];
    form: DeviceFormItem;
    deviceTypesList: string[];
    ownershipTypesList: string[];
    snomedCodesList: SnomedCodeNamed[];
    deviceFormMode: DeviceFormModes;
    onSubmit: (deviceItem: DeviceFormItem) => void;
    onClose: () => void;
};

export enum DeviceFormModes {
    addDeviceMode,
    editDeviceMode,
    returnDeviceMode
}

export const DeviceForm: FC<DeviceFormProps> = ({
    id,
    loading,
    deviceTypesList,
    ownershipTypesList,
    snomedCodesList,
    form,
    onSubmit,
    deviceFormMode,
    onClose
}) => {
    const [errorsList, setErrorsList] = useState([]);

    const [formState, setFormState] = useState(form);

    const [isFirstInputsGroupDisabled, setFirstInputsGroupDisabled] = useState(true);

    const [isSecondInputsGroupDisabled, setSecondInputsGroupDisabled] = useState(true);

    useEffect( () => {
        if (formState.ownershipInfo) {
            disableOptionalFields(formState.ownershipInfo);
        }
    }, [formState.ownershipInfo]);

    const handleChange = ({ target:{ value,name } }) => {
        setFormState((state) => {
            return {
                ...state,
                [name]: value
            };
        });
    };

    const disableOptionalFields = (ownershipInfo: string) => {
        if (ownershipInfo === 'Trust owned, reusable') {
            setFirstInputsGroupDisabled(true);
            setSecondInputsGroupDisabled(false);
        } else {
            setFirstInputsGroupDisabled(false);
            setSecondInputsGroupDisabled(true);
        }
    };

    const onDateChange = useCallback(({ target: { name, value } }) => {
        if (!value) {
            return;
        }
        setFormState((state) => {
            return {
                ...state,
                [name]: value,
            };
        });
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formErrors = deviceFormMode === DeviceFormModes.returnDeviceMode ? validateReturnDeviceForm(formState) : validateAddOrEditDeviceForm(formState);
        const correspondingSnowmedCode = snomedCodesList.find(item => item.deviceName === formState.type);

        if (correspondingSnowmedCode) {
            formState.snomedCode = correspondingSnowmedCode.code;
        }

        if (formErrors.length > 0) {
            setErrorsList(formErrors);
        } else {
            onSubmit(formState);
            onClose();
        }
    };

    if (loading) {
        return (
            <h5>{_`Loading... please wait`}</h5>
        );
    }

    return (
        <ModalDialog
            title={deviceFormMode === DeviceFormModes.editDeviceMode ? _`Update device`
                : (deviceFormMode === DeviceFormModes.returnDeviceMode ? _`Return device` : _`Add new device`)}
            onClose={onClose}
        >
            <ErrorView errors={errorsList}/>
            <form className={'device-form'} onSubmit={(event) => handleSubmit(event)}>
                {deviceFormMode !== DeviceFormModes.returnDeviceMode &&
                (
                    <>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-dateAssigned`}>
                                        {_`Date assigned`}
                                        <DateInput
                                            dateFormat={'DD-MMM-YYYY'}
                                            value={formState.dateAssigned}
                                            name='dateAssigned'
                                            onChange={onDateChange}
                                        />
                                    </label>
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-assignedBy`}>{_`Assigned by`}</label>
                                    <input id={`${id}-assignedBy`} type="text" value={formState.assignedBy}
                                        name="assignedBy" className={'form-control'} onChange={handleChange} />
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-department`}>{_`Department`}</label>
                                    <input id={`${id}-department`} type="text" value={formState.department}
                                        name="department" className={'form-control'} onChange={handleChange} />
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-type`}>{_`Type`}</label>
                                    <select id={`${id}-type`} name="type" data-testid={`${id}-type`} className='form-control' onChange={handleChange} >
                                        {formState.type === '' ?
                                            <option key={'default'} >{_`-- Select device type --`}</option>
                                            : <option key={'default'} value={formState.type}>{formState.type}</option>}
                                        {deviceTypesList?.map((device, idx) => {
                                            if (device !== formState.type) {
                                                return (
                                                    <option key={idx} value={device}>{device}</option>
                                                );
                                            }
                                            return null;
                                        })}
                                    </select>
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-ownershipInfo`}>{_`Trust owned?`}</label>
                                    <select id={`${id}-ownershipInfo`} name="ownershipInfo" className='form-control' onChange={handleChange} >
                                        {formState.ownershipInfo === '' ?
                                            <option value='' >{_`-- Select ownership --`}</option>
                                            : <option value={formState.ownershipInfo}>{formState.ownershipInfo}</option>}
                                        {ownershipTypesList?.map((ownershipInfo, idx) => {
                                            if (ownershipInfo !== formState.ownershipInfo) {
                                                return (
                                                    <option key={idx} value={ownershipInfo}>{ownershipInfo}</option>
                                                );
                                            }
                                            return null;
                                        })}
                                    </select>
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-make`}>{_`Make`}</label>
                                    <input id={`${id}-make`} disabled={isFirstInputsGroupDisabled} value={formState.make}
                                        type="text" name="make" className={'form-control'} onChange={handleChange} />
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-model`}>{_`Model`}</label>
                                    <input id={`${id}-model`} disabled={isFirstInputsGroupDisabled} value={formState.model}
                                        type="text" name="model" className={'form-control'} onChange={handleChange} />
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor={`${id}-id`}>{_`CE ID`}</label>
                                    <input id={`${id}-id`} disabled={isSecondInputsGroupDisabled} value={formState.id}
                                        type="text" name="id" className={'form-control'} onChange={handleChange} />
                                </FormGroup>
                            </Column>
                        </Row>
                    </>
                )}
                {(deviceFormMode === DeviceFormModes.returnDeviceMode
                    || deviceFormMode === DeviceFormModes.editDeviceMode) &&
                (
                    <>
                        <Row>
                            <Column md="12">
                                <label htmlFor='dateReturned'>{_`Date returned`}</label>
                                <FormGroup>
                                    <DateInput
                                        value={formState.dateReturned}
                                        dateFormat={'DD-MMM-YYYY'}
                                        minDate={formState.dateAssigned}
                                        disabled={deviceFormMode === DeviceFormModes.editDeviceMode
                                            && !formState.dateReturned && !formState.returnedTo}
                                        name='dateReturned'
                                        onChange={onDateChange}
                                    />
                                </FormGroup>
                            </Column>
                        </Row>
                        <Row>
                            <Column md="12">
                                <FormGroup>
                                    <label htmlFor='returnedTo'>{_`Returned to`}</label>
                                    <input
                                        type="text"
                                        name="returnedTo"
                                        disabled={deviceFormMode === DeviceFormModes.editDeviceMode
                                            && !formState.dateReturned && !formState.returnedTo}
                                        value={formState.returnedTo}
                                        className={'form-control'}
                                        onChange={handleChange} />
                                </FormGroup>
                            </Column>
                        </Row>
                    </>
                )}
                <Row>
                    <Column md="12">
                        <FormGroup>
                            <button type={'submit'} className='btn btn-default'>
                                {deviceFormMode === DeviceFormModes.editDeviceMode ? _`Update device`
                                    : (deviceFormMode === DeviceFormModes.returnDeviceMode ? _`Return device` : _`Add new device`)}
                            </button>
                        </FormGroup>
                    </Column>
                </Row>
            </form>
        </ModalDialog>
    );
};

