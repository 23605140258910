import React from 'react';
import DataStore from 'services/data-store';

let roles, service;

DataStore.getListener('currentRoles', (value) => {
    roles = value;
});

DataStore.getListener('me.currentRole', (value) => {
    service = (value || {}).serviceName || '';
});

export function $check(roleName, ignoreService = false) {
    if (!roles.hasOwnProperty(roleName)) {
        return false;
    }

    if (ignoreService) {
        return true;
    }

    if (roles[roleName].indexOf(service) !== -1) {
        return true;
    }

    return false;
}

const HasRole = ({ role, fail, children }) => {
    const firstChild = [].concat(children).shift();

    if ($check(role)) {
        return firstChild;
    } else {
        if (fail) {
            return fail;
        } else {
            return (<noscript />);
        }
    }
};

export default HasRole;
