import React from 'react';
import DataStore from 'services/data-store';

let roles, service;

DataStore.getListener('currentRoles', (value) => {
    roles = value
});

DataStore.getListener('me.currentRole', (value) => {
    service = (value || {}).serviceName || "";
});

export function $check(serviceName) {
    return service.toLowerCase() == serviceName.toLowerCase();
}

export function getService() {
    return service;
}

const HasService = ({ service, fail = void 0, children = void 0 }) => {
    let firstChild = [].concat(children).shift();

    if ($check(service)) {
        return firstChild;
    } else {
        if (fail) {
            return fail;
        } else {
            return (<noscript />);
        }
    }
};

export default HasService;