/* eslint-disable indent */
import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { browserHistory } from 'react-router';
import AppointmentContainer from 'features/patient/appointments/AppointmentContainer';

export default class extends BasePage {
    constructor(props) {
        super(props);
        this.state = {
            folderId: this.props.folderId,
        };
        if (this.props.location) {
            this.backURL = `${this.getFolderPath()}/patient/appointments`;
            this.backText = 'Return to appointments';
            if (this.props.location.query.back) {
                switch (this.props.location.query.back.toLowerCase()) {
                    case 'pathway':
                        this.backURL = `${this.getFolderPath()}/patient/pathway`;
                        this.backText = 'Return to pathway';
                        break;
                }
            }
        }

        this.folderId = this.props.folderId;

        this.bindFunctions(['handleBackClick', 'handleSubmit']);
    }

    pageTitle() {
        return _`Appointments` + ' | ' + _`MyPathway Clinical Portal`;
    }

    handleSubmit() {
        browserHistory.push(this.backURL);
    }

    handleBackClick(event) {
        event.preventDefault();
        browserHistory.push(this.backURL);
    }

    render() {
        const uuid = this.props.params
            ? this.props.params.uuid
            : this.props.uuid;

        return (
            <div className="page-appointment">
                {!this.props.inModal && (
                    <div className="top-bar">
                        <a onClick={this.handleBackClick}>{_`${this.backText}`}</a>
                    </div>
                )}

                <AppointmentContainer
                    uuid={uuid}
                    folderId={this.props.folderId}
                    onSubmit={this.handleSubmit}
                />
            </div>
        );
    }
}
