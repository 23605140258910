import { useState, useEffect, createContext, useContext } from 'react';
import { UserFolder } from 'models/UserFolder';
import { StateLinkable } from './StateLinking';
import { SelectionStateContainer } from 'models/AdvancedSearchDefinition';

enum FolderSelectionMode
{
    ALL, SPECIFIED
}

type FolderSelectionCallbackFunction = (newState: boolean) => unknown;
type GlobalStateChangeCallbackFunction = (selectionState: FolderSelectionState) => unknown;

/**
 * External state manager for patient folder selections from advanced search results (or anywhere else we want).
 * Maintains selection across all pages, to handle pagination and not having all the folderIds in memory.
 * Supports Select All with manual exclusions, as well as direct selections.
 */
export class FolderSelectionState implements StateLinkable<boolean, number | string>
{
    private folderCount: number;

    private selectionMode: FolderSelectionMode;
    private selectedIds: Set<number | string>;
    private excludedIds: Set<number | string>;

    private folderListeners: Map<number | string, () => void> = new Map();
    private globalListeners: Set<GlobalStateChangeCallbackFunction> = new Set();

    constructor(folderCount: number)
    {
        this.folderCount = folderCount;
        this.reset();
    }

    public hasSelection = (): boolean =>
    {
        return this.getSelectionCount() > 0;
    }

    public getFolderCount = (): number =>
    {
        return this.folderCount;
    }

    public getSelectionCount = (): number =>
    {
        if (this.selectionMode === FolderSelectionMode.ALL)
        {
            return this.folderCount - this.excludedIds.size;
        }

        return this.selectedIds.size;
    }

    public getState = (folderId: number | string): boolean =>
    {
        if (this.selectionMode === FolderSelectionMode.ALL)
        {
            return !this.excludedIds.has(folderId);
        }

        return this.selectedIds.has(folderId);
    }

    public selectAll = (): void =>
    {
        this.changeMode(FolderSelectionMode.ALL);
    }

    public selectOnlyFolders = (folders: UserFolder[]): void =>
    {
        this.selectOnlyFolderIds(folders.map((patient) => { return patient.id; }));
    }

    public selectOnlyFolderIds = (folderIds: number[] | string[]): void =>
    {
        this.reset();

        folderIds.forEach((folderId) => this.setState(true, folderId));
    }

    public setState = (selected: boolean, folderId: number | string): void =>
    {
        if (this.selectionMode === FolderSelectionMode.ALL)
        {
            if (selected)
            {
                this.excludedIds.delete(folderId);
            }
            else
            {
                this.excludedIds.add(folderId);
            }
        }
        else
        {
            if (selected)
            {
                this.selectedIds.add(folderId);
            }
            else
            {
                this.selectedIds.delete(folderId);
            }
        }

        this.notifyForFolder(folderId);
    }

    public reset = (): void =>
    {
        this.selectionMode = FolderSelectionMode.SPECIFIED;
        this.selectedIds = new Set<number | string>();
        this.excludedIds = new Set<number | string>();

        this.notifyAll();
    }

    private notifyAll = (): void =>
    {
        this.folderListeners.forEach((callbackFn) => (callbackFn()));

        this.globalListeners.forEach((callbackFn) => (callbackFn(this)));
    }

    private notifyForFolder = (folderId: number | string): void =>
    {
        const callbackFn = this.folderListeners.get(folderId);
        if (callbackFn)
        {
            callbackFn();
        }

        this.globalListeners.forEach((callbackFn) => (callbackFn(this)));
    }

    private changeMode = (mode: FolderSelectionMode): void =>
    {
        this.reset();
        this.selectionMode = mode;
        this.notifyAll();
    }

    public registerStateChangeListener = (toRegister: {stateChangeCallback: FolderSelectionCallbackFunction; subStateKey: number | string}): void =>
    {
        const folderId = toRegister.subStateKey;
        this.folderListeners.set(folderId, () => toRegister.stateChangeCallback(this.getState(folderId)));
    }
    public deregisterStateChangeListener = (toDeregister: {subStateKey: number | string}): void =>
    {
        const folderId = toDeregister.subStateKey;
        this.folderListeners.delete(folderId);
    }

    public registerGlobalListener = (callbackFn: GlobalStateChangeCallbackFunction): void =>
    {
        this.globalListeners.add(callbackFn);
    }
    public deregisterGlobalListener = (callbackFn: GlobalStateChangeCallbackFunction): void =>
    {
        this.globalListeners.delete(callbackFn);
    }

    public getSimpleRepresentation = (): FolderSelectionStateRepresentation =>
    {
        return { selectionCount: this.getSelectionCount(), folderCount: this.getFolderCount(), hasSelection: this.hasSelection() };
    }

    public isSelectAll = () => {
        return this.selectionMode == FolderSelectionMode.ALL;
    }

    public getIncludedFolderIds = (): (number | string)[] => {
        return Array.from(this.selectedIds);
    }

    public getExcludedFolderIds = (): (number | string)[] => {
        return Array.from(this.excludedIds);
    }
}

type FolderSelectionStateRepresentation =
{
    selectionCount: number;
    folderCount: number;
    hasSelection: boolean;
}

export function useFolderSelectionState(selectionState: FolderSelectionState): FolderSelectionStateRepresentation
{
    const [newState, onStateChange] = useState<FolderSelectionStateRepresentation>(selectionState.getSimpleRepresentation());

    const listener = (selectionStateSource: FolderSelectionState) =>
    {
        onStateChange(selectionStateSource.getSimpleRepresentation());
    };

    useEffect(() =>
    {
        selectionState.registerGlobalListener(listener);
        listener(selectionState);
        return function cleanup() {selectionState.deregisterGlobalListener(listener);};
    }, [selectionState]);

    return newState;
}

export function resetSelectionState(onResetFn: (newState: FolderSelectionState) => unknown, folderCount: number)
{
    onResetFn(new FolderSelectionState(folderCount));
}

export const SelectionStateContext = createContext<SelectionStateContainer>(null);
export const useSelectionStateContext = () => {
    const selectionState = useContext<SelectionStateContainer>(SelectionStateContext);
    if (!selectionState) {
        throw new Error('This component must be within a SelectionStateContext provider');
    }
    return selectionState;
};
