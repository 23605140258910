import React, { FC } from 'react';
import moment from 'moment';
// components
import AdvancedSearchResultsTableDisplay from 'features/patients/advanced-search/AdvancedSearchResultsTableDisplay';
import Pagination from 'components/pagination';
// hooks
import {
    useFolderSelectionState,
    useSelectionStateContext,
} from 'components/dataSource/FolderSelectionState';
import { useFilterChangeDelay } from './AdvancedSearchResultsDisplay.hooks';
// interfaces
import {
    AdvancedSearchResultsDisplayProps,
    PaginationSummaryProps,
} from './AdvancedSearchResultsDisplay.interface';
// styles
import './AdvancedSearchResultsDisplay.scss';

export const availablePerPageValues: number[] = [10, 25, 50, 100];

const PaginationSummary: FC<PaginationSummaryProps> = (props) => {
    const selectionStateContainer = useSelectionStateContext();
    const folderSelectionStateSummary = useFolderSelectionState(
        selectionStateContainer.selectionState,
    );
    return (
        <p className="pagination-summary">
            Total number of results: {props.patientsTotal}. Selected:{' '}
            {folderSelectionStateSummary.selectionCount}
        </p>
    );
};

const AdvancedSearchResultsDisplay: FC<AdvancedSearchResultsDisplayProps> = (
    props,
) => {
    const searchDefinition = props.searchDefinition;
    const searchResultsManager = props.searchResultsManager;

    const [filterStateDisplayed, handleDelayedFilterStateUpdate] =
        useFilterChangeDelay(
            searchResultsManager.filterState,
            searchResultsManager.setFilter,
        );

    return (
        <>
            {searchDefinition.static && (
                <div className="alert alert-info alert-static-query">
                    <span>
                        This is saved data: a filter of patients and their data
                        saved on{' '}
                        {moment(searchResultsManager.updatedAt).format(
                            'DD MMM YYYY HH:mm:ss',
                        )}
                        . Clicking into these results may result in different
                        current data being returned.
                    </span>
                </div>
            )}
            <AdvancedSearchResultsTableDisplay
                documentSearchConfigs={props.documentSearchConfigs}
                searchQueryDefinition={searchDefinition.queryDefinition}
                results={searchResultsManager.queryResults}
                onSort={searchResultsManager.setSortState}
                onFilter={handleDelayedFilterStateUpdate}
                sortOrder={searchResultsManager.sortState}
                filterState={filterStateDisplayed}
            />
            {searchResultsManager.queryResults.length > 0 && (
                <div className="advanced-search-pagination">
                    <div className="advanced-search-pagination__settings">
                        <Pagination
                            pageCount={Math.ceil(
                                searchResultsManager.patientsTotal /
                                    searchResultsManager.perPage,
                            )}
                            currentPage={searchResultsManager.currentPage}
                            onChange={searchResultsManager.setCurrentPage}
                        />
                        <div className="push" />
                        <label>Per page: </label>
                        <select
                            className="dropdown-options"
                            onChange={(event) =>
                                searchResultsManager.setPerPage(
                                    parseInt(event.target.value),
                                )
                            }
                            value={searchResultsManager.perPage}
                        >
                            {availablePerPageValues.map(
                                (perPageCount: number, index: number) => {
                                    return (
                                        <option
                                            key={index}
                                            value={perPageCount}
                                        >
                                            {perPageCount}
                                        </option>
                                    );
                                },
                            )}
                        </select>
                    </div>
                    <PaginationSummary
                        patientsTotal={searchResultsManager.patientsTotal}
                    />
                </div>
            )}
        </>
    );
};

export default AdvancedSearchResultsDisplay;
