import React, { FC } from 'react';
import _ from 'services/i18n';
import './ComponentLoadingWrapper.scss';
export const ComponentLoadingWrapper: FC<{loading: boolean; dataExists: boolean; height?: number}> = (props) => {

    if(!props.loading && props.dataExists) {
        return (
            <>{props.children}</>
        );
    }

    if(!props.loading && !props.dataExists) {
        return (
            <div className={'dummy-no-data'} style={{ height: `${props.height}px` }}>
                <div className={'dummy-no-data_label'}>
                    {_`No data`}
                </div>
            </div>
        );
    }

    return (
        <div className={'dummy-no-data'} style={{ height: `${props.height}px` }}>
            <div className={'dummy-no-data_label'}>
                <i className="fas fa-spinner fa-spin" />  &nbsp;
                {_`Loading...`}
            </div>
        </div>
    );
};
ComponentLoadingWrapper.defaultProps = { height: 240 };
