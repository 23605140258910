import { isValidNhsNumber } from 'services/nhs-number-validator';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { PRIMARY_IDENTIFIER_NAMESPACE } from 'models/PersonPrimaryIdentifier';
import { removeSpacesFromString } from 'services/utils/common';

export function useFileUploadValidator(documentType: string, path: string) {
    const teamPreferences = useTeamPreferences();

    if (
        documentType == 'demographics' &&
        path == 'folder.person_primary_identifier.value'
    ) {
        return (ppi: string) => isPPIValid(ppi, teamPreferences);
    }

    throw new Error(
        `Document type ${documentType} and path ${path} have no validation function`,
    );
}

function isPPIValid(ppi: string, teamPreferences): boolean {
    const isNHS =
        teamPreferences?.primary_identifier?.namespace ===
        PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER;
    if (!isNHS) {
        return true;
    }
    return isValidNhsNumber(ppi);
}

export function useFileUploadNormaliser(documentType: string, path: string) {
    if (
        documentType == 'demographics' &&
        path == 'folder.person_primary_identifier.value'
    ) {
        return removeSpacesFromString;
    }

    throw new Error(
        `Document type ${documentType} and path ${path} have no normalisation function`,
    );
}
