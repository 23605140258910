import _ from 'services/i18n';
import { TEAM_ROLES_GROUPS, useTeamRoles } from 'common/useTeamRoles';
import React, { FC, useState } from 'react';
import UserInviteModal, { InviteFormBaseProps } from 'components/user/invite/modal';
import { Modal } from 'common/ui/modal';
import { TeamMember } from 'services/clinicians.service';
import { RoleDefinition } from 'services/roles.service';

type DeleteUserModalProps = {
    onClose: () => void;
    onSubmitDeleteUser: () => void;
    entityType: string;
    busy: boolean;
    name: string;
    email: string;
    roles: string;
    status: string;
}

export const DeleteUserModal: FC<DeleteUserModalProps> = ({ onClose, entityType, onSubmitDeleteUser, busy, name, email, roles, status }) => {

    const buttons = [
        { label: 'Confirm Delete', style: 'danger', callback: onSubmitDeleteUser },
        { label: 'Close', callback: onClose }
    ];

    return (
        <Modal
            title={_`Confirm User Deletion`}
            onClose={onClose}
            buttons={buttons}
            busy={busy}>
            <div className="row">
                <div className="col-md-12">
                    <p>Are you sure you wish to remove this user from the {entityType}?</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <p>{_`Name`}</p>
                </div>
                <div className="col-md-8">
                    <p>{name}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <p>{_`Email`}</p>
                </div>
                <div className="col-md-8">
                    <p>{email}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <p>{_`Roles`}</p>
                </div>
                <div className="col-md-8">
                    <p>{roles}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <p>{_`Status`}</p>
                </div>
                <div className="col-md-8">
                    <p>{status}</p>
                </div>
            </div>
        </Modal>
    );
};

type ChangeTypeModalProps = {
    user: TeamMember;
    onClose: () => void;
    onSubmitUserType: (roles: RoleDefinition[], user: TeamMember) => void;
    busy: boolean;
    error?: string;
}

export const ChangeTypeModal: FC<ChangeTypeModalProps> = ({ user, onClose, onSubmitUserType, busy, error }) => {
    const [, teamRolesGroups] = useTeamRoles();
    const clinicianRoleList = teamRolesGroups.get(TEAM_ROLES_GROUPS.CLINICIAN) || [];

    const [selectedRoles, setSelectedRoles] = useState<Set<string>>(() => {
        return new Set(user.user_roles || []);
    });

    const handleChange = (checked: boolean, role: RoleDefinition) => {
        if (checked) {
            setSelectedRoles(new Set([...Array.from(selectedRoles), role.name]));
        } else {
            selectedRoles.delete(role.name);
            setSelectedRoles(new Set([...Array.from(selectedRoles)]));
        }
    };

    const buttons = [
        {
            label: 'Change Type',
            callback: () => {
                const selected = clinicianRoleList.filter((role) => Array.from(selectedRoles).includes(role.name));
                onSubmitUserType(selected, user);
            }
        },
        { label: 'Close', callback: onClose }
    ];

    return (
        <Modal
            title={_`Change User Type`}
            size="sm"
            onClose={onClose}
            buttons={buttons}
            busy={busy}
        >
            { error &&
                <span className={'error'}>{error}</span>
            }
            <form>
                <h5>
                    <b>Available roles</b>
                    <span style={{ color: 'red' }}>*</span>
                </h5>
                {clinicianRoleList.map((role, i) => {
                    return (
                        <div className="checkbox" key={role.uuid}>
                            <label htmlFor={`id${i}`}>
                                <input
                                    type={'checkbox'}
                                    checked={selectedRoles.has(role.name)}
                                    onChange={(e) => handleChange(e.target.checked, role)}
                                    id={`id${i}`}
                                />
                                {role.name}
                            </label>
                        </div>
                    );
                })}
            </form>
        </Modal>
    );
};

export enum ModalType  {
    userInvite ='userInvite',
    changeType = 'changeType',
    deleteUser = 'deleteUser',
}

export type ModalUserInviteProps = {
    name: ModalType.userInvite;
    params: InviteFormBaseProps;
}

export type ModalChangeTypeProps = {
    name: ModalType.changeType;
    params: ChangeTypeModalProps;
}

export type ModalDeleteUserProps = {
    name: ModalType.deleteUser;
    params: DeleteUserModalProps;
}

export type ShowModalParams =
    | ModalUserInviteProps
    | ModalChangeTypeProps
    | ModalDeleteUserProps;

type ShowModalProps = {
    modal: ShowModalParams;
}

const memberListModals: Record<ModalType, any> = {
    userInvite: UserInviteModal,
    changeType: ChangeTypeModal,
    deleteUser: DeleteUserModal,
};

export function ShowModal({ modal }: ShowModalProps): JSX.Element {
    const SelectedModal = memberListModals[modal.name];

    return SelectedModal ? <SelectedModal {...modal.params} /> : null;
}
