import React, { FC, useState } from 'react';
// components
import { ComponentLoadingWrapper } from 'features/patient/patient-dashboard/component-loading-wrapper/ComponentLoadingWrapper';
import PIFUFormOption from './PIFUFormOption';
// hooks
import { usePIFU, usePIFUOptionList } from 'features/patient/pifu/pifu.hooks';
// services
import { terService } from 'services/ter.service';
import { portalToast } from 'ui/toast/Toast';
// styles
import './PIFUFormContainer.style.scss';

type PIFUFormContainerProps = {
    onAllocationSuccess?: () => void;
    folderId: number;
};

const PIFUFormContainer: FC<PIFUFormContainerProps> = ({
    onAllocationSuccess,
    folderId
}) => {
    const [selectedOptionId, setSelectedOptionId] = useState<string | null>(
        null,
    );
    const [isSending, setIsSending] = useState(false);

    const { isLoading, list } = usePIFUOptionList();
    const { displayName } = usePIFU();

    const handleOptionToggle = (id: string) => {
        setSelectedOptionId(selectedOptionId === id ? null : id);
    };

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        setIsSending(true);
        try {
            const selectedOption = list.find(
                (option) => option.id === selectedOptionId,
            );

            if (!selectedOptionId) {
                portalToast.error({
                    title: 'Error!',
                    content: 'Please select an option!',
                });
                return;
            }

            await terService.createTer({
                action: 'pifu',
                folderId,
                data: {
                    name: selectedOption.live.label_name,
                    context: selectedOption.live.label_context,
                },
            });

            setIsSending(false);

            portalToast.success({
                title: 'Successfully created.',
                content: 'Your results will be available shortly.',
            });

            if (typeof onAllocationSuccess === 'function') {
                onAllocationSuccess();
            }
        } catch (err) {
            console.error(err);
            portalToast.error({
                title: 'Error.',
                content:
                    'Something went wrong when we tried to handle your request.',
            });
            setIsSending(false);
        }
    };

    return (
        <div className="pifu-form-container">
            <ComponentLoadingWrapper
                loading={isLoading}
                dataExists={list.length !== 0}
            >
                <form onSubmit={handleSubmit}>
                    <div className="pifu-form__option-list">
                        {list.map((option, index) => {
                            return (
                                <div
                                    key={option.id}
                                    className="pifu-form__option-wrapper"
                                >
                                    <PIFUFormOption
                                        id={option.id}
                                        title={option.live?.name}
                                        description={option.live?.description}
                                        onToggle={handleOptionToggle}
                                        selected={
                                            selectedOptionId === option.id
                                        }
                                        underline={index !== list.length - 1}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="pifu-form__bottom">
                        <button
                            type="submit"
                            className="pifu-form__button"
                            disabled={!selectedOptionId || isSending}
                        >
                            {`Allocate ${displayName}`}
                        </button>
                    </div>
                </form>
            </ComponentLoadingWrapper>
        </div>
    );
};

export default PIFUFormContainer;
