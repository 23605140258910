import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';
import { browserHistory } from 'react-router';
import { authenticate } from 'actions/auth.actions';
import { connect } from 'react-redux';

class LoginPage extends BaseComponent {
    /**
     * @param {Object} props - Passed in properties
     */
    constructor (props) {
        super(props);

        let location = this.props.location || {}, query = location.query || {};

        this.state = {
            email: query.email || '',
            token: query.token || '',
            status: query.status || false,
            password: '',
            errors: []
        };

        this.bindEvents([
            'Change',
            'Submit'
        ]);

        this.View = View;
    }

    handleChange (e) {
        switch(e.target.name) {
            case 'email':
                this.setState({
                    email: e.target.value
                });
                break;
            case 'password':
                this.setState({
                    password: e.target.value
                });
                break;
        }
    }

    handleSubmit (e) {
        e.preventDefault();

        const {
            email,
            password
        } = this.state;

        const {
            portal
        } = this.props;

        this.setState({loading: true});
        this.props.dispatch(authenticate({
            email,
            password,
            portal
        }))
            .then((successful) => {

                if (!successful){
                    this.setState({
                        loading: false,
                        errors: [
                            'Sorry, but the username or password you have entered was not accepted. You may need to confirm your account\'s registration before you can access the portal.'
                        ]
                    });
                    return;
                }

                if (this.props.hasOwnProperty('onLoginEvent')) {
                    if (typeof(this.props.onLoginEvent) == 'function') {
                        this.props.onLoginEvent();
                    }
                }

                this.setState({
                    loading:false
                });

                if (this.state.token == '') {
                    browserHistory.push('/' + this.props.portal);
                } else {
                    browserHistory.push('/' + this.props.portal + '/invite/accept/' + this.state.token);
                }
            });
    };
}

function mapDispatchToProps(dispatch){
    return {
        dispatch
    };
}

export default connect(undefined,mapDispatchToProps)(LoginPage);