import React from 'react';
import BasePage from 'components/page/base';

import QuestionnaireTemplatesList from 'components/questionnaire-template/list';

/**
 * Extended Page Component for questionnaire templates
 **/
export default class QuestionnaireTemplatesListPage extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return 'Listing Questionnaire Templates | PHR Admin Portal';
    }

    render () {
        return (<QuestionnaireTemplatesList {...this.props} />);
    }
}
