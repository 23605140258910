
import { Composition } from 'models/Composition';
import * as TER from 'models/TriggerEngineRequestContent';
import { ResourceType } from 'models/compositions/Resource';
import { CoopResourceCategory } from 'models/compositions/CoopResource';

export type CreateResourceState = {
    type: ResourceType | '';
    label: string;
    url: string;
    phone: string;
    html: string;
    description: string;
    mediaFile: null | File;
    mediaFileUuid: string;
    category: CoopResourceCategory[];
    sendPush: boolean;
    notifyTimeline: boolean;
    lozengeTitle: string;
    lozengeText: string;
    tags? : string[];
}

export function validate(state: CreateResourceState, onErrors: (errors: string[]) => void): boolean {
    const normalisedState = normalise(state);

    const errors = [];

    if (normalisedState.label === '') {errors.push('Label is required');}

    if (normalisedState.type === 'url') {
        if (!normalisedState.url) {
            errors.push('A URL is required');
        } else {
            try {
                new URL(normalisedState.url);
            } catch {
                errors.push('The provided URL is not valid');
            }
        }
    }    

    if (normalisedState.type === 'phone' && !normalisedState.phone) {
        errors.push('Either a phone number is required');
    }

    if (normalisedState.type === 'html' && !normalisedState.html) {
        errors.push('Either HTML content is required');
    }

    if (normalisedState.type === 'media_resource' && !normalisedState.mediaFileUuid && !normalisedState.mediaFile) {
        errors.push('Select file is required');
    }

    if (normalisedState.type === '') {errors.push('A type is required');}

    onErrors(errors);
    
    return errors.length == 0;
}

export function normalise(state: CreateResourceState): CreateResourceState {
    return {
        type: state.type.trim() as ResourceType,
        label: state.label.trim(),
        url: state.url,
        phone: state.phone,
        html: state.html,
        description: state.description,
        mediaFile: state.mediaFile,
        mediaFileUuid: state.mediaFileUuid,
        category: state.category,
        sendPush: state.sendPush,
        notifyTimeline: state.notifyTimeline,
        lozengeTitle: state.lozengeTitle,
        lozengeText: state.lozengeText
    };
}


export const build = (resource: Composition<any>, category: any): Promise<CreateResourceState> => {
    const hasHtmlContent: boolean = resource.content.html_content;

    return Promise.resolve({
        type: resource.content.resource_type,
        label: resource.content.label,
        url: resource.content.url,
        phone: resource.content.phone,
        html: hasHtmlContent ? resource.content.html_content : '',
        description: resource.content.description,
        mediaFile: null,
        mediaFileUuid: resource.content.media_resource_uuid,
        category: category,
        categoryId: category.uuid,
        sendPush: false,
        notifyTimeline: false,
        lozengeTitle: '',
        lozengeText: ''
    });
};

export const getTriggerEngineContent = (state: CreateResourceState): TER.AllocateResourceWithMessage => {
    return {
        title: state.lozengeTitle || 'Information to help you',
        content: state.lozengeText || 'Your clinician has suggested some information that may be helpful to you. Tap here to read it',
        sender_name: 'clinician',
        type: 'clinician',
        resources: [{
            type: state.type,
            description: state.description,
            title: state.label,
            url: state.url, 
            phone: state.phone,
            media_resource_uuid: state.mediaFileUuid,
            html_content: state.html
        }]
    };
};
