import React from 'react';

import BasePage from 'components/page/base';
import _ from 'services/i18n';

import lodash from 'lodash';
import classNames from 'classnames';
import PaginationView from 'components/pagination';
import { convertToDate } from 'common/datetime/convertToDate';
import { defaultDateFormat } from 'common/datetime/DateTime';
import { questionnaireService } from 'services/questionnaire.service';
import { DataStore } from 'services/data-store';
import { DateTime } from 'common/datetime/DateTime';
import { TopBar } from 'features/patients/questionnaire/questionnaire-view/TopBar';

const tag = '[CompareQuestionnairesPage]';

export default class CompareQuestionnairesPage extends BasePage {
    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                current: 1
            },
            perPage: 20,
            count: 0,
            isLoading: true,
            hasError: false,
            questionnaires: [],
            uiQuestionnaires: [],
            questionnaireName: '',
            order: {
                by: 'completedOn',
                dir: 'desc'
            },
            isCoopQuestionnaire: typeof this.props.location.query.coopQuestionnaire !== 'undefined'
        };


        this.onApplySort = this.onApplySort.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onPrint = this.onPrint.bind(this);

        this.backLink = `/clinical_portal/folder/${this.props.params.folderId}/patient/questionnaires`;
    }

    pageTitle() {
        return _`Compare ${this.$p()} Questionnaires | MyPathway Clinical Portal`;
    }

    onChangePage(idx) {
        this.setState({
            pagination: {
                current: idx
            },
            loading: false,
            uiQuestionnaires: this.state.questionnaires.slice((idx - 1) * this.state.perPage, idx * this.state.perPage)
        });
    }

    onPrint() {
        window.print();
    }

    onApplySort(by) {
        this.setState((state) => {
            const newState = {
                order: {
                    by
                }
            };
            if (state.order.by === by) {
                newState.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
            } else {
                newState.order.dir = 'desc';
            }
            newState.questionnaires = this.sortList(state.questionnaires, newState.order);
            newState.count = newState.questionnaires.length;
            return newState;
        }, () => {
            this.onChangePage(this.state.pagination.current);
        });
    }


    sortList(list = [], order) {

        order = order || this.state.order;

        if (!order) {
            return list;
        }

        let ascComparator;

        switch (order.by) {
        case 'scoreName':
            ascComparator = (a, b) => {
                const nameA = lodash.upperCase(a.scoreName);
                const nameB = lodash.upperCase(b.scoreName);
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            };
            break;
        case 'scoreValue':
            ascComparator = (a, b) => {
                if (typeof a.scoreValue === 'number' && typeof b.scoreValue === 'number'){
                    return a.scoreValue - b.scoreValue;
                }
                const nameA = lodash.upperCase(a.scoreValue);
                const nameB = lodash.upperCase(b.scoreValue);
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            };
            break;
        case 'sequence':
            ascComparator = (a, b) => {
                return a.orderOfCompletion - b.orderOfCompletion;
            };
            break;
        case 'completedOn':
            ascComparator = (a, b) => {
                const aTime = convertToDate(a.questionnaireResponse.document.updated_at) ? convertToDate(a.questionnaireResponse.document.updated_at).unix() : 0;
                const bTime = convertToDate(b.questionnaireResponse.document.updated_at) ? convertToDate(b.questionnaireResponse.document.updated_at).unix() : 0;
                return aTime - bTime;
            };
            break;
        default:
            break;
        }

        let comparator = ascComparator;

        if (!comparator) {
            return list;
        }

        if (order.dir === 'desc') {
            comparator = (a, b) => {
                return -1 * ascComparator(a, b);
            };
        }

        return list.sort(comparator);
    }


    componentDidMount() {

        this.setState({
            isLoading: true
        });

        const {
            questionnaire_uuid: questionnaireUuid,
        } = this.props.location.query;

        Promise.all([
            this.getQuestionnaireAndPrepareMap(questionnaireUuid),
            this.getResponses(questionnaireUuid),
            this.getScores(questionnaireUuid)
        ])
            .then(([questionnaireMap, questionnaireResponses, questionnaireScoresMap]) => {

                if(!questionnaireMap || !questionnaireResponses || !questionnaireScoresMap) {
                    console.error(tag, 'Something went wrong during loading questionnaire data',
                        { questionnaireMap, questionnaireResponses, questionnaireScoresMap, questionnaireUuid });
                    return Promise.reject('Unable to load the data');
                }

                const list = this.createQuestionnairesList(questionnaireMap, questionnaireResponses, questionnaireScoresMap);
                const sortedList = this.sortList(list);

                this.setState({
                    questionnaires: sortedList,
                    uiQuestionnaires: sortedList.slice(0, this.state.perPage),
                    count: sortedList.length,
                    isLoading: false,
                    hasError: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    isLoading: false,
                    hasError: true
                });
            });
    }

    async getScores(questionnaireUuid) {
        const role = DataStore.get('me.currentRole');

        const questionnaireScores = await questionnaireService.search({
            archetypeName: 'questionnaireScores',
            search: {
                'content.questionnaire_uuid': questionnaireUuid
            },
            role
        });

        const scoresMap = new Map();

        questionnaireScores.message.results.forEach((score) => {
            scoresMap.set(score.content.questionnaire_response_uuid, score.content.scores.map((score) => {
                return {
                    scoreName: score.score_name,
                    scoreValue: extractScoreValue(score)
                };
            }));
        });

        return scoresMap;
    }

    async getResponses(questionnaireUuid) {
        const role = DataStore.get('me.currentRole');

        const questionnaireResponses = await questionnaireService.search({
            archetypeName: 'questionnaireResponse',
            search: {
                'content.questionnaire_uuid': questionnaireUuid,
                'content.status': 'scored',
            },
            role
        });

        const documents = lodash.map(questionnaireResponses.message.results, (questionnaire) => {

            const newItem = Object.assign({}, questionnaire, {
                'created_at': convertToDate(questionnaire.created_at).format(defaultDateFormat)
            });

            return {
                type: this.state.isCoopQuestionnaire ? 'coopQuestionnaireResponse' : 'questionnaireResponse',
                document: newItem
            };
        });

        return {
            documents,
            count: documents.length
        };
    }

    async getNonDeletedQuestionnaire(uuid) {
        let questionnaire;
        try {
            questionnaire = await questionnaireService.getQuestionnaire({
                archetype: 'questionnaire',
                uuid: uuid
            });
        } catch (error) {
            console.error(tag, error, 'Unable to find any questionnaires with uuid', uuid);
        }

        if(!questionnaire) {
            console.error(tag, 'Unable to find any questionnaires with uuid', uuid);
            return;
        }
        return questionnaire;
    }

    getQuestionnaireAndPrepareMap(uuid) {
        return this.getNonDeletedQuestionnaire(uuid, this.state.isCoopQuestionnaire)
            .then((questionnaire) => {

                if (!questionnaire) {
                    return;
                }

                const questionnairesMap = new Map();

                const data = {
                    type: this.state.isCoopQuestionnaire ? 'coopQuestionnaire' : 'questionnaire',
                    document: {
                        version: questionnaire.content.version,
                        name: questionnaire.content.name
                    }
                };
                questionnairesMap.set(questionnaire.uuid, data);
                //fallback for legacy questionnaires (uuid should be used for grouping)
                questionnairesMap.set(questionnaire.content.questionnaire_uuid, Object.assign({}, data, { isLegacy: true }));

                this.setState({
                    questionnaireName: lodash.get(questionnaire, 'content.name', '')
                });

                return questionnairesMap;
            });
    }

    createQuestionnairesList(questionnairesMap, questionnaireResponses, questionnaireScoresMap) {

        const sortedQuestionnaires = questionnaireResponses.documents.sort((a, b) => {
            const aTime = convertToDate(a.document.updated_at) ? convertToDate(a.document.updated_at).unix() : 0;
            const bTime = convertToDate(b.document.updated_at) ? convertToDate(b.document.updated_at).unix() : 0;
            return aTime - bTime;
        });


        const { list } = sortedQuestionnaires.reduce((acc, questionnaireResponse, index) => {

            const {
                document
            } = questionnaireResponse;

            const questionnaire = questionnairesMap.get(document.content.questionnaire_uuid);

            if (!questionnaire) {
                return acc;
            }

            let scores;
            if (this.state.isCoopQuestionnaire){
                /*
                    Dirty hack for old archetype - coopQuestionnaire (EQ5D)
                    https://adi-health.atlassian.net/browse/MPA-2063
                    Extract value not from scoring but from composition response composition itself
                    EQ5D has 6 answers
                */
                // eslint-disable-next-line @typescript-eslint/camelcase
                const vasScore = lodash.get(document,'content.details.answers',[]).find(({ question_id }) => {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    return question_id === '6';
                });
                if (vasScore){
                    scores = [{
                        scoreValue: parseInt(vasScore.value,10),
                        scoreName: 'VAS'
                    }];
                }
            }else{
                scores = questionnaireScoresMap.get(document.uuid);
            }

            if (scores){
                scores.forEach(({ scoreValue,scoreName }) => {
                    acc.list.push({
                        questionnaireResponse,
                        orderOfCompletion: index,
                        index: acc.questionnaireListIndex++,
                        scoreValue,
                        scoreName
                    });
                });
            }

            return acc;
        }, { list:[],questionnaireListIndex:0 });

        return list;
    }


    render() {

        const {
            isLoading,
            uiQuestionnaires,
            questionnaireName,
            order,
            pagination,
            count,
            perPage,
            hasError
        } = this.state;

        if (isLoading) {
            return (
                <div>
                loading data...
                </div>
            );
        }

        if (hasError) {
            return (
                <div>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                Couldn't load data
                </div>
            );
        }

        if (!uiQuestionnaires || !uiQuestionnaires.length) {
            return (
                <div>
                No questionnaires to compare to
                </div>
            );
        }

        return (
            <div className='compare-questionnaires-page'>
                <TopBar showAllocateQuestionnaire={false} backToQuestionnairesListLink={this.backLink}/>
                {
                    <h2>Questionnaire name: <br/> {questionnaireName}</h2>
                }
                <button className="btn btn-primary" onClick={this.onPrint}>Print sheet</button>
                <CompareQuestionnaireTable onApplySort={this.onApplySort}
                    questionnaires={uiQuestionnaires}
                    order={order}/>
                <PaginationView currentPage={pagination.current}
                    pageCount={Math.ceil(count / perPage)}
                    onChange={this.onChangePage}/>
            </div>
        );
    }
}

function CompareQuestionnaireTable(props) {

    const {
        onApplySort,
        questionnaires,
        order
    } = props;

    const headers = [
        {
            text: 'Date',
            callback: () => {
                onApplySort('completedOn');
            },
            sortField: 'completedOn'
        },
        {
            text: 'Care Event'
        },
        {
            text: 'Sequence',
            callback: () => {
                onApplySort('sequence');
            }
        },
        {
            text: 'PROM Score',
            callback: () => {
                onApplySort('scoreName');
            },
            sortField: 'scoreName'
        },
        {
            text: 'Score/Value',
            callback: () => {
                onApplySort('scoreValue');
            },
            sortField: 'scoreValue'
        }
    ];

    const rows = questionnaires.map((questionnaire) => {

        const {
            questionnaireResponse
        } = questionnaire;
        const dateCompletedAt = questionnaireResponse.document.content.status_changes.completed_at;
        const completedOn = dateCompletedAt ?
            (
                <DateTime>
                    {dateCompletedAt}
                </DateTime>
            ) : console.error('Error in status_changes');
        const careEvent = '';
        const sequence = questionnaire.orderOfCompletion;
        const promScore = questionnaire.scoreName;
        const scoreValue = typeof questionnaire.scoreValue !== 'undefined' ? questionnaire.scoreValue.toString() : undefined;

        return (
            <tr key={questionnaire.index}>
                <th>{completedOn}</th>
                <th>{careEvent}</th>
                <th>{sequence}</th>
                <th>{promScore}</th>
                <th>{scoreValue}</th>
            </tr>
        );
    });

    return (
        <table className="table table-bordered table-condensed table-striped table-responsive table-nomargin">
            <thead>
                <tr>
                    {
                        headers.map(({ text, callback, sortField, width }, index) => {

                            const isSelectedField = props.order.by === sortField;

                            const className = classNames({
                                'table__header': true,
                                'table__header--selectable': callback != null,
                                'table__header--selected': isSelectedField,
                                'table__header--sort-asc': isSelectedField && order.dir === 'asc',
                                'table__header--sort-desc': isSelectedField && order.dir === 'desc'
                            });

                            return (
                                <th onClick={callback} key={index} width={width || 'auto'} className={className}>
                                    {text}
                                </th>
                            );
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
}

function extractScoreValue(score) {
    if (score.score_value_int != null) {
        return parseInt(score.score_value_int,10);
    }
    if (score.score_value_float != null) {
        return parseFloat(score.score_value_float);
    }
    if (score.score_value_string != null) {
        return score.score_value_string;
    }
    if (score.score_value_boolean != null) {
        return Boolean(score.score_value_boolean);
    }
    return undefined;
}
