import React from 'react';
import _ from 'services/i18n';
import {Panel, PanelFooter, Column} from 'ui';
import ImageModal from 'components/image';
import BackgroundImageChecker from 'components/background-image-checker';

export default class PhotographFormComponent extends React.Component {
    
    render () {

        let alertText, alertType;
        
        if (this.props.saving) {
            alertType = 'info';
            alertText = _`Saving. Please wait...`;
        } else if (this.props.saveStatus === false) {
            alertType = 'danger';
            alertText = _`An error occurred while uploading your photo. Please try again.`;
        } else if (this.props.saveStatus === true) {
            alertType = 'success';
            alertText = _`Successfully uploaded your photo.`;
        }
        
        return (
            <Column md='6'>
                <div className='intro-text'>
                    {_`This page shows your current photograph on the i-Focus system, if you have one. Click the button below to upload a new one.`}
                </div>
                <Panel
                    title={_`Photograph`}
                    busy={this.props.saving}
                    alertText={alertText}
                    alertType={alertType} >
                    
                    {this.renderPhoto()}
                    
                    <PanelFooter>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={this.props.onClickChangePhoto} >
                            {_`Change Photo`}
                        </button>
                    </PanelFooter>
                    
                </Panel>
                {this.renderPhotoModal()}
            </Column>
        );
    }
    
    renderPhotoModal () {
        if (this.props.showPhotoModal) {
            return (
                <ImageModal
                    width="200"
                    height="200"
                    onClose={this.props.onCloseChangeModal}
                    onUpload={this.props.onUploadChangeModal} />
            )
        }
    }
    renderPhoto () {

        const userPhotoPath = `url(${this.props.userPhotoPath}?random=${Date.now()})`;

        return (
            <div className='user-photo-frame'>
                <BackgroundImageChecker imageUrl={userPhotoPath}>
                    <div className='current-user-photo'></div>
                </BackgroundImageChecker>
            </div>
        );
    }
}