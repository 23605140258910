import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import LoginComponent from 'components/login';
import { connect } from 'react-redux';
import { logout } from 'actions/auth.actions';

/**
 * Extended Page Component for user_portal/login
 **/

class AdminLogout extends BasePage {
    constructor(props) {
        super(props);

        this.state = {
            message: _`Clearing session...`
        };
    }

    pageTitle() {
        return _`Admin Portal Login | iFocus Healthcare`;
    }

    componentDidMount() {
        this.props.dispatch(logout())
            .then(() => {
                this.setState({
                    message: _`You have been logged out of the Admin Portal`
                });
            });
    }

    render() {
        return (<LoginComponent title={_`Admin Portal Login`} message={this.state.message}
                                portal="admin_portal" {...this.props} />)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(undefined, mapDispatchToProps)(AdminLogout);
