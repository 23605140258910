import { SortOrder } from 'common/ui/grid';
import { useEffect } from 'react';
import { resourcesService } from 'services/resources.service';
import { ResourcesSearchCriteria } from '../SearchResourcesComponent/SearchResourcesComponent';

export interface ResourcesSearchQueryResults {
    resultsPageData: any[];
    totalResultCount: number;
}

export type ResourcesSearchQueryResultsWrapper = {
    loading: boolean;
    error: boolean;
    results: ResourcesSearchQueryResults;
};

export function useResourcesSearchResults(
    searchCriteria: ResourcesSearchCriteria,
    perPage: number,
    currentPage: number,
    sortState: Record<string, SortOrder>,
    setQueryResults: (resourceResultsWrapper: ResourcesSearchQueryResultsWrapper) => void,
    refreshRequestedAt: Date,
) {

    useEffect(() => {
        getPaginatedData(searchCriteria, currentPage, perPage, sortState).then((paginatedDataResponse) => {
            setQueryResults({
                error: false,
                loading: false,
                results: paginatedDataResponse
            });
        }).catch((e) => {
            setQueryResults({
                error: true,
                loading: false,
                results: null
            });
        });
    }, [setQueryResults, refreshRequestedAt, searchCriteria, currentPage, perPage, sortState]);
}

async function getPaginatedData(searchCriteria, currentPage, perPage, sortState): Promise<ResourcesSearchQueryResults> {

    const offset = (currentPage - 1) * perPage;

    const sortParameters = Object.keys(sortState).flatMap((s: string) => {
        return {
            ascending: sortState[s] === 1,
            field: s
        };
    });

    const searchQuery = buildSearchQuery(searchCriteria);

    try {
        const response = await resourcesService.search({ limit: perPage, offset: offset, sort: sortParameters, search: searchQuery, folderId: 'team' });

        return Promise.resolve({
            totalResultCount: response.message.total,
            resultsPageData: response.message.results
        });
    } catch (e) {
        return Promise.resolve({
            totalResultCount: 0,
            resultsPageData: null
        });
    }
}

function buildSearchQuery(searchCriteria: ResourcesSearchCriteria) {

    let searchQuery;

    if (searchCriteria.keyword === null && searchCriteria.department === null
        && searchCriteria.collection === null && searchCriteria.resourceType === null) {
        searchQuery = {
            'uuid': {
                '$exists': true
            }
        };
    } else {
        if (searchCriteria.keyword) {
            searchQuery = {
                ...searchQuery,
                'content.label': {
                    $regex: searchCriteria.keyword,
                    $options: 'i'
                }
            };
        }
        if (searchCriteria.department && searchCriteria.collection == null) {
            searchQuery = {
                ...searchQuery,
                'content.labels.context': searchCriteria.department
            };
        }
        if (searchCriteria.collection) {
            searchQuery = {
                ...searchQuery,
                'content.category.category_id': searchCriteria.collection.value
            };
        }
        if (searchCriteria.resourceType) {
            searchQuery = {
                ...searchQuery,
                'content.resource_type': searchCriteria.resourceType.value
            };
        }
    }

    return searchQuery;
}
