import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { useNonDischargedReferrals } from '../patient/referrals/useNonDischargedReferrals';

export function useActivePathways({ folderId, teamId }) {
    const [referrals = []] = useNonDischargedReferrals({ folderId, teamId });
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        let newLabels = referrals.reduce((acc, ref) => {
            const labels = ref.content.labels && ref.content.labels.filter(({ status, type }) => {
                return status && type === 'pathway';
            }) || [];
            return acc.concat(labels);
        }, []);

        newLabels = lodash.uniqWith(newLabels, (a, b) => {
            return a.name === b.name &&
                a.type === b.type &&
                a.context === b.context &&
                a.status === b.status;
        });

        setLabels((oldLabels) => {
            if (!lodash.isEqual(newLabels, oldLabels)) {
                return newLabels;
            }

            return oldLabels;
        });

    }, [referrals]);

    return labels;
}
