import React from 'react';
import _ from 'services/i18n';

export default class extends React.Component {
    render () {
        return (
            <div className="add-file-modal">
                <div className="modal show" tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {this.renderHeader()}
                            {this.renderBody()}
                            {this.renderFooter()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderHeader () {
        return (
            <div className="modal-header">
                <button type="button" className="close" aria-label="Close" onClick={this.props.onClose}><span
                    aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">{_`Add File`}</h4>
            </div>
        )
    }

    renderBody () {
        let message;

        if (true == this.props.invalidFile) {
            if (this.props.error != '') {
                message = this.props.error;
            } else {
                message = _`Click here or drag file here`;
            }
        }

        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="file-box">
                            <div className="contents">
                                <div className='empty-file'>
                                    <div className={this.props.error ? 'text-danger' : ''}>{message}</div>

                                    <em>{this.props.filename}</em>
                                </div>
                            </div>
                            <input type="file" className="json-file" name="json-file"
                                   onChange={this.props.onChangeFile}/>
                        </div>
                    </div>
                </div>

                {this.props.errorList.length > 0 ?
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <h5>{_`JSON Schema Errors`}:</h5>

                            <div className="table-responsive">
                                <table
                                    className='table table-bordered table-condensed table-striped table-nomargin json-schema-errors'>
                                    <tbody>
                                    {this.renderErrorList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }

    renderErrorList () {
        let errorList = this.props.errorList;

        return errorList.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>
                        <small>{item}</small>
                    </td>
                </tr>
            );
        });
    }

    renderFooter () {
        let uploadBtn;

        if (true == this.props.invalidFile) {
            uploadBtn = (<button type="button" className="btn btn-disabled" disabled>{_`Select a File First`}</button>);
        } else {
            uploadBtn = (
                <button type="button" className="btn btn-primary"
                        onClick={this.props.onInternalUpload}>{_`Upload File`}</button>);
        }

        return (
            <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={this.props.onClose}>{_`Close`}</button>
                {uploadBtn}
            </div>
        )
    }
}
