import { CompositionService } from './composition.service';
import { IFolderSelectorParam, IRole } from 'phr-api-client';
import { Referral, REFERRAL_STATUS } from 'models/compositions/Referral';
import { Composition } from 'models/Composition';
import { post } from "../../app/services/api";
import { DataStore } from "../../app/services/data-store";

const teamId = (DataStore.get("me.currentRole") || {}).teamId || 0;

class ReferralsService extends CompositionService {

    get archetypeName(): string {
        return 'referral';
    }

    changeStatus({role, folderId, uuid, status}: {role?: IRole, folderId: IFolderSelectorParam, uuid: string, status: REFERRAL_STATUS}): Promise<any> {
        return this.getByUuid({role, folderId, uuid})
            .then((response) => {
                const content = response.message.content;
                content.status = status;
                return this.update({role, folderId, uuid, content});
            });
    }

    getReferrals({folderId, teamId}: {folderId: number, teamId: string}): Promise<Composition<Referral>[]> {
        return this.list({folderId}).then(({message}) => message.results);
    }

}

export const referralsService = new ReferralsService();
export default referralsService;
