import { GroupTypeBase } from 'react-select';
import { SortOrder } from 'common/ui/grid';
import { Filter } from 'common/ui/grid/paginatedTableReducer';
import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';
import { Label } from 'models/Label';

export const PSEUDO_ENTITY_PATH_DELIMITER = '|';

export interface AdvancedSearchDefinition {
    name: string;
    static: boolean;
    queryDefinition: AdvancedSearchQueryDefinition;
}

export interface AdvancedSearch extends AdvancedSearchDefinition {
    viewIdentifier: string;
    viewUpdatedAt?: string;
    labels?: Label[];
}

export type AdvancedSearchQueryJoinMode = 'inner-join' | 'left-outer-join' | 'anti-join';

export interface AdvancedSearchQueryDefinition {
    documentType: string;
    outputs: string[];
    joinMode?: AdvancedSearchQueryJoinMode;
    criteria?: AdvancedSearchQueryCriterion[];
    children?: AdvancedSearchQueryDefinition[];
}

export interface AdvancedSearchQueryCriterionValueDefinition {
    value?: string;
    values?: string[];
    range?: { from: string; to: string };
}

export interface AdvancedSearchQueryCriterion {
    path: string;
    comparator: string;
    value: AdvancedSearchQueryCriterionValueDefinition;
}

export type DocumentLinkCardinality = 'single' | 'multiple';

export interface AdvancedSearchDocumentField {
    name: string;
    path: string;
    type: string;
    comparators?: string[];
    options?: {name: string; value: string}[];
    default?: AdvancedSearchDocumentFieldInclusionDefault;
    elementFields?: AdvancedSearchDocumentField[];
    defaultCriterion?: AdvancedSearchDocumentFieldCriterionDefault;
}

export interface AdvancedSearchDocumentFieldCriterionDefault {
    value: AdvancedSearchQueryCriterionValueDefinition;
    comparator: string;
}

export interface AdvancedSearchDocumentFieldInclusionDefault {
    ordinal: number;
    mandatory?: boolean;
}

export type AdvancedSearchDocumentConfigParentLink = {
    documentType: string;
    cardinality: DocumentLinkCardinality;
}

export interface AdvancedSearchDocumentConfig {
    name: string;
    documentType: string;
    classification?: string;
    parentLinks: AdvancedSearchDocumentConfigParentLink[];
    fields: AdvancedSearchDocumentField[];
}

export interface AdvancedSearchFieldComparatorDisplay {
    label: string;
    value: string;
}

export interface AdvancedSearchQueryResultsManager {
    searchUuid: string;
    viewIdentifier: string;
    error: boolean;
    loading: boolean;
    queryResults: AdvancedSearchQueryResult[];
    patientsTotal: number;
    updatedAt: string;
    onRefresh: () => void;
    currentPage: number;
    setCurrentPage: (newCurrentPageVal: number) => void;
    perPage: number;
    setPerPage: (newPerPageVal: number) => void;
    sortState: Record<string, SortOrder>;
    setSortState: (newSortState: Record<string, SortOrder>) => void;
    filterState: Filter;
    setFilter: (newFilter: Filter) => void;
}

export interface AdvancedSearchQueryResults {
    resultsPageData: AdvancedSearchQueryResult[];
    totalResultCount: number;
    lastUpdatedAt: Date;
}

export type AdvancedSearchQuerySingleResultDetail = {[key: string]: unknown};
export type AdvancedSearchQueryResultDetails = AdvancedSearchQuerySingleResultDetail | AdvancedSearchQuerySingleResultDetail[]

export interface AdvancedSearchQueryResult {
    documentType: string;
    uuid: string;
    _composition_id: string;
    folderId: number;
    team_id: number;
    result: AdvancedSearchQueryResultDetails;
    children?: {
        documentType: string;
        results: AdvancedSearchQueryResult[];
    }[];
}

export function canBeRootDocument(config: AdvancedSearchDocumentConfig): boolean {
    return canBeChildOf(config, null);
}

export function canBeChildOf(config: AdvancedSearchDocumentConfig, documentType: string): boolean {
    if (documentType == config.documentType) {
        return false;
    }

    if (documentType == 'demographics') {
        return canBeRootDocument(config);
    }

    return getParentLink(config, documentType) != null;
}

export function getParentLink(
    config: AdvancedSearchDocumentConfig,
    parentDocumentType: string
): AdvancedSearchDocumentConfigParentLink {
    const specificLink = config.parentLinks.find(parent => parent.documentType == parentDocumentType);
    if (specificLink) {
        return specificLink;
    }

    if (parentDocumentType == 'demographics') {
        return getParentLink(config, null);
    }

    return null;
}

export function getDefaultOutputs(config: AdvancedSearchDocumentConfig): AdvancedSearchDocumentField[] {
    return config.fields.filter(output => !!output.default);
}

export function getMandatoryOutputs(config: AdvancedSearchDocumentConfig): AdvancedSearchDocumentField[] {
    return getDefaultOutputs(config).filter(output => output.default.mandatory);
}

export function getDefaultCriteria(config: AdvancedSearchDocumentConfig): AdvancedSearchQueryCriterion[] {
    return config.fields.filter(field => !!field.defaultCriterion).map(field => {
        return {
            path: field.path,
            comparator: field.defaultCriterion.comparator,
            value: field.defaultCriterion.value
        };
    });
}

export function makeQueryDefinitionFromConfig(config: AdvancedSearchDocumentConfig): AdvancedSearchQueryDefinition {
    return {
        documentType: config.documentType,
        outputs: getDefaultOutputs(config).sort((a, b) => a.default.ordinal - b.default.ordinal ).map(output => output.path),
        criteria: getDefaultCriteria(config)
    };
}

export function makeQueryCriterionFromConfig(config: AdvancedSearchDocumentField): AdvancedSearchQueryCriterion {
    return {
        path: config.path,
        comparator: config.comparators ? config.comparators[0]: null,
        value: {}
    };
}

export function getChildQueryDefinitionsWithCardinality(
    definition: AdvancedSearchQueryDefinition,
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    cardinality: DocumentLinkCardinality
): AdvancedSearchQueryDefinition[] {
    if (definition.children) {
        return definition.children.filter(childDefinition => {
            return getChildQueryDefinitionCardinality(
                definition.documentType,
                childDefinition,
                documentSearchConfigs
            ) == cardinality;
        });
    }
    return [];
}

export function getChildQueryDefinitionCardinality(
    documentType: string,
    childDefinition: AdvancedSearchQueryDefinition,
    documentSearchConfigs: AdvancedSearchDocumentConfig[]
): DocumentLinkCardinality {
    const childConfig = getDocumentSearchConfig(documentSearchConfigs, childDefinition.documentType);
    const parentLink = getParentLink(childConfig, documentType);
    return parentLink.cardinality;
}

export function getDocumentSearchConfig(configs: AdvancedSearchDocumentConfig[], documentType: string) {
    //return configs.find(config => config.documentType == documentType);
    //todo handle nested using recursion. Not needed yet?
    const subDocumentPathDelimiter = documentType.indexOf(PSEUDO_ENTITY_PATH_DELIMITER);
    if (subDocumentPathDelimiter > 0) {
        const parentDocumentType = documentType.substring(0, subDocumentPathDelimiter);
        const parentConfig = configs.find(config => config.documentType == parentDocumentType);
        const childPath = documentType.substring(subDocumentPathDelimiter+1);
        const childField = parentConfig.fields.find(field => field.path == childPath);
        return makePseudoConfigFromChildField(childField, parentDocumentType);
    }
    return configs.find(config => config.documentType == documentType);
}

export function getChildDocumentSearchConfigs(configs: AdvancedSearchDocumentConfig[], myConfig: AdvancedSearchDocumentConfig) {
    const documentType = myConfig.documentType;
    const pseudoChildFields = myConfig.fields.filter(isFieldAPseudoDocument);
    const pseudoChildConfigs: AdvancedSearchDocumentConfig[] = pseudoChildFields.map(childField => {
        return makePseudoConfigFromChildField(childField, documentType);
    });
    const childConfigs = configs.filter(config => canBeChildOf(config, documentType));
    const combined = [];
    combined.splice(0, 0, ...pseudoChildConfigs);
    combined.splice(combined.length, 0, ...childConfigs);
    return combined;
}

function makePseudoConfigFromChildField(childField: AdvancedSearchDocumentField, documentType: string): AdvancedSearchDocumentConfig {
    return {
        name: childField.name,
        documentType: `${documentType}${PSEUDO_ENTITY_PATH_DELIMITER}${childField.path}`,
        classification: '',
        parentLinks: [{ documentType: documentType, cardinality: getPseudoChildCardinality(childField) }],
        fields: childField.elementFields
    };
}

export function getFieldConfig(fields: AdvancedSearchDocumentField[], path: string) {
    return fields.find(field => field.path == path);
}

export function getComparatorDisplayValue(comparator: string): AdvancedSearchFieldComparatorDisplay {
    switch (comparator) {
    case 'NOT_IN':
        return {
            label: 'None of',
            value: 'NOT_IN'
        };
    case 'IN':
        return {
            label: 'Any of',
            value: 'IN'
        };
    case 'EQUALS':
        return {
            label: 'Equals',
            value: 'EQUALS'
        };
    case 'NOT_EQUALS':
        return {
            label: 'Does not equal',
            value: 'NOT_EQUALS'
        };
    case 'BETWEEN':
        return {
            label: 'Between',
            value: 'BETWEEN'
        };
    case 'GREATER_THAN':
        return {
            label: 'Greater than',
            value: 'GREATER_THAN'
        };
    case 'GREATER_THAN_OR_EQUAL_TO':
        return {
            label: 'Greater than or equal to',
            value: 'GREATER_THAN_OR_EQUAL_TO'
        };
    case 'LESS_THAN':
        return {
            label: 'Less than',
            value: 'LESS_THAN'
        };
    case 'LESS_THAN_OR_EQUAL_TO':
        return {
            label: 'Less than or equal to',
            value: 'LESS_THAN_OR_EQUAL_TO'
        };
    default:
        console.error('Unsupported comparator type ' + comparator);
    }
}

type AdvancedSearchDocumentConfigClassificationMap = {
    [key: string]: AdvancedSearchDocumentConfig[];
}

export const groupDocumentConfigsByClassification = (configs: AdvancedSearchDocumentConfig[]) => {

    const classificationMap: AdvancedSearchDocumentConfigClassificationMap = configs.reduce<AdvancedSearchDocumentConfigClassificationMap>((acc, criteria) => {
        const classificationList = acc[criteria.classification] || [];

        const updatedAcc: AdvancedSearchDocumentConfigClassificationMap = {
            ...acc,
            [criteria.classification]: [...classificationList, criteria],
        };

        return updatedAcc;
    }, {});

    return Object.keys(classificationMap).map(key => {
        const group: GroupTypeBase<{ label: string; value: AdvancedSearchDocumentConfig}> = {
            label: key,
            options: classificationMap[key].map(value => {
                return { label: value.name, value: value };
            })
        };

        return group;
    });
};

export function getDisplayListForQueryValueOptions(config: AdvancedSearchDocumentField) {
    if (config.type == 'fileupload') {
        return [];
    }

    if (!config.options && config.type == 'yesNo') {
        return [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' }
        ];
    }
    return config.options.map(option => {
        return { label: option.name, value: option.value };
    });
}

export function isFieldAPseudoDocument(field: AdvancedSearchDocumentField): boolean {
    return field.type == 'array';
}

export function getPseudoChildCardinality(field: AdvancedSearchDocumentField): DocumentLinkCardinality {
    return field.type == 'array' ? 'multiple' : 'single';
}

export function isFieldSearchable(field: AdvancedSearchDocumentField): boolean {
    return !!field.comparators;
}

export interface SelectionStateContainer {
    selectionState: FolderSelectionState;
    viewIdentifier?: string;
    advancedSearchUuid?: string;
    filterState?: Filter;
}

export function getDisplayableChildDefinitions(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition,
): AdvancedSearchQueryDefinition[] {
    const displayableChildren = queryDefinition.children.reduce(
        (
            previousValue: AdvancedSearchQueryDefinition[],
            definition: AdvancedSearchQueryDefinition
        ) => {
            previousValue.push(definition);
            const singleCardinalityChildren = getChildQueryDefinitionsWithCardinality(definition, documentSearchConfigs, 'single');
            previousValue.push(...singleCardinalityChildren);
            return previousValue;
        },
        []
    );

    return displayableChildren.filter(childDefinition => childDefinition.outputs && childDefinition.outputs.length > 0);
}
