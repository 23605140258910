import React from 'react';
import _ from 'services/i18n';
import { Column } from 'ui';
import FormPanel from 'components/form-panel';
import TextInput from 'components/form-panel/text-input';
import lodash from 'lodash';

export default class extends React.Component {
    
    render () {
        let alertType, alertText;
        
        if (this.props.loading) {
            alertType = 'info';
            alertText = _`Loading. Please wait...`;
        } else if (this.props.saving) {
            alertType = 'info';
            alertText = _`Saving. Please wait...`;
        } else if (this.props.saveStatus === false) {
            alertType = 'danger';
            alertText = _`Could not update your password. Please try again, ensuring you enter your existing password correctly.`;
        } else if (this.props.saveStatus === true) {
            alertType = 'success';
            alertText = _`Successfully changed your password.`;
        } else if (this.props.loadStatus === false) {
            alertType = 'warning';
            alertText = _`An error occurred while loading your user profile. Please try again later.`;
        }
        
        return (
            <Column md='6'>
                <FormPanel
                    key={this.props.formKey}
                    title={_`Change Your Password`}
                    intro={_`Use this form to change the password you use to login to the i-Focus system and related apps.`}
                    id='contact-details-form'
                    onSubmit={this.props.onSubmit}
                    onReset={this.props.onReset}
                    submitLabel={_`Save Changes`}
                    resetLabel={_`Discard Changes`}
                    busy={this.props.loading || this.props.saving || (this.props.loadStatus === false)} 
                    alertText={alertText}
                    alertType={alertType} >
                    
                        <TextInput
                            label={_`Existing Password:`}
                            id='pf-existing-password'
                            initialValue=''
                            required={true}
                            type='password'
                            hideValidationSuccess={true}
                            helpText={_`For security reasons, please confirm your existing password above.`}
                        />
                        
                        <TextInput
                            label={_`New Password:`}
                            id='pf-new-password'
                            initialValue=''
                            required={true}
                            minLength={8}
                            type='password'
                            onValidate={this.props.onValidatePassword}
                            helpText={_`Your new password must be at least 8 characters long, and must not be the same as your email address.`}
                        />
                
                </FormPanel>
            </Column>
        );
    }
}