import { TopTeamPreferencesContext } from 'common/TeamPreferences';
import { useContext } from 'react';

export const getRoutesStackForPath = (routes, path) => {
    const name = path.replace(/\/$/, '').split('/');

    const recursion = (routes, routesStack = []) => {

        for (const route of routes) {
            if (route.ignoreTeamPreferences) {
                continue;
            }

            if (route.path === name[name.length - 1]) {
                return [...routesStack, route];
            }

            if (route.childRoutes) {
                const result = recursion(route.childRoutes, [...routesStack, route]);
                if (result) {
                    return result;
                }
            }
        }

        return null;
    };

    return recursion(routes);
};

const isRouteEnabledByTeamPref = (routesEnabled = [], routesStack = []) => {
    const routesPath = routesStack.map(route => route.path).filter(path => !!path).join('/');

    return routesEnabled.some(routeEnabled =>
        routesPath.indexOf(routeEnabled) !== -1
        && routesPath.indexOf(routeEnabled) === routesPath.length - routeEnabled.length
    );
};

const isAccessableUrl = (routeStack, teamPrefs) => {
    if (!teamPrefs || !teamPrefs.prefs) {
        return [false, false, false];
    }

    const { routes_enabled: routesEnabled } = teamPrefs.prefs.portal;
    const prefsEnabled = isRouteEnabledByTeamPref(routesEnabled, routeStack);

    const route = routeStack[routeStack.length - 1];

    const isRoot = routeStack.length <= 2;

    const ignorePrefs = route?.ignoreTeamPreferences ?? false;
    const isAccessable = prefsEnabled || isRoot || ignorePrefs || !route.path;

    return [isAccessable, teamPrefs.prefs.isDefaultRoutes];
};

export const useIsAccessableUrl = (routes, url) => {
    const teamPrefs = useContext<any>(TopTeamPreferencesContext);

    if (!routes || !url) {
        return [true, true];
    }
    const routeStack = getRoutesStackForPath(routes, url);

    if (!routeStack || routeStack.length === 0) {
        return  [false, false];
    }

    return isAccessableUrl(routeStack, teamPrefs);
};

export const useIsAccessableRoute = (routeStack) => {
    const teamPrefs = useContext<any>(TopTeamPreferencesContext);

    if (routeStack.some(route => route.ignoreTeamPreferences)) {
        return [true, teamPrefs.prefs.isDefaultRoutes];
    }

    return isAccessableUrl(routeStack, teamPrefs);
};

