import { useCallback } from 'react';

export function useDefaultMouseEventPrevention<V>(fn: (param?: V) => void) {
    return useCallback(
        (ev: React.MouseEvent<HTMLButtonElement>, param?: V): void => {
            ev.preventDefault();
            fn(param);
        },
        [fn],
    );
}
