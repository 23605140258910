import React, { FC, useState } from 'react';
// components
import AdvancedSearchResultsExplorerModal from 'features/patients/advanced-search/AdvancedSearchResultsExplorerModal';
// interfaces
import { AdvancedSearchResultsTableRowActionProps } from './AdvancedSearchResultsTableRowActions.interface';
import { useShowHideModalFn } from 'common/ui/modal';
import { MODAL_TYPE } from 'features/pathwayModal/ModalType';
import { AdvancedSearchPatientResultViewModal } from 'features/patients/advanced-search/AdvancedSearchPatientResultViewModal';

const AdvancedSearchResultsTableRowActions: FC<AdvancedSearchResultsTableRowActionProps> = ({
    documentSearchConfigs,
    searchDefinition,
    result
}) => {
    const [setShowExploreResultsModal, exploreResultsModalVisibilityFnRef] = useShowHideModalFn();
    const [setShowPatientViewModal, patientViewModalVisibilityFnRef] = useShowHideModalFn();
    const [selectedTab, setSelectedTab] = useState<string>('');
    const [tabView, setTabView] = useState<boolean>(false);
    const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

    const handleDropdownSelect = (item: string, tabView: boolean) => {
        setSelectedTab(item);
        setTabView(tabView);
        setShowPatientViewModal(true);
    };

    const toggleDropdown = () => setDropdownOpened(!dropdownOpened);
    const listItemStyling = { cursor: 'pointer' };

    return (
        <>
            <div className={'results-row-actions-list'}>
                <div className={'results-row-actions-header'}>
                    <a onClick={() => handleDropdownSelect('', true)}>
                        Manage
                    </a>
                    <button
                        type="button"
                        className={`glyphicon glyphicon-chevron-${dropdownOpened ? 'down' : 'right'}`}
                        onClick={toggleDropdown}
                    />
                </div>
                {dropdownOpened && (
                    <ul>
                        <li style={listItemStyling} key={'view_record'}>
                            <a href={`/clinical_portal/folder/${result.folderId}/patient`}>
                                Go to record
                            </a>
                        </li>
                        <li style={listItemStyling} key={'explore_results'}>
                            <a onClick={() => setShowExploreResultsModal(true)}>
                                Explore results
                            </a>
                        </li>
                        {dropdownItems.map((item) => (
                            <li style={listItemStyling} key={item}>
                                <a onClick={() => handleDropdownSelect(item, false)}>
                                    {item}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <AdvancedSearchResultsExplorerModal
                documentConfigs={documentSearchConfigs}
                searchDefinition={searchDefinition}
                result={result}
                showHideFnRef={exploreResultsModalVisibilityFnRef}
            />
            <AdvancedSearchPatientResultViewModal
                selectedTab={selectedTab}
                folderId={result.folderId}
                tabView={tabView}
                showHideFnRef={patientViewModalVisibilityFnRef}
            />
        </>
    );
};

const dropdownItems = [MODAL_TYPE.APPOINTMENTS, MODAL_TYPE.QUESTIONNAIRES, MODAL_TYPE.MESSAGES, MODAL_TYPE.RESOURCES];

export default AdvancedSearchResultsTableRowActions;
