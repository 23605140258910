import {$post} from 'services/api';

export default class {
    CloseModal () {
        this.setState({
            showFileModal: false
        });
    }

    Upload (data) {
        this.setState({
            results: data,
            previewResults: data.slice(0,5)
        });
    }

    ShowModal () {
        this.setState({
            showFileModal: true
        });
    }

    Import () {
        this.setState({
            status: 'processing'
        });

        $post(['admin_portal','upload-invites'], {
            invites: this.state.results
        }, this.getTeamId()).then((response) => {
            this.setState({
                status: 'processed',
                total: response.message.total,
                duplicates: response.message.duplicates,
                imported: response.message.imported,
                failed: response.message.failed,
                ageFailed: response.message.age_failed,
                failedRows: response.message.failed_rows
            });
        });
    }
}