export const remap = (o: any, fn: any) => {
    if (typeof o !== 'object' || o === null){
        return o;
    }
    let newO: any;
    let origKey;
    let newKey;
    let value;
    if (o instanceof Array) {
        return o.map((val: any) =>  {
            if (typeof val === 'object') {
                val = remap(val, fn);
            }
            return val;
        });
    } else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = fn(origKey);
                value = o[origKey];
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = remap(value, fn);
                }
                newO[newKey] = value;
            }
        }
    }
    return newO;
};
