import React, { FC } from 'react';

export const AdvancedSearchInputUnavailable: FC = () => {
    return (
        <div className={'form-group'}>
            <input
                placeholder="Unavailable"
                type="text"
                className={'form-control'}
                disabled
            />
        </div>
    );
};
