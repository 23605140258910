import React, { FC, useEffect, useState } from 'react';
import { defaultSort, Grid, GridBody, GridHeader, GridHeaderCell, GridHeaderRow, SortOrder } from 'common/ui/grid';
import _ from 'services/i18n';
import {
    GridItem,
    ListRow
} from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/dashboard-questionnaire-row/DashboardQuestionnaireRow';

interface ListProps {
    items: GridItem[];
}

const getKeyString: (item: GridItem, key: string) => string = (item, key) => {
    switch (key) {
    case 'score':
        return parseInt(item[key]);
    case 'diff':
        if (isNaN(parseInt(item[key]))) {
            return -Infinity;
        }
        return parseInt(item[key]);
    default:
        return item[key];
    }
};

const listSort = (sortKey, sortOrder) => (a, b) => {
    const aString = getKeyString(a, sortKey);
    const bString = getKeyString(b, sortKey);

    return defaultSort(aString, bString, sortOrder);
};


export const QuestionnaireList: FC<ListProps> = ({
    items,
}) => {
    const [sortOrder, setSortOrder] = useState<{ [key: string]: SortOrder }>({ measure: SortOrder.DESC });
    const [sortedItems, setSortedItems] = useState<GridItem[]>(items);
    useEffect(() => {
        const sortEntries = Object.entries(sortOrder);
        if (sortEntries.length > 0) {
            const [sortKey, sortOrder] = sortEntries[0];
            const reSorted = [...sortedItems].sort(listSort(sortKey, sortOrder));
            setSortedItems(reSorted);
        }
    }, [items, sortOrder]);

    return (
        <Grid onSort={setSortOrder} sortOrder={sortOrder}>
            <GridHeader>
                <GridHeaderRow>
                    <GridHeaderCell filterable={false} field="measure" className="text-center">{_`Measure`}</GridHeaderCell>
                    <GridHeaderCell filterable={false} field="score" className="text-center">{_`Score`}</GridHeaderCell>
                    <GridHeaderCell filterable={false} field="date" className="text-center">{_`Date`}</GridHeaderCell>
                    <GridHeaderCell filterable={false} field="diff" className="text-center">{_`Change`}</GridHeaderCell>
                    <GridHeaderCell filterable={false} field="changePeriod" className="text-center">{_`Change Period`}</GridHeaderCell>
                </GridHeaderRow>
            </GridHeader>
            <GridBody>
                {sortedItems.map((item, i) => {
                    return <ListRow key={i} item={item}/>;
                })}
            </GridBody>
        </Grid>
    );
};
