import React from 'react';
import BasePage from 'components/page/base';
import Events from './_events';
import _ from 'services/i18n';
import ActionMenu from 'ui/action-menu';
import HeadingDoc from 'ui/heading-doc';
import { Row, Column, ResponsiveTable } from 'ui';
import NHSNumber from 'ui/nhs-number';
import Pagination from 'components/pagination';
import lodash from 'lodash';
import { browserHistory } from 'react-router';
import { feedbackService } from 'services/feedback.service';
import { patientsService } from 'services/patients.service';
import { DateTime } from 'common/datetime/DateTime';


/**
 * UserFeedback page component
 * No properties are to be passed into this component
 * This page renders a list of user feedback items visible to the currently
 * logged in clinician's team.
 */
export default class UserFeedback extends BasePage {
    constructor(props) {
        super(props);

        this.state = {
            feedback: [],
            count: 0,
            page: 1,
            perPage: 10,
            patients: new Map(),
            loading: true,
            loadingError: false
        };

        this.bindEvents(['ChangePage'], new Events());

        this.bindFunctions(['loadComponent']);
    }

    pageTitle() {
        return _`${this.$ps()} Feedback | iFocus Clinical Portal`;
    }

    componentWillMount() {
        this.loadComponent();
    }

    loadComponent() {
        const {
            perPage,
            page
        } = this.state;

        feedbackService.list({
            folderId: 'all',
            sort: [{field: 'created_at', ascending: false}],
            limit: perPage,
            offset: (page - 1) * perPage
        })
            .then((response) => {
                const feedback = lodash.get(response, 'message.results', []);
                const count = lodash.get(response, 'message.total', 0);
                this.setState({feedback, count});
                const uniqFolderIds = Object.keys(feedback.reduce((acc, {folder_id}) => {
                    acc[folder_id] = null;
                    return acc;
                }, {}));
                return Promise.all(uniqFolderIds.map((folderId) => {
                    return patientsService.getByFolderId({folderId});
                }));
            })
            .then((patientFolders) => {

                let patients = new Map();
                patientFolders.map((item) => {
                    patients.set(item.id, item);
                });

                this.setState({
                    patients,
                    loading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    loadingError: true
                });
            });
    }

    render() {
        if (this.state.loadingError) {
            return <LoadingError $ps={this.$ps} $p={this.$p}/>;
        }

        return <Page state={this.state} events={this.events} $p={this.$p} $ps={this.$ps}/>;
    }

    getUniqueFieldValues(nameOfField, arr) {
        const folderList = new Set();
        const folderListArray = arr.reduce((acc, current) => {
            if (!folderList.has(current[nameOfField])) {
                folderList.add(current[nameOfField]);
                acc.push(current[nameOfField]);
            }
            return acc;
        }, []);
        return folderListArray;
    }
}

/**
 * Displays the generic error message encountered if the ajax calls fail to return correctly
 * @param props.$ps - The plural form of the term "patients" for this service
 * @return React Component
 */
export const LoadingError = ({$ps, $p}) => {
    return (
        <div className="page page-user-feedback">
            <PageHeader p={$p()} ps={$ps()}/>
            <div className="alert alert-danger">
                <span>This page was unable to load the required information due to an unspecified error. Please try again later.</span>
            </div>
        </div>
    );
};

/**
 * Displays the page header
 * @param props.$ps - The plural form of the term "patients" for this service
 * @return React Component
 */
export const PageHeader = ({ps, p}) => {
    return (
        <HeadingDoc title={_`${p} Feedback`}>
            {_`This page will show any ${ps} who have submitted feedback.`}
        </HeadingDoc>
    );
};

/**
 * Displays the page
 * @param props.$p - The singular form of the term "patients" for this service
 * @param props.$ps - The plural form of the term "patients" for this service
 * @param props.state - The passed in state
 * @param props.state.loading - True if page is still loading
 * @param props.state.feedback - An array of user feedback objects
 * @param props.state.patients - A map object of folder to patient details
 * @return React Component
 */
export const Page = ({$p, $ps, state, events}) => {
    if (state.loading) {
        return (
            <div className="page page-user-feedback">
                <PageHeader p={$p()} ps={$ps()}/>
                <p><em>Loading please wait...</em></p>
            </div>
        );
    }

    return (
        <div className="page page-user-feedback">

            <PageHeader ps={$ps()} p={$p()}/>
            <Row>
                <Column md="12">
                    <ResponsiveTable>
                        <thead>
                        <tr>
                            <th>NHS Number</th>
                            <th>Usability</th>
                            <th>Recommendation</th>
                            <th>Features</th>
                            <th>Feedback Date</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <Results {...state} />
                    </ResponsiveTable>
                    <Pagination currentPage={state.page}
                                pageCount={Math.ceil(state.count / state.perPage)}
                                onChange={events.onChangePage}/>
                </Column>
            </Row>
        </div>
    );
};

/**
 * Displays the body of the results table
 * @param props.feedback - An array of user feedback objects
 * @param props.patients - A map object of folder to patient details
 * @return React Component
 */
export const Results = ({feedback, patients}) => {
    let list = feedback.map((item, idx) => {
        return <ResultRow key={idx} {...item} patients={patients}/>;
    });

    return (
        <tbody>{list}</tbody>
    );
};

/**
 * Renders an individual row of the feedback
 * @param  props.patients A map object of folder to patient details
 * @param  props.folder_id The folder id the user feedback used to obtain patient details from)
 * @param  props.uuid The uuid of the user feedback for the link
 * @param  props.content The main content of the user feedback composition
 * @param  props.created_at The time/date the user feedback was created
 * @return React Component
 */
export const ResultRow = ({patients, folder_id, uuid, content, created_at}) => {
    let patient = patients.get(folder_id) || {
        preferred_name: 'User Not Found',
        nhs_number: ''
    };

    const actionList = [{
        type: 'callback',
        callback: () => {
            browserHistory.push(`/clinical_portal/folder/${folder_id}/user-feedback/${uuid}`);
        },
        label: _`View Feedback`
    }];
    let headings = new Map();

    lodash.forEach(content.ratings, (item) => {
        headings.set(item.label, item.value + ' / 5');
    });

    return (
        <tr>
            <td><NHSNumber empty="n.a">{getNHS(patient)}</NHSNumber></td>
            <td>{headings.get('Usability') || 'n.a'}</td>
            <td>{headings.get('Recommendation') || 'n.a'}</td>
            <td>{headings.get('Features') || 'n.a'}</td>
            <td>{<DateTime>{created_at}</DateTime>}</td>
            <td>
                <ActionMenu size="xs" links={actionList} label={_`Actions`}/>
            </td>
        </tr>
    );
};

function getNHS(patient) {
    if (lodash.get(patient, 'person_primary_identifier.namespace', '') === 'NHS_Number') {
        return lodash.get(patient, 'person_primary_identifier.value', '');
    }
    return '';
}