import React, { Component } from 'react';
import Modal from 'ui/modal';
import { FormGroup } from 'ui';
import _ from 'services/i18n';
import './change-email-and-reinvite.less';
import { merge, cloneDeep } from 'lodash';
import EmailValidator from 'email-validator';

export default class ChangeEmailModal extends Component {


    constructor(props) {
        super(props);

        const emailValidator = ['EMAIL', (v) => EmailValidator.validate(v)];

        this.errors = {
            REQUIRED: 'Field is required.',
            WIRED_NOT_MATCH: 'Email is different from above.',
            EMAIL: 'Invalid Email.'
        };

        this.state = {
            error: '',
            originalEmail: '',
            inputs: {
                email: {
                    value: '',
                    errors: [],
                    validators: [emailValidator]
                },
                email2: {
                    value: '',
                    errors: [],
                    validators: [emailValidator]
                }
            }
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);

    }

    isValid() {

        const generalErrors = {
            SOME_IS_EMPTY: 'Please fill all the fields.',
            EMAILS_NOT_EQUAL: 'Emails are not equal.',
            FIX_FIELD_ERRORS: 'Please fix the errors.'
        };

        if (!this.state.inputs.email.value || !this.state.inputs.email2.value) {
            this.setState({error: generalErrors.SOME_IS_EMPTY});
            return false;
        }
        if (this.state.inputs.email.value !== this.state.inputs.email2.value) {
            this.setState({error: generalErrors.EMAILS_NOT_EQUAL});
            return false;
        }

        if (this.state.inputs.email.errors.length || this.state.inputs.email2.errors.length) {
            this.setState({error: generalErrors.FIX_FIELD_ERRORS});
            return false;
        }
        return true;
    }

    onSubmit() {
        if (this.isValid(true)) {
            const {data: {id}} = this.props;
            this.props.onSendInvite(id, this.state.inputs.email.value)
                .then(() => {
                    this.onClose();
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({error: 'Unable to change email. Please Try Again later'});
                });
        }
    }

    onClose() {
        this.props.onClose();
    }

    onChange(e) {
        const name = e.target.name;
        const value = (e.target.value || '').trim();
        const inputs = cloneDeep(this.state.inputs);
        this.setState({
            inputs: merge(inputs, {[name]: {value}}),
            error: ''
        });
    }

    inputIsValid(inputName) {
        const inputs = cloneDeep(this.state.inputs);
        inputs[inputName].errors = [];
        this.setState({inputs});
    }

    inputInvalid(inputName, errors) {
        const inputs = cloneDeep(this.state.inputs);
        inputs[inputName].errors = errors || [];
        this.setState({inputs});
    }

    clearError() {
        this.setState({error: ''});
    }

    onValidate(name, errors) {
        return errors ? this.inputInvalid(name, errors) : this.inputIsValid(name);
    }

    render() {
        const buttons = [
            {type: 'callback', callback: this.onSubmit, label: 'Send Re-Invite', style: 'success'},
            {type: 'callback', callback: this.onClose, label: 'Cancel'}
        ];

        const {name: {given_name: givenName, family_name: familyName}} = this.props.data.user_details
        || {name: {given_name: '', family_name: ''}};

        const email = this.state.inputs.email;
        const email2 = this.state.inputs.email2;

        const preferredName = this.props.data.preferred_name;
        const legalName = this.props.data.legal_name;

        return (
            <Modal className={'change-email-modal'}  title={_`Change Email`} type="info" onClose={this.onClose} buttons={buttons}>
                {this.state.error &&
                <div className="alert alert-danger alert-dismissable">
                    <button type="button" className="close" onClick={() => this.clearError()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <strong>{_`Error.`} &nbsp;</strong>
                    <span>{_([this.state.error])}</span>
                </div>}
                <p><strong>{_`Patient:`}</strong> { preferredName || legalName }</p>
                <FormGroup>
                    <label>{_`New Email`}</label>
                    <InputText
                        value={email.value}
                        className="form-control"
                        name="email"
                        onChange={(e) => this.onChange(e)}
                        validators={email.validators}
                        onValidate={(name, errors) => this.onValidate(name, errors)}
                        required={true}
                    />
                    <div className="change-email-modal__errors-email">
                        {email.errors.map((v, i) => this.errors[v]).join(' | ')}
                    </div>
                </FormGroup>
                <FormGroup>
                    <label>{_`Confirm Email`}</label>
                    <InputText
                        value={email2.value}
                        className="form-control"
                        name="email2"
                        onChange={(e) => this.onChange(e)}
                        validators={email2.validators}
                        onValidate={(name, errors) => this.onValidate(name, errors)}
                        match={email.value}
                        required={true}
                    />
                    <div className="change-email-modal__errors-email">
                        {email2.errors.map((v, i) => this.errors[v]).join(' | ')}
                    </div>
                </FormGroup>
            </Modal>
        );
    }
};

class InputText extends Component {

    middleValue;

    constructor(props) {
        super(props);
        this.state = {
            pristine: true, // was ever changed?
            touched: false, // was blurred?
            invalid: false
        };
    }

    onChange(e) {
        this.props.onChange(e);
        this.setState({
            pristine: false
        }, () => {
            this.validate();
        });
        this.middleValue = e.target.value;
    }

    onBlur(e) {
        this.props.onBlur && this.props.onBlur(e);
        this.setState({
            touched: true
        }, () => {
            this.validate();
        });
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                pristine: false,
                touched: true
            }, () => {
                this.validate();
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.value !== this.props.value // change value in input
            || nextProps.match !== this.props.match // for validation and add appropriate classes
            || nextState.pristine !== this.state.pristine // for add appropriate class
            || nextState.touched !== this.state.touched // for add appropriate class
            || nextState.invalid !== this.state.invalid; // for add appropriate class
    }

    isExternalChangeOfValue(value) {
        return (this.props.value !== value && this.middleValue !== this.props.value);
    }

    isExternalChangeOfMatch(prevProps) {
        return (this.props.match !== prevProps.match);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.isExternalChangeOfValue(prevProps.value)) {
            this.setState({
                pristine: false,
                touched: true
            }, () => {
                this.validate();
            });
        }

        if(this.isExternalChangeOfMatch(prevProps)) {
            this.setState({
                pristine: false
            }, () => {
                this.validate();
            });
        }

    }

    validate(value = this.props.value || '') {
        if (!this.props.validators && !this.props.required && !('match' in this.props)) {
            return;
        }

        const errors = [];
        if (!value && this.props.required) {
            errors.push('REQUIRED');
        } else {
            this.props.validators.forEach(([name, fn]) => {
                if (!fn(value)) {
                    errors.push(name);
                }
            });
            if ('match' in this.props) {
                if (this.props.match !== value) {
                    errors.push('WIRED_NOT_MATCH');
                }
            }
        }

        if (errors.length && this.state.touched) {
            this.props.onValidate(this.props.name, errors);
            this.setState({invalid: true}, () => {
            });
        } else {
            this.props.onValidate(this.props.name);
            this.setState({invalid: false}, () => {
            });
        }
    }

    render() {
        const {
            name = '',
            value = '',
            placeholder = '',
        } = this.props;

        let validationClassNames = `` +
            `${this.state.invalid ? 'invalid' : 'valid'} ` +
            `${this.state.pristine ? 'pristine' : 'dirty'} ` +
            `${this.state.touched ? 'touched' : 'untouched'}`;

        let className = (`${this.props.className || ''} ${validationClassNames}`).trim();

        return (
            <input type="text"
                   name={name}
                   value={value}
                   className={className}
                   placeholder={placeholder}
                   onChange={(e) => this.onChange(e)}
                   onBlur={(e) => this.onBlur(e)}
            />
        );
    }

}
