import React, { useMemo, ChangeEvent, FC, FormEvent } from 'react';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import Select from 'react-select';
// interfaces
import { UserFolder } from 'models/UserFolder';
import {
    BulkOperationFiltersProps,
    ClinicianOption,
} from './BulkOperationFilters.interface';
// styles
import './BulkOperationFilters.scss';
import 'antd/dist/antd.css'; 
import _ from 'services/i18n';

const { RangePicker } = DatePicker;

function getClinicianName(clinician: UserFolder) {
    return clinician.user_details.name
        ? `${clinician.user_details.name.given_name} ${clinician.user_details.name.family_name}`
        : clinician.user_details.email;
}

const BulkOperationFilters: FC<BulkOperationFiltersProps> = (props) => {
    const {
        clinician,
        clinicianList,
        bulkOperationName,
        dateRange,
        onClinicianChange,
        onBulkOperationNameChange,
        onDateRangeChange,
        onSubmit,
        isLoading,
    } = props;

    const clinicianOptionList = useMemo(() => {
        return clinicianList.map((clinician) => {
            const clinicianName = getClinicianName(clinician);

            return {
                label: clinicianName,
                value: clinician,
            };
        });
    }, [clinicianList]);

    const selectedClinician = clinician
        ? {
            label: getClinicianName(clinician),
            value: clinician,
        }
        : null;

    const handleClinicianChange = (option: ClinicianOption) => {
        onClinicianChange(option ? option.value : undefined);
    };

    const handleBulkOperationNameChange = (
        ev: ChangeEvent<HTMLInputElement>,
    ) => {
        const updatedBOName = ev.target.value;

        onBulkOperationNameChange(updatedBOName);
    };

    const handleDateRangeChange = (range: [Moment, Moment] | null) => {
        onDateRangeChange(range);
    };

    const handleSubmit = (ev: FormEvent) => {
        ev.preventDefault();

        onSubmit();
    };

    return (
        <form className="bo-filter__container" onSubmit={handleSubmit}>
            <div className="bo-filter__inputs">
                <div className="form-group">
                    <label htmlFor="bo-clinician-filter">{_`User`}</label>
                    <Select
                        value={selectedClinician}
                        options={clinicianOptionList}
                        onChange={handleClinicianChange}
                        isClearable={true}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="bo-name-filter">Bulk Operation Name</label>
                    <input
                        type="text"
                        id="bo-name-filter"
                        className="form-control"
                        value={bulkOperationName}
                        onChange={handleBulkOperationNameChange}
                    />
                </div>
                <div className="form-group">
                    <label>Date Range</label>
                    <div>
                        <RangePicker
                            onChange={handleDateRangeChange}
                            value={dateRange}
                            className="bo-filter__datepicker"
                        />
                    </div>
                </div>
            </div>
            <div className="bo-filter__submit-wrapper">
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                >
                    Filter
                </button>
            </div>
        </form>
    );
};

export default BulkOperationFilters;
