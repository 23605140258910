import React from 'react';
import { connect } from 'react-redux';
import { modalService } from './modal.service';

const modalPortal = (props) => {
    const { modalType, modalProps } = props;
    if (!modalType) {
        return null;
    }

    const ModalComponent = modalService.getModalComponenent(modalType);
    if (!ModalComponent) {
        return null;
    }

    return (
        <div className="modal-portal">
            <ModalComponent {...modalProps}></ModalComponent>
        </div>
    );
};

function mapStateToProps(state) {
    return state.common.modal.toJS();
}

export const ModalPortal = connect(mapStateToProps)(modalPortal);
