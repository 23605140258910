import React, { FunctionComponent, useContext } from 'react';
import { GridContext } from './Grid';
import { SortOrder } from './SortOrder';

export const GridBody: FunctionComponent<{className?: string}> = (props) => {
    const { children, className = '' } = props;
    return (
        <tbody className={className}>{children}</tbody>
    );
};
export const GridRow: FunctionComponent<{className?: string}> = (props) => {
    const { children, className = '' } = props;
    return (
        <tr className={`ui-grid__row ${className}`}>{children}</tr>
    );
};
export const GridCell: FunctionComponent<{
    className?: string;
    colSpan?: number;
    field?: string;
}> = (props) => {
    const { children, className = '', colSpan, field } = props;

    const {
        sortOrder: gridSortOrder,
    } = useContext(GridContext);

    const isSorted = gridSortOrder && (gridSortOrder[field] === SortOrder.ASC || gridSortOrder[field] === SortOrder.DESC);

    return (
        <td className={`ui-grid__cell ${className} ${isSorted ? 'ui-grid__cell-sorted' : ''}`} colSpan={colSpan}>{children}</td>
    );
};

/**
 * SimpleGridCell doesn't subscribe to a context for sorting updates.
 * Any change to the context would cause the cell to re-render, even if the changed part was not used.
 * So if you have a large table that's filterable but not sortable, and you use plain old GridCell,
 *  you're re-rendering every cell in the table every time the filter state changes,
 *  before you even do anything with the filter itself, for no good reason.
 */
export const SimpleGridCell: FunctionComponent<{
    className?: string;
    colSpan?: number;
}> = (props) => {
    return (
        <td className={`ui-grid__cell ${props.className ?? ''}`} colSpan={props.colSpan}>{props.children}</td>
    );
};
