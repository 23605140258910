import React from 'react';
import View from './view';
import BaseComponent from 'components/BaseComponent';
import { questionnaireService } from 'services/questionnaire.service';
import lodash from 'lodash';

/**
 * Questionnaire Template list component
 */
export default class QuestionnaireTemplateListComponent extends BaseComponent {
    constructor (props) {
        super(props);

        this.state = {
            pagination: {
                current: 1
            },
            results: [],
            displayResults:[],
            perPage: 10,
            count: 0,
            loading: true
        };

        this.bindEvents([
            'ChangePage'
        ]);

        this.bindFunctions([
            'loadComponent'
        ]);

        this.View = View;
    }

    handleChangePage (idx) {

        this.setState({
            pagination:{
                current:idx
            },
            loading:false,
            displayResults: this.state.results.slice((idx-1)*this.state.perPage,idx*this.state.perPage)
        });
    }

    componentWillMount () {
        this.loadComponent();
    }

    loadComponent () {
        this.getQuestionnaires()
            .then(({documents,count}) => {
                this.setState({
                    loading: false,
                    results: documents,
                    displayResults: documents.slice(0,this.state.perPage),
                    count: count
                });
            });
    }

    getQuestionnaires(){
        return Promise.all([
            questionnaireService.getCoopQuestionnaires(),
            questionnaireService.getTemplates({limit: Infinity})
        ]).then((results) => {
            const questionnaireResults = results.flat();

            return questionnaireResults.map((questionnaire) => {
                return {
                    type: questionnaire.document_type === 'questionnaire' ? 'composition' : 'template',
                    document: questionnaire
                };
            });
        }).then((documents) => {
            return {
                documents,
                count: documents.length
            };
        });
    }
}
