import React from 'react';
import { Link } from 'react-router';

import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { PatientSimpleQuestionnaireCreateForm } from 'components/user/simple-questionnaire/patientForm';

export default class AllocateSimpleQuestionnairePage extends BasePage {

    constructor (props) {
        super(props);

        this.bindEvents(['Submit']);
    }

    pageTitle () {
        return _`Allocate ${this.$p()} Simple Questionnaire | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="page">
                <div className='top-bar'>
                    <Link to={`${this.getFolderPath()}/patient/questionnaires`}>
                        {_`Back to Questionnaires List`}
                    </Link>
                </div>

                <PatientSimpleQuestionnaireCreateForm
                    folderId={this.getFolderId()}
                    onCompletionRedirect={`${this.getFolderPath()}/patient/questionnaires`}
                />
            </div>
        );
    }
}
