import { apiV2Service } from 'services/api-v2.service';
import DataStore from 'services/data-store';
import { CompositionService } from './composition.service';

const tag = '[Predefined Messages Service]';

class PredefinedMessagesService extends CompositionService {
    get archetypeName() {
        return 'message-template';
    }

    public isAvailable(): boolean {
        const role = DataStore.get('me.currentRole');
        if (!role) {
            console.error(tag, 'role is not available', role);
            return false;
        }
        return true;
    }
}

export const predefinedMessagesService = new PredefinedMessagesService();
export default predefinedMessagesService;
