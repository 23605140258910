import React, { useState, useEffect } from 'react';
import _ from 'services/i18n';
import { ModalDialog } from 'common/modalDialog';
import { resourceCategoryService } from 'services/resourceCategory.service';
import { CreateResourceState, normalise, validate } from 'components/user/resource/create/validator';
import { ResourceCreateForm } from 'components/user/resource/create/form';
import { ResourceType } from 'models/compositions/Resource';
import { resourcesService } from 'services/resources.service';
import { Composition } from 'models/Composition';
import { ResourceCategory } from 'models/ResourceCategory';

const initialFormState: CreateResourceState = {
    type: '',
    label: '',
    url: '',
    phone: '',
    html: '',
    description: '',
    mediaFile: null,
    mediaFileUuid: '',
    category: [],
    lozengeTitle: '',
    lozengeText: '',
    sendPush: false,
    notifyTimeline: false,
    tags: []
};

const ManageResourceModal = ({ id, categoryId, currentUser, onClose }) => {
    const [resource, setResource] = useState<CreateResourceState>({ ...initialFormState });
    const [errors, setErrors] = useState([]);
    const [availableCategories, setAvailableCategories] = useState<Composition<ResourceCategory>[]>([]);

    useEffect(() => {

        async function fetchCategories() {
            try {
                const categoryList = await resourceCategoryService.list({ folderId: 'team' });
                setAvailableCategories(categoryList?.message?.results || []);
            } catch(e) {
                setAvailableCategories([]);
            }
        }
        fetchCategories();
    }, []);

    useEffect(() => {

        async function loadResource() {
            try {
                const resourceResponse = await resourcesService.getByUuid({ folderId: 'team', uuid: id });

                const {
                    resource_type,
                    html_content,
                    labels
                } = resourceResponse.message.content;

                let tags = [];

                if (labels) {
                    tags = labels
                        .filter(label => label.type === 'resourceTags' && label.status === true)
                        .map(label => label.name);
                }

                setResource({
                    ...resourceResponse.message.content,
                    type: resource_type,
                    html: html_content,
                    categoryId: categoryId,
                    tags: tags
                });
            } catch(e) {
                setErrors([_`Could not load resource`]);
            }
        }
        loadResource();
    }, [categoryId, id]);

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (validate(resource, setErrors)) {

            const currentResource = await resourcesService.getByUuid({ folderId: 'team', uuid: id });

            const {
                labels
            } = currentResource.message.content;

            const normalisedResource = normalise(resource);
            const category = availableCategories.find(({ uuid }) => uuid === categoryId);

            let newLabels = [];

            if (labels) {
                const nonTagLabels = labels.filter((label) => label.type !== 'resourceTags');
                newLabels = [...nonTagLabels];

                if (resource.tags && resource.tags.length) {
                    newLabels = [...newLabels, ...resource.tags.map(tag => ({
                        name: tag,
                        context: tag.toLowerCase().replace(' ', '_'),
                        type: 'resourceTags',
                        status: true,
                    }))];
                }
            }

            const updatedResource = {
                label: normalisedResource.label,
                url: normalisedResource.url,
                phone: normalisedResource.phone,
                resource_type: normalisedResource.type as ResourceType,
                description: normalisedResource.description,
                html_content: normalisedResource.html,
                category: [{
                    category_id: category.uuid,
                    category_folder_id: category.folder_id,
                    name: category.content.name
                }],
                labels: newLabels,
                name_of_issuer: currentUser
            };

            resourcesService.update({ uuid: id, content: updatedResource, file: normalisedResource.mediaFile, folderId: 'team' })
                .then(() => onClose());
        }
    };

    return (
        <ModalDialog 
            title={_`Edit Resource`}
            onClose={onClose} 
            busy={false} 
            buttons = {
                (
                    <>
                        <button
                            className="btn btn-default btn-success"
                            onClick={handleSubmit}
                        >
                            {_`Update resource`}
                        </button>
                        <button className="btn btn-default" onClick={onClose}>
                                Cancel
                        </button>
                    </>
                )
            }
            overflow={true}
        >
            <ResourceCreateForm
                errors={errors}
                onChange={(c) => setResource({ ...resource, ...c })}
                form={resource}
                onSubmit={handleSubmit}
                hideSubmit={true}
                onShowMediaResource={null}
                showPatientOptions={false} />
        </ModalDialog>
    );
};

export default ManageResourceModal;
