import React, { Fragment } from 'react';
import NhsNumber from 'ui/nhs-number';
import { Link } from 'react-router';

import { DateSpan } from 'common/datetime/DateTime';
import {
    GridCell,
    GridRow
} from 'common/ui/grid';

import { MNDDepressedPatientsWorklistItem } from './MNDDepressedPatientsWorklistItem';

const IsValueNotEmpty = (value: number) => {
    if (value == 0) {
        return false;
    }
    return true;
};

export function MNDDepressedPatientsWorklistRow({
    worklistItem,
    isLoading,
    className
}: {
    worklistItem: MNDDepressedPatientsWorklistItem;
    isLoading: boolean;
    className: string;
}) {
    return (
        <GridRow className={className}>
            {!!isLoading && (
                <GridCell colSpan={13}>Loading</GridCell>
            )}
            {!isLoading && (
                <Fragment>
                    <GridCell field="name"><Link to={worklistItem.patientURL}><span className="no-wrap">{worklistItem.name}</span></Link></GridCell>
                    <GridCell field="nhs"><span className="no-wrap"><NhsNumber>{worklistItem.nhs || '--'}</NhsNumber></span></GridCell>
                    <GridCell field="phq8Timestamp"><span className="no-wrap"> {
                        IsValueNotEmpty(worklistItem.phq8Timestamp) && (
                            <DateSpan>{worklistItem.phq8Timestamp}</DateSpan>
                        )}
                    </span>
                    </GridCell>
                    <GridCell field="phq8Score" className="text-right">{
                        worklistItem.phq8QuestionnaireURL && (
                            <Link to={worklistItem.phq8QuestionnaireURL}>{worklistItem.phq8ScoreString}</Link>
                        )}
                    </GridCell>
                    <GridCell field="phq8ScoreDiff" className="text-right"><Link to={worklistItem.patientGraphsURL}>
                        <Diff>{worklistItem.phq8ScoreDiff}</Diff>
                    </Link></GridCell>
                    <GridCell field="gad7Timestamp"><span className="no-wrap"> {
                        IsValueNotEmpty(worklistItem.gad7Timestamp) && (
                            <DateSpan>{worklistItem.gad7Timestamp}</DateSpan>
                        )}
                    </span>
                    </GridCell>
                    <GridCell field="gad7Score" className="text-right">{
                        worklistItem.gad7QuestionnaireURL && (
                            <Link to={worklistItem.gad7QuestionnaireURL}>{worklistItem.gad7ScoreString}</Link>
                        )}
                    </GridCell>
                    <GridCell field="gad7ScoreDiff" className="text-right"><Link to={worklistItem.patientGraphsURL}><Diff>{worklistItem.gad7ScoreDiff}</Diff></Link></GridCell>
                </Fragment>
            )}
        </GridRow>

    );
}

const Diff = ({ children }) => {
    if (children === -Infinity) {
        return <Fragment>&nbsp;</Fragment>;
    }
    if (children === undefined || children === null) {
        return <Fragment>&nbsp;</Fragment>;
    }

    if (!children){
        return <Fragment>-</Fragment>;
    }

    if (typeof children !== 'number'){
        return <Fragment>{children}</Fragment>;
    }

    const value = children;
    return <Fragment>{value > 0 ? '+' : ''}{value}</Fragment>;
};
