import { ModalDialog } from "common/modalDialog";
import React, { Fragment } from "react";
import DOMPurify from 'dompurify';

export const HTMLModalDialog = ({onClose, label, html_content}) => {

    const buttons = (
        <Fragment>
            <button
                className="btn btn-default"
                onClick={onClose}
            >Close</button>
        </Fragment>
    );


    return (
        <ModalDialog title={label} onClose={onClose} buttons={buttons}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html_content, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']}) }} />
        </ModalDialog>
    );
}