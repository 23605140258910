import { useEffect, useState } from 'react';
import teamPreferencesService from 'services/team-preferences.service';
import { useTeamPreferences } from 'common/useTeamPreferences';

export function useCareEvents({ folderId, teamId }) {
    const [state, setState] = useState({
        careEvents: [],
        isFetching: true,
    });
    const { careEvents, isFetching } = state;

    useEffect(() => {
        async function getCareEvents() {
            const teamPreferences = await teamPreferencesService.get();
            let { care_events: careEvents = [] } = teamPreferences;

            if (careEvents.some((careEvent) => { return typeof careEvent !== 'string'; })) {
                careEvents = careEvents.map(careEvent => { return { careEventName: careEvent.care_event, details: careEvent.details }; });
            } else {
                careEvents = careEvents.map(careEvent => { return { careEventName: careEvent };});
            }

            setState({
                careEvents,
                isFetching: false,
            });
        }
        getCareEvents();
    }, [folderId, teamId]);

    return [careEvents, isFetching];
}

export function useCareEventsForArchetype(archetype: string) {
    const teamPreferences = useTeamPreferences();
    const careEventsEntitySpecific = teamPreferences.care_events_entity_specific ?? {};
    const careEventsForEntityType = careEventsEntitySpecific[archetype] ?? [];
    return careEventsForEntityType.length === 0 ? false : careEventsForEntityType;
}