import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import ServerFetch from 'server-fetch';

/**
 * Extended Page Component for user_portal/documents
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Page Not Found | PHR Clinical Portal`;
    }

    componentWillMount () {
        ServerFetch.renderFromServerState();
    }

    render () {
        return (
            <div className="page-not-found">
                <h1>Page Not Found</h1>
                <p>We are sorry, but the page you are trying to access has not been found. Please navigate using the sidebar.</p>
            </div>
        );
    }
}
