import { TerAction } from 'services/ter.service';
import * as TER from 'models/TriggerEngineRequestContent';
import { SelectionStateContainer } from 'models/AdvancedSearchDefinition';
import {AssignPathwayEventContent} from "models/TriggerEngineRequestContent";

export type BulkOperationRequestParams = {
    boName: string;
    actions: BulkOperationRequestAction<TER.TriggerEngineRequestContent>[];
    selectionStateContainer: SelectionStateContainer;
}

export interface BulkOperationRequestAction<V extends TER.TriggerEngineRequestContent> {
    readonly action_type: TerAction;
    readonly content: V;

    validateContent(): boolean;
}

export class BulkOperationRequestSendTimelineMessage implements BulkOperationRequestAction<TER.SendTimelineMessageContent>
{
    public readonly action_type: TerAction = 'sendMessage';
    public readonly content: TER.SendTimelineMessageContent;

    constructor(content: TER.SendTimelineMessageContent) {
        this.content = content;
    }

    public validateContent(): boolean {
        return true;
    }
}

export class BulkOperationRequestSendSms implements BulkOperationRequestAction<TER.SendSmsContent> {
    public readonly action_type: TerAction = 'sendSms';
    public readonly content: TER.SendSmsContent;

    constructor(content: TER.SendSmsContent) {
        this.content = content;
    }

    public validateContent(): boolean {
        return true;
    }
}

export class BulkOperationRequestCreateResource implements BulkOperationRequestAction<TER.AllocateResourceWithMessage> {
    public readonly action_type: TerAction = 'allocateResourceWithMessage';
    public readonly content: TER.AllocateResourceWithMessage;

    constructor(content: TER.AllocateResourceWithMessage) {
        this.content = content;
    }

    public validateContent(): boolean {
        return true;
    }
}

export class BulkOperationRequestSendQuestionnaire implements BulkOperationRequestAction<TER.SendQuestionnaireContent> {
    public readonly action_type: TerAction = 'assignQuestionnaire';
    public readonly content: TER.SendQuestionnaireContent;

    constructor(content: TER.SendQuestionnaireContent)
    {
        this.content = content;
    }

    public validateContent(): boolean
    {
        return true;
    }
}

export class BulkOperationRequestSendSimpleQuestionnaire implements BulkOperationRequestAction<TER.SendSimpleQuestionnaireContent> {
    public readonly action_type: TerAction = 'assignSimpleQuestionnaire';
    public readonly content: TER.SendSimpleQuestionnaireContent;

    constructor(content: TER.SendSimpleQuestionnaireContent) {
        this.content = content;
    }

    public validateContent(): boolean {
        return true;
    }
}

export class BulkOperationRequestSendEmail implements BulkOperationRequestAction<TER.SendEmailContent> {
    public readonly action_type: TerAction = 'sendEmail';
    public readonly content: TER.SendEmailContent;

    constructor(content: TER.SendEmailContent) {
        this.content = content;
    }

    public validateContent(): boolean {
        return true;
    }
}

export class BulkOperationRequestSendPushNotification implements BulkOperationRequestAction<TER.SendPushNotificationContent>
{
    public readonly action_type: TerAction = 'sendPushNotification';
    public readonly content: TER.SendPushNotificationContent;

    constructor(content: TER.SendPushNotificationContent) {
        this.content = content;
    }

    public validateContent(): boolean {
        return TER.validatePushNotificationContent(this.content);
    }
}

export class BulkOperationRequestSendLetter implements BulkOperationRequestAction<TER.SendLettersContent> {
    public readonly action_type: TerAction = 'sendHtmlLetter';
    public readonly content: TER.SendLettersContent;

    constructor(content: TER.SendLettersContent) {
        this.content = content;
    }

    public validateContent(): boolean {
        return true;
    }
}

export class BulkOperationRequestAddPifu implements BulkOperationRequestAction<TER.AddPifuContent> {
    public readonly action_type: TerAction = 'pifu';
    public readonly content: TER.AddPifuContent;

    constructor(content: TER.AddPifuContent)
    {
        this.content = content;
    }

    public validateContent(): boolean
    {
        return !!this.content.name;
    }
}

export class BulkOperationRequestAutoCommunication implements BulkOperationRequestAction<TER.AutoCommunicationContent> {
    public readonly action_type: TerAction = 'autoCommunication';
    public readonly content: TER.AutoCommunicationContent;

    constructor(content: TER.AutoCommunicationContent)
    {
        this.content = content;
    }

    public validateContent(): boolean
    {
        return this.content.emailIfRegistered !== undefined ||
            this.content.emailIfUnregistered !== undefined ||
            this.content.sms !== undefined ||
            this.content.letter !== undefined;
    }
}

export class BulkOperationSendResourceCollection implements BulkOperationRequestAction<TER.AutoCommunicationContent> {
    public readonly action_type: TerAction = 'assignTeamResources';
    public readonly content: TER.SendResourceCollectionContent;

    constructor(content: TER.SendResourceCollectionContent)
    {
        this.content = content;
    }

    public validateContent(): boolean
    {
        return this.content.resourceCategoryUuids.length > 0;
    }
}

export class BulkOperationAssignPathwayEvent implements BulkOperationRequestAction<TER.AssignPathwayEventContent> {
    public readonly action_type: TerAction = 'assignPathwayEvent';
    public readonly content: TER.AssignPathwayEventContent;

    constructor(content: TER.AssignPathwayEventContent)
    {
        this.content = content;
    }

    public validateContent(): boolean
    {
        return this.content.pathwayEvent.length > 0;
    }
}