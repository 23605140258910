import { SortOrder } from 'common/ui/grid';
import { Filter } from 'common/ui/grid/paginatedTableReducer';
import React, { useCallback, useState } from 'react';
import { ResourcesSearchQueryResultsWrapper, useResourcesSearchResults } from '../ResourcesSearchResults/useResourcesSearchResults';
import { availablePerPageValues } from '../ResourcesSearchResultsPagination/ResourcesSearchResultsPagination';
import { ResourcesSearchCriteria } from '../SearchResourcesComponent/SearchResourcesComponent';
import _ from 'lodash';

export interface ResourcesSearchQueryResultsManager {
    error: boolean;
    loading: boolean;
    queryResults: any[];
    totalResources: number;
    currentPage: number;
    setCurrentPage: (newCurrentPageVal: number) => void;
    perPage: number;
    setPerPage: (newPerPageVal: number) => void;
    sortState: Record<string, SortOrder>;
    setSortState: (newSortState: Record<string, SortOrder>) => void;
    filterState: Filter;
    setFilter: (newFilter: Filter) => void;
    onRefresh: () => void;
}

const loadingResponse: ResourcesSearchQueryResultsWrapper = {
    loading: true,
    results: null,
    error: false,
};

const resourcesSearchPerPageSetting = 'resources-search-per-page';

const getStoredPerPageValue = (): number => {
    const perPageValue: string = localStorage.getItem(resourcesSearchPerPageSetting);
    return parseInt(perPageValue) || availablePerPageValues[0];
};

const initialSortState = {
    'result.content.updated_at': SortOrder.ASC,
};
const initialFilterState: Filter = {};

export function useResourcesSearchResultManager(searchCriteria: ResourcesSearchCriteria): ResourcesSearchQueryResultsManager {
    const [queryResults, setQueryResults] = useState<ResourcesSearchQueryResultsWrapper>(loadingResponse);
    const [perPage, setPerPage] = useState<number>(getStoredPerPageValue());
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortState, setSortState] = useState<Record<string, SortOrder>>(initialSortState);
    const [filterState, setFilterState] = useState<Filter>(initialFilterState);
    const [refreshRequestedAt, setRefreshRequestedAt] = useState<Date>(null);

    const onRefresh = useCallback(() => {
        setRefreshRequestedAt(new Date());
    }, []);

    useResourcesSearchResults(searchCriteria, perPage, currentPage, sortState, setQueryResults, refreshRequestedAt);

    const updateFilterState = useCallback((newState: Filter) => {
        if (_.isEmpty(newState)) {
            setFilterState(initialFilterState);
        }
        else {
            setFilterState(newState);
        }
    }, []);

    const onPerPageChange = useCallback((newPerPageVal: number) => {
        localStorage.setItem(resourcesSearchPerPageSetting, newPerPageVal.toString());
        setPerPage(newPerPageVal);
    }, []);

    const usePageReset = <V extends unknown>(fn: (newValue?: V) => void): (newValue?: V) => void => useCallback(newValue => {
        setCurrentPage(1);
        fn(newValue);
    }, [fn]);

    const hasResult = !queryResults.loading && !queryResults.error;

    return {
        error: queryResults.error,
        loading: queryResults.loading,
        queryResults: hasResult && queryResults.results.resultsPageData,
        totalResources: hasResult && queryResults.results.totalResultCount,
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        perPage: perPage,
        setPerPage: usePageReset(onPerPageChange),
        sortState: sortState,
        setSortState: usePageReset(setSortState),
        filterState: filterState,
        setFilter: usePageReset(updateFilterState),
        onRefresh: onRefresh
    };
}
