import React from 'react';
import {Row, Column} from 'ui';

import Referral from './timeline-archetypes/referral';
import Appointment from './timeline-archetypes/appointment';
import CoopQuestionnaire from './timeline-archetypes/coop-questionnaire';
import Questionnaire from './timeline-archetypes/questionnaire';
import Message from './timeline-archetypes/message';
import Goal from './timeline-archetypes/goal';

/**
 * Mapping of archetype names to their corresponding display component.
 */
const archetypeComponents = {
    'appointment': Appointment,
    'coopQuestionnaireResponse': CoopQuestionnaire,
    'questionnaireResponse': Questionnaire,
    'message': Message,
    'referral': Referral,
    'user-goal': Goal
};

/**
 * Displays a single composition on a MyPathway timeline.
 * Props:
 * - composition = Required. An object containing the composition content.
 * - folderLink = Required. Base URL for the patient's folder in the portal. This is used to construct links to specific archetypes.
 * - compositionQueryString = Query string to add to composition url
 */
const TimelineComposition = ({composition, folderLink, compositionQueryString}) => {

    if (!composition) {
        console.warn('Skipping empty composition object in timeline display.');
        return (<noscript />);
    }

    const ArchetypeComponent = archetypeComponents[composition.document_type] || undefined;
    if (!ArchetypeComponent) {
        console.warn('Skipping unrecognised archetype in timeline: ' + composition.document_type);
        return (<noscript />);
    }

    return (
        <div className="composition">
            <Row>
                <Column md="12">
                    <ArchetypeComponent
                        compositionQueryString={compositionQueryString}
                        composition={composition}
                        folderLink={folderLink}
                        />
                </Column>
            </Row>
        </div>
    )
};

export default TimelineComposition;
