import { useCallback, useEffect, useState } from 'react';
import { defaultFilter } from './helpers';

export interface UseTableFilterProps<T> {
    items: T[];
    filters?: Record<string, (filterBy: any, filterString: string) => boolean>;
    selectors?: Record<string, (item: T) => any>;
    defaultFilterHandler?: (filterBy: any, filterString: string) => boolean;
}
export interface UseTableFilterReturn<T> {
    onFilter: (filterState: Record<string, string>) => void;
    filteredItems: T[];
    filterState: Record<string, string>;
}
const nope = {};
export const useTableFilter = <Type extends {}>(props: UseTableFilterProps<Type>): UseTableFilterReturn<Type> => {
    const {
        items,
        filters = nope,
        selectors = nope,
        defaultFilterHandler = defaultFilter,
    } = props;
    const [filteredItems, setFilteredItems] = useState(items || []);
    const [filterState, setFilterState] = useState<Record<string, string>>({});

    const onFilter = useCallback((newFilterState: Record<string, string>) => {
        setFilterState(newFilterState);
    }, []);

    useEffect(() => {
        const filterStrings: [string, string][] = Object.entries(filterState).filter(([, filterString]) => filterString);
        const newFilteredItems = items.filter((item) => {
            return filterStrings.every(([key, value]) => {
                const selector = selectors[key] || ((ob) => ob[key]);
                const filter = filters[key] || defaultFilterHandler;
                return filter(selector(item), value);
            });
        });
        setFilteredItems(newFilteredItems);
    }, [defaultFilterHandler, filterState, filters, items, selectors]);

    return { onFilter, filteredItems, filterState };
};
