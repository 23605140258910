import * as lodash from 'lodash';
import { CompositionService } from './composition.service';
import { IRole } from 'phr-api-client/index';
import { Demographics } from 'models/compositions/Demographics';
import { Composition } from 'models/Composition';

class DemographicsService extends CompositionService {

    get archetypeName(): string {
        return 'demographics';
    }

    getFirst({ role, folderId }: {role?: IRole; folderId?: number}): Promise<Composition<Demographics>> {
        return this.list({ role, folderId })
            .then((response) => {
                return lodash.get(response, 'message.results[0]', null);
            });
    }
}

export const demographicsService = new DemographicsService();
