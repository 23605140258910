import { isEqual } from 'lodash';
import React, { useContext, useEffect, useState, useCallback } from 'react';

import { teamPreferencesService } from 'services/team-preferences.service';
import { TeamPreferencesContext } from './TeamPreferencesContext';
import { useAPI } from './useAPI';

export const TopTeamPreferencesContext = React.createContext();

export const TopTeamPreferencesComponent = (props) => {
    const [prefs, setPrefs] = useState({
        isDefaultRoutes: true,
        portal: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            routes_enabled: ['my-team', 'my-team/patients', 'worklists', 'user-feedback'],
        }
    });

    const setTeamPreferences = useCallback((teamPreferences) => {
        if (!isEqual(prefs, teamPreferences))
            setPrefs(teamPreferences)
    }, [prefs]);

    return (
        <TopTeamPreferencesContext.Provider value={{ prefs, setPrefs: setTeamPreferences }}>
            {props.children}
        </TopTeamPreferencesContext.Provider>
    );
};

/**
 * @param  {any} children
 */
export function TeamPreferences({ children, team }) {
    const { setPrefs, prefs } = useContext(TopTeamPreferencesContext);
    const { version } = useAPI();


    const setTeamPreferences = useCallback((teamPreferences) => {
        if (!isEqual(prefs, teamPreferences))
            setPrefs(teamPreferences)
    }, [prefs]);


    useEffect(() => {
        if (!team) {
            return setTeamPreferences(teamPreferencesService.defaultProperties);
        }

        teamPreferencesService.getFirst({}).then((teamPreferences) => {
            setTeamPreferences(teamPreferences);
        });

    }, [team, setPrefs, version]);

    return (
        <TeamPreferencesContext.Provider
            value={prefs}
        >
            {children}
        </TeamPreferencesContext.Provider>
    );
}
