// https://bitbucket.org/adihealth/private-server/src/master/resources/schemas/archetypes/definitions/labels.json
export interface Label {
    context?: string;
    event_uuid?: string;
    name: string;
    referral_uuid?: string;
    status: boolean;
    type: string;
}

export function addOrReplaceLabel(labels: Label[], newLabel: Label, findFn: (label: Label) => boolean) {
    const labelIndex = labels.findIndex(findFn);
    if(labelIndex !== -1) {
        labels.splice(labelIndex, 1, newLabel);
    } else {
        labels.push(newLabel);
    }
}
