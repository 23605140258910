import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';
import _ from 'services/i18n';
import parse from 'csv-parse/lib/sync';

export default class extends BaseComponent {
    
    /**
     * 
     * @param props
     */
    constructor (props) {
        super(props);

        this.state = {
            filename: '',
            results: [],
            error: '',
            errorList: [],
            invalidFile: true
        };

        this.bindEvents([
            'ChangeFile',
            'InternalUpload'
        ]);

        this.bindFunctions([
            'validate'
        ]);

        this.View = View;

        this.fileReaderString = new FileReader();
        this.fileReaderString.onload = this.validate;
    }

    /**
     * 
     * @param e
     */
    validate (e) {
        let result;

        try {
            result = parse(e.target.result, {
                columns: true,
                skip_empty_lines: true,
                trim: true
            });

            this.setState({
                results: result,
                invalidFile: false,
                filename: this.state.filename + ' (' + result.length + ' records)',
                errorList: [],
                error: ''
            });
        } catch (e) {
            this.setState({
                invalidFile: true,
                errorList: [],
                error: 'Not a valid CSV file'
            });
        }
    }

    /**
     * 
     * @param e
     */
    handleChangeFile (e) {
        let fileElement = e.target, newState = {};

        if (fileElement.files.length == 0) {
            return; //no file selected
        }

        this.setState({
            error: '',
            errorList: [],
            invalidFile: false
        }, () => {
            newState = {
                error: '',
                filename: fileElement.files[0].name
            };

            // type doesnt work
            //if (fileElement.files[0].type == '') {
              //  newState.error = _`Not a JSON file`;
            //}
            if (fileElement.files[0].size > 500000) {
                newState.error = _`File too large. 500kb max`;
            }
            else {
                this.fileReaderString.readAsText(fileElement.files[0]);
            }

            if(newState.error != ''){
                newState.invalidFile = true;
            }

            this.setState(newState);
        });
    }

    /**
     * 
     */
    handleInternalUpload () {
        this.props.onUpload(this.state.results);
        this.props.onClose();
    }
}
