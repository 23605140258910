const YMLSchema = [
    {
        'type': 'email',
        'description': 'Content for email',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'subject': {
                'description': 'Email subject',
                'type': 'text'
            },
            'txt_content': {
                'description': 'Email text',
                'type': 'text'
            },
            'html_content': {
                'description': 'Email text as HTML',
                'type': 'html'
            }
        },
        'required': ['name', 'description', 'content_type', 'subject', 'txt_content', 'html_content'],
        'order': ['name', 'description', 'content_type', 'subject', 'txt_content', 'html_content'],
        'tags': [
            '~~FULL_NAME_WITH_TITLE_OR_PATIENT~~',
            '~~FULL_NAME_WITHOUT_TITLE~~',
            '~~FULL_NAME_WITH_TITLE~~',
            '~~GIVEN_NAME~~',
            '~~MYPATHWAY_APP_URL_TEXT~~',
            '~~MYPATHWAY_APP_URL_HTML~~',
            '~~CONFIRMATION_LINK~~',
            '~~IMAGE:path/to/file.jpg~~',
            '~~INVITATION_LINK~~',
            '~~FOLDER_ID~~',
            '~~APPOINTMENT_DATE~~',
            '~~APPOINTMENT_TIME_12HR~~',
            '~~APPOINTMENT_TIME_24HR~~',
            '~~APPOINTMENT_DEPARTMENT~~',
            '~~INTRO_LOCATION_DATE_TIME~~',
            '~~REFERENCE~~',
            '~~PATIENT_CONTACTS_TXT~~',
            '~~DEPARTMENT~~',
            '~~VIDEO_APPOINTMENT_LINK~~',
            '~~REFERRAL_RECEIPT_DATE~~',
            '~~APPOINTMENT_TYPE~~',
            '~~APPOINTMENT_LOCATION~~',
            '~~APPOINTMENT_ADDRESS~~',
            '~~APPOINTMENT_TEL_NUMBER~~',
            '~~APPOINTMENT_SITE_MAP~~',
            '~~APPOINTMENT_SITE_MAP_LOCATION~~',
            '~~APPOINTMENT_GOOGLE_MAP~~',
            '~~NHS_NUMBER~~'
        ]
    },
    {
        'type': 'message',
        'description': 'Content for message',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'title': {
                'description': 'Message title',
                'type': 'text'
            },
            'lozenge_content': {
                'description': 'Message lozenge text',
                'type': 'text'
            },
            'popup_content': {
                'description': 'Message popup text',
                'type': 'text'
            }
        },
        'required': ['name', 'description', 'content_type', 'title', 'lozenge_content', 'popup_content'],
        'order': ['name', 'description', 'content_type', 'title', 'lozenge_content', 'popup_content'],
        'tags': [
            '~~GIVEN_NAME~~',
            '~~CONFIDENCE_AREA_CAPITALS~~',
            '~~CONFIDENCE_AREA_LOWER_CASE~~',
            '~~LIST_OF_AREAS_OF_FOCUS~~',
            '~~CATEGORY_BLURBS~~',
            '~~ALCOHOL_SCORE_VALUE~~',
            '~~RISK_CATEGORY_VALUE~~',
            '~~PAM_SCORE_VALUE~~',
            '~~LEVEL_VALUE~~',
            '~~LEVEL_TITLE~~'
        ]
    },
    {
        'type': 'questionnaire-timeline',
        'description': 'Content for questionnaire timeline',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'lozenge_title': {
                'description': 'Questionnaire lozenge title',
                'type': 'text'
            },
            'lozenge_content': {
                'description': 'Questionnaire lozenge content',
                'type': 'text'
            },
            'popup_title': {
                'description': 'Questionnaire popup title',
                'type': 'text'
            },
            'popup_content': {
                'description': 'Questionnaire popup content',
                'type': 'text'
            }
        },
        'required': ['name', 'description', 'content_type', 'lozenge_title', 'lozenge_content', 'popup_title', 'popup_content'],
        'order': ['name', 'description', 'content_type', 'lozenge_title', 'lozenge_content', 'popup_title', 'popup_content'],
        'tags': [
            '~~FULL_NAME_WITHOUT_TITLE~~',
            '~~GIVEN_NAME~~',
            '~~QUESTIONNAIRE_NAME~~'
        ]
    },
    {
        'type': 'questionnaire-score',
        'description': 'Content for scoring description',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'score_description': {
                'description': 'Score description',
                'type': 'html'
            }
        },
        'required': ['name', 'description', 'content_type', 'score_description'],
        'order': ['name', 'description', 'content_type', 'score_description'],
        'tags': []
    },
    {
        'type': 'sms',
        'description': 'Content for sms',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'message': {
                'description': 'SMS text',
                'type': 'text'
            }
        },
        'required': ['name', 'description', 'content_type', 'message'],
        'order': ['name', 'description', 'content_type', 'message'],
        'tags': [
            '~~FULL_NAME_WITHOUT_TITLE~~',
            '~~FULL_NAME_WITH_TITLE~~',
            '~~FULL_NAME_WITH_TITLE_OR_PATIENT~~',
            '~~NHS_NUMBER~~',
            '~~INVITATION_LINK~~',
            '~~INVITATION_SHORT_LINK~~'
        ]
    },
    {
        'type': 'key-value',
        'description': 'Content for key-value table',
        'properties': {
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'random_key': {
                'description': 'Several key-value pairs',
                'type': 'text'
            }
        },
        'required': ['content_type', 'description'],
        'order': ['content_type', 'description', 'random_key'],
        'tags': []
    },
    {
        'type': 'push-notification',
        'description': 'Content for push notification',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'title': {
                'description': 'Push notification text',
                'type': 'input'
            },
            'body': {
                'description': 'Push notification body',
                'type': 'text'
            }
        },
        'required': ['name', 'description', 'content_type', 'title', 'body'],
        'order': ['name', 'description', 'content_type', 'title', 'body'],
        'tags': []
    },
    {
        'type': 'resource',
        'description': 'Content for resource',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'label': {
                'description': 'Resource name',
                'type': 'input'
            },
            'url': {
                'description': 'Resource URL',
                'type': 'input'
            },
            'type': {
                'description': 'Resource type',
                'enum': [
                    'media_resource',
                    'audio',
                    'phone',
                    'pdf',
                    'url',
                    'video',
                    'html'
                ]
            },
            'resource_description': {
                'description': 'Resource Description',
                'type': 'text'
            },
            'phone': {
                'description': 'Resource phone',
                'type': 'input'
            },
            'category': {
                'description': 'Resource category',
                'type': 'input'
            },
            'html_content': {
                'description': 'Resource html content',
                'type': 'input'
            }
        },
        'required': ['name', 'description', 'content_type', 'label', 'type', 'resource_description'],
        'order': ['name', 'description', 'content_type', 'label',  'url', 'type', 'resource_description', 'phone', 'category', 'html_content'],
        'tags': []
    },
    {
        'type': 'goal',
        'description': 'Content for goal',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'title': {
                'description': 'Goal title',
                'type': 'input'
            },
            'lozenge_content': {
                'description': 'Goal lozenge text',
                'type': 'text'
            },
            'popup_content': {
                'description': 'Goal popup text',
                'type': 'text'
            },
            'lozenge_title': {
                'description': 'Goal lozenge title',
                'type': 'text'
            },
            'popup_title': {
                'description': 'Goal popup title',
                'type': 'text'
            },
            'GOAL_TITLE': {
                'description': 'Goal main text',
                'type': 'text'
            }
        },
        'required': ['name', 'description', 'content_type', 'title', 'lozenge_content', 'popup_content', 'lozenge_title', 'popup_title', 'GOAL_TITLE'],
        'order': ['name', 'description', 'content_type', 'title', 'lozenge_content', 'popup_content', 'lozenge_title', 'popup_title', 'GOAL_TITLE'],
        'tags': [
            '~~GOAL_TITLE~~',
            '~~CONFIDENCE_AREA~~',
            '~~CONFIDENCE_ANSWER~~',
            '~~GOAL_CATEGORY~~',
            '~~GOAL_CATEGORY_ID~~'
        ]
    },
    {
        'type': 'timeline-content',
        'description': 'Content for timeline',
        'properties': {
            'name': {
                'description': 'Content name',
                'type': 'input'
            },
            'description': {
                'description': 'Content description',
                'type': 'text'
            },
            'content_type': {
                'description': 'Content type',
                'enum': [
                    'email',
                    'message',
                    'sms',
                    'goal',
                    'resource',
                    'push-notification',
                    'key-value',
                    'questionnaire-score',
                    'questionnaire-timeline',
                    'timeline-content'
                ]
            },
            'lozenge_content': {
                'description': 'Timeline lozenge text',
                'type': 'text'
            },
            'popup_content': {
                'description': 'Timeline popup text',
                'type': 'text'
            },
            'lozenge_title': {
                'description': 'Timeline lozenge title',
                'type': 'text'
            },
            'popup_title': {
                'description': 'Timeline popup title',
                'type': 'text'
            },
            'lozenge_footer': {
                'description': 'Timeline lozenge footer',
                'type': 'text'
            }
        },
        'required': ['name', 'description', 'content_type', 'lozenge_content', 'popup_content', 'lozenge_title', 'popup_title'],
        'order': ['name', 'description', 'content_type', 'lozenge_content', 'popup_content', 'lozenge_title', 'popup_title', 'lozenge_footer'],
        'tags': [
            '~~DEPARTMENT_TELL_NUMBER~~',
            '~~DEPARTMENT~~',
            '~~APPOINTMENT_TIME_24HR~~',
            '~~APPOINTMENT_DATE~~'
        ]
    }
];

export default YMLSchema;

