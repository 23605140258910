import React from 'react';
import PropTypes from 'prop-types';
import DataStore from 'services/data-store';

export const AdminSidebar = ({
    currentTeam,
    currentOrg,
}) => {
    const handleClick = () => {
        DataStore.set('me.admin', {});
        DataStore.set('me.currentOrg', {});
        DataStore.set('me.currentTeam', {});
    };

    if (currentTeam.teamId) {
        return <div className="team-name" onClick={handleClick} title={currentTeam.teamName}>
            Team: {currentTeam.teamName}
        </div>;
    }

    return <div className="team-name organisation-highlight" onClick={handleClick}>
        Org: {currentOrg.name}
    </div>;
};

AdminSidebar.propTypes = {
    currentTeam: PropTypes.object,
    currentOrg: PropTypes.object,
};
