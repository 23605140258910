import React from 'react';
import _ from 'services/i18n';

import { BulkOperationReviewProps } from './models/bulkOperationHistoryModels';
import AutoCommunicationSmsReview from "pages/clinical_portal/bulk-operations/autoCommunication/autoSmsReview";
import AutoCommunicationEmailReview from "pages/clinical_portal/bulk-operations/autoCommunication/autoEmailReview";
import AutoCommunicationLetterReview from "pages/clinical_portal/bulk-operations/autoCommunication/autoLetterReview";
import {Row, Column} from "ui";

const BulkOperationAutoCommunicationReview = ({ bulkOperationData, bulkOperationAction }: BulkOperationReviewProps): JSX.Element => {

    return (
        <div className="action-preview-body">
            
            {'emailIfRegistered' in bulkOperationAction.content ? (
                <Row className="action-preview-container">
                    <Column sm={12} className="action-preview-component">
                        <AutoCommunicationEmailReview
                            title={_`Email - Registered Patient`}
                            subject={bulkOperationAction.content.emailIfRegistered.subject}
                            htmlContent={bulkOperationAction.content.emailIfRegistered.htmlContent}/>
                    </Column>
                </Row>
            ) : null}

            {'emailIfUnregistered' in bulkOperationAction.content ? (
                <Row className="action-preview-container">
                    <Column sm={12} className="action-preview-component">
                        <AutoCommunicationEmailReview
                            title={_`Email - Non Registered Patient`}
                            subject={bulkOperationAction.content.emailIfRegistered.subject}
                            htmlContent={bulkOperationAction.content.emailIfRegistered.htmlContent}/>
                    </Column>
                </Row>
            ) : null}

            {'sms' in bulkOperationAction.content ? (
                <Row className="action-preview-container">
                    <Column sm={12} className="action-preview-component">
                        <AutoCommunicationSmsReview message={bulkOperationAction.content.sms.message}/>
                    </Column>
                </Row>
            ) : null}

            {'letter' in bulkOperationAction.content ? (
                <Row className="action-preview-container">
                    <Column sm={12} className="action-preview-component">
                        <AutoCommunicationLetterReview pages={bulkOperationAction.content.letter.pages}/>
                    </Column>
                </Row>
            ) : null}

        </div>
    );
};

export default BulkOperationAutoCommunicationReview;
