import React, { useState, Fragment } from 'react';
import {
    useFolderSelectionState,
    useSelectionStateContext
} from 'components/dataSource/FolderSelectionState';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationSetupModal, BulkOperationPreviewModal } from './BulkOperationSetup';
import { useEnabledBulkOperations } from 'components/bulk-operations/BulkOperationPreferences';
import { SelectionStateContainer } from 'models/AdvancedSearchDefinition';

type BulkOperationButtonProps = {
    actionComponent: BulkOperationConfigurationComponent<unknown>;
    selectionStateContainer: SelectionStateContainer;
    queryName: string;
    queryResultsLastUpdated;
};

enum BulkOperationSetupDisplayMode {
    NONE, SETUP, PREVIEW
}

export function BulkOperationSetupButton(props: BulkOperationButtonProps) {
    const [displayMode, onChangeDisplayMode] = useState<BulkOperationSetupDisplayMode>(BulkOperationSetupDisplayMode.NONE);
    const actionName: string = props.actionComponent.getActionName();

    const onCloseAll = () => {
        props.actionComponent.reset();
        onChangeDisplayMode(BulkOperationSetupDisplayMode.NONE);
    };

    return (
        <Fragment>
            <a
                href="#"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => onChangeDisplayMode(BulkOperationSetupDisplayMode.SETUP)}>
                {actionName}
            </a>

            { (displayMode == BulkOperationSetupDisplayMode.SETUP) && (
                <BulkOperationSetupModal
                    actionComponent={props.actionComponent}
                    actionName={actionName}
                    onClose={onCloseAll}
                    onPreview={() => { onChangeDisplayMode(BulkOperationSetupDisplayMode.PREVIEW); }}
                />

            ) }

            { (displayMode == BulkOperationSetupDisplayMode.PREVIEW) && (

                <BulkOperationPreviewModal
                    actionPreviewComponent={props.actionComponent.getPreviewBuilder(props.selectionStateContainer.selectionState)}
                    actionName={actionName}
                    onClosePreview={() => { onChangeDisplayMode(BulkOperationSetupDisplayMode.SETUP); }}
                    onCloseAll={onCloseAll}
                    selectionStateContainer={props.selectionStateContainer}
                    queryName={props.queryName}
                    queryResultsLastUpdated={props.queryResultsLastUpdated}
                />
            ) }
        </Fragment>
    );
}

export function BulkOperationList(props: {
    queryName: string;
    queryResultsLastUpdated;
}): JSX.Element {
    const bulkActions: BulkOperationConfigurationComponent<unknown>[] = useEnabledBulkOperations();
    const selectionStateContainer = useSelectionStateContext();
    const folderSelectionStateRepresentation = useFolderSelectionState(selectionStateContainer.selectionState);
    const actionsEnabled = folderSelectionStateRepresentation.hasSelection && bulkActions.length > 0;
    const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

    const dropdownToggle = (): void => {
        setDropdownOpened(!dropdownOpened);
    };

    const dropdownClose = (): void => {
        setDropdownOpened(false);
    };

    return (
        <div className={`btn-split-dropdown btn-group ${dropdownOpened ? 'open' : ''}`}  >
            <button
                type="button"
                disabled={!actionsEnabled}
                onBlur={dropdownClose}
                onClick={dropdownToggle}
                className={'btn dropdown-toggle btn btn-xs btn-primary btn_patient-filter--new'}
            >
                Bulk operations
            </button>
            <button
                type="button"
                className={'btn dropdown-toggle btn btn-xs btn-primary'}
                disabled={!actionsEnabled}
                onBlur={dropdownClose}
                onClick={dropdownToggle}>
                <span className="caret"/>
                <span className="sr-only">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu" onMouseUp={dropdownClose}>
                { bulkActions.map((component: BulkOperationConfigurationComponent<unknown>, index: number) => (
                    <li key={index}>
                        <BulkOperationSetupButton
                            actionComponent={component}
                            selectionStateContainer={selectionStateContainer}
                            queryName={props.queryName}
                            queryResultsLastUpdated={props.queryResultsLastUpdated}
                        />
                    </li>
                )) }
            </ul>
        </div>
    );
}
