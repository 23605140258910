import React, { Fragment } from 'react';

import SmsPreview from "components/user/sms/create/preview";

const AutoCommunicationSmsReview = ({message}: { message: string }) => {
    return (
        <Fragment>
            <h4>SMS</h4>
            <SmsPreview
                message={message}
            />
        </Fragment>
    );
};

export default AutoCommunicationSmsReview;