import { useContentVariables } from 'common/useContentVariables';
import React, { FC, useState } from 'react';
import Select from 'react-select';
import { getDisplayValueForValueOption } from '../dropdown/DropdownUtils';

interface EditorTagsProps {
    callback: (val: string) => void;
}

export const EditorTags: FC<EditorTagsProps> = ({ callback }) => {
    const [value, setValue] = useState<string>(null);
    const variables = useContentVariables();

    const optionList = variables.map((option) => {
        return { label: option.variable, value: option.variable };
    });

    const valueWithDefault = getDisplayValueForValueOption(
        optionList,
        value,
    );

    const handleOnChange = (selectedOption) => {
        setValue(selectedOption);
        if (selectedOption) {
            callback(selectedOption);
        }
    };

    return (
        <div className={'form-inline'}>
            <Select
                value={valueWithDefault}
                options={optionList}
                onChange={(option) =>
                    handleOnChange(
                        option ? option.value : null,
                    )
                }
                className={'react-select-input-container'}
                classNamePrefix={'react-select-input'}
                placeholder={'-- Unselected --'}
                isClearable={true}
            />
        </div>
    );
};
