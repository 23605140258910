import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AdvancedSearchDocumentConfig,
    AdvancedSearchQueryCriterion,
    AdvancedSearchQueryCriterionValueDefinition,
    AdvancedSearchQueryDefinition,
    AdvancedSearchDefinition,
    canBeRootDocument,
    getMandatoryOutputs,
    getDocumentSearchConfig,
    getChildDocumentSearchConfigs,
    AdvancedSearchQueryJoinMode
} from 'models/AdvancedSearchDefinition';

/**
 * N.B. You must define getProperty and setProperty as functions that won't be created all the time
 *  - either with useCallback, or by defining them as independent functions.
 */
function useProperty<S, P>(
    source: S,
    getProperty: (source: S) => P,
    setProperty: (source: S, newValue: P) => void
): [propertyValue: P, updateProperty: (newPropertyValue: P) => void] {

    const [propertyState, setPropertyState] = useState<P>(getProperty(source));

    const getPropertyCallback = useCallback(() => getProperty(source), [source, getProperty]);
    const setPropertyCallback = useCallback((newValue: P) => setProperty(source, newValue), [source, setProperty]);

    //Now subscribe to the source object (via its callback function), so if the source object changes
    // we know in here that we need to re-display.
    useEffect(() => {
        const newPropertyValue = getPropertyCallback();
        setPropertyState(newPropertyValue);
    }, [getPropertyCallback]);

    const updateProperty = useCallback((newValue: P) => {
        setPropertyCallback(newValue);
        setPropertyState(newValue);
    }, [setPropertyCallback]);

    return [propertyState, updateProperty];
}

/**
 * N.B. You must define getProperty and setProperty as functions that won't be created all the time
 *  - either with useCallback, or by defining them as independent functions.
 */
function useArrayProperty<S, P>(
    source: S,
    getProperty: (source: S) => P[],
    setProperty: (source: S, newValue: P[]) => void
): [
    propertyValue: P[],
    addElement: (newElement: P) => void,
    removeElement: (toRemove: number) => P,
    moveElement: (toMove: number, places: number) => void
] {
    const [property, updateProperty] = useProperty<S, P[]>(source, getProperty, setProperty);

    const addElement = (newElement: P) => {
        updateProperty([...property, newElement]);
    };

    const removeElement = (toRemove: number) => {
        const newPropertyValue = [...property];
        const removedElements = newPropertyValue.splice(toRemove, 1);
        updateProperty(newPropertyValue);
        return removedElements[0];
    };

    const moveElement = (toMove: number, places: number) => {
        const newPropertyValue = [...property];
        const newIndex = toMove + places;
        const removedElement = newPropertyValue.splice(toMove, 1)[0];
        newPropertyValue.splice(newIndex, 0, removedElement);
        updateProperty(newPropertyValue);
    };

    return [property, addElement, removeElement, moveElement];
}

export function useAdvancedSearchCriteria(advancedSearch: AdvancedSearchQueryDefinition): [
    criteria: AdvancedSearchQueryCriterion[],
    addCriterion: (newCriterion: AdvancedSearchQueryCriterion) => void,
    removeCriterion: (index: number) => AdvancedSearchQueryCriterion
] {
    const getCriteria = useCallback((advancedSearch: AdvancedSearchQueryDefinition): AdvancedSearchQueryCriterion[] => {
        return advancedSearch.criteria ? [...advancedSearch.criteria] : [];
    }, []);

    const setCriteria = useCallback((advancedSearch: AdvancedSearchQueryDefinition, newCriteria: AdvancedSearchQueryCriterion[]) => {
        advancedSearch.criteria = newCriteria;
    }, []);

    const [
        criteria,
        addCriterion,
        removeCriterionByIndex
    ] = useArrayProperty<AdvancedSearchQueryDefinition, AdvancedSearchQueryCriterion>(advancedSearch, getCriteria, setCriteria);

    return [criteria, addCriterion, removeCriterionByIndex];
}

export function useAdvancedSearchChildren(advancedSearch: AdvancedSearchQueryDefinition): [
    children: AdvancedSearchQueryDefinition[],
    addChild: (newChild: AdvancedSearchQueryDefinition) => void,
    removeChild: (toRemove: number) => AdvancedSearchQueryDefinition,
    moveChild: (toMove: number, places: number) => void
] {
    const getChildren = useCallback((advancedSearch: AdvancedSearchQueryDefinition): AdvancedSearchQueryDefinition[] => {
        return advancedSearch.children ? [...advancedSearch.children] : [];
    }, []);

    const setChildren = useCallback((advancedSearch: AdvancedSearchQueryDefinition, newChildren: AdvancedSearchQueryDefinition[]) => {
        advancedSearch.children = newChildren;
    }, []);

    return useArrayProperty<AdvancedSearchQueryDefinition, AdvancedSearchQueryDefinition>(advancedSearch, getChildren, setChildren);
}

export function useAdvancedSearchSubqueryJoinModeSetting(advancedSearch: AdvancedSearchQueryDefinition): [
    joinMode: AdvancedSearchQueryJoinMode,
    setJoinMode: (newJoinMode: AdvancedSearchQueryJoinMode) => void
] {
    const getJoinMode = useCallback((advancedSearch: AdvancedSearchQueryDefinition) => advancedSearch.joinMode ?? 'inner-join', []);
    const setJoinMode = useCallback((advancedSearch: AdvancedSearchQueryDefinition, newJoinMode: AdvancedSearchQueryJoinMode) => advancedSearch.joinMode = newJoinMode, []);
    return useProperty(advancedSearch, getJoinMode, setJoinMode);
}

export function useAdvancedSearchSubqueryJoinModeDescriptions(
    childEntityName: string,
    parentEntityName?: string
): { label: string, value: AdvancedSearchQueryJoinMode }[] {
    const parentDisplayName = parentEntityName ? parentEntityName.toLowerCase() : 'patient';
    const childDisplayName = childEntityName.toLowerCase();
    return [
        { label: `The ${parentDisplayName} has ${childDisplayName} details with:`, value: 'inner-join' },
        { label: `The ${parentDisplayName} has no ${childDisplayName} details with:`, value: 'anti-join' },
        { label: `The ${parentDisplayName} may have ${childDisplayName} details with:`, value: 'left-outer-join' }
    ]
}

export function useAdvancedSearchOutputs(config: AdvancedSearchDocumentConfig, advancedSearch: AdvancedSearchQueryDefinition): [
    outputs: string[],
    addOutput: (newOutputPath: string) => void,
    removeOutput: (path: string) => string,
    moveOutput: (path: string, places) => void
] {
    const getOutputs = useCallback((advancedSearch: AdvancedSearchQueryDefinition): string[] => {
        return advancedSearch.outputs ? [...advancedSearch.outputs] : [];
    }, []);

    const setOutputs = useCallback((advancedSearch: AdvancedSearchQueryDefinition, newOutputs: string[]) => {
        advancedSearch.outputs = newOutputs;
    }, []);

    const [outputs, addOutput, removeOutputByIndex, moveOutputByIndex] = useArrayProperty<AdvancedSearchQueryDefinition, string>(advancedSearch, getOutputs, setOutputs);

    const filterFunction = (outputPath: string, path: string) => outputPath == path;
    const mandatoryOutputs = getMandatoryOutputs(config);
    const isMandatoryOutput = (outputPath: string) => mandatoryOutputs.find(value => value.path == outputPath) != null;

    const removeOutputByPath = (outputPath: string): string => {
        const removalIndex = outputs.findIndex(element => filterFunction(element, outputPath));
        if (removalIndex > -1
          && !isMandatoryOutput(outputPath)) {
            return removeOutputByIndex(removalIndex);
        }
        return null;
    };

    const moveOutputByPath = (path: string, places: number) => {
        const moveIndex = outputs.findIndex(element => filterFunction(element, path));
        if (moveIndex > -1) {
            moveOutputByIndex(moveIndex, places);
        }
    };

    const addUniqueOutput = (newOutput: string) => {
        if (outputs.find(output => output == newOutput) == null) {
            addOutput(newOutput);
        }
    };
    return [outputs, addUniqueOutput, removeOutputByPath, moveOutputByPath];
}

export function useAdvancedSearchQueryName(advancedSearch: AdvancedSearchDefinition): [
    queryName: string,
    updateQueryName: (newQueryName: string) => void
] {
    const getQueryName = useCallback((advancedSearch: AdvancedSearchDefinition) => advancedSearch.name, []);
    const setQueryName = useCallback((advancedSearch: AdvancedSearchDefinition, newQueryName: string) => advancedSearch.name = newQueryName, []);

    return useProperty<AdvancedSearchDefinition, string>(advancedSearch, getQueryName, setQueryName);
}

export function useRootDocumentConfigs(configs: AdvancedSearchDocumentConfig[]): AdvancedSearchDocumentConfig[] {
    return useMemo(() => configs.filter(config => canBeRootDocument(config)), [configs]);
}

export function useChildDocumentConfigs(configs: AdvancedSearchDocumentConfig[], myConfig: AdvancedSearchDocumentConfig): AdvancedSearchDocumentConfig[] {
    return useMemo(() => {
        return getChildDocumentSearchConfigs(configs, myConfig);
    }, [configs, myConfig]);
}

export function useDocumentConfig(configs: AdvancedSearchDocumentConfig[], documentType: string): AdvancedSearchDocumentConfig {
    return useMemo(() => {
        return getDocumentSearchConfig(configs, documentType);
    }, [configs, documentType]);
}

export function useCriterionComparator(criterion: AdvancedSearchQueryCriterion) {
    return useProperty(criterion,
        useCallback(criterion => criterion.comparator, []),
        useCallback((criterion, newComparator) => {
            if ( newComparator === criterion.comparator ) { return; }
            criterion.comparator = newComparator;
            resetCriterionValues(criterion);
        }, []));
}

function resetCriterionValues(criterion: AdvancedSearchQueryCriterion) {
    criterion.value = { };
}

export function useCriterionSingleValue(valueWrapper: AdvancedSearchQueryCriterionValueDefinition) {
    return useProperty(valueWrapper,
        useCallback((valueWrapper) => valueWrapper.value, []),
        useCallback((valueWrapper, newValue) => {
            valueWrapper.value = newValue;
        }, []));
}

export function useCriterionListValue(valueWrapper: AdvancedSearchQueryCriterionValueDefinition) {
    return useProperty(valueWrapper,
        useCallback(valueWrapper => valueWrapper.values, []),
        useCallback((valueWrapper, newValue) => {
            valueWrapper.values = newValue;
        }, []));
}

export function useCriterionRangeValue(valueWrapper: AdvancedSearchQueryCriterionValueDefinition): [
    from: string,
    to: string,
    setFrom: (newValue: string) => void,
    setTo: (newValue: string) => void,
    reset: () => void
] {
    const [rangeValue, setRangeValue] = useProperty(valueWrapper,
        useCallback(valueWrapper => valueWrapper.range, []),
        useCallback((valueWrapper, newValue) => {
            if (!valueWrapper.range) {
                valueWrapper.range = { from: undefined, to: undefined };
            }
            valueWrapper.range = newValue;
        }, []));

    const setFrom = (newValue: string) => setRangeValue({ ...rangeValue, from: newValue });
    const setTo = (newValue: string) => setRangeValue({ ...rangeValue, to: newValue });
    const reset = () => setRangeValue({ ...rangeValue, from: undefined, to: undefined })
    return [rangeValue?.from, rangeValue?.to, setFrom, setTo, reset];
}

export function useDateCriterionRelative(initialDate?: string, reset?: () => void): [
    boolean,
    (newValue: boolean) => void
] {
    const [isRelativeDate, setRelativeDate] = useState(initialDate ? initialDate.startsWith('relative:') : true);

    const onChange = useCallback((newValue: boolean) => {
        if (reset) {
            reset();
        }
        setRelativeDate(newValue);
    }, [reset]);
    return [ isRelativeDate, onChange ];
}

export type RelativeDateUnit = 'day' | 'week' | 'month' | 'year';

export function useDateCriterionRelativeValue(
    onChange: (newValue: string) => void,
    value?: string,
): [
    amount: number,
    setAmount: (newAmount: number) => void,
    unit: RelativeDateUnit,
    setUnit: (newUnit: RelativeDateUnit) => void,
] {
    const [, initialAmount, initialUnit] = value ? value.split(':') : [undefined, '', 'day'];
    const [ amount, setAmount ] = useState<number>(initialAmount ? parseInt(initialAmount) : undefined);
    const [ unit, setUnit ] = useState<RelativeDateUnit>(initialUnit as RelativeDateUnit);

    return [
        amount,
        (newAmount) => {
            onChange(`relative:${newAmount}:${unit}`);
            setAmount(newAmount);
        },
        unit,
        (newUnit) => {
            onChange(`relative:${amount}:${newUnit}`);
            setUnit(newUnit);
        }
    ]
}
