/* eslint-disable indent */
import React, { FC } from 'react';
// components
import {
    AdvancedSearchCriterionList,
    AdvancedSearchCriterionRange,
    AdvancedSearchCriterionSingleValue,
} from 'features/patients/advanced-search/AdvancedSearchCriteriaSelectors';
// interfaces
import { AdvancedSearchCriterionValueInputProps } from './AdvancedSearchCriterionValueInput.interface';

const AdvancedSearchCriterionValueInput: FC<AdvancedSearchCriterionValueInputProps> =
    (props) => {
        switch (props.comparator) {
            case 'BETWEEN':
                return (
                    <AdvancedSearchCriterionRange
                        config={props.config}
                        criterionValue={props.criterionValue}
                        validator={props.validator}
                        setError={props.setError}
                    />
                );
            case 'IN':
            case 'NOT_IN':
                return (
                    <div className={'advanced-search-query-request-criterion__value'}>
                        <AdvancedSearchCriterionList
                            documentType={props.documentType}
                            config={props.config}
                            criterionValue={props.criterionValue}
                            validator={props.validator}
                        />
                    </div>
                );
            default:
                return (
                    <div className={'advanced-search-query-request-criterion__value form-horizontal'}>
                        <AdvancedSearchCriterionSingleValue
                            config={props.config}
                            criterionValue={props.criterionValue}
                        />
                    </div>
                );
        }
    };

export default AdvancedSearchCriterionValueInput;
