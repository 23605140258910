import ServerFetch from 'server-fetch';
import identify from 'services/identify';
import { browserHistory } from 'react-router';
import { AUTH } from 'actions/types';
import DataStore from 'services/data-store';
import { customHttp } from 'services/customHttp.service';

export const logout = ({redirectTo} = {}) => {

    return (dispatch) => {

        return customHttp.request(`/logout`,{
            method: 'POST'
        })
            .then(() => {

                DataStore.clear();
                localStorage.setItem('isAuthenticated',false);
                dispatch({type:AUTH.LOGOUT_SUCCESSFUL});

                if (!redirectTo){
                    window.location.reload();
                    return;
                }

                browserHistory.push(redirectTo);
            });
    };
};

export const authenticate = (data = {}) => {

    return (dispatch) => {

        const {
            email,
            password,
            portal
        } = data;

        const {
            browser,
            browserVersion,
            os,
            osVersion
        } = identify();

        DataStore.clear();

        return customHttp.request(`/login`,{
            method: 'POST',
            body: {
                email,
                password,
                portal,
                model: browser + ' ' + browserVersion,
                os: os + ' ' + osVersion
            }
        })
            .then((response) => {
                if (response.status !== 'OK') {
                    dispatch({type:AUTH.AUTH_FAILED});
                    localStorage.setItem('isAuthenticated',false);
                    return false;
                }

                ServerFetch.clearLoginSession();
                localStorage.setItem('isAuthenticated',true);
                dispatch({type:AUTH.AUTH_SUCCESSFUL});
                return true;
            });
    };
};