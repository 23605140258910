import React, { useEffect, useState } from 'react';
import { ResponsiveTable } from 'ui';
import _ from 'services/i18n';
import './user-notes.less';
import { observationsService } from 'services/observations.service';
import { ObservationResultsPage } from 'pages/clinical_portal/folder/_folderId/patient/observations/observationResults';
import DataStore from 'services/data-store';
import { Dot } from 'common/ui/line-graph/LineGraph';
import moment from 'moment';
import { Composition } from 'models/Composition';
import { Observation } from 'models/compositions/Observation';

export const Observations = () => {
    const [observations, setObservations] = useState([]);
    const [currentNote, setCurrentNote] = useState(null);
    const [currentCompoundNote, setCurrentCompoundNote] = useState([]);
    const [error, setError] = useState();
    const [clickedDot, setClickedDot] = useState(null);
    const [currentObservationName, setCurrentObservationName] = useState(null);

    const folderId = DataStore.get('currentFolder');
    const teamId = (DataStore.get('me.currentRole') || {}).teamId || 0;

    useEffect(() => {
        loadNotes();
    }, []);

    useEffect(() => {
        displaySelectedNote();
    }, [clickedDot, currentObservationName]);

    const loadNotes = () => {
        observationsService.list()
            .then((response) => {
                setObservations(response.message.results);
            })
            .catch((error) => {
                setError(error);
            });
    };

    const createSimpleNote = (clickedDotTimestamp, noteName) => {
        const filteredNotes = filterNotesByTimeAndNames(clickedDot, [noteName]);
        if (filteredNotes.length === 1) {
            setCurrentNote(filteredNotes[0]);
        }
    };

    const createCompoundNote = (observationsList, clickedDot, relevantNoteNames) => {
        const compoundNote = filterNotesByTimeAndNames(clickedDot, relevantNoteNames);
        if (compoundNote[0].content.code.text === 'Heart rate') {
            const bpNoteCorrectOrder = compoundNote.sort((a,b) => (a.content.code.text > b.content.code.text) ? 1 : -1);
            setCurrentCompoundNote(bpNoteCorrectOrder);
        } else {
            setCurrentCompoundNote(compoundNote);
        }
    };

    const displaySelectedNote = () => {
        if (!clickedDot) {
            return;
        }
        cleanNoteInfo();

        if (currentObservationName === 'Blood pressure') {
            const relevantNoteNames = ['Blood pressure','Heart rate'];
            createCompoundNote(observations, clickedDot, relevantNoteNames);
        } else {
            createSimpleNote(clickedDot, currentObservationName);
        }
    };

    const filterNotesByTimeAndNames = (clickedDot, relevantNoteNames) => {
        const clickedDotTimestamp = clickedDot.x / 1000;
        return observations.filter((note) => {
            const noteTimestamp = getCurrentObservationTimestamp(note);
            const noteName = note.content.code.text;
            return noteTimestamp === clickedDotTimestamp && relevantNoteNames.includes(noteName);
        });
    };

    const getCurrentGraphDot = (dot: Dot, observationName: string) => {
        setClickedDot(dot);
        setCurrentObservationName(observationName);
    };

    const cleanNoteInfo = () => {
        setCurrentNote(null);
        setCurrentCompoundNote(null);
    };

    const dateAndTimeWithoutSeconds = (observation: Composition<Observation>) => {
        const dateTimeValue = observation.content.effectiveDateTime ?
            observation.content.effectiveDateTime :
            observation.content.effectivePeriod.end;
        return moment(dateTimeValue).format('DD-MMM-YYYY HH:mm');
    };

    return (
        <>
            <h3>Observations</h3>
            <ObservationResultsPage
                folderId={folderId}
                teamId={teamId}
                getCurrentGraphDot={getCurrentGraphDot}
                changeMeasurementCallback={cleanNoteInfo}
            />
            <ResponsiveTable className='table table-bordered table-condensed table-striped table-nomargin'>
                <thead>
                    <tr>
                        <th>{_`Measurement`}</th>
                        <th>{_`Unit`}</th>
                        <th>{_`Value`}</th>
                        <th>{_`Date/Time`}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        error && <tr>
                            <td colSpan={3}>
                                {_`Sorry, the server reported an error`}
                            </td>
                        </tr>
                    }

                    {
                        observations.length <= 0 && !error && <tr>
                            <td colSpan={3}>
                                {_`Sorry, no matching results found`}
                            </td>
                        </tr>
                    }
                    {
                        currentCompoundNote && currentCompoundNote.map((note, idx) => {
                            return 'component' in note.content ? (
                                note.content.component.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {item.code.coding[0].display}
                                            </td>
                                            <td>
                                                {item.valueQuantity.unit}
                                            </td>
                                            <td>
                                                {item.valueQuantity.value}
                                            </td>
                                            <td>
                                                {dateAndTimeWithoutSeconds(note)}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr key={idx}>
                                    <td>
                                        {note.content.code.coding[0].display}
                                    </td>
                                    <td>
                                        {note.content.valueQuantity.unit}
                                    </td>
                                    <td>
                                        {note.content.valueQuantity.value}
                                    </td>
                                    <td>
                                        {dateAndTimeWithoutSeconds(note)}
                                    </td>
                                </tr>

                            );
                        })
                    }
                    {
                        currentNote && (
                            <tr>
                                <td>
                                    {currentNote.content.code.text}
                                </td>
                                <td>
                                    {currentNote.content.valueQuantity.unit}
                                </td>
                                <td>
                                    {currentNote.content.valueQuantity.value}
                                </td>
                                <td>
                                    {dateAndTimeWithoutSeconds(currentNote)}
                                </td>
                            </tr>
                        )
                    }

                </tbody>
            </ResponsiveTable>
        </>
    );
};

export const getCurrentObservationTimestamp = (observation: Composition<Observation>): number => {
    const timestamp = observation.content.effectivePeriod ?
        observation.content.effectivePeriod.end : observation.content.effectiveDateTime;
    return Date.parse(timestamp) / 1000;
};

export default Observations;
