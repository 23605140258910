import React, { useContext, useMemo } from 'react';
import { REFERRAL_STATUS } from 'models/compositions/Referral';
import { ReferralsContext } from 'features/ReferralsProvider';

export function useActiveReferrals() {
    const {
        list,
        status: {
            isFetching,
        },
    } = useContext(ReferralsContext);

    const filteredList = useMemo(() => {
        return list.filter((referral) => {
            return referral.content.status === REFERRAL_STATUS.OPEN;
        });
    }, [list]);

    return [
        filteredList,
        isFetching
    ];
}
