import React from 'react';
import PropTypes from 'prop-types'
import SearchBox from 'common/ui/search-box';
import SideBarPanel from 'ui/side-bar-panel';
import {Link} from 'react-router'
/**
 * Renders the header-menu of the main page template
 */
class SideMenu extends React.Component {

  constructor(props){
    super(props);
    this.touchEnd = 0;
    this.touchStart = 0;
  }
  toggle(){
    this.props.toggle();
  }
  searchAction(searchPayload){
    this.props.toggle();
    this.props.searchAction(searchPayload.type, searchPayload.value);
  }
  render () {
    return (
      <SideBarPanel toggle={this.toggle.bind(this)}
                    visible={this.props.visible}
                    className="side-menu side-bar-panel_side-menu_theme"
                    title={this.props.title}>

        <SearchBox placeholder={'Find Patient...'}
                   onSearchBoxChange={this.props.onSearchBoxChange}
                   onSubmit={this.searchAction.bind(this)}
                   className={'search-box_side-menu_theme search-box_side-menu_transform'}/>


        <ul className="side-menu__navigation clearfix">
          {this.props.links.map((v,i)=>{return <li key={i}><Link to={v.link} onClick={this.toggle.bind(this)}>{v.title}</Link></li>})}
        </ul>
      </SideBarPanel>
    )
  }
}

SideMenu.propTypes = {
  onSearchBoxChange: PropTypes.func
};

export default SideMenu;
