import React, { Component } from 'react';
import { Modal } from 'ui/modal';
import _ from 'services/i18n';
import { ResponsiveTable } from 'ui';

/**
 * Create a modal that contains a lookup for teams and organisations
 */
export default class extends Component {

    /**
     * Initialise default values of lookup and bind functions
     *
     * @param {Object} props - The properties to pass in
     */
    constructor (props) {
        super(props);

        this.state = {
            teams: [],
            teamLoaded: false,
            selectedItem: null,
            selectionType: null
        };
    }

    handleSelection(selectionType, idx) {
        this.setState({
            selectedItem: idx,
            selectionType
        });
    }

    handleSelectionSubmit(selectionType, idx) {
        if (idx != null) {
            this.setState({
                selectedItem: idx,
                selectionType
            }, () => {
                const { selectionType } = this.state;
                const type = selectionType === 'team' ? 'teams' : 'organisations';
                this.props.onSubmit(selectionType, this.props[type][this.state.selectedItem]);
            });
        }
    }

    renderOrgResults() {
        let rows;
        if (!this.props.organisations || this.props.organisations.length === 0) {
            return null;
        }

        rows = this.props.organisations.map((item, idx) => {
            let selectedRow = '';

            if (idx === this.state.selectedItem && this.state.selectionType === 'organisation') {
                selectedRow = 'selected';
            }

            return (
                <tr key={idx}
                    className={selectedRow}
                    onClick={() => this.handleSelection('organisation', idx)}
                    onDoubleClick={() => this.handleSelectionSubmit('organisation', idx)}
                >
                    <td>{item.name}</td>
                </tr>
            );
        });

        return (
            <div>
                <h4>Organisations</h4>
                <ResponsiveTable className="table table-condensed-extra table-striped table-nomargin">
                    <thead>
                    <tr>
                        <th>{_`Organisation Name`}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </ResponsiveTable>
            </div>
        );
    }

    renderTeamResults() {
        let rows;

        if (!this.props.teams || this.props.teams.length < 0) {
            return null;
        }

        rows = this.props.teams.map((item, idx) => {
            let selectedRow = '';

            if (idx === this.state.selectedItem && this.state.selectionType === 'team') {
                selectedRow = 'selected';
            }

            return (
                <tr key={idx}
                    className={selectedRow}
                    onClick={() => this.handleSelection('team', idx)}
                    onDoubleClick={() => this.handleSelectionSubmit('team', idx)}
                >
                    <td>{item.organisation}</td>
                    <td>{item.team}</td>
                    <td>{item.serviceName}</td>
                </tr>
            );
        });

        return (
            <div>
                <h4>Teams</h4>
                <ResponsiveTable className="table table-condensed-extra table-striped table-nomargin">
                    <thead>
                    <tr>
                        <th>{_`Organisation`}</th>
                        <th>{_`Team`}</th>
                        <th>{_`Service`}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </ResponsiveTable>
            </div>
        );
    }

    render() {

        const { teams = [], organisations = [] } = this.props;

        let buttons = [];

        buttons.push({
            type: 'callback',
            callback: this.handleSelectionSubmit.bind(this, this.state.selectionType, this.state.selectedItem),
            label: _`Select`
        });

        const entityType = teams.length && organisations.length ? 'team or organisation' : teams.length ? 'team' : 'organisation';

        return (
                <Modal title={_`Please select the ${entityType} you wish to manage`} noClose={this.props.noClose} buttons={buttons}>
                    <div className="team-selector">
                        {this.renderOrgResults()}
                        {this.renderTeamResults()}
                    </div>
                </Modal>
        );
    }

}
