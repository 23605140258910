import React from 'react';

export const SecondsToText = ({seconds, minutes, hours, days = true, weeks = true, years = true, hideSmall = false,prefix = false, children}) => {
    let secondsLeft = [].concat(children).shift(),
        message = [];

    if (seconds) {
        if (secondsLeft % 60 !== 0) {
            message.push((secondsLeft % 60) + ' seconds');
        }
    }

    secondsLeft = Math.floor(secondsLeft / 60);

    if (minutes) {
        if (secondsLeft % 60 !== 0) {
            message.push((secondsLeft % 60) + ' minutes');
        }
    }

    secondsLeft = Math.floor(secondsLeft / 60);

    if (hours) {
        if (secondsLeft % 24 !== 0) {
            message.push((secondsLeft % 24) + ' hours');
        }
        secondsLeft = Math.floor(secondsLeft / 24);
    } else {
        secondsLeft = Math.round(secondsLeft / 24);
    }

    if (days) {
        if (secondsLeft % 7 !== 0) {
            message.push((secondsLeft % 7) + ' days');
        }
    }

    secondsLeft = Math.floor(secondsLeft / 7);

    if (secondsLeft % 52 !== 0) {
        message.push((secondsLeft % 52) + ' weeks');
    }

    secondsLeft = Math.floor(secondsLeft / 52);

    if (secondsLeft > 0) {
        message.push(secondsLeft + ' years');
    }

    if (message.length == 0) {
        if (!hideSmall) {
            message.push('Too small to display');
        } else {
            message.push(hideSmall);
            prefix = "";
        }
    }
    return (
        <span>{message.reverse().join(', ')} {prefix}</span>
    );
};