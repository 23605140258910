import React from 'react';
import View from './view';
import BaseComponent from 'components/BaseComponent';
import DataStore from 'services/data-store';
import lodash from 'lodash';
import _ from 'services/i18n';

import { profileService } from '../../../../../app/services/profile.service';

/**
 * A component containing a form letting the user change his/her password.
 */
export default class extends BaseComponent {
    constructor (props) {
        super(props);

        let data = DataStore.get('currentUser');

        this.state = {
            // Indicates if data is currently being loaded from the server.
            // We only need to load the user's profile from the server if it's not in the datastore already.
            loading: (data == null),

            // Indicates if we're currently saving data to the server.
            saving: false,

            // Indicates if the most recent attempt to load profile info from the server succeeded or not.
            // true = load succeeded, false = load failed, null = load not attempted or not finished
            loadStatus: null,

            // Indicates if the most recent attempt to save the profile info to the server succeeded or not.
            // true = save succeeded, false = save failed, null = save not attempted or not finished
            // If a load and a save have both failed, then only the save is reported.
            saveStatus: null,

            // Key used to force a reload of the form on reset.
            formKey: 0,

            // User's email address, as loaded from profile data.
            // This is loaded solely to ensure the email address can't be used as a password.
            email: lodash.get(data, 'emails[0].email', null)
        };

        this.bindEvents([
            'Submit',
            'Reset',
            'ValidatePassword'
        ]);

        this.bindFunctions([
            'loadData',
            'saveData'
        ]);

        // The profile fields expected in the server data for this form.
        this.expectedServerFields = [
            'emails'
        ];

        this.View = View;
    }

    componentWillMount () {
        // Only load the user profile if the datastore hasn't already provided it.
        if (this.state.email == null) {
            this.loadData();
        }
    }

    loadData () {
        // Update the UI to indicate that we're loading.
        this.$setState({
            loading: true,
            saving: false,
            loadStatus: null,
            data: new Map()

        }).then(() => {
            // Request the user profile data from the server.
            return profileService.getCurrentUserProfile();
        }).then(
            (response) => {
                // We got a server response. Check the status.
                if ((response.status >= 200 && response.status < 300) || response.status == 'OK') {
                    // Status was good. Convert and store the data it returned.
                    this.setState({
                        loading: false,
                        loadStatus: true,
                        email: lodash.get(response.message, 'emails[0].email', null)
                    });

                } else {
                    // Status indicated an error.
                    this.setState({
                        loading: false,
                        loadStatus: false
                    });
                }
            },
            (error) => {
                // Our request failed.
                console.warn(error);
                this.setState({
                    loading: false,
                    loadStatus: false
                });
            }
        );
    }

    /**
     * Save the given form data to the server.
     */
    saveData (map) {
        // Update the UI to indicate that we're saving.
        this.$setState({
            loading: false,
            saving: true,
            saveStatus: null
        }).then(() => {
            // Update the user profile data on the server.
            let data = {
                password: map.get('pf-existing-password'),
                newPassword: map.get('pf-new-password'),
            };
            return profileService.changePassword(data.password, data.newPassword, this.props.route.portal);
        }).then(
            (response) => {
                // We got a server response. Check the status.
                if ((response.status >= 200 && response.status < 300) || response.status == 'OK') {
                    // Status was good. Report the success and reload the server data.
                    this.setState({
                        saving: false,
                        saveStatus: true
                    }, () => {
                        this.clearSaveStatusAfterDelay();
                        this.refreshForm();
                    });
                } else {
                    // Status indicated an error.
                    this.setState({
                        saving: false,
                        saveStatus: false
                    });
                }
            },
            (error) => {
                // Our request failed.
                console.warn(error);
                this.setState({
                    saving: false,
                    saveStatus: false,
                });
            }
        );
    }

    /**
     * Forces the form to reload initial values.
     */
    refreshForm () {
        this.setState({
            formKey: this.state.formKey + 1
        });
    }

    /**
     * Event handler: User has clicked the submit button the form.
     */
    handleSubmit (data) {
        this.saveData(data);
    }

    /**
     * Event handler: User has clicked the reset (or Discard Changes) button on the form.
     */
    handleReset () {
        this.refreshForm();
    }

    /**
     * Event handler: The form needs to validate the user's password.
     */
    handleValidatePassword (value) {
        // The TextInput component already checks for minimum length.
        // This only needs to ensure it doesn't match the email address.

        if (value == this.state.email) {
            return [_`Your password must not be the same as your email address`];
        }

        return true;
    }

    /**
     * Clears the save status back to null after a short delay.
     * This only works if the status is true when the delay expires.
     * This means this can only be used to automatically clear a success message.
     * It cannot clear an error message as that would be against our style guide.
     */
    clearSaveStatusAfterDelay () {
        setTimeout(() => {
            if (this.state.saveStatus === true) {
                this.setState({
                    saveStatus: null
                });
            }
        }, 5000);
    }
}
