/* eslint-disable @typescript-eslint/camelcase */

import { SimpleQuestion, SimpleQuestionnaireType, questionTypeDropdownOptions } from 'models/compositions/SimpleQuestionnaire';
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'services/i18n';

type CreateQuestionInputProps = {
    value: SimpleQuestion;
    onChange: (e) => unknown;
    onRemove: (question: SimpleQuestion) => unknown;
};

export const CreateQuestionInput = ({ value, onChange, onRemove }: CreateQuestionInputProps): JSX.Element => {
    const [question, setQuestion] = useState(value);

    useEffect(() => {
        setQuestion(value);
    }, [value]);

    const validate = useCallback((question: SimpleQuestion): boolean => {
        if (isMultiTypeQuestion(question.question_type)) {
            return isMultiTypeQuestionValid(question);
        }
        if (isSimpleTypeQuestion(question.question_type)) {
            return isQuestionTextValid(question.question_text);
        }

        return false;
    }, []);

    const changeQuestion = useCallback((question) => {
        question.isQuestionValid = validate(question);

        onChange(question);
        return question;
    }, [onChange, validate]);

    const handleParameterChange = useCallback((e): void => {
        const optionString = e.target.value;
        const optionsArray = optionString.split(',');
        setQuestion(question => changeQuestion({ ...question, question_parameters: { ...question.question_parameters, options: optionsArray } }));
    }, [changeQuestion]);

    const handleTextChange = useCallback((e): void => {
        setQuestion(question => changeQuestion({ ...question, question_text: e.target.value }));
    }, [changeQuestion]);

    const handleTypeChange = useCallback((e): void => {
        const question_type: SimpleQuestionnaireType = e.target.value;
        let question_parameters: any = {
            options: []
        };

        if (question_type === SimpleQuestionnaireType.text) {
            question_parameters = {
                max_text_length: 200
            };
        }
        if (question_type === SimpleQuestionnaireType.number) {
            question_parameters = {
                number_type: 'integer'
            };
        }

        setQuestion(question => changeQuestion({ ...question, question_type, question_parameters }));
    }, [changeQuestion]);

    const isMultiTypeQuestion = (question_type: SimpleQuestionnaireType): boolean => {
        return (question_type === SimpleQuestionnaireType.mcq || question_type === SimpleQuestionnaireType.multiselect);
    };

    const isSimpleTypeQuestion = (question_type: SimpleQuestionnaireType): boolean => {
        return (question_type === SimpleQuestionnaireType.text || question_type === SimpleQuestionnaireType.number);
    };

    const isQuestionTextValid = (questionText: string): boolean => !!questionText;

    const isMultiTypeQuestionValid = (question: SimpleQuestion): boolean => {
        const paramsAreValid = question.question_parameters.options.length >= 2;

        return isQuestionTextValid(question.question_text) && paramsAreValid;
    };

    return (
        <div className='form-group question-card'>
            <div className='question-box'>
                <button type='button' className='close' onClick={() => { onRemove(question); }} aria-label='Close'>
                    <span aria-hidden="true">&times;</span>
                </button>
                <label className='order'>#{question.order}</label>
                <label>Question*</label>
                <input className='form-control question-input' id={`question${question.order}`} name='question' value={question.question_text || ''} onChange={handleTextChange} />
                <div className='select'>
                    <label className='select-label' >Answer Type</label>
                    <select name="optionsType" className="form-control question-type" value={question.question_type || ''} onChange={handleTypeChange}>
                        <option value={null || ''}>{_`-- Select a Type --`}</option>
                        {questionTypeDropdownOptions.map((type) => {
                            return (
                                <option key={type.name} value={type.value}>{type.name}</option>
                            );
                        })}
                    </select>
                </div>
                {isMultiTypeQuestion(question.question_type) && (
                    <div className='options'>
                        <label className='options-title' >Options* - Example ( yes, no, maybe, other )</label>
                        <input className='form-control' type='text' id={`options${question.order}`} value={question.question_parameters.options || ''} onChange={handleParameterChange} />
                    </div>
                )}
            </div>
        </div>
    );
};
