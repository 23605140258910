import React, { Component } from 'react';
import './button-split-dropdown.less';

export default class ButtonSplitDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ddOpened: false
        };
        this.dropdownClose = this.dropdownClose.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
    }

    dropdownToggle() {
        this.setState({
            ddOpened: !this.state.ddOpened
        });
    }

    dropdownClose() {
        this.setState({
            ddOpened: false
        });
    }

    render() {
        const {buttonsClassNames, onClick, label} = this.props;
        return (
            <div className={`btn-split-dropdown btn-group ${this.state.ddOpened ? 'open' : ''}`}>
                <button type="button"
                        className={`btn dropdown-toggle ${buttonsClassNames}`}
                        onClick={onClick}>
                    {label}
                </button>
                <button type="button"
                        className={`btn dropdown-toggle ${buttonsClassNames}`}
                        onBlur={this.dropdownClose}
                        onClick={this.dropdownToggle}>
                    <span className="caret"></span>
                    <span className="sr-only">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu" onMouseDown={(e) => e.preventDefault()} onMouseUp={this.dropdownClose}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}