import { useCallback, useEffect, useState } from 'react';
import { SortOrder } from '../grid';
import { defaultStringSort } from './helpers';

interface TableSortProps {
    items: any[];
    selectors?: Record<string, (item: any) => any>;
    sortHandlers?: Record<string, (a: any, b: any, sortOrder: SortOrder) => number>;
    defaultSortHandler?: (a: any, b: any, sortOrder: SortOrder) => number;
    initialSortState: {[key: string]: SortOrder};
}
const nope = {};

export const useTableSort = (props: TableSortProps) => {
    const {
        items,
        sortHandlers = nope,
        defaultSortHandler = defaultStringSort,
        selectors = nope,
        initialSortState
    } = props;
    const [sortedItems, setSortedItems] = useState(items);
    const [sortState, setSortState] = useState(initialSortState);

    const onSort = useCallback((sortState: Record<string, SortOrder>) => {
        setSortState(sortState);
    }, []);

    useEffect(() => {
        const [sortColName, sortValue] = Object.entries(sortState)[0] || [];
        const selector = selectors[sortColName] || ((ob) => ob[sortColName]);
        const sortHandler = sortHandlers[sortColName] || defaultSortHandler;
        const newSortedItems = items.sort((a, b) => {
            a = selector(a);
            b = selector(b);
            return sortHandler(a, b, sortValue);
        });
        setSortedItems([...newSortedItems]);
    }, [defaultSortHandler, items, selectors, sortHandlers, sortState]);

    return { onSort, sortedItems, sortState };
};
