import React, { useState, useEffect,  Fragment, useMemo } from 'react';
import Select from 'react-select';
// components
import ErrorView from 'components/errorbox';
import { Row, Column, FormGroup } from 'ui';
import { SendEmailState, EmailTemplateContent } from 'components/user/email/create/validator';
import { WYSIWYGPreview } from 'common/ui/wyiswyg-preview/WYSIWYGPreview';
// hooks
import { useSortedDepartmentFolderIdData } from 'features/department/hooks/useSortedDepartmentData';
// services
import _ from 'services/i18n';
import { contentService } from 'services/content.service';
// utils
// interfaces
import { DataOption } from 'features/department/department.interface';
import { useGroupedDepartmentDataOptionList } from 'features/department/hooks/useGroupedDepartmentDataOptionList';

type EmailCreateFormProps = {
    id: string;
    loading?: boolean;
    errors?: string[];
    onChange: (c) => unknown;
    form: SendEmailState;
    onSubmit: (f) => unknown;
}

export const EmailCreateForm = (props: EmailCreateFormProps) => {
    const [emailTemplates, setEmailTemplates] = useState<EmailTemplateContent[]>([]);
    const [triggerContentUpdate, setTriggerContentUpdate] = useState<boolean>(false);

    useEffect(() => {
        getEmailTemplates();
    }, []);

    const getEmailTemplates = async (): Promise<void> => {
        const content: EmailTemplateContent[] = await contentService.getBulkOperationEmailTemplates();

        setEmailTemplates(content);
    };

    const handleTemplateSelection = (template: EmailTemplateContent | null): void => {
        setTriggerContentUpdate(true);

        updateTemplateValue(template).then(() => {
            setTriggerContentUpdate(false);
        });
    };

    const updateTemplateValue = (selectedTemplate: EmailTemplateContent | null): Promise<unknown> => {
        if (!selectedTemplate) {
            return Promise.resolve(
                props.onChange({
                    htmlContent: '',
                    plainTextContent: ''
                })
            );
        }

        return Promise.resolve(
            props.onChange({
                htmlContent: selectedTemplate.htmlContent,
                plainTextContent: selectedTemplate.txtContent
            })
        );
    };

    const sortedEmailTemplateList = useSortedDepartmentFolderIdData(emailTemplates);

    const [templateOptionList] = useGroupedDepartmentDataOptionList(sortedEmailTemplateList, d => d.name);

    if (props.loading) {
        return (
            <h5>{_`Loading... please wait`}</h5>
        );
    }

    return (
        <Fragment>
            <ErrorView errors={props.errors} />
            <form noValidate onSubmit={props.onSubmit} id={props.id} >
                <Row>
                    <Column md={12}>
                        <FormGroup>
                            <label htmlFor={`${props.id}-title`}>{_`Email subject`}</label>
                            <input
                                id={`${props.id}-title`}
                                type="text"
                                name="title"
                                className={'form-control'}
                                value={props.form.subject}
                                onChange={(e) => { props.onChange({ subject: e.target.value });}} />
                        </FormGroup>
                    </Column>
                </Row>
                <Row>
                    <Column md={12}>
                        <FormGroup>
                            <div className={'form-group'}>
                                <label htmlFor={`${props.id}-templates`}>HTML Templates</label>
                                <Select
                                    inputId={`${props.id}-templates`}
                                    className="filter-select"
                                    classNamePrefix="filter-select"
                                    isClearable={true}
                                    placeholder="Unselected"
                                    options={templateOptionList}
                                    onChange={(option: DataOption<EmailTemplateContent>) =>
                                        handleTemplateSelection(option ? option.value : null)
                                    }
                                />
                            </div>
                        </FormGroup>
                    </Column>
                </Row>
                <Row>
                    <Column md="12">
                        <FormGroup>
                            <label htmlFor='content'>{_`Email content`}</label>
                            <WYSIWYGPreview
                                triggerContentUpdate={triggerContentUpdate}
                                onChange={(value, field?) => {
                                    if (field === 'txt_content') {
                                        props.onChange({ plainTextContent: value });
                                        return;
                                    }

                                    props.onChange({ htmlContent: value });
                                }}
                                type={'email'}
                                value={props.form.htmlContent} />
                        </FormGroup>
                    </Column>
                </Row>
            </form>
        </Fragment>
    );
};
