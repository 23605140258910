import React from 'react';
import Select from 'react-select';
import _ from 'services/i18n';

export const resourceTypeList = [
    { value: 'audio', label: _`Audio File` },
    { value: 'phone', label: _`Contact Number` },
    { value: 'pdf', label: _`PDF` },
    { value: 'url', label: _`URL` },
    { value: 'video', label: _`Video` },
    { value: 'html', label: _`HTML` },
    { value: 'media_resource', label: _`Uploaded File` },
];

const ResourceTypeSelect = ({ resourceType, setResourceType }) => {

    return (
        <Select
            value={resourceType}
            options={resourceTypeList}
            onChange={setResourceType}
            placeholder={_`Select Type`}
            isClearable={true}
        />
    );
};

export default ResourceTypeSelect;
