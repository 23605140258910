import React from 'react';
import PropTypes from 'prop-types'
import {Row, Column} from 'ui';

/**
 * Displays the page footer for the transport and interpreter worklist.
 *
 * Props (data):
 * - loadingInitial = Required. Boolean indicating if the initial page load is in progress. No user actions are possible while the page is loading.
 * - loadingInitialError = Required. Boolean indicating if an error occurred during initial page loading. No user actions are possible if this happens.
 * - loadingMore = Required. Boolean indicating if the page is loading additional items from the worklist. Other parts of the page are still functional while loading more entries.
 * - loadingMoreError = Required. Boolean indicating if an error occurred while loading more entries from the worklist. The remainder of the page should still be functional if this happens.
 * - numItemsLeftToLoad = Required. The number of additional items on the worklist, in addition to what is currently displayed.
 * - hasCompletedItems = Required. Boolean indicating if any of the items on the displayed work list have been completed.
 * 
 * Props (functions):
 * - onShowMore = Required. Callback triggered when the user wants to load more entries from the worklist.
 * - onHideCompletedItems = Required. Callback triggered when the user wants to hide completed items from the displayed worklist.
 */
const Footer = ({loadingInitial, loadingInitialError, loadingMore, loadingMoreError, numItemsLeftToLoad, hasCompletedItems, onShowMore, onHideCompletedItems}) => {

    if (loadingInitialError) {
        return (
            <Row>
                <Column md='12'>
                    <div className='danger'>
                        Failed to load worklist. Please try again later.
                    </div>
                </Column>
            </Row>
        );
    }

    if (loadingInitial) {
        return (
            <Row>
                <Column md='12'>
                    Loading worklist. Please wait...
                </Column>
            </Row>
        );
    }

    if (loadingMore) {
        return (
            <Row>
                <Column md='12'>
                    Loading more items. Please wait...
                </Column>
            </Row>
        );
    }

    let errorRow;
    if (loadingMoreError) {
            errorRow = (
            <Row>
                <Column md='12'>
                    <div className='warning'>
                        Failed to load more items. Please try again later.
                    </div>
                </Column>
            </Row>
        );
    }

    // Display the number of items left to show.
    let worklistSizeMessage;
    if (numItemsLeftToLoad > 1) {
        worklistSizeMessage = `There are ${numItemsLeftToLoad} more items on this worklist.`;
    } else if (numItemsLeftToLoad == 1) {
        worklistSizeMessage = 'There is 1 more item on this worklist.';
    } else {
        worklistSizeMessage = 'There are no more items on this worklist.';
    }

    // Only show the "Show More..." button if there are more items left to load.
    let showMoreButton;
    if (numItemsLeftToLoad > 0) {
        showMoreButton = (
            <button className='btn' onClick={onShowMore}>
                Show More...
            </button>
        );
    }

    // Only show the "Hide Completed Items" button if there are some completed items.
    let completedItemsRow;
    if (hasCompletedItems) {
        completedItemsRow = (
            <Row>
                <Column md='12'>
                    <button className='btn btn-xs' onClick={onHideCompletedItems}>
                        Hide Completed Items
                    </button>
                </Column>
            </Row>
        );
    }

    return (
        <div className='text-center'>
            {errorRow}
            <Row>
                <Column md='12'>
                    <p>{worklistSizeMessage}</p>
                    <p>{showMoreButton}</p>
                </Column>
            </Row>
            {completedItemsRow}
        </div>
    );
};

export default Footer;

Footer.propTypes = {
    loadingInitial:             PropTypes.bool.isRequired,
    loadingInitialError:        PropTypes.bool.isRequired,
    loadingMore:                PropTypes.bool.isRequired,
    loadingMoreError:           PropTypes.bool.isRequired,
    numItemsLeftToLoad:         PropTypes.number.isRequired,
    hasCompletedItems:          PropTypes.bool.isRequired,

    onShowMore:                 PropTypes.func.isRequired,
    onHideCompletedItems:       PropTypes.func.isRequired
};
