import React from 'react';

export default class extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showModal: false
        };

        this.onShowModal = this.onShowModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
    }

    onShowModal (e) {
        e.preventDefault();
        this.setState({
            showModal: true
        });
    }

    onHideModal (e) {
        e.preventDefault();

        this.setState({
            showModal: false
        });
    }

    render () {
        return (
            <div className="password-guidance-link">
                {this.state.showModal
                    ?<GuidanceModal onClose={this.onHideModal} />
                    :<a href='#' onClick={this.onShowModal}>Show Password Guidance</a>
                }
            </div>
        );
    }
}

const GuidanceModal = (props) => {
    return (
        <div>
            <a href="#" onClick={props.onClose}>Hide Password Guidance</a>
            <div className="alert alert-info">
                <span>Your password must be at least 8 characters in length and not match your email address</span>
            </div>
        </div>
    );
}