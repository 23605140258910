import React, { useEffect, useMemo } from 'react';
import { useCompositionCollection } from 'common/useCompositionCollection';
import { get } from 'services/api';
import { useCompositions } from 'common/useCompositions';
import { AntenatalWorklistItem } from './antenatal-worklist/AntenatalWorklist';
import {
    getNameFromDemographics,
    getPatientUrlFromReferral,
    getDobFromDemographics,
    getNHSFromDemographics,
    getTriageDecisionStatusFromReferral,
    getHospitalNumberFromDemographics,
    getQuestionnaireStatus,
    getAppointmentStatus,
    getAppointmentDetails,
    getAppointmentLabel,
    isAppointmentLockedForEdit,
    TRIAGE_STATUS,
} from './antenatalHelpers';
import { convertToDate } from 'common/datetime/convertToDate';
import DataStore from 'services/data-store';
import { useAPI } from 'common/useAPI';

function mapToAntenatalWorklistItem(uuid, {
    referralsMap,
    appointmentsMap,
    questionnaireResponsesMap,
    demographicsMap
}): AntenatalWorklistItem {
    try {
        const referral = referralsMap.get(uuid);
        if (!referral) { return undefined; }

        const demographics = demographicsMap.get(uuid);
        const questionnaireResponse = questionnaireResponsesMap.get(uuid);
        const appointment = appointmentsMap.get(uuid);
        const questionnaireStatus = getQuestionnaireStatus(questionnaireResponse);
        const triageStatus = getTriageDecisionStatusFromReferral(referral, questionnaireStatus);

        const {
            isInterpreter,
            interpreterDetails,
            triageDetails
        } = getAppointmentDetails(appointment);


        const appointmentStatus = getAppointmentStatus(appointment, triageStatus);
        const appointmentLabel = getAppointmentLabel(appointment, appointmentStatus);
        const appointmentUBRNBookingPasswordLockedForEdit = isAppointmentLockedForEdit(appointment);
        const isUrgent = referral.content.priority === 'Urgent';
        const createAt = convertToDate(referral.created_at);
        const selfReferredTimestamp = createAt && createAt.isValid() && createAt.unix();

        return {
            uuid: referral.uuid,
            name: getNameFromDemographics(demographics),
            patientURL: getPatientUrlFromReferral(referral),
            dob: getDobFromDemographics(demographics),
            nhs: getNHSFromDemographics(demographics),
            hospitalNumber: getHospitalNumberFromDemographics(demographics),
            selfReferredTimestamp,
            questionnaireStatus,
            triageStatus,
            isInterpreter,
            interpreterDetails,
            triageDetails,
            isUrgent,
            appointmentStatus,
            appointmentLabel,
            appointmentUBRNBookingPasswordLockedForEdit,
        };
    } catch (err) {
        console.error(err);
        return undefined;
    }
}

function getQuestionnaireStuffFromWorklist(worklistCollection) {
    const questionnaireAndResponses = worklistCollection.map(({ relatedCompositions, ...referral }) => {
        const questionnaires = relatedCompositions.questionnaire;
        const questionnaireResponses = relatedCompositions.questionnaireResponse || [];
        const questionnaireScorings = relatedCompositions.questionnaireScoring || [];
        const questionnaireScoresArr = relatedCompositions.questionnaireScores || [];

        const questionnaire = questionnaires ? questionnaires[0] : null;
        let questionnaireResponse = null;
        let questionnaireScores = null;
        let questionnaireScoring = null;

        if (questionnaire) {
            const uuid = questionnaire.uuid;
            questionnaireResponse = questionnaireResponses.find(
                ({ content }) => (content.questionnaire_uuid === uuid || content.questionnaireId === uuid) && (content.linked_composition_uuid === referral.uuid)
            ) || ({} as any);
            questionnaireScoring = questionnaireScorings.find(({ content }) => {
                return content.questionnaire_response_uuid === questionnaireResponse.uuid;
            });
            questionnaireScores = questionnaireScoresArr.find(({ content }) => {
                return content.questionnaire_response_uuid === questionnaireResponse.uuid;
            });
        }
        return [referral.uuid, questionnaire, questionnaireResponse, questionnaireScoring, questionnaireScores];
    });

    return {
        questionnaires: questionnaireAndResponses.map(([referralUuid, questionnaire]) => ([referralUuid, questionnaire])),
        questionnaireResponses: questionnaireAndResponses.map(([referralUuid, , questionnaireResponse]) => ([referralUuid, questionnaireResponse])),
        questionnaireScoring: questionnaireAndResponses.map(([referralUuid, , , scoring]) => ([referralUuid, scoring])),
        questionnaireScores: questionnaireAndResponses.map(([referralUuid, , , , scores]) => ([referralUuid, scores]))
    };
}

function getAppointmentFromWorklist(worklistCollection) {
    return worklistCollection.map(({ relatedCompositions, ...referral }) => {
        const appointments = relatedCompositions.appointment;

        return [referral.uuid, appointments && appointments.length ? appointments[0] : undefined];
    });
}

function getDemographicsFromWorklist(worklistCollection) {
    return worklistCollection.map(({ relatedCompositions, ...referral }) => {
        const demographics = relatedCompositions.demographics;

        return [referral.uuid, demographics && demographics.length ? demographics[0] : undefined];
    });
}

export type WorklistCollectionStuff = [
    AntenatalWorklistItem[],
    {
        isFetching: boolean;
        isLazyFetching: boolean;
        isError: boolean;
        totalItems: number;
    },
    {
        referralsMap: Map<string, any>;
        questionnairesMap: Map<string, any>;
        questionnaireScoresMap: Map<string, any>;
        questionnaireScoringMap: Map<string, any>;
        questionnaireResponsesMap: Map<string, any>;
        appointmentsMap: Map<string, any>;
        demographicsMap: Map<string, any>;
    },
    {
        updateReferral: (uuid: string, content: object, options?: {
            isPushToServer?: boolean;
            isPartial?: boolean;
        }) => void;
        updateAppointment: (uuid: string, content: object, options?: {
            isPushToServer?: boolean;
            isPartial?: boolean;
        }) => void;
    }
];

const getAntenatalPatientsURL = (teamId, roleUuid, perPage, page) =>
    `/teams/${teamId}/worklists/labelled-pathway-worklist?using_role_uuid=${roleUuid}&context=antenatal&per_page=${perPage}&page=${page}`;

export function useAntenatalWorklistCollection({ teamId }): WorklistCollectionStuff {
    const tag = '[useAntenatalWorklistCollection]';
    const [worklistCollection, {
        isFetching,
        isLazyFetching,
        totalItems,
        isError
    }] = useCompositionCollection({
        archetype: 'WorklistReferral',
        teamId,
        getListFunction: async ({ teamId, perPage, page }) => {
            const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});
            const worklistURL = getAntenatalPatientsURL(teamId, roleUuid, perPage, page);
            const response = await get(worklistURL);
            const results = response.message.results || {};
            return [Object.values(results), response.message.meta.count];
        },
        limitFetching: true,
    });

    const [referralsMap, , {
        setItems: setReferrals,
        updateContent: updateReferral,
    }] = useCompositions({
        archetype: 'referral',
        teamId
    });

    const [questionnairesMap, , {
        setItems: setQuestionnaires
    }] = useCompositions({
        archetype: 'questionnaire',
        teamId
    });

    const [questionnaireResponsesMap, , {
        setItems: setQuestionnaireResponses
    }] = useCompositions({
        archetype: 'questionnaireResponse',
        teamId
    });

    const [questionnaireScoresMap, , {
        setItems: setQuestionnaireScores
    }] = useCompositions({
        archetype: 'questionnaireScores',
        teamId
    });

    const [questionnaireScoringMap, , {
        setItems: setQuestionnaireScoring
    }] = useCompositions({
        archetype: 'questionnaireScoring',
        teamId
    });

    const [appointmentsMap, , {
        setItems: setAppointments,
        updateContent: updateAppointment,
    }] = useCompositions({
        archetype: 'appointment',
        teamId
    });


    const [demographicsMap, , {
        setItems: setDemographics
    }] = useCompositions({
        archetype: 'demographics',
        teamId
    });

    useEffect(() => {
        const referralsFromWorklist = worklistCollection.map(({ relatedCompositions, ...referral }) => ([referral.uuid, referral]));
        setReferrals(referralsFromWorklist);

        const { questionnaires, questionnaireResponses, questionnaireScores, questionnaireScoring } = getQuestionnaireStuffFromWorklist(worklistCollection);
        setQuestionnaires(questionnaires);
        setQuestionnaireResponses(questionnaireResponses);
        setQuestionnaireScoring(questionnaireScoring);
        setQuestionnaireScores(questionnaireScores);

        const appointments = getAppointmentFromWorklist(worklistCollection);
        setAppointments(appointments);

        const demographics = getDemographicsFromWorklist(worklistCollection);

        setDemographics(demographics);
    }, [
        worklistCollection,
        setReferrals,
        setQuestionnaires,
        setQuestionnaireResponses,
        setAppointments,
        setDemographics,
        setQuestionnaireScores,
        setQuestionnaireScoring,
    ]);

    const worklistItems = useMemo(() => {

        return worklistCollection.filter((item) => {
            const { relatedCompositions }= item;
            const isValid = relatedCompositions && relatedCompositions.appointment && relatedCompositions.appointment.length;
            const isValidTriage = item.content.triage_decision == null || Object.entries(TRIAGE_STATUS).find(([, status]) => status === item.content.triage_decision);

            if(!isValid) {
                console.error(tag, 'Invalid worklist item - has no appointment', { item });
            }
            if(!isValidTriage) {
                console.error(tag, 'Invalid worklist item - somehow it has some strange td status', { item });
            }

            return isValid && isValidTriage;
        }).map(({ uuid }) => mapToAntenatalWorklistItem(uuid, {
            referralsMap,
            appointmentsMap,
            questionnaireResponsesMap,
            demographicsMap
        })).filter(item => !!item);
    }, [worklistCollection, appointmentsMap, questionnaireResponsesMap, demographicsMap, referralsMap]);

    return [worklistItems, {
        isFetching,
        isLazyFetching,
        totalItems,
        isError
    }, {
        referralsMap,

        questionnairesMap,
        questionnaireResponsesMap,
        questionnaireScoresMap,
        questionnaireScoringMap,

        appointmentsMap,
        demographicsMap
    }, {
        updateAppointment,
        updateReferral,
    }];
}
