import React, { useState } from 'react';
import DepartmentSelect from '../../department/components/DepartmentSelect/DepartmentSelect';
import CollectionSelect from '../../resource/CollectionSelect/CollectionSelect';
import ResourceCollectionModal from '../../../../portal-components/components/resource/category/create/ResourceCollectionModal';
import _ from 'services/i18n';
import './ManageCollectionsContainer.scss';
import { browserHistory } from 'react-router';
import useFilteredCollections from '../CollectionSelect/useFilteredCollections';

const ManageCollectionsContainer = () => {
    const [displayCreateModal, setDisplayCreateModal] = useState<boolean>(false);
    const [editDepartmentOption, setEditDepartmentOption] = useState<string>(null);
    const collectionsList = useFilteredCollections(editDepartmentOption);

    const onChangeCollection = (collection) => {
        browserHistory.push(`/admin_portal/resources/${collection.value}`);
    };

    return (
        <>
            <div className="manage-resources-container">
                <div className='manage-resources-panel'>
                    <div className='manage-resources-content'>
                        <h4 className='manage-resources-heading'>{_`Create new collection`}</h4>
                        <button className='btn btn-success create-new-collection-btn' 
                            onClick={() => setDisplayCreateModal(true)}>{_`Start`}</button>
                    </div>
                </div>
                <div className='manage-resources-panel'>
                    <div className='manage-resources-content'>
                        <h4 className='manage-resources-heading'>{_`Edit existing collection`}</h4>
                        <div className='manage-resources-select-container'>
                            <DepartmentSelect departmentUUID={editDepartmentOption} setDepartmentUUID={setEditDepartmentOption}/>
                            <CollectionSelect categoryList={collectionsList} category_id={null} onChange={onChangeCollection} />
                        </div>
                    </div>
                </div>
            </div>
            { displayCreateModal &&
                ( 
                    <ResourceCollectionModal
                        onClose={() => setDisplayCreateModal(false)}
                        onSubmit={() => setDisplayCreateModal(false)}
                    />
                )
            }
        </>
    );
};

export default ManageCollectionsContainer;
