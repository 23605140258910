import React from 'react';
import BasePage from 'components/page/base';
import Events from './_events';
import _ from 'services/i18n';
import {DateTime} from 'common/datetime/DateTime';
import ActionMenu from 'ui/action-menu';
import HeadingDoc from 'ui/heading-doc';
import {Row, Column, ResponsiveTable} from 'ui';
import NHSNumber from 'ui/nhs-number';
import { feedbackService } from 'services/feedback.service';
import uuid from 'uuid';

import {Link} from 'react-router';

/**
 * Default page to render a specific user's user feedback
 * @param props.params.uuid The uuid of the user feedback to load
 */
export default class extends BasePage {
    constructor(props) {
        super(props);

        this.state = {
            uuid: props.params.uuid,
            loading: true,
            loadingError: false
        };

        this.bindEvents([], new Events());

        this.bindFunctions(['loadComponent']);
    }

    pageTitle() {
        return _`${this.$p()} Feedback | iFocus Clinical Portal`;
    }

    componentWillMount() {
        this.loadComponent();
    }

    loadComponent() {
        feedbackService.getByUuid({uuid: this.state.uuid}).then((response) => {
            this.setState({
                loading: false,
                content: response.message.content,
                created_at: response.message.created_at
            });
        }, (error) => {
            this.setState({
                loadingError: true
            });
        });
    }

    render() {
        if (this.state.loadingError) {
            return (
                <LoadingError p={this.$p()} />
            );
        }

        return <Page state={this.state} $p={this.$p} $ps={this.$ps}/>;
    }
}

/**
 * Displays the generic error message encountered if the ajax calls fail to return correctly
 * @param props.$p - The singular form of the term "patients" for this service
 * @return React Component
 */
export const LoadingError = ({p}) => {
    return (
        <div className="page page-user-feedback">
            <HeadingDoc title={_`${p} Feedback`}>
                {_`This page will show the selected feedback item.`}
            </HeadingDoc>
            <div className="alert alert-danger">
                <span>This page was unable to load the required information due to an unspecified error. Please try again later.</span>
            </div>
        </div>
    );
};

/**
 * Renders the user feedback on the page
 * @param  props.$p     The singular form of the term "patient" for this service
 * @param  props.$ps    The plural form of the term "patient" for this service
 * @param  props.state.loading - Whether the feedback has loaded or not
 * @param  props.state.content.ratings - The ratings of user feedback
 * @param  props.state.created_at - The time/date the feedback was created
 * @param  props.state.content.Feedback - A key value pair of this feedback's comments
 * @return React Component
 */
export const Page = ({$p, $ps, state}) => {
    if (state.loading) {
        return (
            <div className="page page-user-feedback">
                <HeadingDoc title={_`${$p()} Feedback`}>
                    {_`This page will show the selected feedback item.`}
                </HeadingDoc>
                <p><em>Loading please wait...</em></p>
            </div>
        );
    }

    let headings = new Map();

    state.content.ratings.map((item) => {
        headings.set(item.label, item.value + ' / 5');
    });

    return (
        <div className="page page-user-feedback">
            <HeadingDoc title={_`${$p()} Feedback`}>
                {_`This page will show the selected feedback item.`}
            </HeadingDoc>
            <div className='top-bar'>
                    <Link
                        to={"/clinical_portal/my-team/user-feedback"}>{_`Back to All Feedback`}</Link>
                </div>
            <Row>
                <Column md="12">
                    <ResponsiveTable>
                        <thead>
                        <tr>
                            <th>Submission Date</th>
                            <th>Usability</th>
                            <th>Recommendation</th>
                            <th>Features</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><DateTime>{state.created_at}</DateTime></td>
                                <td>{headings.get("Usability") || 'n.a'}</td>
                                <td>{headings.get("Recommendation") || 'n.a'}</td>
                                <td>{headings.get("Features") || 'n.a'}</td>
                            </tr>
                        </tbody>
                    </ResponsiveTable>
                    <Feedback items={state.content.Feedback} />
                </Column>
            </Row>
        </div>
    );
};

/**
 * Renders a list of feedback comments for this composition
 * @param  props.items An array of feedback items
 * @return React Component
 */
export const Feedback = ({items}) => {
    let list = items.map((item, idx) => {
        return (
            <div className="feedback-item" key={idx}>
                <h4>{item.label}</h4>
                <p className="feedback-message">
                    {item.text}
                </p>
            </div>
        );
    });

    return (
        <div className="feedback-list">
            {list}
        </div>
    )
};