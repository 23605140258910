import { cloneDeep } from 'lodash';
import {
    AdvancedSearch,
    AdvancedSearchDefinition,
    AdvancedSearchDocumentConfig,
    makeQueryDefinitionFromConfig,
} from 'models/AdvancedSearchDefinition';
import { Composition } from 'models/Composition';

export function getSearchDefinitionCopy(
    initialConfig: AdvancedSearchDocumentConfig,
    searchComposition: Composition<AdvancedSearch>,
): AdvancedSearchDefinition {
    if (searchComposition) {
        const compositionContentCopy = cloneDeep(searchComposition.content);
        return {
            name: compositionContentCopy.name,
            static: compositionContentCopy.static,
            queryDefinition: compositionContentCopy.queryDefinition,
        };
    }

    return {
        name: '',
        static: false,
        queryDefinition: makeQueryDefinitionFromConfig(initialConfig),
    };
}
