import React, { useState, useEffect, Fragment } from 'react';
import { folderService } from 'services/folder.service';
import { MODAL_TYPE } from './ModalType';
import PatientBarModal from './PatientBarModal';

const PatientTabView = ({ folderId, renderElement, teamId, navList, tabView }): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<string>('');
    const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);
    const [folderDetails, setFolderDetails] = useState<any>({});

    useEffect(() => {
        setSelectedTab(MODAL_TYPE.APPOINTMENTS);
        loadFolderDetails();
    }, []);

    const loadFolderDetails = (): void => {
        if (folderId) {
            folderService.getFolderById({ folderId }).then((folder) => {
                setFolderDetails(folder);
            }).catch((e) => {
                console.error('Error during loading folderDetails data', e);
            });
        }
    };

    let className = 'nav-item';
    const handleNavigation = (navigationTab, idx): void => {
        setSelectedTab(navigationTab);
        setSelectedTabIdx(idx);
    };
    const preventDefault = (e): void => {
        e.preventDefault();
    };

    return (
        <div>
            {tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
            <div className='tab-conatiner'>
                <div className='nav nav-tabs'>
                    {navList.map((navigationTab, idx) => {
                        if (idx === selectedTabIdx) {
                            className += ' active';
                        }
                        if (idx !== selectedTabIdx) {
                            className = 'nav-link';
                        }
                        return (
                            <li key={idx} className={className} onClick={() => handleNavigation(navigationTab, idx)}>
                                <a className="nav-link" onClick={(e) => preventDefault(e)}>
                                    {navigationTab}
                                </a>
                            </li>
                        );
                    })}
                </div>
            </div>
            {selectedTab && renderElement(selectedTab)}
        </div>
    );
};

export default PatientTabView;
