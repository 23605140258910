import { CompositionService } from './composition.service';
import { get } from './api'
import { DataStore } from "../../app/services/data-store";

const { teamId, uuid } = (DataStore.get('me.currentRole') || {});

class ReportsService extends CompositionService {

    get archetypeName(): string {
        return 'reports';
    }

    getCompletedEq5ds(fromDate, toDate): Promise<any> {
        return get(`/clinical_portal/reports/patient-eq5ds?team_id=${teamId}&from=${fromDate}&to=${toDate}&using_role_uuid=${uuid}`);
    }

    getIssueToCompletion(fromDate, toDate): Promise<any> {
        return get(`/clinical_portal/reports/issue-completion-eq5ds?team_id=${teamId}&from=${fromDate}&to=${toDate}&using_role_uuid=${uuid}`);
    }

    getOverdueEq5ds(fromDate, toDate): Promise<any> {
        return get(`/clinical_portal/reports/overdue-eq5ds?team_id=${teamId}&from=${fromDate}&to=${toDate}&using_role_uuid=${uuid}`);
    }
    
    getPatientInvites(fromDate, toDate, referrer): Promise<any> {
        return get(`/clinical_portal/reports/patient-invites?team_id=${teamId}&from=${fromDate}&to=${toDate}&referrer=${referrer}&using_role_uuid=${uuid}`);
    }

    getReferrals(fromDate, toDate): Promise<any> {
        return get(`/clinical_portal/reports/referrals?team_id=${teamId}&from=${fromDate}&to=${toDate}&using_role_uuid=${uuid}`);
    }

}

export const reportsService = new ReportsService();
