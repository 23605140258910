import { SortOrder } from './SortOrder';

export const defaultFilter = (str, filterString) => {
    if (typeof(str) === 'object') {
        str = Object.values(str).filter((val) => typeof(val) === 'string').join(' ');
    }

    filterString = filterString.toLowerCase();
    str = str.toLowerCase();
    return str.includes(filterString);
};

export const defaultStringSort: (a: any, b: any, sortOrder: SortOrder) => number = (a, b, sortOrder) => {

    if(sortOrder === SortOrder.NO) {
        return 0;
    }

    a = String(a??'').toLowerCase();
    b = String(b??'').toLowerCase();

    return sortOrder == SortOrder.ASC ?
        a > b ?
            1 :
            a < b ?
                -1 :
                0 :
        a > b ?
            -1 :
            a < b ?
                1 :
                0;
};

export const defaultNumberSort = (a, b, sortOrder) => {
    if(sortOrder === SortOrder.NO) {
        return 0;
    }
    const numA = parseFloat(a) || 0;
    const numB = parseFloat(b) || 0;

    return sortOrder === SortOrder.ASC ? numA - numB : numB - numA;
};

export const defaultSort = (a, b, sortOrder) => {
    const aIsNull = a == null;
    const bIsNull = b == null;

    if(typeof a === 'string') {
        a = a.toLowerCase();
    }
    if(typeof b === 'string') {
        b = b.toLowerCase();
    }

    if (a === b || (aIsNull && bIsNull)) {
        return 0;
    }

    if (aIsNull && !bIsNull) {
        return sortOrder === SortOrder.ASC ? -1 : 1;
    }

    if (!aIsNull && bIsNull) {
        return sortOrder === SortOrder.DESC ? 1 : -1;
    }

    if (a > b) {
        return sortOrder === SortOrder.DESC ? -1 : 1;
    }

    if (a < b) {
        return sortOrder === SortOrder.DESC ? 1 : -1;
    }
};
