import React from 'react';
import {Link} from 'react-router';
import ErrorView from 'components/errorbox';
import _ from 'services/i18n';
import FileModal from 'components/jsonfile';

/**
 * Create message component
 */
export default class extends React.Component {

    /**
     * Generates an array of questionnaire templates
     * @return {Array} A list of options
     */
    renderTemplateLookup () {

        const templateList = this.props.templateList || [];

        let optionList = templateList.map((item, idx) => {
            return (
                <option key={idx} data-type="template" value={item.id}>{item.name}</option>
            );
        });

        optionList = optionList.concat((this.props.compositionsList || []).map((item, idx) => {
            return (
                <option key={templateList.length+idx} data-type="composition" value={item.uuid}>{item.content.name}</option>
            );
        }));

        if(this.props.parentId === 0) {
            optionList.unshift(<option key={'default'} value=''>{_`-- Select a Questionnaire --`}</option>);
        }

        return optionList;
    }
    
    /**
     * Render the create form
     */
    renderForm () {
        return (
            <div className="form">
                <div className='form-group'>
                    <div className="radio">
                        <label>
                            <input type="radio" name="brandNewTemplate" id="brandNewTemplate"
                                   value="1" checked={this.props.brandNewTemplate == 1} onChange={this.props.onChange} />
                            {_`Brand new template`}
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input type="radio" name="brandNewTemplate" id="brandNewTemplate"
                                   value="0" checked={this.props.brandNewTemplate == 0} onChange={this.props.onChange} />
                            {_`New version of an existing template`}
                        </label>
                    </div>
                </div>

                {this.props.brandNewTemplate == 1 ?
                    <div className="form-group">
                        <label htmlFor='name'>{_`Questionnaire Name`}</label>
                        <input type='text' id='name' className='form-control' value={this.props.name}
                               onChange={this.props.onChange} required/>
                    </div> : null}
                
                {this.props.brandNewTemplate == 0 ?
                    <div className="form-group">
                        <label htmlFor='parentId'>{_`Select an existing Questionnaire`}</label>
                        <select id='parentId' className='form-control' value={this.props.sendAsEmail}
                                onChange={this.props.onChange}>
                            {this.renderTemplateLookup()}
                        </select>
                    </div> : null}

                <div className="form-group">
                    <label htmlFor='details'>{_`Template File`}</label>

                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-xs-3">
                            <input type="input" id='filename' className='form-control' value={this.props.filename} disabled />
                        </div>
                        <div className="col-md-6 col-lg-6 col-xs-6">
                            <button type="button" className="btn btn-default " onClick={this.props.onClickChangeFile}>{_`Select File`}</button>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <textarea id="fileContent" className='form-control' rows='7' value={this.props.fileContent} readOnly />
                </div>

                {this.renderFileModal()}

                <div className="form-group">
                    <button type='submit' className='btn btn-default'>{_`Add Questionnaire`}</button>
                </div>
            </div>
        );
    }

    renderFileModal () {
        if (this.props.showModal) {
            return (
                <FileModal width="200" height="200" onClose={this.props.onCloseChangeModal}
                      onUpload={this.props.onUploadChangeModal}/>
            )
        }
    }

    renderTopBar () {
        return (
            <div>
                <div className='top-bar'>
                    <Link to={'/admin_portal/questionnaire-templates'}>{_`Back to Questionnaire Template List`}</Link>
                </div>
                <h3 className="spacer20">{_`Add Questionnaire Template`}</h3>
            </div>
        );
    }

    render () {
        return (
            <div className="questionnaire-templates-create">

                {this.renderTopBar()}

                <ErrorView errors={this.props.errors}/>

                <form noValidate onSubmit={this.props.onSubmit}>
                    {this.renderForm()}
                </form>
            </div>
        );
    }
}
