import React, { FC } from 'react';
import { Timeline, TimelineContext, TimelineTranslations } from 'msk-timeline';
import marked from 'marked';
import HelpIcon from '@material-ui/icons/Help';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from '@material-ui/icons/Person';
import FilterIcon from '@material-ui/icons/FilterList';
import './TimelinePhone.scss';
import { useI18N } from 'common/i18n/useI18N';


interface TimelineProps {
    content_type?: string;
    title?: string;
    lozenge_content?: string;
    lozenge_title?: string;
    popup_title?: string;
    popup_content?: string;
    questionnaire?: any,
    classes?: string
}

const escapeTildas = (str) => {
    if (str) {
        return str.replace(/~/img, '\\~');
    }
    return '';
};

const timelineMessage = (value): {[key: string]: unknown} => {
    return {
        'document_type': 'message',
        content: {},
        timelineOrder: 1,
        timelineType: 'message',
        timelineDate: new Date(),
        lozenge: {
            title: escapeTildas(value.title),
            description: escapeTildas(value.lozenge_content)
        },
    };
};

const timelineQuestionnaire = (value): {[key: string]: unknown} => {
    return {
        'document': {
            'uuid': '5d8c5df5-aa16-4db3-b397-91d167283aac',
            'document_type': 'questionnaire',
            'content': {
                'linked_composition_uuid': null,
                'questionnaire_name': escapeTildas(value.lozenge_title),
                'status': 'assigned',
                'hide_on_complete': false,
                'hidden_from_timeline': false,
            },
            'isTodo': false,
            'isCompleted': true,
            'isOverdue': false,
            'timelineOrder': 1,
            'timelineTimestamp': 1615888826
        },
        'uuid': '5d8c5df5-aa16-4db3-b397-91d167283aac',
        'isTodo': false,
        'isOverdue': false,
        'timelineOrder': 1,
        'timelineType': 'questionnaire',
        'timelineDate': new Date(),
        'lozenge': {
            'title': escapeTildas(value.lozenge_title),
            'description': escapeTildas(value.lozenge_content)
        },
        'key': '5d8c5df5-aa16-4db3-b397-91d167283aac',
        'isFirstElementInDay': false,
        'questionnaire': value.questionnaire
    };
};

const timelineElements = {
    message: timelineMessage,
    questionnaire: timelineQuestionnaire,
    fallback: timelineMessage
};

const TimelinePhone: FC<TimelineProps> = (value) => {
    const _ = useI18N();
    const type = value.content_type.indexOf('questionnaire') !== -1 ? 'questionnaire' : value.content_type;
    let timeline = timelineElements[type] ? timelineElements[type](value) : timelineElements.fallback(value);


    const popupTitle = value.popup_title;
    const popupContent = value.popup_content;

    if (popupTitle || popupContent) {
        timeline = { ...timeline,
            popup: {
                title: escapeTildas( popupTitle || value.title),
                description:  escapeTildas( popupContent || value.lozenge_content)
            } 
        };
    }

    return (
        <div className={`phone-wrapper ${value.classes}`}>
            <img src="/assets/phone.png" className="phone-bg" alt="phone bg"/>


            <div className="phone-timeline-wrapper">
                <div className="phone-timeline-line" />
                <div className="phone-timeline-header">
                    <div className="phone-timeline-header-icon">
                        <FilterIcon style={{ fontSize: '35px' }} />
                    </div>
                    <h2 className="phone-timeline-header-title">My Pathway</h2>
                </div>
                <TimelineContext.Provider value={{
                    currentLanguage: 'en',
                    getMarkedHTML: (markdown) => {
                        if (!markdown) {
                            return { __html: '' };
                        }
                        return { __html: marked(markdown) };
                    },
                    getTranslate: (str) => _`${TimelineTranslations[str] ? TimelineTranslations[str] : str}`,
                    onTimelineElementClick() {
                        console.log('clicked');
                    }
                }}>
                    <Timeline
                        timeline={[timeline]}
                        showPopup={true}
                    />
                </TimelineContext.Provider>

                <div className="phone-timeline-bottom">
                    <div className="phone-timeline-bottom-element">
                        <div><PersonIcon style={{ fontSize: 20 }} /></div>
                        <span>Profile</span>
                    </div>
                    <div className="phone-timeline-bottom-element">
                        <div><TrendingUpIcon style={{ fontSize: 20 }} /></div>
                        <span>Pathway</span>
                    </div>

                    <div className="phone-timeline-bottom-element">
                        <div><HelpIcon style={{ fontSize: 20 }} /></div>
                        <span>Help</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimelinePhone;
