import React, { FC, useEffect, useReducer } from 'react';
import PageTitle from 'page-title';
import { DropdownSelect, DropdownSelectOption } from 'common/ui/dropdown/DropdownSelect';
import './ResultsPage.less';
import { LineGraph, Line } from 'common/ui/line-graph/LineGraph';
import { UserNote } from 'models/compositions/UserNote';
import { Composition } from 'models/Composition';
import { Label } from 'models/Label';
import _ from 'services/i18n';
import { useNonDischargedReferrals } from 'features/patient/referrals/useNonDischargedReferrals';
import { Appointment } from 'models/compositions/Appointment';
import { Referral } from 'models/compositions/Referral';
import { resultsPageReducer } from './ResultsPageReducer';
import { getAllUserCompositions } from 'services/composition.service';
import { getQuestionnaireResults } from 'features/patient/questionnaire-results/getQuestionnaireResults';
import { QuestionnaireScoreResults } from 'models/QuestionnaireResult';


export interface ResultsPageState {
    form: {
        pathwayContext: string;
        measure: string;
        questionnaireName: string;
        isValid: boolean;
        pathways: { name: string; value: string }[];
        questionnaireNames: { name: string; value: string }[];
        measures: { name: string; value: string }[];
    };
    compositions: {
        referrals: Composition<Referral>[];
        userNotes: Composition<UserNote>[];
        appointment: Composition<Appointment>[];
    };
    noData: boolean;
    loading: boolean;
    lines: Line[];
    timelines: {
        userNotes: Line;
        appointments: Line;
    };
    pathwayLabels: Label[];
    questionnaireResults: QuestionnaireScoreResults;
}

const initialState: ResultsPageState = {
    form: {
        pathwayContext: undefined,
        measure: undefined,
        questionnaireName: undefined,
        isValid: false,
        pathways: [],
        questionnaireNames: [],
        measures: []
    },
    compositions: {
        referrals: [],
        userNotes: [],
        appointment: [],
    },
    noData: true,
    loading: false,
    pathwayLabels: [],
    lines: [],
    timelines: {
        userNotes: undefined,
        appointments: undefined,
    },
    questionnaireResults: {}
};


const arrayDummy: any = [];
export const ResultsPage: FC<{ folderId: number; teamId: number }> = ({ folderId, teamId }) => {

    const [state, dispatch] = useReducer(resultsPageReducer, initialState);
    const {
        form,
        noData,
        lines,
        timelines
    } = state;

    const [referrals] = useNonDischargedReferrals();
    useEffect(() => {
        PageTitle.setTitle(
            'Patient Graphs | PHR Clinical Portal'
        );
        dispatch({ type: 'SET_COMPOSITIONS_REFERRAL', payload: { compositions: referrals || arrayDummy } });
    }, [referrals]);
    useEffect(() => {
        if (!teamId || !folderId) {
            return;
        }
        let cancelPrev;
        getAllUserCompositions<UserNote>({ folderId, teamId, archetype: 'user-note' })
            .then((compositions) => {
                if(cancelPrev) {
                    return;
                }
                dispatch({ type: 'SET_COMPOSITIONS_USER_NOTE', payload: { compositions } });
            });
        getAllUserCompositions<Appointment>({ folderId, teamId, archetype: 'appointment' })
            .then((compositions) => {
                if(cancelPrev) {
                    return;
                }
                dispatch({ type: 'SET_COMPOSITIONS_APPOINTMENT', payload: { compositions } });
            });

        return () => {
            cancelPrev = true;
        };
    }, [teamId, folderId]);
    useEffect(() => {
        if(!form.pathwayContext) {
            return;
        }
        let cancelPrevious;
        getQuestionnaireResults({ folderId, teamId, pathwayContext: form.pathwayContext })
            .then((result) => {
                if(cancelPrevious) {
                    return;
                }
                dispatch({ type: 'QUESTIONNAIRE_RESULTS_UPDATE', payload: { questionnaireResults: result } });
            });

        return () => {
            cancelPrevious = true;
        };
    }, [form.pathwayContext, folderId, teamId]);

    const handleChange = ({ name, value }: { name: string; value: string }) => {
        switch (name) {
        case 'pathway':
            dispatch({ type: 'PATHWAY_CHANGED', payload: { pathwayContext: value } });
            break;
        case 'questionnaire':
            dispatch({ type: 'QUESTIONNAIRE_CHANGED', payload: { questionnaireName: value } });
            break;
        case 'measure':
            dispatch({ type: 'MEASURE_CHANGED', payload: { scoreName: value } });
            break;
        }
    };

    let content;

    if (noData) {
        content = (
            <div className="results-page_select-first">
                {_`No Data`}
            </div>
        );
    } else if (!form.isValid && !timelines.appointments && !timelines.userNotes) {
        content = (
            <div className="results-page_select-first">
                {_`Please select Pathway, Questionnaire and Measure first`}
            </div>
        );
    } else {
        content = (
            <LineGraph
                lines={lines}
                timeLines={[timelines.appointments, timelines.userNotes].filter(Boolean)}
            />
        );
    }

    return (
        <div className={'results-page'}>
            <h3>{_`Questionnaire Results:`}</h3>
            <br/>
            <ResultsForm
                onSelected={handleChange}
                selectedMeasure={form.measure}
                selectedPathway={form.pathwayContext}
                selectedQuestionnaire={form.questionnaireName}
                pathways={form.pathways}
                measures={form.measures}
                questionnaireNames={form.questionnaireNames}
            />
            <div className={'results-page_graph'}>
                {content}
            </div>
        </div>
    );
};

// helpers


interface ResultsFormProps {
    questionnaireNames: { name: string; value: string }[];
    pathways: { name: string; value: string }[];
    measures: { name: string; value: string }[];

    onSelected: ({ name, value }) => void;

    selectedQuestionnaire: string;
    selectedPathway: string;
    selectedMeasure: string;
}
export const ResultsForm: FC<ResultsFormProps> = (props) => {

    const {
        questionnaireNames,
        pathways,
        measures,

        onSelected,

        selectedQuestionnaire,
        selectedPathway,
        selectedMeasure
    } = props;

    const currentPathwayLabel = pathways &&
        pathways.find(({ value }) => value === selectedPathway)?.name || '';
    const currentQuestionnaireLabel = questionnaireNames &&
        questionnaireNames.find(({ value }) => value === selectedQuestionnaire)?.name || '';

    const currentMeasureLabel = measures &&
        measures.find(({ value }) => value === selectedMeasure)?.name || '';

    return (
        <div className={'results-form'}>
            <div className={'results-form_item'}>
                <label>{'Pathway / Context'}</label>
                <DropdownSelect placeholder={'Select Pathway...'}
                    label={currentPathwayLabel}
                    name={'pathway'}
                    onChange={({ target: { name, value } }) => {
                        onSelected({ name, value });
                    }}
                >
                    {pathways?.map(({ name, value }) => {
                        return (
                            <DropdownSelectOption key={value} value={value}>
                                {name}
                            </DropdownSelectOption>
                        );
                    })}
                </DropdownSelect>
            </div>
            <div className={'results-form_item'}>
                <label>{'Questionnaire'}</label>
                <DropdownSelect placeholder={'Select Questionnaire...'}
                    label={currentQuestionnaireLabel}
                    name={'questionnaire'}
                    onChange={({ target: { name, value } }) => {
                        onSelected({ name, value });
                    }}
                >
                    {questionnaireNames?.map(({ name, value }) => {
                        return (
                            <DropdownSelectOption key={value} value={value}>
                                {name}
                            </DropdownSelectOption>
                        );
                    })}
                </DropdownSelect>
            </div>
            <div className={'results-form_item'}>
                <label>{'Measure'}</label>
                <DropdownSelect placeholder={'Select measure...'}
                    label={currentMeasureLabel}
                    name={'measure'}
                    onChange={({ target: { name, value } }) => {
                        onSelected({ name, value });
                    }}
                >
                    {measures?.map(({ name, value }) => {
                        return (
                            <DropdownSelectOption key={value} value={value}>
                                {name}
                            </DropdownSelectOption>
                        );
                    })}
                </DropdownSelect>
            </div>
        </div>
    );
};
