import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import Events from './_events';
import {NotFound, Page, PageLoading} from './_ui';
import { browserHistory } from 'react-router';

/**
 * Extended Page Component for user_portal/dashboard
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            question_id: parseInt(this.props.params.question_id) || 0,
            uuid: this.props.params.uuid,
            questionnaire: {},
            response: {},
            loading: true,
            showSubmitScreen: false,
            hasChanges: false,
            showConfirmModal: false
        };

        this.bindEvents([
            'NextQuestion',
            'LastQuestion',
            'SubmitOrSave',
            'CloseSubmitOrSave',
            'SetAnswer',
            'SaveForLater',
            'Submit',
            'ConfirmDashboard',
            'GoDashboard',
            'GoSubmit',
            'CloseConfirm'
        ], new Events());

        this.bindFunctions([
            'loadComponent'
        ]);
    }

    componentWillReceiveProps (newProps) {
        if (newProps.params.uuid != this.state.uuid) {
            this.$setState({
                question_id: parseInt(newProps.params.question_id) || 0,
                uuid: this.props.params.uuid
            }).then(() => {
                this.loadComponent();
            });
        } else {
            if (newProps.params.question_id != this.state.question_id) {
                this.setState({
                    question_id: parseInt(newProps.params.question_id) || 0
                });
            }
        }
    }

    componentWillMount () {
        this.loadComponent();
    }

    loadComponent () {
        this.$search('coopQuestionnaireResponse', [
            ['uuid','=',this.state.uuid],
            ['content.status', '!=', 'completed']
        ]).then((response) => {
            let result = response.message.results[0];

            if (response.message.results.length == 0) {
                this.setState({
                    notFound: true,
                    response: null
                });
                return Promise.reject();
            }

            this.setState({
                response: result
            });

            return this.$search('coopQuestionnaire', [
                ['content.questionnaire_uuid', '=', result.content.questionnaire_uuid]
            ]);
        }).then((response) => {
            let result = response.message.results[0],
                currentQuestion = result.content.details.questions.find((item) => {
                    if (item.id == this.state.question_id) {
                        return true;
                    }
                    return false;
                });

            if (!currentQuestion && this.state.question_id != 0) {
                browserHistory.push('/coopweb/questionnaire/' + this.state.uuid);
                this.setState({
                    question_id: 0,
                    questionnaire: result,
                    hasChanges: false,
                    loading: false
                });
            } else {
                this.setState({
                    questionnaire: result,
                    hasChanges: false,
                    loading: false
                });
            }
        }, () => {
            return;
        });
    }

    pageTitle () {
        return _`Questionnaire | COOP`;
    }

    render () {
        if (this.state.notFound) {
            return (<NotFound onConfirmDashboard={this.events.onConfirmDashboard} />);
        };

        if (this.state.loading) {
            return (<PageLoading />);
        }

        return (
            <Page events={this.events} state={this.state}/>
        );
    }
};
