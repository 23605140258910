import {
    defaultNumberSort,
    defaultSort,
} from 'common/ui/grid';
import { convertToDate, standardDateTimeFormats } from 'common/datetime/convertToDate';

import { MNDDepressedPatientsWorklistItem } from './MNDDepressedPatientsWorklistItem';

export const getDefaultSortString: (item: MNDDepressedPatientsWorklistItem, key: string) => string = (item, sortKey) => {
    return item[sortKey];
};

export const getMNDDepressedPatientsWorklistString: (item: MNDDepressedPatientsWorklistItem, key: string) => string = (item, key) => {
    return getDefaultSortString(item, key) ?? '--';
};

export const getDefaultSortNumber: (item: MNDDepressedPatientsWorklistItem, key: string) => number = (item, sortKey) => {
    return item[sortKey] ?? -Infinity;
};

export const getMNDDepressedPatientsWorklistNumber: (item: MNDDepressedPatientsWorklistItem, key: string) => number = (item, key) => {
    return getDefaultSortNumber(item, key);
};

export const getDateFilterString: (timestamp: number) => string = (timestamp) => {
    const date = convertToDate(timestamp);
    return date && date.isValid() && date.format(standardDateTimeFormats.nhs_date_short) || '--';
};

export const getMNDDepressedPatientsWorklistFilterString: (item: MNDDepressedPatientsWorklistItem, key: string) => string = (item, key) => {
    switch (key) {
    case 'phq8Timestamp':
        return getDateFilterString(item[key]) ;
    case 'gad7Timestamp':
        return getDateFilterString(item[key]) ;
    case 'phq8Score':
        return getMNDDepressedPatientsWorklistString(item, `${key}String`) || '-';
    case 'gad7Score':
        return getMNDDepressedPatientsWorklistString(item, `${key}String`) || '-';
    case 'phq8ScoreDiff':
    case 'gad7ScoreDiff':
        return `${getMNDDepressedPatientsWorklistString(item, key)}`;
    default:
        return getMNDDepressedPatientsWorklistString(item, key);
    }
};

export const MNDDepressedPatientsWorklistSort = (sortKey, sortOrder) => (a, b) => {
    if ([
        'phq8Score',
        'gad7Score',
        'phq8ScoreDiff',
        'gad7ScoreDiff',
        'phq8Timestamp',
        'gad7Timestamp',
    ].indexOf(sortKey) > -1) {
        const aNumber = getMNDDepressedPatientsWorklistNumber(a, sortKey);
        const bNumber = getMNDDepressedPatientsWorklistNumber(b, sortKey);

        return defaultNumberSort(aNumber, bNumber, sortOrder);
    }

    const aString = getMNDDepressedPatientsWorklistString(a, sortKey);
    const bString = getMNDDepressedPatientsWorklistString(b, sortKey);

    return defaultSort(aString, bString, sortOrder);
};

export function getNameFromDemographics(demographic) {
    if (!demographic) { return ''; }
    const demographicContent = demographic.content;
    const nameObject = (demographicContent.preferred_name || demographicContent.name);

    if (!nameObject) {
        return '';
    }

    return `${nameObject.given_name || ''} ${nameObject.family_name || ''}`.trim();
}

export function getPatientUrl(folderId) {
    return `/clinical_portal/folder/${folderId}/patient/questionnaires`;
}

export function getPatientGraphsURL(folderId) {
    return `/clinical_portal/folder/${folderId}/patient/graphs`;
}

export function getNHSFromDemographics(demographic) {
    return (demographic && demographic.folder && demographic.folder.person_primary_identifier && demographic.folder.person_primary_identifier.value) || '';
}

const getScores = (score: string): [number, string] => {
    const [scoreValue, scoreString] = score.split(' ');

    if (`${parseInt(scoreValue, 10)}` === scoreValue) {
        return [parseInt(scoreValue, 10), `${scoreValue}${scoreString ? ` (${scoreString})` : ''}`];
    }

    return [undefined, score];
};

const getDiff = (score: number, previousScore: number, previousScoreString: string) => {
    if (score === undefined || previousScore === undefined) {
        return previousScoreString;
    }

    return (score - previousScore).toString();
};

export function getMNDQuestionnaireInfo (questionnaireName, questionnaireScores, questionnaireResponses, folderId) {
    const resultQuestionnaireInfo = {
        date: 0,
        score: '',
        scoreString: '',
        diff: '-',
        url: ''
    };

    const completedQuestionnaireResponses = questionnaireResponses.filter((item) => {
        return item.content.questionnaire_name === questionnaireName;
    }).filter((item) => {
        return item.content.status_changes?.scored_at;
    });

    if (completedQuestionnaireResponses.length === 0) {
        return resultQuestionnaireInfo;
    }

    const sortQuestionnaireResponsesByDate = completedQuestionnaireResponses.sort((a, b) => {
        return a.content.status_changes.scored_at - b.content.status_changes.scored_at;
    });

    const lastQuestionnaireResponse = sortQuestionnaireResponsesByDate.pop();

    const questionnaireTimestamp = lastQuestionnaireResponse && lastQuestionnaireResponse.content && lastQuestionnaireResponse.content.status_changes && lastQuestionnaireResponse.content.status_changes.completed_at;

    resultQuestionnaireInfo.date = questionnaireTimestamp;
    resultQuestionnaireInfo.url = `/clinical_portal/folder/${folderId}/patient/questionnaires/${lastQuestionnaireResponse.uuid}?questionnaire`;

    const lastCompleteQuestionnaireScore = questionnaireScores.filter((item) => {
        return (item.content.questionnaire_response_uuid === lastQuestionnaireResponse.uuid);
    });

    if (lastCompleteQuestionnaireScore.length === 0) {
        return resultQuestionnaireInfo;
    }

    const lastCompleteQuestionnaireScoreValue = lastCompleteQuestionnaireScore[0].content.scores[0].score_value_string;

    const [scoreValue, scoreString] = getScores(lastCompleteQuestionnaireScoreValue);
    resultQuestionnaireInfo.scoreString = scoreString;
    resultQuestionnaireInfo.score = scoreValue === undefined ? undefined : scoreValue.toString();

    if (completedQuestionnaireResponses.length === 0) {
        return resultQuestionnaireInfo;
    }

    const penultQuestionnaireResponse = sortQuestionnaireResponsesByDate.pop();

    const penultCompleteQuestScore = questionnaireScores.filter((item) => {
        return (item.content.questionnaire_response_uuid === penultQuestionnaireResponse.uuid);
    });

    if (lastCompleteQuestionnaireScore.length === 0) {
        return resultQuestionnaireInfo;
    }

    const [penultScoreValue, penultScoreString] = getScores(penultCompleteQuestScore[0].content.scores[0].score_value_string);
    resultQuestionnaireInfo.diff = getDiff(scoreValue, penultScoreValue, penultScoreString);

    return resultQuestionnaireInfo;
}
