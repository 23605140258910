import React from 'react';
import BasePage from 'components/page/base';

import { Row, Column } from 'ui';
import { TabNavigator, NavTab } from 'ui/tab/navigator';

export default class WorklistsPage extends BasePage {
    constructor (props) {
        super(props);
        this.baseRoute = '/clinical_portal/my-team/worklists';
    }

    pageTitle () {
        return _`Worklists | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="page">
                <Row>
                    <Column sm='12'>
                        <TabNavigator
                            currentRoute={this.props.location.pathname}
                            tabPosition='top'
                            tabStyle='tab'
                            routes={this.props.routes}
                        >
                            <NavTab title='Transport &amp; Interpreter' route={`${this.baseRoute}/transport-interpreter`}
                                prefixMatch={false} />
                            <NavTab title='Antenatal' route={`${this.baseRoute}/antenatal`} prefixMatch={false} />
                            <NavTab title='Priority Work List' route={`${this.baseRoute}/mnd`} prefixMatch={false} />
                            <NavTab title='MND Worklist' route={`${this.baseRoute}/mnd-worklist`} prefixMatch={false} />
                            <NavTab title='Attend Anywhere' route={`${this.baseRoute}/attend-anywhere`} prefixMatch={false} />
                        </TabNavigator>
                    </Column>

                    <Column sm='12'>
                        {this.props.children}
                    </Column>
                </Row>
            </div>
        );
    }
}
