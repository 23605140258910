import { CompositionService } from './composition.service';
import { IFolderSelectorParam, IRole } from 'phr-api-client';
import { isEmpty as _isEmpty } from 'lodash';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';

export type TerAction = 'assignQuestionnaire'
    | 'assignCoopQuestionnaire'
    | 'sendInvitation'
    | 'sendMessage'
    | 'setUserPreference'
    | 'setLabel'
    | 'unsetLabel'
    | 'allocateResourceWithMessage'
    | 'inviteClinician'
    | 'deregister'
    | 'changeEmail'
    | 'createUser'
    | 'updateUser'
    | 'removeUserFromTeam'
    | 'sendPushNotification'
    | 'setAppointmentMode'
    | 'assignSimpleQuestionnaire'
    | 'sendSms'
    | 'sendEmail'
    | 'sendHtmlLetter'
    | 'pifu'
    | 'autoCommunication'
    | 'assignTeamResources'
    | 'assignPathwayEvent'

interface TerComposition {
    action: string;
    status: string;
    data: undefined | TriggerEngineRequestContent;
}

export type TerParams = {
    action: TerAction;
    data?: TriggerEngineRequestContent;
    role?: IRole;
    folderId?: IFolderSelectorParam;
}

class TerService extends CompositionService {

    get archetypeName(): string {
        return 'trigger-engine-request';
    }

    public createTer({ action, data, role, folderId }: TerParams): Promise<any> {

        const terComposition: TerComposition = {
            action,
            status: 'pending',
            data: !_isEmpty(data) ? data : undefined
        };

        return this.create({ content: terComposition, role, folderId });
    }
}

export const terService = new TerService();
