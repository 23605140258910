import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { appointmentsService } from 'services/appointments.service';
import referralsService from 'services/referrals.service';
import { AppointmentsIndex } from 'features/patient/appointments/AppointmentsIndex';

export default class AppointmentsPage extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            results: [],
            referrals: [],
            loading: true
        };

        this.bindFunctions(['loadComponent']);
    }

    componentDidMount () {
        this.loadComponent();
    }

    loadComponent() {
        referralsService.getReferrals({ teamId: this.props.teamId, folderId: this.props.folderId }).then((res) => {
            this.setState({
                referrals: res,
                loading: false
            });
        });
        appointmentsService.list({ folderId: this.props.folderId, limit: Infinity })
            .then((response) => {
                this.setState({
                    results: response.message.results,
                    loading: false
                });
            });
    }

    pageTitle () {
        return _`Appointments` + ' | ' + _`MyPathway Clinical Portal`;
    }

    render () {
        return (
            <AppointmentsIndex
                path={`/clinical_portal/folder/${this.props.folderId}/patient/appointments`}
                folderId={this.props.folderId}
                teamId={this.props.teamId}
                loading={this.state.loading}
                referrals={this.state.referrals}
                appointments={this.state.results}
            />
        );
    }
}
