import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import DOMPurify from 'dompurify';
import './PlainHtmlToIframe.scss';


export const PlainHtmlToIframe: FC<{sanitize?: boolean; className?: string }> = ({
    children,
    sanitize = true,
    ...props
}) => {
    const [iframeElement, setIframeElement] = useState<HTMLIFrameElement>(null);
    const [iframeContentHeight, setIframeContentHeight] = useState(0);
    const mountNode = iframeElement?.contentWindow?.document?.body;

    const content = useMemo(() => {
        let html = (children ?? '') as string;
        if(sanitize) {
            html = DOMPurify.sanitize(children as Node, { FORCE_BODY: true });
        }
        return <div dangerouslySetInnerHTML={{ __html: html } } />;
    }, [children, sanitize]);

    useLayoutEffect(() => {
        if(!iframeElement) {
            return;
        }
        const document = iframeElement.contentWindow.document;
        const innerHeight = document.body.offsetHeight + 50;
        // trick for Firefox https://stackoverflow.com/questions/15036514/why-can-i-not-set-innerhtml-of-an-iframe-body-in-firefox?rq=1
        // otherwise iframe will be empty in ff
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if(isFirefox) {
            document.open();
            document.close();
        }
        //\
        setIframeContentHeight(innerHeight);
        iframeElement.onload = () => {
            replaceLinkTargetsForIframeLinks(iframeElement);
        };
    }, [iframeElement]);

    return (
        <iframe
            style={{ height: `${iframeContentHeight}px` }}
            className={`portal-iframe${props.className ? ` ${props.className}` : ''}`}
            ref={setIframeElement}
        >
            {mountNode && createPortal(content, mountNode)}
        </iframe>
    );
};


/**
 * @description
 * In most of the cases when user clicks on the link with target="_self"
 * (it is default value, i.e when target is not set explicitly the link has _self target by default)
 * within iframe - he will see nothing but blank iframe container with message ~it was unable to load a page
 * or, at best, the page will be open inside iframe container which is probably unwanted behavior.
 * So, to prevent this behavior the function set target="_blank" to all links which
 * 1) don't have explicitly set target or it is set to _self
 * and
 * 2) have href value
 * and
 * 3) href do not set to "javascript:void(0)" - otherwise when user clicks on link with href="javascript:void(0)"
 *    and it will have target="_blank" - it opens just new blank page
 * @param iframe {HTMLIFrameElement}
 */
function replaceLinkTargetsForIframeLinks (iframe: HTMLIFrameElement) {
    const links = iframe.contentWindow.document.getElementsByTagName('a');
    Array.from<HTMLAnchorElement>(links).forEach(a => {
        if((a.target === '_self' || !a.target) && a.href && !/javascript:\s?void\(0\)/.test(a.href)) {
            a.target = '_blank';
        }
    });
}
