import React from 'react';
import View from './view';
import BaseComponent from 'components/BaseComponent';
import {checkAllowed} from 'helpers/patient-links';

export default class InviteBarComponentContainer extends BaseComponent {
    constructor (props) {
        super(props);

        this.state = {
            patient: this.props.patient,
        };

        this.bindEvents([
            'CheckAllowed'
        ]);

        this.View = View;
    }

    handleCheckAllowed (item) {
        return checkAllowed(item);
    }

    componentWillReceiveProps (newProps) {
        let changed = false;

        if (newProps.patientId !== this.props.patientId) {
            changed = true;
        }

        if (!changed) {
            return;
        }

        this.setState({
            patient: newProps.patient
        });
    }
}
