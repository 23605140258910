import { useCallback, useState } from 'react';
import {
    Appointment,
    AppointmentProposedParticipantStatus, AppointmentProposedParticipantStatuses
} from 'models/compositions/Appointment';
import { OptionsType } from 'react-select';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { Composition } from 'models/Composition';
import { TeamPreferences } from 'models/TeamPreferences';

export type ApprovePIFUAppointmentFormState = {
    outcomeAction: AppointmentProposedParticipantStatus;
    notesToStaff: string;
    responseToPatient: string;
};

const defaultState: ApprovePIFUAppointmentFormState = {
    outcomeAction: 'needs-attention',
    responseToPatient: '',
    notesToStaff: '',
};

export function getProposedParticipantStatusList(): OptionsType<{value: string; label: string}> {
    return AppointmentProposedParticipantStatuses.map(status => {
        return { value: status, label: getProposedParticipantStatusActionDescription(status) };
    });
}

function getProposedParticipantStatusActionDescription(status: AppointmentProposedParticipantStatus) {
    switch (status) {
    case 'needs-attention':
        return 'Needs Attention';
    case 'needs-further-information':
        return 'Phone Triage';
    case 'declined':
        return 'Decline and Advise';
    case 'accepted':
        return 'Accept and Book';
    default:
        console.error(`Appointment proposed-participant status ${status} has no display name`);
    }
    return status;
}

export function isCompletedProposedParticipantStatus(status: AppointmentProposedParticipantStatus) {
    return status === 'accepted'
        || status === 'declined';
}

export function useApprovePIFUAppointmentFormState(
    appointment: Composition<Appointment>,
): {
    state: ApprovePIFUAppointmentFormState;
    setResponseToPatient: (s: string) => void;
    setNotesToStaff: (s: string) => void;
    setOutcomeAction: (action: AppointmentProposedParticipantStatus) => void;
} {
    const proposedParticipant = appointment.content.proposed_participant;
    const [outcomeAction, setOutcomeAction] = useState(
        proposedParticipant?.status || defaultState.outcomeAction,
    );
    const [responseToPatient, setResponseToPatient] = useState(
        proposedParticipant?.notes || defaultState.responseToPatient,
    );
    const [notesToStaff, setNotesToStaff] = useState(
        proposedParticipant?.booking_notes || defaultState.notesToStaff,
    );

    return {
        state: {
            outcomeAction,
            responseToPatient,
            notesToStaff,
        },
        setResponseToPatient,
        setNotesToStaff,
        setOutcomeAction,
    };
}

export function useDefaultPatientTextForOutcome(): (newOutcome: AppointmentProposedParticipantStatus) => string {
    const teamPreferences = useTeamPreferences();
    return useCallback((newOutcome: AppointmentProposedParticipantStatus) => {
        return getDefaultPatientTextForOutcome(teamPreferences, newOutcome);
    }, [teamPreferences]);
}

function getDefaultPatientTextForOutcome(
    teamPreferences: TeamPreferences,
    outcomeAction: AppointmentProposedParticipantStatus
): string {
    if (!teamPreferences
      || !teamPreferences.pifu
      || !teamPreferences.pifu.pifu_default_patient_texts) {
        return '';
    }
    const defaultTexts = teamPreferences.pifu.pifu_default_patient_texts;
    const defaultText = defaultTexts.find(defaultText => defaultText.proposed_participant_status === outcomeAction);
    return defaultText ? defaultText.default_patient_text : '';
}
