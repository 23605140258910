import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { Link } from 'react-router';
/**
 * Extended Page Component for user_portal/login
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            confirmed: null,
            hasInvite: null,
            inviteConfirm: null
        };
    }

    pageTitle () {
        return _`Confirm your email address | iFocus Healthcare`;
    }

    componentWillMount () {
        let token = this.props.params.token;

        if (!token) {
            this.setState({
                confirmed: "error"
            });
            return;
        }

        this.server.postRequest('/register-confirm', {
            token: token
        }, (response) => {
            let inviteConfirm, hasInvite = 0;
            if (response.status == 'error') {
                this.setState({
                    confirmed: "error",
                    inviteConfirm: false
                });
            } else {
                if (response.invite.status == 'error') {
                    inviteConfirm = "error";
                    hasInvite = true;
                } else {
                    inviteConfirm = 'confirmed';
                    hasInvite = response.invite.invite;
                }

                this.setState({
                    confirmed: "confirmed",
                    inviteConfirm: inviteConfirm,
                    hasInvite: hasInvite
                });
            }
        });
    }


    render () {
        let msg, inviteMsg = false;

        if (this.state.confirmed === null) {
            msg = _`Confirming your registration. Please wait`;
        }

        if (this.state.confirmed === 'error') {
            msg = _`Confirmation failed. There was an issue with your token. Maybe you have already confirmed your email?`;
        }

        if (this.state.confirmed === 'confirmed') {
            msg = [
                (<p key="1">{_`Confirmation accepted. Your account is now active. You may now login to the admin portal.`}</p>),
                (<Link key="2" to="/admin_portal/login" className="btn btn-default">Go to Admin Portal Login</Link>)
            ];
        }

        if (this.state.hasInvite) {
            if (this.state.inviteConfirm == 'error') {
                inviteMsg = _`Invitation failed. Please contact your administrator to request a new invitation.`;
            } else {
                inviteMsg = _`Invitation accepted. Your account has been linked.`;
            }
        }

        return (
            <div className="sign-up-confirm" style={{paddingTop: '20px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-offset-4 col-lg-4 col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8">
                            <div className="panel panel-default">
                                <div className="panel-heading"><h2 className="panel-title">Admin Portal: Confirm your
                                    registration</h2></div>
                                <div className="panel-body panel-body-relative">
                                    <div>{msg}</div>
                                    {inviteMsg?<p>{inviteMsg}</p>:null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}