import React from 'react';
import { Component } from 'react';
import * as d3 from 'd3';
import styles from './steps-chart.less';

const FINISH_FLAG_IMAGE_PATH = '/assets/flag.png';

export class StepsChart extends Component {

    constructor(props) {
        super(props);

        Object.assign(this,{
            barFontSize: 30,
            flagsHeight: 10,
            margins: {
                top: 20,
                right: 10,
                bottom: 20,
                left: 2
            },
            yAxis: {
                width: 40
            },
            xAxis: {
                height: 20
            },
            axisMargin: 4,
            svgSize: {
                width: 0,
                height: 0
            },
            canvas: {
                width: 0,
                height: 0
            },
            svgElement: undefined,
            d3Svg: undefined,
            d3Line: undefined,
            yAxisGroup:undefined,
            xAxisGroup: undefined,
            canvasGroup: undefined,
            lineGroup: undefined,
            line: undefined,
            flags: undefined,
            bars: undefined
        });

        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.d3Svg = d3.select(this.svgElement);

        this.setSvgSize();
        this.setCanvasSize();
        if (this.props.data && this.props.data.length) {
            this.createGroups();
            this.updateYAxis();
            this.setCanvasSize();
            this.updateXAxis();
            this.drawBars();
            this.d3Line = d3.line()
                .x((d) => d.x)
                .y((d) => this.scaleY(d.y));
            this.drawThresholdLine();
        }
        window.addEventListener('resize', this.update);
    };

    componentDidUpdate(){
        this.update();
    }

    update() {
        if (this.props.data && this.props.data.length) {
            this.setSvgSize();
            this.setCanvasSize();
            this.updateYAxis();
            this.setCanvasSize();
            this.updateXAxis();
            this.drawBars();
            this.d3Line = d3.line()
                .x((d) => d.x)
                .y((d) => this.scaleY(d.y));
            this.drawThresholdLine();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update);
    }


    drawBars() {

        if (this.canvas.width < 600) {
            this.barFontSize = 16;
        } else {
            this.barFontSize = 30;
        }

        this.canvasGroup.attr(...transform(this.margins.left + this.axisMargin + this.yAxis.width, this.margins.top));

        this.bars = (this.bars ? this.bars : this.canvasGroup.selectAll('g.bar-group')).data(this.props.data, (v) => v.x);

        this.bars.exit().remove();

        const newBars = this.bars.enter()
            .append('g')
            .attr('class', 'bar-group');
        newBars.append('rect');
        newBars.append('text');

        this.bars = newBars.merge(this.bars);
        this.bars
            .attr('transform', (v) => transform(this.scaleX(v.x), this.scaleY(v.y))[1]);

        this.bars.select('text')
            .attr('x', this.scaleX.bandwidth() / 2)
            .attr('y', (v) => {
                const barHeight = this.canvas.height - this.scaleY(v.y);

                if (barHeight > (this.barFontSize + 10)) {
                    return barHeight / 2 + this.barFontSize / 3;
                } else {
                    return -5;
                }

            })
            .text((d) => d.y)
            .attr('fill', d => d.color)
            .classed('outside-of-bar', (d) => {
                const barHeight = this.canvas.height - this.scaleY(d.y);
                return (barHeight < (this.barFontSize + 10));
            })
            .attr('font-size', this.barFontSize);

        this.bars.select('rect')
            .attr('width', this.scaleX.bandwidth())
            .attr('height', (v) => {
                return this.canvas.height - this.scaleY(v.y);
            })
            .attr('fill', (v) => v.backgroundColor);
    }

    drawThresholdLine() {

        this.lineGroup.attr(...transform(this.margins.left + this.axisMargin + this.yAxis.width, this.margins.top));
        const lineMargin = 30;
        const leftDot = lineMargin;
        const rightDot = this.canvas.width - lineMargin;

        if (this.props.threshold) {

            const data = [{x: leftDot, y: this.props.threshold}, {x: rightDot, y: this.props.threshold}];

            this.line = this.line ? this.line : this.lineGroup.append('path');
            this.line = this.line.datum(data);
            this.line.attr('d', this.d3Line);
            this.flags = (this.flags ? this.flags : this.lineGroup.selectAll('g.flags')).data(data, d => d.x);
            this.flags.exit().remove();

            const newFlags = this.flags.enter()
                .append('g').classed('flags', true);
            newFlags.append('image').attr('xlink:href', FINISH_FLAG_IMAGE_PATH);

            this.flags = this.flags.merge(newFlags)
                .attr('transform', (d) => transform(d.x, this.scaleY(d.y))[1]);

            const flagsHeight = this.canvas.height * 0.01 * this.flagsHeight;
            const flagsWidth = flagsHeight * 0.8;

            this.flags.select('image')
                .attr('height', flagsHeight)
                .attr('width', flagsWidth)
                .attr('x', -flagsWidth * 0.08)
                .attr('y', -flagsHeight);

        } else if (this.line) {
            this.lineGroup.selectAll('path').remove();
            this.lineGroup.selectAll('g.flags').remove();
            this.line = null;
            this.flags = null;
        }
    }

    setCanvasSize(){
        this.canvas.width = this.svgSize.width - (this.margins.left + this.margins.right + this.yAxis.width + this.axisMargin);
        this.canvas.height = this.svgSize.height - (this.margins.top + this.margins.bottom + this.xAxis.height + this.axisMargin);
    }

    createGroups() {
        this.yAxisGroup = this.d3Svg.append('g').classed('y-axis-group', true);
        this.xAxisGroup =  this.d3Svg.append('g').classed('x-axis-group', true);
        this.canvasGroup = this.d3Svg.append('g').classed('canvas-group', true);
        this.lineGroup = this.d3Svg.append('g').classed('threshold-line-group', true);
    }

    updateYAxis() {
        let maxSteps = d3.max([...this.props.data.map(v => v.y), (this.props.threshold || 0)]);

        const appropriateHeight = this.props.threshold + (this.props.threshold / (100 - this.flagsHeight) * this.flagsHeight);
        if (maxSteps < appropriateHeight) {
            maxSteps = appropriateHeight;
        }

        this.scaleY = d3.scaleLinear()
            .domain([maxSteps, 0])
            .range([0, this.canvas.height]);
        const axisY = d3.axisLeft().scale(this.scaleY);
        this.yAxisGroup.call(axisY);
        this.yAxis.width = Math.round(this.yAxisGroup.selectAll('g.tick').nodes()[0].getBoundingClientRect().width);
        this.yAxisGroup.attr(...transform(this.margins.left + this.yAxis.width, this.margins.top));
    }

    updateXAxis() {
        this.scaleX = d3.scaleBand()
            .domain(this.props.data.map(v => v.x))
            .range([0, this.canvas.width])
            .paddingInner(0.05);
        const axisX = d3.axisBottom().scale(this.scaleX).tickSize(0).tickPadding(12);
        this.xAxisGroup.call(axisX);
        this.xAxisGroup.attr(...transform(
            this.margins.left + this.yAxis.width + this.axisMargin,
            this.svgSize.height - this.margins.bottom - this.xAxis.height
        ));

    }

    setSvgSize() {
        this.svgSize = this.svgElement.getBoundingClientRect();
    }

    render() {
        return <svg className="step-graph step-graph__svg" ref={node => this.svgElement = node}></svg>;
    }
}

function transform(x, y) {
    return ['transform', `translate(${x || 0}, ${y || 0})`];
}
