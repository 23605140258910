import React, { useCallback, useEffect, FC } from 'react';
import Select from 'react-select';
// utils
import {
    getDisplayListForQueryValueOptions,

} from 'models/AdvancedSearchDefinition';
// interfaces
import { AdvancedSearchCriterionListInputSingleSelectionProps } from './AdvancedSearchCriterionListInputSingleSelection.interface';
import { getDisplayValueForValueOption } from 'common/ui/dropdown/DropdownUtils';

export const AdvancedSearchCriterionListInputSingleSelection: FC<AdvancedSearchCriterionListInputSingleSelectionProps> =
    (props) => {
        const optionList = getDisplayListForQueryValueOptions(props.config);
        const valueWithDefault = getDisplayValueForValueOption(
            optionList,
            props.value,
        );
        const onChange = props.onChange;
        const onValueChange = useCallback(
            (newValue) => onChange(newValue.value),
            [onChange],
        );

        useEffect(() => {
            if (optionList.length == 1) {
                onValueChange(optionList[0]);
            }
        }, [onValueChange, optionList]);

        return (
            <Select
                value={valueWithDefault}
                options={optionList}
                onChange={onValueChange}
                className={'react-select-input-container'}
                classNamePrefix={'react-select-input'}
            />
        );
    };
