import React, { FC } from 'react';
import HighlightOff from '@material-ui/icons/HighlightOff';
// styles
import './PIFUModal.style.scss';

type PIFUModalProps = {
    onClose: () => void;
};

const PIFUModal: FC<PIFUModalProps> = ({ onClose, children }) => {    
    return (
        <div className="pifu-modal__wrapper">
            <div className="pifu-modal__background" onClick={onClose} />
            <div className="pifu-modal">
                <div
                    className='pifu-modal__close'
                    onClick={onClose}
                >
                    <HighlightOff  style={{ fontSize: 30 }} />
                </div>
                <div className="pifu-modal__content">{children}</div>
            </div>
        </div>
    );
};

export default PIFUModal;
