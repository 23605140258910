export type SendSmsState = {
    message: string;
}

export function validate(state: SendSmsState, onErrors: (errors: string[]) => void): boolean {
    const normalisedState = normalise(state);
    const errors = [];

    if (normalisedState.message === '') errors.push('SMS content is required');
    onErrors(errors);
    return errors.length === 0;
}

export function normalise(state: SendSmsState): SendSmsState {
    return {
        message: state.message.trim()
    };
}
