import { Composition } from 'models/Composition';
import { Referral } from 'models/compositions/Referral';
import { Label } from 'models/Label';
import lodash from 'lodash';

export function getActivePathwayLabelsFromReferrals(referrals: Composition<Referral>[]): Label[] {
    let newLabels = referrals.reduce((acc, ref) => {
        const labels = filterActivePathwayLabels(ref.content.labels)
            .map((label) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                label.referral_uuid = ref.uuid;
                return label;
            });
        return acc.concat(labels);
    }, []);
    newLabels = getUniqLabels(newLabels);
    return newLabels;
}

export function filterActivePathwayLabels(labels: Label[] = []) {
    return labels.filter(({ status, type }) => {
        return status && type === 'pathway';
    }) || [];
}

export function getUniqLabels(labels: Label[]) {
    return lodash.uniqWith(labels, (a, b) => {
        return a.name === b.name &&
            a.type === b.type &&
            a.context === b.context &&
            a.status === b.status;
    });
}
