import React, { useState } from 'react';
import { useEffect } from 'react';
import { BulkOperationCostEstimator } from './BulkOperationCostEstimator';
import { CostEstimateValue, PendingCostEstimateValue, CostEstimateStatusType } from './CostEstimateValue';

type BulkOperationPreviewProps = {
    previewComponent: JSX.Element;
    costEstimator: BulkOperationCostEstimator;
};

export function BulkOperationPreview(props: BulkOperationPreviewProps)
{
    return (
        <div className="row action-preview-container">
            <div className="col-sm-10 action-preview-component">{props.previewComponent}</div>
            <div className="col-sm-2 action-preview-cost-estimate"><BulkOperationCostEstimate estimator={props.costEstimator} /></div>
        </div>
    );
}

type BulkOperationCostEstimateProps = {
    estimator: BulkOperationCostEstimator;
};

function BulkOperationCostEstimate(props: BulkOperationCostEstimateProps): JSX.Element {
    const [cost, onCostChange] = useState<CostEstimateValue>(PendingCostEstimateValue);

    useEffect(() =>
    {
        let onEstimatePromiseResolve = (newCost: CostEstimateValue) => {onCostChange(newCost);};
        const estimatePromise: Promise<CostEstimateValue> = props.estimator.estimateCost();
        estimatePromise.then((newCost: CostEstimateValue) => onEstimatePromiseResolve(newCost));

        return () => {onEstimatePromiseResolve = () => {return;};};
    }, [props.estimator]);

    const costDescription = cost.status ==CostEstimateStatusType.COMPLETE
        ? cost.value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2 })
        : cost.description;
    return <div>{costDescription}</div>;
}
