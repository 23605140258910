import { useState, useEffect } from 'react';
import DataStore from 'services/data-store';

export function useCurrentTeam() {
    const [team, setTeam] = useState(undefined);

    useEffect(() => {
        const listenIdx1 = DataStore.addListener('me.currentTeam',
            /**
             * @param  {Object} team={}
             */
            (team = {}) => {
                setTeam({
                    teamId: team.teamid,
                    teamName: team.teamname || 'Unselected',
                    teamService: team.serviceName || '',
                    consents: team.consents,
                    teamFolderId: team.teamFolderId,
                });
            }
        );

        const listenIdx2 = DataStore.addListener('me.currentRole',
            /**
             * @param  {Object} role={}
             */
            (role = {}) => {
                setTeam({
                    teamId: role.teamId,
                    teamName: role.team || 'Unselected',
                    teamService: role.serviceName || 'MSK',
                    teamFolderId: 'team',
                });
            }
        );

        const role = DataStore.get('me.currentRole');
        if (role) {
            setTeam({
                teamId: role.teamId,
                teamName: role.team || 'Unselected',
                teamService: role.serviceName || '',
                teamFolderId: 'team',
            });
        } else {
            const currentTeam = DataStore.get('me.currentTeam') || {};

            setTeam({
                teamId: currentTeam.teamid,
                consents: currentTeam.consents,
                teamName: currentTeam.teamname || 'Unselected',
                teamService: currentTeam.serviceName || '',
                teamFolderId: currentTeam.teamFolderId,
            })
        }

        return () => {
            DataStore.removeListener(listenIdx1);
            DataStore.removeListener(listenIdx2);
        };
    }, []);

    return team;
}
