import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';
import { teamsService } from 'services/teams.service';
import { organisationsService } from 'services/organisations.service';

export default class List extends BaseComponent {
    constructor (props) {
        super(props);

        this.View = View;

        this.state = {
            page: 1,
            count: 0,
            perPage: 10,
            search: '',
            errors: [],
            results: [],
            loading: true,
            modal: false,
            modalId: false,
            modalBusy: false,
            service: null, // number!
            teamName: ''
        };

        this.bindEvents([
            'Change',
            'ClickCreateTeam',
            'ClickEditTeam',
            'CloseModal',
            'SubmitModal',
            'ChangePage'
        ]);

        this.bindFunctions([
            'onRequest'
        ]);
    }

    handleClickCreateTeam () {
        this.setState({
            modal: 'manage',
            modalId: false,
            errors: [],
            service: null,
            teamName: ''
        });
    }

    handleClickEditTeam (item) {
        this.setState({
            modal: 'manage',
            modalId: item.ID,
            teamName: item.Name,
            service: item.Service,
            errors: [],
        });
    }

    handleCloseModal () {
        this.setState({
            modal: false,
            modalId: false,
            teamName: ''
        });
    }

    handleSubmitModal (e) {
        let operations = [], errors = [], safeName = this.state.teamName.trim().toLowerCase(), unique;

        e.preventDefault();

        if (safeName == '') {
            errors.push('The team requires a name');
        }

        if (this.state.service == null) {
            errors.push('The team must have a service');
        }

        unique = this.state.results.every(item => item.Name.trim().toLowerCase() != safeName);

        if (!unique) {
            errors.push('This team name is already in use.');
        }

        if (errors.length > 0) {
            this.setState({
                errors: errors,
                modalBusy: false
            });
            return;
        }

        this.setState({
            modalBusy: true
        });


        if (this.state.modalId) {
            operations.push(
                {op: 'replace', path: '/name', value: this.state.teamName}
            );

            teamsService.editTeam({ operations, teamId: this.state.modalId }).then(() => {
                this.setState({
                    modal: false,
                    modalBusy: false,
                    modalId: false,
                    teamName: ''
                });

                this.onRequest();
            });
        } else {
            const newTeamData = {
                organisation_id: 0,
                parent_team_id: 0,
                name: this.state.teamName,
                service_id: this.state.service
            };

            if (this.props.listType === 'organisation') {
                newTeamData.organisation_id = this.props.organisation.id;
            } else {
                newTeamData.parent_team_id = this.props.team.teamid;
                newTeamData.organisation_id = this.props.team.organisation_id;
            }

            teamsService.creatNewTeam(newTeamData).then(() => {
                this.setState({
                    modal: false,
                    modalBusy: false,
                    modalId: false,
                    teamName: ''
                });

                this.onRequest();
            });
        }
    }

    handleChangePage (page) {
        this.setState({
            page: page
        },this.onRequest);
    }

    handleChange (e) {
        if (e.target.name == 'search') {
            this.setState({
                search: e.target.value
            });
        }
        if (e.target.name == 'teamName') {
            this.setState({
                teamName: e.target.value
            });
        }

        // only allow changes if modalId is false
        if (!this.state.modalId) {
            if (e.target.name == 'service') {
                this.setState({
                    service: Number(e.target.value) || null
                });
            }
        }
    }

    onRequest () {
        let promise;

        this.setState({
            loading: true
        });

        if (this.props.listType === 'organisation') {
            promise = organisationsService.teams(this.props.organisation.id);
        } else {
            promise = teamsService.list({teamId: this.props.team.teamid});
        }

        organisationsService.services().then((message) => {
            return this.$setState({
                services: message?.results || []
            });
        });


        promise.then((message) => {
            let results = (message?.results || []).map((item) => {
                return {
                    "Created At": item.created_at,
                    "ID": item.id,
                    "Name": item.name,
                    "Service": item.service_id,
                    "Parent Team": item.parent_team_id,
                    "Updated At": item.updated_at
                };
            });
            this.setState({
                loading: false,
                page: message?.meta?.page,
                count: message?.meta?.count,
                perPage: message?.meta?.per_page,
                results: results
            });
        });
    }

    componentDidMount() {
        this.onRequest();
    }
}
