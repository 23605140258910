import React from 'react';
import { FormGroup } from 'ui';

type PushNotificationCreateProps = {
  onChange: (e) => unknown;
  sendPush: boolean;
}

export const PushNotificationCreateForm = ({
  onChange,
  sendPush
}: PushNotificationCreateProps) => {
  return (
    <FormGroup>
      <label>
        <input
          type="checkbox" 
          name={'sendPush'} 
          onChange={(e) => { onChange({ sendPush: e.target.checked }) }} 
          value={'' + sendPush} 
          checked={sendPush} /> Notify patient by push notification
      </label>
    </FormGroup>
  );
}