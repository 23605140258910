import React, { FC, memo } from 'react';
import { toast } from 'react-toastify';
import './toast.less';


class Toast {
    default = ({ title, content, ...options }) => {
        return toast(<GenericToast
            iconClassName={'fas fa-info-circle'}
            content={content}
            title={title}
        />, { autoClose: 10000, ...options });
    }

    info = ({ title, content, ...options }) => {
        return toast.info(<GenericToast
            iconClassName={'fas fa-info-circle'}
            content={content}
            title={title}
        />, { autoClose: 10000, ...options });
    }

    success = ({ title, content, ...options }) => {
        return toast.success(<GenericToast
            iconClassName={'fas fa-info-circle'}
            content={content}
            title={title}
        />, { autoClose: 10000, ...options });
    }

    warn = ({ title, content, ...options }) => {
        return toast.warn(<GenericToast
            iconClassName={'fas fa-exclamation-triangle'}
            content={content}
            title={title}
        />, { autoClose: 10000, ...options });
    }

    error = ({ title, content, ...options }) => {
        return toast.error(<GenericToast
            iconClassName={'fas fa-times-circle'}
            content={content}
            title={title}
        />, { autoClose: 10000, ...options });
    }
}

const GenericToast: FC<{iconClassName: string; title: string; content: string}> = memo(function genericToast({ iconClassName, title, content }) {
    return (
        <div className={'portal-toast'}>
            <i className={iconClassName} />
            <div className="portal-toast__content">
                <h3 className={'portal-toast__header'}>{title}</h3>
                <div className={'portal-toast__body'}>{content}</div>
            </div>
        </div>
    );
});

export const portalToast = new Toast();
