import { AdvancedSearchQueryCriterionValueDefinition } from 'models/AdvancedSearchDefinition';

export function isValueValid(
    value: AdvancedSearchQueryCriterionValueDefinition,
    comparator: string,
): boolean {
    if (comparator == 'BETWEEN') {
        return !!value.range?.from && !!value.range?.to;
    }

    if (comparator == 'IN' || comparator == 'NOT_IN') {
        return value.values?.length > 0;
    }

    if (comparator === 'BEFORE_EARLIEST_EVENT' || comparator === 'AFTER_LATEST_EVENT') {
        return true;
    }

    // Validate can't be empty but deliberately not trimming the saved data.
    return !!value.value?.trim();
}
