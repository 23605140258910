import React, { FC, Fragment } from 'react';
import { convertToDate, standardDateTimeFormats } from './convertToDate';

export const defaultDateTimeFormat: keyof typeof standardDateTimeFormats = 'nhs_date_short';
export const defaultDateFormat: keyof typeof standardDateTimeFormats  = 'nhs_date_short';
export const DEFAULT_OUTPUT_TIMEZONE = 'Europe/London';

export const convertToOutputTzDate = (source, parseFormat?: keyof typeof standardDateTimeFormats, strict?) => {
    return convertToDate(source, parseFormat, strict).tz(DEFAULT_OUTPUT_TIMEZONE);
};

export const DateTime: FC<{
    format?: keyof typeof standardDateTimeFormats;
    parseFormat?: keyof typeof standardDateTimeFormats;
    empty?: string;
    timezone?: string;
}> = (props) => {
    const {
        format = defaultDateTimeFormat,
        parseFormat = void 0,
        children,
        empty = '',
        timezone = DEFAULT_OUTPUT_TIMEZONE,
    } = props;
    const child = [].concat(children).shift();

    if (child == null || child === false) {
        return <Fragment>{empty}</Fragment>;
    }

    const date = convertToDate(
        child,
        parseFormat ? parseFormat : null
    );

    if (date && !date.isValid()) {
        return <Fragment>{empty}</Fragment>;
    }

    if (!date) {
        return <Fragment>{empty}</Fragment>;
    }

    return (
        <span className="formatted-date">{formatDate(date.tz(timezone), format)}</span>
    );
};

export const DateSpan = (props) => {
    const {
        format = defaultDateFormat,
        parseFormat,
        children,
        empty = '',
    } = props;

    return (
        <DateTime
            format={format}
            parseFormat={parseFormat}
            empty={empty}
        >{children}</DateTime>
    );
};

export const dateSpan: (
    value: any,
    format?: keyof typeof standardDateTimeFormats,
    parseFormat?: keyof typeof standardDateTimeFormats,
    timezone?: string,
) => string = (
    value,
    format = defaultDateFormat,
    parseFormat = void 0,
    timezone = DEFAULT_OUTPUT_TIMEZONE,
) => {
    const momentDate = convertToDate(value, parseFormat);
    return formatDate(momentDate.tz(timezone), format);
};

export function formatDate(
    date,
    formatName: keyof typeof standardDateTimeFormats = defaultDateTimeFormat,
) {
    if (!standardDateTimeFormats[formatName]) {
        console.warn('Date format "' + formatName + '" not recognised. Using default instead.');
        formatName = defaultDateTimeFormat;
    }

    const format = standardDateTimeFormats[formatName];

    return date.format(format);
}
