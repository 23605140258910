import React, { FC } from 'react';
import { useDashboardData } from 'features/patient/patient-dashboard/data/useDashboardData';
import { DASHBOARD_COMPONENT_NAMES } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { DashboardDemographicsView } from 'features/patient/patient-dashboard/dashboard-components/dashboard-demographics-table/dashboard-demographics-view/DashboardDemographicsView';
import {
    DemographicsData,
    DemographicsTableProps,
} from 'features/patient/patient-dashboard/dashboard-components/dashboard-demographics-table/DashboardDemographicsTable';
import { RoleType } from 'features/patient/patient-dashboard/RoleType';

export const DashboardDemographicsTableForCarer: FC<DemographicsTableProps> = ({
    title= 'Linked Account',
}) => {
    const { data, loading } = useDashboardData<DemographicsData>({ type: DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTableForCarer });
    return (
        <DashboardDemographicsView demographics={data} title={title} role={RoleType.Carer} loading={loading}/>
    );
};
