import React, { Fragment } from 'react';

import { GridCell, GridRow } from 'common/ui/grid';

import NhsNumber from 'ui/nhs-number';
import { Link } from 'react-router';
import { DateSpan } from 'common/datetime/DateTime';
import { MNDWorklistItem } from './MndWorklist';

export const MNDWorklistRow = ({
    worklistItem,
    isLoading,
    className
}: {
    worklistItem: MNDWorklistItem;
    isLoading: boolean;
    className: string;
}) => {
    return (
        <GridRow className={className}>
            {!!isLoading && (
                <GridCell colSpan={13}>Loading</GridCell>
            )}
            {!isLoading && (
                <Fragment>
                    <GridCell field="name"><Link
                        to={worklistItem.patientURL}>
                        <span className="no-wrap">{worklistItem.name}</span></Link>
                    </GridCell>
                    <GridCell field="nhs"><span
                        className="no-wrap"><NhsNumber>{worklistItem.nhs || '--'}</NhsNumber></span></GridCell>
                    <GridCell field="phq8Timestamp">
                        <Link to={worklistItem.patientURL}><span className="no-wrap">{
                            !!worklistItem.latestCompletedAt && (
                                <DateSpan>{worklistItem.latestCompletedAt}</DateSpan>
                            )}
                        </span></Link>
                    </GridCell>
                    <GridCell field="als8Score" className="text-right">{
                        worklistItem.alsQuestionnaireURL && (
                            <Link to={worklistItem.alsQuestionnaireURL}>{worklistItem.alsScoreString}</Link>
                        )}
                    </GridCell>
                    <GridCell field="alsScoreDiff" className="text-right">
                        <Link to={worklistItem.patientGraphsURL}>
                            <Diff>{worklistItem.alsScoreDiff}</Diff>
                        </Link>
                    </GridCell>
                    <GridCell field="breathing8Score" className="text-right">{
                        worklistItem.breathingQuestionnaireURL && (
                            <Link to={worklistItem.breathingQuestionnaireURL}>{worklistItem.breathingScoreString}</Link>
                        )}
                    </GridCell>
                    <GridCell field="breathingScoreDiff" className="text-right">
                        <Link to={worklistItem.patientGraphsURL}>
                            <Diff>{worklistItem.breathingScoreDiff}</Diff>
                        </Link>
                    </GridCell>
                    <GridCell field="snaqScore" className="text-right">{
                        worklistItem.snaqQuestionnaireURL && (
                            <Link to={worklistItem.snaqQuestionnaireURL}>{worklistItem.snaqScoreString}</Link>
                        )}
                    </GridCell>
                    <GridCell field="snaqScoreDiff" className="text-right">
                        <Link to={worklistItem.patientGraphsURL}>
                            <Diff>{worklistItem.snaqScoreDiff}</Diff>
                        </Link>
                    </GridCell>
                    <GridCell field="weightScore" className="text-right">{
                        worklistItem.weightQuestionnaireURL && (
                            <Link to={worklistItem.weightQuestionnaireURL}>{worklistItem.weightScoreString}</Link>
                        )}
                    </GridCell>
                    <GridCell field="weightScoreDiff" className="text-right">
                        <Link to={worklistItem.patientGraphsURL}>
                            <Diff>{worklistItem.weightScoreDiffString}</Diff>
                        </Link>
                    </GridCell>
                </Fragment>
            )}
        </GridRow>
    );
};

export const Diff = ({ children }) => {
    if (children === -Infinity || children === +Infinity) {
        return <Fragment>&nbsp;</Fragment>;
    }

    if (children === undefined || children === null) {
        return <Fragment>&nbsp;</Fragment>;
    }

    if (!children){
        return <Fragment>-</Fragment>;
    }

    if (isNaN(children)){
        const parsedNumber = parseInt(children, 10);

        if (`${parsedNumber}` === children) {
            return <Fragment>{parsedNumber > 0 ? '+' : ''}{parsedNumber}</Fragment>;
        }

        return <Fragment>{children}</Fragment>;
    }

    return <Fragment>{children > 0 ? '+' : ''}{children}</Fragment>;
};
