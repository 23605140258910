import React, { FC } from 'react';
import './BreadCrumbs.less';
import { FilesCallBackData } from 'common/ui/files-list/FilesList';

interface BreadCrumbsProps {
    path: string;
    onClick: (data: FilesCallBackData) => void;
}

const BreadCrumbs: FC<BreadCrumbsProps> = (props) => {
    const splitPath = props.path.split('/');
    const clickHandler = (link: string, index: number) => {
        const splitPath = props.path.split('/');
        const currentPath = splitPath.reduce((p, c, i) => {
            if(i === 0) {
                return c;
            }
            if(i <= index) {
                return p + '/' + c;
            }
            return p;
        }, '');
        if (index !== splitPath.length - 1) {
            props.onClick({ newPath: currentPath, replacePath: true });
        }
    };

    return (
        <ul className={'ui-breadcrumbs'}>
            {splitPath.map((link, index) => {
                const lastOne = index === splitPath.length - 1;
                return (
                    <li key={index}>
                        <a onClick={(e) => { e.preventDefault(); clickHandler(link, index); }}
                            href="#"
                            className={lastOne ? 'breadcrumb-link active' : 'breadcrumb-link'}>
                            {link}
                        </a>
                        {!lastOne ? <span className='breadcrumb-divider'>/</span> : ''}
                    </li>
                );
            })}
        </ul>
    );
};

export default BreadCrumbs;
