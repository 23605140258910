import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import {Link} from 'react-router';
import HeadingDoc from 'ui/heading-doc';
import ServiceCheck from 'services/services';
import {DateTime} from 'common/datetime/DateTime';
import {ResponsiveTable} from 'ui';
import { questionnaireService } from 'services/questionnaire.service';

const questionHeaders = {
    1: 'MOBILITY',
    2: 'SELF-CARE',
    3: 'USUAL ACTIVITIES',
    4: 'PAIN / DISCOMFORT',
    5: 'ANXIETY / DEPRESSION',
    6: 'VAS'
};

/**
 * Extended Page Component
 **/
export default class CompareEq5d extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            questionnaires: [],
            scores: new Map(),
            results: new Map(),
            loading: true
        };

        this.bindFunctions(['loadComponent']);
    }

    componentWillMount () {
        this.loadComponent();
    }

    pageTitle () {
        return _`Compare EQ5Ds | Clinical Portal`;
    }

    loadComponent() {
        const coopQuestionnaireParams = {
            search: {
                'deleted_at': {
                    '$exists': false
                }
            }
        };

        questionnaireService.search(
            Object.assign({
                archetypeName: 'coopQuestionnaire'
            } as any, coopQuestionnaireParams)
        ).then(({message}) => {
            let questionnaires = [], uuids = [];
            uuids = message.results.map((item) => {
                let questions = new Map();
                item.content.details.questions.map((question) => {
                    questions.set(question.id, question.type);
                });
                questionnaires.push([
                    item.content.questionnaire_uuid,
                    questions
                ]);
                return item.content.questionnaire_uuid;
            });

            this.setState({
                questionnaires: questionnaires
            });
            return uuids;
        }).then((uuids) => {
            const coopQuestionnaireResponseParams = {
                search: {
                    'deleted_at': {
                        '$exists': false
                    },
                    'content.questionnaire_uuid': {
                        '$in': uuids
                    },
                    'content.status': {
                        '$eq': 'complete'
                    }
                }
            }

            return questionnaireService.search(
                Object.assign({
                    archetypeName: 'coopQuestionnaireResponse'
                } as any, coopQuestionnaireResponseParams)
            );
        }).then(({message}) => {
            let results = new Map(), responseUuids = [];
            message.results.map((item) => {
                let answers = new Map();
                item.content.details.answers.map((answer) => {
                    answers.set(answer.question_id, answer.value);
                });

                if (!results.has(item.content.questionnaire_uuid)) {
                    results.set(item.content.questionnaire_uuid, []);
                }
                results.get(item.content.questionnaire_uuid).push({
                    date: item.content.status_changes.completed_at,
                    response_uuid: item.uuid,
                    answers: answers
                });

                responseUuids.push(item.uuid);
            });

            this.setState({
                results: results,
            });

            const questionnaireScoring = {
                search: {
                    'content.questionnaire_response_uuid': {
                        '$in': responseUuids
                    }
                }
            }

            return questionnaireService.search(
                Object.assign({
                    archetypeName: 'questionnaireScoring'
                } as any, questionnaireScoring)
            );
        }).then(({message}) => {
            let scores = new Map();

            message.results.map((item) => {
                let scoreList = new Map();

                item.content.scoring.questions.map((item) => {
                    scoreList.set(parseInt(item.question_id), item.score);
                });
                scores.set(item.content.questionnaire_response_uuid, scoreList);
            });

            this.setState({
                scores: scores,
                loading: false
            });
        });
    }

    render () {
        const noQuestionnaires = this.state.questionnaires.length <= 0 && !this.state.loading;
        const noScores = this.state.scores.size <= 0 && !this.state.loading;

        let list = this.state.questionnaires.map((item, idx) => {
            const answers = this.state.results.get(item[0]);
            const scores = this.state.scores;

            if (!answers || scores.size <= 0) return;

            return (
                <Questionnaire key={idx} questions={item[1]} scores={this.state.scores} answers={answers}/>
            );
        });

        if (this.state.loading) {
            list = (<p>Loading please wait...</p>);
        }

        if (noQuestionnaires || noScores) {
            list = (<div className="alert alert-info"><span>There are currently no completed EQ5Ds to display</span></div>);
        }

        return (
            <ServiceCheck service="MSK" fail={(<h1>Forbidden</h1>)}>
                <div className="page">
                    <div className='top-bar'>
                        <Link to={`${this.getFolderPath()}${this.props.linkBack || ''}`}>
                            {_`Back to Questionnaires List`}
                        </Link>
                    </div>

                    <HeadingDoc title={_`Compare EQ5Ds`}>
                        The questionnaire section allows you to compare two or more complete EQ5Ds. Incomplete EQ5Ds are not displayed on this page,
                        but can be viewed individually from the questionnaires list.
                    </HeadingDoc>

                    {list}
                </div>
            </ServiceCheck>
        );
    }
}

const Questionnaire = ({questions, scores, answers}) => {
    let questionList = [
        (
            <th key="date">Date</th>
        )
    ], responses;

    questions.forEach((item, idx) => {
        questionList.push(
            <th key={idx}>{questionHeaders[idx]}</th>
        );
    });

    responses = answers.map((answer, idx) => {
        let row = [], score = scores.get(answer.response_uuid);

        if (!score) return;

        row.push(
            <td key="date"><DateTime>{answer.date}</DateTime></td>
        );

        questions.forEach((dummy, questionId) => {
            let current = score.get(parseInt(questionId));
            row.push(<td key={questionId}>{current}</td>);
        });

        return (
            <tr key={idx}>{row}</tr>
        );
    });

    return (
        <div className="questionnaire">
            <ResponsiveTable>
                <thead>
                <tr>
                    {questionList}
                </tr>
                </thead>
                <tbody>
                {responses}
                </tbody>
            </ResponsiveTable>
        </div>
    );
};
