import React from 'react';
import {Link} from 'react-router';
import lodash from 'lodash';
import classNames from 'classnames';

const displayStatusMapping = {
    'complete' : 'Done',
    'assigned' : 'To Do',
    'in_progress' : 'In Progress',
    'scored': 'Scored',
    'expired': 'Expired'
};

/**
 * Displays a questionnaire response composition on a MyPathway timeline.
 * Props:
 * - composition = Required. An object containing the composition data to be displayed.
 * - folderLink = Required. Base URL for the patient's folder in the portal. This is used to construct links to specific archetypes.
 */
const Questionnaire = ({composition, folderLink, compositionQueryString}) => {
    let link = `${folderLink}/questionnaires/${lodash.get(composition, 'uuid', '')}`;
    if (compositionQueryString){
        link += `?${compositionQueryString}&questionnaire`;
    }else{
        link += '?questionnaire';
    }

    const status = lodash.get(composition, 'content.status', '').toLowerCase();
    const displayStatus = displayStatusMapping[status] || status;
    const className = classNames({
        'composition-coop-questionnaire-response': true,
        'complete' : ['complete','scored'].indexOf(status) !== -1
    });

    return (
        <Link to={link}>
            <div className={className}>
                <h4>Important Questions About Your Health.</h4>
                <div className='questionnaire-summary'>Before booking your appointment we need you to answer some questions about your health. This will take less than 2 minutes.</div>
                <div className='status'>{displayStatus}</div>
                {renderIsOverdue(composition, status)}
            </div>
        </Link>
    );
};

const renderIsOverdue = (composition, status) => {
    return status !== 'complete' && composition.isOverdue ? (
        <div className='overdue'>Overdue</div>
    ): null;
};

export default Questionnaire;
