import React, { FC, useState } from 'react';
import _ from 'services/i18n';
import { notesService } from 'services/notes.service';
import { CareEventStatus } from './UserNoteTableDisplay';
import ErrorView from 'components/errorbox';

type UserNoteSimpleEntryProps = {
    parentEntityUuid: string,
    folderId: number,
    defaultTitle: string,
    defaultStatus: CareEventStatus,
    careEventTypes: string[],
    onSubmit: () => unknown
};

export const UserNoteSimpleEntry: FC<UserNoteSimpleEntryProps> = ({
    parentEntityUuid,
    folderId,
    defaultTitle,
    defaultStatus,
    careEventTypes,
    onSubmit
}) => {
    const careEventSelectionOptions = careEventTypes
        .map(careEvent => { return { label: careEvent, value: careEvent } });

    const [careEventSelection, setCareEventSelection] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([])
    const saveCareEvent = () => {
        const errorStack = [];
        if (!careEventSelection) {
            errorStack.push('Event type is mandatory');
        }
        if (!comment) {
            errorStack.push('Comment is mandatory');
        }
        if (errorStack.length > 0) {
            setErrors(errorStack);
            return;
        }
        notesService.create({
            folderId: folderId,
            content: {
                status: defaultStatus,
                title: defaultTitle,
                comment: comment,
                type: careEventSelection,
                parent_doc_uuid: parentEntityUuid
            }
        }).then(() => {
            setCareEventSelection('');
            setComment('');
            setErrors([]);
            onSubmit();
        })
    }

    return (
        <>
            <h3>Add User Note</h3>
            { errors.length > 0 && <ErrorView errors={errors}/>}
            <div className={'inline-form'}>
                <select
                    id='care-event-select'
                    className='form-control inline-form_item'
                    value={careEventSelection}
                    required={true}
                    onChange={s => setCareEventSelection(s.target.value)}
                >
                    <option value={''} disabled selected hidden>Select event type</option>
                    {careEventSelectionOptions.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <input type='text'
                       className='form-control inline-form_item'
                       name='userNoteTitle'
                       value={comment}
                       placeholder={_`Comment`}
                       onChange={(newValue) => setComment(newValue.target.value)}
                />
                <button
                    className='btn btn-default care-event-btn inline-form_item'
                    onClick={() => saveCareEvent()}
                >
                    Add
                </button>
            </div>
        </>
    );
}