/* eslint-disable no-prototype-builtins */

import React, { Fragment } from 'react';
import _ from 'services/i18n';
import { DateTime } from 'common/datetime/DateTime';
import Modal from 'components/modal';
import Markdown from 'components/ui/markdown';
import { EditableQuestionnaire } from 'features/patient/questionnaire/questionnaire-view/EditableQuestionnaire';
import { QuestionnaireArchetype } from 'models/QuestionnaireData';

const questionHeaders = {
    1: 'MOBILITY',
    2: 'SELF-CARE',
    3: 'USUAL ACTIVITIES',
    4: 'PAIN / DISCOMFORT',
    5: 'ANXIETY / DEPRESSION',
    6: 'VAS'
};

/**
 * View Component
 */
const CoopQuestionnaireView = (props) => {

    const {
        loading,
        isUpdating,
        isEditMode,
        showHeader = true,
        questionnaireData = {},
        onCancel,
        onSubmit,
        folderId,
        onShowReport,
        onCloseModal,
        showReport,
        pathwayNamesWithEvent,
    } = props;

    const {
        questionnaireResponse,
        name,
        nameOfIssuer,
        createdAt,
        createdUser,
        dueBy,
        status,
        lastUpdated,
        uuid,
        isHidden,
        canBeCompletedByClinician,
        questionnaireScores,
        viewedQuestions,
        labels,
        sections,
        answers,
        scoring,
        scores,
        questionScores,
        questionGroups,
    } = questionnaireData || {};

    const completedAt =
        questionnaireData &&
        questionnaireData.questionnaireResponse &&
        questionnaireData.questionnaireResponse.content &&
        questionnaireData.questionnaireResponse.content.status_changes &&
        questionnaireData.questionnaireResponse.content.status_changes.completed_at;

    if (loading) {
        return (
            <div className="message-view">
                <h3 className="spacer20">{ _`Loading questionnaire...` }</h3>
            </div>
        );
    }

    if (isHidden){
        return (
            <div className="message-view">
                <h3 className="spacer20">{ _`Confidential` }</h3>
            </div>
        );
    }

    if (isUpdating){
        return (
            <div className="message-view">
                <h3 className="spacer20">{ _`Updating...` }</h3>
            </div>
        );
    }
    return (
        <div className="questionnaire-single">
            {showHeader &&
                <h3 className="spacer20">{_`Viewing ${name}`}</h3>
            }
            <Details {...{
                labels,
                folderId,
                status,
                dueBy,
                nameOfIssuer,
                completedAt,
                createdAt,
                lastUpdated,
                pathwayNamesWithEvent,
            }}
            />
            <ScoringBreakdown scoring={scoring} />
            {
                isEditMode ? (
                    <EditableQuestionnaire archetype={QuestionnaireArchetype.coopQuestionnaire}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                        sections={sections}
                    />
                ) : (
                    <Fragment>
                        <Questions {...{
                            scores,
                            sections,
                            answers,
                            questionScores,
                            questionGroups,
                        }} />
                        <ViewReport {...{ name, onShowReport, onCloseModal, questionScores, answers, sections, showReport }} />
                    </Fragment>
                )
            }
        </div>
    );
};

export default CoopQuestionnaireView;














const ViewReport = ({ name, onShowReport, onCloseModal, questionScores, answers, sections, showReport }) => {
    if (!name.match(/EQ.*5D/)) {
        return <noscript/>;
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <button className="btn btn-success" onClick={onShowReport}>View Report</button>
                </div>
            </div>
            <Report {...{ name, showReport, sections, answers, questionScores, onCloseModal }} />
        </Fragment>
    );
};

const Report = ({ name, showReport, sections, answers, questionScores, onCloseModal }) => {

    if (!name.match(/EQ.*5D/) || !showReport) {
        return (<noscript/>);
    }

    const questions = sections && sections[0].questions || [];

    const scores = {};
    let notAnswered = false;

    questions.map((item, idx) => {
        let userAnswer, userScore;

        userAnswer = answers[item.id];

        if (item.id === 6) {
            return;
        }
        if (answers.hasOwnProperty(item.id)) {
            switch (item.type) {
            case 'mcq':
                // handle the situation where questionnaireScoring doesn't exist.
                if (questionScores.hasOwnProperty(item.id)) {
                    userScore = questionScores[item.id].score;
                } else {
                    notAnswered = true;
                }
                break;
            default:
                userScore = userAnswer;
            }
        } else {
            notAnswered = true;
        }

        scores[item.id] = userScore;
    });

    const buttons = [
        { type: 'callback', label: 'Close', callback: onCloseModal }
    ];

    if (notAnswered) {
        return (
            <Modal title={_`Viewing ${name} Report`} size="sm" onClose={onCloseModal} buttons={buttons}>
                <p>This report can only be viewed once all of the questions are answered.</p>
            </Modal>
        );
    }

    const points = {
        5: [],
        4: [],
        3: [],
        2: [],
        1: []
    };

    let counter = 0;
    const questionSpots = {};

    for (let i = 0; i >= -360; i -= (360 / 5)) {
        counter++;
        const xs = Math.sin(i * Math.PI / 180);
        const ys = Math.cos(i * Math.PI / 180);
        questionSpots[counter] = {};
        questionSpots[counter]['label'] = { x: (350 - (xs * (40 * 5.5))), y: (270 - (ys * (40 * 5.5))) };
        for (let j = 5; j > 0; j--) {
            questionSpots[counter][j] = { x: (350 - (xs * (40 * j))), y: (270 - (ys * (40 * j))) };
            points[j].push(questionSpots[counter][j].x + ',' + questionSpots[counter][j].y);
        }
    }

    const polylines = [];
    for (let i = 1; i < 6; i++) {
        const {
            x,y
        } = questionSpots[1][i];

        polylines.push(
            <text key={'score' + i} x={x - 20} y={y + 8} textAnchor="middle" fill="#4f4f4f">{i}</text>
        );
    }

    for (const idx in points) {
        if (points.hasOwnProperty(idx)) {
            polylines.push(
                <polyline key={idx} fill="none" stroke="#a6a6a6" strokeWidth="1px" points={points[idx].join(' ')}/>
            );
        }
    }

    const scorePoints = [];

    for (const questionId in scores) {
        const x = (questionSpots[questionId][scores[questionId]] || {}).x || 0;
        const y = (questionSpots[questionId][scores[questionId]] || {}).y || 0;

        scorePoints.push(x + ',' + y);
    }

    scorePoints.push(scorePoints[0]);

    polylines.push(
        <polyline key={'scores'} fill="none" stroke="#61a5c3" strokeWidth="6px" points={scorePoints.join(' ')}/>
    );
    questions.map((item, idx) => {
        const x = questionSpots[item.id]['label'].x,
            y = questionSpots[item.id]['label'].y,
            question = questionHeaders[item.id];

        if (item.id == 6) {
            return;
        }
        polylines.push(
            <text key={'text' + item.id} x={x} y={y} textAnchor="middle" style={{ fontWeight: '700' }} fill="black">{question}</text>
        );
    });

    return (
        <Modal title={_`Viewing ${name} Report`} size="lg" onClose={onCloseModal} buttons={buttons}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '700px', height: '500px', border: '1px solid black' }}>
                <svg width="100%" height="500px" xmlns="https://www.w3.org/2000/svg" version="1.1">
                    {polylines}
                </svg>
            </div>
        </Modal>
    );
};

const ScoringBreakdownRow = ({ sum, mean, type, groups, partial }) => {
    const scoreKey = sum == null ? mean == null ? 0 : mean : sum;
    return (
        <tr>
            <td>{type}</td>
            <td>{groups == null ? '' : groups}</td>
            <td>{scoreKey} <em>{partial == null ? '' : ' - Not complete'}</em></td>
        </tr>
    );
};

const ScoringBreakdownResults = ({ scoring }) => {
    let list = scoring.map((item, idx) => {
        return (<ScoringBreakdownRow key={idx} {...item} />);
    });

    if (list.length == 0) {
        list = (
            <tr>
                <td colSpan="3"><em>Scores will appear here once the questionnaire has been answered</em></td>
            </tr>
        );
    }

    return (
        <tbody>
            {list}
        </tbody>
    );
};

const ScoringBreakdown = (props) => {
    const {
        scoring,
    } = props;

    let hasOtherScores;
    if (scoring && scoring.length === 0) {
        return (<noscript/>);
    }

    // now check to see if the only scoring we have is 'all'
    hasOtherScores = scoring.some((item, idx) => {
        return item.hasOwnProperty('type') && item.type !== 'total';
    });

    if (!hasOtherScores) {
        return <noscript/>;
    }

    return (
        <div>
            <h4 className="spacer-top28">{_`Score Breakdown`}</h4>
            <div className="table-responsive">
                <table className="table table-bordered table-condensed table-striped table-nomargin">
                    <thead>
                        <tr>
                            <th>{_`Category`}</th>
                            <th>{_`Groups`}</th>
                            <th>{_`Total`}</th>
                        </tr>
                    </thead>
                    <ScoringBreakdownResults scoring={scoring} />
                </table>
            </div>
        </div>
    );
};

const QuestionRow = ({ answers, item, questionScores, questionGroups }) => {
    let answer, userScore = 0, groups = false, notAnswered,
        userAnswer = answers[item.id];

    if (questionScores.hasOwnProperty(item.id)) {
        userScore = questionScores[item.id].score;
        if (questionScores[item.id].hasOwnProperty('not_answered')) {
            notAnswered = questionScores[item.id].not_answered;
        }
    } else {
        notAnswered = true;
    }

    switch (item.type) {
    case 'mcq':
        if (userAnswer <= item.options.length) {
            answer = item.options[userAnswer];
        }
        break;
    default:
        answer = userAnswer;
    }

    if(answer instanceof Array) {
        answer = answer[1] || answer;
    }

    if (questionGroups.hasOwnProperty(item.id)) {
        groups = questionGroups[item.id].join(', ');
    }

    return (
        <tr>
            <td><Markdown sanitize={false}>{item.question}</Markdown></td>
            <td>{groups || ''}</td>
            <td>{notAnswered ? <em>No answer</em> : answer}</td>
        </tr>
    );
};

const QuestionRows = ({ sections, answers, questionScores, questionGroups }) => {

    const questions = sections && sections[0].questions;
    const list = questions.map((item, idx) => {
        return (
            <QuestionRow key={idx} item={item} {...{ answers, item, questionScores, questionGroups }} />
        );
    });

    return (
        <tbody>
            {list}
        </tbody>
    );
};

const Questions = (props) => {
    const {
        scores = {},
        sections,
        answers,
        questionScores,
        questionGroups,
    } = props;

    return (
        <div>
            <h4 className="spacer-top28">{_`Individual Questions, Answers and Scores`}</h4>
            <div className="table-responsive">
                <table className="table table-bordered table-condensed table-striped table-nomargin table-wrap">
                    <thead>
                        <tr>
                            <th width="60%">{_`Question`}</th>
                            <th>{_`Scale`}</th>
                            <th>{_`Answer`}</th>
                        </tr>
                    </thead>
                    <QuestionRows {...{ sections, answers, questionScores, questionGroups }} />
                </table>
            </div>
        </div>
    );
};

const Details = (props) => {
    const {
        labels,
        folderId,
        status,
        dueBy,
        nameOfIssuer,
        createdAt,
        lastUpdated,
        pathwayNamesWithEvent,
    } = props;

    const pathways = pathwayNamesWithEvent.map(([name]) => name).join(', ');
    const clinicalEvents = pathwayNamesWithEvent.map(([,event]) => event).join(', ');

    return (
        <div className="table-responsive">
            <table className='table table-bordered table-striped'>
                <tbody>
                    <tr>
                        <th>{_`Status`}</th>
                        <td>
                            {status}
                        </td>
                        <th>{_`Response Due By`}</th>
                        <td>{dueBy ?
                            <DateTime format="nhs_date_short">{dueBy}</DateTime> : _`Not Specified`}</td>
                    </tr>
                    <tr>
                        <th>{_`Issued By`}</th>
                        <td>{nameOfIssuer}</td>
                        <th>{_`Completed On`}</th>
                        <td>
                            <DateTime empty={'-'}>
                                {props.completedAt}
                            </DateTime>
                        </td>
                    </tr>
                    <tr>
                        <th>{_`Issued On`}</th>
                        <td><DateTime>{createdAt}</DateTime></td>
                        <th>{_`Updated On`}</th>
                        <td><DateTime>{lastUpdated}</DateTime></td>
                    </tr>
                    <tr>
                        <th>{_`Pathway`}</th>
                        <td>{pathways}</td>
                        <th>{_`Clinical Event`}</th>
                        <td>{clinicalEvents}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
