import React from 'react';

const HeadingDoc = ({ title, children }) => {
    return (
        <div className="heading-documentation">
            <h1>{title}</h1>
            <div className="documentation">
                <div className='row'>
                    <div className="col-lg-6">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeadingDoc;
