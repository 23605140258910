import { useContext, useCallback } from 'react';
import { ReferralsContext } from 'features/ReferralsProvider';
import { REFERRAL_STATUS } from 'models/compositions/Referral';

export const usePathways = () => {
    const {
        itemMap: referralsByUuid,
    } = useContext(ReferralsContext);

    const getPathwaysByLabels = useCallback((labels) => {
        if (!labels) {
            return {
                pathwayNames: '',
                pathwayNamesWithEvent: [],
            };
        }
        const referralLabels = labels
            .filter(isActiveLabelPredicate)
            .map((label) => {
                if (!referralsByUuid.has(label.referral_uuid) || !label.referral_uuid) {
                    return { label, foundLabel: null };
                }

                const referral = referralsByUuid.get(label.referral_uuid);
                if (!referral) {
                    return { label, foundLabel: null };
                }
                const { labels: referralLabels = [] } = referral.content;
                const foundLabel = referralLabels.find(findByContextPredicate(label));

                return {
                    label,
                    foundLabel: isActiveLabelPredicate(foundLabel) ? foundLabel : null,
                };
            });

        const pathwayNamesWithEvent = referralLabels
            .map(({ label, foundLabel }) => [foundLabel ? foundLabel.name : '', label.name]);

        const pathwayNames = referralLabels
            .map(({ foundLabel }) => foundLabel)
            .filter(label => !!label)
            .map(label => label.name)
            .filter(name => !!name)
            .join(', ');

        return {
            pathwayNames,
            pathwayNamesWithEvent
        };
    }, [referralsByUuid]);

    const addPathwaysByLabels = useCallback((list) => {
        return list.map(item => {
            const labels = item?.content?.labels ?? [];
            const { pathwayNames, pathwayNamesWithEvent } = getPathwaysByLabels(labels);
            const pathwaySortAndFilter = pathwayNamesWithEvent
                .flat()
                .map(str => str ? str : '_____') // to group empty pathways
                .join('');

            return {
                ...item,
                pathwayNames,
                pathwayNamesWithEvent,
                pathwaySortAndFilter,
            };
        });
    }, [getPathwaysByLabels]);

    const getPathwaysByReferral = useCallback((id, referrals) => {
        if (!id || !referrals) { return '';}
        const labels = referrals.filter((item) => {
            const { status } = item;
            const isActive = status !== REFERRAL_STATUS.CANCELLED && status !== REFERRAL_STATUS.DISCHARGED && status !== REFERRAL_STATUS.REJECTED;
            return isActive && item.content.referral_ids.indexOf(id) !== -1;
        }).map((item) => {
            if (!item.content.labels) {
                return [];
            }
            const pathwayLabels = item.content.labels.filter((label) => label.type === 'pathway').map(i => i.name);
            return pathwayLabels;
        });
        return labels.flat().join(', ');
    }, []);

    const addPathwaysToReferral = useCallback((referrals) => {
        return referrals.map((referral) => {
            const contextReferral = referralsByUuid.get(referral.uuid) || referral;
            const labels = contextReferral.content.labels || [];
            const pathwayNames = labels
                .filter(label => label.type === 'pathway')
                .filter(isActiveLabelPredicate)
                .map(label => label.name);

            const pathwaySortAndFilter = pathwayNames.join('');
            return {
                pathwaySortAndFilter,
                ...referral
            };
        });
    }, [referralsByUuid]);

    return {
        getPathwaysByLabels,
        addPathwaysByLabels,
        getPathwaysByReferral,
        addPathwaysToReferral,
        deps: [referralsByUuid],
    };
};

function findByContextPredicate(label) {
    return (referralLabel) => {
        return referralLabel.context === label.context;
    };
}

export function isActiveLabelPredicate(label) {
    return label && (label.status === true || label.status === 'true');
}
