import moment from 'moment-timezone';

/**
 * Defines the built-in date formats.
 */

export const standardDateTimeFormats = {
    nhs_month_short: 'MMM-YYYY',
    nhs_month_long: 'MMMM-YYYY',

    nhs_date_short: 'DD-MMM-YYYY',
    nhs_date_long: 'DD MMMM YYYY',

    nhs_date_fulldate: 'YYYY-MM-DD HH:mm:ss',

    nhs_date_short_with_weekday: 'ddd DD-MMM-YYYY',
    nhs_date_long_with_weekday: 'dddd DD MMMM YYYY',

    nhs_time_short: 'HH:mm',
    nhs_time_long: 'HH:mm:ss',

    nhs_weekday_short: 'ddd',
    nhs_weekday_long: 'dddd',

    nhs_date_with_time: 'DD-MMM-YYYY HH:mm',
    nhs_dob: 'DD/MM/YYYY',

    questionnaires_date: 'D-MMM-YYYY',

    date_input: 'YYYY-MM-DD',
    datetime_input: 'YYYY-MM-DD HH:mm',

    year_week: 'YYYY week W',
    year: 'YYYY',

    server_date: 'YYYY-MM-DD',
    server_datetime: 'YYYY-MM-DD HH:mm:ss',
};

export const defaultParseDateTimeFormat = 'nhs_date_short';

const SECONDS_IN_HOUR = 60 * 60;
const SECONDS_IN_MINUTE = 60;

/**
 * @param  {{ dmy: string } | { ymd: string } | { timestamp: number } | { date: string, timezone: string }
 *  | Date | string | number | undefined | null | moment.Moment} source
 * @param {string=} parseFormat
 * @param {boolean=} strict
 * @results {moment.Moment | undefined}
 */
export function convertToDate(source, parseFormat?: keyof typeof standardDateTimeFormats, strict = false) {
    if (moment.isMoment(source)) {
        return source.utc();
    }

    if (typeof(source) === 'string' && parseFormat) {
        if (!standardDateTimeFormats[parseFormat]) {
            console.warn('Date format "' + parseFormat + '" not recognised. Using default for parse instead.');
            parseFormat = defaultParseDateTimeFormat;
        }
        return moment.utc(source, standardDateTimeFormats[parseFormat], strict);
    }

    if (source instanceof Date) {
        return moment.utc(source);
    }

    if (typeof(source) === 'undefined' || source === null) {
        return convertNullOrUndefined();
    }

    if (typeof(source) === 'number') {
        return convertTimestamp(source);
    }

    if (typeof(source) === 'string') {
        return convertString(source);
    }

    if ('dmy' in source) {
        return convertDMYString(source.dmy);
    }

    if ('ymd' in source) {
        return convertYMDString(source.ymd);
    }

    if ('date' in source && 'timezone' in source) {
        return convertDateWithTimezone(source.date, source.timezone);
    }

    if ('timestamp' in source) {
        return convertTimestamp(source.timestamp);
    }
}

export function convertToTimestamp(source) {
    const momentDate = convertToDate(source);
    return momentDate && momentDate.isValid() ? momentDate.unix() : 0;
}

/**
 * @param  {string} dateString
 * @param  {string} timezone
 */
function convertDateWithTimezone(dateString, timezone) {
    const date = new Date(dateString + ' ' + timezone);
    if (isNaN(date.getTime())) {
        return moment.utc([0, 0, 0, 0, 0, 0]);
    }
    return moment.utc(date);
}

/**
 * @param  {string} ymd
 * @returns {moment.Moment | undefined}
 */
function convertYMDString(ymd) {
    // Matches a date of the format yyyy-mm-dd. Ignores leading and trailing space.
    let result = ymd.match(/^\s*([0-9]{4})-([0-9]{2})-([0-9]{2})\s*$/i);
    if (result === null) {
        console.error('Failed to parse "' + ymd + '" as ymd format. Expected yyyy-mm-dd.');
        return undefined;
    }

    return moment.utc([parseInt(result[1], 10), parseInt(result[2], 10) - 1, parseInt(result[3], 10)]);
}

/**
 * @param  {string} dmy
 * @returns {moment.Moment}
 */
function convertDMYString(dmy) {
    const [day, month, year] = dmy.split('/').map(str => parseInt(str, 10));
    return moment.utc([year, month - 1, day]);
}

/**
 * @param  {string} str
 * @returns {moment.Moment}
 */
function convertString(str) {
    const date = new Date(str.replace(/(\-[0-9]+) ([0-9]+\:)/, '$1T$2') + 'Z');
    if (!isNaN(date.getTime())) {
        return moment.utc(date);
    }

    const momentDate = moment.utc(str);
    if (momentDate.isValid()) {
        return momentDate;
    }

    return moment.utc([0, 0, 0, 0, 0, 0]);

}

/**
 * @param  {number} timestamp
 * @returns {moment.Moment}
 */
function convertTimestamp(timestamp) {
    const fromTimestampMS = moment.unix(timestamp).utc();

    if (fromTimestampMS.year() <= 2300) {
        return fromTimestampMS;
    }

    return moment(timestamp).utc();
}

/**
 * @param  {number} numberOfSeconds
 * @returns {moment.Moment}
 */
function convertSecondsNumber(numberOfSeconds) {
    const date = moment.utc();
    date.hours(Math.round(numberOfSeconds / (SECONDS_IN_HOUR)));
    date.minutes(Math.round((numberOfSeconds % (SECONDS_IN_HOUR)) / SECONDS_IN_MINUTE));
    date.seconds(numberOfSeconds % SECONDS_IN_MINUTE);
    return date;
}
/**
 * @returns {undefined}
 */
function convertNullOrUndefined() {
    console.error('Invalid date format passed');
    return;
}
