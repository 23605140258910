import React from 'react';
import {Link} from 'react-router';
import _ from 'services/i18n';

/**
 * Questionnaire Template View Component
 */
export default class extends React.Component {

    renderTable () {
        if (this.props.loading) {
            return (
                <h5>{_`Loading questionnaire template, please wait...`}</h5>
            );
        }

        return (
            <div>
                <h3 className="spacer20">{_`Questionnaire Template`}</h3>

                <div className="table-responsive">
                    <table className='table table-bordered table-striped'>
                        <tbody>
                        <tr>
                            <th>{_`Name`}</th>
                            <td>{this.props.document.content.name}</td>
                        </tr>
                        <tr>
                            <th>{_`Version`}</th>
                            <td>
                                {this.props.document.content.version}
                            </td>
                        </tr>
                        <tr>
                            <th>{_`Date Created`}</th>
                            <td>{this.props.document.created_at}</td>
                        </tr>
                        <tr>
                            <th>{_`Created By`}</th>
                            <td>{this.props.document.created_user ? this.props.document.created_user.name : null}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p><strong>{_`Template JSON Content`}</strong></p>
                <pre>{JSON.stringify({
                    created_at:this.props.document.created_at,
                    content:this.props.document.content
                })}</pre>
            </div>
        );
    }


    renderTopBar () {
        return (
            <div>
                <div className="top-bar">
                    <Link to={"/admin_portal/questionnaire-templates"}>{_`Back to Questionnaire Templates`}</Link>
                    <Link to={"/admin_portal/questionnaire-templates/create"}>{_`Add New Questionnaire Template`}</Link>
                </div>
            </div>
        );
    }

    render () {
        return (
            <div className="questionnaire-template-view">
                {this.renderTopBar()}
                {this.renderTable()}
            </div>
        );
    }
}
