import React from 'react';
import PropTypes from 'prop-types'
import _ from 'services/i18n';
import ActionMenu from 'components/action-menu';
import getPatientLinks from 'helpers/patient-links';
import lodash from 'lodash';
import { convertToDate, standardDateTimeFormats } from 'common/datetime/convertToDate';

export default class InviteBarComponent extends React.Component {

    renderDropdownLinks () {
        return getPatientLinks(this.props.folderId);
    }

    getDoBWithYears(dob){
        if(!/\d{4}-\d{2}-\d{2}/.test(dob)){
            return null;
        }
        const dateOfBirth = convertToDate({ymd: dob}).format(standardDateTimeFormats.nhs_date_short);
        const years = convertToDate(new Date()).diff(convertToDate(dob, 'date_input'), 'years');
        return `${dateOfBirth} (${years}y)`;
    }

    getName({given_name:givenName = '',family_name:familyName = ''} = {}){
        return `${lodash.upperFirst(givenName)} ${lodash.upperFirst(familyName)}`.trim();
    }

    render () {
        const invite = this.props.invite || {};
        const rawWhsNumber = lodash.get(invite,'identifiers.nhs_number','');
        const parts = rawWhsNumber.replace(' ','').match(/^(.{3})(.{3})(.{4})$/);
        const preferredName = this.getName(invite.preferred_name || {});
        const name = this.getName(invite.name || {});

        let formattedNhsNumber;
        if (parts) {
            formattedNhsNumber = [parts[1],parts[2],parts[3]].join(' ');
        } else {
            formattedNhsNumber = rawWhsNumber;
        }

        const formattedDob = this.getDoBWithYears(invite.date_of_birth);

        return (
            <div className="invite-bar">
                <div className="invite-bar__id">{preferredName || name || '(name unknown)'}</div>
                <div className="invite-bar__id">NHS # {formattedNhsNumber}</div>
                {
                    formattedDob && <div className="invite-bar__id">{formattedDob}</div>
                }
                <div className="invite-bar__links">
                    <ActionMenu size="lg"
                                links={this.renderDropdownLinks()}
                                onCheckAllowed={this.props.onCheckAllowed}
                                label={_`Quick Links`} />
                </div>
            </div>
        );
    }
}

InviteBarComponent.propTypes = {
    onCheckAllowed: PropTypes.func,
    invite: PropTypes.object,
    folderId: PropTypes.number
};
