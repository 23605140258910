import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { EditorTags } from 'common/ui/editor-tags/EditorTags';
import YMLSchema from './YMLSchema';
import { WYSIWYGPreview } from 'common/ui/wyiswyg-preview/WYSIWYGPreview';
import 'msk-timeline/dist/style.css';
import './JsonEdit.component.scss';

import TimelinePhone from 'common/ui/timeline-phone/TimelinePhone';

const disabledTags = {
    'name': true,
    'content_type': true,
    'description': true,
};

interface ElementProps {
    field: string;
    value: string;
    onChange: (s: unknown, k: string) => void;
    type: string;
    tags: string[];
    disabled: boolean;
    options: string[];
}

interface JsonEditProps {
    data: {[key: string]: unknown};
    disabled?: boolean;
    onChange: (value: {[key: string]: unknown} | false) => void;
}


const TextElement = (props: ElementProps) =>  {
    const [elRef, setRef] = useState(null);

    const insertTag = (tag) => {
        if (elRef) {
            const start = elRef.selectionStart;
            const value = elRef.value;
            const newValue = `${value.substr(0, start)} ${tag} ${value.substr(start)}`;
            props.onChange(newValue, props.field);
        }
    };

    return (
        <div className='form-group'>
            <label htmlFor={props.field}>{props.field}</label>
            {!!props.tags.length && !props.disabled && <EditorTags callback={insertTag} />}
            <textarea className='form-control' name={props.field} disabled={props.disabled}
                ref={(el) => { setRef(el); }}
                onChange={(event) => props.onChange(event.target.value, props.field)}
                value={props.value}
            />
        </div>
    );
};


const InputElement = (props: ElementProps) => (
    <div className={'form-group'}>
        <label htmlFor={props.field}>{props.field}</label>
        <input className='form-control'
            name={props.field}
            value={props.field}
            disabled={props.disabled}
            type='text'
            placeholder={props.field}
            onChange={(event) => props.onChange(event.target.value, props.field)}
        />
    </div>
);

const HTMLElement = (props: ElementProps) => (
    <div className='form-group'>

        <label htmlFor={props.field}>{props.field}</label>

        <WYSIWYGPreview onChange={(value, field?) => {
            if (field) {
                props.onChange(value, field);
            } else {
                props.onChange(value, props.field);
            }
        }} type={props.type} value={props.value} />
    </div>
);

const DropdownElement = (props: ElementProps) => (
    <div className='form-group'>

        <label htmlFor={props.field}>{props.field}</label>

        <div>
            <select disabled={props.disabled} value={props.value}
                className={'form-control'}
                onChange={(e) => props.onChange(e.target.value, props.field)}>
                {props.options.map((option => (<option value={option} key={option}>{option}</option>)))}
            </select>
        </div>
    </div>
);

const FieldJson = ({
    onChange,
    json
}) => {
    const [value, setValue] = useState(json);
    return  (
        <div className='form-group'>
            <label htmlFor='json'>JSON</label>
            <textarea className='form-control' name={'json'}
                style={{ width: '80%', minHeight: '320px' }}
                onChange={(event) => {
                    setValue(event.target.value);
                    onChange(event.target.value);
                }}
                value={value}
            />
        </div>
    );
};

export const JsonEdit: FC<JsonEditProps> = ({
    data = {},
    disabled,
    onChange = () => { return void 0; }
}) => {
    const initialState: {[key: string]: unknown} = {};
    const [schema, setSchema] = useState(initialState);
    const elements = {
        input: InputElement,
        text: TextElement,
        fallback    : TextElement,
        html: HTMLElement
    };
    const order = (schema.order || []) as Array<string>;
    const jsonString = useMemo(() => {
        return JSON.stringify(data, undefined, 4);
    }, [data]);
    const result: Array<JSX.Element> = [];
    const schemaItem = YMLSchema.find((item) => item.type === data.content_type);
    const showTimeline = data.content_type === 'message' || data.content_type === 'questionnaire-timeline' || data.content_type === 'timeline-content';

    const handleOnChange = (newValue, key) => {
        onChange({
            ...data,
            [key]: newValue
        });
    };

    const getSchema = () => {
        if (schemaItem) {
            return setSchema(schemaItem);
        }
        return {};
    };

    const buildElementsBySchema = (keys) => {
        keys.forEach((key) => {
            const schemaValue = schema.properties[key] || {};
            const skipTextIfHtmlPresent = key === 'txt_content' && schema.properties['html_content'];
            if (skipTextIfHtmlPresent) {
                return;
            }
            const Element = schemaValue.enum ? DropdownElement : (elements[schemaValue.type] || elements['fallback']);

            const allPossibleProps = {
                type: data.content_type,
                tags: schema.tags,
                field: key,
                key,
                disabled: disabled || disabledTags[key],
                options: schemaValue.enum || [],
                value: data[key] || '',
                onChange: handleOnChange
            };
            if (Element) {
                result.push(<Element {...allPossibleProps} />);
            }
        });
    };

    useEffect(() => {
        getSchema();
    });

    const replaceJSON = (str) => {
        let json;
        try {
            json = JSON.parse(str);
            onChange({ ...json });
        } catch {
            onChange(false);
        }
    };

    if (data.content_type === undefined || !schemaItem || data.content_type === 'key-value') {
        return <FieldJson json={jsonString} onChange={replaceJSON} />;
    }

    if (!schema.properties) {
        return null;
    }
    const keys = data.type === 'key-value' ? Object.keys(data) : order;

    buildElementsBySchema(keys);


    if (!data) {
        console.error('Wrong value for JsonEdit!');
        return null;
    }


    return (
        <Fragment>
            <div className='col-sm-6'>
                {result}
            </div>
            
            <div className="col-sm-6">
                {showTimeline && <TimelinePhone {...data} /> }
            </div>

        </Fragment>
    );
};
