import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { browserHistory } from 'react-router';
import { Panel, Row, Column, FormGroup, InputField } from 'ui';
import ServerFetch from 'server-fetch';

/**
 * Extended Page Component for user_portal/activation/:token
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
        let token = this.props.params.token || '';

        this.state = {
            token: token,
            error: false,
            warning: false,
            loading: true
        };

        ServerFetch.setPortal('admin');

        this.appUrl = '/admin_portal';
        this.loadComponent = this.loadComponent.bind(this);
    }

    componentWillMount () {
        this.loadComponent();
    }

    loadComponent () {
        // Make sure there's a token specified.
        if (this.state.token == '') {
            this.setState({
                loading: false,
                error: 'No invitation token provided. Please ensure you followed the link from your invitation email.'
            });
            return;
        }

        ServerFetch.addRequest(this,'/api/api/acceptInvite', {
            token: this.state.token
        }, 'invitationToken.' + this.props.params.token, (json) => {
            let data = {
                loading: false
            };

            if (json.hasOwnProperty('status')) {
                if (json.status == 'OK' || (json.status >= 200 && json.status < 400)) {
                    // Success. Redirect to the application.
                    data.error = false;
                    setTimeout(() => {
                        window.location.href = this.appUrl;
                    }, 5000);
                } else {
                    data.warning = 'Your invitation token is either expired or invalid. Please contact your team administrator.';
                }

            } else {
                data.warning = 'An error occurred while trying to validate your token. Please try again, or contact your  team administrator if this problem persists.';
            }

            this.setState(data);

        },'POST');

        ServerFetch.renderFromServerState();
    }

    componentWillUnmount () {
        ServerFetch.cancelContext(this);
    }

    pageTitle () {
        return _`Accept Your Invitation | PHR Admin Portal`;
    }

    render () {
        return (<Page {...this.state} appUrl={this.appUrl} />);

        if (this.state == 'loading') {
            return (
                <h1>Activating your token please wait...</h1>
            );
        } else {
            return (
                <h1>Token has been accepted. You will now be redirected to your dashboard</h1>
            );
        }
    }
}

const Page = (props) => {
    return (
        <div className="login-component" style={{paddingTop: '20px'}}>
            <div className="container hint-admin_portal">
                <Row>
                    <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="2" sm="8" xs="12">
                        <Panel title='Admin Portal Invitation'>
                            <Content {...props} />
                        </Panel>
                    </Column>
                </Row>
            </div>
        </div>
    );
};

const Content = (props) => {
    if (props.loading) {
        return (
            <div className="content">
                <p>Processing your invitation. Please wait...</p>
            </div>
        );
    }

    if (props.error) {
        return (
            <div className="content">
                <div className="alert alert-error">
                    <span>{props.error}</span>
                </div>
            </div>
        );
    }

    if (props.warning) {
        return (
            <div className="content">
                <div className="alert alert-warning">
                    <span>{props.warning}</span>
                </div>
                <hr />
                <div className="form-group text-center">
                    <a href={props.appUrl}>Click here to continue to the app anyway.</a><br />
                    <span>However, please note that functionality may be limited.</span>
                </div>
            </div>
        );
    }

    return (
        <div className="content">
            <div className="alert alert-success">
                <span>Thank you. Your invitation has been accepted, and you will shortly be redirected to the app.</span>
            </div>
            <hr />
            <div className="form-group text-center">
                <Link to={props.appUrl}>Click here to continue immediately.</Link>
            </div>
        </div>
    );
};
