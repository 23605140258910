import React, { createContext, useContext } from 'react';

interface CompositionListResponse {
    message: {
        results: object[];
    };
}

export interface API {
    version: 1 | 2;
    get: (url: string) => Promise<any>;
    post: (url: string, data: object) => Promise<any>;
    fullList: (archetype: string, query: string[], folderId?: number, teamId?: number) => Promise<CompositionListResponse>;
    listByUuids: (archetype: string, uuids: string[], folderId?: number, teamId?: number) => Promise<CompositionListResponse>;
}

export const APIContext = createContext<API>(undefined);

export const useAPI = () => {
    const api = useContext(APIContext);
    return api;
};
