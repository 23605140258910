import { CompositionService } from 'services/composition.service';
import { makeGetRequest } from 'services/api';
import { DataStore } from 'services/data-store';

export class LocationsService extends CompositionService {
    get archetypeName(): string {
        throw new Error('Method not implemented.');
    }

    getLocations = (): Promise<any> => {
        const role = DataStore.get('me.currentRole');
        const apiVersion = 1;
        const location = 'appointment_locations';
        const id = 'Locations';

        return makeGetRequest(`/teams/${role.teamId}/content?using_role_uuid=${role.uuid}&location=${location}&id=${id}`, apiVersion);
    };
}

const locationsService = new LocationsService();

export {
    locationsService
};
