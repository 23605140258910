import React, { FC } from 'react';
import { DemographicsData } from '../DashboardDemographicsTable';
import _ from 'services/i18n';
import NhsNumber from 'ui/nhs-number';
import { ComponentLoadingWrapper } from 'features/patient/patient-dashboard/component-loading-wrapper/ComponentLoadingWrapper';
import classnames from 'classnames';
import { RoleType } from 'features/patient/patient-dashboard/RoleType';
import { PRIMARY_IDENTIFIER_NAMESPACE } from 'models/PersonPrimaryIdentifier';

interface DashboardDemographicsViewProps {
    demographics: DemographicsData;
    title: string;
    role?: RoleType;
    loading: boolean;
}

export const DashboardDemographicsView: FC<DashboardDemographicsViewProps> = (props) => {
    return  (
        <div className={classnames(['demographics-table', `widget-for--${props.role.toLowerCase()}`])}>
            <header>
                <div className="info-cell">
                    <strong>{props.title}</strong>
                </div>
                <div className="info-cell">
                    <span>{_`Role`}: </span>
                    <strong>{props.role}</strong>
                </div>
            </header>
            <ComponentLoadingWrapper loading={props.loading} dataExists={props.demographics && Boolean(Object.keys(props.demographics).length)} height={100}>
                <div className="demographics-table-body">
                    <div className='demographics-table-body__main'>
                        <h5>{props.demographics?.name}</h5>
                        <div className="demographics-table-body__right">
                            <div className="info-cell">
                                <span>{_`Born`}: </span>
                                <strong>{props.demographics?.dob}</strong>
                            </div>
                            <div className="info-cell">
                                <span>{_`Gender`}: </span>
                                <strong>{props.demographics?.gender}</strong>
                            </div>
                            <div className="info-cell">
                                <span>{props.demographics?.primaryIdentifier.label}: </span>
                                <strong>
                                    {props.demographics?.primaryIdentifier.namespace === PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER ? (
                                        <NhsNumber>
                                            {props.demographics?.primaryIdentifier.value}
                                        </NhsNumber>
                                    ) : props.demographics?.primaryIdentifier.value
                                    }
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div className="demographics-table-body__footer">
                        <div>
                            <strong>{props.demographics?.email}</strong>
                        </div>
                    </div>
                </div>
            </ComponentLoadingWrapper>
        </div>
    );
};
DashboardDemographicsView.defaultProps = {
    role: RoleType.Patient
};
