import React from 'react';
import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';
import { BulkOperationCostEstimator } from './BulkOperationCostEstimator';
import { BulkOperationPreview } from './BulkOperationPreviewDisplay';
import { CostEstimateValue } from './CostEstimateValue';
import { StateProvider } from 'components/dataSource/StateLinking';
import { BulkOperationRequestAction } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';

type CostEstimateChangeCallback = (newState: CostEstimateValue[]) => void;

/**
 * Due to be rewritten to conform to React coding standards in https://adi-health.atlassian.net/browse/MPA-7191
 * Don't copy this pattern
 */
export abstract class BulkOperationPreviewBuilder<S> implements StateProvider<CostEstimateValue[], null>
{
    private readonly actionState: S;

    private previewItems: BulkOperationPreviewItem[];
    private bulkOperationName: string;

    private costChangeListeners: Set<CostEstimateChangeCallback> = new Set();

    constructor(selectionState: FolderSelectionState, actionState: S)
    {
        this.actionState = actionState;
        this.previewItems = this.buildPreviewItems(selectionState);
    }

    abstract getActionName(): string;
    abstract buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[];
    public abstract getBulkOperations(): Promise<BulkOperationRequestAction<TriggerEngineRequestContent>[]>;

    protected getActionState(): S
    {
        return this.actionState;
    }

    public onAnyCostEstimateChange = (): void => {
        this.costChangeListeners.forEach((callback) => callback(this.getCostEstimates()));
    }

    public getCostEstimates = (): CostEstimateValue[] => {
        return this.previewItems.map((value: BulkOperationPreviewItem) => (value.costEstimator.getCurrentCostEstimate()));
    }

    public PreviewComponents(): JSX.Element[]
    {
        return this.previewItems.map((value: BulkOperationPreviewItem, index) => (value.PreviewComponent(index)));
    }

    public getBulkOperationName(): string
    {
        return this.bulkOperationName;
    }
    public setBulkOperationName(newName: string): void
    {
        this.bulkOperationName = newName;
    }

    public registerStateChangeListener(toRegister: {stateChangeCallback: CostEstimateChangeCallback}): void
    {
        this.costChangeListeners.add(toRegister.stateChangeCallback);
    }
    public deregisterStateChangeListener(toDeregister: {stateChangeCallback: CostEstimateChangeCallback}): void
    {
        this.costChangeListeners.delete(toDeregister.stateChangeCallback);
    }
}

export class BulkOperationPreviewItem
{
    readonly previewComponent: JSX.Element;
    readonly costEstimator: BulkOperationCostEstimator;

    constructor(previewComponent: JSX.Element, costEstimator: BulkOperationCostEstimator)
    {
        this.previewComponent = previewComponent;
        this.costEstimator = costEstimator;
    }

    public PreviewComponent(index: number): JSX.Element
    {
        return <BulkOperationPreview key={index} previewComponent={this.previewComponent} costEstimator={this.costEstimator} />;
    }
}
