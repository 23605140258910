/**
 * This is a workaround for some react-select display bugs which are fixed in 5.5.0 but upgrading is a breaking change
 * Until 5.5.0, showing the dropdown in a scrollable modal doesn't pin it to the right place,
 * so we have to target the backdrop instead of the modal itself and disable scrolling the modal while the dropdown is open.
 * Then we can sort the z-index out with CSS for when the dropdown menu portal is a descendant of the modal backdrop.
 * This causes the main body content (faded out behind the backdrop) to jump sideways by 17px for some reason,
 * but I don't care because it doesn't cause any real problems, and it's the least-bad option available for this ticket.
 */
const useDropdownTarget = (): [boolean, HTMLElement] => {
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
        return [ true, modalBackdrop as HTMLElement ];
    }
    return [ false, document.body ];
}

export default useDropdownTarget;
