import './Pathways.less';
import React, { useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from 'common/useOnClickOutside';

export const Pathways = ({
    pathways,
    allPathways,
    addIsActive,
    onRemove,
    onAdd,
}) => {
    if (!pathways || !allPathways) {
        return null;
    }

    const existedKeys = [...pathways.keys()];

    const pathwaysForDropdown = [...allPathways.entries()]
        .filter(([key, value]) => existedKeys.indexOf(key) === -1)
        .map(([key, pathway]) => ({
            name: pathway.name,
            callback: (event) => {event.preventDefault(); onAdd(pathway)},
        }));

    return (
        <div className="pathways-component">
            <table>
                <tbody>
                {[...pathways.entries()].map(([pathwayId, {name, context, type}], i) => {
                    return <PathwayItem
                        name={name}
                        context={context}
                        type={type}
                        key={pathwayId}
                        onRemove={onRemove}
                    />;
                })}
                </tbody>
            </table>
            {addIsActive && pathwaysForDropdown && !!pathwaysForDropdown.length &&
            <DropDown items={pathwaysForDropdown}>
                <button
                    className={'button-add'}
                    title="Add pathway"
                >Add+</button>
            </DropDown>
            }
        </div>
    );
};

const PathwayItem = ({name, context, type, onRemove}) => {
    return (
        <tr>
            <td className="label-column">
                <span className="pathway-label no-wrap" title={name}>{name}</span>
            </td>
            <td>
                <button
                    className="button-remove"
                    title="Remove pathway"
                    type="button"
                    onClick={() => onRemove({name, context, type})}
                >
                    <i className="fas fa-times"/>
                </button>
            </td>
        </tr>
    );
};

export const DropDown = ({ children, items, classNames = '' }) => {
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const setRootRefForClickOutsideHook = useOnClickOutside(setIsShowDropdown);

    const rootRef = useRef(null);

    const rootRefCallback = useCallback((node) => {
        if (node !== null) {
            rootRef.current = node;
            setRootRefForClickOutsideHook(node);
        }
    }, [setRootRefForClickOutsideHook]);

    const onItemClick = useCallback((event, callback) => {
        callback(event);
        setIsShowDropdown(false);
    }, []);

    const toggleDropdown = useCallback(() => {
        setIsShowDropdown(state => !state);
    }, []);

    return (
        <div className={'btn-group ' + classNames} ref={rootRefCallback}>
            <div aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleDropdown}
            >{children}</div>
            {!!isShowDropdown && (
                <ul className="dropdown-menu">
                    {items.map(({ name, callback }, i) => <li key={i}><a href="#" onClick={(event) => onItemClick(event, callback)}>{name}</a></li>)}
                </ul>
            )}
        </div>
    );
};
