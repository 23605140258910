import { apiV2Service } from './api-v2.service';
import { IRole } from 'phr-api-client';
import { DataStore } from 'services/data-store';
import { UserFolder } from 'models/UserFolder';
import { CreateUserContent } from 'models/TriggerEngineRequestContent';
import { terService } from 'services/ter.service';

class FolderService {

    get folderNames() {
        return Object.freeze({
            patient: 'Patient folders',
            staff: 'Staff folders',
            bot: 'Bot folders',
            team: 'Team folder'
        });
    }

    /**
     *
     * @param folderId
     * @param teamId
     * @returns {Promise}
     */
    getFolderData({ folderId, teamId }: {folderId: number; teamId: number}){
        return Promise.resolve();
    }

    getFoldersDefinitions({ role }: {role?: IRole}) {
        role = role || DataStore.get('me.currentRole');
        return apiV2Service.getDocuments({
            archetypeName: 'folder-group-definitions',
            role
        })
            .then((response) => {
                return response.message.results[0].content.folder_groups;
            });
    }

    getFolderGroupUuid({ role, folderName }: {role?: IRole; folderName: string}) {
        role = role || DataStore.get('me.currentRole');
        return this.getFoldersDefinitions({ role })
            .then((foldersDefinitions: any) => foldersDefinitions.find((folder: any) => folder.name === folderName).uuid);
    }

    getFolderById({ role, folderId }: {role?: IRole; folderId: number}): Promise<UserFolder> {
        role = role || DataStore.get('me.currentRole');
        return apiV2Service.getFolderMeta({
            folderId: folderId,
            role: {
                uuid: role.uuid,
                teamId: role.teamId
            }
        }).then(
            (response) => {
                if(response.data.status === 200) {
                    return response.message as UserFolder;
                } else {
                    console.error('Response:', response);
                    throw new Error('Unable to get Folder Meta');
                }
            });
    }

    getUserFolders({ role, offset = 0, limit = 100, sort, status = '', folderGroupUuids = [] }: {role?: IRole; sort?; offset?: number; limit?: number; status?: string; folderGroupUuids?: Array<string>}) {
        const test = apiV2Service.getUserFolders({
            role,
            'folder_group_uuids': folderGroupUuids.join(','),
            status,
            sort,
            offset,
            limit
        });
        return test;
    }

    public createUserFolder({ user, usingRoleUuid = '', folderGroupUuids, teamId = '' }: CreateUserFolderParams): any {
        const role = DataStore.get('me.currentRole');

        // eslint-disable-next-line @typescript-eslint/camelcase
        const folder = Object.assign(user, { role: { uuid: usingRoleUuid || role.uuid, teamId: teamId || role.teamId }, folder_group_uuids: folderGroupUuids }) as any;
        return apiV2Service.createFolder(folder);
    }

    public async createUserFolderWithTer({ user }: { user: CreateUserContent }) {
        return terService.createTer({ action: 'createUser', data: user, folderId: 'team' });
    }
}

export const folderService = new FolderService();

export interface CreateUserFolderParams {
    user: NewUser;
    usingRoleUuid?: string;
    folderGroupUuids: string[];
    teamId?: string;
}

export interface NewUser {
    email: string;
    name?: {
        prefix?: string;
        suffix?: string;
        given_name?: string;
        family_name?: string;
        middle_name?: string;
    };
    role_uuids: string[];
}

export type FolderId = number | 'my' | 'team' | 'all';
