import React, { FC, Fragment, FunctionComponent, useContext, useRef } from 'react';
import './GridHeaderCell.less';
import { GridContext } from './Grid';
import { SortOrder } from './SortOrder';
import { Filter } from './paginatedTableReducer';

const sortOrderClassMap = new Map([
    [SortOrder.ASC, 'ui-grid-header-cell__sort-arrow-asc'],
    [SortOrder.DESC, 'ui-grid-header-cell__sort-arrow-desc'],
    [SortOrder.NO, 'ui-grid-header-cell__sort-arrow-nope']
]);

export interface GridHeaderCellProps {
    sortable?: boolean;
    filterable?: boolean;
    sortOrder?: SortOrder;
    field?: string;
    colSpan?: number;
    rowSpan?: number;
    className?: string;
    isFilterRow?: boolean;
    title?: string;
    width?: string;
    filterType?: TABLE_FILTER;
    filterOptions?: string[];
    preventCloneForFilterRow?: boolean;
}

export enum TABLE_FILTER {
    SELECT = 'select',
    INPUT = 'input',
}

export const GridHeaderCell: FC<GridHeaderCellProps> = (props) => {
    const {
        children,
        sortable,
        filterable,
        field = '',
        colSpan = null,
        rowSpan = null,
        className = '',
        isFilterRow = false,
        width = null,
        filterOptions,
        filterType,
        preventCloneForFilterRow = false
    } = props;

    const title = props.title ?? (typeof children === 'string' ? children : '');

    const {
        onSort,
        onFilter,
        sortOrder: gridSortOrder,
        filterState: gridFilterState,
        globalSortable,
        globalFilterable,
    } = useContext(GridContext);

    const sortOrder = field && gridSortOrder && gridSortOrder[field] || SortOrder.NO;
    // eslint-disable-next-line no-prototype-builtins
    const filterState = field && gridFilterState ? gridFilterState?.hasOwnProperty?.(field) : '';

    const onType = (value: string) => {
        const newState = { ...gridFilterState, [field]: value };
        onFilter(newState);
    };

    const onToggle = () => {
        if(filterState) {
            const { [field]: __, ...newFilterState } = gridFilterState;
            onFilter(newFilterState);
        } else {
            onFilter({ ...gridFilterState, [field]: '' });
        }
    };


    const onChangeSort = () => {
        switch (sortOrder) {
        case SortOrder.NO:
        case SortOrder.DESC:
            return onSort({ [field]: SortOrder.ASC });
        case SortOrder.ASC:
            return onSort({ [field]: SortOrder.DESC });
        }
    };

    const cellClasses = '' +
        ((sortable ?? globalSortable) ? 'ui-grid-header-cell__sortable' : '') +
        ((filterable ?? globalFilterable) ? ' ui-grid-header-cell__filterable' : '') + ` ${className}`;

    const isSorted = sortOrder === SortOrder.ASC || sortOrder === SortOrder.DESC;

    return (
        <Fragment>
            {!isFilterRow && (
                <th className={`ui-grid-header-cell ${cellClasses} ${isSorted ? 'ui-grid__cell-sorted' : ''}`}
                    onClick={(sortable ?? globalSortable) ? onChangeSort : null}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    title={title}
                    style={width ? { width } : null}
                >
                    <div className="ui-grid-header-cell__layout">
                        <div className="ui-grid-header-cell__title-controls">
                            {children}
                        </div>
                    </div>
                    <GridHeaderCellControls {...{
                        filterable: filterable ?? globalFilterable,
                        sortable: sortable ?? globalSortable,
                        sortOrder,
                        filterState: Boolean(gridFilterState),
                        filterToggle: onToggle
                    }}/>
                </th>
            )}
            {isFilterRow && (
                <th className={'ui-grid-header-cell ' + cellClasses} colSpan={colSpan} rowSpan={rowSpan}>
                    {/* eslint-disable-next-line no-prototype-builtins */}
                    {(filterable ?? globalFilterable) && gridFilterState?.hasOwnProperty?.(field) && (
                        <div className="ui-grid-header-cell__filter">
                            <GridHeaderCellFilterInput {...{
                                filterState: gridFilterState,
                                field,
                                onType,
                                filterOptions,
                                filterType
                            }} />
                        </div>
                    )}
                </th>
            )}
        </Fragment>
    );
};

const GridHeaderCellFilterInput: FunctionComponent<{
    filterState: Filter;
    field?: string ;
    onType?: Function;
    filterOptions?: string[];
    filterType: TABLE_FILTER;
}> = ({
    filterState,
    field,
    onType,
    filterType = TABLE_FILTER.INPUT,
    filterOptions = []
}) => {
    const isFocused = useRef(false);

    if(filterType === TABLE_FILTER.INPUT) {
        return (
            <input ref={(ref) => {
                if (!ref || isFocused.current) { return; }
                ref.focus();
                isFocused.current = true;
            }}
            className={'form-control form-control_small'}
            type="text" value={filterState[field]}
            onChange={({ target }) => onType(target.value)}
            placeholder={'Filter...'}
            />
        );
    }

    if(filterType === TABLE_FILTER.SELECT) {
        const title = filterOptions.find((value) => value === filterState[field]) || '';
        return (
            <select ref={(ref) => {
                if (!ref || isFocused.current) { return; }
                ref.focus();
                isFocused.current = true;
            }}
            className={'form-control form-control_small'}
            title={title}
            value={filterState[field] || ''}
            onChange={({ target }) => onType(target.value)}
            >
                <option value={''}>{'All'}</option>
                {filterOptions.map((val, key) => {
                    return <option key={key} value={val}>{val}</option>;
                })}
            </select>
        );
    }

};

export interface GridHeaderCellControlsProps {
    filterable?: boolean;
    sortable?: boolean;
    sortOrder?: SortOrder;
    filterState?: boolean;
    filterToggle?: () => any;
}

export const GridHeaderCellControls: FunctionComponent<GridHeaderCellControlsProps> = (props) => {
    const {
        filterable = false,
        sortable = false,
        filterState = false,
        sortOrder = SortOrder.NO,
        filterToggle = () => undefined,
    } = props;

    return (
        <Fragment>
            {(filterable || sortable) ? (
                <div className="ui-grid-controls">
                    {filterable && (
                        <div className="ui-grid-controls__sort-button-container"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button
                                type={'button'}
                                className={'ui-grid-controls__sort-button' + (filterState ? ' active' : '')}
                                onClick={filterToggle}>
                                <i className="fas fa-filter"/>
                            </button>
                        </div>
                    )}
                    {sortable &&
                        <div className={'ui-grid-header-cell__sort-arrow ' + (sortOrderClassMap.get(sortOrder) || '')}/>
                    }
                </div>
            ) : null
            }
        </Fragment>
    );
};
