import React, { Component } from 'react';
import PageTitle from 'page-title';

import { set as _set,  cloneDeep as _cloneDeep } from 'lodash';
import { connect } from 'react-redux';


import { clearPatientData, loadUserPreferences, updateUserPrefences } from 'actions/patient.actions';
import { Loading, Custom } from 'common/ui/alert-boxes';

class SettingsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initialUserPreferencesContent: undefined,
            currentUserPreferencesContent: undefined,
            isSubmitting: false,
            errorMessage: '',
            shouldShowOptedOutWarning: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.loadData();
        PageTitle.setTitle('Settings | PHR Clinical Portal');
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.userPreferences === this.props.userPreferences) {
            return;
        }

        const content = nextProps.userPreferences.content;

        this.setState({
            initialUserPreferencesContent: _cloneDeep(content),
            currentUserPreferencesContent: _cloneDeep(content)
        });
    }

    onChange({target}) {

        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState((state) => {
            return {
                currentUserPreferencesContent: _set(state.currentUserPreferencesContent, target.name, value)
            };
        });
    }

    onSubmit(event) {

        event.preventDefault();

        this.setState({
            isSubmitting: true
        });

        this.props.dispatch(updateUserPrefences({content: this.state.currentUserPreferencesContent}))
            .then(() => {
                this.setState((state) => {
                    return {
                        initialUserPreferencesContent: _cloneDeep(state.currentUserPreferencesContent),
                        isSubmitting: false
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    isSubmitting: false
                });
            })
    }

    loadData() {
        this.props.dispatch(loadUserPreferences())
            .catch((error) => {
                console.error(error);
                this.setState({
                    errorMessage: `Failed to load user preferences`
                });
            });
    }

    shouldShowOptedOutWarning() {

        const fieldName = 'opted_out_of_digital_communications';

        return this.state.initialUserPreferencesContent[fieldName] === false && this.state.currentUserPreferencesContent[fieldName] === true;
    }

    componentWillUnmount() {
        this.props.dispatch(clearPatientData({fields: ['userPreferences']}));
    }

    render() {

        const {
            isSubmitting,
            errorMessage,
            currentUserPreferencesContent
        } = this.state;

        if (errorMessage) {
            return (<Custom boxStyle={'alert-danger'}>
                <p>
                    {errorMessage}
                </p>
            </Custom>);
        }

        if (!currentUserPreferencesContent) {
            return (<Loading show={true}/>);
        }

        const shouldShowOptedWarning = this.shouldShowOptedOutWarning();

        return (<div className="page user-preferences-page">
            <h3>
                Here you can edit patient's settings
            </h3>
            {shouldShowOptedWarning && <Custom boxStyle={'warning'}>
                <p>
                    After updating settings patient will no longer receive emails
                </p>
            </Custom>
            }
            <form onSubmit={this.onSubmit}>
                <div className="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            name="opted_out_of_digital_communications"
                            checked={Boolean(currentUserPreferencesContent.opted_out_of_digital_communications)}
                            onChange={this.onChange}/>
                        Should be opted out of digital communications
                    </label>
                </div>
                <button type="submit" className="pull-right btn btn-default" disabled={isSubmitting}>
                    Update
                </button>
            </form>
        </div>);
    }

}

function mapStateToProps({currentlyViewedPatient}) {

    const {
        composition,
        isFetching
    } = currentlyViewedPatient.get('userPreferences');

    return {
        userPreferences: composition,
        isLoading: isFetching
    }
}

export default connect(mapStateToProps)(SettingsPage);
