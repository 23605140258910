import React from 'react';
import PropTypes from 'prop-types'
import View from './view';
import BaseComponent from 'components/BaseComponent';
import { messagesService } from 'services/messages.service';

/**
 * Message list component
 */
export default class UserMessagesListComponent extends BaseComponent {
    constructor (props) {
        super(props);

        console.log(this.props.folderId);

        this.state = {
            folderId: this.props.folderId,
            error: false,
            searchText: '',
            pagination: {
                current: 1
            },
            results: [],
            perPage: 100,
            count: 0,
            loading: true,
            clinicalView: this.props.clinicalView || false,
            backRoute: this.props.backRoute || '/clinical_portal/patients',
            baseRoute: this.props.baseRoute || `${this.getFolderPath()}/messages`,
            createRoute: this.props.createRoute || `${this.getFolderPath()}/messages/create`
        };

        this.bindEvents([
            'Submit',
            'ChangeSearch',
            'ChangePage'
        ]);

        this.bindFunctions([
            'loadComponent',
            'onResults'
        ]);

        this.View = View;
    }

    handleChangePage (idx) {
        this.setState({
            pagination: {
                current: idx
            }
        }, this.loadComponent);
    }

    loadComponent (folderId) {
        let search = undefined;
        this.setState({
            error: false,
            loading: true
        });

        let searchText = this.state.searchText.trim();
        if (searchText !== this.state.searchText) {
            this.setState({
                searchText: searchText
            });
        }

        if (searchText !== '') {
            search = {
                'content.title': {
                    $regex: searchText,
                    $options: 'i'
                }
            };
        }

        const offset = (this.state.pagination.current - 1) * this.state.perPage;
        let promise;

        if (search) {
            promise = messagesService.search({folderId, sort: [{field: 'created_at', ascending: false}], offset, limit: this.state.perPage, search});
        } else {
            promise = messagesService.list({folderId, sort: '+created_at', offset});
        }

        promise.then(this.onResults);
    }

    componentWillReceiveProps (newProps) {
        this.setState({
            clinicalView: newProps.clinicalView || false
        }, () => {
            this.loadComponent(this.state.folderId);
            this.server.renderFromServerState();
        });
    }

    componentWillMount () {
        this.loadComponent();
        this.server.renderFromServerState();
    }

    onResults (response, status) {
        if (status >= 300) {
            this.setState({
                error: true,
                loading: false
            });
        } else {
            this.setState({
                error: false,
                loading: false,
                results: response.message.results,
                count: response.message.total
            });
        }
    }

    handleChangeSearch (e) {
        this.setState({
            searchText: e.target.value
        });
    }

    handleSubmit (e) {
        e.preventDefault();

        this.setState({
            pagination: {
                current: 1
            }
        }, this.loadComponent);
    }
}

UserMessagesListComponent.propTypes = {
    baseRoute: PropTypes.string,
    createRoute: PropTypes.string,
    backRoute: PropTypes.string
};

