import React from 'react';
import _ from 'services/i18n';
import ErrorBox from 'components/errorbox';
import { Link } from 'react-router';
import { Panel, Row, Column, FormGroup, InputField } from 'ui';

/**
 * A generic logout component
 */
export default class extends React.Component {
    renderLoading () {
        return (
            <div className="panel-loading">
                <span className="glyphicon glyphicon-lock"/>
            </div>
        );
    }

    render () {
        if (this.props.complete) {
            return this.renderComplete();
        }
        return (
            <div className="register-component" style={{paddingTop: '20px'}}>
                <div className="container">
                    <Row>
                        <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="3" sm="6">
                            <Panel title={_`Forgot Password`}>
                                {this.props.loading ? this.renderLoading() : null}
                                <p>{_`Please enter your email address below. After entering you will receive an email which you can use to reset your password.`}</p>
                                <ErrorBox errors={this.props.errors}/>
                                <form onSubmit={this.props.onSubmit}>
                                    <FormGroup>
                                        <label>{_`Email`}</label>
                                        <InputField name="email" cls={this.props} />
                                    </FormGroup>
                                    <FormGroup>
                                        <button type="submit" className="btn btn-primary btn-block">{_`Forgot Password`}</button>
                                    </FormGroup>
                                    {this.props.portal !== 'camhs' && <div className="form-group text-center">
                                        <Link to={"/" + this.props.portal + "/login" + (this.props.location.search || '')} className="text-info">{_`Return to Login Page`}</Link>
                                    </div>}
                                    <hr />
                                    <div className="form-group text-center">
                                        <Link to={"/" + this.props.portal + "/sign-up" + (this.props.location.search || '')} className="text-success">{_`Sign Up`}</Link>
                                    </div>
                                </form>
                            </Panel>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }

    renderComplete () {
        return (
            <div className="register-component" style={{paddingTop: '20px'}}>
                <div className="container">
                    <Row>
                        <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="2" sm="8">
                            <Panel title={_`Forgot Password Email`}>
                                <p>{_`If your account exists in the system you will shortly receive an email which you can use to reset your password.`}</p>
                                <div className="form-group text-center">
                                    <Link to={"/" + this.props.portal + "/login" + (this.props.location.search || '')}>Return to Login Page</Link>
                                </div>
                            </Panel>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }
}