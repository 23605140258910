/* eslint-disable @typescript-eslint/camelcase */
import { CompositionService } from './composition.service';
import * as lodash from 'lodash';
import { IRole } from 'phr-api-client/index';
import _ from 'services/i18n';
import { TeamPreferences } from 'models/TeamPreferences';

class TeamPreferencesService extends CompositionService {

    get archetypeName(): string {
        return 'team-preferences';
    }

    static readonly defaultProperties: TeamPreferences = Object.freeze({
        show_appointments: true,
        show_referrals: true,
        show_goals: true,
        show_messages: true,
        show_communications_tab: false,
        show_results_page_v2: false,
        show_mechanical_turk_elements: false,
        show_advanced_search_button: false,
        show_create_patient: false,
        show_create_appointment_button: true,
        show_create_patient_button: false,
        can_user_add_personalized_resource: false,
        hide_allocate_questionnaire: false,
        referrals_list_category_groups: [],
        team_role_uuids: {} as any,
        pathway_list: [],
        primary_identifier: void 0,
        features: {
            public_resources: false,
            resource_tags: false,
        },
        portal: {
            routes_enabled: [
                'my-team',
                'my-team/patients',
                'worklists',
                'user-feedback',
            ],
            graph_results: [],
            admin_registration_with_ter: false,
            show_pathway: true,
            show_dashboard: false,
            dashboard_modules: [],
        },
    });

    get defaultProperties(): any {
        return lodash.cloneDeep(TeamPreferencesService.defaultProperties);
    }

    getFirst({ role }: { role?: IRole }): Promise<any> {
        return this.list({ role, folderId: 'team' })
            .then((response) => {
                const content = lodash.get(response, 'message.results[0].content', {});

                if (content.portal && content.portal.routes_enabled) {
                    content.portal.routes_enabled = [
                        ...content.portal.routes_enabled,
                        'my-team'
                    ];
                }

                return lodash.merge(this.defaultProperties, content, content.portal);
            });
    }

    get() {
        return this.getFirst({});
    }

    extractPrimaryIdentifierData = (
        userPrimaryIdentifier = { namespace: '', value: '' },
        teamPreferencesOfPrimaryIdentifier = { human_readable_labels: [] }
    ) => {

        const userPrimaryIdentifierNamespace = userPrimaryIdentifier.namespace;
        const userPrimaryIdentifierValue = userPrimaryIdentifier.value;
        const availableLabels = teamPreferencesOfPrimaryIdentifier.human_readable_labels;
        const found = availableLabels.find(({ namespace: ns }) => ns === userPrimaryIdentifierNamespace);
        const label = found && found.label;

        return {
            label,
            namespace: userPrimaryIdentifierNamespace,
            value: userPrimaryIdentifierValue,
        };
    }
}

export const extractPrimaryIdentifierNamespaceAndLabel = (teamPrefPrimaryIdentifier, namespace = void 0) => {
    const nhsNamespace = 'NHS_Number';
    const nhsLabel = _`NHS Number`;
    if (!teamPrefPrimaryIdentifier) {
        return { namespace: nhsNamespace, label: nhsLabel };
    }

    namespace = namespace || teamPrefPrimaryIdentifier.namespace || nhsNamespace;

    const label = (teamPrefPrimaryIdentifier.human_readable_labels || [])
        .find(({ namespace: ns }) => namespace === ns) || { human_readable_label: nhsLabel };

    return {
        namespace,
        label: label.label,
    };
};

export const teamPreferencesService = new TeamPreferencesService();
export default teamPreferencesService;
