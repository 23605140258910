import React from 'react';
import InviteBar from 'components/invite-bar';
import DataStore from 'services/data-store';
import BaseComponent from 'components/BaseComponent';

import { demographicsService } from 'services/demographics.service'
import { folderService } from 'services/folder.service'

/**
 * This component is a container for all pages which operate on a specific patient folder.
 * It verifies that the user is allowed to access the folder, and adds a patient information
 *  bar along the top.
 */
export default class FolderCheckMiddleware extends BaseComponent {
    constructor (props) {
        super(props);

        this.state = {
            // Indicates if we're currently loading the folder data.
            loading: true,

            // Indicates if there was a problem loading t   he folder data.
            loadingError: false,

            // The numeric ID of the folder which is currently being accessed.
            folderId: parseInt(this.props.params.folderId),

            // An object containing patients demographics composition data
            demographics: null,

            // Numeric ID of the team through which the user is accessing the system.
            teamId: (DataStore.get('me.currentRole') || {}).teamId,

            // Current service name
            teamService: (DataStore.get('me.currentRole') || {}).serviceName || ''
        };

        DataStore.set('currentFolder', this.state.folderId);

        this.listenIdx = null;
    }

    componentWillReceiveProps (newProps) {

        const newFolderid = parseInt(newProps.params.folderId);

        if (newFolderid === this.state.folderId){
            return;
        }

        DataStore.set('currentFolder', newFolderid);

        this.$setState({
            folderId: newFolderid
        })
            .then(() => {
                this.loadData();
            });
    }

    loadData(){
        this.$setState({
            loading: true,
            loadingError: false,
            demographics:{}
        })
            .then(() => {
                return demographicsService.getFirst({folderId:this.state.folderId})
            })
            .then((demographics) => {
                this.setState({
                    loading:false,
                    demographics: demographics
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    loading: false,
                    loadingError: true
                });
            });
    }

    componentWillMount () {

        // Listen for changes of team selections.
        this.listenIdx = DataStore.getListener('me.currentRole', (value) => {
            this.setState({
                teamId: value.teamId
            });
        });

        this.loadData();
    }

    componentWillUnmount () {
        DataStore.removeListener(this.listenIdx);
    }

    render () {

        let patientData = {
            name: '',
            nhs_number: ''
        };
        let bodyContent = null;

        if (this.state.loadingError) {
            bodyContent = (
                <div className="page-not-found">
                    <h1>Page Not Available</h1>
                    <p>Sorry. The page you are trying to access has not been found or is not available. Please navigate using the sidebar.</p>
                </div>
            );

        } else if (this.state.loading) {
            patientData.name = '(loading)';
            patientData.nhs_number = '(loading)';

            bodyContent = (
                <div>
                    <h1>Loading. Please wait...</h1>
                </div>
            );

        } else {
            // We're using the InviteBar for all patient folders just now because it doesn't display a photo.
            // We don't support user photos at the moment.
            // The bar expects data lifted directly from the database in a particular format.
            // We need to massage the data to keep it happy until we can do some refactoring.

            let { demographics } = this.state;

            demographics = demographics || {};

            patientData = {
                name: demographics.content.name || '(name unknown)',
                nhs_number: demographics.content.identifiers.nhs_number || '',
                date_of_birth: demographics.content.date_of_birth,
                preferred_name: demographics.content.name
            };

            if (this.props.children) {
                bodyContent = React.cloneElement(this.props.children, {
                    teamId: this.state.teamId,
                    folderId: demographics.folder_id,
                    patientData: patientData,
                    demographicsData: this.state.demographics.content || {}
                });
            } else {
                console.warn('This element has no child content. The page will be blank!');
            }
        }

        const isInviteBarVisible = this.state.teamService !== 'MSK';

        return (
            <div className="patient-screen">
                { isInviteBarVisible && <InviteBar
                    folderId={this.state.folderId}
                    invite={this.state.demographics}
                /> }
                {bodyContent}
            </div>
        );
    }
}