import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import Select from 'react-select';
import _ from 'services/i18n';
import ErrorBox from 'components/errorbox';
import Wysiwyg from 'components/wysiwyg';
import { CreateResourceState } from './validator';
import { PushNotificationCreateForm } from 'components/user/push-notification/create/form';
import { useSortedDepartmentCompositionData } from 'features/department/hooks/useSortedDepartmentData';
import { useGroupedDepartmentDataOptionList } from 'features/department/hooks/useGroupedDepartmentDataOptionList';
import { Composition } from 'models/Composition';
import { ResourceCategory } from 'models/ResourceCategory';
import { DataOption } from 'features/department/department.interface';
import { DropdownTagsInput } from 'common/ui/tags/DropdownTagsInput';
import { resourcesService } from 'services/resources.service';

type CreateResourceFormProps = {
  onSubmit: (f) => unknown;
  onChange: (c) => unknown;
  form: CreateResourceState;
  hideSubmit?: boolean;
  buttonLabel?: string;
  errors?: string[];
  onShowMediaResource?: () => unknown;
  showPatientOptions?: boolean;
}

export const ResourceCreateForm: FC<CreateResourceFormProps> = ({
    buttonLabel,
    onSubmit,
    onChange,
    errors,
    onShowMediaResource,
    form,
    hideSubmit,
    showPatientOptions = true
}) => {

    const [allTags, setAllTags] = useState([]);

    useEffect(() => {
        async function loadTags() {

            const allResources = await resourcesService.list({ archetypeName: resourcesService.archetypeName, limit: Infinity, folderId: 'team' });
            const labels = allResources.message.results.map(resource => resource.content.labels || []).flat();

            const tags = labels.filter(label => label.type === 'resourceTags')
                .map(label => label.name);

            const uniqueTags = tags.filter((tag, i, arr) => arr.indexOf(tag) === i);

            setAllTags(uniqueTags);
        }
        loadTags();
    }, []);

    if (!form.type) {
        return (
            <>
                <label><b>{_`Type of resource you would like to add`}</b></label>
                <select
                    name="type"
                    value={form.type}
                    onChange={(e) => onChange({ type: e.target.value })}
                    className="form-control">
                    <option value="">-- Select Type --</option>
                    <option value="audio">{_`Audio File`}</option>
                    <option value="phone">{_`Contact Number`}</option>
                    <option value="pdf">{_`PDF`}</option>
                    <option value="url">{_`URL`}</option>
                    <option value="video">{_`Video`}</option>
                    <option value="media_resource">{_`Upload new file`}</option>
                    <option value="html">{_`HTML`}</option>
                </select>
            </>
        );
    }

    return (
        <form onSubmit={onSubmit} className="form">
            <div className="row">
                <div className="col-md-12">
                    <ErrorBox errors={errors} />

                    <div className="form-group">
                        <label>{_`Type`}</label>
                        <select
                            name="type"
                            value={form.type}
                            onChange={(e) => onChange({ type: e.target.value })}
                            className="form-control">
                            <option value="">-- Select Type --</option>
                            <option value="audio">{_`Audio File`}</option>
                            <option value="phone">{_`Contact Number`}</option>
                            <option value="pdf">{_`PDF`}</option>
                            <option value="url">{_`URL`}</option>
                            <option value="video">{_`Video`}</option>
                            <option value="media_resource">{_`Upload new file`}</option>
                            <option value="html">{_`HTML`}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>{_`Title`}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="label"
                            onChange={(e) => onChange({ label: e.target.value })}
                            value={form.label} />
                    </div>
                    <div className="form-group">
                        <label>{_`Tags`}</label>
                        <DropdownTagsInput allTags={allTags} tags={form.tags} onChange={(tags) => onChange({ tags: tags })}/>
                    </div>
                    <ShowUrl type={form.type}>
                        <div className="form-group">
                            <label>{_`URL`}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="url"
                                onChange={(e) => onChange({ url: e.target.value })}
                                value={form.url} />
                        </div>
                    </ShowUrl>
                    <ShowPhone type={form.type}>
                        <div className="form-group">
                            <label>{_`Phone Number`}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="phone"
                                onChange={(e) => onChange({ phone: e.target.value })}
                                value={form.phone} />
                        </div>
                    </ShowPhone>
                    <ShowHTML type={form.type}>
                        <div className="form-group">
                            <label>{_`HTML`}</label>
                            <Wysiwyg
                                onChange={(e) => onChange({ html: e.target.value })}
                                value={form.html}
                            />
                        </div>
                    </ShowHTML>
                    { form.type === 'media_resource' && form.mediaFileUuid && (
                        <div className="form-group attached-pdf-link">
                            <a
                                href="javascript: void(0)"
                                onClick={onShowMediaResource}>
                                <i className="fas fa-file-pdf"/> {_`Attached PDF File`}
                            </a>
                        </div>
                    )}
                    { form.type === 'media_resource' && (
                        <div className="form-group">
                            <label htmlFor="file">{form.mediaFileUuid ? _`New file` : _`File`}</label>
                            <input
                                type="file"
                                onChange={(e) => onChange({ mediaFile: e.target.files[0] })}
                                className="form-control"
                                accept="application/pdf"
                                name="file" />
                        </div>
                    )}
                    <div className="form-group">
                        <label>{_`Description`}</label>
                        <textarea
                            className="form-control"
                            name="description"
                            onChange={(e) => onChange({ description: e.target.value })}
                            value={form.description} />
                    </div>
                    {showPatientOptions && (
                        <>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="shouldNotifyTimeline"
                                        onChange={(e) => onChange({
                                            notifyTimeline: e.target.checked,
                                            lozengeText: '',
                                            lozengeContent: '',
                                            sendPush: false
                                        })}
                                        checked={form.notifyTimeline} />{' Notify Resource on Patient\'s Timeline'}
                                </label>
                            </div>
                            { form.notifyTimeline && (
                                <div>
                                    <div className="form-group">
                                        <label>{_`Lozenge Title`}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="lozengeTitle"
                                            onChange={(e) => onChange({ lozengeTitle: e.target.value })}
                                            value={form.lozengeTitle} />
                                    </div>
                                    <div className="form-group">
                                        <label>{_`Lozenge text`}</label>
                                        <textarea
                                            className="form-control"
                                            name="lozengeText"
                                            onChange={(e) => onChange({ lozengeText: e.target.value })}
                                            value={form.lozengeText} />
                                    </div>
                                    <PushNotificationCreateForm
                                        sendPush={form.sendPush}
                                        onChange={onChange}
                                    />
                                </div>
                            ) }
                        </>
                    )}
                </div>
            </div>
            { !hideSubmit && (
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={onSubmit}>{buttonLabel}</button>
          &nbsp;
                    </div>
                </div>
            ) }
        </form>
    );
};

const ShowPhone = ({ type, children }) => {
    if (type == 'phone') {
        return (
            <div>
                {children}
            </div>
        );
    }

    return <noscript />;
};

const ShowHTML = ({ type, children }) => {
    if (type === 'html') {
        return (
            <div>
                {children}
            </div>
        );
    }

    return null;
};

const ShowUrl = ({ type, children }) => {
    const isUrlBasedResourceType = !['phone', 'media_resource', 'html'].includes(type);

    if (isUrlBasedResourceType) {
        return (
            <div>
                {children}
            </div>
        );
    }
    return <noscript />;
};
