import React from 'react';


export default function ToggleSwitch(props: {
    value: boolean;
    setValue: (newValue: boolean) => void;
    offLabel: string;
    onLabel: string;
}) {
    return (
        <div className='form-switch-wrapper'>
            <button
                onClick={() => props.setValue(false)}
                className={'form-switch-button' + (props.value ? '' : ' selected')}
                data-testid='toggle-off'
            >
                {props.offLabel}
            </button>
            <label className={'form-switch'}>
                <input
                    onChange={() => props.setValue(!props.value)}
                    type={'checkbox'}
                    checked={props.value}
                />
                <span className={'slider round'} />
            </label>
            <button
                onClick={() => props.setValue(true)}
                className={'form-switch-button' + (props.value ? ' selected' : '')}
                data-testid='toggle-on'
            >
                {props.onLabel}
            </button>
        </div>
    );
}
