import React from 'react';
import { Route } from 'react-router';

import AdminPortal from 'routes/admin-portal';
import ClinicalPortal from 'routes/clinical-portal';
import Guest from 'routes/guest';
import CoopWeb from 'routes/coopweb';

const routes = (
    <Route path="/">
        {Guest}
        {AdminPortal}
        {ClinicalPortal}
        {CoopWeb}
    </Route>
);

export default routes;
