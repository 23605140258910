import Immutable from 'immutable';
import { PATIENT } from 'actions/types';

const initialState = Immutable.Map({
    userPreferences: {
        isFetching: false,
        composition: undefined
    }
});

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PATIENT.LOAD_PREFERENCES_START:
            return state.setIn(['userPreferences', 'isFetching'], true);
        case PATIENT.LOAD_PREFERENCES_SUCCESS:
            return state.setIn(['userPreferences', 'composition'], action.composition);
        case PATIENT.LOAD_PREFERENCES_FAIL:
            return state.setIn(['userPreferences', 'isFetching'], false);
        case PATIENT.CLEAR_DATA:

            if (!action.fields || !action.fields.length) {
                return initialState;
            }

            const newState =  action.fields.reduce((acc: any, field: any) => {
                acc[field] = initialState.get(field);
                return acc;
            }, {});

            return state.merge(newState);
        default:
            return state;
    }
};

export default reducer;