import _ from 'services/i18n';
import { convertToDate, standardDateTimeFormats } from 'common/datetime/convertToDate';

export enum TRIAGE_STATUS {
    NOT_AVAILABLE = '__01_not_available',
    NONE = '__02_create',
    ONE_TO_ONE = 'one-to-one',
    ONE_TO_ONE60 = 'One to one 60',
    ONE_TO_ONE30 = 'One to one 30',
    TELEPHONE = 'telephone',
    TELEPHONE60 = 'Telephone 60',
    TELEPHONE30 = 'Telephone 30',
    VIDEO = 'video',
    GROUP = 'group',
    REJECT = 'reject',
}

export enum QUESTIONNAIRE_STATUS {
    NONE = '__01_none',
    COMPLETED = '__02_results',
}

export enum APPOINTMENT_STATUS {
    NOT_AVAILABLE = '__03_not_available',
    UNKNOWN = '__02_unknown',
    NONE = '__01_none',
    AVAILABLE_TO_BOOK = 'available-to-book',
    BOOKED = 'booked',
    CANCELLED = 'cancelled',
    WAITING = 'waiting',
    ADMITTED = 'admitted',
    ARRIVED = 'arrived',
    CALLED = 'called',
    SEEN = 'seen',
    DEPARTED = 'departed',
    DISCHARGED = 'discharged',
    DID_NOT_ATTEND = 'did-not-attend',
    CLOSED = 'closed',
}

export function getNameFromDemographics(demographic) {
    if (!demographic) { return ''; }
    const demographicContent = demographic.content;
    const nameObject = (demographicContent.preferred_name || demographicContent.name);

    if (!nameObject) {
        return '';
    }

    return `${nameObject.given_name || ''} ${nameObject.family_name || ''}`.trim();
}

export function getFullNameFromDemographics(demographic) {
    if (!demographic) { return ''; }
    const demographicContent = demographic.content;
    const nameObject = (demographicContent.preferred_name || demographicContent.name);

    if (!nameObject) {
        return '';
    }

    const {
        family_name: familyName,
        given_name: givenName,
        prefix,
    } = nameObject;

    const name = `${familyName || ''}${familyName && givenName ? ',' : ''}  ${givenName || ''}`;

    return `${name} ${prefix || ''}`.trim();
}

export function getNHSFromDemographics(demographic) {
    return (demographic && demographic.content && demographic.content.identifiers && demographic.content.identifiers.nhs_number) || '';
}

export function getHospitalNumberFromDemographics(demographic) {
    return (demographic && demographic.content && demographic.content.identifiers && demographic.content.identifiers.rhq_id) || '';
}

export function getDobFromDemographics(demographic) {
    const date = convertToDate(demographic?.content?.date_of_birth);
    return (date && date.isValid() && date.unix()) || 0;
}

export function getPatientBannerInfo(demographics) {
    return {
        name: getFullNameFromDemographics(demographics),
        dob: getDobFromDemographics(demographics),
        nhs: getNHSFromDemographics(demographics),
    };
}

export function getQuestionnaireStatus(questionnaireResponse) {
    if (!questionnaireResponse) { return QUESTIONNAIRE_STATUS.NONE; }

    if (questionnaireResponse.content.status === 'scored' || questionnaireResponse.content.status === 'complete') {
        return QUESTIONNAIRE_STATUS.COMPLETED;
    }

    return QUESTIONNAIRE_STATUS.NONE;
}

export function getTriageDecisionStatusFromReferral(referral, questionnaireStatus) {
    const isAntenatalStatus = (triageDecisionStatus) => !!Object.entries(TRIAGE_STATUS).find(([, status]) => status === triageDecisionStatus);
    if (!referral.content.triage_decision && questionnaireStatus === QUESTIONNAIRE_STATUS.COMPLETED) {
        return TRIAGE_STATUS.NONE;
    }

    if (!isAntenatalStatus(referral.content.triage_decision)) {
        return TRIAGE_STATUS.NOT_AVAILABLE;
    }

    if (questionnaireStatus !== QUESTIONNAIRE_STATUS.COMPLETED) {
        return TRIAGE_STATUS.NOT_AVAILABLE;
    }

    return referral.content.triage_decision;
}

export function isAppointmentLockedForEdit(appointment) {
    if(!appointment || appointment.content.status !== APPOINTMENT_STATUS.AVAILABLE_TO_BOOK) {
        return true;
    }

    const timeToLock = (appointment && appointment.content.lock_ubrn_booking_password);
    let appointmentUBRNBookingPasswordLockedForEdit = false;
    if(timeToLock) {
        appointmentUBRNBookingPasswordLockedForEdit = timeToLock > (Date.now() / 1000);
    }

    return appointmentUBRNBookingPasswordLockedForEdit;
}

export function getAppointmentBookingPasswordAndUBRNFromComposition(appointment) {
    if (!appointment) {
        return {
            ubrn: undefined,
            bookingPassword: undefined,
        };
    }
    return {
        ubrn: appointment.content.ubrn,
        bookingPassword: appointment.content.booking_password,
    };
}

export function getAppointmentStatus(appointment, triageStatus) {
    if (!triageStatus) {
        return APPOINTMENT_STATUS.NOT_AVAILABLE;
    }

    if (triageStatus === TRIAGE_STATUS.NOT_AVAILABLE || triageStatus === TRIAGE_STATUS.NONE || triageStatus === TRIAGE_STATUS.REJECT) {
        return APPOINTMENT_STATUS.NOT_AVAILABLE;
    }

    if (!appointment) { return APPOINTMENT_STATUS.NONE; }
    if (!Object.values(APPOINTMENT_STATUS).includes(appointment.content.status)) { return APPOINTMENT_STATUS.UNKNOWN; }

    return appointment.content.status;
}

export function getAppointmentDetails(appointment) {
    if (!appointment || !appointment.content || !appointment.content.interpreter) {
        return {
            isInterpreter: false,
            interpreterDetails: '',
        };
    }

    return {
        isInterpreter: appointment.content.interpreter.required,
        interpreterDetails: appointment.content.interpreter.details || '',
        triageDetails: appointment.content.triage_decision_notes || '',
    };
}

export function getAppointmentLabel(appointment, appointmentStatus): string {
    if (appointmentStatus === APPOINTMENT_STATUS.NONE || appointmentStatus === APPOINTMENT_STATUS.NOT_AVAILABLE) { return ''; }

    if (appointmentStatus === APPOINTMENT_STATUS.AVAILABLE_TO_BOOK) {
        const isEdit = !isAppointmentLockedForEdit(appointment) && appointment.content.ubrn && appointment.content.booking_password;
        return isEdit ? _`Edit` : _`Create`;
    }

    if (appointment.content.unknown_date && appointmentStatus === APPOINTMENT_STATUS.BOOKED) {
        return _`Unknown date`;
    }

    if (appointment.content.date && appointmentStatus === APPOINTMENT_STATUS.BOOKED) {
        const date = convertToDate(appointment.content.date);
        return date && date.isValid() && date.format(standardDateTimeFormats.nhs_date_short);
    }

    switch (appointmentStatus) {
    case APPOINTMENT_STATUS.BOOKED: return _`Booked`;
    case APPOINTMENT_STATUS.WAITING: return _`Waiting`;
    case APPOINTMENT_STATUS.ADMITTED: return _`Admitted`;
    case APPOINTMENT_STATUS.ARRIVED: return _`Arrived`;
    case APPOINTMENT_STATUS.CALLED: return _`Called`;
    case APPOINTMENT_STATUS.SEEN: return _`Seen`;
    case APPOINTMENT_STATUS.DEPARTED: return _`Attended`;
    case APPOINTMENT_STATUS.DISCHARGED: return _`Discharged`;
    case APPOINTMENT_STATUS.DID_NOT_ATTEND: return _`Did not attend`;
    case APPOINTMENT_STATUS.CANCELLED: return _`Cancelled`;
    case APPOINTMENT_STATUS.CLOSED: return _`Closed`;
    }
}

export function getPatientUrlFromReferral(referral) {
    return `/clinical_portal/folder/${referral.folder_id}/patient/pathway`;
}
