import DataStore from 'services/data-store';

export const getTeam = () => {
    return DataStore.get('me.currentRole') || {};
};

export const getTeamId = () => {
    const role = DataStore.get('me.currentRole');
    return role ? role.teamId : null;
};
