import { useState, useEffect } from 'react';
import DataStore from 'services/data-store';

export function useCurrentOrg() {
    const [currentOrg, setCurrentOrg] = useState({});

    useEffect(() => {
        const listenIdx = DataStore.addListener('me.currentOrg',
            (currentOrg = {}) => {
                setCurrentOrg(currentOrg);
            }
        );

        const currentOrg = DataStore.get('me.currentOrg') || {};
        setCurrentOrg(currentOrg);

        return () => {
            DataStore.removeListener(listenIdx);
        };
    }, []);

    return currentOrg;
}
