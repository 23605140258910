import React from 'react';
import PropTypes from 'prop-types';
import TeamSelectorComponent from './view';
import BaseComponent from 'components/BaseComponent';

let listenIdx;
/**
 * Create a modal that contains a lookup for medicines
 */
export default class TeamSelector extends BaseComponent {

    /**
     * Initialise default values of lookup and bind functions
     *
     * @param {Object} props - The properties to pass in
     */
    constructor (props) {
        super(props);

        this.state = {
            selectedItemIndex: null,
            hasInvites: false
        };

        this.bindEvents([
            'Selection',
            'SelectionSubmit'
        ]);

        this.View = TeamSelectorComponent;
    }

    componentWillMount () {
        listenIdx = this.data.getListener('has_invites', (value) => {
            this.setState({
                hasInvites: value
            });
        });
    }

    componentWillUnmount () {
        this.data.removeListener(listenIdx);
        listenIdx = null;
        this.server.cancelContext();
    }

    handleSelection (idx) {
        this.setState({
            selectedItemIndex: idx
        });
    }

    handleSelectionSubmit (idx) {
        this.setState({
            selectedItemIndex: idx
        }, () => {
            if (this.state.selectedItemIndex !== null) {
                this.props.onSubmit(this.props.teams[this.state.selectedItemIndex]);
            }
        });
    }
}

TeamSelector.propTypes = {
    teams: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired
};

TeamSelector.defaultProps = {
    teams: []
};
