export type SimpleQuestionnaire = {
  name: string;
  description: string;
  location: string;
  content_type: string;
  live: {
    introduction_text: string;
    questions: [{
      question_text: string;
      type: SimpleQuestionnaireType;
      parameters: {
        options?: string[];
        min_value?: number;
        max_value?: number;
        step_value?: number;
        discrete_steps?: number;
      };
    }];
  };
}

export type DepartmentSimpleQuestionnaire = {
  label: string;
  value: SimpleQuestionnaireItem;
}

export type SimpleQuestionnaireItem = {
  available_states: {
    live: boolean;
    test: boolean;
    draft: boolean;
  };
  content_type: string;
  location: string;
  name: string;
  id: string;
  department_folder_uuid?: string;
}

export enum SimpleQuestionnaireType {
  slider = 'slider',
  multiselect = 'multi-select',
  mcq = 'multiple-choice',
  text = 'text',
  number = 'numeric'
}

export interface SimpleQuestionnaireCreate {
  location: string;
  id: string;
  version: string;
  content_type: string;
  live: {
    in_lozenge: boolean;
    name: string;
    questions: SimpleQuestion[];
    introduction_text: string;
  };
}

export type SimpleQuestionnaireApiForm = {
  location: string;
  id: string;
  version: string;
  content_type: string;
  content: {
    in_lozenge: boolean;
    name: string;
    introduction_text: string;
    questions: SimpleQuestion[];
  };
}

export type SimpleQuestion = {
  question_text: string;
  question_type: SimpleQuestionnaireType;
  question_parameters?: {
    options: string[];
    max_text_length?: number;
  };
  order: number;
  isQuestionValid: boolean;
}

export type SimpleQuestionnaireFormResult = {
  messages: string[];
  isValid: boolean;
}

interface CreateQuestionInputType {
  name: string;
  value: SimpleQuestionnaireType;
}

export const questionTypeDropdownOptions: CreateQuestionInputType[] = [{
    name: 'Multiple Select',
    value: SimpleQuestionnaireType.multiselect
},
{
    name: 'Multiple Choice',
    value: SimpleQuestionnaireType.mcq
},
{
    name: 'Text',
    value: SimpleQuestionnaireType.text
},
{
    name: 'Number',
    value: SimpleQuestionnaireType.number
}
];
