import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useI18N } from 'common/i18n/useI18N';

import { useShowViewedQuestions } from 'features/patient/questionnaire/useShowViewedQuestions';
import { QuestionnaireRow } from './QuestionnaireRow';
import { DateTime } from 'common/datetime/DateTime';
import {
    PreviousQuestionnaireResponseDetails
} from './usePreviousCompletedQuestionnaire';

type QuestionProps = {
    questions: any;
    viewedQuestions: any;
    answers: any;
    scoreValues: any;
    previousResponseData?: PreviousQuestionnaireResponseDetails;
    previousScoreValues: any;
}

function usePerQuestionScoreValues(scoreValues: any[]) {
    return useMemo(() => {
        return scoreValues.reduce(
            (acc, scoreValue) => ({ ...acc, [scoreValue.questionId]: [scoreValue] }),{}
        );
    }, [scoreValues]);
}

export const QuestionnaireQuestions: FunctionComponent<QuestionProps> = (props) => {
    const {
        questions,
        answers,
        scoreValues,
        viewedQuestions,
        previousResponseData,
        previousScoreValues
    } = props;

    const previousCompletedAt = previousResponseData?.previousResponseCompletedAt;
    const previousAnswers = previousResponseData?.previousResponseAnswers;
    const previousResponsePath = previousResponseData?.previousResponsePath;

    const _ = useI18N();

    const someIsNotTextQuestions = questions
        .reduce((acc, question) => acc.concat(question.inputs), [])
        .some((input) => input.type !== 'text');

    const questionsWidth = someIsNotTextQuestions ? '30%' : '50%';

    const {
        questions: displayQuestions,
        isShowQuestion,
        showViewedQuestions,
        hideViewedQuestions
    } = useShowViewedQuestions(questions, viewedQuestions);

    const [showComparison, setShowComparison] = useState<boolean>(false);
    const toggleShowComparison = useCallback((shouldShowComparison) => {
        setShowComparison(shouldShowComparison);
        if (shouldShowComparison) {
            showViewedQuestions();
        }
    }, [setShowComparison, showViewedQuestions]);

    const scoreValuesMap = usePerQuestionScoreValues(scoreValues);
    const previousScoreValuesMap = usePerQuestionScoreValues(previousScoreValues);

    const allowHidingUnansweredQuestions = !showComparison && viewedQuestions;
    const allowShowComparisonButton = !!previousCompletedAt;
    const showPreviousScoresColumn = showComparison && previousScoreValues?.length > 0;

    return (
        <div>
            <h4 className="spacer-top28">{_`Individual Questions, Answers and Scores`}</h4>
            <div className="table-responsive">
                <table className="portal-table table-bordered table-condensed table-nomargin table-wrap">
                    <thead>
                        <tr>
                            <th >{_`Question`}</th>
                            <th>
                                <div className='hflow-vcenter'>
                                    <div>{_`Inputs`}</div>
                                    {allowHidingUnansweredQuestions && (isShowQuestion
                                        ? (
                                            <button className="btn btn-sm btn-border-none btn-color-plainGray bold push-right" onClick={hideViewedQuestions}>Hide
                                                All</button>
                                        ) : (
                                            <button className="btn btn-sm btn-border-none btn-color-plainGray bold push-right" onClick={showViewedQuestions}>Show
                                                All</button>
                                        )
                                    )}
                                    {allowShowComparisonButton && !showComparison && (
                                        <button
                                            className="btn btn-sm btn-border-none btn-color-plainGray bold"
                                            onClick={() => toggleShowComparison(true)}
                                        >
                                            {'Show Comparison'}
                                        </button>
                                    )}
                                </div>
                            </th>
                            {scoreValues && scoreValues.length > 0 && <th className="text-center">{_`Value`}</th>}
                            {showComparison && (
                                <th className='ws-nowrap'>
                                    <div className='hflow-vcenter'>
                                        <div>
                                            {_`Previous Inputs`}
                                            {' ('}
                                            <a href={previousResponsePath}><DateTime>{previousCompletedAt}</DateTime></a>
                                            {')'}
                                        </div>
                                        <button
                                            className="btn btn-sm btn-border-none btn-color-plainGray bold push-right"
                                            onClick={() => toggleShowComparison(false)}
                                        >
                                            {'Hide Comparison'}
                                        </button>
                                    </div>
                                </th>
                            )}
                            {showPreviousScoresColumn && <th className="text-center">{_`Previous Value`}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            displayQuestions.map((question, idx) => {
                                return (
                                    <QuestionnaireRow
                                        key={idx}
                                        question={question}
                                        answer={answers[question.id]}
                                        scoreValues={scoreValuesMap[question.id]}
                                        previousAnswer={showComparison && previousAnswers && previousAnswers[question.id]}
                                        previousScoreValues={showComparison && previousScoreValuesMap && previousScoreValuesMap[question.id]}
                                    />
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};
