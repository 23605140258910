import { useState, useEffect, useCallback } from 'react';
import { browserHistory } from 'react-router';
import ServerFetch from 'server-fetch';
import { $check } from 'services/roles';
import DataStore from 'services/data-store';
import { useAPI } from 'common/useAPI';

export function useTeamSelect() {
    const [stage, setStage] = useState(0);
    const { version } = useAPI();

    const handleTeamSelect = useCallback((teamOrRole) => {
        if (version === 2) {
            DataStore.set('me.currentRole', teamOrRole);
            return;
        }
        const team = teamOrRole;
        // load up service permissions
        DataStore.set('me.currentTeam', team);
        DataStore.set('role', team && team.is_admin === true ? 'admin' : 'clinician');
        ServerFetch.getRequest(this, '/api/clinical_portal/team/' + team.teamid + '/config',
            /**
             * @param  {Object} response
             */
            (response) => {
                let consents = {};
                let consentLookup = {};
                let configuration;
                response.message.documentTypes.map((item) => {
                    consentLookup[item.id] = item.name;
                    consents[item.name] = {
                        id: item.id,
                        create: item.create,
                        read: item.read,
                        delete: item.delete,
                        update: item.update
                    };
                });
                configuration = response.message.configuration;
                team.consents = consents;
                team.consentLookup = consentLookup;
                DataStore.set('me.currentTeam', team);
                DataStore.set('me.configuration', configuration);
                setStage(stage => stage + 1);
            }
        );

        if ($check('be clinician/see patient list')) {
            // load up patients list
            ServerFetch.getRequest(this, '/api/clinical_portal/patients?per_page=0&team_id=' + team.teamid,
                /**
                 * @param  {Object} response
                 */
                (response) => {
                    const patients = {};
                    const folderLookup = {};
                    response.message.results.map((item) => {
                        patients[item.id] = item;
                        folderLookup[item.team_folder_id] = item.id;
                        DataStore.set('patient.' + item.id, item);
                    });
                    DataStore.set('me.folderLookup', folderLookup);
                    DataStore.set('me.patients', patients);
                    setStage(stage => stage + 1);
                }
            );
        } else {
            DataStore.set('me.folderLookup', {});
            DataStore.set('me.patients', {});
            setStage(stage => stage + 1);
        }

        if ($check('be clinician/see invite list')) {
            DataStore.set('me.folderInviteLookup', {});
            DataStore.set('me.invites', {});
            setStage(stage => stage + 1);
        }
    }, []);

    useEffect(() => {
        if (stage > 1) {
            browserHistory.push('/clinical_portal');
        }
    }, [stage]);

    return handleTeamSelect;
}
