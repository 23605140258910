import React from 'react';
import PropTypes from 'prop-types'
import lodash from 'lodash';

/**
 * Displays the contents of a table cell containing the patient's name.
 * This is part of a row of the transport and interpreter worklist.
 * 
 * Props (data):
 * - demographics = Optional. The patient's demographics composition. If the data is still being loaded, this should be undefined. If the patient has no demographics data, this should be an empty object.
 */
const NameField = ({demographics}) => {
    if (!demographics) {
        return (<span className='text-muted'>Loading...</span>);
    }

    return (<span>{extractPatientName(demographics) || '(unknown)'}</span>);
};

export default NameField;

NameField.propTypes = {
    demographics: PropTypes.object
};

/**
 * Extract a patient name for display from a demographics composition.
 * This will get preferred name if available, or legal name otherwise.
 *
 * @param {object} demographics The demographics composition to extract the patient's name from.
 * @return {string} The patient name which it to be displayed. This will be an empty string if the demographics composition contains no name information.
 */
const extractPatientName = (demographics) => {
    let nameObject;
    nameObject = lodash.get(demographics, 'content.preferred_name') || lodash.get(demographics, 'content.name');
    if (!nameObject) {
        return '';
    }

    return `${lodash.get(nameObject, 'given_name', '')} ${lodash.get(nameObject, 'family_name', '')}`.trim();
}
