import React, { FC, useEffect, useState } from 'react';
import { notesService } from 'services/notes.service';
import { ResponsiveTable } from 'ui';
import _ from 'services/i18n';
import { DateTime } from 'common/datetime/DateTime';
import './user-notes.less';
import { PaginationView } from 'components/pagination';
import Select from 'react-select';
import lodash from "lodash";
import { UserNoteRetrievalFn } from './UserNoteHooks';
import useDropdownTarget from 'common/useDropdownTarget';

type UserNoteTableProps = {
    folderId: number;
    retrieveNotes: UserNoteRetrievalFn;
    initialPagination: { current: number };
}

const perPage = 100;
export const defaultPagination = { current: 1 };

export type CareEventStatus = 'active' | 'reviewed' | 'deleted';

const statusOptions: { label: string; value: CareEventStatus }[] = [
    { label: 'Active', value: 'active' },
    { label: 'Reviewed', value: 'reviewed' },
    { label: 'Deleted', value: 'deleted' }
];

const getSelectedStatus = (userNote) => statusOptions.find(option => option.value === userNote.content.status);
const hasLabels = (userNote) => userNote.content.labels !== undefined && userNote.content.labels.length > 0;
const processComment = (userNote) => hasLabels(userNote) ? userNote.content.comment : JSON.parse(userNote.content.comment);
const hasJsonComment = (userNote) => {
    try {
        JSON.parse(userNote.content.comment);
    } catch (e) {
        return false;
    }
    return true;
}

export const UserNoteTable: FC<UserNoteTableProps> = ({
    folderId,
    retrieveNotes,
    initialPagination
}) => {

    const [userNotes, setUserNotes] = useState([]);
    const [totalUserNotes, setTotalUserNotes] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [pagination, setPagination] = useState(initialPagination);

    const [blockScroll, dropdownTarget] = useDropdownTarget();

    useEffect(() => {
        setPagination(initialPagination);
    }, [initialPagination]);

    useEffect(() => {
        setLoading(true);
        const offset = (pagination.current - 1) * perPage;

        retrieveNotes(perPage, offset)
            .then((response) => {
                setUserNotes(response.message.results);
                setTotalUserNotes(response.message.total);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [pagination]);

    const updateNoteStatus = (userNote, newStatus) => {
        const content = lodash.cloneDeep(userNote.content);
        content.status = newStatus;
        notesService.update({ uuid: userNote.uuid, content, folderId })
            .then(() => notesService.getByUuid({uuid: userNote.uuid, folderId }))
            .then((newUserNoteResponse) => {
                const newUserNotes = [...userNotes];
                const curIndex = newUserNotes.findIndex(userNoteItem => userNoteItem.uuid === userNote.uuid);
                newUserNotes.splice(curIndex, 1, newUserNoteResponse.message)
                setUserNotes(newUserNotes);
            });
    }

    const handleChangePage = (idx) => {
        setPagination({
            current: idx
        });
    }

    return (
        <>
            <h3>User Notes</h3>
            <ResponsiveTable className='table table-bordered table-condensed table-striped table-nomargin'>
                <thead>
                <tr>
                    <th>{_`Date`}</th>
                    <th>{_`Type`}</th>
                    <th>{_`Content`}</th>
                    <th>{_`Status`}</th>
                </tr>
                </thead>
                <tbody>
                {
                    error && <tr>
                        <td colSpan={4}>
                            {_`Sorry, the server reported an error`}
                        </td>
                    </tr>
                }
                {
                    loading && <tr>
                        <td colSpan={4}>
                            {_`Loading user notes... please wait`}
                        </td>
                    </tr>
                }
                {
                    userNotes.length <= 0 && !loading && !error && <tr>
                        <td colSpan={4}>
                            {_`Sorry, no matching results found`}
                        </td>
                    </tr>
                }
                {
                    userNotes.map((userNote) => {
                        return (
                            <tr key={userNote.uuid}>
                                <td><DateTime format="nhs_date_with_time">{userNote.created_at}</DateTime></td>
                                <td>{hasLabels(userNote) ? "User Note" : userNote.content.type}</td>
                                <td>
                                    {!hasLabels(userNote) && !hasJsonComment(userNote) && <table className="user-note-comment-table">
                                        <tbody>
                                            <tr>
                                                <td>{_`Title: `}{userNote.content.title}</td>
                                            </tr>
                                            <tr>
                                                <td>{_`Comment: `}{userNote.content.comment}</td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                    {!hasLabels(userNote) && hasJsonComment(userNote) && processComment(userNote)
                                        .map((comment, index) => {
                                            return (
                                                <table className="user-note-comment-table" key={index}>
                                                    <tbody>
                                                    <tr>
                                                        <td>{_`Label: `}{comment.label}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{_`Type: `}{comment.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{_`Value: `} {comment.value}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    )}
                                    {hasLabels(userNote) && userNote.content.labels.map((label, index) => {
                                        return (
                                            <table className="user-note-comment-table" key={index}>
                                                <tbody>
                                                    <tr>
                                                        <td>{_`Name: `} {label.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{_`Type: `} {label.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{_`Title: `}{userNote.content.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{_`Comment: `}{processComment(userNote)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    })}
                                </td>
                                <td>
                                    <Select
                                        id='status-select'
                                        value={getSelectedStatus(userNote)}
                                        options={statusOptions}
                                        onChange={newStatus => updateNoteStatus(userNote, newStatus.value)}
                                        classNamePrefix={'react-select-input'}
                                        menuShouldBlockScroll={blockScroll}
                                        menuPlacement={'auto'}
                                        menuPortalTarget={dropdownTarget}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </ResponsiveTable>

            <PaginationView currentPage={pagination.current}
                            pageCount={Math.ceil(totalUserNotes / perPage)}
                            onChange={handleChangePage}/>
        </>
    );
};

export default UserNoteTable;
