import React, { FC, useContext, useEffect, useState, createContext } from 'react';
import { locationsService } from 'services/locations.service';

const defaultLocationsContextValue = {
    locations: null,
};

const LocationsContext = createContext(defaultLocationsContextValue);

export const LocationsContextProvider: FC = ({ children }) => {
    const [locations, setLocations] = useState();

    useEffect(() => {
        async function loadLocations() {
            try {
                const locationsContent = await locationsService.getLocations();

                setLocations(locationsContent.message.live);
            } catch (err) {
                setLocations(null);
            }
        }

        loadLocations();
    }, []);

    return (
        <LocationsContext.Provider
            value={{
                locations,
            }}
        >
            {children}
        </LocationsContext.Provider>
    );
};

export const useLocationsContext = () => useContext(LocationsContext);
