import React from 'react';
import _ from 'services/i18n';
import ErrorBox from 'components/errorbox';
import { Link } from 'react-router';
import { Panel, Row, Column, FormGroup, InputField } from 'ui';
import objectToQueryString from 'services/to-query-string';

/**
 * A generic logout component
 */
export default class extends React.Component {
    renderLoading () {
        return (
            <div className="panel-loading">
                <span className="glyphicon glyphicon-lock"/>
            </div>
        );
    }

    render () {
        let signUpLink, urlQuery = {}, forgotPasswordLink, message = null;

        if (this.props.token != '') {
            urlQuery.token = this.props.token;
        }

        if (this.props.email !== '') {
            urlQuery.email = this.props.email;
        }

        if (this.props.status == 'session-timeout') {
            message = (
                <div className="alert alert-warning">
                    Your session has timed out due to inactivity. Please login below to continue.
                </div>
            );
        }

        if (this.props.status == 'email-changed') {
            message = (
                <div className="alert alert-warning">
                    Your email was changed. Please login below to continue.
                </div>
            );
        }

        if (this.props.message) {
            message = (
                <div className="alert alert-warning">
                    {this.props.message}
                </div>
            );
        }

        urlQuery = Object.assign({}, this.props.location.query, urlQuery);
        signUpLink = '/' + this.props.portal + '/sign-up?' + objectToQueryString(urlQuery);
        forgotPasswordLink = '/' + this.props.portal + '/forgot-password?' + objectToQueryString(urlQuery);

        return (
            <div className="login-component" style={{paddingTop: '20px'}}>
                <div className={'container hint-' + (this.props.portal || 'normal')}>
                    <Row>
                        <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="2" sm="8" xs="12">
                            <Panel title={this.props.title || 'Login'}>
                                {this.props.loading ? this.renderLoading() : null}
                                {message}
                                <p>{_`Please login using the form below`}</p>
                                <ErrorBox errors={this.props.errors} />
                                <form onSubmit={this.props.onSubmit} autoComplete="off">
                                    <div style={{overflow: 'none', height: 0, background: 'transparent'}} data-description="dummyPanel for auto-fill issue">
                                        <input type="email" name="email" style={{height:0, background: 'transparent', color: 'transparent', border: 'none', padding: 0}} data-description="dummyEmail"></input>
                                        <input type="password" name="password" style={{height:0, background: 'transparent', color: 'transparent', border: 'none', padding: 0}} data-description="dummyPassword"></input>
                                    </div>
                                    <FormGroup>
                                        <label>{_`Email Address`}</label>
                                        <InputField type="email" name="email" cls={this.props} autoComplete="nope"
                                            list="autocompleteOff" id="email__input"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>{_`Password`}</label>
                                        <InputField type="password" name="password" cls={this.props} autoComplete="nope"
                                            list="autocompleteOff" id="password__input"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <button type="submit" className="btn btn-primary btn-block">{_`Login`}</button>
                                    </FormGroup>
                                    <div className="form-group text-center">
                                        <Link to={forgotPasswordLink} className="text-danger">{_`Forgot Password`}</Link>
                                    </div>
                                    <hr />
                                </form>
                            </Panel>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }
}

