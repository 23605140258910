import React from 'react';
import lodash from 'lodash';

import TimelineComposition from './timeline-composition';

import { convertToDate } from 'common/datetime/convertToDate';


/**
 * The format for displaying dates in the timeline.
 */
const dateFormat = 'Do MMMM YYYY';

/**
 * Names of the archetypes which will be displayed on the timeline.
 */
const visibleArchetypes = [
    'referral',
    'appointment',
    'message',
    'coopQuestionnaireResponse',
    'questionnaireResponse',
    'user-goal'
];

/**
 * A dummy welcome message composition which is added to the timeline where necessary.
 */
const dummyWelcomeMessage = {
    document_type: 'message',
    content: {
        title: 'Welcome to MyPathway.',
        content: 'For patients of Barcelona Teaching Hospitals.',
        type: 'welcome'
    }
};

/**
 * Displays a patient's MyPathway timeline for the clinical portal.
 * Props:
 * - compositions = Required. An array of compositions to display on the timeline.
 * - folderLink = Required. Base URL for the patient's folder in the portal. This is used to construct links to specific archetypes.
 * - suppressAutoWelcomeMessage = Optional. If false or omitted, a welcome message will be added to the beginning of the timeline if the provided compositions don't already provide one. If true, this will be suppressed.
 */
export const PatientTimeline = ({compositions, folderLink, suppressAutoWelcomeMessage = false, compositionQueryString}) => {

    const timelineItems = processCompositionsForDisplay(compositions, suppressAutoWelcomeMessage);

    let rows = [];
    let currentDisplayDate = '';
    timelineItems.forEach((item, idx) => {
        if (item.timelineDateTime) {
            const itemDisplayDate = item.timelineDateTime.local().format(dateFormat);
            if (itemDisplayDate != currentDisplayDate) {
                // Insert a date separator row.
                currentDisplayDate = itemDisplayDate;
                rows.push(
                    <div key={idx + '_sep'} className='timeline-date'>
                        {currentDisplayDate}
                    </div>
                );
            }
        }

        // Add this composition.
        rows.push(
            <TimelineComposition
                key={`${idx}_cmp`}
                compositionQueryString={compositionQueryString}
                composition={item.composition}
                folderLink={folderLink}
                />
        );
    });

    return (
        <div className="mypathway-timeline">
            {rows}
        </div>
    );
};

export default PatientTimeline;

/**
 * Run through an array compositions to filter and sort them for display, to simulate the app timeline.
 * Each item in the returned array will be an object with two properties:
 * - composition = the composition to be displayed
 * - timelineDateTime = the date/time to display the composition in the timeline
 *
 * Compositions which should not be displayed will be filtered-out.
 * The results will be sorted by the timelineDateTime values.
 *
 * @param {Array} input An array of all compositions on the patient's pathway.
 * @param {boolean} suppressAutoWelcomeMessage If falsy, a welcome message will be added at the start of the output if one isn't already in the given input. If truthy, this will be suppressed.
 * @return {Array} Compositions filtered and sorted to simulate the app's timeline.
 */
function processCompositionsForDisplay (input, suppressAutoWelcomeMessage) {
    const output = [];
    // Go through each input composition.
    let hasWelcomeMessage = false;
    input.forEach((composition) => {
        // Ignore compositions which should not be visible.
        if (!isCompositionVisibleOnTimeline(composition)) {
            return;
        }

        let compositionData = extractCompositionData(composition);
        if(!(compositionData instanceof Array)) {
            compositionData = [compositionData];
        }

        compositionData.forEach(({isOverdue, composition, timelineDate}) => {
            composition.isOverdue = isOverdue;

            output.push({
                composition: composition,
                timelineDateTime: timelineDate
            });

            if (composition.document_type === 'message' && composition.content.type === 'welcome') {
                hasWelcomeMessage = true;
            }
        });
    });

    // Sort the compositions by the timeline date.
    output.sort((a, b) => {
        return a.timelineDateTime - b.timelineDateTime;
    });

    // Add a welcome message if there isn't one already.
    // THIS IS TEMPORARY ONLY.
    // The welcome message should be added by the system in future.
    if (!hasWelcomeMessage && !suppressAutoWelcomeMessage) {
        output.unshift({
            composition: dummyWelcomeMessage,
            timelineDateTime: undefined
        });
    }

    return output;
}

/**
 * Check if the given composition should be displayed on the timeline.
 *
 * @param {Object} composition The composition to check.
 * @return {boolean} True if the composition should be displayed, or false otherwise.
 */
function isCompositionVisibleOnTimeline (composition) {
    if (!composition) {
        console.warn('Hiding empty composition from timeline.');
        return false;
    }

    if (!composition.content) {
        console.warn('Hiding composition from timeline because it has no content.');
        return false;
    }

    if (composition.document_type === 'user-goal' && composition.content.status !== 'active') {
        return false;
    }

    if (composition.document_type === 'referral' && (!composition.content.referral_receipt_date || !composition.content.details)) {
        return false;
    }

    // Limit to the list of visible archetypes.
    if (visibleArchetypes.indexOf(composition.document_type) == -1) {
        return false;
    }

    // Check for the timeline visibility flag.
    if (composition.content.hidden_from_timeline) {
        return false;
    }

    // Check for the deleted flag for archived eq5d's
    if (composition.deleted_at) {
        return false;
    }

    return true;
}

/**
 * Gets the timeline date/time for the given composition,
 *  for questionnaires also the flag isOverdue.
 * This extrapolates the date/time against which the item should be shown on
 *  the pathway timeline.
 *
 * @param {Object} composition The composition to get a timeline date for.
 * @return {Object}
 */
function extractCompositionData (composition) {

    // Fall back on current date/time if all else fails.
    const now = convertToDate(new Date());
    const compositionData = {
        timelineDate: now,
        composition
    };

    if (!composition) {
        console.warn('Empty composition passed to extractCompositionData');
        return compositionData;
    }

    // By default, we'll use the created_at date of the composition
    //  unless we encounter a special case below.
    if (composition.created_at) {
        const created_at = convertToDate(composition.created_at);
        if (created_at.isValid()) {
            compositionData.timelineDate = created_at;
        }
    }

    // We can't look for special cases if there's no composition content.
    if (!composition.content) {
        console.warn('Composition is missing content property.');
        return compositionData;
    }

    // Make the updated_at date available as well, if it is known.
    let updated_at;
    if (composition.updated_at) {
        const temp_updated_at = convertToDate(composition.updated_at);
        if (temp_updated_at.isValid()) {
            updated_at = temp_updated_at;
        }
    }

    // Appointments:
    if (composition.document_type === 'appointment') {
        const type = lodash.get(composition, 'content.type', '').toLowerCase();
        const status = lodash.get(composition, 'content.status', '').toLowerCase();
        const date = lodash.get(composition, 'content.date', undefined);

        // For a booked non-triage appointment, use the time and date of the appointment if known.
        if (type !== 'triage' && status !== 'available-to-book' && date) {
            const appointmentDateTime = convertToDate(date);
            if (appointmentDateTime.isValid()) {
                compositionData.timelineDate = appointmentDateTime;
                return compositionData;
            }
        }

        // In all other situations, use the updated_at date of the composition.
        // NOTE: In future, this will use created_at instead.

        compositionData.timelineDate = updated_at || compositionData.timelineDate;
        return compositionData;
    }

    // Questionnaires:
    if (['coopQuestionnaireResponse', 'questionnaireResponse'].indexOf(composition.document_type) !== -1) {
        // If the questionnaire is complete, use the date it was last updated.
        const status = lodash.get(composition, 'content.status', '');
        if (['complete', 'scored'].indexOf(status) !== -1) {
            compositionData.timelineDate = updated_at;
            return compositionData;
        }

        let dueDate;
        if (composition.content.due_date) {
            dueDate = convertToDate(composition.content.due_date);
        } else {
            dueDate = convertToDate(composition.created_at).add(1, 'days');
        }

        compositionData.isOverdue = now.isAfter(dueDate);
        compositionData.timelineDate = compositionData.isOverdue ? now : dueDate;

        return compositionData;
    }

    if (composition.document_type === 'referral') {
        if (lodash.has(composition, 'content.referral_receipt_date')) {
            compositionData.timelineDate = convertToDate(composition.content.referral_receipt_date);
            return compositionData;
        }
    }

    if (composition.document_type === 'user-goal') {
        const uuid = composition.uuid;
        const scoreGroups = {};
        const givenValue = composition.content.given_value;
        const minValueToScore = Math.ceil(givenValue * 0.8);
        if (!composition.content.scores) {
            return [];
        }
        composition.content.scores.forEach((score) => {
            const timelineDate = convertToDate(score.date);
            const groupDate = timelineDate.format(dateFormat);
            const isGoalReached = minValueToScore ? score.value >= minValueToScore : true;
            if (!scoreGroups[groupDate]) {
                scoreGroups[groupDate] = {
                    document_type: composition.document_type,
                    uuid,
                    value: score.value,
                    isScore: true,
                    document: composition,
                    givenValue,
                    isGoalReached,
                    timelineDate,
                    isActive: convertToDate(new Date()).isSame(timelineDate, 'day')
                };
                return;
            }
            scoreGroups[groupDate].value += score.value;
            scoreGroups[groupDate].isGoalReached = scoreGroups[groupDate].value >= minValueToScore;
        });

        const isDue = convertToDate(new Date()).isSameOrAfter(convertToDate(composition.content.date_scheduled).unix(), 'day');

        const nowFormattedDate = convertToDate(new Date()).format(dateFormat);

        if (isDue && !scoreGroups[nowFormattedDate]) {
            scoreGroups[nowFormattedDate] = {
                document_type: composition.document_type,
                uuid,
                givenValue,
                document: composition,
                isActive: true,
                timelineDate: convertToDate(new Date())
            };
        }

        const output = [];
        lodash.forEach(scoreGroups, (composition) => {
            output.push({
                timelineDate: composition.timelineDate,
                composition
            });
        });

        return output;
    }

    return compositionData;
}
