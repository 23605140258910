let dataStore = {},
    listeners = {},
    idxLookup = {},
    listenIdx = 0,
    saveTimer = null;

/**
 * Store values, obtain back global values, and optionally run callbacks that are triggered
 * when values change
 */
export class DataStore {
    static clear (cb) {
        dataStore = {};

        if (!saveTimer) {
            clearTimeout(saveTimer);
        }

        localStorage.setItem("datastore", JSON.stringify(dataStore));
        if (typeof cb === 'function'){
            cb();
        }
    }

    static set (key, value) {
        let i;

        dataStore[key] = value;

        localStorage.setItem("datastore", JSON.stringify(dataStore));

        // run any callbacks
        if (listeners.hasOwnProperty(key)) {
            for(i in listeners[key]) {
                if (listeners[key].hasOwnProperty(i)) {
                    listeners[key][i](value);
                }
            }
        }
    }

    static get (key) {
        if (!dataStore.hasOwnProperty(key)) {
            return null;
        }
        return dataStore[key];
    }

    static getListener (key, cb) {
        let xlistenIdx = DataStore.addListener (key, cb);

        cb(this.get(key));

        return xlistenIdx;
    }

    static addListener (key, cb) {
        if (!listeners.hasOwnProperty(key)) {
            listeners[key] = {};
        }

        listeners[key][listenIdx] = cb;

        idxLookup[listenIdx] = key;

        listenIdx++;

        return listenIdx - 1;
    }

    static getListenerDestroy(key, cb) {
        let v;
        window.setTimeout(() => {
            v = DataStore.getListener(key, cb);
        });
        return () => {
            DataStore.removeListener(v);
        };
    }

    static removeListener (idx) {
        let key;
        if (!idxLookup.hasOwnProperty(idx)) {
            return;
        }

        key = idxLookup[idx];

        delete(listeners[key][idx]);
    }
}

// on load

if (typeof(document) !== 'undefined') {
    let tempDataStore = localStorage.getItem("datastore");

    if (tempDataStore) {
        dataStore = JSON.parse(tempDataStore);
    } else {
        dataStore = {};
    }
}

export default DataStore;
