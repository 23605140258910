import React, { FC } from 'react';
import './DashboardDemographicsTable.scss';
import { DashboardDemographicsView } from './dashboard-demographics-view/DashboardDemographicsView';
import { useDashboardData } from 'features/patient/patient-dashboard/data/useDashboardData';
import { DASHBOARD_COMPONENT_NAMES } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { PRIMARY_IDENTIFIER_NAMESPACE } from 'models/PersonPrimaryIdentifier';

export interface DemographicsTableProps {
    title?: string;
}

export interface DemographicsData {
    name: string;
    dob?: string;
    gender?: string;
    primaryIdentifier?: {label: string; value: string; namespace: PRIMARY_IDENTIFIER_NAMESPACE};
    email?: string;
}

export const DashboardDemographicsTable: FC<DemographicsTableProps> = ({
    title= 'Linked Account',
}) => {
    const { data, loading } = useDashboardData<DemographicsData>({ type: DASHBOARD_COMPONENT_NAMES.DashboardDemographicsTable });
    return (
        <DashboardDemographicsView demographics={data} title={title} loading={loading} />
    );
};
