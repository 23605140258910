import React from 'react';
import { Link } from 'react-router';
import { useI18N } from 'common/i18n/useI18N';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { TopBar } from 'features/patient/top-bar/TopBar';
import { AppointmentsGrid } from './AppointmentsGrid';

export const AppointmentsIndex = ({
    loading,
    appointments = [],
    folderId,
    referrals,
    teamId,
    path
}) => {
    const _ = useI18N();
    const { show_create_appointment_button: showCreateAppointmentButton }  = useTeamPreferences();


    return (
        <div className="page-appointment">
            <TopBar
                folderId={folderId}
                teamId={teamId}
            >
                {!!showCreateAppointmentButton && <Link to={`${path}/create`} >{_`Create Appointment`}</Link>}
            </TopBar>
            <AppointmentsGrid
                loading={loading}
                referrals={referrals}
                appointments={appointments}
            />
        </div>
    );
};
