import React, { cloneElement } from 'react';
import { useIsAccessableRoute } from './routes';

export const MSKRouteComponent = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...otherProps } = props;
    const { routes, route } = props;

    const routeStack = [];

    for (const routeIterator of routes) {
        routeStack.push(routeIterator);
        if (routeIterator === route) {
            break;
        }
    }

    const [isGreenLight, isDefaultRoutes] = useIsAccessableRoute(routeStack);

    const Component = route.originalComponent;

    if (route.originalComponent && isGreenLight && children) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { routes, route, router, routeParams, params, ...childProps } = otherProps;
        const newChildren = cloneElement(children, { ...childProps, ...children.props });
        return <Component {...otherProps}>{newChildren}</Component>;
    }

    if (route.originalComponent && isGreenLight) {
        return <Component {...otherProps} />;
    }

    if (children && isGreenLight) {
        const newChildren = cloneElement(children, { ...otherProps, ...children.props });
        return newChildren;
    }

    if (isDefaultRoutes) { return null; }

    return <h1>Not Allowed to see this</h1>;
};
