// https://bitbucket.org/adihealth/private-server/src/master/resources/schemas/archetypes/email.json

import { Label } from '../Label';

export enum EmailStatus {
    DRAFT = 'draft',
    PENDING = 'pending',
    QUEUED = 'queued',
    SENT = 'sent',
    DELIVERED = 'delivered',
    OPENED = 'opened',
    CLICKED = 'clicked',
    BOUNCED = 'bounced',
    BLOCKED = 'blocked',
    MARKED_AS_SPAM = 'marked_as_spam',
    ERROR = 'error',
    USER_OPTED_OUT = 'user_opted_out'
}

export const EmailStatusMap = {
    draft: 'Draft',
    pending: 'Pending',
    queued: 'Queued',
    sent: 'Sent',
    delivered: 'Delivered',
    opened: 'Opened',
    clicked: 'Clicked',
    bounced: 'Bounced',
    blocked: 'Blocked',
    marked_as_spam: 'Marked as SPAM',
    error: 'Sending Error',
    user_opted_out : 'User is opted out'
};

export interface Email {
    status: EmailStatus;
    email_address: string;
    email_subject: string;
    email_body_txt: string;
    email_body_html: string;
    queued_timestamp?: number; // timestamp
    pending_timestamp?: number; // timestamp
    sent_timestamp?: number; // timestamp
    reply_email?: string;
    reply_name?: string;
    associated_composition_uuid?: string;
    external_id?: string;
    error_details?: string;
    labels?: Label[];
}
