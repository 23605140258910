import { isEmpty, isEqual } from 'lodash';
import {
    AttendAnywhereItem,
    optionsAppointmentMode
} from 'features/attend-anywhere/AttendAnywhereHelpers';
import { APPOINTMENT_MODE } from 'services/appointment-helpers';

export interface State {
    list: Map<string, AttendAnywhereItem>;
    loading: boolean;
    asyncQueue: AsyncJob[];
    changes?: Map<'created' | 'updated' | 'deleted', Map<string, AttendAnywhereItem>>;
}
export enum JOB_NAME {
    SUBMIT_APPOINTMENT_TYPE = 'SUBMIT_APPOINTMENT_TYPE',
}
export type AsyncJob =
    | {
    job: JOB_NAME.SUBMIT_APPOINTMENT_TYPE;
    uuid: string;
    data: {
        appointmentType: APPOINTMENT_MODE;
        folderId: number;
        teamId: number;
    };
};
export enum ACTION {
    LOAD_LIST_START = 'LOAD_LIST_START',
    LOAD_LIST_DONE = 'LOAD_LIST_DONE',
    SYNC_LIST_DONE = 'SYNC_LIST_DONE',
    ITEM_ACTION = 'ITEM_ACTION',
    CLEAR_ASYNC_QUEUE = 'CLEAR_ASYNC_QUEUE',
    CHANGE_APPT_TYPE = 'CHANGE_APPT_TYPE',
    SUBMIT_APPT_TYPE = 'SUBMIT_APPT_TYPE',
    UPDATE_LIST = 'UPDATE_LIST',
}
export type Actions =
    | { type: ACTION.LOAD_LIST_START; payload: { loading: boolean } }
    | { type: ACTION.LOAD_LIST_DONE; payload: { list: AttendAnywhereItem[] } }
    | { type: ACTION.SYNC_LIST_DONE; payload: { list: AttendAnywhereItem[] } }
    | { type: ACTION.ITEM_ACTION; payload: ItemActions }
    | { type: ACTION.CLEAR_ASYNC_QUEUE }
    | { type: ACTION.UPDATE_LIST }
export type ItemActions =
    | { type: ACTION.CHANGE_APPT_TYPE; payload: { uuid: string; type: APPOINTMENT_MODE } }
    | { type: ACTION.SUBMIT_APPT_TYPE; payload: { uuid: string } }

const itemReducer = (state: State, action: ItemActions) => {
    switch (action.type) {
    case ACTION.CHANGE_APPT_TYPE:
        return onChangeApptType(state, action);
    case ACTION.SUBMIT_APPT_TYPE:
        // eslint-disable-next-line no-case-declarations
        return onSubmitApptType(state, action);
    default:
        return state;
    }
};
export const reducer = (state: State, action: Actions) => {
    switch (action.type) {
    case ACTION.LOAD_LIST_START:
        return {
            ...state,
            loading: action.payload.loading
        };
    case ACTION.LOAD_LIST_DONE:
        return {
            ...state,
            loading: false,
            list: action.payload.list.reduce((acc, item) => {
                acc.set(item.uuid, item);
                return acc;
            }, new Map)
        };
    case ACTION.UPDATE_LIST:
        // eslint-disable-next-line no-case-declarations
        const { changes } = state;
        // eslint-disable-next-line no-case-declarations
        const list = new Map(Array.from(state.list));
        if (changes.has('deleted')) {
            changes.get('deleted').forEach((val, key) => {
                list.delete(key);
            });
        }
        if (changes.has('created')) {
            changes.get('created').forEach((val, key) => {
                list.set(key, val);
            });
        }
        return {
            ...state,
            changes: void 0,
            list,
        };
    case ACTION.SYNC_LIST_DONE:
        return handleSyncList(state, action);
    case ACTION.ITEM_ACTION:
        return itemReducer(state, action.payload);
    case ACTION.CLEAR_ASYNC_QUEUE:
        return {
            ...state,
            asyncQueue: []
        };
    default:
        return state;
    }
};

function handleSyncList(state: State, action: Actions) {
    if(action.type !== ACTION.SYNC_LIST_DONE) {
        return state;
    }

    const changes = getChanges(state.list, action.payload.list);
    if(!changes) {
        return state;
    }

    if (changes.has('updated')) {
        // TODO we should think how to define updated compositions
        // Right now it catches any change in demographics/appointment etc
        // There are no actions for updated compositions right now
        changes.delete('updated');
    }

    if(!changes.size) {
        if(state.changes) {
            return {
                ...state,
                changes: void 0
            };
        } else {
            return state;
        }
    }

    return {
        ...state,
        changes,
    };
}
function onChangeApptType(state: State, action: ItemActions) {

    if(action.type !== ACTION.CHANGE_APPT_TYPE) {
        return state;
    }

    const list = copyMapAndCopyAndModifyItemById({
        map: state.list,
        id: action.payload.uuid
    }, (item) => {
        item.prevType = item.type;
        item.type = action.payload.type;
        return item;
    });
    return {
        ...state,
        list
    };
}
function onSubmitApptType(state: State, action: ItemActions) {
    if(action.type !== ACTION.SUBMIT_APPT_TYPE) {
        return state;
    }
    let item = state.list.get(action.payload.uuid);
    item = { ...item };
    item.type = item.type === APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED ?
        APPOINTMENT_MODE.ATTEND_ANYWHERE :
        item.type === APPOINTMENT_MODE.TELEPHONE_REQUESTED ?
            APPOINTMENT_MODE.TELEPHONE :
            item.type;
    item.appointmentModeOptions = optionsAppointmentMode;
    item.sentType = item.type;
    item.processing = true;
    // updateItem & updateList
    const list = updateListWithItem(state.list, item);


    if(!item.type) {
        console.error('[onSubmitApptType] Error. Submit appointmentType = ', item.type);
    }

    const asyncJob: AsyncJob = {
        job: JOB_NAME.SUBMIT_APPOINTMENT_TYPE,
        uuid: action.payload.uuid,
        data: {
            appointmentType: item.type,
            folderId: item.folderId,
            teamId: item.teamId,
        },
    };

    return {
        ...state,
        list,
        asyncQueue: [...state.asyncQueue, asyncJob]
    };
}
function getChanges<T>(existingListMap: Map<string, T>, newListArr: T[]): Map<'updated' | 'created' | 'deleted', Map<string, T>> {

    const arr = [...newListArr];
    const newMap = toMap<T>('uuid', arr);
    const diff = new Map();

    existingListMap.forEach((val, key) => {
        if(newMap.has(key)) {
            const newVal = newMap.get(key);
            if(!isEqual(val, newVal)) {
                if (!diff.has('updated')) {
                    diff.set('updated', new Map());
                }
                diff.get('updated').set(key, newVal);
            }
            newMap.delete(key);
        } else {
            if(!diff.has('deleted')) {
                diff.set('deleted', new Map());
            }
            diff.get('deleted').set(key, val);
        }
    });
    if(newMap.size) {
        diff.set('created', newMap);
    }

    return isEmpty(diff) ? void 0 : diff;
}
function copyMapAndCopyAndModifyItemById<T extends {}>({ map: listMap, id }: { map: Map<string, T>; id: string }, callback: (ob: T) => T) {
    let item = listMap.get(id);
    if (!item) {
        return listMap;
    }
    const map = new Map<string, object>(Array.from(listMap));
    item = {
        ...item
    };

    map.set(id, callback(item));
    return map;
}
function updateListWithItem (list, item) {
    item = {
        ...item
    };
    list = new Map(Array.from(list));
    list.set(item.uuid, item);
    return list;
}
function toMap<T, K = string>(key: string, arr: T[]) {
    return arr.reduce((acc, val) => {
        acc.set(val[key], val);
        return acc;
    }, new Map<K, T>());
}
