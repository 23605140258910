import React from 'react';
import { Panel } from 'react-bootstrap';
import ToggleSwitch from 'common/ui/ToggleSwitch';

type AutoCommunicationPanelHeaderProps = {
    title: string;
    hasErrors: boolean;
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
}

const AutoCommunicationPanelHeader = ({
    title,
    hasErrors,
    enabled,
    setEnabled
}: AutoCommunicationPanelHeaderProps) => {
    return (
        <Panel.Heading>
            <Panel.Title toggle>
                {title}
                {hasErrors && <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
            </Panel.Title>
            <ToggleSwitch value={enabled} setValue={setEnabled} offLabel='' onLabel=''/>
        </Panel.Heading>
    );
};

export default AutoCommunicationPanelHeader;
