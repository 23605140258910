import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { TopBar } from 'features/patient/top-bar/TopBar';
import PatientResourcesContainer from 'features/resource/PatientResourcesContainer/PatientResourcesContainer';
export default class ResourcePage extends BasePage {

    constructor(props) {
        super(props);
    }

    pageTitle() {
        return _`${this.$p()} Resource Manager | MyPathway Clinical Portal`;
    }

    render() {

        return (
            <>
                <TopBar
                    folderId={this.props.folderId}
                    teamId={this.props.teamId}
                >
                </TopBar>
                <PatientResourcesContainer folderId={this.props.folderId} currentUser={this.getUserName()}/>
            </>
        );
    }
}
