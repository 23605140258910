import {
    AdvancedSearchDocumentConfig,
    AdvancedSearchQueryDefinition,
} from 'models/AdvancedSearchDefinition';
import { Validator } from 'common/ui/validation/NestedComponentValidator';

export type AdvancedSearchCriteriaAndOutputsProps = {
    documentConfig: AdvancedSearchDocumentConfig;
    queryDefinition: AdvancedSearchQueryDefinition;
    validator: Validator;
};

export type QueryTabDisplayProps = {
    tab: QueryTab;
    title: string;
    selectTab: (newTab: QueryTab) => void;
    activeTab: QueryTab;
};

export enum QueryTab {
    CRITERIA,
    OUTPUTS,
}
