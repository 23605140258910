import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';

import UserMessageCreateComponent from 'components/user/message/create';

import { messageTemplateService } from 'services/message-template.service';

/**
 * Extended Page Component for user_portal/documents
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.backRoute = `${this.getFolderPath()}/patient/messages`;
    }

    pageTitle () {
        return _`Create new ${this.$p()} Message | MyPathway Clinical Portal`;
    }

    render () {
        return (<UserMessageCreateComponent backRoute={this.backRoute} {...this.props} messageTemplateService={messageTemplateService} />);
    }
}
