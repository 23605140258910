import React, { useState, useEffect } from 'react';
import { PaginationView } from 'components/pagination';
import ResourceList from 'components/resource/list/ResourceList';
import DepartmentSelect from 'features/department/components/DepartmentSelect/DepartmentSelect';
import NotifyTimelineForm from 'features/resource/NotifyTimelineForm/NotifyTimelineForm';
import { Composition } from 'models/Composition';
import { ResourceCategory } from 'models/ResourceCategory';
import { resourceCategoryService } from 'services/resourceCategory.service';
import ResourceCollectionsTable from '../../../../app/features/resource/ResourceCollectionsTable/ResourceCollectionsTable';
import { SendResourceCollectionState } from './BulkOperationSendResourceCollectionConfigurator';
import _ from 'services/i18n';
import usePaginatedResourceList from 'features/resource/CollectionContainer/usePaginatedResourceList';
import './ResourceCollectionSelector.scss';

const COLLECTIONS_PER_PAGE = 10;

type ResourceCollectionSelectorProps = {
    formState: SendResourceCollectionState;
    setFormState: (formState: SendResourceCollectionState) => void;
}

const ResourceCollectionSelector = ({ formState, setFormState }: ResourceCollectionSelectorProps) => {
    const [departmentUUID, setDepartmentUUID] = useState<string>(null);
    const [results, setResults] = useState([]);
    const [totalResults, setTotalResults] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCollection, setViewCollection] = useState<boolean>(false);
    const [collection, setCollection] = useState<Composition<ResourceCategory>>(null);

    useEffect(() => {
        async function fetchCollections() {

            const offset = (currentPage - 1) * COLLECTIONS_PER_PAGE;

            try {
                let categoryListResponse;

                if (departmentUUID) {
                    const search = {
                        'content.labels.context': departmentUUID,
                    };
                    categoryListResponse = await resourceCategoryService.search({ limit: COLLECTIONS_PER_PAGE, offset: offset, search: search, folderId: 'team' });
                } else {
                    categoryListResponse = await resourceCategoryService.list({ limit: COLLECTIONS_PER_PAGE, offset: offset, folderId: 'team' });
                }
                setResults(categoryListResponse.message.results);
                setTotalResults(categoryListResponse.message.total);
            } catch(e) {
                setResults([]);
                setTotalResults(0);
            }
        }
        fetchCollections();
    }, [currentPage, departmentUUID]);

    return (
        <div>
            {(viewCollection && collection)
                ? (
                    <>
                        <a onClick={() => setViewCollection(false)}>{`< ${_`Back`}`}</a>
                        <CollectionInfo collection={collection} />
                    </>
                )
                : (
                    <div className="collections-selector">
                        <h4 className='collections-selector__title'>{_`Select the resource collection you would like to send to this patient list`}</h4>
                        <div className="collections-selector__filters">
                            <div className='collections-selector__filters-item'>
                                <DepartmentSelect departmentUUID={departmentUUID} setDepartmentUUID={setDepartmentUUID} />
                            </div>
                        </div>
                        <ResourceCollectionsTable 
                            results={results} 
                            selectedCollections={formState.selectedCollections} 
                            setSelectedCollections={(selectedCollections) => setFormState({ ...formState, selectedCollections: selectedCollections })}
                            setViewCollection={setViewCollection}
                            setCollection={setCollection} />
                        <PaginationView currentPage={currentPage}
                            pageCount={totalResults / COLLECTIONS_PER_PAGE}
                            onChange={setCurrentPage}/>
                        <NotifyTimelineForm 
                            form={formState}
                            setForm={setFormState}
                        />
                    </div>
                )
            }
        </div>
    );
};

const CollectionInfo = ({ collection }: { collection: Composition<ResourceCategory>}) => {

    const { 
        results: resources,
        totalResults: totalResources,
        currentPage: currentResourcePage,
        setCurrentPage: setCurrentResourcePage,
        onRefresh
    } = usePaginatedResourceList(collection?.uuid);

    return (
        <>
            <div>
                <h4><b>{collection.content.name}</b></h4>
                <h5>{collection.content.name}</h5>
            </div>
            <ResourceList 
                categoryId={collection.uuid} 
                currentUser={null} 
                showActions={false}
                results={resources}
                totalResults={totalResources}
                currentPage={currentResourcePage}
                setCurrentPage={setCurrentResourcePage}
                onRefresh={onRefresh}
            />
        </>
    );
};

export default ResourceCollectionSelector;
