import React, { useState, useCallback, useEffect, useReducer, useRef } from 'react';
import { compositionService } from 'services/composition.service';
import { useAPI } from 'common/useAPI';

export function useCompositions({
    archetype = 'referral',
    folderId,
    teamId,
}: {
    archetype: string;
    folderId?: number;
    teamId?: number;
}): [
    Map<string, object>,
    Map<string, {
        isFetching: boolean;
        isInvalidated: boolean;
        isError: boolean;
    }>, {
        invalidateItem: (uuid: string) => void;
        updateContent: (uuid: string, content: object) => void;
        setItems: (items: any[]) => void;
    }
] {
    const [compositions, setCompositions] = useState(new Map());
    const [compositionsToFetch, setCompositionsToFetch] = useState([]);
    const compositionCycleCount = useRef(0);
    const { listByUuids } = useAPI();

    const [fetchingStatus, setFetchingStatus]: [Map<any, any>, any] = useReducer((state, {uuids, status}) => {
        return new Map([
            ...Array.from(state),
            ...uuids.map((uuid: string): [string, object] => ([uuid, {
                ...(state.has(uuid)
                    ? state.get(uuid)
                    : {
                        isFetching: false,
                        isInvalidated: false,
                        isError: false,
                    }),
                ...status,
            }]))
        ]);
    }, new Map<string, object>());

    const fetchItems = useCallback(
        async (uuids): Promise<[string, object][]> => {
            setFetchingStatus({
                uuids,
                status: {
                    isFetching: true,
                },
            });

            try {
                const response = await listByUuids(archetype, uuids, folderId, teamId);
                const list = response.message.results;
                setFetchingStatus({
                    uuids,
                    status: {
                        isError: false,
                    },
                });
                return uuids.map((uuid: string) =>
                    [uuid, list.find((item: any) => item.uuid === uuid)]
                );
            } catch (err) {
                console.error(err);
                setFetchingStatus({
                    uuids,
                    status: {
                        isError: true ,
                    },
                });
            } finally {
                setFetchingStatus({
                    uuids,
                    status: {
                        isFetching: false,
                    },
                });
            }
            return uuids.map((uuid: string) => ([uuid, null]));
        },
        [archetype, folderId, teamId],
    );

    const invalidateItem = useCallback((uuid) => {
        setFetchingStatus({
            uuids: [uuid],
            status: {
                isInvalidated: true,
            }
        });
    }, []);

    const updateContent = useCallback(
        async (uuid, content, options = {
            isPushToServer: false,
            isPartial: true,
        }) => {
            const { isPushToServer, isPartial } = options;
            const composition = compositions.get(uuid);

            if (!composition) {
                console.warn('Trying to update non-existing composition', { uuid });
                return;
            }

            const newContent = isPartial ? {
                ...composition.content,
                ...content,
            } : {
                ...content
            };

            setCompositions(new Map([
                ...Array.from(compositions),
                [uuid, {
                    ...composition,
                    content: newContent
                }],
            ]));

            if (isPushToServer) {
                await compositionService.update({uuid: composition.uuid, folderId: composition.folder_id, archetypeName: archetype, content: newContent})
            }
        }, [compositions, archetype, teamId]
    );

    const setItems = useCallback((items: [string, any][]) => {
        if (!items.length) { return; }
        setFetchingStatus({
            uuids: items.map(([uuid]) => uuid),
            status: {
                isInvalidated: false,
            }
        });

        setCompositions(compositions => new Map([
            ...Array.from(compositions),
            ...items,
        ]));
    }, []);

    useEffect(() => {
        if (compositionsToFetch.length) { return; }
        compositionCycleCount.current++;
        if (compositionCycleCount.current > 200) { throw new Error('[useCompositions] Too much fetch composition cycles'); }
        const uuids = Array.from(fetchingStatus)
            .filter(([, status]) => status.isInvalidated && !status.isFetched && !status.isError)
            .map(([uuid]) => uuid);

        if (uuids.length) {
            return setCompositionsToFetch(uuids);
        }

        compositionCycleCount.current = 0;
    }, [fetchingStatus, compositionsToFetch]);

    useEffect(() => {
        if (!compositionsToFetch.length) { return; }
        let didCancel = false;
        const fetchAndSetItems = async () => {
            compositionCycleCount.current++;
            if (compositionCycleCount.current > 200) { throw new Error('[useCompositions] Too much fetch composition cycles'); }
            const items = await fetchItems(compositionsToFetch);
            if (didCancel) { return; }
            setItems(items);
            setCompositionsToFetch([]);
        };

        fetchAndSetItems();

        return () => {
            didCancel = true;
        };
    }, [compositionsToFetch, fetchItems, setItems]);

    return [compositions, fetchingStatus, {
        invalidateItem,
        updateContent,
        setItems,
    }];
}
