import React, { useState, useCallback, useEffect, useMemo } from 'react';

import {
    fetchPage,
    addPage,
    TIMELINE_ELEMENTS_PER_PAGE,
    getTimeline,
} from 'msk-timeline';

import { useAPI } from 'common/useAPI';

export const useTimeline = ({
    teamId,
    folderId,
}) => {
    const [timeline, setTimeline] = useState([]);
    const [fetchedPages, setFetchedPages] = useState({});
    const { version, fullList } = useAPI();

    const timelineService = useMemo(() => ({
        getTimeline,
    }), []);

    const compositionFetchers = useMemo(() => {
        const listCompositions = (archetype) => (folderId, teamId) => fullList(archetype, undefined, folderId, teamId).then(response => response.message.results);

        return {
            message: () => listCompositions('message')(folderId, teamId),
            questionnaire: () => listCompositions('questionnaire')(folderId, teamId),
            questionnaireResponse: () => listCompositions('questionnaireResponse')(folderId, teamId),
            coopQuestionnaire: () => listCompositions('coopQuestionnaire')(folderId, teamId),
            coopQuestionnaireResponse: () => listCompositions('coopQuestionnaireResponse')(folderId, teamId),
            appointment: () => listCompositions('appointment')(folderId, teamId),
            referral: () => listCompositions('referral')(folderId, teamId),
            goal: () => version === 2 ? listCompositions('user-goal')(folderId, teamId) : [],
            userNote: () => version === 2 ? listCompositions('user-note')(folderId, teamId): [],
            observation: () => listCompositions('observation')(folderId, teamId)
        };
    }, [folderId, teamId, version, fullList]);

    const createNewTimeline = useCallback(
        async () => {
            setTimeline((timeline) => timeline.length ? new Array(timeline.length).fill(undefined) : []);
            setFetchedPages({});

            const [pageTimeline, page, totalCount] = await fetchPage({
                timelineService,
                compositionFetchers,
            });
            setFetchedPages(fetchedPages => ({ ...fetchedPages, [page]: true }));
            setTimeline(() => addPage(new Array(totalCount).fill(undefined), pageTimeline, page));
        },
        [compositionFetchers, timelineService],
    );

    useEffect(() => {
        if (!teamId || !folderId) { return; }
        createNewTimeline();
    }, [teamId, folderId, createNewTimeline]);

    const fetchPageIfNeeded = useCallback(async (page) => {
        if (fetchedPages[page]) { return; }
        fetchedPages[page] = true;

        const [pageTimeline, fetchedPage, totalCount] = await fetchPage({
            page,
            timelineService,
            compositionFetchers,
        });
        setTimeline(timeline => {
            if (totalCount !== timeline.length) {
                setFetchedPages({ [fetchedPage]: true });
                return totalCount ? new Array(totalCount).fill(undefined) : [];
            }
            return addPage(timeline, pageTimeline, page);
        });
    }, [fetchedPages, compositionFetchers, timelineService]);

    const fetchItemByIndex = useCallback((index) => {
        if (timeline[index]) { return; }
        const page = Math.floor(index / TIMELINE_ELEMENTS_PER_PAGE) + 1;

        fetchPageIfNeeded(page);
    }, [timeline, fetchPageIfNeeded]);

    return {
        timeline,
        createNewTimeline,
        fetchItemByIndex,
    };
};
