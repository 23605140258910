import React from 'react';
import { browserHistory, Link } from 'react-router';

import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { AllocateQuestionnaireComponent } from 'features/patient/questionnaire/create';
import HeadingDoc from 'ui/heading-doc';
import { questionnaireService } from 'services/questionnaire.service';

export default class AllocateQuestionnairePage extends BasePage {

    constructor (props) {
        super(props);

        this.state = {
            pathwayUuid: this.props.location.query.pathway
        };

        this.bindEvents(['Submit']);
    }

    pageTitle () {
        return _`Allocate ${this.$p()} Questionnaire | PHR Clinical Portal`;
    }

    handleSubmit({ questionnaireData: { document }, state: { useDueBy, dueBy, timeline } }, callback) {
        const questionnaireName = document.name || document.content.name;
        const questionnaireDocumentType = document.document_type ? document.document_type : 'coopQuestionnaire';
        const data = {};

        if (useDueBy && dueBy) {
            data.due_date = dueBy.unix();
        }

        if(timeline.lozengeTitle || timeline.lozengeContent || timeline.popupTitle || timeline.popupContent) {
            data.lozenge_title = timeline.lozengeTitle;
            data.lozenge_content = timeline.lozengeContent;
            data.popup_title = timeline.popupTitle;
            data.popup_content = timeline.popupContent;
        }

        questionnaireService.allocate({ questionnaireDocumentType, questionnaireName, data })
            .then(() => callback())
            .then(() => {
                const { pathname } = browserHistory.getCurrentLocation();

                if(/\/patient\//.test(pathname)) {
                    browserHistory.push(`${this.getFolderPath()}/patient/questionnaires`);
                } else {
                    console.error('Possible wrong redirect');
                    browserHistory.push(`${this.getFolderPath()}/questionnaires`);
                }
            });
    }

    render () {
        return (
            <div className="page">
                <div className='top-bar'>
                    <Link to={`${this.getFolderPath()}/patient/questionnaires`}>
                        {_`Back to Questionnaires List`}
                    </Link>
                </div>

                <HeadingDoc title={_`Allocate Questionnaire`}>
                    The questionnaire section allows you to allocate questionnaires.
                </HeadingDoc>
                <AllocateQuestionnaireComponent {...this.props} {...this.events} />
            </div>
        );
    }
}
