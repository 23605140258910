import Pagination from 'components/pagination';
import React from 'react';
import { ResourcesSearchQueryResultsManager } from '../ResourcesSearchResultManager/useResourcesSearchResultManager';

export const availablePerPageValues: number[] = [10, 25, 50, 100];

export const ResourcesSearchResultsPagination = ({ searchQueryResultsManager }: { searchQueryResultsManager: ResourcesSearchQueryResultsManager}) => {

    const results = searchQueryResultsManager.queryResults;

    return (
        (results && results.length > 0) && (
            <div className="advanced-search-pagination">
                <div className="advanced-search-pagination__settings">
                    <Pagination
                        pageCount={Math.ceil(
                            searchQueryResultsManager.totalResources /
                            searchQueryResultsManager.perPage,
                        )}
                        currentPage={searchQueryResultsManager.currentPage}
                        onChange={searchQueryResultsManager.setCurrentPage}
                    />
                    <div className="push" />
                    <label>Per page: </label>
                    <select
                        className="dropdown-options"
                        onChange={(event) =>
                            searchQueryResultsManager.setPerPage(
                                parseInt(event.target.value),
                            )
                        }
                        value={searchQueryResultsManager.perPage}
                    >
                        {availablePerPageValues.map(
                            (perPageCount: number, index: number) => {
                                return (
                                    <option
                                        key={index}
                                        value={perPageCount}
                                    >
                                        {perPageCount}
                                    </option>
                                );
                            },
                        )}
                    </select>
                </div>
            </div>
        )
    );
};
