import React, { useState, useEffect, useCallback } from 'react';
import PageTitle from 'page-title';
import { browserHistory, Link } from 'react-router';
import _ from 'services/i18n';
import { goalsService } from 'services/goals.service';
import { PatientGoalsTable } from './PatientGoalsTable';
import { useTeamPreferences } from 'common/useTeamPreferences';
// eslint-disable-next-line @typescript-eslint/camelcase
import Not_Found from 'pages/clinical_portal/not_found';

export function PatientGoalsTab({ folderId }) {
    const [state, setState] = useState({
        goals: [],
        loading: true,
        error: false,
    });
    const teamPreferences = useTeamPreferences();

    useEffect(() => {
        PageTitle.setTitle(
            'Patients Goals | PHR Clinical Portal'
        );
    }, []);

    const loadData = useCallback(async () => {
        const { status, message } = await goalsService.list({ folderId });

        if (status >= 300) {
            // Something went wrong.
            setState({
                goals: [],
                loading: false,
                error: true
            });
            return;
        }

        const { results: goals } = message;

        setState({
            goals,
            loading: false,
            error: false,
        });
    }, [folderId]);

    useEffect(() => {
        if (teamPreferences.show_goals) {
            loadData();
        }
    }, [loadData, teamPreferences.show_goals]);

    if (!teamPreferences.show_goals) {
        return <Not_Found/>;
    }

    function handleShowGoal(goal) {
        browserHistory.push(`${window.location.pathname}/` + goal.uuid);
    }

    return (
        <div className="page">
            <div className="top-bar">
                <Link to={`/clinical_portal/folder/${folderId}/patient/goals/create`}>{_`Create Goal`}</Link>
            </div>
            <PatientGoalsTable
                error={state.error}
                loading={state.loading}
                goals={state.goals}
                onShowGoal={handleShowGoal}
            />
        </div>
    );
}
