import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router';
// components
import PIFUModal from 'features/patient/pifu/PIFUModal';
import PIFUFormContainer from 'features/patient/pifu/PIFUFormContainer';
// hooks
import { usePIFU } from 'features/patient/pifu/pifu.hooks';
import { useCareEvents } from '../care-event/useCareEvents';
// services
import _ from 'services/i18n';
// styles
import './TopBar.style.scss';

type TopBarProps = {
    folderId: number;
    teamId: number;
};

export const TopBar: FC<TopBarProps> = ({ children, folderId, teamId }) => {
    const { isEnabled, displayName } = usePIFU();
    const [isPIFUModalOpen, setIsPIFUModalOpen] = useState(false);

    const [careEvents, isFetchingCareEvents] = useCareEvents({
        folderId,
        teamId,
    });

    const onPIFUAllocationSuccess = useCallback(() => {
        setIsPIFUModalOpen(false);
    }, []);

    const pathname = window.location.pathname;
    const prevRoute = pathname.substring(pathname.lastIndexOf('/') + 1);
   
    return (
        <>
            <div className="top-bar--patient">
                <div className="top-bar--patient__left">{children}</div>
                <div className="top-bar--patient__right">
                    {isEnabled && (
                        <button
                            className="top-bar--patient__right-button pifu-button"
                            onClick={() => setIsPIFUModalOpen(true)}
                        >
                            {`Add ${displayName}`}
                        </button>
                    )}
                  
                    {!isFetchingCareEvents && careEvents && (
                        <Link
                            className=" top-bar--patient__right-button care-event-button"
                            to={`/clinical_portal/folder/${folderId}/patient/care-event/${teamId}?prevRoute=${prevRoute}`}
                        >
                            {_`+ Care Event`}
                        </Link>
                    )}
                </div>
            </div>
            {isPIFUModalOpen && (
                <PIFUModal onClose={() => setIsPIFUModalOpen(false)}>
                    <PIFUFormContainer folderId={folderId} onAllocationSuccess={onPIFUAllocationSuccess} />
                </PIFUModal>
            )}
        </>
    );
};
