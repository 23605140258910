import { useState } from 'react';
import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';
import { BulkOperationPreviewBuilder } from './BulkOperationPreviewBuilder';

/**
 * Due to be rewritten to conform to React coding standards in https://adi-health.atlassian.net/browse/MPA-7191
 * Don't copy this pattern
 */
export default abstract class BulkOperationConfigurationComponent<S>
{
    private formState: S = this.getInitialFormState();

    protected getFormState(): S {
        return this.formState;
    }

    protected useFormState(): { currentFormState: S; onFormStateChange: (newFormState: S) => void } {
        const [currentFormState, onFormStateChange] = useState<S>(this.formState);
        const state = {
            currentFormState: currentFormState,
            onFormStateChange: (newFormState: S) => {
                this.formState = newFormState;
                onFormStateChange(newFormState);
            }
        };
        return state;
    }

    abstract getInitialFormState(): S;

    abstract getActionName(): string;
    abstract SetupComponent(): JSX.Element;
    abstract validate(): boolean;
    abstract getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<S>;

    /**
     * To be called when the associated setup modal is closed, so implementations do not need to trigger a re-render.
     */
    public reset() {
        this.formState = this.getInitialFormState();
    }
}
