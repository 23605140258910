import {getStartOfDay,getEndOfDay} from 'services/date-time-helper';
import lodash from 'lodash';
import { reportsService } from 'services/reports.service';
import { DataStore } from 'services/data-store';

export default class {
    Reset () {
        this.setState({
            formKey: this.state.formKey + 1
        });
    }

    Submit (data) {
        let fromDate = data.get('report-from-date').toDate(),
            toDate = data.get('report-to-date').toDate();

        if (fromDate > toDate) {
            this.setState({
                loading: false,
                alertText: 'From date must be on or before the To date'
            });

            return;
        }

        this.$setState({
            fromDate,
            toDate,
            alertText: false,
            notRun: false,
            loading: true
        }).then(() => {
            let toDateInclusive = getEndOfDay(this.state.toDate);

            const from = parseInt(getStartOfDay(this.state.fromDate).getTime() / 1000);
            const to = parseInt(toDateInclusive.getTime() / 1000);

            return reportsService.getReferrals(from, to)
        }).then((response) => {
            // Sort the results by name then code.
            let results = lodash.cloneDeep(response.message.results);
            results.sort((itemA, itemB) => {
                let sortValueA = (lodash.get(itemA, 'referrer_name', '') + lodash.get(itemA, 'referrer_code', '')).toLowerCase();
                let sortValueB = (lodash.get(itemB, 'referrer_name', '') + lodash.get(itemB, 'referrer_code', '')).toLowerCase();
                let cmp = sortValueA.localeCompare(sortValueB);
                if (cmp != 0) {
                    return cmp;
                }
            });

            this.setState({
                results: results,
                loading: false
            });
        }, (error) => {
            this.setState({
                loading: false,
                alertText: 'An unspecified error occured'
            });
        });
    }

    DownloadCSV () {
        const uuid = (DataStore.get('me.currentRole') || {}).uuid || 0;
        let url = '/files/clinical_portal/clinical_portal/reports/referrals?',
            query = [],
            toDateInclusive = getEndOfDay(this.state.toDate);

        if (this.getType() == 'team') {
            query.push('team_id=' + this.getTeamId());
        } else {
            query.push('organisation_id=' + this.getOrganisationId());
        }

        query.push('from=' + parseInt(getStartOfDay(this.state.fromDate).getTime() / 1000));
        query.push('to=' + parseInt(toDateInclusive.getTime() / 1000));
        query.push('using_role_uuid=' + uuid);
        query.push('csv=1');

        window.open(url + query.join('&'));
    }
}
