import React from 'react';
import {Link} from 'react-router';
import classNames from 'classnames';
import _ from 'services/i18n';

const TeamSelectorComponent = (props) =>{

    const {
        noClose,
        teams,
        portal_path,
        onSelectionSubmit,
        selectedItemIndex,
        onClose
    } = props;

    return (
        <div className='team-selector'>
            <div className='modal show' tabIndex='-1' role='dialog'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {noClose
                                ? null
                                : <button type='button' className='close' onClick={onClose}><span
                                    aria-hidden='true'>�</span></button>}
                            <h4 className='modal-title'>{_`Please select your team`}</h4>
                        </div>
                        <div className='modal-body'>
                            <TeamSelectorBody {...props}/>
                        </div>
                        <div className='modal-footer'>
                            {noClose
                                ? null
                                : <button type='button' className='btn btn-default' onClick={onClose}>
                                    {_`Close`}
                                </button>}
                            {teams.length === 0 ?
                                <Link className="btn btn-default" to={`${portal_path}/logout`}>Log Out</Link>
                                :
                                <button type='button' className='btn btn-primary'
                                        onClick={onSelectionSubmit.bind(null, selectedItemIndex)}>
                                    {_`Select Team`}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TeamSelectorBody = (props) =>{
    const {
        teams,
        selectedItemIndex,
        onSelection,
        onSelectionSubmit,
        hasInvites
    } = props;

    if (teams.length === 0) {
        let message;
        if (hasInvites) {
            message = (
                <p>You are not assigned to any teams. However, you have an outstanding invitation to accept.
                    Please check your email including your junk/spam folders. If you need any assistance, please
                    speak to your administrator.</p>
            );
        } else {
            message = (
                <p>You are not assigned to any teams. Please speak to your administrator if you believe this should not
                    be the case.</p>
            );
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {message}
                </div>
            </div>
        );
    }

    let message = null;
    if (hasInvites) {
        message = (
            <p>You have an outstanding invitation to accept. Please check your email including your junk/spam
                folders.</p>
        );
    }

    const rows = (teams || []).map((item, idx) =>{
        const className = classNames({
            'selected': idx === selectedItemIndex
        });

        return (
            <tr key={idx} className={className} onClick={onSelection.bind(null, idx)}
                onDoubleClick={onSelectionSubmit.bind(null, idx)}>
                <td>{item.organisation}</td>
                <td>{item.team}</td>
                <td>{item.name}</td>
            </tr>
        );
    });

    return (
        <div className="table-responsive">
            <table className='table table-condensed-extra table-nomargin'>
                <thead>
                    <tr>
                        <th>{_`Organisation`}</th>
                        <th>{_`Team`}</th>
                        <th>{_`Role`}</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            {message}
        </div>
    );
};

export default TeamSelectorComponent;
