import { useState, useEffect, useCallback } from 'react';
import { filterSpaces } from 'services/appointment-helpers';

const filters = {
    ubrn(str: string): string {
        return filterSpaces(str);
    }
};

export function useForm<T>(initialState: T, schema = {}, callback) {
    const [state, setState] = useState<T>(initialState);
    const [disable, setDisable] = useState(true);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setDisable(true);
    }, []);

    useEffect(() => {
        if (isDirty) {
            setDisable(validateState());
        }
    }, [state, isDirty]);

    const validateState = useCallback(
        () => {
            const hasErrorInState = Object.keys(schema).some(key => {
                const isInputFieldRequired = schema[key].required;
                const stateValue = state[key].value;
                const stateError = state[key].error;

                return (isInputFieldRequired && !stateValue) || stateError;
            });

            return hasErrorInState;
        },
        [state, schema],
    );

    const validateField = useCallback(
        (name, value) => {
            const fieldSchema = schema[name];

            if (fieldSchema?.required && !value) {
                return 'This is a required field.';
            }

            if (fieldSchema?.rules) {
                return fieldSchema.rules
                    .map((fn) => fn(value, state))
                    .filter((error) => !!error)[0];
            }
        },
        [state, schema],
    );

    const validateEachField = useCallback(
        () => {
            const names = Object.keys(schema);

            const errors = [];
            for (const name of names) {
                const value = state[name].value;
                const error = validateField(name, value);
                setState(prevState => ({
                    ...prevState,
                    [name]: { value, error },
                }));

                if (!!error) {
                    errors.push(error);
                }
            }

            return errors.length;
        },
        [state, schema],
    );

    const handleOnChange = useCallback(
        (event) => {
            setIsDirty(true);

            // eslint-disable-next-line prefer-const
            let { name, value, checked, type } = event.target;
            if (type === 'checkbox') {
                value = checked;
            }

            const newValue = filters[name] ? filters[name](value) : value;

            const error = validateField(name, value) || '';

            setState(prevState => ({
                ...prevState,
                [name]: { value: newValue, error },
            }));
        },
        [schema],
    );

    const handleOnBlur = useCallback(
        (event) => {
            const { name, value } = event.target;
            const fieldSchema = schema[name];
            const { format } = fieldSchema;

            if (format) {
                setState(prevState => {
                    const { error } = prevState[name];

                    return ({
                        ...prevState,
                        [name]: { value: format(value), error },
                    })
                });
            }
        },
        [schema],
    );

    const handleOnSubmit = useCallback(
        event => {
            event && event.preventDefault();
            if (!validateEachField()) {
                callback(state);
            }
        },
        [state],
    );

    const setField = useCallback(
        (field, value) => {
            const error = validateField(field, value) || '';

            setState(prevState => ({
                ...prevState,
                [field]: { value, error },
            }));
        }
    , []);

    return { state, disable, handleOnChange, handleOnBlur, handleOnSubmit, setField };
}
