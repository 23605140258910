import * as lodash from 'lodash';
import { apiV2Service, fetchAll } from './api-v2.service';
import { folderService } from './folder.service';
import { IRole, ISearchUserFoldersParams, ISearchUserFoldersResult } from 'phr-api-client';
import { DataStore } from 'services/data-store';
import { terService } from 'services/ter.service';
import { UserFolder } from 'models/UserFolder';

type IPatientStatus = 'registered' | 'invited' | 'pending';

const statusLabels: Record <IPatientStatus | 'unknown', string> = {
    'registered': 'Registered',
    'invited': 'Invited',
    'pending': 'Pending',
    'unknown': 'Unknown',
}

export function getStatusLabel(status: string): string {
    return statusLabels[status] || statusLabels.unknown;
}

class PatientsService {

    get STATUS(): { [key: string]: IPatientStatus } {
        return {
            REGISTERED: 'registered',
            INVITED: 'invited',
            PENDING: 'pending'
        };
    }

    public list({ role, limit, offset = 0, status, sort }: { role?: IRole; limit: number; offset: number; sort?: string; status?: IPatientStatus }): Promise<{patientsTotal: number; patients: UserFolder[]}> {
        role = role || DataStore.get('me.currentRole');

        return folderService
            .getFoldersDefinitions({ role })
            .then(folderGroups => {
                return folderGroups
                    .filter(folder => folder.name.indexOf('Patient') === 0)
                    .map(folder => folder.uuid);
            }).then((folderGroupUuids: string[]) => {
                return folderService.getUserFolders({
                    role,
                    folderGroupUuids,
                    status: status || '',
                    offset,
                    sort,
                    limit
                });
            })
            .then((response: any = {}) => {
                if (lodash.get(response, 'status') != 200) {
                    throw new Error('Error reported by PHR. Status code: ' + response.status);
                }

                const count = lodash.get(response, 'message.total', undefined);
                const results = lodash.get(response, 'message.results', undefined);

                if (count === undefined || results === undefined) {
                    throw new Error('PHR response did not have expected structure.');
                }

                return {
                    patients: results,
                    patientsTotal: count
                };
            });
    }

    public search({ role, limit = 100, offset = 0, sortRules, status, patientIdentifier }: { role?: IRole; limit?: number; sortRules?; offset?: number; status?: IPatientStatus; patientIdentifier: string }) {
        if (limit === Infinity) {
            return this.searchAll({ role, status, patientIdentifier });
        }
        role = role || DataStore.get('me.currentRole');

        return folderService
            .getFoldersDefinitions({ role })
            .then(folderGroups => {
                return folderGroups
                    .filter(folder => folder.name.indexOf('Patient') === 0 || folder.name.indexOf('Carer') === 0)
                    .map(folder => folder.uuid);
            }).then((folderGroupUuids: string[]) => {
                return apiV2Service.searchUserFolders({
                    teamId: Number(role.teamId),
                    searchString: patientIdentifier,
                    roleUuid: role.uuid,
                    folderGroupUuids,
                    status,
                    offset,
                    sortRules,
                    limit
                });
            }).then(({ message }) => {
                const folders: ISearchUserFoldersResult = message as ISearchUserFoldersResult;
                return {
                    patients: folders.results,
                    patientsTotal: folders.total
                };
            });
    }

    public getByFolderId({ role, folderId }: { role?: IRole; folderId: number }) {
        return folderService.getFolderById({ role, folderId });
    }

    public deregister(folderId: number) {
        return terService.createTer({ action: 'deregister', folderId });
    }

    private searchAll({ role, status, patientIdentifier }: { role?: IRole; status?: IPatientStatus; patientIdentifier: string }) {
        const limit = 100;
        return fetchAll((offset: number) => {
            return this.search({ role, status, patientIdentifier, offset, limit });
        });
    }
}

export const patientsService = new PatientsService();
