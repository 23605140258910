/* eslint-disable indent */
import { SortOrder } from 'common/ui/grid';
import { Filter } from 'common/ui/grid/paginatedTableReducer';
import {
    AdvancedSearch,
    AdvancedSearchDocumentConfig,
    AdvancedSearchQueryResults,
    AdvancedSearchQueryResultsManager, SelectionStateContainer
} from 'models/AdvancedSearchDefinition';
import { Composition } from 'models/Composition';
import { compositionService } from 'services/composition.service';
import { DataStore } from 'services/data-store';
import { makeGetRequest, makePostRequest } from 'services/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    availablePerPageValues
} from 'features/patients/advanced-search/AdvancedSearchResultsDisplay/AdvancedSearchResultsDisplay';
import { convertToTimestamp } from 'common/datetime/convertToDate';
import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';
import moment, { Moment } from 'moment';
import _, { isNull, isUndefined } from 'lodash';

export interface AdvancedSearchServiceProvider {

    rebuildViewData: (advancedSearchComposition: Composition<AdvancedSearch>) => Promise<string>;

    getPaginatedViewData: (viewIdentifier: string, limit: number, page: number, sort: Record<string, SortOrder>, filter: Filter) => Promise<AdvancedSearchQueryResults>;

    getSearchConfig: () => Promise<AdvancedSearchDocumentConfig[]>;

    createAdvancedSearch: (content: AdvancedSearch) => Promise<SaveResponse>;

    updateAdvancedSearch: (uuid: string, content: AdvancedSearch) => Promise<SaveResponse>;

    deleteAdvancedSearch: (uuid: string) => Promise<unknown>;

    listSearches: () => Promise<Composition<AdvancedSearch>[]>;

    exportData: (advancedSearchComposition: Composition<AdvancedSearch>) => Promise<string>;
}

//todo it's really annoying that the create chops out the other data!
export type SaveResponse = string | {
    uuid: string;
    action: string;
    updated_at: string;
};

class AdvancedSearchServiceProviderImpl implements AdvancedSearchServiceProvider {
    private roleKey = 'me.currentRole';
    private apiVersion = 2;

    private role() {
        return DataStore.get(this.roleKey);
    }

    createAdvancedSearch(content: AdvancedSearch): Promise<SaveResponse> {
        return compositionService.create({ role: this.role(), content: content, folderId: 'team', archetypeName: 'advanced-search' });
    }

    updateAdvancedSearch(uuid: string, content: AdvancedSearch): Promise<SaveResponse> {
        return compositionService.update({ uuid: uuid, role: this.role(), content: content, folderId: 'team', archetypeName: 'advanced-search' })
            .then(response => response.message);
    }

    deleteAdvancedSearch(uuid: string): Promise<unknown> {
        return compositionService.delete({ uuid: uuid, role: this.role(), folderId: 'team', archetypeName: 'advanced-search' });
    }

    listSearches(): Promise<Composition<AdvancedSearch>[]> {
        //todo need to go to advanced-search-specific endpoint for this? Or plug extra filters into general endpoint for catch-all?
        return compositionService.list({ role: this.role(), folderId: 'team', archetypeName: 'advanced-search', limit: Infinity })
            .then(response => response.message.results);
    }

    getSearchConfig(): Promise<AdvancedSearchDocumentConfig[]> {
        return makeGetRequest(`/teams/${this.role().teamId}/user-folders/advanced-search/document-type-criteria?using_role_uuid=${this.role().uuid}`, this.apiVersion)
            .then((response) => response.message);
    }

    exportData(searchComposition: Composition<AdvancedSearch>) {
        const exportUrl = `/teams/${this.role().teamId}/team-folder/compositions/advanced-search/${searchComposition.uuid}/export-data?using_role_uuid=${this.role().uuid}`;
        return makePostRequest(exportUrl, {}, this.apiVersion);
    }

    rebuildViewData(searchComposition: Composition<AdvancedSearch>): Promise<string> {
        const isStatic = searchComposition.content.static;
        const currentViewIdentifier = searchComposition.content.viewIdentifier;
        if (currentViewIdentifier && isStatic) {
            return Promise.resolve(currentViewIdentifier);
        }

        const refreshUrl = `/teams/${this.role().teamId}/team-folder/compositions/advanced-search/${searchComposition.uuid}/refresh-data?using_role_uuid=${this.role().uuid}`;
        return makePostRequest(refreshUrl, {}, this.apiVersion)
            .then(() => {
                //todo TEMPORARY nasty update just for safety - followup work coming down the line to make this system work better.
                // Don't want the client to care about this, but right now the results link is within the content so can be overwritten
                // unsafely by the client unless we take care. Sadness.
                return compositionService.getByUuid({
                    uuid: searchComposition.uuid,
                    role: this.role(),
                    folderId: 'team',
                    archetypeName: 'advanced-search'
                }).then(compositionResponse => {
                    searchComposition.content.viewIdentifier = compositionResponse.message.content.viewIdentifier;
                    searchComposition.content.viewUpdatedAt = compositionResponse.message.content.viewUpdatedAt;
                    return searchComposition.content.viewIdentifier;
                });
            });
    }

    getPaginatedViewData(advancedSearchCompositionUuid: string, limit: number, page: number, sort: Record<string, SortOrder>, filter: Filter): Promise<AdvancedSearchQueryResults> {

        const sortParameters = Object.keys(sort).flatMap((s: string) => {
            return {
                ascending: sort[s] === 1,
                field: s
            };
        });

        const filterParameters = !filter ? [] : Object.entries(filter).map((item) => {
            return {
                field: item[0],
                value: item[1]
            };
        });

        const query = {
            limit,
            page,
            sort: sortParameters,
            filter: filterParameters
        };

        const url = `/teams/${this.role().teamId}/team-folder/compositions/advanced-search/${advancedSearchCompositionUuid}/view-data?&using_role_uuid=${this.role().uuid}`;
        return makePostRequest(url, query, this.apiVersion)
            .then((response) => {
                return {
                    totalResultCount: response.message.total,
                    resultsPageData: response.message.results,
                    lastUpdatedAt: response.message.updated_at
                };
            });
    }
}

export const AdvancedSearchServiceProviderInstance = new AdvancedSearchServiceProviderImpl();



const patientsPerPageSetting = 'as-patients-per-page';

const getStoredPerPageValue = (): number => {
    const perPageValue: string = localStorage.getItem(patientsPerPageSetting);
    return parseInt(perPageValue) || availablePerPageValues[0];
};

function compositionHasChanged(c1: Composition<unknown>, c2: Composition<unknown>) {
    if (!c1 && !c2) {
        return false;
    }

    if (!(!!c1 && !!c2)) {
        return true;
    }

    return c1.uuid != c2.uuid
        || dateHasChanged(c1.updated_at, c2.updated_at);
}

function dateHasChanged(d1: Date, d2: Date) {
    if (isNull(d1) && isNull(d2)) {
        return false;
    }

    if (isUndefined(d1) && isUndefined(d2)) {
        return false;
    }

    return (isUndefined(d1) != isUndefined(d2))
        || (isNull(d1) != isNull(d2))
        || convertToTimestamp(d1) != convertToTimestamp(d2);
}

export type AdvancedSearchQueryResultsWrapper = {
    loading: boolean;
    error: boolean;
    results: AdvancedSearchQueryResults;
};

const loadingResponse: AdvancedSearchQueryResultsWrapper = {
    error: false,
    loading: true,
    results: null,
};

const errorResponse: AdvancedSearchQueryResultsWrapper = {
    error: true,
    loading: false,
    results: null,
};

function useAdvancedSearchResultsWrapper(
    searchService: AdvancedSearchServiceProvider,
    searchComposition: Composition<AdvancedSearch>,
    reloadRequestedAt: Moment,
    perPage: number,
    currentPage: number,
    sortState: Record<string, SortOrder>,
    filterState: Filter,
    onResults: (results: AdvancedSearchQueryResultsWrapper) => void
) {
    const resultsPromiseRef = useRef<Promise<AdvancedSearchQueryResultsWrapper>>(null);

    const resultsPromise = useAdvancedSearchResults(
        searchService,
        searchComposition,
        reloadRequestedAt,
        perPage,
        currentPage,
        sortState,
        filterState,
    );

    if (resultsPromiseRef.current != resultsPromise) {
        resultsPromiseRef.current = resultsPromise;
        onResults(loadingResponse);
    }

    useEffect(() => {
        const resultsHandler: {
            fn: (results: AdvancedSearchQueryResultsWrapper) => void;
        } = { fn: onResults };

        resultsPromise.then(resultsWrapper => {
            resultsHandler.fn(resultsWrapper);
        });

        return () => {
            resultsHandler.fn = () => {
                return;
            };
        };
    }, [onResults, resultsPromise]);
}

type SearchDataReloadRequestTracker = {
    searchComposition: Composition<AdvancedSearch>;
    reloadRequestedAt: Moment;
};

function useAdvancedSearchResults(
    searchService: AdvancedSearchServiceProvider,
    searchComposition: Composition<AdvancedSearch>,
    reloadRequestedAt: Moment,
    perPage: number,
    currentPage: number,
    sortState: Record<string, SortOrder>,
    filterState: Filter
): Promise<AdvancedSearchQueryResultsWrapper> {
    const reloadLastRequestedRef = useRef<SearchDataReloadRequestTracker>({
        searchComposition: searchComposition,
        reloadRequestedAt: reloadRequestedAt
    });

    const reloadPromise = useMemo<Promise<string>>(() => {
        if (!searchComposition) {
            return Promise.resolve(null);
        }

        if (
            reloadLastRequestedRef.current.searchComposition == searchComposition
            && reloadLastRequestedRef.current.reloadRequestedAt
            && reloadLastRequestedRef.current.reloadRequestedAt.isSame(reloadRequestedAt)
        ) {
            return Promise.resolve(searchComposition.uuid);
        }

        reloadLastRequestedRef.current = {
            searchComposition: searchComposition,
            reloadRequestedAt: reloadRequestedAt
        };

        return searchService.rebuildViewData(searchComposition).then(() =>  {
            return Promise.resolve(searchComposition.uuid);
        });
    }, [searchService, searchComposition, reloadRequestedAt]);

    return useMemo<Promise<AdvancedSearchQueryResultsWrapper>>(() => {
        return reloadPromise.then(compositionUuid => {
            if (!compositionUuid) {
                return Promise.resolve(loadingResponse);
            }

            return searchService.getPaginatedViewData(
                compositionUuid,
                perPage,
                currentPage,
                sortState,
                filterState
            ).then(results => {
                    return Promise.resolve({
                        error: false,
                        loading: false,
                        results: results
                    });
                })
                .catch(() => {
                    return Promise.resolve(errorResponse);
                });
        }).catch(() => {
            return Promise.resolve(errorResponse);
        });
    }, [searchService, reloadPromise, perPage, currentPage, sortState, filterState]);
}

const initialFilterState: Filter = {};

export function useAdvancedSearchResultsManager(
    searchService: AdvancedSearchServiceProvider,
    searchComposition: Composition<AdvancedSearch>,
    initialSortState: Record<string, SortOrder>
): AdvancedSearchQueryResultsManager {
    const [queryResults, setQueryResults] = useState<AdvancedSearchQueryResultsWrapper>(loadingResponse);
    const [perPage, setPerPage] = useState<number>(getStoredPerPageValue());
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortState, setSortState] = useState<Record<string, SortOrder>>(initialSortState);
    const [filterState, setFilterState] = useState<Filter>(initialFilterState);
    const [reloadRequestedAt, setReloadRequestedAt] = useState<Moment>(null);
    const currentSearchCompositionRef = useRef<Composition<AdvancedSearch>>(null);

    const updateFilterState = useCallback((newState: Filter) => {
        if (_.isEmpty(newState)) {
            setFilterState(initialFilterState);
        }
        else {
            setFilterState(newState);
        }
    }, []);

    const compositionChanged = compositionHasChanged(searchComposition, currentSearchCompositionRef.current);
    currentSearchCompositionRef.current = searchComposition;

    const currentPageToUse = compositionChanged ? 1 : currentPage;
    const sortStateToUse = compositionChanged ? initialSortState : sortState;
    const filterStateToUse = compositionChanged ? initialFilterState : filterState;

    if (compositionChanged) {
        setCurrentPage(1);
        setSortState(initialSortState);
        updateFilterState(initialFilterState);
    }

    useAdvancedSearchResultsWrapper(
        searchService,
        searchComposition,
        reloadRequestedAt,
        perPage,
        currentPageToUse,
        sortStateToUse,
        filterStateToUse,
        setQueryResults
    );

    const onRefresh = useCallback(() => {
        setReloadRequestedAt(moment());
    }, []);

    const onPerPageChange = useCallback((newPerPageVal: number) => {
        localStorage.setItem(patientsPerPageSetting, newPerPageVal.toString());
        setPerPage(newPerPageVal);
    }, []);

    const usePageReset = <V extends unknown>(fn: (newValue?: V) => void): (newValue?: V) => void => useCallback(newValue => {
        setCurrentPage(1);
        fn(newValue);
    }, [fn]);

    const hasResult = !queryResults.loading && !queryResults.error;

    return {
        searchUuid: searchComposition?.uuid,
        viewIdentifier: searchComposition?.content.viewIdentifier,
        error: queryResults.error,
        loading: queryResults.loading,
        queryResults: hasResult && queryResults.results.resultsPageData,
        patientsTotal: hasResult && queryResults.results.totalResultCount,
        updatedAt: hasResult && searchComposition?.content.viewUpdatedAt,
        onRefresh: usePageReset(onRefresh),
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        perPage: perPage,
        setPerPage: usePageReset(onPerPageChange),
        sortState: sortState,
        setSortState: usePageReset(setSortState),
        filterState: filterState,
        setFilter: usePageReset(updateFilterState)
    };
}

export function useSelectionStateContainer(searchQueryResultsManager: AdvancedSearchQueryResultsManager): SelectionStateContainer {
    const updatedAtRef = useRef<string>(searchQueryResultsManager.updatedAt);
    const viewIdentifierRef = useRef<string>(searchQueryResultsManager.viewIdentifier);
    const advancedSearchUuidRef = useRef<string>(searchQueryResultsManager.searchUuid);
    const patientsTotalRef = useRef<number>(searchQueryResultsManager.patientsTotal);
    const filterStateRef = useRef<Filter>(searchQueryResultsManager.filterState);

    if (!searchQueryResultsManager.loading) {
        updatedAtRef.current = searchQueryResultsManager.updatedAt;
        viewIdentifierRef.current = searchQueryResultsManager.viewIdentifier;
        patientsTotalRef.current = searchQueryResultsManager.patientsTotal;
        filterStateRef.current = searchQueryResultsManager.filterState;
        advancedSearchUuidRef.current = searchQueryResultsManager.searchUuid;
    }

    const updatedAt = updatedAtRef.current;
    const viewIdentifier = viewIdentifierRef.current;
    const advancedSearchUuid = advancedSearchUuidRef.current;
    const patientsTotal = patientsTotalRef.current;
    const filterState = filterStateRef.current;

    return useMemo(() => {
        updatedAtRef.current = updatedAt;
        if (!patientsTotal) {
            return null;
        }
        const folderSelectionState = new FolderSelectionState(patientsTotal);
        return {
            selectionState: folderSelectionState,
            viewIdentifier: viewIdentifier,
            advancedSearchUuid: advancedSearchUuid,
            filterState: filterState,
        };
    }, [updatedAt, patientsTotal, viewIdentifier, advancedSearchUuid, filterState]);
}
