import React from 'react';
import {Link} from 'react-router';
import lodash from 'lodash';
import { convertToOutputTzDate } from 'common/datetime/DateTime';

/**
 * Displays an appointment composition on a MyPathway timeline.
 * Props:
 * - composition = Required. An object containing the composition data to be displayed.
 * - folderLink = Required. Base URL for the patient's folder in the portal. This is used to construct links to specific archetypes.
 */
export const Appointment = ({composition, folderLink, compositionQueryString}) => {
    let link = `${folderLink}/appointments/${lodash.get(composition, 'uuid', '')}`;
    if (compositionQueryString){
        link += `?${compositionQueryString}`;
    }

    const type = lodash.get(composition, 'content.type', '').toLowerCase();
    const timeline = lodash.get(composition, 'content.timeline', '');
    const status = lodash.get(composition, 'content.status', '').toLowerCase();
    //const details = lodash.get(composition, 'content.details', '');
    const location = lodash.get(composition, 'content.location', '');

    const date_unknown = lodash.get(composition, 'content.date_unknown', false);
    const timestamp = (date_unknown ? undefined : lodash.get(composition, 'content.date', undefined));

    let appointment;
    if (type == 'triage') {
        appointment = (<TriageAppointment />);
    } else if (status == 'available-to-book') {
        appointment = (<AvailableToBookAppointment title={timeline.title} />);
    } else {
        appointment = (
            <BookedAppointment
                type={timeline.title || type}
                location={location}
                timestamp={timestamp}
                status={status}
                />
        );
    }

    return (
        <Link to={link}>
            <div className="composition-appointment">
                {appointment}
            </div>
        </Link>
    );
};

export default Appointment;

/**
 * Renders fixed information about a triage appointment.
 * Note that this is temporary only. In future, triage appointments will not be
 *  rendered on the timeline. Instead, triage referrals will be shown instead.
 */
export const TriageAppointment = () => {
    return (
        <div>
            <h4>Referral Received</h4>
            <div>
                Your information sent to us by your GP is being reviewed by a senior clinician to decide who you should see first.{' '}
                We will contact you within 10 days.
            </div>
            <div className="status">Referral Received</div>
        </div>
    );
}

/**
 * Renders fixed information about an available-to-book appointment.
 * Note that in future, the information to be displayed will be loaded from the
 *  composition, having been put there by the bot or PHR.
 */
export const AvailableToBookAppointment = ({title}) => {
    return (
        <div>
            <h4>{title || 'Booking'}</h4>
            <div>
                You can now book your first appointment. This will take you to the NHS e-Referral booking service.
            </div>
            <div className="status">Available To Book</div>
        </div>
    );
}

/**
 * Renders information about a booked appointment.
 * Props:
 * - type = The type of appointment, e.g. "outpatient" or "triage".
 * - location = Human-readable location information, indicating where the appointment will take place.
 * - status = The status of the appointment, e.g. "booked" or "cancelled".
 * - timestamp = The date and time the appointment is booked for, expressed as a Unix timestamp (seconds).
 */
export const BookedAppointment = ({type, location, status, timestamp, timezone}) => {
    return (
        <div>
            <h4>{type || 'Appointment'}</h4>
            <div className='appointment-location'>{location || ''}</div>
            <Time>{timestamp}</Time>
            <Date>{timestamp}</Date>
            <div className="status">{status || 'unknown'}</div>
        </div>
    );
}

/**
 * Format an appointment time for display, if it is known.
 *
 * Props:
 * - children = The time stored as a unix timestamp (seconds), or undefined to display nothing.
 */
export const Time = ({children}) => {

    if (!children) {
        return (<noscript />);
    }

    let m = convertToOutputTzDate(children);
    if (!m || !m.isValid()) {
        console.warn('Invalid timestamp: ' + children);
        return (<noscript />);
    }

    m = m.utc();

    return (
        <div>
            Time : {m.format('HH:mm')}
        </div>
    );
};

/**
 * Format an appointment date for display, if it is known.
 *
 * Props:
 * - children = The date stored as a unix timestamp (seconds), or undefined to display nothing.
 */
export const Date = ({children}) => {

    if (!children) {
        return (<noscript />);
    }

    let m = convertToOutputTzDate(children);
    if (!m || !m.isValid()) {
        console.warn('Invalid timestamp: ' + children);
        return (<noscript />);
    }

    m = m.utc();

    return (
        <div>
            Date: {m.format('Do MMMM YYYY')}
        </div>
    );
};
