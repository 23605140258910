import React from 'react';
import PropTypes from 'prop-types'
import lodash from 'lodash';



/**
 * Displays the contents of a table cell containing an appointment location in human-readable form.
 * This is part of a row of the transport and interpreter worklist.
 * 
 * Props (data):
 * - appointment = Required. The appointment to extract the location from.
 */
const LocationField = ({appointment}) => {
    return (<span>{lodash.get(appointment, 'content.location') || '(unknown)'}</span>);
};

export default LocationField;

LocationField.propTypes = {
    appointment: PropTypes.object.isRequired
};
