import React, { Fragment, useEffect, useState } from 'react';

import {
    Grid,
    GridBody,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    SortOrder, styleSortedColumns,
} from 'common/ui/grid';
import PaginationView from 'components/pagination';
import { Loading } from 'common/ui/alert-boxes';
import { usePagination } from 'common/ui/usePagination';

import { MNDDepressedPatientsWorklistItem } from './MNDDepressedPatientsWorklistItem';
import { MNDDepressedPatientsWorklistRow } from './MNDDepressedPatientsWorklistRow';
import { getMNDDepressedPatientsWorklistFilterString, MNDDepressedPatientsWorklistSort } from './MNDDepressedPatientsHelpers';
import { useI18N } from 'common/i18n/useI18N';
import { filterNhs } from 'common/filterNhs';

export function MNDDepressedPatientsWorklist({
    items,
    isLazyFetchingInProcess,
}: {
    items: MNDDepressedPatientsWorklistItem[];
    isLazyFetchingInProcess?: boolean;
}) {
    const _ = useI18N();
    const [sortOrder, setSortOrder] = useState<{ [key: string]: SortOrder }>({ phq8Timestamp: SortOrder.DESC });
    const [filterState, setFilterState] = useState<{ [key: string]: string }>({});
    const [filteredItems, setFilteredItems] = useState([]);
    const { page, total: pagesTotal, items: paginatedItems, onPageChange } = usePagination({
        items: isLazyFetchingInProcess ? items : filteredItems
    });

    useEffect(() => {
        const sortEntries = Object.entries(sortOrder);
        let sortedItems = items;
        if (sortEntries.length > 0) {
            const [sortKey, sortOrder] = sortEntries[0];
            sortedItems = items.sort(MNDDepressedPatientsWorklistSort(sortKey, sortOrder));
        }

        const filterEntries = Object.entries(filterState).filter(([, filterString]) => filterString !== '');
        const filteredItems = sortedItems.filter(item => {
            if (!filterEntries.length) {
                return true;
            }
            return filterEntries.every(([filterKey, filterString]) => {
                const itemString = getMNDDepressedPatientsWorklistFilterString(item, filterKey);

                if (!itemString) {
                    return false;
                }

                if(filterKey === 'nhs') {
                    return filterNhs(filterString, itemString);
                }
                return itemString.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
            });
        });
        setFilteredItems(filteredItems);
    }, [filterState, items, sortOrder]);

    return (
        <Fragment>
            <Grid onSort={setSortOrder}
                sortOrder={sortOrder}
                onFilter={setFilterState}
                filterState={filterState}
                sortable={!isLazyFetchingInProcess}
                filterable={!isLazyFetchingInProcess}
                className={styleSortedColumns}
            >
                <GridHeader>
                    <tr>
                        <th colSpan={2} className="ui-grid-header-cell ui-grid-header-cell__title-controls">Patient</th>
                        <th colSpan={3} className="ui-grid-header-cell ui-grid-header-cell__title-controls">PHQ-8</th>
                        <th colSpan={3} className="ui-grid-header-cell ui-grid-header-cell__title-controls">GAD-7</th>
                    </tr>
                    <GridHeaderRow>
                        <GridHeaderCell field="name" className="text-center">Name</GridHeaderCell>
                        <GridHeaderCell field="nhs" className="text-center">NHS</GridHeaderCell>
                        <GridHeaderCell field="phq8Timestamp" className="text-center">Date</GridHeaderCell>
                        <GridHeaderCell field="phq8Score" className="text-center">Score</GridHeaderCell>
                        <GridHeaderCell field="phq8ScoreDiff" className="text-center">diff</GridHeaderCell>
                        <GridHeaderCell field="gad7Timestamp" className="text-center">Date</GridHeaderCell>
                        <GridHeaderCell field="gad7Score" className="text-center">Score</GridHeaderCell>
                        <GridHeaderCell field="gad7ScoreDiff" className="text-center">diff</GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {paginatedItems.map((item, i) => (
                        <MNDDepressedPatientsWorklistRow
                            key={item.nhs}
                            worklistItem={item}
                            isLoading={false}
                            className={i % 2 === 1 ? 'even' : 'odd'}
                        />
                    ))}
                </GridBody>
            </Grid>
            <PaginationView
                currentPage={page}
                pageCount={pagesTotal}
                onChange={onPageChange}
            ></PaginationView>
        </Fragment>
    );

}


