import { Composition } from 'models/Composition';
import { useEffect, useState } from 'react';
import DataStore from 'services/data-store';
import { QuestionnaireResponseAnyType } from 'models/compositions/QuestionnaireResponse';
import questionnaireService from 'services/questionnaire.service';
import { getQuestionnairePath } from 'features/patient/questionnaire/list/ListViewHelpers';

function getPreviousCompletedQuestionnaireResponseComposition<T extends QuestionnaireResponseAnyType>(
    questionnaireResponse?: Composition<T>
): Promise<Composition<T> | null> {
    if (questionnaireResponse && ['complete', 'scored'].includes(questionnaireResponse.content.status)) {
        const role = DataStore.get('me.currentRole');
        return questionnaireService.search({
            role: role,
            folderId: questionnaireResponse.folder_id,
            search: {
                'content.questionnaire_uuid': questionnaireResponse.content.questionnaire_uuid,
                'content.status_changes.completed_at': { '$lt': questionnaireResponse.content.status_changes.completed_at }
            },
            sort: [{ field: 'content.status_changes.completed_at', ascending: false }],
            limit: 1,
            offset: 0,
            archetypeName: questionnaireResponse.document_type
        })
            .then((response) => {
                if (response.message.results.length > 0) {
                    const questionnaireResponse = response.message.results[0];
                    //Prevent inadvertently revealing content that should be hidden from the clinician.
                    //Do it centrally in the hook to avoid bugs elsewhere - just pretend there's nothing to see.
                    if (questionnaireResponse.content.hide_from_clinician && role !== 'admin') {
                        return null;
                    }
                    return questionnaireResponse;
                }
                return null;
            });
    }
    return Promise.resolve(null);
}

function getPreviousQuestionnaireScores(
    previousQuestionnaireResponse?: Composition<QuestionnaireResponseAnyType>
): Promise<any[] | null> {
    if (previousQuestionnaireResponse && previousQuestionnaireResponse.content.status === 'scored') {
        return questionnaireService.getQuestionnaireScores({
            uuid: previousQuestionnaireResponse.uuid,
            folderId: previousQuestionnaireResponse.folder_id
        });
    }
    return Promise.resolve(null);
}

export type PreviousQuestionnaireResponseDetails = {
    previousResponseCompletedAt?: number;
    previousResponseAnswers?: any;
    previousResponseScores?: any[];
    previousResponsePath?: string;
};

function mapPreviousCompletedQuestionnaireCompositionsToResponseDetails(
    previousCompletedResponse?: Composition<QuestionnaireResponseAnyType>,
    previousScores?: any[]
): PreviousQuestionnaireResponseDetails {
    //This is really important because it internally replaces the default not-answered text of '99999'
    // and without that replacement the display blows up later on. It's bad that it's hidden away in this secret place,
    // but I can't change that existing pattern without lots of refactoring in other places because it's existing code.
    const { answers } = previousCompletedResponse ? questionnaireService.calculateQuestionnaireScoring({
        questionnaireResponse: previousCompletedResponse,
        score: null,
        archetype: previousCompletedResponse?.document_type
    }) : { answers: null };

    //Re-using existing URL-generation function, but it expects a weird non-standard data structure for its parameters
    // so rather than extensive refactoring I'm just building the structure it needs
    const previousResponsePath = previousCompletedResponse && getQuestionnairePath({
        questionnaireResponse: {
            type: previousCompletedResponse.document_type,
            document: { uuid: previousCompletedResponse.uuid }
        },
        folderId: previousCompletedResponse.folder_id
    });

    return {
        previousResponseCompletedAt: previousCompletedResponse?.content.status_changes?.completed_at,
        previousResponseAnswers: answers,
        previousResponseScores: previousScores,
        previousResponsePath: previousResponsePath
    };
}

export function usePreviousCompletedQuestionnaire(
    questionnaireResponse: Composition<QuestionnaireResponseAnyType>
): PreviousQuestionnaireResponseDetails {
    const [previousCompletedResponseDetails, setPreviousCompletedResponseDetails] = useState<PreviousQuestionnaireResponseDetails>({});
    useEffect(() => {
        getPreviousCompletedQuestionnaireResponseComposition(questionnaireResponse)
            .then(previousCompletedResponse => {
                return getPreviousQuestionnaireScores(previousCompletedResponse)
                    .then(previousScores => {
                        const previousResponseDetails = mapPreviousCompletedQuestionnaireCompositionsToResponseDetails(
                            previousCompletedResponse,
                            previousScores
                        );
                        setPreviousCompletedResponseDetails(previousResponseDetails);
                    });
            });
    }, [questionnaireResponse]);
    return previousCompletedResponseDetails;
}
