/**
 * Sets the page title of the window both client and server side
 */
class PageTitle {
    constructor () {
        this.currentTitle = 'Default Page';
    }

    /**
     * Sets the page title
     * @param {String} newTitle - The title to set the window to
     */
    setTitle (newTitle) {
        this.currentTitle = newTitle;
        if (typeof(window) !== 'undefined') {
            document.title = newTitle;
        }
    }
}

export default new PageTitle();
