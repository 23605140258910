import { DataStore } from 'services/data-store';
import { folderService } from 'services/folder.service';
import { api } from 'common/api';
import { IDocumentGroup } from 'services/document-group.service';

export async function fetchPrivacyNoticeDocumentGroups(): Promise<IDocumentGroup[]> {
    const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});

    const teamId = (DataStore.get('me.currentRole') || {}).teamId || 0;

    const privacyNoticeDocumentGroups = await folderService.getFoldersDefinitions(roleUuid).then((res) => {
        const folderGroupUuid = res.find((folder: any) => folder.name === 'Team folder').uuid;
        const fetchListUrl = `/teams/${teamId}/team-folder/compositions/privacy-notice/${folderGroupUuid}/document-groups?using_role_uuid=${roleUuid}`;
        return api.get(fetchListUrl);
    }).then(res => {
        return res.message;
    });

    return privacyNoticeDocumentGroups;
}
