import React from 'react';

class SideBarPanel extends React.Component {

  constructor(props){
    super(props);
    this.touchEnd = 0;
    this.touchStart = 0;
  }

  toggleNav(){
    this.props.toggle();
  }

  onTouchStart(e){
    this.touchStart = e.touches[0].pageX;
  }

  onTouchEnd(){
    this.touchStart = 0;
    this.touchEnd = 0;
   }

  onTouchMove(e){
    if(e.touches.length){
      this.touchEnd = e.touches[0].pageX;
      this.handleSwipe(this.touchStart, this.touchEnd)
    }
  }

  handleSwipe(start, end){
    let diff = start - end;
    if(diff>150 && end !== 0 && start !== 0){
      this.toggleNav();
      this.onTouchEnd();
    }
  }

  render () {
    let componentClasses = 'side-bar-panel clearfix' +
                           (this.props.visible ? ' opened':'') +
                           (this.props.className ? (' ' + this.props.className) : '');

    return (
      <div className={componentClasses}
           onTouchStart={this.onTouchStart.bind(this)}
           onTouchEnd={this.onTouchEnd.bind(this)}
           onTouchMove={this.onTouchMove.bind(this)}>
        {this.props.title ?
          <div className="side-bar-panel__title">
            <h2>{this.props.title}</h2>
          </div>:''}
        <a className="side-bar-panel__close" onClick={this.toggleNav.bind(this)}>&times;</a>
        <div className="padding-block clearfix">
          {this.props.children}
        </div>
      </div>);
  }
}

export default SideBarPanel;
