import './ExternalLink.less';
import React from 'react';

export const ExternalLink = ({href = '', onClick, children}: {href?: string, onClick?: any, children: any}) => {

    href = href ? /https?:\/\//.test(href) ? href : `http://${href}` : 'javascript:void(0)';

    const handleOnClick = (event) => {
        if (onClick) {
            event.preventDefault();
            onClick(event);
        }
    }

    return (
        <span className="pt-external-link">
            <a target="_blank" href={href} onClick={handleOnClick}>{children}</a>&nbsp;
            <i className="fas fa-external-link-alt"></i>
        </span>
    );
};