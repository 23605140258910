
export enum DASHBOARD_COMPONENT_NAMES {
    DashboardRow = 'DashboardRow',
    DashboardColumn = 'DashboardColumn',
    DashboardDummy = 'DashboardDummy',
    DashboardQuestionnaireGraph = 'DashboardQuestionnaireGraph',
    DashboardQuestionnaireGraphForCarer = 'DashboardQuestionnaireGraphForCarer',
    DashboardQuestionnaireTable = 'DashboardQuestionnaireTable',
    DashboardQuestionnaireTableForCarer = 'DashboardQuestionnaireTableForCarer',
    DashboardDemographicsTable = 'DashboardDemographicsTable',
    DashboardDemographicsTableForCarer = 'DashboardDemographicsTableForCarer',

}
export interface TeamPrefDashboardModule<T=any> {
    name: DASHBOARD_COMPONENT_NAMES;
    props?: T;
    children?: TeamPrefDashboardModule[];
}
