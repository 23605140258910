import {getStartOfDay,getEndOfDay} from 'services/date-time-helper';
import { reportsService } from 'services/reports.service';
import { DataStore } from 'services/data-store';

export default class {
    Reset () {
        this.setState({
            formKey: this.state.formKey + 1
        });
    }

    Submit (data) {
        let fromDate = data.get('report-from-date').toDate(),
            toDate = data.get('report-to-date').toDate();

        if (fromDate > toDate) {
            this.setState({
                loading: false,
                alertText: 'From date must be on or before the To date'
            });

            return;
        }

        this.$setState({
            fromDate,
            toDate,
            alertText: false,
            loading: true
        }).then(() => {
            let toDateInclusive = getEndOfDay(this.state.toDate);
            const from = parseInt(getStartOfDay(this.state.fromDate).getTime() / 1000);
            const to = parseInt(toDateInclusive.getTime() / 1000);

            return reportsService.getOverdueEq5ds(from, to);
        }).then((response) => {
            this.setState({
                total: response.message.total,
                overdue: response.message.overdue,
                percentage: response.message.percentage,
                loading: false
            });
        }, (error) => {
            this.setState({
                loading: false,
                alertText: 'An unspecified error occured'
            });
        });
    }

    DownloadCSV () {
        const uuid = (DataStore.get('me.currentRole') || {}).uuid || 0;
        let url = '/files/clinical_portal/clinical_portal/reports/overdue-eq5ds?',
            query = [],
            toDateInclusive = getEndOfDay(this.state.toDate);

        if (this.getType() == 'team') {
            query.push('team_id=' + this.getTeamId());
        } else {
            query.push('organisation_id=' + this.getOrganisationId());
        }

        query.push('from=' + parseInt(getStartOfDay(this.state.fromDate).getTime() / 1000));
        query.push('to=' + parseInt(toDateInclusive.getTime() / 1000));
        query.push('using_role_uuid=' + uuid);
        query.push('csv=1');

        window.open(url + query.join('&'));
    }
}
