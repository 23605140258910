import React from 'react';
import DOMPurify from 'dompurify';

type EmailPreviewProps = {
    subject: string;
    htmlContent: string;
}

const EmailPreview = ({ subject, htmlContent }: EmailPreviewProps) => {
    return (
        <div className="email-preview">
            <p><strong>Email Subject: </strong></p>
            <p>{subject}</p>
            <p><strong>Email Body:</strong></p>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }) }} />
        </div>
    );
};

export default EmailPreview;
