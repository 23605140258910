import * as TER from 'models/TriggerEngineRequestContent';

export type SendQuestionnaireState = {
    questionnaireName: string;
    questionnaireId: string | number;
    lozengeTitle: string;
    lozengeContent: string;
    popupTitle: string;
    popupContent: string;
    sendPush: boolean;
}

export function validate(state: SendQuestionnaireState, onErrors: (errors: string[]) => void): boolean {
    const normalisedState = normalise(state);
    const errors = [];

    if (normalisedState.questionnaireName === '') errors.push('Questionnaire is required');
    onErrors(errors);
    return errors.length === 0;
}

export const defaults = (state: SendQuestionnaireState): SendQuestionnaireState => ({
    ...state,
    lozengeTitle: state.questionnaireName,
    lozengeContent: 'We now need you to answer some questions about your condition so that we can ensure we provide the best care. Please do this now - it will take you less than 2 minutes.\n',
    popupTitle: state.questionnaireName,
    popupContent: 'We now need you to answer some questions about your condition so that we can ensure we provide the best care. Please do this now - it will take you less than 2 minutes.\n'
})


export function normalise(state: SendQuestionnaireState): SendQuestionnaireState {
    return {
        questionnaireName: state.questionnaireName.trim(),
        questionnaireId: state.questionnaireId,
        lozengeTitle: state.lozengeTitle.trim(),
        lozengeContent: state.lozengeContent.trim(),
        popupTitle: state.popupTitle.trim(),
        popupContent: state.popupContent.trim(),
        sendPush: state.sendPush
    };
}

export function getTriggerEngineContent(state: SendQuestionnaireState): TER.SendQuestionnaireContent {
    return {
        questionnaire_name: state.questionnaireName,
        lozenge_title: state.lozengeTitle || defaults(state).lozengeTitle,
        lozenge_content: state.lozengeContent || defaults(state).lozengeContent,
        popup_title: state.popupTitle || defaults(state).popupTitle,
        popup_content: state.popupContent || defaults(state).popupContent,
        send_push: state.sendPush,
    };
}
