import React, { FC, Fragment, useEffect, useMemo } from 'react';
import _ from 'services/i18n';
import { Loading } from 'common/ui/alert-boxes';
import {
    Grid,
    GridBody,
    GridCell,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow,
    SortOrder
} from 'common/ui/grid';
import PaginationView from 'components/pagination';
import { usePathwayNames } from 'features/pathway-labels/usePathwayNames';
import { DateTime } from 'common/datetime/DateTime';

import { Link } from 'react-router';
import ButtonGroup from 'components/button-group';
import {
    defaultFilter,
    filterSelectors,
    getButtonsActionList,
    getQuestionnairePath,
    getScore,
    getUIStatus,
    QuestionnaireListData, sortHandlers, sortSelectors
} from './ListViewHelpers';
import { useTableFilter } from 'common/ui/grid/useTableFilter';
import { useTableSort } from 'common/ui/grid/useTableSort';
import { usePagination } from 'common/ui/usePagination';
import { usePathways } from 'features/patients/pathways/usePathways';
import { useAPI } from 'common/useAPI';
import { QuestionnaireResponse } from 'models/compositions/QuestionnaireResponse';
import './MSKListView.scss';


const useSortedFilteredAndPaginatedList = ({ list: preparedList }) => {
    const { onFilter, filteredItems, filterState } = useTableFilter<QuestionnaireListData>({
        items: preparedList,
        defaultFilterHandler: defaultFilter,
        selectors: filterSelectors
    });

    const { onSort, sortedItems, sortState } = useTableSort({
        items: filteredItems,
        sortHandlers,
        selectors: sortSelectors,
        initialSortState: { completedOn: SortOrder.DESC }
    });

    const { items: paginatedItems, total, onPageChange, page } = usePagination({
        items: sortedItems,
        perPage: 8,
        currentPage: 1
    });

    useEffect(() => {
        onPageChange(1);
    }, [sortState, filterState, onPageChange]);

    return {
        onFilter,
        onSort,
        onPageChange,
        paginatedItems,
        sortState,
        filterState,
        total,
        page
    };
};


export interface ListViewProps {
    onArchiveQuestionnaire: (uuid: string) => void;
    onCompare: (questionnaireResponse: object) => void;
    list: QuestionnaireListData[];
    loading: boolean;
    folderId: number;
}
export const MSKListView: FC<ListViewProps> = ({
    loading: isLoading,
    list,
    onCompare,
    onArchiveQuestionnaire,
    folderId
} ) => {
    const { addPathwaysByLabels } = usePathways();

    const { version } = useAPI();

    const preparedList = useMemo(() => {
        const questionnaireResponseList = list.map(item => item.questionnaireResponse.document);
        const pathwayData = addPathwaysByLabels(questionnaireResponseList);
        return list.map((item, index) => {
            const {
                pathwayNames,
                pathwayNamesWithEvent,
                pathwaySortAndFilter
            } = pathwayData[index];
            
            const clinicalEventSortAndFilter = pathwaySortAndFilter;

            return {
                ...item,
                pathwayNames,
                pathwayNamesWithEvent,
                pathwaySortAndFilter,
                clinicalEventSortAndFilter,
            };
        });
    }, [addPathwaysByLabels, list]);

    const {
        onSort,
        onFilter,
        onPageChange,
        paginatedItems,
        page,
        total,
        sortState,
        filterState
    } = useSortedFilteredAndPaginatedList({ list: preparedList });
    if (isLoading) {
        return (
            <Loading show={isLoading}>{_`Loading...`}</Loading>
        );
    }

    if(!list?.length) {
        return <span>{_`Sorry, no matching results found`}</span>;
    }

    return (
        <Fragment>
            <Grid
                onSort={onSort}
                onFilter={onFilter}
                sortOrder={sortState}
                filterState={filterState}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'pathwaySortAndFilter'}
                        >
                            {_`Pathway`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'clinicalEventSortAndFilter'}
                        >
                            {_`Clinical Event`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'name'}
                            width={'25%'}
                        >
                            {_`Questionnaire`}
                        </GridHeaderCell>
                        {version === 1 && (
                            <GridHeaderCell
                                sortable={true}
                                filterable={true}
                                field={'issuedOn'}>
                                {_`Issued On`}
                            </GridHeaderCell>
                        )}
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'completedOn'}
                        >
                            {_`Completed On`}
                        </GridHeaderCell>
                        {version === 1 && (
                            <GridHeaderCell
                                sortable={true}
                                filterable={true}
                                field={'status'}>
                                {_`Status`}
                            </GridHeaderCell>
                        )}
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'score'}>
                            {_`Score`}
                        </GridHeaderCell>
                        {version === 2 && (
                            <GridHeaderCell
                                sortable={true}
                                filterable={true}
                                field={'status'}>
                                {_`Status`}
                            </GridHeaderCell>
                        )}
                        {version === 2 && (
                            <GridHeaderCell
                                sortable={true}
                                filterable={true}
                                field={'issuedBy'}>
                                {_`Issued By`}
                            </GridHeaderCell>
                        )}
                        {version === 2 && (
                            <GridHeaderCell
                                sortable={true}
                                filterable={true}
                                field={'issuedOn'}>
                                {_`Issued On`}
                            </GridHeaderCell>
                        )}
                        <GridHeaderCell
                            sortable={false}
                            filterable={false}>
                            {_`Actions`}
                        </GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {paginatedItems.map((questionnaireData) => (
                        <QuestionnaireListRow
                            key={questionnaireData?.questionnaireResponse?.document?.uuid}
                            questionnaireData={questionnaireData}
                            onCompare={onCompare}
                            onArchiveQuestionnaire={onArchiveQuestionnaire}
                            folderId={folderId}
                        />
                    )
                    )}
                </GridBody>
            </Grid>
            <PaginationView
                currentPage={page}
                pageCount={total}
                onChange={onPageChange}
            />
        </Fragment>
    );
};
export default MSKListView;

interface QuestionnaireListRowProps {
    questionnaireData: QuestionnaireListData;
    onArchiveQuestionnaire: (uuid: string) => void;
    onCompare: (questionnaireResponse: any) => void;
    folderId: number;
}
const QuestionnaireListRow: FC<QuestionnaireListRowProps> = (props) => {
    const { questionnaireData, folderId, onCompare, onArchiveQuestionnaire } = props;

    const { version } = useAPI();

    const {
        questionnaire,
        questionnaireResponse,
        couldBeCompared,
        isValid,
        scoreName,
        isCompleted,
        scoreValue
    } = questionnaireData;

    const labels = useMemo(() => {
        const labels = (questionnaireResponse.document.content as QuestionnaireResponse)?.labels?.filter?.((item) => {
            return item.status;
        }) || [];
        return labels;
    }, [questionnaireResponse]);

    const { pathwayNamesWithEvent } = usePathwayNames({ labels });

    if (!isValid) {
        return (
            <GridRow>
                <GridCell colSpan={6}>Invalid Questionnaire</GridCell>
            </GridRow>
        );
    }

    const path = getQuestionnairePath({ questionnaireResponse, folderId });
    const score = version === 1
        ? getScore({ questionnaire, isCompleted, scoreName, scoreValue })
        : (isCompleted && scoreName != null) ? `${scoreName} - ${scoreValue}` : 'Not Scored';

    const questionnaireStatus = questionnaireResponse?.document?.content?.status?.trim()?.toLowerCase();
    const questionnaireType = questionnaire.type;

    const issuedOn = (
        <DateTime
            parseFormat={'nhs_date_fulldate'}
            format={'questionnaires_date'}
        >{questionnaireResponse?.document?.created_at}</DateTime>
    );
    const dateCompletedAt = questionnaireResponse.document.content.status_changes?.completed_at;
    const completedOn = dateCompletedAt ?
        (
            <DateTime empty={'-'}>
                {dateCompletedAt}
            </DateTime>
        ) : '-';

    const status = getUIStatus(questionnaireResponse.document.content.status);

    const actionList = getButtonsActionList({
        questionnaireStatus,
        couldBeCompared,
        onArchiveQuestionnaire,
        onCompare,
        questionnaireResponse,
        path,
        questionnaireType
    });

    const rowClassName = questionnaireStatus === 'overdue' ? 'warning' : '';
    return (
        <GridRow className={rowClassName}>
            <GridCell>
                <PathwayNamesDisplay
                    pathwayNamesWithEvent={pathwayNamesWithEvent}
                    showClinicalEvent={false}
                />
            </GridCell>
            <GridCell>
                <PathwayNamesDisplay
                    pathwayNamesWithEvent={pathwayNamesWithEvent}
                    showClinicalEvent={true}
                />
            </GridCell>
            <GridCell>
                {
                    !questionnaireData.isConfidential ?
                        <Link to={path}>{questionnaire.document.name}</Link> :
                        <span>{questionnaire.document.name}</span>
                }
            </GridCell>
            {version === 1 && (
                <GridCell>
                    {issuedOn}
                </GridCell>
            )}
            <GridCell>
                {completedOn ?? '-'}
            </GridCell>
            {version === 1 && (
                <GridCell>
                    {status}
                </GridCell>
            )}
            <GridCell>
                <div className="no-wrap">
                    {score ?? '-'}
                </div>
            </GridCell>
            {version === 2 && (
                <GridCell>
                    {status}
                </GridCell>
            )}
            {version === 2 && (
                <GridCell>
                    <div className="no-wrap">
                        {questionnaireResponse.document.content.name_of_issuer}
                    </div>
                </GridCell>
            )}
            {version === 2 && (
                <GridCell>
                    <div className="no-wrap">
                        <DateTime
                            format="questionnaires_date"
                        >{questionnaireResponse.document.created_at}</DateTime>
                    </div>
                </GridCell>
            )}
            <GridCell>
                {
                    !questionnaireData.isConfidential ? (
                        <ButtonGroup
                            size="xs"
                            upperCased
                            links={actionList}/>
                    )
                        :
                        <p>Confidential</p>
                }
            </GridCell>
        </GridRow>
    );
};

interface PathwayNamesDisplayProps {
    pathwayNamesWithEvent: [string, string][];
    showClinicalEvent: boolean;
}
export const PathwayNamesDisplay: FC<PathwayNamesDisplayProps> = ({ pathwayNamesWithEvent, showClinicalEvent = false }) => {
    const field = showClinicalEvent ? 1 : 0;
    return (!!pathwayNamesWithEvent.length && (
        <div className="pathway-labels">
            {pathwayNamesWithEvent.map((names, i) => {
                return (
                    <div className="pathway-labels_row" key={i}>
                        <div className="pathway-labels_cell no-wrap" title={names[field] || ''}>{names[field] || ''}</div>
                    </div>
                );
            })}
        </div>
    )
    );
};
