import React from 'react';

export default Loading;

export const Loading = (props) => {
    const {status, children} = props;
    if (status) {
        return (
            <span><span className="glyphicon glyphicon-refresh icon-spin" /> {children}</span>
        );
    }

    return <span>{children}</span>;
};

