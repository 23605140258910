import React, { FC } from 'react';
import { AdvancedSearchCriterionDateInputProps } from './AdvancedSearchCriterionDateInput.interface'
import {
  AdvancedSearchCriterionAbsoluteDateInput,
  AdvancedSearchCriterionRelativeDateInput,
} from 'features/patients/advanced-search/AdvancedSearchCriterionInputs';
import {useDateCriterionRelative} from "features/patients/advanced-search/AdvancedSearchSetupHooks";

export const AdvancedSearchCriterionDateInput: FC<AdvancedSearchCriterionDateInputProps> = (props) => {
    const [useRelativeDate, setUseRelativeDate] = useDateCriterionRelative(props.value, props.reset);
    const valueWithDefault = props.value ? props.value : '';
    return (
        <>
            <label>Relative</label>
            <input
                type="checkbox"
                className={'checkbox'}
                checked={useRelativeDate}
                onChange={() => setUseRelativeDate(!useRelativeDate)}
            />
            <div className={'advanced-search-query-request-criterion__value'}>
                {useRelativeDate ? (
                    <AdvancedSearchCriterionRelativeDateInput
                        value={valueWithDefault}
                        onChange={props.onChange}
                    />
                ) : (
                    <AdvancedSearchCriterionAbsoluteDateInput
                        value={valueWithDefault}
                        onChange={props.onChange}
                    />
                )}
            </div>
        </>
    );
};
