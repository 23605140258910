import { CompositionService } from './composition.service';

class MessageTemplateService extends CompositionService {

    get archetypeName(): string {
        return 'message-template';
    }
}

export const messageTemplateService = new MessageTemplateService();
