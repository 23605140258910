import React, { FC, useEffect, useState } from 'react';
import { Dot, DotType, Line, LineGraph } from 'common/ui/line-graph/LineGraph';
import _ from 'services/i18n';
import { observationsService } from 'services/observations.service';
import { getCurrentObservationTimestamp } from 'pages/clinical_portal/folder/_folderId/patient/observations/index';

interface ObservationResultsProps {
    observationValues: ObservationGraphValue[];
    onSelected: ({ name, value }) => void;
    selectedMeasurement: string;
}

interface ObservationGraphValue {
    name: string;
    value: number;
    timestamp: number;
    component?: [];
}

export const ObservationResultsPage: FC<{ folderId: number; teamId: number; getCurrentGraphDot: (dot: Dot, observationName: string) => void; changeMeasurementCallback: () => void }> = ({ folderId, teamId, getCurrentGraphDot, changeMeasurementCallback }) => {
    const [observationsList, setObservationsList] = useState<ObservationGraphValue[]>([]);
    const [emptyGraphData, setEmptyGraphData] = useState(true);
    const [unitsOfMeasureList, setUnitsOfMeasureList] = useState([]);
    const [observationsGraph, setObservationsGraph] = useState(<LineGraph
        lines={[]}
    />);

    let currentMeasurement = '',
        lines = [];

    useEffect(() => {
        let isMounted = true;
        observationsService.list()
            .then((res) => {
                if (isMounted) {
                    const observationsList = res.message.results;
                    const graphObjects = mapObservationsToGraphObjects(observationsList);
                    const unitsOfMeasure = getUnitsOfMeasure(observationsList);
                    setUnitsOfMeasureList(unitsOfMeasure);
                    setObservationsList(graphObjects);
                }
            });
        return () => {
            isMounted = false;
        };
    }, []);

    const getDotParams = (targetDot: Dot) => {
        getCurrentGraphDot(targetDot, currentMeasurement);
    };

    const convertMeasureItemsToGraphDots = (measureItemsList): Dot[] => {
        return measureItemsList.sort(({ timestamp: a }, { timestamp: b }) => a - b)
            .map(({ timestamp, value }) => ({ x: timestamp * 1000, y: value }));
    };

    const getUnitsOfMeasure = (observationsList) => {
        const unitsOfMeasure = [];
        observationsList.forEach(observItem => {
            const content = observItem.content;
            unitsOfMeasure.push({
                measureName: content.code.text,
                unit: content.component ? content.component[0].valueQuantity.unit
                    : content.valueQuantity.unit
            });
        });
        const map = new Map();

        unitsOfMeasure.forEach(element => {
            map.set(element.measureName, element);
        });

        const filteredByMeasureName = [];

        map.forEach( (value, key, map) => {
            filteredByMeasureName.push(value);
        });

        return filteredByMeasureName;
    };

    const drawSimpleObservationGraph = (sameNameObservations, observationName, currentUnitsOfMeasure) => {
        const lines = [];

        const measureDots = sameNameObservations.sort(({ timestamp: a }, { timestamp: b }) => a - b)
            .map(({ timestamp, value }) => ({ x: timestamp * 1000, y: value }));

        lines.push({
            color: generateHexColor(),
            title: observationName + ` (${currentUnitsOfMeasure.unit})`,
            dotType: DotType.Square,
            dots: measureDots
        });
        return lines;
    };

    const drawCompoundObservationGraph = (sameNameObservations, allObservations, observationName, currentUnitsOfMeasure) => {
        const lines = [];
        const allSubMeasuresList = [];
        const subMeasureNames = sameNameObservations[0].component.map(item => item['name'])
            .filter((value, index, self) => self.indexOf(value) === index);

        sameNameObservations.forEach(item => {
            item.component.forEach(measure => {
                allSubMeasuresList.push(measure);
            });
        });

        subMeasureNames.forEach(name => {
            const groupSubMeasuresByName = allSubMeasuresList.filter(item => item.name === name);
            const subMeasuresDots = convertMeasureItemsToGraphDots(groupSubMeasuresByName);
            lines.push({
                color: generateHexColor(),
                title: name + ` (${currentUnitsOfMeasure.unit})`,
                dotType: DotType.Square,
                dots: subMeasuresDots
            });
        });

        if (observationName === 'Blood pressure') {
            const heartRateItems = allObservations.filter(value => value.name === 'Heart rate');
            const heartRateDots = convertMeasureItemsToGraphDots(heartRateItems);
            lines.push({
                color: generateHexColor(),
                title: 'Heart rate (beats/minute)',
                dotType: DotType.Round,
                dots: heartRateDots
            });
        }
        return lines;
    };

    const observationsToGraphLine = (observationsList: ObservationGraphValue[], observationName: string): Line[] => {
        const getObservationsByName = observationsList.filter(value => value.name === observationName);
        const currentUnitsOfMeasure = unitsOfMeasureList.filter(item => item.measureName === observationName)[0];
        if (getObservationsByName[0].component) {
            return drawCompoundObservationGraph(getObservationsByName, observationsList, observationName, currentUnitsOfMeasure);
        } else {
            return drawSimpleObservationGraph(getObservationsByName, observationName, currentUnitsOfMeasure);
        }
    };

    const handleChange = ({ name, value }: { name: string; value: string }) => {
        const currentUnitsOfMeasure = {
            simpleUnits: undefined,
            compoundUnits: []
        };
        let unitsOfMeasureString = '';

        switch (name) {
        case 'measurement':
            changeMeasurementCallback();
            currentMeasurement = value;
            setEmptyGraphData(false);

            if (currentMeasurement === 'Blood pressure') {
                unitsOfMeasureList.forEach(item => {
                    if (item.measureName === 'Heart rate' || item.measureName === 'Blood pressure') {
                        currentUnitsOfMeasure.compoundUnits.push(item.measureName + ' (' + item.unit + ')' );
                    }
                });
            } else {
                currentUnitsOfMeasure.simpleUnits = unitsOfMeasureList.filter(item => item.measureName === currentMeasurement)[0];
            }

            unitsOfMeasureString = currentUnitsOfMeasure.simpleUnits ? value + ` (${currentUnitsOfMeasure.simpleUnits.unit})`
                : currentUnitsOfMeasure.compoundUnits.join(' / ');

            lines = observationsToGraphLine(observationsList, value);
            setObservationsGraph(<LineGraph
                lines={lines}
                legendXAxis={'Date'}
                legendYAxis={unitsOfMeasureString}
                dotClickCallback={getDotParams}
                isDotShownByClick={true}
            />);
            break;
        }
    };

    return (
        <div className={'results-page'}>
            <br/>
            <GraphDataOptions
                onSelected={handleChange}
                selectedMeasurement={currentMeasurement}
                observationValues={observationsList}
            />
            {emptyGraphData ?
                (
                    <div className="results-page_select-first">
                        {_`Please select measurement type`}
                    </div>
                ) :
                (
                    <div className={'results-page_graph'}>
                        {observationsGraph}
                    </div>
                )
            }
        </div>
    );
};

export const mapObservationsToGraphObjects = (observations): ObservationGraphValue[]  => {
    let graphObjects = [];
    graphObjects = observations.map(item => {
        return {
            name: item.content.code.text,
            ...(!item.content.component && { value: parseInt(item.content.valueQuantity.value) }),
            timestamp: getCurrentObservationTimestamp(item),
            ...(item.content.component && {
                component: item.content.component.map(subItem => {
                    return {
                        name: subItem.code.coding[0].display,
                        value: subItem.valueQuantity.value,
                        timestamp: getCurrentObservationTimestamp(item),
                    };
                })
            })
        };
    });
    return graphObjects;
};

export const GraphDataOptions: FC<ObservationResultsProps> = (props) => {
    const {
        observationValues,
        onSelected,
    } = props;

    const observationNamesSet = observationValues.map(item => item['name'])
        .filter((value, index, self) => self.indexOf(value) === index);

    return (
        <div className={'results-form'}>
            <div className={'results-form_item'}>
                <label>{_`Measurement`}</label>
                <select defaultValue="" name={'measurement'} className='form-control' onChange={({ target: { name, value } }) => {
                    onSelected({ name, value });
                }}>
                    <option value="" disabled hidden >{_`Select Measurement...`}</option>
                    {observationNamesSet?.map((name, idx) => {
                        return (
                            <option key={idx} value={name}>
                                {name}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
};

export const generateHexColor = (): string => {
    let color = '#';
    for (let i = 0; i < 3; i++) {
        color += ('0' + Math.floor(Math.random() * Math.pow(16, 2) / 2).toString(16)).slice(-2);
    }
    return color;
};
