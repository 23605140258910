import React from 'react';
import BasePage from 'components/page/base';

import _ from 'services/i18n';
/**
 * Extended Page Component for admin_portal/dashboard
 **/
class IndexPage extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            header: _`Admin Portal`,
            menuText: _`Some text goes here`,
            menu: [
                {link: '/organisations', label: _`Organisations`},
            ],
            prefix: '/admin_portal'
        }
    }

    pageTitle () {
        return _`Dashboard | PHR Admin Portal`;
    }

    render () {
        return (
            <div className="page-dashboard">
                <h1>Admin Portal Dashboard</h1>
                <p>Please select a menu item from the sidebar to continue.</p>
            </div>
        );
    }
}

export default IndexPage;

