import React from 'react';
import _ from 'services/i18n';
import ActionMenu from 'components/action-menu';

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.actionCallbacks = {};
    }

    renderHeader(columns) {
        return columns.map((item, idx) => {
            return (
                <th key={idx}>{item}</th>
            );
        });
    }

    renderBody(columns, results) {
        return results.map((item, idx) => {
            let idValue = 0, actions, row = columns.map((columnItem, columnIdx) => {
                let value;

                if (item.hasOwnProperty(columnItem)) {
                    value = item[columnItem];
                } else {
                    value = '';
                }

                return (<td key={columnIdx}>{value}</td>);
            });

            if (this.props.hasOwnProperty('idKey')) {
                idValue = item[this.props.idKey]
            } else {
                idValue = item.id;
            }

            if (this.props.hasOwnProperty('actions')) {
                actions = this.props.actions.map((actionItem, actionIdx) => {
                    let newAction = Object.assign({}, actionItem);
                    if (actionItem.hasOwnProperty('type')) {
                        if (actionItem.type == 'callback') {
                            if (!this.actionCallbacks.hasOwnProperty(idx)) {
                                this.actionCallbacks[idx] = {};
                            }

                            if (!this.actionCallbacks[idx].hasOwnProperty(actionIdx)) {
                                this.actionCallbacks[idx][actionIdx] = actionItem.callback.bind(null, item);
                            }
                            newAction.callback = this.actionCallbacks[idx][actionIdx];
                        }
                    } else {

                        newAction.route = newAction.route.replace(/%[iI][dD]%/g,idValue)
                    }

                    return newAction;
                });

                row.push(<td key='actions'>
                    <ActionMenu size="xs" links={actions} label={_`Actions`} />
                </td>);
            }

            return (
                <tr key={idx}>
                    {row}
                </tr>
            );
        });
    }

    render() {
        let columns = [], results = this.props.results || [], columnKeys = {};

        if (results.length == 0) {
            return (
                <div className="json-table table-responsive">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td><em>No results currently available</em></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        // loop through each row and get a list of columns
        results.forEach((item, idx) => {
            let key;

            for(key in item) {
                if (item.hasOwnProperty(key)) {
                    if (!columnKeys.hasOwnProperty(key)) {
                        columnKeys[key] = columns.length;
                        columns.push(key);
                    }
                }
            }
        });

        return (
            <div className="json-table table-responsive">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        {this.renderHeader(columns)}
                        {this.props.hasOwnProperty('actions')?<th>Actions</th>:null}
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderBody(columns, results)}
                    </tbody>
                </table>
            </div>
        );
    }
}
