import { SelectionStateContext } from 'components/dataSource/FolderSelectionState';
import { Composition } from 'models/Composition';
import { CoopResource } from 'models/compositions/CoopResource';
import React, { useState } from 'react';
import ResourcesSearchQueryToolbar from '../ResourcesSearchQueryToolbar/ResourcesSearchQueryToolbar';
import { useResourcesSearchResultManager } from '../ResourcesSearchResultManager/useResourcesSearchResultManager';
import { ResourcesSearchResultsPagination } from '../ResourcesSearchResultsPagination/ResourcesSearchResultsPagination';
import ResourcesSearchResultsTable from '../ResourcesSearchResultsTable/ResourcesSearchResultsTable';
import { useResourcesSelectionStateContainer } from '../ResourcesSelectionStateContainer/useResourcesSelectionStateContainer';

export type ResourcesSearchCriteria = {
    keyword: string;
    department: string;
    collection: {
        label: string;
        value: string;
    };
    resourceType: {
        label: string;
        value: string;
    };
}

const initialSearchCriteria: ResourcesSearchCriteria = {
    keyword: null,
    department: null,
    collection: null,
    resourceType: null
};

type SearchResourcesComponentProps = {
    onSubmitSelection: (resourceIds: (string | number)[]) => void;
    submitSelectionText: string;
    includedColumnHeading: string;
    isIncluded: (resource: Composition<CoopResource>) => boolean;
}

export const SearchResourcesComponent = ({ onSubmitSelection, submitSelectionText, includedColumnHeading, isIncluded }: SearchResourcesComponentProps) => {

    const [searchCriteria, setSearchCriteria] = useState<ResourcesSearchCriteria>(initialSearchCriteria);

    const searchQueryResultsManager = useResourcesSearchResultManager(searchCriteria);
    const folderSelectionStateContainer = useResourcesSelectionStateContainer(searchQueryResultsManager);

    return (
        <div>
            <SelectionStateContext.Provider value={folderSelectionStateContainer}>
                <ResourcesSearchQueryToolbar 
                    searchCriteria={searchCriteria} 
                    setSearchCriteria={setSearchCriteria} 
                    reloadSearch={searchQueryResultsManager.onRefresh}/>
                <ResourcesSearchResultsTable 
                    results={searchQueryResultsManager.queryResults} 
                    isIncluded={isIncluded}
                    includedColumnHeading={includedColumnHeading}
                    sortOrder={searchQueryResultsManager.sortState} 
                    onSort={searchQueryResultsManager.setSortState}
                />
                <ResourcesSearchResultsPagination searchQueryResultsManager={searchQueryResultsManager} />
                <button className="btn btn-primary" onClick={() => {
                    onSubmitSelection(folderSelectionStateContainer.selectionState.getIncludedFolderIds());
                    folderSelectionStateContainer.selectionState.reset();
                }}>
                    {submitSelectionText}
                </button>
            </SelectionStateContext.Provider>
            
        </div>
    );
};
