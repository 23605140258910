import React, { Fragment, useMemo } from 'react';
import _ from 'services/i18n';
import ErrorView from 'components/errorbox';
import { Row, Column, FormGroup } from 'ui';
import { useEffect } from 'react';
import { useState } from 'react';
import { CompositionService } from 'services/composition.service';
import { SendMessageState } from 'components/user/message/create/validator';
import { PushNotificationCreateForm } from 'components/user/push-notification/create/form';
import Select from 'react-select';
import { useSortedDepartmentLabelableData } from 'features/department/hooks/useSortedDepartmentData';
import {
    DataOption,
    GroupedDataOption,
    SortedDepartmentData,
} from 'features/department/department.interface';
import { MessageTemplate } from 'models/messageType';
import { useGroupedDepartmentDataOptionList } from 'features/department/hooks/useGroupedDepartmentDataOptionList';

type MessageCreateFormProps = {
    loading?: boolean;
    errors?: string[];
    backRoute?: string;
    messageType?: string;
    onChange: (c) => unknown;
    form: SendMessageState;
    onSubmit: (f) => unknown;
    hideSubmit?: boolean;
    messageTemplateService: CompositionService;
};

export const MessageCreateForm = (
    props: MessageCreateFormProps,
): JSX.Element => {
    const [messageTemplates, onMessageTemplatesChange] = useState<MessageTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<DataOption<MessageTemplate> | null>(null);


    useEffect(() => {
        if (messageTemplates.length >= 1) {
            return;
        }
        props.messageTemplateService
            .list({ folderId: 'team' })
            .then((response) => {
                if (response.message.total >= 1) {
                    const messageTemplates = response.message.results.map(
                        (item: any, index: number) => {
                            return { ...item.content, index };
                        },
                    );
                    onMessageTemplatesChange(messageTemplates);
                }
            });
    });

    const sortedListData: SortedDepartmentData<MessageTemplate> = useSortedDepartmentLabelableData(messageTemplates);

    const [searchOptionList, optionCount] = useGroupedDepartmentDataOptionList(sortedListData, d => d.name);

    const handleTemplateSelect = (template: MessageTemplate | null): void => {
        let data;
        let selectedTemplateOption: DataOption<MessageTemplate> | null;
        if(template) {
            data = {
                title: template.name,
                content: template.content,
            };
            selectedTemplateOption = {
                label: template.name,
                value: template,
            };
        } else {
            data = {
                title: '',
                content: '',
            };
            selectedTemplateOption = null;
        }

        props.onChange(data);
        setSelectedTemplate(selectedTemplateOption);
    };

    if (props.loading) {
        return <h5>{_`Loading... please wait`}</h5>;
    }

    return (
        <Fragment>
            <ErrorView errors={props.errors} />
            <form noValidate onSubmit={props.onSubmit}>
                <Row>
                    <Column md={8}>
                        <FormGroup>
                            <div >
                                <Select
                                    isClearable={true}
                                    className="filter-select"
                                    classNamePrefix="filter-select"
                                    placeholder={`Templates (${optionCount})`}
                                    options={searchOptionList}
                                    onChange={(option) => {
                                        handleTemplateSelect(option ? option.value : null);
                                    }}
                                    value={selectedTemplate}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="title">{_`Message title`}</label>
                            <input
                                type="text"
                                name="title"
                                className={'form-control'}
                                value={props.form.title}
                                onChange={(e) => {
                                    props.onChange({ title: e.target.value });
                                }}
                            />
                        </FormGroup>
                    </Column>
                </Row>
                <Row>
                    <Column md="12">
                        <FormGroup>
                            <label htmlFor="content">{_`Message content`}</label>
                            <textarea
                                rows={5}
                                name="content"
                                className="form-control"
                                value={props.form.content}
                                onChange={(e) => {
                                    props.onChange({ content: e.target.value });
                                }}
                                required
                            />
                        </FormGroup>
                        <PushNotificationCreateForm
                            sendPush={props.form.sendPush}
                            onChange={props.onChange}
                        />
                        {!props.hideSubmit && (
                            <FormGroup>
                                <button
                                    type="submit"
                                    className="btn btn-default"
                                >
                                    {props.messageType == 'welcome'
                                        ? _`Assign Message`
                                        : _`Send Message`}
                                </button>
                            </FormGroup>
                        )}
                    </Column>
                </Row>
            </form>
        </Fragment>
    );
};
