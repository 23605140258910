import React from 'react';
import HeadingDoc from 'ui/heading-doc';

/**
 * Displays the page header for the transport and interpreter worklist.
 */
const Header = () => {
    return (
        <HeadingDoc title='Transport &amp; Interpreter Worklist'>
            <p>This page lists booked appointments where transport or interpreter arrangements need to be made. The appointments are listed in date order, soonest first.</p>
            <p>When transport or interpreter arrangements have been made for an appointment, click the “Done“ button corresponding to "Transport is Required" or "Interpreter is Required". You can click "Undo" afterwards if you make a mistake, although these are only available until you load the page again or click "Hide Completed Items" at the bottom of the page.</p>
            <p>Note that this page will only display a few items at first. Scroll to the bottom and click "Show More..." if you have completed or had to skip everything that is already on the list.</p>
        </HeadingDoc>
    );
};

export default Header;
