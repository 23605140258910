import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { browserHistory } from 'react-router';

import BaseComponent from 'components/BaseComponent';
import _ from 'services/i18n';
import FormPanel from 'components/form-panel';
import RelativeDateInput from 'components/form-panel/date-inputs/relative-date-input';
import DateInputField from 'components/form-panel/date-inputs/date-input-field';
import TextInput from 'components/form-panel/text-input';
import SelectorInput from 'components/form-panel/selector-input';
import CheckboxInput from 'components/form-panel/checkbox-input';

import * as AlertBoxes from 'common/ui/alert-boxes';

import { teamPreferencesService } from 'services/team-preferences.service';
import { filterSpaces } from 'services/appointment-helpers';
import { appointmentsService } from 'services/appointments.service';
// Maps status codes to human-readable labels.
const appointmentStatuses = [
    ['', ''],
    ['available-to-book', 'Available to book'],
    ['booked', 'Booked'],
    ['waiting', 'Waiting'],
    ['admitted', 'Admitted'],
    ['arrived', 'Arrived'],
    ['called', 'Called'],
    ['seen', 'Seen'],
    ['departed', 'Attended'],
    ['discharged', 'Discharged'],
    ['did-not-attend', 'Did not attend'],
    ['cancelled', 'Cancelled'],
    ['closed', 'Closed']
];

const approvedPIFUAppointmentStatuses = [
    ['proposed', 'Proposed (Accepted)'],
    ['booked', 'Booked'],
];

const rejectedPIFUAppointmentStatuses = [
    ['proposed', 'Proposed (Declined)'],
];

// Maps appointment types to human-readable labels.
// Note that this is deliberately a subset for this simplified form.
// The first item is the default.
const appointmentTypes = [
    ['', ''],
    ['assessment', _`Assessment`],
    ['day-care', _`Day Care`],
    ['inpatient', _`Inpatient`],
    ['other', _`Other`],
    ['outpatient', _`Outpatient`],
    ['pre-admission', _`Pre-Admission`],
    ['telephone', _`Telephone`],
    ['triage', _`Triage`],
    ['waiting-list', _`Waiting List`],
    ['ward-attendance', _`Ward Attendance`]
];

function createOptionList(item, idx) {
    return (<option key={`${item[0]}${idx}`} value={item[0]}>{item[1]}</option>);
}

// Create the selector options for status codes.
const statusOptions = appointmentStatuses.map(createOptionList);
const approvedPIFUOptions = approvedPIFUAppointmentStatuses.map(createOptionList);
const rejectedPIFUOptions = rejectedPIFUAppointmentStatuses.map(createOptionList);

// Create the selector options for appointment types.
const typeOptions = appointmentTypes.map(createOptionList); 

/**
 * Represents a form for creating or editing an appointment.
 * Note that this cannot currently be used to edit an existing appointment with a relative date.
 * This is because relative dates only exist in bundle appointments, while this form can only
 *  edit actual appointment compositions.
 *
 * Props (data):
 * - uuid = Required. UUID of the existing appointment to be edited. Set to 'create' to create a new appointment instead. or create-spa for alternative view
 * - dateStyle = Optional. Set to 'relative' to enable relative date input. Otherwise, absolute date input is used.
 * - disablePathway = Optional. If true, pathway selection will not be provided. Defaults to false.
 *
 * Props (functions):
 * - onSuccess = Optional. Called after successful submission.
 * - onCancel = Optional. Called if the cancel button is clicked.
 */
export default class AppointmentForm extends BaseComponent {
    constructor (props) {
        super(props);

        if (this.props.dateStyle === 'relative') {
            if (this.props.uuid && this.props.uuid != 'create') {
                console.warn('This form cannot currently be used to edit existing appointments with relative dates.');
            }
        }

        this.state = {
            // Indicates if the form is currently loading.
            // It needs to load a list of referrals.
            // In future, it may need to load a lookup table of clinic codes.
            loading: true,

            // Indicates if an error occurred during loading.
            loadingError: false,

            // Indicates if the form data is currently being submitted.
            saving: false,

            // Indicates if the form was successfully submitted.
            // (False does not necessarily indicate an error.)
            saved: false,

            // Indicates if an error occurred while submitting the form.
            savingError: false,

            // The content of the appointment composition being created/edited.
            content: {},

            // A counter used to force the form to reload when data changes.
            formKey: 0,

            // Default team-preferences properties to use in form
            teamPreferences: teamPreferencesService.defaultProperties
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.getStatusOptions = this.getStatusOptions.bind(this);
    }

    /**
     * Perform initial setup of the component when it first mounts.
     */
    componentDidMount () {
        this.$setState({
            loading: true,
            loadingError: false
        }).
            then(() => {
                return this.loadAppointment(this.props.uuid, this.props.folderId);
            }).
            then((content) => {
                return this.$setState({
                    loading: false,
                    loadingError: false,
                    content
                });
            }).
            then(() => {
                this.reloadForm();
            }).
            catch((err) => {
                console.warn(err);
                this.$setState({
                    loading: false,
                    loadingError: true
                }).
                    then(() => {
                        AlertBoxes.scrollToFirstAlertBox();
                    });
            });
        teamPreferencesService.getFirst({})
            .then((teamPreferences) => {
                this.setState({ teamPreferences });
            });
    }

    handleLoad(uuid, folderId) {
        return appointmentsService.getByUuid({ uuid, folderId });
    }

    /**
     * Loads the existing data for the appointment being edited, if applicable.
     * Returns a promise which resolves with the appointment composition content if successful.
     * This will provide default content if a new appointment is being created instead.
     * The promise is rejected if an error occurrs, e.g. the appointment cannot be found.
     * Note that this does not modify state at all.
     * @returns {Promise}
     */
    loadAppointment (uuid, folderId) {
        if (!this.props.uuid || this.props.uuid == '' || this.props.uuid == 'create') {
            // We're creating a new appointment so there's nothing to do.
            return Promise.resolve({});
        }

        return this.handleLoad(uuid, folderId)
            .then((response) => {
                const content = lodash.get(response, 'message.content', false);
                if (!content) {
                    throw new Error('Server response did not have expected structure.');
                }
                return content;
            });
    }

    onFormChange(id, rawValue, effectiveValue, valid){
        if (rawValue === undefined && effectiveValue === undefined && valid === undefined){
            return;
        }
        switch (id){
        case 'appt-transport-required':
            this.setState({
                content: lodash.set(this.state.content,'transport.required',effectiveValue)
            });
            return;
        case 'appt-interpreter-required':
            this.setState({
                content: lodash.set(this.state.content,'interpreter.required',effectiveValue)
            });
            return;
        case 'appt-transport-details':
            this.setState({
                content: lodash.set(this.state.content,'transport.details',effectiveValue)
            });
            return;
        case 'appt-interpreter-details':
            this.setState({
                content: lodash.set(this.state.content,'interpreter.details',effectiveValue)
            });
            return;
        }
    }

    getInitialDataForDateInput(content) {

        let unknownDate = false;
        if (content.unknown_date !== undefined) {
            unknownDate = content.unknown_date;
        }

        let absoluteDate;
        if (content.date) {
            absoluteDate = new Date(content.date * 1000);
        } else {
            absoluteDate = new Date();
            // No date was specified so it must be unknown.
            // This is important information to infer because otherwise
            //  editing the user might not realise that a date has been
            //  implicitly selected on the form when editing an available-to-book
            //  appointment.
            unknownDate = true;
        }

        if (!this._dateInputValue ||
            this._dateInputValue.value.toISOString().split('T')[0] !== absoluteDate.toISOString().split('T')[0] ||
            this._dateInputValue.unknown !== unknownDate ||
            this._dateInputValue.aprox !== content.approximate_date
        ) {
            this._dateInputValue = { value: absoluteDate, unknown: unknownDate, approx: content.approximate_date };
        }

        return this._dateInputValue;
    }
    /**
     * Construct the appropriate component for entering/selecting the appointment date/time.
     * This is an absolute date/time for creating appointments directly, or relative for
     *  putting them in bundles.
     */
    renderAppointmentDateInput(){

        const {
            dateStyle
        } = this.props;

        const {
            content
        } = this.state;

        const isDisabled = this.checkReadOnly();

        // TODO: remove relative dates when appointments are no longer allowed in bundles.
        if (dateStyle === 'relative') {
            // The date is relative, which means the appointment is being put in a bundle.
            // Currently, the system doesn't let the user edit an existing bundle appointment.
            // As such, loading it here should never be necessary. It's included anyway,
            //  just in case.

            const contentDate = content.date || {};
            const relativeDate = {
                years: contentDate.years || 0,
                months: contentDate.months || 0,
                days: contentDate.days || 0,
                hours: contentDate.hours || 0,
                minutes: contentDate.minutes || 0
            };

            return  (
                <RelativeDateInput label={_`Appointment Relative Date and Time:`}
                    id="appt-date-relative"
                    disabled={isDisabled}
                    initialValue={relativeDate}
                    showTime={true}
                    hideValidationSuccess={true}
                />
            );
        }

        // The date is absolute.
        // It needs to be encoded as an object containing a Date, along with
        //  the unknown and approx check values.

        // Note that Appointment compositions contain a Unix timestamp (seconds).
        // This needs to be converted to milliseconds for JavaScript.

        const date = this.getInitialDataForDateInput(content);
        return (
            <DateInputField
                label={_`Appointment Date and Time:`}
                id='appt-date-absolute'
                disabled={isDisabled}
                initialValue={date}
                showTime={true}
                hideValidationSuccess={true}
            />
        );
    }

    async handleSubmit(content, uuid) {
        const folderId = this.props.folderId;

        if (!uuid || uuid === 'create') {
            await appointmentsService.create({ content, folderId });
        } else {
            await appointmentsService.update({ uuid, content, folderId });
        }

        if(typeof this.props.onSuccess === 'function') {
            this.props.onSuccess();
        }
    }

    isCreateMode () {
        return !this.props.uuid
            || this.props.uuid == 'create';
    }

    checkReadOnly () {
        const { content } = this.state;
        if (this.isPifuAppointment(content)) {
            return false;
        }
        return this.state.teamPreferences.appointments_read_only
            && !this.isCreateMode();
    }

    handleDisplayedTitle () {
        if (this.checkReadOnly()) {
            return _`View Appointment`;
        }
        return this.isCreateMode() ? _`Create Appointment` : _`Update Appointment`;
    }

    /**
     * Event handler: The form has been submitted.
     * The parameter will contain a map of form values.
     */
    onSubmit (data) {
        if(this.checkReadOnly()) {
            browserHistory.push(this.props.backURL);
        }

        this.$setState({
            saving: true,
            saved: false,
            savingError: false
        }).
            then(() => {

                // Start with any existing content which we've loaded.
                // Apply form values on top of it. This ensures we don't
                //  destroy any additional data which this form doesn't handle,
                //  e.g. HL7 information.
                const content = lodash.cloneDeep(this.state.content || {});
                if(!content['additional_details']) {
                    delete content['additional_details'];
                }

                const stripSpaces = (str) => {
                    if (str == undefined || str == null || str == '') {
                        return undefined;
                    }
                    return str.replace(/\s/g, '');
                };

                content.status = data.get('appt-status') || 'booked';
                content.location = data.get('appt-location') || '';
                content.details = data.get('appt-details') || '';
                content.type = data.get('appt-type') || '';
                content.ubrn = stripSpaces(data.get('appt-ubrn')) || undefined;

                const readableTitle = lodash.trim(data.get('appt-timeline-title')) || undefined;

                const isTransportRequired = !!data.get('appt-transport-required');
                const isInterpreterRequired = !!data.get('appt-interpreter-required');

                lodash.set(content, 'transport.required', isTransportRequired);
                lodash.set(content, 'transport.arranged', !!data.get('appt-transport-arranged'));
                lodash.set(content, 'interpreter.required', isInterpreterRequired);
                lodash.set(content, 'interpreter.arranged', !!data.get('appt-interpreter-arranged'));

                const newState = {};

                if (!isInterpreterRequired){
                    lodash.set(newState,'interpreter.details',undefined);
                    lodash.set(content,'interpreter.details',undefined);
                }
                if (!isTransportRequired){
                    lodash.set(newState,'transport.details',undefined);
                    lodash.set(content,'transport.details',undefined);
                }

                const transportDetails = (lodash.get(content, 'transport.details') || '').trim() || undefined;
                const interpreterDetails = (lodash.get(content, 'interpreter.details') || '').trim() || undefined;

                lodash.set(content, 'transport.details', transportDetails);
                lodash.set(content, 'interpreter.details', interpreterDetails);

                this.setState(newState);

                if (readableTitle){
                    lodash.set(content, 'timeline.title', readableTitle);
                }

                // The date and time require special handling.
                const apptDateAbsolute = data.get('appt-date-absolute');
                const apptDateRelative = data.get('appt-date-relative');
                const isRelative = (this.props.dateStyle == 'relative');

                // TODO: remove relative dates when appointments are no longer allowed in bundles.

                if (isRelative) {
                // We're storing a relative date.
                // This currently means we're creating a pseudo-appointment to be stored
                //  in a bundle. The format of the relative is an object with properties:
                //   years, months, days, hours, minutes.
                // This violates the appointment archetype schema. We rely on it being
                //  converted to an absolute date when the bundle is unpacked.
                // This insanity should be fixed at some point. It should be possible to
                //  let the appointment archetype store a relative date.

                    if (apptDateRelative == undefined) {
                        console.warn('Missing relative date in form data.');
                    }

                    // The relative date picker gives us the appropriate format automatically.
                    content.date = {
                        years: lodash.get(apptDateRelative, 'years', 0),
                        months: lodash.get(apptDateRelative, 'months', 0),
                        days: lodash.get(apptDateRelative, 'days', 0),
                        hours: lodash.get(apptDateRelative, 'hours', 0),
                        minutes: lodash.get(apptDateRelative, 'minutes', 0)
                    };
                    // Implicitly set these checkbox values since we don't have this info in the form.
                    content.approximate_date = false;
                    content.unknown_date = false;

                } else {
                // We're storing an absolute date.
                // It needs to be expressed as a Unix timestamp (seconds).
                // However, it needs to be extracted from the object provided by the form element.

                    if (!apptDateAbsolute) {
                        console.warn('Missing absolute date in form data.');
                        delete content.date;
                        content.unknown_date = true;
                        content.approximate_date = false;
                    }

                    if (apptDateAbsolute) {
                        const date = apptDateAbsolute.value;
                        const unknownDate = apptDateAbsolute.unknown;

                        content.unknown_date = unknownDate;

                        if (date && date.isValid()) {
                            content.date = date.unix();
                        } else {
                            delete content.date;
                        }
                    }
                }

                // Pass the appointment data to the parent.
                return this.handleSubmit(content, this.props.uuid);
            }).
            then(() => {
                return this.$setState({
                    saving: false,
                    saved: true,
                    savingError: false
                });
            }).
            catch((err) => {
                console.warn(err);
                return this.$setState({
                    saving: false,
                    saved: false,
                    savingError: true
                });
            }).
            then(() => {
                AlertBoxes.scrollToFirstAlertBox();
            });
    }

    /**
     * Force the form to reload.
     * This is useful if the data the form is built on has been reloaded,
     *  and the initialValue properties might have been updated.
     */
    reloadForm () {
        this.setState((prevState) => {
            return {
                formKey: prevState.formKey + 1
            };
        });
    }

    isPifuAppointment(content) {
        if (content.status === 'proposed' && content.proposed_participant) {
            return true;
        }

        return false;
    }

    getStatusOptions() {
        const { content } = this.state;

        if (!content) {
            return statusOptions;
        }

        if (this.isPifuAppointment(content)) {
            const pifuStatus = content.proposed_participant.status;

            if (pifuStatus === 'accepted') {
                return approvedPIFUOptions;
            }

            return rejectedPIFUOptions;
        }

        return statusOptions;
    }

    /**
     * Render the contents of this page.
     */
    render () {
        let hiddenFields = [];
        if (this.state.teamPreferences && this.state.teamPreferences.portal.hide_appointment_fields) {
            hiddenFields = this.state.teamPreferences.portal.hide_appointment_fields;
        }
        const isCreateMode = this.isCreateMode();
        const isReadOnly = this.checkReadOnly();
        // TODO: Add a drop-down box for location, which specifies code and text in one go.
        //       (Include a "custom" option for entering these details manually.)

        // TODO: Add referral ID drop-down box back in.

        return (
            <div className='appointment'>
                <AlertBoxes.Loading show={this.state.loading} />
                <AlertBoxes.LoadingError show={this.state.loadingError && !this.state.loading} />
                <AlertBoxes.SavingError show={this.state.savingError && !this.state.saving} />
                <AlertBoxes.Saved show={this.state.saved && !this.state.savingError && !this.state.saving} />
                <AlertBoxes.Custom boxStyle={'light'} show={!isCreateMode && !this.state.loading && !this.state.saving} >
                    <p className='appointment-edit-warning'>
                        {isReadOnly ? _`Editing appointment details has been disabled for your team.` : _`Warning: editing details of appointments here will not alter appointments in the hospital's booking system`}
                    </p>
                </AlertBoxes.Custom>


                <FormPanel
                    title={this.handleDisplayedTitle()}
                    intro=''
                    id='appointment-form'
                    onSubmit={this.onSubmit}
                    onChange={this.onFormChange}
                    onCancel={this.props.onCancel}
                    submitLabel={isReadOnly ? _`Close` : _`Save`}
                    cancelLabel={_`Cancel`}
                    busy={this.state.loading || this.state.saving || this.state.loadingError}
                    key={this.state.formKey}
                >
                    {this.state.content.proposed_participant &&
                        this.state.content.proposed_participant.notes !== undefined
                            && (
                                <div className="form-group">
                                    <label
                                        className="control-label"
                                    >
                                        Response to patient
                                    </label>
                                    <textarea
                                        className="form-control"
                                        value={this.state.content.proposed_participant.notes}
                                        contentEditable={false}
                                        disabled
                                    />
                                </div>
                            )
                    }
                    {this.state.content.proposed_participant &&
                        this.state.content.proposed_participant.booking_notes !== undefined
                            && (
                                <div className="form-group">
                                    <label
                                        className="control-label"
                                    >
                                        Notes to booking staff
                                    </label>
                                    <textarea
                                        className="form-control"
                                        value={this.state.content.proposed_participant.booking_notes}
                                        contentEditable={false}
                                        disabled
                                    />
                                </div>
                            )
                    }
                    {!hiddenFields.includes('status') &&
                    (
                        <SelectorInput
                            label={_`Status:`}
                            id='appt-status'
                            initialValue={this.state.content.status || 'booked'}
                            hideValidationSuccess={true}
                            helpText={_`Select the status of this appointment; e.g. "booked" or "cancelled".`}
                            disabled={isReadOnly}

                        >
                            {this.getStatusOptions()}
                        </SelectorInput>
                    )}
                    {!hiddenFields.includes('ubrn') &&
                    (
                        <TextInput
                            label={_`Appointment UBRN:`}
                            id='appt-ubrn'
                            initialValue={this.state.content.ubrn}
                            placeholder={_`e.g. 1234 1234 1234`}
                            minLength={12}
                            maxLength={12}
                            filterValue={filterSpaces}
                            hideValidationSuccess={true}
                            required={this.props.uuid === 'create' || this.state.content.ubrn}
                            helpText={_`Enter the Unique Booking Reference Number of this appointment.`}
                            readOnly={isReadOnly} />
                    )}
                    {!hiddenFields.includes('location') &&
                    (
                        <TextInput
                            label={_`Location:`}
                            id='appt-location'
                            initialValue={this.state.content.location}
                            placeholder={_`e.g. Northern General`}
                            hideValidationSuccess={true}
                            helpText={_`A description of where this appointment will be.`}
                            readOnly={isReadOnly}
                        />
                    )}
                    {!hiddenFields.includes('timeline-title') &&
                    (
                        <TextInput
                            label={_`Title for timeline:`}
                            id='appt-timeline-title'
                            initialValue={lodash.get(this.state.content, 'timeline.title', false)}
                            placeholder={_`e.g. Rheumatology Outpatient Appointment`}
                            hideValidationSuccess={true}
                            helpText={_`A user focused, helpful title which directs the user to distinguish between appointments.`}
                            readOnly={isReadOnly}
                        />
                    )}
                    {!hiddenFields.includes('details') &&
                    (
                        <TextInput
                            label={_`Details:`}
                            id='appt-details'
                            initialValue={this.state.content.details}
                            placeholder={_`e.g. Your Checkup`}
                            hideValidationSuccess={true}
                            helpText={_`A description of the appointment.`}
                            readOnly={isReadOnly}
                        />
                    )}
                    {!hiddenFields.includes('additional-details') &&
                    (
                        <TextInput
                            label='Additional details:'
                            readOnly={true}
                            initialValue={this.state.content.additional_details}
                            hideValidationSuccess={true}
                            helpText='This information is displayed to the patient while the appointment is available to book. It can be used to notify them that the appointment booking may not appear on their timeline.'
                        />
                    )}
                    {this.renderAppointmentDateInput()}
                    {!hiddenFields.includes('type') &&
                    (
                        <SelectorInput
                            label={_`Type:`}
                            id='appt-type'
                            initialValue={this.state.content.type}
                            hideValidationSuccess={true}
                            helpText={_`Select the type of appointment.`}
                            readOnly={isReadOnly}
                        >
                            {typeOptions}
                        </SelectorInput>
                    )}
                    {!hiddenFields.includes('transport') &&
                    (
                        <CheckboxInput
                            label='Transport required'
                            id='appt-transport-required'
                            initialValue={lodash.get(this.state.content, 'transport.required', false)}
                            helpText='Tick this box if the patient will need transport arranged for their appointment.'
                            disabled={isReadOnly}
                        />
                    )}

                    {
                        lodash.get(this.state.content, 'transport.required', false) && !hiddenFields.includes('transport') &&
                        (
                            <TextInput
                                id='appt-transport-details'
                                label='Transport requirements details:'
                                initialValue={lodash.get(this.state.content, 'transport.details', '')}
                                hideValidationSuccess={true}
                                helpText='Additional details about transport requirements'
                                readOnly={isReadOnly}
                            />
                        )}
                    {!hiddenFields.includes('transport') &&
                    (
                        <CheckboxInput
                            label='Transport arranged'
                            id='appt-transport-arranged'
                            initialValue={lodash.get(this.state.content, 'transport.arranged', false)}
                            helpText='Tick this box if transport has been arranged for the patient.'
                            disabled={isReadOnly}
                        />
                    )}
                    {!hiddenFields.includes('interpreter') &&
                    (
                        <CheckboxInput
                            label='Interpreter required'
                            id='appt-interpreter-required'
                            initialValue={lodash.get(this.state.content, 'interpreter.required', false)}
                            helpText='Tick this box if the patient will need an interpreter arranged for their appointment.'
                            disabled={isReadOnly}
                        />
                    )}

                    {
                        lodash.get(this.state.content, 'interpreter.required', false) && !hiddenFields.includes('interpreter') &&
                        (
                            <TextInput
                                id='appt-interpreter-details'
                                label='Interpreter requirements details:'
                                initialValue={lodash.get(this.state.content, 'interpreter.details', '')}
                                hideValidationSuccess={true}
                                helpText='Additional details about interpreter requirements'
                                readOnly={isReadOnly}
                            />
                        )}
                    {!hiddenFields.includes('interpreter') &&
                    (
                        <CheckboxInput
                            label='Interpreter arranged'
                            id='appt-interpreter-arranged'
                            initialValue={lodash.get(this.state.content, 'interpreter.arranged', false)}
                            helpText='Tick this box if an interpreter has been arranged for the patient.'
                            disabled={isReadOnly}
                        />
                    )}
                </FormPanel>
            </div>
        );
    }
}

AppointmentForm.propTypes = {
    uuid: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    dateStyle: PropTypes.string
};
