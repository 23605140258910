import { useMemo } from 'react';
import { useI18N } from 'common/i18n/useI18N';
import { convertToOutputTzDate, formatDate } from 'common/datetime/DateTime';
import { UserCommunication } from 'models/UserCommunication';
import { convertToTimestamp } from 'common/datetime/convertToDate';

export const usePreparedCommunications = (communications: UserCommunication[]) => {
    const _ = useI18N();

    return useMemo(() => communications.map(communication => {
        const date = communication.date ? convertToTimestamp(communication.date) : null;
        const dateFilter = communication.date ? formatDate(convertToOutputTzDate(communication.date), 'nhs_date_with_time') : null;

        return {
            key: communication.uuid,
            dateSort: date,
            dateFilter: dateFilter,
            ...communication,
            _source: communication
        };
    }), [communications]);
};
