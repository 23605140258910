import React from 'react';
import PropTypes from 'prop-types'

import View from './view';
import BaseComponent from 'components/BaseComponent';
import { messagesService } from 'services/messages.service';

/**
 * User Message Index Component
 */
export default class UserMessageShowComponent extends BaseComponent {
    constructor (props) {
        super(props);

        this.state = {
            error: false,
            loading: true,
            messageId: this.props.params.messageId || 0,
            clinicalView: this.props.clinicalView || false,
            message: undefined,
            backRoute: this.props.backRoute || `${this.getFolderPath()}/messages`,
            createRoute: this.props.createRoute || `${this.getFolderPath()}/messages/create`
        };

        this.bindFunctions([
            'onResults',
            'loadComponent'
        ]);

        this.View = View;
    }

    componentWillReceiveProps (newProps) {
        this.setState({
            error: false,
            loading: true,
            messageId: newProps.params.messageId || 0,
            clinicalView: newProps.clinicalView || false,
            message: undefined
        }, () => {
            this.loadComponent();
            this.server.renderFromServerState();
        });
    }

    loadComponent () {
        this.setState({
            error: false,
            loading: true,
            message: undefined
        });

        messagesService.getByUuid({uuid: this.state.messageId})
            .then(this.onResults);
    }

    componentWillMount () {
        this.loadComponent();
    }

    onResults (response, status) {
        
        if (status >= 300) {
            this.setState({
                error: true
            });
        }
        
        if (!response.hasOwnProperty('message')) {
            return null;
        }


        this.setState({
            error: false,
            loading: false,
            message: response.message
        });
    }
}

UserMessageShowComponent.propTypes = {
    backRoute: PropTypes.string
};