import _ from 'services/i18n';
import DataStore from 'services/data-store';

let team;
DataStore.getListener('me.currentRole', (value) => {
    team = value;
});

function getPatientLinks(folderId, additional) {
    let patientLink = '/clinical_portal/folder/' + folderId;
    // TODO: Automatically sort the links alphabetically
    return [].concat(additional || [],[
        {route: patientLink + "/appointments", label: _`Appointments`, documentType: 'appointment'},
        {route: patientLink + "/how-to-help-plan", label: _`How to Help Plan`, documentType: 'coopSafetyPlan'},
        {route: patientLink + "/medication-logs", label: _`Medication Logs`, documentType: 'medicationLog'},
        {route: patientLink + "/medication-routines", label: _`Medication Routines`, documentType: 'medicationRoutine'},
        {route: patientLink + "/messages", label: _`Messages`, documentType: 'message'},
        {route: patientLink + "/notes", label: _`Notes`, documentType: 'coopNote', hideForServices: ["MSK", "CAMHS"]},
        {route: patientLink + "/physiological-measurement-logs", label: _`Physiological Measurement Logs`, documentType: 'physiologicalMeasurementLog'},
        {route: patientLink + "/prescribed-measurements", label: _`Prescribed Measurements`, documentType: 'prescribedMeasurement'},
        {route: patientLink + "/prescriptions", label: _`Prescriptions`, documentType: 'prescription'},
        {route: patientLink + "/questionnaires", label: _`Questionnaires`, documentType: 'coopQuestionnaire'},
        {route: patientLink + "/referrals", label: _`Referrals`, documentType: 'referral'},
        {route: patientLink + "/resources", label: _`Resources`, documentType: 'coopResource'},
        {route: patientLink, label: _`Timeline`, show: true, hideForServices: ["MEDS", "CAMHS", "LMSU"]}
    ]);
}

export function checkAllowed (item) {
    let consents = team.consents, service = team.serviceName, show = false;

    if (typeof(item) == 'undefined') {
        return false;
    }

    if (item.hasOwnProperty('show')) {
        show = true
    }
    if (item.hasOwnProperty('documentType')) {
        if (consents.hasOwnProperty(item.documentType)) {
            if (consents[item.documentType].read == 1) {
                show = true;
            } else {
                return false;
            }
        }
    }

    if (item.hasOwnProperty('hideForServices')) {
        if (item.hideForServices.find((item) => item == service)) {
            return false;
        }
    }

    return show;
}

export default getPatientLinks;
