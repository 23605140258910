import { Dot, Line, LineGraph } from 'common/ui/line-graph/LineGraph';
import { RoleType } from 'features/patient/patient-dashboard/RoleType';
import React, { FC, useMemo } from 'react';
import { browserHistory } from 'react-router';
import _ from 'services/i18n';
import { ComponentLoadingWrapper } from 'features/patient/patient-dashboard/component-loading-wrapper/ComponentLoadingWrapper';
import classnames from 'classnames';
import './DashboardQuestionnaireGraph.scss';

interface QuestionnaireGraphViewProps {
    loading: boolean;
    questionnaireName: string;
    folderId: number;
    lines: Line[];
    boundaryValues?: number[];
    max?: number;
    min?: number;
    role?: RoleType;
}
export const QuestionnaireGraphView: FC<QuestionnaireGraphViewProps> = (props) => {
    const openQuestionnaire = (targetDot: Dot) => {
        browserHistory.push(`/clinical_portal/folder/${props.folderId}/patient/questionnaires/${targetDot.uuid}?questionnaire`);
    };

    const axisYParams = useMemo(() => {
        return {
            max: props.max,
            min: props.min,
        };
    }, [props.max, props.min]);

    return (
        <div className={classnames(['dashboard-graph-component', `widget-for--${props.role.toLowerCase()}`])}>
            <header>
                <h3>{_`Questionnaire: `} <span>{props.questionnaireName}</span></h3>
            </header>
            <ComponentLoadingWrapper loading={props.loading} dataExists={Boolean(props.lines?.length)}>
                <LineGraph
                    lines={props.lines}
                    legendTitle={false}
                    className={'dashboard-graph'}
                    axisYParams={axisYParams}
                    boundaryValues={props.boundaryValues}
                    dotClickCallback={openQuestionnaire}
                />
            </ComponentLoadingWrapper>
        </div>

    );
};

QuestionnaireGraphView.defaultProps = {
    role: RoleType.Patient,
};
