import { CompositionService } from 'services/composition.service';

class DeviceDispenseService extends CompositionService {

    get archetypeName(): string {
        return 'device-dispense';
    }

}

export const deviceDispenseService = new DeviceDispenseService();
