import React from 'react';
import { BulkOperationPreviewBuilder } from './BulkOperationPreviewBuilder';
import { CostEstimateValue, PendingCostEstimateValue, CostEstimateStatusType, UnknownCostEstimateValue } from './CostEstimateValue';
import { useStateProviderCallback } from 'components/dataSource/StateLinking';


export function BulkOperationCostEstimateSummary(props: { previewComponent: BulkOperationPreviewBuilder<unknown> }): JSX.Element
{
    const costEstimates = useStateProviderCallback<CostEstimateValue[], unknown>(props.previewComponent, props.previewComponent.getCostEstimates);

    const failed: CostEstimateValue[] = costEstimates.filter((value: CostEstimateValue) => (value.status == CostEstimateStatusType.FAILED));
    if (failed.length > 0) {
        return <b>{failed[0].description}</b>;
    }

    const pending: CostEstimateValue[] = costEstimates.filter((value: CostEstimateValue) => (value.status == CostEstimateStatusType.PENDING));
    if (pending.length > 0) {
        return <b>{PendingCostEstimateValue.description}</b>;
    }

    const unknown: CostEstimateValue[] = costEstimates.filter((value: CostEstimateValue) => (value.status == CostEstimateStatusType.UNKNOWN));
    if (unknown.length > 0) {
        return <b>{UnknownCostEstimateValue.description}</b>;
    }

    const complete: CostEstimateValue[] = costEstimates.filter((value: CostEstimateValue) => (value.status == CostEstimateStatusType.COMPLETE));
    if (complete.length > 0) {
        const costTotal = complete
            .map((value: CostEstimateValue) => (value.value))
            .reduce((previousValue: number, currentValue: number) => {
                return previousValue + currentValue;
            });
        return <b>{costTotal.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2 })}</b>;
    }

    return <b>{costEstimates[0].description}</b>;
}
