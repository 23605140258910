import React, { FC } from 'react';
// components
import FolderBreadcrumbItem from './FolderBreadcrumbItem';
// interfaces
import { FolderBreadcrumbsProps } from './FolderBreadcrumbs.interface';
// styles
import './FolderBreadcrumbs.scss';

const FolderBreadcrumbs: FC<FolderBreadcrumbsProps> = ({
    folderList,
    handleLocationChange,
}) => {
    return (
        <div className="folder-breadcrumbs">
            {folderList.map((folder, i) => {
                const isLast = i === folderList.length - 1;

                return (
                    <FolderBreadcrumbItem
                        key={folder.id}
                        folder={folder}
                        isCurrent={isLast}
                        handleLocationChange={handleLocationChange}
                    />
                );
            })}
        </div>
    );
};

export default FolderBreadcrumbs;
