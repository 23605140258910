import React, { useState } from 'react';

import {
    SendSimpleQuestionnaireState,
    getTriggerEngineContent,
    validate,
    normalise
} from './validator';

import { SimpleQuestionnaireCreateForm } from './form';
import _ from 'services/i18n';
import { terService } from 'services/ter.service';
import { browserHistory } from 'react-router';

const initialFormState: SendSimpleQuestionnaireState = {
    location: '',
    id: '',
    lozengeTitle: '',
    lozengeContent: ''
};

export const PatientSimpleQuestionnaireCreateForm = (props: { folderId: number, onCompletionRedirect: string }) => {
    const [currentErrors, onErrors] = useState<string[]>([]);
    const [ currentFormState, onFormStateChange ] = useState(initialFormState);
    const onSubmit = () => {
        if (validate(currentFormState, onErrors)) {
            const terContent = getTriggerEngineContent(normalise(currentFormState));
            terService.createTer({
                action: 'assignSimpleQuestionnaire',
                folderId: props.folderId,
                data: terContent
            }).then(() => {
                browserHistory.push(`${props.onCompletionRedirect}`);
            })
        }
    }

    return (
        <>
            <SimpleQuestionnaireCreateForm
                form={currentFormState}
                onChange={(c) => onFormStateChange({ ...currentFormState, ...c })}
                errors={currentErrors}
            />
            <button onClick={onSubmit} className='btn btn-primary'>
                {_`Allocate Questionnaire`}
            </button>
        </>
    );
}