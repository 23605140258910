import React from 'react';
import { GridRow, GridCell } from 'common/ui/grid';

import Ubrn from 'ui/ubrn';
import HL7Name from 'ui/hl7/name';
import { DateTime } from 'common/datetime/DateTime';
import { useReferralPathways } from 'features/patients/pathways/useReferralPathways';
import { Pathways } from 'features/patients/pathways/Pathways';


export const ReferralGridRow = ({
    referralListItem,
    isShowDischargeButton = false,
    teamPreferences
}) => {
    const {
        uuid,
        ubrn,
        status,
        displayStatus,
        triageDecisionCell,
        referringServiceName,
        referredToServiceName,
        date,
        sendLetterButton,
        dischargeButton,
    } = referralListItem;

    const {
        pathways,
        allPathways,
        handleAddPathway,
        handleRemovePathway,
    } = useReferralPathways(uuid);

    return (
        <GridRow>
            <GridCell>
                <Pathways
                    pathways={pathways}
                    allPathways={allPathways}
                    onAdd={handleAddPathway}
                    onRemove={handleRemovePathway}
                    addIsActive={status === 'open'}
                />
            </GridCell>
            <GridCell><Ubrn className="no-wrap ubrn" empty='-' value={ubrn}/></GridCell>
            <GridCell>{displayStatus}</GridCell>
            <GridCell>{triageDecisionCell}</GridCell>
            <GridCell><HL7Name name={referringServiceName} empty='-' /></GridCell>
            <GridCell><HL7Name name={referredToServiceName} empty='-' /></GridCell>
            <GridCell><DateTime empty="-">{date}</DateTime></GridCell>
            <GridCell>{sendLetterButton}</GridCell>
            {isShowDischargeButton && teamPreferences && !teamPreferences.hide_discharge_button && <GridCell>{dischargeButton}</GridCell>}
        </GridRow>
    );
};
