import React, { ChangeEvent, FC, useState } from 'react';
import parse from 'csv-parse/lib/sync';
// components
import { LoadingError } from 'common/ui/alert-boxes';
// utils
import {
    useFileUploadNormaliser,
    useFileUploadValidator,
} from './AdvancedSearchCriterionCSVColumnImport.utils';
// interfaces
import { AdvancedSearchCriterionCSVColumnImportProps } from './AdvancedSearchCriterionCSVColumnImport.interface';
import { useValidator } from 'common/ui/validation/NestedComponentValidator';

export const AdvancedSearchCriterionCSVColumnImport: FC<AdvancedSearchCriterionCSVColumnImportProps> = ({
    documentType,
    path,
    onChange,
    initialValues,
    validator
}) => {
    const isValueValid: (value: string) => boolean = useFileUploadValidator(
        documentType,
        path,
    );
    const normaliseValue: (value: string) => string = useFileUploadNormaliser(documentType, path);

    const getInvalidValues = (values: string[]) => values ? values.filter(value => !isValueValid(value)) : [];

    const [inputValues, setInputValues] = useState<string[]>(initialValues ?? []);
    const [invalidValues, setInvalidValues] = useState<string[]>(getInvalidValues(initialValues));

    useValidator(validator, () => !invalidValues || invalidValues.length == 0);

    const updateValues = (values: string[]) => {
        const valuesNormalised = values.map(normaliseValue);
        setInvalidValues(getInvalidValues(valuesNormalised));
        setInputValues(valuesNormalised);
        onChange(valuesNormalised);
    };

    const showError = invalidValues.length > 0;

    const handleCSV = (e: ChangeEvent<HTMLInputElement>): void => {
        const csvFile = e.target.files[0];

        if (!csvFile) {
            return;
        }

        const fileReader: FileReader = new FileReader();

        fileReader.readAsText(csvFile);
        fileReader.onload = (e) => {
            const values: string[] = parse(e.target.result, {
                columns: true,
                // eslint-disable-next-line @typescript-eslint/camelcase
                skip_empty_lines: true,
                trim: true,
            });

            applyCSVResult(values);
        };
    };

    const applyCSVResult = (values: string[]): void => {
        const firstColumnData: string[] = values.map(
            (value) => value[Object.keys(value)[0]],
        );

        updateValues(firstColumnData);
    };

    const handleEdit = (value: string): void => {
        const valueTrimmed = value?.trim();

        if (!valueTrimmed) {
            updateValues([]);
        } else {
            const values: string[] = valueTrimmed.split(',');
            updateValues(values);
        }
    };

    return (
        <div className="form-group">
            <input
                type="file"
                accept=".csv"
                className={'form-control'}
                onChange={handleCSV}
            />
            <textarea
                className={'form-control'}
                onChange={(e) => handleEdit(e.target.value)}
                value={inputValues === null ? '' : inputValues}
            />
            {showError && (
                <LoadingError>
                    <p>The following entries were invalid:</p>
                    <ul>
                        {invalidValues.map(
                            (value: string, index: number) => {
                                return <li key={index}>{value}</li>;
                            },
                        )}
                    </ul>
                </LoadingError>
            )}
        </div>
    );
};
