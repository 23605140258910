import React, { Fragment, useEffect, useState } from 'react';
import _ from 'services/i18n';
import ErrorView from 'components/errorbox';
import { Row, Column, FormGroup } from 'ui';
import { SendSmsState } from '../create/validator';
import { EditorTags } from 'common/ui/editor-tags/EditorTags';
import { SMSTemplate } from "models/smsType";
import { DataOption, SortedDepartmentData } from "features/department/department.interface";
import { useSortedDepartmentLabelableData } from "features/department/hooks/useSortedDepartmentData";
import { useGroupedDepartmentDataOptionList } from "features/department/hooks/useGroupedDepartmentDataOptionList";
import Select from "react-select";
import { CompositionService } from "services/composition.service";

type SmsCreateFormProps = {
    id: string;
    loading?: boolean;
    errors?: string[];
    onChange: (c) => unknown;
    form: SendSmsState;
    onSubmit: (f) => unknown;
    smsTemplateService?: CompositionService;
}


export const SmsCreateForm = (props: SmsCreateFormProps) => {

    const [smsTemplates, onSmsTemplatesChange] = useState<SMSTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<DataOption<SMSTemplate> | null>(null);


    useEffect(() => {
        if (smsTemplates.length >= 1 || !props.smsTemplateService) {
            return;
        }
        props.smsTemplateService
            .list({ folderId: 'team' })
            .then((response) => {
                if (response.message.total >= 1) {
                    const smsTemplates = response.message.results.map(
                        (item: any, index: number) => {
                            return { ...item.content, index };
                        },
                    );
                    onSmsTemplatesChange(smsTemplates);
                }
            });
    });

    const sortedListData: SortedDepartmentData<SMSTemplate> = useSortedDepartmentLabelableData(smsTemplates);

    const [searchOptionList, optionCount] = useGroupedDepartmentDataOptionList(sortedListData, d => d.name);

    const handleTemplateSelect = (template: SMSTemplate | null): void => {
        let data;
        let selectedTemplateOption: DataOption<SMSTemplate> | null;
        if (template) {
            data = {
                message: template.content,
            };
            selectedTemplateOption = {
                label: template.name,
                value: template,
            };
        } else {
            data = {
                message: '',
            };
            selectedTemplateOption = null;
        }

        props.onChange(data);
        setSelectedTemplate(selectedTemplateOption);
    };

    if (props.loading) {
        return (
            <h5>{_`Loading... please wait`}</h5>
        );
    }

    return (
        <Fragment>
            <ErrorView errors={props.errors}/>
            <form noValidate onSubmit={props.onSubmit} id={props.id}>
                <Row>
                    <Column md="12">
                        { props.smsTemplateService && <FormGroup>
                            <div >
                                <Select
                                    isClearable={true}
                                    className="filter-select"
                                    classNamePrefix="filter-select"
                                    placeholder={`Templates (${optionCount})`}
                                    options={searchOptionList}
                                    onChange={(option) => {
                                        handleTemplateSelect(option ? option.value : null);
                                    }}
                                    value={selectedTemplate}
                                />
                            </div>
                        </FormGroup>}
                        <FormGroup>
                            <label htmlFor={`${props.id}-content`}>{_`SMS content`}</label>
                            <EditorTags callback={(value) => props.onChange({ message: `${props.form.message} ${value}` })} />
                            <textarea rows={5} id={`${props.id}-content`} name='content' className='form-control' value={props.form.message}
                                onChange={(e)=>{props.onChange({ message:e.target.value });}} required />
                        </FormGroup>
                    </Column>
                </Row>
            </form>
        </Fragment>
    );
};
