import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { Row, Column, Panel, PanelFooter } from 'ui';
import { Link } from 'react-router';
import DataStore from 'services/data-store';
import { DateTime } from 'common/datetime/DateTime';

let coopAppType;

DataStore.getListener('coop-app-type', (value) => {
    coopAppType = value;
});

/**
 * Extended Page Component for user_portal/dashboard
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            questionnaires: new Map(),
            available: [],
            started: [],
            loading: true
        };
    }

    componentWillMount () {
        let questionnaires = new Map();
        this.$search('coopQuestionnaire', [
            ['content.name','=','Strengths and Difficulties Questionnaire']
        ]).then(({message: {results}}) => {
            let uuids = [];

            results.map((item) => {
                uuids.push(item.content.questionnaire_uuid);
                questionnaires.set(item.content.questionnaire_uuid, item);
            });

            return this.$search('coopQuestionnaireResponse', [
                ['content.status', 'IN', ['Assigned', 'in_progress']],
                ['content.questionnaire_uuid', 'IN', uuids]
            ]);
        }).then(({message: {results}}) => {
            let available = [], started = [];

            results.map((item) => {
                if (item.content.status == 'Assigned') {
                    available.push(item)
                } else {
                    started.push(item)
                }
            });

            this.setState({
                available: available,
                started: started,
                questionnaires: questionnaires,
                loading: false
            });
        });
    }

    pageTitle () {
        return _`Dashboard | COOP`;
    }

    render () {
        if (this.state.loading) {
            return (<PageLoading />);
        }

        if (this.state.available.length == 0 && this.state.started.length == 0) {
            return (
                <PageEmpty />
            );
        }

        return (<Page questionnaires={this.state.questionnaires} available={this.state.available} started={this.state.started} />);
    }
}

const Page = ({available, started, questionnaires}) => {
    return (
        <div className="dashboard-page">
            <PageHeader />
            <QuestionnaireList title="List of available questionnaires not yet started."
                               questionnaires={questionnaires}
                               responses={available} />
            <QuestionnaireList title="Questionnaires you have started"
                               questionnaires={questionnaires}
                               responses={started} />
        </div>
    );
};

const PageHeader = () => {
    return (
        <div className="page-header">
            <div className="header-bar text-right">
                {coopAppType == 'desktop'
                    ?
                    <Link to="/coopweb/logout" className="btn btn-default btn-sm">
                        <i className="glyphicon glyphicon-log-out"/> Log Out
                    </Link>
                    :
                    <a href="http://localhost/return-to-app" className="btn btn-default btn-sm">
                        <i className="glyphicon glyphicon-log-out"/> Return to App
                    </a>
                }
            </div>
            <h1>COOP Dashboard</h1>
        </div>
    );
};
const PageEmpty = () => {
    return (
        <div className="dashboard-page">
            <PageHeader />
            <Row>
                <Column md="12">
                    <div className="text-center">
                        <span className="glyphicon glyphicon-list-alt"></span>
                    </div>
                    <p>It appears that there are no questionnaires assigned to you at present. Please check back later.</p>
                </Column>
            </Row>
        </div>
    );
};

const PageLoading = () => {
    return (
        <div className="dashboard-page">
            <PageHeader />
            <div className="alert alert-info">
                <span className="glyphicon glyphicon-time"></span>{' '}
                <span>Bare with us as we load up your questionnaires...</span>
            </div>
        </div>
    );
};

const QuestionnaireList = ({title, questionnaires, responses}) => {
    let list = responses.map((item, idx) => {
        let questionnaire = questionnaires.get(item.content.questionnaire_uuid);

        return (
            <QuestionnaireRow key={idx} questionnaire={questionnaire} response={item} />
        );
    });

    if (list.length == 0) {
        return <noscript />;
    }

    return (
        <div className="questionnaire-list">
            <h3>{title}</h3>
            {list}
        </div>
    );
};

const QuestionnaireRow = ({questionnaire, response}) => {
    return (
        <Panel title={questionnaire.content.name} type="info">
            <p><strong>Issuer: </strong> {response.content.name_of_issuer} ({response.content.name_of_team})</p>
            <p><strong>Issued On: </strong> <DateTime>{response.created_at}</DateTime></p>
            <PanelFooter>
                <Row>
                    <Column md="12">
                        <div className="text-center">
                            <Link className="btn btn-success" to={"/coopweb/questionnaire/" + response.uuid}>Start Questionnaire</Link>
                        </div>
                    </Column>
                </Row>
            </PanelFooter>
        </Panel>
    );
};

