import { useCallback, useEffect, useRef, useState } from 'react';

export const useOnClickOutside = (setActive: (active: boolean) => any) => {
    const localRef = useRef(null);
    const [nodeIsSet, nodeState] = useState(null);

    const callback = useCallback(({target}) => {
        if (!localRef.current.contains(target)) {
            setActive(false);
        }
    }, []);

    useEffect(() => {
        if(!nodeIsSet) {
            return;
        }
        window.addEventListener('click', callback, {capture: true});
        return () => window.removeEventListener('click', callback, {capture: true});
    }, [nodeIsSet]);


    const setRef = useCallback((node) => {
        if(node !== null) {
            localRef.current = node;
            nodeState(true);
        }
    }, []);

    return setRef
};