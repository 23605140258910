import { OptionsType } from 'react-select';
import { useCallback } from 'react';

type SimpleDropdownSelectOption<V> = {value: V; label: string};

export function getDisplayValueForValueOption<V>(
    optionDisplayList: OptionsType<SimpleDropdownSelectOption<V>>,
    value: V
): SimpleDropdownSelectOption<V> {
    return value ? optionDisplayList.find(option => option.value == value) : null;
}

export function getDisplayValuesForValueOptions<V>(
    optionDisplayList: OptionsType<SimpleDropdownSelectOption<V>>,
    values: V[]
): SimpleDropdownSelectOption<V>[] {
    return values ? values.map(value => getDisplayValueForValueOption(optionDisplayList, value)) : null;
}

export function useStateUpdateFromListSelection<V>(
    doStateUpdate: (newState: V) => void,
    postUpdateCallbackFn?: (newState: V) => void
): (newValue: SimpleDropdownSelectOption<V>) => void {
    return useCallback(
        (newValue: SimpleDropdownSelectOption<V>) => {
            doStateUpdate(newValue.value);
            if (postUpdateCallbackFn) {
                postUpdateCallbackFn(newValue.value);
            }
        },
        [doStateUpdate, postUpdateCallbackFn]
    );
}
