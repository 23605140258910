import React, { Fragment, useEffect, useState } from 'react';
import { NavTab, TabNavigator } from 'ui/tab/navigator';
import { Column, Row } from 'ui';
import BreadCrumbs from 'common/ui/breadcrumbs/BreadCrumbs';
import FilesList from 'common/ui/files-list/FilesList';
import {
    getUserFiles,
    getLiveFiles,
    refreshContentFiles,
    getChangedFiles
} from './bitbucket.service';
import DataStore from 'services/data-store';
import { JsonEdit } from 'common/ui/json/JsonEdit.component';
import { Modal } from 'common/ui/modal';
import { DateTime } from 'common/datetime/DateTime';

enum TABS {
    Live = 0,
    MyEdits = 1,
    Changes = 2,
}

enum STATES {
    RefreshInProgress,
    LoadingInProgress,
    Error,
    FileDisplay,
    PageDisplay
}


const PathwayEditorPage = () => {
    const [path, setPath] = useState('home');
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [changedFiles, setChangedFiles] = useState([]);
    const [tab, setTab] = useState(TABS.Live);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [cancelConfirm, setCancelConfirm] = useState(false);
    const [prConfirm, setPrConfirm] = useState(false);
    const [prButtonState, setPrButtonState] = useState(false);
    const [prData, setPrData] = useState({ 'created_on': '', id: null });

    const [pageState, setPageState] = useState(STATES.RefreshInProgress);


    const changeTab = (data) => {
        setTab(data);
        cutAndSetFileFromPath();
        changePageToDisplay();
    };

    const grabFiles = async () => {
        const folder = path.split('/').slice(1).join('/');
        try {
            const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});
            let files;

            if (tab === TABS.Live) {
                files = await getLiveFiles({
                    folder,
                    roleUuid,
                });
            }

            if (tab === TABS.MyEdits) {
                files = await getUserFiles({
                    folder,
                    roleUuid,
                });
            }

            const changedFiles = await getChangedFiles({
                roleUuid,
            });

            setChangedFiles(changedFiles);

            if (tab === TABS.Changes) {
                setFiles(changedFiles || []);
            } else {
                setFiles(files || []);
            }
            changePageToDisplay();
        } catch (e) {
            setError(e.message);
            setFiles([]);
            setPageState(STATES.Error);
        }
    };

    const getPR = async () => {
        const prsStream = await fetch('/bitbucket/pull-request');
        const prsResponse = await prsStream.json();
        setPrButtonState(prsResponse.length !== 0);
        if (prsResponse[0]) {
            setPrData({ 'created_on': prsResponse[0]['created_on'], id: prsResponse[0]['id'] });
        }
    };

    const createPR = async () => {
        setPageState(STATES.RefreshInProgress);
        setPrConfirm(false);
        try {
            cutAndSetFileFromPath();
            await fetch('/bitbucket/pull-request', { method: 'POST' });
            await getPR();
            changePageToDisplay();
        } catch(e) {
            setError(e.message);
            setPageState(STATES.Error);
        }
    };

    useEffect(() => {
        const refresh = async () => {
            const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});

            try {
                await refreshContentFiles({
                    roleUuid,
                });
                changePageToDisplay();
            } catch (e) {
                setError(e.message);
                setPageState(STATES.Error);
            }
        };
        refresh().then(() => {
            grabFiles();
        });
        getPR();
    }, []);

    useEffect(() => {
        if (pageState === STATES.RefreshInProgress || pageState === STATES.FileDisplay) {
            return;
        }
        setPageState(STATES.LoadingInProgress);
        grabFiles();
    }, [path, tab]);


    const changePath = ({ newPath, replacePath, isFile, file }) => {
        if (isFile) {
            setPageState(STATES.FileDisplay);
            setFile(file);
        } else if (pageState !== STATES.PageDisplay) {
            changePageToDisplay();
            setFile(null);
        }
        if (replacePath) {
            setPath(newPath);
        } else {
            setPath(`${path}/${newPath}`);
        }
    };

    const submitJsonChanges = async (filePath) => {
        try {
            await fetch(`/bitbucket/files?path=${filePath}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ content: file.json })
            });
        } catch (e) {
            setError(e.message);
            setPageState(STATES.Error);
        }
    };

    const dropChangesClick = async () => {
        setPageState(STATES.LoadingInProgress);
        setDeleteConfirm(false);
        try {
            await fetch('/bitbucket/files', { method: 'DELETE' });
            await grabFiles();
            await getPR();
            changePageToDisplay();
        } catch (e) {
            setError(e.message);
            setPageState(STATES.Error);
        }
    };

    const cutAndSetFileFromPath = () => {
        const newPath = path.split('/');
        if (newPath.length > 1) {
            setPath(newPath.slice(0, newPath.length - 1).join('/'));
        }
    };

    const onSubmitClick = () => {
        setPageState(STATES.LoadingInProgress);
        submitJsonChanges(file.path).then(() => {
            changePageToDisplay();
            cutAndSetFileFromPath();
        }).catch(() => {
            setPageState(STATES.Error);
            setError('Something went wrong');
        });
    };

    const cancelSubmitChanges = async () => {
        setPageState(STATES.LoadingInProgress);
        try {
            setCancelConfirm(false);
            await fetch(`/bitbucket/pull-request/decline/?id=${prData.id}`, { method: 'POST' });
            await getPR();
            changePageToDisplay();
        } catch (e) {
            setError(e.message);
            setPageState(STATES.Error);
        }
    };

    const changePageToDisplay = () => {
        setError('');
        setPageState(STATES.PageDisplay);
    };


    const jsonChangeHandler = (val) => {
        if (val === false) {
            setError('File formatting is invalid');
        } else {
            setError('');
            setFile({ ...file, json: val });
        }
    };

    return (
        <div>
            <h1>Your Pathway</h1>
            {prButtonState && pageState === STATES.FileDisplay && (
                <div className="top-bar">
                    <strong>You cannot edit files because you have pending changes.</strong>
                </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ maxWidth: '500px' }}>
                    <p>
                        View and edit pathway content for appointments, resources, exercises, questionnaires and communications
                    </p>
                </div>

                {(tab === TABS.MyEdits && pageState !== STATES.FileDisplay) && (
                    <div className={'form-group'}>
                        <button disabled={prButtonState || pageState !== STATES.PageDisplay} onClick={() => setPrConfirm(true)} className={'btn btn-info'} style={{ marginRight: '5px' }}>
                            {
                                prButtonState ? (
                                    <React.Fragment>
                                        <span>Submitted: </span>
                                        <DateTime parseFormat="nhs_date_fulldate">
                                            {prData.created_on}
                                        </DateTime>
                                    </React.Fragment>
                                ) : 'Submit changes'
                            }
                        </button>
                        {prButtonState && (
                            <button
                                style={{ marginRight: '5px' }}
                                disabled={pageState !== STATES.PageDisplay}
                                onClick={() => setCancelConfirm(true)}
                                className={'btn btn-danger'}>
                                Cancel Submitted Changes
                            </button>
                        )}
                        {
                            !prButtonState && (
                                <button onClick={() => setDeleteConfirm(true)}
                                    disabled={pageState !== STATES.PageDisplay}
                                    className={'btn btn-danger'}>
                                    Cancel Changes
                                </button>
                            )
                        }
                    </div>
                )}
            </div>

            {cancelConfirm && (
                <Modal title={'Warning'}
                    size="lg"
                    onClose={() => setCancelConfirm(false)}
                    buttons={[{
                        label: 'Yes',
                        classNames: 'btn-info',
                        callback: cancelSubmitChanges
                    }, {
                        label: 'No',
                        classNames: 'btn-danger',
                        callback: () => setCancelConfirm(false)
                    }]}
                >
                    <div>
                        <h3 style={{ textAlign: 'center' }}>Are you sure you want to discard submitted changes?</h3>

                        <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <strong>The following files are sent for review. Do you want to cancel and start editing?</strong>
                        </p>

                        <FilesList
                            path={path}
                            files={changedFiles}
                            changedFiles={[]}
                            onClick={changePath}
                        />
                    </div>
                </Modal>
            )}

            {deleteConfirm && (
                <Modal title={'Warning'}
                    size="lg"
                    onClose={() => setDeleteConfirm(false)}
                    buttons={[{
                        label: 'Yes',
                        classNames: 'btn-info',
                        callback: dropChangesClick
                    }, {
                        label: 'No',
                        classNames: 'btn-danger',
                        callback: () => setDeleteConfirm(false)
                    }]}
                >
                    <div>
                        <h3 style={{ textAlign: 'center' }}>Are you sure you want to discard changes?</h3>

                        <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <strong>This action is irreversible. The changes in following files will be discarded.</strong>
                        </p>

                        <FilesList
                            path={path}
                            files={changedFiles}
                            changedFiles={[]}
                            onClick={changePath}
                        />
                    </div>
                </Modal>
            )}

            {prConfirm && (
                <Modal title={'Warning'}
                    size="lg"
                    onClose={() => setPrConfirm(false)}
                    buttons={[{
                        label: 'Yes',
                        classNames: 'btn-info',
                        callback: createPR
                    }, {
                        label: 'No',
                        classNames: 'btn-danger',
                        callback: () => setPrConfirm(false)
                    }]}
                >
                    <div>
                        <h3 style={{ textAlign: 'center' }}>Are you sure you want to commit changes?</h3>

                        <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <strong>The following files have been changed</strong>
                        </p>

                        <FilesList
                            path={path}
                            files={changedFiles}
                            changedFiles={[]}
                            onClick={changePath}
                        />
                    </div>
                </Modal>
            )}

            <Row>
                <Column sm="12">
                    <TabNavigator
                        selectedItem={tab}
                        onClick={changeTab}
                        tabPosition='top'
                        tabStyle='tab'
                    >
                        <NavTab title={'Live'}/>
                        <NavTab title={'My Edits'}/>
                        <NavTab title={'Changes'}/>
                    </TabNavigator>
                </Column>
            </Row>

            <Fragment>
                <div style={{ marginTop: '20px' }}>
                    <BreadCrumbs onClick={changePath} path={path}/>
                </div>
                {pageState === STATES.Error && <div className="alert alert-danger">{error}</div>}
                {pageState === STATES.RefreshInProgress &&
                <div className="alert alert-info">Refreshing repository... Please wait.</div>}
                {pageState === STATES.LoadingInProgress &&
                <div className="alert alert-info">Fetching... Please wait.</div>}
                {pageState === STATES.PageDisplay && (
                    <FilesList
                        path={path}
                        files={files}
                        changedFiles={changedFiles.map(file => file.path) || []}
                        onClick={changePath}
                    />
                )}
                {pageState === STATES.FileDisplay && (
                    <div>
                        {error !== '' && <div className="alert alert-danger">{error}</div>}
                        <div style={{ marginBottom: '20px' }}>
                            <JsonEdit data={file.json} disabled={prButtonState} onChange={jsonChangeHandler}/>
                        </div>
                        <div className={'col-sm-12'}>
                            <button disabled={error !== '' || prButtonState} onClick={onSubmitClick} className='btn btn-success'>Submit</button>
                        </div>
                    </div>
                )}

            </Fragment>
        </div>
    );
};

export default PathwayEditorPage;
