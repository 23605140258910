import React, { useState } from 'react';
import { DropdownForTags } from './DropdownForTags';
import { ControlledTagsInput } from './ControlledTagsInput';

export const DropdownTagsInput = ({
    tags = [],
    allTags= [],
    onChange = (tags: string[]) => void 0,
}) => {
    const [filter, setFilter] = useState('');
    const [isDropdownOpen, setIsOpenDropdown] = useState(false);

    const dropdownValues: [string, string][] = allTags
        .filter(tag => tags.indexOf(tag) === -1)
        .concat(filter && allTags.indexOf(filter) == -1 ? filter : '')
        .filter(tag => !!tag)
        .map(tag => [tag, tag]);

    const handleChange = (changedTags: string[]) => {
        onChange(changedTags);
        setIsOpenDropdown(false);
        setTimeout(() => {
            setIsOpenDropdown(true);
        }, 100);
    };

    const handleAddTag = (event: any) => {
        onChange(tags.indexOf(event.target.value) === -1 ? [...tags, event.target.value] : tags);
        setFilter('');
        setIsOpenDropdown(false);
        setTimeout(() => {
            setIsOpenDropdown(true);
        }, 100);
    };

    return (
        <div>
            <div className="form-group">
                <DropdownForTags
                    name='tagsDropdown'
                    items={dropdownValues}
                    input={(
                        <ControlledTagsInput
                            tags={tags}
                            currentTag={filter}
                            onFocus={() => setIsOpenDropdown(true)}
                            onBlur={() => setIsOpenDropdown(false)}
                            onChange={handleChange}
                            onCurrentTagChange={(event) => setFilter(event.target.value)}
                        ></ControlledTagsInput>
                    )}
                    showFilter={false}
                    filterString={filter}
                    openDropdown={isDropdownOpen}
                    value={''}
                    onChange={handleAddTag}
                />
            </div>
        </div>
    );
};
