import { CompositionService } from './composition.service';
import { Composition } from 'models/Composition';
import { ResourceCategory } from 'models/ResourceCategory';
const tag = '[ResourceCategoryService]';
class ResourceCategoryService extends CompositionService {

    get archetypeName(): string {
        return 'resourceCategory';
    }
    getCategoryList({ folder }: {folder: 'team' | 'org'}): Promise<Composition<ResourceCategory>[]> {
        return this.list({ folderId: 'team' })
            .then(({ message: { results } }) => {
                return results;
            })
            .catch((err) => {
                console.log(tag, 'Unable to get category list', err);
                return Promise.reject(err);
            });
    }
}

export const resourceCategoryService = new ResourceCategoryService();
