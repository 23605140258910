import { useState, useEffect } from 'react';
import ServerFetch from 'server-fetch';
import DataStore from 'services/data-store';
import { useAPI } from './useAPI';

export function useCurrentTeams(portal = 'clinical_portal') {
    const [currentTeams, setCurrentTeams] = useState(undefined);
    const { version } = useAPI();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const listenIdx = DataStore.getListener('me.teams',
            (teams) => {
                if (isError) { return; }

                if (!teams && version === 1) {
                    return ServerFetch.getRequest(this, `/api/${portal}/teams`,
                        (response) => {
                            DataStore.set('me.teams', response.message);
                        },
                        () => {
                            console.error('Error when fetching teams');
                            setIsError(true);
                        });
                }

                setCurrentTeams(teams);
            });

        const teams = DataStore.get('me.teams');
        setCurrentTeams(teams);

        return () => {
            DataStore.removeListener(listenIdx);
        };
    }, [portal, isError, version]);

    return currentTeams;
}
