import React, {FC, useEffect} from 'react';
import PageTitle from 'page-title';
import { notesService } from 'services/notes.service';
import './user-notes.less';
import UserNoteTableDisplay, { defaultPagination } from './UserNoteTableDisplay';
import referralsService from 'services/referrals.service';

export const UserNotes: FC<{folderId: number}> = ({ folderId }) => {
    useEffect(() => {
        PageTitle.setTitle(
            'User Notes | PHR Clinical Portal'
        );
    }, []);

    //Only notes linked to referrals or nothing should be shown on the general page
    const retrieveNotes = (limit: number, offset: number) => referralsService
        .list({ limit: Infinity})
        .then((response) => response.message.results.map(result => result.uuid))
        .then((uuids: string[]) => notesService.search({
            limit,
            offset,
            sort: [{
                ascending: false,
                field: 'created_at'
            }],
            search: {
                'content.parent_doc_uuid': { '$in': [ null, '', ...uuids ] }
            }
        }));

    return <UserNoteTableDisplay folderId={folderId} retrieveNotes={retrieveNotes} initialPagination={defaultPagination}/>;
};

export default UserNotes;
