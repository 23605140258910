import React, { useState, useEffect } from 'react';
import { useReferralsByUuid } from 'features/patient/referrals/useReferrals';
import { Label } from 'models/Label';
import { Referral } from 'models/compositions/Referral';
import { Composition } from 'models/Composition';

export function usePathwayNames({ labels }: {labels: Label[]}) {
    const [referralsByUuid, {
        invalidateItem,
    }] = useReferralsByUuid();

    const [pathwayNames, setPathwayNames] = useState<string>('');
    const [pathwayNamesWithEvent, setPathwayNamesWithEvent] = useState<[string, string][]>([]);

    useEffect(() => {
        if (!labels) { return; }

        for (const label of labels) {
            if(label.referral_uuid) {
                invalidateItem(label.referral_uuid);
            }
        }

    }, [labels]);

    useEffect(() => {
        if (!labels) { return; }

        const filteredLabels: Label[] = labels.filter(label => label.type === 'clinical_event');

        const allLabels = filteredLabels
            .reduce<{label: Label; referralLabel: Label}[]>((acc, label) => {
                const referralLabel = getRelatedAndActiveReferralLabel(referralsByUuid.get(label.referral_uuid), label);
                const item = {
                    label,
                    referralLabel,
                };
                acc.push(item);
                return acc;
            }, []);

        const pathwayNamesWithEvent = allLabels
            .map<[string, string]>(({ label, referralLabel }) => {
                return [referralLabel ? referralLabel.name : 'Pathway not identified', label.name];
            });

        const names = allLabels
            .filter(({ referralLabel }) => Boolean(referralLabel?.name))
            .map(({ referralLabel }) => referralLabel.name)
            .join(', ');

        setPathwayNames(names);
        setPathwayNamesWithEvent(pathwayNamesWithEvent);
    }, [referralsByUuid, labels]);

    return { pathwayNames, pathwayNamesWithEvent };
}

function getRelatedAndActiveReferralLabel(referral: Composition<Referral> | undefined, label): Label {
    if(!referral) {
        return;
    }
    const { labels: referralLabels = [] } = referral.content;
    const foundLabel = referralLabels.find((referralLabel) => {
        return referralLabel.context === label.context;
    });
    return foundLabel;
}

export function isActiveLabelPredicate(label) {
    return label && (label.status === true || label.status === 'true');
}
