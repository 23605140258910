import React from 'react';
import PropTypes from 'prop-types'

import {browserHistory, Link} from 'react-router';
import lodash from 'lodash';

import BasePage from 'components/page/base';
import TriageDecisionForm from 'components/user/triage-decision/form';
import { referralsService } from 'services/referrals.service';
import { appointmentsService } from 'services/appointments.service';
import * as AlertBoxes from 'common/ui/alert-boxes';
import { setAppointmentMode } from 'services/appointment-helpers';
import { triageDecisionFlat } from 'components/user/triage-decision/form/triage-decision-codes';
import { demographicsService } from 'services/demographics.service';
import { folderService } from 'services/folder.service';



export default class EnterTriageDecisionPage extends BasePage
{

    constructor (props) {
        super(props);
        this.state = {
            // Indicates if the form is currently loading.
            // It needs to load a list of referrals.
            // In future, it may need to load a lookup table of clinic codes.
            loading: true,

            // Indicates if an error occurred during loading.
            loadingError: false,

            // A customised message to display for the loading error.
            // If it is empty, a default message will be displayed.
            loadingErrorMessage: '',

            // Indicates if the form data is currently being submitted.
            saving: false,

            // Indicates if an error occurred while submitting the form.
            savingError: false,

            // A customised message to display for the saving error.
            // If it is empty, a default message will be displayed.
            savingErrorMessage: '',

            // The demographics composition for the patient 
            demographics: {},

            // The referral composition being edited.
            referral: {},
            folderDetails: {},
            appointment: {
                transport: {
                    required: false,
                    details: ''
                },
                interpreter: {
                    required: false,
                    details: ''
                }
            }
        };
    }

    /**
     * React handler: The component has mounted.
     * This loads the referral identified in the route parameters.
     * This is done after mounting to minimise the delay before displaying something.
     */
    componentDidMount () {
        this.loadReferral();
    }

    /**
     * Load the referral composition identified in the route parameters.
     *
     * @return {Promise} A promise which resolves when loading is complete or has failed. It only rejects when there is a low-level error.
     */
    loadReferral () {
        const uuid = lodash.get(this, 'props.params.referralUuid', undefined);
        return this.$setState({
            loading: true,
            loadingError: false,
            loadingErrorMessage: '',
            referral: {},
            demographics: {}
        }).
        then(() => {
            return this.loadFolderDetails();
        }).
        then(() => {
            return demographicsService.getFirst({folderId: this.state.folderId });
        }).
        then((demographics) => {
            this.setState({
                demographics
            });

            if (!uuid) {
                console.warn('Could not determine referral UUID from route parameter.');
                return Promise.reject();
            }

            return referralsService.getByUuid({uuid});
        }).
        then((response) => {
            if (!lodash.has(response, 'message.content') || !lodash.has(response, 'message.uuid')) {
                return Promise.reject('Server response did not have expected structure');
            }

            const referral = response.message;
            const triage_decision = lodash.get(referral, 'content.triage_decision', undefined);

            // Don't allow the user to enter a triage decision if the referral already has one.
            if (triage_decision) {
                return this.$setState({
                    loadingErrorMessage: '' +
                        'A triage decision has already been entered for this referral. ' +
                        'Please go back to the referrals page and select another.'
                }).
                then(() => {
                    return Promise.reject('Referral already has a triage decision.');
                });
            }

            return this.$setState({
                loading: false,
                loadingError: false,
                referral: response.message
            });
        }).
        catch((err) => {
            console.warn(err);
            return this.$setState({
                loading: false,
                loadingError: true
            }).
            then(() => {
                AlertBoxes.scrollToFirstAlertBox();
            });
        });
    }

    loadFolderDetails() {
        if (this.props.params && this.props.params.folderId) {
            folderService.getFolderById({ folderId: this.props.params.folderId }).then((folder) => {
                this.setState({
                    folderDetails: folder
                });
            })
            .catch((e) => {
                console.error('Error during loading folderDetails data', e);
            });
        }
    }

    onFormChange(id, rawValue, effectiveValue, valid) {

        if (rawValue === undefined && effectiveValue === undefined && valid === undefined) {
            return;
        }

        const appointment = lodash.cloneDeep(this.state.appointment);

        switch (id) {
            case 'tdf-transport-required':
                appointment.transport.required = effectiveValue;
                if (!effectiveValue) {
                    appointment.transport.details = '';
                }
                break;
            case 'tdf-interpreter-required':
                appointment.interpreter.required = effectiveValue;
                if (!effectiveValue) {
                    appointment.interpreter.details = '';
                }
                break;
            case 'tdf-transport-details':
                appointment.transport.details = effectiveValue;
                break;
            case 'tdf-interpreter-details':
                appointment.interpreter.details = effectiveValue;
                break;
        }
        this.setState({appointment});
    }

    /**
     * Event handler: The form has been submitted.
     * Returns a promise which resolves whether saving completes or fails.
     * It only rejects if there was a low-level error.
     *
     * @param {Map} data A map of data from the form.
     * @return {Promise}
     */
    onSubmit (data) {
        return this.$setState({
            saving: true,
            savingError: false,
            savingErrorMessage: ''
        }).
        then(() => {
            return this.ensureAppointmentUbrnDoesNotConflict(data);
        }).
        then(() => {
            return this.submitAppointment(data);
        }).
        then(() => {
            return this.submitReferral(data);
        }).
        then(() => {
            return this.$setState({
                saving: false,
                savingError: false
            });
        }).
        then(() => {
            this.goToReferralsPage();
        }).
        catch((err) => {
            console.warn(err);
            return this.$setState({
                saving: false,
                savingError: true
            }).
            then(() => {
                AlertBoxes.scrollToFirstAlertBox();
            });
        });
    }

    /**
     * Update the referral composition using the given form data.
     *
     * @param {Map} data A map of data from the form.
     * @return {Promise}
     */
    submitReferral (data) {
        if (!this.state.referral || !this.state.referral.uuid || !this.state.referral.content) {
            return Promise.reject('Expected referral composition in state.');
        }

        const newReferralContent = lodash.cloneDeep(this.state.referral.content);

        newReferralContent.triage_decision = data.get('tdf-triage-decision');
        if (!newReferralContent.triage_decision) {
            return Promise.reject('Triage decision code not specified.');
        }

        newReferralContent.booking_password = data.get('tdf-booking-password');
        if (!newReferralContent.booking_password) {
            return Promise.reject('Booking password not specified.');
        }

        // Do nothing if the referral content hasn't actually changed.
        if (lodash.isEqual(newReferralContent, this.state.referral.content)) {
            return Promise.resolve();
        }

        return referralsService.update({uuid: this.state.referral.uuid, content: newReferralContent});
    }


    /**
     * Create the appointment using the given form data.
     *
     * @param {Map} data A map of data from the form.
     * @return {Promise}
     */
     submitAppointment (data) {

        const content = lodash.cloneDeep(this.state.appointment);

        // Store the referral ID in the appointment.
        // This is determined entirely by the referral route used to reach this page.
        // It cannot be modified using the form.
        content.referral_id = lodash.get(this.state.referral, 'content.referral_ids[0]', undefined);
        if (!content.referral_id) {
            return Promise.reject('Could not identify referral ID.');
        }

        // Set various values which are fixed for this process.
        content.location = '';
        content.status = 'available-to-book';
        content.details = "You can now book your first appointment. This will take you to the NHS e-Referral booking service";

        // TEMPORARY: Store the booking password.
        // Note that this property is deprecated and is maintained for the benefit of existing apps only.
        // It should always be retrieved from the associated referral composition instead.
        content.booking_password = data.get('tdf-booking-password') || undefined;

        // Store the other values specified on the form.
        content.ubrn = data.get('tdf-appointment-ubrn');
        if (!content.ubrn) {
            return Promise.reject('Appointment UBRN not specified.');
        }
        content.type = data.get('tdf-appointment-type');
        if (!content.type) {
            return Promise.reject('Appointment type not specified.');
        }

        const mode = data.get('tdf-appointment-mode');
        if(!mode) {
            return Promise.reject('Appointment mode not specified.');
        }

        // Make sure the appointment date is marked as unknown.
        content.unknown_date = true;

        // --- DIRTY HACK --- //

        // We need to add some extra information for certain triage outcomes.
        // TODO: Make this more intelligent, e.g. part of a trigger engine?


        const triage_decision = data.get('tdf-triage-decision');
        if (triage_decision === 'Clinic PA') {
            content.additional_details = 'You may find that you are unable to book an appointment as there are none available. In this case, Sheffield Teaching Hospitals Pain Clinic will know about your referral and will be in touch with an appointment as soon as possible. If you wish to speak to someone about your appointment please call 0114 271 5210.';

        } else if (triage_decision === 'Clinic PD') {
            content.additional_details = 'Your appointment will be at a clinic provided by Pain Management Solutions. Unfortunately, we are not currently able to connect MyPathway to their appointment system, so details of your appointment will not appear on your MyPathway timeline. If you wish to speak to someone about your appointment, please call Pain Management Solutions on 0800 034 0406. This includes appointments at the Concord Move More Centre, Dovercourt Surgery, Mill Road Surgery or Sloane Medical Practice.';

        } else if (triage_decision.indexOf('Clinic O') === 0) {
            content.additional_details = '**If you choose to book with one of our partner hospitals...**  \n\nYou can book through the link above with our partner hospitals but your appointment details will not appear on your MyPathway timeline before your appointment. If you have any further queries about this appointment then please contact your chosen hospital: \n\n- Barlborough NHS Treatment Centre on 0333 200 4066 \n\n- Claremont Hospital on 0114 263 0330 \n\n- Thornbury Hospital on 0114 266 1133 \n\n- Pain Management Solutions (PMS) on 0800 034 0406 \n\n**If you try to book an appointment but none are available.** \n\n You may not be able to book an appointment as there might not be any available. If this is the case, Sheffield Teaching Hospitals will contact you with an appointment as soon as possible. If you wish to speak to someone about your appointment please call 0114 226 6255.';

        } else if (triage_decision.indexOf('Clinic PW') === 0  || triage_decision.indexOf('Clinic VF') === 0) {
            delete content.additional_details;
        } else {
            content.additional_details = "Please book your appointment as soon as possible. You will receive a reminder letter if you haven’t done this.\nIf you do not book your appointment you will be referred back to your GP and your treatment will be delayed.";
        }

        // --- END DIRTY HACK --- //

        // Look up the triage codes in title
        content.timeline = {};
        content.timeline.title = (lodash.get(triageDecisionFlat, triage_decision, '') + ' Appointment').trim();

        lodash.set(content, 'timeline.booking_link_url','https://www.ebs.ncrs.nhs.uk/pabs/login');
        lodash.set(content, 'timeline.booking_username_label', 'Booking UBRN');
        lodash.set(content, 'timeline.booking_password_label', 'Booking Password');
        lodash.set(content, 'timeline.booking_link_label', 'Click here to book your appointment');
        lodash.set(content, 'timeline.booking_link_advice', 'You will need your booking UBRN and booking password to do this.');
        
        if (!content.interpreter.details) delete content.interpreter.details;
        if (!content.transport.details) delete content.transport.details;
        
        return appointmentsService.create({content}).then((uuid) => {
            return setAppointmentMode(uuid, mode, this.props.folderId, this.props.teamId);
        });
    }

    /**
     * Check that the appointment UBRN in the given form data does not conflict with existing data on the server.
     *
     * @param {Map} data A map of data from the form.
     * @return {Promise} Resolves if the UBRN does NOT conflict. Rejects otherwise.
     */
    ensureAppointmentUbrnDoesNotConflict (data) {
        const ubrn = data.get('tdf-appointment-ubrn');
        if (!ubrn) {
            return Promise.reject('Appointment UBRN not specified in form data.');
        }

        return this.appointmentUbrnExists(ubrn).
        then((exists) => {
            if (exists) {
                return this.$setState({
                    savingErrorMessage: 'That appointment UBRN already exists. Perhaps this appointment has already been added to the system?'
                }).
                then(() => {
                    return Promise.reject('Appointment UBRN conflict');
                });
            }
        });
    }

    /**
     * Check if an appointment with the given UBRN already exists in the patient's folder.
     *
     * @param {string} ubrn The appointment UBRN to check for.
     * @return {Promise} Resolves with true if UBRN already exists, or false if not. Rejects if an error occurred.
     */
    appointmentUbrnExists (ubrn) {
        if (!ubrn) {
            return Promise.reject('Cannot search for empty appointment UBRN.');
        }

        const search = {'content.ubrn': {$eq: ubrn}};
        const limit = 1;

        return appointmentsService.search({search, limit}).then((response) => {
            const count = lodash.get(response, 'message.total', undefined);
            if (count === undefined) {
                return Promise.reject('Unexpected response structure from server.');
            }

            return (count > 0);
        });
    }

    /**
     * Event handler: The form has been cancelled.
     */
    onCancel () {
        this.goToReferralsPage();
    }

    /**
     * Navigate the user back to the referrals list.
     */
    goToReferralsPage () {
        browserHistory.push(`${this.getFolderPath()}/patient/referrals`);
    }

    /**
     * Check if the patient has contact details 
     */
    hasContactDetails () {
        const { demographics, folderDetails } = this.state;

        if (lodash.isEmpty(demographics)) return false;

        if (folderDetails.status === "registered") return true;

        const hasPatientEmail = demographics.content.email && demographics.content.email.length >= 1;
        const hasPatientMobile = demographics.content.mobile_phone && demographics.content.mobile_phone.length >= 1;
        const hasPatientAddress = demographics.content.home_address && !lodash.isEmpty(demographics.content.home_address);

        return hasPatientEmail || hasPatientMobile || hasPatientAddress;
    }

    /**
     * Get the title of the page to be displayed in the browser title bar.
     *
     * @return {string} The title of the page.
     */
    pageTitle () {
        return 'Enter Triage Decision | MyPathway Clinical Portal';
    }

    /**
     * Render the React component.
     *
     * @return {mixed}
     */
    render () {
        // TODO: Show an error if somebody who is not on the MyPathway service tries to access this page.

        // --- TEMPORARY CONTACT DETAILS WARNING --- //
        let noContactDetailsWarning = null;
    
        if (!this.state.loading && !this.hasContactDetails()) {
            noContactDetailsWarning = (
                <div className='alert alert-danger'>
                    <h2>Warning: No contact details</h2>
                    <p>There are no contact details on the PHR for this patient.</p>
                    <p>You can still enter a triage decision, but no invitation to register will be sent. This may only be a temporary problem.</p>
                </div>
            );
        }
        // ----------------------------------------- //

        return (
            <div className="page-triage-decision">
                <div className="top-bar">
                    <Link to={`${this.getFolderPath()}/patient/referrals`}>Back to referrals</Link>
                </div>

                {noContactDetailsWarning}

                <AlertBoxes.Loading show={this.state.loading} />
                <AlertBoxes.LoadingError show={this.state.loadingError && !this.state.loading}>{this.state.loadingErrorMessage}</AlertBoxes.LoadingError>
                <AlertBoxes.SavingError show={this.state.savingError && !this.state.saving}>{this.state.savingErrorMessage}</AlertBoxes.SavingError>

                <TriageDecisionForm
                    busy={this.state.loading || this.state.loadingError || this.state.saving}
                    onSubmit={this.onSubmit.bind(this)}
                    onFormChange={this.onFormChange.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    appointment={this.state.appointment}
                    referral={this.state.referral}
                />
            </div>
        );
    }
}
