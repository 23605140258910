import { API } from 'common/useAPI';
import { compositionService } from 'services/composition.service';
import * as apiv2 from 'services/api';

export const api: API = {
    version: 2,
    post: (url, data) => apiv2.$post(url.split('/'), data),
    get: (url) => apiv2.makeGetRequest(url),
    fullList: (archetypeName, query, folderId) => {
        if (query) {
            console.error('Not implemented');
        }

        return compositionService.list({
            folderId,
            archetypeName,
        });
    },
    listByUuids: (archetypeName, uuids, folderId) => {
        return compositionService.search({
            archetypeName,
            folderId,
            search: {
                'uuid': {
                    $in: uuids
                }
            }
        });
    }
};
