import React from 'react';

import {getService} from '../../../../services/services';
import MyPathwayPatientDashboard from 'components/my-pathway/patient-dashboard';

/**
 * Displays a page showing the patient dashboard based on the current folder.
 * Currently, the dashboard is only implemented for MyPathway.
 */
export default class PatientDashboardPage extends React.Component
{
    /**
     * Construct a new instance of this page component.
     * @param {Object} props React props for this component.
     */
    constructor (props) {
        super(props);

        this.state = {
            // The name of the service which the team is accessing the system as.
            // This determines which dashboard we show.
            service: ''
        };
    }

    pageTitle () {
        return 'Timeline | PHR Clinical Portal';
    }

    componentWillMount () {
        this.setState({
            service: getService()
        });
    }

    
    render () {
        if (!this.state.service || this.state.service == '') {
            return (
                <div className='alert alert-warning'>
                    Sorry. The patient dashboard cannot be shown.<br />
                    The service could not be identified.
                </div>
            );
        }
        
        // Load the appropriate dashboard component, based on the team's service.
        if (this.state.service == 'MSK') {
            return (<MyPathwayPatientDashboard />);
        }

        return (
            <div className='alert alert-warning'>
                Sorry. The patient dashboard cannot be shown.<br />
                It has not been implemented for this service yet.
            </div>
        );
    }
}
