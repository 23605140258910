import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import SignUpComponent from 'components/sign-up';

/**
 * Extended Page Component for user_portal/login
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle ()  {
        return _`Clinical Portal Register | iFocus Healthcare`;
    }

    render () {
        return (<SignUpComponent portal="clinical_portal" {...this.props} />)
    }
}