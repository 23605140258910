import { useCallback, useEffect, useState } from 'react';
import { resourcesService } from 'services/resources.service';

const RESOURCES_PER_PAGE = 10;

const usePaginatedResourceList = (categoryId: string) => {
    const [results, setResults] = useState([]);
    const [totalResults, setTotalResults] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [refreshRequestedAt, setRefreshRequestedAt] = useState<Date>(null);

    const onRefresh = useCallback(() => {
        setRefreshRequestedAt(new Date());
    }, []);

    useEffect(() => {
        async function fetchResources() {
            const search = { 'content.category.category_id': categoryId };
            const offset = (currentPage - 1) * RESOURCES_PER_PAGE;

            const params = {
                search,
                offset,
                limit: RESOURCES_PER_PAGE,
                page: currentPage,
            };

            try {
                const resourceResponse = await resourcesService.search({ ...params, folderId: 'team' });
                setResults(resourceResponse.message.results);
                setTotalResults(resourceResponse.message.total);
            } catch (e) {
                setResults([]);
            }
        }
        fetchResources();
    }, [categoryId, currentPage, refreshRequestedAt]);

    return {
        results,
        totalResults,
        currentPage,
        setCurrentPage,
        onRefresh
    };
};

export default usePaginatedResourceList;
