import React from 'react';

export function getStartOfDay(date) {
    let newDate = new Date(date.getTime());
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
}

export function getEndOfDay(date) {
    let newDate = new Date(date.getTime());
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    newDate.setDate(newDate.getDate()+1);
    newDate.setSeconds(newDate.getSeconds()-1);

    return newDate;
}
