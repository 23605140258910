import { DashboardContext } from 'features/patient/patient-dashboard/data/DashboardContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'services/i18n';
import { PatientContext } from 'pages/clinical_portal/folder/_folderId/patient/PatientContext';
import { ParamTypes } from 'features/patient/patient-dashboard/data/getDashboardData';
import { isEqual } from 'lodash';
interface State<T> {
    loading: boolean;
    data: T;
    error: string;
}
export const useDashboardData = <T>(hookParams: ParamTypes): State<T> & {patient: {folderId: number; teamId: number}} => {
    const { folderId, teamId } = useContext(PatientContext);
    const [carerFolderId, setCarerFolderId] = useState<number | null>(null);
    const dashboardContext = useContext(DashboardContext);
    const dataGetterRef = useRef(dashboardContext.getDashboardData);
    dataGetterRef.current = dashboardContext.getDashboardData;
    const [state, setState] = useState<State<T>>({ loading: true, data: void 0, error: '' });

    const prevParamsRef = useRef(hookParams);
    if(!isEqual(prevParamsRef.current, hookParams)) {
        prevParamsRef.current = hookParams;
    }
    const extendedHookParams = useMemo(() => {
        return { ...prevParamsRef.current, payload: { folderId, teamId, ...prevParamsRef.current.payload } };
    }, [folderId, teamId]) as ParamTypes;

    useEffect(() => {
        let cancelled = false;
        dataGetterRef.current(extendedHookParams)
            .then(({ data, skip, carerFolderId }) => {
                if(cancelled || skip) {
                    return;
                }
                if (carerFolderId) {
                    setCarerFolderId(carerFolderId);
                }
                setState((st: State<T>): State<T> => {
                    return {
                        ...st,
                        data,
                        loading: false,
                        error: '',
                    };
                });
            })
            .catch(() => {
                setState((st: State<T>): State<T> => {
                    return {
                        ...st,
                        data: void 0,
                        loading: false,
                        error: _`Something went wrong`,
                    };
                });
            });
        return () => {
            cancelled = true;
        };
    }, [extendedHookParams]);

    return useMemo(() => {
        return {
            patient: {
                folderId: carerFolderId ? carerFolderId : folderId,
                teamId,
            },
            ...state
        };
    }, [state, folderId, teamId]);
};
