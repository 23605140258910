import React, { Fragment, useMemo } from 'react';
import { useI18N } from 'common/i18n/useI18N';
import { usePatientsString } from 'common/usePatientsString';
import { Grid, GridHeader, GridHeaderRow, GridHeaderCell, GridBody, SortOrder } from 'common/ui/grid';
import PaginationView from 'components/pagination';
import { useTableFilter } from 'common/ui/grid/useTableFilter';
import { defaultNumberSort } from 'common/ui/grid/helpers';
import { useTableSort } from 'common/ui/grid/useTableSort';
import { usePagination } from 'common/ui/usePagination';
import { usePreparedAppointments } from './usePreparedAppointments';
import { AppointmentGridRow } from './AppointmentGridRow';

const initialSortState = {
    dateTime: SortOrder.DESC,
};

const filterSelector = {
    dateTime: item => item.dateTimeFilter,

};
const sortSelector = {
    dateTime: item => item.dateTimeSort,
};
const sortHandlers = {
    dateTime: defaultNumberSort,
};

export const AppointmentsGrid = ({
    loading,
    referrals,
    appointments,
}) => {
    const _ = useI18N();
    const [patientString] = usePatientsString();

    const preparedItems = usePreparedAppointments(appointments, referrals);

    const { onFilter, filteredItems, filterState } = useTableFilter({
        items: preparedItems,
        selectors: filterSelector,
    });

    const { onSort, sortedItems, sortState } = useTableSort({
        items: filteredItems,
        initialSortState,
        selectors: sortSelector,
        sortHandlers,
    });

    const { items: paginatedItems, total, onPageChange, page } = usePagination({
        items: sortedItems,
        perPage: 8,
        currentPage: 1
    });


    if (loading) {
        return (
            <div>
                <p><em>{_`Loading appointments please wait...`}</em></p>
            </div>
        );
    }

    if (appointments.length === 0) {
        return (
            <div>
                <div>{_`There are currently no appointments for this ${patientString}`}</div>
            </div>
        );
    }

    return (
        <Fragment>
            <Grid
                onSort={onSort}
                onFilter={onFilter}
                sortOrder={sortState}
                filterState={filterState}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'pathwayNames'}
                        >{_`Pathway`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'type'}
                        >{_`Type`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'location'}
                        > {_`Location`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'consultationMechanism'}
                        > {_`Consultation Mechanism`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'dateTime'}
                        >{_`Date / Time`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'status'}
                        >{_`Status`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={false}
                            filterable={false}
                        >{_`Actions`}</GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {paginatedItems.map(item => (
                        <AppointmentGridRow
                            key={item.key}
                            appointmentListItem={item}
                        />
                    ))}
                </GridBody>
            </Grid>
            <PaginationView
                currentPage={page}
                pageCount={total}
                onChange={onPageChange}
            />
        </Fragment>
    );
};
