import React from 'react';
import BasePage from 'components/page/base';
import HeadingDoc from 'ui/heading-doc';
import _ from 'services/i18n';
import FileModal from 'components/csvfile';
import Events from './_events';
import JSONTable from 'components/jsontable';
import {Panel, ResponsiveTable} from 'ui';

/**
 * Extended Page Component for questionnaire templates
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            showFileModal: false,
            results: false,
            previewResults: false,
            status: false,
            total: 0,
            duplicates: 0,
            imported: 0,
            failed: 0,
            ageFailed: 0,
            failedRows: []
        };

        this.bindEvents(['CloseModal', 'Upload', 'ShowModal', 'Import'], new Events);
    }

    pageTitle () {
        return 'Upload Pre-Invite List | PHR Admin Portal';
    }

    render () {
        return <Page state={this.state} events={this.events} />;
    }
}

const Heading = () => {
    return (<HeadingDoc title={_`Upload Pre-invite List`}>
        {_`This page will let you upload a pre-invite csv list. Your CSV will need to be correctly formatted.`}
    </HeadingDoc>);
}
const Page = ({state, events}) => {
    let failures = state.failedRows.map((item, idx) => {
        return (
            <FailedRow key={idx} {...item} />
        );
    }), headers = [];

    state.failedRows.some((item, idx) => {
        let aidx, aitem;

        for(aidx in item.row) {
            if (item.row.hasOwnProperty(aidx)) {
                aitem = item.row[aidx];
                headers.push(
                    <th key={aidx}>{aidx}</th>
                );
            }
        }
        return true;
    });

    if (state.status == 'processing') {
        return (
            <div className="page page-waiting-list">
                <Heading />
                <div className="alert alert-info">
                    <span>Loading... please wait</span>
                </div>
            </div>
        );
    }

    if (state.status == 'processed') {
        return (
            <div className="page page-waiting-list">
                <Heading />
                <div className="alert alert-info">
                    <span>Import complete.</span>
                </div>
                <ResponsiveTable>
                    <tbody>
                        <tr><th>Total</th><td>{state.total}</td></tr>
                        <tr><th>Imported</th><td>{state.imported}</td></tr>
                        <tr><th>Failed</th><td>{state.failed}</td></tr>
                        <tr><th>Failed (age under 15 or over 120)</th><td>{state.ageFailed}</td></tr>
                        <tr><th>Duplicates</th><td>{state.duplicates}</td></tr>
                    </tbody>
                </ResponsiveTable>
                <Panel title="Failed Rows">
                    <ResponsiveTable>
                        <thead>
                            <tr>
                                <th>Reason</th>
                                {headers}
                            </tr>
                        </thead>
                        <tbody>
                        {failures}
                        </tbody>
                    </ResponsiveTable>
                </Panel>
            </div>
        );
    }

    return (
        <div className="page page-waiting-list">
            <Heading />
            <Conditional check={!state.previewResults}>
                <div className="alert alert-info">
                    <span>Click on Choose File to select the CSV file you wish to upload. You will then be shown a preview</span>
                </div>
            </Conditional>
            <div className="form-group">
                <button type="button" className="btn btn-default" onClick={events.onShowModal}>Choose File</button>
            </div>
            <Conditional check={state.previewResults}>
                <div>
                    <div className="alert alert-info">
                        <span>Below is a preview of the first five rows of the CSV. If you are are happy this is the correct file, click Import.</span>
                    </div>
                    <JSONTable results={state.previewResults} />
                    <div className="form-group">
                        <button type="button" className="btn btn-success" onClick={events.onImport}>Import {state.results.length} Records</button>
                    </div>
                </div>
            </Conditional>
            <Conditional check={state.showFileModal}>
                <FileModal width="200" height="200" onClose={events.onCloseModal}
                           onUpload={events.onUpload} />
            </Conditional>
        </div>
    );
};

const FailedRow = (props) => {
    let columns = [], item, idx;

    for(idx in props.row) {
        if (props.row.hasOwnProperty(idx)) {
            columns.push(
                <td key={idx}>{props.row[idx]}</td>
            );
        }
    }

    return (
        <tr>
            <td>
                <strong className="text-danger">{props.type}</strong>
            </td>
            {columns}
        </tr>
    );
};

const Conditional = ({check, children}) => {
    // get the first child only
    let firstOnly = [].concat(children).pop();
    if (check) {
        return (
            <div>{firstOnly}</div>
        );
    }

    return (<noscript />);
}