import { useDepartmentLocation } from 'features/department/department.context';
import { convertDepartmentLocationTree, findDepartmentName } from 'features/department/department.utils';
import React, { useEffect, useState } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import './DepartmentSelect.scss';
import _ from 'services/i18n';

export type DepartmentOption = {
    label?: string;
    value: string;
}

export type DepartmentSelectProps = {
    departmentUUID: string;
    setDepartmentUUID: (departmentUUID: string) => void;
    showAllDepartmentOption?: boolean;
}

const DepartmentSelect = ({ departmentUUID, setDepartmentUUID, showAllDepartmentOption = true }: DepartmentSelectProps) => {
    const { currentLocation, locationTree } = useDepartmentLocation();
    const [departments, setDepartments] = useState([]);
    const [departmentLabel, setDepartmentLabel] = useState<string>(null);

    useEffect(() => {
        if (departmentUUID && departmentLabel === null) {
            const departmentName = findDepartmentName(departmentUUID, locationTree);
            setDepartmentLabel(departmentName);
        }
    }, [currentLocation, departmentLabel, departmentUUID, locationTree]);

    useEffect(() => {
        if (locationTree) {
            const departmentLocationList = convertDepartmentLocationTree(locationTree);

            const allDepartmentOption = {
                label: _`All Departments`,
                value: null
            };
            const currentDepartmentOption = {
                label: _`Current Department`,
                value: currentLocation
            };

            const departmentLocations = [];

            if (showAllDepartmentOption) {
                departmentLocations.push(allDepartmentOption);
            }
            setDepartments([...departmentLocations, currentDepartmentOption, departmentLocationList]);
        }
    }, [locationTree, currentLocation, showAllDepartmentOption]);

    const onChange = (currentNode, selectedNodes) => {
        setDepartmentLabel(selectedNodes[0].label);
        setDepartmentUUID(selectedNodes[0].value);
    };
    
    return (
        <div className='department-select-wrapper'>
            <div className='department-select-container'>
                <DropdownTreeSelect 
                    className="department-select"
                    data={departments}
                    onChange={onChange} 
                    onAction={() => null} 
                    onNodeToggle={() => null} 
                    mode='radioSelect' 
                    keepChildrenOnSearch={true} 
                    keepTreeOnSearch={true}
                    inlineSearchInput={true}
                    texts={{
                        placeholder: departmentLabel ? departmentLabel : 'Select department...'
                    }}
                />
                { departmentUUID && (
                    <i className="fa fa-times department-select-clear" aria-hidden="true" onClick={() => {
                        setDepartmentUUID(null);
                        setDepartmentLabel(null);
                    }
                    }/>
                )}
            </div>
        </div>
    );
};

export default DepartmentSelect;
