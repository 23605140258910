import { apiV2Service } from 'services/api-v2.service';
import DataStore from 'services/data-store';
import { CompositionService } from './composition.service';

const tag = '[Predefined SMS Service]';

class PredefinedSMSService extends CompositionService {
    get archetypeName() {
        return 'sms-template';
    }

    public isAvailable(): boolean {
        const role = DataStore.get('me.currentRole');
        if (!role) {
            console.error(tag, 'role is not available', role);
            return false;
        }
        return true;
    }
}

export const predefinedSMSService = new PredefinedSMSService();
export default predefinedSMSService;
