import { CompositionService } from 'services/composition.service';

class DeviceService extends CompositionService {

    get archetypeName(): string {
        return 'device';
    }

}

export const deviceService = new DeviceService();
