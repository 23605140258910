import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';

export default class ForgotPassword extends BaseComponent {
    /**
     * @param {Object} props - Passed in properties
     */
    constructor (props) {
        super(props);

        this.state = {
            email: this.props.location.query.email || '',
            errors: [],
            complete: false,
            portal: props.portal,
        };

        this.bindEvents([
            'Change',
            'Submit'
        ]);

        this.View = View;
    }

    handleChange ({
        target: {
            name, value
        }
    }) {
        switch(name) {
            case 'email':
                this.setState({[name]: value});
                break;
        }
    }

    handleSubmit (e) {
        var submission = {}, safeEmail = this.state.email.trim();
        e.preventDefault();
        const portalType = /clinical_portal/.test(window.location.pathname) ? 'clinical_portal' : 'admin_portal';
        const resetUrl = `${window.location.origin}/${portalType}/password-reset`;
        submission = {
            email: this.state.email,
            portal: this.props.portal,
            password_reset_url: resetUrl,
        };

        if (safeEmail == '') {
            this.setState({
                errors: [
                    'Email address is required'
                ]
            });

            return;
        }

        if (!safeEmail.match(/.+@.+/) || safeEmail.match(/[ \t]/)) {
            this.setState({
                errors: [
                    'Email address must be valid'
                ]
            });

            return;
        }

        this.setState({loading: true});

        const url = '/forgot-password';
        const query = `?password_reset_url=${encodeURIComponent(resetUrl)}`;
        this.server.postRequest(url + query, submission, (response) => {
            let details = {};
            if (response.hasOwnProperty('success')) {
                this.setState({
                    complete: true
                });
                return;
            }

            if (response.httpStatusCode == 400) {
                this.setState({
                    loading: false,
                    errors: [
                        response.details
                    ]
                });
                return;
            }

            this.setState({
                loading: false,
                errors: [
                    details.details || 'Unspecified error'
                ]
            });
        });
    }
}
