/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import { EmailCreateForm } from 'components/user/email/create/form';
import { SendEmailState, validate, normalise } from 'components/user/email/create/validator';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import { BulkOperationCostEstimatorNone } from './BulkOperationCostEstimator';
import { BulkOperationRequestAction, BulkOperationRequestSendEmail } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import { bulkOperationService } from 'services/bulk-operation.service';
import EmailPreview from 'components/user/email/create/preview';

const initialFormState: SendEmailState = { subject: '', htmlContent: '', plainTextContent: '' };

export class BulkOperationSendEmailConfigurator extends BulkOperationConfigurationComponent<SendEmailState> {
    public validate: () => boolean;
    
    constructor() {
        super();
    }
    
    public getActionName(): string {
        return 'Send Email';
    }
    
    public getInitialFormState(): SendEmailState {
        return initialFormState;
    }
    
    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState<string[]>([]);
        
        const { currentFormState, onFormStateChange } = this.useFormState();
        
        this.validate = () => validate(this.getFormState(), onErrors);
        
        return (
            <EmailCreateForm
                id='bo-create-email'
                loading={false}
                errors={currentErrors}
                onChange={(c) => onFormStateChange({ ...currentFormState, ...c })}
                form={currentFormState}
                onSubmit={(f) => f.preventDefault()}
            />
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<SendEmailState> {
        return new BulkOperationSendEmailPreviewBuilder(selectionState, normalise(this.getFormState()));
    }
}

export class BulkOperationSendEmailPreviewBuilder extends BulkOperationPreviewBuilder<SendEmailState>
{
    public getActionName(): string {
        return 'Send Email';
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];

        const costEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);
        
        const emailPreview = (): JSX.Element => {
            return (
                <EmailPreview
                    subject={this.getActionState().subject}
                    htmlContent={this.getActionState().htmlContent}
                />
            );
        }; 
        
        items.push(new BulkOperationPreviewItem(emailPreview(), costEstimatorMessage));

        return items;
    }

    public getBulkOperations(): Promise<BulkOperationRequestAction<TriggerEngineRequestContent>[]> {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationRequestSendEmail({
            subject: this.getActionState().subject,
            htmlContent: this.getActionState().htmlContent,
            plainTextContent: this.getActionState().plainTextContent
        }));

        return Promise.resolve(request);
    }
}
