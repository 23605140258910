import { makeDeleteRequest, makeGetRequest, makePostRequest } from './api';
import { MFAMethod, MFAOption } from 'models/mfa';

const baseUrl = '/mfa/options'

function buildBaseUrlForOptionID(optionID: number) {
    return `${baseUrl}/${optionID}`;
}
function buildSetupBaseUrl(method: MFAMethod): string {
    return `${baseUrl}/setup/${method}`
}
function buildSetupStartUrl(method: MFAMethod): string {
    return buildSetupBaseUrl(method);
}
function buildSetupBaseUrlForOption(option: MFAOption): string {
    return `${buildSetupBaseUrl(option.mfa_method)}/${option.id}`;
}
function buildSetupResumeUrlForOption(option: MFAOption): string {
    return `${buildSetupBaseUrlForOption(option)}/resume`;
}
function buildSetupConfirmUrlForOption(option: MFAOption): string {
    return `${buildSetupBaseUrlForOption(option)}/confirm`;
}

export type QRCodeDetails = {
    url: string;
    account: string;
    secret: string;
}

class MFASetupService {

    listUserMFAOptions(): Promise<MFAOption[]> {
        const url = `${baseUrl}`;
        return makeGetRequest(url).then(result => result.message);
    }

    getUserMFAOption(optionID: number): Promise<MFAOption> {
        const url = buildBaseUrlForOptionID(optionID);
        return makeGetRequest(url).then(result => result.message);
    }

    deleteUserMFAOption(optionID: number): Promise<MFAOption[]> {
        const url = buildBaseUrlForOptionID(optionID);
        return makeDeleteRequest(url).then(result => result.message);
    }

    makeUserMFAOptionPreferred(optionID: number): Promise<MFAOption[]> {
        const url = `${buildBaseUrlForOptionID(optionID)}/make-preferred`;
        return makePostRequest(url).then(result => result.message);
    }

    startUserMFAOptionSetupSMS(): Promise<MFAOption> {
        const url = buildSetupStartUrl('sms');
        return makePostRequest(url).then(result => result.message.mfaOption);
    }

    startUserMFAOptionSetupEmail(): Promise<MFAOption> {
        const url = buildSetupStartUrl('email');
        return makePostRequest(url).then(result => result.message.mfaOption);
    }

    startUserMFAOptionSetupAuthenticator(): Promise<{ mfaOption: MFAOption, qrCodeDetails: QRCodeDetails }> {
        const url = buildSetupStartUrl('authenticator');
        return makePostRequest(url).then(result => result.message);
    }

    getQRCodeUrl(mfaOption: MFAOption): Promise<QRCodeDetails> {
        const url = `${buildSetupBaseUrlForOption(mfaOption)}/qr-code-url`;
        return makeGetRequest(url).then(response => response.message);
    }

    resumeUserMFAOptionSetupSMS(option: MFAOption, mobileNumber: string): Promise<MFAOption> {
        const url = buildSetupResumeUrlForOption(option);
        return makePostRequest(url, { mobile_number: mobileNumber }).then(result => result.message.mfaOption);
    }

    resumeUserMFAOptionSetupEmail(option: MFAOption): Promise<MFAOption> {
        const url = buildSetupResumeUrlForOption(option);
        return makePostRequest(url).then(result => result.message.mfaOption);
    }

    resumeUserMFAOptionSetupAuthenticator(option: MFAOption): Promise<{ mfaOption: MFAOption, qrCodeDetails: QRCodeDetails }> {
        const url = buildSetupResumeUrlForOption(option);
        return makePostRequest(url).then(result => result.message);
    }

    requestNewConfirmationCode(option: MFAOption): Promise<boolean> {
        const url = `${buildSetupBaseUrlForOption(option)}/request-code`;
        return makePostRequest(url);
    }

    confirmUserMFAOptionSetup(option: MFAOption, oneTimeCode: string, makePreferred: boolean): Promise<MFAOption[]> {
        const url = buildSetupConfirmUrlForOption(option);
        return makePostRequest(url, { otp: oneTimeCode, preferred: makePreferred }).then(result => result.message);
    }
}

export const mfaSetupService = new MFASetupService();
