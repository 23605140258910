import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';
import { SelectionStateContainer } from 'models/AdvancedSearchDefinition';
import React, { useMemo } from 'react';
import { ResourcesSearchQueryResultsManager } from '../ResourcesSearchResultManager/useResourcesSearchResultManager';

export function useResourcesSelectionStateContainer(searchQueryResultsManager: ResourcesSearchQueryResultsManager): SelectionStateContainer {

    return useMemo(() => {
        const folderSelectionState = new FolderSelectionState(searchQueryResultsManager.totalResources);
        return {
            selectionState: folderSelectionState,
        };
    }, [searchQueryResultsManager.totalResources]);
}
