export const linksFilter = ({ content: text, regExes, contentType='plain' }) => {
    let content;
    if(contentType==='md') {
        const markupLinkRegexp = /\[.+?]\((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*))\)/igm;
        content = text.replace(markupLinkRegexp, (match, url) => {
            let resultedLinkTag = match;
            if (regExes.some((regex) => regex.test(url))) {
                resultedLinkTag = match.replace(/(\[.+?])\(.+?\)/, (match, linkText) => {
                    return `${linkText}()`;
                });
            }
            return resultedLinkTag;
        });
    } else if (contentType==='html') {
        const htmlLinkRegexp = /<a.*?\shref\s*=\s*(['"])(\s*https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\/{0,1}[-a-zA-Z0-9@:%._+~#=]{1,256}\b([-a-zA-Z0-9@:%_+.~#?&/=;]*)\s*)\1.*?\s*>/igm;
        content = text.replace(htmlLinkRegexp, (match, _, url) => {
            let resultedLinkTag = match;
            if (regExes.some((regex) => regex.test(url))) {
                resultedLinkTag = match.replace(/href=(['"])(.+?)\1/, (match, p1, p2) => {
                    return 'href="javascript:void(0)"';
                });
            }
            return resultedLinkTag;
        });
        // replace inline links
    } else {
        content = text;
    }

    const linkRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@:%_+.~#?&\/=]*)/igm;
    content = content.replace(linkRegexp, (url) => {
        let result = url;
        if (regExes.some((regex) => regex.test(url))) {
            const baseUrl = getBaseUrl(url);
            if(baseUrl) {
                result = `&lt; replaced link to ${baseUrl} &gt;`;
            }
        }
        return result;
    });
    
    return content;
};

function getBaseUrl(url) {
    const parser = URL ? new URL(url) : document.createElement('a');
    parser.href = url;
    return parser.origin;
}

