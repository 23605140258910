import React, { Fragment, FunctionComponent } from 'react';
import { useI18N } from 'common/i18n/useI18N';

import './questionnaire.view.less';
import { EditableQuestionnaire } from 'features/patient/questionnaire/questionnaire-view/EditableQuestionnaire';
import { QuestionnaireDetails } from './QuestionnaireDetails';
import { QuestionnaireScores } from './QuestionnaireScores';
import { QuestionnaireQuestions } from './QuestionnaireQuestions';
import { useQuestionnaireScoresValues } from './useQuestionnaireScoresValues';
import { QuestionnaireArchetype, QuestionnaireData } from 'models/QuestionnaireData';
import {
    usePreviousCompletedQuestionnaire
} from './usePreviousCompletedQuestionnaire';
import { UserNoteEntryView } from 'pages/clinical_portal/folder/_folderId/patient/user-notes/UserNoteEntryView';


export interface QuestionnaireViewProps {
    loading: boolean;
    isUpdating: boolean;
    isEditMode: boolean;
    folderId: number;
    questionnaireData: QuestionnaireData;
    onSubmit?: (params: any) => void;
    onCancel?: () => void;
    showHeader?: boolean;
    pathwayNamesWithEvent: [string, string][];
}

const QuestionnaireView: FunctionComponent<QuestionnaireViewProps> = (props) => {
    const {
        loading,
        isUpdating,
        isEditMode,
        folderId,
        questionnaireData,
        onSubmit,
        onCancel,
        showHeader = true,
        pathwayNamesWithEvent,
    } = props;

    const {
        name,
        nameOfIssuer,
        createdAt,
        dueBy,
        status,
        lastUpdated,
        completedBy,
        useQuestionNumber,
        isHidden,
        questionnaireScores,
        viewedQuestions,
        labels,
        sections,
        answers,
    } = questionnaireData || ({} as any);

    const questionnaireResponseData = questionnaireData?.questionnaireResponse;

    const previousResponse = usePreviousCompletedQuestionnaire(questionnaireResponseData);

    const scoreValues = useQuestionnaireScoresValues(questionnaireScores);
    const previousScoreValues = useQuestionnaireScoresValues(previousResponse.previousResponseScores);

    const _ = useI18N();

    if (loading) {
        return (
            <div className="message-view">
                <h3 className="spacer20">{_`Loading questionnaire...`}</h3>
            </div>
        );
    }

    if (isHidden) {
        return (
            <div className="message-view">
                <h3 className="spacer20">{_`Confidential`}</h3>
            </div>
        );
    }

    if (isUpdating) {
        return (
            <div className="message-view">
                <h3 className="spacer20">{_`Updating...`}</h3>
            </div>
        );
    }

    const questions = sections.flatMap((section, sectionIdx) => {
        const { questions } = section;
        return questions.map((question, questionIdx) => {
            return { ...question, questionIndex: `${sectionIdx}-${questionIdx}` };
        });
    }).map(({ question_number: questionNumber, id, ...restQuestion }) => ({
        questionDisplayNumber: useQuestionNumber
            ? questionNumber ? `__${questionNumber}__. ` : ''
            : `__${id}__. `,
        id,
        ...restQuestion,
    }));

    return (
        <Fragment>
            <div className="questionnaire-single">
                {showHeader &&
                <h3 className="spacer20">{_`Viewing ${name}`}</h3>
                }
                <QuestionnaireDetails
                    status={status}
                    createdAt={createdAt}
                    completedBy={questionnaireData?.questionnaireResponse?.content?.respondent_name}
                    completedAt={questionnaireData?.questionnaireResponse?.content?.status_changes?.completed_at}
                    lastUpdated={lastUpdated}
                    dueBy={dueBy}
                    labels={labels}
                    folderId={folderId}
                    nameOfIssuer={nameOfIssuer}
                    pathwayNamesWithEvent={pathwayNamesWithEvent}
                />
                {
                    isEditMode ? (
                        <EditableQuestionnaire
                            archetype={QuestionnaireArchetype.questionnaire}
                            useQuestionNumber={useQuestionNumber}
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                            sections={sections}
                        />
                    ) : (
                        <Fragment>
                            <QuestionnaireScores
                                status={status}
                                questionnaireScores={questionnaireScores}
                                previousResponseData={previousResponse}
                            />
                            <QuestionnaireQuestions questions={questions}
                                answers={answers}
                                viewedQuestions={viewedQuestions}
                                scoreValues={scoreValues}
                                previousResponseData={previousResponse}
                                previousScoreValues={previousScoreValues}
                            />
                        </Fragment>
                    )
                }
            </div>
            <UserNoteEntryView
                folderId={folderId}
                parentComposition={questionnaireData.questionnaireResponse}
                defaultTitle={'Questionnaire event'}
                defaultStatus={'reviewed'}
            />
        </Fragment>
    );
};

export default QuestionnaireView;
