import React from 'react';
import BasePage from 'components/page/base';

import _ from 'services/i18n';
import DataStore from 'services/data-store';

import UserMemberList from 'components/user/member-list';

/**
 * Extended Page Component for user_portal/documents
 **/

let listenIdx;

class ViewPage extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            orgteam: {}
        };
    }

    componentWillMount () {
        listenIdx = DataStore.getListener('me.admin', (value) => {
            this.setState({
                orgteam: value || {}
            });
        });
    }
    
    componentWillUnmount () {
        DataStore.removeListener(listenIdx);
    }

    pageTitle () {
        return _`Viewing Members | PHR Admin Portal`;
    }

    render () {
        const selectionType = this.state.orgteam.selectionType || '';
        // todo: remove bloated component UserMemberList - here is absolutely no need to encapsulate it as component, the code just should be placed on this page, as it is actually the page
        if (selectionType == 'organisation') {
            return (<UserMemberList listType="organisation" organisation={this.state.orgteam.organisation} />)
        }
        return (<UserMemberList listType="team" team={this.state.orgteam.team} />)
    }
}

export default ViewPage;
