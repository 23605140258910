import { GridSortState, SortOrder } from './index';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { standardDateTimeFormats } from '../../datetime/convertToDate';
import { defaultDateFormat } from '../../datetime/DateTime';
import { Reducer, useCallback, useEffect, useReducer } from 'react';

export type searchCallback = (item: unknown) => void;

export interface State<T> {
    sortOrder: GridSortState;
    filterState: Filter;
    fullList: T[];
    filteredAndSortedList: T[];
    pageList: T[];
    isLoading: boolean;
    currentPage: number;
    pagesTotal: number;
    perPage: number;
}
export type Filter = { [key: string]: string };
export type SortBy = GridSortState; // todo - replace;
export type Actions<T> =
    | {type: 'itemsLoadingStart'}
    | {type: 'itemsLoadingDone'; payload: T[]}
    | {type: 'sortingChange'; payload: { [key: string]: SortOrder }}
    | {type: 'filterChange'; payload: { [key: string]: string }}
    | {type: 'pageChange'; payload: number}
    | {type: 'search'; payload: searchCallback};
export function reducer<T>(state: State<T>, action: Actions<T>): State<T> {
    let newState;
    switch (action.type) {
    case 'itemsLoadingStart':
        newState = {
            ...state,
            isLoading: true,
        };
        break;
    case 'itemsLoadingDone':
        newState = processNewItems<T>(state, action.payload);
        break;
    case 'sortingChange':
        newState = processChangedSorting<T>(state, action.payload);
        break;
    case 'filterChange':
        newState = processFilterChanged<T>(state, action.payload);
        break;
    case 'pageChange':
        newState = processPageChanged<T>(state, action.payload);
        break;
    case 'search':
        newState = processSearch<T>(state, action.payload);
        break;
    default:
        newState = state;
    }
    return newState;
}
function processPageChanged<T>(state: State<T>, page: number): State<T> {
    return {
        ...state,
        pageList: getPage(page, state.perPage, state.filteredAndSortedList),
        currentPage: page,
    };
}
function processFilterChanged<T>(state: State<T>, filter: Filter): State<T> {
    if (isEqual(filter, state.filterState)) {
        return state;
    }
    const filteredList = filterList<T>(state.fullList, filter);
    const sortedList = sortList<T>(filteredList, state.sortOrder);
    return {
        ...state,
        pageList: getPage(1, state.perPage, sortedList),
        filteredAndSortedList: sortedList,
        currentPage: 1,
        pagesTotal: Math.ceil(sortedList.length / state.perPage),
        filterState: filter || {},
    };
}

function processSearch<T>(state: State<T>, callback): State<T> {
    const list = state.fullList.filter(callback);
    return {
        ...state,
        pageList: getPage(1, state.perPage, list),
        filteredAndSortedList: list
    };
}

function processChangedSorting<T>(state: State<T>, sortOrder: GridSortState): State<T> {
    if (isEqual(sortOrder, state.sortOrder)) {
        return state;
    }
    const sortedList = sortList(state.filteredAndSortedList, sortOrder);
    return {
        ...state,
        pageList: getPage(1, state.perPage, sortedList),
        filteredAndSortedList: sortedList,
        currentPage: 1,
        sortOrder: sortOrder || {},
    };
}
function processNewItems<T>(state: State<T>, list: T[]): State<T> {
    let filteredList = list;
    if(!isEmpty(state.filterState)) {
        filteredList = filterList<T>(list, state.filterState);
    }
    const sortedList = sortList<T>(filteredList, state.sortOrder);
    const totalPages = Math.ceil(filteredList.length / state.perPage);
    const currentPage = totalPages >= state.currentPage ? state.currentPage : totalPages || 1;
    return {
        ...state,
        isLoading: false,
        fullList: list,
        currentPage,
        pageList: getPage(currentPage, state.perPage, sortedList),
        filteredAndSortedList: sortedList,
        pagesTotal: Math.ceil(list.length / state.perPage),
    };
}
function getPage(page: number, perPage: number, list: any[]) {
    const start = (page -1) * perPage;
    return [...list].slice(start, start + perPage);
}
export const defaultSortHandlers = {
    date: (dateA, dateB, direction) => {
        const a = moment(dateA, standardDateTimeFormats[defaultDateFormat]).unix();
        const b = moment(dateB, standardDateTimeFormats[defaultDateFormat]).unix();
        return direction === SortOrder.ASC ? a - b : direction === SortOrder.DESC ? b - a : 0;
    },
};
const customSortHandlers = {
};
let filterOptions: GridFilterOptions = {
    handlers: {}
};

function sortList<T>(list: T[], sortBy: SortBy) {

    const sortKey: string = Object.keys(sortBy)[0];
    const sortVector = sortBy[sortKey];
    const customHandler = customSortHandlers[sortKey];
    (list as any).sort((a, b) => {
        if(customHandler) {
            return customHandler(a, b, sortVector);
        }
        const aValue = a[sortKey];
        const bValue = a[sortKey];
        return sortVector === SortOrder.ASC ?
            (aValue > bValue ? 1 : aValue < bValue ? -1 : 0) :
            SortOrder.DESC ? (aValue > b ? -1 : aValue < bValue ? 1 : 0) : 0;
    });

    return list;
}
function filterList<T>(list: any[], filter: Filter): T[] {

    return list.filter((item) => {
        const keys = Object.keys(filter);
        return keys.every((filterKey) => {
            if(filterOptions.handlers?.[filterKey]) {
                return filterOptions.handlers[filterKey](filter[filterKey], item[filterKey], item);
            }
            return defaultFilter(filter[filterKey], item[filterKey], item);
        });
    }) as T[];
}

/**
 *
 * @param filter {string}
 * @param field {string}
 * @param item {object}
 * @returns {boolean} - true if left in the list false if filtered
 */
const defaultFilter = (filter = '', field = '', item: object) => {
    const filterValue = filter.trim().toLowerCase();
    const value = String(field).toLowerCase();
    return filterValue === '' || value.includes(filterValue);
};


export const initialState: State<any> = {
    sortOrder: { date: SortOrder.ASC },
    filterState: {},
    filteredAndSortedList: [],
    fullList: [],
    pageList: [],
    isLoading: true,
    currentPage: 1,
    pagesTotal: 1,
    perPage: 10,
};
export interface GridFilterOptions {
    handlers?: {[key: string]: (filterValue: string, fieldValue: string | object, item: object) => boolean };
}
export interface GridSortOptions {
    handlers: {[key: string]: (a, b, direction) => number };
}
interface ListParams<T> {
    list: Array<T>;
    loading: boolean;
    sortOrder?: GridSortState;
    filterState?: Filter;
    sortOptions?: GridSortOptions;
    filterOptions?: GridFilterOptions;
    perPage?: number;
}
const dummy = {};
export const usePaginatedSortedAndFilteredList = <T extends {}>({ list, sortOrder = dummy, perPage, filterState = dummy, loading , sortOptions, filterOptions: filterOpts }: ListParams<T>) => {

    Object.assign(customSortHandlers, sortOptions?.handlers || {});
    filterOptions = filterOpts || filterOptions;
    initialState.sortOrder = sortOrder;
    initialState.filterState = filterState;
    initialState.perPage = perPage;

    const [state, dispatch] = useReducer<Reducer<State<T>, Actions<T>>>(reducer, initialState as State<T>);

    const onFilter = useCallback((filterBy: Filter) => {
        dispatch({ type: 'filterChange', payload: filterBy });
    }, []);

    const onSearch = useCallback((callback: searchCallback) => {
        dispatch({ type: 'search', payload: callback });
    }, []);

    const onSort = useCallback((sortBy: SortBy) => {
        dispatch({ type: 'sortingChange', payload: sortBy });
    }, []);

    const onPageChange = useCallback((page: number) => {
        dispatch({ type: 'pageChange', payload: page });
    }, []);

    useEffect(() => {
        if(list) {
            dispatch({ type: 'itemsLoadingDone', payload: list });
        }
    }, [list]);
    useEffect(() => {
        dispatch({ type: 'sortingChange', payload: sortOrder });
    }, [sortOrder]);
    useEffect(() => {
        dispatch({ type: 'filterChange', payload: filterState });
    }, [filterState]);

    useEffect(() => {
        if(loading) {
            dispatch({ type: 'itemsLoadingStart' });
        }
    }, [loading]);

    return {
        list: state.pageList,
        sortOrder: state.sortOrder,
        filterState: state.filterState,
        page: state.currentPage,
        pagesTotal: state.pagesTotal,
        onSort,
        onFilter,
        onSearch,
        onPageChange,
        isLoading: state.isLoading,
    };
};
