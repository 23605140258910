import React, { FC } from 'react';
import Select, { components } from 'react-select';
// interfaces
import { AdvancedSearchCriterionListInputMultiSelectionProps } from './AdvancedSearchCriterionListInputMultiSelection.interface';

function MultiSelectOption(props) {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
}

export const AdvancedSearchCriterionListInputMultiSelection: FC<AdvancedSearchCriterionListInputMultiSelectionProps> =
    (props) => {
        return (
            <Select
                value={props.value}
                options={props.options}
                onChange={props.onChange}
                components={{ Option: MultiSelectOption }}
                hideSelectedOptions={false}
                isMulti={true}
                closeMenuOnSelect={false}
                className={'react-select-input-container'}
                classNamePrefix={'react-select-input'}
            />
        );
    };
