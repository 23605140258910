import React, { FC, Fragment, useState } from 'react';
// utils
import { toFormattedData } from 'features/patients/advanced-search/AdvancedSearchResultsTableDisplay/AdvancedSearchResultsTableDisplay';
import {
    AdvancedSearchDocumentConfig,
    AdvancedSearchQueryDefinition,
    AdvancedSearchQueryResult,
    getDocumentSearchConfig,
} from 'models/AdvancedSearchDefinition';

type AdvancedSearchResultsExplorerProps = {
    documentConfigs: AdvancedSearchDocumentConfig[];
    searchDefinition: AdvancedSearchQueryDefinition;
    result: AdvancedSearchQueryResult;
};

export const AdvancedSearchResultsExplorer: FC<AdvancedSearchResultsExplorerProps> =
    (props) => {
        const documentType = props.result.documentType;
        const documentConfig = getDocumentSearchConfig(
            props.documentConfigs,
            documentType,
        );

        return (
            <div className={'advanced-search-query-definition'}>
                <div className={'advanced-search-query-request'}>
                    <label>
                        <strong>{`${documentConfig.name} results`}</strong>
                    </label>
                    <AdvancedSearchResultExplorer
                        documentConfigs={props.documentConfigs}
                        documentConfig={documentConfig}
                        searchDefinition={props.searchDefinition}
                        result={props.result}
                        allowCollapse={false}
                    />
                </div>
            </div>
        );
    };

type AdvancedSearchResultExplorerProps = {
    documentConfigs: AdvancedSearchDocumentConfig[];
    documentConfig: AdvancedSearchDocumentConfig;
    searchDefinition: AdvancedSearchQueryDefinition;
    result: AdvancedSearchQueryResult;
    allowCollapse: boolean;
    resultIndex?: number;
};

const AdvancedSearchResultExplorer: FC<AdvancedSearchResultExplorerProps> = (
    props,
) => {
    return (
        <Fragment>
            {!!props.resultIndex && (
                <label>{`${props.documentConfig.name} #${props.resultIndex}:`}</label>
            )}
            {
                <AdvancedSearchResultDetailsDisplay
                    documentConfig={props.documentConfig}
                    searchDefinition={props.searchDefinition}
                    result={props.result}
                />
            }
            {props.searchDefinition.children?.map((childSearch, index) => (
                <AdvancedSearchResultChildExplorer
                    key={index}
                    documentConfigs={props.documentConfigs}
                    searchDefinition={childSearch}
                    resultWrapper={props.result.children[index]}
                />
            ))}
        </Fragment>
    );
};

type AdvancedSearchResultChildExplorerProps = {
    documentConfigs: AdvancedSearchDocumentConfig[];
    searchDefinition: AdvancedSearchQueryDefinition;
    resultWrapper: {
        documentType: string;
        results: AdvancedSearchQueryResult[];
    };
};

const AdvancedSearchResultChildExplorer: FC<AdvancedSearchResultChildExplorerProps> =
    (props) => {
        const documentType = props.resultWrapper.documentType;
        const documentConfig = getDocumentSearchConfig(
            props.documentConfigs,
            documentType,
        );
        const [expanded, setExpanded] = useState<boolean>(false);

        return (
            <div className={'advanced-search-query-request'}>
                <div className={'advanced-search-query-row'}>
                    <button
                        type="button"
                        className={
                            'btn btn-xs glyphicon glyphicon-' +
                            (expanded ? 'chevron-down' : 'chevron-right')
                        }
                        onClick={() => setExpanded(!expanded)}
                    />
                    <label>
                        <strong>{`${documentConfig.name} results`}</strong>
                    </label>
                </div>
                {expanded && (
                    <div>
                        {props.resultWrapper.results.length > 0 &&
                            props.resultWrapper.results.map((result, index) => (
                                <AdvancedSearchResultExplorer
                                    key={index}
                                    documentConfigs={props.documentConfigs}
                                    documentConfig={documentConfig}
                                    searchDefinition={props.searchDefinition}
                                    result={result}
                                    allowCollapse={true}
                                    resultIndex={
                                        props.resultWrapper.results.length == 1
                                            ? undefined
                                            : index + 1
                                    }
                                />
                            ))}
                        {props.resultWrapper.results.length == 0 && (
                            <label>None</label>
                        )}
                    </div>
                )}
            </div>
        );
    };

type AdvancedSearchResultDetailsDisplayProps = {
    documentConfig: AdvancedSearchDocumentConfig;
    searchDefinition: AdvancedSearchQueryDefinition;
    result: AdvancedSearchQueryResult;
};

const AdvancedSearchResultDetailsDisplay: FC<AdvancedSearchResultDetailsDisplayProps> =
    (props) => {
        return (
            // TODO is advanced-search-query-request-config class needed?
            <div className={'advanced-search-query-request-config'}>
                {props.searchDefinition.outputs.map((output, index) => (
                    <AdvancedSearchResultDetailDisplay
                        key={index}
                        documentConfig={props.documentConfig}
                        result={props.result}
                        output={output}
                    />
                ))}
            </div>
        );
    };

type AdvancedSearchResultDetailDisplayProps = {
    documentConfig: AdvancedSearchDocumentConfig;
    result: AdvancedSearchQueryResult;
    output: string;
};

const AdvancedSearchResultDetailDisplay: FC<AdvancedSearchResultDetailDisplayProps> =
    (props) => {
        const criterionConfig = props.documentConfig.fields.find(
            (criterion) => criterion.path == props.output,
        );
        const data = toFormattedData(
            props.output,
            props.result,
            criterionConfig,
        );

        return (
            <label>
                {`${criterionConfig.name}: ${data == null ? 'unknown' : data}`}
            </label>
        );
    };
