import React from 'react';
import PropTypes from 'prop-types'
import {Link} from 'react-router';
import {Row, Column, ResponsiveTable} from 'ui';
import classNames from 'classnames';

/**
 * Displays a user-goal composition on a MyPathway timeline.
 * Props:
 * - composition = Required. An object containing the composition data to be displayed.
 * - folderLink = Required. Base URL for the patient's folder in the portal. This is used to construct links to specific archetypes.
 */
export const Goal = ({composition, folderLink}) => {

    const title = 'Daily physical activity';
    const description = `You have received a prescription from your physiotherapist for a minimum of ${composition.givenValue} daily steps.`;

    const link = `${folderLink}/goals/${composition.uuid}`;
    const goal = composition.document.content;

    const body = (
        <div className={classNames('composition-goal', {'composition-goal_inactive': !composition.isActive})}>
            <h4>{title}</h4>
            {(!goal.type || goal.type === 'steps_data') && <div className='composition-goal__content'>
                {description}
            </div>}
            {(goal.type && goal.type !== 'steps_data') && <div className='composition-goal__content'>
                {goal.description}
            </div>}
            {composition.isScore &&
            <div>
                <div>
                    <span>{`You have made ${composition.value} steps.`}</span>
                </div>
                {composition.isGoalReached &&
                <div>
                    <span>You're doing very well! Keep it up!</span>
                    {composition.isActive ? <span className="composition-goal__smile icon-smile"></span> : <span className="composition-goal__smile icon-smile-gray"></span>}
                </div>
                }
                {!composition.isGoalReached &&
                <div>
                    <span>It is very important that you walk more. It's for your health!</span>
                    {composition.isActive ? <span className="composition-goal__smile icon-sad"></span> : <span className="composition-goal__smile icon-sad-gray"></span>}
                </div>
                }
            </div>}
        </div>
    );

    return (
        <Link to={link}>
            {body}
        </Link>
    );
};

Goal.propTypes = {
    composition: PropTypes.object.isRequired,
    folderLink: PropTypes.string.isRequired,
    compositionQueryString: PropTypes.string
};

export default Goal;
