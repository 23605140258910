import React, {
    Dispatch,
    Fragment,
    FunctionComponent,
    MutableRefObject, SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import ReactDOM from 'react-dom';
import _ from 'services/i18n';
import { Link } from 'react-router';
import { useWindowSize } from '../../useWindowResize';
import './modal.less';

export interface ModalButton {
    label: string;
    type?: 'link' | 'callback'; // deprecated - do not use it!
    style?: string;
    classNames?: string;
    callback?: () => any;
    url?: string;
    disabled?: boolean;
}

interface ModalProps {
    size?: 'sm' | 'md' | 'lg';
    busy?: boolean;
    onClose?: () => void;
    title?: string;
    buttons?: ModalButton[];
    patientBanner?: () => any;
    className?: string;
    content?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = (props) => {

    const {
        children,
        size = 'md',
        className = '',
        onClose = () => void 0,
        title = '',
        buttons = [],
        busy = false,
        content = true,
        patientBanner = () => null,
    } = props;

    const [el, setEl] = useState<any>(null);

    const windowSize = useWindowSize();
    const modalRef = useRef(null);
    const dialogRef = useRef(null);

    useEffect(() => {
        const modal: HTMLElement = document.getElementById('modalRoot');
        const el: HTMLDivElement = document.createElement('div');

        document.documentElement.className = document.documentElement.className + ' modal-shown';

        el.className = 'modal-backdrop';
        modal.appendChild(el);

        setEl(el);

        return () => {
            modal.removeChild(el);
            document.documentElement.className = document.documentElement.className.replace(/ ?modal-shown/, '');
        };
    }, []);


    // flexbox and overflow scroll not always work well in ie 11
    // see answer here: https://stackoverflow.com/questions/44635857/flexbox-max-height-issue-with-ie11
    // this solves the issue
    const isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
    const bodyStyleIE11Fix = isIE11 ? { maxHeight: windowSize.height - 250 } : {};

    if (!el) {
        return null;
    }

    return (
        ReactDOM.createPortal(
            <div className={'modal show ' + className} tabIndex={-1} role='dialog' ref={modalRef}>
                <div className={'modal-dialog modal-' + size} ref={dialogRef}>
                    <div className='modal-content'>
                        <ModalBusy busy={busy} />
                        {content && (
                            <Fragment>
                                <ModalHeader onClose={onClose} title={title} patientHeader={patientBanner} />
                                <div className='modal-body' style={bodyStyleIE11Fix}>
                                    {children}
                                </div>
                                <ModalFooterButtons buttons={buttons} />
                            </Fragment>
                        )}
                        {!content && children}
                    </div>
                </div>
            </div>,
            el
        )
    );
};
export default Modal;

const ModalBusy = (props) => {
    if (props.busy) {
        return (
            <div className="modal-busy">
                <span className="glyphicon glyphicon-lock" />
            </div>
        );
    }

    return (<noscript />);
};

// This is exported only to allow our tests to run.
// The display names of stateless components are not preserved when
//  collecting code coverage so we need to search by constructor instead.
export const ModalHeader = ({ patientHeader, noClose = false, onClose, title }) => {
    return (
        <div className='modal-header'>
            {noClose ? null : (
                <button type='button' className='close' onClick={onClose} aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                </button>
            )}
            <h4 className='modal-title'>{title || _`Modal Title`}</h4>
            {patientHeader && patientHeader()}
        </div>
    );
};

// This is exported only to allow our tests to run.
// The display names of stateless components are not preserved when
//  collecting code coverage so we need to search by constructor instead.
export const ModalFooterButtons: FunctionComponent<{buttons: ModalButton[]}> = (props) => {
    const buttons = (props.buttons || []).map((item, idx) => {
        if (item.callback) {
            return (
                <button
                    key={idx}
                    type="button"
                    className={`btn btn-${item.style || 'default'} ` + item.classNames}
                    onClick={item.callback}
                    disabled={item.disabled}
                >
                    {item.label}
                </button>
            );
        }

        if (item.url) {
            return (
                <Link
                    key={idx}
                    to={item.url}
                    className={`btn btn-${item.style || 'default'} ` + item.classNames}
                >
                    {item.label}
                </Link>
            );
        }
    });

    return (
        <div className='modal-footer'>{buttons}</div>
    );
};

type ModalShowHideFn = (show: boolean) => void;
export type ModalShowHideFnRef = React.MutableRefObject<ModalShowHideFn>;

export const useShowHideModalFn = (): [ModalShowHideFn, ModalShowHideFnRef] => {
    const ref = useRef<ModalShowHideFn>(() => null);
    return [
        (show: boolean) => ref.current(show),
        ref
    ];
};

export const useShowHideModalState = (showHideFnRef: ModalShowHideFnRef): [boolean, () => void] => {
    const [showing, setShowing] = useState<boolean>(false);
    showHideFnRef.current = setShowing;

    const close = () => setShowing(false);
    return [showing, close];
};
