import React, { Fragment } from 'react';
import _ from 'services/i18n';

interface PopupFormProps {
    onChange: (e) => unknown,
    popupTitle: string,
    popupContent: string
}

export const PopupContentForm = ({
    onChange,
    popupTitle,
    popupContent
}: PopupFormProps) => {
    return (
        <Fragment>
            <div className='form-group'>
                <label htmlFor='popupTitle'>{_`Popup Title`}: </label>
                <input
                    placeholder={_`Please enter popup title...`}
                    type="text"
                    className={'form-control popup-title'}
                    name={'popupTitle'}
                    value={popupTitle}
                    onChange={(e) => { onChange({ popupTitle: e.target.value }) }}
                />
            </div>
            <div className='form-group'>
                <label htmlFor='popupContent'>{_`Popup Content`}: </label>
                <input
                    placeholder={_`Please enter popup content...`}
                    type="text"
                    className={'form-control popup-content'}
                    name={'popupContent'}
                    value={popupContent}
                    onChange={(e) => { onChange({ popupContent: e.target.value }) }}
                />
            </div>
        </Fragment>
    )
}