import React from 'react';
import { Link } from 'react-router';
import _ from 'services/i18n';
import DataStore from 'services/data-store';
import BackgroundImageChecker from 'components/background-image-checker'

var MediaQuery = require('react-responsive');

/**
 * Renders the footer of the main page template
 */

let listenIdx;

export default class extends React.Component {
    constructor (props) {
        let currentUser, emails;
        super(props);

        currentUser = DataStore.get('currentUser') || {};

        emails = (currentUser.emails || [])[0] || {};

        this.state = {
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
            preferred_name: currentUser.preferred_name,
            email: emails.email
        };
    }

    componentDidMount () {
        listenIdx = DataStore.addListener('currentUser',() => {
            let currentUser = (DataStore.get('currentUser') || {}), emails;

            emails = (currentUser.email || [])[0] || {};

            this.setState({
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                preferred_name: currentUser.preferred_name,
                email: emails.email
            });
        });
    }

    componentWillUnmount () {
        DataStore.removeListener(listenIdx);
    }

    renderSidebarMenu (mobileToggle) {
        let menuItems = this.props.menu.filter((item, idx) => {
            if (item.hide) {
                return false;
            }

            if (!this.props.hasOwnProperty('onCheckAllowed')) {
                return true;
            }

            return this.props.onCheckAllowed(item);
        });

        return menuItems.map((item, idx) => {
            let activeClass = '';

            if (window.location.pathname == item.link) {
                activeClass = 'active';
            }

            if (item.matching) {
                if (window.location.pathname.substr(0,item.link.length) == item.link) {
                    activeClass = 'matching';
                }
            }

            if (mobileToggle) {
                return (
                    <li key={idx}>
                        <Link className={activeClass} onClick={this.props.onToggleSidebar} to={item.link}>{item.label}</Link>
                    </li>
                )
            } else {
                return (
                    <li key={idx}>
                        <Link className={activeClass} to={item.link}>{item.label}</Link>
                    </li>
                )
            }
        });
    }
    /**
     * @private
     */
    render () {
        const userPhoto = `/files${this.props.link}/api/currentUserPhoto`;
        const photoUrl = `url(${userPhoto}?random=${Date.now()})`;
        let showSidebar = true;

        if (this.props.hasOwnProperty('loading')) {
            return (
                <div className="sidebar">
                    <div className="brand-name">
                        Loading...
                    </div>
                </div>
            );
        }

        this.props.menu.forEach((item, idx) => {
            let activeClass = '';

            if (window.location.pathname == item.link) {
                if (item.disableSidebar) {
                    showSidebar = false;
                }
            }

            if (item.matching) {
                if (window.location.pathname.substr(0,item.link.length) == item.link) {
                    if (item.disableSidebar) {
                        showSidebar = false;
                    }
                }
            }
        });

        if (!showSidebar) {
            return null;
        }

        if (this.props.hasOwnProperty('collapse') && this.props.collapse) {
            return (
                <div className="sidebarmenuonly">
                    <div className="menu-collapse" onClick={this.props.onToggleSidebar}>
                        <i className="glyphicon glyphicon-menu-hamburger" />
                    </div>
                </div>
            );
        }

        return (
            <div className="sidebar">
                <div className="brand-name">
                    <div className="menu-collapse" onClick={this.props.onToggleSidebar}>
                        <i className="glyphicon glyphicon-menu-hamburger" />
                    </div>
                    <Link to={this.props.link || '#'}>{this.props.title || _`Portal`}</Link>
                </div>
                <BackgroundImageChecker imageUrl={photoUrl}>
                    <div className="user-photo">
                    </div>
                </BackgroundImageChecker>
                <div className="user-name">
                    {this.state.preferred_name
                        ?this.state.preferred_name
                        :(this.state.first_name
                            ?(this.state.first_name + " " + this.state.last_name)
                            :this.state.email)}
                </div>
                <div className="presidebar-items">
                    {this.props.sideBarItems?this.props.sideBarItems():null}
                </div>
                <div className="sidebar-menu">
                    <MediaQuery query='(max-width: 768px)'>
                        <ul>
                            {this.renderSidebarMenu(true)}
                        </ul>
                    </MediaQuery>
                    <MediaQuery query='(min-width: 769px)'>
                        <ul>
                            {this.renderSidebarMenu(false)}
                        </ul>
                    </MediaQuery>
                </div>
            </div>
        );
    }
}
