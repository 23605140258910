import React, {Component, FunctionComponent} from 'react';
import _ from 'services/i18n';
import Markdown from 'components/ui/markdown';
import * as lodash from 'lodash';
import classNames from 'classnames';
import questionnaireService from 'services/questionnaire.service';
import {QuestionnaireArchetype, QuestionType} from 'models/QuestionnaireData';

interface EditableQuestionnaireProps {
    sections: any;
    archetype: QuestionnaireArchetype;
    useQuestionNumber: boolean;
    onSubmit: (params: any) => void;
    onCancel: () => void;
}

export const EditableQuestionnaire: FunctionComponent<EditableQuestionnaireProps> = (props) => {
    const { sections, archetype, useQuestionNumber, onSubmit, onCancel } = props;
    return (
        <div className='editable-questionnaire'>
            <QuestionnaireEditForm
                sections={sections}
                archetype={archetype}
                useQuestionNumber={useQuestionNumber}
                onSubmit={onSubmit}
                onCancel={onCancel}/>
        </div>
    );
};

class QuestionnaireEditForm extends Component<EditableQuestionnaireProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            sections: [],
            isFormValid: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    handleEdit(answer, input, questionId) {
        
        if (input.type !== QuestionType.multiselect) {
            input.answer = answer;
        } else if (!input.answer.includes(answer)) {
            input.answer.push(answer);
        } else if (input.answer.includes(answer)) {
            const index = input.answer.indexOf(answer);
            input.answer.splice(index, 1);
        }

        this.cleanValidationErrorsForQuestion(questionId);
        this.onEnterValidate(answer, input);

        if (this.props.archetype === 'questionnaire') {
            this.processConditions(answer, input);
        }
        this.validateForm();
        this.forceUpdate();
    }

    cleanValidationErrorsForQuestion(questionId) {
        loops:
        for (let ln = this.state.sections.length; ln--;) {
            const section = this.state.sections[ln];
            for (let ln = section.questions.length; ln--;) {
                const question = section.questions[ln];
                if (question.id === questionId) {
                    question.invalid = false;
                    question.invalidError = '';
                    break loops;
                }
            }
        }
    }

    validateForm() {
        let valid = true;
        this.state.sections.forEach((section) => {
            if (section.hidden || section.disabled) {
                return;
            }
            section.questions.forEach((question) => {

                if (question.hidden || question.disabled) {
                    return;
                }

                if (this.props.archetype === 'questionnaire') {
                    let answered = false;
                    let invalid = false;
                    question.inputs.forEach((input) => {
                        if (input.hidden || input.disabled) {
                            return;
                        }
                        if (input.answer) {
                            answered = true;
                        }
                        if (input.invalid) {
                            invalid = true;
                            console.log('input is invalid!', input);
                        }
                    });

                    if (!answered && question.mandatory) {
                        valid = false;
                        question.invalid = true;
                        question.invalidError = 'Valid answer is required';
                        return;
                    }

                    if (invalid) {
                        valid = false;
                        question.invalid = true;
                    }
                } else {
                    if (!question.answer && question.mandatory) {
                        valid = false;
                        question.invalid = true;
                        question.invalidError = 'Valid answer is required';
                        return;
                    }
                    if (question.invalid && question.answer) {
                        valid = false;
                    }
                }
            });
        });


        this.setState({
            isFormValid: valid
        });

        return valid;
    }

    onEnterValidate(answer, input) {

        if (input.type === 'number') {

            if (!/^-?\d*\.?\d+$/.test(answer)) {
                input.invalid = true;
                input.invalidError = 'Only digits allowed';
                return;
            }

            if (input.options) {
                if (input.options.max && input.options.max < answer) {
                    input.invalid = true;
                    input.invalidError = `Max allowed value is ${input.options.max}`;
                    return;
                }
                if (input.options.min && input.options.min > answer) {
                    input.invalid = true;
                    input.invalidError = `Min allowed value is ${input.options.min}`;
                    return;
                }
            }

            input.invalid = false;
            input.invalidError = '';
        }
    }

    processConditions(answer, input) {
        if (input.conditionalLinks && input.conditionalLinks.length) {
            const deactivatedLinks = new Set();
            input.conditionalLinks.forEach(({ condition, link }) => {
                // Since we have only negative conditions ( hide | disable )
                // so for not process the same link twice if it was already deactivated in this loop
                // it (the link) will be added in the set
                // Otherwise we can get the case when it will set as hidden and then set as not hidden
                // during this same loop, moreover - the order of conditions will matter
                if (deactivatedLinks.has(link)) {
                    return;
                }
                const fn = getConditionalExpression(condition.comparison);
                const isHidden = fn(answer, String(condition.value));
                if (isHidden) {
                    deactivatedLinks.add(link);
                }
                if (condition.type === 'hide') {
                    link.hidden = isHidden;
                } else if (condition.type === 'disable') {
                    link.disabled = isHidden;
                } else {
                    console.warn('unknown condition type', condition.type);
                }

                this.clearValuesForDisabledAndHidden(link);
                const inputs = link.questions ? this.flatten([link]) : link.inputs || [];

                inputs.forEach((inp) => {
                    this.processConditions(inp.answer, inp);
                });
            });
        }
    }

    clearValuesForDisabledAndHidden(link) {
        if (link.hidden || link.disabled) { // can be for input, question or section
            link.answer && (link.answer = '');
            link.invalid && (link.invalid = false);
            link.invalidError && (link.invalidError = '');

            if (link.questions) { // link is section?
                link.questions.forEach(question => {
                    question.answer && (question.answer = '');
                    question.invalid = false;
                    question.invalidError = '';
                    if (question.inputs) {
                        question.inputs.forEach((input) => {
                            input.answer = '';
                            input.invalid = false;
                            input.invalidError = '';
                        });
                    }
                });
            }
            if (link.inputs) {
                link.inputs.forEach((input) => {
                    input.answer = '';
                    input.invalid = false;
                    input.invalidError = '';
                });
            }

        }
    }

    componentDidMount() {
        const sections = lodash.cloneDeep(this.props.sections);
        this.findAndLinkConditions(sections);
        const inputs = this.flatten(sections);
        inputs.forEach((input) => {
            input.answer = (input.default === '99999' ? '' : input.default) || '';
            if (input.type == QuestionType.multiselect) {input.answer = [];}
        });
        inputs.forEach((inp) => {
            this.processConditions(inp.answer, inp);
        });
        this.setState({ sections }, () => {
            this.validateForm();
        });
    }

    findAndLinkConditions(sections) {

        if (this.props.archetype === 'coopQuestionnaire') {
            return;
        }

        const conditions = this.findAllConditionals(sections);
        if (!conditions.length) {
            return;
        }
        conditions.forEach((cond) => {
            cond.conditional.forEach((condition) => {
                const input = this.findInput(sections, condition.question, condition.input) || {};
                input.conditionalLinks = input.conditionalLinks || [];
                input.conditionalLinks.push({
                    condition, // comparison, type, value
                    link: cond.link
                });
            });
        });
    }

    findInput(sections, questionId, inputId) {
        for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
            const questions = sections[sectionIndex].questions || [];
            const question = questions.find((question) => {
                return questionId === question.id;
            });
            if (!question) {
                continue;
            }
            if (this.props.archetype === 'coopQuestionnaire') {
                return question;
            }
            const desiredInput = question.inputs.find((input) => {
                return inputId === input.id;
            });
            if (desiredInput) {
                return desiredInput;
            }
        }
        console.log(`questionId=${questionId} inputId=${inputId} , input wasn't found`);
        return undefined;
    }

    flatten(sections) {
        return sections.reduce((list, section) => {
            section.questions.forEach((question) => {
                if (this.props.archetype === 'questionnaire') {
                    if (question.inputs) {
                        question.inputs.forEach((input) => {
                            list.push(input);
                        });
                    }
                } else {
                    list.push(question);
                }
            });
            return list;
        }, []);
    }

    findAllConditionals(sections) {

        if (this.props.archetype === 'coopQuestionnaire') {
            return;
        }

        const conditional = [];
        sections.forEach((section) => {

            if (section.conditional) {
                conditional.push({ conditional: section.conditional, link: section });
            }
            section.questions.forEach((question) => {
                if (question.conditional) {
                    conditional.push({ conditional: question.conditional, link: question });
                }
                if (question.inputs) {
                    question.inputs.forEach((input) => {
                        if (input.conditional) {
                            conditional.push({ conditional: input.conditional, link: input });
                        }
                    });
                }
            });
        });
        return conditional;
    }

    handleSubmit(e) {

        e.preventDefault();

        if (!this.state.isFormValid) {
            return;
        }

        this.props.onSubmit({
            answers: this.fillAnswers()
        });
    }

    fillAnswers() {
        const answers = [];

        if (this.props.archetype === 'questionnaire') {
            this.state.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    const answer = {
                        question_id: `${question.id}`,
                        inputs: []
                    };
                    question.inputs.forEach((input) => {
                        if (input.type == QuestionType.multiselect) {
                            answer.inputs.push({
                                id: input.id,
                                value: hasAnswer(input.answer) ? preprocessAnswer(input) : questionnaireService.DEFAULT_ANSWER_VALUE
                            });
                        } else {
                            answer.inputs.push({
                                id: input.id,
                                value: hasAnswer(input.answer) ? `${preprocessAnswer(input)}` : questionnaireService.DEFAULT_ANSWER_VALUE
                            });}
                    });
                    answers.push(answer);
                });
            });
        } else {
            this.state.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    const answer = {
                        question_id: `${question.id}`,
                        value: hasAnswer(question.answer) ? `${question.answer}` : questionnaireService.DEFAULT_ANSWER_VALUE
                    };
                    answers.push(answer);
                });
            });
        }

        return answers;
    }

    render() {

        const {
            sections,
            isFormValid
        } = this.state;

        const {
            onCancel,
            archetype,
            useQuestionNumber,
        } = this.props;

        return (
            <div className="questionnaire-edit-form">
                <h4 className="spacer-top28">{_`Individual Questions, Answers and Scores`}</h4>
                <div className="table-responsive">
                    <form onSubmit={this.handleSubmit}>
                        {sections && (
                            <QuestionnaireEditableSections
                                archetype={archetype}
                                sections={sections}
                                useQuestionNumber={useQuestionNumber}
                                onEdit={this.handleEdit}
                            />
                        )}
                        <div className="button-row">
                            <button className="btn btn-primary" disabled={!isFormValid}>
                                Submit
                            </button>
                            <button className="btn btn-default" type="button" onClick={onCancel}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

class QuestionnaireEditableSections extends Component<any, any> {

    render() {
        const { sections, onEdit, archetype, useQuestionNumber } = this.props;
        const sectionBlocks = sections.reduce((list, section, si) => {
            if (section.hidden) {
                return list;
            }

            const rows = section.questions.reduce((questionsList, question, i) => {
                if (question.hidden) {
                    return questionsList;
                }

                const questionValidationClassName = classNames({
                    'red-text': true
                });

                const questionDisplayNumber = useQuestionNumber
                    ? question.question_number ? `__${question.question_number}__. ` : ''
                    : `__${question.id}__. `;
                const markdownContent = `${questionDisplayNumber}${question.question}`;

                const sectionInputsDisabled = shouldDisableInput(section);
                const item = (
                    <tr key={si + '_' + i}>
                        <td>
                            <Markdown sanitize={false}>
                                {`${markdownContent} ${question.mandatory ? '<span class="red-text">*</span>' : ''}`}
                            </Markdown>
                            <span className={questionValidationClassName}>{question.invalidError}</span>
                        </td>
                        <td><EditableQuestion archetype={archetype}
                            disabled={sectionInputsDisabled}
                            question={question}
                            onEdit={onEdit} /></td>
                    </tr>
                );

                questionsList.push(item);
                return questionsList;
            }, []);


            const preamble = section.preamble;

            const sectionBlock = (
                <div key={si} className={'questionnaire-edit-section'}>
                    <Markdown sanitize={false}>{preamble}</Markdown>
                    <table className="table table-bordered table-condensed table-striped table-nomargin">
                        <thead>
                            <tr>
                                <th style={{ width: '60%' }}>{_`Question`}</th>
                                <th>{_`Answer`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            );

            list.push(sectionBlock);

            return list;
        }, []);

        return (
            <div>
                {sectionBlocks}
            </div>
        );
    }
}

class GenericQnInput extends React.Component<any, any> {
    ref: any;
    constructor(props) {
        super(props);
        this.onChanged = this.onChanged.bind(this);
    }
    onChanged(value) {
        this.props.onEdit(value, this.props.input, this.props.questionId);
    }
}

class McqInput extends GenericQnInput {


    render() {
        const { input } = this.props;

        return (
            <div className="form-group questionnaire-input">
                {input.label && <label><Markdown sanitize={false}>{input.label}</Markdown></label>}
                <select ref={r => this.ref = r}
                    className="form-control"
                    onChange={() => this.onChanged(this.ref.value !== '--' ? this.ref.value : undefined)}
                    value={input.answer == null ? '' : input.answer}
                    disabled={this.props.disabled || input.disabled}>
                    <option>{_`--`}</option>
                    {input.options.map((v, i) => <option key={i} value={i}>{v}</option>)}
                </select>
            </div>
        );
    }
}


class MultipleSelect extends GenericQnInput {
    render() {
        const { input } = this.props;
        return (
            <div className="questionnaire-input">
                {input.label && <label><Markdown sanitize={false}>{input.label}</Markdown></label>}
                {input.options.map((v, i) => {
                    return (
                        <div key={i} className='multi-select'>
                            <input className='multi-select-checkbox' 
                                onClick={() => this.onChanged(i.toString())}
                                type='checkbox' value={i} name={v}/>
                            <label className='multi-select-label'>{v}</label>
                        </div>
                    );
                })}
            </div>
        );
    }
}

class SliderInput extends GenericQnInput {
    render() {
        const { input } = this.props;

        return (
            <div className="slider-input questionnaire-input">
                <div className="slider-input__row">
                    <div className="slider-input__cell">
                        {input.label && <label><Markdown sanitize={false}>{input.label}</Markdown></label>}
                        <input max={input.options && input.options.max}
                            min={input.options && input.options.min}
                            step={1}
                            type="range"
                            ref={(r) => this.ref = r}
                            value={input.answer == null ? '' : input.answer}
                            disabled={this.props.disabled || input.disabled}
                            onChange={() => this.onChanged(this.ref.value)}/>
                    </div>
                    <div className="slider-input__cell slider-input__value">
                        {input.answer || '--'}
                    </div>
                </div>
                <div className="slider-input__min-label">
                    <Markdown sanitize={false}>{input.options.min_label}</Markdown>
                </div>
                <div className="slider-input__max-label">
                    <Markdown sanitize={false}>{input.options.max_label}</Markdown>
                </div>
            </div>
        );
    }
}

class NumberInput extends GenericQnInput {

    decimals: any;
    shouldHandleDecimals: any;
    regex: any;
    constructor(props) {
        super(props);

        this.decimals = this.props.input?.options?.decimals || 0;
        this.shouldHandleDecimals = this.decimals > 0;

        const NUMBER_REGEXP = this.shouldHandleDecimals ? '^\\s*(\\-|\\+)?(\\d+|(\\d*(\\.\\d*)))\\s*$' : '^\\s*(\\-|\\+)?(\\d+)\\s*$';
        this.regex = new RegExp(NUMBER_REGEXP);

        this.onChanged = this.onChanged.bind(this);
    }

    onChanged(uiValue) {

        if (!uiValue) {
            super.onChanged(uiValue);
            return;
        }

        if (uiValue.indexOf('-') === 0) {
            return;
        }

        if (!this.regex.test(uiValue)) {
            return;
        }

        if (this.shouldHandleDecimals) {
            if (uiValue[uiValue.length - 1] !== '.') {

                const decimalPart = uiValue.split('.')[1];

                if (typeof decimalPart !== 'undefined' && decimalPart.length > this.decimals) {
                    return;
                }
            }
        }

        super.onChanged(uiValue);
    }

    render() {
        const { input } = this.props;

        const className = classNames({
            'form-group': true,
            'questionnaire-input': true,
            'has-error': input.invalid
        });

        return (
            <div className={className}>
                {input.label && <label><Markdown sanitize={false}>{input.label}</Markdown></label>}
                <input ref={(r) => this.ref = r}
                    onChange={() => this.onChanged(this.ref.value)}
                    className="form-control"
                    value={input.answer == null ? '' : input.answer}
                    disabled={this.props.disabled || input.disabled}
                    type="text"/>
            </div>
        );
    }
}

class TextInput extends GenericQnInput {
    render() {
        const { input } = this.props;

        const className = classNames({
            'form-group': true,
            'questionnaire-input': true,
            'has-error': input.invalid
        });

        return (
            <div className={className}>
                {input.label && <label><Markdown sanitize={false}>{input.label}</Markdown></label>}
                <input ref={(r) => this.ref = r}
                    onChange={() => this.onChanged(this.ref.value)}
                    className="form-control"
                    disabled={this.props.disabled || input.disabled}
                    value={input.answer == null ? '' : input.answer}
                    type="text"/>
            </div>
        );
    }
}

class BooleanInput extends GenericQnInput {
    render() {
        const { input } = this.props;

        return (
            <div className="boolean-input questionnaire-input">

                {input.label ? (
                    <label>
                        <input ref={(r) => this.ref = r}
                            disabled={this.props.disabled || input.disabled}
                            type="checkbox"
                            checked={input.answer == null ? false : input.answer}
                            onChange={() => this.onChanged(this.ref.checked)}/>
                        <Markdown sanitize={false}>{input.label}</Markdown>
                    </label>
                ) : (
                    <input ref={(r) => this.ref = r}
                        disabled={this.props.disabled || input.disabled}
                        type="checkbox"
                        checked={input.answer == null ? false : input.answer}
                        onChange={() => this.onChanged(this.ref.checked)}/>
                )}
            </div>
        );
    }
}

export function getInputByType(type) {
    switch (type) {
        case 'mcq':
            return McqInput;
        case 'slider':
            return SliderInput;
        case 'text':
            return TextInput;
        case 'number':
            return NumberInput;
        case 'boolean':
            return BooleanInput;
        case QuestionType.multiselect:
            return MultipleSelect;
        default:
            return null;
    }
}

function EditableQuestion(props) {

    const { question, onEdit, disabled, archetype } = props;

    const questionInputsDisabled = shouldDisableInput(question);
    if (archetype === 'questionnaire') {
        return <QuestionInputs question={question} onEdit={onEdit} disabled={disabled || questionInputsDisabled}/>;
    }

    const CustomInput = getInputByType(question.type);
    return <CustomInput questionId={question.id} input={question} onEdit={onEdit} disabled={disabled || questionInputsDisabled}/>;
}

function QuestionInputs(props) {

    const { question, onEdit, disabled } = props;

    return (
        <div>
            {
                question.inputs.reduce((list, input, i) => {
                    if (input.hidden) {
                        return list;
                    }
                    const CustomInput = getInputByType(input.type);
                    const inputDisabled = shouldDisableInput(input);
                    const Element = (
                        <div key={i}>
                            <CustomInput input={input}
                                questionId={question.id}
                                onEdit={onEdit}
                                disabled={disabled || inputDisabled}/>
                            {input.invalidError && <span className="red-text">{input.invalidError}</span>}
                        </div>
                    );
                    list.push(Element);
                    return list;
                }, [])
            }
        </div>
    );
}

function getConditionalExpression(comparison) {
    switch (comparison) {
        case '=':
            return (a, b) => {
                if (typeof a === 'boolean') {
                    a = a ? "true" : "false";
                }
                return a === b;
            };
        case '>=':
            return (a, b) => {
                return a >= b;
            };
        case '<=':
            return (a, b) => {
                return a <= b;
            };
        case '>':
            return (a, b) => {
                return a > b;
            };
        case '<':
            return (a, b) => {
                return a < b;
            };
        case '<>':
            return (a, b) => {
                if (typeof a === 'boolean') {
                    a = a ? "true" : "false";
                }
                return a !== b;
            };
        default:
            return () => {
                console.log(`Unknown comparison ${comparison}`);
                return false;
            };
    }
}

function hasAnswer(value) {
    return value !== undefined && value !== null && value !== '' && value !== false;
}

function preprocessAnswer(input) {
    if (input.type === 'number' && typeof input?.options?.decimals !== 'undefined') {
        return parseFloat(input.answer).toFixed(input.options.decimals || 0);
    }
    return input.answer;
}

function shouldDisableInput(item) {
    return item.disabled || item.hidden;
}
