import marked from 'marked';
import React from 'react';
import DOMPurify from 'dompurify';

const Markdown = (props) => {
    const { children, sanitize } = props;

    let htmlString = children ?? '';
    if(sanitize) {
        htmlString = DOMPurify.sanitize(htmlString, null) ?? '';
    }

    const html = {
        __html: marked(htmlString)
    };

    return (
        <div className="markdown-content" dangerouslySetInnerHTML={html} />
    );
};

export default Markdown;
