import { useMemo } from 'react';

export const useQuestionnaireScoresValues = (scores) => {
    return useMemo(() => {
        if (!scores) {
            return [];
        }
        const nonEmptyScoreValues = scores.map(scoresRow => {
            return {
                name: scoresRow.score_name,
                scoresValues: scoresRow.input_values ? scoresRow.input_values.map(({
                    question_id: questionId,
                    input_id: inputId,
                    value_string: stringValue,
                }) => ({
                    questionId,
                    inputId,
                    value: `${stringValue ?? ''}`,
                })).filter(({ inputId }) => inputId === 1) : [],
            };
        }).filter(({ scoresValues }) => scoresValues.length > 0);

        return nonEmptyScoreValues.length ? nonEmptyScoreValues[0].scoresValues : [];
    }, [scores]);
};
