import React, { FC } from 'react';
// components
import AdvancedSearchResultsExplorer from './AdvancedSearchResultsExplorer';
// interfaces
import { AdvancedSearchResultsExplorerModalProps } from './AdvancedSearchResultsExplorerModal.interface';
// TODO figure out what classes need to be extracted
import '../AdvancedSearchQueryDefinition.scss';
import { ModalDialogSelfClosing } from 'common/ModalDialogSelfClosing';

const AdvancedSearchResultsExplorerModal: FC<AdvancedSearchResultsExplorerModalProps> =
    (props) => {
        return (
            <ModalDialogSelfClosing
                showHideFnRef={props.showHideFnRef}
                size={'lg'}
                title={'Explore Results'}
                overflow={true}>
                <AdvancedSearchResultsExplorer
                    documentConfigs={props.documentConfigs}
                    searchDefinition={props.searchDefinition}
                    result={props.result}
                />
            </ModalDialogSelfClosing>
        );
    };

export default AdvancedSearchResultsExplorerModal;
