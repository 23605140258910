import React from 'react';
import BasePage from 'components/page/base';

import QuestionnaireTemplateCreateComponent from 'components/questionnaire-template/create';
import _ from 'services/i18n';

/**
 * Extended Page Component for user_portal/documents
 **/
class CreateQuestionnaireTemplatePage extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Create new Questionnaire Template | PHR Admin Portal`;
    }

    render () {
        return (<QuestionnaireTemplateCreateComponent {...this.props} />);
    }
}

export default CreateQuestionnaireTemplatePage;
