import React from 'react';
import PropTypes from 'prop-types'
import lodash from 'lodash';

import NameField from './name-field';
import NhsNumberField from './nhs-number-field';
import DateTimeField from './date-time-field';
import LocationField from './location-field';
import ReasonableAdjustmentField from './reasonable-adjustment-field';

/**
 * Displays a data row in the body of the transport and interpreter worklist table.
 * 
 * Props (data):
 * - appointment = Required. The appointment composition being displayed in this row.
 * - demographics = Optional. The demographics composition describing the patient who has this appointment. If it is not loaded yet, this property should be undefined. If there is no demographics for this patient, this should be an empty object.
 * - updatingTransport = Optional. Boolean indicating if the transport arrangements for the appointment are still being udpated. Assumed to be false if undefined.
 * - updatingInterpreter = Optional. Boolean indicating if the interpreter arrangements for the appointment are still being udpated. Assumed to be false if undefined.
 * - failed = Optional. Boolean indicating if an attempt to update the appointment has failed. Assumed to be false if undefined.
 * 
 * Props (functions):
 * - onArrangedTransport = Required. Callback to be triggered when the user has arranged transport for the appointment.
 * - onArrangedInterpreter = Required. Callback to be triggered when the user has arranged an interpreter for the appointment.
 * - onUndoTransport = Required. Callback to be triggered when the user has undone the transport arrangements for the appointment.
 * - onUndoInterpreter = Required. Callback to be triggered when the user has undone the interpreter arrangements for the appointment.
 * 
 */
const WorklistEntry = ({appointment, demographics, updatingTransport, updatingInterpreter, failed, onArrangedTransport, onArrangedInterpreter, onUndoTransport, onUndoInterpreter}) => {

    const transportRequired = !!lodash.get(appointment, 'content.transport.required', false);
    const transportArranged = !!lodash.get(appointment, 'content.transport.arranged', false);
    const transportDetails = lodash.get(appointment, 'content.transport.details', '');

    const interpreterRequired = !!lodash.get(appointment, 'content.interpreter.required', false);
    const interpreterArranged = !!lodash.get(appointment, 'content.interpreter.arranged', false);
    const interpreterDetails = lodash.get(appointment, 'content.interpreter.details', '');
    return (
        <tr>
            <td><NameField      demographics={demographics} /></td>

            <td><NhsNumberField demographics={demographics} /></td>

            <td><DateTimeField  appointment={appointment} /></td>

            <td><LocationField  appointment={appointment} /></td>

            <td className='text-center'>
                <ReasonableAdjustmentField
                    type='transport'
                    required={transportRequired}
                    arranged={transportArranged}
                    details={transportDetails}
                    updating={updatingTransport}
                    failed={failed}
                    onArranged={onArrangedTransport}
                    onUndo={onUndoTransport}
                />
            </td>

            <td className='text-center'>
                <ReasonableAdjustmentField
                    type='interpreter'
                    required={interpreterRequired}
                    arranged={interpreterArranged}
                    details={interpreterDetails}
                    updating={updatingInterpreter}
                    failed={failed}
                    onArranged={onArrangedInterpreter}
                    onUndo={onUndoInterpreter}
                />
            </td>
        </tr>
    );
};

export default WorklistEntry;

WorklistEntry.propTypes = {
    appointment:                PropTypes.object.isRequired,
    demographics:               PropTypes.object,
    updatingTransport:          PropTypes.bool,
    updatingInterpreter:        PropTypes.bool,
    failed:                     PropTypes.bool,

    onArrangedTransport:        PropTypes.func.isRequired,
    onArrangedInterpreter:      PropTypes.func.isRequired,
    onUndoTransport:            PropTypes.func.isRequired,
    onUndoInterpreter:          PropTypes.func.isRequired
};
