import React, { FC } from 'react';
// interfaces
import {  AdvancedSearchCriterionAbsoluteDateInputProps } from './AdvancedSearchCriterionAbsoluteDateInput.interface';

export const AdvancedSearchCriterionAbsoluteDateInput: FC<AdvancedSearchCriterionAbsoluteDateInputProps> =
    (props) => {
        const valueWithDefault = props.value ? props.value : '';

        return (
            <input
                type="date"
                className={'form-control w-auto'}
                max="2999-12-31"
                value={valueWithDefault}
                onChange={e => props.onChange(e.target.value)}
            />
        );
    };
