import React, { useState, useEffect } from 'react';
import _ from 'services/i18n';
import CollectionSelect from '../CollectionSelect/CollectionSelect';
import DepartmentSelect from 'features/department/components/DepartmentSelect/DepartmentSelect';
import SearchIcon from '@material-ui/icons/Search';
import './ResourcesSearchQueryToolbar.scss';
import ResourceTypeSelect from '../ResourceTypeSelect/ResourceTypeSelect';
import useFilteredCollections from '../CollectionSelect/useFilteredCollections';
import useDebounce from '../../../../../shared/common/useDebounce';
import { ResourcesSearchCriteria } from '../SearchResourcesComponent/SearchResourcesComponent';

const DEBOUNCE_DELAY_IN_MS = 500;

type ResourcesSearchQueryToolbarProps = {
    searchCriteria: ResourcesSearchCriteria;
    setSearchCriteria: React.Dispatch<React.SetStateAction<ResourcesSearchCriteria>>;
    reloadSearch: () => void;
}

const ResourcesSearchQueryToolbar = ({ searchCriteria, setSearchCriteria, reloadSearch }: ResourcesSearchQueryToolbarProps) => {
    const [keyword, setKeyword] = useState(searchCriteria.keyword);
    const collectionsList = useFilteredCollections(searchCriteria.department);
    const debouncedKeyword = useDebounce<string>(keyword, DEBOUNCE_DELAY_IN_MS);

    useEffect(() => {
        setSearchCriteria(currentCriteria => {
            return ({ ...currentCriteria, keyword: debouncedKeyword === '' ? null : debouncedKeyword });
        });
    }, [debouncedKeyword, setSearchCriteria]);

    return (
        <div className="resources-search-toolbar-container"> 
            <div className='resource-search-item'>
                <input type="text" className="form-control dark-input" name="keyword"
                    onChange={e => setKeyword(e.target.value)} value={keyword} placeholder={_`Enter keyword...`}/>
            </div>
            <div className='resource-search-item'>
                <DepartmentSelect departmentUUID={searchCriteria?.department} setDepartmentUUID={(department) => {
                    setSearchCriteria({ ...searchCriteria, department: department });}
                } />
            </div>
            <div className='resource-search-item'>
                <CollectionSelect categoryList={collectionsList} category_id={searchCriteria?.collection} onChange={(collection) => setSearchCriteria({ ...searchCriteria, collection: collection })} />
            </div>
            <div className='resource-search-item'>
                <ResourceTypeSelect resourceType={searchCriteria?.resourceType} setResourceType={(resourceType) => setSearchCriteria({ ...searchCriteria, resourceType: resourceType })}/>
            </div>
            <button
                id='reload-search-resources-btn'
                title={_`Search`}
                className={'btn btn-xs btn-default icon-button search-btn'}
                onClick={() => reloadSearch()}
            >
                <SearchIcon style={{ fontSize: 20, color: '#ffffff' }} />
            </button>
        </div>
    );
};

export default ResourcesSearchQueryToolbar;
