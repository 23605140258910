import { TeamPrefCsvImportParam } from 'models/TeamPreferences';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { capitalize } from 'lodash';

export interface FormControlsDataForCsvParams {
    label: string;
    name: string;
    value: string;
    options: {
        label: string;
        value: string;
    }[];
}

const getAdditionalCsvFormFieldsData = async (csvImportOption: TeamPrefCsvImportParam): Promise<FormControlsDataForCsvParams[]> => {
    if(!csvImportOption.parameters) {
        return [];
    }
    return csvImportOption.parameters.reduce((arr, param) => {
        if(!param.options) {
            return arr;
        }
        const options = param.options.map((opt) => {
            return {
                label: capitalize(opt),
                value: opt,
            };
        });
        arr.push({
            label: capitalize(param.name),
            name: param.name,
            value: options[0].value,
            options,
        });
        return arr;
    }, [] as FormControlsDataForCsvParams[]);
};




// exported fn

export const getUploadURL = (teamId: number, roleUUID: string, script: string): string => `upload/teams/${teamId}/csv-import/${script}?using_role_uuid=${roleUUID}`;

export const useAdditionalFormFieldsData = (teamPrefCsvImportParam?: TeamPrefCsvImportParam): {
    additionalCsvFormFieldsList: FormControlsDataForCsvParams[];
    handleChangeCsvParams: (e: ChangeEvent<HTMLSelectElement>) => void;
} => {
    const [additionalCsvFormFieldsData, setCSVImportAdditionalParams] = useState<Map<string, FormControlsDataForCsvParams>>(new Map);
    const cachedAdditionalParams = useRef({});

    const handleChangeCsvParams = useCallback( ({ target: { name, value } }: ChangeEvent<HTMLSelectElement>) => {
        setCSVImportAdditionalParams((state) => {
            const newVal = new Map([
                ...Array.from(state)
            ]);
            const formInput = newVal.get(name);
            if(formInput) {
                formInput.value = value;
            }
            return newVal;
        });
    }, []);

    useEffect(() => {
        let isCancelled = false;
        if(!teamPrefCsvImportParam) {
            return;
        }
        if(cachedAdditionalParams.current[teamPrefCsvImportParam.endpoint]) {
            setCSVImportAdditionalParams(cachedAdditionalParams[teamPrefCsvImportParam.endpoint]);
            return;
        }
        getAdditionalCsvFormFieldsData(teamPrefCsvImportParam)
            .then((controlsData) => {
                if(isCancelled) {
                    return;
                }
                const newData = new Map(controlsData.map((d => [d.name, d])));
                cachedAdditionalParams.current[teamPrefCsvImportParam.endpoint] = newData;
                setCSVImportAdditionalParams(newData);
            });
        return () => {
            isCancelled = true;
        };
    }, [teamPrefCsvImportParam]);

    return {
        additionalCsvFormFieldsList: useMemo(() => Array.from(additionalCsvFormFieldsData.values()), [additionalCsvFormFieldsData]),
        handleChangeCsvParams,
    };
};
