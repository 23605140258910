import React, { Component } from 'react';
export default class BackgroundImageChecker extends Component<any, any> {

    constructor(props){
        super(props);

        this.state = {
            hasErrors:false
        };
    }

    doCheck(){
        let img = document.createElement('img');

        img.onload = (() => {
            this.setState({
                hasErrors: false
            });
        }).bind(this);

        img.onerror = (() => {
            this.setState({
                hasErrors: true
            });
        }).bind(this);

        img.src = this.extractUrl(this.props.imageUrl);
    }

    componentDidMount(){
        this.doCheck();
    }

    componentWillReceiveProps(newProps){
        if (this.props.imageUrl === newProps.imageUrl){
            return;
        }
        this.doCheck();
    }

    extractUrl(backgroundImgUrl){
        return backgroundImgUrl && backgroundImgUrl.match(/url\(([^)]+)\)/)[1].replace(/"/g,'') || '';
    }

    render() {

        const {
            hasErrors
        } = this.state;
        
        const {
            imageUrl,
            placeholderUrl,
            children
        } = this.props;
        
        const newChildProps = {
            style:{
                backgroundImage: !hasErrors ? imageUrl : placeholderUrl
            }
        };
        return React.cloneElement((children as any), newChildProps);
    }
}
