import { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
// hooks
import { TEAM_ROLES_GROUPS, useTeamRoles } from 'common/useTeamRoles';
// services
import { cliniciansService } from 'services/clinicians.service';
// interfaces
import { UserFolder } from 'models/UserFolder';

type InitialBulkOperationFiltersData = {
    clinician?: UserFolder;
    bulkOperationName?: string;
    dateRange?: [Moment, Moment] | null;
};

const defaultInitials: Required<InitialBulkOperationFiltersData> = {
    clinician: undefined,
    bulkOperationName: '',
    dateRange: [moment().subtract(1, 'year'), moment()],
};

export function useBulkOperationFilters(
    initial?: InitialBulkOperationFiltersData,
) {
    const [clinician, setClinician] = useState<UserFolder | undefined>(
        initial?.clinician || defaultInitials.clinician,
    );
    const [bulkOperationName, setBulkOperationName] = useState<string>(
        initial?.bulkOperationName || defaultInitials.bulkOperationName,
    );
    const [dateRange, setDateRange] = useState<[Moment, Moment] | null>(
        () => initial?.dateRange || defaultInitials.dateRange,
    );

    useEffect(() => {
        if (initial?.clinician) {
            setClinician(initial.clinician);
        }
        if (initial?.bulkOperationName) {
            setBulkOperationName(initial.bulkOperationName);
        }
        if(initial?.dateRange) {
            setDateRange(initial.dateRange);
        }
    }, [initial]);

    const changeClinician = useCallback(
        (clinician: UserFolder | undefined) => {
            setClinician(clinician);
        },
        [],
    );

    const changeBulkOperationName = useCallback((boName: string) => {
        setBulkOperationName(boName);
    }, []);

    const changeDateRange = useCallback((range: [Moment, Moment] | null) => {
        setDateRange(range);
    }, []);

    return {
        clinician,
        bulkOperationName,
        dateRange,
        changeClinician,
        changeBulkOperationName,
        changeDateRange,
    };
}

export const useTeamClinicianList = () => {
    const [, teamRolesGroups] = useTeamRoles();

    const [clinicianList, setClinicianList] = useState([]);

    useEffect(() => {
        if (!teamRolesGroups) {
            return;
        }

        const clinicalRoles = teamRolesGroups.get(TEAM_ROLES_GROUPS.CLINICIAN);

        if (!clinicalRoles) {
            return;
        }

        const clinicalRoleList = Array.from(clinicalRoles).map(
            ({ uuid }) => uuid,
        );

        async function getClinicianList(clinicalRoleList) {
            const clinicalListResult = await cliniciansService.list({
                roleUuids: clinicalRoleList,
                limit: Infinity,
                offset: 0,
            }) as UserFolder[];

            setClinicianList(clinicalListResult);
        }

        getClinicianList(clinicalRoleList);
    }, [teamRolesGroups]);

    return clinicianList;
};
