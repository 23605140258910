import React from 'react';
import BaseComponent from 'components/BaseComponent';

/**
 * Base class for any type of input field in the form panel.
 * The props below are mostly not actually used by this base class.
 * However, they are expected to be used by all sub-classes.
 *
 * Props (data):
 *  - id = Required. An identifier for the input field.
 *  - label = Required. A human-readable label identifying/describing the field.
 *  - required = Optional. Boolean indicating if a value must be entered in this field. Defaults to false.
 *  - readOnly = Optional. Boolean flag indicating if the input element is readOnly. The value will not be reported to the parent form.
 *  - disabled = Optional. Boolean flag indicating if the input element is disabled. The value will not be reported to the parent form.
 *
 * Props (handlers):
 *  - onChange = Required. A function called when the value changes. Parameters are: id, value, valid. NOTE: This is provided automatically by the parent form if used directly inside FormPanel.
 *  - onValidate = Optional. A custom validation function which will be called to validate the value. It should return true if the value is valid, or if not it should return a string containing an error message, or an array of multiple error messages. This will only be called if any automated validation passes.
 *
 */
export default class BaseInput extends BaseComponent {
    constructor (props) {
        super(props);
        
        if (this.props.id == '') {
            console.warn('id prop must not be empty.');
        }
        
        if (this.props.label == '') {
            console.warn('label prop must not be empty.');
        }
        
        if (this.props.onChange == null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }
    }
    
    componentWillMount () {
        // Sub-classes will need to override this method to perform initial validation if appropriate,
        //  and to notify the parent of the initial value after that.
    }
    
    componentWillUnmount () {
        // Notify the parent that this form element is gone.
        // If sub-classes override this method they will need to do this.
        if (this.props.onChange) {
            this.props.onChange(this.props.id, undefined, undefined);
        }
    }
    
    componentWillReceiveProps (newProps) {
        if (newProps.id == '') {
            console.warn('id prop must not be empty.');
        }
        
        if (newProps.label == '') {
            console.warn('label prop must not be empty.');
        }
        
        if (newProps.onChange == null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }
    }
    
    /**
     * Run the custom validator on the given value and return the result.
     * Returns true (no error) if there is no custom validator.
     */
    getCustomValidationResult (value) {
        if (this.props.onValidate) {
            let result = this.props.onValidate(value);
            if (result != true) {
                if (result instanceof Array) {
                    if (result.length > 0) {
                        return result;
                    }
                    console.warn('Empty array returned by custom validator. It should return a non-empty string or a non-empty array of strings if validation failed, or true if validation passed.');
                    
                } else if (result instanceof String) {
                    if (result.length > 0) {
                        return [result];
                    }
                    console.warn('Empty string returned by custom validator. It should return a non-empty string or a non-empty array of strings if validation failed, or true if validation passed.');
                    
                } else {
                    console.warn('Unrecognised type or value returned from custom validator. It should return a non-empty string or a non-empty array of strings if validation failed, or true if validation passed.');
                }
                
                return [_`Please enter a valid value.`];
            }
        }
        
        return true;
    }
}

