import React, { useRef, FC, CSSProperties } from "react";

type IFrameProps = {
  srcDoc: string;
  className?: string;
  style?: CSSProperties;
  useScrollHeight?:boolean;
};

export const Iframe: FC<IFrameProps> = (props) => {
  const {className, style, srcDoc, useScrollHeight} = props;

  const ref = useRef();

  const adjustHeight = () => {
    const iframe: any = ref && ref.current;

    if (iframe) {
      if (useScrollHeight) {
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
      }
      iframe.contentWindow.document.body.style.margin = 0;
    }
  };

  return (
      <iframe
          ref={ref}
          className={className}
          style={style}
          srcDoc={srcDoc}
          onLoad={adjustHeight}
      />
  );
};
