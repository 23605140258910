import { useCallback, useEffect, useState } from 'react';

export function select(arrOfItems, perPage, currentPage) {
    let i1, i2;
    if(!arrOfItems || !arrOfItems.length) {
        return [];
    }
    if(arrOfItems.length < (perPage * currentPage)) {
        i1 = arrOfItems.length - ((arrOfItems.length % perPage) || perPage);
        i2 = arrOfItems.length;
    } else {
        i1 = perPage * (currentPage - 1);
        i2 = perPage * currentPage;
    }
    return arrOfItems.slice(i1, i2);
}

const getPaginatedItems = (items = [], perPage, currentPage) => {
    if(!items || !items.length) {
        return {
            items: [],
            totalPages: 0,
        };
    }
    return {
        items: select(items, perPage, currentPage),
        totalPages: Math.ceil(items.length / perPage),
    };
};


export const usePagination = ({ items, perPage = 10, currentPage = 1 }): {page: number; total: number; onPageChange: any; items: any[]} => {
    const [internalCurrent, setCurrentPage] = useState(currentPage || 1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageItems, setPageItems] = useState([]);

    useEffect(() => {
        if(!items || !items.length) {
            setPageItems([]);
            setTotalPages(0);
        }
        const { items: selectedItems, totalPages } = getPaginatedItems(items, perPage, internalCurrent);
        setPageItems(selectedItems);
        setTotalPages(totalPages);
    }, [internalCurrent, perPage, items]);

    useEffect(() => {
        setCurrentPage(currentPage);
    }, [currentPage]);

    const onPageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    return {
        page: internalCurrent,
        total: totalPages,
        onPageChange,
        items: pageItems
    };
};
