import React from 'react';
import BasePage from 'components/page/base';
import lodash from 'lodash';

import transportAndInterpreterService from 'services/transport-and-interpreter.service';
import { demographicsService } from 'services/demographics.service';

import TransportAndInterpreterWorklist from 'components/worklists/transport-and-interpreter';

export default class TransportAndInterpreterWorklistPage extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            // Boolean indicating if we're currently loading the page data for
            //  the first time. The user cannot do anything until this is
            //  complete.
            loadingInitial: true,

            // Boolean indicating if we're loading additional records on an
            //  existing page. The user can still view and modify other items
            //  while the new items are being loaded.
            loadingMore: false,

            // Boolean indicating if an error occurred during initial loading.
            // This situation will prevent the user doing anything with the page.
            loadingInitialError: false,

            // Boolean indicating if an error occurred during subsequent loading.
            // The user may still be able to work with the existing content.
            loadingMoreError: false,

            // The contents of the worklist loaded so far.
            // This will include items which have been completed until the
            //  page is reloaded. This allows the user to un-check items which
            //  might have been checked by mistake.
            // This list is sorted by appointment date/time, soonest first.
            worklist: [],

            // The total number of incomplete items on the full worklist.
            // This includes everything on the server, not just our local list.
            totalRemainingWorklistSize: 0,

            // A map of demographics information for patients mentioned in the worklist.
            // This maps folder ID to the corresponding demographics composition.
            demographicsLookup: new Map(),

            // The UUIDs of appointments for which the transport arrangements
            //  are currently being updated.
            updatingTransport: [],

            // The UUIDs of appointments for which the interpreter arrangements
            //  are currently being updated.
            updatingInterpreter: [],

            // The UUIDs of appointments we've failed to update.
            failed: []
        };

        // The amount by which the worklist display size will increase each time
        //  the "Show More..." button is clicked.
        this.worklistDisplaySizeIncrement = 5;
    }

    /**
     * Get the title of this page, to be displayed in the browser title bar.
     *
     * @return {string} The title of this page.
     */
    pageTitle () {
        return 'Transport & Interpreter Worklist | PHR Clinical Portal';
    }

    /**
     * This is called by React after the page component has appeared on screen.
     * We load start loading here rather than before mounting so that there is
     *  less delay before something gets displayed.
     */
    componentDidMount() {
        return this.$setState({
            loadingInitial: true,
            loadingInitialError: false
        }).
        then(() => {
            return this.loadWorklist(this.worklistDisplaySizeIncrement);
        }).
        then(() => {
            return this.$setState({
                loadingInitial: false
            });
        }).
        catch((err) => {
            console.warn(err);
            return this.$setState({
                loadingInitialError: true
            });
        });
    }

    /**
     * Load the worklist from the server, and merge the results into our existing list.
     * All existing items will be kept on the list. Any new ones will be updated.
     *
     * @param {number} numRecords The number of worklist items to request from the server.
     * @return {Promise} The promise will resolve if loading was successful. It will reject with an error otherwise.
     */
    loadWorklist (numRecords) {
        return transportAndInterpreterService.list({perPage: numRecords}).
        then(({count, results}) => {
            return this.$setState({
                totalRemainingWorklistSize: count
            }).
            then(() => {
                return this.mergeWorklist(results);
            });
        });
    }

    /**
     * Merge the results of a new worklist query into our existing work list.
     * This will update any items it already has, and append the rest to the end.
     * This will not remove any items from our existing list. This ensures that
     *  they do no disappear unexpectedly.
     *
     * @param {array} appointments The results array from a worklist query. Each element should be an appointment composition.
     * @return {Promise} Resolves when the worklist has been merged.
     */
    mergeWorklist (appointments) {
        return this.$setState((prevState, props) => {
            const worklist = lodash.clone(prevState.worklist);
            const folderIdsProcessed = [];

            // Go through each new worklist item.
            appointments.forEach((newItem) => {
                // Do we already have this item?
                const index = worklist.findIndex((oldItem) => {
                    if (oldItem.uuid === newItem.uuid) {
                        return true;
                    }
                });
                if (index >= 0) {
                    // Update our existing item.
                    worklist[index] = newItem;
                    return;

                }

                // Add the item to our new worklist array.
                worklist.push(newItem);

                // Lookup the demographics data for the new item.
                const folderId = lodash.get(newItem, 'folder_id');
                if (folderId) {
                    if (folderIdsProcessed.indexOf(folderId) < 0) {
                        // Note: We're deliberately discarding the promise here.
                        // Just let the demographics load in the background.
                        this.loadDemographics(folderId);
                        folderIdsProcessed.push(folderId);
                    }
                } else {
                    console.warn(`Missing or invalid folder ID in appointment composition ${newItem.uuid}`);
                }
            });

            return {worklist};
        });
    }

    /**
     * Update a single appointment in our local worklist.
     * It will be ignored if an appointment with the same UUID does not already
     *  exist in our local worklist.
     * If the update changes the worklist item from incomplete to complete, or
     *  vice versa, this will decrement/increment the stored size of the the
     *  remaining worklist.
     *
     * Note: This assumes that the appointment will not have changed folders,
     *  and as such will not reload demographics.
     *
     * @param {object} updatedAppointment The updated appointment composition.
     * @return {Promise} The promise will resolve when the update is complete, or if the update was skipped due to the appointment not existing.
     */
    updateWorklistItem (updatedAppointment) {
        if (!updatedAppointment || !updatedAppointment.uuid) {
            return Promise.reject('Cannot update empty appointment, or appointment without a UUID.');
        }

        return this.$setState((prevState) => {
            // Make sure we already had the appointment in our worklist.
            const index = prevState.worklist.findIndex((item) => {
                if (item.uuid === updatedAppointment.uuid) {
                    return true;
                }
            });
            if (index < 0) {
                return {};
            }
            const oldAppointment = prevState.worklist[index];

            // Update the entry in the worklist.
            const worklist = lodash.clone(prevState.worklist);
            worklist[index] = updatedAppointment;

            // If there was a change of completion then update the worklist size accordingly.
            let totalRemainingWorklistSize = prevState.totalRemainingWorklistSize;
            const updatedAppointmentCompleted = this.isItemCompleted(updatedAppointment);
            if (this.isItemCompleted(oldAppointment)) {
                if (!updatedAppointmentCompleted) {
                    // Appointment has gone from completed to incomplete.
                    // This puts it back on the official worklist.
                    totalRemainingWorklistSize++;
                }
            } else if (updatedAppointmentCompleted) {
                // Appointment has gone from incomplete to completed.
                // This means it is not longer on the official worklist.
                if (totalRemainingWorklistSize > 0) {
                    totalRemainingWorklistSize--;
                }
            }

            return {
                worklist,
                totalRemainingWorklistSize
            };
        });
    }


    /**
     * Get the number of items on our local worklist which have been completed.
     * This is based on current state.
     *
     * @return {number} The number of items on our local copy of the worklist which are completed; i.e. technically no longer worklist items.
     */
    getNumCompletedItems () {
        let numCompletedItems = 0;
        this.state.worklist.forEach((appointment) => {
            if (this.isItemCompleted(appointment)) {
                numCompletedItems++;
            }
        });
        return numCompletedItems;
    }

    /**
     * Check if the given appointment composition is complete for the purposes of the worklist.
     * A 'completed' item is one where transport has been arranged if required,
     *  and an interpreter has been arranged if required. An item which doesn't
     *  require either is considered complete, as it will no longer appear in
     *  the server worklist query, even though it may still be in our local worklist.
     *
     * @param {object} appointment The appointment composition to check.
     * @return {bool} True if the appointment represents a completed worklist item, or false otherwise.
     */
    isItemCompleted (appointment) {
        if (lodash.get(appointment, 'content.transport.required', false)) {
            if (!lodash.get(appointment, 'content.transport.arranged', false)) {
                return false;
            }
        }

        if (lodash.get(appointment, 'content.interpreter.required', false)) {
            if (!lodash.get(appointment, 'content.interpreter.arranged', false)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Fetch demographics data for the specified patient folder and store it in state.
     * Note that this will not reload demographics data which is already in the lookup map.
     *
     * @param {number} folderId The ID of the folder to load demographics data from.
     * @return {Promise} A promise which resolves when the demographics data has loaded, or when any associated error has been handled.
     */
    loadDemographics (folderId) {
        // Nothing to do if we already have demographics for this folder.
        if (this.state.demographicsLookup.has(folderId)) {
            return Promise.resolve();
        }

        return demographicsService.getFirst({folderId, returnFullComposition:true}).
            then((demographics) => {
                return this.setDemographics(folderId, demographics);
            }).
            catch((err) => {
                console.warn(`Failed to load demographics for folder ${folderId}.`);
                console.warn(err);

                return this.setDemographics(folderId, {});
            });
    }

    /**
     * Set the demographics data associated with the given folder in state.
     * This adds it to the map if it does not already exist, or updates it otherwise.
     * An empty object can be provided to indicate that the demographics data
     *  was missing or could not be loaded. This will only be stored if the map
     *  does not already contain demographics data for that folder ID.
     *
     * @param {number} folderId The ID of the folder which demographics data has been loaded from.
     * @param {object} demographics The demographics composition to store. If this is an empty object then it will only be stored if there isn't already an entry for this folder ID.
     * @return {Promise} The promise resolves when the state has been updated.
     */
    setDemographics (folderId, demographics) {
        return this.$setState((prevState) => {
            // Don't overwrite existing data with an empty object.
            if (lodash.isEmpty(demographics) && prevState.demographicsLookup.has(folderId)) {
                return {};
            }

            const demographicsLookup = lodash.cloneDeep(prevState.demographicsLookup);
            demographicsLookup.set(folderId, demographics);
            return {demographicsLookup};
        });
    }

    /**
     * The user wants to show more items on the worklist.
     * This will ensure that the first incomplete item is scrolled into view.
     *
     * @return {Promise} The promise will resolve when the items are loaded, or when errors have been handled.
     */
    onShowMore () {
        // Do nothing if we're already loading.
        if (this.state.loadingInitial || this.state.loadingMore) {
            return Promise.resolve();
        }

        return this.$setState({
            loadingMore: true,
            loadingMoreError: false
        }).
        then(() => {
            // We want to increase the size of the worklist by a fixed amount.
            // However, our local list may contain some items which are completed,
            //  and which will not be returned in the server worklist.
            // Figure out how many items we need to fetch from the server to expand
            //  our local worklist by the fixed amount.

            const newWorklistDisplaySize = this.state.worklist.length + this.worklistDisplaySizeIncrement;
            const numItemsToRequest = newWorklistDisplaySize - this.getNumCompletedItems();

            return this.loadWorklist(numItemsToRequest);
        }).
        then(() => {
            return this.$setState({
                loadingMore: false
            });
        }).
        then(() => {
            this.scrollToFirstIncompleteItem();
        }).
        catch((err) => {
            console.warn(err);
            return this.$setState({
                loadingMore: false,
                loadingMoreError: true
            });
        });
    }

    /**
     * Scroll the first incomplete worklist item into view.
     */
    scrollToFirstIncompleteItem () {
        const elem = document.querySelector('.worklist-item-incomplete');
        if (elem) {
            elem.scrollIntoView();
        }
    }

    /**
     * Add a value to a named array in state, if it's not already there.
     *
     * @param {string} arrayName Name of the array property in state. This array must already exist in state.
     * @param {any} value The value to add to the array if it's not already there.
     * @return {Promise} The promise will resolve when the state update is complete. It will reject if an error occurs.
     */
    addValueToStateArray (arrayName, value) {
        if (!arrayName) {
            return Promise.reject('Cannot add a value to a nameless state array.');
        }

        if (value === undefined) {
            return Promise.reject('Cannot add undefined value to a state array.');
        }

        return this.$setState((prevState) => {
            // Make sure the array already exists in state.
            if (!prevState.hasOwnProperty(arrayName)) {
                throw new Error(`Property not found in state: ${arrayName}`);
            }
            if (!Array.isArray(prevState[arrayName])) {
                throw new Error(`State property is not an array: ${arrayName}`);
            }

            // Nothing to do if the value is already in the array.
            if (prevState[arrayName].indexOf(value) >= 0) {
                return {};
            }

            // Add the value to the array.
            const newState = {};
            newState[arrayName] = lodash.cloneDeep(prevState[arrayName]);
            newState[arrayName].push(value);
            return newState;
        });
    }

    /**
     * Remove a value from a named array in state.
     *
     * @param {string} arrayName Name of the array property in state. This array must already exist in state.
     * @param {any} value The value to remove from the array, if present.
     * @return {Promise} The promise will resolve when the state update is complete. It will reject if an error occurs.
     */
    removeValueFromStateArray (arrayName, value) {
        if (!arrayName) {
            return Promise.reject('Cannot remove a value from a nameless state array.');
        }

        if (value === undefined) {
            return Promise.reject('Cannot remove undefined value from a state array.');
        }

        return this.$setState((prevState) => {
            // Make sure the array already exists in state.
            if (!prevState.hasOwnProperty(arrayName)) {
                throw new Error(`Property not found in state: ${arrayName}`);
            }
            if (!Array.isArray(prevState[arrayName])) {
                throw new Error(`State property is not an array: ${arrayName}`);
            }

            // Nothing to do if the value is not in the array.
            const index = prevState[arrayName].indexOf(value);
            if (index < 0) {
                return {};
            }

            // Remove the value from the array.
            const newState = {};
            newState[arrayName] = lodash.cloneDeep(prevState[arrayName]);
            newState[arrayName].splice(index, 1);
            return newState;
        });
    }

    /**
     * Update state to indicate that an appointment's transport arrangement flag is currently being updated.
     *
     * @param {object} appointment The appointment composition being updated.
     * @return {Promise} The promise will resolve when the state is updated.
     */
    setUpdatingTransportFlag (appointment) {
        return this.addValueToStateArray('updatingTransport', lodash.get(appointment, 'uuid'));
    }

    /**
     * Update state to indicate that an appointment's transport arrangement flag is no longer being updated.
     *
     * @param {object} appointment The appointment composition which is no longer being updated.
     * @return {Promise} The promise will resolve when the state is updated.
     */
    clearUpdatingTransportFlag (appointment) {
        return this.removeValueFromStateArray('updatingTransport', lodash.get(appointment, 'uuid'));
    }

    /**
     * Update state to indicate that an appointment's interpreter arrangement flag is currently being updated.
     *
     * @param {object} appointment The appointment composition being updated.
     * @return {Promise} The promise will resolve when the state is updated.
     */
    setUpdatingInterpreterFlag (appointment) {
        return this.addValueToStateArray('updatingInterpreter', lodash.get(appointment, 'uuid'));
    }

    /**
     * Update state to indicate that an appointment's interpreter arrangement flag is no longer being updated.
     *
     * @param {object} appointment The appointment composition which is no longer being updated.
     * @return {Promise} The promise will resolve when the state is updated.
     */
    clearUpdatingInterpreterFlag (appointment) {
        return this.removeValueFromStateArray('updatingInterpreter', lodash.get(appointment, 'uuid'));
    }

    /**
     * Mark an appointment as having failed to update.
     *
     * @param {object} appointment The appointment composition which we failed to u pdate.
     * @return {Promise} The promise will resolve when the state is updated.
     */
    setUpdateFailedFlag (appointment) {
        return this.addValueToStateArray('failed', lodash.get(appointment, 'uuid'));
    }

    /**
     * Update the transport arrangement flag for the specified appointment.
     *
     * @param {object} appointment The appointment composition which will be updated.
     * @param {bool} arranged True to mark the transport as being arranged, or false to mark it as being not arranged.
     * @return {Promise} A promise which settles when the update is complete and any errors have been handled.
     */
    updateTransportArrangements (appointment, arranged) {
        if (!appointment || !appointment.uuid) {
            console.warn('Cannot update empty appointment or appointment without UUID.');
            return Promise.resolve();
        }

        return this.setUpdatingTransportFlag(appointment).
        then(() => {
            return transportAndInterpreterService.update({
                folderId: appointment.folder_id,
                appointmentUuid: appointment.uuid,
                content: appointment.content,
                transportArranged: arranged
            });
        }).
        then((updatedAppointment) => {
            return this.updateWorklistItem(updatedAppointment);
        }).
        then(() => {
            return this.clearUpdatingTransportFlag(appointment);
        }).
        catch((err) => {
            console.warn(err);
            return this.setUpdateFailedFlag(appointment);
        });
    }

    /**
     * Update the interpreter arrangement flag for the specified appointment.
     *
     * @param {object} appointment The appointment composition which will be updated.
     * @param {bool} arranged True to mark the interpreter as being arranged, or false to mark it as being not arranged.
     * @return {Promise} A promise which settles when the update is complete and any errors have been handled.
     */
    updateInterpreterArrangements (appointment, arranged) {
        if (!appointment || !appointment.uuid) {
            console.warn('Cannot update empty appointment or appointment without UUID.');
            return Promise.resolve();
        }

        return this.setUpdatingInterpreterFlag(appointment).
        then(() => {
            return transportAndInterpreterService.update({
                folderId: appointment.folder_id,
                appointmentUuid: appointment.uuid,
                content: appointment.content,
                interpreterArranged: arranged
            });
        }).        
        then((updatedAppointment) => {
            return this.updateWorklistItem(updatedAppointment);
        }).
        then(() => {
            return this.clearUpdatingInterpreterFlag(appointment);
        }).
        catch((err) => {
            console.warn(err);
            return this.setUpdateFailedFlag(appointment);
        });
    }

    /**
     * Based on the latest information in state, figure out how many more
     *  worklist items there are left to load from the server.
     * This takes into account the fact that some or all of the items already
     *  loaded may have been completed.
     *
     * @return {number} The number of worklist there are left to load from the server, based on current state.
     */
    getNumItemsLeftToLoad () {
        return this.state.totalRemainingWorklistSize - (this.state.worklist.length - this.getNumCompletedItems());
    }

    /**
     * Remove all the completed items from the displayed worklist.
     * This will not load any new items. This means that if the entire local
     *  list had been completed, then it will not load any more.
     *
     * @return {Promise} The promise resolves when the worklist is updated.
     */
    onHideCompletedItems () {
        return this.$setState((prevState) => {
            const worklist = this.state.worklist.filter((appointment) => {
                return !this.isItemCompleted(appointment);
            });

            return {worklist};
        });
    }

    render () {
        return (
            <div className='worklist-tab'>
                <TransportAndInterpreterWorklist
                    appointments=               {this.state.worklist}
                    demographicsLookup=         {this.state.demographicsLookup}
                    updatingTransportList=      {this.state.updatingTransport}
                    updatingInterpreterList=    {this.state.updatingInterpreter}
                    failedList=                 {this.state.failed}
                    loadingInitial=             {this.state.loadingInitial}
                    loadingInitialError=        {this.state.loadingInitialError}
                    loadingMore=                {this.state.loadingMore}
                    loadingMoreError=           {this.state.loadingMoreError}
                    numItemsLeftToLoad=         {this.getNumItemsLeftToLoad()}
                    hasCompletedItems=          {this.getNumCompletedItems() > 0}

                    onArrangedTransport=        {(appointment) => this.updateTransportArrangements(appointment, true)}
                    onArrangedInterpreter=      {(appointment) => this.updateInterpreterArrangements(appointment, true)}
                    onUndoTransport=            {(appointment) => this.updateTransportArrangements(appointment, false)}
                    onUndoInterpreter=          {(appointment) => this.updateInterpreterArrangements(appointment, false)}
                    onShowMore=                 {this.onShowMore.bind(this)}
                    onHideCompletedItems=       {this.onHideCompletedItems.bind(this)}
                />
                <br/><br/>
            </div>
        );
    }
}


