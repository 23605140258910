import { PushNotificationCreateForm } from 'components/user/push-notification/create/form';
import React from 'react';
import _ from 'services/i18n';

export type NotifyTimelineForm = {
    notifyTimeline: boolean;
    lozengeTitle: string;
    lozengeContent: string;
    sendPush: boolean;
}

type NotifyTimelineFormProps = {
    form: NotifyTimelineForm;
    setForm: (form: NotifyTimelineForm) => void;
}

const NotifyTimelineForm = ({ form, setForm }: NotifyTimelineFormProps) => {
    return (
        <>
            <div className="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="shouldNotifyTimeline"
                        onChange={(e) => setForm({
                            ...form,
                            notifyTimeline: e.target.checked,
                            lozengeTitle: '',
                            lozengeContent: '',
                            sendPush: false
                        })}
                        checked={form.notifyTimeline} />{' Notify Resource on Patient\'s Timeline'}
                </label>
            </div>
            {form.notifyTimeline && (
                <div>
                    <div className="form-group">
                        <label>{_`Lozenge Title`}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="lozengeTitle"
                            onChange={(e) => setForm({ ...form, lozengeTitle: e.target.value })}
                            value={form.lozengeTitle} />
                    </div>
                    <div className="form-group">
                        <label>{_`Lozenge text`}</label>
                        <textarea
                            className="form-control"
                            name="lozengeText"
                            onChange={(e) => setForm({ ...form, lozengeContent: e.target.value })}
                            value={form.lozengeContent} />
                    </div>
                    <PushNotificationCreateForm
                        sendPush={form.sendPush}
                        onChange={(change) => setForm({ ...form, ...change })}
                    />
                </div>
            )}
        </>
    );
};

export default NotifyTimelineForm;
