import referralsService from 'services/referrals.service';
import { Composition } from 'models/Composition';
import { Referral, REFERRAL_STATUS } from 'models/compositions/Referral';
import { getActivePathwayLabelsFromReferrals } from 'features/pathway-labels/activePathwayHelpers';

export const getActivePathwayLabels = ({ teamId, folderId }) => {
    return referralsService.getReferrals({ teamId, folderId })
        .then((referrals: Composition<Referral>[]) => {
            const activeReferrals = referrals.filter((referral) => {
                return !([
                    REFERRAL_STATUS.CANCELLED,
                    REFERRAL_STATUS.DISCHARGED,
                    REFERRAL_STATUS.REJECTED
                ].includes(referral.content.status));
            });
            return getActivePathwayLabelsFromReferrals(activeReferrals);
        })
        .catch((e) => {
            console.error('[getLabels] something gone wrong during fetching of referral compositions', e);
            return Promise.reject(e);
        });
};
