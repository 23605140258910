import React, { ChangeEvent, FC, Fragment } from 'react';
import _ from 'services/i18n';
import { DropdownList } from 'components/action-menu';
import { ShowModal, ShowModalParams } from 'components/user/member-list/memberListModals';
import ButtonSplitDropdown from '../../../../portal-components/components/button-split-dropdown/index';
import { Grid, GridBody, GridCell, GridHeader, GridHeaderCell, GridHeaderRow, GridRow, GridSortState, TABLE_FILTER } from 'common/ui/grid';
import { USER_STATUS } from 'models/UserFolder';
import { Loading } from 'common/ui/alert-boxes';
import { Filter } from 'common/ui/grid/paginatedTableReducer';
import { TeamMember } from 'services/clinicians.service';

interface UserListProps {
    results: TeamMember[];
    onChangeType: (item: TeamMember) => void;
    onDeleteMember: (item: TeamMember) => void;
    onSort: (sortState: GridSortState) => void;
    onFilter: (filterState: Filter) => void;
    sortState: GridSortState;
    filterState: Filter;
    filterRoleOptions: string[];
    filterStatusOptions: USER_STATUS[];
}
const UsersList: FC<UserListProps> = ({ results, onChangeType, onDeleteMember, onSort, onFilter, filterState, sortState, filterRoleOptions, filterStatusOptions }) => {
    results = results || [];
    return (
        <Fragment>
            <Grid onSort={onSort} onFilter={onFilter} filterState={filterState} sortOrder={sortState}>
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell
                            field={'name'}
                            filterType={TABLE_FILTER.INPUT}
                        >{_`Name`}</GridHeaderCell>
                        <GridHeaderCell
                            field={'email'}
                            filterType={TABLE_FILTER.INPUT}
                        >{_`Email`}</GridHeaderCell>
                        <GridHeaderCell
                            field={'roles'}
                            filterType={TABLE_FILTER.SELECT}
                            filterOptions={filterRoleOptions}
                        >{_`Roles`}</GridHeaderCell>
                        <GridHeaderCell
                            field={'status'}
                            filterType={TABLE_FILTER.SELECT}
                            filterOptions={filterStatusOptions}
                        >{_`Status`}</GridHeaderCell>
                        <GridHeaderCell filterable={false} sortable={false}>{_`Actions`}</GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    <UsersRows
                        results={results}
                        onChangeType={onChangeType}
                        onDeleteMember={onDeleteMember}
                    />
                </GridBody>
            </Grid>
            {results.length === 0 && <div role="alert">{_`Sorry, no matching results found.`}</div>}
        </Fragment>
    );
};
interface UserRowsProps {
    results: TeamMember[];
    onChangeType: (item: TeamMember) => void;
    onDeleteMember: (item: TeamMember) => void;
}
const UsersRows: FC<UserRowsProps> = ({ results, onChangeType, onDeleteMember }) => {
    results = results || [];
    return (
        <Fragment>
            {results.map((item, idx) => {

                const links = [
                    { callback: onChangeType.bind(null, item), 'label': _`Change Roles` },
                    { callback: onDeleteMember.bind(null, item), 'label': _`Remove Member` }
                ];

                const givenName = item?.user_details?.name?.given_name || '';
                const familyName = item?.user_details?.name?.family_name || '';
                const email = item?.user_details?.email || '';
                const roles = (item?.user_roles || []).join(', ');

                return (
                    <GridRow key={idx}>
                        <GridCell>{`${givenName} ${familyName}`}</GridCell>
                        <GridCell>{email}</GridCell>
                        <GridCell>{roles}</GridCell>
                        <GridCell>{item.status}</GridCell>
                        <GridCell>
                            <div className="space-handler">
                                <div style={{ position: 'absolute' }}>
                                    <ButtonSplitDropdown buttonsClassNames={'btn-xs'} key={idx} label={_`Actions`}>
                                        <DropdownList onlyList={true} list={links}/>
                                    </ButtonSplitDropdown>
                                </div>
                            </div>
                        </GridCell>
                    </GridRow>
                );
            })}
        </Fragment>
    );
};
interface MemberListViewProps {
    entityType: 'team' | 'org';
    loading: boolean;
    error: string;
    results: TeamMember[];
    onChangeUserRoles: (user: TeamMember) => void;
    onDeleteMember: (user: TeamMember) => void;
    onChangePage: (i: number) => void;
    onUserInvite: () => void;
    onSearchSubmit: (e: unknown) => void;
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onSort: (sortState: GridSortState) => void;
    onFilter: (filterState: Filter) => void;
    sortState: GridSortState;
    filterState: Filter;
    searchString: string;
    modal?: ShowModalParams;
    filterRoleOptions: string[];
    filterStatusOptions: USER_STATUS[];
}
const MemberListView: FC<MemberListViewProps> = (props) => {

    const {
        entityType,
        loading,
        error,
        results,
        onChangeUserRoles,
        onDeleteMember,
        onUserInvite,
        onSearchSubmit,
        onSearchChange,
        onSort,
        onFilter,
        sortState,
        filterState,
        searchString,
        modal,
        filterRoleOptions,
        filterStatusOptions,
    } = props;

    let listType = _`Organisation`;

    if (entityType == 'team') {
        listType = _`Team`;
    }

    return (
        <div className="patient-list">
            <h3>{_`Members List`}</h3>
            <div className="row spacer">
                <div className="col-md-12">
                    <div className="patient-list__top-bar">
                        <form action="" onSubmit={onSearchSubmit}>
                            <div className="input-group">
                                <input type="text" className={'form-control'} placeholder={_`Type email or name`} value={searchString} onChange={onSearchChange}/>
                                <div className="input-group-btn">
                                    <button className="btn btn-default">{_`Search`}</button>
                                </div>
                            </div>
                        </form>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={onUserInvite}>{_`Invite Clinician to ${listType}`}</button>
                    </div>

                </div>
            </div>
            {error && <div className={'alert alert-danger'}>{error}</div>}
            {loading ? <Loading/> : (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <UsersList
                                results={results}
                                onChangeType={onChangeUserRoles}
                                onSort={onSort}
                                onFilter={onFilter}
                                filterState={filterState}
                                sortState={sortState}
                                onDeleteMember={onDeleteMember}
                                filterRoleOptions={filterRoleOptions}
                                filterStatusOptions={filterStatusOptions}
                            />
                        </div>
                    </div>
                </>
            )}
            {modal && (
                <ShowModal
                    modal={modal}
                />
            )}
        </div>
    );

};

export default MemberListView;
