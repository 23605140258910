import React from 'react';
import ReactDOM from 'react-dom';
import { IndexRoute, IndexRedirect, Route } from 'react-router';

import { AdminPortalTemplate } from 'layouts/admin_portal/AdminPortalTemplate';

import IndexPage                    from 'pages/admin_portal';

import Invite_Accept            from 'pages/admin_portal/invite/accept/_token';

import PreInvites_Index      from 'pages/admin_portal/pre-invites';

import { PrivacyNoticeList } from 'features/privacy-notices/PrivacyNoticeList';
import { PrivacyNotice } from 'features/privacy-notices/PrivacyNotice';
import { PrivacyNoticeCreate } from 'features/privacy-notices/PrivacyNoticeCreate';

// resources
import Resource_Index          from 'pages/admin_portal/resources';
import Collection_ViewPage     from 'pages/admin_portal/resources/collections/_id';

// predefined messages
import PredefinedMessages_Index from 'pages/admin_portal/predefined-messages';
import PredefinedMessages_EditPage from 'pages/admin_portal/predefined-messages/edit';

// predefined sms
import PredefinedSMS_Index from 'pages/admin_portal/predefined-sms';
import PredefinedSMS_EditPage from 'pages/admin_portal/predefined-sms/edit';

// other
import Members_IndexPage from 'pages/admin_portal/members';
import Teams_IndexPage from 'pages/admin_portal/teams';

import QuestionnaireTemplate_IndexPage from 'pages/admin_portal/questionnaire-templates';
import QuestionnaireTemplate_CreatePage  from 'pages/admin_portal/questionnaire-templates/create';
import QuestionnaireTemplate_ViewPage    from 'pages/admin_portal/questionnaire-templates/_templateId';

import { EditorView } from 'features/content-editor/index';
import { CreateSimpleQuestionnaire }from 'features/content-editor/CreateSimpleQuestionnaire';
import { EditSimpleQuestionnaire } from 'features/content-editor/EditSimpleQuestionnaire';

import Login from 'pages/admin_portal/login';
import Logout from 'pages/admin_portal/logout';
import SignUp from 'pages/admin_portal/sign-up';
import PasswordReset from 'pages/admin_portal/password-reset';
import ForgotPassword from 'pages/admin_portal/forgot-password';
import ConfirmSignup from 'pages/admin_portal/confirm';
import AuthCheckMiddleware from 'middleware/authorize-check';

import { ProfileContainer } from '../../app/features/profile/ProfileContainer';
import Profile_Photograph           from '../../app/features/profile/photograph';
import Profile_Password             from '../../app/features/profile/password';
import { ProfileChangeEmail } from '../../app/features/profile/ProfileChangeEmail';
import Profile_MFA from 'features/profile/mfa';

import { connect } from 'react-redux';
import PathwayEditorPage from 'pages/admin_portal/pathway-editor/PathwayEditorIndex';
import { CsvImport } from 'pages/admin_portal/upload-users/CsvImport';
import MFA from 'pages/admin_portal/mfa';

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const TemplateWithRedux =  connect(undefined, mapDispatchToProps)(AdminPortalTemplate);

const routes = (
    <Route path="/admin_portal">
        <Route path="sign-up" component={SignUp} />
        <Route path="confirm/:token" component={ConfirmSignup} />
        <Route path="forgot-password" component={ForgotPassword} />
        <Route path="password-reset/:token" component={PasswordReset} />
        <Route path="login" component={Login} />
        <Route path="mfa" ignoreTeamPreferences={true} component={MFA} />

        <Route path="invite/accept/:token" component={Invite_Accept} />

        <Route component={(props) => <AuthCheckMiddleware {...props} redirectTo={'/admin_portal/login'}/>}>

            <Route path="logout" component={Logout} />

            <Route component={TemplateWithRedux}>
                <IndexRoute component={IndexPage} />
                <Route path="pathway-editor" component={PathwayEditorPage} />
                <Route path="csv-import" component={CsvImport} />

                <Route path="profile">
                    <IndexRedirect to={'change-email'} />
                    <Route component={(props) => <ProfileContainer {...props} portal='admin_portal' />} >
                        <Route path="photograph" component={Profile_Photograph} portal='admin_portal' />
                        <Route path="password" component={Profile_Password}  portal='admin_portal' />
                        <Route path="change-email" component={ProfileChangeEmail} portal='admin_portal' />
                        <Route path="mfa" component={Profile_MFA} />
                    </Route>
                </Route>

                <Route path="members">
                    <IndexRoute component={Members_IndexPage} />
                </Route>

                <Route path="pre-invites" component={PreInvites_Index} />

                <Route path="teams">
                    <IndexRoute component={Teams_IndexPage} />
                </Route>

                <Route path="resources">
                    <IndexRoute component={Resource_Index}/>
                    <Route path=":categoryId" component={Collection_ViewPage}/>
                    <Route path=":categoryId/:id" component={Collection_ViewPage}/>
                </Route>

                <Route path="privacy-notices">
                    <IndexRoute component={PrivacyNoticeList} />
                    <Route path="add/:uuid" component={PrivacyNoticeCreate} />
                    <Route path="add" component={PrivacyNoticeCreate} />
                    <Route path=":uuid" component={PrivacyNotice} />
                </Route>

                <Route path="questionnaire-templates">
                    <IndexRoute component={QuestionnaireTemplate_IndexPage} />
                    <Route path="create" component={QuestionnaireTemplate_CreatePage} />
                    <Route path=":templateId" component={QuestionnaireTemplate_ViewPage} />
                </Route>

                <Route path="editor">
                    <IndexRoute component={EditorView} />
                    <Route path="simplequestionnaire/create" component={CreateSimpleQuestionnaire} />
                    <Route path="simplequestionnaire/edit/:id" component={EditSimpleQuestionnaire} />
                </Route>

                <Route path="predefined-messages">
                    <IndexRoute component={PredefinedMessages_Index}/>
                    <Route path=":page" component={PredefinedMessages_Index}/>
                    <Route path="edit/:messageId" component={PredefinedMessages_EditPage} />
                </Route>

                <Route path="predefined-sms">
                    <IndexRoute component={PredefinedSMS_Index}/>
                    <Route path=":page" component={PredefinedSMS_Index}/>
                    <Route path="edit/:smsId" component={PredefinedSMS_EditPage} />
                </Route>
            </Route>
        </Route>
    </Route>
);


export default routes;
