import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import faviconService from 'services/favicon.service.js';
import { Link } from 'react-router';
import './guest.scss';

/**
 * Extended Page Component for user_portal/dashboard
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Welcome to i-Focus Healthcare`;
    }

    componentWillMount(){
        faviconService.setDefaultIcon();
    }

    render () {
        return (
            <div className="homepage">
                <div className="splash">
                    <div className="row">
                        <img src="/assets/logo.png" className='logo-img' alt=""/>
                    </div>
                    <div className="row">
                        <h2>Please choose an option from below:</h2>
                    </div>
                    <div style={{ clear: 'both' }}/>
                    <div className="portal">
                        <Link to="/clinical_portal/login">Clinical Portal</Link>
                    </div>
                    <div className="portal">
                        <Link to="/admin_portal/login">Admin Portal</Link>
                    </div>
                </div>
            </div>
        );
    }
}
