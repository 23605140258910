import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import DataStore from 'services/data-store';
import { departmentService } from './department.service';
// interfaces
import {
    DepartmentContextValue,
    DepartmentLocation,
} from './department.interface';

const defaultDepartmentContextValue: DepartmentContextValue = {
    currentLocation: undefined,
    locationTree: undefined,
    changeLocation: (id: string) => {
        void id;
    },
};

export const DepartmentContext = React.createContext<DepartmentContextValue>(
    defaultDepartmentContextValue,
);

export const DepartmentContextProvider: FC = ({ children }) => {
    const [currentLocation, setCurrentLocation] = useState<string>(
        defaultDepartmentContextValue.currentLocation,
    );
    const [locationTree, setLocationTree] = useState<DepartmentLocation>(
        defaultDepartmentContextValue.locationTree,
    );

    useEffect(() => {
        async function loadCurrentLocation(
            teamId: number | string,
            roleId: string,
        ) {
            try {
                const currentLocation =
                    await departmentService.getCurrentLocation(teamId, roleId);

                setCurrentLocation(currentLocation.uuid);
            } catch (err) {
                console.error(err);
            }
        }

        async function loadLocationTree(team: number | string, role: string) {
            try {
                const locationTree = await departmentService.getLocationTree(
                    team,
                    role,
                );

                setLocationTree(locationTree);
            } catch (err) {
                console.error(err);
            }
        }

        const listenerId = DataStore.addListener('me.currentRole', (role) => {
            if (!role) {
                return;
            }

            loadCurrentLocation(role.teamId, role.uuid);
            loadLocationTree(role.teamId, role.uuid);
        });

        const role = DataStore.get('me.currentRole');
        if (role) {
            loadCurrentLocation(role.teamId, role.uuid);
            loadLocationTree(role.teamId, role.uuid);
        }

        return () => {
            DataStore.removeListener(listenerId);
        };
    }, []);

    const changeLocation = useCallback((departmentId: string) => {
        const role = DataStore.get('me.currentRole');

        setCurrentLocation(departmentId);
        departmentService.changeLocation(role.teamId, role.uuid, departmentId);
    }, []);

    return (
        <DepartmentContext.Provider
            value={{
                currentLocation,
                locationTree,
                changeLocation,
            }}
        >
            {children}
        </DepartmentContext.Provider>
    );
};

export const useDepartmentLocation = () => {
    const { currentLocation, locationTree, changeLocation } =
        useContext(DepartmentContext);

    return {
        currentLocation,
        locationTree,
        changeLocation,
    };
};
