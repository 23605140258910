import React, { FC } from 'react';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';
// components
import StaticSettingToggle from './StaticSettingToggle';
import { BulkOperationList } from 'components/bulk-operations/BulkOperationSetupButton';
// utils
import { useDefaultMouseEventPrevention } from './AdvancedSearchQueryToolbar.utils';
// interfaces
import { AdvancedSearchQueryToolbarProps } from './AdvancedSearchQueryToolbar.interface';
// styles
import './AdvancedSearchQueryToolbar.scss';

const AdvancedSearchQueryToolbar: FC<AdvancedSearchQueryToolbarProps> = (
    props,
) => {
    const handleEditQuery = useDefaultMouseEventPrevention(
        props.onSearchDefinitionEdit,
    );
    const handleDeleteQuery = useDefaultMouseEventPrevention(
        props.onSearchDefinitionDelete,
    );
    const handleExportQuery = useDefaultMouseEventPrevention(
        props.onQueryResultExport,
    );
    const handleRefresh = useDefaultMouseEventPrevention(props.onRefresh);

    return (
        <div className={'advanced-search-query-toolbar'}>
            {props.hasQueryResults && <BulkOperationList queryName={props.queryName} queryResultsLastUpdated={props.queryResultsLastUpdated}/>}
            <button
                title={'Edit Filter'}
                className={'btn btn-xs btn-default icon-button'}
                onClick={handleEditQuery}
            >
                <span>Edit Filter</span>
                <BorderColorIcon style={{ fontSize: 20, paddingLeft: 4 }} />
            </button>
            <button
                title={'Refresh Filter'}
                type={'button'}
                className={'btn btn-xs btn-default icon-button'}
                onClick={handleRefresh}
                disabled={props.isStaticSearch}
            >
                <AutorenewIcon style={{ fontSize: 20 }} />
            </button>
            <button
                title={`Export ${props.searchRootName} to CSV`}
                className={'btn btn-xs btn-default icon-button'}
                onClick={handleExportQuery}
                disabled={!props.hasQueryResults}
            >
                <SaveAltIcon style={{ fontSize: 20 }} />
            </button>
            <button
                title={'Archive Filter'}
                className={'btn btn-xs btn-danger icon-button'}
                onClick={handleDeleteQuery}
            >
                <DeleteIcon style={{ fontSize: 20 }} />
            </button>
            <StaticSettingToggle
                isStaticSearch={props.isStaticSearch}
                onSearchStaticSettingChange={props.onSearchStaticSettingChange}
            />
        </div>
    );
};

export default AdvancedSearchQueryToolbar;
