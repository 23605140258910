import { Api, Http } from 'phr-api-client';
import { customHttp } from './customHttp.service';

class ApiV2 extends Api {
    private static _instance: ApiV2;
    constructor(params: {
        http: Http;
        apiBaseURL: string;
    }) {
        if(ApiV2._instance) {
            return ApiV2._instance;
        }
        super(params);
        ApiV2._instance = this;
    }
}

export const http = new Http(customHttp);
export const apiBaseURL = '';
export const apiV2Service = new ApiV2({
    http,
    apiBaseURL
});

export function fetchAll(fetch: Function): Promise<Array<any>> {
    const output: Array<any> = [];

    return next(fetch)
        .then(() => {
            return output;
        });

    function next(fetch: Function, offset: number = 0) {
        return fetch(offset)
            .then((response: any) => {
                output.push(...response.message.results);

                if(output.length < response.message.total) {
                    return next(fetch, output.length);
                }
            });
    }
}
