import React from 'react';
import BasePage from 'components/page/base';

import {Row, Column} from 'ui';
import _ from 'services/i18n';
import HeadingDoc from 'ui/heading-doc';

export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Reports | PHR Clinical Portal`;
    }

    render () {
        return (
            <div className="report-tab">
                <HeadingDoc title="Reports">
                    This section provides various reporting facilities.
                </HeadingDoc>
            </div>
        );
    }
}