// https://bitbucket.org/adihealth/private-server/src/master/resources/schemas/archetypes/appointment.json
import { Label } from '../Label';
import { ClinicalCode } from './ClinicalCode';

export type AppointmentProposedParticipantStatus =
    | 'needs-attention'
    | 'accepted'
    | 'declined'
    | 'needs-further-information';

export const AppointmentProposedParticipantStatuses: AppointmentProposedParticipantStatus[] = [
    'needs-attention',
    'accepted',
    'declined',
    'needs-further-information'
];

/**
 * Schema for the appointment archetype in the PHR. Stores details about a clinical
 * appointment in a patient's folder.
 */
export interface Appointment {
    /**
     * TEMPORARY - Provides some additional information which will be displayed as part of the
     * appointment in the app.
     */
    additional_details?: string;
    /**
     * Currently 'ON' or 'OF' for new or updated outpatient appointments. Populated from HL7.
     */
    admission_type?: string;
    /**
     * The clinician who is booked to see the patient.
     */
    admitting_doctor?: Doctor;
    /**
     * Yet another identifier from HL7. Purpose and uniqueness TBC.
     */
    appointment_id?: string;
    /**
     * If set to true, the date and time of the appointment are approximate. Assume false if
     * omitted.
     */
    approximate_date?: boolean;
    /**
     * The clinician who actually saw the patient (this is filled-in from HL7 AFTER the
     * appointment).
     */
    attending_doctor?: Doctor;
    /**
     * DEPRECATED. The booking password will now be in the associated referral.
     */
    booking_password?: string;
    /**
     * DEPRECATED. The folder where the bundle of resources identified by bundle_uuid can be
     * found. Required if bundle_uuid is specified.
     */
    bundle_folder_id?: number;
    /**
     * DEPRECATED. UUID of a bundle of resources and other material associated with this
     * appointment.
     */
    bundle_uuid?:    string;
    clinical_codes?: ClinicalCode[];
    /**
     * The overseeing consultant responsible for the case.
     */
    consulting_doctor?: Doctor;
    /**
     * Unix timestamp indicating the UTC date and time of the appointment, if known. Should be
     * present if unknown_data is false or omitted.
     */
    date?: number;
    /**
     * Human-readable information about the appointment.
     */
    details: string;
    /**
     * If true, this appointment should be hidden from the patient's timeline in the app. If
     * this property is omitted, assume it is false.
     */
    hidden_from_timeline?: boolean;
    /**
     * The clinic specialty code.
     */
    hospital_service?: string;
    /**
     * Contains information about the patient's requirements/arrangements for interpretation at
     * this appointment. If there are no child properties defined, then this top-level property
     * should be omitted. In this case, assume that an interpreter is neither required nor
     * arranged.
     */
    interpreter?: AppointmentInterpreter;

    labels?: Label[];
    /**
     * Human-readable description of the location where the appointment will take place, e.g. an
     * address. Where relevant, this will be derived from the contents of location_details.
     */
    location: string;
    /**
     * DEPRECATED. This is now stored in location_details.room
     */
    location_code?: string;
    /**
     * Contains one or more coded items of information identifying where the appointment will
     * take place.
     */
    location_details?: LocationDetails;
    /**
     * Unix timestamp since the fields is locked for updates
     */
    lock_ubrn_booking_password?: number;
    /**
     * For RheumaBot to store diagnosis outcome according to incoming extract in appointment
     * composition, so labelling can be handled by Rules in Trigger Engine
     */
    outcome?: string;
    /**
     * DEPRECATED. The UUID of the pathway which this appointment is related to.
     */
    pathway_uuid?: string;
    /**
     * Currently only 'O' for outpatient appointments. Populated from HL7.
     */
    patient_class?: string;
    /**
     * Unique identifier from HL7. Often the same as UBRN, but not always. This is NOT related
     * to pathway template or composition UUIDs in the PHR.
     */
    patient_pathway_identifier?: string;
    /**
     * Unique identifier from HL7. Should correspond to edpisode identifier on referrals(?).
     */
    preadmit_number?: string;
    /**
     * The ID of the associated referral. Replaces referral_ubrn.
     */
    referral_id?: string;
    /**
     * DEPRECATED. Replaced by referral_uuid.
     */
    referral_ubrn?: string;
    /**
     * An array of UUIDs identifying any scheduledTriggers compositions which will send
     * reminders for this invitation.
     */
    reminder_triggers?: string[];
    /**
     * Specifies the UUIDs of zero or more resources associated with this appointment. The user
     * should be able to access the resources via the appointment. The resource compositions may
     * be in the patient's own folder, or they may be in a team/organisation folder.
     */
    resource_uuids?: string[];
    /**
     * Indicates whether the appointment is booked, attended, cancelled, etc.
     */
    status?: APPOINTMENT_STATUS;
    /**
     * Contains information specific to the patients timeline that is not stored elsewhere.
     */
    timeline?: Timeline;
    /**
     * Contains information about the patient's requirements/arrangements for transportation to
     * attend this appointment. If there are no child properties defined, then this top-level
     * property should be omitted. In this case, assume that transport is neither required nor
     * arranged.
     */
    transport?: Transport;
    /**
     * TBC. Populated from HL7.
     */
    treatment_function?: string;
    /**
     * Human-readable information about the appointment. Just additional notes
     */
    triage_decision_notes?: string;
    /**
     * Identifies the type of appointment, e.g. 'triage'.
     */
    type: string;
    /**
     * Unique Booking Reference Number. Another unique identifier from HL7.
     */
    ubrn?: string;
    /**
     * If set to true, the date and time of the appointment are unknown. Assume false if omitted.
     */
    unknown_date?: boolean;
    /**
     * PIFU/SOS initiated appointment data
     */
    proposed_participant?: {
        participant: string;
        status: AppointmentProposedParticipantStatus;
        notes?: string;
        booking_notes?: string;
    };
}

/**
 * The clinician who is booked to see the patient.
 *
 * The clinician who actually saw the patient (this is filled-in from HL7 AFTER the
 * appointment).
 *
 * The overseeing consultant responsible for the case.
 */
export interface Doctor {
    /**
     * TBC
     */
    assigning_authority?: string;
    /**
     * The doctor's family name or surname.
     */
    family_name?: string;
    /**
     * The doctor's first name(s) or initial(s).
     */
    given_name?:  string;
    identifiers?: Identifier[];
    /**
     * The doctor's prefix or title.
     */
    prefix?: string;
    /**
     * DEPRECATED - The SPINE look-up identifier for this doctor - now included in list of
     * identifiers if present.
     */
    sdsid?: string;
    /**
     * The doctor's suffix.
     */
    suffix?: string;
}

export interface Identifier {
    /**
     * Identifies the authority by which the id was assigned
     */
    assigning_authority?: string;
    /**
     * A unique identifier. The format depends on the type of identifier.
     */
    id: string;
    /**
     * Identifies the type or namespace of the identifier, e.g. 'GMC' or 'SDSID'.
     */
    type?: string;
}

/**
 * Contains information about the patient's requirements/arrangements for interpretation at
 * this appointment. If there are no child properties defined, then this top-level property
 * should be omitted. In this case, assume that an interpreter is neither required nor
 * arranged.
 */
export interface AppointmentInterpreter {
    /**
     * Indicates if an interpreter for the patient has been arranged for this appointment. If
     * this property is omitted, assumes its value is false.
     */
    arranged?: boolean;
    /**
     * Details entered by portal staff member at time of interpreter being arranged.
     */
    details?: string;
    /**
     * Indicates if the patient will require an interpreter for this appointment. If this
     * property is omitted, assume its value is false.
     */
    required?: boolean;
}

/**
 * Contains one or more coded items of information identifying where the appointment will
 * take place.
 */
export interface LocationDetails {
    /**
     * A code identifying the general clinic or facility.
     */
    point_of_care?: string;
    /**
     * A code identifying which room the appointment will take place in. This value used to be
     * stored in location_code.
     */
    room?: string;
    /**
     * A code identifying the medium through which appointment will take place.
     */
    consultation_mechanism?: string;
}

/**
 * Indicates whether the appointment is booked, attended, cancelled, etc.
 */
export enum APPOINTMENT_STATUS {
    EMPTY = '',
    AVAILABLE_TO_BOOK = 'available-to-book',
    BOOKED = 'booked',
    WAITING = 'waiting',
    ARRIVED = 'arrived',
    CALLED = 'called',
    SEEN = 'seen',
    DEPARTED = 'departed',
    DISCHARGED = 'discharged',
    DID_NOT_ATTEND = 'did-not-attend',
    CANCELLED = 'cancelled',
    CLOSED = 'closed',
    ADMITTED = 'admitted'
}

/**
 * Contains information specific to the patients timeline that is not stored elsewhere.
 */
export interface Timeline {
    /**
     * If approximate_date set to true, the service specific text to be displayed by the app to
     * explain the provisional nature of the appointment.
     */
    approx_date_text?: string;
    /**
     * Some advice for accepting the booking url
     */
    booking_link_advice?: string;
    /**
     * Label for the link on the timeline to the booking system.
     */
    booking_link_label?: string;
    /**
     * Url of the booking system.
     */
    booking_link_url?: string;
    /**
     * Label for the booking system password.
     */
    booking_password_label?: string;
    /**
     * Label for the booking system username.
     */
    booking_username_label?: string;
    /**
     * Timeline title. To include a reference to the department and any other helpful
     * identifying information useful for the patient.
     */
    title?: string;
}

/**
 * Contains information about the patient's requirements/arrangements for transportation to
 * attend this appointment. If there are no child properties defined, then this top-level
 * property should be omitted. In this case, assume that transport is neither required nor
 * arranged.
 */
export interface Transport {
    /**
     * Indicates if transportation arrangements have been made on behalf of the patient in order
     * to attend this appointment. If this property is omitted, assumes its value is false.
     */
    arranged?: boolean;
    /**
     * Details entered by portal staff member at time of transport being arranged.
     */
    details?: string;
    /**
     * Indicates if the patient requires transport to be arranged on their behalf in order to
     * attend this appointment. If this property is omitted, assume its value is false.
     */
    required?: boolean;
}
