import React, { FC, useState } from 'react';
import { Button } from 'antd';
// components
import AdvancedSearchCriteria from 'features/patients/advanced-search/AdvancedSearchCriteria';
import AdvancedSearchOutputs from 'features/patients/advanced-search/AdvancedSearchOutputs';
// hooks
import {
    useAdvancedSearchCriteria,
    useAdvancedSearchOutputs,
} from 'features/patients/advanced-search/AdvancedSearchSetupHooks';
// interfaces
import {
    AdvancedSearchQueryCriterion,
    getDefaultOutputs,
    isFieldAPseudoDocument,
    isFieldSearchable,
} from 'models/AdvancedSearchDefinition';
import {
    AdvancedSearchCriteriaAndOutputsProps,
    QueryTab,
    QueryTabDisplayProps,
} from './AdvancedSearchCriteriaAndOutputs.interface';
// styles
import './AdvancedSearchCriteriaAndOutputs.scss';

const QueryTabDisplay: FC<QueryTabDisplayProps> = (props) => {
    return (
        <Button
            key={props.tab}
            className={`tab ${props.activeTab == props.tab ? 'active' : ''}`}
            onClick={() => props.selectTab(props.tab)}
        >
            {props.title}
        </Button>
    );
};

const AdvancedSearchCriteriaAndOutputs: FC<AdvancedSearchCriteriaAndOutputsProps> =
    (props) => {
        const [activeTab, setActiveTab] = useState<QueryTab>(QueryTab.CRITERIA);

        const [selectedCriteria, addCriterion, removeCriterion] = useAdvancedSearchCriteria(props.queryDefinition);
        const [selectedOutputs, addOutput, removeOutput, moveOutput] = useAdvancedSearchOutputs(
            props.documentConfig,
            props.queryDefinition,
        );

        const remainingOutputs = props.documentConfig.fields.filter((availableOutput) => {
            return (
                !isFieldAPseudoDocument(availableOutput) &&
                !selectedOutputs.includes(availableOutput.path)
            );
        });
        const remainingCriteria = props.documentConfig.fields.filter((availableCriterion) => {
            return (
                !isFieldAPseudoDocument(availableCriterion) &&
                isFieldSearchable(availableCriterion) &&
                !selectedCriteria.find(
                    (selectedCriterion) =>
                        selectedCriterion.path == availableCriterion.path,
                )
            );
        });

        const addCriterionAndOutput = (newCriterion: AdvancedSearchQueryCriterion) => {
            addCriterion(newCriterion);
            addOutput(newCriterion.path);
        };

        const removeCriterionAndOutput = (index: number) => {
            const removedCriterion = removeCriterion(index);
            const isDefaultOutput = !!getDefaultOutputs(props.documentConfig)
                .find((field) => field.path == removedCriterion.path);

            if (!isDefaultOutput) {
                removeOutput(removedCriterion.path);
            }
        };

        return (
            <div className={'advanced-search-query-request-config'}>
                <div className={'advanced-search-query-request-config__tabs'}>
                    <QueryTabDisplay
                        tab={QueryTab.CRITERIA}
                        title="Criteria"
                        selectTab={setActiveTab}
                        activeTab={activeTab}
                    />
                    <QueryTabDisplay
                        tab={QueryTab.OUTPUTS}
                        title="Organise results"
                        selectTab={setActiveTab}
                        activeTab={activeTab}
                    />
                </div>
                {activeTab == QueryTab.CRITERIA && (
                    <AdvancedSearchCriteria
                        documentConfig={props.documentConfig}
                        remainingCriteria={remainingCriteria}
                        criteria={selectedCriteria}
                        addCriterion={addCriterionAndOutput}
                        removeCriterion={removeCriterionAndOutput}
                        validator={props.validator}
                    />
                )}
                {activeTab == QueryTab.OUTPUTS && (
                    <AdvancedSearchOutputs
                        documentConfig={props.documentConfig}
                        remainingOutputs={remainingOutputs}
                        selectedOutputs={selectedOutputs}
                        addOutput={addOutput}
                        removeOutput={removeOutput}
                        moveOutput={moveOutput}
                    />
                )}
            </div>
        );
    };

export default AdvancedSearchCriteriaAndOutputs;
