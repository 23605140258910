import { cloneDeep as _cloneDeep, get as _get, merge as _merge, reduce as _reduce } from 'lodash';
import { IFolderSelectorParam, IRole } from 'phr-api-client/index';
import { CompositionService } from './composition.service';
import { terService } from './ter.service';

class UserPreferencesService extends CompositionService {

    get archetypeName(): string {
        return 'user-preferences';
    }

    public static readonly defaultProperties = Object.freeze({
        opted_out_of_digital_communications: false
    });

    get defaultProperties(): any {
        return _cloneDeep(UserPreferencesService.defaultProperties);
    }

    public getFirst({role, folderId}: { role?: IRole, folderId?: IFolderSelectorParam }): Promise<any> {
        return this.list({role, folderId})
            .then((response) => {
                const data = _get(response, 'message.results[0]', {});

                return {
                    uuid: data.uuid,
                    content: _merge({}, UserPreferencesService.defaultProperties, data.content)
                };
            })
            .catch((error) => {
                if (error && error.response && error.response.status) {
                    if (parseInt(error.response.status, 10) === 404) {
                        return {
                            uuid: undefined,
                            content: _merge({}, UserPreferencesService.defaultProperties)
                        };
                    }
                }
                throw error;
            });
    }

    public update({role, content, folderId}: { role?: IRole, content: any, folderId?: IFolderSelectorParam }): Promise<any> {

        const createTerActions = _reduce(content, (promises, value: any, key: any) => {
            promises.push(terService.createTer({
                action: 'setUserPreference',
                data: {
                    key,
                    value
                },
                folderId,
                role
            }));
            return promises;
        }, []);

        return Promise.all(createTerActions);
    }
}

export const userPreferencesService = new UserPreferencesService();
