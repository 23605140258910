import React, { useState, useEffect } from 'react';
import { ResourceCreateForm } from 'components/user/resource/create/form';
import { CreateResourceState, normalise, validate } from 'components/user/resource/create/validator';
import { resourcesService } from 'services/resources.service';
import { Composition } from 'models/Composition';
import { ResourceCategory } from 'models/ResourceCategory';
import { resourceCategoryService } from 'services/resourceCategory.service';
import { ResourceType } from 'models/compositions/Resource';
import { makeDepartmentFolderLabel } from 'features/department/department.utils';
import { portalToast } from 'ui/toast/Toast';

const initialFormState: CreateResourceState = {
    type: '',
    label: '',
    url: '',
    phone: '',
    html: '',
    description: '',
    mediaFile: null,
    mediaFileUuid: '',
    category: [],
    lozengeTitle: '',
    lozengeText: '',
    sendPush: false,
    notifyTimeline: false,
    tags: []
};

const CreateResourceComponent = ({ submitLabel, categoryId, currentUser, onRefresh }) => {
    const initialResourceFormState = { ...initialFormState, categoryId: categoryId };
    const [resource, setResource] = useState<CreateResourceState>(initialResourceFormState);
    const [errors, setErrors] = useState([]);
    const [availableCategories, setAvailableCategories] = useState<Composition<ResourceCategory>[]>([]);

    useEffect(() => {

        async function fetchCategories() {
            try {
                const categoryList = await resourceCategoryService.list({ folderId: 'team' });
                setAvailableCategories(categoryList?.message?.results || []);
            } catch(e) {
                setAvailableCategories([]);
            }
        }
        fetchCategories();
    }, []);

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (validate(resource, setErrors)) {

            const normalisedResource = normalise(resource);
            const category = availableCategories.find(({ uuid }) => uuid === categoryId);

            let labels = [];

            if (resource.tags && resource.tags.length) {
                labels = [...labels, ...resource.tags.map(tag => ({
                    name: tag,
                    context: tag.toLowerCase().replace(' ', '_'),
                    type: 'resourceTags',
                    status: true,
                }))];
            }

            if (category.content?.labels) {
                const categoryDepartmentLabel = category.content.labels.find(label => label.type === 'department-folder');
                const departmentLabel = makeDepartmentFolderLabel(categoryDepartmentLabel.context);
                labels.push(departmentLabel);
            }

            const newResource = {
                label: normalisedResource.label,
                url: normalisedResource.url,
                phone: normalisedResource.phone,
                resource_type: normalisedResource.type as ResourceType,
                description: normalisedResource.description,
                html_content: normalisedResource.html,
                category: [{
                    category_id: category.uuid,
                    category_folder_id: category.folder_id,
                    name: category.content.name
                }],
                labels: labels,
                name_of_issuer: currentUser
            };

            resourcesService.create({ content: newResource, file: normalisedResource.mediaFile, folderId: 'team' })
                .then(() => {
                    setResource(initialResourceFormState);
                    portalToast.success({
                        title: 'Successfully added new resource.',
                        content: 'The new resource has successfully been added to the collection.',
                    });
                    onRefresh();
                }).catch(() => {
                    portalToast.error({
                        title: 'Error',
                        content: 'A problem was encountered whilst trying to create the new resource.',
                    });
                });
        }
    };

    return (
        <ResourceCreateForm
            errors={errors}
            onChange={(c) => setResource({ ...resource, ...c })}
            form={resource}
            onSubmit={handleSubmit}
            hideSubmit={false}
            buttonLabel={submitLabel}
            onShowMediaResource={null}
            showPatientOptions={false} />
    );
};

export default CreateResourceComponent;
