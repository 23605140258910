import React, { useState, useEffect } from 'react';
import { PrivacyNoticeComposition } from './PrivacyNotice.archetype';
import { compositionService } from 'services/composition.service';


export function usePrivacyNotices(team): [any[], boolean] {
    const [privacyNotices, setPrivacyNotices] = useState<PrivacyNoticeComposition[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!team || !team.teamFolderId) {
            setPrivacyNotices([]);
            return;
        }
        setIsLoading(true);

        compositionService.list({
            folderId: team.teamFolderId,
            archetypeName: 'privacy-notice',
        }).then((response) => {
            const privacyNotices: PrivacyNoticeComposition[] = response.message.results;
            setIsLoading(false);
            if(!privacyNotices) {
                return setPrivacyNotices([]);
            }

            setPrivacyNotices(privacyNotices);
        });
    }, [team]);

    return [privacyNotices, isLoading];
}
