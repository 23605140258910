import React from 'react';
import { Link } from 'react-router';
import PaginationView from 'components/pagination';
import _ from 'services/i18n';
import ActionMenu from 'components/action-menu';
import { ResponsiveTable } from 'ui';
import RoleCheck from 'services/roles';
import Markdown from 'components/ui/markdown';
import { convertToOutputTzDate } from 'common/datetime/DateTime';
import { DateTime } from 'common/datetime/DateTime';
import { TopBar } from 'features/patient/top-bar/TopBar';


class Index extends React.Component {
    renderResultRows () {
        let results;

        if (this.props.error) {
            return (
                <tr>
                    <td colSpan="7">
                        {_`Sorry, the server reported an error`}
                    </td>
                </tr>
            );
        }

        if (this.props.loading) {
            return (
                <tr>
                    <td colSpan="7">
                        {_`Loading messages... please wait`}
                    </td>
                </tr>
            );
        }

        results = this.props.results || [];

        if (results.length == 0) {
            return (
                <tr>
                    <td colSpan="7">
                        {_`Sorry, no matching results found`}
                    </td>
                </tr>
            );
        }


        return results.map((item, idx) => {
            let messageLink;

            if (this.props.clinicalView) {
                messageLink = `${this.props.baseRoute}/${item.uuid}`;
            } else {
                messageLink = '/user_portal/folder/' + item.folder_id + '/messages/' + item.uuid;
            }

            const createdAtUnixMs = convertToOutputTzDate(item.created_at, 'server_datetime').valueOf();

            return (
                <tr key={idx}>
                    <td><Markdown sanitize={false}>{item.content.title}</Markdown></td>
                    <td>{item.content.type}</td>
                    <td>{item.content.sender_name}</td>
                    <td>
                        <span>{item.content.send_as_email == 1 ? _`Email` : _`In App`}</span>
                    </td>
                    <td><DateTime>{createdAtUnixMs}</DateTime></td>
                    <td>
                        <ActionMenu size="xs" links={[
                            { route: messageLink, label: _`View Message` }
                        ]} label={_`Select an Option`}/>
                    </td>
                </tr>
            );
        });
    }

    renderResults () {
        return (
            <ResponsiveTable className='table table-bordered table-condensed table-striped table-nomargin table-wrap'>
                <thead>
                    <tr>
                        <th>{_`Title`}</th>
                        <th>{_`Type`}</th>
                        <th>{_`Sender`}</th>
                        <th>{_`Type`}</th>
                        <th>{_`Date Sent`}</th>
                        <th>{_`Actions`}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderResultRows()}
                </tbody>
            </ResponsiveTable>
        );
    }

    renderTopBar () {
        if (this.props.clinicalView) {
            return (
                <TopBar
                    folderId={this.props.folderId}
                    teamId={this.props.teamId}
                >
                    <RoleCheck role="be clinician/see patient list">
                        <Link to={this.props.backRoute}>{_`Back to ${this.props.$p} List`}</Link>
                    </RoleCheck>
                    <Link to={this.props.createRoute}>{_`Add New Message`}</Link>
                </TopBar>
            );
        } else {
            return null;
        }
    }

    render () {
        return (
            <div className='message-list'>
                {this.renderTopBar()}

                <h3>{_`Messages List`}</h3>

                <form className='form-inline spacer' onSubmit={this.props.onSubmit}>
                    <input className='form-control' type='text' value={this.props.searchText}
                        placeholder={_`Search messages`} width='50' onChange={this.props.onChangeSearch}/>

                    <button type='submit' className='btn btn-default'>{_`Submit`}</button>
                </form>

                {this.renderResults()}

                <PaginationView currentPage={this.props.pagination.current}
                    pageCount={Math.ceil(this.props.count / this.props.perPage)}
                    onChange={this.props.onChangePage}/>
            </div>
        );
    }
}

export default Index;
