import React from 'react';
import PropTypes from 'prop-types'
import {IndexLink} from 'react-router';
import _ from 'services/i18n';
import UserProfileMenu from 'components/user-profile-menu';
import SearchBox from 'common/ui/search-box';
import lodash from 'lodash';
import DepartmentLocation from 'features/department/components/DepartmentLocation';

/**
 * Renders the header menu of the main page template
 */
class HeaderMenu extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            searchParams:  this.getAppliedSearchParams(props.location)
        };
    }

    componentWillReceiveProps (newProps) {
        this.setState({
            searchParams: {
                value: newProps.searchValue
            }
        });
    }

    getAppliedSearchParams({query}){
        const availableSearchTypes = ['search'];
        const [type,value] = (lodash.find(lodash.toPairs(query),([searchType]) => {
            return availableSearchTypes.indexOf(searchType) !== -1;
        }) || []);

        return {
            type,
            value
        };
    }

    searchAction(searchPayload){
        this.props.searchAction(searchPayload.type, searchPayload.value);
    }

    openSideMenu(){
        this.props.sideMenuButtonAction();
    }

    render () {

        const {
            title,
            links,
            onSearchBoxChange,
            baseLink
        } = this.props;

        const {
            searchParams
        } = this.state;

        return (
            <div>
                <div className="header-menu">
                    <div className="container">

                        <div className="header-menu__menu-button" onClick={this.openSideMenu.bind(this)}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                        <h2 className="header-menu__placeholder"><IndexLink to={baseLink}>{title}</IndexLink></h2>

                        <ul className="header-menu__menu clearfix">
                            {
                                links.map((v,i)=>{
                                    return <li className="header__items" key={i}>
                                        <IndexLink to={v.link} activeClassName="active">{v.title}</IndexLink>
                                    </li>})
                            }
                        </ul>

                        <SearchBox placeholder={'Find Patient...'}
                                inputValue={searchParams.value}
                                onChange={onSearchBoxChange}
                                onSubmit={this.searchAction.bind(this)}
                                className={'search-box_header_theme search-box_header_transform'}/>

                        {this.renderUserProfile()}
                    </div>
                </div>
                <div
                    className="container"
                    style={{
                        paddingTop: 54,
                        marginBottom: 10,
                    }}
                >
                    <DepartmentLocation />
                </div>
            </div>
        );
    }

    renderUserProfile (){
        if (!(this.props.user)){
            return null;
        }
        return    (
            <UserProfileMenu user={this.props.user}
                             upMenuContent={this.props.userMenuContent || this.props.children}
                             menu={this.props.userMenuLinks ? this.props.userMenuLinks: null}
                             className={'user-profile-menu_theme_header user-profile-menu_position'}/>
        )
    }
}

HeaderMenu.propTypes = {
    onSearchBoxChange: PropTypes.func
};

export default HeaderMenu;
