import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import faviconService from 'services/favicon.service.js';
import ServerFetch from 'server-fetch';
import _ from 'services/i18n';
import { demographicsService } from 'services/demographics.service';
import { useCurrentTeam } from 'common/useCurrentTeam';
import { useCurrentOrg } from 'common/useCurrentOrg';
import { useCurrentUser } from 'common/useCurrentUser';
import { useCurrentRoles } from 'common/useCurrentRoles';
import { useInactive } from 'common/useInactive';
import { useCurrentOrgs } from 'common/useCurrentOrgs';
import AdminSelector from 'components/admin-selector';
import DataStore from 'services/data-store';
import { AdminPortalTemplateLayout } from './AdminPortalTemplateLayout';
import { DepartmentContextProvider } from 'features/department/department.context';

import { Modal } from 'ui/modal';
import { logout } from 'actions/auth.actions';
import { browserHistory } from 'react-router';

export const AdminPortalTemplate = ({
    children,
    dispatch,
}) => {
    ServerFetch.setPortal('admin');
    useInactive('admin_portal');

    const currentOrgs = useCurrentOrgs('admin_portal');
    const [currentRoles, hasInvites] = useCurrentRoles('admin_portal', ['teamAdmin']);

    const currentOrg = useCurrentOrg();
    const currentTeam = useCurrentTeam();
    useCurrentUser();

    const handleSelect = useCallback((selectionType, selection) => {
        if(selectionType === 'team') {
            DataStore.set('me.currentOrg', undefined);
            DataStore.set('me.currentRole', selection);
        } else {
            DataStore.set('me.currentRole', undefined);
            DataStore.set('me.currentOrg', selection);
        }

        browserHistory.push('/admin_portal');
    }, []);

    useEffect(() => {
        if (hasInvites) { return; }
        if (!currentRoles || !currentOrgs ) { return; }
        if (currentTeam.teamId || currentOrg.id) { return; }
        if (currentRoles.length === 1 && currentOrgs.length === 0) {
            return handleSelect('team', currentRoles[0]);
        }

        if (currentRoles.length === 0 && currentOrgs.length === 1) {
            return handleSelect('org', currentOrgs[0]);
        }
    }, [currentRoles, currentOrgs, currentTeam, currentOrg, hasInvites, handleSelect]);

    faviconService.setDefaultIcon();

    if (!currentRoles || !currentOrgs) {
        return <div>{_`Please wait...`}</div>;
    }

    if (!currentRoles.length && !currentOrgs.length) {
        const buttons = [
            {
                type: 'callback',
                callback: () => dispatch(logout()),
                label: 'Log Out'
            }
        ];
        return (
            <Modal
                title={_`Admin Portal Access Restricted`}
                noClose={true}
                buttons={buttons}
            >
                <p>You are not currently an administration of any teams or organisations. If you
                    feel this is in error, please contact the appropriate team manager.</p>
            </Modal>
        );
    }

    if (!currentOrg.id && !currentTeam.teamId) {
        return (
            <AdminSelector
                organisations={currentOrgs}
                teams={currentRoles}
                noClose={true}
                onSubmit={handleSelect}
            />
        );
    }

    return (
        <DepartmentContextProvider>
            <AdminPortalTemplateLayout
                currentTeam={currentTeam}
                currentOrg={currentOrg}
            >
                {children}
            </AdminPortalTemplateLayout>
        </DepartmentContextProvider>
    );
};

AdminPortalTemplate.propTypes = {
    children: PropTypes.element.isRequired
};
