import React, { FC, Fragment, useEffect, useState } from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import DataStore from 'services/data-store';
import { Link, browserHistory } from 'react-router';
import NhsNumber from 'ui/nhs-number';
import { Column, Row } from 'ui';
import lodash from 'lodash';
import Pagination from 'components/pagination';
import { patientsService } from 'services/patients.service';
import { terService } from 'services/ter.service';
import {
    DeregistrationHelper,
    PatientsTableRowActions,
    PatientStatusCells
} from 'pages/clinical_portal/patients';
import { CreatePatientButton } from 'features/patients/create-patients/CreatePatientButton';
import { UserFolder } from 'models/UserFolder';
import { TeamPreferencesContext } from 'common/TeamPreferencesContext';
import { PersonPrimaryIdentifierType, TeamPreferences } from 'models/TeamPreferences';
import { getPersonPrimaryIdentifierFromUserFolder } from 'common/personPrimaryIdentifierHelpers';
import { PRIMARY_IDENTIFIER_NAMESPACE } from 'models/PersonPrimaryIdentifier';
import { teamPreferencesService } from 'services/team-preferences.service';
import { PatientsTable } from 'features/patients/patient-table/PatientsTable';
import { DashboardNavigation, DashboardTab, DashboardTabType, DashboardTabTypeList } from 'features/patients/dashboard/DashboardNavigation';
import AdvancedSearchContainer from 'features/patients/advanced-search/AdvancedSearchContainer';
import { AdvancedSearchServiceProviderInstance } from 'features/patients/advanced-search/AdvancedSearchServiceProvider';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { DateTime } from 'common/datetime/DateTime';

interface ClinicianPageState {
    service: string;
    header: string;
    menuText: string;
    prefix: string;
    patientsNumber: string;
    invites: string;
    consents: any;
    isPatientsLoading: boolean;
    patients: UserFolder[];
    patientsTotal: number;
    invitations: Map<string, any>;
    deregistrations: Map<string, any>;
    teamPreferences: TeamPreferences;
    tabs: DashboardTab[];
}

const defaultTabs: DashboardTab[] = [
    {
        title: 'Active',
        type: 'registered',
    },
    {
        title: 'Invited',
        type: 'invited',
    },
    {
        title: 'Pending',
        type: 'pending',
    }
];

export default class ClinicalIndexPage extends BasePage {
    deregistrationHelper: DeregistrationHelper;
    listeners: any[] = [];
    perPage = 100;
    baseRoute = '/clinical_portal';
    state: ClinicianPageState
    constructor(props) {
        super(props);

        this.deregistrationHelper = new DeregistrationHelper((deregistrations) => {
            this.setState({ deregistrations });
        });

        this.state = {
            service: (DataStore.get('me.currentRole') || {}).serviceName || '',
            header: _`Clinical Portal`, // ?
            menuText: _`Some information to go here...`, // ?
            prefix: '/clinical_portal', // ?
            patientsNumber: 'loading', // ?
            invites: 'loading', // ?
            consents: (DataStore.get('me.currentRole') || {}).consents,
            isPatientsLoading: false,
            patients: [],
            patientsTotal: 0,
            invitations: new Map(),
            deregistrations: new Map(),
            teamPreferences: {},
            tabs: defaultTabs,
        };

        this.onSendInvite = this.onSendInvite.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
    }

    /**
     * Get the currently applied filter.
     * This is based on the query string parameters, although validation is applied.
     *
     * @return {string} The currently applied filter, determined by query string parameters.
     */
    getAppliedFilter() {
        const filter = (this.props.location.query.filter || 'registered').trim().toLowerCase();
        if (filter === 'registered' || filter === 'invited' || filter === 'pending') {
            return filter;
        }
        console.warn('Unrecognised filter value: "' + filter + '". Defaulting to "registered".');
        return 'registered';
    }

    /**
     * Get the currently shown page of results.
     * This is parsed from query string parameters.
     * It is 1-based, and defaults to 1.
     *
     * @return {number} The page number specified in the query string, or 1 if it is not specified.
     */
    getPageNumber() {
        const pageNumber = parseInt(this.props.location.query.page || '1');
        if (pageNumber <= 0) {
            console.warn('Invalid page number: ' + pageNumber);
            return 1;
        }
        return pageNumber;
    }

    pageTitle() {
        return _`Dashboard | PHR Clinical Portal`;
    }

    onSendInvite(folderId, email) {
        return this.setInvitationStatus(folderId, 'sending')
            .then(() => {
                return terService.createTer({ folderId, action: 'sendInvitation', data: email ? { email } : {} });
            }).then(() => {
                return this.setInvitationStatus(folderId, 'sent');
            }).catch((err) => {
                this.setInvitationStatus(folderId, 'failed');
                return Promise.reject(err);
            });
    }
    /**
     * Set the status of the specified invitation.
     * Returns a promise which resolves when the state has been updated.
     *
     * @param {number} inviteId The numeric ID of the invitation which was sent.
     * @param {string} status The status of the invitation. Valid values are: sending, sent, failed.
     * @return {Promise}
     */
    setInvitationStatus(inviteId, status) {
        if (status !== 'sending' && status !== 'sent' && status !== 'failed') {
            console.warn('Unrecognised invitation status: ' + status);
            return Promise.reject();
        }

        return this.$setState((prevState, props) => {
            const invitations = prevState.invitations || new Map();
            invitations.set(inviteId, status);
            return { invitations: invitations };
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.listeners.forEach(listenerIndex => {
            DataStore.removeListener(listenerIndex);
        });
    }

    componentDidMount() {
        this.listeners.push(
            DataStore.getListener('me.currentRole', (value) => {
                if (value) {
                    this.setState({
                        consents: value.consents
                    });
                }
            })
        );

        if (this.state.service === 'MSK') {
            this.loadTabPatients();

            teamPreferencesService.getFirst({}).catch(() => {
                return teamPreferencesService.defaultProperties;
            }).then((teamPreferences) => {
                this.setState({ teamPreferences });
                this.handleTabVisibilityPreferences(teamPreferences);
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            filter: newFilter,
            page: newPage,
            uuid: newUuid,
        } = this.props.location.query;
        const {
            filter: prevFilter,
            page: prevPage,
            uuid: prevUuid,
        } = prevProps.location.query;

        const { teamPreferences: newTeamPreferences } = this.state;
        const { teamPreferences: prevTeamPreferences } = prevState;

        if (newTeamPreferences.show_advanced_search_button != prevTeamPreferences.show_advanced_search_button) {
            this.handleTabVisibilityPreferences(newTeamPreferences);
        }

        // if query params changed
        if (
            prevFilter !== newFilter ||
            prevPage !== newPage ||
            prevUuid !== newUuid
        ) {
            this.loadTabPatients();
        }
    }

    handleTabVisibilityPreferences(teamPreferences: any) {
        const showDefaultTabs = teamPreferences.portal.show_default_dashboard_tabs;
        const newTabs = showDefaultTabs ? [...defaultTabs] : [];
        if (teamPreferences.show_advanced_search_button) {
            newTabs.push({
                title: 'Custom',
                type: 'advanced-search'
            });
            this.setState({ tabs: newTabs });
        }

        const activeTab = this.getSelectedTab();
        if (!newTabs.find(tab => tab.type == activeTab)) {
            this.handleChangeTab(newTabs.length > 0 ? newTabs[0].type : undefined);
        }
    }

    /**
     * Request a list of patients with the given filter from the server.
     * Returns a promise which resolves with the server response,
     *  or rejects if an error occurs.
     *
     * @param {number} page The page number of results to request.
     * @param {string} filter The type of filter to apply. Valid values are: 'all', 'registered', 'invited', 'pending'.
     * @return {Promise}
     */
    getPatientsList(page, filter) {

        const allowedFilters = [
            patientsService.STATUS.INVITED,
            patientsService.STATUS.PENDING,
            patientsService.STATUS.REGISTERED
        ];

        const params: any = {
            offset: (page - 1) * this.perPage,
            limit: this.perPage
        };

        if (lodash.indexOf(allowedFilters, filter) !== -1) {
            params.status = filter;
        } else {
            console.warn('Unrecognised patient list filter: ' + filter);
        }

        return patientsService.list(params);
    }
    /**
     * Event handler: User has selected a different page of results.
     *
     * @param {number} newPage The number of the page being switched to.
     */
    onChangePage(newPage) {
        this.changeQuery({ page: newPage });
    }

    loadTabPatients = () => {
        const { page } = this.props.location.query;
        const filter = this.getAppliedFilter();

        if (!DashboardTabTypeList.includes(filter) || !page) {
            return;
        }

        if (filter == 'advanced-search') {
            return;
        }

        return this.getPatientsList(page, filter)
            .then(({ patients, patientsTotal }) => {
                this.setState({
                    patients,
                    patientsTotal,
                    isPatientsLoading: false
                });
            })
            .catch(() => {
                this.setState({
                    isPatientsLoading: false
                });
            });
    }

    handleChangeTab = async (tab: DashboardTabType) => {
        browserHistory.replace({
            pathname: this.props.location.pathname,
            query: {
                filter: tab,
                page: 1,
                uuid: undefined,
            },
        });
    }

    getSelectedTab: () => DashboardTabType | null = () => {
        const routeFilter = this.props.location.query.filter;

        if (DashboardTabTypeList.includes(routeFilter)) {
            return routeFilter as DashboardTabType;
        }

        return null;
    }

    render() {
        const {
            tabs
        } = this.state;

        const activeTab = this.getSelectedTab();

        return (
            <div className="dashboard-page">
                {
                    this.state.service === 'MSK' && (
                        <div>
                            <Row>
                                <Column sm="12">
                                    <h2>
                                        Your Patient Filters
                                    </h2>

                                    <p>
                                        View and manage your patients&apos; appointments, resources, questionnaires, messages and referrals.
                                    </p>
                                    <CreatePatientButton/>
                                    <DashboardNavigation
                                        activeTab={activeTab}
                                        tabs={tabs}
                                        onTabChange={this.handleChangeTab}
                                        currentRoute={this.props.location.pathname}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column sm="12">
                                    { activeTab == 'advanced-search' && this.state.tabs.find(tab => tab.type == 'advanced-search') && (
                                        <AdvancedSearchContainer
                                            searchService={AdvancedSearchServiceProviderInstance}
                                        />
                                    )}
                                    { activeTab != 'advanced-search' && (
                                        <Fragment>
                                            <TeamPreferencesContext.Consumer>
                                                {({ primary_identifier: primaryIdentifierType }) => (
                                                    <PatientsTable
                                                        isPatientsLoading={this.state.isPatientsLoading}
                                                        patientsTotal={this.state.patientsTotal}
                                                        activeTab={activeTab}
                                                    >
                                                        {
                                                            this.state.patients.map((patient) => {
                                                                return (
                                                                    <PatientsTableRow
                                                                        primaryIdentifierType={primaryIdentifierType}
                                                                        deregistrationHelper={this.deregistrationHelper}
                                                                        invitations={this.state.invitations}
                                                                        patient={patient}
                                                                        key={patient.uuid}
                                                                        activeTab={activeTab}
                                                                        onSendInvite={this.onSendInvite}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </PatientsTable>
                                                )
                                                }
                                            </TeamPreferencesContext.Consumer>
                                            <PaginationBlock {...this.state}
                                                currentPage={this.getPageNumber()}
                                                onChange={this.onChangePage}
                                                perPage={this.perPage}/>
                                        </Fragment>
                                    )}
                                </Column>
                            </Row>
                        </div>
                    )
                }
            </div>
        );
    }
}

interface PatientsTableRowProps {
    deregistrationHelper: DeregistrationHelper;
    invitations: Map<string, any>;
    patient: UserFolder;
    activeTab: DashboardTabType;
    onSendInvite: (folderId: string, email: string) => void;
    primaryIdentifierType: PersonPrimaryIdentifierType;
}
const PatientsTableRow: FC<PatientsTableRowProps> = ({ activeTab, primaryIdentifierType, deregistrationHelper, invitations, patient, onSendInvite }) => {
    const { deregistrations } = deregistrationHelper;
    const givenName = patient?.user_details?.name?.given_name;
    const familyName = patient?.user_details?.name?.family_name;
    const teamPrefs = useTeamPreferences();
    const [patientLink, setPatientLink] = useState(`/clinical_portal/folder/${patient?.id}/patient`);

    const personPrimaryIdentifier = getPersonPrimaryIdentifierFromUserFolder(patient, primaryIdentifierType?.human_readable_labels);

    const isShowDeregister = !deregistrations.get(patient?.id);

    useEffect(() => {
        if (teamPrefs.portal.show_dashboard) {
            setPatientLink(`/clinical_portal/folder/${patient?.id}/patient/dashboard`);
        } else if (teamPrefs.portal.show_pathway) {
            setPatientLink(`/clinical_portal/folder/${patient?.id}/patient/pathway`);
        } else {
            setPatientLink(`/clinical_portal/folder/${patient?.id}/patient/appointments`);
        }
    }, [teamPrefs.portal.show_dashboard, teamPrefs.portal.show_pathway, patient]);

    return (
        <tr>
            <td>
                <Link to={patientLink}>
                    {[givenName, familyName].join(' ').trim() || 'Not Set'}
                </Link>
            </td>
            <td>
                {patient?.hospital_number || '-'}
            </td>
            {
                (activeTab === 'registered' || activeTab === 'invited') &&
                (
                    <td>
                        {activeTab === 'registered' && patient?.date_registered?.date && <DateTime>{patient.date_registered.date}</DateTime>}
                        {activeTab === 'invited' && patient?.date_invited?.date && <DateTime>{patient.date_invited.date}</DateTime>}
                    </td>
                )
            }
            <td>
                {personPrimaryIdentifier?.namespace === PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER ?
                    <NhsNumber empty="-">{personPrimaryIdentifier?.value}</NhsNumber>:
                    <Fragment>{personPrimaryIdentifier?.value}</Fragment>
                }
            </td>
            <td><PatientStatusCells deregistrations={deregistrations} invitations={invitations} data={patient}/></td>
            <td>
                <PatientsTableRowActions
                    data={patient}
                    isShowDeregister={isShowDeregister}
                    onSendInvite={onSendInvite}
                    onDeregister={(folderId) => deregistrationHelper.deregister(folderId)}
                />
            </td>
        </tr>
    );
};

const PaginationBlock: FC<{
    patientsTotal: number;
    perPage: number;
    currentPage: number;
    onChange: (newPage) => void;
}> = ({ patientsTotal, perPage, currentPage, onChange }) => {
    return (
        <div>
            <Pagination
                pageCount={Math.ceil(patientsTotal / perPage)}
                currentPage={currentPage}
                onChange={onChange}
            />
            <p>
                Total number of results: {patientsTotal}
            </p>
        </div>
    );
};
