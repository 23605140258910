import { makeGetRequest } from './api';
import { DataStore } from './data-store';
import { CompositionService } from './composition.service';
import { EmailTemplateContent } from 'components/user/email/create/validator';
import { ContentVariableItem } from 'common/useContentVariables';

const apiVersion = 2;

class ContentService extends CompositionService {
    get archetypeName(): string {
        return 'content';
    }

    public getBulkOperationEmailTemplates = (): Promise<EmailTemplateContent[]> => {
        const role = DataStore.get('me.currentRole');

        return makeGetRequest(`/teams/${role.teamId}/team-folder/content?types[]=emails&using_role_uuid=${role.uuid}`, apiVersion)
            .then((response) => response.message.items[0].content.templates || []);
    }

    async getAllVariables(): Promise<ContentVariableItem[]> {
        const role = DataStore.get('me.currentRole');

        const response = await makeGetRequest(
            `/teams/${role.teamId}/team-folder/content/variables?using_role_uuid=${role.uuid}`,
            apiVersion,
        );

        return response.message.variables;
    }
}

export const contentService = new ContentService();
