
export type SendMessageState =
{
    title: string;
    content: string;
    sendPush: boolean;
}

export function validate(state: SendMessageState, onErrors: (errors: string[]) => void): boolean
{
    const normalisedState = normalise(state);

    const errors = [];
    if (normalisedState.title === '') {
        errors.push('Title is required');
    }

    if (normalisedState.content === '') {
        errors.push('Content is required');
    }

    onErrors(errors);
    
    return errors.length == 0;
}

export function normalise(state: SendMessageState): SendMessageState
{
    return {
        title: state.title.trim(),
        content: state.content.trim(),
        sendPush: state.sendPush,
    };
}
