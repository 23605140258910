import React, { ReactElement, Fragment } from 'react';
import _ from 'services/i18n';
import { Params } from 'react-router/lib/Router';

import { useCurrentTeam } from 'common/useCurrentTeam';
import { usePrivacyNotices } from './usePrivacyNotices';
import { PrivacyNoticeComposition } from './PrivacyNotice.archetype';

import { Row, Column } from 'ui';
import Markdown from 'components/ui/markdown';
import HeadingDoc from 'ui/heading-doc';

export function PrivacyNotice({
    params,
}: {
    params: Params
}): ReactElement {
    const team = useCurrentTeam();
    const [privacyNotices, isLoading] = usePrivacyNotices(team);
    const privacyNotice: PrivacyNoticeComposition = privacyNotices.find(fetchedPrivacyNotice => fetchedPrivacyNotice.uuid === params.uuid)

    return <div className="page">
        <HeadingDoc title="Privacy Policy">
            {!isLoading && privacyNotice && <Fragment>
                <p>Name: {privacyNotice.content.name}</p>
                <p>Language: {privacyNotice.content.language || _`Not set`}</p>
                <p>Version: {privacyNotice.content.version}</p>
            </Fragment>}
        </HeadingDoc>
        <Column md="12">
            {!!isLoading && <h1>Loading...</h1>}
            {!isLoading && !privacyNotice && <h1>Not Found</h1>}
            {!isLoading && privacyNotice && <div>
                <h2>Summary</h2>
                <Row>
                    <Markdown>{privacyNotice.content.summary}</Markdown>
                </Row>
                <h2>Body</h2>
                <Row>
                    <Markdown>{privacyNotice.content.body}</Markdown>
                </Row>
            </div>}
        </Column>
    </div>
}
