import { useIsAccessableUrl } from 'common/routes/routes';
import React from 'react';
import { browserHistory } from 'react-router';

const Tab = ({
    route,
    routes,
    title,
    selectedTabIdx,
    idx,
    onClick,
    preventDefault,
    isTabVisible
}) => {
    const [isAccessable] = useIsAccessableUrl(routes, route);
    if (!isAccessable) { return null; }
    if (!isTabVisible) { return null; }

    // Style classes identify the currently selected tab.
    let className = 'nav-item';
    if (selectedTabIdx == idx) {
        className += ' active';
    }

    // Setup the onClick handler for this tab.
    let onClickHandler;
    if (onClick == null) {
        // Define a local click handler which navigates to the tab's route.
        onClickHandler = (e) => {
            e.preventDefault();
            if (route === null) {
                console.warn('Tab "' + title + '" does not have a route. Cannot navigate to it.');
            } else {
                browserHistory.push(route);
            }
        };
    } else {
        // Bind to the parent component's click handler.
        onClickHandler = onClick.bind(null, idx);
    }

    return (
        <li key={idx} className={className} onClick={onClickHandler}>
            <a href={route || '#'} className="nav-link" onClick={preventDefault}>
                {title}
            </a>
        </li>
    );
};

/**
 * Component for showing a series of tabs, each of which will navigate to a different route.
 * It is designed to be used as a consistent navigation bar across multiple pages.
 *
 * Parameters:
 *  - currentRoute = Conditional. The route currently being viewed. This is used to infer the current tab. Ignored if selectedItem is specified.
 *  - selectedItem = Conditional. Integer index of the currently selected tab. Required if currentRoute is not specified.
 *  - onClick = Optional. Event handler for responding to clicks on tabs. Passes in the index of the clicked tab. If not specified, a default handler will be defined which navigates to each tab's route.
 *  - tabPosition = Optional. Indicates where the tabs will be positioned; 'top', 'left', 'right', 'bottom'. Defaults to 'top'. This dictates the visual style.
 *  - tabStyle = Optional. Determines the visual style of the tabs. For top positioned tabs, this defaults to 'tab', but can be set to 'pill'. All other positions only use 'pill'.
 *
 * The child components should be instances of NavTab (see below).
 */
export const TabNavigator = (props) => {
    const tabsInfo = [].concat(props.children);

    // Event handler to prevent the default action.
    // This is used to prevent the links navigating by href.
    const preventDefault = (e) => {
        e.preventDefault();
    };

    // Determine which tab should be shown as currently selected.
    let selectedTabIdx = null;
    if (props.selectedItem !== undefined && props.selectedItem !== null) {
        // A specific tab has been identified in the props.
        selectedTabIdx = props.selectedItem;

    } else if (props.currentRoute != null) {
        // We need to match the tabs to the current route automatically.
        const currentRouteLC = props.currentRoute.toLowerCase();

        // Check for an exact match first.
        tabsInfo.some((item, idx) => {
            if (item.props.route != null && item.props.route.toLowerCase() == currentRouteLC) {
                selectedTabIdx = idx;
                return true;
            }
            return false;
        });

        // Fall-back on prefix matching if necessary.
        if (selectedTabIdx === null) {
            tabsInfo.some((item, idx) => {
                if (item.props.route != null && item.props.prefixMatch == true && currentRouteLC.indexOf(item.props.route.toLowerCase() + '/') === 0) {
                    selectedTabIdx = idx;
                    return true;
                }
                return false;
            });
        }
    } else {
        console.warn('Either currentRoute or selectedItem must be specified in the props.');
    }

    if (selectedTabIdx === null) {
        console.warn('Unable to identify currently selected tab.');
    }

    // Go through the input tab data to render the tab list.
    const tabList = tabsInfo.map((item, idx) => (
        <Tab
            route={item.props.route}
            routes={props.routes}
            title={item.props.title}
            selectedTabIdx={selectedTabIdx}
            idx={idx}
            key={idx}
            onClick={props.onClick}
            isTabVisible={item.props.isTabVisible !== undefined ? item.props.isTabVisible : true}
            preventDefault={preventDefault}
        />
    )).filter(component => !!component);

    // Setup suitable defaults.
    let defaultTabStyle, vertical;
    switch (tabPosition) {
    case 'left':
        defaultTabStyle = 'pill';
        vertical = true;
        break;

    case 'right':
        defaultTabStyle = 'pill';
        vertical = true;
        break;

    case 'bottom':
        defaultTabStyle = 'pill';
        vertical = false;
        break;

    default:
        // Always default to top position.
        defaultTabStyle = 'tab';
        vertical = false;
        break;
    }

    const tabPosition = props.tabPosition || 'top';
    const tabStyle = props.tabStyle || defaultTabStyle;
    const hasClassName = props.className;

    // Select suitable classes for the navigation list.
    let listClassName = tabStyle === 'pill' ? 'nav nav-pills ' : 'nav nav-tabs ';

    if (hasClassName) listClassName += props.className + ' ';

    if (vertical) listClassName += 'nav-stacked ';

    return (
        <div className="tab-container">
            <ul className={listClassName}>
                {tabList}
            </ul>
        </div>
    );
};

/**
 * Represents a single tab in the tab navigator.
 * This is a dummy component designed only to provide information to the parent TabNavigator.
 * Child elements inside these tabs are ignored.
 *
 * Properties:
 *  - title = Required. The name which will appear in the tab in the UI.
 *  - route = Conditional. The route which the tab navigates to. Required if an onClick handler is not provided in the TabNavigator props.
 *  - prefixMatch = Optional. Only relevant if currentRoute is specified in the TabNavigator props. This allows this tab to be identified using prefix matching on the route. If false or omitted, the route must match exactly.
 */
export const NavTab = (props) => {
    console.warn('This is a proxy component for providing data only. It should not be rendered.');
    return (<noscript />);
};
