import React, { useEffect } from 'react';

import { useI18N } from 'common/i18n/useI18N';
import PageTitle from 'page-title';
import { useCurrentTeam } from 'common/useCurrentTeam';

import { useMNDPatientsWorklistCollection } from './useMNDPatientsWorklistCollection';
import { MndWorklist } from './MndWorklist';

export const MndWorklistPage = () => {
    const _ = useI18N();
    const team = useCurrentTeam() || {};
    const [
        worklistItems,
        { isLazyFetching }
    ] = useMNDPatientsWorklistCollection({
        teamId: team.teamId
    });

    useEffect(() => {
        PageTitle.setTitle(_`Worklist for MND patients | MyPathway Clinical Portal`);
    }, [_]);

    return (
        <MndWorklist items={worklistItems} isLazyFetchingInProcess={isLazyFetching}/>
    );
};
