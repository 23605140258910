import { ADMINP_MEMBERS_LIST } from './types';
import { AsyncStates, ModalTypes } from 'reducers/admin-portal/members-page.reducer';
import { folderService } from 'services/folder.service';
import { CreateUserContent } from 'models/TriggerEngineRequestContent';
import { terService } from 'services/ter.service';

export const inviteClinician = () => {
    return (dispatch: any) => {
        dispatch({
            type: ADMINP_MEMBERS_LIST.OPEN_MODAL,
            payload: { type: ModalTypes.Invite }
        });
    };
};

export const submitNewUser = ({ user, withTer, folderGroupUuids }: { user: CreateUserContent; withTer?: boolean; folderGroupUuids: string[] }) => {
    return (dispatch: any) => {

        dispatch({
            type: ADMINP_MEMBERS_LIST.SUBMIT_NEW_USER,
            payload: { state: AsyncStates.Loading }
        });

        let promise;
        if(withTer) {
            promise = folderService.createUserFolderWithTer({
                user
            });
        } else {
            promise = folderService.getFoldersDefinitions({}).then((folderGroupDefinitions) => {
                const nonPatientFolderGroupDefinitions = folderGroupDefinitions
                    .filter(folder => folder.name.indexOf('Patient') !== 0)
                    .map(folder => folder.uuid);

                const filteredFolderGroupUuids = folderGroupUuids.filter(uuid => nonPatientFolderGroupDefinitions.indexOf(uuid) !== -1);
                return folderService.createUserFolder({
                    user,
                    folderGroupUuids: filteredFolderGroupUuids,
                });
                // eslint-disable-next-line @typescript-eslint/camelcase
            }).then(({ message: { folder_id } }: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                return terService.createTer({ folderId: folder_id, action: 'sendInvitation' });
            });
        }

        promise.then(() => {
            dispatch({
                type: ADMINP_MEMBERS_LIST.SUBMIT_NEW_USER,
                payload: { state: AsyncStates.Success }
            });
        }).catch(() => {
            dispatch({
                type: ADMINP_MEMBERS_LIST.SUBMIT_NEW_USER,
                payload: { state: AsyncStates.Fail }
            });
        });
    };
};

export const closeModal = (type: string) => {
    return (dispatch: any) => {
        dispatch({
            type: ADMINP_MEMBERS_LIST.CLOSE_MODAL
        });
    };
};

export const finishRegistration = (reset = true) => {
    return (dispatch: any) => {
        dispatch({
            type: ADMINP_MEMBERS_LIST.CLOSE_MODAL
        });
        dispatch({
            type: ADMINP_MEMBERS_LIST.RESET_INVITE_FORM
        });
    };
};
