
import React from 'react';
import { StepsChart } from 'components/steps-chart';
import classNames from 'classnames';

export function StepsChartFragment({
    stepsData,
    currentWeekIndex,
    currentDay,
    threshold,
    onSelectNextPage,
    onSelectPrevPage,
}) {
    return (
        <div className="graph-wrapper">
            <div className="graph-wrapper__bt graph-wrapper__bt-left">
                <button type="button"
                        onClick={onSelectPrevPage}
                        disabled={!stepsData[currentWeekIndex - 1]}
                        className={classNames('button glyphicon glyphicon-arrow-left', {
                            'button--hidden': !stepsData[currentWeekIndex - 1]
                        })}/>
            </div>
            <div className="graph-wrapper__graph">
                <h2>
                    Current day {currentDay}
                    <br/>
                    Week {currentWeekIndex + 1}
                </h2>
                <StepsChart data={stepsData[currentWeekIndex].days} threshold={threshold}/>
            </div>

            <div className="graph-wrapper__bt graph-wrapper__bt-right">
                <button type="button"
                        onClick={onSelectNextPage}
                        disabled={!stepsData[currentWeekIndex + 1]}
                        className={classNames('button glyphicon glyphicon-arrow-right', {
                            'button--hidden': !stepsData[currentWeekIndex + 1]
                        })}/>
            </div>
        </div>
    );
}
