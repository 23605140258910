import { DemographicsData } from 'features/patient/patient-dashboard/dashboard-components/dashboard-demographics-table/DashboardDemographicsTable';
import { prepareDemographics } from 'features/patient/patient-dashboard/data/getDataForDemographicTable';
import { demographicsService } from 'services/demographics.service';
import { patientsService } from 'services/patients.service';
import { IUserFolder } from 'phr-api-client';
import { UserFolder } from 'models/UserFolder';
import { folderService } from 'services/folder.service';

export const getDataForCarerDemographicTable = async (params: {folderId: number}): Promise<DemographicsData> => {
    const { folderId } = params;
    if(!folderId) {
        return;
    }
    const { carer } = await getCarerInfo(folderId);

    if(!carer) {
        return;
    }

    const composition = await demographicsService.getFirst({ folderId: carer.id });

    if (composition?.content) {
        return prepareDemographics(composition.content, carer.person_primary_identifier.value);
    }
};

export const getCarerInfo = async (folderId: number): Promise<{ carer: IUserFolder }> => {
    const patientFolder: UserFolder =  await folderService.getFolderById({ folderId });
    const { patients }: any  = await patientsService
        .search({ patientIdentifier: `Carer_${patientFolder.person_primary_identifier.value}` });
    return { carer: patients?.[0] };
};
