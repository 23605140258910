import React, { useState, useEffect, FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker';
// interfaces
import { LettersService } from 'services/letters.service';
import { LetterStationeryItem } from '../letters.interface';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

type LetterPdfPreviewProps = {
    fileDownloadFn: () => Promise<ArrayBuffer>
};

export const generatePdfPreview: (
    stationery: LetterStationeryItem,
    letter: string,
    letterService: LettersService
) => Promise<ArrayBuffer> = (
    stationery: LetterStationeryItem,
    letter: string,
    letterService: LettersService
) => {
    return letterService.getPdfPreview({
        header: stationery.header.htmlFilename,
        body: letter,
        footer: stationery.footer.htmlFilename,
    });
}

export const LetterPdfPreview: FC<LetterPdfPreviewProps> = (props) => {
    const [error, setError] = useState<string>();
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(true);
    const [previewUrl, setPreviewUrl] = useState<string>();

    useEffect(() => {
        props.fileDownloadFn()
            .then((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                setError('');
                setPreviewUrl(url);
                setIsLoadingPreview(false);
            })
            .catch(() => {
                setError('An error occurred');
                setIsLoadingPreview(false);
            });
    }, []);

    return (
        <div>
            {error && <span>{error}</span>}
            {!error && isLoadingPreview && <span>Loading...</span>}
            {!error && !isLoadingPreview && (
                <div>
                    <Document file={previewUrl}>
                        <Page pageNumber={1} width={300} />
                    </Document>
                    <div>
                        <a href={previewUrl} download="letter-preview.pdf">
                            Download file
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};
