/* eslint-disable indent */
import React, { ChangeEvent, FC } from 'react';
// components
import {
    AdvancedSearchCriterionDateInput,
    AdvancedSearchCriterionListInputSingleSelection,
    AdvancedSearchCriterionNumberInput,
    AdvancedSearchCriterionTextInput,
    AdvancedSearchInputUnavailable,
} from 'features/patients/advanced-search/AdvancedSearchCriterionInputs';
// hooks
import { useCriterionSingleValue } from 'features/patients/advanced-search/AdvancedSearchSetupHooks';
// interfaces
import { AdvancedSearchCriterionSingleValueProps } from './AdvancedSearchCriterionSingleValue.interface';

export const AdvancedSearchCriterionSingleValue: FC<AdvancedSearchCriterionSingleValueProps> =
    (props) => {
        const type = props.config.type;

        const [value, setValue] = useCriterionSingleValue(props.criterionValue);

        const onInput = (e: ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value);

        if (props.config.options?.length > 0 || type == 'yesNo') {
            return (
                <AdvancedSearchCriterionListInputSingleSelection
                    config={props.config}
                    value={value}
                    onChange={setValue}
                />
            );
        }
        

        switch (type) {
            case 'integer':
                return (
                    <AdvancedSearchCriterionNumberInput
                        value={value}
                        onChange={onInput}
                        step={1}
                    />
                );
            case 'float':
                return (
                    <AdvancedSearchCriterionNumberInput
                        value={value}
                        onChange={onInput}
                    />
                );
            case 'text':
                return (
                    <AdvancedSearchCriterionTextInput
                        value={value}
                        onChange={onInput}
                    />
                );
            case 'date':
            case 'datetime':
                return (
                    <AdvancedSearchCriterionDateInput
                        value={value}
                        onChange={setValue}
                        reset={() => setValue(undefined)}
                    />
                );
            default:
                console.error(
                    `Search criteria type [${type}] isn't a single-value input.`,
                );
                return <AdvancedSearchInputUnavailable />;
        }
    };
