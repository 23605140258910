import React, { FunctionComponent } from 'react';
import { useI18N } from 'common/i18n/useI18N';

import Markdown from 'components/ui/markdown';

interface AnswerProps {
    input: any;
    answer: any;
}
const McqAnswer: FunctionComponent<AnswerProps> = ({ input, answer }) => {
    const _ = useI18N();
    const value = answer.value;
    return (
        <table className="questionnaire__table-answer">
            <tbody>
                <tr>
                    {input.label &&
                        <td style={{ width: '60%' }}><Markdown>{input.label}</Markdown></td>
                    }
                    <td>
                        <Markdown>{value ? input.options[value] : _`No Answer Given`}</Markdown>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const SliderAnswer: FunctionComponent<AnswerProps> = ({ input, answer }) => {
    const _ = useI18N();
    return (
        <table className="questionnaire__table-answer questionnaire-slider-answer">
            <thead>
                <tr>
                    {!!input.label && <th>{_`Label`}</th>}
                    <th className="questionnaire-slider-answer__min-max-header">{_`Answer`}</th>
                    <th className="questionnaire-slider-answer__min-max-header">{_`Min`}</th>
                    <th className="questionnaire-slider-answer__min-max-header">{_`Max`}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    {input.label ? <th><Markdown>{input.label}</Markdown></th> : null}
                    <td className="questionnaire-slider-answer__min-max-cell">
                        {answer.value == null ? _`No Answer Given` : answer.value}
                    </td>
                    <td className="questionnaire-slider-answer__min-max-cell">
                        <div><Markdown sanitize={false}>{input.options.min_label}</Markdown></div>
                        <div>{input.options.min}</div>
                    </td>
                    <td className="questionnaire-slider-answer__min-max-cell">
                        <div><Markdown sanitize={false}>{input.options.max_label}</Markdown></div>
                        <div>{input.options.max}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const TextAnswer: FunctionComponent<AnswerProps> = ({ input, answer }) => {
    const _ = useI18N();
    return (
        <table className="questionnaire__table-answer">
            {input.label && (
                <thead>
                    <tr>
                        <th><Markdown>{input.label}</Markdown></th>
                    </tr>
                </thead>
            )}
            <tbody>
                <tr>
                    <td>
                        <Markdown>{answer.value == null ? _`No Answer Given` : answer.value}</Markdown>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const NumberAnswer: FunctionComponent<AnswerProps> = ({ input, answer }) => {
    const _ = useI18N();
    return (
        <table className="questionnaire__table-answer">
            <tbody>
                <tr>
                    {input.label &&
                <td style={{ width: '60%' }}><Markdown>{input.label}</Markdown></td>}
                    <td>
                        <Markdown>{answer.value == null ? _`No Answer Given` : answer.value}</Markdown>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const BooleanAnswer: FunctionComponent<AnswerProps> = ({ input, answer }) => {
    const _ = useI18N();
    return (
        <table className="questionnaire__table-answer">
            <tbody>
                <tr>
                    {input.label &&
                <td style={{ width: '60%' }}><Markdown>{input.label}</Markdown></td>}
                    <td>
                        <Markdown>{answer.value == null ? _`No Answer Given` : answer.value ? _`Checked` : _`Not Checked`}</Markdown>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const MultiSelectAnswer: FunctionComponent<AnswerProps> = ({ input, answer }) => {
    const _ = useI18N();
    return (
        <table className="questionnaire__table-answer questionnaire-multi-select-answer">
            <tbody>
                <tr>
                    { input.label &&
                        <td style={{ width: '60%' }}><Markdown>{input.label}</Markdown></td>
                    }
                    <td>
                        { answer.value && (
                            <ul>
                                { answer.value.map((value: string, key) => {
                                    return (<li key={key}>{ input.options[value] }</li>);
                                }) }
                            </ul>
                        )}
                        { !answer.value &&
                            <Markdown>{_`No Answer Given`}</Markdown>
                        }
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const BodyMapAnswer: FunctionComponent<AnswerProps> = ({ input, answer }) => {
    const heightBound = 400; //the template image is bigger than the completed image so limit height & preserve ratio
    const imgSrc = answer.value ?? input.options;
    return (
        <div className={'hflow-vcenter'}>
            <img src={imgSrc[0]} height={heightBound} alt={'Body map front view'}/>
            <img src={imgSrc[1]} height={heightBound} alt={'Body map rear view'}/>
        </div>
    );
};

const AnswerInput: FunctionComponent<{
    answerInput: any;
    question: any;
}> = ({
    answerInput,
    question,
}) => {
    const input = question.inputs[answerInput.id - 1] || {};
    switch (input.type) {
    case 'mcq':
        return <McqAnswer input={input} answer={answerInput}/>;
    case 'slider':
        return <SliderAnswer input={input} answer={answerInput}/>;
    case 'text':
        return <TextAnswer input={input} answer={answerInput}/>;
    case 'number':
        return <NumberAnswer input={input} answer={answerInput}/>;
    case 'boolean':
        return <BooleanAnswer input={input} answer={answerInput}/>;
    case 'multiSelect':
        return <MultiSelectAnswer input={input} answer={answerInput}/>;
    case 'bodychart':
        return <BodyMapAnswer input={input} answer={answerInput}/>;
    default:
        console.error('[Questionnaire] Unknown type of input', input);
        return <div>{answerInput.value}</div>;
    }
};

interface QuestionRowProps {
    question: any;
    answer: any;
    scoreValues: any[];
    previousAnswer?: any;
    previousScoreValues?: any[];
}

export const QuestionnaireRow: FunctionComponent<QuestionRowProps> = ({
    question,
    answer: answerInputs,
    scoreValues,
    previousAnswer: previousAnswerInputs,
    previousScoreValues
}) => {
    const markdownContent = `${question.questionDisplayNumber}${question.question}`;
    const answerChanged = previousAnswerInputs?.length > 0 && answerInputs.some((answerInput, index) => {
        return previousAnswerInputs[index].value !== answerInput.value;
    });
    const answerGivenChanged = answerChanged && (
        answerInputs.some((answerInput) => !answerInput.value) || previousAnswerInputs.some((answerInput) => !answerInput.value)
    );
    const answerChangedClass = answerGivenChanged ? 'bg-diff-neutral-lighter' : answerChanged ? 'bg-diff-neutral' : '';
    return (
        <tr>
            <td><Markdown sanitize={false}>{markdownContent}</Markdown></td>
            <td className={answerChangedClass}>{
                answerInputs.map((answerInput, i) => <AnswerInput answerInput={answerInput} question={question} key={i}/>)
            }</td>
            {!!scoreValues && scoreValues.length == 1 && <td className={`text-center ${answerChangedClass}`}>{scoreValues[0].value}</td>}
            {!!scoreValues && scoreValues.length != 1 && <td className={answerChangedClass} >&nbsp;</td>}
            { previousAnswerInputs?.length > 0 && (
                <td className={answerChangedClass}>{
                    previousAnswerInputs.map((answerInput, i) => <AnswerInput answerInput={answerInput} question={question} key={i}/>)
                }</td>
            )}
            {!!previousScoreValues && previousScoreValues.length == 1 && <td className={`text-center ${answerChangedClass}`}>{previousScoreValues[0].value}</td>}
            {!!previousScoreValues && previousScoreValues.length != 1 && <td className={answerChangedClass} >&nbsp;</td>}
        </tr>
    );
};
