import { Label } from 'models/Label';
import { QuestionnaireResult } from 'models/QuestionnaireResult';

export enum ACTION_NAME {
    PATHWAY_CONTEXTS_RECEIVED = 'PATHWAY_CONTEXTS_RECEIVED',
    RESULTS_RECEIVED = 'RESULTS_RECEIVED',
    CLEAN_JOBS = 'CLEAN_JOBS',
    ONCHANGE_CONTEXT = 'ONCHANGE_CONTEXT',
    ONCHANGE_QUESTIONNAIRE = 'ONCHANGE_QUESTIONNAIRE',
    ONCHANGE_MEASURE = 'ONCHANGE_MEASURE',
}

export type Action =
    | { type: ACTION_NAME.PATHWAY_CONTEXTS_RECEIVED; payload: Label[] }
    | { type: ACTION_NAME.CLEAN_JOBS; payload? }
    | { type: ACTION_NAME.RESULTS_RECEIVED; payload: { results: Record<string, QuestionnaireResult[]>; context: string }}
    | { type: ACTION_NAME.ONCHANGE_CONTEXT; payload: { id: string; value: string }}
    | { type: ACTION_NAME.ONCHANGE_QUESTIONNAIRE; payload: { id: string; value: string }}
    | { type: ACTION_NAME.ONCHANGE_MEASURE; payload: { id: string; value: string }};

export const actionCleanJobs = (): Action => {
    return { type: ACTION_NAME.CLEAN_JOBS };
};
export const actionResultsReceived = (payload: { results: Record<string, QuestionnaireResult[]>; context: string }): Action => {
    return { type: ACTION_NAME.RESULTS_RECEIVED, payload };
};
export const actionContextsReceived = (labels: Label[]) => {
    return { type: ACTION_NAME.PATHWAY_CONTEXTS_RECEIVED, payload: labels };
};
export const actionValueChanged = ({ name, value, id }): Action => {
    switch (name) {
    case 'context':
        return { type: ACTION_NAME.ONCHANGE_CONTEXT, payload: { value, id } };
    case 'questionnaire':
        return { type: ACTION_NAME.ONCHANGE_QUESTIONNAIRE, payload: { value, id } };
    case 'measure':
        return { type: ACTION_NAME.ONCHANGE_MEASURE, payload: { value, id } };
    default:
        console.error('[valueChanged] Error: unknown name of changed field', { name, value, id });
    }
};
