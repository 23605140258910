import React, { Fragment } from 'react';

import EmailPreview from "components/user/email/create/preview";

type EmailReviewProps = {
    title: string;
    subject: string;
    htmlContent: string;
}

const AutoCommunicationEmailReview = ({title, subject, htmlContent}: EmailReviewProps) => {
    return (
        <Fragment>
            <h4>{title}</h4>
            <EmailPreview subject={subject} htmlContent={htmlContent}/>
        </Fragment>
    );
};

export default AutoCommunicationEmailReview;
