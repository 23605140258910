import React from 'react';
import _ from 'services/i18n';
import Modal from 'common/ui/modal';
import { AntenatalWorklistItem } from 'features/antenatal/antenatal-worklist/AntenatalWorklist';

export function TriageDetailsModal(props: {
    onClose: () => void;
    worklistItem: AntenatalWorklistItem;
}) {
    return (
        <Modal
            title={_`Details`}
            onClose={props.onClose}
        >
            {
                props.worklistItem.interpreterDetails && (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <strong>{_`Interpreter details`}</strong>
                        <div style={{paddingLeft: '10px'}}>{props.worklistItem.interpreterDetails}</div>
                    </div>
                )
            }
            {
                props.worklistItem.triageDetails && (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <strong>{_`Details`}</strong>
                        <div style={{paddingLeft: '10px'}}>{props.worklistItem.triageDetails}</div>
                    </div>
                )
            }
        </Modal>
    );
}
