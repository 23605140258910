/* eslint-disable indent */
import React, { ChangeEvent, FC } from 'react';
// components
import {
    AdvancedSearchCriterionAbsoluteDateInput,
    AdvancedSearchCriterionListInputSingleSelection,
    AdvancedSearchCriterionNumberInput,
    AdvancedSearchInputUnavailable,
    AdvancedSearchCriterionRelativeDateInput,
} from 'features/patients/advanced-search/AdvancedSearchCriterionInputs';
// hooks
import {
    RelativeDateUnit,
    useCriterionRangeValue,
    useDateCriterionRelative
} from 'features/patients/advanced-search/AdvancedSearchSetupHooks';
// interfaces
import { AdvancedSearchCriterionRangeProps } from './AdvancedSearchCriterionRange.interface';
import { useValidator } from 'common/ui/validation/NestedComponentValidator';
import moment from "moment";

export const AdvancedSearchCriterionRange: FC<AdvancedSearchCriterionRangeProps> =
(props) => {

    const type = props.config.type;
    if (type === 'date' || type === 'datetime') {
        return AdvancedSearchCriterionDateRange({ ...props });
    }

    return AdvancedSearchCriterionValueRange({ ...props })
};

const AdvancedSearchCriterionValueRange: FC<AdvancedSearchCriterionRangeProps> = (props) => {
    const [from, to, setFrom, setTo] = useCriterionRangeValue(
        props.criterionValue,
    );

    const elementForType = (value, setValue) => {
        const onInput = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
        const type = props.config.type;
        switch (type) {
            case 'integer':
                return (
                    <AdvancedSearchCriterionNumberInput
                        value={value}
                        onChange={onInput}
                        step={1}
                    />
                );
            case 'float':
                return (
                    <AdvancedSearchCriterionNumberInput
                        value={value}
                        onChange={onInput}
                    />
                );
            case 'options':
                return (
                    <AdvancedSearchCriterionListInputSingleSelection
                        config={props.config}
                        value={value}
                        onChange={setValue}
                    />
                );
            default:
                console.error(
                    `Search criteria type [${type}] doesn't support a range input.`,
                );
                return <AdvancedSearchInputUnavailable/>;
        }
    };

    return (
        <div className={'advanced-search-query-request-criterion__value'}>
            <label>From (incl.)</label>
            {elementForType(from, setFrom)}
            <label>to (excl.)</label>
            {elementForType(to, setTo)}
        </div>
    );
}

const parseDateToTimestamp = (relative: boolean, dateStr: string) => {
    if (!relative) {
        return Date.parse(dateStr);
    }
    const relativeComponents = dateStr.split(':');
    const time = parseInt(relativeComponents[1]);
    const unit = relativeComponents[2] as RelativeDateUnit;
    return moment().startOf('day').add(time, unit).toDate().getTime();
}

const AdvancedSearchCriterionDateRange: FC<AdvancedSearchCriterionRangeProps> = (props) => {
    const [from, to, setFrom, setTo, reset] = useCriterionRangeValue(
        props.criterionValue,
    );

    const [useRelativeDate, setUseRelativeDate] = useDateCriterionRelative(
        from,
        reset
    );

    useValidator(props.validator, () => {
        const dateFrom = parseDateToTimestamp(useRelativeDate, from);
        const dateTo = parseDateToTimestamp(useRelativeDate, to);
        const isValidDateRange = dateFrom < dateTo;

        props.setError( isValidDateRange ? null : 'The "from" date must be before the "to" date.');
        return isValidDateRange;
    });

    const inputElement = (value, setValue) => {
        return useRelativeDate ? (
            <AdvancedSearchCriterionRelativeDateInput
                value={value}
                onChange={setValue}
            />
        ) : (
            <AdvancedSearchCriterionAbsoluteDateInput
                value={value}
                onChange={setValue}
            />
        );
    };

    return (
        <>
            <div className={'advanced-search-query-request-criterion__value form-horizontal'}>
                <label>Relative</label>
                <input
                    type="checkbox"
                    className={'checkbox'}
                    checked={useRelativeDate}
                    onChange={() => setUseRelativeDate(!useRelativeDate)}
                />
            </div>

            <div className={'advanced-search-query-request-criterion'} style={{
                gridRow: 2,
                gridColumn: 3
            }}>
                <label>From (incl.)</label>
                {inputElement(from, setFrom)}
            </div>

            <div className={'advanced-search-query-request-criterion'} style={{
                gridRow: 3,
                gridColumn: 3
            }}>
                <label>to (excl.)</label>
                {inputElement(to, setTo)}
            </div>
        </>
    );
};