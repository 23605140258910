import lodash from 'lodash';
import { convertToDate } from 'common/datetime/convertToDate';


export function checkErrors ({ questionnaireTemplateId, useDueBy, dueBy, expirationDate }) {
    const errors = [];
    const today = convertToDate(new Date()).startOf('day');

    if (questionnaireTemplateId === '') {
        errors.push('A questionnaire template needs to be selected');
    }

    if (expirationDate && expirationDate.isBefore(today)){
        errors.push('Expiration Date couldn\'t be in the past');
    }

    // check the date is not in the past
    if (true === useDueBy && dueBy < today) {
        errors.push('Due by date must not be before today');
    }

    return errors;
}

/**
 *
 * @param questionnaireTemplateList
 * @param questionnaireTemplateId
 * @returns {T}
 */
export function getQuestionnaire ({ questionnaireTemplateList, questionnaireTemplateId }) {

    const questionnaire = lodash.find(questionnaireTemplateList,(({ type,document }) => {
        if (type === 'composition'){
            return document.uuid == questionnaireTemplateId;
        }else{
            return document.id == questionnaireTemplateId;
        }
    }));

    if (questionnaire.type === 'template'){
        questionnaire.document.details = JSON.parse(questionnaire.document.details);
    }

    return questionnaire;
}
