import React, { useState, Fragment } from 'react';
import { CreatePatientModalDialog } from './CreatePatientModalDialog';
import { useTeamPreferences } from 'common/useTeamPreferences';

export const CreatePatientButton = () => {
    const [isShowDialog, setShowDialog] = useState(false);
    const teamPreferences = useTeamPreferences();

    const handleClick = (e) => {
        setShowDialog(!isShowDialog);
        e.preventDefault();
    };

    if (!teamPreferences.show_create_patient) {
        return null;
    }

    return <Fragment>
        <a onClick={handleClick}>Create new patient</a>
        {isShowDialog && <CreatePatientModalDialog
            onClose={() => setShowDialog(false)}
        />}
    </Fragment>;
};
