/* eslint-disable @typescript-eslint/camelcase */

import * as TER from 'models/TriggerEngineRequestContent';

export type SendSimpleQuestionnaireState = {
    location: string;
    id: string;
    lozengeTitle: string;
    lozengeContent: string;
}

export function validate(state: SendSimpleQuestionnaireState, onErrors: (errors: string[]) => void): boolean {
    const normalisedState = normalise(state);
    const errors = [];

    if (normalisedState.id === '') {errors.push('Simple questionnaire is required');}

    if (normalisedState.lozengeTitle === '') {errors.push('Lozenge title is required');}

    if (normalisedState.lozengeContent === '') {errors.push('Lozenge content is required');}

    onErrors(errors);

    return errors.length === 0;
}

export function normalise(state: SendSimpleQuestionnaireState): SendSimpleQuestionnaireState {
    return {
        location: state.location,
        id: state.id.trim(),
        lozengeTitle: state.lozengeTitle.trim(),
        lozengeContent: state.lozengeContent.trim()
    };
}

export function getTriggerEngineContent(state: SendSimpleQuestionnaireState): TER.SendSimpleQuestionnaireContent {
    return {
        location: state.location,
        id: state.id,
        lozenge_title: state.lozengeTitle,
        lozenge_content: state.lozengeContent,
        //Just cloning directly for now. Just need something set properly to pass schema validation.
        popup_title: state.lozengeTitle,
        popup_content: state.lozengeContent
    };
}
