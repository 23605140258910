import React from 'react';
import lodash from 'lodash';
import { Link } from 'react-router';

import BasePage from 'components/page/base';
import { referralsService } from 'services/referrals.service';
import { appointmentsService } from 'services/appointments.service';
import { lettersService } from 'services/letters.service';
import { demographicsService } from 'services/demographics.service';
import Modal from 'ui/modal';
import { teamPreferencesService } from 'services/team-preferences.service';
import { ReferralsIndex } from 'features/patient/referrals/ReferralsIndex';
import { folderService } from 'services/folder.service';
import TriageAndInviteAction from "pages/clinical_portal/folder/_folderId/patient/referrals/TriageAndInviteAction";

export default class ReferralsListPage extends BasePage
{
    constructor (props) {
        super(props);

        this.state = {
            // An array containing all the referral compositions in the patient's folder.
            // These will be sorted with most recent first.
            referrals: [],
            
            // Maps each referral composition UUID to its associated appointment composition.
            // There should eventually be an entry in here for every referral in the referrals property.
            // Until it's loaded, the property will be set to undefined.
            // Each key is a string containing a referral composition UUID.
            // Each value is an object containing an appointment composition.
            // Where there are multiple appointments associated with a referral, this will be the most
            //  recently created on which is not deleted or cancelled.
            appointments: undefined,
            
            // A map of appointment UBRNs to their letter sending status.
            // Each value is a boolean, where true means a letter has been or is being sent.
            // False means a letter has not been sent, or sending failed.
            // If an entry is missing entirely, it means a letter has not been sent.
            // Until it's loaded, the property will be set to undefined.
            appointmentLetterStatuses: undefined,
            
            // Indicates if the page content is currently loading.
            loading: true,
            
            // Indicates if an error occurred during loading.
            loadingError: false,
            categoryGroups:[],
            folderDetails: {},
            confirmModal: {
                show: false,
                title: '',
                body: '',
                buttons: []
            },
            folderId: this.props.folderId ? this.props.folderId : ''
        };
        
        
        this.getTriageDecisionForList = this.getTriageDecisionForList.bind(this);
        this.getSendLetterButton = this.getSendLetterButton.bind(this);
        
        this.onDischarge = this.onDischarge.bind(this);
        this.onRestore = this.onRestore.bind(this);
    }

    
    /**
     * Fetch the title of this page, to be displayed in the browser tab/title bar.
     *
     * @return {string} Title of the page.
     */
    pageTitle () {
        return 'Referrals | MyPathway Clinical Portal';
    }
    
    loadTeamPreferences(){
        return teamPreferencesService.getFirst({})
            .then(({ referrals_list_category_groups:categoryGroups }) => {
                return this.$setState({ categoryGroups });
            });
    }
    
    loadFolderDetails() {
        if (this.state && this.state.folderId) {
            folderService.getFolderById({ folderId: this.state.folderId }).then((folder) => {
                this.setState({
                    folderDetails: folder
                });
            })
                .catch((e) => {
                    console.error('Error during loading folderDetails data', e);
                });
        }
    }
    
    /**
     * React hook: The component has mounted.
     * This triggers loading of the referral compositions.
     * It's done after mounting to reduce any delay before displaying something.
     */
    componentDidMount () {
        return this.$setState({
            referrals: [],
            appointments: undefined,
            appointmentLetterStatuses: undefined,
            loading: true,
            loadingError: false
        })
            .then(() => {
                return this.loadFolderDetails();
            })
            .then(() => {
                return this.loadTeamPreferences();
            })
            .then(() => {
                return this.loadReferrals();
            })
            .then(() => {
                // We'll finish the main loading here so that the page can be displayed.
                // We still have some more stuff to load, but it's not essential.
                return this.$setState({ loading: false });
            })
            .catch((err) => {
                console.warn(err);
                return this.$setState({
                    loading: false,
                    loadingError: true
                });
            })
            .then(() => {
                return this.loadAppointments();
            })
            .then(() => {
                return this.loadLetters();
            })
            .catch((err) => {
                console.warn(err);
            });
    }

    /**
     * Load the patient's referral compositions and store them in state.
     * This does not modify the loading or loadingError flags.
     *
     * @return {Promise} Resolves when the compositions have been loaded, or rejects if an error occurred.
     */
    loadReferrals () {
        return referralsService.list({folderId: this.state.folderId})
            .then((response) => {

                if (!lodash.has(response, 'message.results')) {
                    throw new Error('Server response did not have expected structure.');
                }

                // Sort the results with the most recent first.
                const results = lodash.cloneDeep(response.message.results);
                results.sort((a, b) => {
                    return lodash.get(b, 'created_at', '').localeCompare(lodash.get(a, 'created_at', ''));
                });

                return this.$setState({
                    referrals: results
                });
            });
    }

    /**
     * Load the patient's appointment compositions and store them in the map in state.
     * This only stores the most recently created appointment for each referral which
     *  is not cancelled or deleted.
     * This does not modify the loading or loadingError flags.
     * This must be called after the referrals have been loaded.
     *
     * @return {Promise} Resolves when the compositions have been loaded, or rejects if an error occurred.
     */
    loadAppointments () {
        // We currently just load all the appointments, which isn't very efficient.
        // It should be acceptable for now though.
        return appointmentsService.list({folderId: this.state.folderId})
            .then((response) => {

                if (!lodash.has(response, 'message.results')) {
                    throw new Error('Server response did not have expected structure.');
                }

                // Sort the results with the most recently created first.
                const results = lodash.cloneDeep(response.message.results);
                results.sort((a, b) => {
                    return lodash.get(b, 'created_at', '').localeCompare(lodash.get(a, 'created_at', ''));
                });

                // Go through each appointment.
                const appointments = new Map();
                results.forEach((appointment) => {
                    // Ignore anything which is cancelled.
                    if (lodash.get(appointment, 'content.status') === 'cancelled') {
                        return;
                    }

                    // Ignore anything which doesn't correspond to a known referral.
                    const appointmentReferralId = lodash.get(appointment, 'content.referral_id');
                    if (!appointmentReferralId) {
                        return;
                    }
                    const referral = this.state.referrals.find((referral) => {
                        // Note that each referral can have multiple referral IDs.
                        const referral_ids = lodash.get(referral, 'content.referral_ids');
                        if (!referral_ids) {
                            return false;
                        }

                        return (referral_ids.indexOf(appointmentReferralId) >= 0);
                    });
                    if (!referral || !referral.uuid) {
                        return;
                    }


                    // Only store this appointment if we have NOT already seen one for the same referral.
                    if (!appointments.has(referral.uuid)) {
                        appointments.set(referral.uuid, appointment);
                    }
                });

                return this.$setState({ appointments });
            });
    }

    /**
     * Load the patient's letter compositions and store a summary in state.
     * This only stores information about the status of each letter which relates
     *  to one of the referral appointments.
     * This does not modify the loading or loadingError flags.
     * This should be called after the appointments have finished loading.
     *
     * @return {Promise} Resolves when the compositions have been loaded, or rejects if an error occurred.
     */
    loadLetters () {
        // We currently just load all the letters, which isn't very efficient.
        // It should be acceptable for now though.
        return lettersService.list({})
            .then((response) => {

                if (!lodash.has(response, 'message.results')) {
                    throw new Error('Server response did not have expected structure.');
                }

                const results = lodash.cloneDeep(response.message.results);

                // Go through each letter.
                const promises = [];
                const appointmentLetterStatuses = new Map();
                results.forEach((letter) => {
                    // Find the UBRN entry from the letter template properties, if it is there.
                    // Note that the template properties are a flat array; i.e. [key1, value1, key2, value2, ...].
                    const templateProperties = lodash.get(letter, 'content.template_properties');
                    if (!templateProperties) {
                        console.warn('Skipping letter which does not have template properties.');
                        return;
                    }

                    const ubrnKeyIndex = templateProperties.indexOf('UBRN');
                    if (ubrnKeyIndex < 0) {
                        console.warn('Skipping letter which does not have a UBRN template property.');
                        return;
                    }
                    const ubrnValueIndex = ubrnKeyIndex + 1;
                    if (ubrnValueIndex >= templateProperties.length) {
                        console.warn('UBRN template property would be past the end of the template properties array.');
                        return;
                    }

                    const appointmentUbrn = templateProperties[ubrnValueIndex];
                    if (!appointmentUbrn) {
                        console.warn('Skipping letter with empty UBRN property.');
                        return;
                    }

                    // Ignore letters which are not sent or queued to be sent.
                    const letterStatus = lodash.get(letter, 'content.status');
                    if (letterStatus !== 'sent' && letterStatus !== 'pending') {
                        return;
                    }

                    appointmentLetterStatuses.set(appointmentUbrn, true);
                });

                return this.$setState({ appointmentLetterStatuses });
            });
    }

    /**
     * Construct a URL for entering a triage decision for the given referral.
     *
     * @param {object} referral The referral composition which the triage decision will be entered for.
     * @return {string} The URL for entering the triage decision, or an empty string if the required data was missing from the referral composition.
     */
    makeLinkToEnterTriageDecision (referral) {
        const uuid = lodash.get(referral, 'uuid', '');
        if (!uuid) {
            console.warn('Cannot identify referral UUID from composition.');
            return '';
        }
        return `/clinical_portal/folder/${this.state.folderId}/patient/referrals/${uuid}/enter-triage-decision`;
    }

    /**
     * Update an appointment letter status to the given value.
     * Set status to an empty string to remove the entry from the appointment letter statuses.
     *
     * @param {string} appointmentUbrn The UBRN of the appointment whose status will be updated.
     * @param {boolean} status The status value to store. True means the letter has been sent or is being sent. False means the letter has not been sent, or it failed.
     * @return {Promise} Resolves when the state has been updated. Rejects if a parameter was invalid.
     */
    setAppointmentLetterStatus (appointmentUbrn, status) {
        if (!appointmentUbrn) {
            console.warn('Empty appointment UBRN is not allowed.');
            return Promise.reject();
        }

        if (status !== true && status !== false) {
            console.warn('Invalid status. Expected boolean true or false.');
            return Promise.reject();
        }

        return this.$setState((prevState) => {
            // Do nothing if not state change is required.
            // An empty string is equivalent to having no value.
            if (prevState.appointmentLetterStatuses && prevState.appointmentLetterStatuses.get(appointmentUbrn) === status) {
                return {};
            }

            // We must not mutate the previous state.
            // Operate on a clone or create a new map if one does not already exist.
            const appointmentLetterStatuses = lodash.cloneDeep(prevState.appointmentLetterStatuses || new Map());

            // Update the state.
            appointmentLetterStatuses.set(appointmentUbrn, status);
            return { appointmentLetterStatuses };
        });
    }

    onDischarge (referral) {
        this.setState({
            confirmModal: Object.assign(this.state.confirmModal, {
                show: true,
                title: 'Are you sure you want to discharge this patient?',
                body: 'Loading patient details',
                buttons: [
                    { type: 'callback', callback: () => this.handleDischarge(referral), label: 'Discharge', style: 'success' },
                    { type: 'callback', callback: () => this.onCloseConfirmModal(), label: 'Cancel' }
                ]
            })
        }, () => {
            demographicsService.getFirst({ folderId: this.state.folderId })
                .then((demographics) => {
                    const patientName = demographics.content.name.family_name + ' ' + demographics.content.name.given_name;
                    this.setState({
                        confirmModal: Object.assign(this.state.confirmModal, {
                            body: patientName
                        })
                    });
                });
        });
    }

    handleDischarge (referral) {
        const status = 'discharged';

        return referralsService.changeStatus({ uuid: referral.uuid, folderId: this.state.folderId, status })
            .then(() => {
                referral.content.status = status;
                this.onCloseConfirmModal();
            });
    }

    onRestore (referral) {
        this.setState({
            confirmModal: Object.assign(this.state.confirmModal, {
                show: true,
                title: 'Are you sure you want to restore this patient?',
                body: 'Loading patient details',
                buttons: [
                    { type: 'callback', callback: () => this.handleRestore(referral), label: 'Restore', style: 'success' },
                    { type: 'callback', callback: () => this.onCloseConfirmModal(), label: 'Cancel' }
                ]
            })
        }, () => {
            demographicsService.getFirst({})
                .then((demographics) => {
                    const patientName = demographics.content.name.family_name + ' ' + demographics.content.name.given_name;
                    this.setState({
                        confirmModal: Object.assign(this.state.confirmModal, {
                            body: patientName
                        })
                    });
                });
        });
    }

    handleRestore (referral) {
        const status = 'open';
        return referralsService.changeStatus({ uuid: referral.uuid, status })
            .then(() => {
                referral.content.status = status;
                this.onCloseConfirmModal();
            });
    }

    onCloseConfirmModal () {
        this.setState({
            confirmModal: {
                show: false
            }
        });
    }

    getTriageDecisionForList(referral) {
        const { triage_decision: triageDecision, status } = referral.content;

        if (triageDecision) {
            return {
                triageDecisionCell: triageDecision,
                triageDecisionSortFilter: triageDecision,
            };
        }

        if (status === 'rejected') {
            return {
                triageDecisionCell: '-',
                triageDecisionSortFilter: '-',
            };
        }

        const triageDecisionUrl = this.makeLinkToEnterTriageDecision(referral);
        if (triageDecisionUrl) {
            return {
                triageDecisionCell: <Link to={triageDecisionUrl}>Enter Triage Decision</Link>,
                triageDecisionSortFilter: 'Enter Triage Decision',
            };
        }
    }

    getSendLetterButton(referral, appointments, appointmentLetterStatuses) {
        const isPatientRegistered = lodash.get(this, 'state.folderDetails.status') === 'registered';
        
        let appointment, appointmentUbrn, appointmentLetterStatus;
        if (appointments) {
            appointment = appointments.get(lodash.get(referral, 'uuid', ''));
            if (appointment) {
                appointmentUbrn = lodash.get(appointment, 'content.ubrn');
                if (appointmentLetterStatuses) {
                    appointmentLetterStatus = appointmentLetterStatuses.get(lodash.get(appointment, 'content.ubrn', ''));
                }
            }
        }

        const rawStatus = lodash.trim(lodash.get(referral, 'content.status', ''));

        let message = undefined;

        if (isPatientRegistered) {
            message = 'Patient has already registered.';
        } else if (['rejected','cancelled'].indexOf(rawStatus) !== -1){
            message = 'Not Applicable.';
        } else if (!lodash.has(referral, 'content.triage_decision')) {
            message = 'Please enter the triage decision.';
        } else if (appointments === undefined || appointmentLetterStatuses === undefined) {
            message = 'Loading...';

        } else if (appointment === undefined) {
            message = 'No appointment found.';

        } else if (appointmentLetterStatus === true) {
            message = 'Letter sent or sending.';

        } else if (lodash.get(appointment, 'content.status') !== 'available-to-book') {
            message = 'Appointment already booked.';
        } else if (!appointmentUbrn) {
            message = 'Error. Appointment doesn\'t have UBRN and available-to-book.';
        }

        if (message) {
            return (<span>{message}</span>);
        }

        return (
            null
        );
    }

    getDischargeButton(referral) {
        const { status } = referral.content;

        switch (status) {
        case 'open':
            return (
                <button className='btn btn-xs btn-danger' onClick={() => this.onDischarge(referral)}>Discharge</button>
            );
        case 'closed':
            return (
                <button className='btn btn-xs btn-default' onClick={() => this.onRestore(referral)}>Restore</button>
            );
        default:
            return null;
        }
    }

    /**
     * Render the contents of this React component.
     */
    render () {
        const { appointments, appointmentLetterStatuses } = this.state;

        return (
            <>
                <div className="top-bar">
                    <TriageAndInviteAction folderId={this.state.folderId} teamId={this.props.teamId}/>
                </div>
                <div className='page-referrals-list'>
                    {this.state.confirmModal.show ? (
                        <Modal title={this.state.confirmModal.title} type="info" onClose={() => this.onCloseConfirmModal()} buttons={this.state.confirmModal.buttons}>
                                {this.state.confirmModal.error ? <div className="alert alert-danger"><span>{this.state.confirmModal.error}</span></div> : null}
                                {this.state.confirmModal.body}
                            </Modal>
                        ): null}

                    <ReferralsIndex
                        referrals={this.state.referrals}
                        loading={this.state.loading}
                        getTriageDecisionForList={this.getTriageDecisionForList}
                        getSendLetterButton={(referral) => this.getSendLetterButton(referral, appointments, appointmentLetterStatuses)}
                        getDischargeButton={(referral) => this.getDischargeButton(referral)}
                    ></ReferralsIndex>
                </div>
            </>
        );
    }
}
