import { Link } from 'react-router';
import _ from 'services/i18n';
import { ComponentLoadingWrapper } from 'features/patient/patient-dashboard/component-loading-wrapper/ComponentLoadingWrapper';
import { QuestionnaireList } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/dashboard-questionnaire-list/DashboardQuestionnaireList';
import React, { FC } from 'react';
import classnames from 'classnames';
import { RoleType } from 'features/patient/patient-dashboard/RoleType';
import { GridItem } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-table/dashboard-questionnaire-row/DashboardQuestionnaireRow';
import './DashboardQuestionnaireTable.scss';

interface DashboardQuestionnaireTableViewProps {
    title: string;
    data: GridItem[];
    loading: boolean;
    role?: RoleType;
}
export const DashboardQuestionnaireTableView: FC<DashboardQuestionnaireTableViewProps> = ({ role, title, data, loading }) => {
    return (
        <div className={classnames(['dashboard-questionnaire-table', `widget-for--${role.toLowerCase()}`])}>
            <header>
                <h3>{title}</h3>
                <button className={'btn btn-sm btn-default'}>
                    <Link to={'/clinical_portal/my-team/worklists/mnd'}>{_`Go to worklist`}</Link>
                </button>
            </header>
            <ComponentLoadingWrapper loading={loading} dataExists={Boolean(data?.length)}>
                <QuestionnaireList items={data}/>
            </ComponentLoadingWrapper>
        </div>
    );
};
DashboardQuestionnaireTableView.defaultProps = {
    role: RoleType.Patient
};
