import { browserHistory } from 'react-router';

export default class {
    ConfirmDashboard () {
        if (this.state.hasChanges) {
            this.setState({
                showConfirmModal: {
                    text: "You have unsaved changes. Are you sure you wish to go to the dashboard?",
                    onConfirm: this.events.onGoDashboard
                }
            });
        } else {
            this.events.onGoDashboard();
        }
    }

    CloseConfirm () {
        this.setState({
            showConfirmModal: false
        });
    }

    GoDashboard () {
        browserHistory.push('/coopweb');
    }

    SaveForLater () {
        let newResponse = JSON.parse(JSON.stringify(this.state.response.content));

        if (newResponse.details.hasOwnProperty('lastUpdated')) {
            delete(newResponse.details.lastUpdated);
        }
        newResponse.status = 'in_progress';

        this.$update('coopQuestionnaireResponse', this.state.response.uuid, newResponse, this.state.response.folder_id)
            .then(this.events.onGoDashboard);
    }

    Submit () {
        let questions = new Map(), valid = false;

        this.state.questionnaire.content.details.questions.map((item) => {
            questions.set(item.id, item);
        });

        // loop through response, see if any questions are left unanswered
        valid = this.state.response.content.details.answers.every((item) => {
            let defaultValue = questions.get(item.question_id).default;

            if (item.value == defaultValue) {
                return false;
            }
            return true;
        });

        if (!valid) {
            this.setState({
                showConfirmModal: {
                    text: "Some questions are unanswered. Are you sure you wish to submit?",
                    onConfirm: this.events.onGoSubmit
                }
            });
        } else {
            this.events.onGoSubmit();
        }
    }

    GoSubmit () {
        let newResponse = JSON.parse(JSON.stringify(this.state.response.content));
        if (newResponse.details.hasOwnProperty('lastUpdated')) {
            delete(newResponse.details.lastUpdated);
        }
        newResponse.status = 'complete';

        this.$update('coopQuestionnaireResponse', this.state.response.uuid, newResponse, this.state.response.folder_id)
            .then(this.events.onGoDashboard);
    }

    SubmitOrSave () {
        this.setState({
            showSubmitScreen: true
        });
    }

    CloseSubmitOrSave () {
        this.setState({
            showSubmitScreen: false
        });
    }

    SetAnswer (questionId, e) {
        let newAnswer = e.target.value,
            newResponse = JSON.parse(JSON.stringify(this.state.response)),
            currentResponse = newResponse.content.details.answers.find((item) => {
                if (item.question_id == questionId) {
                    return true;
                }
                return false;
            });


        if (currentResponse.value == newAnswer) {
            // no change so skip
            return;
        }
        currentResponse.value = newAnswer;

        this.setState({
            response: newResponse,
            hasChanges: true
        });
    }

    NextQuestion () {
        browserHistory.push('/coopweb/questionnaire/' + this.state.uuid + '/' + (this.state.question_id + 1));
    }

    LastQuestion () {
        if (this.state.question_id == 1) {
            browserHistory.push('/coopweb/questionnaire/' + this.state.uuid);
        } else {
            browserHistory.push('/coopweb/questionnaire/' + this.state.uuid + '/' + (this.state.question_id - 1));
        }
    }
}
