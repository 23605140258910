import { USER_LETTER_STATUS } from './compositions/UserLetter';
import { PUSH_NOTIFICATION_STATUS } from './compositions/PushNotification';
import { LETTER_PURPOSE, LetterExternalIdObject } from 'models/compositions/Letter';

export enum COMMUNICATION_TYPE {
    EMAIL = 'email',
    LETTER = 'letter',
    MESH_LETTER = 'mesh letter',
    SMS = 'sms',
    PUSH = 'push',
    HTML_LETTER = 'html letter',
}

type UserCommunicationCommonFields = {
    status: string; // for now
    date: Date;
    subject: string;
    uuid: string;
    to: string;
}

export type UserCommunicationEmail = UserCommunicationCommonFields & {
    type: COMMUNICATION_TYPE.EMAIL;
    from: string;
    content: string;
}

export type UserCommunicationMeshLetter = UserCommunicationCommonFields & {
    type: COMMUNICATION_TYPE.MESH_LETTER;
    status: USER_LETTER_STATUS;
    xml: string;
    html: string;
    pdfMediaResourceId: string;
    xmlMediaResourceId: string;
}

export type UserCommunicationPushNotification = UserCommunicationCommonFields & {
    type: COMMUNICATION_TYPE.PUSH;
    error: string;
    status: PUSH_NOTIFICATION_STATUS;
    highPriority: boolean;
}

export interface UserCommunicationSMS {
    type: COMMUNICATION_TYPE.SMS;
    content: string;
    status: string;
    subject: string;
    uuid: string;
    to: string;
    date: Date;
}

export type UserCommunicationLetter = UserCommunicationCommonFields & {
    type: COMMUNICATION_TYPE.LETTER;
    purpose: LETTER_PURPOSE;
    name: string;
    templateProperties: string[];
    externalIds: LetterExternalIdObject[];
};

export type UserCommunicationHtmlLetter = UserCommunicationCommonFields & {
    type: COMMUNICATION_TYPE.HTML_LETTER;
    pages: string[];
    status: string;
    mediaResourceUuid: string;
}

export type UserCommunication = UserCommunicationEmail | UserCommunicationMeshLetter | UserCommunicationPushNotification | UserCommunicationSMS | UserCommunicationLetter | UserCommunicationHtmlLetter;
