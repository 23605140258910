import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './bulkOperation.scss';
import { getBulkOperationData } from './bulkOperationDataFormat';
import { Row, Column, FormGroup } from 'ui';
import _ from 'services/i18n';
import { BulkOperationHistoryData } from './models/bulkOperationHistoryModels';
import { simpleQuestionnaireService } from 'features/content-editor/simpleQuestionnaireContentService';
import BulkOperationEmailReview from './BulkOperationEmailReview';
import BulkOperationLetterReview from './BulkOperationLetterReview';
import BulkOperationResourceReview from './BulkOperationResourceReview';
import { BulkOperationRequest } from 'models/BulkOperationRequests';
import BulkOperationAutoCommunicationReview
    from "pages/clinical_portal/bulk-operations/BulkOperationAutoCommunicationReview";
import BulkOperationPifuReview from "pages/clinical_portal/bulk-operations/BulkOperationPifuReview";

const BulkOperationReview = (props: { bulkOperation: BulkOperationRequest; bulkOperationDate: string }): JSX.Element => {
    const bulkOperationContent = props.bulkOperation.content;
    const bulkOperationAction = bulkOperationContent.actions[0];
    const [questionnaireName, setQuestionnaireName] = useState<string>('');
    const bulkOperationType: string = bulkOperationAction.action_type;
    const [bulkOperationData, setBulkOperationData] = useState<BulkOperationHistoryData>(getBulkOperationData(bulkOperationContent, props.bulkOperationDate));

    const getQuestionnaireData = useCallback((): void => {
        setBulkOperationData({
            ...bulkOperationData,
            name: questionnaireName
        });
    }, [bulkOperationData, questionnaireName]);
    
    useEffect(() => {
        const getSimpleQuestionnaire = async (): Promise<void> => {
            const _simpleQuestionnaire = bulkOperationAction.content;
            simpleQuestionnaireService.getContentById(_simpleQuestionnaire.id, _simpleQuestionnaire.location).then((res) => {
                setQuestionnaireName(res.message.live.name);
            });
        };
        if(bulkOperationType === 'assignSimpleQuestionnaire') {
            if(!questionnaireName) {
                getSimpleQuestionnaire();
            } else {
                getQuestionnaireData();
            }
        }
    }, [bulkOperationAction.content, bulkOperationType, getQuestionnaireData, questionnaireName]);

    const renderBulkOperationReviewPage = (): JSX.Element => {
        return (
            <Fragment>
                <form noValidate>
                    <Column md={12}>
                        <Row>
                            <FormGroup>
                                <label htmlFor='template'>{_`Template`}</label>
                                <input value={bulkOperationData.name} type='text' name='Template' readOnly className={_`form-control`} />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor='title'>{bulkOperationType === 'assignSimpleQuestionnaire' ? _`Lozenge title` : _`Message title`}</label>
                                <input type="text" name="title" className={'form-control'} value={bulkOperationData.title} readOnly />
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <label htmlFor='content'>{bulkOperationType === 'assignSimpleQuestionnaire' ? _`Lozenge content` : _`Message content`}</label>
                                <textarea rows={5} name='content' className='form-control' value={bulkOperationData.description} readOnly />
                            </FormGroup>
                        </Row>
                        {bulkOperationType === 'assignQuestionnaire' ? (
                            <Fragment>
                                <Row>
                                    <FormGroup>
                                        <label htmlFor='popupTitle'>{_`Pop up title`}</label>
                                        <input name='popupTitle' className='form-control' value={bulkOperationData.popupTitle} readOnly />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup>
                                        <label htmlFor='content'>{_`Pop up content`}</label>
                                        <textarea rows={5} name='content' className='form-control' value={bulkOperationData.popupContent} readOnly />
                                    </FormGroup>
                                </Row>
                            </Fragment>
                        ) : null}
                    </Column>
                    {renderPushNotificationCheckbox()}
                </form>
            </Fragment>
        );
    };

    const renderPushNotificationCheckbox = (): JSX.Element => {
        if (bulkOperationType === 'sendSms') {
            return;
        }
        return (
            bulkOperationData.pushNotification ? 
                <label><input type='checkbox' disabled checked />Notify patient by push notification</label> :
                <label><input type='checkbox' disabled />Notify patient by push notification</label>
        );
    };

    const renderBulkOperationReview = (): JSX.Element => {
        switch (bulkOperationType) {
            case 'sendMessage':
                return (
                    renderBulkOperationReviewPage()
                );
            case 'assignSimpleQuestionnaire':
                return (
                    questionnaireName && renderBulkOperationReviewPage()
                );
            case 'sendSms':
                return (
                    renderBulkOperationReviewPage()
                );
            case 'sendEmail':
                return (
                    <BulkOperationEmailReview bulkOperationData={bulkOperationData}
                                              bulkOperationType={bulkOperationType}/>
                );
            case 'sendHtmlLetter':
                return (
                    <BulkOperationLetterReview bulkOperationData={bulkOperationData}/>
                );
            case 'allocateResourceWithMessage':
                return (
                    <BulkOperationResourceReview bulkOperationAction={bulkOperationAction}
                                                 bulkOperationData={bulkOperationData}
                                                 renderPushNotification={renderPushNotificationCheckbox}/>
                );
            case 'assignQuestionnaire':
                return (
                    renderBulkOperationReviewPage()
                );
            case 'pifu':
                return (
                    <BulkOperationPifuReview bulkOperationAction={bulkOperationAction}
                                             bulkOperationData={bulkOperationData}/>
                );
            case 'autoCommunication':
                return (
                    <BulkOperationAutoCommunicationReview bulkOperationAction={bulkOperationAction}
                                                          bulkOperationData={bulkOperationData}/>
                );
            case 'assignTeamResources':
                return (
                    renderBulkOperationReviewPage()
                );
        }
    };

    return (
        <div>
            {renderBulkOperationReview()}
        </div>
    );
};

export default BulkOperationReview;
