import React from 'react';
import createQuestionnaireTemplateComponent from './container';
import ViewQuestionnaireTemplate from './view';
import ViewQuestionnaireComposition from './view-composition';

export const ViewQuestionnaireTemplateComponent = createQuestionnaireTemplateComponent(ViewQuestionnaireTemplate,{
    archetype:'template'
});
export const ViewQuestionnaireCompositionComponent = createQuestionnaireTemplateComponent(ViewQuestionnaireComposition,{
    archetype:'composition'
});