import React, { FC, useEffect, useState } from 'react';
import { NavTab, TabNavigator } from 'ui/tab/navigator';
import { Column, Row } from 'ui';
import { htmlToText } from 'html-to-text';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, Modifier, ContentState } from 'draft-js';
import { Wysiwyg, WysiwygUpdateFunc } from '../../../../portal-components/ui/wysiwyg';
import { EditorTags } from 'common/ui/editor-tags/EditorTags';

enum Tabs {
    text,
    preview
}

export interface WYSIWYGPreviewProps {
    value: string;
    type?: string;
    onChange: (value: unknown, field?: string) => void;
    triggerContentUpdate?: boolean;
    readOnly: boolean;
}

export const WYSIWYGPreview: FC<WYSIWYGPreviewProps>  = ({
    value,
    type,
    onChange,
    triggerContentUpdate,
    readOnly = false
}) => {
    const [tab, setTab] = useState(1);
    const [text, setText] = useState(value);
    const [editorValue, setEditorValue] = useState(null);

    useEffect(() => {
        changeTextValue();
    }, [value]);

    const changeTab = (data) => {
        setTab(data);
    };

    const changeTextValue = () => {
        const convertedText = htmlToText(value, {
            wordWrap: 130,
        });

        setText(convertedText);
        onChange(convertedText, 'txt_content');
    };

    const handleEditorOnChange = (value: WysiwygUpdateFunc) => {
        setEditorValue(value);
        if (value.target.value) {
            onChange(value.target.value);
        }
    };

    const updateTextArea = (tag, editorValue) => {
        if (editorValue && editorValue.target.textarea) {
            const start = editorValue.target.textarea.selectionStart;
            const value = editorValue.target.textarea.value;
            const newValue = `${value.substr(0, start)} ${tag} ${value.substr(start)}`;
            editorValue.target.setHtmlEditorContent(newValue);
            const contentBlock = htmlToDraft(newValue);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const newState = EditorState.createWithContent(contentState);
            editorValue.target.setEditorState(newState);
            onChange(newValue, 'txt_content');
        }
    };

    const updateVisualEditor = (tag, editorValue) => {
        const contentState = Modifier.replaceText(
            editorValue.target.editorState.getCurrentContent(),
            editorValue.target.editorState.getSelection(),
            tag,
            editorValue.target.editorState.getCurrentInlineStyle(),
        );
        const newState = EditorState.push(editorValue.target.editorState, contentState, 'insert-characters');
        editorValue.target.setEditorState(newState);
        const newValue = editorValue.target.getHTMLAndText(newState.getCurrentContent());
        onChange(newValue.value);
    };

    const insertTag = (tag) => {
        if (editorValue) {
            if (editorValue.target.updateTextArea) {
                updateTextArea(tag, editorValue);
            } else {
                updateVisualEditor(tag, editorValue);
            }
        }
    };

    return (
        <React.Fragment>
            {type === 'email' && (
                <Row>
                    <Column sm="12">
                        <TabNavigator
                            selectedItem={tab}
                            onClick={changeTab}
                            tabPosition='top'
                            tabStyle='tab'
                        >
                            <NavTab title={'Plain Text'}/>
                            <NavTab title={'Preview'}/>
                        </TabNavigator>

                    </Column>
                </Row>
            )}

            <div>
                {tab === Tabs.preview && (
                    <div>
                        {readOnly && <div dangerouslySetInnerHTML={{
                            __html: value
                        }}/>}
                        {!readOnly && (<>
                            <Row className="tags-toolbar">
                                <Column sm="12" md="8">
                                    <EditorTags callback={insertTag}/>
                                </Column>
                            </Row>
                            <Wysiwyg
                                triggerContentUpdate={triggerContentUpdate}
                                onChange={handleEditorOnChange}
                                value={value}
                            />
                        </>)}
                    </div>
                )}
                {tab === Tabs.text && <textarea disabled value={text} className="wysiwyg-preview form-control"/>}
            </div>
        </React.Fragment>  
    );
    
};
