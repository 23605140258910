import React, { Fragment } from 'react';
import { Row, Column } from 'ui';
import _ from 'services/i18n';
import mediaResourceService from 'services/media-resource/media-resource.service';
import { ExternalLink } from 'common/ui/ExternalLink';
import { BulkOperationReviewProps } from './models/bulkOperationHistoryModels';

const BulkOperationResourceReview = ({ bulkOperationData, bulkOperationAction, renderPushNotification }: BulkOperationReviewProps): JSX.Element => {

    const handleOpenResourceLink = (resource): void => {
        if (resource.type === 'media_resource') {
            mediaResourceService.getFileLink(resource.media_resource_uuid)
                .then((url) => {
                    window.open(url, '_blank');
                });
            return;
        }
        if(resource.type === 'phone') {
            window.open(`tel:${resource.phone}`, '_blank');
            return;
        }
        let href: string = resource.url || '';
        href = href ? /https?:\/\//.test(href) ? href : `http://${href}` : '';
        window.open(href);
    };


    return (
        <Fragment>
            <form noValidate>
                <Row>
                    <Column md={12}>
                        <label htmlFor='title'>Title</label>
                        <input
                            type="text"
                            name="title"
                            className={'form-control'}
                            value={bulkOperationData.title} readOnly />
                    </Column>
                </Row>
                <Row>
                    <Column md={12}>
                        <label htmlFor='message'>{_`Message`}</label>
                        <textarea
                            name="message"
                            className={'form-control'}
                            value={bulkOperationData.content} readOnly />
                    </Column>
                </Row>
                <Row>
                    <Column md={12}>
                        <label htmlFor='description'>{_`Description`}</label>
                        <textarea
                            name="description"
                            className={'form-control'}
                            value={bulkOperationData.description} readOnly />
                    </Column>
                </Row>
                <Row>
                    <Column md={12}>
                        <label htmlFor='type'>{_`Resource type`}</label>
                        <input
                            type="text"
                            name="type"
                            className={'form-control'}
                            value={bulkOperationData.type} readOnly />
                        <label htmlFor='resource'>{_`Resource`}</label><br/>
                        <ExternalLink onClick={() => handleOpenResourceLink(bulkOperationAction.content.resources[0])}>{bulkOperationData.url}</ExternalLink>
                    </Column>
                </Row>
                <br/>
                {renderPushNotification()}
            </form>
        </Fragment>
    );
};

export default BulkOperationResourceReview;
