import { compositionService } from './composition.service';
import { syncService } from './sync.service';
import DataStore from 'services/data-store';
import questionnaireService from './questionnaire.service';

export const getQuestionnaireTemplateCurrentVersion = async (uuid, params = {}) => {
    const role = DataStore.get('me.currentRole');

    const response = await compositionService.getByUuid({
        uuid,
        role,
        folderId: 'team',
        archetypeName: 'questionnaire',
    });
    const questionnaire = response.message;
    return questionnaire.content.version;
};

export const createOrUpdateQuestionnaireTemplate = async ({
    json,
    fileContent,
    name,
    isComposition,
    brandNewTemplate,
    parentId,
    team,
}) => {
    const role = DataStore.get('me.currentRole');
    if (!isComposition) {
        const content = {
            jsonString: fileContent,
            jsonObject: json,
            parent_id: !brandNewTemplate ? parentId : 0,
            name: brandNewTemplate === 1 ? name : ''
        };
        return compositionService.create({
            role,
            folderId: 'team',
            archetypeName: 'questionnaire-template',
            content
        })
    }

    if (brandNewTemplate) {
        return compositionService.create({role, folderId: 'team', archetypeName: 'questionnaire', content: json});
    } else {
        const version = await getQuestionnaireTemplateCurrentVersion(parentId);
        const content = {
            ...json,
            version: version + 1
        }
        return compositionService.update({uuid: parentId, role, folderId: 'team', archetypeName: 'questionnaire', content});
    }
};

export const getQuestionnaires = async (): Promise<any> => {
    const role = DataStore.get('me.currentRole');

    const templates = await syncService.fullSync({
        role,
        folderId: 'team',
        archetypeNames: 'questionnaire',
        limit: Infinity,
        offset: 0
    });

    return templates.flatMap((template) => {
        return {
            type: 'composition',
            document: template
        }
    });
}

export const getQuestionnaireTemplates = async (params = {}) => {
    const role = DataStore.get('me.currentRole');

    const templates = await Promise.all([
        syncService.fullSync({
            role,
            folderId: 'team',
            archetypeNames: 'questionnaire-template,questionnaire',
            limit: Infinity,
            offset: 0
        }),
        questionnaireService.getCoopQuestionnaires(0, 0, 'clinical_portal')
    ]);

    return templates.flat().reduce((result, questionnaire) => {
        if(questionnaire.document_type === 'questionnaire') {
            result.compositions.push({
                type: 'composition',
                document: questionnaire,
            });
        } else {
            result.templates.push({
                type: 'template',
                document: questionnaire
            });
        }
        return result;
    }, {templates: [], compositions: []});
};

export const getQuestionnaireComposition = async (templateId, params = {}) => {
    const role = DataStore.get('me.currentRole');
    const result = await compositionService.getByUuid({
        uuid: templateId,
        role,
        folderId: 'team',
        archetypeName: 'questionnaire',
    });

    const {
        content,
        created_at,
        created_user,
    } = result.message;

    return {
        name: content.name,
        version: content.version,
        created_at: created_at,
        created_user: created_user,
        pretty: JSON.stringify({
            created_at,
            content,
        }, null, 2),
    };
}

export const getQuestionnaireTemplate = async (templateId, params = {}) => {
    const role = DataStore.get('me.currentRole');
    const result = await compositionService.getByUuid({
        uuid: templateId,
        role,
        folderId: 'team',
        archetypeName: 'questionnaire-template',
    })

    const {
        name,
        version,
        created_at,
        created_user,
        pretty,
    } = result.message;

    return {
        name,
        version,
        created_at,
        created_user,
        pretty,
    };
}