import React from 'react';
import _ from 'services/i18n';

export default class extends React.Component {

    render () {
        let formGroupClass = 'form-group';

        let requiredIndicator;
        if (this.props.required && !this.props.readOnly && !this.props.disabled) {
            requiredIndicator = (
                <span className="text-danger">{' '}*</span>
            );
        }

        // Important: Don't show the validation result if no validation has been done.
        if ((this.props.showValidation || this.props.forceShowValidation) && this.props.isValidated) {
            formGroupClass += ' has-success';
        }

        return (
            <div className={formGroupClass}>
                <label className='control-label' htmlFor={this.props.id}>
                    {this.props.label}
                    {requiredIndicator}
                </label>

                <div className="form-group form-inline">
                    <div className="input-group">
                        <input type="number" min="0" max="1000" value={this.props.value.years} name="years" className="form-control"
                               onChange={this.props.onChange} />
                        <span className="input-group-addon">years</span>
                    </div>
                    <div className="input-group">
                        <input type="number" min="0" max="11" value={this.props.value.months} name="months" className="form-control"
                               onChange={this.props.onChange} />
                        <span className="input-group-addon">months</span>
                    </div>
                    <div className="input-group">
                        <input type="number" min="0" max="31" value={this.props.value.days}  name="days" className="form-control"
                               onChange={this.props.onChange} />
                        <span className="input-group-addon">days</span>
                    </div>
                    {this.props.showTime
                        ?<span style={{paddingLeft: "8px"}}>
                            <div className="input-group">
                                <input type="number" min="0" max="23" size="2" value={this.props.value.hours} name="hours" className="form-control"
                                       onChange={this.props.onChange} />
                                <span className="input-group-addon">:</span>
                                <input type="number" min="0" max="59"  size="2" value={this.props.value.minutes} name="minutes" className="form-control"
                                       onChange={this.props.onChange} />
                                 <span className="input-group-addon">Time</span>
                            </div>
                        </span>
                        :null}
                </div>

                <Errors {...this.props} />
                <HelpBlock {...this.props} />
            </div>
        );
    }
}

const Errors = ({isValidated, showValidation, forceShowValidation, errors}) => {
    // Don't show any errors if validation passed or hasn't been done,
    //  or if validation errors are being suppressed.
    if (!isValidated || errors.length == 0 || (!showValidation && !forceShowValidation)) {
        return (<noscript />);
    }

    let content;
    if (errors.length == 1) {
        content = (<span>{errors[0]}</span>);
    } else {
        let list = errors.map((error, idx) => {
            return (<li key={idx}>{error}</li>)
        });

        content = (<ul>{list}</ul>);
    }

    return (
        <div className='alert alert-danger'>
            {content}
        </div>
    );
};

const HelpBlock = ({helpText}) => {
    if (helpText == null || helpText == '') {
        return (<noscript />);
    }
    return (
        <p className='help-block'>
            {helpText}
        </p>
    );
};
