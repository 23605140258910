import React, { useState, useEffect } from 'react';
import { SendSimpleQuestionnaireState } from './validator';
import ErrorView from 'components/errorbox';
import { LozengeContentForm } from '../lozenge/create/form';
import _ from 'services/i18n';
import { simpleQuestionnaireService } from 'features/content-editor/simpleQuestionnaireContentService';
import { useSortedDepartmentFolderIdData } from 'features/department/hooks/useSortedDepartmentData';
import Select from 'react-select';
import { useGroupedDepartmentDataOptionList } from 'features/department/hooks/useGroupedDepartmentDataOptionList';
import { SimpleQuestionnaireItem } from 'models/compositions/SimpleQuestionnaire';
import { findDepartmentDataOptionInGroupedList } from 'features/department/department.utils';

export interface SimpleQuestionnaireCreateFormProps {
    onChange: (c) => unknown;
    form: SendSimpleQuestionnaireState;
    errors?: string[];
}

export const SimpleQuestionnaireCreateForm = ({
    onChange,
    form,
    errors,
}: SimpleQuestionnaireCreateFormProps) => {
    const [simpleQuestionnaires, setSimpleQuestionnaires] = useState<SimpleQuestionnaireItem[]>([]);

    useEffect(() => {
        getSimpleQuestionnaires();
    }, []);

    const contentByDepartment = useSortedDepartmentFolderIdData<SimpleQuestionnaireItem>(simpleQuestionnaires);
    const [searchQuestionnaireList, numOptions] = useGroupedDepartmentDataOptionList(contentByDepartment, d => d.name);
    const selectedQuestionnaireOption = findDepartmentDataOptionInGroupedList(searchQuestionnaireList, q => q.id === form.id);

    const getSimpleQuestionnaires = () => {
        const content = 'simple-questionnaire';

        simpleQuestionnaireService
            .getContentList(content)
            .then((res) => {
                return res.message.content;
            })
            .then((simpleQuestionnaireList: SimpleQuestionnaireItem[]) => {
                setSimpleQuestionnaires(simpleQuestionnaireList);
            });
    };

    const getLocationFromId = (id): string => {
        const simpleQuestionnaire = simpleQuestionnaires.find(
            (questionnaire) => questionnaire.id === id,
        );

        return simpleQuestionnaire.location;
    };

    const handleChange = (questionnaire: SimpleQuestionnaireItem | null): void => {
        if (questionnaire) {
            onChange({
                id: questionnaire.id,
                location: `general_content/${getLocationFromId(questionnaire.id)}`,
            });
        } else {
            onChange({
                id: '',
                location: ''
            });
        }

    };

    return (
        <form>
            <ErrorView errors={errors} />
            <div className="form-group">
                <label htmlFor="questionnaireTemplateId">
                    {_`Questionnaire`}:{' '}
                </label>
                <div>
                    <Select
                        maxMenuHeight={170}
                        isClearable={true}
                        className="filter-select"
                        classNamePrefix="filter-select"
                        placeholder={`Available questionnaires (${numOptions})`}
                        options={searchQuestionnaireList}
                        onChange={( option ) => handleChange(option ? option.value : null)}
                        value={selectedQuestionnaireOption}
                    />
                </div>
            </div>
            <div className="timeline-fields">
                <LozengeContentForm
                    onChange={onChange}
                    lozengeTitle={form.lozengeTitle}
                    lozengeContent={form.lozengeContent}
                />
            </div>
        </form>
    );
};
