import { Composition } from 'models/Composition';
import { QuestionnaireResponseAnyType } from 'models/compositions/QuestionnaireResponse';
import { Label } from 'models/Label';

export enum QuestionnaireArchetype {
    coopQuestionnaire = 'coopQuestionnaire',
    questionnaire = 'questionnaire'
}

export interface QuestionnaireData {
    questionnaireResponse: Composition<QuestionnaireResponseAnyType>;
    name: string;
    nameOfIssuer: string;
    createdAt: string;
    createdUser: string;
    dueBy: string;
    status: string;
    lastUpdated: string;
    respondentName: string;
    uuid: string;
    isHidden: string;
    useQuestionNumber: boolean;
    canBeCompletedByClinician: boolean;
    questionnaireScores: any[];
    viewedQuestions: any[];
    labels: Label[];
    sections: any;
    answers: any;
    scoring?: any;
    scores?: any;
    questionScores?: any;
    questionGroups?: any;
}

export enum QuestionnaireType {
    TEMPLATE = 'template',
    COMPOSITION = 'composition'
}

export type CoopQuestionnaire = {
    created_at: string
    created_user_id: number
    details: string,
    disabled_at: string,
    id: number
    name: string,
    parent_id: number,
    service_id: number,
    uuid: string,
    version: number,
    labels: Label[],
}

export type Questionnaire = {
    details: {
        preamble: string,
        sections: [{
            conditional: any[],
            id: number,
            preamble: string,
            questions: [{
                conditional: any[],
                default: string,
                id: number,
                inputs: [{
                    type: QuestionType,
                    options: any,
                    id: number
                }],
                mandatory: boolean,
                question: string
            }],
            title: string
        }]
    },
    labels: Label[],
    uuid: string,
    name: string,
    version: number,
    questionnaire_uuid: string,
    id?: number
}

export enum QuestionType {
    slider = 'slider',
    multiselect = 'multiSelect',
    mcq = 'mcq',
    text = 'text',
    number = 'number'
}
