import { useEffect, useState } from 'react';
// hooks
import { useTeamPreferences } from 'common/useTeamPreferences';
// services
import { pifuContentService } from 'features/content-editor/contentService';
// interfaces
import { PIFUContent } from 'models/compositions/PIFU';

export function usePIFU() {
    const [isPifuEnabled, setIsPifuEnabled] = useState(false);
    const [pifuDisplayName, setPifuDisplayName] = useState('');

    const teamPreferences = useTeamPreferences();

    useEffect(() => {
        if (!teamPreferences.pifu) {
            return;
        }

        setIsPifuEnabled(teamPreferences.pifu.pifu_enabled);
        setPifuDisplayName(teamPreferences.pifu.pifu_display_name);
    }, [teamPreferences]);

    return { isEnabled: isPifuEnabled, displayName: pifuDisplayName };
}

export function usePIFUOptionList() {
    const [isLoading, setIsLoading] = useState(true);
    const [optionList, setOptionList] = useState<PIFUContent[]>([]);

    useEffect(() => {
        async function loadOptionList() {
            try {
                const pifuListContent =
                    await pifuContentService.getContentList(
                        'pifu-label-option',
                        null,
                    );

                const pifuContentOptionList = (pifuListContent.content ||
                    []);

                const promiseArr = pifuContentOptionList.map(
                    (contentOption) => {
                        return pifuContentService.getContentById(
                            contentOption.id,
                            'pifu',
                        );
                    },
                );

                const optionList = await Promise.all(promiseArr);


                setOptionList(optionList);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        }

        loadOptionList();
    }, []);

    return { isLoading, list: optionList };
}
