const tag = '[getRelativePosition]';
export enum RelativePosition {
    TopRight,
    TopCenter,
    TopLeft,
    BottomRight,
    BottomCenter,
    BottomLeft,
}
export interface IDimensionContainer {
    width: number;
    height: number;
    top: number;
    left: number;
}
export interface IDimensionContainers {
    boundingBoxContainer: IDimensionContainer,
    contentContainer: IDimensionContainer,
    targetContainer: IDimensionContainer
}
function getY(params: {where: 'top' | 'bottom', boundingBoxContainer: IDimensionContainer , targetContainer: IDimensionContainer, contentContainer: IDimensionContainer}): {fits: boolean; y: number} {
    const {
        where,
        boundingBoxContainer,
        targetContainer,
        contentContainer
    } = params;
    let coord: any = {};
    switch (where) {
        case 'top':
            coord.y = targetContainer.top - contentContainer.height;
            coord.fits = coord.y >= boundingBoxContainer.top;
            if(!coord.fits) {
                coord.y = boundingBoxContainer.top
            }
            break;
        case 'bottom':
            coord.y = targetContainer.top + targetContainer.height;
            coord.fits = boundingBoxContainer.top + boundingBoxContainer.height - (coord.y + contentContainer.height) >= 0;
            if(!coord.fits) {
                coord.y = boundingBoxContainer.top + boundingBoxContainer.height - contentContainer.height;
            }
            break;
    }

    return coord;
}
function fitsBoundaries(container: {right: number; left: number}, boundaries: {right: number; left: number}) {
    return {
        rightBoundaryFits: container.right <= boundaries.right,
        leftBoundaryFits: container.left >= boundaries.left,
        containerFits: (container.right - container.left) <= (boundaries.right - boundaries.left),
    }
}
function getLeft (forSide: 'top' | 'bottom', params: IDimensionContainers) {
    const {
        boundingBoxContainer,
        contentContainer,
        targetContainer,
    } = params;
    const {y, fits} = getY({where: forSide, ...params});
    const {leftBoundaryFits, rightBoundaryFits, containerFits} = fitsBoundaries(
        {
            left: targetContainer.left,
            right: targetContainer.left + contentContainer.width
        },
        {
            left: boundingBoxContainer.left,
            right: boundingBoxContainer.left + boundingBoxContainer.width
        }
    );
    const stickToRightBorder = (boundingBoxContainer.left + boundingBoxContainer.width - contentContainer.width);
    const stickToLeftBorder = (boundingBoxContainer.left);
    const stickToProperPosition = targetContainer.left;
    const x = containerFits && leftBoundaryFits ?
        (rightBoundaryFits ? stickToProperPosition : stickToRightBorder ) :
        stickToLeftBorder;

    return {
        x,
        y,
        fits,
    }
}

function getCenter (forSide: 'top' | 'bottom', params: IDimensionContainers) {
    const {
        boundingBoxContainer,
        contentContainer,
        targetContainer,
    } = params;
    const {y, fits} = getY({where: forSide, ...params});
    const {leftBoundaryFits, rightBoundaryFits, containerFits} = fitsBoundaries(
        {
            left: targetContainer.left + targetContainer.width / 2 - contentContainer.width / 2,
            right: targetContainer.left + targetContainer.width / 2 + contentContainer.width / 2
        },
        {
            left: boundingBoxContainer.left,
            right: boundingBoxContainer.left + boundingBoxContainer.width
        }
    );
    const stickToRightBorder = (boundingBoxContainer.left + boundingBoxContainer.width - contentContainer.width);
    const stickToLeftBorder = (boundingBoxContainer.left);
    const stickToProperPosition = (targetContainer.left + targetContainer.width / 2 - contentContainer.width / 2);
    const x = containerFits ?
        (leftBoundaryFits ? rightBoundaryFits ? stickToProperPosition : stickToRightBorder : stickToLeftBorder) :
        (boundingBoxContainer.left + boundingBoxContainer.width / 2 - contentContainer.width / 2);
    return {
        x,
        y,
        fits,
    }
}

function getRight (forSide: 'top' | 'bottom', params: IDimensionContainers) {
    const {
        boundingBoxContainer,
        contentContainer,
        targetContainer,
    } = params;
    const {y, fits} = getY({where: forSide, ...params});
    const {leftBoundaryFits, rightBoundaryFits, containerFits} = fitsBoundaries(
        {
            left: targetContainer.left + targetContainer.width - contentContainer.width,
            right: targetContainer.left + targetContainer.width
        },
        {
            left: boundingBoxContainer.left,
            right: boundingBoxContainer.left + boundingBoxContainer.width
        }
    );
    const stickToRightBorder = (boundingBoxContainer.left + boundingBoxContainer.width - contentContainer.width);
    const stickToLeftBorder = (boundingBoxContainer.left);
    const stickToProperPosition = (targetContainer.left + targetContainer.width - contentContainer.width);
    const x = containerFits && rightBoundaryFits ?
        (leftBoundaryFits ? stickToProperPosition : stickToLeftBorder ) :
        stickToRightBorder;

    return {
        x,
        y,
        fits,
    }
}

const handlers: Map<RelativePosition, (forSide: 'top' | 'bottom', params: IDimensionContainers) => ({x: number; y: number; fits: boolean})> = new Map([
    [RelativePosition.TopLeft, getLeft.bind(null, 'top')],
    [RelativePosition.TopCenter, getCenter.bind(null, 'top')],
    [RelativePosition.TopRight, getRight.bind(null, 'top')],
    [RelativePosition.BottomLeft, getLeft.bind(null, 'bottom')],
    [RelativePosition.BottomCenter, getCenter.bind(null, 'bottom')],
    [RelativePosition.BottomRight, getRight.bind(null, 'bottom')],
]);

export function getRelativePosition(params: {containers: IDimensionContainers, where?: RelativePosition[]}) {
    const {
        containers,
        where = [RelativePosition.TopLeft, RelativePosition.BottomLeft]
    } = params;
    let coords: {x: number, y: number, position: RelativePosition}[] = [];
    const found = where.some((position) => {
        const handler: any = handlers.get(position);
        const c = handler(containers);
        coords.push(c);
        return c && c.fits;
    });

    let data;

    if(found) {
        data = coords[coords.length - 1];
        data.position = where[coords.length - 1];
    } else {
        data = coords[0];
        if(data) {
            data.position = where[0];
        } else {
            console.warn(tag, 'Position not found', where);
            data = {x: 0, y: 0, position: RelativePosition.BottomLeft};
        }
    }
    return data;
}
