import React, { Component } from 'react';
import Modal from 'components/modal';
import { cloneDeep } from 'lodash';
import _ from 'services/i18n';
import EmailValidator from 'email-validator';
import { connect } from 'react-redux';
import { RoleDefinition } from 'services/roles.service';
import { submitNewUser, finishRegistration } from 'actions/members-page.actions';
import { AsyncStates } from 'reducers/admin-portal/members-page.reducer';
import { useTeamRoles, TEAM_ROLES_GROUPS } from 'common/useTeamRoles';
import { TeamPreferences } from 'models/TeamPreferences';
import teamPreferencesService from 'services/team-preferences.service';

export type InviteFormBaseProps = {
    onClose: () => void;
}

type InviteFormProps = InviteFormBaseProps & {
    dispatch: any;
    reduxStore: any;
    teamRoles: RoleDefinition[];
}

function ClinicianRoles(props: InviteFormBaseProps) {
    const [teamRoles, teamRolesGroups] = useTeamRoles();

    const clinicianRoles = teamRolesGroups.get(TEAM_ROLES_GROUPS.CLINICIAN);

    function mapDispatchToProps(dispatch: (action: any) => void) {
        return {
            dispatch,
        };
    }

    function mapStateToProps({ adminPortalMembersList }: any) {
        return {
            reduxStore: adminPortalMembersList.toJS(),
            teamRoles: clinicianRoles,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const ReduxComponent = connect(mapStateToProps, mapDispatchToProps)(InviteModal);

    return <ReduxComponent {...props} />;
}

class InviteModal extends Component<InviteFormProps> {
    public state: { teamPreferences?: TeamPreferences; roles: any; form: any } = {
        teamPreferences: {},
        roles: [],
        form: {
            email: {
                type: 'email',
                label: 'Email',
                placeholder: 'Enter clinician email...',
                required: true,
                name: 'email',
                error: '',
                value: ''
            },
            givenName: {
                type: 'text',
                label: 'Given Name',
                placeholder: 'Enter given name...',
                required: true,
                name: 'givenName',
                error: '',
                value: ''
            },
            middleName: {
                type: 'text',
                label: 'Middle Name',
                placeholder: 'Enter middle name...',
                required: false,
                name: 'middleName',
                error: '',
                value: ''
            },
            familyName: {
                type: 'text',
                label: 'Family name',
                placeholder: 'Enter family name...',
                required: true,
                name: 'familyName',
                error: '',
                value: ''
            },
            namePrefix: {
                type: 'text',
                label: 'Name Prefix',
                placeholder: 'Like Ms. or Mr.',
                required: false,
                name: 'namePrefix',
                error: '',
                value: ''
            },
            nameSuffix: {
                type: 'text',
                label: 'Name Suffix',
                placeholder: 'Like MD., Ph.D',
                required: false,
                name: 'nameSuffix',
                touched: false,
                error: '',
                value: ''
            },
            roles: {
                type: 'checkboxGroup',
                label: 'Available Roles',
                required: true,
                name: 'roles',
                touched: false,
                error: '',
                options: []
            }
        }
    };

    componentDidMount() {
        if (this.props.teamRoles) {
            this.setRolesOptions(this.props.teamRoles);
        }
        teamPreferencesService.get()
            .then((teamPreferences) => {
                this.setState({ teamPreferences });
            });
    }

    public handleBlur(e: any): void {
        const target = e.target;
        const form = cloneDeep(this.state.form);
        this.setState({ form }, () => {
            const error = this.validate(target.name, target.value) || '';
            if (form[target.name].error !== error) {
                form[target.name].error = error;
                this.setState({ form });
            }
        });
    }

    public handleChange(e: any): void {

        const target = e.target;
        const form = cloneDeep(this.state.form);

        if (form[target.name].type === 'checkboxGroup') {
            form[target.name].options.forEach((option: any) => {
                option.checked = target.value.some((val: string) => val === option.value);
            });
            form[target.name].error = this.validate(target.name, target.value);
        } else {
            form[target.name].value = target.value;
        }

        this.setState({ form });
    }

    public componentWillReceiveProps({ teamRoles }: Readonly<InviteFormProps>, nextContext: any): void {
        this.setRolesOptions(teamRoles);
    }

    private setRolesOptions(teamRoles: RoleDefinition[]) {
        if (teamRoles.length) {
            const form = cloneDeep(this.state.form);
            form.roles.options = teamRoles.map((role) => {
                return {
                    checked: false,
                    value: role.uuid,
                    label: role.name
                };
            });
            this.setState({ form });
        }
    }

    public render() {
        let buttons = [
            { label: 'Invite', style: 'primary', callback: this.handleSubmit.bind(this) },
            { label: 'Close', callback: () => {
                this.props.dispatch(finishRegistration());
                this.props.onClose && this.props.onClose();
            }
            }
        ];

        const { reduxStore } = this.props;


        if (reduxStore.modal.userSubmitState === AsyncStates.Success) {
            buttons = [
                { label: 'Ok', callback: () =>  {
                    this.props.dispatch(finishRegistration());
                    this.props.onClose && this.props.onClose();
                } }
            ];
            return (
                <Modal
                    title={_`Invite Clinician To Team`}
                    onClose={() =>  {
                        this.props.dispatch(finishRegistration());
                        this.props.onClose && this.props.onClose();
                    }}
                    busy={reduxStore.modal.userSubmitState === AsyncStates.Loading}
                    buttons={buttons}>
                    {this.state.teamPreferences.portal && this.state.teamPreferences.portal.admin_registration_with_ter ?
                        'Please wait. Member will appear in the list when their invitation has been sent.' :
                        'Clinician was successfully created.'}
                </Modal>
            );
        }

        return (
            <div className="invite-modal">
                <Modal title={_`Invite Clinician To Team`}
                    onClose={this.props.onClose}
                    busy={reduxStore.modal.userSubmitState === AsyncStates.Loading}
                    buttons={buttons}>
                    <div className="invite-modal_container">
                        {
                            reduxStore.modal.userSubmitState === AsyncStates.Fail &&
                            <div className="alert alert-danger">Unable to Invite Clinician. Please try again later</div>
                        }
                        <form className="portal_form">
                            {Object.keys(this.state.form).map((inputName, i) => {
                                const formElement = this.state.form[inputName];
                                switch (formElement.type) {
                                case 'text':
                                case 'password':
                                case 'email':
                                case 'tel':
                                    return (
                                        <FormInput
                                            {...formElement}
                                            key={i}
                                            blur={(e: any) => this.handleBlur(e)}
                                            change={(e: any) => this.handleChange(e)}
                                        />
                                    );
                                case 'checkboxGroup':
                                    return (
                                        <FormCheckboxGroup
                                            {...formElement}
                                            key={i}
                                            change={(e: any) => this.handleChange(e)}
                                        />
                                    );
                                }
                            })}
                            <div className="portal_form-note">
                                <span className="portal_note-asterisk">*</span> - Field is required
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }

    private handleSubmit() {
        const errors: string[] = this.validateAll();
        if (!errors.length) {
            const user = this.getUserDataFromForm();
            const withTer = this.state.teamPreferences.portal && this.state.teamPreferences.portal.admin_registration_with_ter;
            this.props.dispatch(submitNewUser({ user, folderGroupUuids: this.getFolderGroupsUuids(), withTer }));
        }
    }

    private getFolderGroupsUuids(): string[] {
        const folderGroupsUuids = this.props.teamRoles.reduce((acc: string[], role: any) => {
            const uuids = role.folder_groups.map((fg: any) => fg.uuid);
            return acc.concat(uuids);
        }, []);

        return Array.from(new Set(folderGroupsUuids));
    }

    private validate(name: string, value: any) {
        const inputData = this.state.form[name];
        if (!value.length && inputData.required) {
            return value instanceof Array ? 'At least one should be selected' : 'Field is required!';
        }
        if (value.length && inputData.type === 'email') {
            return EmailValidator.validate(value) ? '' : 'Email is invalid.';
        }
        return null;
    }

    private validateAll() {
        const form = cloneDeep(this.state.form);
        const errors: string[] = [];
        Object.keys(form)
            .forEach((itemName) => {

                const item = form[itemName];
                let value;
                if (item.type === 'checkboxGroup') {
                    value = item.options.reduce((acc: string[], option: any) => {
                        if (option.checked) {
                            acc.push(option.value);
                        }
                        return acc;
                    }, []);
                } else {
                    value = item.value;
                }

                form[itemName].error = this.validate(itemName, value);
                if (form[itemName].error) {
                    errors.push(form[itemName].error);
                }
            });

        this.setState({ form });
        return errors;
    }

    private getUserDataFromForm() {
        const user = {
            email: this.state.form.email.value,
            // eslint-disable-next-line @typescript-eslint/camelcase
            role_uuids: this.state.form.roles.options.reduce((acc: string[], option: any) => {
                if (option.checked) {
                    acc.push(option.value);
                }
                return acc;
            }, []),
            name: {} as any
        };

        if (this.state.form.givenName) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            user.name.given_name = this.state.form.givenName.value;
        }

        if (this.state.form.familyName) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            user.name.family_name = this.state.form.familyName.value;
        }

        if (this.state.form.middleName?.value) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            user.name.middle_name = this.state.form.middleName.value;
        } else {
            delete user.name.middle_name;
        }

        if (this.state.form.suffix) {
            user.name.suffix = this.state.form.suffix.value;
        }

        if (this.state.form.prefix) {
            user.name.prefix = this.state.form.prefix.value;
        }

        return user;
    }
}

const FormInput = ({ required, label, type, placeholder, name, error, value, change, blur, focus }: any) => {

    return (
        <div className={'portal_form-group' + (error ? ' input_invalid' : '')}>
            <label title={required ? _`${label} (Required)` : _`${label}`} className={required ? 'portal_required-asterisk' : null}>
                {_`${label}`}
            </label>
            <input type={type}
                name={name}
                placeholder={_`${placeholder}`}
                required={required}
                value={value}
                onBlur={(e) => blur && blur(e)}
                onChange={(e) => change && change(e)}
                onFocus={(e) => focus && focus(e)}
            />
            <div className="portal_control-alert">{error}</div>
        </div>
    );
};

interface CheckboxGroupProps {
    options: Array<{ value: string; label: string; checked: boolean }>;
    required: boolean;
    label: string;
    name: string;
    error: string;
    change: (e: any) => any;
    blur: (e: any) => any;
    focus: (e: any) => any;
}

class FormCheckboxGroup extends Component<CheckboxGroupProps, { focused: boolean }> {

    constructor(props: CheckboxGroupProps) {
        super(props);
    }

    public handleChange(event: any) {
        const newValue = event.target.value;
        const checked = event.target.checked;
        const values = this.props.options.reduce((acc, next) => {
            if (next.checked) {
                acc.push(next.value);
            }
            return acc;
        }, []);

        const exists: number = values.findIndex((v) => v === newValue);

        if (checked) {
            if (exists === -1) {
                values.push(newValue);
            }
        } else {
            if (exists !== -1) {
                values.splice(exists, 1);
            }
        }
        this.props.change({
            target: {
                name: this.props.name,
                value: values
            }
        });
    }

    public render() {
        const { required, label, options, error } = this.props;
        return (
            <div className={'portal_form-group' + (error ? ' input_invalid' : '')}>
                <label title={required ? _`${label} (Required)` : _`${label}`} className={required ? 'portal_required-asterisk' : null}>
                    {_`${label}`}
                </label>
                {options.map((option: any, i) => {
                    return (
                        <div key={i} className="portal_checkbox-group">
                            <input type="checkbox"
                                onChange={(e) => this.handleChange(e)}
                                value={option.value}
                                checked={option.checked}
                                id={`id${i}`}/>
                            <label htmlFor={`id${i}`}>{option.label}</label>
                        </div>
                    );
                })}
                <div className="portal_control-alert">{error ? <strong>Error. </strong> : null}{error}</div>
            </div>
        );
    }
}

export default ClinicianRoles;
