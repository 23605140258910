import React from 'react';
import PropTypes from 'prop-types'
import lodash from 'lodash';

import {ResponsiveTable, Row, Column} from 'ui';
import WorklistEntry from './worklist-entry';

/**
 * Displays the table containing the transport and interpreter worklist data and buttons.
 * 
 * Props (data):
 * - appointments = Required. A sorted array of appointments to display in the worklist table.
 * - demographicsLookup = Required. A map of folder IDs to demographics compositions for looking-up patient identity details. Entries can be omitted if they are still loading. If a folder doesn't have demographics info, it should have an entry with an empty object.
 * - updatingTransportList = Required. An array of appointment UUIDs where the transport arrangements are being updated.
 * - updatingInterpreterList = Required. An array of appointment UUIDs where the interpreter arrangements are being updated.
 * - failedList = Required. An array of appointments UUIDs where an update failed.
 * 
 * Props (functions):
 * - onArrangedTransport = Required. Callback to be triggered when the user has arranged transport for an appointment. Takes the appointment composition as a parameter.
 * - onArrangedInterpreter = Required. Callback to be triggered when the user has arranged an interpreter for an appointment. Takes the appointment composition as a parameter.
 * - onUndoTransport = Required. Callback to be triggered when the user has cancelled transport arrangements for an appointment. Takes the appointment composition as a parameter.
 * - onUndoInterpreter = Required. Callback to be triggered when the user has cancelled interpreter arrangements for an appointment. Takes the appointment composition as a parameter.
 * 
 */
const TransportAndInterpreterTable = ({appointments, demographicsLookup, updatingTransportList, updatingInterpreterList, failedList, onArrangedTransport, onArrangedInterpreter, onUndoTransport, onUndoInterpreter}) => {

    // This function will get called once for each appointment.
    // It should carry out any required lookups on the input tables, and return
    //  a JSX table row.
    const generateWorklistEntry = (appointment, index) => {

        const folderId = lodash.get(appointment, 'folder_id');
        if (!folderId) {
            console.warn(`Folder ID missing from appointment composition ${index}.`);
        }

        const uuid = lodash.get(appointment, 'uuid', '');
        if (!uuid) {
            console.warn(`UUID missing from appointment composition at index ${index}.`);
        }

        return (
            <WorklistEntry
                key=                    {index}
                appointment=            {appointment}
                demographics=           {demographicsLookup.get(folderId)}
                updatingTransport=      {updatingTransportList.indexOf(uuid) >= 0}
                updatingInterpreter=    {updatingInterpreterList.indexOf(uuid) >= 0}
                failed=                 {failedList.indexOf(uuid) >= 0}
                onArrangedTransport=    {onArrangedTransport.bind(null, appointment)}
                onArrangedInterpreter=  {onArrangedInterpreter.bind(null, appointment)}
                onUndoTransport=        {onUndoTransport.bind(null, appointment)}
                onUndoInterpreter=      {onUndoInterpreter.bind(null, appointment)}
            />
        );
    };

    return (
        <Row>
            <Column md='12'>
                <ResponsiveTable className='table table-bordered table-striped'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>NHS number</th>
                            <th>Date &amp; Time</th>
                            <th>Location</th>
                            <th>Transport</th>
                            <th>Interpreter</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointments.map(generateWorklistEntry)}
                    </tbody>
                </ResponsiveTable>

            </Column>
        </Row>
    );
};

export default TransportAndInterpreterTable;

TransportAndInterpreterTable.propTypes = {
    appointments:               PropTypes.array.isRequired,
    demographicsLookup:         PropTypes.object.isRequired,
    updatingTransportList:      PropTypes.array.isRequired,
    updatingInterpreterList:    PropTypes.array.isRequired,
    failedList:                 PropTypes.array.isRequired,

    onArrangedTransport:        PropTypes.func.isRequired,
    onArrangedInterpreter:      PropTypes.func.isRequired,
    onUndoTransport:            PropTypes.func.isRequired,
    onUndoInterpreter:          PropTypes.func.isRequired
};
