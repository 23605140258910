import React, { createContext } from 'react';

import { useCompositionList } from 'common/useCompositionList';

export const ReferralsContext = createContext({
    list: [],
    status: {
        isFetching: true,
    },
    itemMap: new Map(),
    invalidate: () => { console.error('Referal Context not initialized'); },
    invalidateItem: (uuid: string) => { console.error('Referal Context not initialized'); },
    updateContent: (uuid: string, content: object) => { console.error('Referal Context not initialized'); },
});

export function ReferralsProvider({
    folderId,
    teamId,
    children,
}: {
    folderId: number;
    teamId: number;
    children: any;
}) {
    const [
        list,
        status,
        itemMap,
        {
            updateContent,
            invalidate,
            invalidateItem
        }
    ] = useCompositionList({
        archetype: 'referral',
        folderId,
        teamId,
    });

    return (
        <ReferralsContext.Provider value={{
            list,
            status,
            itemMap,
            updateContent,
            invalidate,
            invalidateItem,
        }}>
            {children}
        </ReferralsContext.Provider>
    );
}
