import React from 'react';
import { ResponsiveTable } from 'ui';
import _ from 'services/i18n';
import ResourceLabel from '../ResourceLabel/ResourceLabel';
import './PatientResourcesTable.scss';
import { resourceTypeList } from '../ResourceTypeSelect/ResourceTypeSelect';

export default function PatientResourcesTable({ results, onEditResource, onRemoveResource }) {

    const list = results.map(({ content, uuid }, idx) => {
        const categories = content.category.map(({ name }) => name);

        return (
            <tr key={idx}>
                <td>{resourceTypeList.find((type) => type.value === content.resource_type).label || ''}</td>
                <ResourceLabel 
                    resource={content} />
                <td>{categories.join(', ')}</td>
                <td>{content.description}</td>
                <td>
                    <div className="btn-group">
                        <button className="btn btn-xs btn-default" onClick={() => onEditResource(uuid)}>
                            {_ `Edit`}
                        </button>
                        <button className="btn btn-xs btn-danger" onClick={() => onRemoveResource(uuid)}>
                            {_ `Remove`}
                        </button>
                    </div>
                </td>
            </tr>
        );
    });

    if (results.length === 0) {
        return <p>{_`This patient has no assigned resources`}</p>;
    }

    return (
        <ResponsiveTable className="table table-bordered table-condensed table-hover table-nomargin patient-resources-table">
            <thead>
                <tr>
                    <th>{_`Type`}</th>
                    <th>{_`Title`}</th>
                    <th>{_`Collection`}</th>
                    <th>{_`Details`}</th>
                </tr>
            </thead>
            <tbody>
                {list}
            </tbody>
        </ResponsiveTable>
    );
}
