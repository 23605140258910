/**
 * Service class that can be used to set application favicon
 */
class FaviconService {

    constructor(){
        this.faviconElementSelector = '#favicon'
    }

    setPathwayIcon(){
        document.querySelector(this.faviconElementSelector).setAttribute('href','favicon-pathway.ico');
    }

    setDefaultIcon(){
        document.querySelector(this.faviconElementSelector).setAttribute('href','favicon.ico');
    }

}

export default new FaviconService();