import React, { FunctionComponent } from 'react';
import CoopQuestionnaireView from 'features/patient/questionnaire/questionnaire-view/coop-questionnaire.view';
import QuestionnaireView from 'features/patient/questionnaire/questionnaire-view/questionnaire.view';
import './questionnaire-component';
import { usePathwayNames } from 'features/pathway-labels/usePathwayNames';
import { QuestionnaireArchetype, QuestionnaireData } from 'models/QuestionnaireData';

export interface QuestionnaireComponentProps {
    loading: boolean;
    isUpdating: boolean;
    isEditMode: boolean;
    questionnaireData: QuestionnaireData;
    archetype?: QuestionnaireArchetype;
    folderId: number;
    showReport?: boolean;
    showHeader?: boolean;
    onCloseModal?: (item: any) => void;
    onShowReport?: (item: any) => void;
    onCancel?: () => void;
    onSubmit?: (item: any) => void;
}

export const QuestionnaireComponent: FunctionComponent<QuestionnaireComponentProps> = (props) => {
    const {
        archetype,
        loading,
        isUpdating,
        isEditMode,
        questionnaireData,
        showHeader,
        onCancel,
        onSubmit,
        folderId,
        onShowReport,
        showReport,
        onCloseModal,
    } = props;

    const { pathwayNamesWithEvent } = usePathwayNames({ labels: questionnaireData.labels });

    return (
        archetype === QuestionnaireArchetype.coopQuestionnaire ? (
            <CoopQuestionnaireView {...{
                loading,
                isUpdating,
                isEditMode,
                showHeader,
                folderId,
                questionnaireData,
                showReport,
                onSubmit,
                onCancel,
                onShowReport,
                onCloseModal,
                pathwayNamesWithEvent,
            }} />
        ) : (
            <QuestionnaireView {...{
                loading,
                isUpdating,
                isEditMode,
                showHeader,
                folderId,
                questionnaireData,
                onSubmit,
                onCancel,
                pathwayNamesWithEvent,
            }} />
        )
    );
};
export default QuestionnaireComponent;
