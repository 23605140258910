import React, { FC } from 'react';
import PaginationView from 'components/pagination';
import { PaginationContext } from 'components/pagination-wrapper/PaginationContext';
import {
    Filter,
    GridFilterOptions,
    GridSortOptions,
    usePaginatedSortedAndFilteredList
} from 'common/ui/grid/paginatedTableReducer';
import { GridSortState } from 'common/ui/grid';

interface Props {
    data: unknown[];
    loading: boolean;
    perPage?: number;
    sortOrder?: GridSortState;
    filterState?: Filter;
    filterOptions?: GridFilterOptions;
    sortOptions?: GridSortOptions;
    searchBy?: string;
}

export const PaginationWrapper: FC<Props> = (props) => {

    const pagination = usePaginatedSortedAndFilteredList({
        list: props.data,
        loading: props.loading,
        perPage: props.perPage,
        sortOrder: props.sortOrder,
        sortOptions: props.sortOptions,
        filterOptions: props.filterOptions,
        filterState: props.filterState,
    });

    return (
        <PaginationContext.Provider value={ { data: pagination.list, onSearch: pagination.onSearch }}>
            {props.children}

            <PaginationView
                currentPage={pagination.page}
                pageCount={pagination.pagesTotal}
                onChange={pagination.onPageChange}
            />
        </PaginationContext.Provider>
    );
};

