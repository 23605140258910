import React, { Component } from 'react';
import lodash from 'lodash';
import { convertToDate, standardDateTimeFormats } from 'common/datetime/convertToDate';
import { PRIMARY_IDENTIFIER_NAMESPACE } from 'models/PersonPrimaryIdentifier';
import NhsNumber from 'ui/nhs-number';
import { teamPreferencesService } from 'services/team-preferences.service';

export default class PatientBar extends Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            newEmail: '',
            editableEmail: false,
            invalidEmail: false,
            teamPreferences: null
        };

        this.getTeamPreferences();
    }

    getPhoneNumber(): string {
        const { patientData } = this.props;
        const hasMobilePhone: boolean = patientData.mobile_phone;
        const hasHomePhone: boolean = patientData.home_phone;

        if (hasMobilePhone) {
            return patientData.mobile_phone[0];
        }
        if (hasHomePhone) {
            return patientData.home_phone[0];
        }
        return '';
    }

    getTeamPreferences() {
        teamPreferencesService.getFirst({}).catch(() => {
            return teamPreferencesService.defaultProperties;
        }).then((teamPreferences) => {
            this.setState({ teamPreferences });
        });
    }

    getEmail() {
        const { folderData } = this.props;
        const { email } = folderData;
        return this.state.editableEmail ? this.state.newEmail : email || '';
    }

    onChangeEmail(value) {
        this.setState({
            newEmail: value
        });
    }

    onSubmitEmail() {
        if (this.isValidEmail()) {
            this.props.onSubmitEmail(this.state.newEmail)
                .then(() => {
                    this.setState({
                        newEmail: '',
                        editableEmail: false
                    });
                })
                .catch(() => {
                    this.setState({
                        editableEmail: false
                    });
                });
        } else {
            this.setState({
                invalidEmail: true
            });
        }
    }

    onEmailFocused() {
        this.setState({
            editableEmail: true
        });
    }

    isValidEmail() {
        return /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])+(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])+)*$/.test(this.state.newEmail);
    }

    cancelEmailEdit(erase = false) {
        this.setState({
            editableEmail: false,
            invalidEmail: false
        });

        if (erase) {
            this.setState({
                newEmail: '',
                invalidEmail: false
            });
        }
    }

    getDoBWithYears(dob, isDeceased) {
        if (!/\d{4}-\d{2}-\d{2}/.test(dob)) {
            return null;
        }
        const dateOfBirth = convertToDate({ ymd: dob }).format(standardDateTimeFormats.nhs_date_short);
        const years = convertToDate(new Date()).diff(convertToDate(dob, 'date_input'), 'years');
        return isDeceased ? `${dateOfBirth}` : `${dateOfBirth} (${years}y)`;
    }

    getName({ given_name: givenName = '', family_name: familyName = '', prefix = '' } = {}) {
        const nameBlockSeparator = (!lodash.isEmpty(familyName) && !lodash.isEmpty(givenName)) ? ',' : '';
        const prefixBlock = prefix ? `(${lodash.upperFirst(lodash.lowerCase(prefix))})` : '';
        return `${lodash.toUpper(familyName)}${nameBlockSeparator} ${lodash.upperFirst(givenName)} ${prefixBlock}`.trim();
    }

    getPreferredName({ given_name: givenName = '' } = {}) {
        return `${lodash.upperFirst(givenName)}`.trim();
    }

    render() {
        const {
            patientData,
            folderData,
            personPrimaryIdentifier
        } = this.props;

        const { teamPreferences } = this.state;
        const isDeceased = lodash.get(patientData, 'death_indicator');
        const dobString = this.getDoBWithYears(lodash.get(patientData, 'date_of_birth'), isDeceased);
        const gender = lodash.upperFirst(lodash.get(patientData, 'gender'));
        const hospitalNumber = lodash.get(patientData, 'identifiers.rhq_id');
        const preferredName = this.getPreferredName(patientData.preferred_name || {});
        const name = this.getName(patientData.name || {});
        const phoneNumber = this.getPhoneNumber();
        const shouldBeHidden = !(personPrimaryIdentifier || dobString || preferredName ||
            hospitalNumber || gender || name);
        const status = folderData && folderData.status;

        return (
            <div className="patient-bar" hidden={shouldBeHidden}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="patient-bar__name">
                                <strong>{name}</strong>
                            </div>
                            {
                                preferredName && (
                                    <div className="patient-bar__preferred-name">
                                        <span>Preferred Name: <strong>{preferredName}</strong>&nbsp;</span>
                                    </div>
                                )}
                        </div>
                        <div className="col-md-6 text-right">
                            {
                                dobString && (
                                    <div>
                                        Born: <strong>{dobString} {isDeceased && (<span className="important">{"(DECEASED)"}</span>)}</strong>&nbsp;
                                    </div>
                                )}
                            {
                                hospitalNumber && (
                                    <span>
                                        Hospital Number: <strong>{hospitalNumber}</strong>&nbsp;
                                    </span>
                                )}
                            {
                                gender && (
                                    <span className="no-wrap">
                                        Gender: <strong>{gender}</strong>&nbsp;
                                    </span>
                                )}
                            {
                                personPrimaryIdentifier && (
                                    personPrimaryIdentifier.namespace === PRIMARY_IDENTIFIER_NAMESPACE.NHS_NUMBER ?
                                        (
                                            <span className="no-wrap">
                                                {personPrimaryIdentifier.label}: <strong><NhsNumber>{personPrimaryIdentifier.value}</NhsNumber></strong>
                                            </span>
                                        ) :
                                        (
                                            <span className="no-wrap">
                                                {personPrimaryIdentifier.label}: <strong>{personPrimaryIdentifier.value}</strong>
                                            </span>
                                        )
                                )}
                        </div>
                    </div>
                    <div className="row patient-bar__flex-table">
                        <div className="patient-bar__flex-col">&nbsp;</div>
                        <div className="patient-bar__flex-col patient-bar__email">
                            {status === 'registered' ? (
                                <ChangeEmail value={this.getEmail()}
                                    editable={this.state.editableEmail}
                                    invalidEmail={this.state.invalidEmail}
                                    onChange={(value) => this.onChangeEmail(value)}
                                    onSubmit={() => this.onSubmitEmail()}
                                    onFocus={() => this.onEmailFocused()}
                                    onBlur={() => this.cancelEmailEdit()}
                                    onCancel={() => this.cancelEmailEdit(true)}
                                />
                            ) : <span>Email:&nbsp;<strong>{this.getEmail()}</strong></span>
                            }
                        </div>
                        <div className="patient-bar__flex-col">
                            {(teamPreferences && teamPreferences.portal.show_patient_phone) && <span>Phone Number:<strong>{phoneNumber}</strong>&nbsp;</span>}
                        </div>
                        <div className="patient-bar__flex-col">&nbsp;</div>
                    </div>
                </div>
            </div>
        );
    }
}

interface ChangeEmailProps {
    value: string;
    onChange?: any;
    onFocus?: any;
    onBlur?: any;
    onCancel?: any;
    onSubmit?: any;
    editable?: boolean;
    invalidEmail?: boolean;
}
class ChangeEmail extends Component<ChangeEmailProps> {

    constructor(props) {
        super(props);
    }

    onSubmit() {
        this.setState({
            submitted: true
        });
        this.props.onSubmit();
    }

    render() {
        const { value, onChange, onFocus, onBlur, onCancel, onSubmit, editable, invalidEmail } = this.props;
        if (editable) {
            return (
                <div>
                    <Input value={value}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        invalid={invalidEmail}
                        onBlur={onBlur}
                        onCancel={onCancel}
                        placeholder={'Input new email...'}
                    />
                </div>
            );
        } else {
            return <div onClick={onFocus}><strong>{value}</strong><i className="fas fa-edit edit-email"></i></div>;
        }
    }
}

class Input extends Component<any, any> {
    input: any
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            mouseDown: false,
            mouseLeaved: false,
            buttonPressed: false,
        };
    }

    resetState() {
        this.setState({
            submitted: false,
            mouseDown: false,
            mouseLeaved: false,
            buttonPressed: false
        });
    }

    onBlur() {
        if (!this.state.mouseDown) {
            this.resetState();
            this.props.onBlur();
        }
    }

    onMouseDown() {
        this.setState({
            mouseDown: true
        });
    }

    onMouseUp() {
        if (this.state.submitted && !this.props.invalid) {
            this.props.onBlur();
        } else {
            this.input.focus();
        }
        this.resetState();
    }

    onMouseOut() {
        if (this.state.mouseDown) {
            this.resetState();
            this.props.onBlur();
        }
    }

    onSubmit() {
        this.setState({
            submitted: true
        });
        this.props.onSubmit();
    }

    onCancel() {
        this.resetState();
        this.props.onBlur && this.props.onBlur();
        this.props.onCancel && this.props.onCancel();
    }

    render() {
        const { value, onChange, invalid, placeholder } = this.props;

        return (
            <div className={'input-with-buttons' + (invalid ? ' input-invalid' : '')}
                onMouseUp={() => this.onMouseUp()}
                onMouseLeave={() => this.onMouseOut()}
            >
                <input value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    onBlur={() => this.onBlur()}
                    ref={(input) => {
                        input && input.focus();
                        this.input = input;
                    }}
                />
                <button type="button" className="btn-submit" onMouseDown={() => this.onMouseDown()} onClick={() => this.onSubmit()}>
                    <i className="fas fa-check"></i></button>
                <button type="button" className="btn-cancel" onMouseDown={() => this.onMouseDown()} onClick={() => this.onCancel()}>
                    <i className="fas fa-times"></i>
                </button>
            </div>
        );
    }
}
