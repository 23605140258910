import React, { FC } from 'react';
import { useDashboardData } from 'features/patient/patient-dashboard/data/useDashboardData';
import { Line } from 'common/ui/line-graph/LineGraph';
import { DASHBOARD_COMPONENT_NAMES } from 'features/patient/patient-dashboard/TeamPrefDashboardModule';
import { QuestionnaireGraphView } from 'features/patient/patient-dashboard/dashboard-components/dashboard-questionnaire-graph/DashboardQuestionnaireGraphView';
import { RoleType } from 'features/patient/patient-dashboard/RoleType';

interface QuestionnaireGraphDashboardComponentProps {
    context: string;
    questionnaire: string;
    measure?: string;
    minValue?: number;
    maxValue?: number;
    boundaryValues?: number[];
}
export const DashboardQuestionnaireGraphForCarer: FC<QuestionnaireGraphDashboardComponentProps> = (props) => {
    const { loading, data, patient } = useDashboardData<Line[]>({
        type: DASHBOARD_COMPONENT_NAMES.DashboardQuestionnaireGraphForCarer,
        payload: { context: props.context, questionnaire: props.questionnaire, measure: props.measure }
    });

    return (
        <QuestionnaireGraphView
            role={RoleType.Carer}
            loading={loading}
            lines={data}
            questionnaireName={props.questionnaire}
            boundaryValues={props.boundaryValues}
            min={props.minValue}
            max={props.maxValue}
            folderId={patient.folderId}
        />
    );
};
