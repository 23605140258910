import React, { FunctionComponent, Children, Fragment, cloneElement} from 'react';

export const GridHeaderRow: FunctionComponent<{className?: string}> = (props) => {
    const { children, className } = props;

    return <Fragment>
        <tr className={className}>{children}</tr>
        <tr className={className}>{
            Children.map(children, (child: any) => {
                if (!child) { return null; }
                if (child.props.preventCloneForFilterRow) { return null; }
                return cloneElement((child), { isFilterRow: true });
            })
        }</tr>
    </Fragment>;
};
