/* eslint-disable @typescript-eslint/camelcase */
import AppointmentsComponent from 'pages/clinical_portal/folder/_folderId/patient/appointments/index';
import React, { Fragment, useMemo } from 'react';
import ResourceComponent from 'pages/clinical_portal/folder/_folderId/patient/resources/index';
import QuestionnaireComponent from 'pages/clinical_portal/folder/_folderId/patient/questionnaires/index';
import MessageComponent from 'pages/clinical_portal/folder/_folderId/patient/messages/index';
import PatientTabView from 'features/pathwayModal/PatientTabView';
import { PatientCommunicationsPage } from 'pages/clinical_portal/folder/_folderId/patient/communications/PatientCommunicationsPage';
import ReferralsListPage from 'pages/clinical_portal/folder/_folderId/patient/referrals/list';
import { PatientGoalsTab } from 'features/patient/goals/PatientGoalsTab';
import { UserNotes } from 'pages/clinical_portal/folder/_folderId/patient/user-notes';
import { QuestionnaireResultsPage } from 'features/patient/questionnaire-results/QuestionnaireResultsPage';
import Settings_Page from 'pages/clinical_portal/folder/_folderId/patient/settings';
import { MODAL_TYPE } from './ModalType';
import PatientBarModal from './PatientBarModal';
import { getTeamId } from 'common/baseComponentFunctions';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { TeamPreferences } from 'models/TeamPreferences';
import { ReferralsProvider } from 'features/ReferralsProvider';

const PatientModalView = ({ tab, folderId, tabView }): JSX.Element => {
    const teamId = getTeamId();
    const teamPreferences = useTeamPreferences();
    const navList = useNavList(teamPreferences);

    const renderElement = (tab): JSX.Element => {
        switch (tab) {
        case MODAL_TYPE.APPOINTMENTS:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <AppointmentsComponent folderId={folderId} teamId={teamId} />
                </Fragment>
            );
        case MODAL_TYPE.RESOURCES:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <ResourceComponent folderId={folderId} teamId={teamId} />
                </Fragment>
            );
        case MODAL_TYPE.QUESTIONNAIRES:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <QuestionnaireComponent folderId={folderId} teamId={teamId} />
                </Fragment>
            );
        case MODAL_TYPE.PROMS:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <QuestionnaireComponent folderId={folderId} teamId={teamId} />
                </Fragment>
            );
        case MODAL_TYPE.MESSAGES:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <MessageComponent folderId={folderId} teamId={teamId} />
                </Fragment>
            );
        case MODAL_TYPE.REFERRALS:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <ReferralsListPage folderId={folderId} />
                </Fragment>
            );
        case MODAL_TYPE.GOALS:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <PatientGoalsTab folderId={folderId} />
                </Fragment>
            );
        case MODAL_TYPE.SETTINGS:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <Settings_Page />
                </Fragment>
            );
        case MODAL_TYPE.COMMUNICATIONS:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <PatientCommunicationsPage folderId={folderId} teamId={teamId} />
                </Fragment>
            );
        case MODAL_TYPE.GRAPHS:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <QuestionnaireResultsPage folderId={folderId} teamId={teamId} />
                </Fragment>
            );
        case MODAL_TYPE.USER_NOTES:
            return (
                <Fragment>
                    {!tabView && <PatientBarModal folderId={folderId} teamId={teamId} />}
                    <UserNotes folderId={folderId} />
                </Fragment>
            );
        default:
            return (
                teamPreferences && <PatientTabView renderElement={renderElement} folderId={folderId} teamId={teamId} navList={navList} tabView={tabView}/>
            );
        }
    };

    return (
        <div>
            <ReferralsProvider folderId={folderId} teamId={teamId} >
                {renderElement(tab)}
            </ReferralsProvider>
        </div>
    );
};

const useNavList = (teamPreferences: TeamPreferences) => {
    return useMemo(() => {
        if (!teamPreferences) {
            return [
                MODAL_TYPE.PROMS,
                MODAL_TYPE.RESOURCES,
                MODAL_TYPE.SETTINGS,
            ];
        }

        const navList = [];

        teamPreferences.show_appointments && navList.push(MODAL_TYPE.APPOINTMENTS);
        navList.push(MODAL_TYPE.PROMS);
        navList.push(MODAL_TYPE.RESOURCES);
        teamPreferences.show_messages && navList.push(MODAL_TYPE.MESSAGES);
        teamPreferences.show_referrals && navList.push(MODAL_TYPE.REFERRALS);
        teamPreferences.show_goals && navList.push(MODAL_TYPE.GOALS);
        teamPreferences.show_communications_tab && navList.push(MODAL_TYPE.COMMUNICATIONS);
        teamPreferences.show_graphs && navList.push(MODAL_TYPE.GRAPHS);
        teamPreferences.show_user_notes && navList.push(MODAL_TYPE.USER_NOTES);

        navList.push(MODAL_TYPE.SETTINGS);

        return navList;
    }, [teamPreferences]);
};

export default PatientModalView;
