import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { Column,Row } from 'ui/index';
import { TabNavigator, NavTab } from 'ui/tab/navigator';

export default class MyTeamPage extends BasePage {
    constructor (props) {
        super(props);

        this.baseRoute = '/clinical_portal/my-team';
    }

    pageTitle () {
        return _`My Team | PHR Clinical Portal`;
    }

    render () {

        return (
            <div className='page'>
                <Row>
                    <Column sm="6" md="7" lg="8">
                        <ul className="breadcrumb">
                            <li className="active">
                                My Team
                            </li>
                        </ul>
                    </Column>
                </Row>
                <Row>
                    <Column sm="12">
                        <TabNavigator
                            currentRoute={this.props.location.pathname}
                            tabPosition='top'
                            tabStyle='tab'
                            routes={this.props.routes}
                        >
                            <NavTab title={_`Patients`}
                                route={`${this.baseRoute}/patients`}
                                prefixMatch={true} />
                            <NavTab title={_`Overdue Questionnaires`}
                                route={`${this.baseRoute}/overdue-questionnaires`}
                                prefixMatch={true} />
                            <NavTab title={_`Reports`}
                                route={`${this.baseRoute}/reports`}
                                prefixMatch={true} />
                            <NavTab title={_`User-Feedback`}
                                route={`${this.baseRoute}/user-feedback`}
                                prefixMatch={true} />
                            <NavTab title={_`Worklists`}
                                route={`${this.baseRoute}/worklists`}
                                prefixMatch={true} />
                        </TabNavigator>
                    </Column>
                </Row>
                <Row>
                    <Column sm="12">
                        {this.props.children}
                    </Column>
                </Row>
            </div>
        );
    }
}
