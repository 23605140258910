import { BulkOperationCostEstimator } from './BulkOperationCostEstimator';
import { CostEstimateValue, CostEstimateStatusType } from './CostEstimateValue';
import { SendSmsState } from '../user/sms/create/validator';
import { FolderSelectionState } from 'components/dataSource/FolderSelectionState';

export class BulkOperationSmsCostEstimator extends BulkOperationCostEstimator {
    private actionState: SendSmsState;

    constructor(actionState: SendSmsState, selectionState: FolderSelectionState, onCostEstimateChange: (newCostEstimate: CostEstimateValue) => void) {
        super(selectionState, onCostEstimateChange);

        this.actionState = actionState;
    }
    
    protected estimateCostImpl(): Promise<CostEstimateValue>
    {
        const getSmsCost = (): number => {
            const price: number = 0.03;
            const selected: number = this.getSelectionState().getSelectionCount()
            const characterValue: number = this.actionState.message.length / 160;
            const totalValue: number = (price * selected) * Math.ceil(characterValue);

            return totalValue;
        }
        
        return new Promise<CostEstimateValue>((resolve) => resolve({
            status: CostEstimateStatusType.COMPLETE,
            value: getSmsCost(),
        }));
    }
}