import { Dispatch } from 'react';
import { Action, actionCleanJobs, actionContextsReceived, actionResultsReceived } from './actions';
import { getQuestionnaireResults } from 'features/patient/questionnaire-results/getQuestionnaireResults';
import { getActivePathwayLabels } from 'features/pathway-labels/getActivePathwayLabels';
// import {
//     getActivePathwayLabels,
//     getQuestionnaireResults,
// } from './mockedData';

export enum ASYNC_JOB_NAME {
    LOAD_RESULTS = 'LOAD_RESULTS',
    LOAD_CONTEXTS = 'LOAD_CONTEXTS',
}
export type AsyncJob =
    | { type: ASYNC_JOB_NAME.LOAD_RESULTS; payload: { folderId: number; teamId: number; context: string } }
    | { type: ASYNC_JOB_NAME.LOAD_CONTEXTS; payload: { folderId: number; teamId: number } }

interface PrevData {
    context: string;
    isInProgress: boolean;
}
const loadResults = (dispatch: Dispatch<Action>, { context, folderId, teamId }, prev: PrevData) => {
    let isInProgress = true;
    if(prev?.isInProgress && prev?.context === context) {
        return;
    }
    getQuestionnaireResults({ pathwayContext: context, folderId, teamId, })
        .then((results) => {
            isInProgress = false;
            dispatch(actionResultsReceived({ results, context }));
        })
        .catch(() => {
            isInProgress = false;
        });

    return (): PrevData => {
        return {
            context,
            isInProgress,
        };
    };
};
const loadContexts = (dispatch, { folderId, teamId }) => {
    let cancelled = false;
    getActivePathwayLabels({ folderId, teamId })
        .then((results) => {
            if(cancelled) {
                return;
            }
            dispatch(actionContextsReceived(results));
        });

    return () => {
        cancelled = true;
    };
};

export const asyncJobsHandlers: Record<ASYNC_JOB_NAME, (dispatch: Dispatch<Action>, payload?: any, dataFromPrev?: any) => any> = {
    [ASYNC_JOB_NAME.LOAD_RESULTS]: loadResults,
    [ASYNC_JOB_NAME.LOAD_CONTEXTS]: loadContexts,
};

const purge = {};
export const processAsyncJobs = (jobs: AsyncJob[], dispatch: Dispatch<Action>) => {
    jobs.forEach((job) => {
        const prev = purge[job.type]?.();
        const prevHandler = asyncJobsHandlers[job.type](dispatch, job.payload, prev);
        purge[job.type] = prevHandler ? prevHandler : purge[job.type];
    });
    dispatch(actionCleanJobs());
};
