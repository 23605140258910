import React from 'react';
import {Column, Row} from 'ui';

import { BulkOperationReviewProps } from './models/bulkOperationHistoryModels';

const BulkOperationPifuReview = ({ bulkOperationData, bulkOperationAction }: BulkOperationReviewProps): JSX.Element => {
    return (
        <div className="action-preview-body">
            <Row className="action-preview-container">
                <Column sm={12} className="action-preview-component">
                    <h4>{bulkOperationAction.content.name}</h4>
                    {'description' in bulkOperationAction.content ? (
                        <div className='pifu-preview'>
                            <p><strong>Description:</strong></p>
                            <p>{bulkOperationAction.content.description}</p>
                        </div>
                    ) : null}
                </Column>
            </Row>
        </div>
    );
};

export default BulkOperationPifuReview;
