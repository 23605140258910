import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'services/i18n';
import './alert-boxes.scss';
/**
 * Scroll to the first alert box.
 */
export function scrollToFirstAlertBox () {
    const elem = document.querySelector('.alert-box-scroll-target');
    if (elem) {
        elem.scrollIntoView();
    }
}

/**
 * Shows an alert box with a spinner to indicate loading.
 * If any child content is specified, it is shown inside the alert box.
 * Otherwise, a default block of text is shown.
 *
 * Props (data):
 * - visible = Boolean. Optional. If truthy or undefined, the alert box will be visible. If false or null, it will be hidden.
 */
export const Loading: FC<any> = (props) => {
    const { show, children } = props;
    // Order is important for Babel
    if (show !== undefined && !show) {
        return (<noscript />);
    }
    return (
        <div className='alert alert-info alert-box-scroll-target alert-box-loading'>
            <span>
                <i className="fas fa-spinner fa-spin" />
                &nbsp;&nbsp;
                {children || 'Loading. Please wait...'}
            </span>
        </div>
    );
};

/**
 * Shows an alert box to indicate that there is no data to show.
 * If any child content is specified, it is shown inside the alert box.
 * Otherwise, a default block of text is shown.
 *
 * Props (data):
 * - visible = Boolean. Optional. If truthy or undefined, the alert box will be visible. If false or null, it will be hidden.
 */
export const NoResults: FC<any> = ({ show, children }) => {
    // Order is important for Babel
    if (show !== undefined && !show) {
        return (<noscript />);
    }
    return (
        <div>
            <span>
                {children || 'No results to display.'}
            </span>
        </div>
    );
};

/**
 * Shows an alert box indicating that saving was successful.
 * If any child content is specified, it is shown inside the alert box.
 * Otherwise, a default block of text is shown.
 *
 * Props (data):
 * - visible = Boolean. Optional. If truthy or undefined, the alert box will be visible. If false or null, it will be hidden.
 */
export const Saved: FC<any> = ({ show, children }) => {
    // Order is important for Babel
    if (show !== undefined && !show) {
        return (<noscript />);
    }
    return (
        <div className='alert alert-success alert-box-scroll-target alert-box-saved'>
            <span className='glyphicon glyphicon-floppy-saved' />
            &nbsp;&nbsp;
            {children || 'Saved successfully.'}
        </div>
    );
};

/**
 * Shows an alert box specifying that an error occurred during loading.
 * If any child content is specified, it is shown inside the alert box.
 * Otherwise, a default block of text is shown.
 *
 * Props (data):
 * - visible = Boolean. Optional. If truthy or undefined, the alert box will be visible. If false or null, it will be hidden.
 */
export const LoadingError: FC<any> = ({ show, children }) => {
    // Order is important for Babel
    if (show !== undefined && !show) {
        return (<noscript />);
    }
    return (
        <div className='alert alert-warning alert-box-scroll-target alert-box-loading-error'>
            <span className='glyphicon glyphicon-warning-sign' />
            &nbsp;&nbsp;
            {children || _`Sorry. An error occurred while loading.`}
        </div>
    );
};

/**
 * Shows an alert box specifying that an error occurred during saving.
 * If any child content is specified, it is shown inside the alert box.
 * Otherwise, a default block of text is shown.
 *
 * Props (data):
 * - visible = Boolean. Optional. If truthy or undefined, the alert box will be visible. If false or null, it will be hidden.
 */
export const SavingError: FC<any> = ({ show, children }) => {
    // Order is important for Babel
    if (show !== undefined && !show) {
        return (<noscript />);
    }
    return (
        <div className='alert alert-warning alert-box-scroll-target alert-box-saving-error'>
            <span className='glyphicon glyphicon-floppy-remove' />
            &nbsp;&nbsp;
            {children || 'Sorry. An error occurred while saving.'}
        </div>
    );
};

export const Custom: FC<any> = (props) => {

    const {
        children,
        boxStyle
    } = props;

    const className = classNames({
        'alert':true,
        'alert-box-scroll-target':true,
        'alert-warning': boxStyle === 'warning',
        'alert-light': boxStyle === 'light',
        'alert-danger': boxStyle === 'danger',
    });

    return (
        <div className={className}>
            {children}
        </div>
    );
};

Custom.propTypes = {
    children: PropTypes.element.isRequired,
    boxStyle: PropTypes.oneOf([
        'warning',
        'light',
        'danger'
    ]).isRequired
};
