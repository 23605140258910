import { get } from 'services/api';
import { Appointment } from 'models/compositions/Appointment';
import { Demographics } from 'models/compositions/Demographics';
import { Composition } from 'models/Composition';
import { Referral } from 'models/compositions/Referral';
import { dateSpan } from 'common/datetime/DateTime';
import DataStore from 'services/data-store';
import {
    APPOINTMENT_MODE,
    appointmentModeLabels,
    getAppointmentMode,
    getAppointmentSpeciality,
    getAppointmentSpecialityCode,
    getClinicCode,
    getHospitalNumber,
    isStatusProcessing
} from 'services/appointment-helpers';

export interface AttendAnywhereItem {
    name: string;
    dateOfBirth: string;
    nhsNumber: string;
    hospitalNumber: string;
    triageDecision: string;
    speciality: string;
    clinicCode: string;
    date: string;
    uuid: string;
    folderId: number;
    teamId: number;

    // mutable state;
    appointmentModeOptions: {value: APPOINTMENT_MODE; label: string; unselectable?: boolean}[];
    type: APPOINTMENT_MODE;
    prevType: APPOINTMENT_MODE;
    sentType: APPOINTMENT_MODE;
    processing: boolean;
}

export interface ExtendedComposition<T, A> extends Composition<T> {
    relatedCompositions: A;
}
interface RelatedCompositions {
    demographics: Composition<Demographics>[];
    referral: Composition<Referral>[];
}
export type ExtendedAppointmentComposition = ExtendedComposition<Appointment, RelatedCompositions>

export const optionsAppointmentMode = [
    {
        value: APPOINTMENT_MODE.TELEPHONE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.TELEPHONE),
    },
    {
        value: APPOINTMENT_MODE.TELEPHONE_REQUESTED,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.TELEPHONE_REQUESTED),
        unselectable: true,
    },
    {
        value: APPOINTMENT_MODE.ATTEND_ANYWHERE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.ATTEND_ANYWHERE),
    },
    {
        value: APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED),
        unselectable: true,
    },
    {
        value: APPOINTMENT_MODE.FACE_TO_FACE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.FACE_TO_FACE),
    },
    {
        value: APPOINTMENT_MODE.NON_FACE_TO_FACE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.NON_FACE_TO_FACE),
        unselectable: true,
    },
];
export const optionsAppointmentModeForRequested = [
    {
        value: APPOINTMENT_MODE.ATTEND_ANYWHERE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.ATTEND_ANYWHERE),
    },
    {
        value: APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED),
        unselectable: true,
    },
    {
        value: APPOINTMENT_MODE.TELEPHONE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.TELEPHONE),
    },
    {
        value: APPOINTMENT_MODE.TELEPHONE_REQUESTED,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.TELEPHONE_REQUESTED),
        unselectable: true,
    },
    {
        value: APPOINTMENT_MODE.NON_FACE_TO_FACE,
        label: appointmentModeLabels.get(APPOINTMENT_MODE.NON_FACE_TO_FACE),
        unselectable: true,
    },
];

const getAttendAnywhereCollection = async (teamId: string, roleUuid: number): Promise<[ExtendedAppointmentComposition[], number]> => {
    const response = await get(`/teams/${teamId}/worklists/labelled-appointments-worklist?using_role_uuid=${roleUuid}&context=aa_worklist`);

    return [response.message.results, response.message.meta.count];
};

export const getAttendAnywhereList = async (teamId): Promise<AttendAnywhereItem[]> => {
    try {
        const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});
        const [results] = await getAttendAnywhereCollection(teamId, roleUuid);
        return results.map<AttendAnywhereItem>(({ 
            relatedCompositions, 
            content : appointment,
            uuid,
            folder_id: folderId 
        }) => {
            const {
                demographics: [demographics],
                referral: [referral]
            } = relatedCompositions || { demographics: [], compositions: [] };
            const type = getAppointmentMode(appointment.labels);
            const givenName = demographics.content?.preferred_name?.given_name || demographics?.content?.name?.given_name;
            const familyName = demographics?.content?.preferred_name?.family_name || demographics?.content?.name?.family_name;
            const name = [givenName, familyName].filter(Boolean).join(' ');
            const speciality = getAppointmentSpeciality(appointment)?.description || getAppointmentSpecialityCode(appointment);
            const clinicCode = getClinicCode(appointment);
            const hospitalNumber = getHospitalNumber(demographics.content);
            const isNotRequestedMode = type !== APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED && type !== APPOINTMENT_MODE.TELEPHONE_REQUESTED;
            const processing = isStatusProcessing(appointment.labels);
            return {
                name: name || '-',
                dateOfBirth: demographics?.content?.date_of_birth || '-',
                nhsNumber: demographics?.content?.identifiers?.nhs_number || '-',
                hospitalNumber: hospitalNumber || '-',
                triageDecision: referral?.content?.triage_decision || '-',
                speciality: speciality || '-',
                clinicCode: clinicCode || '-',
                date: dateSpan(appointment.date, 'nhs_date_with_time') || '-',
                uuid,
                folderId,
                teamId,
                type,
                prevType: void 0,
                sentType: type,
                appointmentModeOptions: isNotRequestedMode ? optionsAppointmentMode : optionsAppointmentModeForRequested,
                processing,
            };
        });
    } catch(e) {
        console.error('[getAttendAnywhereList]', e);
        throw new Error(e);
    }
};

export function groupBy<T, K = string>(key, arr: Array<T>): Map<K, T[]> {
    const map = arr.reduce((acc, item) => {
        if (!acc.has(item[key])) {
            acc.set(item[key], []);
        }
        const array = acc.get(item[key]);
        array.push(item);
        return acc;
    }, new Map<K, T[]>());
    return map;
}
