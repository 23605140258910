import React, { FC } from 'react';
// components
import AdvancedSearchQueryDefiner from 'features/patients/advanced-search/AdvancedSearchQueryDefiner';
import AdvancedSearchQueryNameInput from './AdvancedSearchQueryNameInput';
// hooks
// interfaces
import { AdvancedSearchDefinitionFormProps } from './AdvancedSearchDefinitionForm.interface';

const AdvancedSearchDefinitionForm: FC<AdvancedSearchDefinitionFormProps> = (
    props,
) => {
    return (
        <div className="advanced-search-query-definition">
            <AdvancedSearchQueryNameInput
                searchRequest={props.searchRequest}
                validator={props.validator}
            />
            <AdvancedSearchQueryDefiner
                documentConfigs={props.documentConfigs}
                queryDefinition={props.searchRequest.queryDefinition}
                onRemove={undefined}
                isRootQuery={true}
                validator={props.validator}
                customTitle={'Find patients with...'}
            />
        </div>
    );
};

export default AdvancedSearchDefinitionForm;
