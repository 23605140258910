import React from 'react';
import ServerFetch  from 'server-fetch';

const CoopwebTemplate = ({children}) => {
    ServerFetch.setPortal('coop');

    return (
        <div className="container-fluid">
            <div className="coop-web">
                {children}
            </div>
        </div>
    );
};

export default CoopwebTemplate;
