import React from 'react';
import { PatientPathway } from 'features/timeline-react/PatientPathway';

export const PatientPathwayPage = ({
    params,
    patientFolderId
}) => {
    const folderId = patientFolderId;
    return (
        <PatientPathway
            params={params}
            patientFolderId={folderId}
        ></PatientPathway>
    )
}

export default PatientPathwayPage;
