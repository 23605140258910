import React, { useState } from 'react';
import _ from 'services/i18n';
import { AntenatalAppointmentForm } from 'features/antenatal/appointment-form/AntenatalAppointmentForm';
import Modal, { ModalButton } from 'common/ui/modal';

export function AntenatalAppointmentModal({
    patientBanner,
    ubrn,
    bookingPassword,
    isReadOnly = false,
    onClose,
    onSubmit,
    busy,
}: {
    patientBanner?: any;
    ubrn?: string;
    bookingPassword?: string;
    isReadOnly?: boolean;
    busy?: boolean;
    onClose: () => void;
    onSubmit: ({ubrn, bookingPassword}: {ubrn: string, bookingPassword: string}) => void;
}) {
    const isEdit = !!ubrn;
    const [isSubmitFromOutside, setIsSubmitFromOutside] = useState(false);
    const [disable, setDisable] = useState(true);

    const handleFormChange = ({
        disable,
        isSubmitFromOutside,
    }) => {
        console.log('form change', disable, isSubmitFromOutside);
        setIsSubmitFromOutside(isSubmitFromOutside);
        setDisable(disable);
    };

    const buttons: ModalButton[] = [];
    if(!isReadOnly) {
        buttons.push({
            classNames: 'btn ' + (disable ? 'btn-default' : 'btn-primary'),
            callback: () => setIsSubmitFromOutside(true),
            label: _(['Submit'])
        })
    }
    if(onClose) {
        buttons.push({
            label: _(['Cancel']),
            callback: () => onClose()
        })
    }

    return <Modal
        title={isEdit ? _`Edit appointment details` : _`Create appointment details` }
        onClose={onClose}
        buttons={buttons}
        busy={busy}
        patientBanner={patientBanner}
    >
        <AntenatalAppointmentForm
            ubrn={ubrn}
            bookingPassword={bookingPassword}
            isReadOnly={isReadOnly}
            isSubmitFromOutside={isSubmitFromOutside}
            onChange={handleFormChange}
            onSubmit={onSubmit}
        />
    </Modal>;
}
