import React, { FC, Reducer, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import PageTitle from 'page-title';
import { Line } from 'common/ui/line-graph/LineGraph';
import _ from 'services/i18n';
import './QuestionnaireResultsPage.scss';
import {
    FormSelectItem,
    OptionItem
} from './inline-selector-form/InlineSelectorsForm';
import { Loading } from 'common/ui/alert-boxes';
import { ASYNC_JOB_NAME, AsyncJob, processAsyncJobs } from './asyncHandlers';
import { Action, actionValueChanged } from './actions';
import { reducer } from './QuestionnaireResultsPage.reducer';
import { QuestionnaireResult } from 'models/QuestionnaireResult';
import { QuestionnaireResultComponent } from 'features/patient/questionnaire-results/questionnaire-result/QuestionnaireResult';
import { TeamPreferencesContext } from 'common/TeamPreferencesContext';

export enum SELECTORS {
    CONTEXT = 'context',
    QUESTIONNAIRE = 'questionnaire',
    MEASURE = 'measure',
}
export interface State {
    teamId?: number;
    folderId?: number;
    graphEdgeValues: {
        max: number;
        min: number;
    };
    rawResults: Map<string, Record<string, QuestionnaireResult[]>>;
    contexts: OptionItem[];
    items: ResultsItem[];
    errorMessage: string;
    loading: boolean;
    asyncJobs: AsyncJob[];
    teamPreferences: {};
}
export interface ResultsItem {
    selectors: Map<SELECTORS, FormSelectItem>;
    initialSectionName: string;
    lines: Line[];
    timelines: Line[];
    axisY?: { min: number };
    linesLoading: boolean;
    tableData?: {date: number; value: string}[];
    boundaryValues?: number[];
}

const initialState: State = {
    rawResults: new Map(),
    graphEdgeValues: {
        max: 0,
        min: 0,
    },
    contexts: [],
    items: [],
    loading: true,
    errorMessage: '',
    asyncJobs: [],
    teamPreferences: undefined
};

const useQuestionnaireResult = (initialState: State) => {
    const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);
    // TODO use const teamPreferences = useTeamPreferences();
    const prefs = useContext(TeamPreferencesContext);
    const {
        asyncJobs,
        teamId,
        items,
        folderId,
        contexts,
        loading,
        errorMessage,
        graphEdgeValues
    } = state;

    if (prefs.portal && prefs.portal.graph_results && prefs.portal.graph_results.length) {
        const items = prefs.portal.graph_results.map((graphResult) => {
            const {
                context,
                questionnaire,
                measure,
                minValue,
                maxValue,
            } = graphResult;

            return {
                ...graphResult,
                axisY: { min: minValue, max: maxValue },
                lines: [],
                linesLoading: true,
                timelines: [],
                initialSectionName: questionnaire,
                selectors: generateSelectData({
                    context,
                    questionnaire,
                    measure,
                })
            };
        });
        initialState.items = items;
    }

    const onChange = useCallback(({ name, value }, id) => {
        dispatch(actionValueChanged({ name, value, id }));
    }, []);

    useEffect(() => {
        processAsyncJobs([{ type: ASYNC_JOB_NAME.LOAD_CONTEXTS, payload: { teamId, folderId } }], dispatch);
    }, [teamId, folderId]);

    useEffect(() => {
        if(!asyncJobs?.length) {
            return;
        }
        processAsyncJobs(asyncJobs, dispatch);
    }, [asyncJobs]);


    return {
        items,
        contexts,
        loading,
        errorMessage,
        graphEdgeValues,
        onChange,
    };
};

export const QuestionnaireResultsPage: FC<{teamId: number; folderId: number}> = ({ teamId, folderId }) => {
    initialState.folderId = folderId;
    initialState.teamId = teamId;

    const {
        onChange,
        items,
        contexts,
        loading,
        errorMessage,
        graphEdgeValues: { max, min },
    } = useQuestionnaireResult(initialState);

    useEffect(() => {
        PageTitle.setTitle(
            'Patient Graphs | PHR Clinical Portal'
        );
    }, []);

    const newItems = useMemo(() => {
        return items.map((item) => {
            item.selectors.get(SELECTORS.CONTEXT).options = contexts.reduce((acc, option) => {
                acc.set(option.value, option);
                return acc;
            }, new Map());
            return item;
        });
    }, [items, contexts]);

    return (
        <QuestionnaireResultsView
            onChange={onChange}
            axisXMin={min}
            axisXMax={max}
            items={newItems}
            loading={loading}
            errorMessage={errorMessage}
        />
    );
};

interface QuestionnaireResultsProps {
    items: ResultsItem[];
    onChange: ({ name, value }, i) => void;
    loading: boolean;
    errorMessage: string;
    axisXMin: number;
    axisXMax: number;
}
const QuestionnaireResultsView: FC<QuestionnaireResultsProps> = ({ items = [], onChange, loading, errorMessage, axisXMin, axisXMax }) => {
    const onChangeCallbackGenerator = useCallback((idx) => {
        return ({ name, value }) => {
            return onChange({ name, value }, idx);
        };
    }, [onChange]);

    const axisX = useMemo(() => {
        return { min: axisXMin, max: axisXMax };
    }, [axisXMax, axisXMin]);

    if(loading) {
        return <Loading/>;
    }

    if(errorMessage) {
        return  (
            <div className={'questionnaire-results'}>
                <h3 className={'questionnaire-results__header'}>{_`Questionnaire results`}</h3>
                <h4>{errorMessage}</h4>
            </div>
        );
    }

    return (
        <div className={'questionnaire-results'}>
            <h3 className={'questionnaire-results__header'}>{_`Questionnaire results`}</h3>
            {!items.length && <h4>{_`No data`}</h4>}
            {items.map(({
                lines,
                timelines,
                selectors,
                linesLoading,
                axisY,
                initialSectionName,
                tableData,
                boundaryValues
            }, i) => {
                const selectorItems = Array.from(selectors.values());
                const onChangeCallback = onChangeCallbackGenerator(i);
                return (
                    <QuestionnaireResultComponent
                        key={i}
                        axisX={axisX}
                        axisY={axisY}
                        lines={lines}
                        timelines={timelines}
                        selectorItems={selectorItems}
                        loading={linesLoading}
                        questionnaireName={initialSectionName}
                        onChange={onChangeCallback}
                        tableData={tableData}
                        boundaryValues={boundaryValues}
                    />
                );
            })}
        </div>
    );
};


// helpers
function generateSelectData ({ context = '', questionnaire = '', measure = '' } = {}) {
    return new Map([
        [SELECTORS.CONTEXT, {
            label: _`Pathway/Context`,
            value: context,
            name: 'context',
            placeholder: _`-- Please select pathway --`
        }],
        [SELECTORS.QUESTIONNAIRE, {
            options: new Map(),
            label: _`Questionnaire`,
            value: questionnaire,
            name: 'questionnaire',
            placeholder: _`-- Please select questionnaire --`
        }],
        [SELECTORS.MEASURE, {
            options: new Map(),
            label: _`Measure`,
            value: measure,
            name: 'measure',
            placeholder: _`-- Please select measure --`
        }]
    ]);
}
