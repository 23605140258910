import './InlineSelectorsForm.scss';
import React, { FC, useCallback } from 'react';
import { DropdownSelect, DropdownSelectOption } from 'common/ui/dropdown/DropdownSelect';

export interface OptionItem {
    value: string;
    label: string;
    disabled?: boolean;
}
export interface FormSelectItem {
    options?: Map<string, OptionItem>;
    label: string;
    value: string;
    name: string;
    placeholder?: string;
}
interface ResultsFormProps {
    onChange: ({ name, value }) => void;
    items: FormSelectItem[];
}

export const InlineSelectorsForm: FC<ResultsFormProps> = (props) => {

    const {
        onChange,
        items,
    } = props;

    const onChangeCallback = useCallback(({ target: { name, value } }) => {
        onChange({ name, value });
    }, [onChange]);

    if (!items?.length) {
        return null;
    }

    return (
        <div className={'inline-form'}>
            {items.map((item, i) => {

                const {
                    options,
                    label,
                    value,
                    name,
                    placeholder
                } = item;

                const optionsArray = Array.from(options.values());

                return (
                    <div key={i} className={'inline-form_item'}>
                        <label>{label}</label>
                        <DropdownSelect
                            placeholder={placeholder}
                            label={options.get(value)?.label}
                            name={name}
                            onChange={onChangeCallback}
                        >
                            {optionsArray?.map(({ label, value }) => {
                                return (
                                    <DropdownSelectOption
                                        key={value}
                                        value={value}
                                    >
                                        {label}
                                    </DropdownSelectOption>
                                );
                            })}
                        </DropdownSelect>
                    </div>
                );
            })}
           
        </div>
    );
};
