import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { ModalDialog } from 'common/modalDialog';

const defaults = {
    title: 'Warning',
    message: 'Are you sure?',
}

type ConfirmationModalProps = {
    title: string,
    message: string | string[],
    onAnswer: Function,
}
const ConfirmationModalElement = (props: ConfirmationModalProps) => {
    const title: string = props.title || defaults.title;
    const messages: string[] = Array.isArray(props.message) ? props.message : [props.message || defaults.message];

    const buttons = (
        <Fragment>
            <button className="btn btn-default  btn-primary" onClick={() => props.onAnswer(true)}>Confirm</button>
            <button className="btn btn-default" onClick={() => props.onAnswer(false)}>Cancel</button>
        </Fragment>
    )
    
    const children = messages.map((message, index) => <p key={index}>{message}</p>)

    return (
       <ModalDialog onClose={() => props.onAnswer(false)} buttons={buttons} title={title} children={children}/>
    )
}

class ConfirmationModal {

    private title: string = 'Warning';
    private elementId: string = 'confirmation-dialog';

    /**
     * Show Confirmation Dialog
     * @param {string} title a string
     * @param {string | string[]} message to be displayed in modal body
     * @returns {promise boolean} True or False value based on user input
     * @example show('This is a dangerous operation, are you sure?')
     */
    async show(message: string | string[]): Promise<boolean> {
        const containerElement: HTMLElement = document.getElementById(this.elementId);

        if (!containerElement) {
            return true;
        }

        const messages: string[] = Array.isArray(message) ? message : [message || defaults.message];

        return new Promise((resolve) => {
            const onAnswer = (value: boolean): void => {
                resolve(value);
                this.close();
            }

            const element = ConfirmationModalElement({ title: this.title, message: messages, onAnswer: onAnswer });
            ReactDOM.render(element, containerElement);
        });
    }

    private close(): void {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.elementId));
    }
}

const confirmationModal = new ConfirmationModal();

export default confirmationModal;
