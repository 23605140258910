import React from 'react';
import { Link } from 'react-router';
import _ from 'services/i18n';
import { MessageCreateForm } from './form';

const MessageCreateView = (props) => {
    if (props.messageType == 'welcome') {
        return (
            <div className="messages-create">
                <h3 className="spacer20">{_`Add New Message`}</h3>
                <MessageCreateForm {...props} />
            </div>
        );
    }
    return (
        <div className="messages-create">
            <div className="top-bar">
                <Link to={props.backRoute}>
                    {_`Back to messages list`}</Link>
            </div>

            <h3 className="spacer20">{_`Add New Message`}</h3>
            <MessageCreateForm {...props} />
        </div>
    );
};

export default MessageCreateView;
