import _ from 'services/i18n';

export const siteLinks = [
    {link: '/admin_portal', label: _`Dashboard`},
    {link: '/admin_portal/pathway-editor', label: _`Pathway Editor`, teamPref: 'features.edit-content'},
    {link: '/admin_portal/csv-import', label: _`CSV Import`, teamPref: 'features.csv_import'},
    {link: '/admin_portal/questionnaire-templates', label: _`Questionnaire Templates`, requireTeamId: true, services: ['CAMHS', 'MSK', 'LMSU']},
    {link: '/admin_portal/editor', label: _`Editor`},
    {link: '/admin_portal/members', label: _`Members`, requireTeamId: true},
    {link: '/admin_portal/pre-invites', label: _`Pre-invite Importer`, requireTeamId: true, services: ['MSK']},
    {link: '/admin_portal/teams', label: _`Teams`, requireOrgId: true},
    {link: '/admin_portal/resources', label: _`Resource Manager`, requireTeamId: true, services: ['CAMHS', 'MSK', 'LMSU']},
    {link: '/admin_portal/privacy-notices', label: _`Privacy Policies`, requireTeamId: true},
    {link: '/admin_portal/predefined-messages', requireTeamId: true, label: _`Predefined Messages`},
    {link: '/admin_portal/predefined-sms', requireTeamId: true, label: _`Predefined SMS Templates`},
    {link: '/admin_portal/profile', services: ['MSK', 'LMSU'], label: _`My Profile`},
    {link: '/admin_portal/logout', label: _`Log Out`}
];
