import React from 'react';
import { useI18N } from 'common/i18n/useI18N';
import { GridRow, GridCell } from 'common/ui/grid';
import ButtonGroup from 'components/button-group';

export const AppointmentGridRow = ({
    appointmentListItem,
}) => {
    const _ = useI18N();

    const links = [
        { route: appointmentListItem.appointmentLink, label: _`View` }
    ];


    return (
        <GridRow>
            <GridCell><span className="no-wrap">{appointmentListItem.pathwayNames}</span></GridCell>
            <GridCell>{appointmentListItem.type}</GridCell>
            <GridCell>{appointmentListItem.location}</GridCell>
            <GridCell>{appointmentListItem.consultationMechanism}</GridCell>
            <GridCell>{appointmentListItem.dateTime}</GridCell>
            <GridCell>{appointmentListItem.status}</GridCell>
            <GridCell>
                <ButtonGroup
                    size="xs"
                    links={links}
                />
            </GridCell>
        </GridRow>
    );
};
