import React, {FC, useEffect, useState} from 'react';
import { MFAOption } from 'models/mfa';
import { mfaSetupService, QRCodeDetails } from 'services/mfa-setup.service';
import DataStore from 'services/data-store';
import { OTPEntry } from './OTPEntry';

export type Mode = 'view' | 'setup' | 'confirm';

export const MFAOptionDisplay: FC<{
        initialOption: MFAOption,
        onVerified: (updatedOptions: MFAOption[]) => unknown,
        onDeleted: (updatedOptions: MFAOption[]) => unknown,
        onMadePreferred: (updatedOptions: MFAOption[]) => unknown
    }> = ({
    initialOption,
    onVerified,
    onDeleted,
    onMadePreferred
}) => {
    const [option, setOption] = useState<MFAOption>(initialOption);
    const [methodDetails, setMethodDetails] = useState<string>( 'method_details' in option ? option.method_details : '');
    const [qrCodeDetails, setQrCodeDetails] = useState<QRCodeDetails>(null);
    const [mode, setMode] = useState<Mode>(option.id === -1 ? 'setup' : 'view');
    const currentUser = DataStore.get('currentUser');
    const [otpCode, setOTPCode] = useState<string>('');
    const [otpError, setOTPError] = useState<string>('');
    const [codeSending, setCodeSending] = useState<boolean>(false);
    const onOTPEntryUpdate = (otpCode: string, complete: boolean) => {
        setOTPCode(complete ? otpCode : '');
    }

    const startSetup = async (): Promise<MFAOption> => {
        switch (option.mfa_method) {
            case "authenticator":
                return mfaSetupService.startUserMFAOptionSetupAuthenticator()
                    .then(optionAndURL => {
                        setQrCodeDetails(optionAndURL.qrCodeDetails);
                        return optionAndURL.mfaOption;
                    });
            case "email":
                return mfaSetupService.startUserMFAOptionSetupEmail();
            case "sms":
                return mfaSetupService.startUserMFAOptionSetupSMS();
        }
    }

    const resumeSetup = async (): Promise<MFAOption> => {
        setQrCodeDetails(null);
        switch (option.mfa_method) {
            case "authenticator":
                return mfaSetupService.resumeUserMFAOptionSetupAuthenticator(option)
                    .then(optionAndURL => {
                        setQrCodeDetails(optionAndURL.qrCodeDetails);
                        return optionAndURL.mfaOption;
                    });
            case "email":
                return mfaSetupService.resumeUserMFAOptionSetupEmail(option);
            case "sms":
                return mfaSetupService.resumeUserMFAOptionSetupSMS(option, methodDetails);
        }
    }

    useEffect(() => {
        if (option.status === 'pending'
          && option.id === -1
          && mode === 'setup') {
            startSetup().then(setOption);
        }
    }, [option, mode])

    useEffect(() => {
        if (option.status === 'pending'
            && option.mfa_method === 'authenticator'
            && option.id !== -1
            && mode === 'setup'
            && !qrCodeDetails
        ) {
            mfaSetupService.getQRCodeUrl(option).then(setQrCodeDetails);
        }
    }, [option, mode])

    const enableRequestCode = () => setTimeout(() => setCodeSending(false), 1000);

    const onSubmit = (evt) => {
        evt.preventDefault();
        setOTPError('');
        if (mode === 'setup') {
            setCodeSending(true);
            resumeSetup()
                .then(setOption)
                .then(() => setMode('confirm'))
                .then(enableRequestCode);
        } else if (mode === 'confirm') {
            mfaSetupService.confirmUserMFAOptionSetup(option, otpCode, false)
                .then(onVerified)
                .catch((response) => {
                    if (response.status === 400) {
                        setOTPError('The code you entered was not accepted. Please try again.')
                    } else {
                        setOTPError('An error occurred. Please try again.')
                    }
                });
        }
    }

    const deleteOption = () => mfaSetupService.deleteUserMFAOption(option.id).then(onDeleted);

    const onSetupCancel = () => {
        if (option.id === -1) {
            mfaSetupService.listUserMFAOptions().then(onDeleted);
        } else {
            setMode('view');
        }
    }

    const makePreferred = () => {
        mfaSetupService.makeUserMFAOptionPreferred(option.id).then(onMadePreferred);
    }

    const requestNewCode = () => {
        setCodeSending(true);
        mfaSetupService.requestNewConfirmationCode(option)
            .then(enableRequestCode);
    }

    return (
        <form className='panel panel-default' onSubmit={onSubmit}>
            <div className='panel-heading hflow-vcenter'>
                <h2 className='panel-title'>Your <strong>{option.mfa_method}</strong> MFA option is <strong>{option.status}</strong></h2>
                {option.status === 'confirmed' && <span className='push-right'>{option.preferred
                    ? <strong>Preferred</strong>
                    : <button className='btn btn-link' onClick={makePreferred}>Make this my preferred option</button>}
                </span>}
            </div>
            <div className='panel-body-footer-group'>
                <div className='panel-body'>
                    {mode === 'view' && <>
                        {option.mfa_method === 'email' && <span>MFA codes will be sent to your registered email address <strong>{currentUser.email}</strong></span>}
                        {option.mfa_method === 'sms' && mode === 'view' && <span>MFA codes will be sent to <strong>{methodDetails}</strong></span>}
                        {option.mfa_method === 'authenticator' && <span>MFA codes will be generated by <strong>your authenticator app</strong></span>}
                    </>}
                    {mode === 'setup' && <>
                        {option.mfa_method === 'email' && <span>MFA codes will be sent to your registered email address <strong>{currentUser.email}</strong></span>}
                        {option.mfa_method === 'sms' &&
                            <span>MFA codes will be sent to <input
                                type='tel'
                                value={methodDetails}
                                required={true}
                                onChange={evt => setMethodDetails(evt.target.value)}
                            /></span>}
                        {option.mfa_method === 'authenticator' && <div className='container-fluid'>
                            <p className='row'>Download/use any authenticator app (e.g. <a
                                href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                            >
                                Google Authenticator
                            </a>) on your mobile device.</p>
                            <hr className='row'/>
                            <p className='row'>On your authenticator app, click the <code>+</code> button and when prompted choose to scan the QR code below:</p>
                            {qrCodeDetails && <div className='row'>
                                <img className='col-md-4' width={250} height={250} src={qrCodeDetails.url}
                                     alt='Authenticator App QR Code'/>
                                <div className='col-md-5'>
                                    <div className='panel panel-default'>
                                        <div className='panel-heading'>
                                            If you cannot scan the QR code, enter the following account and key
                                            information into the app instead:
                                        </div>
                                        <div className='panel-body-footer-group'>
                                            <div className='panel-body'>
                                                <p><strong>Account</strong></p>
                                                <p><input className='form-control' type='text' readOnly={true} value={qrCodeDetails.account}/></p>
                                                <p><strong>Key</strong></p>
                                                <p><input className='form-control' type='text' readOnly={true} value={qrCodeDetails.secret}/></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {!qrCodeDetails && <div className='row'>Loading...</div>}
                        </div>}
                    </>}
                    {mode === 'confirm' && <>
                    {option.mfa_method === 'email' &&
                            <span>Please enter the code sent to your registered email address <strong>{currentUser.email}:</strong></span>}
                        {option.mfa_method === 'sms' &&
                            <span>Please enter the code sent to <strong>{methodDetails}:</strong></span>}
                        {option.mfa_method === 'authenticator' &&
                            <span>Please enter the code generated by <strong>your authenticator app:</strong></span>}
                        <hr/>
                        <OTPEntry onOTPEntryUpdate={onOTPEntryUpdate}/>
                        {otpError && <><hr/><div className="hflow-vcenter ant-row-center alert alert-warning"><span>{otpError}</span></div></>}
                    </>}
                </div>
            </div>
            <div className='panel-footer'>
                {mode === 'view' && <>
                    {option.status === 'pending' && <button type='submit' className='btn btn-primary' onClick={() => setMode('setup')}>Resume Setup</button>}
                    <button className='btn btn-warning' onClick={deleteOption}>Delete Option</button>
                </>}
                {mode === 'setup' && <>
                    {<button type='submit' className='btn btn-primary'>Continue</button>}
                    <button className='btn btn-default' onClick={onSetupCancel}>Cancel</button>
                </>}
                {mode === 'confirm' && <>
                    <button type='submit' className='btn btn-primary' disabled={!otpCode}>Verify code {otpCode}</button>
                    {(option.mfa_method === 'sms' || option.mfa_method === 'email') && (
                        <button className='btn btn-default'
                                onClick={requestNewCode}
                                disabled={codeSending}
                        >
                            Re-send code
                        </button>
                    )}
                    <button className='btn btn-default' onClick={() => setMode('setup')}>Cancel</button>
                </>}
            </div>
        </form>
    );
}