import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import moment from 'moment';
// utils
import confirmationModal from 'components/ConfirmationModal';
import { portalToast } from 'ui/toast/Toast';
// interfaces
import { AdvancedSearch } from 'models/AdvancedSearchDefinition';
import { AdvancedSearchServiceProvider } from 'features/patients/advanced-search/AdvancedSearchServiceProvider';
import { Composition } from 'models/Composition';

export const handleExportAggregateQuery = async (
    searchService: AdvancedSearchServiceProvider,
    selectedSearch: Composition<AdvancedSearch>,
): Promise<void> => {
    const toastId = 'exportRequestToast';
    const confirmExport = await confirmationModal.show(
        'You are about to export confidential patient data. This export will be logged. Do you wish to continue?',
    );

    if (!confirmExport) {
        return;
    }

    portalToast.info({
        title: 'Export Requested',
        content: 'Your advanced search export is being processed.',
        toastId,
    });

    const timeStampNow = moment().format('DDMMYYYY-HHmmss');
    const queryName = selectedSearch.content.name
        .replace(/[^a-z0-9]/gi, '_')
        .toLowerCase();
    const fileName = `${queryName} - ${timeStampNow}.csv`;

    searchService
        .exportData(selectedSearch)
        .then((response) => {
            toast.dismiss(toastId);

            portalToast.success({
                title: 'Export Request Success',
                content: 'Your advanced search export has been processed.',
                toastId,
            });

            fileDownload(response, fileName);
        })
        .catch(() => {
            toast.dismiss(toastId);

            portalToast.error({
                title: 'Export Request Failed',
                content: 'Your advanced search export could not be processed.',
                toastId,
            });
        });
};
