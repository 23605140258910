import React, { useCallback, useEffect, useState } from 'react';
import ResourceList from '../../../../../shared/components/resource/list/ResourceList';
import { SearchResourcesComponent } from 'features/resource/SearchResourcesComponent/SearchResourcesComponent';
import { Panel, PanelGroup } from 'react-bootstrap';
import CreateResourceComponent from '../../../../portal-components/components/resource/CreateResourceComponent';
import ResourceCollectionModal from '../../../../portal-components/components/resource/category/create/ResourceCollectionModal';
import ManageResourceModal from '../../../../portal-components/components/resource/manage/ManageResourceModal';
import { resourceCategoryService } from 'services/resourceCategory.service';
import _ from 'services/i18n';
import { browserHistory } from 'react-router';
import usePaginatedResourceList from './usePaginatedResourceList';
import './CollectionContainer.scss';
import { resourcesService } from 'services/resources.service';
import { portalToast } from 'ui/toast/Toast';

type CollectionContainerProps = {
    editResourceId: string;
    categoryId: string;
    currentUser: string;
}

const CollectionContainer = ({ editResourceId, categoryId, currentUser }: CollectionContainerProps) => {
    const [category, setCategory] = useState(null);
    const [displayManageModal, setDisplayManageModal] = useState<boolean>(false);

    const { results, totalResults, currentPage, setCurrentPage, onRefresh } = usePaginatedResourceList(categoryId);

    const loadCategory = useCallback(async () => {
        const categoryResponse = await resourceCategoryService.getByUuid({ uuid: categoryId });
        setCategory(categoryResponse.message);
    }, [categoryId]);

    useEffect(() => {
        loadCategory();
    }, [categoryId, loadCategory]);

    const onCloseModal = () => {
        browserHistory.push('/admin_portal/resources/' + categoryId);
    };

    const addResourcesToCollection = async (resourceIds: (string | number)[]) => {

        if (resourceIds.length > 0) {
            Promise.all(resourceIds.map(async (resourceId) => {

                const resource = await resourcesService.getByUuid({ uuid: resourceId as string });

                const updatedResource = {
                    ...resource.message.content,
                    category: [...resource.message.content.category,
                        {
                            category_id: category.uuid,
                            category_folder_id: category.folder_id,
                            name: category.content.name
                        }
                    ],
                };

                return resourcesService.update({ uuid: resourceId as string, content: updatedResource });
            })).then(() => {
                portalToast.success({
                    title: 'Successfully added resources.',
                    content: 'The resources have been successfully added to the collection.',
                });
                onRefresh();
            }).catch(() => {
                portalToast.error({
                    title: 'Unable to add resources.',
                    content: 'There was an error whilst attempting to add resources to the collection.',
                });
            });
        }
    };
    
    return (
        <>
            <div className='collection-info-header'>
                <div>
                    <h3><b>{category?.content.name}</b></h3>
                    <p>{category?.content?.description}</p>
                    <h4>This collection has {totalResults} resource(s)</h4>
                </div>
                <button
                    title={_`Edit Collection`}
                    className={'btn btn-xs btn-primary icon-button'}
                    onClick={() => setDisplayManageModal(true)}
                >
                    <span>{_`Edit Collection`}</span>
                </button>
            </div>
            <PanelGroup className="resource-panel-group">
                <Panel eventKey="1">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            {_`View included resources`}
                            <i className="fa fa-plus panel-icon" aria-hidden="true"></i>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <ResourceList 
                            categoryId={categoryId} 
                            currentUser={currentUser}
                            results={results}
                            totalResults={totalResults}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            onRefresh={() => onRefresh()}
                            showActions={true}/>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="2">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            {_`Search for resources in library`}
                            <i className="fa fa-plus panel-icon" aria-hidden="true"></i>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <SearchResourcesComponent 
                            onSubmitSelection={addResourcesToCollection}
                            submitSelectionText={_`Add resources to collection`}
                            isIncluded={(resource) => {
                                return results.find((result) => result.uuid === resource.uuid) ? true : false;
                            }}
                            includedColumnHeading={_`Is Included`}
                        />
                    </Panel.Body>
                </Panel>
                <Panel eventKey="3">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            {_`Add new resource`}
                            <i className="fa fa-plus panel-icon" aria-hidden="true"></i>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <CreateResourceComponent 
                            submitLabel={_`Add this resource to the collection`} 
                            categoryId={categoryId}
                            currentUser={currentUser}
                            onRefresh={() => onRefresh()}
                        />
                    </Panel.Body>
                </Panel>
            </PanelGroup>
            {editResourceId && (
                <ManageResourceModal
                    id={editResourceId}
                    categoryId={categoryId}
                    onClose={onCloseModal}
                    currentUser={currentUser}
                />
            )}
            {displayManageModal && (
                <ResourceCollectionModal
                    category={category}
                    onClose={() => setDisplayManageModal(false)}
                    onSubmit={() => {
                        setDisplayManageModal(false);
                        loadCategory();
                    }}
                />
            )}
        </>
    );
};

export default CollectionContainer;
