import React, { FC } from 'react';
import Select from 'react-select';
// components
import AdvancedSearchCriterionRow from 'features/patients/advanced-search/AdvancedSearchCriterionRow';
// hooks
// utils
import {
    AdvancedSearchDocumentField,
    AdvancedSearchQueryCriterion,
    getFieldConfig,
    makeQueryCriterionFromConfig,
} from 'models/AdvancedSearchDefinition';
// interfaces
import { AdvancedSearchCriteriaProps } from './AdvancedSearchCriteria.interface';
// styles
import './AdvancedSearchCriteria.scss';

const AdvancedSearchCriteria: FC<AdvancedSearchCriteriaProps> = (props) => {
    const remainingCriteriaList = props.remainingCriteria.map((criterion) => {
        return {
            label: getFieldConfig(props.documentConfig.fields, criterion.path)
                .name,
            value: criterion,
        };
    });

    return (
        <div className={'advanced-search-query-request-criteria'}>
            {props.criteria.map(
                (criterion: AdvancedSearchQueryCriterion, index: number) => {
                    const config = getFieldConfig(
                        props.documentConfig.fields,
                        criterion.path,
                    );
                    return (
                        <AdvancedSearchCriterionRow
                            key={`${props.documentConfig.documentType}_${criterion.path}_${index}`}
                            fieldConfig={config}
                            documentType={props.documentConfig.documentType}
                            criterion={criterion}
                            onRemove={() => props.removeCriterion(index)}
                            validator={props.validator}
                        />
                    );
                },
            )}
            {remainingCriteriaList.length > 0 && (
                <Select
                    value={null}
                    options={remainingCriteriaList}
                    onChange={(selection: {
                        label: string;
                        value: AdvancedSearchDocumentField;
                    }) => {
                        props.addCriterion(
                            makeQueryCriterionFromConfig(selection.value),
                        );
                    }}
                    placeholder={props.criteria.length == 0 ? 'Filter by' : 'and...'}
                    className={'react-select-input-container'}
                    classNamePrefix={'react-select-input'}
                />
            )}
        </div>
    );
};

export default AdvancedSearchCriteria;
