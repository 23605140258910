import { makeGetRequest, makePostRequest } from './api';
import { MFAOption } from 'models/mfa';

const baseUrl = '/mfa/authenticate'

function buildBaseUrlForOption(option: MFAOption) {
    return `${baseUrl}/${option.mfa_method}/${option.id}`;
}

class MFAAuthenticationService {
    listUserMFAOptions(): Promise<MFAOption[]> {
        const url = `${baseUrl}/options`;
        return makeGetRequest(url).then(result => result.message);
    }

    requestCode(option: MFAOption): Promise<boolean> {
        const url = `${buildBaseUrlForOption(option)}/request-code`;
        return makePostRequest(url).then(result => result.message);
    }

    submitCode(option: MFAOption, otp: string): Promise<boolean> {
        const url = `${buildBaseUrlForOption(option)}/check-code`;
        return makePostRequest(url, { otp: otp }).then(result => result.status === 'OK');
    }
}

export const mfaAuthenticationService = new MFAAuthenticationService();
