import { SortOrder } from 'common/ui/grid';
import {
    AttendAnywhereItem,
    getAttendAnywhereList,
    groupBy
} from 'features/attend-anywhere/AttendAnywhereHelpers';
import React, { Fragment, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { AttendAnywhereWorkList } from 'features/attend-anywhere/AttendAnywhereWorklist';
import _ from 'services/i18n';
import './AttendAnywhere.scss';
import {
    ACTION,
    Actions,
    AsyncJob,
    ItemActions,
    JOB_NAME,
    reducer,
    State
} from 'features/attend-anywhere/AttendAnywhereReducer';
import { useCurrentTeam } from 'common/useCurrentTeam';
import PageTitle from 'page-title';
import { APPOINTMENT_MODE, setAppointmentMode } from 'services/appointment-helpers';


const sortOrder = { date: SortOrder.DESC };
const filterState = {};

function processJobsOfOneItem(jobs: Map<JOB_NAME, AsyncJob[]>) {
    const promises = [];
    jobs.forEach((jobs, jobName) => {
        switch (jobName) {
        case JOB_NAME.SUBMIT_APPOINTMENT_TYPE:
            // eslint-disable-next-line no-case-declarations
            const lastJob: AsyncJob = jobs[jobs.length - 1];
            // eslint-disable-next-line no-case-declarations
            const mode = lastJob.data.appointmentType === APPOINTMENT_MODE.ATTEND_ANYWHERE_REQUESTED ?
                APPOINTMENT_MODE.ATTEND_ANYWHERE :
                lastJob.data.appointmentType === APPOINTMENT_MODE.TELEPHONE_REQUESTED ?
                    APPOINTMENT_MODE.TELEPHONE :
                    lastJob.data.appointmentType;
            promises.push(setAppointmentMode(lastJob.uuid, mode, lastJob.data.folderId, lastJob.data.teamId));
            break;
        }
    });
    return Promise.all(promises);
}
const init = (dispatch: (action: Actions | ItemActions) => void, teamId, pollInterval = 60000) => {

    if(!teamId) {
        return;
    }

    let cancelled = false;
    dispatch({ type: ACTION.LOAD_LIST_START, payload: { loading: true } });
    PageTitle.setTitle(_`Attend Anywhere Worklist | MyPathway Clinical Portal`);
    getAttendAnywhereList(teamId)
        .then((list) => {
            if (cancelled) {
                return;
            }
            dispatch({ type: ACTION.LOAD_LIST_DONE, payload: { list } });
        })
        .catch(() => {
            // stub
        });

    let timeoutId;
    (function poll() {
        timeoutId = window.setTimeout(() => {
            getAttendAnywhereList(teamId)
                .then((list) => {
                    if (cancelled) {
                        return;
                    }
                    dispatch({ type: ACTION.SYNC_LIST_DONE, payload: { list } });
                    poll();
                })
                .catch(() => {
                    if (cancelled) {
                        return;
                    }
                    poll();
                });
        }, pollInterval);
    })();

    return () => {
        window.clearTimeout(timeoutId);
        cancelled = true;
    };
};
const handleAsyncQueue = (dispatch: (action: Actions | ItemActions) => void, queue: AsyncJob[]) => {
    if (!queue?.length) {
        return;
    }
    let jobsCancelled = false;
    const map = groupBy<AsyncJob>('uuid', queue);

    const processingJobs = [];
    map.forEach((arr, key) => {
        const jobs = groupBy<AsyncJob, JOB_NAME>('job', arr);
        processingJobs.push(processJobsOfOneItem(jobs));
    });

    Promise.all(processingJobs)
        .then(() => {
            if (jobsCancelled) {
                return;
            }
        })
        .catch((e) => {
            console.error('[handleAsyncQueue] Error', e);
        })
        .then(() => {
            dispatch({ type: ACTION.CLEAR_ASYNC_QUEUE });
        });

    return () => {
        jobsCancelled = true;
    };
};

const initialState: State = {
    list: new Map(),
    loading: true,
    asyncQueue: [],
    changes: void 0,
};

interface ActionHandlerParams {
    type: 'onchange' | 'onsubmit';
    uuid: string;
    data: {type: APPOINTMENT_MODE | undefined};
}

export const AttendAnywherePage = () => {

    const pollInterval = 60000;
    const team = useCurrentTeam();
    const teamId = team?.teamId;

    const [state, dispatch] = useReducer<Reducer<State, Actions>>(reducer, initialState);
    const handleAction = useCallback(({ type, uuid, data }: ActionHandlerParams) => {
        switch (type) {
        case 'onchange':
            if(data.type) {
                dispatch({ type: ACTION.ITEM_ACTION, payload: { type: ACTION.CHANGE_APPT_TYPE, payload: { uuid, ...data } } });
            }
            break;
        case 'onsubmit':
            dispatch({ type: ACTION.ITEM_ACTION, payload: { type: ACTION.SUBMIT_APPT_TYPE, payload: { uuid, ...data } } });
            break;
        }
    }, []);

    const updateList = useCallback(() => {
        dispatch({ type: ACTION.UPDATE_LIST });
    }, []);

    useEffect(() => init(dispatch, teamId, pollInterval), [teamId]);
    useEffect(() => handleAsyncQueue(dispatch, state.asyncQueue), [state.asyncQueue]);

    const arrayList: AttendAnywhereItem[] = useMemo(() => {
        return Array.from(state.list.values());
    }, [state.list]);
    return (
        <Fragment>
            {state.changes && (
                <div className={'attend-anywhere__toolbar'}>
                    <div>{_`There are new changes from server`}</div>
                    <button className={'btn btn-default btn-xs'} onClick={updateList}>{_`Update`}</button>
                </div>
            )}
            <AttendAnywhereWorkList
                list={arrayList}
                loading={state.loading}
                onListItemAction={handleAction}
                sortOrder={sortOrder}
                filterState={filterState}
            />
        </Fragment>
    );
};
