import styles from './PatientGoalView.less';

import React from 'react';
import _ from 'services/i18n';
import lodash from 'lodash';
import { useStepsChart } from './useStepsChart';
import { StepsChartFragment } from './StepsChartFragment';
import { DateTime } from 'common/datetime/DateTime';

export function PatientGoalView({
    goal,
    showScores,
}) {
    if (!goal) {
        return null;
    }

    const [stepsChartState, isError, handleOnSelectNextPage, handleOnSelectPrevPage] = useStepsChart({ goal });

    if (isError) {
        return (
            <div>
                {'This goal has invalid data and can\'t be displayed'}
            </div>
        );
    }

    return (
        <div className="goal-display table-responsive">
            {stepsChartState && stepsChartState.stepsData.length > 0 && (
                <div>
                    {(
                        goal.content.type === 'numerical'
                        || goal.content.type === 'steps-target'
                        || goal.content.type === 'range'
                        || goal.content.type === 'boolean'
                    ) && (
                        <StepsChartFragment {...stepsChartState }
                            onSelectNextPage={handleOnSelectNextPage}
                            onSelectPrevPage={handleOnSelectPrevPage}
                        />
                    )}
                </div>
            )}
            <table className="table table-bordered table-condensed table-striped table-nomargin">
                <tbody>
                    {renderLabel({ goal })}
                    {renderDescription({ goal })}
                    {(goal.content.type === 'numerical' || goal.content.type === 'steps-target') && renderTarget({ goal })}
                    {renderScoreType({ goal })}
                    {renderStatus({ goal })}
                    {renderRecurring({ goal })}
                    {renderDateScheduled({ goal })}
                    {(showScores || (goal.content.type === 'text')) ? renderScores({ goal }) : null}
                    {renderCreatedAt({ goal })}
                    {renderUpdatedAt({ goal })}
                    {renderPushNotificationReminder({ goal })}
                </tbody>
            </table>
        </div>
    );
}

function renderDataRow(name, content, nameClass = '', contentClass = '') {
    return (
        <tr>
            <th className={nameClass}>{name}</th>
            <td className={contentClass}>{content}</td>
        </tr>
    );
}

function renderLabel({
    goal
}) {
    let output = lodash.get(goal, 'content.label', '');
    let outputClass = '';
    if (output == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }

    return renderDataRow(_`Title`, output, '', outputClass);
}

function renderDescription({
    goal
}) {
    let output = lodash.get(goal, 'content.description', '');
    let outputClass = '';
    if (output == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }

    return renderDataRow(_`Description`, output, '', outputClass);
}

function renderScoreType({
    goal
}) {
    const type = lodash.get(goal, 'content.type', 'numerical');

    const types = {
        numerical: _`Numerical`,
        range: _`Range`,
        boolean: _`Bolean`,
        text: _`Text`,
        'steps-target': _`Steps`,
    };

    let output = types[type] ? types[type] : _`Number`;

    let outputClass = '';
    if (output == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }

    return renderDataRow(_`Score type`, output, '', outputClass);
}

function renderPushNotificationReminder({ goal }) {
    let output = lodash.get(goal, 'content.push_notification_on_reminder', '');
    let outputClass = '';

    if (output == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }
    return renderDataRow(_`Notify patient by push notification when goal is ready to rate`, String(output), '', outputClass);
}

function renderTarget({
    goal
}) {
    let output = lodash.get(goal, 'content.given_value', '');
    const unit = lodash.get(goal, 'content.unit', _`steps`);
    let outputClass = '';
    output = output ? `${unit}: ${output}` : output;
    if (output == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }

    return renderDataRow('Target', output, '', outputClass);
}

function renderStatus({
    goal,
}) {
    const original = lodash.get(goal, 'content.status', '');
    let output = original;
    let outputClass = '';

    if (original == 'active') {
        output = _`Active`;
    } else if (original == 'completed') {
        output = _`Completed`;
    } else if (original == 'deleted') {
        output = _`Archived`;
    } else if (original == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }

    return renderDataRow(_`Status`, output, '', outputClass);
}

function renderRecurring({
    goal,
}) {
    const original = lodash.get(goal, 'content.recurring', '');
    let output = original;
    let outputClass = '';

    if (original == 'daily') {
        output = _`Daily`;
    } else if (original == 'weekly') {
        output = _`Weekly`;
    } else if (original == 'school day') {
        output = _`School day`;
    } else if (original == 'weekday') {
        output = _`Weekday`;
    } else if (original == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }

    return renderDataRow(_`Recurring`, output, '', outputClass);
}

function renderDateScheduled ({
    goal
}) {
    let output = '', outputClass = '';
    if (lodash.has(goal, 'content.date_scheduled')) {
        output = <DateTime>{goal.content.date_scheduled}</DateTime>;
    } else {
        output = _`(not set)`;
        outputClass = 'text-muted';
    }

    return renderDataRow(_`Scheduled`, output, '', outputClass);
}

function renderGivenValue({
    goal,
}) {
    const original = lodash.get(goal, 'content.given_value', '');
    let output, outputClass;
    if (output == '') {
        output = _`(not set)`;
        outputClass = 'text-muted';

    } else if (Array.isArray(original)) {
        output = this.renderGivenValueArray(original);

    } else if (typeof original == 'string') {
        output = original;

    } else {
        output = JSON.stringify(original);
    }

    return renderDataRow(_`Result`, output, '', outputClass);
}

function renderScores({
    goal,
}) {
    const original = lodash.get(goal, 'content.scores', []);

    const rows = [];
    if (Array.isArray(original)) {
        original.forEach((item, idx) => {
            const timestamp = lodash.get(item, 'date', null);
            const score = lodash.get(item, 'value', '');
            const datetime = '';

            let body = '';
            if (timestamp == null) {
                if (score == '') {
                    return;
                }
                body = (score);

            } else {
                body = (
                    <span>
                        {score}&nbsp;&nbsp;
                        <span className="text-muted">({<DateTime>{timestamp}</DateTime>})</span>
                    </span>
                );
            }

            rows.push(<li key={idx}>{body}</li>);
        });
    }

    if (rows.length === 0) {
        return renderDataRow(_`Scores`, '-');
    }

    return renderDataRow(_`Scores`, (
        <ul className="">
            {rows}
        </ul>
    ));
}

function renderCreatedAt({
    goal
}) {
    const timestamp = lodash.get(goal, 'created_at', null);
    let output, outputClass;
    if (timestamp == null) {
        output = _`Unknown`;
        outputClass = 'text-muted';
    } else {
        output = <DateTime>{timestamp}</DateTime>;
    }

    return renderDataRow(_`Created at`, output, '', outputClass);
}

function renderUpdatedAt({
    goal
}) {
    const timestamp = lodash.get(goal, 'updated_at', null);
    let output, outputClass;
    if (timestamp == null) {
        output = _`Unknown`;
        outputClass = 'text-muted';
    } else {
        output = <DateTime>{timestamp}</DateTime>;
    }

    return renderDataRow(_`Last updated at`, output, '', outputClass);
}
