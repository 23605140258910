import React, { FC, useEffect, useState } from 'react';
import { Column, ResponsiveTable, Row } from 'ui';
import { Link } from 'react-router';
import './FilesList.less';
import { DateTime } from '../../datetime/DateTime';

export interface FileUnit {
    name: string;
    path: string;
    dateUpdated: number;
    description: string;
    type: string;
    content_type: string;
    isHighlighted: boolean;
    json: { [key: string]: string };
}

export interface FilesCallBackData {
    file?: FileUnit;
    newPath: string;
    isFile?: boolean;
    replacePath?: boolean;
}


interface FilesListProps {
    files: FileUnit[];
    path: string;
    changedFiles?: string[];
    onClick: (data: FilesCallBackData) => void;
}

const FilesList: FC<FilesListProps> = ({
    files,
    path,
    changedFiles = [],
    onClick,
}) => {
    const [showParentRow, setShowParentRow] = useState(false);

    const clicked = (file: FileUnit) => {
        if (file.content_type === 'directory') {
            onClick({ newPath: file.name || '' });
        } else {
            onClick({ newPath: file.name || '', isFile: true, file });
        }
    };

    const navigateToParent = () => {
        const route = path.split('/');
        const newRoute = route.slice(0, route.length - 1).join('/');
        onClick({ newPath: newRoute, replacePath: true });
    };

    useEffect(() => {
        const length = path.split('/').length;
        setShowParentRow(length > 1);
    }, [path]);

    return (
        <div className={'filelist'}>
            <Row>
                <Column sm="12">
                    <ResponsiveTable className="table table-bordered table-striped table-condensed">
                        <thead>
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>Last Commit</th>
                                <th>Description</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showParentRow ? <ParentRow onClick={navigateToParent}/> : null}
                            {files.map((file) => {
                                return <FilesRow onClick={clicked} key={file.path} file={file} isHighlight={changedFiles.indexOf(file.path) !== -1}/>;
                            })}
                        </tbody>
                    </ResponsiveTable>
                </Column>
            </Row>
        </div>
    );
};

const FilesRow: FC<{
    file: FileUnit;
    isHighlight: boolean;
    onClick: (file: FileUnit) => void;
}> = ({
    file,
    isHighlight,
    onClick,
}) => {
    const clicked = () => {
        onClick(file);
    };
    const isDirectory = file.content_type === 'directory';
    const filePath = file.path.split('/');
    const fileName = filePath[filePath.length - 1];
    return (
        <tr className={isHighlight ? 'danger' : ''}>
            <td>
                {/*<i className="fa fa-file-code-o" aria-hidden="true"></i>*/}
                {isDirectory ?
                    <i className="fa fa-folder-o fileslist_icon" aria-hidden="true" />
                    : <i className="fa fa-file-code-o fileslist_icon" aria-hidden="true" />
                }
                <Link to={'#'} onClick={(e) => {
                    e.preventDefault();
                    clicked();
                }}>
                    {isDirectory ? file.name : fileName}
                </Link>
            </td>
            <td>
                <DateTime parseFormat="nhs_date_fulldate">
                    {new Date(file.dateUpdated)}
                </DateTime>
            </td>
            <td>
                {file.description}
            </td>
            <td>
                {file.content_type}
            </td>
        </tr>
    );
};

const ParentRow: FC<{ onClick: () => void }> = ({
    onClick,
}) => {
    const clicked = () => {
        onClick();
    };
    return (
        <tr>
            <td colSpan={4}>
                <Link to={'#'} onClick={(e) => {
                    e.preventDefault();
                    clicked();
                }}>
                    <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link>
            </td>
        </tr>
    );
};

export default FilesList;
