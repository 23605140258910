import { useCallback, useMemo } from 'react';
import { useDepartmentLocation } from 'features/department/department.context';
// interfaces
import { Composition } from 'models/Composition';
import { Label } from 'models/Label';
import { SortedDepartmentData } from 'features/department/department.interface';

const useSortedDepartmentData = <T>(
    data: T[],
    getDepartmentId: (d: T) => null | string,
): SortedDepartmentData<T> => {
    const { currentLocation, locationTree } = useDepartmentLocation();
    const rootLocationId = locationTree?.uuid;

    return useMemo(() => {
        if (!currentLocation) {
            return {
                current: [],
                other: [],
            };
        }
        return data.reduce(
            (acc, dataItem) => {
                const updatedCurrent = [...acc.current];
                const updatedOther = [...acc.other];

                const departmentId = getDepartmentId(dataItem);

                if (!departmentId) {
                    // if there are no labels field we assume it belongs to root
                    if (currentLocation === rootLocationId) {
                        updatedCurrent.push(dataItem);
                    } else {
                        updatedOther.push(dataItem);
                    }
                } else {
                    if (departmentId === currentLocation) {
                        updatedCurrent.push(dataItem);
                    } else {
                        updatedOther.push(dataItem);
                    }
                }
                return {
                    current: updatedCurrent,
                    other: updatedOther,
                };
            },
            {
                current: [],
                other: [],
            },
        );
    }, [currentLocation, rootLocationId, data, getDepartmentId]);
};

const useSortedDepartmentDataForLabelable = <D, T extends { labels?: Label[] }>(
    data: D[],
    getLabelable: (d: D) => T,
): SortedDepartmentData<D> => {
    const getDepartmentFolderUuid = useCallback((dataItem: D) => {
        const labelable = getLabelable(dataItem);

        if (!labelable.labels) {
            return null;
        }

        const departmentLabel = labelable.labels.find(
            (label: Label) => label.type === 'department-folder',
        );

        if (!departmentLabel) {
            return null;
        }

        return departmentLabel.context;
    },
    [getLabelable],
    );

    return useSortedDepartmentData(data, getDepartmentFolderUuid);
};

export const useSortedDepartmentCompositionData = <
    T extends { labels?: Label[] },
>(data: Composition<T>[]) => {
    const getLabelable = useCallback((d: Composition<T>) => d.content, []);
    return useSortedDepartmentDataForLabelable(data, getLabelable);
};
export const useSortedDepartmentLabelableData = <
    T extends { labels?: Label[] },
>(data: T[]) => {
    const getLabelable = useCallback((d: T) => d, []);

    return useSortedDepartmentDataForLabelable(data, getLabelable);
};

export const useSortedDepartmentFolderIdData = <
    T extends { department_folder_uuid?: string },
>(data: T[]) => {
    const getDepartmentId = useCallback(
        (d: T) => d.department_folder_uuid || null,
        [],
    );

    return useSortedDepartmentData(data, getDepartmentId);
};
