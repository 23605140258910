import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import ForgotPasswordComponent from 'components/forgot-password';

/**
 * Extended Page Component for user_portal/login
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle ()  {
        return _`Admin Portal Forgot Password | iFocus Healthcare`;
    }

    render () {
        return (<ForgotPasswordComponent portal="admin_portal" {...this.props} />)
    }
}