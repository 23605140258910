import React from 'react';
import _ from 'services/i18n';
import ErrorBox from 'components/errorbox';
import { Link } from 'react-router';
import { Panel, Row, Column, FormGroup, InputField } from 'ui';
import PasswordGuidance from 'ui/password-guidance';

/**
 * A generic logout component
 */
export default class extends React.Component {
    renderLoading () {
        return (
            <div className="panel-loading">
                <span className="glyphicon glyphicon-lock"/>
            </div>
        );
    }

    render () {
        if (this.props.complete) {
            return this.renderComplete();
        }

        return (
            <div className="register-component" style={{paddingTop: '20px'}}>
                <div className="container">
                    <Row>
                        <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="2" sm="8">
                            <Panel title={_`Reset your Password`}>
                                {this.props.loading ? this.renderLoading() : null}
                                <p>{_`Please reset your password using the form below`}</p>
                                <ErrorBox errors={this.props.errors}/>
                                <form onSubmit={this.props.onSubmit}>
                                    <FormGroup>
                                        <label>{_`Email`}</label>
                                        <InputField type="email" name="email" cls={this.props} />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>{_`Password`}</label>
                                        <InputField type="password" name="password" cls={this.props} />
                                    </FormGroup>
                                    <PasswordGuidance />
                                    <hr />
                                    <FormGroup>
                                        <button className="btn btn-primary">{_`Reset Password`}</button>
                                    </FormGroup>
                                </form>
                            </Panel>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }

    renderComplete () {
        let appName = "";
        switch (this.props.clientId) {
            case 'coop':
                appName = 'StepUp!';
                break;
            case 'medsRoutine':
                appName = 'MedsMinder';
                break;
            case 'msk':
                appName = 'MyPathway';
                break;
        }

        switch (this.props.clientId) {
            case 'coop':
            case 'medsRoutine':
            case 'msk':
                return(
                    <div className="register-component" style={{paddingTop: '20px'}}>
                        <div className="container">
                            <Row>
                                <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="2" sm="8">
                                    <Panel title={_`Reset your Password`}>
                                        {this.props.loading ? this.renderLoading() : null}
                                        <p>{_`Your password has been reset. You may now login to the ${appName} application using your email and new password.`}</p>
                                    </Panel>
                                </Column>
                            </Row>
                        </div>
                    </div>
                );
        }
        return (
            <div className="register-component" style={{paddingTop: '20px'}}>
                <div className="container">
                    <Row>
                        <Column lgOffset="4" lg="4" mdOffset="3" md="6" smOffset="2" sm="8">
                            <Panel title={_`Reset your Password`}>
                                {this.props.loading ? this.renderLoading() : null}
                                <p>{_`Your password has been reset. You may now log in.`}</p>
                                <FormGroup>
                                    <Link to={"/" + this.props.portal + "/login"} className="btn btn-primary">{_`Go to Login Page`}</Link>
                                </FormGroup>
                            </Panel>
                        </Column>
                    </Row>
                </div>
            </div>
        );
    }
}