import React, { FC, Fragment } from 'react';
import { useI18N } from 'common/i18n/useI18N';
import {
    Grid,
    GridHeader,
    GridHeaderRow,
    GridHeaderCell,
    GridBody,
    SortOrder,
    defaultNumberSort
} from 'common/ui/grid';
import PaginationView from 'components/pagination';
import { useTableFilter } from 'common/ui/grid/useTableFilter';
import { DeviceGridRow } from 'features/patient/devices/DevicesGridRow';
import { usePagination } from 'common/ui/usePagination';
import { useTableSort } from 'common/ui/grid/useTableSort';
import { DeviceGridItem } from './services/DevicesServiceHelper';

export interface DevicesGridProps {
    loading: boolean;
    devices: DeviceGridItem[];
    onReturnDevice: (deviceItem: DeviceGridItem) => void;
    onEditDevice: (deviceItem: DeviceGridItem) => void;
}

const initialSortState = {
    date: SortOrder.DESC,
};

const filterSelector = {
    dateAssigned: item => item.dateFilter,
};

const sortSelector = {
    date: item => item.dateSort,
};
const sortHandlers = {
    date: defaultNumberSort,
};
export const DevicesGrid: FC<DevicesGridProps> = ({
    loading,
    devices,
    onReturnDevice,
    onEditDevice
}) => {
    const _ = useI18N();
    const { onFilter, filteredItems, filterState } = useTableFilter({
        items: devices,
        selectors: filterSelector,
    });

    const { onSort, sortedItems, sortState } = useTableSort({
        items: filteredItems,
        initialSortState,
        selectors: sortSelector,
        sortHandlers,
    });

    const { items: paginatedItems, total, onPageChange, page } = usePagination({
        items: sortedItems,
        perPage: 8,
        currentPage: 1
    });

    if (loading) {
        return (
            <div>
                <p><em>{_`Loading devices, please wait...`}</em></p>
            </div>
        );
    }

    if (devices.length === 0) {
        return (
            <div>
                <div>{_`There are currently no devices for this patient`}</div>
            </div>
        );
    }

    return (
        <Fragment>
            <Grid
                onSort={onSort}
                sortOrder={sortState}
                onFilter={onFilter}
                filterState={filterState}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'type'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Type`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'ownershipInfo'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Trust owned?`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'make'}
                            className={'ui-grid-header-cell__no-line-break'}
                        > {_`Make`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'model'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Model`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'id'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`CE ID number`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'dateAssigned'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Date assigned`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'assignedBy'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Assigned by`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'department'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Department`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'dateReturned'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Date returned`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={true}
                            filterable={true}
                            field={'returnedTo'}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Returned to`}</GridHeaderCell>
                        <GridHeaderCell
                            sortable={false}
                            filterable={false}
                            className={'ui-grid-header-cell__no-line-break'}
                        >{_`Actions`}</GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {paginatedItems.map(item  => (
                        <DeviceGridRow
                            key={item.key}
                            deviceItem={item}
                            onEditDevice={onEditDevice}
                            onReturnDevice={onReturnDevice}
                            actionsLabels={{
                                return: 'RETURN',
                                edit: 'EDIT'
                            }}
                        />
                    ))}
                </GridBody>
            </Grid>
            <PaginationView
                currentPage={page}
                pageCount={total}
                onChange={onPageChange}
            />
        </Fragment>
    );
};
