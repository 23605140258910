import {BulkOperationHistoryData} from './models/bulkOperationHistoryModels';
import {BulkOperationContent} from 'models/BulkOperationRequests';

export const getBulkOperationData = (
    bulkOperationContent: BulkOperationContent,
    bulkOperationDate: string
): BulkOperationHistoryData => {

    const bulkOperationAction = bulkOperationContent.actions[0];
    const pushIncluded = bulkOperationContent.actions[1]?.action_type === 'sendPushNotification';
    const bulkOperationType = bulkOperationAction.action_type;
    switch (bulkOperationType) {
        case 'sendMessage':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    template: bulkOperationAction.content.title,
                    title: bulkOperationAction.content.title,
                    description: bulkOperationAction.content.content,
                    pushNotification: bulkOperationAction.content.send_push || pushIncluded
                }
            );
        case 'assignSimpleQuestionnaire':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: '',
                    template: bulkOperationAction.content.id,
                    title: bulkOperationAction.content.lozenge_title,
                    description: bulkOperationAction.content.lozenge_content,
                }
            );
        case 'sendSms':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    title: bulkOperationContent.name,
                    description: bulkOperationAction.content.message,
                }
            );
        case 'sendEmail':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    subject: bulkOperationAction.content.subject,
                    htmlContent: bulkOperationAction.content.htmlContent,
                    textContent: bulkOperationAction.content.plainTextContent
                }
            );
        case 'sendHtmlLetter':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    subject: bulkOperationContent.name,
                    htmlContent: bulkOperationAction.content.pages[0].body,
                    textContent: bulkOperationAction.content.pages[0].body
                }
            );
        case 'allocateResourceWithMessage':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    title: bulkOperationAction.content.resources[0].title,
                    content: bulkOperationAction.content.content,
                    url: bulkOperationAction.content.resources[0].url,
                    type: bulkOperationAction.content.resources[0].type,
                    description: bulkOperationAction.content.resources[0].description,
                    pushNotification: bulkOperationAction.content.send_push || pushIncluded
                }
            );
        case 'assignQuestionnaire':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    template: bulkOperationAction.content.questionnaire_name,
                    title: bulkOperationAction.content.lozenge_title,
                    description: bulkOperationAction.content.lozenge_content,
                    popupTitle: bulkOperationAction.content.popup_title,
                    popupContent: bulkOperationAction.content.popup_content,
                    pushNotification: bulkOperationAction.content.send_push || pushIncluded
                }
            );
        case 'pifu':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    template: bulkOperationAction.content.questionnaire_name,
                    title: bulkOperationAction.content.lozenge_title,
                    description: bulkOperationAction.content.lozenge_content,
                    popupTitle: bulkOperationAction.content.popup_title,
                    popupContent: bulkOperationAction.content.popup_content,
                    pushNotification: bulkOperationAction.content.send_push || pushIncluded
                }
            );
        case 'autoCommunication':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    template: bulkOperationAction.content.questionnaire_name,
                    title: bulkOperationAction.content.lozenge_title,
                    description: bulkOperationAction.content.lozenge_content,
                    popupTitle: bulkOperationAction.content.popup_title,
                    popupContent: bulkOperationAction.content.popup_content,
                    pushNotification: bulkOperationAction.content.send_push || pushIncluded
                }
            );
        case 'assignTeamResources':
            return (
                {
                    documentType: bulkOperationType,
                    timelineDate: bulkOperationDate,
                    name: bulkOperationContent.name,
                    title: bulkOperationAction.content.message.title,
                    description: bulkOperationAction.content.message.content,
                    pushNotification: bulkOperationAction.content.send_push || pushIncluded
                }
            );
    }
};
