import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';
import _ from 'services/i18n';
import lodash from 'lodash';

import { jsonService } from '../../../app/services/json.service';


export default class extends BaseComponent {

    /**
     *
     * @param props
     */
    constructor (props) {
        super(props);

        this.state = {
            filename: '',
            fileContent: '',
            error: '',
            errorList: [],
            invalidFile: true
        };

        this.bindEvents([
            'ChangeFile',
            'InternalUpload'
        ]);

        this.bindFunctions([
            'onValidationResult',
            'isValidJSON'
        ]);

        this.View = View;

        this.fileReaderString = new FileReader();
        this.fileReaderString.onload = this.isValidJSON.bind(this);
    }

    /**
     *
     * @param e
     */
    isValidJSON (e) {
        const jsonString = e.target.result;

        this.setState({
            fileContent: jsonString
        }, () => {
            try {
                const json = JSON.parse(jsonString);
                const isComposition = lodash.has(json, 'details.sections');

                if (isComposition){
                    return;
                }

                return jsonService.isValidJson(jsonString)
                .then(this.onValidationResult);
            } catch (e) {
                this.setState({
                    error: _`File does not contain valid JSON`,
                    invalidFile: true
                });
            }
        });
    }

    /**
     * handle the Validation Result
     */
    onValidationResult (response) {
        let errorList = (response.message || {}).errors || [],
            errorCount = errorList.length, errorArray;

        errorArray = errorList.map((item) => {
            return (
                <span>{item.message} <em>({item.pointer})</em></span>
            );
        });
        if(errorList.length > 0){
            this.setState({
                error: _`The template contains ${errorCount} JSON schema errors`,
                errorList: errorArray,
                invalidFile: true
            });
        }
    }

    /**
     *
     * @param e
     */
    handleChangeFile (e) {
        let fileElement = e.target, newState = {};

        if (fileElement.files.length == 0) {
            return; //no file selected
        }

        this.setState({
            error: '',
            errorList: [],
            invalidFile: false
        }, () => {
            newState = {
                error: '',
                filename: fileElement.files[0].name
            };

            // type doesnt work
            //if (fileElement.files[0].type == '') {
            //  newState.error = _`Not a JSON file`;
            //}
            if (fileElement.files[0].size > 500000) {
                newState.error = _`File too large. 500kb max`;
            }
            else {
                this.fileReaderString.readAsText(fileElement.files[0]);
            }

            if(newState.error != ''){
                newState.invalidFile = true;
            }

            this.setState(newState);
        });
    }

    /**
     *
     */
    handleInternalUpload () {
        this.props.onUpload(this.state.fileContent, this.state.filename);
        this.props.onClose();
    }
}
