import React from 'react';
import View from './view';
import BaseComponent from 'components/BaseComponent';
import lodash from 'lodash';

/**
 * @deprecated
 * DEPRECATED
 * Represents a simple form contained in a panel.
 *
 * Props (data):
 *  - title = Optional. A title to display in the form panel.
 *  - intro = Optional. Text to display in an introductory paragraph above the form panel.
 *  - id = Optional. ID for the form.
 *  - submitLabel = Optional. The text to display on the submit button. Default text will be used if this is not specified.
 *  - cancelLabel = Optional. The text to display on the cancel button. Default text will be used if this is not specified. Ignored if onCancel is not specified.
 *  - resetLabel = Optional. The text to display on the reset button. Default text will be used if this is not specified. Ignored if onReset is not specified.
 *  - busy = Optional. If true, the form will be shown disabled.
 *  - alertText = Optional. Text to display in an alert box at the top of the form. The alert is only displayed if this is not empty.
 *  - alertType = Optional. Type for an alert box to display at the top of the form. Can be 'info' (default), 'warning', 'danger', or 'success'. Ignored if alertText is empty.
 *
 * Props (handlers):
 *  - onSubmit = Required. A function to call when the form is submitted. It will be given a Map of input IDs to values (equivalent to 'effectiveValue' in the onChange handler). It will only be called if all validation was successful.
 *  - onCancel = Optional. A function to call to cancel the form. This could be useful in a modal. The cancel button is only shown if this is not null.
 *  - onReset = Optional. A function to call to reset the form. The reset button will only be shown if this is specified.
 *  - onChange = Optional. A function called when an item in the form changes. The parameters are: id, rawValue, effectiveValue, valid.
 *
 * Input elements (TextInput etc) should be added as immediate children.
 * They must not be contained inside anything else.
 */
export default class FormPanel extends BaseComponent {
        
    constructor (props) {
        super(props);

        this.state = {
            showReset: (this.props.onReset != null),
            showCancel: (this.props.onCancel != null),
            hasRequiredChildren: false,
            
            // Map of input IDs to data objects.
            // Each data object will contain properties 'value' and 'valid'.
            data: new Map(),
            
            // Boolean flag indicating if all form data currently appears to be valid.
            allValid: true,
            
            // Boolean flag indicating if validation errors should be forcibly displayed in the form.
            // This is used to ensure any errors are visible after a failed form submission.
            forceShowValidation: false
        };
        
        if (this.props.onSubmit == null) {
            console.warn('Missing required prop "onSubmit".');
        }

        this.bindEvents([
            'Submit',
            'Cancel',
            'Reset',
            'Change'
        ]);

        this.bindFunctions([
            
        ]);

        this.View = View;
    }
    
    componentWillMount () {
        this.checkForRequiredChildren(this.props);
    }
    
    componentWillReceiveProps (newProps) {
        if (newProps.onSubmit == null) {
            console.warn('Missing required prop "onSubmit".');
        }
        
        this.checkForRequiredChildren(newProps);
    }
    
    checkForRequiredChildren (props) {
        let hasRequiredChildren = false;
        React.Children.forEach(props.children, (child) => {
            if (child != null && child.props != null && child.props.required && !child.props.readOnly && !child.props.disabled) {
                hasRequiredChildren = true;
            }
        });
        
        this.setState({
            hasRequiredChildren: hasRequiredChildren
        });
    }
    
    handleSubmit (e) {
        e.preventDefault();
        
        // Do not submit the form if it's invalid.
        if (!this.state.allValid) {
            this.setState({
                // Instead, ensure the validation is shown in the form elements.
                forceShowValidation: true
            });
            return;
        }
        
        if (!this.props.onSubmit) {
            console.warn('Form parent did not provide an onSubmit handler.');
            return;
        }
        
       
        let processedData = new Map();
        for (let [id, obj] of this.state.data.entries()) {
            processedData.set(id, obj.effectiveValue);
        }
        this.props.onSubmit(processedData); 
        
    }
    
    handleCancel (e) {
        e.preventDefault();
        
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    
    handleReset (e) {
        e.preventDefault();
        
        if (this.props.onReset) {
            this.props.onReset();
        } else {
            console.warn('Form does not have an onReset handler.');
        }
    }
    
    /**
     * Event handler: A child input component has changed value.
     * Parameters:
     *  - id: The id of the input element.
     *  - rawValue: The raw value of the input element, or undefined if the element has been removed or disabled.
     *  - effectiveValue: The effective (preprocessed) value of the input element, or undefined if the element has been removed or disabled.
     *  - valid: Indicates if the input value is valid. Will be undefiend if the element has been removed or disabled.
     */
    handleChange (id, rawValue, effectiveValue, valid) {
        
        // Don't do anything if the value hasn't changed.
        if (rawValue === undefined && effectiveValue === undefined && valid === undefined) {
            // The value is being removed.
            if (!this.state.data.has(id)) {
                // Value doesn't exist so there's nothing to remove.
                return;
            }
            
        } else if (this.state.data.has(id)) {
            // The value is being modified.
            let existingEntry = this.state.data.get(id);
            if (existingEntry.rawValue == rawValue && existingEntry.effectiveValue == effectiveValue && existingEntry.valid == valid) {
                // There's no change.
                return;
            }
        }
        
        // NOTE: It is important to notify the parent before updating our own state.
        // This reduces the chance of an interim re-render sending stale data to the
        //  input component's props temporarily.
        
        // Notify the parent, if possible.
        if (this.props.onChange) {
            this.props.onChange(id, rawValue, effectiveValue, valid);
        }
        
        // The state update must be done atomically.
        // This is because multiple inputs can trigger change handlers in very
        //  quick succession, especially during initialisation and form-wide updates.
        this.setState((prevState, props) => {
            
            let data = prevState.data;
            if (rawValue === undefined && effectiveValue === undefined && valid === undefined) {
                data.delete(id);
            } else {
                data.set(
                    id,
                    {
                        rawValue: rawValue,
                        effectiveValue: effectiveValue,
                        valid: valid
                    }
                );
            };
            
            return {
                data: data,
                allValid: this.allValid(data)
            };
        });
    }
    
    /**
     * Check if all items in the given data map array are marked as valid.
     */
    allValid (data) {
        // Check if all items are now valid.
        let allValid = true;
        for (let item of data.values()) {
            if (item.hasOwnProperty('valid')) {
                if (!item.valid) {
                    allValid = false;
                    break;
                }
            } else {
                console.warn('Missing "valid" property in form data map entry.');
            }
        }
        return allValid;
    }
}
