import { fromJS, Map } from 'immutable';
import { ADMINP_MEMBERS_LIST } from 'actions/types';

export enum AsyncStates {
    Idle = 'idle', // never loaded
    Loading = 'loading',
    Success = 'success',
    Fail = 'fail'
}

export enum ModalTypes {
    Invite = 'MemberInvite',
    Error = 'Error',
    DeleteUser = 'DeleteUser',
    ChangeType = 'ChangeType',
    UserInvited = 'UserInvited'
}

const initialState: Map<any, any> = Map({
    teamRoles: Map({
        state: AsyncStates.Idle, // loading, success, fail
        roles: []
    }),
    modal: Map({
        type: null,
        userSubmitState: AsyncStates.Idle
    })
});

const reducer = (state = initialState, {type, payload}: any) => {
    let newState = state;
    switch (type) {
        case ADMINP_MEMBERS_LIST.GET_ROLES:
            switch (payload.state) {
                case AsyncStates.Loading:
                    newState = state.setIn(['teamRoles', 'state'], AsyncStates.Loading);
                    break;
                case AsyncStates.Success:
                    newState = state.setIn(['teamRoles'], Map({
                        state: AsyncStates.Success,
                        roles: fromJS(payload.roles)
                    }));
                    break;
                case AsyncStates.Fail:
                    newState = state.setIn(['teamRoles', 'state'], AsyncStates.Fail);
            }
            break;
        case ADMINP_MEMBERS_LIST.OPEN_MODAL:
            newState = state.setIn(['modal'], Map(Object.assign(state.get('modal').toJS(), payload)));
            break;
        case ADMINP_MEMBERS_LIST.CLOSE_MODAL:
            newState = state.setIn(['modal'], Map(Object.assign(state.get('modal').toJS(), {type: null})));
            break;
        case ADMINP_MEMBERS_LIST.SUBMIT_NEW_USER:
            newState = state.setIn(['modal', 'userSubmitState'], payload.state);
            break;
        case ADMINP_MEMBERS_LIST.RESET_INVITE_FORM:
            newState = state.setIn(['modal', 'userSubmitState'], initialState.get('modal'));
            break;
    }

    return newState;
};

export default reducer;
