import React from 'react';
import View from './view';
import _ from 'services/i18n';
import BaseInput from 'components/form-panel/base-input';
import moment from 'moment-timezone';
import { standardDateTimeFormats } from 'common/datetime/convertToDate';

/**
 * Represents a date input field in a form panel.
 *
 * WARNING: This doc-comment is incorrect. The value and initialValue info needs to be updated.
 *
 * @deprecated
 * DEPRECATED
 * cause of deprecation - uses BaseInput
 * Props (data):
 *  - label = Required. A text label identifying the input field.
 *  - id = Required. An identifier for the input field.
 *  - helpText = Optional. Help text to display below the input field.
 *  - showTime = Optional. Display hours and minutes also
 *  - required = Optional. Boolean indicating if a value must be entered in this field. Defaults to false.
 *  - readOnly = Optional. Boolean flag indicating if the input element is readOnly. The value will not be reported to the parent form.
 *  - disabled = Optional. Boolean flag indicating if the input element is disabled. The value will not be reported to the parent form.
 *  - forceShowValidation = Optional. Boolean indicating if validation errors will be forced to be shown in the UI. Defaults to false. Normally, some errors will be suppresed, e.g. if the user hasn't had a chance to enter anything yet. This flag is used by the containing form.
 *  - value = Optional. JavaScript Date object dictating the value shown in the field. Only set this if you intend to intercept and respond to the onChange handler.
 *  - initialValue = Optional. JavaScript Date object dictating the initial value for the form element. Ignored after construction, or if value is specified.
 *  - hideValidationSuccess = Optional. Defaults to false. If true, the component will not show a green tick when validation passes. This is useful where such information would be ambiguous, e.g. on a password field.
 *  - min = Optional. Date object defining the earliest date value allowed by validation. Ignored if not specified.
 *  - max = Optional. Date object defining the latest date value allowed by validation. Ignored if not specified.
 *  - pastOnly = Optional. If true, only dates which are in the past will be allowed.
 *  - futureOnly = Optional. If true, only dates which are in the future will be allowed.
 *
 * Props (handlers):
 *  - onChange = Required. A function called when the value changes. Parameters are: id, value, value, validated (the duplicated value is redundant in this case -- it is to maintain compatibility with TextInput). NOTE: This is provided automatically by the parent form if used directly inside FormPanel.
 *  - onValidate = Optional. A custom validation function which will be called to validate the value. It should return true if the value is valid, or if not it should return a string containing an error message, or an array of multiple error messages. This will only be called if any automated validation passes.
 */
export default class DateInputField extends BaseInput {
    constructor (props) {
        super(props);

        if (this.props.min != null && !(this.props.min instanceof Date)) {
            console.warn('Prop "min" should be null or an instance of Date.');
        }

        if (this.props.max != null && !(this.props.max instanceof Date)) {
            console.warn('Prop "max" should be null or an instance of Date.');
        }

        let value = this.props.value || this.props.initialValue, valueString;

        if (this.props.showTime) {
            if (!value.hasOwnProperty('value') && !(value.value instanceof Date)) {
                value = {
                    value: null,
                    unknown: false,
                    approx: false
                };
            }

            valueString = this.toDateString(value.value);
        } else {
            if (!(value instanceof Date)) {
                //It needs some clarification why it is converted to null. It leads to unwanted errors in console from 'convertToDate function
                //value = null;
                value = new Date();
            }
            value = this.convertToDate(value);
            valueString = this.toDateString(value);
        }

        this.state = {
            // A Date object representing the value in the input box, or null if no value is entered.
            value,

            // A date string based on the state value above, in the format YYYY-MM-DD.
            // This will be null if no date is entered.
            valueString,

            // Boolean flag indicating if validation has been run, regardless of whether it passed or failed.
            isValidated: false,

            // Boolean flag specifying if validation should be shown in the user interface.
            // This is used to avoid displaying errors which would not be helpful, e.g. reporting
            //  that a value is required before the user has had a chance to enter anything.
            // This is ignored if isValidated is false or props.forceShowValidation is true.
            showValidation: false,

            // Stores any validation error messages.
            // If the array if empty, validation either passed or hasn't been done yet.
            errors: []
        };

        this.bindEvents([
            'Change',
            'ChangeApprox',
            'ChangeUnknown',
            'LoseFocus',
            'SetInvalid'
        ]);

        this.bindFunctions([
        ]);

        this.View = View;
    }

    convertToDate (dt) {
        return dt && moment(dt);
    }

    componentWillMount () {
        // Validation will notify the parent of the initial value.
        this.initialValidation();
    }

    componentWillReceiveProps (newProps) {

        super.componentWillReceiveProps(newProps);

        if (newProps.min != null && !(newProps.min instanceof Date)) {
            console.warn('Prop "min" should be null or an instance of Date.');
        }

        if (newProps.max != null && !(newProps.max instanceof Date)) {
            console.warn('Prop "max" should be null or an instance of Date.');
        }

        let revalidate = false,
            changed = false,
            valueString,
            propsValueString,
            value = newProps.value,
            oldValue = this.props.value;
        const newState = {};

        if (value) {
            if (this.props.showTime) {
                if (!value.hasOwnProperty('value') && !(value.value instanceof Date)) {
                    value = {
                        value: null,
                        unknown: false,
                        approx: false
                    };
                }

                if (!oldValue.hasOwnProperty('value') && !(oldValue.value instanceof Date)) {
                    oldValue = {
                        value: null,
                        unknown: false,
                        approx: false
                    };
                }

                valueString = this.toDateString(value.value);
                propsValueString = this.toDateString(oldValue.value);

                if (valueString != propsValueString || value.unknown != oldValue.unknown || value.approx != oldValue.approx) {
                    // The value has changed.
                    newState.value = value;
                    newState.valueString = valueString;
                    revalidate = true;
                    changed = true;
                }

            } else {
                if (!(value instanceof Date)) {
                    value = null;
                }
                if (!(oldValue instanceof Date)) {
                    oldValue = null;
                }
                valueString = this.toDateString(value);
                propsValueString = this.toDateString(oldValue);

                if (valueString !== propsValueString) {
                    // The value has changed.
                    newState.value = value;
                    newState.valueString = valueString;
                    revalidate = true;
                    changed = true;
                }
            }
        }

        if (newProps.onValidate != this.props.onValidate) {
            // The custom validator has changed.
            revalidate = true;
        }

        if (newProps.onChange != this.props.onChange) {
            // The parent's change handler has changed.
            // Revalidation notifies the parent of the current value.
            revalidate = true;
        }

        if (changed) {
            this.setState(
                newState,
                () => {
                    if (revalidate) {
                        this.initialValidation();
                    }
                }
            );
        } else if (revalidate) {
            this.initialValidation();
        }
    }

    initialValidation () {
        // Run validation and notify the parent of the initial value.
        // However, only report the validation result to the UI if the
        //  initial value was not empty, or we were already reporting it.
        this.validate(
            this.state.value != null || this.state.showValidation,
            true
        );
    }

    handleChangeApprox(isTimeApproximate) {
        this.setState(
            (state) => {
                return {
                    value: {
                        ...state.value,
                        approx: isTimeApproximate,
                    },
                    valueError: false,
                    isValidated: false,
                    errors: []
                };
            },
            this.validate.bind(this, true, true)
        );
    }
    handleChangeUnknown(isDateUnknown) {
        this.setState(
            (state) => {
                return {
                    value: {
                        ...state.value,
                        unknown: isDateUnknown,
                    },
                    valueError: false,
                    isValidated: false,
                    errors: []
                };
            },
            () => this.validate(true, true)
        );
    }
    /**
     * Event handler: The content of the form field has changed.
     */
    handleChange (e) {

        const formData = e.target.value;
        let value;

        if (this.props.showTime) {
            value = {
                unknown: this.state.value && this.state.value.unknown,
                approx: this.state.value && this.state.value.approx,
                value: this.fromDateString(formData)
            };
        } else {
            value = this.fromDateString(formData);
        }
        const valueString = this.toDateString(formData);

        this.setState(
            {
                value,
                valueString,
                valueError: false,
                isValidated: false,
                errors: []
            },
            this.validate.bind(this, true, true)
        );
    }

    /**
     * Event handler: Keyboard focus has moved off the input field.
     */
    handleLoseFocus (e) {
        // Make sure any validation errors are displayed.
        this.setState({
            showValidation: true
        });
    }

    /**
     * Validate the current date.
     * This operates on stored state.
     * Parameters:
     *  - showValidation = Optional. If true (default), the validation result will be shown in the user interface. If false, the interface won't show any validation status.
     *  - notifyParent = Optional. If true (default), the value and its validation status will be sent to the parent's onChange handler.
     *  - cb = Optional. A call-back to trigger after validation has finished and any notifications have been dispatched.
     */
    validate (showValidation = true, notifyParent = true, cb = null) {

        // Don't perform any validation for a control that is readOnly or disabled.
        if (this.props.readOnly || this.props.disabled) {
            this.setState({
                isValidated: false,
                showValidation: showValidation,
                errors: []
            }, () => {
                if (notifyParent && this.props.onChange) {
                    // Notify the parent that the value doesn't exist.
                    this.props.onChange(this.props.id, undefined, undefined, undefined);
                }
                if (cb) {
                    cb();
                }
            });

            return;
        }

        // Validate the data.
        let result = this.getValidationResult();
        if (result === true) {
            result = [];
        }

        this.setState({
            isValidated: true,
            showValidation: showValidation,
            errors: result
        }, () => {
            if (notifyParent && this.props.onChange) {
                // Report the result to the parent.
                this.props.onChange(this.props.id, this.state.value, this.state.value, this.state.errors.length == 0);
            }
            if (cb) {
                cb();
            }
        });
    }

    /**
     * Run validation on the stored effective value, and return the result.
     * This will not update current state.
     * This will return true if the validation passes.
     * Otherwise it will return an array of error messages.
     */
    getValidationResult () {
        // Ensure that an empty value isn't allowed through if the rules don't permit it.
        if (this.props.required && this.state.value == null) {
            return [_`A date must be specified.`];
        }

        if (this.state.valueError) {
            return [_`Invalid Date or Time Entered`];
        }

        if (this.state.value != null) {

            // Check the minimum and maximum constraints.

            const now = moment().unix();
            if (this.props.pastOnly && this.state.value.unix() > now) {
                return [_`This date must be in the past.`];
            }
            if (this.props.futureOnly && this.state.value.unix() < now) {
                return [_`This date must be in the future.`];
            }
        }

        return this.getCustomValidationResult(this.state.effectiveValue);
    }

    toDateString(dt) {
        const date = this.convertToDate(dt);

        if (!date || !date.isValid()) {
            return null;
        }

        if (this.props.showTime) {
            return date.format(standardDateTimeFormats.datetime_input);
        } else {
            return date.format(standardDateTimeFormats.date_input);
        }
    }

    fromDateString (str) {
        if (str == null) {
            return null;
        }
        let dt;
        if (this.props.showTime) {
            dt = moment(str, 'YYYY-MM-DD HH:mm');
        } else {
            dt = moment(str, 'YYYY-MM-DD');
        }
        if (!dt.isValid()) {
            return null;
        }

        return dt.local();
    }

}
