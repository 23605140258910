import React from 'react';
import _ from 'services/i18n';

export default class extends React.Component {

    render () {
        let formGroupClass = 'form-group';

        let requiredIndicator;
        if (this.props.required && !this.props.readOnly && !this.props.disabled) {
            requiredIndicator = (
                <span className="text-danger">{' '}*</span>
            );
        }

        // Important: Don't show the validation result if no validation has been done.
        if ((this.props.showValidation || this.props.forceShowValidation) && this.props.isValidated) {
            if (this.props.errors.length > 0) {
                formGroupClass += ' has-error';

            } else if (!this.props.hideValidationSuccess) {
                formGroupClass += ' has-success';
            }
        }

        // Show an unselectable placeholder if one has been specified and nothing is selected.
        // Don't show it otherwise.
        let placeholder;
        if (this.props.placeholder != null && (!this.props.value || this.props.value == '')) {
            placeholder = (
                <option key='___placeholder___' disabled={true} value='' className='placeholder'>{this.props.placeholder || ''}</option>
            );
        }

        return (
            <div className={formGroupClass}>
                <label className='control-label' htmlFor={this.props.id}>
                    {this.props.label}
                    {requiredIndicator}
                </label>

                <select
                    id={this.props.id}
                    className="form-control selector-input"
                    onChange={this.props.onChange}
                    onBlur={this.props.onLoseFocus}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                    value={this.props.value || ''}
                >
                    {placeholder}
                    {this.props.children}
                </select>

                <Errors {...this.props} />
                <HelpBlock {...this.props} />
            </div>
        );
    }
}

const Errors = ({isValidated, showValidation, forceShowValidation, errors}) => {
    // Don't show any errors if validation passed or hasn't been done,
    //  or if validation errors are being suppressed.
    if (!isValidated || errors.length == 0 || (!showValidation && !forceShowValidation)) {
        return (<noscript />);
    }

    let content;
    if (errors.length == 1) {
        content = (<span>{errors[0]}</span>);
    } else {
        let list = errors.map((error, idx) => {
            return (<li key={idx}>{error}</li>)
        });

        content = (<ul>{list}</ul>);
    }

    return (
        <div className='alert alert-danger'>
            {content}
        </div>
    );
};

const HelpBlock = ({helpText}) => {
    if (helpText == null || helpText == '') {
        return (<noscript />);
    }
    return (
        <p className='help-block'>
            {helpText}
        </p>
    );
};
