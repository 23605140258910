import React, { ChangeEvent, FC } from 'react';
import { AdvancedSearchCriterionRelativeDateInputProps } from './AdvancedSearchCriterionRelativeDateInput.interface';
import {
    RelativeDateUnit,
    useDateCriterionRelativeValue
} from "features/patients/advanced-search/AdvancedSearchSetupHooks";

export const AdvancedSearchCriterionRelativeDateInput: FC<AdvancedSearchCriterionRelativeDateInputProps> = (props) => {
    const [ amount, setAmount, unit, setUnit ] = useDateCriterionRelativeValue(props.onChange, props.value);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAmount(parseInt(e.target.value));
    };

    const handleDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setUnit(e.target.value as RelativeDateUnit);
    };

    return (
        <>
            <input
                type="number"
                className={'form-control min-w-0 w-auto'}
                value={amount}
                onChange={handleInputChange}
            />
            <select
                className={'form-control w-auto'}
                value={unit}
                onChange={handleDropdownChange}
            >
                <option value='day'>Days</option>
                <option value='week'>Weeks</option>
                <option value='month'>Months</option>
                <option value='year'>Years</option>
            </select>
        </>
    );
};
