import React from 'react';
import BaseComponent from 'components/BaseComponent';
import View from './view';
import { browserHistory } from 'react-router';

export default class extends BaseComponent {
    /**
     * @param {Object} props - Passed in properties
     */
    constructor (props) {
        super(props);

        this.state = {
            email: this.props.location.query.email || '',
            clientId: this.props.location.query.clientId || '',
            password: '',
            errors: [],
            complete: false
        };

        this.bindEvents([
            'Change',
            'Submit'
        ]);

        this.View = View;
    }

    handleChange (e) {
        let newState = {};

        switch(e.target.name) {
            case 'email':
            case 'password':
                newState[e.target.name] = e.target.value;
                this.setState(newState);
                break;
        }
    }

    handleSubmit (e) {
        var submission = {};
        e.preventDefault();

        // duplicate password as confirmation to match with the registration flow
        submission = {
            token: this.props.params.token,
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password
        };
        
        if (this.state.password.length < 8) {
            this.setState({
                errors: [
                    'Password must be at least 8 characters long.'
                ]
            });
            return;
        }

        this.setState({loading: true});

        this.server.postRequest('/password-reset', submission, (response) => {
            if (response.status == 'OK') {
                this.setState({
                    loading: false,
                    complete: true
                });
                return;
            }
            
            let details = response.details || '';
            if (details == '') {
                details = 'Failed to reset password. Please ensure your password is at least 8 characters long and is not the same as your email address.';
            }

            this.setState({
                loading: false,
                errors: [ details ]
            });
        });
    }
}