import { InlineSelectorsForm } from 'features/patient/questionnaire-results/inline-selector-form/InlineSelectorsForm';
import { Loading } from 'common/ui/alert-boxes';
import { LineGraph } from 'common/ui/line-graph/LineGraph';
import React, { FC } from 'react';
import _ from 'services/i18n';
import { DateSpan } from 'common/datetime/DateTime';
import './QuestionnaireResult.scss';
import { browserHistory } from 'react-router';

export const QuestionnaireResultComponent: FC<any> = ({ questionnaireName, selectorItems, onChange, loading, lines, axisX, axisY, tableData, boundaryValues }) => {
    const openQuestionnaire = (targetDot) => {
        const navigateBack = location.pathname.replace('graphs', '');
        browserHistory.push(navigateBack + 'questionnaires/' + targetDot.uuid + '?questionnaire');
    };
    return (
        <div className={'questionnaire-results-item'}>
            <h4>{`${questionnaireName} questionnaire`}</h4>
            <InlineSelectorsForm
                onChange={onChange}
                items={selectorItems}
            />
            {loading &&
            <Loading/>
            }
            {!loading && (
                <LineGraph
                    lines={lines}
                    dotClickCallback={openQuestionnaire}
                    axisXParams={axisX}
                    axisYParams={axisY}
                    boundaryValues={boundaryValues}
                />
            )}
            {Boolean(tableData?.length) && (
                <table className={'table table-bordered'}>
                    <thead>
                        <tr>
                            <th>{_`Date`}</th>
                            <th>{_`Score`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map(({ date, value }, i) => {
                            return (
                                <tr key={i}>
                                    <td><DateSpan>{date}</DateSpan></td>
                                    <td>{value}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};
