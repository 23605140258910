import React, { FC, useState, useEffect } from 'react';
import { mfaSetupService } from 'services/mfa-setup.service';
import { MFAMethod, MFAOption } from 'models/mfa';
import { MFAOptionDisplay } from './MFAOptionDisplay';

export const MFAOptionSetup: FC<any> = () => {
    const [options, setOptions] = useState<MFAOption[]>([]);
    const [setupMode, setSetupMode] = useState<boolean>(false);

    useEffect(() => {
        mfaSetupService.listUserMFAOptions()
            .then(setOptions);
    }, [])

    useEffect(() => {
        setSetupMode(options.some(option=> option.id === -1))
    }, [options])

    const startSetup = (mfaMethod: MFAMethod) => {
        setOptions([...options, {
            id: -1,
            status: 'pending',
            mfa_method: mfaMethod,
            created_at: new Date(),
            updated_at: new Date()
        }])
    }

    const resetOptions = (options: MFAOption[]) => {
        setOptions([]);
        setOptions(options);
    }

    return (
        <div className="col-sm-12">
            <div className='intro-text'>
                <h3><strong>Secure your account by setting up MFA</strong></h3>
                <hr/>
                <h4><strong>Multi-factor authentication (MFA) methods</strong></h4>
                <div>To help keep your account secure, you can now set up your preferred authentication methods.</div>
            </div>
            {
                options
                    .filter(option => option.status !== 'disabled')
                    .map(option => (
                        <MFAOptionDisplay key={option.id}
                                          initialOption={option}
                                          onVerified={resetOptions}
                                          onDeleted={resetOptions}
                                          onMadePreferred={resetOptions}
                        />))
            }
            {options.length !== 3 && <div className='panel-footer'>
                {!options.some(option => option.mfa_method === 'authenticator') &&
                    <button className='btn btn-link' disabled={setupMode} onClick={() => startSetup('authenticator')}>Add Authenticator App</button>}
                {!options.some(option => option.mfa_method === 'email') &&
                    <button className='btn btn-link' disabled={setupMode} onClick={() => startSetup('email')}>Add Email Authentication</button>}
                {!options.some(option => option.mfa_method === 'sms') &&
                    <button className='btn btn-link' disabled={setupMode} onClick={() => startSetup('sms')}>Add SMS Authentication</button>}
            </div>}
        </div>
    );
}