import React from 'react';
import _ from 'services/i18n';

export default class extends React.Component {
    render () {
        return (
            <div className="add-image-modal">
                <div className="modal show" tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {this.renderHeader()}
                            {this.renderBody()}
                            {this.renderFooter()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderHeader () {
        return (
            <div className="modal-header">
                <button type="button" className="close" aria-label="Close" onClick={this.props.onClose}><span
                    aria-hidden="true">&times;</span></button>
                <h4 className="modal-title">{_`Add New Image (${this.props.width}px by ${this.props.height}px)`}</h4>
            </div>
        )
    }

    renderBody () {
        var ratio = parseInt(parseFloat(this.props.height) / parseFloat(this.props.width) * 100),
            imageRatio = {
                paddingBottom: ratio + "%"
            },contentsImage,
            message = _`Click here or drag image here`;

        if (this.props.error) {
            message = this.props.error;
        }

        if (!this.props.emptyImage) {
            contentsImage = {
                backgroundImage: 'url(' + this.props.temporaryFile + ')'
            }
        }

        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="image-box" style={imageRatio}>
                            <div className="contents" style={contentsImage}>
                                {this.props.emptyImage ?
                                    <div className='empty-image'>{message}</div> : null}
                            </div>
                            <input type="file" className="image-file" name="image-file"
                                   onChange={this.props.onChangeImage}/>
                        </div>
                    </div>
                </div>
                {this.renderLinkBox()}
            </div>
        )
    }

    renderLinkBox () {
        if (!this.props.requireLink) {
            return null;
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="linkValue">Link:</label>
                        <input type="text" id="linkValue" name="linkValue" className="form-control"
                               value={this.props.linkValue} onChange={this.props.onChangeLink}/>
                    </div>
                </div>
            </div>
        )
    }

    renderFooter () {
        var uploadBtn = '';

        if (this.props.emptyImage) {
            uploadBtn = (<button type="button" className="btn btn-disabled" disabled>Select Image First</button>);
        } else {
            uploadBtn = (
                <button type="button" className="btn btn-primary" onClick={this.props.onInternalUpload}>Upload Image</button>);
        }

        return (
            <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={this.props.onClose}>Close</button>
                {uploadBtn}
            </div>
        )
    }
}
