import React, { ReactElement, useState, useEffect } from 'react';
import _ from 'services/i18n';
import { browserHistory } from 'react-router';
import { Params } from 'react-router/lib/Router';

import { useForm } from 'common/form/useForm';
import { useCurrentTeam } from 'common/useCurrentTeam';
import { usePrivacyNotices } from './usePrivacyNotices';
import { PrivacyNoticeComposition } from './PrivacyNotice.archetype';
import { compositionService } from 'services/composition.service';

import HeadingDoc from 'ui/heading-doc';
import { Loading } from 'ui/loading';
import { FormGroup, Row, Column } from 'ui';
import { fetchPrivacyNoticeDocumentGroups } from 'features/privacy-notices/fetchPrivacyNoticeDocumentGroups';
import { IDocumentGroup } from 'services/document-group.service';

const privacyNoticeState = {
    name: { value: '', error: '' },
    language: { value: '', error: '' },
    body: { value: '', error: '' },
    summary: { value: '', error: '' },
    documentGroupId: { value: '', error: '' }
};

const privacyNoticeSchema = {
    name: {
        required: true,
    },
    language: {
    },
    body: {
        required: true,
    },
    summary: {
    },
    documentGroupId: {
        required: false,
    }
};

export function PrivacyNoticeCreate({
    params,
}: {
    params: Params
}): ReactElement {

    const team = useCurrentTeam();
    const [privacyNotices, isLoading] = usePrivacyNotices(team);

    const [ formError, setFormError ] = useState('');

    const [documentGroupsList, setDocumentGroupsList] = useState<IDocumentGroup[]>([]);

    useEffect(() => {
        let isMounted = true;

        fetchPrivacyNoticeDocumentGroups().then((groupsList) => {
            if (isMounted) {
                setDocumentGroupsList(groupsList);

                switch (groupsList.length) {
                case 0:
                    setFormError('No document groups are provided. It is not possible to upload a new privacy-notice');
                    break;
                case 1:
                    privacyNoticeState.documentGroupId.value = groupsList[0].uuid;
                    break;
                default:
                    privacyNoticeSchema.documentGroupId.required = true;
                    break;
                }
            }
        });

        return () => {
            isMounted = false;
        };
    },[]);

    async function onSubmitForm(state) {
        if (documentGroupsList.length !== 0) {
            setFormError('');
        }
        const maxVersion = privacyNotices.reduce((acc, privacyNotice) => privacyNotice.content.version > acc ? privacyNotice.content.version : acc, 0)

        const content = {
            name: state.name.value,
            body: state.body.value,
            summary: state.summary.value,
            language: state.language.value,
            version: maxVersion + 1,
        };

        if (!content.language) { delete content.language }
        if (!content.summary) { delete content.summary }

        const documentGroupUuid = state.documentGroupId.value;

        await compositionService.create({
            folderId: team.teamFolderId,
            archetypeName: 'privacy-notice',
            content: content,
            documentGroupUuid: documentGroupUuid
        });

        browserHistory.push("/admin_portal/privacy-notices");
    }

    const { state, handleOnChange, handleOnSubmit } = useForm(
        privacyNoticeState,
        privacyNoticeSchema,
        onSubmitForm,
    );

    useEffect(() => {
        const privacyNotice: PrivacyNoticeComposition = params.uuid
            ? privacyNotices.find(fetchedPrivacyNotice => fetchedPrivacyNotice.uuid === params.uuid)
            : undefined;
        if (privacyNotice) {
            handleOnChange({target: {name: 'body', value: privacyNotice.content.body}})
            handleOnChange({target: {name: 'language', value: privacyNotice.content.language}})
            handleOnChange({target: {name: 'name', value: privacyNotice.content.name}})
            handleOnChange({target: {name: 'summary', value: privacyNotice.content.summary}})
        }
    }, [privacyNotices])

    return <div className="page">
        <HeadingDoc title="Create Privacy Policy">
            You can create new version of privacy policy
        </HeadingDoc>
        {!!formError && <div className="alert alert-danger">{formError}</div>}
        <Loading show={isLoading}></Loading>
        {!isLoading && <form name="privacyNoticeForm"
            onSubmit={handleOnSubmit}
        >
            <Row>
                <Column md="12">
                    <div className={'form-group form-required ' + (state.name.error ? 'has-error' : '')}>
                        <label htmlFor="name">{_`Name`}</label>
                        <input type="text" name="name" className="form-control"
                            value={state.name.value}
                            onChange={handleOnChange}
                        />
                        {state.name.error && <div className="form-errors">
                            <div className="form-error">{state.name.error}</div>
                        </div>}
                    </div>
                    <div className={'form-group ' + (state.language.error ? 'has-error' : '')}>
                        <label htmlFor="language">{_`Language`}</label>
                        <input type="text" name="language" className="form-control"
                            value={state.language.value}
                            onChange={handleOnChange}
                        />
                        {state.language.error && <div className="form-errors">
                            <div className="form-error">{state.language.error}</div>
                        </div>}
                    </div>
                    <div className={'form-group form-required ' + (state.body.error ? 'has-error' : '')}>
                        <label htmlFor="body">{_`Body`}</label>
                        <textarea name="body" className="form-control" rows={15}
                            value={state.body.value}
                            onChange={handleOnChange}
                        />
                        {state.body.error && <div className="form-errors">
                            <div className="form-error">{state.body.error}</div>
                        </div>}
                    </div>
                    <div className={'form-group form-required ' + (state.summary.error ? 'has-error' : '')}>
                        <label htmlFor="summary">{_`Summary`}</label>
                        <textarea name="summary" className="form-control" rows={15}
                            value={state.summary.value}
                            onChange={handleOnChange}
                        />
                        {state.summary.error && <div className="form-errors">
                            <div className="form-error">{state.summary.error}</div>
                        </div>}
                    </div>
                    { documentGroupsList.length > 1 && (
                        <div className={'form-group form-required ' + (state.documentGroupId.error ? 'has-error' : '')}>
                            <label htmlFor="documentGroupId">{_`Document group `}</label>
                            <select name="documentGroupId" className='form-control' onChange={handleOnChange} >
                                <option key={'default'} >{_`-- Select document group --`}</option>
                                {documentGroupsList.map((documentGroup, idx) => {
                                    return (
                                        <option key={idx} value={documentGroup.uuid}>{documentGroup.name}</option>
                                    );
                                })}
                            </select>
                            {state.documentGroupId.error && <div className="form-errors">
                                <div className="form-error">{state.documentGroupId.error}</div>
                            </div>}
                        </div>
                    )
                    }
                </Column>
            </Row>
            <hr />
            <Row>
                <Column md="12">
                    <FormGroup>
                        <input type="submit" className="btn btn-success" value={_`Add Privacy Policy`}/>
                    </FormGroup>
                </Column>
            </Row>
        </form>}
    </div>
}
