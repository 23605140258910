/* eslint-disable indent */
import React, { FC, useCallback, useMemo, useState } from 'react';
// components
import { ModalDialog } from 'common/modalDialog';
import AdvancedSearchDefinitionForm from 'features/patients/advanced-search/AdvancedSearchDefinitionForm';
// hooks
import { useDepartmentLocation } from 'features/department/department.context';
import { useValidatorRoot } from 'common/ui/validation/NestedComponentValidator';
// services
import _ from 'services/i18n';
// utils
import { getSearchDefinitionCopy } from './AdvancedSearchSetupModal.utils';
// interfaces
import { AdvancedSearch, AdvancedSearchDefinition } from 'models/AdvancedSearchDefinition';
import { AdvancedSearchProps } from './AdvancedSearchSetupModal.interface';
import { addOrUpdateDepartmentFolderLabel } from 'features/department/department.utils';

const AdvancedSearchSetupModal: FC<AdvancedSearchProps> = ({
    searchService,
    searchConfigs,
    searchComposition,
    onClose,
    onSave
}) => {
    const currentLocation = useDepartmentLocation();
    const validator = useValidatorRoot();

    const [allowSave, setAllowSave] = useState<boolean>(true);
    const [formError, setFormError] = useState<string[]>([]);

    const searchDefinition: AdvancedSearchDefinition = useMemo(() => {
        if (!searchConfigs) {
            return null;
        }
        const rootConfig = searchConfigs.find(
            (config) => config.documentType == 'demographics',
        );
        return getSearchDefinitionCopy(rootConfig, searchComposition);
    }, [searchConfigs, searchComposition]);

    const doSave = useCallback(() => {
        if (!validator.validate()) {
            return Promise.reject();
        }

        setAllowSave(false);

        const compositionContent: AdvancedSearch = {
            ...searchComposition?.content,
            ...searchDefinition,
            viewIdentifier: '',
            viewUpdatedAt: ''
        };

        compositionContent.name = compositionContent.name.trim();
        //todo - for now, we'll only set the label when creating a new search.
        // later, we'll add functionality to save (a copy?) to a new location.
        if (!searchComposition) {
            addOrUpdateDepartmentFolderLabel(compositionContent, currentLocation.currentLocation);
        }

        const response = searchComposition
            ? searchService.updateAdvancedSearch(searchComposition.uuid, compositionContent)
            : searchService.createAdvancedSearch(compositionContent);

        return response
            .then(onSave)
            .catch(() => {
                setAllowSave(true);
                setFormError(['Sorry, something went wrong while saving your search. Please try again later.']);
            });
    }, [currentLocation.currentLocation, onSave, searchComposition, searchDefinition, searchService, validator]);

    return (
        <ModalDialog
            size={'lg'}
            title={_`Patient Filter`}
            buttons={
                (
                    <>
                        <button
                            className="btn btn-default btn-primary"
                            onClick={doSave}
                            disabled={!allowSave}
                        >
                            Save
                        </button>
                        <button className="btn btn-default" onClick={onClose}>
                            Cancel
                        </button>
                    </>
                )
            }
            onClose={onClose}
            overflow={true}
        >
            <form
                id="advancedSearchForm"
                name="advancedSearchForm"
                onSubmit={(event) => event.preventDefault()}
            >
                {formError.length > 0 && (
                    <div className="alert alert-danger">
                        <ul>
                            {formError.map((error, i) => {
                                return <li key={i}>{error}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <AdvancedSearchDefinitionForm
                    documentConfigs={searchConfigs}
                    searchRequest={searchDefinition}
                    validator={validator}
                />
            </form>
        </ModalDialog>
    );
};

export default AdvancedSearchSetupModal;
