import React from 'react';
import View from './view';
import BaseComponent from 'components/BaseComponent';
import _ from 'services/i18n';
import BaseInput from 'components/form-panel/base-input';

/**
 * Represents a selector field in a form panel.
 *
 * Props (data):
 *  - label = Required. A text label identifying the input field.
 *  - id = Required. An identifier for the input field.
 *  - helpText = Optional. Help text to display below the input field.
 *  - required = Optional. Boolean indicating if a value must be entered in this field. Defaults to false.
 *  - readOnly = Optional. Boolean flag indicating if the input element is readOnly. The value will not be reported to the parent form.
 *  - disabled = Optional. Boolean flag indicating if the input element is disabled. The value will not be reported to the parent form.
 *  - value = Optional. The raw value in the input field. Only set this if you intend to respond to this control's onChange or the form's onChange events.
 *  - initialValue = Optional. The initial value for the form. Ignored after construction, or if value is specified.
 *  - placeholder = Optional. Placeholder text to display in the input field if there is no value present.
 *  - hideValidationSuccess = Optional. Defaults to false. If true, the component will not show a green tick when validation passes. This is useful where such information would be ambiguous, e.g. on a password field.
 *
 * Props (handlers):
 *  - onChange = Required. A function called when the value changes. Parameters are: id, rawValue, effectiveValue, validated. NOTE: This is provided automatically by the parent form if used directly inside FormPanel.
 *  - onValidate = Optional. A custom validation function which will be called to validate the value. It should return true if the value is valid, or if not it should return a string containing an error message, or an array of multiple error messages. This will only be called if any automated validation passes.
 *
 */
export default class RelativeDateInput extends BaseInput {
    constructor (props) {
        super(props);

        let value = this.props.value || this.props.initialValue || {
                years: 0,
                months: 0,
                days: 0,
                hours: 0,
                minutes: 0
            };

        if (typeof(value) != 'object' || !value.hasOwnProperty('years') || !value.hasOwnProperty('months') || !value.hasOwnProperty('days') || !value.hasOwnProperty('hours')) {
            console.warn('value is not valid, resetting to blank structure');
            value = {
                years: 0,
                months: 0,
                days: 0,
                hours: 0,
                minutes: 0
            };
        }

        this.state = {
            // Boolean flag indicating if validation has been run, regardless of whether it passed or failed.
            isValidated: false,

            // Boolean flag specifying if validation should be shown in the user interface.
            // This is used to avoid displaying errors which would not be helpful, e.g. reporting
            //  that a value is required before the user has had a chance to enter anything.
            // This is ignored if isValidated is false or props.forceShowValidation is true.
            showValidation: false,

            // Stores any validation error messages.
            // If the array if empty, validation either passed or hasn't been done yet.
            errors: [],
            value: value
        };

        if (this.props.label == '') {
            console.warn('label prop must not be empty.');
        }

        if (this.props.id == '') {
            console.warn('id prop must not be empty.');
        }

        if (this.props.onChange == null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }

        this.bindEvents([
            'Change',
            'LoseFocus'
        ]);

        this.bindFunctions([]);

        this.View = View;
    }


    componentWillMount () {
        // Validation will notify the parent of the initial value.
        this.initialValidation();
    }

    componentWillReceiveProps (newProps) {

        if (this.props.onChange == null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }

        let revalidate = false, changed = false, newState = {},
            rawValue = newProps.value;

        // We need to avoid updating data unless necessary.
        // This prevents UI glitches and potential infinite event loops.

        if (rawValue != this.state.rawValue) {
            // The contents of the input field have been changed from outside.
            newState.value = rawValue;
            changed = true;
        }

        if (newProps.onValidate != this.props.onValidate) {
            // The custom validator has changed.
            revalidate = true;
        }

        if (newProps.onChange != this.props.onChange) {
            // The parent's change handler has changed.
            // Revalidation notifies the parent of the current value.
            revalidate = true;
        }

        if (changed) {
            this.setState(
                newState,
                () => {
                    if (revalidate) {
                        this.initialValidation();
                    }
                }
            );
        } else if (revalidate) {
            this.initialValidation();
        }
    }

    initialValidation () {
        const value = false;

        // Run validation and notify the parent of the initial value.
        // However, only report the validation result to the UI if the
        //  initial value was not empty, or we were already reporting it.
        this.validateEffectiveValue(
            value != '' || this.state.showValidation,
            true
        );
    }

    handleChange (e) {
        let value = this.state.value;

        value[e.target.name] = e.target.value;

        this.setState({
            value: value
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.props.id, this.state.value, this.state.value, this.state.errors.length == 0);
            }
        });
    }

    /**
     * Event handler: Keyboard focus has moved off the input field.
     */
    handleLoseFocus (e) {
        // Make sure any validation errors are displayed.
        this.setState({
            showValidation: true
        });
    }

    /**
     * Validate the current effective value.
     * This operates on stored state.
     * Parameters:
     *  - showValidation = Optional. If true (default), the validation result will be shown in the user interface. If false, the interface won't show any validation status.
     *  - notifyParent = Optional. If true (default), the value and its validation status will be sent to the parent's onChange handler.
     *  - cb = Optional. A call-back to trigger after validation has finished and any notifications have been dispatched.
     */
    validateEffectiveValue (showValidation = true, notifyParent = true, cb = null) {

        // Don't perform any validation for a control that is readOnly or disabled.
        if (this.props.readOnly || this.props.disabled) {
            this.setState({
                isValidated: false,
                showValidation: showValidation,
                errors: []
            }, () => {
                if (notifyParent && this.props.onChange) {
                    // Notify the parent that the value doesn't exist.
                    this.props.onChange(this.props.id, undefined, undefined, undefined);
                }
                if (cb) {
                    cb();
                }
            });

            return;
        }

        // Validate the data.
        let result = this.getValidationResult();
        if (result === true) {
            result = [];
        }

        this.setState({
            isValidated: true,
            showValidation: showValidation,
            errors: result
        }, () => {
            if (notifyParent && this.props.onChange) {
                // Report the result to the parent.
                this.props.onChange(this.props.id, this.state.rawValue, this.state.effectiveValue, this.state.errors.length == 0);
            }
            if (cb) {
                cb();
            }
        });
    }

    /**
     * Run validation on the stored effective value, and return the result.
     * This will not update current state.
     * This will return true if the validation passes.
     * Otherwise it will return an array of error messages.
     */
    getValidationResult () {
        // Note: It's important to incorporate the 'required' check into the length checks
        //  so that we can give more helpful error messages. E.g. it's not helpful to say
        //  "this must not be empty" and then say "this must be at least 3 characters long".
        // The error messages should be consistent.

        return this.getCustomValidationResult(this.state.effectiveValue);
    }
}

