import React, { Component } from 'react';
import BasePage from 'components/page/base';
import Events from './_events';
import _ from 'services/i18n';
import DataStore from 'services/data-store';
import HeadingDoc from 'ui/heading-doc';
import { Row, Column, ResponsiveTable } from 'ui';
import NHSNumber from 'ui/nhs-number';
import { questionnaireService } from 'services/questionnaire.service';
import { notificationLogService } from 'services/notificationLog.service';
import { demographicsService } from 'services/demographics.service';
import { DateTime } from 'common/datetime/DateTime';

export default class OverdueQuestionnairesPage extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            results: [],
            patientLookup: new Map()
        };

        this.bindEvents([], new Events() as any);

        this.bindFunctions(['loadComponent']);
    }

    pageTitle () {
        return _`${this.$ps()} Overdue Questionnaires | iFocus Clinical Portal`;
    }

    componentDidMount () {
        this.loadComponent();
    }

    loadComponent () {

        const params: any = {
            folderId: 'all',
            search: {
                'content.due_date': { $lt: Date.now() },
                'content.status': { $in: ['in_progress', 'Assigned'] }
            }
        };

        Promise.all([
            questionnaireService.search(
                Object.assign({
                    archetypeName: 'coopQuestionnaireResponse'
                }, params)
            ),
            questionnaireService.search(
                Object.assign({
                    archetypeName: 'questionnaireResponse'
                }, params)
            ),
            notificationLogService.search({
                folderId: 'all',
                search: {
                    'content.document_type': 'coopQuestionnaireResponse'
                }
            })
        ]).then((responses) => {
            const questionnaires = responses[0].message.results.concat(responses[1].message.results);
            const notifications = responses[2];
            const notificationLookup = new Map();
            const folderList = new Map();
            const folderListArray = [];
            const sortedResults = questionnaires.sort((a, b) => {
                return a.content.due_date > b.content.due_date;
            });

            sortedResults.map((item) => {
                folderList.set(item.folder_id, item.folder_id);
            });

            folderList.forEach((folderId) => {
                folderListArray.push(folderId);
            });

            notifications.message.results.map((item) => {
                const existing = notificationLookup.get(item.content.document_uuid);

                if (existing && item.content.sent_on <= existing) {
                    return;
                }

                notificationLookup.set(item.content.document_uuid, item.content.sent_on);
            });

            this.setState({
                results: sortedResults,
                notifications: notificationLookup
            });

            return Promise.all(folderListArray.map((folderId) => {
                return demographicsService.getFirst({ folderId })
                    .then((demographics) => {
                        this.state.patientLookup.set(folderId, demographics.content);
                    });
            }))
                .then(() => {
                    this.forceUpdate();
                });
        });
    }

    render () {
        return <Page state={this.state} events={this.events} $p={this.$p} $ps={this.$ps} />;
    }
}

const Page = ({ $p, $ps, events, state }) => {
    return (
        <div className="page page-overdue-questionnaires">
            <HeadingDoc title={_`${$p()} Overdue Questionnaires`}>
                {_`This page will show any ${$ps()} who have questionnaires that are overdue.
                    You can then choose to send them a reminder.`}
            </HeadingDoc>
            <Row>
                <Column md="12">
                    <ResponsiveTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>NHS Number</th>
                                <th>Due Date</th>
                                <th>Last Reminder Sent</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <Results {...state} />
                    </ResponsiveTable>
                </Column>
            </Row>
        </div>
    );
};

const Results = ({ results, notifications, patientLookup }) => {
    const list = results.map((item, idx) => {
        return (
            <RowErrorBoundary key={idx}>
                <ResultRow {...item} patientLookup={patientLookup} reminder={notifications.get(item.uuid)} />
            </RowErrorBoundary>
        );
    });

    return (
        <tbody>{list}</tbody>
    );
};

const ResultRow = (props) => {
    const patient = props.patientLookup.get(props.folder_id) || getInviteFromFolderId(props.folder_id) || {
            // eslint-disable-next-line @typescript-eslint/camelcase
            preferred_name: 'User Not Found',
            // eslint-disable-next-line @typescript-eslint/camelcase
            nhs_number: ''
        }, actionList = [
            {
                type: 'callback', callback: () => {
                    console.log('Send Reminder Clicked');
                }, label: _`Send Reminder`
            },
        ];

    return (
        <tr>
            <td>{patient?.preferred_name?.given_name || patient?.name?.given_name}</td>
            <td><NHSNumber empty="n.a">{patient.nhs_number}</NHSNumber></td>
            <td><DateTime
                format="nhs_date_short"
            >{props.content.due_date}</DateTime></td>
            <td><DateTime
                format="nhs_date_short"
                empty="No reminder sent"
            >{props.reminder}</DateTime></td>
            <td>
                <button type="button" disabled className="btn btn-disabled">Send Reminder</button>
            </td>
        </tr>
    );
};

export class RowErrorBoundary extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            return null;
        }
        return this.props.children;
    }
}

function getInviteFromFolderId (folderId) {
    const patients = (DataStore.get('me.invites') || {});
    let item;

    for (const idx in patients) {
        if (Object.prototype.hasOwnProperty.call(patients, idx)) {
            item = patients[idx];
            if (item.team_folder_id == folderId) {
                return item;
            }
        }
    }
    return false;
}
