import React, { ReactNode } from 'react';
import { ResponsiveTable } from 'ui';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { getNamespaceLabel } from 'common/personPrimaryIdentifierHelpers';
import _ from 'services/i18n';
import { DashboardTabType } from 'features/patients/dashboard/DashboardNavigation';

type PatientTableProps = {
    isPatientsLoading: boolean;
    patientsTotal: number;
    activeTab: DashboardTabType;
    children: ReactNode;
}

export const PatientsTable = ({ isPatientsLoading, patientsTotal, children, activeTab }: PatientTableProps) => {
    let body = children;
    const teamPreferences = useTeamPreferences();

    if (isPatientsLoading) {
        body = (
            <tr>
                <td colSpan={5}>Loading. Please wait...</td>
            </tr>
        );
    } else if (patientsTotal === 0) {
        body = (
            <tr>
                <td colSpan={5}><strong>No records match the specified filter/search.</strong></td>
            </tr>
        );
    }

    return (
        <ResponsiveTable className="table table-bordered table-striped table-condensed">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Hospital Number</th>
                    {activeTab === 'registered' && <th>{_`Date Registered`}</th>}
                    {activeTab === 'invited' && <th>{_`Date Invited`}</th>}
                    <th>{getNamespaceLabel(teamPreferences && teamPreferences.primary_identifier)}</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {body}
            </tbody>
        </ResponsiveTable>
    );
};
