/* eslint-disable @typescript-eslint/camelcase */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router';
import { bulkOperationService } from 'services/bulk-operation.service';
import {
    Grid,
    GridBody,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow,
    SimpleGridCell,
    TABLE_FILTER
} from 'common/ui/grid';
import { useI18N } from 'common/i18n/useI18N';
import {
    BulkOperationStatus,
    FAILURE_STATUSES,
    getBulkOperationStatusDescription,
    getBulkOperationStatusLabels,
    getBulkOperationStatusLabel,
    getBulkOperationStatusValuesForLabel,
    INCOMPLETE_STATUSES
} from 'models/BulkOperationStatus';
import { TERActionTypes } from 'models/TerActionTypes';
import { Loading } from 'common/ui/alert-boxes';
import './bulkOperation.scss';
import {
    BulkOperationDetailResponse,
    BulkOperationFolderActionDetail,
    BulkOperationFolderDetail,
    BulkOperationRequest
} from 'models/BulkOperationRequests';
import { formatDateToString } from 'features/patients/advanced-search/ASBOWLUtils';
import { ModalDialog } from 'common/modalDialog';
import BulkOperationReview from './BulkOperationHistoryReview';
import { Filter } from 'common/ui/grid/paginatedTableReducer';
import Pagination from 'components/pagination';
import {
    useRealFilterChange
} from 'features/patients/advanced-search/AdvancedSearchResultsDisplay/AdvancedSearchResultsDisplay.hooks';

const perPage = 100;

type BulkOperationFolderActionDetailPreRendered = {
    folder_id: number;
    link: string;
    demographics: {
        formatted_name: string;
        nhs_number: string;
    };
    action: {
        action_description: string;
        status: BulkOperationStatus;
        status_label: string;
        status_description: string;
    };
}

type BulkOperationDetailResponsePreRendered = {
    bulkOperationRequest: BulkOperationRequest;
    actionDetails: BulkOperationFolderActionDetailPreRendered[];
}

const filterOptions = ['Any Incomplete', 'Any Failed', ...getBulkOperationStatusLabels()];

const preRenderAction = (
    bulkOperationDetails: BulkOperationDetailResponse,
    actionDetail: BulkOperationFolderActionDetail
): BulkOperationFolderActionDetailPreRendered => {
    return {
        folder_id: actionDetail.folder_id,
        link: `/clinical_portal/folder/${actionDetail.folder_id}/patient`,
        demographics: {
            formatted_name: formatName(actionDetail.demographics.name),
            nhs_number: getNhsNumber(actionDetail.demographics)
        },
        action: {
            action_description: TERActionTypes[getActionType(bulkOperationDetails, actionDetail.action.action_uuid)],
            status: actionDetail.action.status,
            status_label: getBulkOperationStatusLabel(actionDetail.action.status),
            status_description: getBulkOperationStatusDescription(actionDetail.action.status)
        }
    };
};

const preRender = (bulkOperationDetails: BulkOperationDetailResponse): BulkOperationDetailResponsePreRendered => {
    const preRenderActionDetail = (action: BulkOperationFolderActionDetail) => preRenderAction(bulkOperationDetails, action);
    return {
        bulkOperationRequest: bulkOperationDetails.bulkOperationRequest,
        actionDetails: bulkOperationDetails.actionDetails.map(preRenderActionDetail)
    };
};

const getStatusFilterValues = (statusFilter: string): string[] => {
    if (!statusFilter) {
        return [];
    }

    if (statusFilter === 'Any Incomplete') {
        return INCOMPLETE_STATUSES;
    }

    if (statusFilter === 'Any Failed') {
        return FAILURE_STATUSES;
    }

    return getBulkOperationStatusValuesForLabel(statusFilter);
};

export const BulkOperationView = (props) => {
    const uuid = props.params.uuid;
    const [bulkOperationDetail, setBulkOperationDetail] = useState<BulkOperationDetailResponsePreRendered>(null);
    const [bulkOperationDate, setBulkOperationDate] = useState<string>(null);
    const [bulkOperationActionsLoading, setBulkOperationActionsLoading] = useState<boolean>(true);
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
    const _ = useI18N();
    const [rawFilter, setRawFilter] = useState<Filter>({});

    const [page, setPage] = useState<number>(1);
    const [totalActionCount, setTotalActionCount] = useState<number>(0);

    const onFilterChange = useCallback(() => setPage(1), []);
    const realFilter = useRealFilterChange(rawFilter, onFilterChange);

    const getBulkOperationContent = useCallback(() => {
        const statusFilter = realFilter ? realFilter['action.status'] ?? '' : '';
        const statusValues = getStatusFilterValues(statusFilter);
        const ppi = realFilter ? realFilter['ppi'] ?? '' : '';
        bulkOperationService.getBulkOperationDetailByUuid(uuid, perPage, (page-1)*perPage, statusValues, ppi)
            .then(bulkOperationDetails => {
                const createdAt = bulkOperationDetails.bulkOperationRequest.created_at;
                const formattedDate: string = formatDateToString(createdAt, 'dddd Do MMMM YYYY [at] HH:mm');
                setBulkOperationDate(formattedDate);
                setTotalActionCount(bulkOperationDetails.currentActionCount);
                setBulkOperationDetail(preRender(bulkOperationDetails));
                setBulkOperationActionsLoading(false);
            });
    }, [uuid, page, realFilter]);

    useEffect(() => {
        setBulkOperationActionsLoading(true);
        getBulkOperationContent();
    }, [getBulkOperationContent]);

    return (
        <div className={'scrollable-table'}>
            <h1>Bulk Operation</h1>

            {(!bulkOperationActionsLoading && bulkOperationDetail.actionDetails.length === 0) &&
                <p>There are no actions to review.</p>
            }

            {bulkOperationActionsLoading && (
                <Loading />
            )}

            {!bulkOperationActionsLoading && bulkOperationDetail &&
                (
                    <Fragment>
                        <p>Here is  the detail for <strong>{bulkOperationDetail.bulkOperationRequest.content.name}</strong>, created on {bulkOperationDate}.</p>
                        <button className='btn btn-default btn btn-primary review-button' onClick={() => setShowReviewModal(true)}>Review Bulk Operation</button>
                        {showReviewModal && (
                            <ModalDialog size='md' onClose={() => setShowReviewModal(false)} title={bulkOperationDetail.bulkOperationRequest.content.name}>
                                <BulkOperationReview bulkOperation={bulkOperationDetail.bulkOperationRequest} bulkOperationDate={bulkOperationDate} />
                            </ModalDialog>
                        )}
                        <div className={'scrollable-table bulk'}>
                            <Grid onFilter={setRawFilter} filterable={true} filterState={rawFilter}>
                                <GridHeader>
                                    <GridHeaderRow>
                                        <GridHeaderCell
                                            sortable={false}
                                            filterable={false}
                                            field={''}
                                        >{_`Patient Name`}
                                        </GridHeaderCell>
                                        <GridHeaderCell
                                            sortable={false}
                                            filterable={true}
                                            filterType={TABLE_FILTER.INPUT}
                                            field={'ppi'}
                                        >{_`NHS Number`}
                                        </GridHeaderCell>
                                        <GridHeaderCell
                                            sortable={false}
                                            filterable={false}
                                            field={''}
                                        >{_`Action`}
                                        </GridHeaderCell>
                                        <GridHeaderCell
                                            sortable={false}
                                            filterable={true}
                                            filterType={TABLE_FILTER.SELECT}
                                            filterOptions={filterOptions}
                                            field={'action.status'}
                                        >{_`Status`}
                                        </GridHeaderCell>
                                    </GridHeaderRow>
                                </GridHeader>
                                <GridBody>
                                    {bulkOperationDetail.actionDetails.map((actionDetail, index: number) => (
                                        <BulkOperationViewRow
                                            key={index}
                                            actionDetail={actionDetail}
                                        />
                                    ))}
                                </GridBody>
                            </Grid>
                        </div>
                        <div>
                            <Pagination
                                pageCount={Math.ceil(totalActionCount / perPage)}
                                currentPage={page}
                                onChange={setPage}
                            />
                        </div>
                    </Fragment>
                )
            }
        </div>
    );
};

const getNhsNumber = (folderDetail: BulkOperationFolderDetail): string => {
    const folderDetailIdentifier: string = folderDetail.person_primary_identifier?.namespace;
    const folderNhsIdentifier: string = folderDetail.identifiers.NHS_Number;
    if (folderNhsIdentifier) {
        return folderNhsIdentifier;
    }
    if (!folderNhsIdentifier && folderDetailIdentifier === 'NHS_Number') {
        return folderDetail.person_primary_identifier.value;
    }
    if (!folderNhsIdentifier && !folderDetailIdentifier) {
        return '';
    }
};

const getActionType = (bulkOperationDetail: BulkOperationDetailResponse, actionUuid: string) => {
    const actions = bulkOperationDetail.bulkOperationRequest.content.actions;
    if (actions.length === 1) {
        return actions[0]?.action_type;
    }
    return actions.find(action => action.action_uuid === actionUuid)?.action_type;
};

const formatName = (name): string => {
    if (!name) {
        return '';
    }
    return `${name.given_name} ${name.family_name}`.trim();
};

const BulkOperationViewRow = (props: {
    actionDetail: BulkOperationFolderActionDetailPreRendered;
}) => {
    const actionDetail = props.actionDetail;

    return (
        <GridRow>
            <SimpleGridCell>
                <Link to={actionDetail.link}>
                    {actionDetail.demographics.formatted_name}
                </Link>
            </SimpleGridCell>
            <SimpleGridCell>
                <Link to={actionDetail.link}>
                    {actionDetail.demographics.nhs_number}
                </Link>
            </SimpleGridCell>
            <SimpleGridCell>
                <div className="no-wrap">
                    {actionDetail.action.action_description}
                </div>
            </SimpleGridCell>
            <SimpleGridCell>
                <div className="no-wrap" title={actionDetail.action.status_description}>
                    {actionDetail.action.status_label}
                </div>
            </SimpleGridCell>
        </GridRow>
    );
};
