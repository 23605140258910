import React, { FC, useState } from 'react';
import Select from 'react-select';
// components
import { LoadingError } from 'common/ui/alert-boxes';
import AdvancedSearchCriterionValueInput from 'features/patients/advanced-search/AdvancedSearchCriterionValueInput';
// hooks
import {
    useCriterionComparator,
} from 'features/patients/advanced-search/AdvancedSearchSetupHooks';
// utils
import { getComparatorDisplayValue } from 'models/AdvancedSearchDefinition';
import { isValueValid } from './AdvancedSearchCriterionRow.utils';
// interfaces
import { AdvancedSearchCriterionRowProps } from './AdvancedSearchCriterionRow.interface';
// styles
import './AdvancedSearchCriterionRow.scss';
import { useValidator } from 'common/ui/validation/NestedComponentValidator';

const AdvancedSearchCriterionRow: FC<AdvancedSearchCriterionRowProps> = (
    props,
) => {
    const comparatorDisplayOptions = props.fieldConfig.comparators.map(comparator => getComparatorDisplayValue(comparator));

    const [error, setError] = useState<string>(null);
    const [selectedComparator, setSelectedComparator] = useCriterionComparator(props.criterion);
    const selectedComparatorDisplayValue = getComparatorDisplayValue(selectedComparator);

    useValidator(props.validator, () => {
        const isValid = props.criterion.comparator &&
            isValueValid(props.criterion.value, props.criterion.comparator);
        setError(
            isValid
                ? null
                : 'You must complete all the fields for this criterion.',
        );
        return isValid;
    });

    return (
        <>
            {error && <LoadingError>{error}</LoadingError>}
            <div className={'advanced-search-query-request-criterion'}>
                <label>
                    <strong>{props.fieldConfig.name}</strong>
                </label>
                <Select
                    id={'comparator-select'}
                    value={selectedComparatorDisplayValue}
                    options={comparatorDisplayOptions}
                    onChange={(newSelection) =>
                        setSelectedComparator(newSelection.value)
                    }
                    className={'react-select-input-container'}
                    classNamePrefix={'react-select-input'}
                    isSearchable={false}
                />
                <AdvancedSearchCriterionValueInput
                    documentType={props.documentType}
                    config={props.fieldConfig}
                    comparator={selectedComparator}
                    criterionValue={props.criterion.value}
                    validator={props.validator}
                    setError={setError}
                />
                <button
                    type="button"
                    className="btn btn-xs btn-primary glyphicon glyphicon-remove"
                    onClick={props.onRemove}
                />
            </div>
        </>
    );
};

export default AdvancedSearchCriterionRow;
