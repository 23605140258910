import { CompositionService } from './composition.service';
import { IFolderSelectorParam } from 'phr-api-client';
import { documentGroupService } from './document-group.service';
import { IRole } from 'phr-api-client';
import { DataStore } from 'services/data-store';

class GoalsService extends CompositionService {

    get archetypeName(): string {
        return 'user-goal';
    }

    create({role, content, folderId}: {role?: IRole, content: any, folderId?: IFolderSelectorParam}): Promise<any> {
        return super.create({role, content, folderId})
            .then((uuid) => {
                return documentGroupService.changeDocumentGroup({
                    documentGroupName: 'Goals',
                    forDocument: {
                        uuid,
                        archetypeName: this.archetypeName
                    },
                    role,
                    folderId,
                });
            });
    }
}

export const goalsService = new GoalsService();
