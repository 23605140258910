import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.less';

export interface WysiwygUpdateFunc {
    target: {
        name: string;
        value: string;
        editorState: EditorState;
        getHTMLAndText: () => ({ rawContentState: Array<{ text: string }>; value: string });
        setEditorState: (val: EditorState) => void;
        textarea?: HTMLTextAreaElement;
        updateTextArea: boolean;
        setHtmlEditorContent: (val) => void;
    };
}

export interface WysiwygProps {
    onChange: (val: WysiwygUpdateFunc) => void;
    value: string;
    triggerContentUpdate?: boolean;
}

export const Wysiwyg: FunctionComponent<WysiwygProps> = (props) => {
    const {
        onChange,
        value,
        triggerContentUpdate
    } = props;
    const [txtRef, setRef] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [htmlEditorContent, setHtmlEditorContent] = useState(props.value);
    const [showEditorCode, setShowEditorCode] = useState(false);

    const initialiseEditor = () => {
        const contentBlock = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    };

    useEffect(() => {
        if (!triggerContentUpdate) return;

        setEditorState(initialiseEditor());
    }, [triggerContentUpdate]);
    
    useMemo(() => {
        setEditorState(initialiseEditor());
    }, []);

    const uploadCallback = (file) => {
        return new Promise(
            (resolve) => {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        resolve({ data: { link: e.target.result } });
                    };
                    reader.readAsDataURL(file);
                }
            }
        );
    };

    const toggleEditorCode = () => {
        setShowEditorCode(!showEditorCode);
        onChangeCallback(editorState);
    };

    const ShowEditorCode = () => (
        <div className="rdw-option-wrapper"
            onClick={toggleEditorCode}>
            {showEditorCode ? 'Hide' : 'Show'} HTML Code
        </div>
    );

    const getHTMLAndText = (content?) => {
        const draftContent = content || editorState.getCurrentContent();
        const converted = convertToRaw(draftContent);
        const htmlEditorContent = draftToHtml(converted);
        return { rawContentState: converted.blocks , value: htmlEditorContent };
    };

    const onChangeCallback = (editorState) => {
        setEditorState(editorState);
        const htmlEditorContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setHtmlEditorContent(htmlEditorContent);
        onChange({ target: {
            value: htmlEditorContent,
            name: 'html',
            editorState,
            setEditorState,
            textarea: txtRef,
            updateTextArea: showEditorCode,
            setHtmlEditorContent,
            getHTMLAndText
        } });
    };

    const onEditEditorHTML = e => {
        const htmlEditorContent = formatHTML(e.target.value);
        let editorState;
        const contentBlock = htmlToDraft(htmlEditorContent);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty();
        }

        onChangeCallback(editorState);
        setHtmlEditorContent(htmlEditorContent);
    };

    const formatHTML = (htmlEditorContent) => {
        if (htmlEditorContent === null) {
            return '';
        }
        const pTagBuffer = '<p></p>';

        // https://github.com/jpuri/react-draft-wysiwyg/issues/609#issuecomment-386050815
        const requireWorkaround = !htmlEditorContent.startsWith(pTagBuffer) && htmlEditorContent !== '';

        return requireWorkaround ? `${pTagBuffer}${htmlEditorContent}` : htmlEditorContent;
    };


    return (
        <Fragment>
            <Editor
                toolbar={{
                    options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'history'],
                    image: {
                        urlEnabled: false,
                        uploadEnabled: true,
                        uploadCallback: uploadCallback,
                        previewImage: true,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                        alt: { present: false, mandatory: false },
                        defaultSize: {
                            present: false,
                            height: 'auto',
                            width: 'auto',
                        },
                    },
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                }}
                toolbarCustomButtons={[<ShowEditorCode key={'show-editor'} />]}
                editorState={editorState}
                wrapperClassName="wysiwyg-wrapper"
                editorClassName={showEditorCode ? 'wysiwyg-editor html-active' : 'wysiwyg-editor' }
                onEditorStateChange={onChangeCallback} />

            <textarea className="form-control wysiwyg-active"
                style={{
                    width: (showEditorCode ? '100%' : 0),
                    height: (showEditorCode ? 'auto' : 0),
                    minHeight: (showEditorCode ? '250px' : 0),
                    visibility: (showEditorCode ? 'unset' : 'hidden')
                }}
                value={htmlEditorContent}
                ref={(el) => { setRef(el); }}
                onChange={onEditEditorHTML} />
        </Fragment>
    );
};
