import React from 'react';
import PropTypes from 'prop-types'
import lodash from 'lodash';

/**
 * Displays the contents of a table cell showing whether a reasonable adjustment (transport or interpreter) is required and/or arranged for an appointment in the transport and interpreter worklist.
 *
 * Props (data):
 * - type = Required. String specifying what kind of reasonable adjustment this is. Valid values are 'transport' and 'interpreter'.
 * - required = Required. Boolean specifying if the reasonable adjustment is required; e.g. does the patient require transport to be arranged for this appointment?
 * - arranged = Required. Boolean specifying whether the reasonable adjustment has been arranged.
 * - updating = Optional. Boolean specifying whether this field is currently being updated. Assumed to be false if omitted.
 * - failed = Optional. Boolean specifying whether an update on this field has failed. Assumed to be false if omitted.
 *
 * Props (functions):
 * - onArranged = Required. Callback to be triggered when the user indicates that the reasonable adjustment has been arranged. No arguments are necessary.
 * - onUndo = Required. Callback to be triggered when the user indicates the reasonable adjustment has NOT been arranged. This is expected to be used to reverse a mistake. No arguments are necessary.
 */
const ReasonableAdjustmentField = ({type, required, arranged, details, updating, failed, onArranged, onUndo}) => {
    const typeLower = type.toLowerCase();
    if (typeLower != 'transport' && typeLower != 'interpreter') {
        console.warn(`ReasonableAdjustmentField - unexpected type: ${type}`);
    }
    const displayType = lodash.capitalize(type);

    const updatingMessage = (
        <div>
            <span className='glyphicon glyphicon-refresh icon-spin' />{' '}
            Saving...
        </div>
    );

    const undoButton = (
        <button className='btn btn-default btn-xs' onClick={onUndo}>
            Undo
        </button>
    );

    const arrangedButton = (
        <button className='btn btn-default btn-xs' onClick={onArranged} >
            Done
        </button>
    );

    if (failed) {
        return (
            <div>
                <span className='glyphicon glyphicon-exclamation-sign text-danger' /><br />
                Unable to update appointment. Please try again later.
            </div>
        );
    }

    if (!required) {
        return (<div className='text-muted'><br/><br/>{displayType} is not required.</div>);
    }

    if (arranged) {
        return (
            <div className='ti-reasonable-adjustment'>
                <div className='status-arranged'>
                    <span className='glyphicon glyphicon-ok-sign text-success'/><br />
                    {displayType} has been arranged.
                </div>
                {details &&
                <div className="details">
                    <strong>Details:</strong> {details}
                </div>}
                <br/>
                <div>
                    {updating ? updatingMessage : undoButton}
                </div>
                <br/>
            </div>
        );
    }

    return (
        <div className='ti-reasonable-adjustment'>
            <div className='status-required'>
                <span className='glyphicon glyphicon-info-sign text-warning'/><br />
                {displayType} is required.
            </div>
            {details &&
            <div className="details">
                <strong>Details:</strong> {details}
            </div>}
            <br/>
            <div>
                {updating ? updatingMessage : arrangedButton}
            </div>
            <br/>
        </div>
    );
};

export default ReasonableAdjustmentField;

ReasonableAdjustmentField.propTypes = {
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    arranged: PropTypes.bool.isRequired,
    updating: PropTypes.bool,
    failed: PropTypes.bool,
    onArranged: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired
};
