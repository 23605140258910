/* eslint-disable indent */
// TODO try to extract some logic out of this file
import React, { FC, Fragment, useMemo } from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
// components
import {
    Grid,
    GridBody,
    GridCell,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow,
    GridSortState,
} from 'common/ui/grid';
import { LinkedCheckbox } from 'ui/checkbox/LinkedCheckbox';
import { SelectionOptionsDropdown } from 'components/dataSource/FolderSelectionStateOptions';
import AdvancedSearchResultsTableRowActions from 'features/patients/advanced-search/AdvancedSearchResultsTableRowActions';
// hooks
import { useSelectionStateContext } from 'components/dataSource/FolderSelectionState';
import { useStateSourceCallback } from 'components/dataSource/StateLinking';
// utils
import {
    MultipleRowExpansionState,
    useExpansionStateContext,
    ExpansionStateContext,
} from 'features/patients/advanced-search/MultipleRowExpansionState';
import {
    AdvancedSearchDocumentConfig,
    AdvancedSearchDocumentField,
    AdvancedSearchQueryDefinition,
    AdvancedSearchQueryResult,
    getChildQueryDefinitionCardinality,
    getDisplayableChildDefinitions,
    getDocumentSearchConfig,
    getFieldConfig,
} from 'models/AdvancedSearchDefinition';
// interfaces
import { Filter } from 'common/ui/grid/paginatedTableReducer';
// styles
// TODO extract relevant styles into separate file
import '../AdvancedSearchResultDisplay.scss';
import getAdvancedSearchTableViewButtonData from './AdvancedSearchTableViewButton';

const demographicsPathsToMakeLinks = [
    'folder.person_primary_identifier.value',
    'content.name.given_name',
    'content.name.family_name',
];

function mapSearchDefinitionToHeaderGroupCell(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition,
    pathPrefix: string,
) {
    const documentSearchConfig = getDocumentSearchConfig(
        documentSearchConfigs,
        queryDefinition.documentType,
    );
    const path = pathPrefix ? `${pathPrefix}.header.results` : 'header.results';
    const colSpan = calculateGroupedHeaderColSpan(queryDefinition);

    return (
        <GridHeaderCell
            key={path}
            className={
                'advanced-search-child-column-leading advanced-search-column-group-header'
            }
            sortable={false}
            filterable={false}
            colSpan={colSpan}
        >
            {documentSearchConfig.name}
        </GridHeaderCell>
    );
}

function mapSearchDefinitionToColumnHeaderGroupCells(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition,
) {
    const headerGroups = [
        mapSearchDefinitionToHeaderGroupCell(
            documentSearchConfigs,
            queryDefinition,
            '',
        ),
    ];

    if (queryDefinition.children) {
        const displayableChildren = getDisplayableChildDefinitions(documentSearchConfigs, queryDefinition);
        const childHeaders = displayableChildren.map((child, index) => {
            return mapSearchDefinitionToHeaderGroupCell(
                documentSearchConfigs,
                child,
                `header.children[${index}]`,
            );
        });
        headerGroups.push(...childHeaders);
    }

    return headerGroups;
}

function mapSearchOutputToColumnHeaderCell(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition,
    output: string,
    allowSort: boolean,
    allowFilter: boolean,
    pathPrefix: string,
    index: number,
) {
    const documentSearchConfig = getDocumentSearchConfig(
        documentSearchConfigs,
        queryDefinition.documentType,
    );
    const outputConfig = getFieldConfig(documentSearchConfig.fields, output);
    const fullPath = `${pathPrefix}.${output}`;
    const className = index == 0 ? 'advanced-search-child-column-leading' : '';
    return (
        <GridHeaderCell
            key={fullPath}
            className={className}
            sortable={allowSort}
            filterable={allowFilter}
            field={fullPath}
        >
            {outputConfig.name}
        </GridHeaderCell>
    );
}

function mapSearchOutputsToColumnHeaderCellsWithTaskColumn(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition,
    allowSort: boolean,
    allowFilter: boolean,
    pathPrefix: string
) {
    const cells = queryDefinition.outputs.map((output, outputIndex) => {
        return mapSearchOutputToColumnHeaderCell(
            documentSearchConfigs,
            queryDefinition,
            output,
            allowSort,
            allowFilter,
            pathPrefix,
            outputIndex,
        );
    });

    if (shouldHaveActionsColumn(queryDefinition)) {
        cells.push(
            <GridHeaderCell
                key={`${pathPrefix}.UITasks`}
                sortable={false}
                filterable={false}
            >
                Task
            </GridHeaderCell>
        );
    }

    return cells;
}

function mapSearchOutputsToColumnHeaderCells(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition
) {
    const outputs = mapSearchOutputsToColumnHeaderCellsWithTaskColumn(
            documentSearchConfigs,
            queryDefinition,
            true,
            true,
            'result'
    );

    if (queryDefinition.children) {
        const displayableChildren = getDisplayableChildDefinitions(documentSearchConfigs, queryDefinition);
        const childOutputs = displayableChildren.map((child, childIndex) => {
            return mapSearchOutputsToColumnHeaderCellsWithTaskColumn(
                    documentSearchConfigs,
                    child,
                    false,
                    false,
                    `result.children.${childIndex}`
                );
        });
        childOutputs.forEach((childOutputGroup) => {
            outputs.push(...childOutputGroup);
        });
    }

    return outputs;
}

function mapOutputsToEmptyTableRowData(
    queryDefinition: AdvancedSearchQueryDefinition,
    pathPrefix: string,
    className?: string
): ResultDisplayData[] {
    const outputToEmptyCell = (
        output: string,
        index: number,
        pathPrefix: string,
    ): ResultDisplayData => {
        const fullPath = `${pathPrefix}.${output}`;
        const classNameSuffix =
            index == 0 ? ' advanced-search-child-column-leading' : '';

        return { key: fullPath, cellClassName: `${className} ${classNameSuffix}`, data: '\u00A0' };
    };

    const emptyCells =  queryDefinition.outputs.map((output, index) =>
        outputToEmptyCell(output, index, `${pathPrefix}.results`),
    );
    if (shouldHaveActionsColumn(queryDefinition)) {
        emptyCells.push(outputToEmptyCell('UITasks', emptyCells.length, `${pathPrefix}.results`));
    }
    return emptyCells;

}

type ResultDisplayData = {
    key: string;
    cellClassName: string;
    data: string | JSX.Element;
};

type ElementMayBeLeading = ResultDisplayData & {
    isLeading: boolean;
}

function buildMultipleResultNestedRows(
    parentResult: AdvancedSearchQueryResult,
    resultRows: ElementMayBeLeading[][],
    childPathPrefix: string,
    childDefinition: AdvancedSearchQueryDefinition,
): ResultDisplayData[] {
    const resultColumns = _.unzip(resultRows);

    return resultColumns.map((column, index) => getMultipleResultColumnData(
        parentResult.uuid,
        column,
        index,
        childDefinition.outputs[index],
        childPathPrefix
    ));
}

function getMultipleResultColumnData(
    parentResultUuid: string,
    columnData: ElementMayBeLeading[],
    columnNumber: number,
    output: string,
    pathPrefix: string
): ResultDisplayData {

    const keyBase = pathPrefix
        ? `${pathPrefix}.results[${parentResultUuid}]`
        : `results[${parentResultUuid}]`;
    const classNameSuffix = columnData[0].isLeading ? ' advanced-search-child-column-leading' : '';

    return {
        key: `${keyBase}.${output}`,
        cellClassName: 'advanced-search-multi-result-wrapper-cell' + classNameSuffix,
        data: (
            <MultipleResultColumn
                columnData={columnData}
                columnNumber={columnNumber}
                output={output}
                keyBase={keyBase}
            />
        )
    };
}

function MultipleResultColumn(props: {
    columnData: ElementMayBeLeading[];
    columnNumber: number;
    output: string;
    keyBase: string;
}) {
    const expansionState = useExpansionStateContext();

    const expanded = useStateSourceCallback<boolean, string>(
        expansionState,
        props.keyBase,
    );

    return (
        <div className={'advanced-search-multi-result-wrapper-grid'}>
            {expanded && props.columnData.map((row) => (
                <div key={row.key} className={row.cellClassName}>{row.data}</div>
            ))}
            <ExpansionManagerDisplayRow
                columnNumber={props.columnNumber}
                expansionState={expansionState}
                expansionStateKey={props.keyBase}
                itemCount={props.columnData.length}
                expanded={expanded}
            />
        </div>
    );
}

function ExpansionManagerDisplayRow(props: {
    columnNumber: number;
    expansionState: MultipleRowExpansionState;
    expansionStateKey: string;
    itemCount: number;
    expanded: boolean;
}) {
    const toggleExpanded = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        event.preventDefault();
        props.expansionState.setState(!props.expanded, props.expansionStateKey);
    };
    const labelText = props.expanded ? 'Hide' : `Show all ${props.itemCount}`;
    const className = props.expanded
        ? 'advanced-search-multi-result-cell'
        : 'advanced-search-multi-result-cell-first';

    if (props.columnNumber == 0) {
        return (
            <div key={'expansionMgr'} className={`ui-grid__cell ${className}`}>
                <a onClick={toggleExpanded}>{labelText}</a>
            </div>
        );
    }

    return (
        <div key={'expansionMgr'} className={`ui-grid__cell ${className}`}>
            &nbsp;
        </div>
    );
}

function getOutputRawData(output: string, result: AdvancedSearchQueryResult) {
    const outputPathTokens = output.split('.');
    return outputPathTokens.reduce((data, token) => data[token], result.result);
}

function mapOptionRawData(criterionConfig: AdvancedSearchDocumentField, data) {
    if (criterionConfig && criterionConfig.options) {
        const option = criterionConfig.options.find(
            (option) => option.value == data,
        );
        return option ? option.name : data;
    }
    return data;
}

function dataToString(criterionConfig: AdvancedSearchDocumentField, data) {
    if (!criterionConfig) {
        return data;
    }

    if (criterionConfig.type == 'boolean') {
        return data ? 'true' : 'false';
    }

    if (criterionConfig && criterionConfig.type == 'yesNo') {
        return data ? 'Yes' : 'No';
    }

    return data;
}

function formatDataForType(type: string, data) {
    if (data == '') {
        return data;
    }

    switch (type) {
        case 'date':
            return moment(data).format('DD MMM YYYY');
        case 'datetime':
            return moment(data).format('DD MMM YYYY HH:mm:ss');
        default:
            return data;
    }
}

export function toFormattedData(
    output: string,
    result: AdvancedSearchQueryResult,
    criterionConfig: AdvancedSearchDocumentField,
) {
    const rawData = getOutputRawData(output, result);

    if (rawData == null) {
        return null;
    }

    const dataAsString = dataToString(criterionConfig, rawData);

    const mappedData = mapOptionRawData(criterionConfig, dataAsString);

    return formatDataForType(criterionConfig.type, mappedData);
}

function enrichDisplayData(
    documentConfig: AdvancedSearchDocumentConfig,
    criterionConfig: AdvancedSearchDocumentField,
    formattedData,
    result: AdvancedSearchQueryResult,
) {
    if (
        documentConfig.documentType == 'demographics' &&
        demographicsPathsToMakeLinks.includes(criterionConfig.path)
    ) {
        return (
            <Link to={`/clinical_portal/folder/${result.folderId}/patient`}>
                {formattedData}
            </Link>
        );
    }
    return formattedData;
}

function toFormattedOutputDataItem(
    output: string,
    result: AdvancedSearchQueryResult,
    documentConfig: AdvancedSearchDocumentConfig,
    cellClassName: string,
    key: string
): ResultDisplayData {
    const criterionConfig = documentConfig.fields.find(
        (criterion) => criterion.path == output,
    );

    const formattedData = toFormattedData(output, result, criterionConfig);

    if (!formattedData) {
        return { key: key, cellClassName: cellClassName, data: '\u00A0' };
    }

    const enrichedData = enrichDisplayData(
        documentConfig,
        criterionConfig,
        formattedData,
        result,
    );

    return { key: key, cellClassName: cellClassName, data: enrichedData };
}

function mapChildResultsToDisplayData(
    queryDefinition: AdvancedSearchQueryDefinition,
    index: number,
    result: AdvancedSearchQueryResult,
    resultData: ResultDisplayData[],
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
) {
    const childPathPrefix = `children[${index}]`;
    const resultChildren = result.children[index].results;

    if (!resultChildren || resultChildren.length == 0) {
        mapEmptyChildResultsToEmptyTableRowCells(resultData, queryDefinition, childPathPrefix, documentSearchConfigs);
    } else if (resultChildren.length == 1) {
        mapSingleChildResultToTableRowCells(resultData, documentSearchConfigs, queryDefinition, resultChildren, childPathPrefix);
    } else {
        mapMultipleChildResultsToTableRowCells(resultChildren, childPathPrefix, documentSearchConfigs, queryDefinition, result, resultData);
    }
}

function appendSingleCardinalityJoinChildQueryResults(
    queryDefinition: AdvancedSearchQueryDefinition,
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    resultCells: ResultDisplayData[],
    result: AdvancedSearchQueryResult,
    childResultIndex: number,
    childPathPrefix: string,
    className: string
): void {
    if (!queryDefinition.children) {
        return;
    }

    queryDefinition.children.forEach((childQueryDefinition, childIndex) => {
        const documentType = queryDefinition.documentType;
        const cardinality = getChildQueryDefinitionCardinality(documentType, childQueryDefinition, documentSearchConfigs);
        if (cardinality != 'single') {
            return;
        }

        const cellData: ResultDisplayData[] = [];
        if (result) {
            const childResults = result.children[childIndex].results;
            const pathPrefix = `${childPathPrefix}results[${childResultIndex}]children[${childIndex}]`;
            if (childResults && childResults.length > 0) {
                cellData.push(...mapResultToDisplayData(
                    documentSearchConfigs,
                    childQueryDefinition,
                    childResults[0],
                    pathPrefix,
                    className,
                ));
            } else {
                cellData.push(...mapOutputsToEmptyTableRowData(childQueryDefinition, pathPrefix, className));
            }
        } else {
            const pathPrefix = `${childPathPrefix}results[-]children[${childIndex}]`;
            cellData.push(...mapOutputsToEmptyTableRowData(childQueryDefinition, pathPrefix, className));
        }

        const cellsWithLeadingFlag: ElementMayBeLeading[] = cellData.map((rowCell, index) => {
            return { ...rowCell, isLeading: index == 0 };
        });

        resultCells.push(...cellsWithLeadingFlag);
    });
}

function mapEmptyChildResultsToEmptyTableRowCells(
    resultData: ResultDisplayData[],
    queryDefinition: AdvancedSearchQueryDefinition,
    childPathPrefix: string,
    documentSearchConfigs: AdvancedSearchDocumentConfig[]
) {
    resultData.push(...mapOutputsToEmptyTableRowData(queryDefinition, childPathPrefix));

    appendSingleCardinalityJoinChildQueryResults(
        queryDefinition,
        documentSearchConfigs,
        resultData,
        null,
        null,
        childPathPrefix,
        ''
    );
}

function mapSingleChildResultToTableRowCells(
    resultData: ResultDisplayData[],
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition,
    resultChildren: AdvancedSearchQueryResult[],
    childPathPrefix: string
) {
    resultData.push(...mapResultToDisplayData(
            documentSearchConfigs,
            queryDefinition,
            resultChildren[0],
            childPathPrefix,
            '',
        )
    );

    appendSingleCardinalityJoinChildQueryResults(
        queryDefinition,
        documentSearchConfigs,
        resultData,
        resultChildren[0],
        0,
        childPathPrefix,
        ''
    );
}

function mapMultipleChildResultsToTableRowCells(
    resultChildren: AdvancedSearchQueryResult[],
    childPathPrefix: string,
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    queryDefinition: AdvancedSearchQueryDefinition,
    result: AdvancedSearchQueryResult,
    resultData: ResultDisplayData[]
) {
    const resultRows = resultChildren.map(
        (resultChild, childResultIndex) => {
            const childResultPathPrefix = `${childPathPrefix}.results[${childResultIndex}]`;
            const className =
                childResultIndex == 0
                    ? 'advanced-search-multi-result-cell-first'
                    : 'advanced-search-multi-result-cell';
            const rowData = mapResultToDisplayData(
                documentSearchConfigs,
                queryDefinition,
                resultChild,
                childResultPathPrefix,
                `ui-grid__cell ${className}`,
            );

            const rowCellsWithLeading: ElementMayBeLeading[] = rowData.map((rowCell, index) => {
                return { ...rowCell, isLeading: index == 0 };
            });

            appendSingleCardinalityJoinChildQueryResults(
                queryDefinition,
                documentSearchConfigs,
                rowCellsWithLeading,
                resultChild,
                childResultIndex,
                childResultPathPrefix,
                `ui-grid__cell ${className}`
            );

            return rowCellsWithLeading;
        },
    );
    const resultTableCells = buildMultipleResultNestedRows(
        result,
        resultRows,
        childPathPrefix,
        queryDefinition,
    );
    resultData.push(...resultTableCells);
}

function mapResultToDisplayData(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    searchDefinition: AdvancedSearchQueryDefinition,
    result: AdvancedSearchQueryResult,
    pathPrefix: string,
    cellClassName: string,
): ResultDisplayData[] {
    const documentConfig = getDocumentSearchConfig(
        documentSearchConfigs,
        searchDefinition.documentType,
    );
    const getClassNameSuffix = (index: number) => index == 0 ? ' advanced-search-child-column-leading' : '';
    const getKey = (pathPrefix: string, output: string) => pathPrefix ? `${pathPrefix}.${output}` : output;
    const cellData = searchDefinition.outputs.map((output, index) => {
        const classNameSuffix = getClassNameSuffix(index);
        const key = getKey(pathPrefix, output);
        return toFormattedOutputDataItem(
            output,
            result,
            documentConfig,
            `${cellClassName}${classNameSuffix}`,
            key
        );
    });
    if (shouldHaveActionsColumn(searchDefinition)) {
        const classNameSuffix = getClassNameSuffix(cellData.length);
        const key = getKey(pathPrefix, 'UITasks');
        cellData.push(getAdvancedSearchTableViewButtonData(result,`${cellClassName}${classNameSuffix}`, key));
    }
    return cellData;
}

function mapResultWithChildrenToDisplayData(
    documentSearchConfigs: AdvancedSearchDocumentConfig[],
    searchDefinition: AdvancedSearchQueryDefinition,
    result: AdvancedSearchQueryResult,
) {
    const resultData = mapResultToDisplayData(
        documentSearchConfigs,
        searchDefinition,
        result,
        '',
        '',
    );

    if (searchDefinition.children) {
        searchDefinition.children.forEach((child, index) => {
            mapChildResultsToDisplayData(
                child,
                index,
                result,
                resultData,
                documentSearchConfigs,
            );
        });
    }

    return resultData;
}

function ResultTableRow(props: {
    documentSearchConfigs: AdvancedSearchDocumentConfig[];
    searchDefinition: AdvancedSearchQueryDefinition;
    result: AdvancedSearchQueryResult;
}) {
    return (
        <GridRow>
            <GridCell>
                <FolderSelectionStateCheckbox
                    folderId={props.result.folderId}
                />
            </GridCell>
            {mapResultWithChildrenToDisplayData(
                props.documentSearchConfigs,
                props.searchDefinition,
                props.result,
            ).map(displayData => <GridCell key={displayData.key} className={displayData.cellClassName}>{displayData.data}</GridCell>)}
            <GridCell className={'centred vertical-flow '}>
                <AdvancedSearchResultsTableRowActions
                    searchDefinition={props.searchDefinition}
                    documentSearchConfigs={props.documentSearchConfigs}
                    result={props.result}
                />
            </GridCell>
        </GridRow>
    );
}

function FolderSelectionStateCheckbox(props: { folderId: number }) {
    const selectionStateContainer = useSelectionStateContext();

    return (
        <LinkedCheckbox
            stateSource={selectionStateContainer.selectionState}
            stateKey={props.folderId}
        />
    );
}

function SelectionOptionsDropdownWrapper(props: { pageFolderIds: number[] }) {
    const selectionStateContainer = useSelectionStateContext();

    return (
        <SelectionOptionsDropdown
            folderSelectionState={selectionStateContainer.selectionState}
            folderIds={props.pageFolderIds}
        />
    );
}

const shouldHaveActionsColumn = (queryDefinition): boolean => {
    const documentType = queryDefinition.documentType;
    return documentType === 'appointment' || documentType === 'questionnaireResponse';
};

function calculateGroupedHeaderColSpan(queryDefinition: AdvancedSearchQueryDefinition): number {
    const numOutputs = queryDefinition.outputs.length;
    if (shouldHaveActionsColumn(queryDefinition)) {
        return numOutputs + 1;
    }
    return numOutputs;
}

type AdvancedSearchResultsTableDisplayProps = {
    documentSearchConfigs: AdvancedSearchDocumentConfig[];
    searchQueryDefinition: AdvancedSearchQueryDefinition;
    results: AdvancedSearchQueryResult[];
    onSort: (param: GridSortState) => void;
    onFilter: (param: Filter) => void;
    sortOrder: GridSortState;
    filterState: Filter;
};

export const AdvancedSearchResultsTableDisplay: FC<AdvancedSearchResultsTableDisplayProps> =
    (props) => {
        const hasResults = props.results.length > 0;

        const expansionState = new MultipleRowExpansionState();

        const pageFolderIds: number[] = useMemo(
            () => props.results.map((result) => result.folderId),
            [props.results],
        );

        return (
            <Fragment>
                <div className={'advanced-search-table scrollable-table'}>
                    <Grid
                        onSort={props.onSort}
                        onFilter={props.onFilter}
                        sortOrder={props.sortOrder}
                        filterState={props.filterState}
                    >
                        <GridHeader>
                            <GridHeaderRow>
                                <GridHeaderCell
                                    sortable={false}
                                    filterable={false}
                                    rowSpan={4}
                                    preventCloneForFilterRow={true}
                                    className={'header-cell-centred'}
                                >
                                    {hasResults && (
                                        <SelectionOptionsDropdownWrapper
                                            pageFolderIds={pageFolderIds}
                                        />
                                    )}
                                </GridHeaderCell>
                                {mapSearchDefinitionToColumnHeaderGroupCells(
                                    props.documentSearchConfigs,
                                    props.searchQueryDefinition,
                                )}
                                <GridHeaderCell
                                    sortable={false}
                                    filterable={false}
                                    rowSpan={4}
                                    preventCloneForFilterRow={true}
                                    className={'header-cell-centred'}
                                >
                                    Actions
                                </GridHeaderCell>
                            </GridHeaderRow>
                            <GridHeaderRow>
                                {mapSearchOutputsToColumnHeaderCells(
                                    props.documentSearchConfigs,
                                    props.searchQueryDefinition,
                                )}
                            </GridHeaderRow>
                        </GridHeader>
                        <ExpansionStateContext.Provider value={expansionState}>
                            <GridBody>
                                {hasResults &&
                                    props.results.map((result) => (
                                        <ResultTableRow
                                            key={result.uuid}
                                            documentSearchConfigs={
                                                props.documentSearchConfigs
                                            }
                                            searchDefinition={
                                                props.searchQueryDefinition
                                            }
                                            result={result}
                                        />
                                    ))}
                            </GridBody>
                        </ExpansionStateContext.Provider>
                    </Grid>
                </div>
                {!hasResults && (
                    <label className={'advanced-search-table-placeholder'}>
                        No records match the specified filters.
                    </label>
                )}
            </Fragment>
        );
    };
