import React from 'react';
import View from './view';
import _ from 'services/i18n';
import BaseInput from '../base-input';

/**
 * Represents a general text input field in a form panel.
 *
 * Props (data):
 *  - label = Required. A text label identifying the input field.
 *  - id = Required. An identifier for the input field.
 *  - helpText = Optional. Help text to display below the input field.
 *  - required = Optional. Boolean indicating if a value must be entered in this field. Defaults to false.
 *  - spaces = Optional. String defining how whitespaces are handled in the value before validation. Defaults to 'trim' which removes leading/trailing space. Set to 'preserve' to leave spaces alone, or 'remove' to get rid of all whitespace in the value.
 *  - readOnly = Optional. Boolean flag indicating if the input element is readOnly. The value will not be reported to the parent form.
 *  - disabled = Optional. Boolean flag indicating if the input element is disabled. The value will not be reported to the parent form.
 *  - forceShowValidation = Optional. Boolean indicating if validation errors will be forced to be shown in the UI. Defaults to false. Normally, some errors will be suppresed, e.g. if the user hasn't had a chance to enter anything yet. This flag is used by the containing form.
 *  - value = Optional. The raw value in the input field. Only set this if you intend to respond to this control's onChange or the form's onChange events.
 *  - initialValue = Optional. The initial value for the form. Ignored after construction, or if value is specified.
 *  - minLength = Optional. The minimum length (in characters) for a valid value, after any preprocessing (e.g. trimming of spaces). Ignored if 0, negative, null, undefined. Length requirements are not applied if the field is blank. Use 'required' prop to reject empty values.
 *  - maxLength = Optional. The maximum length (in characters) for a valid value, after any preprocessing (e.g. trimming of spaces). Ignored if 0, negative, null, undefined. Length requirements are not applied if the field is blank. Use 'required' prop to reject empty values.
 *  - placeholder = Optional. Placeholder text to display in the input field if there is no value present.
 *  - type = Optional. The type of input field to display. Defaults to 'text', but can also be 'email'.
 *  - validation = Optional. Identifies a standard validation procedure to carry out. Ignored if blank or unspecified. Valid values are: 'email', 'numeric' (decimal digits only), 'alpha' (letters A-Z only, either case), 'alphanumeric' (letters A-Z and numbers only).
 *  - hideValidationSuccess = Optional. Defaults to false. If true, the component will not show a green tick when validation passes. This is useful where such information would be ambiguous, e.g. on a password field.
 *  - autofocus = Optional. Defaults to false. If true, the compenent will focus on first render.
 *
 * Props (handlers):
 *  - onChange = Required. A function called when the value changes. Parameters are: id, rawValue, effectiveValue, validated. NOTE: This is provided automatically by the parent form if used directly inside FormPanel.
 *  - onValidate = Optional. A custom validation function which will be called to validate the value. It should return true if the value is valid, or if not it should return a string containing an error message, or an array of multiple error messages. This will only be called if any automated validation passes.
 *
 */
export default class TextInput extends BaseInput {
    constructor (props) {
        super(props);

        const value = this.props.value || this.props.initialValue || '';
        const spaces = this.validateSpacesProp(this.props.spaces);

        this.state = {
            spaces: spaces,

            // This contains the unedited value in the input box.
            rawValue: value,
            // This will contain the effective value used in validation etc.
            // It may have preprocessing applied, such as trimming whitespace.
            effectiveValue: this.getEffectiveValue(value, spaces),

            // Boolean flag indicating if validation has been run, regardless of whether it passed or failed.
            isValidated: false,

            // Boolean flag specifying if validation should be shown in the user interface.
            // This is used to avoid displaying errors which would not be helpful, e.g. reporting
            //  that a value is required before the user has had a chance to enter anything.
            // This is ignored if isValidated is false or props.forceShowValidation is true.
            showValidation: false,

            // Stores any validation error messages.
            // If the array if empty, validation either passed or hasn't been done yet.
            errors: [],
        };

        if (this.props.label === '') {
            console.warn('label prop must not be empty.');
        }

        if (this.props.id === '') {
            console.warn('id prop must not be empty.');
        }

        if (this.props.onChange === null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }

        this.bindEvents([
            'Change',
            'LoseFocus'
        ]);

        this.bindFunctions([]);

        this.View = View;
    }

    componentWillMount() {
        // Validation will notify the parent of the initial value.
        this.initialValidation();
    }

    componentWillReceiveProps(newProps) {

        if (this.props.onChange == null) {
            console.warn('No onChange handler specified. You need to specify it explicitly if this component is not an immediate child of FormPanel.');
        }

        let revalidate = false, changed = false;
        const newState = {};
        const spaces = this.validateSpacesProp(newProps.spaces);
        const rawValue = (newProps.value !== undefined && newProps.value !== (this.state.rawValue || '').trim()) ? newProps.value : this.state.rawValue;
        const effectiveValue = this.getEffectiveValue(rawValue, spaces);

        // We need to avoid updating data unless necessary.
        // This prevents UI glitches and potential infinite event loops.

        if (spaces !== this.state.spaces) {
            // The way the raw value is preprocessed has been changed.
            newState.spaces = spaces;
            changed = true;
        }

        if (rawValue !== this.state.rawValue) {
            // The contents of the input field have been changed from outside.
            newState.rawValue = rawValue;
            changed = true;
        }

        if (effectiveValue !== this.state.effectiveValue) {
            // The result of preprocessing the input value has changed.
            newState.effectiveValue = effectiveValue;
            revalidate = true;
            changed = true;
        }

        if (newProps.onValidate !== this.props.onValidate) {
            // The custom validator has changed.
            revalidate = true;
        }

        if (newProps.onChange !== this.props.onChange) {
            // The parent's change handler has changed.
            // Revalidation notifies the parent of the current value.
            revalidate = true;
        }

        if (changed) {
            this.setState(
                newState,
                () => {
                    if (revalidate) {
                        this.initialValidation();
                    }
                }
            );
        } else if (revalidate) {
            this.initialValidation();
        }
    }

    initialValidation() {
        // Run validation and notify the parent of the initial value.
        // However, only report the validation result to the UI if the
        //  initial value was not empty, or we were already reporting it.
        this.validateEffectiveValue(
            this.state.effectiveValue !== '' || this.state.showValidation,
            true
        );
    }

    /**
     * Checks that the given value is valid for the 'spaces' property.
     * Returns the validated value, replacing any invalid value with the default ('trim').
     * Outputs a console warning if the value is not valid.
     */
    validateSpacesProp(spaces) {
        // Default to 'trim'.
        if (spaces == null) {
            return 'trim';
        }

        if (spaces === 'preserve' || spaces === 'trim' || spaces === 'remove') {
            return spaces;
        }

        console.warn('Unrecognised value for prop "spaces". Expected "preserve", "trim", or "remove".');
        return 'trim';
    }

    /**
     * Get the effective version of the given raw value.
     * This will apply any preprocessing indicated by the given parameters.
     * It will not read or modify state.
     * Parameters:
     *  - rawValue = Required. The raw value to process into an effective value.
     *  - spaces = Required. A string describing the action to take with spaces. Valid values are "trim" (null), "preserve", and "remove"
     */
    getEffectiveValue(rawValue, spaces) {
        const value = this.props.filterValue ? this.props.filterValue(rawValue) : rawValue;
        if (spaces === 'trim') {
            return value.trim();
        }
        if (spaces === 'remove') {
            return value.replace(/\s/g, '');
        }

        return value;
    }

    /**
     * Event handler: The content of the form field has changed.
     */
    handleChange(e) {
        const value = e.target.value || '';
        this.setState(
            {
                rawValue: value,
                effectiveValue: this.getEffectiveValue(value, this.state.spaces),
                isValidated: false,
                errors: []
            },
            this.validateEffectiveValue.bind(this, true, true)
        );
    }

    /**
     * Event handler: Keyboard focus has moved off the input field.
     */
    handleLoseFocus() {
        // Make sure any validation errors are displayed.
        this.setState({
            showValidation: true
        });
    }

    /**
     * Validate the current effective value.
     * This operates on stored state.
     * Parameters:
     *  - showValidation = Optional. If true (default), the validation result will be shown in the user interface. If false, the interface won't show any validation status.
     *  - notifyParent = Optional. If true (default), the value and its validation status will be sent to the parent's onChange handler.
     *  - cb = Optional. A call-back to trigger after validation has finished and any notifications have been dispatched.
     */
    validateEffectiveValue(showValidation = true, notifyParent = true, cb = null) {
        // Don't perform any validation for a control that is readOnly or disabled.
        if (this.props.readOnly || this.props.disabled) {
            this.setState({
                isValidated: false,
                showValidation: showValidation,
                errors: []
            }, () => {
                if (notifyParent && this.props.onChange) {
                    // Notify the parent that the value doesn't exist.
                    this.props.onChange(this.props.id, undefined, undefined, undefined);
                }
                if (cb) {
                    cb();
                }
            });

            return;
        }

        // Validate the data.
        let result = this.getValidationResult();
        if (result === true) {
            result = [];
        }

        this.setState({
            isValidated: true,
            showValidation: showValidation,
            errors: result
        }, () => {
            if (notifyParent && this.props.onChange) {
                // Report the result to the parent.
                this.props.onChange(this.props.id, this.state.rawValue, this.state.effectiveValue, this.state.errors.length === 0);
            }
            if (cb) {
                cb();
            }
        });
    }

    /**
     * Run validation on the stored effective value, and return the result.
     * This will not update current state.
     * This will return true if the validation passes.
     * Otherwise it will return an array of error messages.
     */
    getValidationResult() {
        // Note: It's important to incorporate the 'required' check into the length checks
        //  so that we can give more helpful error messages. E.g. it's not helpful to say
        //  "this must not be empty" and then say "this must be at least 3 characters long".
        // The error messages should be consistent.

        // Length checks are only applied to an empty value if empty values aren't allowed.
        if (this.state.effectiveValue !== '' || this.props.required) {
            const minLength = parseInt(this.props.minLength || '0');
            const maxLength = parseInt(this.props.maxLength || '0');
            const valueLength = this.state.effectiveValue.length;
            if (minLength > 0) {

                if (maxLength === minLength) {
                    // Min and max length are the same so an exact length is required.
                    if (valueLength !== minLength) {
                        if (this.props.required) {
                            if (minLength === 1) {
                                return [_`This must be exactly 1 character long.`];
                            } else {
                                return [_`This must be exactly ${minLength} characters long.`];
                            }
                        } else {
                            if (minLength === 1) {
                                return [_`This must be 0 or 1 characters long.`];
                            } else {
                                return [_`This must be empty or exactly ${minLength} characters long.`];
                            }
                        }
                    }
                } else if (maxLength != null && maxLength > minLength) {
                    // There is a range of valid lengths.
                    if (valueLength < minLength || valueLength > maxLength) {
                        if (this.props.required) {
                            return [_`This must be between ${minLength} and ${maxLength} characters long.`];
                        } else {
                            return [_`This must be empty or between ${minLength} and ${maxLength} characters long.`];
                        }
                    }
                } else if (valueLength < minLength) {
                    // There is only a minimum required length.
                    if (this.props.required) {
                        if (minLength === 1) {
                            return [_`This must be at least 1 character long.`];
                        } else {
                            return [_`This must be at least ${minLength} characters long.`];
                        }
                    } else {
                        // Note: There is no point enforcing a minimum of 1 character length
                        //  if the field isn't required.
                        if (minLength > 1) {
                            return [_`This must be empty or at least ${minLength} characters long.`];
                        }

                    }
                }
            } else if (maxLength > 0) {
                // There is only a maximum required length.
                if (valueLength > maxLength) {
                    if (this.props.required) {
                        return [_`This must be between 1 and ${maxLength} characters long.`];
                    } else {
                        return [_`This must be less than ${maxLength + 1} characters long.`];
                    }
                }
            }
        }

        // Ensure that an empty value isn't allowed through if the rules don't permit it.
        if (this.props.required && this.state.effectiveValue === '') {
            return [_`This must not be empty.`];
        }

        const validation = (this.props.validation || '').toLowerCase();
        switch (validation) {
        case '':
            // No validation. Do nothing.
            break;

        case 'email':
            // Ensure the email address contains exactly one @ symbol,
            //  with at least 1 other character on either side, and no whitespace.
            if (this.state.effectiveValue.match(/^[^\s@]+@[^\s@]+$/) == null) {
                return [_`This must be a valid email address.`];
            }
            break;

        case 'alpha':
            // Should only contains letters A-Z.
            if (this.state.effectiveValue.match(/[^a-z]/i) != null) {
                return [_`This can only contain letters A to Z.`];
            }
            break;

        case 'numeric':
            // Should only contains decimal digits 0-9.
            if (this.state.effectiveValue.match(/[^0-9]/) != null) {
                return [_`This can only contain decimal digits 0 to 9.`];
            }
            break;

        case 'alphanumeric':
            // Should only contains letters A-Z or decimal digits 0-9.
            if (this.state.effectiveValue.match(/[^a-z0-9]/i) != null) {
                return [_`This can only contain letters A to Z and digits 0-9.`];
            }
            break;

        default:
            console.warn('Unrecognised validation type: ' + this.props.validation);
            break;
        }

        return this.getCustomValidationResult(this.state.effectiveValue);
    }
}

