import React, { FC } from 'react';
// components
import DepartmentLocation from 'features/department/components/DepartmentLocation';

const TemplateFooter: FC = () => {
    return (
        <div
            className="footer"
            style={{
                marginBottom: 20,
            }}
        >
            <DepartmentLocation />
        </div>
    );
};

export default TemplateFooter;
