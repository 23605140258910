import React from 'react';
import {Folder, ConfirmModal, FormGroup, Row, Column, Panel, PanelFooter, ResponsiveTable} from 'ui';
import Modal from 'ui/modal';
import {Link} from 'react-router';

const SubmitOrSave = ({onClose, onSaveForLater, onSubmit}) => {
    return (
        <div className="save-modal">
            <Modal title="Save Answers" type="success" onClose={onClose}>
                <Row>
                    <Column md="6" mdOffset="3" sm="6" smOffset="3" xs="12">
                        <FormGroup>
                            <button type="button" className="btn btn-default btn-block" onClick={onSaveForLater}>
                                Save For Later
                            </button>
                        </FormGroup>
                        <FormGroup>
                            <button type="button" className="btn btn-default btn-block" onClick={onSubmit}>Submit
                            </button>
                        </FormGroup>
                        <FormGroup>
                            <button type="button" className="btn btn-default btn-block" onClick={onClose}>Go Back To
                                Questionnaire
                            </button>
                        </FormGroup>
                    </Column>
                </Row>
            </Modal>
        </div>
    );
};

const CurrentQuestion = ({
    id, questionnaire: {preamble, questions},
    response: {answers}, onNextQuestion, onLastQuestion, onSetAnswer, onSubmitOrSave, onCloseSubmitOrSave
}) => {
    let currentQuestion, currentResponse, hasNextQuestion = true;

    if (id == 0) {
        return (<Preamble preamble={preamble} onStartQuestionnaire={onNextQuestion}/>);
    }

    currentQuestion = questions.find(item => (item.id == id));
    hasNextQuestion = questions.find(item => (item.id == id + 1));
    currentResponse = answers.find(item => (item.question_id == id));

    return (
        <Panel title={"Question " + currentQuestion.id + ' out of ' + questions.length}>
            <h4><span className="question-id">{id}.</span> <span className="question">{currentQuestion.question}</span></h4>
            <div className="answer-row">
                <Answer question={currentQuestion} answer={currentResponse}
                        onSetAnswer={onSetAnswer.bind(null, currentQuestion.id)}/>
            </div>
        </Panel>
    );
};

const Answer = ({
    question,
    question: {type, options},
    answer: {value},
    onSetAnswer
}) => {
    switch (type) {
        case 'text': {
            let safeValue = value;

            if (value == question.default) {
                safeValue = '';
            }

            return (
                <Row>
                    <Column md="12">
                        <FormGroup>
                            <textarea className="form-control" rows="7"
                                      onChange={onSetAnswer} value={safeValue}/>
                        </FormGroup>
                    </Column>
                </Row>
            );
        }
        case 'mcq': {
            let colSize = Math.floor(12 / options.length),
                answerList = options.map((item, idx) => {
                    let checked = false;

                    if (idx == value) {
                        checked = true;
                    }

                    return (
                        <Column key={idx} md={colSize} sm={colSize} xs="12">
                            <div className="radio" onClick={onSetAnswer.bind(null, {target: {value: idx}})}>
                                <label>
                                    <input type="radio" readOnly="readOnly" checked={checked}/>
                                    {item}
                                </label>
                            </div>
                        </Column>
                    );
                });

            return (
                <Row>
                    {answerList}
                </Row>
            );
        }
    }
};

const Preamble = ({preamble, onStartQuestionnaire}) => {
    return (
        <div className="question-preamble">
            <Panel title="Questionnaire Information">
                {preamble}
            </Panel>
        </div>
    );
};

export const PageLoading = () => {
    return (
        <div className="questionnaire-page">
            <div className="header-bar">
                <Link to="/coopweb" className="btn btn-default btn-sm">
                    <i className="glyphicon glyphicon-arrow-left"/> Dashboard
                </Link>
            </div>
            <h1>COOP Questionnaire</h1>
            <p><em>Questionnaire is loading... please wait.</em></p>
        </div>
    );
};

export const Page = ({
    events: {
        onCloseSubmitOrSave, onConfirmDashboard, onSaveForLater, onSubmit, onNextQuestion,
        onSetAnswer, onLastQuestion, onSubmitOrSave, onCloseConfirm
    }, state
}) => {
    let questions = state.questionnaire.content.details.questions.map((item, idx) => {
        return (
            <CurrentQuestion key={idx} id={item.id} questionnaire={state.questionnaire.content.details}
                             response={state.response.content.details} onNextQuestion={onNextQuestion}
                             onLastQuestion={onLastQuestion} onSetAnswer={onSetAnswer}
                             onSubmitOrSave={onSubmitOrSave}/>
        );
    });
    return (
        <div className="questionnaire-page">
            <div className="header-bar">
                <button className="btn btn-default btn-sm" type="button" onClick={onConfirmDashboard}>
                    <i className="glyphicon glyphicon-arrow-left"/> Dashboard
                </button>
            </div>
            <h1>{state.questionnaire.content.name}</h1>
            {state.showSubmitScreen
                ? <SubmitOrSave onClose={onCloseSubmitOrSave}
                                onSaveForLater={onSaveForLater} onSubmit={onSubmit}/> : null}
            <Preamble preamble={state.questionnaire.content.details.preamble}/>
            <div className="question-list">
                {questions}
            </div>
            <Folder type="bottom">
                <button className="btn btn-success" type="button" onClick={onSubmitOrSave}>Submit or Save</button>
            </Folder>
            {state.showConfirmModal
                ? <ConfirmModal onConfirm={state.showConfirmModal.onConfirm} onClose={onCloseConfirm}>
                {state.showConfirmModal.text}
            </ConfirmModal>
                : null}
        </div>
    );
};

export const NotFound = ({onConfirmDashboard}) => {
    return (
        <div className="questionnaire-page">
            <div className="header-bar">
                <button className="btn btn-default btn-sm" type="button" onClick={onConfirmDashboard}>
                    <i className="glyphicon glyphicon-arrow-left"/> Dashboard
                </button>
            </div>
            <h1>Page Not Found</h1>
            <p>We are sorry, but the page you are trying to access has not been found. Please use the navigation bar
                above.</p>
        </div>
    );
}