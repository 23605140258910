// Polyfills for React 16
import 'raf/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'date-input-polyfill-react';

import moment from 'moment-timezone';

import React from 'react';
import ReactDOM from 'react-dom';
import ApplicationRoutes from 'Routes';
import userActivityTracker from 'services/user-activity-tracker.service';
import { store } from 'store';

import { I18NContext } from 'common/i18n/I18NContext';
import _ from 'services/i18n';

import { APIContext } from 'common/useAPI';
import { api } from 'common/api';
import { TopTeamPreferencesComponent } from 'common/TeamPreferences';

import { ToastContainer } from 'react-toastify';
import '../less/style.less';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/portal-styles.scss';

ReactDOM.render((
    <div className="app-root">
        <I18NContext.Provider value={_}>
            <APIContext.Provider value={api}>
                <TopTeamPreferencesComponent>
                    <ApplicationRoutes store={store} />
                </TopTeamPreferencesComponent>
            </APIContext.Provider>
        </I18NContext.Provider>
        <ToastContainer
            position={'top-right'}
            limit={6}
        />
        <div id='confirmation-dialog'></div>
    </div>
), document.getElementById('mainSite'));

userActivityTracker.start();

moment.locale('en-GB');
