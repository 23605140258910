import React from 'react';
import ReactDOM from 'react-dom';
import {IndexRoute, Router, Route, Link} from 'react-router';

import Template from 'layouts/guest/template';

import IndexPage            from 'pages/guest';

const routes = (
    <Route component={Template}>
        <IndexRoute component={IndexPage} />
    </Route>
);


export default routes;
