import React, { Component } from 'react';
import lodash from 'lodash';

import classNames from 'classnames';

export interface SearchBoxProps {
    searchTypes: string[];
    placeholder: string;
    buttonText: string;
    inputValue: string;

    className?: string;
    selectedType?: string;
    onChange?: Function;
    onSubmit?: Function;
}

export interface SearchBoxState {
    inputValue: string;
    active: boolean;
    type: string;
    expanded: boolean;
}
const defaultProps: SearchBoxProps = {
    buttonText: 'Go',
    placeholder: 'Search...',
    inputValue: '',
    searchTypes: []
};


export default class SearchBoxComponent extends Component<SearchBoxProps, SearchBoxState>{

    input: any;
    timeoutId: any;
    lockCollapse: any;

    constructor(props){
        super(props);

        this.input = null;
        this.timeoutId = null;
        this.lockCollapse = false;

        this.state = {
            inputValue: this.props.inputValue,
            active:false,
            type: this.findSelectedType(this.props.selectedType) || lodash.get(this.props,'searchTypes[0]',null),
            expanded:false
        };
    }
    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps({ searchTypes, inputValue }) {
        this.setState({
            inputValue
        });

        if (lodash.isEqual(searchTypes,this.props.searchTypes)){
            return;
        }
        const shouldChangeType = !lodash.some(searchTypes,(item) => {
            return lodash.lowerCase(item) === lodash.lowerCase(this.state.type);
        });
        if (!shouldChangeType){
            return;
        }
        this.setState({
            type: lodash.get(searchTypes,'[0]',null)
        });
    }
    findSelectedType(type){
        return lodash.find(this.props.searchTypes,(searchType) =>{
            return lodash.lowerCase(type) === lodash.lowerCase(searchType);
        });
    }
    onChange(evt){
        if(lodash.lowerCase(this.state.type) === 'nhs'){
            this.handleNHSNumberTyping();
            return;
        } 
        this.setState({ inputValue:evt.target.value, expanded:false });
        if (this.props.onChange){
            this.props.onChange({
                value:evt.target.value
            });
        }
    }
    onSubmit(e){
        let value;
        e.preventDefault();

        if(lodash.lowerCase(this.state.type) === 'nhs'){
            value = this.state.inputValue.replace(/[^0-9]/g,'');
        }

        this.props.onSubmit({
            value: value || this.state.inputValue.trim(),
            type:lodash.lowerCase(this.state.type)
        });
        if(this.state.active){
            this.input.focus();
        }
    }
    onFocus(){
        this.setState({ active:true });
        this.lockCollapse = false;
        if(this.timeoutId){
            window.clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }
    onBlur(){
        if(!this.lockCollapse){
            this.setState({ expanded:false });
        }
        this.timeoutId = window.setTimeout(()=> {
            this.setState({
                active: false
            });
        }, 1000);
    }
    onMouseDown(){
        this.lockCollapse = true;
        this.input.focus();
    }
    formatNhsNumber(value) {
        let newValue, parts;

        // strip out all non numerics
        newValue = (value || '').replace(/[^0-9]/g,'');

        // now format to 3 3 4
        parts = newValue.match(/^([0-9]{3})([0-9]{3})([0-9]{4})$/);
        if (!parts) {
            parts = newValue.match(/^([0-9]{3})([0-9]{3})([0-9]*)$/);
        }
        if (!parts) {
            parts = newValue.match(/^([0-9]{3})([0-9]*)$/);
        }
        if (!parts) {
            parts = newValue.match(/^([0-9]*)$/);
        }

        if (parts.length == 4) {
            newValue = parts[1] + ' ' + parts[2] + ' ' + parts[3];
        } else if (parts.length == 3) {
            newValue = parts[1] + ' ' + parts[2];
        } else if (parts.length == 2) {
            newValue = parts[1];
        }

        return newValue.trim();
    }
    getCaretPosition (oField) {

        let iCaretPos = 0,
            oSel;

        // IE Support
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (document.selection) {

            // Set focus on the element
            oField.focus();

            // To get cursor position, get empty selection range
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            oSel = document.selection.createRange();

            // Move selection start to 0 position
            oSel.moveStart('character', -oField.value.length);

            // The caret position is selection length
            iCaretPos = oSel.text.length;
        }

        // Firefox support
        else if (oField.selectionStart || oField.selectionStart == '0'){
            iCaretPos = oField.selectionStart;
        }

        // Return results
        return iCaretPos;
    }
    diff(word){

        let diff = 0;
        const ws = word.replace(/[^\d]+/g, '');

        diff = diff - (word.length - ws.length);

        if(ws.length > 3){
            diff++;
        }

        if(ws.length > 6){
            diff++;
        }

        return diff;
    }
    handleNHSNumberTyping(){
        const formattedValue = this.formatNhsNumber(this.input.value).substr(0,12);
        const cursorPosition = this.getCaretPosition(this.input);
        const diff = this.diff(this.input.value.substr(0, cursorPosition));

        if(cursorPosition > 12){
            this.setState({ inputValue: formattedValue });
            return;
        }

        this.setState({ inputValue: formattedValue }, ()=> {
            const cp = cursorPosition + diff;
            this.input.setSelectionRange(cp, cp);
        });

    }
    expand(){
        this.lockCollapse = false;
        this.setState({ expanded: !this.state.expanded });
        this.input.focus();
    }
    selectType(type){
        this.lockCollapse = false;
        this.setState({
            type,
            expanded:false,
            inputValue:''
        });
        this.input.focus();
    }
    renderItem(v, i){
        return (
            <li key={i} className={classNames({ 'active': this.state.type === v })}>
                <a onClick={this.selectType.bind(this, v)}>{`By  ${v}`}</a>
            </li>
        );
    }

    render(){

        const {
            placeholder,
            className,
            buttonText,
            searchTypes
        } = this.props;

        const {
            active,
            expanded,
            type,
            inputValue
        } = this.state;

        return (
            <form className={classNames('search-box clearfix',className,{ 'active': active,'search-box--multiple':!lodash.isEmpty(searchTypes) })} onSubmit={this.onSubmit.bind(this)}>
                <div className={classNames('search-box__input-body clearfix',{ 'expanded': expanded })}>
                    {
                        type &&  (
                            <div className="search-box__type-label" onClick={this.expand.bind(this)} onMouseDown={this.onMouseDown.bind(this)}>
                                <div>By {type}</div>
                            </div>
                        )
                    }
                    {
                        type && <div className="search-box__dropdown-button" onClick={this.expand.bind(this)} onMouseDown={this.onMouseDown.bind(this)}/>
                    }
                    <input type="text"
                        ref={(input)=>{this.input = input;}}
                        value={inputValue}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        onChange={this.onChange.bind(this)}
                        placeholder={active ? 'Search...' : placeholder}
                        className={classNames('search-box__input',{ 'active': active })}/>
                    {
                        !lodash.isEmpty(searchTypes) && (
                            <div className="search-box__dropdown" onMouseDown={this.onMouseDown.bind(this)}>
                                <ul>
                                    {searchTypes && searchTypes.map((v, i)=>this.renderItem(v, i))}
                                </ul>
                            </div>
                        )}
                    <button className="search-box__button">{buttonText}</button>
                </div>
            </form>
        );
    }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
SearchBoxComponent.defaultProps = defaultProps;
