export enum PRIMARY_IDENTIFIER_NAMESPACE {
    NHS_NUMBER= 'NHS_Number',
    CARER_ID = 'Carer_Id',
}

export interface PersonPrimaryIdentifier {
    namespace: PRIMARY_IDENTIFIER_NAMESPACE;
    label?: string;
    value: string;
}
