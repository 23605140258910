import en_GB from 'lang/en_GB';

const locale = 'en_GB',
    lookup = {
        'en_GB': en_GB
    };

/**
 * The i18n Component
 * @extends React.Component
 */
export default function (literal, ...values) {
    let templateString = '', i, originalString = '';

    for(i = 0; i < values.length; i++) {
        templateString += literal[i] + "{" + i + "}";
        originalString += literal[i] + values[i];
    }

    originalString += literal[values.length];
    templateString += literal[values.length];

    if (lookup[locale].hasOwnProperty(templateString)) {
        return renderTemplateString(lookup[locale][templateString],values);
    }

    return originalString;
}

function renderTemplateString (newLiteral, values) {
    let i;

    return newLiteral.replace(/{(\d)}/g, (_, index) => values[Number(index)]);
}
