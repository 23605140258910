import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from 'reducers';
import { composeWithDevTools } from 'redux-devtools-extension';


const middlewares = [
    thunkMiddleware
];

if (process.env.NODE_ENV !== 'production'){
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
}

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
);