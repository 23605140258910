import React, { useEffect, useMemo, useState } from 'react';
import { useI18N } from 'common/i18n/useI18N';
import { useTeamPreferences } from 'common/useTeamPreferences';
import { useAPI } from 'common/useAPI';
import { useCurrentTeam } from 'common/useCurrentTeam';
import DataStore from 'services/data-store';
import { getUploadURL, useAdditionalFormFieldsData } from 'pages/admin_portal/upload-users/csvImportHelpers';

export const CsvImport = () => {
    const _ = useI18N();
    const { post } = useAPI();
    const team = useCurrentTeam();
    const { features: { csv_import: teamPrefCsvImportParams } = {} } = useTeamPreferences();
    const [csvContent, setCsvContent] = useState('');
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [errorsList, setErrorsList] = useState('');
    const [successFlag, setSuccessFlag] = useState(false);
    const [successfullyHandled, setSuccessfullyHandled] = useState(0);
    const [selectedScript, setSelectedScript] = useState<string>('');
    const selectedTeamPrefCsvImportParam = useMemo(() => teamPrefCsvImportParams?.find(param => param.endpoint === selectedScript), [selectedScript, teamPrefCsvImportParams]);
    const { additionalCsvFormFieldsList, handleChangeCsvParams } = useAdditionalFormFieldsData(selectedTeamPrefCsvImportParam);

    useEffect(() => {
        if (teamPrefCsvImportParams) {
            setSelectedScript(teamPrefCsvImportParams[0]?.endpoint);
        }
    }, [teamPrefCsvImportParams]);

    const handleInput = (event) => {
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.readAsText(file);
        setCurrentFile(file);
        reader.onload = () => {
            setCsvContent(reader.result as string);
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!currentFile) {
            return;
        }
        try {
            const formData = new FormData();
            const { uuid: roleUuid } = (DataStore.get('me.currentRole') || {});

            additionalCsvFormFieldsList.forEach((item) => {
                const { value, name } = item;
                if(value) {
                    formData.append(name, value);
                }
            });

            formData.append('file', currentFile);
            const results = await post(getUploadURL(team.teamId, roleUuid, selectedScript), formData);
            if (results?.message?.success === true) {
                setSuccessFlag(true);
                setSuccessfullyHandled(results?.message?.handled || 0);
            } else if (results?.message?.success !== true && results?.message?.errors) {
                setErrorsList(Object.values(results.message.errors).join('\n'));
                setSuccessfullyHandled(results?.message?.handled || 0);
            } else {
                setErrorsList(_`Something went wrong`);
            }
        } catch (e) {
            setErrorsList(_`Something went wrong`);
        }
    };

    const handleOnChange = (e) => {
        setSelectedScript(e.target.value);
    };

    return (
        <div className={'form col-md-7'}>
            <h1>{_`Csv Import`}</h1>

            <form onSubmit={handleSubmit}>
                {!!teamPrefCsvImportParams?.length && (
                    <>
                        <div className="form-group">
                            <label htmlFor="script">{_`Select the script to run`}</label>
                            <select name="script" id="script" value={selectedScript} onChange={handleOnChange} className="form-control">
                                {teamPrefCsvImportParams?.map((entity) => (
                                    <option key={entity?.name} value={entity?.endpoint}>{entity?.name}</option>
                                ))}
                            </select>
                        </div>
                        {additionalCsvFormFieldsList.map(({ name, value, options, label }, i) => {
                            return (
                                <div className="form-group" key={i}>
                                    <label htmlFor="script">{label}</label>
                                    <select name={name} id="script" value={value} onChange={handleChangeCsvParams} className="form-control">
                                        {options?.map((opt, i) => (
                                            <option key={i} value={opt.value}>{opt.label}</option>
                                        ))}
                                    </select>
                                </div>
                            );
                        })}
                    </>
                )}
                <div className="form-group">
                    <label htmlFor="file">{_`Select csv file`}</label>
                    <input onChange={handleInput} id={'file'} accept={'.csv'} type={'file'}/>
                </div>
                <div className="form-group">
                    <textarea readOnly className={'form-control'} value={csvContent}/>
                </div>
                <div className="form-group">
                    <button disabled={currentFile === null} type={'submit'} className={'btn btn-default'}>{_`Submit`}</button>
                </div>
                <div hidden={errorsList === ''} className="form-group">
                    <label htmlFor="file">{_`Errors found. ${successfullyHandled} patients were labelled`}</label>
                    <textarea readOnly className={'form-control'} value={errorsList}/>
                </div>
                <div hidden={successFlag === false} className="form-group">
                    <label htmlFor="file">{_`File successfully handled! ${successfullyHandled} patients were labelled`}</label>
                </div>
            </form>
        </div>
    );
};


