import _ from 'services/i18n';
import { DeviceFormItem } from '../services/DevicesServiceHelper';

export function validateAddOrEditDeviceForm(formState: DeviceFormItem): string[]
{
    const errors = [];

    if (formState.dateAssigned === '') {
        errors.push(_`Date when device was assigned is required`);
    }

    if (formState.assignedBy === '') {
        errors.push(_`Assigner name is required`);
    }

    if (formState.department === '') {
        errors.push(_`Department is required`);
    }

    if (formState.department.trim().length > 100) {
        errors.push(_`Department name is too long (Max 100 Characters)`);
    }

    if (formState.ownershipInfo === '') {
        errors.push(_`It's required to select if the device is trust/patient owned`);
    }

    if (formState.type === '') {
        errors.push(_`Device type is required`);
    }

    if (formState.make === '' && (isTrustOwnedSingleUse(formState) || isPatientOwned(formState) )) {
        errors.push(_`Device make is required`);
    }

    if (formState.model === '' && (isTrustOwnedSingleUse(formState) || isPatientOwned(formState) )) {
        errors.push(_`Device model is required`);
    }

    if (formState.id === '' && isTrustOwnedReusable(formState)) {
        errors.push(_`Device CE ID is required`);
    }

    return errors;
}

export function validateReturnDeviceForm(formState: DeviceFormItem): string[]
{
    const errors = [];

    if (formState.dateReturned === '') {
        errors.push(_`Date when the device was returned is required`);
    }

    if (formState.returnedTo === '') {
        errors.push(_`To whom the device was returned is required`);
    }

    return errors;
}

const isTrustOwnedSingleUse = (formState: DeviceFormItem): boolean => {
    return formState.ownershipInfo === 'Trust owned, single use';
};

const isTrustOwnedReusable = (formState: DeviceFormItem): boolean => {
    return formState.ownershipInfo === 'Trust owned, reusable';
};

const isPatientOwned = (formState: DeviceFormItem): boolean => {
    return formState.ownershipInfo === 'Patient owned';
};
