import { ModalShowHideFnRef, useShowHideModalState } from 'common/ui/modal';
import { ModalDialog } from 'common/modalDialog';
import React, { FC } from 'react';

type ModalDialogSelfClosingProps = Readonly<any> & {
    showHideFnRef: ModalShowHideFnRef;
}

export const ModalDialogSelfClosing: FC<ModalDialogSelfClosingProps> = (props) => {
    const [showing, close] = useShowHideModalState(props.showHideFnRef);

    return showing && (
        <ModalDialog {...props} onClose={() => {
            close();
            if (props.onClose) {
                props.onClose();
            }
        }}>
            {props.children}
        </ModalDialog>
    );
};
