import React from 'react';
import PropTypes from 'prop-types'
import {Link} from 'react-router';
import lodash from 'lodash';

/**
 * Displays a referral composition on a MyPathway timeline.
 * Props:
 * - composition = Required. An object containing the composition data to be displayed.
 * - folderLink = Required. Base URL for the patient's folder in the portal. This is used to construct links to specific archetypes.
 */
export const Referral = ({composition, folderLink, compositionQueryString}) => {

    const uuid = lodash.get(composition, 'uuid');
    const title = lodash.get(composition, 'content.title', '');
    const details = lodash.get(composition, 'content.details', '');

    if (!title || !details){
        return (<noscript />);
    }

    const link = `${folderLink}/referrals`;

    const body = (
        <div className='composition-referral'>
            <h4>{title}</h4>
            <div className='composition-referral__content'>
                {details}
            </div>
        </div>
    );

    return (
        <Link to={link}>
            {body}
        </Link>
    );
};

Referral.propTypes = {
    composition: PropTypes.object.isRequired,
    folderLink: PropTypes.string.isRequired,
    compositionQueryString: PropTypes.string
};

export default Referral;
