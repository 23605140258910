import { CompositionService } from './composition.service';
import { terService } from './ter.service';
import { IRole, IFolderSelectorParam } from 'phr-api-client';
import { SendTimelineMessageContent } from 'models/TriggerEngineRequestContent';

class MessagesService extends CompositionService {

    get archetypeName(): string {
        return 'message';
    }

    create({ role, content, folderId }: {role?: IRole; content: SendTimelineMessageContent; folderId?: IFolderSelectorParam}): Promise<any> {
        return terService.createTer({ action: 'sendMessage', data: content, role, folderId });
    }
}

export const messagesService = new MessagesService();
