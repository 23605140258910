import React, { FC, useCallback, useState } from 'react';
import UserNoteTableDisplay, { CareEventStatus, defaultPagination } from './UserNoteTableDisplay';
import { UserNoteSimpleEntry } from './UserNoteSimpleEntry';
import {
    useEntityNoteRetriever
} from 'pages/clinical_portal/folder/_folderId/patient/user-notes/UserNoteHooks';
import { Composition } from 'models/Composition';
import { useCareEventsForArchetype } from 'features/patient/care-event/useCareEvents';

type UserNoteEntryViewProps = {
    parentComposition: Composition<any>,
    folderId: number,
    defaultTitle: string,
    defaultStatus: CareEventStatus,
}

const resetPagination = () => {
    return Object.assign({}, defaultPagination);
};

export const UserNoteEntryView: FC<UserNoteEntryViewProps> = ({
    parentComposition,
    folderId,
    defaultTitle,
    defaultStatus
}) => {
    const [initialPagination, setInitialPagination] = useState(defaultPagination);
    const retrieveNotes = useEntityNoteRetriever(folderId, parentComposition.uuid);
    const careEventsForArchetype = useCareEventsForArchetype(parentComposition.document_type);
    //reset pagination to new object to change props of table display and re-retrieve the table contents from page 1.
    const onSubmit = useCallback(() => setInitialPagination(resetPagination()), []);

    return careEventsForArchetype && (
        <>
            <UserNoteSimpleEntry
                parentEntityUuid={parentComposition.uuid}
                folderId={folderId}
                defaultTitle={defaultTitle}
                defaultStatus={defaultStatus}
                careEventTypes={careEventsForArchetype}
                onSubmit={onSubmit}
            />
            <UserNoteTableDisplay
                folderId={folderId}
                retrieveNotes={retrieveNotes}
                initialPagination={initialPagination}
            />
        </>
    );
}