import { useState, useEffect } from 'react';
import ServerFetch from 'server-fetch';
import DataStore from 'services/data-store';
import { useAPI } from 'common/useAPI';

const ROLES_NAMES = {
    patient: 'Patient',
    staff: 'General staff',
    clinician: 'Clinician',
    teamAdmin: 'Team admin',
    bot: 'Bot'
};

export function useCurrentRoles(portal = 'clinical_portal', types = []) {
    const [currentRoles, setCurrentRoles] = useState(undefined);
    const [hasInvites, setHasInvites] = useState(undefined);
    const [isError, setIsError] = useState(false);
    const { version } = useAPI();

    useEffect(() => {
        const listenIdx = DataStore.getListener('currentRoles',
            (roles) => {
                if (isError) { return; }

                if (!roles) {
                    if (version === 1) {
                        ServerFetch.getRequest(this, `/api/api/currentUserInfo`,
                            (response) => {
                                DataStore.set('has_invites', response.message.has_invites);
                                DataStore.set('currentRoles', response.message.roles);
                                DataStore.set('userFolderId', response.message.user_folder_id);
                                setHasInvites(response.message.has_invites);
                            },
                            () => {
                                console.error('Error when fetching user info')
                                setIsError(true);
                            });
                    }

                    if (version === 2) {
                        ServerFetch.getRequest(this, `/me/role-assignments`,
                            (response) => {
                                let roles = response.message.results;
                                if (types.length) {
                                    roles = roles.filter(role => types.find(type => role.name.indexOf(ROLES_NAMES[type]) === 0));
                                }
                                roles = roles.map(role => ({
                                    uuid: role.uuid,
                                    teamId: role.team_id,
                                    team: role.team,
                                    organisation: role.organisation,
                                    organisationId: role.organisation_id,
                                    name: role.name,
                                    serviceName: role.service_name
                                }))

                                //DataStore.set('has_invites', response.message.has_invites);
                                DataStore.set('currentRoles', roles);
                                DataStore.set('me.teams', roles);
                                // DataStore.set('userFolderId', response.message.user_folder_id);
                                setHasInvites(response.message.has_invites);
                            },
                            (err) => {
                                console.error(err);
                                console.error('Error when fetching roles')
                                setIsError(true);
                            });
                    }

                    return setCurrentRoles(roles);
                }

                if (version === 1) {
                    if (!Object.prototype.hasOwnProperty.call(roles, 'be clinician') && !hasInvites) {
                        return window.location.href = `/${portal}/login?status=not-a-clinician`;
                    }
                }

                if (version === 2) {
                    if (!roles.length){
                        //return window.location.href = `/${portal}/login?status=not-a-clinician`;
                    }
                }

                setCurrentRoles(roles);
            });
        return () => {
            DataStore.removeListener(listenIdx);
        };
    }, []);
    return [currentRoles, hasInvites];
}
