import { GridCell, GridRow } from 'common/ui/grid';
import React, { FC } from 'react';
import { DEVICE_OWNERSHIP_TYPES, DeviceGridItem } from './services/DevicesServiceHelper';

interface DevicesGridRowProps {
    deviceItem: DeviceGridItem;
    onEditDevice: (deviceItem: DeviceGridItem) => void;
    onReturnDevice: (deviceItem: DeviceGridItem) => void;
    actionsLabels: {
        return: string;
        edit: string;
    };
}

const defineOptionalFields = (deviceItem) => {
    if (deviceItem.ownershipInfo === DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_SINGLE_USE ||
        deviceItem.ownershipInfo === DEVICE_OWNERSHIP_TYPES.PATIENT_OWNED) {
        return {
            ...deviceItem,
            id: '',
        };
    } else {
        return {
            ...deviceItem,
            make: '',
            model: ''
        };
    }
};

export const DeviceGridRow: FC<DevicesGridRowProps> = ({
    deviceItem,
    onReturnDevice,
    onEditDevice,
    actionsLabels,
}) => {
    const processedDeviceItem = defineOptionalFields(deviceItem);
    const editActionLabel = actionsLabels.edit;
    const returnActionLabel = actionsLabels.return;
    return (
        <GridRow>
            <GridCell className={'ui-grid__cell--break-word'}>{processedDeviceItem.type}</GridCell>
            <GridCell className={'ui-grid__cell--break-word'}>{processedDeviceItem.ownershipInfo}</GridCell>
            <GridCell className={processedDeviceItem.ownershipInfo === DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_REUSABLE ?
                'ui-grid__cell--non-active' : 'ui-grid__cell ui-grid__cell--break-word'}
            >{processedDeviceItem.make}</GridCell>
            <GridCell className={processedDeviceItem.ownershipInfo === DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_REUSABLE ?
                'ui-grid__cell--non-active' : 'ui-grid__cell ui-grid__cell--break-word'}
            >{processedDeviceItem.model}</GridCell>
            <GridCell className={processedDeviceItem.ownershipInfo !== DEVICE_OWNERSHIP_TYPES.TRUST_OWNED_REUSABLE ?
                'ui-grid__cell--non-active' : 'ui-grid__cell ui-grid__cell--break-word'}>{processedDeviceItem.id}</GridCell>
            <GridCell>{processedDeviceItem.dateAssigned}</GridCell>
            <GridCell className={'ui-grid__cell--break-word'}>{processedDeviceItem.assignedBy}</GridCell>
            <GridCell className={'ui-grid__cell--break-word'}>{processedDeviceItem.department}</GridCell>
            <GridCell className={!processedDeviceItem.dateReturned ?
                'ui-grid__cell--non-active' : 'ui-grid__cell'}>{processedDeviceItem.dateReturned}</GridCell>
            <GridCell className={!processedDeviceItem.returnedTo ?
                'ui-grid__cell--non-active' : 'ui-grid__cell ui-grid__cell--break-word'}>{processedDeviceItem.returnedTo}</GridCell>
            <GridCell>
                <div className="btn-group">
                    {!processedDeviceItem.returnedTo && !processedDeviceItem.dateReturned &&
                    (
                        <button className="btn btn-xs btn-success" onClick={() => onReturnDevice(processedDeviceItem)}>
                            {returnActionLabel}
                        </button>
                    )}
                    <button className="btn btn-xs btn-default" onClick={() => onEditDevice(processedDeviceItem)}>
                        {editActionLabel}
                    </button>
                </div>
            </GridCell>
        </GridRow>
    );
};
