/* eslint-disable @typescript-eslint/camelcase */
import { Action, ACTION_NAME } from './actions';
import { ResultsItem, SELECTORS, State } from './QuestionnaireResultsPage';
import { Label } from 'models/Label';
import { ASYNC_JOB_NAME } from 'features/patient/questionnaire-results/asyncHandlers';
import { QuestionnaireResult, QuestionnaireScoreResults } from 'models/QuestionnaireResult';
import { cloneDeep, isEmpty } from 'lodash';
import { FormSelectItem } from 'features/patient/questionnaire-results/inline-selector-form/InlineSelectorsForm';
import { Dot, DotType, Line } from 'common/ui/line-graph/LineGraph';
import _ from 'services/i18n';

export const reducer = (state: State, { type, payload }: Action) => {
    if(!reducerHandlers[type]) {
        console.error('[QuestionnaireResultsPage reducer] ReducerHandler not found');
        return state;
    }
    return reducerHandlers[type](state, payload);
};

const isValidNumberScore = (score: QuestionnaireResult) => {
    const {
        score_value_float: scoreValueFloat,
        score_value_int: scoreValueInt,
        score_value_string: scoreValueString,
    } = score;

    return (scoreValueFloat !== null && scoreValueFloat !== undefined)
        || (scoreValueInt !== null && scoreValueInt !== undefined)
        || (scoreValueString !== null && scoreValueString !== undefined && `${parseFloat(scoreValueString)}` === scoreValueString);
};

const tryExtractNumberValue = (score: QuestionnaireResult) => {
    const {
        score_value_float: scoreValueFloat,
        score_value_int: scoreValueInt,
        score_value_string: scoreValueString,
    } = score;
    if (scoreValueInt == null && scoreValueFloat == null) {
        const newInt = parseInt(scoreValueString, 10);
        const newFloat = parseFloat(scoreValueString);
        return {
            ...score,
            score_value_float: isNaN(newInt) ? null : newInt,
            score_value_int: isNaN(newFloat) ? null : newFloat,
            score_value_string: scoreValueString,
        };
    }

    return {
        ...score,
        score_value_float: scoreValueFloat,
        score_value_int: scoreValueInt,
        score_value_string: scoreValueString,
    };
};

// reducer handlers

const handleContextReceived =  (state: State, labels: Label[]): State => {
    const contexts = getActivePathwayLabels(labels).map((label) => {
        return { label: label.name, value: label.context };
    });
    if(!contexts?.length) {
        const items = [];
        return {
            ...state,
            items,
            errorMessage: _`There are no active pathways for the patient`,
            contexts: [],
            loading: false,
        };
    }

    const asyncJobs = cloneDeep(state.asyncJobs);
    const items = cloneDeep(state.items);
    if (!items.length) {
        return {
            ...state,
            loading: false,
            errorMessage: 'Graphs are not set in team preferences. Please contact MyPathway Support.',
            contexts,
            asyncJobs,
        };
    }

    items.forEach((item) => {
        let context = item.selectors.get(SELECTORS.CONTEXT).value;
        const exists = contexts.find(({ value }) => value === context);
        if (!exists) {
            context = contexts[0].value;
            item.selectors.get(SELECTORS.CONTEXT).value = context;
        }
        asyncJobs.push({
            type: ASYNC_JOB_NAME.LOAD_RESULTS,
            payload: { context, folderId: state.folderId, teamId: state.teamId },
        });
    });

    const graphEdgeValues = getMaxMin(items);

    return {
        ...state,
        graphEdgeValues,
        loading: true,
        items,
        contexts,
        asyncJobs,
    };
};
const handleCleanJobs = (state: State): State => {
    return {
        ...state,
        asyncJobs: []
    };
};
const handleResultsReceived = (state: State, { results, context }: {results: Record<string, QuestionnaireResult[]>; context: string}): State => {
    state.rawResults.set(context, results);
    let items = cloneDeep(state.items);

    items = items.filter((item) => {
        const contextName = item.selectors.get(SELECTORS.CONTEXT)?.value;
        const questionnaireName = item.selectors.get(SELECTORS.QUESTIONNAIRE)?.value;
        return contextName === context && (Object.keys(results).includes(questionnaireName) || !questionnaireName);
    });

    items.forEach((item) => {
        const ctx = item.selectors.get(SELECTORS.CONTEXT)?.value;
        if(context !== ctx) {
            return;
        }
        updateResultsItem(item, results);
    });
    const graphEdgeValues = getMaxMin(items);
    return {
        ...state,
        graphEdgeValues,
        items,
        loading: false,
    };
};
const handleContextChanged = (state: State, { value, id }) => {
    const results = state.rawResults.get(value);
    const items = cloneDeep(state.items);
    const item = items[id];
    item.selectors.get(SELECTORS.CONTEXT).value = value;
    let partState = {};
    if(!results) {
        item.linesLoading = true;
        partState = {
            asyncJobs: [
                ...(state.asyncJobs || []),
                { type: ASYNC_JOB_NAME.LOAD_RESULTS, payload: { context: value } }
            ],
        };
    } else {
        updateResultsItem(item, results);
    }
    const graphEdgeValues = getMaxMin(items);
    return {
        ...state,
        ...partState,
        graphEdgeValues,
        items,
    };
};
const handleQuestionnaireChanged = (state: State, { value, id }) => {
    const items = cloneDeep(state.items);
    const item = items[id];
    const measureSelector = item.selectors.get(SELECTORS.MEASURE);
    const questionnaireSelector = item.selectors.get(SELECTORS.QUESTIONNAIRE);

    questionnaireSelector.value = value;
    const results = state.rawResults.get(item.selectors.get(SELECTORS.CONTEXT).value);
    const measureNames = getUniqValues('score_name', results[value]);

    measureSelector.value = measureNames[0] || '';
    if(measureNames.length > 1) {
        measureNames.push('All');
        measureSelector.value = 'All';
    }
    setOptions(measureSelector, measureNames);
    item.lines = generateLines(results, questionnaireSelector.value, measureSelector.value);
    if(measureSelector.value === 'Weight') {
        item.tableData = generateRelativeWeightsData(results);
    } else {
        item.tableData = [];
    }
    const graphEdgeValues = getMaxMin(items);
    return {
        ...state,
        graphEdgeValues,
        items,
    };
};
const handleMeasureChanged = (state: State, { value, id }) => {
    const items = cloneDeep(state.items);
    const item = items[id];
    item.selectors.get(SELECTORS.MEASURE).value = value;
    const context = item.selectors.get(SELECTORS.CONTEXT).value;
    const questionnaireName = item.selectors.get(SELECTORS.QUESTIONNAIRE).value;
    const scoreName = value;
    const results = state.rawResults.get(context);
    item.lines = generateLines(results, questionnaireName, scoreName);
    const graphEdgeValues = getMaxMin(items);
    return {
        ...state,
        graphEdgeValues,
        items,
    };
};
export const reducerHandlers: Record<string, (s: State, payload?: any) => State> = {
    [ACTION_NAME.PATHWAY_CONTEXTS_RECEIVED]: handleContextReceived,
    [ACTION_NAME.CLEAN_JOBS]: handleCleanJobs,
    [ACTION_NAME.RESULTS_RECEIVED]: handleResultsReceived,
    [ACTION_NAME.ONCHANGE_CONTEXT]: handleContextChanged,
    [ACTION_NAME.ONCHANGE_QUESTIONNAIRE]: handleQuestionnaireChanged,
    [ACTION_NAME.ONCHANGE_MEASURE]: handleMeasureChanged,
};

// helpers
const getMaxMin = (items: ResultsItem[]) => {
    const dots = items.reduce((acc, item) => {
        const dots = item.lines.reduce((acc, line) => {
            return acc.concat(line.dots || []);
        }, []);
        return acc.concat(dots);
    }, []).sort(({ x: a }, { x: b }) => {
        return a - b;
    });

    return {
        min: dots[0]?.x || 0,
        max: dots[dots.length - 1]?.x || 0
    };
};
const getActivePathwayLabels = (labels: Label[]) => {
    return labels.filter((lbl) => {
        return lbl.status && lbl.type === 'pathway';
    });
};
const groupBy = <T extends {}>(field: string, arr: T[] = []): Map<string, T[]> => {
    return arr.reduce((acc, item) => {
        if(!Object.prototype.hasOwnProperty.call(item, field)) {
            console.error('[groupBy] no such field in the item', field, item);
            return acc;
        }
        if (!acc.has(item[field])) {
            acc.set(item[field], []);
        }
        acc.get(item[field]).push(item);
        return acc;
    }, new Map());
};
const getUniqValues = <T>(field, arr: T[] = []): any[] => {
    const set = arr.reduce((acc, item) => {
        if(!Object.prototype.hasOwnProperty.call(item, field)) {
            console.error('[getUniqValue] no such field in the item', field, item);
            return acc;
        }
        acc.add(item[field]);
        return acc;
    }, new Set());
    return Array.from(set);
};
export const generateLines = (results: QuestionnaireScoreResults, questionnaire: string, measure: string | 'all' = 'all') => {
    const section = results?.[questionnaire] || [];
    const scores = groupBy<QuestionnaireResult>('score_name', section);
    if(!scores.size) {
        return [];
    }
    const scoreGraphLines = scoresToGraphLines((/^all$/i.test(measure) || !measure) ? Array.from(scores) : [[measure, scores.get(measure) || []]]);
    return scoreGraphLines;
};
const scoresToGraphLines = (arr: [string, QuestionnaireResult[]][]) => {
    const colors = [
        '#447ec5',
        '#c54478',
        '#71c544',
        '#a344c5',
        '#44c59c',
        '#b8c544',
        '#866487',
    ];

    return arr.map(([scoreName, scores], i): Line => {
        const dots = scoresToDots(scores);
        return {
            title: scoreName,
            color: colors[i%colors.length],
            dotType: DotType.Square,
            dots,
        };
    });
};
export const scoresToDots = (scores: QuestionnaireResult[]): Dot[] => {
    return scores.sort(({ timestamp: a }, { timestamp: b }) => a - b)
        .map(item => tryExtractNumberValue(item))
        .filter(item => isValidNumberScore(item))
        .map<Dot>(({
            timestamp,
            questionnaire_response_uuid,
            score_value_float: scoreValueFloat,
            score_value_int: scoreValueInt,
            score_value_string: scoreValueString,
        }) => {
            const val = scoreValueFloat ?? scoreValueInt ?? (scoreValueString && parseFloat(scoreValueString));
            return { x: timestamp * 1000, y: val, uuid: questionnaire_response_uuid  };
        });
};
const updateResultsItem = (item: ResultsItem, results: Record<string, QuestionnaireResult[]>): void => {

    const questionnaireSelector = item.selectors.get(SELECTORS.QUESTIONNAIRE);
    const measureSelector = item.selectors.get(SELECTORS.MEASURE);

    if(isEmpty(results)) {
        setOptions(questionnaireSelector, []);
        setOptions(measureSelector, []);
        item.linesLoading = false;
        item.lines = [];
        return;
    }

    const questionnaireNames = Object.keys(results);
    setOptions(questionnaireSelector, questionnaireNames);

    const measureNames = getUniqValues('score_name', results[questionnaireSelector.value] || []);

    measureNames.push('All');

    setOptions(measureSelector, measureNames);

    item.linesLoading = false;
    item.lines = generateLines(results, questionnaireSelector.value, measureSelector.value);

    if(measureSelector.value === 'Weight') {
        item.tableData = generateRelativeWeightsData(results);
    }
};

const setOptions = (selector: FormSelectItem, optionsData: string[] = []): void => {
    // if selected value is not in the options, generate disabled option for selected
    selector.options = optionsData
        .reduce((acc, d) => {
            acc.set(d, { value: d, label: d });
            return acc;
        }, new Map());
};

function generateRelativeWeightsData(results: Record<string, QuestionnaireResult[]>) {
    const weightResults = results['Weight'];

    return weightResults
        .map(item => tryExtractNumberValue(item))
        .filter(item => !isValidNumberScore(item))
        .map(({ timestamp, score_value_string: scoreValueString }) => {
            return { date: timestamp, value: scoreValueString };
        });
}
