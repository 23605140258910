import React from 'react';
import { IndexRoute, Route } from 'react-router';

import Template from 'layouts/coopweb/template';

import IndexPage from 'pages/coopweb';
import Login from 'pages/coopweb/login';
import Transfer from 'pages/coopweb/transfer';
import Logout from 'pages/coopweb/logout';
import SignUp from 'pages/coopweb/sign-up';
import PasswordReset from 'pages/coopweb/password-reset';
import ForgotPassword from 'pages/coopweb/forgot-password';
import ConfirmSignup from 'pages/coopweb/confirm';
import QuestionnairePage from 'pages/coopweb/questionnaire/_uuid';
import AuthCheckMiddleware from 'middleware/authorize-check';

const routes = (
    <Route path="/coopweb">
        <Route path="sign-up" component={SignUp} />
        <Route path="confirm/:token" component={ConfirmSignup} />
        <Route path="forgot-password" component={ForgotPassword} />
        <Route path="password-reset/:token" component={PasswordReset} />
        <Route path="login" component={Login} />
        <Route path="transfer" component={Transfer} />

        <Route component={(props) => <AuthCheckMiddleware {...props} redirectTo={'/coopweb/login'}/>}>
            <Route path="logout" component={Logout} />
            <Route component={Template}>
                <IndexRoute component={IndexPage} />
                <Route path="questionnaire/:uuid" component={QuestionnairePage} />
                <Route path="questionnaire/:uuid/:question_id" component={QuestionnairePage} />
            </Route>
        </Route>
    </Route>
);

export default routes;
