/* eslint-disable @typescript-eslint/camelcase */

import { SimpleQuestion, SimpleQuestionnaireApiForm, SimpleQuestionnaireCreate } from 'models/compositions/SimpleQuestionnaire';
import React, { useEffect, useState } from 'react';
import _ from 'services/i18n';
import { CreateQuestionInput } from './CreateQuestionInput';
import { simpleQuestionnaireService } from './simpleQuestionnaireContentService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SimpleQuestionnaireForm } from './model/SimpleQuestionnnaireModel';
import { PreviewSimpleQuestionnaireModal } from './PreviewSimpleQuestionnaireModal';
import lodash from 'lodash';
import confirmationModal from 'components/ConfirmationModal';

export const CreateSimpleQuestionnaire = ({ questionnaireState }): JSX.Element => {
    const [questionnaire, setQuestionnaire] = useState<SimpleQuestionnaireForm>(SimpleQuestionnaireForm.Create(questionnaireState));
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [formErrors]);
    
    useEffect(() => {
        if (!questionnaireState) { return; }
        setQuestionnaire(SimpleQuestionnaireForm.Create(questionnaireState));
        setIsEdit(true);
    }, [questionnaireState]);

    const handleCancel = (): void => {
        window.location.href = '/admin_portal/editor?type=simple-questionnaire';
    };

    const togglePreviewQuestionnaire = (): void => {
        setShowPreview(!showPreview);
    };


    const apiQuestionaireForm = (questionnaire: SimpleQuestionnaireCreate): SimpleQuestionnaireApiForm => {
        const _apiQuestionnaire: SimpleQuestionnaireApiForm = {
            location: 'general_content/simple-questionnaire',
            id: questionnaire.id,
            version: questionnaire.version,
            content_type: questionnaire.content_type,
            content: {
                in_lozenge: questionnaire.live.in_lozenge,
                name: questionnaire.live.name,
                introduction_text: questionnaire.live.introduction_text,
                questions: questionnaire.live.questions
            },
        };

        return _apiQuestionnaire;
    };

    const createQuestionnaire = (): void => {
        const formError = [];
        const formValidation = questionnaire.isValid();
        setFormErrors(formValidation.messages);

        if (!formValidation.isValid) { return; }

        if (isEdit) {
            simpleQuestionnaireService.updateContentById(apiQuestionaireForm(questionnaire)).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    window.location.href = '/admin_portal/editor?type=simple-questionnaire';
                }
            }).catch((res) => {
                formError.push(res.response.response.data);
                setFormErrors(formError);
            });

            return;
        }
        simpleQuestionnaireService.postContent(apiQuestionaireForm(questionnaire)).then((res) => {
            if (res.status === 200 || res.status === 201) {
                window.location.href = '/admin_portal/editor?type=simple-questionnaire';
            }
        }).catch((res) => {
            formError.push(res.response.response.data);
            setFormErrors(formError);
        });
    };

    const onQuestionChanged = (question: SimpleQuestion): void => {
        const _questionnaire = questionnaire;
        _questionnaire.updateQuestion(question);
        setQuestionnaire(SimpleQuestionnaireForm.Update(_questionnaire));
    };

    const addQuestion = (): void => {
        questionnaire.addQuestion();
        setQuestionnaire(SimpleQuestionnaireForm.Update(questionnaire));
    };

    const removeQuestion = (question: SimpleQuestion): void => {
        questionnaire.removeQuestion(question);
        setQuestionnaire(SimpleQuestionnaireForm.Update(questionnaire));
    };

    const handleOnDragEnd = (result: any): void => {
        if (!result.source) { return; }
        if (!result.destination) { return; }

        const _questionnaire = questionnaire;
        _questionnaire.updateQuestionPosition(result.source.index, result.destination.index);
        setQuestionnaire(SimpleQuestionnaireForm.Update(_questionnaire));
    };

    const onPropertyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === 'live.name' && isEdit === false) {
            const fileSafeName: string = value.replace(/[^a-z0-9]/gi, '-').toLowerCase();
            questionnaire.id = fileSafeName;
        }

        lodash.set(questionnaire, name, value);
        setQuestionnaire(SimpleQuestionnaireForm.Update(questionnaire));
    };

    const handleArchive = async (): Promise<any> => {
        const confirmArchive = await confirmationModal.show('Are you sure you would like to delete this questionnaire?');
        if (!confirmArchive) { return; }
        const formError = [];
        const location = 'general_content/simple-questionnaire';
        simpleQuestionnaireService.deleteContentById(questionnaire.id, location).then((res) => {
            window.location.href = '/admin_portal/editor?type=simple-questionnaire';
        }).catch((res) => {
            formError.push(res.response.response.data);
            setFormErrors(formError);
        });

    };

    return (
        <div>
            {formErrors.length > 0 && (
                <div className='alert alert-danger'>
                    <ul>
                        {formErrors.map((error, i) => {
                            return (<li key={i}>{error}</li>);
                        })}
                    </ul>
                </div>
            )}
            {questionnaireState && (
                <div>
                    <button className='btn btn-danger' onClick={handleArchive}>Archive</button>
                </div>
            )}

            <section className='questionnaire-create-section'>
                <div>
                    <label>Questionnaire Name*</label>
                    <input type='text' id='name' className='form-control' name='live.name' value={questionnaire.live.name || ''} onChange={onPropertyChange} maxLength={100} />
                    <label>Introduction text*</label>
                    <input type='text' id='introduction_text' className='form-control' name='live.introduction_text' value={questionnaire.live.introduction_text} onChange={onPropertyChange} />
                    <button className='btn btn-default add' onClick={addQuestion}>Add Question</button>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppable">
                        {(droppableProvided, droppableSnapshot) => (
                            <div
                                ref={droppableProvided.innerRef}>
                                {questionnaire.questions.map((question, index) => (
                                    <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                                        {(draggableProvided, draggableSnapshot) => (
                                            <div ref={draggableProvided.innerRef}
                                                {...draggableProvided.draggableProps}
                                                {...draggableProvided.dragHandleProps}>

                                                <CreateQuestionInput
                                                    value={question}
                                                    onChange={onQuestionChanged}
                                                    onRemove={removeQuestion}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div>
                    <p>Questions: {questionnaire.questions.length}</p>
                    <button className='btn btn-default cancel' onClick={handleCancel}>Cancel</button>
                    { isEdit  && <button className='btn btn-primary cancel' onClick={togglePreviewQuestionnaire}>Preview Questionnaire</button> }
                    <button className='btn btn-success create' onClick={createQuestionnaire}>{questionnaireState ? 'Update Questionnaire' : 'Create Questionnaire'}</button>
                </div>
            </section>
            { showPreview && (
                <PreviewSimpleQuestionnaireModal
                    questionnaire={questionnaire}
                    onClose={togglePreviewQuestionnaire} />
            ) }
        </div>
    );
};
