import React from 'react';
import BasePage from 'components/page/base';

import _ from 'services/i18n';
import DataStore from 'services/data-store';

import SubTeamList from 'components/team/list';

let listenIdx;

export default class extends BasePage {
    constructor (props) {
        super(props);

        this.state = {
            orgteam: {}
        };
    }

    componentWillMount () {
        listenIdx = DataStore.getListener('me.admin', (value) => {
            this.setState({
                orgteam: value || {}
            });
        });
    }

    componentWillUnmount () {
        DataStore.removeListener(listenIdx);
    }

    pageTitle () {
        return _`Viewing Teams | PHR Admin Portal`;
    }

    render () {
        if (DataStore.get('me.currentOrg')) {
            return (<SubTeamList listType="organisation" organisation={DataStore.get('me.currentOrg')} />)
        } else {
            return (<SubTeamList listType="team" team={DataStore.get('me.currentRole')} />)
        }
    }
}
