import BaseComponent from 'components/BaseComponent';
import View from './view';
import { connect } from 'react-redux';

class MFAPage extends BaseComponent {
    /**
     * @param {Object} props - Passed in properties
     */
    constructor (props) {
        super(props);

        let location = this.props.location || {}, query = location.query || {};

        this.View = View;
    }

}

function mapDispatchToProps(dispatch){
    return {
        dispatch
    };
}

export default connect(undefined,mapDispatchToProps)(MFAPage);