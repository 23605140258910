export const isValidNhsNumber = (txtNhsNumber: string): boolean => {
    let isValid: boolean = false;

    if (txtNhsNumber.length === 10) {
        let total: number = 0;
        let i: number = 0;

        for (i = 0; i <= 8; i++) {
            let digit: number = parseInt(txtNhsNumber.substr(i, 1));
            let factor: number = 10 - i;

            total += (digit * factor);
        }

        let checkDigit: number = (11 - (total % 11));

        if (checkDigit === 11) { checkDigit = 0; }

        if (checkDigit ===parseInt(txtNhsNumber.substr(9, 1))) isValid = true;
    }

    return isValid;
}