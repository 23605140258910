export default class {
    Change (e) {
        switch(e.target.name) {
            case 'surname':
            case 'nhsNumber':
                this.setState({
                    [e.target.name]: e.target.value
                });
        }
    }

    Filter () {
        this.setState({
            filter: {
                surname: this.state.surname,
                nhsNumber: this.state.nhsNumber
            },
            surname: '',
            nhsNumber: ''
        });
    }

    ToggleChecked (id) {
        let newMap = new Map(this.state.selected);
        if (newMap.has(id)) {
            newMap.delete(id);
        } else {
            newMap.set(id, true);
        }

        this.setState({
            selected: newMap
        });
    }
}