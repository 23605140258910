export enum TERActionTypes {
  assignQuestionnaire = 'Send Questionnaire',
  assignCoopQuestionnaire = 'Send Questionnaire',
  sendInvitation = 'Send Invitation',
  sendMessage = 'Send Message',
  setUserPreference = 'Set User Preference',
  setLabel = 'Set Label',
  unsetLabel = 'Unset Label',
  allocateResourceWithMessage = 'Assign Personalised Resource',
  inviteClinician = 'Invite Clinician',
  deregister = 'Deregister',
  changeEmail = 'Change Email',
  createUser = 'Create User',
  updateUser = 'Update User',
  removeUserFromTeam = 'Remove User from Team',
  sendPushNotification = 'Send Push Notification',
  setAppointmentMode = 'Set Appointment Mode',
  sendSms = 'Send SMS',
  assignSimpleQuestionnaire = 'Send Simple Questionnaire',
  sendEmail = 'Send Email',
  sendHtmlLetter = 'Send Letter',
  pifu = 'PIFU',
  autoCommunication = 'Auto Communication',
  assignTeamResources = 'Send Resource Collection',
  assignPathwayEvent = 'Send Invitation'
}
