import BasePage from '../../../../portal-components/components/page/base';
import React from 'react';
import _ from 'services/i18n';
import { MFAOptionSetup } from 'components/user/profile/mfa-setup/MFAOptionSetup';

/**
 * Extended Page Component for clinical_portal/documents
 **/
export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Multi-Factor Authentication` + ' | ' + _`User Profile` + ' | ' + _`PHR Clinical Portal`;
    }

    render () {
        return <MFAOptionSetup/>;
    }
}
