import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
// components
import {
    Grid,
    GridHeader,
    GridHeaderRow,
    GridHeaderCell,
    GridBody,
} from 'common/ui/grid';
import Pagination from 'components/pagination';
import { Loading } from 'common/ui/alert-boxes';
import BulkOperationFilters from 'features/patients/bulk-operations/components/BulkOperationFilters';
// utils
import { bulkOperationService } from 'services/bulk-operation.service';
// hooks
import { useI18N } from 'common/i18n/useI18N';
import {
    useBulkOperationFilters,
    useTeamClinicianList,
} from 'features/patients/bulk-operations/bulk-operations.hooks';
// interfaces
import { BulkOperationRequest } from 'models/BulkOperationRequests';
// styles
import './bulkOperation.scss';
import BulkOperationTableRow from 'pages/clinical_portal/bulk-operations/BulkOperationTableRow';
import { useCurrentUser } from 'common/useCurrentUser';

const BulkOperations: FC = () => {
    const [bulkOperationsCollection, setBulkOperationsCollection] = useState<
        BulkOperationRequest[]
    >([]);
    const [totalBulkOperations, setTotalBulkOperations] = useState<number>(0);
    const [isBulkOperationsLoading, setIsBulkOperationsLoading] =
        useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const perPageLimit = 10;
    const _ = useI18N();

    const clinicianList = useTeamClinicianList();
    const currentUser = useCurrentUser();

    const initialFilters = useMemo(() => {
        if (currentUser) {
            const initialUser = clinicianList.find((clinician) => clinician.user_id === currentUser.id);
            return {
                clinician: initialUser,
            };
        }
    }, [currentUser, clinicianList]);

    const {
        clinician,
        bulkOperationName,
        dateRange,
        changeClinician,
        changeBulkOperationName,
        changeDateRange,
    } = useBulkOperationFilters(initialFilters);

    const withPageReset = useCallback(<T,>(fn: (arg: T) => void) => {
        return (arg: T) => {
            setCurrentPage(1);
            fn(arg);
        };
    }, []);
    const onClinicianChange = withPageReset(changeClinician);
    const onBulkOperationNameChange = withPageReset(changeBulkOperationName);
    const onDateRangeChange = withPageReset(changeDateRange);

    const handleFilter = () => {
        setCurrentPage(1);
        if (isBulkOperationsLoading) {
            return;
        }

        getBulkOperations();
    };

    const getBulkOperations = useCallback((): void => {
        setIsBulkOperationsLoading(true);
        const offset: number = (currentPage - 1) * perPageLimit;
        const ISOformat = 'YYYY-MM-DD';

        bulkOperationService
            .getBulkOperationRequests({
                onlyInProgressBulkOperations: false,
                limit: perPageLimit,
                offset: offset,
                clinicianId: clinician ? clinician.user_id : undefined,
                boName: bulkOperationName ? bulkOperationName : undefined,
                fromDate: dateRange
                    ? dateRange[0].format(ISOformat)
                    : undefined,
                toDate: dateRange ? dateRange[1].format(ISOformat) : undefined,
            })
            .then(({ total, results }) => {
                setTotalBulkOperations(total);
                setBulkOperationsCollection(results);
                setIsBulkOperationsLoading(false);
            });
    }, [bulkOperationName, clinician, currentPage, dateRange]);

    useEffect(() => {
        getBulkOperations();
    }, [currentPage, getBulkOperations]);

    return (
        <div className={'scrollable-table'}>
            <h1>Operations</h1>

            <BulkOperationFilters
                clinician={clinician}
                clinicianList={clinicianList}
                bulkOperationName={bulkOperationName}
                dateRange={dateRange}
                onClinicianChange={onClinicianChange}
                onBulkOperationNameChange={onBulkOperationNameChange}
                onDateRangeChange={onDateRangeChange}
                onSubmit={handleFilter}
                isLoading={isBulkOperationsLoading}
            />

            {isBulkOperationsLoading && <Loading />}

            {!isBulkOperationsLoading &&
                bulkOperationsCollection.length < 1 && (
                <p>There are no bulk operations to review.</p>
            )}

            {!isBulkOperationsLoading && bulkOperationsCollection.length >= 1 && (
                <Fragment>
                    <p>
                        Below you will find a history of all the bulk operations
                        you have performed, each individual bulk operation can
                        be viewed to check the status per patient.
                    </p>
                    <div className={'scrollable-table bulk'}>
                        <Grid>
                            <GridHeader>
                                <GridHeaderRow>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                        field={
                                            'bulkOperationsData.results.content.name'
                                        }
                                    >
                                        {_`Name`}
                                    </GridHeaderCell>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                        field={''}
                                    >
                                        {_`Operation Type`}
                                    </GridHeaderCell>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                        field={''}
                                    >
                                        {_`Patients`}
                                    </GridHeaderCell>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                        field={
                                            'bulkOperationsData.results.created_at'
                                        }
                                    >
                                        {_`Date`}
                                    </GridHeaderCell>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                    >
                                        {_`% Processed`}
                                    </GridHeaderCell>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                    >
                                        {_`Action Statistics`}
                                    </GridHeaderCell>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                        field={
                                            'bulkOperationsData.results.content.status'
                                        }
                                    >
                                        {_`Status`}
                                    </GridHeaderCell>
                                    <GridHeaderCell
                                        sortable={false}
                                        filterable={false}
                                    >
                                        Details
                                    </GridHeaderCell>
                                </GridHeaderRow>
                            </GridHeader>
                            <GridBody>
                                {bulkOperationsCollection &&
                                    bulkOperationsCollection.map((bulkOperation, index) => {
                                        return <BulkOperationTableRow key={index} bulkOperation={bulkOperation} />;
                                    })
                                }
                            </GridBody>
                        </Grid>
                    </div>

                    <div className="advanced_search-pagination">
                        <Pagination
                            pageCount={Math.ceil(
                                totalBulkOperations / perPageLimit,
                            )}
                            currentPage={currentPage}
                            onChange={(newPage: number) =>
                                setCurrentPage(newPage)
                            }
                        />
                        <p className="pagination-summary">
                            Total number of results: {totalBulkOperations}
                        </p>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default BulkOperations;
