import React from 'react';
import {Link} from 'react-router';
import _ from 'services/i18n';

/**
 * Renders the header of the main page template
 */
class Template_Header extends React.Component {
    /**
     * @private
     */
    render () {
        return (
            <div className={"header " + this.props.mainPage}>
                <h2 className="header-text">{this.props.title}</h2>
            </div>
        );
    }
}

export default Template_Header;
