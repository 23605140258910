import React from 'react';
import { Link } from 'react-router';

/**
 * Render a patients name in a consistent way.
 * Currently this is just a link but we might want to get more complex later
 * 
 * Properties:
 *  - linksTo = Required. The full path we link to (href)
 *  - name = Required. The patient name
 *  - className = Optional. Class name to apply to the link or defaults to patient-name if nothing passed
 */

export const PatientName = ({linksTo, name, className}) => {
   
    return (
        <Link to={linksTo} className={className || 'patient-name'}>
            {name}
        </Link>
    );
};

export default PatientName;
