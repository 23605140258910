import { CompositionService } from './composition.service';
import { DataStore } from './data-store';
import { makeGetRequest, makePostRequest } from './api';
import {
    LetterStationeryItem,
    LetterTemplateItem
} from 'components/user/letters/letters.interface';

const apiVersion = 2;

export class LettersService extends CompositionService {
    get archetypeName(): string {
        return 'letter';
    }

    async getAllTemplates(): Promise<{
        stationery?: LetterStationeryItem[];
        templates?: LetterTemplateItem[];
    }> {
        const role = DataStore.get('me.currentRole');

        const response = await makeGetRequest(
            `/teams/${role.teamId}/team-folder/content?types[]=html-letters&using_role_uuid=${role.uuid}`,
            apiVersion,
        );

        const letterTemplates = response?.message?.items?.find(
            (item) => item.type === 'html-letters',
        );

        return {
            stationery: letterTemplates?.content?.stationery,
            templates: letterTemplates?.content?.letters,
        };
    }

    async getPdfPreview({
        header,
        body,
        footer,
    }: {
        header: string;
        body: string;
        footer: string;
    }): Promise<ArrayBuffer> {
        const role = DataStore.get('me.currentRole');

        const data = {
            using_role_uuid: role.uuid, // eslint-disable-line @typescript-eslint/camelcase
            pages: [
                {
                    page: 1,
                    header,
                    footer,
                    body,
                },
            ],
        };

        return await makePostRequest(
            `teams/${role.teamId}/team-folder/content/preview`,
            data,
            apiVersion,
            {
                responseType: 'arraybuffer',
            },
            {
                'Is-Request-Binary': 'true',
            },
        );
    }
}

export const lettersService = new LettersService();
