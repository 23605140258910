import React from 'react';
import PropTypes from 'prop-types'
import View from './view';
import BaseComponent from 'components/BaseComponent';
import DataStore from 'services/data-store';

import { profileService } from '../../../../../app/services/profile.service';

/**
 * A component containing a form letting a user update his/her photograph.
 */
export default class PhotographFormContainer extends BaseComponent {
    constructor (props) {
        super(props);

        this.state = {
            saving: false,
            saveStatus: null,
            userPhoto: null,
            showPhotoModal: false,
            userPhotoPath: this.getUserPhotoPath(props.portal)
        };

        this.bindEvents([
            'ClickChangePhoto',
            'CloseChangeModal',
            'UploadChangeModal'
        ]);

        this.bindFunctions([
        ]);

        this.View = View;
    }
    
    componentWillMount () {
    }

    getUserPhotoPath(){
        return `/files/${this.props.route.portal}/api/currentUserPhoto`;
    }
  
    /**
     * Event handler: User has clicked button to open the Change Photo modal.
     */
    handleClickChangePhoto () {
        this.setState({
            showPhotoModal: true,
            saveStatus: null
        });
    }
    
    /**
     * Event handler: User has closed or cancelled the Change Photo modal.
     */
    handleCloseChangeModal () {
        this.setState({
            showPhotoModal: false,
            saveStatus: null
        });
    }
    
    /**
     * Event handler: User has submitted the Change Photo modal.
     */
    handleUploadChangeModal (data) {
        this.$setState({
            saving: true,
            saveStatus: null,
            showPhotoModal: false
            
        }).then(() => {
            // Send the new photo to the server.
            return profileService.changePhoto(data, this.props.route.portal);
        }).then((response) => {
            // Request went through.
            if (response.status == 200 || response.status == 'OK') {
                this.setState({
                    saving: false,
                    saveStatus: true
                });
                this.updateUserProfile();
            } else {
                // An error was reported by the server.
                this.setState({
                    saving: false,
                    saveStatus: false
                });
            }
        }, () => {
            // Request failed.
            this.setState({
                saving: false,
                saveStatus: false
            });
        });
    }
    
    /**
     * Fetch the latest user profile data and put it in the data store.
     * This is used to force an update of the photo in the sidebar.
     */
    updateUserProfile () {
        profileService.getCurrentUserProfile()
        .then((response) => {
            if (response.status == 200 || response.status == 'OK') {
                DataStore.set('currentUser', response.message);
            } else {
                console.warn('Failed to fetch updated user profile.');
            }
        }, (error) => {
            console.warn(error);
        });
    }
}

PhotographFormContainer.propTypes = {
    portal: PropTypes.string
};

PhotographFormContainer.defaultProps = {
    portal:'clinical'
};